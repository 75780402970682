import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { modelActions } from '../../../../../store/_actions';
//components
import { Loader } from '../../../../shared/Loader/Loader';
//icons
import { ReactComponent as ArrowDown } from './../../../../../Assets/images/icons/icon_arrow_down_1.svg';
import { ReactComponent as ArrowUp } from './../../../../../Assets/images/icons/icon_arrow_up_1.svg';
import { Topic } from '../../topic/Topic';

class ModelIndex extends Component {
    constructor(props) {
        super(props);

        //need this because theres a trash tag wrapping titles
        this.pTag = "<p>";
        this.filterArray = [
            {
                Label: "ABC",
                Filter: [this.pTag + 'a', this.pTag + 'b', this.pTag + 'c']
            },
            {
                Label: "DEF",
                Filter: [this.pTag + 'd', this.pTag + 'e', this.pTag + 'f']
            },
            {
                Label: "GHI",
                Filter: [this.pTag + 'g', this.pTag + 'h', this.pTag + 'i']
            },
            {
                Label: "JKL",
                Filter: [this.pTag + 'j', this.pTag + 'k', this.pTag + 'l']
            },
            {
                Label: "MNO",
                Filter: [this.pTag + 'm', this.pTag + 'n', this.pTag + 'o']
            },
            {
                Label: "PQR",
                Filter: [this.pTag + 'p', this.pTag + 'q', this.pTag + 'r']
            },
            {
                Label: "STU",
                Filter: [this.pTag + 's', this.pTag + 't', this.pTag + 'u']
            },
            {
                Label: "VWY",
                Filter: [this.pTag + 'v', this.pTag + 'w', this.pTag + 'x']
            },
            {
                Label: "YZ",
                Filter: [this.pTag + 'y', this.pTag + 'z']
            }
        ];

        this.state = {
            indexLoader: true,
            indexSize: "160px",
            indexMinHeight: "177px",
            showArrow: true,
            indexContainers: [],
            indexTopics: [],
            indexBites: [],
            ActiveFilter: -1
        };

        // handlers
        this.selectContainer = this.selectContainer.bind(this);
        this.selectTopic = this.selectTopic.bind(this);
        this.selectBite = this.selectBite.bind(this);
        this.hideBites = this.hideBites.bind(this);
    }


    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {

        if (this.props.SelectedPath.LastUpdate === "Page") {

            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
            //check if this tree was already loaded
            if (!activeModelSet.containers) {
                this.props.getModelTree(this.props.SelectedPath.Model, this.props.SelectedLanguage);
            } else {
                this.SetModelIndex();
            }
        }
    }

    componentDidUpdate(prevProps) {

        //new tree loaded
        if (prevProps.LoadingModelTree && !this.props.LoadingModelTree) {
            //update modelTree
            let newModels = JSON.parse(JSON.stringify(this.props.ModelSet));
            newModels.items = newModels.items.map((item) => { if (item.id === this.props.ModelTree.id) item = this.props.ModelTree; return item; });
            this.props.updateModelSet(newModels);
        }

        //modelSet updated with new tree
        if (prevProps.ModelSet !== this.props.ModelSet) {
            if (this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0].containers) {
                this.SetModelIndex();
            }
        }

        //selected model changed
        if (prevProps.SelectedPath !== this.props.SelectedPath) {

            //selected model changed
            if (prevProps.SelectedPath.Model !== this.props.SelectedPath.Model && this.props.SelectedPath.LastUpdate === "Model") {
                var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
                //check if this tree was already loaded
                if (!activeModelSet.containers) {
                    this.props.getModelTree(this.props.SelectedPath.Model, this.props.SelectedLanguage);

                } else {
                    this.SetModelIndex();
                }
            } else if (this.props.SelectedPath.LastUpdate === "Carousel") {
                //change by selecting a model section
                this.SetSelection();
            }
        }
    }


    SetSelection() {
        //change by selecting a model section
        var pos = -1;
        if (this.props.SelectedPath.Container !== -1) {
            this.SetModelIndexTopics(this.props.SelectedPath.Container, true);

            pos = this.state.indexContainers.findIndex(c => c.Id === this.props.SelectedPath.Container);
            if (pos > 0) {
                this.setState({ indexContainers: this.rearrangeItems(this.state.indexContainers, pos, 0) });
            }
        } else {
            this.SetModelIndex();
        }

        if (this.props.SelectedPath.Topic !== -1) {
            this.SetModelIndexTopics(this.props.SelectedPath.Container, true);
            this.SetModelIndexBites(this.props.SelectedPath.Topic, true);
        }

        if (this.props.SelectedPath.Bite !== -1) {
            this.SetModelIndexTopics(this.props.SelectedPath.Container, true);
            this.SetModelIndexBites(this.props.SelectedPath.Topic, true);
        }
    }

    /************************************
    * click on Model section or index
    ***********************************/

    rearrangeItems(items, moveFromIndex, moveToIndex) {

        const movingItem = items[moveFromIndex];
        items.splice(moveFromIndex, 1);
        items.splice(moveToIndex, 0, movingItem);

        return items;
    }

    /************************************
    * UPDATE INDEX (OK)
    ************************************/

    SetModelIndex(ignorePage) {
        var lastUpdate = this.props.SelectedPath.LastUpdate;

        this.setState({ indexLoader: true, ActiveFilter: -1 }, function () {
            setTimeout(function () {
                if (lastUpdate !== "Model") {
                    if (lastUpdate !== "Page" || ignorePage) {
                        //update selected path
                        this.UpdateSelectedPath(-1, -1, -1, "Index", null);
                    }
                } else {
                    this.UpdateSelectedPath(-1, -1, -1, "Model", null);
                }

                var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
              
                //change show containers
                var containers = activeModelSet.containers.map(c => ({
                    Id: c.id,
                    Title: c.title
                }));

                //change show topics
                var topics = [];
                for (var i = 0; i < activeModelSet.containers.length; i++) {
                    topics = topics.concat(activeModelSet.containers[i].topics.map(c => ({
                        Id: c.id,
                        Title: c.title,
                        Bites: c.bites
                    })));
                }
                
                //change show bites
                var bites = [];
                for (i = 0; i < activeModelSet.containers.length; i++) {
                    for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                        bites = bites.concat(activeModelSet.containers[i].topics[j].bites.map(c => ({
                            Id: c.id,
                            Title: c.title,
                            inProduction: c.inProduction,
                            hidden: false
                        })));
                    }
                }

                //update state
                this.setState({
                    indexContainers: containers,
                    indexTopics: topics,
                    indexBites: bites,
                    indexLoader: false
                }, function () {
                    if (lastUpdate === "Page") {
                        this.SetSelection();
                    }
                });
            }.bind(this), 50);
        });
    }

    //udpate topics in index
    SetModelIndexTopics(id, rearrange) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //change show topics
        var topics = [];
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            if (activeModelSet.containers[i].id === id) {
                topics = topics.concat(activeModelSet.containers[i].topics.map(c => ({
                    Id: c.id,
                    Title: c.title,
                    Bites: c.bites
                })));
            }
        }

        if (rearrange) {
            var pos = topics.findIndex(c => c.Id === this.props.SelectedPath.Topic);
            if (pos > 0) {
                topics = this.rearrangeItems(topics, pos, 0);
            }
        }

        //update state
        this.setState({
            indexTopics: topics,
            indexBites: [],
            indexLoader: false,
            ActiveFilter: -1
        });
    }

    //update bites in index
    SetModelIndexBites(id, rearrange) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //change show bites
        var bites = [];
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                if (activeModelSet.containers[i].topics[j].id === id) {
                    bites = bites.concat(activeModelSet.containers[i].topics[j].bites.map(c => ({
                        Id: c.id,
                        Title: c.title,
                        inProduction: c.inProduction
                    })));
                }
            }
        }

        if (rearrange) {
            var pos = bites.findIndex(c => c.Id === this.props.SelectedPath.Bite);
            if (pos > 0) {
                bites = this.rearrangeItems(bites, pos, 0);
            }
        }

        //update state
        this.setState({
            indexBites: bites,
            indexLoader: false,
            ActiveFilter: -1
        });
    }

    //get parent container
    getTopicParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            if (activeModelSet.containers[i].topics.filter(c => c.id === id).length > 0) {
                return activeModelSet.containers[i].topics.filter(c => c.id === id)[0].containerId;
            }
        }

        return -1;
    }

    //get parent topic
    getBiteParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                if (activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id).length > 0) {
                    return activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id)[0].topicId;
                }
            }
        }

        return -1;
    }

    /************************************
    * CLICK ON INDEX ITEM (OK)
    ************************************/

    selectContainer(id, e) {
        this.setState({ indexLoader: true }, function () {
            if (id === -1 || (e && id === this.props.SelectedPath.Container)) {
                this.SetModelIndex(true);
            } else {
                //update selected path
                this.UpdateSelectedPath(id, -1, -1, "Index", null);

                //1. set inner topics and bites
                this.SetModelIndexTopics(id);
            }
        });
    }

    selectTopic(Topic, e) {
        if (Topic.Bites.length === 0){
            return
        }
        this.setState({ indexLoader: true }, function () {
            if (Topic.Id === -1 || (Topic.Id === this.props.SelectedPath.Topic)) {
                this.SetModelIndex(true);
            } else {
                //update selected path
                this.UpdateSelectedPath(this.getTopicParent(Topic.Id), Topic.Id, -1, "Index", null);

                //1. set inner bites
                this.SetModelIndexBites(Topic.Id);
            }
        });
    }

    selectBite(id, e) {
        this.setState({ indexLoader: true }, function () {
            if (id === -1 || (id === this.props.SelectedPath.Bite)) {
                this.SetModelIndex(true);
            } else {
                //1. set selected bite
                //update selected pat
                var topicId = this.getBiteParent(id);
                this.UpdateSelectedPath(this.getTopicParent(topicId), topicId, id, "Index", null);
            }
        });
    }

    /************************************
    * SELECTED PATH UPDATE
    ***********************************/

    UpdateSelectedPath(container, topic, bite, lastUpdate, model) {
        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);

        if (model) {
            path.Model = model;
        }
        if (container) {
            path.Container = container;
        }
        if (topic) {
            path.Topic = topic;
        }
        if (bite) {
            path.Bite = bite;
        }
        if (lastUpdate != null) {
            path.LastUpdate = lastUpdate;
        }

        this.props.setSelectedPath(path);

        this.setState({ indexLoader: false });
    }

    /************************************
    * INDEX BITES FILTER
    ***********************************/

    hideBites(i) {
        if (this.state.ActiveFilter === i) {
            //1. remove filter
            let sorted = JSON.parse(JSON.stringify(this.state.indexBites.slice())).map((item) => { item.hidden = false; return item });

            //2. Update state
            this.setState({ indexBites: sorted, ActiveFilter: -1 });
        } else {
            //1. get leters to check
            const checkLetters = this.filterArray[i].Filter;

            //2. set hidden val
            let sorted = JSON.parse(JSON.stringify(this.state.indexBites.slice()))
            for (let b = 0; b < sorted.length; b++) {
                if (checkLetters.length === 3) {
                    if (sorted[b].Title.toLowerCase().indexOf(checkLetters[0]) === 0 ||
                        sorted[b].Title.toLowerCase().indexOf(checkLetters[1]) === 0 ||
                        sorted[b].Title.toLowerCase().indexOf(checkLetters[2]) === 0) {
                        sorted[b].hidden = false;
                    } else {
                        sorted[b].hidden = true;
                    }
                } else {
                    if (sorted[b].Title.toLowerCase().indexOf(checkLetters[0]) === 0 ||
                        sorted[b].Title.toLowerCase().indexOf(checkLetters[1]) === 0) {
                        sorted[b].hidden = false;
                    } else {
                        sorted[b].hidden = true;
                    }
                }
            }

            //3. Update state
            this.setState({ indexBites: sorted, ActiveFilter: i });
        }
    }

    /************************************
    * INDEX SIZE CHANGE
    ***********************************/

    indexSize(index) {
        //check if bottom arrow is to hide
        if (this.state.indexSize === "160px" && index === 1) {
            this.setState({ showArrow: false });
        } else {
            this.setState({ showArrow: true });
        }

        //set container height for correct animations
        if (this.state.indexSize === "160px") {
            if (index === 0) {
                this.setState({ indexSize: "0" });
                this.setState({ indexMinHeight: "17px", });
            } else {
                this.setState({ indexSize: "300px" });
            }
        } else {
            this.setState({ indexSize: "160px", });
            this.setState({ indexMinHeight: "177px", });
        }
    }

    render() {
        const {
            SelectedPath
        } = this.props

        return (
            <div className="model-sub-navigation">
                <div className="msn-menu">
                    <div className="msn-menu-nav">INDEX</div>
                    <div className="msn-menu-nav">TOPIC</div>
                    <div className="msn-menu-nav">FEED</div>
                </div>
                <div className="msn-outer" style={{ minHeight: this.state.indexMinHeight }}>
                    <div className="msn-outer-container">
                        <div className="msn-inner" style={{ maxHeight: this.state.indexSize, minHeight: this.state.indexSize }}>
                            <div className="arrow up" onClick={() => this.indexSize(0)}><ArrowUp /></div>
                            <div className="msn-section">
                                <div className="msn-section-inner" ref="indexContainers">
                                    {this.state.indexContainers && this.state.indexContainers.map((Container, index) => {
                                        return <p
                                            id={"indexTopic-" + Container.Id} key={"container" + index}
                                            className={SelectedPath.Container === Container.Id ? "active" :  ""}
                                            onClick={(e) => this.selectContainer(Container.Id, e)}>{Container.Title}
                                            </p>
                                    })}
                                </div>
                            </div>
                            <div className="msn-section">
                                <div className="msn-section-inner" ref="indexTopics">
                                    {this.state.indexTopics && this.state.indexTopics.map((Topic, index) => {
                                        let isDisabledClassName = Topic.Bites.length === 0 ? 'disabled' : '';
                                        const isActiveClassName = (SelectedPath.Topic === Topic.Id ? "active" : "");
                                        const className = [isActiveClassName, isDisabledClassName].join(' ')
                                        
                                        return <p
                                            id={"indexBite-" + Topic.Id}
                                            className={className}
                                            onClick={(e) => this.selectTopic(Topic, e)}
                                            dangerouslySetInnerHTML={{ __html: Topic.Title }}
                                            key={"topic" + index}></p>
                                    })}
                                </div>
                            </div>
                            <div className="msn-section">
                                {this.state.indexBites.length > 19 && <div className="msn-alphabet">
                                    {this.filterArray.map((letters, index) => {
                                        return <span
                                            key={"filter" + index}
                                            className={(this.state.ActiveFilter === index ? ' active' : '')}
                                            onClick={() => this.hideBites(index)}>{letters.Label}</span>
                                    })}
                                </div>}
                                <div className="msn-section-inner" ref="indexBites">
                                    {this.state.indexBites && !this.changingLetter && this.state.indexBites.map((Bite, index) => {
                                        const isDisabledClassName = Bite.inProduction ? 'disabled' : '';
                                        const isActiveClassName = SelectedPath.Bite === Bite.Id ? "active" : "";
                                        const isHiddenClassName = Bite.hidden ? 'hidden' : '';
                                        
                                        const className = [isActiveClassName, isDisabledClassName, isHiddenClassName].join(' ')
                                        
                                        if(Bite.inProduction){
                                            return <p
                                                id={"indexPage-" + Bite.Id}
                                                className={className}
                                                dangerouslySetInnerHTML={{ __html: Bite.Title }}
                                                key={"topic" + index}></p>
                                        }

                                        return <Link id={`indexPage-${Bite.Id}`}
                                                     to={{pathname: '/feed/' + Bite.Id}}
                                                     key={"bite" + index}
                                                     className={className}
                                                     dangerouslySetInnerHTML={{__html: Bite.Title}}></Link>
                                    })}
                                </div>
                            </div>
                        </div>
                        {this.state.indexLoader && <Loader />}
                        <div className={"arrow " + (this.state.showArrow ? "down" : " down rotate180")} onClick={() => this.indexSize(1)} ><ArrowDown /></div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelSet,
        LoadingModels,
        LoadingModelTree,
        ModelTree,
        SelectedPath
    } = state.model;

    return {
        SelectedLanguage,
        ModelSet,
        LoadingModels,
        LoadingModelTree,
        ModelTree,
        SelectedPath
    };
}

const actionCreators = {
    getModelTree: modelActions.getModelTree,
    updateModelSet: modelActions.updateModelSet,
    setSelectedPath: modelActions.setSelectedPath
};

const connectedModelIndex = connect(mapState, actionCreators)(ModelIndex);
export { connectedModelIndex as ModelIndex };