import React, { Component } from 'react';
//icons
import { ReactComponent as Loading } from './../../../Assets/images/icons/icon_loadmore.svg';
//styles
import "./LoadMore.scss";

export class LoadMore extends Component {
    render() {
        return (
            <div className="load-more-container">
                <div className={"load-more" + (this.props.loading ? ' loading' : '')} onClick={() => this.props.loadMore()}>
                    <span>Load more</span>
                    <Loading />
                </div>
            </div>
        );
    }
}