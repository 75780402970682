import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions } from './../../../../../../store/_actions';
//components
import { ModelActiveL2 as ModelActive } from './ModelActiveL2';
//icons
import { ReactComponent as FullscreenClose } from './../../../../../../Assets/images/icons/icon_fullscreen_2.svg';
import { ReactComponent as Zoom } from './../../../../../../Assets/images/icons/icon_zoom.svg';
import { ReactComponent as ArrowDown } from './../../../../../../Assets/images/icons/icon_arrow_down_1.svg';

class Model2 extends Component {
    constructor(props) {
        super(props);

        this.activeModel = this.props.ModelSet.items[1];
        this.ModelId = this.activeModel.id;
        this.Mapping = this.activeModel.mapping;

        // initial state
        this.state = {
            activePie: -1,
            hoverPie: -1,
            changed: false,/*force styles update*/
            switch: false,
            Zoom: false,
            showIndicator: false,
            actionFromPageExecuted: false,
            callFromPage: true
        }

        // event handlers
        this.hoverPie = this.hoverPie.bind(this);
        this.setStyles = this.setStyles.bind(this);
        this.selectPie = this.selectPie.bind(this);
        this.switchLevel = this.switchLevel.bind(this);
        this.ToggleFullsreen = this.ToggleFullsreen.bind(this);
        this.setIndicator = this.setIndicator.bind(this);
    }

    /************************************
    * LIFECYCLE
    ************************************/

    componentDidMount() {
        if (this.props.FromContext) {
            //ste intial path coming from this.props.Tile
            if (this.props.Tile.type === "Bite") {
                this.setSection(this.props.Tile.id, this.props.Tile.Topic.id, this.props.Tile.Container.id);
            } else if (this.props.Tile.type === "Topic") {
                this.setSection(-1, this.props.Tile.id, this.props.Tile.Container.id);
            }
        }

        if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted) {
            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
            if (activeModelSet.containers) {
                //ste intial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }
        }
    }

    componentDidUpdate(prevProps) {
        //if model is selected
        if (this.props.SelectedPath.Model === this.ModelId) {
            //index change incoming
            if (this.props.SelectedPath.LastUpdate === "Index" && !this.Updating) {
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

            //when coming from topic/bite page
            if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted && this.state.callFromPage) {
                //ste initial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

        } else if (this.props.SelectedPath.LastUpdate === "Model" && !this.Updating) {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
   * SELECTED PATH UPDATE
   ***********************************/

    UpdateSelectedPath(container, topic, bite, lastUpdate, model) {

        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);

        if (model) {
            path.Model = model;
        }
        if (container) {
            path.Container = container;
        }
        if (topic) {
            path.Topic = topic;
        }
        if (bite) {
            path.Bite = bite;
        }

        if (lastUpdate != null) {
            path.LastUpdate = lastUpdate;
        }
        this.props.setSelectedPath(path);
        setTimeout(function () {
            this.Updating = false;
        }.bind(this), 300);
    }

    //get parent container
    getTopicParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            if (activeModelSet.containers[i].topics.filter(c => c.id === id).length > 0) {
                return activeModelSet.containers[i].topics.filter(c => c.id === id)[0].containerId;
            }
        }

        return -1;
    }

    //get parent topic
    getBiteParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                if (activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id).length > 0) {
                    return activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id)[0].topicId;
                }
            }
        }

        return -1;
    }

    /************************************
    * SECTIONS HOVER & STYLES
    ***********************************/

    hoverPie(index) {
        if (!this.props.FromContext) {
            this.setState({ hoverPie: index, changed: true });
        }
    }

    setStyles(index) {
        var styles = "";

        //active
        if (this.state.activePie === index) {
            styles += " active";
        }

        //hover
        if (this.state.hoverPie === index) {
            styles += " hover";
        }

        return styles;
    }

    /************************************
    * SETTING SELECTED AREA
    ************************************/

    selectPie(e, index) {
        if (this.props.SelectedPath.Model === this.ModelId || this.props.FromContext) {

            if (index === this.state.activePie) {
                this.setIndex(e, -1);
            } else {

                this.setState({
                    activePie: index,
                    changed: true
                });
                this.setIndex(e, index);
                this.setIndicator();
            }
        }
    }

    setIndex(e, index) {
        //console.log("index: " + index);
        //this.props.setUpdateComingFrom("model");
        this.setState({ callFromPage: e === null }, function () {
            var container = -1, topic = -1, bite = -1;

            if (index === -1) {
                this.setState({
                    activePie: -1,
                    changed: false
                });
            } else {
                //area got selected
                var map = this.Mapping.filter(map => map.id === index);

                if (map.length > 0) {
                    map = map[0];
                    if (map.type === "Container") {
                        container = map.resourceId;
                    } else if (map.type === "Topic") {
                        topic = map.resourceId;

                        //get parent container
                        container = this.getTopicParent(topic);
                    } else if (map.type === "Bite") {
                        bite = map.resourceId;

                        //get parent topic
                        var topicId = this.getBiteParent(map.resourceId);
                        topic = topicId;

                        //get parent container
                        container = this.getTopicParent(topicId);
                    }
                }
            }

            //console.log("this.props.SelectedPath.LastUpdate-> " + this.props.SelectedPath.LastUpdate);
            if (this.props.SelectedPath.LastUpdate === "Index") {
                this.UpdateSelectedPath(null, null, null, "", null);

            } else if (!this.state.actionFromPageExecuted && this.props.SelectedPath.LastUpdate === "Page") {
                this.setState({ actionFromPageExecuted: true });

            } else if (!this.state.callFromPage) {
                this.UpdateSelectedPath(container, topic, bite, "Carousel", null);
            }

            //id 1 has to switch level
            if (index === 1) {
                this.switchLevel();
            } else if (this.state.switch) {
                this.setState({ switch: false });
            }
        });
    }

    setSection(bite, topic, container) {
        this.Updating = true;

        var section = [];

        if (bite !== -1) {
            section = this.Mapping.filter(map => map.resourceId === bite && map.type === "Bite");
        }

        //check if bite section was found. If not search topic
        if (section.length === 0) {
            if (topic !== -1) {
                section = this.Mapping.filter(map => map.resourceId === topic && map.type === "Topic");
            }
        }

        //check if topic section was found. If not search Container
        if (section.length === 0) {
            if (container !== -1) {
                section = this.Mapping.filter(map => map.resourceId === container && map.type === "Container");
            }
        }

        //update model
        if (section.length > 0) {
            //set section
            if (section[0].id !== this.state.activePie) {
                this.selectPie(null, section[0].id);
            } else {
                setTimeout(function () {
                    this.Updating = false;
                }.bind(this), 300);
            }
        } else {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
    * LEVEL SWITCH
    ************************************/

    switchLevel(toggleLevel) {
        if (this.props.SelectedPath.Model === this.ModelId) {
            this.setState({ switch: toggleLevel });
        }
    }

    /************************************
    * TOGGLE FULLSCREEN
    ************************************/

    ToggleFullsreen() {
        this.setState({ Zoom: !this.state.Zoom });
        this.props.handleZoom();
    }

    /************************************
    * SHOW "GO TO" INDICATOR
    ************************************/

    setIndicator() {
        if (!this.state.showIndicator && this.state.Zoom) {
            this.setState({ showIndicator: true }, function () {
                setTimeout(function () {
                    this.setState({ showIndicator: false })
                }.bind(this), 3000);
            });
        }
    }

    /************************************
    * CHANGE TO MODEL FÜRUNGSKRAFT
    ************************************/

    changeModel(e) {
        if (this.state.Zoom) {
            this.ToggleFullsreen();
        }
        this.props.handleChangeModel(e, this.props.ModelSet.items[2].id, this.ModelId)
    }

    render() {
        const { SelectedLanguage } = this.props

        return (
            <div className={"slide-container" + (this.state.Zoom ? ' magnify' : '')}>
                <ModelActive Level={this.state.switch ? 2 : 1} toggleLevel={this.switchLevel} activeModel={this.props.Selected} />
                <svg version="1.1" className={"model model2" + (this.state.switch ? ' switch' : '')} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 850.39 850.39" xmlSpace="preserve">
                    <g className="lv1">

                        <g id="Circle_-_3_2_">
                            <g id="Circle_1_" className={"model-option" + (this.state.changed ? this.setStyles(3) : '')}>
                                <path className="st0" onMouseEnter={() => this.hoverPie(3)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 3)}
                                    d="M425.2,75.33c-193.23,0-349.87,156.64-349.87,349.87S231.97,775.07,425.2,775.07S775.07,618.43,775.07,425.2 S618.43,75.33,425.2,75.33z M425.57,697.69c-143.85,0-262.54-46.57-280.14-132.68c-29.51-65.99-6.1-168.97,52.78-270.94 c62.28-107.88,145.38-181.95,220.68-181.82c79.55-12.49,170.64,64.45,237.64,180.5c62.76,108.7,85.23,218.54,46.25,283.5 C677.52,655.17,563.03,697.69,425.57,697.69z" />
                            </g>
                            {SelectedLanguage === "en-GB" &&
                                <>
                                    <g>
                                        <path className="st1" d="M383.0585,761.5977l.1162-.8317a4.3278,4.3278,0,0,1-.6581.4527,3.4985,3.4985,0,0,1-2.1294.2984,2.9538,2.9538,0,0,1-2.7054-2.0733,3.1565,3.1565,0,0,1-.013-1.2576c.5031-3.177.7547-4.7655,1.2579-7.9424a5.3712,5.3712,0,0,1,.5539-1.8156c.823-1.45,2.6062-1.54,4.0394-1.3319,1.1353.1644,2.6345.6131,3.1949,1.7569a2.6546,2.6546,0,0,1,.1834,1.5384l-.27,2.0427c-1.0529-.1393-1.5792-.2112-2.6312-.36l.19-1.3469c.067-.4758.1732-.9446-.2658-1.3109a1.4571,1.4571,0,0,0-.7536-.3106,1.5079,1.5079,0,0,0-.8536.098c-.4345.2205-.6075.6373-.7376,1.5075-.4413,2.9517-.6619,4.4276-1.1032,7.3794a1.887,1.887,0,0,0-.0064.7474c.0752.3121.3.6931,1.0669.8046a1.4316,1.4316,0,0,0,1.1082-.2048,1.7516,1.7516,0,0,0,.487-1.2043l.307-2.1785c-.6559-.0925-.9839-.14-1.6395-.2355.1228-.8391.1841-1.2586.3069-2.0977,1.7091.25,2.5643.3693,4.2759.5956-.4018,3.0378-.6026,4.5567-1.0044,7.5945C384.4478,761.79,383.9846,761.7271,383.0585,761.5977Z" />
                                        <path className="st1" d="M395.2413,762.4193a4.63,4.63,0,0,1-3.2993.5956,4.8647,4.8647,0,0,1-2.9775-1.1714,2.2918,2.2918,0,0,1-.6224-.9634,3.6618,3.6618,0,0,1-.1119-1.6462c.3909-3.1443.5863-4.7165.9772-7.8608a3.396,3.396,0,0,1,1.1962-2.4327,4.7049,4.7049,0,0,1,3.1854-.5558c2.0374.2264,3.0323,1.166,3.3731,2.0242a3.882,3.882,0,0,1,.1374,1.9035c-.3077,3.1292-.4615,4.6939-.7691,7.8231A2.9513,2.9513,0,0,1,395.2413,762.4193Zm-.81-10.2a2.4548,2.4548,0,0,0-.0394-1.1514,1.1214,1.1214,0,0,0-1.02-.7352,1.4719,1.4719,0,0,0-.8734.1641c-.6284.33-.62.955-.6864,1.5322-.328,2.8372-.4919,4.2558-.82,7.0929a2.1613,2.1613,0,0,0,.0995,1.3208,1.4444,1.4444,0,0,0,1.0543.5417,1.6366,1.6366,0,0,0,.879-.1451c.5355-.2836.5739-.8437.6291-1.36C393.9655,756.575,394.1209,755.123,394.4316,752.2191Z" />
                                        <path className="st1" d="M403.3658,763.777c-1.0987-.0826-1.6479-.1263-2.7461-.2183-.7016-5.7275-1.0266-8.5919-1.624-14.3208,1.1051.1029,1.6578.1518,2.7635.2446.24,3.77.3689,5.6548.6432,9.4248.8243-3.6828,1.2286-5.5254,2.0219-9.2128,1.0664.08,1.6.1179,2.6665.1887C405.65,755.4489,404.9048,758.2278,403.3658,763.777Z" />
                                        <path className="st1" d="M407.9575,764.0889c.3424-5.6385.5134-8.4577.856-14.0962,2.7441.1667,4.1169.2346,6.8633.3395l-.0817,2.139c-1.7144-.0656-2.5715-.1043-4.2851-.1937l-.173,3.3166c1.634.0852,2.4511.1225,4.0858.1862l-.0834,2.1379c-1.6459-.0642-2.4687-.1017-4.1138-.1875-.09,1.7338-.1357,2.6008-.2261,4.3346,1.8513.0966,2.7773.1381,4.6295.2077l-.0819,2.179C412.39,764.3409,410.9119,764.2683,407.9575,764.0889Z" />
                                        <path className="st1" d="M423.181,764.65c-.041-.0806-.0822-.1407-.1232-.2214a2.4606,2.4606,0,0,1-.2568-1.1442c.0184-1.2881.0277-1.9321.0461-3.22.0032-.22.0085-.4419-.0109-.68-.0633-.7743-.6661-.9695-1.3666-.9827-.5109-.01-.7664-.0149-1.2772-.0266-.0569,2.4875-.0853,3.7312-.1422,6.2187-1.0924-.025-1.6386-.04-2.7309-.0741.1773-5.6461.2658-8.4691.4432-14.1152,2.0882.0656,3.1326.0895,5.2214.1194a2.6928,2.6928,0,0,1,1.7241.48,2.7924,2.7924,0,0,1,.8087,2.5264c-.0036.6081-.0055.9122-.0091,1.52a3.4536,3.4536,0,0,1-.2951,1.5185,1.6662,1.6662,0,0,1-.3109.3776,2.6705,2.6705,0,0,1-1.2773.4873,3,3,0,0,1,.7985.2888,2.3319,2.3319,0,0,1,1.16,2.1893l-.0164,2.8806a3.0793,3.0793,0,0,0,.3689,1.662.8791.8791,0,0,0,.25.2207C424.9836,764.6712,424.3827,764.6661,423.181,764.65Zm-.2834-10.9466c.0078-.54-.0706-.7373-.29-.9439a1.2058,1.2058,0,0,0-.9065-.236c-.55-.01-.8249-.0158-1.3747-.0284-.0353,1.544-.053,2.316-.0883,3.86.5568.0127.8352.0185,1.3919.0287a1.2531,1.2531,0,0,0,1.0085-.3239c.203-.2358.2309-.4361.239-.9965Z" />
                                        <path className="st1" d="M432.9491,764.6292c-1.1244-4.2136-1.6693-6.3229-2.7243-10.5459.1286,4.2315.1945,6.3473.3293,10.5787-1.0257.01-1.5386.0132-2.5644.0152-.0109-5.6488-.0165-8.4733-.0272-14.1221,1.3418-.0026,2.0126-.0076,3.3543-.0249,1.0738,4.1312,1.628,6.1945,2.7709,10.3158-.1958-4.1414-.2919-6.2122-.4806-10.3538.9558-.0195,1.4335-.0311,2.3891-.058.159,5.6466.2386,8.47.3978,14.1165C435.0162,764.59,434.3272,764.6052,432.9491,764.6292Z" />
                                        <path className="st1" d="M444.267,764.2319c-.1916-.911-.2869-1.3666-.4764-2.2779-1,.0517-1.5006.0756-2.5011.1194-.1181.926-.1777,1.3889-.2981,2.3147-1.0919.0465-1.638.0674-2.73.1045.9587-5.6847,1.4125-8.5289,2.27-14.22,1.1966-.0515,1.7949-.08,2.9911-.1434,1.3568,5.5851,2.06,8.3741,3.5142,13.9442C445.9288,764.1417,445.3749,764.1734,444.267,764.2319Zm-2.0586-10.4509c-.2657,2.44-.4023,3.66-.6829,6.0993.7455-.0334,1.1182-.0513,1.8635-.0891C442.91,757.3879,442.6738,756.1859,442.2084,753.781Z" />
                                        <path className="st1" d="M453.83,763.5832c-1.3978-4.1312-2.0795-6.2005-3.4085-10.3457.4041,4.2142.6076,6.3212,1.0179,10.5348-1.0232.0771-1.535.1136-2.5586.1825-.3794-5.6361-.5691-8.4542-.9483-14.09,1.3395-.09,2.009-.1389,3.3477-.2437,1.3418,4.0522,2.03,6.0749,3.44,10.1129-.4661-4.12-.6974-6.18-1.1563-10.3.9535-.0819,1.43-.1247,2.3833-.2141.5271,5.6243.7909,8.4364,1.3181,14.0606C455.8918,763.4085,455.2047,763.4692,453.83,763.5832Z" />
                                        <path className="st1" d="M467.4961,760.5217c-.4594,2.0339-3.2085,2.3246-3.5232,2.3608a7.4425,7.4425,0,0,1-1.4174.0182,3.0652,3.0652,0,0,1-2.663-1.4065,3.6165,3.6165,0,0,1-.3584-1.45c-.3115-3.0567-.4672-4.585-.7787-7.6416-.0243-.239-.0257-.5051-.0346-.74a2.7646,2.7646,0,0,1,.7805-2.2731,4.3991,4.3991,0,0,1,2.8307-1.1372,4.8288,4.8288,0,0,1,2.7135.321,2.6063,2.6063,0,0,1,1.01.9216,3.2334,3.2334,0,0,1,.4433,1.556l.2735,2.1436c-1.0394.1326-1.5592.1967-2.5994.3206-.0842-.7071-.1263-1.0607-.21-1.7677-.1278-1.0734-.3767-1.6258-1.5423-1.4922-1.0047.1151-1.1379.7521-1.0367,1.6674.3325,3.0061.4987,4.5091.8312,7.5152.1011.9143.3843,1.57,1.5072,1.4407,1.2061-.1385,1.2314-.8086,1.1036-1.882l-.2175-1.8268c1.0525-.1253,1.5785-.19,2.63-.3244.1013.7936.1519,1.19.2531,1.9838A4.9879,4.9879,0,0,1,467.4961,760.5217Z" />
                                        <path className="st1" d="M470.14,761.81c-.7542-5.5982-1.1314-8.3973-1.8857-13.9956,2.7294-.3678,4.0926-.567,6.8153-.9964l.3335,2.1143c-1.7.2681-2.55.3961-4.2524.64.1887,1.315.283,1.9725.4716,3.2874,1.6232-.2328,2.4341-.3546,4.0549-.6089l.3316,2.1137c-1.6318.256-2.4484.3786-4.0827.6131.2466,1.7186.37,2.5779.6164,4.2965,1.8392-.2638,2.7582-.4025,4.5944-.6933l.3411,2.1537C474.5467,761.1986,473.0789,761.4137,470.14,761.81Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M122.1218,300.4415l-.7788-.3154a4.3468,4.3468,0,0,1,.2892.7259,3.29,3.29,0,0,1-.1738,2.0555,2.8669,2.8669,0,0,1-2.57,2.0737,3.2734,3.2734,0,0,1-1.23-.2558l-7.5071-2.8818a5.1806,5.1806,0,0,1-1.6578-.9281c-1.238-1.1279-.9079-2.9716-.3453-4.3813.4457-1.1167,1.2809-2.5311,2.5409-2.8067a2.5582,2.5582,0,0,1,1.5387.2049l1.9041.7874c-.4124.9973-.6165,1.497-1.02,2.498l-1.2615-.509c-.4451-.18-.8749-.3982-1.3408-.0441a1.5311,1.5311,0,0,0-.4895.6841,1.5885,1.5885,0,0,0-.1123.8791c.1106.4861.4779.7507,1.2965,1.0731,2.777,1.0939,4.1656,1.6408,6.9426,2.7345a1.94,1.94,0,0,0,.7257.1773c.32,0,.7362-.1239,1.0136-.82a1.3631,1.3631,0,0,0,.0495-1.093,1.7988,1.7988,0,0,0-1.0549-.7539l-2.04-.8233c-.2455.6084-.3675.913-.61,1.5228l-1.9707-.7832c.64-1.6114.9661-2.4146,1.6284-4.0162,2.8319,1.1711,4.2479,1.7567,7.08,2.9276C122.6266,299.1994,122.4574,299.613,122.1218,300.4415Z" />
                                        <path className="st1" d="M126.1232,289.45a4.4113,4.4113,0,0,1-.39,3.1961,4.6279,4.6279,0,0,1-1.9269,2.39,2.3084,2.3084,0,0,1-1.0866.3058,3.7976,3.7976,0,0,1-1.61-.3518c-2.9187-1.2336-4.3779-1.85-7.2966-3.0842a3.2769,3.2769,0,0,1-1.9974-1.8477,4.9129,4.9129,0,0,1,.4406-3.3387c.8622-1.9681,2.1-2.6593,3.0286-2.7222a3.7478,3.7478,0,0,1,1.8506.4713l7.16,3.246A3.0446,3.0446,0,0,1,126.1232,289.45Zm-9.9393-2.4185a2.3793,2.3793,0,0,0-1.11-.31,1.1551,1.1551,0,0,0-1.0227.7841,1.5466,1.5466,0,0,0-.113.9093c.1248.7158.7279.885,1.2607,1.1156l6.5533,2.8369a2.2272,2.2272,0,0,0,1.29.2937c.5051-.1127.7655-.7047.814-.8155a1.5616,1.5616,0,0,0,.1141-.8565c-.1149-.5816-.635-.79-1.1105-1.0009Z" />
                                        <path className="st1" d="M130.0237,282.5886c-.4568.9491-.6832,1.4246-1.1318,2.3777-5.5906-1.3874-8.3947-2.0567-14.0194-3.3456.4849-1.0549.73-1.5813,1.2239-2.6315,3.628,1.0659,5.4391,1.6069,9.055,2.7044-3.149-2.0782-4.72-3.1242-7.8531-5.23.4852-1.008.73-1.511,1.2238-2.5145C123.0967,277.4473,125.3972,279.1753,130.0237,282.5886Z" />
                                        <path className="st1" d="M131.96,278.6384c-5.0545-2.5226-7.5818-3.7841-12.636-6.307,1.2843-2.5732,1.9409-3.8525,3.2823-6.3956l1.8933.9987c-.826,1.5659-1.2336,2.3518-2.0379,3.9294l2.9584,1.5083c.7508-1.4726,1.131-2.2065,1.9009-3.6689l1.8937.997c-.7647,1.4525-1.1423,2.1813-1.888,3.644l3.8671,1.9715c.8162-1.601,1.23-2.3984,2.0694-3.987l1.9281,1.0186C133.87,274.8484,133.2236,276.1068,131.96,278.6384Z" />
                                        <path className="st1" d="M138.7713,265.7673c-.0895-.004-.1615.0018-.2511-.0022a2.5554,2.5554,0,0,1-1.123-.3275l-2.812-1.57c-.1918-.1071-.3853-.2175-.6038-.3137-.7111-.3129-1.1679.1226-1.5049.7325-.2458.4449-.3683.6676-.6123,1.1134l5.4573,2.9864c-.5014.9163-.75,1.3756-1.2438,2.2962-4.9787-2.6694-7.4679-4.0042-12.4462-6.6739,1.0328-1.9261,1.5575-2.8848,2.6229-4.7928a2.8539,2.8539,0,0,1,1.2859-1.3491c.9735-.4613,1.8153.0441,2.6147.4993l1.3213.7523a3.4557,3.4557,0,0,1,1.1787,1.003,1.6954,1.6954,0,0,1,.1777.4565,2.7478,2.7478,0,0,1-.1913,1.3567,2.9618,2.9618,0,0,1,.6375-.5609,2.3093,2.3093,0,0,1,2.4719.061l2.503,1.4263a3.2129,3.2129,0,0,0,1.6219.5073.8589.8589,0,0,0,.3107-.1C139.6157,264.2652,139.3327,264.765,138.7713,265.7673Zm-9.76-4.9648c-.4715-.2632-.6843-.2861-.973-.185-.3436.12-.4765.4038-.6484.7146-.2749.497-.4118.7459-.6845,1.2441l3.3867,1.8534c.2694-.4921.4046-.7379.6762-1.229a1.2789,1.2789,0,0,0,.1934-1.05c-.1109-.2927-.2736-.4126-.7627-.6855Z" />
                                        <path className="st1" d="M143.4572,257.6915c-4.1455-1.3189-6.2255-1.9625-10.3991-3.2175,3.6923,2.0712,5.5379,3.1082,9.2273,5.1845-.4986.8441-.7462,1.2672-1.238,2.1153-4.8869-2.8339-7.33-4.2509-12.2169-7.0849.7042-1.2143,1.06-1.8195,1.7771-3.026,4.1079,1.2011,6.1552,1.8176,10.2352,3.0822-3.6-2.0576-5.4-3.0851-9.003-5.1368.5176-.8558.7781-1.2827,1.3024-2.1345,4.81,2.9613,7.216,4.4419,12.0268,7.403C144.4782,255.999,144.1358,256.562,143.4572,257.6915Z" />
                                        <path className="st1" d="M149.1956,248.5119c-.846-.3868-1.2693-.58-2.1162-.9646-.5286.8153-.7913,1.2239-1.3135,2.0432.6933.6242,1.04.9358,1.735,1.5581-.56.8817-.8386,1.3238-1.3914,2.21-4.211-3.9232-6.301-5.9051-10.4491-9.909.6725-1.057,1.0113-1.5839,1.6941-2.6344,5.3411,2.1482,8,3.2442,13.296,5.4792C150.0646,247.1791,149.7736,247.6225,149.1956,248.5119Zm-9.6743-4.4535c1.828,1.64,2.7442,2.4571,4.5812,4.085.3951-.6184.5935-.927.9922-1.5432C142.8686,245.5775,141.7539,245.0692,139.5213,244.0584Z" />
                                        <path className="st1" d="M154.3035,240.9049c-4.051-1.5857-6.085-2.3632-10.1684-3.887,3.5494,2.3077,5.3233,3.4629,8.8693,5.7756-.5529.81-.8277,1.2164-1.374,2.0309l-11.7287-7.8666c.7823-1.1664,1.1767-1.7475,1.972-2.9055,4.0214,1.4656,6.0244,2.2139,10.0139,3.7411-3.4578-2.2877-5.1878-3.43-8.6495-5.7121.573-.8211.8611-1.2306,1.4406-2.0473l11.518,8.1718C155.4335,239.2814,155.0549,239.8212,154.3035,240.9049Z" />
                                        <path className="st1" d="M160.8469,229.1375c1.1445,1.7331-.4633,3.8128-.6433,4.0555a7.0846,7.0846,0,0,1-.9122,1,2.9934,2.9934,0,0,1-2.7712.9494,3.7539,3.7539,0,0,1-1.3185-.702l-6.2287-4.4951c-.1948-.1406-.3939-.3167-.5758-.4671a2.6942,2.6942,0,0,1-1.152-2.1267,4.601,4.601,0,0,1,1.1571-2.961A5.0844,5.0844,0,0,1,150.58,222.55a2.596,2.596,0,0,1,1.3866-.1082,3.1221,3.1221,0,0,1,1.4257.7685l1.7193,1.3084c-.6423.844-.9616,1.2674-1.5967,2.1166l-1.4257-1.0661c-.8653-.647-1.4444-.8451-2.1672.13-.623.8409-.246,1.3778.4957,1.9228L156.51,232.1c.7416.5449,1.4081.7835,2.0586-.094.6988-.9426.2323-1.4173-.633-2.0644l-1.4738-1.1021c.6276-.8393.9433-1.2577,1.5781-2.0919l1.5919,1.2114A5.1735,5.1735,0,0,1,160.8469,229.1375Z" />
                                        <path className="st1" d="M163.5673,228.25l-11.1782-8.631c1.7608-2.28,2.6543-3.4112,4.4674-5.6533.6658.5385.9987.8076,1.6645,1.346-1.1164,1.3806-1.67,2.0746-2.7662,3.47l2.611,2.0519c1.0235-1.3025,1.54-1.9505,2.5809-3.24.6661.5378.999.8067,1.6651,1.3444-1.0341,1.2808-1.5468,1.9244-2.5634,3.218l3.4129,2.6821c1.1129-1.4161,1.6746-2.12,2.8087-3.5207l1.6945,1.3723C166.1792,224.8944,165.3,226.0067,163.5673,228.25Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M684.5049,223.9544l.6609-.5189a4.3821,4.3821,0,0,1-.7733-.11,3.2914,3.2914,0,0,1-1.6974-1.1732,2.8673,2.8673,0,0,1-.5215-3.2605,3.28,3.28,0,0,1,.834-.94c2.493-2.0323,3.74-3.0483,6.2329-5.0805a5.179,5.179,0,0,1,1.6294-.9769c1.5942-.5133,3.0294.6894,3.9731,1.8788.7473.9419,1.5592,2.37,1.1718,3.6a2.5617,2.5617,0,0,1-.9428,1.2331l-1.63,1.2605c-.66-.8538-.9924-1.2794-1.66-2.1278l1.0689-.8414c.3772-.2968.7808-.5607.7049-1.1414a1.5315,1.5315,0,0,0-.35-.7649,1.5887,1.5887,0,0,0-.7066-.5341c-.4768-.1457-.889.0413-1.5758.5913l-5.8245,4.664a1.935,1.935,0,0,0-.5145.5416,1.06,1.06,0,0,0,.2071,1.2867,1.3639,1.3639,0,0,0,.9241.5868,1.7992,1.7992,0,0,0,1.1782-.5408l1.7288-1.3608c-.4059-.5156-.6094-.7728-1.018-1.2863l1.6593-1.32c1.08,1.3569,1.6148,2.0389,2.6753,3.41-2.4242,1.8748-3.6363,2.8122-6.06,4.6872C685.3317,225.01,685.057,224.6577,684.5049,223.9544Z" />
                                        <path className="st1" d="M692.0525,232.8917a4.4116,4.4116,0,0,1-2.5793-1.9281,4.6285,4.6285,0,0,1-1.1156-2.86,2.3061,2.3061,0,0,1,.275-1.0948,3.8042,3.8042,0,0,1,1.1058-1.2223c2.5215-1.919,3.7823-2.8783,6.3039-4.7972a3.2785,3.2785,0,0,1,2.5962-.8143,4.9151,4.9151,0,0,1,2.678,2.0427c1.2786,1.7262,1.2632,3.1442.856,3.9811a3.754,3.754,0,0,1-1.3293,1.371c-2.5506,1.8394-3.8259,2.759-6.3764,4.5985A3.0456,3.0456,0,0,1,692.0525,232.8917Zm7.04-7.4211a2.3834,2.3834,0,0,0,.82-.81,1.1547,1.1547,0,0,0-.1715-1.2768,1.5464,1.5464,0,0,0-.7329-.55c-.6831-.2476-1.13.1916-1.5947.5392-2.2879,1.71-3.4318,2.5651-5.72,4.2753a2.2243,2.2243,0,0,0-.8965.9726,1.38,1.38,0,0,0,.3031,1.1122,1.5628,1.5628,0,0,0,.6864.5247c.5614.1892,1.0015-.1579,1.4206-.4657C695.5619,228.0634,696.7388,227.1992,699.0928,225.4706Z" />
                                        <path className="st1" d="M696.0658,239.6869c-.5962-.868-.8962-1.3008-1.5-2.1638,3.9832-4.1609,5.9582-6.261,9.8729-10.5006.6744.9454,1.0093,1.4195,1.675,2.3707-2.7288,2.6176-4.0987,3.92-6.849,6.5114,3.3688-1.6989,5.0574-2.5416,8.4422-4.2133.6335.9223.9482,1.3848,1.5735,2.3125C703.9709,236.2334,701.3277,237.37,696.0658,239.6869Z" />
                                        <path className="st1" d="M698.53,243.331c4.7019-3.1312,7.053-4.6967,11.7549-7.8275,1.594,2.3937,2.3775,3.6,3.917,6.0278l-1.8078,1.146c-.948-1.4952-1.4272-2.24-2.396-3.7218l-2.78,1.8169c.9044,1.3836,1.352,2.0783,2.2381,3.4735-.7227.4589-1.084.6883-1.8066,1.1473-.88-1.3857-1.3245-2.0757-2.2229-3.45l-3.6333,2.375c.9833,1.5043,1.47,2.26,2.4306,3.7782l-1.8424,1.1665C700.8692,246.8728,700.0986,245.6862,698.53,243.331Z" />
                                        <path className="st1" d="M706.3113,255.6407c.048-.0757.0789-.141.1268-.2167a2.5469,2.5469,0,0,1,.8423-.812l2.76-1.659c.1883-.1133.3807-.2261.5727-.3675.6249-.46.4744-1.0742.1125-1.67-.2638-.4344-.3961-.6514-.6616-1.0848l-5.3046,3.25c-.5456-.8907-.82-1.3349-1.3737-2.2209,4.7915-2.9923,7.1873-4.4884,11.9788-7.48,1.1576,1.8538,1.7286,2.7857,2.8543,4.6588a2.8545,2.8545,0,0,1,.5311,1.7864c-.0838,1.074-.9406,1.5535-1.733,2.0208l-1.31.7724a3.4574,3.4574,0,0,1-1.4565.5235,1.6927,1.6927,0,0,1-.4842-.0724,2.7534,2.7534,0,0,1-1.082-.84,2.97,2.97,0,0,1,.17.832,2.3085,2.3085,0,0,1-1.2823,2.1139l-2.4819,1.4626a3.2114,3.2114,0,0,0-1.2466,1.1549.8585.8585,0,0,0-.0677.32C707.1948,257.12,706.9019,256.6261,706.3113,255.6407Zm9.16-6c.4629-.2783.5886-.4509.6443-.7522a1.2636,1.2636,0,0,0-.2976-.9179c-.2945-.4857-.4424-.7282-.7391-1.2125l-3.292,2.0168c.2931.4785.4391.7181.73,1.1978a1.28,1.28,0,0,0,.8145.69c.3093.0495.494-.0323.9739-.3209Z" />
                                        <path className="st1" d="M710.9881,263.7213c3.2055-2.9408,4.7982-4.4254,7.9624-7.4224-3.633,2.1736-5.45,3.2592-9.0865,5.4277-.4843-.8521-.7282-1.2772-1.2194-2.1254,4.8885-2.831,7.3328-4.2464,12.2214-7.0771.7034,1.2146,1.0518,1.824,1.7419,3.0463-3.0848,2.9668-4.6376,4.4365-7.7636,7.3475,3.575-2.1,5.3618-3.1514,8.9332-5.2572.4853.8747.7263,1.3131,1.2047,2.1917-4.9611,2.7015-7.4418,4.0522-12.4029,6.754C711.949,265.4489,711.6308,264.8719,710.9881,263.7213Z" />
                                        <path className="st1" d="M716.0994,273.2642c.7563-.5415,1.1342-.8127,1.8894-1.356-.4445-.864-.6685-1.2951-1.12-2.1555-.8862.2912-1.3291.4373-2.2144.7307-.4864-.9247-.7316-1.3859-1.2258-2.3062,5.4975-1.703,8.2563-2.5309,13.7927-4.1391.5827,1.1088.8713,1.6646,1.4431,2.7792-4.5194,3.5662-6.7925,5.3287-11.3645,8.8121C716.8239,274.6812,716.5838,274.2082,716.0994,273.2642Zm8.6741-6.1793c-2.3317.7707-3.496,1.1595-5.8219,1.9441.34.65.5092.9756.8454,1.6277C721.7912,269.2338,722.7865,268.5194,724.7735,267.0849Z" />
                                        <path className="st1" d="M720.16,281.4788c3.39-2.7265,5.0759-4.1046,8.4283-6.89-3.767,1.9321-5.6513,2.8969-9.4209,4.8235-.4281-.8825-.6439-1.3229-1.0789-2.2018l12.6568-6.2649c.6231,1.2587.9312,1.89,1.54,3.1556-3.2711,2.76-4.9163,4.1262-8.2249,6.8283,3.7041-1.8627,5.5555-2.7956,9.2566-4.6645.4274.9054.6395,1.359,1.06,2.2678l-12.8169,5.93C721.0067,283.2664,720.7266,282.67,720.16,281.4788Z" />
                                        <path className="st1" d="M727.1163,293.0069c-2.0727.1312-3.0777-2.2978-3.1988-2.5746a7.0882,7.0882,0,0,1-.4141-1.2888,2.9933,2.9933,0,0,1,.554-2.8764,3.756,3.756,0,0,1,1.2648-.7949l6.997-3.1693c.2188-.0991.4706-.1843.6915-.2673a2.6944,2.6944,0,0,1,2.418.0579,4.6019,4.6019,0,0,1,1.9938,2.4762,5.0839,5.0839,0,0,1,.5146,2.8044,2.5968,2.5968,0,0,1-.5955,1.2569,3.1228,3.1228,0,0,1-1.3756.8549l-1.99.8412c-.4129-.977-.6214-1.4646-1.0425-2.4379l1.6339-.7069c.9917-.429,1.4514-.8329.9644-1.9451-.42-.9586-1.0732-.8985-1.9148-.526l-6.9142,3.06c-.8416.3725-1.38.8321-.942,1.8329.47,1.0749,1.1142.9062,2.1058.4771l1.689-.7307c.4161.9618.6222,1.4437,1.03,2.4093l-1.8426.7789A5.179,5.179,0,0,1,727.1163,293.0069Z" />
                                        <path className="st1" d="M726.5336,295.8083l13.0464-5.4072c1.103,2.6615,1.64,3.9991,2.6836,6.6869l-1.9954.7749c-.6427-1.655-.97-2.48-1.6351-4.1252l-3.0785,1.2451c.6212,1.5358.9268,2.3058,1.5279,3.85l-1.9943.7762c-.5971-1.534-.9006-2.2988-1.5175-3.8241l-4.0239,1.6276c.6753,1.67,1.0069,2.5072,1.6581,4.1873-.8133.3152-1.22.4729-2.0332.788C728.1466,299.7431,727.6188,298.4268,726.5336,295.8083Z" />
                                    </g>
                                </>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <><g>
                                    <path className="st1" d="M120.76,303.54l-1.13-0.47l0.33,0.47l0.3,0.98l0.03,0.68l-0.26,0.76l-0.34,0.75l-0.87,0.84l-0.97,0.39
			l-1.63-0.02l-2.24-0.8l-1.87-0.72l-2.77-1.16l-1.32-0.67l-1.06-1.02l-0.48-1.22l-0.01-1.18l0.4-1.5l0.43-0.88l0.79-0.91l0.76-0.5
			l0.95-0.27l0.91,0.04l1.28,0.29l0.87,0.3l-1.04,2.62l-0.56-0.22l-1.32-0.37l-0.55,0.19l-0.55,0.62l-0.08,0.48l0.22,0.65l1.02,0.73
			l1.71,0.74l3.37,1.33l1.11,0.4l1.12,0.16l0.55-0.25l0.38-0.52l0.05-0.62l-0.24-0.59l-0.93-0.58l-1.01-0.44l-0.71-0.28l-0.54,1.36
			l-2.12-0.84l1.54-3.89l7.35,2.91L120.76,303.54z"/>
                                    <path className="st1" d="M121.82,289.92l1.36,0.74l0.85,0.76l0.49,0.73l0.22,0.79l-0.05,1.63l-0.36,0.93l-0.71,1.28l-1.12,0.93
			l-0.84,0.33l-1.2,0.05l-0.68-0.19l-4.26-1.63l-2.09-0.97l-1.03-0.53l-0.9-0.63l-0.63-0.82l-0.32-0.92l0.03-1.12l0.46-1.61
			l0.59-1.07l0.68-0.73l1.28-0.65l1.21-0.07l0.69,0.12l3.38,1.31L121.82,289.92z M115.84,290.56l-1.03-0.38l-0.79-0.08l-0.57,0.15
			l-0.44,0.46l-0.08,0.68l0.61,0.76l0.82,0.47l1.91,0.87l2.92,1.22l1.04,0.41l1.04,0.21l0.71-0.28l0.3-0.39l0.05-0.63l-0.26-0.57
			l-0.99-0.58l-1.58-0.73L115.84,290.56z"/>
                                    <path className="st1" d="M127.37,288.18l-13.99-3.32l1.22-2.72l7.22,1.98l1.49,0.45l1.44,0.56l-1.48-0.85l-1.01-0.67l-6.3-4.33
			l1.27-2.72l11.46,8.83L127.37,288.18z"/>
                                    <path className="st1" d="M130.7,281.2l-12.61-6.34l2.99-5.95l2.29,1.15l-1.7,3.38l2.72,1.36l1.52-3.02l2.23,1.12l-1.52,3.02l3.07,1.54
			l1.7-3.38l2.3,1.16L130.7,281.2z"/>
                                    <path className="st1" d="M137.64,267.84l-0.54-0.04l-1.77-0.68l-0.72-0.39l-1.21-0.58l-0.4-0.1l-0.61,0.03l-0.38,0.32l-0.26,0.36
			l-0.5,0.93l5.01,2.71l-1.39,2.57l-12.42-6.71l2.51-4.65l0.62-0.8l0.58-0.44l0.73-0.31l1.21-0.01l1.65,0.64l0.81,0.48l0.97,0.73
			l0.57,1.01l-0.01,0.9l0.88-0.53l1.07,0.03l0.94,0.44l0.23,0.12l2.01,0.91l1.37,0.37l0.5,0L137.64,267.84z M129.96,265.1l0.07-0.42
			l-0.22-0.57l-0.96-0.63l-0.79-0.38l-0.44-0.08l-0.62,0.1l-0.63,0.91l-0.44,0.81l3.22,1.74L129.96,265.1z"/>
                                    <path className="st1" d="M143.03,258.33l-7.75-1.63l-1.2-0.3l-1.93-0.52l1.26,0.54l1.36,0.72l6.66,3.87l-1.41,2.4l-12.15-7.25
			l1.74-2.87l7.14,1.55l1.79,0.4l1.69,0.5l-1.48-0.65l-0.89-0.46l-6.71-3.9l1.42-2.37l12.12,7.26L143.03,258.33z"/>
                                    <path className="st1" d="M148.97,248.7l-3.66-1.51l-1.23,1.95l2.95,2.64l-1.59,2.52l-10.35-10.14l1.9-3.01l13.56,5.05L148.97,248.7z
			 M140.01,244.92l-1.92-0.93l1.67,1.36l2.57,2.26l0.84-1.34L140.01,244.92z"/>
                                    <path className="st1" d="M154.67,240.01l-7.64-2.06l-1.18-0.36l-1.89-0.63l1.23,0.61l1.32,0.8l6.43,4.23l-1.54,2.31L139.68,237
			l1.9-2.77l7.04,1.94l1.77,0.5l1.65,0.59l-1.44-0.73l-0.86-0.51l-6.48-4.27l1.55-2.29l11.69,7.92L154.67,240.01z"/>
                                    <path className="st1" d="M150.66,230.28l-0.88-0.69l-0.71-0.59l-0.75-0.77l-0.44-0.72l-0.2-0.89l0.02-0.78l0.43-1.34l0.49-0.78
			l0.59-0.64l0.75-0.59l1.35-0.5l0.86,0.01l0.86,0.28l2.17,1.4l-1.63,2.25l-0.74-0.54l-0.87-0.53l-0.59-0.28l-0.46,0.09l-0.55,0.39
			l-0.19,0.63l0.16,0.43l0.25,0.38l1.01,0.83l5,3.63l0.91,0.49l0.53,0.06l0.34-0.12l0.33-0.33l0.19-0.46l-0.19-0.73l-1.63-1.4
			l-0.37-0.27l1.63-2.25l1.8,1.41l0.89,1.19l0.33,1.35l-0.23,1.14l-0.77,1.37l-0.81,0.97l-0.77,0.55l-0.97,0.28l-0.95-0.05
			l-1.39-0.54l-1.84-1.24L150.66,230.28z"/>
                                    <path className="st1" d="M163.9,227.22l-11.18-8.62l4.07-5.27l2.03,1.56l-2.31,2.99l2.41,1.86l2.06-2.68l1.98,1.53l-2.06,2.68
			l2.72,2.1l2.31-2.99l2.04,1.58L163.9,227.22z"/>
                                </g>
                                    <g>
                                        <path className="st1" d="M682.82,222.39l0.97-0.74l-0.58,0.05l-1-0.23l-0.6-0.31l-0.53-0.6l-0.48-0.67l-0.29-1.17l0.15-1.04l0.83-1.4
			l1.82-1.54l1.55-1.26l2.39-1.82l1.25-0.81l1.41-0.41l1.3,0.19l1.03,0.59l1.1,1.1l0.55,0.82l0.39,1.14l0.05,0.9l-0.24,0.96
			l-0.49,0.77l-0.9,0.96l-0.7,0.6l-1.75-2.21l0.47-0.37l0.98-0.96l0.11-0.57l-0.27-0.79l-0.38-0.31l-0.67-0.13l-1.14,0.52l-1.5,1.11
			l-2.84,2.25l-0.9,0.77l-0.7,0.89l-0.06,0.61l0.26,0.58l0.52,0.36l0.63,0.09l0.97-0.51l0.89-0.65l0.6-0.47l-0.91-1.14l1.79-1.42
			l2.6,3.28l-6.19,4.91L682.82,222.39z"/>
                                        <path className="st1" d="M694.09,230.12l-1.32,0.81l-1.08,0.36l-0.88,0.06l-0.8-0.2l-1.39-0.86l-0.63-0.78l-0.76-1.25l-0.25-1.44
			l0.13-0.89l0.56-1.06l0.5-0.5l3.54-2.88l1.88-1.32l0.98-0.63l1-0.47l1.02-0.14l0.96,0.18l0.96,0.58l1.17,1.2l0.63,1.05l0.29,0.96
			l-0.08,1.43l-0.54,1.08l-0.45,0.53l-2.82,2.27L694.09,230.12z M696.53,224.62l0.85-0.71l0.47-0.65l0.16-0.56l-0.18-0.61
			l-0.55-0.41l-0.96,0.14l-0.81,0.48l-1.71,1.22l-2.51,1.92l-0.87,0.7l-0.71,0.8l-0.11,0.76l0.19,0.46l0.52,0.36l0.62,0.06l1-0.57
			l1.42-1L696.53,224.62z"/>
                                        <path className="st1" d="M692.83,235.79l9.87-10.46l1.74,2.42l-5.33,5.26l-1.14,1.06l-1.21,0.96l1.47-0.86l1.09-0.54l6.9-3.29
			l1.72,2.46l-13.38,5.51L692.83,235.79z"/>
                                        <path className="st1" d="M697.2,242.17l11.79-7.75l3.66,5.56l-2.14,1.41l-2.08-3.16l-2.54,1.67l1.86,2.82l-2.09,1.37l-1.86-2.82
			l-2.88,1.89l2.08,3.16l-2.15,1.42L697.2,242.17z"/>
                                        <path className="st1" d="M705.3,254.86l0.31-0.45l1.47-1.19l0.7-0.43l1.11-0.75l0.29-0.29l0.28-0.54l-0.09-0.49l-0.18-0.41l-0.56-0.9
			l-4.85,2.99l-1.53-2.49l12.02-7.4l2.77,4.5l0.39,0.94l0.09,0.72l-0.1,0.79l-0.6,1.05l-1.38,1.11l-0.82,0.46l-1.12,0.47l-1.16-0.01
			l-0.78-0.46l0.02,1.02l-0.57,0.91l-0.85,0.6l-0.22,0.14l-1.79,1.29l-1.01,1l-0.25,0.44L705.3,254.86z M711.52,249.58l0.33,0.27
			l0.6,0.1l1.02-0.51l0.73-0.49l0.29-0.34l0.22-0.59l-0.47-1l-0.48-0.78l-3.12,1.92L711.52,249.58z"/>
                                        <path className="st1" d="M710.85,264.28l5.29-5.89l0.86-0.89l1.42-1.41l-1.1,0.82l-1.31,0.82l-6.68,3.83l-1.37-2.42l12.35-6.89
			l1.62,2.95l-4.91,5.41l-1.24,1.35l-1.27,1.21l1.3-0.95l0.85-0.54l6.74-3.86l1.34,2.41l-12.34,6.87L710.85,264.28z"/>
                                        <path className="st1" d="M716.21,274.24l3.13-2.42l-1.07-2.04l-3.76,1.23l-1.39-2.64l13.96-3.89l1.66,3.15l-11.15,9.22L716.21,274.24z
			 M723.97,268.37l1.76-1.2l-2.01,0.77l-3.24,1.1l0.74,1.4L723.97,268.37z"/>
                                        <path className="st1" d="M720.89,283.52l5.6-5.58l0.9-0.84l1.49-1.32l-1.14,0.76l-1.35,0.74l-6.88,3.45l-1.23-2.49l12.71-6.19
			l1.45,3.03l-5.2,5.12l-1.31,1.28l-1.34,1.14l1.35-0.88l0.87-0.49l6.94-3.48l1.2,2.48l-12.7,6.16L720.89,283.52z"/>
                                        <path className="st1" d="M731.32,284.92l1.04-0.42l0.87-0.32l1.04-0.27l0.84-0.02l0.87,0.27l0.66,0.41l0.95,1.05l0.43,0.82l0.26,0.83
			l0.14,0.95l-0.24,1.42l-0.44,0.74l-0.67,0.61l-2.3,1.18l-1.13-2.54l0.84-0.38l0.9-0.49l0.54-0.37l0.16-0.44l-0.06-0.67l-0.45-0.48
			l-0.45-0.08l-0.45,0.02l-1.22,0.46l-5.65,2.52l-0.88,0.54l-0.32,0.42l-0.06,0.36l0.12,0.45l0.3,0.39l0.73,0.2l2.03-0.71l0.42-0.19
			l1.13,2.54l-2.12,0.85l-1.48,0.18l-1.33-0.39l-0.87-0.77l-0.8-1.35l-0.44-1.18l-0.09-0.94l0.24-0.98l0.52-0.8l1.16-0.93l2-0.98
			L731.32,284.92z"/>
                                        <path className="st1" d="M727.35,297.91l13.06-5.37l2.54,6.16l-2.37,0.97l-1.44-3.5l-2.81,1.16l1.29,3.13l-2.31,0.95l-1.29-3.12
			l-3.18,1.31l1.44,3.5l-2.39,0.98L727.35,297.91z"/>
                                    </g>
                                    <g>
                                        <path className="st1" d="M382.16,762.09l0.18-1.21l-0.38,0.43l-0.88,0.53l-0.65,0.2l-0.8-0.06l-0.81-0.15l-1.03-0.64l-0.62-0.85
			l-0.38-1.58l0.23-2.37l0.24-1.99l0.45-2.97l0.33-1.45l0.73-1.28l1.07-0.77l1.15-0.29l1.55,0.02l0.96,0.21l1.08,0.55l0.67,0.61
			l0.49,0.85l0.19,0.89l0.03,1.32l-0.08,0.92l-2.8-0.37l0.08-0.6l0.04-1.37l-0.32-0.49l-0.74-0.38l-0.49,0.04l-0.57,0.37l-0.46,1.17
			l-0.3,1.84l-0.47,3.59l-0.11,1.18l0.11,1.12l0.38,0.47l0.59,0.24l0.62-0.1l0.51-0.38l0.34-1.04l0.18-1.09l0.1-0.75l-1.45-0.19
			l0.3-2.26l4.15,0.55l-1.03,7.83L382.16,762.09z"/>
                                        <path className="st1" d="M395.83,759.22l-0.29,1.52l-0.46,1.04l-0.55,0.69l-0.69,0.46l-1.57,0.45l-1-0.06l-1.44-0.28l-1.23-0.78
			l-0.57-0.7l-0.41-1.13l-0.03-0.71l0.25-4.56l0.29-2.28l0.19-1.15l0.33-1.05l0.59-0.85l0.78-0.59l1.08-0.32l1.67-0.06l1.2,0.24
			l0.9,0.43l1,1.02l0.43,1.13l0.09,0.69l-0.22,3.62L395.83,759.22z M393.4,753.72l0.05-1.1l-0.16-0.78l-0.31-0.49l-0.58-0.28
			l-0.68,0.13l-0.54,0.81l-0.19,0.93l-0.25,2.08l-0.27,3.15l-0.07,1.12l0.12,1.06l0.48,0.59l0.47,0.17l0.62-0.14l0.46-0.42
			l0.25-1.12l0.21-1.73L393.4,753.72z"/>
                                        <path className="st1" d="M399.55,763.82l-1.94-14.25l2.98,0.17l0.75,7.45l0.11,1.55l-0.01,1.54l0.26-1.69l0.26-1.19l1.78-7.44
			l2.99,0.21l-4.11,13.87L399.55,763.82z"/>
                                        <path className="st1" d="M407.59,764.35l0.59-14.11l6.66,0.28l-0.11,2.56l-3.78-0.16l-0.13,3.04l3.38,0.14l-0.11,2.5l-3.38-0.14
			l-0.14,3.44l3.78,0.16l-0.11,2.58L407.59,764.35z"/>
                                        <path className="st1" d="M422.92,764.79l-0.21-0.5l-0.21-1.88l0.01-0.82l-0.04-1.34l-0.09-0.4l-0.31-0.52l-0.46-0.19l-0.44-0.07
			l-1.06-0.02l-0.09,5.7l-2.92-0.05l0.23-14.12l5.28,0.08l1,0.18l0.66,0.31l0.61,0.51l0.56,1.07l0.19,1.76l-0.05,0.94l-0.2,1.2
			l-0.64,0.97l-0.81,0.41l0.87,0.53l0.46,0.97l0.04,1.04l0,0.26l0.12,2.2l0.3,1.38l0.23,0.44L422.92,764.79z M421.81,756.71
			l0.4-0.13l0.41-0.45l0.12-1.14l-0.03-0.88l-0.13-0.42l-0.37-0.51l-1.1-0.14l-0.92-0.02l-0.06,3.66L421.81,756.71z"/>
                                        <path className="st1" d="M434.33,764.64l-2.74-7.42l-0.39-1.17l-0.58-1.91l0.21,1.36l0.11,1.54l0.27,7.69l-2.78,0.08l-0.33-14.13
			l3.36-0.05l2.49,6.86l0.62,1.73l0.48,1.69l-0.24-1.59l-0.08-1l-0.27-7.75l2.76-0.06l0.31,14.11L434.33,764.64z"/>
                                        <path className="st1" d="M446.02,764.17l-0.89-3.86l-2.3,0.13l-0.44,3.93l-2.98,0.16l2.28-14.31l3.55-0.2l3.73,13.98L446.02,764.17z
			 M443.91,754.67l-0.36-2.1l-0.14,2.15l-0.35,3.41l1.58-0.09L443.91,754.67z"/>
                                        <path className="st1" d="M456.77,763.31l-3.23-7.23l-0.46-1.14l-0.71-1.87l0.3,1.34l0.21,1.53l0.78,7.66l-2.77,0.26l-1.26-14.09
			l3.35-0.28l2.94,6.69l0.73,1.68l0.59,1.66l-0.34-1.58l-0.15-0.99l-0.78-7.72l2.75-0.24l1.24,14.07L456.77,763.31z"/>
                                        <path className="st1" d="M461.58,753.98l-0.09-1.12l-0.05-0.92l0.05-1.07l0.22-0.81l0.51-0.75l0.58-0.51l1.28-0.6l0.91-0.17l0.87,0
			l0.94,0.15l1.29,0.65l0.58,0.63l0.38,0.82l0.45,2.54l-2.76,0.33l-0.11-0.91l-0.2-1l-0.2-0.62l-0.38-0.28l-0.66-0.14l-0.59,0.29
			l-0.21,0.41l-0.11,0.44l0.08,1.3l0.75,6.13l0.26,1l0.31,0.42l0.32,0.16l0.47,0.02l0.46-0.18l0.41-0.63l-0.08-2.15l-0.06-0.46
			l2.76-0.33l0.2,2.27l-0.27,1.46l-0.77,1.16l-0.99,0.6l-1.53,0.37l-1.26,0.07l-0.93-0.19l-0.87-0.52l-0.61-0.73l-0.55-1.38
			l-0.35-2.19L461.58,753.98z"/>
                                        <path className="st1" d="M473.16,761.44l-2.1-13.96l6.58-0.99l0.38,2.53l-3.74,0.56l0.45,3.01l3.34-0.5l0.37,2.47l-3.34,0.5l0.51,3.4
			l3.74-0.56l0.38,2.55L473.16,761.44z"/>
                                    </g>
                                </>}
                        </g>
                        <g id="Circle_-_2_2_" className={"model-option" + (this.state.changed ? this.setStyles(2) : '')}>
                            <path id="Circle_2_" onMouseEnter={() => this.hoverPie(2)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 2)}
                                className="st2" d="M425.2,37.53c-214.1,0-387.67,173.57-387.67,387.67S211.09,812.87,425.2,812.87
		S812.87,639.3,812.87,425.2S639.3,37.53,425.2,37.53z M425.2,775.38c-193.4,0-350.18-156.78-350.18-350.18
		c0-193.4,156.78-350.18,350.18-350.18S775.38,231.8,775.38,425.2C775.38,618.6,618.6,775.38,425.2,775.38z"/>
                            {SelectedLanguage === "en-GB" &&
                                <>
                                    <g>
                                        <path className="st1" d="M80.634,300.66c1.5579,1.3734.542,3.8024.4308,4.0827a7.0512,7.0512,0,0,1-.6251,1.2029,2.9874,2.9874,0,0,1-2.4381,1.6257,3.7613,3.7613,0,0,1-1.4537-.3457l-7.165-2.7695c-.2232-.0863-.4613-.2059-.6756-.3054a2.7142,2.7142,0,0,1-1.658-1.7529,4.5167,4.5167,0,0,1,.3447-3.12,4.9866,4.9866,0,0,1,1.6115-2.3062,2.5915,2.5915,0,0,1,1.306-.4551,3.16,3.16,0,0,1,1.5763.3722l2.0022.8135c-.3956.9736-.592,1.4611-.9821,2.4371l-1.6541-.6611c-1.0029-.4009-1.612-.4438-2.054.6713-.3811.9618.1215,1.3814.9776,1.718l7.038,2.7674c.8561.3367,1.5613.3971,1.9637-.6178.4321-1.09-.1421-1.4279-1.145-1.8288l-1.7085-.6829c.3871-.9683.582-1.4518.9744-2.4178.7412.3012,1.1118.4518,1.853.7528A5.1932,5.1932,0,0,1,80.634,300.66Z" />
                                        <path className="st1" d="M85.1211,292.459a4.3929,4.3929,0,0,1-.341,3.2018,4.6033,4.6033,0,0,1-1.8976,2.4134,2.2988,2.2988,0,0,1-1.0828.3164,3.8031,3.8031,0,0,1-1.6135-.3368L72.86,295.0392a3.3094,3.3094,0,0,1-2.02-1.817,4.82,4.82,0,0,1,.3823-3.3015c.8228-1.9509,2.04-2.6484,2.9655-2.7223a3.79,3.79,0,0,1,1.8568.4418l7.2181,3.1171A3.04,3.04,0,0,1,85.1211,292.459Zm-9.9727-2.2679a2.4083,2.4083,0,0,0-1.1142-.2945,1.14,1.14,0,0,0-1.0059.7858,1.517,1.517,0,0,0-.0961.9009c.1365.707.741.87,1.2761,1.0936l6.5886,2.7558a2.2309,2.2309,0,0,0,1.2936.2784c.5034-.1183.7551-.7131.802-.8244a1.56,1.56,0,0,0,.1013-.8565c-.1237-.5791-.6472-.78-1.1253-.9831Z" />
                                        <path className="st1" d="M89.3057,284.5641c-.09.0036-.16.0157-.25.0193a2.5566,2.5566,0,0,1-1.1472-.2314l-2.9348-1.3267c-.2-.0905-.4027-.1844-.6277-.2614-.7352-.2519-1.1527.217-1.4363.8493-.2068.4612-.31.692-.5155,1.1538l5.684,2.53c-.4253.9553-.6366,1.4336-1.0564,2.3915-5.1741-2.268-7.7612-3.4017-12.9352-5.67.8658-1.9751,1.3042-2.96,2.1923-4.9244a2.7921,2.7921,0,0,1,1.156-1.4321,2.7309,2.7309,0,0,1,2.644.2795l1.382.6341a3.4787,3.4787,0,0,1,1.2627.8942,1.6986,1.6986,0,0,1,.2177.4373,2.7157,2.7157,0,0,1-.07,1.36,2.9324,2.9324,0,0,1,.5849-.6088,2.3178,2.3178,0,0,1,2.4658-.148l2.6173,1.2016a3.2089,3.2089,0,0,0,1.6609.3639.86.86,0,0,0,.3-.1269C90.0192,282.9938,89.78,283.5167,89.3057,284.5641ZM79.16,280.4424c-.4921-.2224-.7057-.2282-.9842-.1055-.3307.1456-.4378.4365-.5815.7569-.23.5122-.3445.7685-.5729,1.2814l3.5267,1.57c.2265-.5088.34-.763.5681-1.271a1.2607,1.2607,0,0,0,.102-1.0547c-.1361-.2815-.3078-.3861-.8186-.6169Z" />
                                        <path className="st1" d="M88.3726,271.05c.2789.6109.1769,1.239-.3852,2.4141-.3865.808-.5788,1.2126-.9614,2.0225l5.3174,2.5119c-.45.9522-.6733,1.429-1.1177,2.3839-5.1219-2.3834-7.6829-3.5748-12.8046-5.9582.9162-1.9692,1.38-2.951,2.3188-4.9089a3.9572,3.9572,0,0,1,.3677-.6278,2.0246,2.0246,0,0,1,1.5225-.85,4.9867,4.9867,0,0,1,2.0424.6475l1.6733.8131C87.5333,270.0756,88.0815,270.4131,88.3726,271.05Zm-4.5381.154a1.6859,1.6859,0,0,0-1.1169-.3062c-.4725.1175-.6534.6708-.6978.764-.2269.4768-.34.7154-.5656,1.1929l3.7448,1.7689c.22-.4658.3305-.6986.5518-1.1639.2374-.4988.3125-.802.1875-1.0437a1.8219,1.8219,0,0,0-.8047-.59Z" />
                                        <path className="st1" d="M97.9746,265.0875a4.3827,4.3827,0,0,1-.55,3.1681,4.59,4.59,0,0,1-2.0514,2.2778,2.298,2.298,0,0,1-1.1008.2429,3.8065,3.8065,0,0,1-1.5873-.4435c-2.844-1.3976-4.2659-2.0963-7.11-3.4937a3.3139,3.3139,0,0,1-1.8959-1.9456,4.8075,4.8075,0,0,1,.5981-3.2643c.9489-1.8891,2.2093-2.5029,3.137-2.5148a3.7956,3.7956,0,0,1,1.8232.5642l6.9955,3.5888A3.0422,3.0422,0,0,1,97.9746,265.0875Zm-9.7991-2.9265a2.4123,2.4123,0,0,0-1.092-.368,1.1387,1.1387,0,0,0-1.0551.716,1.512,1.512,0,0,0-.155.8912c.09.7136.6821.9162,1.2012,1.1751l6.3912,3.1866a2.2343,2.2343,0,0,0,1.2721.364c.51-.0843.8-.66.8542-.768a1.5547,1.5547,0,0,0,.1573-.8468c-.0854-.5853-.5944-.82-1.058-1.0549Z" />
                                        <path className="st1" d="M102.6679,257.4975c-.09-.0024-.1611.0049-.2506.0026a2.56,2.56,0,0,1-1.1293-.3072l-2.84-1.5183c-.1938-.1036-.39-.2107-.609-.3025-.7168-.3-1.1642.1394-1.4887.7509-.2366.4459-.3547.6691-.59,1.1156l5.5037,2.9016c-.487.9238-.7292,1.3865-1.211,2.3132-5.0123-2.606-7.5185-3.9087-12.5308-6.5146.9934-1.9109,1.4955-2.8634,2.5107-4.7625a2.7865,2.7865,0,0,1,1.2474-1.3507,2.7327,2.7327,0,0,1,2.6193.4549l1.3369.7243a3.4783,3.4783,0,0,1,1.2008.9757,1.6956,1.6956,0,0,1,.1884.45,2.71,2.71,0,0,1-.16,1.3513,2.932,2.932,0,0,1,.6236-.5679,2.3187,2.3187,0,0,1,2.47.0169l2.5319,1.3724a3.2131,3.2131,0,0,0,1.6329.4735.859.859,0,0,0,.308-.1065C103.483,255.98,103.21,256.485,102.6679,257.4975Zm-9.85-4.7855c-.4763-.2545-.689-.2745-.9749-.1709-.3394.1231-.4654.406-.63.7157-.263.4954-.3942.7432-.6557,1.2394l3.4149,1.8c.2593-.492.3894-.7378.65-1.229a1.2584,1.2584,0,0,0,.1712-1.0446c-.1173-.29-.2817-.4054-.7761-.67Z" />
                                        <path className="st1" d="M107.2581,249.1392c-.8691-.3323-1.3038-.4982-2.1733-.8293-.4746.8445-.7107,1.2673-1.1807,2.1144.73.5805,1.0957.8706,1.8268,1.45-.5059.9135-.7575,1.3709-1.2582,2.2872-4.43-3.6743-6.6352-5.5257-11.0263-9.2565.5982-1.0793.8991-1.618,1.5044-2.6934,5.4592,1.8183,8.1819,2.743,13.6141,4.6217C108.04,247.7541,107.7781,248.2153,107.2581,249.1392Zm-9.93-3.8449c1.9284,1.5195,2.894,2.2772,4.828,3.7884.3547-.6381.5327-.9567.89-1.5933C100.7605,246.6072,99.6169,246.1682,97.3278,245.2943Z" />
                                        <path className="st1" d="M101.18,235.4781c4.1658,2.4509,6.2485,3.6765,10.4144,6.1271-.5335.9069-.799,1.3611-1.3273,2.2711-4.18-2.4266-6.27-3.64-10.45-6.0668-.5382.927-.8059,1.3912-1.3387,2.3212l-1.77-1.0141c1.6138-2.8167,2.4328-4.2181,4.095-7.0065l1.7524,1.0446C102.0026,234.0825,101.7274,234.5472,101.18,235.4781Z" />
                                        <path className="st1" d="M113.7684,237.9486c-4.8423-2.91-7.263-4.3652-12.1053-7.275,1.4589-2.4275,2.1975-3.6358,3.6929-6.0412l1.818,1.13c-.9227,1.484-1.3805,2.2282-2.2891,3.7207l2.836,1.7267c.8513-1.3983,1.28-2.0956,2.1437-3.4864l1.8185,1.1293c-.86,1.3844-1.2864,2.0785-2.1338,3.47,1.4831.9031,2.2246,1.3545,3.7077,2.2574.9316-1.53,1.4012-2.2931,2.3478-3.8141l1.8515,1.152C115.9624,234.3192,115.2247,235.5253,113.7684,237.9486Z" />
                                        <path className="st1" d="M119.6789,216.3171c.1936.6445.0063,1.2518-.71,2.3391-.4926.7474-.738,1.1216-1.227,1.8713,1.97,1.2852,2.9553,1.9279,4.9257,3.2127-.5751.8818-.8614,1.3235-1.4313,2.2085-4.75-3.0584-7.124-4.5887-11.8736-7.6472,1.1751-1.8246,1.7679-2.7335,2.9642-4.5446a3.9422,3.9422,0,0,1,.45-.5717,2.023,2.023,0,0,1,1.6236-.6341,4.9818,4.9818,0,0,1,1.9355.9194l1.5468,1.0335C118.98,215.2375,119.4773,215.646,119.6789,216.3171Zm-4.5166-.4658a1.6866,1.6866,0,0,0-1.0647-.4557c-.4836.0517-.7385.5752-.7948.6611-.29.4413-.4341.6621-.7225,1.1042,1.3876.905,2.0814,1.3576,3.4689,2.2627.2812-.4311.4221-.6465.7045-1.0769.3027-.4612.4182-.7524.3275-1.0078-.1006-.2829-.4827-.54-.7167-.6942Z" />
                                        <path className="st1" d="M130.0043,211.7165a4.3842,4.3842,0,0,1-.9771,3.0641,4.5909,4.5909,0,0,1-2.3428,1.9778,2.2894,2.2894,0,0,1-1.1237.0907,3.8,3.8,0,0,1-1.5127-.6548c-2.6271-1.7719-3.94-2.658-6.5675-4.43a3.3132,3.3132,0,0,1-1.6127-2.1864,4.8054,4.8054,0,0,1,1.0371-3.1524c1.198-1.7431,2.53-2.18,3.4512-2.0653a3.7971,3.7971,0,0,1,1.73.8068l6.4412,4.5084A3.0434,3.0434,0,0,1,130.0043,211.7165Zm-9.3094-4.2335a2.3982,2.3982,0,0,0-1.0321-.5128,1.1382,1.1382,0,0,0-1.1426.5654,1.5146,1.5146,0,0,0-.2751.862c-.0083.7193.5509,1.0007,1.03,1.3278,2.359,1.6111,3.5384,2.4168,5.8974,4.0278a2.2322,2.2322,0,0,0,1.211.5334,1.3716,1.3716,0,0,0,.9507-.6443,1.5572,1.5572,0,0,0,.2713-.8177c-.0045-.5919-.477-.8938-.9044-1.1892C124.2985,209.9745,123.0974,209.1439,120.6949,207.483Z" />
                                        <path className="st1" d="M132.4228,209.3886c-4.6109-3.264-6.9158-4.8968-11.5266-8.1608.6227-.88.9353-1.3184,1.5632-2.1944,3.8959,2.7917,5.8435,4.188,9.74,6.98.8989-1.2544,1.3511-1.88,2.2608-3.1268.6917.5047,1.0376.7569,1.7294,1.2615C134.6675,206.2339,133.9142,207.282,132.4228,209.3886Z" />
                                        <path className="st1" d="M137.1739,202.8033c-4.552-3.3458-6.8274-5.0193-11.3792-8.365.6337-.8621.9519-1.2924,1.5906-2.1509,4.5327,3.3715,6.7986,5.0579,11.3314,8.43C138.0971,201.55,137.7886,201.9671,137.1739,202.8033Z" />
                                        <path className="st1" d="M143.5179,191.735c1.1247,1.7457-.5139,3.8041-.6978,4.0441a7.0575,7.0575,0,0,1-.9284.9857,2.9839,2.9839,0,0,1-2.7875.8971,3.7593,3.7593,0,0,1-1.3045-.7287l-6.1381-4.6186c-.1912-.1438-.3873-.3238-.5667-.478a2.7122,2.7122,0,0,1-1.118-2.1373,4.509,4.509,0,0,1,1.181-2.9063,4.986,4.986,0,0,1,2.1785-1.7784,2.5973,2.5973,0,0,1,1.38-.0813,3.1614,3.1614,0,0,1,1.4151.788l1.7044,1.3286c-.646.8288-.9677,1.244-1.6089,2.0763l-1.4111-1.0871c-.8556-.6592-1.43-.867-2.1584.0848-.6282.82-.2592,1.3612.4726,1.9185l6.0166,4.5818c.7319.5573,1.394.8076,2.0571-.0581.7123-.93.2519-1.4116-.6037-2.0707l-1.4574-1.1229c.636-.8256.9553-1.2376,1.5962-2.06l1.5774,1.23A5.1785,5.1785,0,0,1,143.5179,191.735Z" />
                                        <path className="st1" d="M145.807,184.7137l3.2593,2.6183c-.6708.835-1.005,1.2534-1.6707,2.0922l-3.4162-2.7114c-3.7712-1.5295-5.6629-2.2837-9.4585-3.77.7015-.8953,1.0537-1.3419,1.7609-2.2332,2.603,1.302,3.9021,1.9569,6.4953,3.2744-1.8738-2.2184-2.8075-3.3307-4.6691-5.5614.7129-.887,1.0707-1.3295,1.7891-2.2125C142.2426,179.6283,143.4246,181.3292,145.807,184.7137Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M704.6283,187.9875c-1.9665.6684-3.5685-1.42-3.7565-1.6561a7.08,7.08,0,0,1-.7332-1.1409,2.989,2.989,0,0,1-.1982-2.9237,3.7715,3.7715,0,0,1,1.023-1.089l5.9654-4.84c.1859-.1507.408-.2981.6009-.4342a2.7167,2.7167,0,0,1,2.3452-.567,4.5156,4.5156,0,0,1,2.5357,1.85,4.9879,4.9879,0,0,1,1.2,2.5449,2.5892,2.5892,0,0,1-.2543,1.36,3.1576,3.1576,0,0,1-1.1067,1.1826c-.6806.533-1.0208.8-1.7013,1.3327-.6481-.8274-.9733-1.24-1.6262-2.0639l1.396-1.1064c.8465-.6708,1.1864-1.1784.4385-2.1163-.6446-.8085-1.2588-.5815-1.9766-.006-2.36,1.892-3.54,2.8381-5.9,4.73-.7178.5754-1.12,1.1576-.44,2.0109.7306.9167,1.31.5871,2.1563-.0837l1.442-1.1428c.6477.8173.97,1.2268,1.6133,2.0475l-1.5745,1.2334A5.2064,5.2064,0,0,1,704.6283,187.9875Z" />
                                        <path className="st1" d="M709.5129,195.9583a4.393,4.393,0,0,1-2.6088-1.8882,4.6037,4.6037,0,0,1-1.15-2.8463,2.2981,2.2981,0,0,1,.2638-1.0968,3.8094,3.8094,0,0,1,1.0944-1.2325l6.2581-4.8574a3.3124,3.3124,0,0,1,2.5809-.8494,4.824,4.824,0,0,1,2.6748,1.9737c1.2832,1.6836,1.2832,3.0871.8874,3.9266a3.7965,3.7965,0,0,1-1.3068,1.3909l-6.2933,4.7128A3.0407,3.0407,0,0,1,709.5129,195.9583Zm6.9261-7.525a2.409,2.409,0,0,0,.8092-.8206,1.14,1.14,0,0,0-.1814-1.2632,1.5157,1.5157,0,0,0-.7337-.5313c-.6813-.2331-1.1231.21-1.5834.5636-2.2668,1.7384-3.4,2.6076-5.6669,4.3462a2.2287,2.2287,0,0,0-.8851.9835,1.3737,1.3737,0,0,0,.3168,1.1062,1.5609,1.5609,0,0,0,.6928.5137c.5636.18.9984-.1737,1.4125-.4875C712.9475,191.08,714.1113,190.1974,716.439,188.4333Z" />
                                        <path className="st1" d="M714.282,203.5144c.0415-.0795.0662-.147.1076-.2264a2.548,2.548,0,0,1,.7709-.8808l2.61-1.8866c.1781-.1288.3605-.2578.5393-.4143.5839-.5113.3848-1.108-.0232-1.6689-.2973-.4087-.4462-.6129-.7447-1.0209-2.0085,1.4694-3.0128,2.2042-5.0214,3.6735-.6174-.8439-.9274-1.265-1.55-2.1055,4.54-3.3616,6.81-5.0426,11.3506-8.4041,1.2832,1.7332,1.92,2.6034,3.1826,4.3506a2.7921,2.7921,0,0,1,.6678,1.7151c.0026,1.0678-.8083,1.6207-1.5569,2.1554l-1.2373.8838a3.4779,3.4779,0,0,1-1.404.65,1.6877,1.6877,0,0,1-.4873-.0281,2.7191,2.7191,0,0,1-1.1451-.7373,2.9475,2.9475,0,0,1,.2374.81,2.3174,2.3174,0,0,1-1.0981,2.2126l-2.3438,1.6733a3.2116,3.2116,0,0,0-1.1416,1.26.865.865,0,0,0-.0389.3241C715.29,204.9142,714.9549,204.447,714.282,203.5144Zm8.62-6.7536c.4377-.3164.5489-.4982.5809-.8015a1.24,1.24,0,0,0-.3675-.8809c-.33-.4541-.4956-.6809-.8271-1.1341l-3.1156,2.2792c.3287.45.4928.6744.82,1.1248a1.2615,1.2615,0,0,0,.8643.613c.3121.022.4881-.0751.9424-.4036Z" />
                                        <path className="st1" d="M726.4709,209.424c-.6687-.0618-1.1632-.4629-1.9033-1.535-.5088-.7369-.7641-1.1048-1.2765-1.8393l-4.8232,3.3646c-.6027-.8639-.9052-1.2949-1.5129-2.1554,4.6145-3.2588,6.9215-4.8885,11.5361-8.1473,1.2529,1.7741,1.8741,2.6646,3.1061,4.4526a3.9464,3.9464,0,0,1,.3618.6312,2.0258,2.0258,0,0,1-.0191,1.7441,4.9959,4.9959,0,0,1-1.5777,1.45l-1.5374,1.0474C727.7338,209.18,727.1686,209.4883,726.4709,209.424Zm2.1225-4.0144c.3288-.2267.708-.4192.821-.8168a1.1933,1.1933,0,0,0-.3156-.9849c-.3009-.4339-.4517-.6507-.7538-1.0839l-3.3968,2.37c.2947.4224.4418.6339.7352,1.0571.3151.4543.54.67.8127.6821a1.8245,1.8245,0,0,0,.9115-.4054Z" />
                                        <path className="st1" d="M726.87,220.72a4.3833,4.3833,0,0,1-2.4753-2.0531,4.5909,4.5909,0,0,1-.9564-2.9124,2.2972,2.2972,0,0,1,.3367-1.0759,3.8153,3.8153,0,0,1,1.174-1.1567l6.5663-4.4316a3.3158,3.3158,0,0,1,2.6307-.6776,4.81,4.81,0,0,1,2.5351,2.1424c1.167,1.7622,1.0732,3.1613.6222,3.9721a3.8014,3.8014,0,0,1-1.3964,1.3009c-2.6367,1.7141-3.9551,2.5712-6.5919,4.2852A3.0431,3.0431,0,0,1,726.87,220.72Zm7.4113-7.0468a2.4149,2.4149,0,0,0,.862-.765,1.138,1.138,0,0,0-.0963-1.2709,1.5114,1.5114,0,0,0-.6962-.5777c-.6638-.277-1.1341.1363-1.6168.458l-5.9426,3.9608a2.233,2.233,0,0,0-.9486.9223,1.37,1.37,0,0,0,.2419,1.1233,1.5552,1.5552,0,0,0,.6566.5575c.55.2165,1.0073-.1077,1.4412-.3933Z" />
                                        <path className="st1" d="M731.1221,228.5656c.0466-.0765.0758-.1422.1223-.2187a2.5527,2.5527,0,0,1,.8278-.8274l2.73-1.7095c.1862-.1166.3768-.2333.5655-.3776.6167-.4715.4578-1.0794.0884-1.6654-.2692-.427-.4041-.64-.6746-1.0667l-5.2539,3.3325c-.5594-.8819-.84-1.322-1.4049-2.2008,4.7532-3.0531,7.13-4.58,11.8827-7.633,1.1639,1.8119,1.7408,2.7213,2.8837,4.5463a2.7874,2.7874,0,0,1,.5518,1.7538c-.0687,1.0649-.9144,1.563-1.6968,2.0469l-1.2931.8a3.4827,3.4827,0,0,1-1.4439.5563,1.6887,1.6887,0,0,1-.4842-.06,2.7145,2.7145,0,0,1-1.0931-.81,2.9407,2.9407,0,0,1,.1827.8234,2.3181,2.3181,0,0,1-1.2428,2.1339l-2.45,1.5143a3.2149,3.2149,0,0,0-1.2227,1.1814.8624.8624,0,0,0-.06.3206C732.0338,230.0275,731.731,229.54,731.1221,228.5656Zm9.0492-6.167c.4576-.2867.5808-.4607.6329-.7608a1.2369,1.2369,0,0,0-.3078-.9024c-.299-.4745-.4488-.7115-.7492-1.1851l-3.26,2.0677c.2978.47.4464.7048.7429,1.1753a1.2591,1.2591,0,0,0,.8213.6679c.31.0425.4918-.0429.9668-.34Z" />
                                        <path className="st1" d="M736.0915,236.7036c.7205-.5886,1.0806-.8832,1.8-1.4729-.4967-.8315-.7462-1.2467-1.2473-2.0757-.8669.3449-1.3.5179-2.1664.8642-.541-.8932-.8128-1.339-1.3589-2.229,5.3906-2.0166,8.0934-3.0094,13.5137-4.9643.6389,1.0555.9566,1.5843,1.5886,2.644-4.2921,3.8325-6.4481,5.7351-10.779,9.5141C736.9047,238.07,736.6346,237.6142,736.0915,236.7036ZM744.3649,230c-2.2772.9178-3.4146,1.3789-5.6872,2.3057.377.6249.565.9376.94,1.5639C741.519,232.325,742.4685,231.5511,744.3649,230Z" />
                                        <path className="st1" d="M750.9665,238.2222c-4.1976,2.3958-6.2966,3.5934-10.4942,5.9894-.5214-.9136-.7835-1.37-1.31-2.28,4.1837-2.42,6.2757-3.63,10.4594-6.05-.5367-.9278-.8063-1.3909-1.3483-2.3157l1.76-1.0316c1.6415,2.8007,2.45,4.2081,4.0429,7.0367l-1.7775,1.001C751.7684,239.63,751.502,239.1605,750.9665,238.2222Z" />
                                        <path className="st1" d="M742.5638,247.9162c4.9322-2.7546,7.3987-4.1312,12.3309-6.8857,1.3809,2.4729,2.0621,3.7146,3.4057,6.2081l-1.8846,1.0155c-.8288-1.5384-1.2469-2.3056-2.09-3.8361l-2.9082,1.6021c.79,1.4339,1.1818,2.1526,1.9591,3.5934l-1.884,1.0163c-.7737-1.4342-1.1637-2.15-1.95-3.5768-1.5208.8377-2.2812,1.2567-3.802,2.0945.8645,1.5692,1.2929,2.3559,2.1419,3.9334l-1.92,1.0337C744.6221,251.6246,743.9424,250.3848,742.5638,247.9162Z" />
                                        <path className="st1" d="M758.3935,263.8c-.6555-.1525-1.0892-.6169-1.6763-1.7793-.4035-.7991-.6062-1.1981-1.0135-1.9951-2.0948,1.07-3.1421,1.6056-5.2366,2.6763-.4791-.9374-.72-1.4053-1.2044-2.34,5.0151-2.6,7.5233-3.8994,12.5386-6.5.9987,1.9267,1.4926,2.893,2.4692,4.8314a3.9441,3.9441,0,0,1,.2724.6744,2.023,2.023,0,0,1-.2571,1.724,4.9808,4.9808,0,0,1-1.76,1.2222l-1.6658.8282C759.6777,263.7305,759.076,263.9589,758.3935,263.8Zm2.65-3.6871c.3567-.18.7586-.3188.9248-.6972a1.1922,1.1922,0,0,0-.1784-1.0183c-.2389-.4707-.3587-.7059-.5988-1.1759l-3.688,1.8847c.2342.4583.351.6877.5841,1.1467.25.4919.4449.7369.7115.7851a1.8261,1.8261,0,0,0,.9587-.2765Z" />
                                        <path className="st1" d="M757.2512,275.0463a4.3841,4.3841,0,0,1-2.1726-2.3714,4.59,4.59,0,0,1-.5511-3.016,2.2874,2.2874,0,0,1,.48-1.02,3.8,3.8,0,0,1,1.32-.987l7.1089-3.4955a3.3135,3.3135,0,0,1,2.699-.3121,4.8055,4.8055,0,0,1,2.2194,2.4672c.9167,1.9061.6331,3.2792.076,4.0213a3.7977,3.7977,0,0,1-1.56,1.1l-7.1143,3.347A3.0441,3.0441,0,0,1,757.2512,275.0463Zm8.3018-5.9723a2.3982,2.3982,0,0,0,.9582-.64,1.1384,1.1384,0,0,0,.0775-1.2725,1.5143,1.5143,0,0,0-.6111-.6673c-.62-.3649-1.1421-.02-1.6641.2334l-6.4269,3.1141a2.2319,2.2319,0,0,0-1.0649.7855,1.3722,1.3722,0,0,0,.0863,1.1451,1.5572,1.5572,0,0,0,.5745.642c.5159.29,1.0127.0306,1.4816-.1933Z" />
                                        <path className="st1" d="M758.0685,278.302c5.1245-2.3777,7.6872-3.5656,12.8116-5.9432.4535.9776.6789,1.4671,1.1268,2.4474-4.3593,1.9921-6.5392,2.9877-10.8986,4.98.6415,1.4037.9592,2.107,1.5888,3.5163l-1.9544.8733C759.69,281.8182,759.1547,280.6434,758.0685,278.302Z" />
                                        <path className="st1" d="M761.42,285.6985c5.1661-2.2858,7.75-3.428,12.9155-5.7138.4329.9785.648,1.4684,1.0754,2.45-5.179,2.2563-7.7688,3.3838-12.9479,5.64C762.0483,287.1228,761.84,286.6476,761.42,285.6985Z" />
                                        <path className="st1" d="M767.8688,296.7061c-2.0739.1078-3.0451-2.3374-3.162-2.6163a7.0523,7.0523,0,0,1-.3934-1.2956,2.9833,2.9833,0,0,1,.6076-2.8645,3.7562,3.7562,0,0,1,1.2808-.77l7.0591-3.0293c.22-.0944.4735-.175.6965-.254a2.7119,2.7119,0,0,1,2.41.0927,4.5088,4.5088,0,0,1,1.9344,2.47,4.9856,4.9856,0,0,1,.46,2.7744,2.5963,2.5963,0,0,1-.6158,1.2379,3.16,3.16,0,0,1-1.3872.836l-2,.8183c-.3978-.9726-.5981-1.4582-1.0014-2.4283l1.6448-.6838c.9973-.4146,1.463-.8094,1-1.9149-.3995-.9529-1.0522-.9017-1.9-.5438l-6.9667,2.942c-.8475.3579-1.3939.8081-.9725,1.8138.4526,1.08,1.0995.92,2.0968.5057l1.6989-.7062c.4.9624.5988,1.4442.9935,2.4091l-1.8511.7572A5.1838,5.1838,0,0,1,767.8688,296.7061Z" />
                                        <path className="st1" d="M772.8225,302.1832l-3.8923,1.526c-.3909-.9972-.5878-1.4951-.9845-2.49l4.051-1.6159c3.202-2.5116,4.797-3.7779,7.9741-6.3319.428,1.0539.64,1.5815,1.0621,2.6383-2.4239,1.6111-3.638,2.4125-6.07,4.0074,2.8563-.5227,4.2857-.78,7.1467-1.2858.4152,1.0595.6211,1.59,1.03,2.6523C779.007,301.619,776.9435,301.799,772.8225,302.1832Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M363.6438,795.5241c-.9707,1.8422-3.66,1.409-3.9682,1.363a7.3129,7.3129,0,0,1-1.3552-.3459,3.0354,3.0354,0,0,1-2.1807-2.0394,3.6718,3.6718,0,0,1,.0275-1.4945c.4807-3.0348.721-4.5522,1.2016-7.5871.0374-.2363.1039-.4942.1559-.7247a2.7624,2.7624,0,0,1,1.3365-1.9964,4.38,4.38,0,0,1,3.0306-.367,4.8146,4.8146,0,0,1,2.5369,1.018,2.598,2.598,0,0,1,.732,1.1548,3.2361,3.2361,0,0,1,.0168,1.6188l-.3016,2.14c-1.032-.1454-1.5478-.22-2.5792-.3709l.2585-1.7624c.1567-1.0687.0607-1.6676-1.0985-1.8411-1-.15-1.2924.4318-1.431,1.3413-.4559,2.99-.6838,4.4856-1.14,7.4761-.1386.9094-.0371,1.6137,1.0679,1.779,1.1864.1774,1.3847-.4622,1.5415-1.5309.1068-.7281.16-1.0922.2671-1.82,1.04.1525,1.56.2273,2.6.3739l-.279,1.98A5.0673,5.0673,0,0,1,363.6438,795.5241Z" />
                                        <path className="st1" d="M372.9743,797.7342a4.5409,4.5409,0,0,1-3.2644.5426,4.7608,4.7608,0,0,1-2.9166-1.2089,2.2827,2.2827,0,0,1-.6006-.9689,3.71,3.71,0,0,1-.0883-1.647c.4282-3.14.6422-4.71,1.07-7.8492a3.3917,3.3917,0,0,1,1.2244-2.4182,4.68,4.68,0,0,1,3.1929-.51c2.0345.2594,3.0117,1.22,3.3348,2.0847a3.8805,3.8805,0,0,1,.0985,1.9049c-.371,3.123-.5565,4.6844-.9276,7.8074A2.9754,2.9754,0,0,1,372.9743,797.7342Zm-.6058-10.2112a2.4587,2.4587,0,0,0-.019-1.1519,1.118,1.118,0,0,0-1.0052-.7519,1.4654,1.4654,0,0,0-.8744.1506c-.6323.3209-.6327.9459-.7078,1.5211-.3695,2.8326-.5543,4.2489-.9239,7.0815a2.1876,2.1876,0,0,0,.0771,1.3219,1.4172,1.4172,0,0,0,1.0321.5562,1.6132,1.6132,0,0,0,.8708-.1318c.5341-.2754.5826-.8345.6469-1.35C371.8265,791.87,372.0071,790.4213,372.3685,787.523Z" />
                                        <path className="st1" d="M382.024,799.381c-.0332-.0838-.0685-.1473-.1017-.2311a2.4937,2.4937,0,0,1-.1527-1.162l.3258-3.2042c.0223-.2186.0469-.44.0484-.6783.0048-.777-.5743-1.0242-1.266-1.0967-.5045-.0528-.7568-.08-1.2612-.1338l-.665,6.186c-1.0719-.1152-1.6078-.1744-2.6791-.2958.6361-5.6133.954-8.42,1.59-14.0333,2.0783.2355,3.118.3473,5.1982.5589a2.6811,2.6811,0,0,1,1.676.63,2.79,2.79,0,0,1,.58,2.5885l-.1454,1.5136a3.4786,3.4786,0,0,1-.4289,1.4862,1.68,1.68,0,0,1-.3411.3486,2.6692,2.6692,0,0,1-1.3076.3733,2.9655,2.9655,0,0,1,.7647.3575,2.3318,2.3318,0,0,1,.95,2.2832l-.2747,2.8668a3.1207,3.1207,0,0,0,.2131,1.689.8763.8763,0,0,0,.2265.2422C383.7936,799.5576,383.2036,799.5,382.024,799.381Zm.6747-10.93c.0547-.5372-.006-.7411-.206-.9663a1.2005,1.2005,0,0,0-.8818-.3122c-.5464-.057-.82-.086-1.3658-.1447l-.4125,3.8382c.5508.0592.8263.0884,1.3773.146a1.2434,1.2434,0,0,0,1.0274-.2354c.2226-.2184.2669-.4145.3236-.9722C382.6161,789.2629,382.6436,788.9923,382.6987,788.4509Z" />
                                        <path className="st1" d="M394.5885,793.8787c-.4664.4839-1.0925.621-2.3977.5167-.8975-.0717-1.3461-.1087-2.2433-.1848-.1988,2.3439-.2982,3.5159-.4971,5.86-1.0819-.0918-1.6227-.1392-2.7042-.2373.51-5.6261.7651-8.4392,1.275-14.0654,2.0979.19,3.1473.2792,5.2468.4451a3.7693,3.7693,0,0,1,.6966.1143,1.9916,1.9916,0,0,1,1.3085,1.1032,5.1227,5.1227,0,0,1,.1457,2.1367l-.1368,1.8553C395.1849,792.739,395.075,793.3737,394.5885,793.8787Zm-1.7791-4.1707a1.7169,1.7169,0,0,0-.1113-1.1521,1.1555,1.1555,0,0,0-.9422-.378c-.5151-.0422-.7726-.0637-1.2876-.1074l-.35,4.1268c.5115.0434.7673.0648,1.2789.1067.5486.045.8577.0062,1.0378-.1972a1.8278,1.8278,0,0,0,.26-.9628Z" />
                                        <path className="st1" d="M403.9479,800.4329a4.53,4.53,0,0,1-3.2165.758,4.747,4.747,0,0,1-2.9855-1.0125,2.2829,2.2829,0,0,1-.6623-.9268,3.7149,3.7149,0,0,1-.1968-1.6375c.2191-3.1612.3286-4.7418.5476-7.903a3.3956,3.3956,0,0,1,1.06-2.4941,4.6675,4.6675,0,0,1,3.1471-.7206c2.0441.1237,3.0813,1.0172,3.46,1.8587a3.8868,3.8868,0,0,1,.2241,1.8941c-.1632,3.1407-.2448,4.7111-.4081,7.8518A2.9783,2.9783,0,0,1,403.9479,800.4329Zm-1.2786-10.1485a2.4631,2.4631,0,0,0-.0951-1.1481,1.116,1.116,0,0,0-1.0511-.6835,1.4615,1.4615,0,0,0-.8611.2083c-.6088.3622-.5679.9858-.6046,1.5647l-.4525,7.1273a2.19,2.19,0,0,0,.1642,1.3138,1.414,1.414,0,0,0,1.065.4865,1.6083,1.6083,0,0,0,.8587-.1894c.514-.31.5254-.8713.5554-1.39C402.4166,794.6582,402.5009,793.2,402.6693,790.2844Z" />
                                        <path className="st1" d="M413.0752,801.4757c-.0387-.0815-.0781-.1424-.1167-.2239a2.494,2.494,0,0,1-.2291-1.1493c.0451-1.2875.0677-1.9312.1128-3.2187.0077-.22.0176-.4426.0034-.68-.0466-.7756-.64-.984-1.3343-1.01-.5064-.0192-.76-.0292-1.2658-.05-.1015,2.4866-.1522,3.73-.2536,6.2165-1.0759-.0438-1.6138-.0673-2.69-.1174.2627-5.6431.3941-8.4647.6566-14.1078,2.0866.0971,3.13.14,5.2175.2128a2.6758,2.6758,0,0,1,1.712.517,2.7912,2.7912,0,0,1,.7493,2.5444c-.0178.608-.0268.9119-.0447,1.52a3.4823,3.4823,0,0,1-.3292,1.5114,1.6817,1.6817,0,0,1-.317.37,2.6633,2.6633,0,0,1-1.2786.4592,2.9594,2.9594,0,0,1,.7859.3061,2.333,2.333,0,0,1,1.0984,2.2151l-.0842,2.8787a3.1224,3.1224,0,0,0,.3241,1.6711.8739.8739,0,0,0,.2418.2267C414.8506,801.5345,414.2587,801.5162,413.0752,801.4757Zm-.0506-10.9508c.019-.54-.055-.7391-.2692-.9505a1.1976,1.1976,0,0,0-.8995-.253c-.5483-.0207-.8225-.0315-1.3707-.0539-.0629,1.5429-.0944,2.3143-.1573,3.8572.5528.0225.8293.0334,1.3822.0543a1.241,1.241,0,0,0,1.0085-.3031c.2074-.2326.2386-.4312.2583-.9914Z" />
                                        <path className="st1" d="M422.904,801.7089c-.1287-.9221-.1926-1.3832-.32-2.3053-.9887-.0139-1.483-.0221-2.4717-.0412-.1742.9171-.2617,1.3756-.4375,2.2926-1.0763-.0217-1.6145-.0341-2.6908-.0619,1.28-5.618,1.9034-8.4292,3.1153-14.0555,1.1967.0237,1.795.0336,2.9918.0495.9857,5.6627,1.4948,8.4929,2.5461,14.15C424.5439,801.729,423.9973,801.7234,422.904,801.7089Zm-1.3579-10.5634c-.42,2.418-.633,3.6268-1.0634,6.0438.738.0138,1.1071.02,1.8452.0307C422.0106,794.7905,421.8542,793.5756,421.5461,791.1455Z" />
                                        <path className="st1" d="M431.7961,789.6593c.0284,4.8331.0425,7.25.0707,12.0828-1.0851.0063-1.6276.0079-2.7126.008,0-4.8332,0-7.25-.0008-12.083-1.0488,0-1.5732-.0014-2.622-.0074l.0115-2.04c3.1485.0179,4.7228.0132,7.8712-.0234.01.816.0142,1.2239.0237,2.04C433.3812,789.6485,432.8528,789.6531,431.7961,789.6593Z" />
                                        <path className="st1" d="M436.2527,801.696c-.0856-5.6486-.1286-8.4729-.2145-14.1215,2.7464-.0417,4.1195-.0729,6.8652-.1561l.0648,2.14c-1.71.0518-2.5649.0737-4.2751.11l.07,3.32c1.6254-.0341,2.4381-.0546,4.0632-.103.0255.8559.0382,1.2838.0637,2.14-1.6327.0486-2.4491.0693-4.0821.1034.0363,1.736.0545,2.604.0908,4.34,1.8293-.0383,2.7439-.0619,4.5729-.1181l.0669,2.18C440.6243,801.6183,439.1672,801.6517,436.2527,801.696Z" />
                                        <path className="st1" d="M457.7727,794.2815c-.3969.5441-.998.7641-2.3048.8385-.8983.0511-1.3475.0756-2.2459.1224.1223,2.3491.1834,3.5237.3056,5.8729-1.0839.0564-1.6259.0831-2.71.1332-.2609-5.6431-.3915-8.4648-.6527-14.108,2.1027-.0972,3.1538-.152,5.2555-.2736a3.7743,3.7743,0,0,1,.7055.0183,1.99,1.99,0,0,1,1.4457.9148,5.1153,5.1153,0,0,1,.4358,2.0969c.0469.7427.07,1.114.1172,1.8567C458.2077,793.0713,458.1859,793.7149,457.7727,794.2815Zm-2.33-3.8895a1.7182,1.7182,0,0,0-.2672-1.1262,1.1542,1.1542,0,0,0-.9839-.2462c-.5158.0283-.7737.0421-1.29.069.0862,1.6544.1293,2.4816.2154,4.136.5122-.0267.7683-.04,1.28-.0685.5489-.03.8505-.1114,1-.3366a1.8318,1.8318,0,0,0,.1273-.9894Z" />
                                        <path className="st1" d="M467.939,799.5a4.5312,4.5312,0,0,1-3.084,1.1892,4.7474,4.7474,0,0,1-3.096-.5964,2.2728,2.2728,0,0,1-.7824-.828,3.709,3.709,0,0,1-.419-1.5954c-.2135-3.1615-.32-4.7423-.5339-7.9039a3.3942,3.3942,0,0,1,.7112-2.6153,4.6658,4.6658,0,0,1,3.02-1.1425c2.0429-.156,3.1923.5879,3.6828,1.37a3.89,3.89,0,0,1,.4806,1.846c.2662,3.1337.3992,4.7.6653,7.8341A2.9794,2.9794,0,0,1,467.939,799.5Zm-2.65-9.88a2.45,2.45,0,0,0-.2511-1.1243,1.1156,1.1156,0,0,0-1.1344-.534,1.4636,1.4636,0,0,0-.8249.3236c-.5538.4418-.4283,1.054-.3859,1.6326.2091,2.849.3136,4.2735.5226,7.1224a2.189,2.189,0,0,0,.3421,1.2792,1.4163,1.4163,0,0,0,1.1212.3369,1.6114,1.6114,0,0,0,.8252-.3046c.4675-.3774.4019-.9348.3609-1.4527C465.6346,793.9874,465.52,792.5316,465.2892,789.62Z" />
                                        <path className="st1" d="M471.3742,799.8447c-.5084-5.6263-.7627-8.4395-1.2712-14.0658,1.0409-.0941,1.5613-.1426,2.6018-.2427.4588,4.771.6883,7.1565,1.1471,11.9275,1.5692-.1509,2.3536-.23,3.9218-.3939.0892.8516.1337,1.2774.2229,2.129C475.3491,799.476,474.0245,799.6052,471.3742,799.8447Z" />
                                        <path className="st1" d="M479.7054,799.0167c-.6088-5.6163-.9133-8.4244-1.522-14.0408,1.0318-.1118,1.5476-.1692,2.5788-.287.6407,5.6128.9612,8.4192,1.602,14.032C481.3009,798.8423,480.7691,798.9014,479.7054,799.0167Z" />
                                        <path className="st1" d="M492.4132,795.8285c-.4309,2.037-3.1349,2.3534-3.4445,2.3932a7.3071,7.3071,0,0,1-1.3966.0365,3.0313,3.0313,0,0,1-2.6514-1.3677,3.6688,3.6688,0,0,1-.3813-1.4454c-.3651-3.0509-.5477-4.5763-.9128-7.6272-.0284-.2376-.0346-.5036-.0477-.74a2.76,2.76,0,0,1,.74-2.285,4.3731,4.3731,0,0,1,2.8135-1.1792,4.8124,4.8124,0,0,1,2.717.2878,2.6025,2.6025,0,0,1,1.0184.9115,3.2375,3.2375,0,0,1,.4574,1.553l.2934,2.1411c-1.0325.1415-1.5488.2107-2.5819.3463l-.2317-1.7661c-.1406-1.0709-.3958-1.621-1.5575-1.472-1.0013.1284-1.1247.7678-1.01,1.6806.3767,3.0014.5652,4.5021.942,7.5036.1146.9127.4047,1.5626,1.5115,1.42,1.1889-.1527,1.2049-.8221,1.0643-1.893l-.2394-1.8242c1.0412-.1367,1.5617-.2065,2.6024-.3491l.2715,1.9815A5.0526,5.0526,0,0,1,492.4132,795.8285Z" />
                                        <path className="st1" d="M499.028,792.2872c.2513,1.6533.3769,2.48.6283,4.1332-1.0918.166-1.6379.2473-2.73.4068-.252-1.7262-.378-2.5893-.63-4.3156-1.712-3.6961-2.5561-5.5478-4.22-9.258,1.0927-.1525,1.6388-.2305,2.7308-.39.9846,2.735,1.4814,4.1014,2.4839,6.8319.1381-2.9.2028-4.3506.3237-7.25,1.0912-.1661,1.6366-.2507,2.727-.4235C499.84,786.1279,499.5779,788.181,499.028,792.2872Z" />
                                    </g>

                                </>}
                            {SelectedLanguage === "de-DE" &&
                                <>
                                    <g>
                                        <path className="st1" d="M71.65,321.3l1.88,0.63l0.84,0.42l0.69,0.59l0.48,0.65l0.24,0.86l0.09,1.08l-0.28,1.29l-0.57,1.5l-0.75,0.91
			l-1.1,0.58l-0.87,0.21L71.46,330l-1.65-0.4l-9.16-2.59l0.77-2.73l8.14,2.3l1.78,0.48l1.12,0.15l0.47-0.2l0.28-0.27l0.25-0.53
			l0.04-0.51l-0.29-0.73l-0.65-0.35l-1.06-0.38l-1.23-0.35l-8.1-2.27l0.78-2.77L71.65,321.3z"/>
                                        <path className="st1" d="M79.11,314.27l-7.91,0.19l-1.23-0.01l-1.99-0.07l1.35,0.24l1.49,0.39l7.37,2.24l-0.82,2.65l-13.48-4.26
			l1.04-3.2l7.3-0.14l1.83-0.03l1.75,0.1l-1.58-0.29l-0.97-0.25l-7.43-2.25l0.84-2.63l13.46,4.27L79.11,314.27z"/>
                                        <path className="st1" d="M81.23,308l-11.06-3.83l-0.76,2.19l-2.29-0.79l2.45-7.09l2.29,0.79l-0.76,2.19l11.06,3.83L81.23,308z" />
                                        <path className="st1" d="M83.31,302.08l-13.23-4.92l2.32-6.24l2.4,0.89l-1.32,3.54l2.85,1.06l1.18-3.17l2.34,0.87l-1.18,3.17l3.22,1.2
			l1.32-3.54l2.42,0.9L83.31,302.08z"/>
                                        <path className="st1" d="M88.72,288.07l-0.55,0.02l-1.83-0.48l-0.76-0.31l-1.26-0.45l-0.41-0.06l-0.6,0.1l-0.34,0.36l-0.22,0.39
			l-0.4,0.98l5.29,2.13l-1.09,2.71l-13.1-5.28l1.98-4.9l0.52-0.87l0.53-0.5l0.7-0.39l1.2-0.14l1.71,0.45l0.86,0.39l1.05,0.62
			l0.68,0.94l0.09,0.9l0.81-0.62l1.07-0.09l0.99,0.33l0.24,0.1l2.1,0.67l1.4,0.22l0.5-0.06L88.72,288.07z M80.78,286.2l0.02-0.42
			l-0.28-0.54l-1.02-0.52l-0.83-0.29l-0.44-0.03l-0.61,0.17l-0.52,0.98l-0.34,0.85l3.39,1.37L80.78,286.2z"/>
                                        <path className="st1" d="M92.98,277.97l-7.89-0.66l-1.23-0.15l-1.97-0.28l1.32,0.38l1.44,0.55l7.09,3.01l-1.1,2.55l-12.95-5.68
			l1.37-3.07l7.28,0.65l1.83,0.17l1.73,0.28l-1.54-0.46l-0.94-0.35l-7.14-3.04l1.11-2.53l12.92,5.69L92.98,277.97z"/>
                                        <path className="st1" d="M95.55,272.38l-12.75-6.07l2.86-6.02l2.31,1.1l-1.62,3.41l2.75,1.31l1.45-3.05l2.26,1.07l-1.45,3.05
			l3.11,1.48l1.62-3.41l2.33,1.11L95.55,272.38z"/>
                                        <path className="st1" d="M102,259.25l-5.45-2.78l-1.15,2.26l5.45,2.78l-1.31,2.57l-12.58-6.42l1.31-2.57l5.06,2.58l1.15-2.26
			l-5.06-2.58l1.31-2.57l12.58,6.42L102,259.25z"/>
                                        <path className="st1" d="M109.41,245.5l-5.11-2.84l-3.26-1.84l-1.73-1.12l0.77,0.59l1.22,0.98l1.45,1.17l5.62,4.91l-1.33,2.35
			l-6.98-2.07l-2.28-0.83l-1.46-0.51l-1-0.42l1.23,0.64l2.51,1.21l6.96,3.92l-1.32,2.33l-12.36-6.79l2.02-3.66l7.73,2.44l1.93,0.64
			l1.33,0.6l-1.45-1.01l-1.04-0.81l-6.49-5.46l2.01-3.62l12.36,6.83L109.41,245.5z"/>
                                        <path className="st1" d="M112.29,240.58l-12.11-7.28l3.43-5.71l2.2,1.32l-1.95,3.24l2.61,1.57l1.74-2.9l2.14,1.29l-1.74,2.9l2.95,1.77
			l1.95-3.24l2.21,1.33L112.29,240.58z"/>
                                        <path className="st1" d="M120.21,227.71l-7.69-1.87l-1.19-0.33l-1.91-0.58l1.24,0.58l1.34,0.76l6.54,4.07l-1.48,2.35l-11.91-7.61
			l1.83-2.82l7.09,1.76l1.78,0.45l1.67,0.55l-1.45-0.7l-0.88-0.49l-6.58-4.1l1.49-2.32l11.88,7.62L120.21,227.71z"/>
                                        <path className="st1" d="M117.68,213.44l-0.23-0.13l-0.79-0.51l-0.91-0.09l-0.51,0.35l-0.15,0.36l-0.04,0.53l0.21,0.41l0.45,0.38
			l0.53,0.29l0.52,0.02l1.51-0.37l1.38-0.53l1.19-0.4l1.57-0.17l1,0.24l1.12,0.59l1.1,0.94l0.75,1.21l0.1,1.28l-0.43,1.55
			l-0.56,0.83l-0.9,1.1l-0.89,0.56l-1.08,0.2l-0.92-0.1l-0.86-0.37l-1.54-1l1.56-2.28l1.01,0.69l0.81,0.38l0.58-0.07l0.58-0.45
			l0.22-0.67l-0.2-0.67l-0.67-0.58l-0.82-0.44l-0.82,0l-1.12,0.37l-1.43,0.57l-1.07,0.36l-1.2,0.22l-0.92-0.17l-1.26-0.67
			l-0.91-0.76l-0.66-0.98l-0.24-0.79l0.04-0.94l0.25-0.82l0.65-1.13l1.23-1.27l1.04-0.46l0.93,0.03l1.26,0.45l1.11,0.71
			L117.68,213.44z"/>
                                        <path className="st1" d="M128.84,214.99l-11.46-8.28l2.67-3.7l0.94-0.85l0.84-0.38l1.04-0.04l1.16,0.37l1.5,0.96l1.1,0.9l0.71,0.73
			l0.47,0.78l0.2,0.86l-0.12,0.92l-0.36,0.88l-0.76,1.08l-0.34,0.47l4.1,2.96L128.84,214.99z M124.82,207.6l0.3-0.69l-0.28-0.64
			l-0.76-0.72L123,204.8l-0.76-0.18l-0.44,0.23l-0.32,0.34l-0.45,0.62l3.36,2.42L124.82,207.6z"/>
                                        <path className="st1" d="M135.99,198.15l1.14,1.05l0.64,0.94l0.3,0.83l0.02,0.82l-0.45,1.57l-0.58,0.82l-1,1.07l-1.31,0.63l-0.9,0.12
			l-1.17-0.24l-0.62-0.35l-3.74-2.61l-1.79-1.45l-0.87-0.77l-0.72-0.83l-0.41-0.94l-0.09-0.97l0.3-1.08l0.83-1.45l0.84-0.9
			l0.84-0.54l1.4-0.32l1.19,0.23l0.64,0.29l2.96,2.09L135.99,198.15z M130.03,197.32l-0.91-0.62l-0.75-0.27l-0.58,0.01l-0.54,0.34
			l-0.24,0.65l0.4,0.89l0.69,0.65l1.64,1.31l2.54,1.89l0.91,0.65l0.96,0.46l0.76-0.1l0.39-0.31l0.21-0.6l-0.11-0.61l-0.82-0.8
			l-1.36-1.09L130.03,197.32z"/>
                                        <path className="st1" d="M140.88,198.95l-10.98-8.88l1.79-2.21l9.02,7.29l2.55-3.16l1.96,1.58L140.88,198.95z" />
                                        <path className="st1" d="M146.16,192.47l-10.82-9.08l1.85-2.21l10.82,9.08L146.16,192.47z" />
                                        <path className="st1" d="M150.22,187.68l-8.83-7.68l-1.52,1.75l-1.83-1.59l4.92-5.66l1.83,1.59l-1.52,1.75l8.83,7.68L150.22,187.68z"
                                        />
                                        <path className="st1" d="M154.34,182.98l-10.5-9.44l1.93-2.14l10.49,9.44L154.34,182.98z" />
                                        <path className="st1" d="M162.51,174.13l-7.39-2.32l5.11,4.73l-1.96,2.08l-10.28-9.69l1.92-2.04l4.63,4.36l-2.58-6.53l2.2-2.33
			l2.42,7.09l8.09,2.35L162.51,174.13z"/>
                                    </g>
                                    <g>
                                        <path className="st1" d="M692.08,170.99l-1.49,1.31l-0.78,0.51l-0.85,0.3l-0.81,0.09l-0.86-0.22l-0.98-0.47l-0.98-0.89l-1.02-1.24
			l-0.41-1.11l0.04-1.24l0.25-0.86l0.45-0.69l1.18-1.23l6.82-6.64l1.98,2.03l-6.06,5.9l-1.3,1.3l-0.69,0.9l-0.06,0.51l0.09,0.38
			l0.33,0.49l0.42,0.29l0.78,0.11l0.63-0.39l0.86-0.72l0.92-0.89l6.02-5.89l2.01,2.06L692.08,170.99z"/>
                                        <path className="st1" d="M694.46,181l3.79-6.95l0.63-1.06l1.05-1.69l-0.88,1.05l-1.08,1.1l-5.62,5.26l-1.89-2.04l10.43-9.54l2.25,2.49
			l-3.53,6.39l-0.89,1.6l-0.96,1.47l1.05-1.23l0.7-0.72l5.66-5.3l1.86,2.04l-10.43,9.52L694.46,181z"/>
                                        <path className="st1" d="M698.81,185.95l8.84-7.66l-1.52-1.75l1.83-1.59l4.91,5.67l-1.83,1.58l-1.52-1.75l-8.84,7.66L698.81,185.95z"
                                        />
                                        <path className="st1" d="M702.9,190.7l10.87-9l4.25,5.13l-1.97,1.63l-2.41-2.91l-2.34,1.94l2.16,2.6l-1.93,1.59l-2.15-2.6l-2.65,2.19
			l2.41,2.91l-1.99,1.64L702.9,190.7z"/>
                                        <path className="st1" d="M712.33,202.39l0.26-0.48l1.33-1.35l0.65-0.5l1.02-0.87l0.25-0.33l0.21-0.57l-0.14-0.47l-0.22-0.38
			l-0.65-0.83l-4.49,3.51l-1.8-2.3l11.12-8.7l3.25,4.16l0.49,0.89l0.17,0.7l-0.01,0.8l-0.48,1.11l-1.25,1.26l-0.77,0.55l-1.06,0.6
			l-1.15,0.12l-0.82-0.37l0.13,1.01l-0.46,0.97l-0.78,0.69l-0.21,0.16l-1.63,1.48l-0.89,1.1l-0.2,0.46L712.33,202.39z
			 M717.91,196.45l0.36,0.23l0.61,0.03l0.96-0.62l0.67-0.57l0.24-0.37l0.16-0.61l-0.58-0.94l-0.57-0.73l-2.88,2.26L717.91,196.45z"
                                        />
                                        <path className="st1" d="M718.93,211.13l4.51-6.5l0.74-0.99l1.23-1.57l-0.99,0.95l-1.2,0.97l-6.15,4.63l-1.66-2.23l11.4-8.37
			l1.97,2.72l-4.2,5.98l-1.06,1.5l-1.11,1.36l1.17-1.11l0.77-0.64l6.2-4.67l1.63,2.23l-11.39,8.34L718.93,211.13z"/>
                                        <path className="st1" d="M722.49,216.16l11.63-8.01l3.78,5.49l-2.11,1.45l-2.14-3.11l-2.5,1.72l1.92,2.78L731,217.9l-1.92-2.78
			l-2.83,1.95l2.14,3.11l-2.13,1.46L722.49,216.16z"/>
                                        <path className="st1" d="M730.64,228.31l5.13-3.33l-1.38-2.13l-5.13,3.33l-1.57-2.42l11.85-7.68l1.57,2.42l-4.77,3.09l1.38,2.13
			l4.77-3.09l1.57,2.42l-11.85,7.68L730.64,228.31z"/>
                                        <path className="st1" d="M738.84,241.6l5.01-3l3.22-1.9l1.84-0.94l-0.9,0.37l-1.46,0.57l-1.74,0.67l-7.07,2.41l-1.37-2.33l5.28-5
			l1.86-1.56l1.17-1l0.87-0.66l-1.16,0.74l-2.3,1.57l-6.87,4.07l-1.36-2.31l12.06-7.32l2.17,3.58l-5.98,5.47l-1.52,1.35l-1.19,0.85
			l1.6-0.75l1.22-0.5l7.97-2.89l2.13,3.55l-12.1,7.29L738.84,241.6z"/>
                                        <path className="st1" d="M741.66,246.55l12.35-6.85l3.23,5.83l-2.24,1.24l-1.83-3.31l-2.66,1.47l1.64,2.96l-2.19,1.21l-1.64-2.96
			l-3.01,1.67l1.83,3.31l-2.26,1.25L741.66,246.55z"/>
                                        <path className="st1" d="M748.85,259.85l5.46-5.73l0.88-0.86l1.46-1.36l-1.12,0.78l-1.33,0.78l-6.79,3.62l-1.29-2.46l12.55-6.51
			l1.52,2.99l-5.07,5.26l-1.28,1.31l-1.31,1.17l1.33-0.91l0.86-0.51l6.85-3.65l1.27,2.45l-12.54,6.48L748.85,259.85z"/>
                                        <path className="st1" d="M762.47,264.79l0.23-0.13l0.84-0.43l0.53-0.74l-0.05-0.62l-0.24-0.31l-0.43-0.3l-0.46-0.02l-0.55,0.2
			l-0.51,0.31l-0.28,0.44l-0.43,1.5l-0.23,1.46l-0.25,1.23l-0.64,1.44l-0.71,0.74l-1.08,0.67l-1.37,0.48l-1.42,0.04l-1.15-0.56
			l-1.13-1.14l-0.43-0.9l-0.51-1.33l-0.04-1.05l0.37-1.04l0.55-0.75l0.75-0.56l1.64-0.83l1.2,2.49l-1.1,0.53l-0.73,0.51l-0.23,0.53
			l0.11,0.73l0.47,0.53l0.68,0.16l0.84-0.29l0.79-0.49l0.41-0.71l0.24-1.16l0.22-1.53l0.22-1.11l0.4-1.15l0.61-0.71l1.21-0.76
			l1.12-0.4l1.18-0.08l0.8,0.19l0.8,0.5l0.59,0.63l0.65,1.13l0.48,1.7l-0.13,1.12l-0.49,0.79l-1.02,0.87l-1.17,0.61L762.47,264.79z"
                                        />
                                        <path className="st1" d="M755.55,273.68l12.9-5.79l1.87,4.16l0.27,1.24l-0.09,0.92l-0.49,0.92l-0.9,0.82l-1.58,0.82l-1.33,0.51
			l-0.99,0.25l-0.91,0.02l-0.84-0.26l-0.74-0.57l-0.58-0.75l-0.56-1.19l-0.24-0.53l-4.62,2.07L755.55,273.68z M763.95,273.9
			l0.45,0.61l0.7,0.08l1.01-0.3l1.19-0.58l0.53-0.57l0.02-0.49l-0.13-0.44l-0.31-0.69l-3.78,1.7L763.95,273.9z"/>
                                        <path className="st1" d="M766.56,288.29l-1.48,0.46l-1.14,0.08l-0.87-0.16l-0.73-0.39l-1.14-1.17l-0.42-0.91l-0.43-1.4l0.11-1.45
			l0.35-0.84l0.8-0.9l0.61-0.36l4.13-1.94l2.15-0.83l1.1-0.37l1.08-0.21l1.02,0.12l0.89,0.41l0.79,0.8l0.84,1.45l0.36,1.17l0.05,1
			l-0.43,1.37l-0.79,0.91l-0.57,0.41l-3.29,1.52L766.56,288.29z M770.26,283.55l0.99-0.48l0.61-0.51l0.29-0.51l-0.03-0.64
			l-0.44-0.53l-0.97-0.09l-0.91,0.27l-1.96,0.77l-2.9,1.25l-1.02,0.47l-0.88,0.6l-0.29,0.71l0.07,0.49l0.42,0.48l0.59,0.21
			l1.11-0.31l1.62-0.63L770.26,283.55z"/>
                                        <path className="st1" d="M763.42,292.13l13.18-5.07l1.02,2.65l-10.83,4.17l1.46,3.79l-2.35,0.91L763.42,292.13z" />
                                        <path className="st1" d="M766.39,299.94l13.27-4.83l0.98,2.71l-13.27,4.83L766.39,299.94z" />
                                        <path className="st1" d="M768.51,305.86l11.06-3.81l-0.76-2.19l2.29-0.79l2.44,7.09l-2.29,0.79l-0.76-2.19l-11.07,3.81L768.51,305.86z
			"/>
                                        <path className="st1" d="M770.52,311.78l13.42-4.37l0.89,2.74l-13.43,4.37L770.52,311.78z" />
                                        <path className="st1" d="M774.1,323.27l5.7-5.24l-6.65,2.06l-0.82-2.74l13.53-4.06l0.8,2.68l-6.09,1.83l6.95,1.03l0.92,3.07
			l-7.35-1.45l-6.08,5.83L774.1,323.27z"/>
                                    </g>

                                    <g>
                                        <path className="st1" d="M344.06,788.21l-0.55,1.9l-0.39,0.85l-0.56,0.71l-0.63,0.51l-0.84,0.27l-1.08,0.14l-1.3-0.22l-1.53-0.5
			l-0.94-0.71l-0.63-1.07l-0.25-0.86l0.01-0.82l0.33-1.67l2.17-9.27l2.76,0.65l-1.93,8.24l-0.4,1.8l-0.1,1.13l0.22,0.46l0.29,0.27
			l0.54,0.23l0.51,0.02l0.71-0.33l0.32-0.67l0.33-1.07l0.29-1.25l1.9-8.2l2.8,0.66L344.06,788.21z"/>
                                        <path className="st1" d="M351.92,794.98l-1.06-7.84l-0.12-1.23l-0.15-1.99l-0.09,1.37l-0.22,1.53l-1.42,7.57l-2.73-0.53l2.76-13.87
			l3.29,0.68l0.93,7.24l0.23,1.82l0.1,1.75l0.12-1.61l0.14-0.99l1.43-7.63l2.71,0.54l-2.78,13.84L351.92,794.98z"/>
                                        <path className="st1" d="M358.68,796.29l2.01-11.52l-2.29-0.4l0.42-2.38l7.39,1.29l-0.42,2.38l-2.29-0.4l-2.01,11.52L358.68,796.29z"
                                        />
                                        <path className="st1" d="M365.1,797.39l2.12-13.96l6.58,1l-0.38,2.53l-3.74-0.57l-0.46,3.01l3.34,0.51l-0.38,2.47l-3.34-0.51
			l-0.52,3.4l3.74,0.57l-0.39,2.55L365.1,797.39z"/>
                                        <path className="st1" d="M380.35,799.51l-0.16-0.52l-0.01-1.9l0.1-0.81l0.1-1.34l-0.05-0.41l-0.25-0.56l-0.43-0.24l-0.43-0.11
			l-1.05-0.13l-0.7,5.66l-2.9-0.36l1.73-14.02l5.24,0.65l0.97,0.28l0.62,0.38l0.55,0.57l0.45,1.12l0,1.77l-0.16,0.93l-0.33,1.17
			l-0.74,0.9l-0.85,0.32l0.81,0.62l0.36,1.01l-0.07,1.04l-0.03,0.26l-0.11,2.2l0.15,1.41l0.18,0.47L380.35,799.51z M380.11,791.35
			l0.41-0.09l0.45-0.41l0.24-1.12l0.07-0.88l-0.09-0.43l-0.32-0.54l-1.08-0.25l-0.91-0.11l-0.45,3.63L380.11,791.35z"/>
                                        <path className="st1" d="M391.56,800.63l-1.88-7.68l-0.25-1.21l-0.36-1.96l0.05,1.37l-0.06,1.54l-0.6,7.67l-2.77-0.23l1.27-14.08
			l3.34,0.33l1.7,7.1l0.42,1.79l0.28,1.73l-0.05-1.61l0.03-1l0.61-7.74l2.75,0.25l-1.29,14.06L391.56,800.63z"/>
                                        <path className="st1" d="M398.09,801.2l0.88-14.09l6.65,0.42l-0.16,2.55l-3.77-0.24l-0.19,3.03l3.37,0.21l-0.16,2.49l-3.37-0.21
			l-0.22,3.43l3.77,0.24l-0.16,2.58L398.09,801.2z"/>
                                        <path className="st1" d="M413,801.95l0.21-6.12l-2.54-0.09l-0.21,6.12l-2.88-0.1l0.5-14.11l2.88,0.1l-0.2,5.68l2.54,0.09l0.2-5.68
			l2.88,0.1l-0.5,14.11L413,801.95z"/>
                                        <path className="st1" d="M429.31,802.07l-0.09-5.84l-0.04-3.74l0.11-2.06l-0.12,0.96l-0.24,1.54l-0.29,1.85l-1.44,7.33l-2.7,0.02
			l-1.69-7.08l-0.42-2.39l-0.28-1.52l-0.14-1.08l0.06,1.38l0.2,2.78l0.09,7.98l-2.68,0.02l-0.31-14.1l4.18-0.09l1.75,7.92l0.41,1.99
			l0.14,1.46l0.15-1.76l0.18-1.3l1.49-8.35l4.14-0.07l0.27,14.12L429.31,802.07z"/>
                                        <path className="st1" d="M435.14,802.03l-0.49-14.11l6.66-0.23l0.09,2.56l-3.78,0.13l0.1,3.04l3.38-0.12l0.09,2.5l-3.38,0.12
			l0.12,3.44l3.78-0.13l0.09,2.58L435.14,802.03z"/>
                                        <path className="st1" d="M450.59,801.27l-3.04-7.3l-0.43-1.16l-0.66-1.88l0.26,1.35l0.18,1.53l0.58,7.68l-2.77,0.19l-0.91-14.11
			l3.35-0.19l2.77,6.76l0.69,1.7l0.54,1.67l-0.3-1.58l-0.12-0.99l-0.58-7.74l2.75-0.17l0.88,14.09L450.59,801.27z"/>
                                        <path className="st1" d="M460.32,790.43l-0.04-0.26l-0.11-0.93l-0.51-0.76l-0.6-0.17l-0.37,0.11l-0.43,0.3l-0.18,0.42l-0.01,0.58
			l0.12,0.59l0.32,0.41l1.25,0.93l1.29,0.72l1.07,0.67l1.12,1.1l0.45,0.92l0.25,1.24l-0.03,1.45l-0.46,1.35l-0.92,0.89l-1.47,0.66
			l-1,0.09l-1.42,0.01l-0.99-0.33l-0.85-0.71l-0.51-0.78l-0.26-0.9l-0.21-1.83l2.75-0.25l0.11,1.21l0.22,0.86l0.42,0.4l0.72,0.16
			l0.66-0.26l0.39-0.58l0.02-0.88l-0.18-0.91l-0.52-0.63l-1-0.63l-1.35-0.74l-0.96-0.59l-0.93-0.78l-0.46-0.82l-0.29-1.4l0.01-1.19
			l0.34-1.14l0.46-0.68l0.75-0.57l0.79-0.33l1.29-0.22l1.76,0.14l1.01,0.51l0.57,0.73l0.46,1.26l0.16,1.31L460.32,790.43z"/>
                                        <path className="st1" d="M466.55,799.92l-1.66-14.05l4.53-0.54l1.26,0.11l0.85,0.36l0.73,0.74l0.51,1.11l0.31,1.76l0.09,1.42
			l-0.06,1.01l-0.26,0.88l-0.5,0.72l-0.76,0.53l-0.89,0.33l-1.31,0.17l-0.58,0.07l0.59,5.03L466.55,799.92z M469.28,791.96
			l0.72-0.25l0.29-0.64l0.02-1.05l-0.2-1.31l-0.38-0.68l-0.46-0.17l-0.46-0.01l-0.75,0.09l0.49,4.11L469.28,791.96z"/>
                                        <path className="st1" d="M483.8,793.26l0.08,1.54l-0.2,1.12l-0.37,0.8l-0.56,0.61l-1.42,0.81l-0.98,0.18l-1.46,0.07l-1.38-0.46
			l-0.73-0.54l-0.67-0.99l-0.2-0.68l-0.86-4.48l-0.27-2.29l-0.09-1.16l0.06-1.1l0.36-0.96l0.62-0.76l0.97-0.57l1.61-0.46l1.22-0.06
			l0.98,0.2l1.22,0.75l0.69,0.99l0.26,0.65l0.66,3.56L483.8,793.26z M480.11,788.51l-0.22-1.08l-0.35-0.72l-0.42-0.4l-0.62-0.13
			l-0.62,0.29l-0.33,0.92l0.04,0.95l0.26,2.08l0.5,3.12l0.2,1.1l0.37,1l0.61,0.46l0.49,0.05l0.56-0.29l0.35-0.52l-0.03-1.15
			l-0.21-1.73L480.11,788.51z"/>
                                        <path className="st1" d="M487.02,797.11l-2.41-13.91l2.8-0.49l1.98,11.43l4-0.69l0.43,2.48L487.02,797.11z" />
                                        <path className="st1" d="M495.48,795.6l-2.67-13.87l2.83-0.54l2.67,13.87L495.48,795.6z" />
                                        <path className="st1" d="M501.83,794.35l-2.41-11.45l-2.27,0.48l-0.5-2.37l7.34-1.54l0.5,2.37l-2.27,0.48l2.41,11.45L501.83,794.35z"
                                        />
                                        <path className="st1" d="M508.14,792.99l-3.15-13.77l2.81-0.64l3.15,13.77L508.14,792.99z" />
                                        <path className="st1" d="M520.11,790.09l-4.99-5.92l1.77,6.73l-2.77,0.7l-3.47-13.69l2.72-0.69l1.56,6.17l1.33-6.9l3.1-0.79
			l-1.77,7.28l5.57,6.33L520.11,790.09z"/>
                                    </g>
                                </>} </g>
                        <g id="Circle_-_1_6_" className={"model-option" + (this.state.changed ? this.setStyles(1) : '')}>
                            <path id="Circle" className="st3" onMouseEnter={() => this.hoverPie(1)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 1)}
                                d="M425.2,0C190.37,0,0,190.37,0,425.2s190.37,425.2,425.2,425.2s425.2-190.37,425.2-425.2
		S660.03,0,425.2,0z M425.2,812.75c-214.04,0-387.55-173.51-387.55-387.55S211.16,37.64,425.2,37.64S812.75,211.16,812.75,425.2
		S639.24,812.75,425.2,812.75z"/>
                            {SelectedLanguage === "en-GB" &&
                                <>
                                    <g>
                                        <path className="st1" d="M374.41,834.5362c.8839-5.58,1.3255-8.37,2.2093-13.9492,2.7222.4312,4.0851.6327,6.8135,1.0078l-.2916,2.1207c-1.702-.234-2.5526-.3564-4.2523-.6121-.1976,1.3134-.2963,1.97-.4939,3.2834,1.6192.2435,2.4294.36,4.0507.5845l-.2931,2.12c-1.6314-.2255-2.4466-.3431-4.076-.5882-.2582,1.717-.3873,2.5755-.6456,4.2925,1.8312.2755,2.7476.4071,4.5815.6581l-.2957,2.16C378.79,835.2143,377.3287,834.9985,374.41,834.5362Z" />
                                        <path className="st1" d="M388.5789,836.4851c-.55-4.3258-.8089-6.4889-1.2953-10.8149-.4337,4.2115-.649,6.3174-1.0772,10.5295-1.0157-.1256-1.5233-.19-2.5382-.3234.7348-5.6013,1.1019-8.4019,1.8365-14.0031,1.3331.1748,2,.2589,3.3343.42.5168,4.2377.7911,6.3566,1.3711,10.5937.36-4.1307.541-6.1959.907-10.3259.9513.1085,1.4271.1611,2.379.2628-.6005,5.6173-.9006,8.4259-1.5011,14.0431C390.628,836.7211,389.9448,836.6446,388.5789,836.4851Z" />
                                        <path className="st1" d="M399.4167,837.5808c-1.0936-.0934-1.64-.1423-2.7333-.2443-.643-5.7338-.9409-8.601-1.4892-14.3352,1.1044.1124,1.6568.1663,2.7619.27.2047,3.7721.3148,5.6583.55,9.4309.8591-3.6749,1.2816-5.5134,2.1125-9.1928,1.0655.091,1.5984.1344,2.6646.217C401.7812,829.2758,401.0079,832.0469,399.4167,837.5808Z" />
                                        <path className="st1" d="M403.9869,837.9411c.4072-5.6345.6108-8.4517,1.0181-14.0863,1.0343.0748,1.5515.11,2.5863.177-.3639,5.6375-.5457,8.4562-.91,14.0937C405.6036,838.0559,405.0646,838.019,403.9869,837.9411Z" />
                                        <path className="st1" d="M415.21,838.5717c-.0386-.0817-.0781-.1429-.1167-.2246a2.4741,2.4741,0,0,1-.2249-1.1508l.1328-3.218c.009-.22.02-.4425.0074-.68-.0423-.7759-.6383-.9881-1.3367-1.02-.5093-.0231-.764-.0351-1.2733-.06-.1219,2.4856-.1828,3.7285-.3047,6.2142-1.0872-.0533-1.6307-.0821-2.7176-.1438l.801-14.1c2.0863.1185,3.13.17,5.2172.2558a2.6833,2.6833,0,0,1,1.71.5264,2.7922,2.7922,0,0,1,.739,2.5478l-.0512,1.52a3.4635,3.4635,0,0,1-.3369,1.51,1.6842,1.6842,0,0,1-.32.3689,2.6787,2.6787,0,0,1-1.2877.4524,2.9926,2.9926,0,0,1,.7887.31,2.331,2.331,0,0,1,1.0959,2.22l-.0961,2.8783a3.0934,3.0934,0,0,0,.3212,1.6725.8813.8813,0,0,0,.2437.2277C417.0042,838.6411,416.406,838.62,415.21,838.5717Zm.0128-10.951c.0223-.54-.0507-.7394-.2639-.9523a1.203,1.203,0,0,0-.9-.2591c-.5492-.0247-.8238-.0376-1.3728-.0645l-.1891,3.8557c.5554.0272.8331.04,1.3885.0652a1.249,1.249,0,0,0,1.0151-.296c.21-.2312.2423-.43.2655-.99Z" />
                                        <path className="st1" d="M427.1993,838.2077a4.597,4.597,0,0,1-3.21.9059,4.8173,4.8173,0,0,1-3.0588-.88,2.2821,2.2821,0,0,1-.7084-.8977,3.6831,3.6831,0,0,1-.2689-1.6278c.0849-3.1676.1273-4.7514.2122-7.919a3.3986,3.3986,0,0,1,.9534-2.5371,4.6838,4.6838,0,0,1,3.1119-.8592c2.0462.0307,3.1248.8724,3.5445,1.6946a3.8938,3.8938,0,0,1,.3154,1.8821c-.0109,3.1445-.0163,4.7168-.0272,7.8613A2.9617,2.9617,0,0,1,427.1993,838.2077Zm-1.7669-10.078a2.4658,2.4658,0,0,0-.1485-1.1424,1.1187,1.1187,0,0,0-1.0829-.6346,1.4666,1.4666,0,0,0-.8518.2469c-.5926.3891-.5241,1.01-.5351,1.59l-.1355,7.14a2.1721,2.1721,0,0,0,.2245,1.3051,1.4333,1.4333,0,0,0,1.0957.4383,1.6332,1.6332,0,0,0,.8569-.2284c.5035-.3335.4885-.8946.4944-1.4141C425.383,832.5105,425.4,831.05,425.4324,828.13Z" />
                                        <path className="st1" d="M435.5633,838.7969c-1.1224-4.2133-1.6679-6.3223-2.7272-10.5442.134,4.2317.2024,6.3474.3419,10.579-1.0215.0114-1.5323.0152-2.5539.0192-.0219-5.6492-.0329-8.4738-.0548-14.123,1.3417-.0052,2.0125-.0111,3.3541-.03,1.0778,4.13,1.6324,6.1935,2.7728,10.315-.1963-4.1417-.2928-6.2126-.4827-10.3545.9556-.02,1.4334-.0313,2.3889-.0578.1567,5.6471.2351,8.4706.3918,14.1176C437.6219,838.7566,436.9357,838.7723,435.5633,838.7969Z" />
                                        <path className="st1" d="M451.267,838.1626c-.2072-4.8935-.3127-7.34-.5269-12.2333-.6087,4.9455-.93,7.4177-1.6054,12.3609-1.0118.0574-1.5178.0843-2.53.1344-1.22-4.8425-1.8134-7.2663-2.9657-12.1187.2884,4.8891.4341,7.3335.7282,12.2223-1.0708.0461-1.6062.0671-2.6771.1051-.2-5.6457-.3-8.4685-.5-14.1142,1.7024-.06,2.5535-.0959,4.2553-.1778.8562,3.9161,1.294,5.8729,2.1892,9.7837.4844-3.9841.7167-5.9764,1.1614-9.9617,1.6772-.0975,2.5155-.1515,4.1917-.27.3983,5.6352.5974,8.4527.9956,14.0879C452.8968,838.0581,452.3536,838.0943,451.267,838.1626Z" />
                                        <path className="st1" d="M456.6724,837.7809c-.4415-5.632-.6623-8.4479-1.1039-14.08,2.7413-.2149,4.1112-.3363,6.8488-.6073.0844.852.1266,1.2781.2109,2.13-1.7078.169-2.5622.2481-4.2714.3955.114,1.3232.1711,1.9848.2852,3.308,1.6283-.14,2.4421-.2154,4.0691-.3753l.2094,2.13c-1.6372.1609-2.4561.2364-4.0946.3777.1491,1.73.2237,2.5948.3728,4.3247,1.8419-.1588,2.7624-.2443,4.6025-.4275l.2161,2.17C461.0815,837.42,459.6124,837.55,456.6724,837.7809Z" />
                                        <path className="st1" d="M470.89,836.3743c-1.5435-4.0784-2.3-6.1216-3.7809-10.215.56,4.1966.8411,6.2947,1.4064,10.4907-1.0164.1144-1.5248.17-2.5417.2769-.5914-5.6182-.8871-8.4273-1.4786-14.0455,1.3361-.1406,2.0039-.2143,3.3389-.3683,1.4906,4,2.2512,5.997,3.8035,9.9822-.6136-4.1007-.9188-6.1512-1.5261-10.2528.9508-.1162,1.4261-.1761,2.3763-.2991.7256,5.6024,1.0883,8.4036,1.8139,14.006C472.9381,836.1261,472.2557,836.2111,470.89,836.3743Z" />
                                        <path className="st1" d="M479.6078,823.007c.7186,4.78,1.0779,7.1693,1.7966,11.9487-1.0872.1635-1.6311.2431-2.7192.398-.6812-4.785-1.0217-7.1775-1.7029-11.9624-1.0428.1484-1.5645.2206-2.6082.3609l-.2718-2.0218c3.1281-.4205,4.69-.6492,7.81-1.1434.1277.806.1915,1.209.3192,2.015C481.1828,822.7681,480.6579,822.8491,479.6078,823.007Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M55.0429,273.6017l-13.1563-5.1351c1.0442-2.6752,1.58-4.0071,2.6777-6.6584l1.9777.8191c-.6764,1.6334-1.01,2.4525-1.6655,4.0952l3.0835,1.2313c.6129-1.5349.9239-2.3,1.5549-3.8271l1.9783.8176c-.627,1.5172-.9361,2.278-1.5452,3.8033l4.0314,1.61c.6675-1.6716,1.0067-2.505,1.6961-4.1669l2.0143.8356C56.6038,269.6441,56.0744,270.9594,55.0429,273.6017Z" />
                                        <path className="st1" d="M60.2939,260.9191c-4.2809-.7774-6.426-1.1508-10.7249-1.8667,3.9257,1.5855,5.888,2.38,9.8117,3.9705-.388.9023-.58,1.3541-.9618,2.2594l-13.0148-5.4845c.544-1.291.8192-1.935,1.3759-3.22,4.2267.67,6.3356,1.0209,10.5444,1.7524-3.8336-1.58-5.7509-2.3683-9.587-3.9423.4028-.9127.6058-1.3683,1.015-2.278,5.1521,2.3177,7.7278,3.4763,12.88,5.7942C61.0912,259.1072,60.8235,259.71,60.2939,260.9191Z" />
                                        <path className="st1" d="M64.6372,251.41c-.4525.9512-.6769,1.4278-1.1219,2.3827-5.5963-1.3664-8.402-2.027-14.0289-3.3032.48-1.0525.7219-1.5776,1.21-2.6259,3.6313,1.0543,5.4442,1.5887,9.0644,2.6719-3.1581-2.0644-4.7337-3.1028-7.8778-5.1922.4786-1.0062.72-1.5084,1.2065-2.5108C57.6855,246.3018,59.9948,248.0172,64.6372,251.41Z" />
                                        <path className="st1" d="M66.553,247.449,53.88,241.2162c.4768-.97.7171-1.4534,1.2014-2.4193,5.05,2.5322,7.5748,3.7979,12.625,6.33C67.2412,246.054,67.0106,246.5184,66.553,247.449Z" />
                                        <path className="st1" d="M71.4683,237.8458c-.09-.0022-.1611.0053-.2506.0033a2.56,2.56,0,0,1-1.13-.3039l-2.8448-1.51c-.194-.103-.39-.2095-.61-.3006-.7177-.2976-1.1653.1459-1.489.7617-.2361.4492-.3537.674-.5881,1.1241l5.518,2.8742c-.4826.9266-.7222,1.3908-1.1976,2.3212L56.3,236.3887c.9908-1.9388,1.4937-2.9043,2.5143-4.8271a2.8345,2.8345,0,0,1,1.2534-1.37c.9617-.4794,1.8145.0071,2.6237.4444l1.3376.7231a3.46,3.46,0,0,1,1.2009.9762,1.6974,1.6974,0,0,1,.188.4518,2.7427,2.7427,0,0,1-.1607,1.3583,2.9529,2.9529,0,0,1,.6246-.5732,2.3117,2.3117,0,0,1,2.4721.0088l2.5331,1.37a3.2139,3.2139,0,0,0,1.6335.472.8624.8624,0,0,0,.308-.1068C72.28,236.3256,72.0081,236.8315,71.4683,237.8458ZM61.6044,233.09c-.477-.2531-.69-.2718-.9767-.1654-.34.1264-.4665.4123-.6314.7256-.2635.5011-.3948.7518-.6563,1.2539l3.4236,1.7834c.2587-.4966.3885-.7446.6491-1.24a1.2733,1.2733,0,0,0,.1707-1.0512c-.1176-.2905-.2822-.4059-.7773-.6686Z" />
                                        <path className="st1" d="M76.4931,227.463a4.3978,4.3978,0,0,1-.7086,3.1361,4.6046,4.6046,0,0,1-2.1572,2.1775,2.3028,2.3028,0,0,1-1.111.192,3.8056,3.8056,0,0,1-1.5656-.5153l-6.9428-3.8146a3.2894,3.2894,0,0,1-1.8006-2.0377,4.8734,4.8734,0,0,1,.7685-3.2608c1.0509-1.86,2.3483-2.4189,3.2775-2.3866a3.7651,3.7651,0,0,1,1.794.6556l6.7994,3.9451A3.0471,3.0471,0,0,1,76.4931,227.463Zm-9.6437-3.41a2.3893,2.3893,0,0,0-1.0731-.4205,1.1492,1.1492,0,0,0-1.0939.672,1.5343,1.5343,0,0,0-.2018.8895c.0527.7218.6352.9516,1.1414,1.235l6.2319,3.4876a2.2314,2.2314,0,0,0,1.2535.424,1.3736,1.3736,0,0,0,.8909-.7271,1.56,1.56,0,0,0,.1991-.8389c-.0561-.5894-.5526-.85-1.004-1.1068Z" />
                                        <path className="st1" d="M81.1432,220.875c-4.1374-1.3447-6.2125-2.0026-10.3759-3.2891,3.678,2.0968,5.5161,3.1465,9.1915,5.2483-.5043.8406-.755,1.2619-1.2531,2.1061-4.8656-2.8711-7.2978-4.3063-12.1633-7.1771.71-1.2039,1.0686-1.8042,1.791-3.0011,4.0982,1.2316,6.1407,1.8619,10.2127,3.1514-3.5874-2.0788-5.3818-3.1167-8.9727-5.19.52-.8493.7823-1.273,1.3089-2.1187,4.7957,2.986,7.1929,4.479,11.9885,7.4654C82.1743,219.1888,81.8287,219.75,81.1432,220.875Z" />
                                        <path className="st1" d="M89.2775,208.1728c-4.01-2.8126-6.0137-4.22-10.02-7.038,3.5313,3.5228,5.3074,5.2714,8.8811,8.7427-.5377.81-.8051,1.2163-1.3369,2.03-4.6139-1.8914-6.9285-2.8217-11.5739-4.6516,4.1632,2.5794,6.2438,3.87,10.4039,6.4551-.557.8646-.8339,1.298-1.3842,2.1668-4.7725-3.0232-7.1582-4.5346-11.9308-7.5574.95-1.4995,1.43-2.2463,2.3985-3.7338,3.6916,1.5748,5.5325,2.3709,9.2052,3.98-2.9254-2.7448-4.3818-4.1248-7.2826-6.9006.97-1.4571,1.4593-2.1827,2.4475-3.6278,4.6635,3.1884,6.9947,4.7827,11.6582,7.9716C90.1538,206.8733,89.8606,207.3057,89.2775,208.1728Z" />
                                        <path className="st1" d="M92.2128,203.88c-4.6388-3.2247-6.9577-4.8369-11.5966-8.061,1.6353-2.3526,2.4652-3.5208,4.149-5.8406l1.7324,1.2574c-1.0374,1.4292-1.5515,2.147-2.57,3.5888l2.7115,1.9162c.9521-1.3472,1.4322-2.018,2.4-3.3541l1.7333,1.2562c-.9624,1.3278-1.4395,1.9945-2.3857,3.3333l3.5449,2.5053c1.037-1.4674,1.56-2.1978,2.6172-3.6518l1.764,1.2821C94.6483,200.402,93.8281,201.5558,92.2128,203.88Z" />
                                        <path className="st1" d="M100.2669,192.7825c-3.9819-1.7536-5.9807-2.6168-9.9942-4.3152,3.4477,2.4571,5.1707,3.6869,8.6153,6.1487-.5872.7865-.8794,1.1809-1.4609,1.9715-4.5513-3.347-6.8264-5.02-11.3777-8.367.8294-1.1278,1.2469-1.69,2.0877-2.8094,3.9541,1.6369,5.9233,2.4693,9.8458,4.1613-3.36-2.43-5.0406-3.6431-8.4044-6.0673.6047-.794.9085-1.19,1.519-1.98,4.47,3.4544,6.7045,5.1818,11.1745,8.6367C101.4647,191.2063,101.0637,191.7305,100.2669,192.7825Z" />
                                        <path className="st1" d="M97.1292,177.1764c3.7629,3.0331,5.6441,4.55,9.4072,7.5832-.6622.8215-.9916,1.2334-1.6472,2.06-3.7867-3.0037-5.68-4.5057-9.4663-7.5092-.6741.85-1.0094,1.2759-1.6766,2.13l-1.6081-1.2552c2.0237-2.5923,3.0513-3.8772,5.137-6.4235l1.5782,1.2926C98.16,175.9008,97.8154,176.3251,97.1292,177.1764Z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M741.6427,180.5046c4.3989-3.5447,6.5983-5.317,10.9968-8.8616,1.802,2.2362,2.6912,3.363,4.4456,5.6339l-1.6939,1.3087c-1.0809-1.3991-1.6259-2.0954-2.7251-3.4812l-2.6014,2.0631c1.027,1.295,1.5364,1.9454,2.5474,3.2519l-1.693,1.31c-1.0047-1.2985-1.511-1.9449-2.5316-3.2317l-3.4011,2.6973c1.1186,1.4105,1.6731,2.1193,2.7723,3.5438l-1.7265,1.3322C744.3005,183.827,743.4225,182.7135,741.6427,180.5046Z" />
                                        <path className="st1" d="M750.036,191.3668c2.8029-3.3278,4.1933-5.0034,6.9519-8.3774-3.3275,2.6178-4.9921,3.9256-8.3232,6.5391-.59-.7854-.8864-1.1771-1.4823-1.9582l11.2295-8.5652c.85,1.1139,1.2716,1.6729,2.11,2.7948-2.6831,3.3341-4.0357,4.99-6.7629,8.2778,3.2768-2.5406,4.9142-3.8121,8.1871-6.3578.5916.8032.8858,1.2057,1.4715,2.0129-4.5725,3.3177-6.8584,4.9764-11.4312,8.2942C751.2114,192.9592,750.8214,192.4272,750.036,191.3668Z" />
                                        <path className="st1" d="M756.1268,199.863c-.6-.8654-.9021-1.297-1.5092-2.1578,3.9679-4.1762,5.936-6.2822,9.841-10.53.6745.94,1.01,1.4108,1.6765,2.3558-2.72,2.6264-4.0853,3.9336-6.8252,6.536,3.3614-1.7136,5.0458-2.5643,8.4219-4.2534.6352.9157.9509,1.3749,1.5787,2.2956C764.0153,196.3714,761.3787,197.522,756.1268,199.863Z" />
                                        <path className="st1" d="M758.6108,203.4944c4.6839-3.1588,7.0253-4.738,11.7091-7.8969.6042.896.9046,1.3452,1.5019,2.2457l-11.77,7.8065C759.4791,204.7855,759.1907,204.3544,758.6108,203.4944Z" />
                                        <path className="st1" d="M764.4988,212.534c.0464-.0767.0755-.1425.1218-.2191a2.5674,2.5674,0,0,1,.8259-.8292l2.7246-1.7174c.1858-.1172.3755-.2345.5638-.38.6153-.4758.4528-1.0837.08-1.67-.2724-.4281-.4089-.642-.6828-1.0691l-5.2375,3.3585c-.5639-.8795-.8475-1.3182-1.4183-2.1932l11.8291-7.7157c1.1895,1.8237,1.7771,2.74,2.9379,4.5816a2.8339,2.8339,0,0,1,.5658,1.7688c-.0622,1.0728-.9085,1.57-1.6905,2.055l-1.2923.8011a3.4586,3.4586,0,0,1-1.4437.557,1.7068,1.7068,0,0,1-.4859-.062,2.7375,2.7375,0,0,1-1.0985-.8147,2.9488,2.9488,0,0,1,.1867.8268,2.3124,2.3124,0,0,1-1.2365,2.141c-.9794.6066-1.4689.91-2.4483,1.5164a3.2138,3.2138,0,0,0-1.2217,1.1827.86.86,0,0,0-.0608.32C765.4141,213.9942,765.1105,213.5067,764.4988,212.534Zm9.0314-6.193c.4568-.288.5789-.4642.6291-.7651a1.2564,1.2564,0,0,0-.3156-.9087c-.3037-.4777-.4559-.7163-.7615-1.1928-1.3.8335-1.95,1.25-3.25,2.0838.3022.4712.4529.7072.7531,1.18a1.2729,1.2729,0,0,0,.8272.6707c.31.0421.4924-.0431.9666-.342Z" />
                                        <path className="st1" d="M771.0089,222.056a4.3977,4.3977,0,0,1-2.3685-2.1736,4.603,4.603,0,0,1-.8165-2.9543,2.3009,2.3009,0,0,1,.3858-1.06,3.7993,3.7993,0,0,1,1.2255-1.1021l6.7616-4.1272a3.2876,3.2876,0,0,1,2.6633-.549,4.87,4.87,0,0,1,2.4467,2.2876c1.0918,1.8372.9312,3.24.4412,4.03a3.7586,3.7586,0,0,1-1.4605,1.2311l-6.8037,3.9378A3.0455,3.0455,0,0,1,771.0089,222.056Zm7.7537-6.6715a2.39,2.39,0,0,0,.8987-.7216,1.15,1.15,0,0,0-.0394-1.2836,1.5361,1.5361,0,0,0-.6715-.6175c-.6524-.3131-1.1415.078-1.6389.3764l-6.1246,3.6728a2.2352,2.2352,0,0,0-.9908.8772,1.3738,1.3738,0,0,0,.1876,1.1341,1.5587,1.5587,0,0,0,.6289.59c.5395.2448,1.0118-.0571,1.4594-.3208Z" />
                                        <path className="st1" d="M774.4128,229.3662c3.224-2.9209,4.8265-4.3942,8.0129-7.3666-3.6481,2.1483-5.4727,3.2212-9.1239,5.3648-.4785-.8553-.7193-1.2821-1.204-2.1339,4.91-2.794,7.3648-4.1906,12.2748-6.9848.6913,1.215,1.034,1.8243,1.7133,3.0462-3.1063,2.9431-4.6688,4.4018-7.8123,7.2935,3.5874-2.0789,5.38-3.12,8.9643-5.2045.4781.874.7157,1.312,1.1876,2.1894-4.9752,2.6763-7.4625,4.0139-12.438,6.69C775.3632,231.0994,775.0483,230.5206,774.4128,229.3662Z" />
                                        <path className="st1" d="M781.3892,242.7394c4.434-2.0808,6.6516-3.1194,11.0883-5.1941-4.8123,1.3123-7.2125,1.9839-12.0012,3.3586-.4355-.8692-.6549-1.3031-1.0967-2.169,3.935-3.0629,5.893-4.6087,9.79-7.73-4.3079,2.33-6.4624,3.4932-10.7733,5.8177-.4733-.9132-.7116-1.3689-1.1918-2.2784,4.996-2.6375,7.4935-3.956,12.4891-6.5939.8288,1.57,1.2382,2.3572,2.0471,3.9375-3.2019,2.42-4.8079,3.6213-8.0306,6.0076,3.8359-1.1735,5.7575-1.7508,9.6082-2.8876.7819,1.5657,1.168,2.3511,1.93,3.9268-5.0851,2.461-7.6274,3.6909-12.7129,6.1514C782.081,244.1459,781.8516,243.6765,781.3892,242.7394Z" />
                                        <path className="st1" d="M783.6545,247.4207c5.1044-2.4214,7.6561-3.6318,12.76-6.0535,1.2281,2.5886,1.8291,3.8895,3.0045,6.5038l-1.9523.8779c-.7242-1.6107-1.0914-2.4136-1.8357-4.0146l-3.0108,1.4c.6955,1.4959,1.0389,2.2459,1.7167,3.75l-1.9518.8793c-.6736-1.4952-1.0147-2.2406-1.7059-3.7273l-3.9362,1.83c.7575,1.6294,1.131,2.4468,1.867,4.0865-.7957.3572-1.1936.5359-1.99.893C785.461,251.263,784.8677,249.9777,783.6545,247.4207Z" />
                                        <path className="st1" d="M789.2783,259.9264c3.5013-2.5829,5.244-3.888,8.7131-6.5258-3.8461,1.77-5.77,2.6531-9.6184,4.4178-.39-.9007-.5872-1.35-.9841-2.2478,5.1669-2.2847,7.75-3.4266,12.9166-5.7115.5661,1.28.8461,1.922,1.3995,3.2082-3.3863,2.6168-5.0876,3.9115-8.506,6.4735,3.7786-1.7071,5.6672-2.562,9.443-4.2753.3882.9195.5808,1.38.9626,2.3024-5.22,2.1607-7.83,3.2406-13.0495,5.4012C790.05,261.7493,789.7949,261.1408,789.2783,259.9264Z" />
                                        <path className="st1" d="M804.3787,264.9635c-4.5025,1.7567-6.7539,2.6348-11.2568,4.3915-.3834-.9829-.577-1.4737-.9681-2.4533,4.4889-1.792,6.7333-2.6877,11.2219-4.48-.4021-1.0073-.6051-1.51-1.0149-2.514l1.8887-.7711c1.2429,3.0448,1.8469,4.5751,3.0191,7.6508l-1.9062.7266C804.9728,266.4923,804.7761,265.9822,804.3787,264.9635Z" />
                                    </g>
                                </>}
                            {SelectedLanguage === "de-DE" &&
                                <>
                                    <g>
                                        <path className="st1" d="M61.91,246l1.74,0.95l0.75,0.56l0.57,0.7l0.36,0.73l0.08,0.88l-0.1,1.08l-0.5,1.22L64,253.51l-0.9,0.76
			l-1.18,0.38l-0.89,0.06l-0.8-0.19l-1.56-0.68l-8.58-4.13l1.23-2.56l7.62,3.67l1.67,0.78l1.08,0.34l0.5-0.11l0.33-0.22l0.34-0.48
			l0.13-0.49l-0.16-0.77l-0.58-0.46l-0.97-0.56l-1.15-0.55l-7.59-3.64l1.25-2.59L61.91,246z"/>
                                        <path className="st1" d="M72.26,236.96l-5.17-2.71l-3.3-1.75l-1.76-1.08l0.79,0.57l1.24,0.95l1.48,1.14l5.75,4.77l-1.27,2.38
			l-7.02-1.9l-2.3-0.78l-1.47-0.48l-1.01-0.4l1.24,0.61l2.54,1.15l7.05,3.74l-1.26,2.37l-12.53-6.47l1.92-3.71l7.79,2.25l1.95,0.59
			l1.35,0.57l-1.48-0.98l-1.06-0.78l-6.62-5.3l1.92-3.67l12.53,6.52L72.26,236.96z"/>
                                        <path className="st1" d="M78.43,225.9l-6.93-2.44l-2.8-1.26l1.83,1.24l1.39,1.07l4.97,4.11l-1.58,2.88l-13.56-4.72l1.38-2.44
			l8.13,3.01l1.01,0.45l1.12,0.58l-1.62-1.04l-1.58-1.2l-5.54-4.53l1.49-2.72l7.01,2.59l2.03,0.88l1.21,0.63l-1.87-1.25l-6.97-5.37
			l1.3-2.34l11.15,9.02L78.43,225.9z"/>
                                        <path className="st1" d="M82.07,219.67l-12.05-7.35l3.46-5.69l2.19,1.33l-1.97,3.23l2.6,1.58l1.76-2.89l2.13,1.3l-1.76,2.89l2.94,1.79
			l1.97-3.23l2.2,1.34L82.07,219.67z"/>
                                        <path className="st1" d="M86.88,211.84L75,204.22l1.53-2.39l9.76,6.26l2.19-3.42l2.12,1.36L86.88,211.84z" />
                                        <path className="st1" d="M91.19,205.16l-9.75-6.46l-1.28,1.93l-2.02-1.34l4.14-6.25l2.02,1.34l-1.28,1.93l9.75,6.46L91.19,205.16z" />
                                    </g>
                                    <g>
                                        <path className="st1" d="M762.16,200.21l-1.69,1.03l-0.86,0.37l-0.89,0.15l-0.81-0.05l-0.81-0.37l-0.89-0.63l-0.81-1.05l-0.79-1.4
			l-0.21-1.16l0.26-1.22l0.4-0.8l0.56-0.6l1.37-1.01l7.87-5.36l1.6,2.35l-6.99,4.76l-1.51,1.05l-0.84,0.76l-0.15,0.49l0.03,0.39
			l0.24,0.53l0.36,0.36l0.75,0.24l0.69-0.27l0.97-0.56l1.06-0.72l6.95-4.76l1.62,2.38L762.16,200.21z"/>
                                        <path className="st1" d="M764.81,213.69l4.93-3.13l3.17-1.99l1.82-0.98l-0.89,0.39l-1.44,0.6l-1.73,0.72l-7,2.59l-1.43-2.29l5.15-5.14
			l1.82-1.61l1.15-1.03l0.85-0.68l-1.14,0.77l-2.26,1.62l-6.76,4.24l-1.42-2.27l11.87-7.62l2.25,3.52l-5.84,5.62l-1.49,1.39
			l-1.17,0.88l1.58-0.79l1.21-0.53l7.9-3.09l2.22,3.5L766.25,216L764.81,213.69z"/>
                                        <path className="st1" d="M771.3,224.57l5.58-4.78l2.49-1.8l-1.99,0.96l-1.63,0.67l-6.05,2.25l-1.7-2.8l10.87-9.38l1.42,2.41
			l-6.67,5.53l-0.89,0.65l-1.06,0.68l1.71-0.88l1.83-0.77l6.69-2.54l1.61,2.65l-5.75,4.77l-1.77,1.32l-1.15,0.73l2.02-1l8.13-3.36
			l1.38,2.3l-13.38,5.15L771.3,224.57z"/>
                                        <path className="st1" d="M774.88,230.84l12.39-6.77l3.19,5.84l-2.25,1.23l-1.81-3.32l-2.67,1.46l1.62,2.97l-2.19,1.2l-1.62-2.97
			l-3.02,1.65l1.81,3.32l-2.26,1.24L774.88,230.84z"/>
                                        <path className="st1" d="M779.25,238.91l12.54-6.48l1.3,2.52l-10.3,5.32l1.86,3.6l-2.24,1.16L779.25,238.91z" />
                                        <path className="st1" d="M782.88,245.99l10.47-5.21l-1.03-2.08l2.17-1.08l3.34,6.71l-2.17,1.08l-1.03-2.08l-10.47,5.21L782.88,245.99z
			"/>
                                    </g>
                                    <g>
                                        <path className="st1" d="M405.45,833.46l-0.21,1.97l-0.23,0.91l-0.43,0.8l-0.54,0.61l-0.79,0.42l-1.04,0.32l-1.32,0l-1.59-0.23
			l-1.05-0.54l-0.81-0.95l-0.39-0.8l-0.13-0.81l0.04-1.7l0.55-9.51l2.84,0.17l-0.49,8.45l-0.09,1.84l0.09,1.13l0.3,0.42l0.33,0.22
			l0.57,0.13l0.51-0.07l0.65-0.44l0.2-0.71l0.14-1.11l0.07-1.28l0.47-8.41l2.88,0.17L405.45,833.46z"/>
                                        <path className="st1" d="M418.16,838.93l0.15-5.84l0.11-3.74l0.19-2.06l-0.16,0.96l-0.3,1.53l-0.37,1.83l-1.74,7.26l-2.7-0.09
			l-1.4-7.14l-0.32-2.41l-0.22-1.53l-0.09-1.08l0.01,1.38l0.09,2.78l-0.24,7.97l-2.68-0.09l0.27-14.09l4.18,0.08l1.42,7.98
			l0.33,2.01l0.08,1.46l0.22-1.75l0.23-1.29l1.83-8.27l4.14,0.1l-0.32,14.11L418.16,838.93z"/>
                                        <path className="st1" d="M431.35,838.96l-1.36-7.22l-0.31-3.06l-0.17,2.2l-0.23,1.74l-1.08,6.36l-3.28,0.07l-2.69-14.1l2.8-0.02
			l1.46,8.54l0.12,1.1l0.06,1.26l0.09-1.92l0.25-1.96l1.15-7.06l3.1-0.07l1.26,7.36l0.26,2.2l0.06,1.36l0.14-2.24l1.16-8.72
			l2.68-0.04l-2.23,14.16L431.35,838.96z"/>
                                        <path className="st1" d="M438.82,838.78l-0.56-14.11l6.66-0.26l0.1,2.56l-3.78,0.15l0.12,3.04l3.38-0.13l0.1,2.5l-3.38,0.13l0.14,3.44
			l3.78-0.15l0.1,2.58L438.82,838.78z"/>
                                        <path className="st1" d="M448.28,838.37l-0.88-14.09l2.83-0.18l0.73,11.58l4.05-0.25l0.16,2.51L448.28,838.37z" />
                                        <path className="st1" d="M456.39,837.83l-0.91-11.67l-2.31,0.18l-0.19-2.41l7.48-0.58l0.19,2.41l-2.31,0.18l0.91,11.67L456.39,837.83z
			"/>
                                    </g>
                                </>}
                        </g>
                        <g id="Inner_Ellipses">
                            <g id="Background_-_2">
                                <path id="Form_1_" className="st4" d="M581.43,382.55c-14.37-8.29-28.79-15.53-43.05-21.63c2.02-16.04,3.05-32.86,3.05-50.21
			c0-109.26-40.63-197.91-115.29-198.65v-0.02c-0.16,0-0.31,0.01-0.47,0.01c-0.16,0-0.31-0.01-0.47-0.01v0.02
			C350.54,112.8,309.9,201.44,309.9,310.7c0,16.87,0.97,33.25,2.89,48.9c-14.57,6.18-29.32,13.56-44,22.04
			c-94.62,54.63-151.07,134.14-114.39,199.17l-0.02,0.01c0.08,0.14,0.16,0.27,0.24,0.4c0.08,0.14,0.15,0.27,0.23,0.41l0.02-0.01
			c37.97,64.28,135.06,55.15,229.68,0.52c14.25-8.23,27.62-17.02,39.95-26.24c12.66,9.55,26.45,18.65,41.17,27.15
			c94.62,54.63,191.71,63.76,229.68-0.52l0.02,0.01c0.08-0.14,0.15-0.27,0.23-0.41c0.08-0.14,0.16-0.27,0.24-0.4l-0.02-0.01
			C732.5,516.69,676.05,437.18,581.43,382.55z"/>
                            </g>
                            <g id="Background_-_1">
                                <path id="Form" className="st5" d="M581.43,382.55c-14.37-8.29-28.79-15.53-43.05-21.63c2.02-16.04,3.05-32.86,3.05-50.21
			c0-109.26-40.63-197.91-115.29-198.65v-0.02c-0.16,0-0.31,0.01-0.47,0.01c-0.16,0-0.31-0.01-0.47-0.01v0.02
			C350.54,112.8,309.9,201.44,309.9,310.7c0,16.87,0.97,33.25,2.89,48.9c-14.57,6.18-29.32,13.56-44,22.04
			c-94.62,54.63-151.07,134.14-114.39,199.17l-0.02,0.01c0.08,0.14,0.16,0.27,0.24,0.4c0.08,0.14,0.15,0.27,0.23,0.41l0.02-0.01
			c37.97,64.28,135.06,55.15,229.68,0.52c14.25-8.23,27.62-17.02,39.95-26.24c12.66,9.55,26.45,18.65,41.17,27.15
			c94.62,54.63,191.71,63.76,229.68-0.52l0.02,0.01c0.08-0.14,0.15-0.27,0.23-0.41c0.08-0.14,0.16-0.27,0.24-0.4l-0.02-0.01
			C732.5,516.69,676.05,437.18,581.43,382.55z"/>
                            </g>
                        </g>
                        <g id="Outer_Ellipses">
                            <g id="Background_1_">
                                <path className="st6" d="M425.57,697.69c137.46,0,251.94-42.53,277.2-121.44c38.98-64.96,16.51-174.8-46.25-283.5
			c-67-116.05-158.08-192.99-237.64-180.5c-75.3-0.14-158.39,73.94-220.68,181.82c-58.87,101.97-82.29,204.95-52.78,270.94
			C163.03,651.12,281.73,697.69,425.57,697.69z M154.87,581.63l-0.02,0.01c-0.08-0.14-0.15-0.27-0.23-0.41
			c-0.08-0.14-0.16-0.27-0.24-0.4l0.02-0.01c-36.69-65.03,19.77-144.54,114.39-199.17c14.69-8.48,29.43-15.86,44-22.04
			c-1.91-15.64-2.89-32.02-2.89-48.9c0-109.26,40.63-197.91,115.29-198.65v-0.02c0.16,0,0.31,0.01,0.47,0.01
			c0.16,0,0.31-0.01,0.47-0.01v0.02c74.66,0.74,115.29,89.39,115.29,198.65c0,17.35-1.03,34.17-3.05,50.21
			c14.26,6.1,28.69,13.34,43.05,21.63c94.62,54.63,151.07,134.14,114.39,199.17l0.02,0.01c-0.08,0.14-0.16,0.27-0.24,0.4
			c-0.08,0.14-0.15,0.27-0.23,0.41l-0.02-0.01c-37.97,64.28-135.06,55.15-229.68,0.52c-14.72-8.5-28.51-17.59-41.17-27.15
			c-12.33,9.22-25.7,18.01-39.95,26.24C289.93,636.78,192.84,645.91,154.87,581.63z"/>
                            </g>
                            <path id="Border_-_3_2_" className="st7" d="M189.86,614.84l-0.01,0.02c0.18,0.11,0.37,0.2,0.55,0.3c0.18,0.11,0.36,0.22,0.54,0.32
		l0.01-0.02c88.7,37.96,197.49-32.21,275.15-166.72s84.92-271.41,7.8-321.59l0.01-0.02c-0.18-0.11-0.37-0.2-0.55-0.3
		c-0.18-0.11-0.36-0.22-0.54-0.32l-0.01,0.02"/>
                            <path id="Border_-_2_3_" className="st7" d="M706.17,543.65h0.02c0-0.21-0.01-0.42-0.01-0.63c0-0.21,0.01-0.42,0.01-0.63h-0.02
		c-1.05-99.75-125.27-154.04-280.59-154.04c-155.32,0-281.35,54.29-282.4,154.04h-0.02c0,0.21,0.01,0.42,0.01,0.63
		c0,0.21-0.01,0.42-0.01,0.63h0.02"/>
                            <path id="Border_-_1_2_" className="st7" d="M381.92,125.2l-0.01-0.02c-0.18,0.11-0.36,0.22-0.54,0.32c-0.18,0.1-0.37,0.2-0.55,0.3
		l0.01,0.02c-81.7,50.94-69.86,187.07,7.8,321.59S574.9,656.06,662.01,613.81l0.01,0.02c0.18-0.11,0.36-0.22,0.54-0.32
		c0.18-0.1,0.37-0.2,0.55-0.3l-0.01-0.02"/>
                        </g>
                        <g id="Inner_Circle_-_4" className={"model-option" + (this.state.changed ? this.setStyles(6) : '')}
                            onMouseEnter={() => this.hoverPie(6)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 6)}>
                            <circle id="Circle_6_" className="st8" cx="228.13" cy="538.55" r="85.03" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st1" d="M193.7368,543.1888c-.32,1.1407-1.26,2.1612-3.6606,2.1612a4.573,4.573,0,0,1-2.9009-.8008,2.3968,2.3968,0,0,1-.74-.96,3.1148,3.1148,0,0,1-.28-1.46v-2.0606h2.5805v1.64c0,.52-.04,1.12.46,1.44a1.4343,1.4343,0,0,0,.8.2,1.2677,1.2677,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8808a1.8041,1.8041,0,0,0-.88-1.86l-2.46-1.66a3.2784,3.2784,0,0,1-1.18-1.16,3.8708,3.8708,0,0,1-.5-2.24,4.7528,4.7528,0,0,1,.34-1.9405c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.68,0,3.42,1.86a2.4069,2.4069,0,0,1,.22,1.1807v1.4h-2.6006v-1.44c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6457,2.6457,0,0,0,.28,1.36,2.3507,2.3507,0,0,0,.54.46l2.52,1.74a5.2849,5.2849,0,0,1,1.1607,1.1006,3.3628,3.3628,0,0,1,.56,2.44A7.9592,7.9592,0,0,1,193.7368,543.1888Z" transform="translate(0 0)" />
                                    <path className="st1" d="M200.396,532.9672v12.082h-2.6206v-12.082h-2.6006v-2.0411h7.8418v2.0411Z" transform="translate(0 0)" />
                                    <path className="st1" d="M210.2163,545.0492c-.04-.08-.08-.14-.12-.22a2.5627,2.5627,0,0,1-.26-1.1407v-3.2207c0-.22,0-.4394-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24v6.2207h-2.6006V530.9261h5.2011a2.6463,2.6463,0,0,1,1.7207.46,2.7939,2.7939,0,0,1,.82,2.52v1.52a3.5392,3.5392,0,0,1-.28,1.52,1.6633,1.6633,0,0,1-.3.38,2.6,2.6,0,0,1-1.24.5,2.85,2.85,0,0,1,.78.28,2.3419,2.3419,0,0,1,1.14,2.1807v2.8808a3.217,3.217,0,0,0,.36,1.66.8582.8582,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.9394a1.2031,1.2031,0,0,0-.9-.2207h-1.36v3.8613h1.36a1.1929,1.1929,0,0,0,.9805-.3408c.2-.2393.22-.44.22-1Z" transform="translate(0 0)" />
                                    <path className="st1" d="M221.7764,544.1293a4.0848,4.0848,0,0,1-3.2608,1.2207,3.8327,3.8327,0,0,1-2.8808-1.02,2.8386,2.8386,0,0,1-.6-.96,4.2644,4.2644,0,0,1-.26-1.5605V530.9261h2.6006v10.503a2.87,2.87,0,0,0,.14,1.1,1.15,1.15,0,0,0,1.08.6006,1.2607,1.2607,0,0,0,.76-.2207c.5-.34.44-1,.44-1.5V530.9261h2.6206v10.9424A3.3252,3.3252,0,0,1,221.7764,544.1293Z" transform="translate(0 0)" />
                                    <path className="st1" d="M232.3555,543.4291c-.68,1.96-3.3008,1.9209-3.6011,1.9209a6.8636,6.8636,0,0,1-1.34-.1406,2.9471,2.9471,0,0,1-2.3809-1.6807,3.79,3.79,0,0,1-.2-1.4795v-7.6816c0-.24.02-.501.04-.74a2.6913,2.6913,0,0,1,1-2.1806,4.2991,4.2991,0,0,1,2.92-.82,4.707,4.707,0,0,1,2.6407.6407,2.4625,2.4625,0,0,1,.88,1.04,3.1137,3.1137,0,0,1,.24,1.6v2.1611h-2.5606v-1.78c0-1.0811-.18-1.6612-1.34-1.6612-1,0-1.2.62-1.2,1.5411v7.5615c0,.92.2,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7412v-1.84h2.5606v2A5.2359,5.2359,0,0,1,232.3555,543.4291Z" transform="translate(0 0)" />
                                    <path className="st1" d="M238.9546,532.9672v12.082H236.334v-12.082h-2.6006v-2.0411h7.8418v2.0411Z" transform="translate(0 0)" />
                                    <path className="st1" d="M250.1753,544.1293a4.0842,4.0842,0,0,1-3.2607,1.2207,3.8334,3.8334,0,0,1-2.8809-1.02,2.8417,2.8417,0,0,1-.6-.96,4.2644,4.2644,0,0,1-.26-1.5605V530.9261h2.6006v10.503a2.8587,2.8587,0,0,0,.1407,1.1,1.1487,1.1487,0,0,0,1.08.6006,1.2594,1.2594,0,0,0,.76-.2207c.5-.34.44-1,.44-1.5V530.9261h2.62v10.9424A3.3269,3.3269,0,0,1,250.1753,544.1293Z" transform="translate(0 0)" />
                                    <path className="st1" d="M258.9341,545.0492c-.04-.08-.08-.14-.12-.22a2.571,2.571,0,0,1-.26-1.1407v-3.2207c0-.22,0-.4394-.0195-.68-.08-.78-.66-.96-1.3409-.96h-1.24v6.2207H253.353V530.9261h5.2012a2.6465,2.6465,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52v1.52a3.542,3.542,0,0,1-.28,1.52,1.6513,1.6513,0,0,1-.3.38,2.6034,2.6034,0,0,1-1.24.5,2.8418,2.8418,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1807v2.8808a3.2175,3.2175,0,0,0,.36,1.66.845.845,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.9394a1.2036,1.2036,0,0,0-.9-.2207h-1.36v3.8613h1.36a1.1932,1.1932,0,0,0,.98-.3408c.2-.2393.22-.44.22-1Z" transform="translate(0 0)" />
                                    <path className="st1" d="M263.5132,545.0492V530.9261h6.8418v2.1407h-4.2412v3.3213h4.0009v2.14h-4.0009v4.3408h4.4414v2.1807Z" transform="translate(0 0)" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st1" d="M194.88,535.13l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L194.88,535.13z"/>
                                    <path className="st1" d="M201.11,545.19v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H201.11z" />
                                    <path className="st1" d="M213.6,545.19l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H213.6z M212.36,537.13l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			l-1.1-0.12h-0.92v3.66H212.36z"/>
                                    <path className="st1" d="M226.56,540.11l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V540.11z"/>
                                    <path className="st1" d="M235.16,545.19l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H235.16z" />
                                    <path className="st1" d="M240.63,545.19v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H240.63z" />
                                    <path className="st1" d="M255.68,540.11l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V540.11z"/>
                                    <path className="st1" d="M263.94,545.19l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H263.94z M262.7,537.13l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			l-1.1-0.12h-0.92v3.66H262.7z"/>
                                </g>
                            }
                        </g>
                        <g id="Inner_Circle_-_3_1_" className={"model-option" + (this.state.changed ? this.setStyles(5) : '')}
                            onMouseEnter={() => this.hoverPie(5)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 5)}>
                            <circle id="Circle_5_" className="st9" cx="621.14" cy="538.99" r="85.03" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st1" d="M597.6191,543.5658c-.68,1.96-3.3007,1.92-3.6005,1.92a6.8846,6.8846,0,0,1-1.34-.14,2.9456,2.9456,0,0,1-2.3808-1.6807,3.7866,3.7866,0,0,1-.2-1.4805v-7.6816c0-.24.02-.5.04-.74a2.6943,2.6943,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.9209-.8193,4.7068,4.7068,0,0,1,2.6406.64,2.4621,2.4621,0,0,1,.88,1.04,3.1108,3.1108,0,0,1,.24,1.6006v2.16h-2.5605v-1.78c0-1.08-.18-1.66-1.34-1.66-1.0009,0-1.2.62-1.2,1.54v7.5615c0,.9209.1992,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.74v-1.8408h2.5605v2.0009A5.2225,5.2225,0,0,1,597.6191,543.5658Z" />
                                    <path className="st1" d="M606.94,544.265a4.0816,4.0816,0,0,1-3.2608,1.2207,3.8274,3.8274,0,0,1-2.8808-1.02,2.8154,2.8154,0,0,1-.6006-.96,4.26,4.26,0,0,1-.26-1.56V531.0629h2.6006v10.5019a2.8871,2.8871,0,0,0,.14,1.1006,1.15,1.15,0,0,0,1.0811.6,1.2654,1.2654,0,0,0,.76-.22c.5-.34.44-1,.44-1.5V531.0629h2.62v10.9423A3.3261,3.3261,0,0,1,606.94,544.265Z" />
                                    <path className="st1" d="M610.1172,545.1859v-14.123h2.6006v11.9824h3.82v2.1406Z" />
                                    <path className="st1" d="M621.7383,533.1029v12.083h-2.62v-12.083h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st1" d="M632.959,544.265a4.0816,4.0816,0,0,1-3.2608,1.2207,3.8274,3.8274,0,0,1-2.8808-1.02,2.8185,2.8185,0,0,1-.6006-.96,4.26,4.26,0,0,1-.26-1.56V531.0629h2.6006v10.5019a2.8852,2.8852,0,0,0,.14,1.1006,1.15,1.15,0,0,0,1.081.6,1.2652,1.2652,0,0,0,.76-.22c.5-.34.44-1,.44-1.5V531.0629h2.62v10.9423A3.3256,3.3256,0,0,1,632.959,544.265Z" />
                                    <path className="st1" d="M641.7178,545.1859c-.04-.08-.08-.1406-.12-.2207a2.5677,2.5677,0,0,1-.26-1.14v-3.2207c0-.22,0-.4405-.0195-.6807-.08-.78-.66-.96-1.3409-.96h-1.24v6.2217h-2.6006v-14.123h5.2012a2.6406,2.6406,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.5205v1.52a3.5445,3.5445,0,0,1-.28,1.5206,1.6976,1.6976,0,0,1-.3.38,2.6116,2.6116,0,0,1-1.24.5,2.8454,2.8454,0,0,1,.7793.28,2.3382,2.3382,0,0,1,1.1406,2.18v2.8809a3.2172,3.2172,0,0,0,.36,1.66.8336.8336,0,0,0,.24.2207Zm-.42-10.9424c0-.54-.08-.7207-.3-.94a1.2031,1.2031,0,0,0-.9-.22h-1.36v3.86h1.36a1.1953,1.1953,0,0,0,.98-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st1" d="M646.2969,545.1859v-14.123h6.8418v2.1406h-4.2412v3.32h4.0009v2.1406h-4.0009v4.3408h4.4414v2.1807Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st1" d="M599.8,545.19l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H599.8z" />
                                    <path className="st1" d="M612.42,540.11l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V540.11z"/>
                                    <path className="st1" d="M614.84,545.19v-14.12h2.84v11.6h4.06v2.52H614.84z" />
                                    <path className="st1" d="M624.84,545.19v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H624.84z" />
                                    <path className="st1" d="M639.88,540.11l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V540.11z"/>
                                    <path className="st1" d="M648.14,545.19l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H648.14z M646.9,537.13l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			l-1.1-0.12h-0.92v3.66H646.9z"/>
                                </g>}
                        </g>
                        <g id="Inner_Circle_-_2" className={"model-option" + (this.state.changed ? this.setStyles(4) : '')}
                            onMouseEnter={() => this.hoverPie(4)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 4)}>
                            <circle id="Circle_4_" className="st10" cx="425.2" cy="197.08" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st1" d="M396.7939,198.45c-.32,1.14-1.26,2.1606-3.6606,2.1606a4.5728,4.5728,0,0,1-2.9009-.8,2.395,2.395,0,0,1-.74-.96,3.1138,3.1138,0,0,1-.28-1.46v-2.06h2.5806v1.64c0,.52-.04,1.12.46,1.44a1.4385,1.4385,0,0,0,.8.2,1.268,1.268,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.8024,1.8024,0,0,0-.88-1.86l-2.46-1.66a3.28,3.28,0,0,1-1.18-1.1606,3.8712,3.8712,0,0,1-.5-2.24,4.7522,4.7522,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4037,2.4037,0,0,1,.22,1.18v1.4h-2.6005v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6448,2.6448,0,0,0,.28,1.36,2.3368,2.3368,0,0,0,.54.46l2.52,1.7407a5.2867,5.2867,0,0,1,1.1606,1.1,3.3625,3.3625,0,0,1,.56,2.44A7.9644,7.9644,0,0,1,396.7939,198.45Z" />
                                    <path className="st1" d="M403.4531,188.2274V200.31h-2.6206V188.2274h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st1" d="M413.2734,200.31c-.04-.08-.08-.14-.12-.22a2.5617,2.5617,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V200.31h-2.6006v-14.123h5.2012a2.643,2.643,0,0,1,1.7207.46,2.794,2.794,0,0,1,.82,2.52v1.5205a3.54,3.54,0,0,1-.28,1.52,1.6837,1.6837,0,0,1-.3.38,2.6043,2.6043,0,0,1-1.24.5,2.8537,2.8537,0,0,1,.78.28,2.3407,2.3407,0,0,1,1.14,2.18V198.43a3.2178,3.2178,0,0,0,.36,1.66.8508.8508,0,0,0,.24.22Zm-.42-10.9423c0-.54-.08-.72-.3-.94a1.2031,1.2031,0,0,0-.9-.22h-1.36v3.8608h1.36a1.1936,1.1936,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st1" d="M422.7729,200.31l-.34-2.3h-2.4l-.38,2.3h-2.6005l2.74-14.123h2.9809l2.64,14.123Zm-1.48-10.5424-.94,6.0615h1.8Z" />
                                    <path className="st1" d="M431.4316,188.2274V200.31h-2.62V188.2274h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st1" d="M435.6709,200.31v-14.123h6.8408v2.1406h-4.24v3.3208h4.001v2.14h-4.001v4.3408h4.44V200.31Z" />
                                    <path className="st1" d="M450.0322,200.31v-.84a4.205,4.205,0,0,1-.56.54,3.22,3.22,0,0,1-1.96.6006,2.8171,2.8171,0,0,1-2.8408-1.6406,3.2908,3.2908,0,0,1-.2-1.24v-8.0415a5.4021,5.4021,0,0,1,.2607-1.88c.58-1.5606,2.32-1.92,3.7608-1.92,1.14,0,2.6807.24,3.3808,1.3a2.67,2.67,0,0,1,.38,1.5v2.06h-2.6006v-1.36c0-.48.04-.96-.44-1.26a1.63,1.63,0,0,0-1.6006.02c-.3994.28-.5.72-.5,1.6v7.462a2.0253,2.0253,0,0,0,.1006.74,1.0434,1.0434,0,0,0,1.12.64,1.2556,1.2556,0,0,0,1.02-.36,1.7335,1.7335,0,0,0,.3007-1.26v-2.2h-1.6005v-2.1206h4.2011V200.31Z" />
                                    <path className="st1" d="M459.251,196.1293V200.31h-2.66v-4.3608l-2.8213-9.7622h2.7412l1.44,7.1216,1.38-7.1216h2.7412Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st1" d="M391.6,190.25l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L391.6,190.25z"/>
                                    <path className="st1" d="M397.84,200.31v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H397.84z" />
                                    <path className="st1" d="M410.32,200.31l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H410.32z M409.08,192.25l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H409.08z"/>
                                    <path className="st1" d="M420.44,200.31l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H420.44z M418.86,190.71
			l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L418.86,190.71z"/>
                                    <path className="st1" d="M426.12,200.31v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H426.12z" />
                                    <path className="st1" d="M432.76,200.31v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H432.76z" />
                                    <path className="st1" d="M447.1,200.31l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H447.1z"/>
                                    <path className="st1" d="M451.9,200.31v-14.12h2.88v14.12H451.9z" />
                                    <path className="st1" d="M457.3,200.31v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H457.3z" />
                                </g>
                            }
                        </g>
                        <g id="Inner_Circle_-_1_1_" className={"model-option" + (this.state.changed ? this.setStyles(7) : '')}
                            onMouseEnter={() => this.hoverPie(7)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.changeModel(e)}>
                            <circle id="Circle_3_" className="st11" cx="425.2" cy="425.2" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st1" d="M381.7021,431.515V417.392h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3409h4.441v2.1806Z" />
                                    <path className="st1" d="M396.0635,431.515l-1.66-4.9014-1.6806,4.9014h-2.82l3.1206-7.5215-2.7807-6.6015h2.8408l1.34,3.9809,1.36-3.9809h2.8408l-2.8208,6.6816,3.1206,7.4414Z" />
                                    <path className="st1" d="M400.7817,431.515V417.392h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3409h4.4409v2.1806Z" />
                                    <path className="st1" d="M417.1035,429.8949c-.68,1.96-3.3008,1.92-3.6011,1.92a6.8884,6.8884,0,0,1-1.34-.14,2.9472,2.9472,0,0,1-2.3809-1.6807,3.7954,3.7954,0,0,1-.2-1.4805v-7.6811c0-.24.02-.5.04-.74a2.6913,2.6913,0,0,1,1-2.1807,4.2991,4.2991,0,0,1,2.92-.82,4.7062,4.7062,0,0,1,2.6406.64,2.4633,2.4633,0,0,1,.88,1.04,3.1151,3.1151,0,0,1,.24,1.6v2.1607h-2.56v-1.78c0-1.0806-.18-1.6606-1.34-1.6606-1,0-1.2.62-1.2,1.5405v7.561c0,.9209.2,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.74v-1.84h2.56v2A5.2365,5.2365,0,0,1,417.1035,429.8949Z" />
                                    <path className="st1" d="M426.4229,430.5941a4.0807,4.0807,0,0,1-3.2608,1.2207,3.8291,3.8291,0,0,1-2.8809-1.02,2.8182,2.8182,0,0,1-.6-.96,4.2615,4.2615,0,0,1-.26-1.5606V417.392h2.6006v10.5019a2.8775,2.8775,0,0,0,.14,1.1006,1.1483,1.1483,0,0,0,1.08.6,1.2652,1.2652,0,0,0,.76-.22c.5-.34.44-1,.44-1.5V417.392h2.6211v10.9424A3.3216,3.3216,0,0,1,426.4229,430.5941Z" />
                                    <path className="st1" d="M433.8623,419.4325V431.515h-2.6211V419.4325h-2.6006V417.392h7.8418v2.0405Z" />
                                    <path className="st1" d="M438.1006,431.515V417.392h2.581v14.123Z" />
                                    <path className="st1" d="M447.8018,431.515h-2.62l-2.8007-14.123h2.7607l1.38,9.3418,1.28-9.3418h2.6611Z" />
                                    <path className="st1" d="M452.1807,431.515V417.392h6.8418v2.1406h-4.2413v3.3208h4.001v2.14h-4.001v4.3409h4.4415v2.1806Z" />
                                    <path className="st1" d="M468.4424,429.6547c-.32,1.14-1.2608,2.16-3.6612,2.16a4.5705,4.5705,0,0,1-2.9-.8,2.3967,2.3967,0,0,1-.74-.9609,3.1063,3.1063,0,0,1-.28-1.46v-2.06h2.581v1.64c0,.5195-.04,1.12.46,1.44a1.4432,1.4432,0,0,0,.8.2,1.2677,1.2677,0,0,0,.9-.2607c.38-.3.38-.92.38-1.88a1.8,1.8,0,0,0-.88-1.86l-2.46-1.66a3.2783,3.2783,0,0,1-1.1807-1.1607,3.8748,3.8748,0,0,1-.5-2.24,4.7422,4.7422,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.4117,2.4117,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.4205-.16-1.0206-1.16-1.0206-.6005,0-1.16.22-1.16,1.4a2.6405,2.6405,0,0,0,.28,1.36,2.3225,2.3225,0,0,0,.54.46l2.5205,1.7408a5.29,5.29,0,0,1,1.16,1.1,3.3609,3.3609,0,0,1,.56,2.44A8.0021,8.0021,0,0,1,468.4424,429.6547Z" />
                                </g>}
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st1" d="M361.64,429.59l0.04-14.12h6.62v2.5h-3.72l-0.06,3.02h3.44v2.44h-3.44v6.16H361.64z" />
                                    <path className="st1" d="M378.36,424.51l-0.12,2.26l-0.48,1.38l-0.96,1.02l-1.12,0.48l-1.58,0.14l-1.04-0.04l-1.44-0.5l-1.02-0.82
			l-0.5-1.04l-0.24-1.18l0.02-1.22v-9.52h2.84v8.46l0.04,1.9l0.18,1.14l0.38,0.42l0.82,0.2l0.6-0.16l0.36-0.26l0.26-0.46l0.14-1.6
			v-1.22l-0.02-8.42h2.88V424.51z M373.32,414.03h-2.04v-2.34h2.04V414.03z M376.96,414.03h-2.06v-2.34h2.06V414.03z"/>
                                    <path className="st1" d="M386.2,429.59v-6.12h-2.54v6.12h-2.88v-14.12h2.88v5.68h2.54v-5.68h2.88v14.12H386.2z" />
                                    <path className="st1" d="M397.44,429.59l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H397.44z M396.2,421.53l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			l-1.1-0.12h-0.92v3.66H396.2z"/>
                                    <path className="st1" d="M410.4,424.51l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V424.51z"/>
                                    <path className="st1" d="M418.74,429.55l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L418.74,429.55z"/>
                                    <path className="st1" d="M430.06,429.59l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H430.06z"/>
                                    <path className="st1" d="M439.44,419.53l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L439.44,419.53z"/>
                                    <path className="st1" d="M450.6,429.59l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H450.6z" />
                                    <path className="st1" d="M460.66,429.59l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H460.66z M459.42,421.53l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H459.42z"/>
                                    <path className="st1" d="M470.78,429.59l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H470.78z M469.2,419.99l-0.24-2.12
			l-0.26,2.14l-0.54,3.38h1.58L469.2,419.99z"/>
                                    <path className="st1" d="M475.2,429.59l0.04-14.12h6.62v2.5h-3.72l-0.06,3.02h3.44v2.44h-3.44v6.16H475.2z" />
                                    <path className="st1" d="M484.78,429.59v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H484.78z" />
                                </g>}
                        </g>
                        <g id="Inner_Ellipses_Kopie">
                            <path id="Border_-_3_1_" className="st12" d="M268.79,381.64c-94.62,54.63-151.07,134.14-114.39,199.17l-0.02,0.01
		c0.08,0.14,0.16,0.27,0.24,0.4c0.08,0.14,0.15,0.27,0.23,0.41l0.02-0.01c37.97,64.28,135.06,55.15,229.68,0.52
		C479.17,527.52,535.62,448,498.94,382.98l0.02-0.01c-0.08-0.14-0.16-0.27-0.24-0.4c-0.08-0.14-0.15-0.27-0.23-0.41l-0.02,0.01
		C460.5,317.88,363.41,327.01,268.79,381.64z"/>
                            <path id="Border_-_2_1_" className="st12" d="M464.73,582.44c94.62,54.63,191.71,63.76,229.68-0.52l0.02,0.01
		c0.08-0.14,0.15-0.27,0.23-0.41c0.08-0.14,0.16-0.27,0.24-0.4l-0.02-0.01c36.69-65.03-19.77-144.54-114.39-199.17
		s-191.71-63.76-229.68,0.52l-0.02-0.01c-0.08,0.14-0.15,0.27-0.23,0.41c-0.08,0.14-0.16,0.27-0.24,0.4l0.02,0.01
		C313.65,448.3,370.11,527.81,464.73,582.44z"/>
                            <path id="Border_-_1_1_" className="st12" d="M541.43,310.7c0-109.26-40.63-197.91-115.29-198.65v-0.02c-0.16,0-0.31,0.01-0.47,0.01
		c-0.16,0-0.31-0.01-0.47-0.01v0.02C350.54,112.8,309.9,201.44,309.9,310.7s40.63,197.91,115.29,198.65v0.02
		c0.16,0,0.31-0.01,0.47-0.01c0.16,0,0.31,0.01,0.47,0.01v-0.02C500.8,508.61,541.43,419.96,541.43,310.7z"/>
                        </g>
                    </g>
                    <g className="lv2" onClick={() => this.switchLevel()}>
                        <g id="Background" className="st0">
                            <rect y="0" className="st1" width="850.4" height="850.4" />
                        </g>
                        <g id="Outer_Circle_-_4">
                            <g id="Circle_4_" className="st2">
                                <path className="st3" d="M425.2,111.6c-173.4,0-314,140.6-314,314s141,312.8,314,312.8s314-139.4,314-312.8S598.6,111.6,425.2,111.6z
			 M425.2,583.2c-87.3,0-158-70.7-158-158s70.7-158,158-158s158,70.7,158,158S512.5,583.2,425.2,583.2z"/>
                            </g>
                        </g>
                        <g id="Outer_Circle_-_3">
                            <path id="Circle_10_" className="st4" d="M425.2,75.2c-193.3,0-349.9,156.7-349.9,349.9S232,775,425.2,775s349.9-156.7,349.9-349.9
		S618.5,75.2,425.2,75.2z M425.2,738.5c-173,0-313.3-140.3-313.3-313.3s140.3-313.3,313.3-313.3s313.3,140.3,313.3,313.3
		S598.2,738.5,425.2,738.5z"/>
                        </g>
                        <g id="Outer_Circle_-_2">
                            <path id="Circle_12_" className="st5" d="M425.2,37.5c-214.1,0-387.7,173.6-387.7,387.7s173.6,387.7,387.7,387.7
		s387.7-173.6,387.7-387.7S639.3,37.5,425.2,37.5z M425.2,775.4C231.8,775.4,75,618.6,75,425.2S231.8,75,425.2,75
		s350.2,156.8,350.2,350.2S618.6,775.4,425.2,775.4z"/>
                        </g>
                        <g id="Outer_Circle_-_1" >
                            <path id="Circle_13_" className="st6" d="M425.2,0C190.4,0,0,190.4,0,425.2s190.4,425.2,425.2,425.2S850.4,660,850.4,425.2
		S660,0,425.2,0z M425.2,812.8c-214,0-387.6-173.5-387.6-387.6S211.2,37.6,425.2,37.6s387.6,173.5,387.6,387.6
		S639.2,812.8,425.2,812.8z"/>
                        </g>
                        <g id="Inner_Cicle_-_1">
                            <circle id="Circle" className="st7" cx="425.2" cy="425.2" r="158" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M370.7412,432.6625V415.017h8.5479v2.6743h-5.2984V421.84h4.9986v2.6743h-4.9986v5.4243h5.5484v2.7237Z" />
                                    <path className="st8" d="M387.79,432.6625l-3.1245-13.2217.2749,13.2217h-3.0493V415.017h4.1743l3.1739,12.9472-.3247-12.9472h2.9741v17.6455Z" />
                                    <path className="st8" d="M400.44,417.5663v15.0962H397.166V417.5663h-3.25V415.017h9.7979v2.5493Z" />
                                    <path className="st8" d="M405.7407,432.6625V415.017h8.5484v2.6743H408.99V421.84h4.9986v2.6743H408.99v5.4243h5.5484v2.7237Z" />
                                    <path className="st8" d="M423.8643,432.6625c-.05-.1-.1-.1748-.15-.2744a3.2084,3.2084,0,0,1-.3252-1.4248v-4.0245c0-.2744,0-.55-.0249-.85-.1-.9751-.8247-1.2-1.6748-1.2H420.14v7.773h-3.25V415.017h6.4986a3.3008,3.3008,0,0,1,2.15.5747c1.05.7749,1.0244,2,1.0244,3.1494v1.8994a4.4156,4.4156,0,0,1-.35,1.8994,2.092,2.092,0,0,1-.375.4751,3.2615,3.2615,0,0,1-1.55.6245,3.5549,3.5549,0,0,1,.9746.35,2.9237,2.9237,0,0,1,1.4248,2.7246v3.5987a4.0243,4.0243,0,0,0,.45,2.0752,1.06,1.06,0,0,0,.3.2744Zm-.5249-13.6719c0-.6748-.1-.8994-.375-1.1743a1.5018,1.5018,0,0,0-1.1246-.2749h-1.7v4.8237h1.7a1.4934,1.4934,0,0,0,1.2247-.4248c.25-.3.2749-.55.2749-1.25Z" />
                                    <path className="st8" d="M438.4883,424.4393c-.5244.65-1.2744.875-2.874.875h-2.7491v7.3482h-3.2744V415.017h6.5479a4.6107,4.6107,0,0,1,.875.0747,2.4638,2.4638,0,0,1,1.7246,1.25,6.4355,6.4355,0,0,1,.375,2.6489v2.3247C439.1133,422.9647,439.0381,423.7645,438.4883,424.4393Zm-2.5742-5.0234a2.1284,2.1284,0,0,0-.25-1.4248,1.396,1.396,0,0,0-1.1993-.375h-1.6V422.79H434.44c.6748,0,1.05-.0752,1.25-.35a2.29,2.29,0,0,0,.2246-1.2246Z" />
                                    <path className="st8" d="M448.7383,432.6625c-.05-.1-.1-.1748-.1494-.2744a3.2084,3.2084,0,0,1-.3252-1.4248v-4.0245c0-.2744,0-.55-.0254-.85-.1-.9751-.8242-1.2-1.6738-1.2h-1.55v7.773h-3.249V415.017h6.4981a3.2986,3.2986,0,0,1,2.1494.5747c1.05.7749,1.0244,2,1.0244,3.1494v1.8994a4.4156,4.4156,0,0,1-.35,1.8994,2.092,2.092,0,0,1-.375.4751,3.2568,3.2568,0,0,1-1.55.6245,3.5634,3.5634,0,0,1,.9756.35,2.9244,2.9244,0,0,1,1.4238,2.7246v3.5987a4.0243,4.0243,0,0,0,.45,2.0752,1.06,1.06,0,0,0,.3.2744Zm-.5244-13.6719c0-.6748-.1006-.8994-.375-1.1743a1.5022,1.5022,0,0,0-1.125-.2749h-1.6993v4.8237h1.6993a1.4926,1.4926,0,0,0,1.2246-.4248c.25-.3.2754-.55.2754-1.25Z" />
                                    <path className="st8" d="M454.4658,432.6625V415.017H457.69v17.6455Z" />
                                    <path className="st8" d="M470.0879,430.3383c-.4,1.4248-1.5752,2.6992-4.5742,2.6992a5.71,5.71,0,0,1-3.6241-1,2.9843,2.9843,0,0,1-.9248-1.1992,3.8843,3.8843,0,0,1-.35-1.8243v-2.5752h3.2237v2.05c0,.65-.05,1.3995.5752,1.8a1.7992,1.7992,0,0,0,1,.25,1.5835,1.5835,0,0,0,1.124-.3252c.4756-.375.4756-1.1495.4756-2.35a2.2525,2.2525,0,0,0-1.1006-2.3242l-3.0742-2.0747a4.094,4.094,0,0,1-1.4746-1.45,4.8408,4.8408,0,0,1-.6241-2.7993,5.9273,5.9273,0,0,1,.4248-2.4243c.9991-2.1494,4.0235-2.1494,4.3741-2.1494.5244,0,3.3486,0,4.2734,2.3242a3.0093,3.0093,0,0,1,.2754,1.4746V420.19h-3.25V418.391c0-.5249-.1992-1.2749-1.4492-1.2749-.75,0-1.4492.2749-1.4492,1.7495a3.2954,3.2954,0,0,0,.35,1.7,2.9231,2.9231,0,0,0,.6748.5752l3.1494,2.1743a6.5987,6.5987,0,0,1,1.4492,1.3745,4.1989,4.1989,0,0,1,.7,3.0493A9.9718,9.9718,0,0,1,470.0879,430.3383Z" />
                                    <path className="st8" d="M473.09,432.6625V415.017h8.5479v2.6743h-5.2988V421.84h4.999v2.6743h-4.999v5.4243h5.5488v2.7237Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M368.3,426.4l-0.1,2.4l-0.2,1.1l-0.5,1l-0.6,0.8l-0.9,0.6l-1.2,0.5l-1.6,0.1l-1.9-0.2L360,432l-1-1.1
			l-0.5-0.9l-0.2-1l-0.1-2v-11.4h3.4v10.2v2.2l0.2,1.3l0.4,0.5l0.4,0.2l0.7,0.1l0.6-0.1l0.7-0.6l0.2-0.9l0.1-1.3v-1.5v-10.1h3.5
			v10.8H368.3z"/>
                                    <path className="st8" d="M378.3,432.5l-3.1-9l-0.4-1.4l-0.6-2.3l0.2,1.6l0.1,1.8l0.1,9.2h-3.3v-17h4l2.8,8.3l0.7,2.1l0.5,2l-0.2-1.9
			l-0.1-1.2l-0.1-9.3h3.3v16.9L378.3,432.5z"/>
                                    <path className="st8" d="M386.7,432.5v-14h-2.8v-2.9h9v2.9h-2.8v14H386.7z" />
                                    <path className="st8" d="M394.6,432.5v-16.9h8v3.1h-4.5v3.6h4.1v3h-4.1v4.1h4.5v3.1L394.6,432.5L394.6,432.5z" />
                                    <path className="st8" d="M412.1,432.5l-0.3-0.6l-0.3-2.3v-1l-0.1-1.6l-0.1-0.5L411,426l-0.6-0.2l-0.5-0.1h-1.3v6.8h-3.5v-16.9h6.3
			l1.2,0.2l0.8,0.4l0.7,0.6l0.7,1.3l0.3,2.1v1.1l-0.2,1.4l-0.7,1.2l-1,0.5l1.1,0.6l0.6,1.2l0.1,1.2v0.3l0.2,2.6l0.4,1.7l0.3,0.5
			L412.1,432.5L412.1,432.5z M410.6,422.9l0.5-0.2l0.5-0.6l0.1-1.4v-1.1l-0.2-0.5l-0.5-0.6l-1.3-0.1h-1.1v4.4h2V422.9z"/>
                                    <path className="st8" d="M424.7,432.5l-3.1-9l-0.4-1.4l-0.6-2.3l0.2,1.6l0.1,1.8l0.1,9.2h-3.3v-17h4l2.8,8.3l0.7,2.1l0.5,2l-0.2-1.9
			l-0.1-1.2l-0.1-9.3h3.3v16.9L424.7,432.5z"/>
                                    <path className="st8" d="M431.6,432.5v-16.9h8v3.1H435v3.6h4.1v3H435v4.1h4.5v3.1L431.6,432.5L431.6,432.5z" />
                                    <path className="st8" d="M448.6,432.5v-7.3h-3v7.3h-3.5v-16.9h3.5v6.8h3v-6.8h3.5v16.9H448.6z" />
                                    <path className="st8" d="M466.7,432.5v-7V421l0.2-2.5l-0.2,1.2l-0.3,1.8l-0.4,2.2l-1.9,8.8h-3.2L459,424l-0.5-2.9l-0.3-1.8l-0.1-1.3
			v1.7l0.2,3.3v9.6h-3.2l-0.1-16.9h5l1.9,9.5l0.5,2.4l0.1,1.8l0.2-2.1l0.2-1.6l1.9-10h5v16.9L466.7,432.5z"/>
                                    <path className="st8" d="M473,432.5v-16.9h8v3.1h-4.5v3.6h4.1v3h-4.1v4.1h4.5v3.1L473,432.5L473,432.5z" />
                                    <path className="st8" d="M490.6,432.5l-3.1-9l-0.4-1.4l-0.6-2.3l0.2,1.6l0.1,1.8l0.1,9.2h-3.3v-17h4l2.8,8.3l0.7,2.1l0.5,2l-0.2-1.9
			l-0.1-1.2l-0.1-9.3h3.3v16.9L490.6,432.5z"/>
                                </g>
                            }
                        </g>
                        <g id="Lines">
                            <line id="Line_12_" className="st9" x1="605.6" y1="605.6" x2="680.3" y2="425.2" />
                            <line id="Line_13_" className="st9" x1="273" y1="467.8" x2="170.1" y2="425.2" />
                            <polyline id="Line_14_" className="st9" points="244.8,605.6 605.6,605.6 502.6,562.9 	" />
                            <line id="Line_15_" className="st9" x1="425.2" y1="680.3" x2="605.6" y2="605.6" />
                            <polyline id="Line_16_" className="st9" points="170.1,425.2 425.2,680.3 244.8,605.6 	" />
                            <line id="Line_17_" className="st9" x1="287.4" y1="347.7" x2="244.8" y2="244.8" />
                            <line id="Line_18_" className="st9" x1="425.2" y1="680.3" x2="382.6" y2="577.4" />
                            <polyline id="Line_19_" className="st9" points="170.1,425.2 425.2,170.1 680.3,425.2 	" />
                            <line id="Line_20_" className="st9" x1="425.2" y1="583.2" x2="425.2" y2="680.3" />
                            <polyline id="Line_21_" className="st9" points="244.8,244.8 425.2,170.1 425.2,267.5 	" />
                            <polyline id="Line_22_" className="st9" points="467.8,273 425.2,170.1 605.6,244.8 	" />
                            <line id="Line_23_" className="st9" x1="605.6" y1="605.6" x2="562.9" y2="502.6" />
                            <line id="Line_24_" className="st9" x1="382.6" y1="273" x2="425.2" y2="170.1" />
                            <line id="Line_25_" className="st9" x1="244.8" y1="605.6" x2="287.4" y2="502.6" />
                            <polyline id="Line_11_" className="st9" points="244.8,605.6 170.1,425.2 244.8,244.8 	" />
                            <polyline id="Line_9_" className="st9" points="502.6,287.4 605.6,244.8 244.8,244.8 	" />
                            <line id="Line_10_" className="st9" x1="170.1" y1="425.2" x2="273" y2="382.6" />
                            <line id="Line_8_" className="st9" x1="313.5" y1="536.9" x2="244.8" y2="605.6" />
                            <polyline id="Line_7_" className="st9" points="562.9,347.8 605.6,244.8 536.9,313.5 	" />
                            <line id="Line_6_" className="st9" x1="425.2" y1="680.3" x2="467.8" y2="577.4" />
                            <polyline id="Line_3_" className="st9" points="425.2,680.3 680.3,425.2 577.4,467.8 	" />
                            <polyline id="Line_2_" className="st9" points="347.8,287.4 244.8,244.8 244.8,605.6 347.8,562.9 	" />
                            <polyline id="Line_1_" className="st9" points="583.2,425.2 680.3,425.2 577.4,382.6 	" />
                            <line id="Line" className="st9" x1="170.1" y1="425.2" x2="267.2" y2="425.2" />
                            <polyline className="st9" points="536.9,536.9 605.6,605.6 605.6,244.8 680.3,425.2 	" />
                            <line className="st9" x1="244.8" y1="244.8" x2="313.5" y2="313.5" />
                        </g>
                        <g id="Element_-_8">
                            <circle id="Circle_5_" className="st3" cx="184.7" cy="184.7" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M139.4316,185.4906v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V183.31h4.4409v2.1807Z" />
                                    <path className="st8" d="M157.9336,185.4906l.24-12.2427-2.2807,12.2427h-2.42l-2.34-12.2427.2,12.2427h-2.56v-14.123h4.2412l1.66,9.8823,1.6806-9.8823h4.1807v14.123Z" />
                                    <path className="st8" d="M170.6528,178.9091c-.42.52-1.02.7-2.3.7h-2.2006v5.8813h-2.6207v-14.123h5.2413a3.6792,3.6792,0,0,1,.7.06,1.9732,1.9732,0,0,1,1.38,1,5.15,5.15,0,0,1,.3,2.1206v1.86C171.1533,177.7289,171.0933,178.369,170.6528,178.9091Zm-2.06-4.0206a1.7078,1.7078,0,0,0-.2-1.1406,1.1192,1.1192,0,0,0-.96-.3h-1.28v4.1406h1.26c.54,0,.84-.06,1-.28a1.83,1.83,0,0,0,.18-.9805Z" />
                                    <path className="st8" d="M173.6909,185.4906v-14.123h2.6006V183.35h3.8208v2.1406Z" />
                                    <path className="st8" d="M189.0728,184.8505a4.34,4.34,0,0,1-3.0611.94,4.5151,4.5151,0,0,1-2.94-.82,2.289,2.289,0,0,1-.7-.88,3.8236,3.8236,0,0,1-.3-1.62v-7.9219a3.3978,3.3978,0,0,1,.88-2.5605,4.5848,4.5848,0,0,1,3.0805-.92c2.0406,0,3.1211.84,3.5411,1.66a3.9074,3.9074,0,0,1,.32,1.88V182.47A3.0652,3.0652,0,0,1,189.0728,184.8505Zm-1.8-10.062a2.485,2.485,0,0,0-.16-1.1407,1.1036,1.1036,0,0,0-1.0806-.62,1.4355,1.4355,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6V182.03a2.2376,2.2376,0,0,0,.24,1.3,1.3436,1.3436,0,0,0,1.0606.42,1.5235,1.5235,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M197.272,181.31v4.1807h-2.66V181.13l-2.8208-9.7622h2.7407l1.44,7.1215,1.38-7.1215h2.7407Z" />
                                    <path className="st8" d="M202.11,185.4906v-14.123h6.8417v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V183.31h4.4414v2.1807Z" />
                                    <path className="st8" d="M211.45,185.4906v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V183.31h4.4414v2.1807Z" />
                                    <path className="st8" d="M228.1318,183.63c-.32,1.14-1.2607,2.1607-3.6611,2.1607a4.5714,4.5714,0,0,1-2.9-.8,2.4,2.4,0,0,1-.74-.96,3.1067,3.1067,0,0,1-.28-1.46V180.51h2.5811v1.64c0,.52-.04,1.12.46,1.44a1.4392,1.4392,0,0,0,.8.2,1.2677,1.2677,0,0,0,.9-.26c.38-.3.38-.9205.38-1.88a1.8,1.8,0,0,0-.88-1.86l-2.46-1.66a3.2768,3.2768,0,0,1-1.1807-1.1607,3.8748,3.8748,0,0,1-.5-2.24,4.7434,4.7434,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.4105,2.4105,0,0,1,.22,1.18v1.4h-2.6006v-1.4405c0-.42-.16-1.02-1.16-1.02-.6006,0-1.16.22-1.16,1.4a2.6416,2.6416,0,0,0,.28,1.36,2.3269,2.3269,0,0,0,.54.46l2.5206,1.7408a5.29,5.29,0,0,1,1.16,1.1,3.3623,3.3623,0,0,1,.56,2.44A7.9909,7.9909,0,0,1,228.1318,183.63Z" />
                                </g>}
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M132.2,185.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H132.2z M130.6,176.1l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L130.6,176.1z"/>
                                    <path className="st8" d="M142.4,185.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L142.4,185.7L142.4,185.7z M141.2,177.6l0.4-0.1l0.4-0.5l0.1-1.1V175l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7L141.2,177.6
			L141.2,177.6z"/>
                                    <path className="st8" d="M147,171.6h4.3h0.6l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2l0.8,0.3
			l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5l-0.6,0.3l-1.3,0.1H147V171.6z M150.4,177.4l0.8-0.1l0.3-0.2l0.3-0.3l0.1-1.4
			l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4L150.4,177.4L150.4,177.4z M150.6,183.4l0.8-0.1l0.4-0.3l0.2-0.8v-0.8l-0.3-1.2l-0.5-0.3
			l-0.8-0.1h-0.9v3.7L150.6,183.4L150.6,183.4z"/>
                                    <path className="st8" d="M157,185.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H157z" />
                                    <path className="st8" d="M165.7,185.7v-14.1h2.9v14.1H165.7z" />
                                    <path className="st8" d="M172.4,185.7V174h-2.3v-2.4h7.5v2.4h-2.3v11.7H172.4z" />
                                    <path className="st8" d="M184.9,185.7l-2.6-7.5L182,177l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8v-14.1h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8v14.1L184.9,185.7z"/>
                                    <path className="st8" d="M190.7,185.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H190.7z" />
                                    <path className="st8" d="M204.8,185.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H204.8z" />
                                    <path className="st8" d="M220,185.7v-5.8v-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3h-2.7l-1.6-7.1l-0.4-2.4l-0.3-1.5l-0.1-1.1
			v1.4l0.2,2.8v8h-2.7l-0.1-14.1h4.2l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1v14.1L220,185.7z"/>
                                    <path className="st8" d="M225.2,185.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H225.2z" />
                                    <path className="st8" d="M239.8,185.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2L238,180h-1.1v5.7H234v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L239.8,185.7L239.8,185.7z M238.5,177.6l0.4-0.1l0.4-0.5l0.1-1.1V175l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7L238.5,177.6
			L238.5,177.6z"/>
                                </g>
                            }
                        </g>
                        <g id="Element_-_7">
                            <circle id="Circle_7_" className="st3" cx="85" cy="425.2" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M48.1011,426.9886c-.32,1.1407-1.26,2.1612-3.6607,2.1612a4.5727,4.5727,0,0,1-2.9008-.8008,2.3973,2.3973,0,0,1-.74-.96,3.1157,3.1157,0,0,1-.28-1.46V423.868H43.1v1.64c0,.52-.04,1.12.46,1.44a1.434,1.434,0,0,0,.8.2,1.2678,1.2678,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8808a1.8025,1.8025,0,0,0-.88-1.86l-2.4605-1.66a3.2809,3.2809,0,0,1-1.18-1.1607,3.8708,3.8708,0,0,1-.5-2.24,4.751,4.751,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.5009-1.72.42,0,2.68,0,3.42,1.86a2.4049,2.4049,0,0,1,.22,1.18v1.4H45.5005v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6,0-1.16.22-1.16,1.4a2.6438,2.6438,0,0,0,.28,1.36,2.3337,2.3337,0,0,0,.54.46l2.52,1.7408a5.2887,5.2887,0,0,1,1.1607,1.1,3.3625,3.3625,0,0,1,.56,2.44A7.9687,7.9687,0,0,1,48.1011,426.9886Z" />
                                    <path className="st8" d="M57.9009,427.9291A4.0848,4.0848,0,0,1,54.64,429.15a3.8327,3.8327,0,0,1-2.8808-1.0205,2.84,2.84,0,0,1-.6-.96,4.267,4.267,0,0,1-.26-1.561V414.726H53.5v10.5024a2.8737,2.8737,0,0,0,.14,1.1,1.15,1.15,0,0,0,1.08.6006,1.2607,1.2607,0,0,0,.76-.2207c.5005-.34.44-1,.44-1.5V414.726H58.541v10.9423A3.3258,3.3258,0,0,1,57.9009,427.9291Z" />
                                    <path className="st8" d="M68.6206,422.2675c-.42.52-1.02.7-2.3.7H64.12v5.8813H61.499V414.726H66.74a3.6786,3.6786,0,0,1,.7.06,1.9732,1.9732,0,0,1,1.38,1,5.15,5.15,0,0,1,.3,2.1206v1.86C69.1211,421.0873,69.061,421.7274,68.6206,422.2675Zm-2.06-4.0206a1.7077,1.7077,0,0,0-.2-1.1406,1.1192,1.1192,0,0,0-.96-.3H64.12v4.1406h1.26c.54,0,.84-.06,1-.28a1.8314,1.8314,0,0,0,.18-.9805Z" />
                                    <path className="st8" d="M78.78,422.2675c-.42.52-1.02.7-2.3.7H74.2793v5.8813H71.6587V414.726H76.9a3.6786,3.6786,0,0,1,.7.06,1.9737,1.9737,0,0,1,1.38,1,5.15,5.15,0,0,1,.3,2.1206v1.86C79.2808,421.0873,79.2207,421.7274,78.78,422.2675Zm-2.06-4.0206a1.7072,1.7072,0,0,0-.2-1.1406,1.119,1.119,0,0,0-.96-.3h-1.28v4.1406h1.26c.5405,0,.84-.06,1-.28a1.83,1.83,0,0,0,.18-.9805Z" />
                                    <path className="st8" d="M81.8184,428.849V414.726h2.6v11.9824H88.24v2.1406Z" />
                                    <path className="st8" d="M90.3184,428.849V414.726h2.5805v14.123Z" />
                                    <path className="st8" d="M95.8984,428.849V414.726H102.74v2.1406H98.499v3.3208H102.5v2.14H98.499v4.3408H102.94v2.1807Z" />
                                    <path className="st8" d="M110.8193,428.849c-.04-.08-.08-.14-.12-.22a2.5712,2.5712,0,0,1-.26-1.1407v-3.2207c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.3408-.96h-1.24v6.2212h-2.6006V414.726H110.44a2.6436,2.6436,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52v1.5205a3.5431,3.5431,0,0,1-.28,1.5205,1.676,1.676,0,0,1-.3.38,2.6074,2.6074,0,0,1-1.24.5,2.843,2.843,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.18v2.8813a3.217,3.217,0,0,0,.36,1.66.8468.8468,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.2032,1.2032,0,0,0-.9-.22h-1.36v3.8608h1.36a1.1942,1.1942,0,0,0,.98-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st8" d="M123.16,426.9886c-.32,1.1407-1.2608,2.1612-3.6612,2.1612a4.5718,4.5718,0,0,1-2.9-.8008,2.4026,2.4026,0,0,1-.74-.96,3.1083,3.1083,0,0,1-.28-1.46V423.868h2.5811v1.64c0,.52-.04,1.12.46,1.44a1.4349,1.4349,0,0,0,.8.2,1.2674,1.2674,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8808a1.8,1.8,0,0,0-.88-1.86l-2.46-1.66a3.2783,3.2783,0,0,1-1.1807-1.1607,3.8748,3.8748,0,0,1-.5-2.24,4.7422,4.7422,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.5009-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4H120.56v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6006,0-1.16.22-1.16,1.4a2.6412,2.6412,0,0,0,.28,1.36,2.3258,2.3258,0,0,0,.54.46l2.52,1.7408a5.2876,5.2876,0,0,1,1.16,1.1,3.3618,3.3618,0,0,1,.56,2.44A7.9965,7.9965,0,0,1,123.16,426.9886Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M33.6,429.1V415h2.8v11.6h4.1v2.5H33.6z" />
                                    <path className="st8" d="M42.3,429.1V415h2.9v14.1H42.3z" />
                                    <path className="st8" d="M47.7,429.1V415h6.7v2.6h-3.8v3H54v2.5h-3.4v3.4h3.8v2.6H47.7z" />
                                    <path className="st8" d="M56.5,429.1V415h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H56.5z" />
                                    <path className="st8" d="M64.8,429.1V415h6.7v2.6h-3.8v3H71v2.5h-3.4v3.4h3.8v2.6H64.8z" />
                                    <path className="st8" d="M79.4,429.1l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9V415h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L79.4,429.1L79.4,429.1z M78.1,421.1l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7h1.6V421.1z"/>
                                    <path className="st8" d="M89.5,429.1l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H89.5z M87.9,419.5l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L87.9,419.5z"/>
                                    <path className="st8" d="M99.8,429.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V415h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V429L99.8,429.1z"/>
                                    <path className="st8" d="M106.8,429.1v-11.7h-2.3V415h7.5v2.4h-2.3v11.7H106.8z" />
                                    <path className="st8" d="M113.4,429.1V415h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H113.4z" />
                                    <path className="st8" d="M128.1,429.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V415h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V429L128.1,429.1z"/>
                                </g>
                            }
                        </g>
                        <g id="Element_-_6">
                            <circle id="Circle_9_" className="st3" cx="184.7" cy="665.7" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M142.3618,672.0717V657.9486h2.5806v14.1231Z" />
                                    <path className="st8" d="M152.6626,672.0717l-2.5-10.5821.22,10.5821h-2.4409V657.9486h3.3408l2.5406,10.3623-.26-10.3623h2.38v14.1231Z" />
                                    <path className="st8" d="M163.4824,672.0717h-2.6206l-2.8-14.1231h2.7608l1.38,9.3418,1.28-9.3418h2.6607Z" />
                                    <path className="st8" d="M168.2812,672.0717V657.9486h6.8418v2.1406h-4.2412v3.3213h4.001v2.14h-4.001v4.3408h4.441v2.1807Z" />
                                    <path className="st8" d="M184.9629,670.2113c-.32,1.1406-1.26,2.1611-3.6607,2.1611a4.5734,4.5734,0,0,1-2.9008-.8007,2.3979,2.3979,0,0,1-.74-.96,3.1166,3.1166,0,0,1-.28-1.46v-2.0605h2.5806v1.64c0,.52-.04,1.12.46,1.44a1.434,1.434,0,0,0,.8.2,1.2678,1.2678,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8809a1.8036,1.8036,0,0,0-.88-1.86l-2.46-1.66a3.2792,3.2792,0,0,1-1.18-1.16,3.872,3.872,0,0,1-.5-2.24,4.751,4.751,0,0,1,.34-1.94c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.68,0,3.42,1.86a2.4067,2.4067,0,0,1,.22,1.1806v1.4h-2.6006v-1.44c0-.4209-.16-1.0205-1.16-1.0205-.6,0-1.16.22-1.16,1.4a2.6454,2.6454,0,0,0,.28,1.36,2.3481,2.3481,0,0,0,.54.46l2.52,1.74a5.28,5.28,0,0,1,1.1607,1.1006,3.3625,3.3625,0,0,1,.56,2.44A7.9652,7.9652,0,0,1,184.9629,670.2113Z" />
                                    <path className="st8" d="M192.0425,659.99v12.0821h-2.6206V659.99h-2.6006v-2.041h7.8418v2.041Z" />
                                    <path className="st8" d="M203.583,671.432a4.34,4.34,0,0,1-3.061.94,4.5182,4.5182,0,0,1-2.94-.82,2.2924,2.2924,0,0,1-.7-.8808,3.8239,3.8239,0,0,1-.3-1.62v-7.9218a3.3989,3.3989,0,0,1,.88-2.5606,4.5855,4.5855,0,0,1,3.0806-.92c2.041,0,3.1211.84,3.541,1.66a3.9129,3.9129,0,0,1,.32,1.8808v7.8613A3.0674,3.0674,0,0,1,203.583,671.432ZM201.7822,661.37a2.4707,2.4707,0,0,0-.16-1.1406,1.1027,1.1027,0,0,0-1.08-.62,1.4377,1.4377,0,0,0-.84.2607c-.58.3994-.5,1.0195-.5,1.6006v7.1406a2.2385,2.2385,0,0,0,.24,1.3008,1.344,1.344,0,0,0,1.06.42,1.52,1.52,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M212.8623,672.0717c-.04-.08-.08-.14-.12-.22a2.57,2.57,0,0,1-.26-1.1406v-3.2207c0-.22,0-.4395-.02-.68-.08-.78-.66-.96-1.3408-.96h-1.24v6.2207h-2.6006V657.9486h5.2012a2.6465,2.6465,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52V662.45a3.5415,3.5415,0,0,1-.28,1.52,1.6614,1.6614,0,0,1-.3.38,2.6044,2.6044,0,0,1-1.24.5,2.844,2.844,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1807v2.8809a3.2172,3.2172,0,0,0,.36,1.66.8467.8467,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.9395a1.2041,1.2041,0,0,0-.9-.2207h-1.36V663.83h1.36a1.1932,1.1932,0,0,0,.9805-.3408c.2-.2392.22-.4394.22-1Z" />
                                    <path className="st8" d="M225.2021,670.2113c-.32,1.1406-1.2607,2.1611-3.6611,2.1611a4.5723,4.5723,0,0,1-2.9-.8007,2.4032,2.4032,0,0,1-.74-.96,3.1079,3.1079,0,0,1-.28-1.46v-2.0605h2.5811v1.64c0,.52-.04,1.12.46,1.44a1.4349,1.4349,0,0,0,.8.2,1.2674,1.2674,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8809a1.8006,1.8006,0,0,0-.88-1.86l-2.46-1.66a3.2766,3.2766,0,0,1-1.1807-1.16,3.8752,3.8752,0,0,1-.5-2.24,4.7434,4.7434,0,0,1,.34-1.94c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.6807,0,3.4209,1.86a2.4123,2.4123,0,0,1,.22,1.1806v1.4h-2.6005v-1.44c0-.4209-.16-1.0205-1.16-1.0205-.6006,0-1.16.22-1.16,1.4a2.642,2.642,0,0,0,.28,1.36,2.3379,2.3379,0,0,0,.54.46l2.52,1.74a5.2809,5.2809,0,0,1,1.16,1.1006,3.3623,3.3623,0,0,1,.56,2.44A7.9874,7.9874,0,0,1,225.2021,670.2113Z" />
                                </g>}
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M136,672.2l-3.4-7l0.1,7h-2.9V658h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5H136V672.2z" />
                                    <path className="st8" d="M145.8,672.2l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H145.8z M144.2,662.6l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L144.2,662.6z"/>
                                    <path className="st8" d="M150.2,672.2V658h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2h-1.3H153v5.1H150.2z M153.9,664.6l0.7-0.2l0.4-0.6l0.1-1v-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1H153.9z"/>
                                    <path className="st8" d="M159.8,672.2V658h2.9v14.1h-2.9V672.2z" />
                                    <path className="st8" d="M166.5,672.2v-11.7h-2.3V658h7.5v2.4h-2.3v11.7L166.5,672.2L166.5,672.2z" />
                                    <path className="st8" d="M178.7,672.2l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H178.7z M177.1,662.6l-0.2-2.1l-0.3,2.1L176,666
			h1.6L177.1,662.6z"/>
                                    <path className="st8" d="M183.1,672.2V658h2.8v11.6h4.1v2.5h-6.9V672.2z" />
                                    <path className="st8" d="M197.4,672.2V671l-0.3,0.5l-0.8,0.6l-0.6,0.3h-0.8h-0.8l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4v-2l0.1-3
			l0.1-1.5l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3v0.9h-2.8v-0.6l-0.1-1.4l-0.4-0.4l-0.8-0.3
			l-0.5,0.1l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6v1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1v-1.1v-0.8h-1.5v-2.3h4.2v7.9h-2.3
			V672.2z"/>
                                    <path className="st8" d="M202.2,672.2V658h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6h-6.7V672.2z" />
                                    <path className="st8" d="M211,658h4.3h0.6l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2l0.8,0.3
			l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5L217,672l-1.3,0.1H211V658z M214.4,663.9l0.8-0.1l0.3-0.2l0.3-0.3l0.1-1.4
			l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4L214.4,663.9L214.4,663.9z M214.6,669.8l0.8-0.1l0.4-0.3l0.2-0.8v-0.8l-0.3-1.2l-0.5-0.3
			l-0.8-0.1h-0.9v3.7h1.1V669.8z"/>
                                    <path className="st8" d="M220.9,672.2V658h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6h-6.7V672.2z" />
                                    <path className="st8" d="M235.5,672.2l-0.2-0.5l-0.2-1.9V669l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9V658h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L235.5,672.2L235.5,672.2z M234.3,664.1l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7h1.6V664.1z"/>
                                </g>}
                        </g>
                        <g id="Element_-_5">
                            <circle id="Circle_1_" className="st3" cx="425.2" cy="765.4" r="85" />

                            <g>
                                <path className="st8" d="M423.8,769.8v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3v-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6v0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L423.8,769.8z M423.8,773.7V771h2.6v2.7H423.8z"/>
                            </g>
                        </g>
                        <g id="Element_-_4">
                            <circle id="Circle_2_" className="st3" cx="665.7" cy="665.7" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M654.373,672.3842l.24-12.2432-2.28,12.2432h-2.4209l-2.34-12.2432.1992,12.2432h-2.5606V658.2611h4.2412l1.66,9.8818,1.6807-9.8818h4.1806v14.1231Z" />
                                    <path className="st8" d="M659.9707,672.3842V658.2611h6.8418v2.1406h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1807Z" />
                                    <path className="st8" d="M677.0527,669.3636a2.9641,2.9641,0,0,1-1.32,2.58,4.18,4.18,0,0,1-2.24.44h-4.1817V658.2611h4.7813a2.9607,2.9607,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02v6.6016C677.0723,668.8431,677.0723,669.1029,677.0527,669.3636Zm-2.581-7.6015a1.39,1.39,0,0,0-.3594-1.2012,1.7475,1.7475,0,0,0-1.1807-.2392h-1.02v10.0019h.9209a4.5945,4.5945,0,0,0,.74-.04c.84-.16.8994-.78.8994-1.4805Z" />
                                    <path className="st8" d="M679.8906,672.3842V658.2611h2.58v14.1231Z" />
                                    <path className="st8" d="M690.3916,672.3842l-.34-2.3008h-2.4l-.3809,2.3008h-2.6l2.74-14.1231h2.9805l2.6406,14.1231Zm-1.4805-10.543-.94,6.0615h1.8008Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M615.2,672.2l-3.4-7l0.1,7H609V658h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5h-3.1V672.2z" />
                                    <path className="st8" d="M627.5,667.8l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L627.5,667.8z
			 M624.5,662.6l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H624.5z"/>
                                    <path className="st8" d="M635.7,672.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V658h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V672L635.7,672.1z"/>
                                    <path className="st8" d="M647.6,672.2l-3.4-7l0.1,7h-2.9V658h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5h-3.1V672.2z" />
                                    <path className="st8" d="M660.2,667.1l-0.1,2l-0.2,0.9l-0.4,0.8l-0.5,0.6l-0.8,0.5l-1,0.4l-1.3,0.1l-1.6-0.1l-1.1-0.5l-0.9-0.9
			l-0.4-0.8l-0.2-0.8l-0.1-1.7V658h2.8v8.5v1.8l0.2,1.1l0.3,0.4l0.3,0.2l0.6,0.1l0.5-0.1l0.6-0.5l0.2-0.7l0.1-1.1v-1.3V658h2.9v9.1
			H660.2z"/>
                                    <path className="st8" d="M668.5,672.2l-0.2-0.5l-0.2-1.9V669l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9V658h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L668.5,672.2L668.5,672.2z M667.2,664.1l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7h1.6V664.1z"/>
                                    <path className="st8" d="M678.9,672.2l-0.2-0.5l-0.2-1.9V669l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1H676v5.7h-2.9V658h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4
			L678.9,672.2L678.9,672.2z M677.6,664.1l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1H676v3.7h1.6V664.1z"/>
                                    <path className="st8" d="M683.4,672.2V658h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6h-6.7V672.2z" />
                                    <path className="st8" d="M698.1,672.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V658h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V672L698.1,672.1z"/>
                                    <path className="st8" d="M705.1,672.2v-11.7h-2.3V658h7.5v2.4H708v11.7L705.1,672.2L705.1,672.2z" />
                                    <path className="st8" d="M711.7,672.2V658h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6h-6.7V672.2z" />
                                    <path className="st8" d="M726.4,672.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V658h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V672L726.4,672.1z"/>
                                </g>
                            }
                        </g>
                        <g id="Element_-_3">
                            <circle id="Circle_6_" className="st3" cx="765.4" cy="425.2" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M734.54,427.6449c-.6806,1.96-3.3007,1.92-3.6005,1.92a6.8979,6.8979,0,0,1-1.3409-.14,2.9485,2.9485,0,0,1-2.3808-1.6807,3.8042,3.8042,0,0,1-.1992-1.4805v-7.6816c0-.24.0195-.5.04-.74a2.6913,2.6913,0,0,1,1-2.1807,4.2987,4.2987,0,0,1,2.92-.82,4.7052,4.7052,0,0,1,2.6406.64,2.4641,2.4641,0,0,1,.8809,1.04,3.12,3.12,0,0,1,.24,1.6v2.1607h-2.56v-1.78c0-1.08-.1807-1.6606-1.3408-1.6606-1,0-1.2.62-1.2,1.5405v7.5615c0,.9209.2,1.6006,1.28,1.6006,1.16,0,1.2608-.66,1.2608-1.7407v-1.84h2.56v2A5.2519,5.2519,0,0,1,734.54,427.6449Z" />
                                    <path className="st8" d="M744.2793,428.3441a4.0806,4.0806,0,0,1-3.2607,1.2207,3.83,3.83,0,0,1-2.8809-1.02,2.8188,2.8188,0,0,1-.6-.96,4.2621,4.2621,0,0,1-.2608-1.5606V415.1415h2.6006v10.5024a2.866,2.866,0,0,0,.1407,1.1006,1.1474,1.1474,0,0,0,1.08.6,1.2636,1.2636,0,0,0,.76-.22c.501-.34.44-1,.44-1.5V415.1415H744.92v10.9424A3.3222,3.3222,0,0,1,744.2793,428.3441Z" />
                                    <path className="st8" d="M755.22,427.4047c-.32,1.14-1.2607,2.16-3.6611,2.16a4.5708,4.5708,0,0,1-2.9-.8,2.3866,2.3866,0,0,1-.74-.9609,3.1049,3.1049,0,0,1-.28-1.46v-2.0605h2.5811v1.64c0,.52-.04,1.1206.46,1.4409a1.4408,1.4408,0,0,0,.8.2,1.2693,1.2693,0,0,0,.9-.2607c.38-.3.38-.92.38-1.88a1.8033,1.8033,0,0,0-.88-1.86l-2.4609-1.66a3.2754,3.2754,0,0,1-1.18-1.1606,3.8672,3.8672,0,0,1-.5-2.24,4.7434,4.7434,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.3982,2.3982,0,0,1,.2207,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6006,0-1.16.22-1.16,1.4a2.6483,2.6483,0,0,0,.2793,1.36,2.3481,2.3481,0,0,0,.54.46l2.52,1.7407a5.288,5.288,0,0,1,1.1611,1.1,3.3623,3.3623,0,0,1,.56,2.44A7.9965,7.9965,0,0,1,755.22,427.4047Z" />
                                    <path className="st8" d="M762.2988,417.182v12.083h-2.6211V417.182h-2.6006v-2.0405h7.8418v2.0405Z" />
                                    <path className="st8" d="M773.8389,428.6244a4.3386,4.3386,0,0,1-3.0606.94,4.5114,4.5114,0,0,1-2.94-.82,2.2842,2.2842,0,0,1-.7-.88,3.82,3.82,0,0,1-.3-1.6206v-7.9219a3.3956,3.3956,0,0,1,.88-2.56,4.5823,4.5823,0,0,1,3.08-.92c2.041,0,3.121.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.88v7.8618A3.0638,3.0638,0,0,1,773.8389,428.6244Zm-1.8-10.062a2.4846,2.4846,0,0,0-.16-1.1406,1.1042,1.1042,0,0,0-1.081-.62,1.4373,1.4373,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2371,2.2371,0,0,0,.24,1.3,1.3421,1.3421,0,0,0,1.0606.4209,1.5258,1.5258,0,0,0,.8193-.24c.4805-.34.461-.9008.461-1.4209Z" />
                                    <path className="st8" d="M786.6992,429.265l.24-12.2431-2.2813,12.2431h-2.42l-2.3408-12.2431.2,12.2431h-2.5606V415.1415h4.2412l1.66,9.8823,1.6806-9.8823H789.3V429.265Z" />
                                    <path className="st8" d="M792.2969,429.265V415.1415h6.8418v2.1406h-4.2412v3.3208h4.0009v2.14h-4.0009v4.3414h4.4414v2.1806Z" />
                                    <path className="st8" d="M807.2178,429.265c-.04-.08-.08-.1406-.12-.2207a2.5677,2.5677,0,0,1-.26-1.14v-3.2212c0-.22,0-.44-.0195-.68-.08-.78-.66-.96-1.3409-.96h-1.24v6.2217h-2.6006V415.1415h5.2012a2.6435,2.6435,0,0,1,1.7207.46,2.7945,2.7945,0,0,1,.82,2.52v1.5206a3.5433,3.5433,0,0,1-.28,1.5205,1.6753,1.6753,0,0,1-.3.38,2.6065,2.6065,0,0,1-1.24.5,2.8454,2.8454,0,0,1,.7793.28,2.3393,2.3393,0,0,1,1.1406,2.18v2.8809a3.2172,3.2172,0,0,0,.36,1.66.8336.8336,0,0,0,.24.2207Zm-.42-10.9429c0-.54-.08-.72-.3-.94a1.2034,1.2034,0,0,0-.9-.22h-1.36v3.8608h1.36a1.1941,1.1941,0,0,0,.98-.34c.2-.24.22-.44.22-1Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M744.4,429.1l-3.4-7l0.1,7h-2.9V415h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5H744.4z" />
                                    <path className="st8" d="M757,424l-0.1,2l-0.2,0.9l-0.4,0.8l-0.5,0.6l-0.8,0.5l-1,0.4l-1.3,0.1l-1.6-0.1l-1.1-0.5l-0.9-0.9l-0.4-0.8
			l-0.2-0.8l-0.1-1.7V415h2.8v8.5v1.8l0.2,1.1l0.3,0.4l0.3,0.2l0.6,0.1l0.5-0.1l0.6-0.5l0.2-0.7l0.1-1.1v-1.3V415h2.9v9H757z"/>
                                    <path className="st8" d="M765.3,429.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V415h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V429L765.3,429.1z"/>
                                    <path className="st8" d="M771.1,415h3.9l1.6,0.2l1.3,0.7l0.6,0.8l0.3,0.9l0.3,1.8l0.1,2.3l-0.1,3.1l-0.3,2l-0.6,1.1l-0.8,0.7l-0.8,0.4
			l-1.8,0.2H771L771.1,415z M774.7,426.7l0.6-0.1l0.4-0.2l0.3-0.5l0.2-3.3v-3.1l-0.1-1.1l-0.3-0.6l-0.5-0.2l-0.5-0.1H774v9.2
			L774.7,426.7L774.7,426.7z"/>
                                    <path className="st8" d="M781.4,429.1V415h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H781.4z" />
                                    <path className="st8" d="M796.1,429.1l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V415h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8V429L796.1,429.1z"/>
                                </g>
                            }
                        </g>
                        <g id="Element_-_2">
                            <circle id="Circle_8_" className="st3" cx="665.7" cy="184.7" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M651.0859,183.8109c-.32,1.14-1.26,2.1607-3.66,2.1607a4.5746,4.5746,0,0,1-2.9014-.8,2.4006,2.4006,0,0,1-.74-.96,3.1208,3.1208,0,0,1-.2793-1.46V180.69h2.58v1.64c0,.52-.04,1.12.46,1.4405a1.44,1.44,0,0,0,.8008.2,1.2661,1.2661,0,0,0,.8994-.26c.3809-.3.3809-.92.3809-1.88a1.8018,1.8018,0,0,0-.8809-1.86l-2.46-1.66a3.2835,3.2835,0,0,1-1.1807-1.1606,3.8748,3.8748,0,0,1-.5-2.24,4.7616,4.7616,0,0,1,.34-1.94c.8008-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4h-2.6005v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3231,2.3231,0,0,0,.54.46l2.52,1.7407a5.2924,5.2924,0,0,1,1.16,1.1,3.3628,3.3628,0,0,1,.5606,2.44A7.9364,7.9364,0,0,1,651.0859,183.8109Z" />
                                    <path className="st8" d="M658.165,173.5887v12.0826h-2.62V173.5887h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M667.126,185.6713l-.3408-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.1231h2.9815l2.6406,14.1231Zm-1.4805-10.5425-.94,6.0615h1.8008Z" />
                                    <path className="st8" d="M676.2051,173.5887v12.0826h-2.62V173.5887h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M680.8643,185.6713V171.5482h6.8408v2.1406h-4.24V177.01h4.001v2.14h-4.001v4.3408h4.4405v2.1807Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M648.9,175.6v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L648.9,175.6z"/>
                                    <path className="st8" d="M655.2,185.7V174h-2.3v-2.4h7.5v2.4H658v11.7H655.2z" />
                                    <path className="st8" d="M667.4,185.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H667.4z M665.8,176.1l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L665.8,176.1z"/>
                                    <path className="st8" d="M677.4,185.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H677.4z M675.8,176.1l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L675.8,176.1z"/>
                                    <path className="st8" d="M683,185.7V174h-2.3v-2.4h7.5v2.4h-2.3v11.7H683z" />
                                </g>
                            }
                        </g>
                        <g id="Element_-_1">
                            <circle id="Circle_3_" className="st3" cx="425.2" cy="85" r="85" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M411.9438,84.4716l.24-12.2427-2.2808,12.2427h-2.42l-2.34-12.2427.2,12.2427h-2.5606V70.3485h4.2412l1.66,9.8823,1.6807-9.8823h4.1806V84.4716Z" />
                                    <path className="st8" d="M417.5415,84.4716V70.3485h6.8418v2.1406h-4.2412V75.81h4.001v2.14h-4.001v4.3408h4.4409v2.1807Z" />
                                    <path className="st8" d="M434.623,81.451a2.9651,2.9651,0,0,1-1.32,2.5806,4.18,4.18,0,0,1-2.24.44h-4.1807V70.3485h4.7813a2.9651,2.9651,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02v6.6016C434.6436,80.931,434.6436,81.1908,434.623,81.451Zm-2.58-7.6015a1.39,1.39,0,0,0-.36-1.2007,1.7422,1.7422,0,0,0-1.18-.24h-1.0205V82.411h.92a4.5945,4.5945,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                    <path className="st8" d="M437.4609,84.4716V70.3485h2.5811V84.4716Z" />
                                    <path className="st8" d="M447.9629,84.4716l-.34-2.3h-2.4l-.3809,2.3h-2.6006l2.7412-14.1231h2.9805l2.6406,14.1231Zm-1.4805-10.5425-.94,6.0615h1.8008Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M407.3,84.4v-5.8v-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3h-2.7l-1.6-7.1l-0.4-2.4l-0.3-1.5l-0.1-1.1
			v1.4l0.2,2.8v8h-2.7l-0.1-14.1h4.2l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1v14.1L407.3,84.4z"/>
                                    <path className="st8" d="M412.6,84.4V70.3h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H412.6z" />
                                    <path className="st8" d="M421.3,70.3h3.9l1.6,0.2l1.3,0.7l0.6,0.8l0.3,0.9l0.3,1.8l0.1,2.3l-0.1,3.1l-0.3,2l-0.6,1.1l-0.8,0.7
			l-0.8,0.4l-1.8,0.2h-3.7V70.3z M425,82l0.6-0.1l0.4-0.2l0.3-0.5l0.2-3.3v-3.1l-0.1-1.1l-0.3-0.6l-0.5-0.2l-0.5-0.1h-0.8V82H425z"
                                    />
                                    <path className="st8" d="M431.7,84.4V70.3h2.9v14.1H431.7z" />
                                    <path className="st8" d="M437.1,84.4V70.3h6.7v2.6H440v3h3.4v2.5H440v3.4h3.8v2.6H437.1z" />
                                    <path className="st8" d="M451.8,84.4l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8V70.3h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8v14.1L451.8,84.4z"/>
                                </g>}
                        </g>
                        <g id="Text">
                            <line id="Line_-_4" className="st9" x1="582" y1="803.3" x2="639" y2="842.4" />
                            <line id="Line_-_3" className="st9" x1="564.9" y1="766.8" x2="638.1" y2="817.1" />
                            <line id="Line_-_2_1_" className="st9" x1="550.4" y1="733.1" x2="638.1" y2="793.3" />
                            <line id="Line_-_1" className="st9" x1="536.9" y1="698.4" x2="638.1" y2="769.2" />
                            <circle id="Circle_-_4" className="st8" cx="578.5" cy="800.8" r="3.5" />
                            <circle id="Circle_-_3" className="st8" cx="563.8" cy="765.6" r="3.5" />
                            <circle id="Circle_-_2" className="st8" cx="550.4" cy="733.1" r="3.5" />
                            <circle id="Circle_-_1" className="st8" cx="536.9" cy="698.4" r="3.5" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <g>
                                        <path className="st8" d="M649.8857,777.6722v-14.123h6.8418V765.69h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1806Z" />
                                        <path className="st8" d="M666.6279,776.0521c-.6806,1.96-3.3008,1.92-3.6006,1.92a6.8976,6.8976,0,0,1-1.3408-.14,2.9485,2.9485,0,0,1-2.3808-1.6807,3.8019,3.8019,0,0,1-.1993-1.48V766.99c0-.24.02-.5.04-.74a2.6913,2.6913,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.92-.8193,4.7048,4.7048,0,0,1,2.6406.64,2.4647,2.4647,0,0,1,.8809,1.04,3.1211,3.1211,0,0,1,.24,1.6006v2.16h-2.56v-1.78c0-1.08-.1807-1.66-1.3408-1.66-1,0-1.2.62-1.2,1.54v7.5616c0,.9209.2,1.6005,1.28,1.6005,1.16,0,1.2608-.66,1.2608-1.74v-1.8408h2.56v2.001A5.25,5.25,0,0,1,666.6279,776.0521Z" />
                                        <path className="st8" d="M676.2676,777.0316a4.3409,4.3409,0,0,1-3.0615.94,4.5131,4.5131,0,0,1-2.9405-.82,2.2848,2.2848,0,0,1-.7-.88,3.8344,3.8344,0,0,1-.3-1.62V766.73a3.4,3.4,0,0,1,.88-2.5606,4.5881,4.5881,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9136,3.9136,0,0,1,.3193,1.88v7.8623A3.0657,3.0657,0,0,1,676.2676,777.0316ZM674.4668,766.97a2.4826,2.4826,0,0,0-.16-1.1406,1.1025,1.1025,0,0,0-1.08-.62,1.4339,1.4339,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1416a2.235,2.235,0,0,0,.2393,1.3,1.343,1.343,0,0,0,1.0605.4209,1.5291,1.5291,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                        <path className="st8" d="M679.9658,777.6722v-14.123h2.6006v11.9824h3.82v2.1406Z" />
                                        <path className="st8" d="M695.3477,777.0316a4.3411,4.3411,0,0,1-3.0616.94,4.513,4.513,0,0,1-2.94-.82,2.2848,2.2848,0,0,1-.7-.88,3.8329,3.8329,0,0,1-.3-1.62V766.73a3.4,3.4,0,0,1,.88-2.5606,4.588,4.588,0,0,1,3.081-.92c2.04,0,3.1211.84,3.5411,1.66a3.9136,3.9136,0,0,1,.3193,1.88v7.8623A3.0661,3.0661,0,0,1,695.3477,777.0316ZM693.5469,766.97a2.484,2.484,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4336,1.4336,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1416a2.235,2.235,0,0,0,.2393,1.3,1.3429,1.3429,0,0,0,1.0605.4209,1.5291,1.5291,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                        <path className="st8" d="M704.4863,777.6722v-.8408a4.25,4.25,0,0,1-.56.54,3.2208,3.2208,0,0,1-1.961.6005,2.8181,2.8181,0,0,1-2.8408-1.6406,3.3081,3.3081,0,0,1-.1992-1.24V767.05a5.4278,5.4278,0,0,1,.26-1.8809c.58-1.5605,2.32-1.92,3.7608-1.92,1.1406,0,2.6807.2392,3.3808,1.3a2.6689,2.6689,0,0,1,.38,1.5v2.06h-2.6006v-1.36c0-.48.04-.96-.44-1.26a1.629,1.629,0,0,0-1.6.02c-.4.28-.5.7207-.5,1.6005v7.462a2.017,2.017,0,0,0,.1.74,1.0432,1.0432,0,0,0,1.12.64,1.2572,1.2572,0,0,0,1.02-.36,1.7312,1.7312,0,0,0,.3-1.26v-2.2h-1.6005V770.01h4.2011v7.6621Z" />
                                        <path className="st8" d="M709.625,777.6722v-14.123h2.58v14.123Z" />
                                        <path className="st8" d="M722.6064,776.0521c-.68,1.96-3.3007,1.92-3.6005,1.92a6.8846,6.8846,0,0,1-1.34-.14,2.9456,2.9456,0,0,1-2.3808-1.6807,3.7861,3.7861,0,0,1-.2-1.48V766.99c0-.24.02-.5.04-.74a2.6943,2.6943,0,0,1,1-2.1807,4.3009,4.3009,0,0,1,2.9209-.8193,4.7068,4.7068,0,0,1,2.6406.64,2.4624,2.4624,0,0,1,.88,1.04,3.11,3.11,0,0,1,.24,1.6006v2.16h-2.5605v-1.78c0-1.08-.18-1.66-1.34-1.66-1.0009,0-1.2.62-1.2,1.54v7.5616c0,.9209.1992,1.6005,1.28,1.6005,1.16,0,1.26-.66,1.26-1.74v-1.8408h2.5605v2.001A5.2223,5.2223,0,0,1,722.6064,776.0521Z" />
                                        <path className="st8" d="M730.2861,777.6722l-.3408-2.3007h-2.4l-.38,2.3007h-2.6005l2.74-14.123h2.9814l2.6407,14.123Zm-1.48-10.5429-.9405,6.0615h1.8008Z" />
                                        <path className="st8" d="M735.1045,777.6722v-14.123h2.6006v11.9824h3.8213v2.1406Z" />
                                        <path className="st8" d="M756.5264,775.8119c-.32,1.14-1.2608,2.16-3.6612,2.16a4.571,4.571,0,0,1-2.9-.8,2.3971,2.3971,0,0,1-.74-.9609,3.1061,3.1061,0,0,1-.28-1.46v-2.0605h2.5811v1.6406c0,.52-.04,1.12.46,1.44a1.443,1.443,0,0,0,.8.2,1.2668,1.2668,0,0,0,.9-.2607c.38-.3.38-.92.38-1.88a1.7979,1.7979,0,0,0-.88-1.86l-2.46-1.66a3.2787,3.2787,0,0,1-1.1807-1.1611,3.8752,3.8752,0,0,1-.5-2.24,4.7426,4.7426,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.5009-1.72.42,0,2.6807,0,3.4209,1.86a2.4081,2.4081,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6006,0-1.16.2207-1.16,1.4a2.64,2.64,0,0,0,.28,1.36,2.3133,2.3133,0,0,0,.54.46l2.52,1.7412a5.2943,5.2943,0,0,1,1.16,1.1,3.3618,3.3618,0,0,1,.56,2.44A8.0017,8.0017,0,0,1,756.5264,775.8119Z" />
                                        <path className="st8" d="M766.4668,771.09c-.4209.52-1.0205.7-2.3008.7h-2.2v5.8818h-2.6211v-14.123h5.2412a3.705,3.705,0,0,1,.7.06,1.9744,1.9744,0,0,1,1.38,1,5.1458,5.1458,0,0,1,.3008,2.1211v1.86C766.9668,769.9105,766.9062,770.55,766.4668,771.09Zm-2.0606-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.1183,1.1183,0,0,0-.96-.3h-1.28V769.77h1.26c.54,0,.8408-.06,1-.2793a1.8268,1.8268,0,0,0,.1806-.9805Z" />
                                        <path className="st8" d="M774.4854,777.6722V771.21h-2.3809v6.4619h-2.6006v-14.123h2.6006v5.541h2.3809v-5.541h2.6005v14.123Z" />
                                        <path className="st8" d="M780.084,777.6722v-14.123h6.8418V765.69h-4.2412v3.32h4.0009v2.1407h-4.0009v4.3408h4.4414v2.1806Z" />
                                        <path className="st8" d="M795.0049,777.6722c-.04-.08-.08-.1406-.12-.2207a2.5687,2.5687,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.0195-.6807-.08-.78-.66-.96-1.3409-.96h-1.24v6.2216h-2.6006v-14.123h5.2012a2.6406,2.6406,0,0,1,1.7207.46,2.7943,2.7943,0,0,1,.82,2.5205v1.52a3.5444,3.5444,0,0,1-.28,1.52,1.7,1.7,0,0,1-.3.38,2.6136,2.6136,0,0,1-1.24.5,2.844,2.844,0,0,1,.7793.28,2.3382,2.3382,0,0,1,1.1406,2.18v2.8809a3.2172,3.2172,0,0,0,.36,1.66.8322.8322,0,0,0,.24.2207Zm-.42-10.9423c0-.54-.08-.7207-.3-.9405a1.2035,1.2035,0,0,0-.9-.22h-1.36v3.86h1.36a1.1954,1.1954,0,0,0,.98-.34c.2-.24.22-.44.22-1Z" />
                                        <path className="st8" d="M800.0039,777.6722v-14.123h6.8418V765.69h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1806Z" />
                                    </g>
                                    <g>
                                        <path className="st8" d="M657.4043,799.9115c-.32,1.14-1.26,2.16-3.66,2.16a4.5741,4.5741,0,0,1-2.9013-.8,2.3975,2.3975,0,0,1-.74-.961,3.1206,3.1206,0,0,1-.2793-1.46V796.79h2.58v1.6406c0,.52-.04,1.12.46,1.44a1.4445,1.4445,0,0,0,.8008.2,1.2661,1.2661,0,0,0,.8994-.2608c.3808-.3.3808-.92.3808-1.88a1.8,1.8,0,0,0-.8808-1.86l-2.46-1.66a3.2855,3.2855,0,0,1-1.1806-1.1611,3.874,3.874,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.409,2.409,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6,0-1.16.2207-1.16,1.4a2.6405,2.6405,0,0,0,.28,1.36,2.31,2.31,0,0,0,.54.46l2.52,1.7412a5.2941,5.2941,0,0,1,1.16,1.1,3.3627,3.3627,0,0,1,.56,2.44A7.9462,7.9462,0,0,1,657.4043,799.9115Z" />
                                        <path className="st8" d="M667.1045,801.1312a4.3409,4.3409,0,0,1-3.0615.94,4.5133,4.5133,0,0,1-2.9405-.82,2.284,2.284,0,0,1-.7-.88,3.8344,3.8344,0,0,1-.3-1.62V790.83a3.4,3.4,0,0,1,.88-2.5606,4.5881,4.5881,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9129,3.9129,0,0,1,.3193,1.88v7.8623A3.0656,3.0656,0,0,1,667.1045,801.1312ZM665.3037,791.07a2.4835,2.4835,0,0,0-.16-1.1406,1.1028,1.1028,0,0,0-1.08-.62,1.4339,1.4339,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1416a2.235,2.235,0,0,0,.2393,1.3,1.343,1.343,0,0,0,1.0605.4209,1.5294,1.5294,0,0,0,.82-.24c.4794-.34.46-.9.46-1.4209Z" />
                                        <path className="st8" d="M678.2041,800.1517c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8808,6.8808,0,0,1-1.34-.14,2.9456,2.9456,0,0,1-2.3809-1.6807,3.7861,3.7861,0,0,1-.2-1.48v-7.6817c0-.24.02-.5.04-.74a2.694,2.694,0,0,1,1-2.1807,4.3006,4.3006,0,0,1,2.9209-.8193,4.707,4.707,0,0,1,2.6406.64,2.4624,2.4624,0,0,1,.88,1.04,3.11,3.11,0,0,1,.24,1.6006v2.16h-2.5605v-1.78c0-1.08-.18-1.66-1.34-1.66-1.001,0-1.2.62-1.2,1.54v7.5616c0,.9209.1992,1.6005,1.28,1.6005,1.16,0,1.26-.66,1.26-1.74V796.47h2.5605v2.001A5.2223,5.2223,0,0,1,678.2041,800.1517Z" />
                                        <path className="st8" d="M680.9619,801.7719V787.6488h2.5811v14.1231Z" />
                                        <path className="st8" d="M691.4639,801.7719l-.3409-2.3008h-2.4l-.38,2.3008h-2.6006l2.74-14.1231h2.9815l2.6406,14.1231Zm-1.4805-10.543-.94,6.0615h1.8008Z" />
                                        <path className="st8" d="M696.2822,801.7719V787.6488h2.6006v11.9824h3.82v2.1407Z" />
                                        <path className="st8" d="M717.7031,799.9115c-.32,1.14-1.26,2.16-3.66,2.16a4.5742,4.5742,0,0,1-2.9014-.8,2.397,2.397,0,0,1-.74-.961,3.1193,3.1193,0,0,1-.2793-1.46V796.79h2.58v1.6406c0,.52-.04,1.12.46,1.44a1.4447,1.4447,0,0,0,.8008.2,1.2659,1.2659,0,0,0,.8994-.2608c.3809-.3.3809-.92.3809-1.88a1.8,1.8,0,0,0-.8809-1.86l-2.46-1.66a3.2855,3.2855,0,0,1-1.1806-1.1611,3.8748,3.8748,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.409,2.409,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6,0-1.16.2207-1.16,1.4a2.6405,2.6405,0,0,0,.28,1.36,2.312,2.312,0,0,0,.54.46l2.5205,1.7412a5.2941,5.2941,0,0,1,1.16,1.1,3.3628,3.3628,0,0,1,.5606,2.44A7.9407,7.9407,0,0,1,717.7031,799.9115Z" />
                                        <path className="st8" d="M727.6436,795.19c-.4209.52-1.0206.7-2.3008.7h-2.2v5.8819h-2.6211V787.6488h5.2412a3.7056,3.7056,0,0,1,.7.06,1.9749,1.9749,0,0,1,1.38,1,5.1461,5.1461,0,0,1,.3008,2.1211v1.86C728.1436,794.01,728.083,794.65,727.6436,795.19Zm-2.0606-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.1181,1.1181,0,0,0-.96-.3h-1.28V793.87h1.26c.5411,0,.8409-.06,1.001-.2793a1.8347,1.8347,0,0,0,.18-.9805Z" />
                                        <path className="st8" d="M735.6621,801.7719V795.31h-2.3809v6.462h-2.6005V787.6488h2.6005v5.541h2.3809v-5.541h2.6006v14.1231Z" />
                                        <path className="st8" d="M741.2607,801.7719V787.6488h6.8418v2.1406h-4.2412v3.32h4.001V795.25h-4.001v4.3408h4.4414v2.1807Z" />
                                        <path className="st8" d="M756.1816,801.7719c-.04-.08-.08-.1407-.12-.2208a2.5689,2.5689,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.02-.6807-.08-.78-.66-.96-1.3408-.96h-1.24v6.2217h-2.6006V787.6488h5.2012a2.6406,2.6406,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52V792.15a3.5444,3.5444,0,0,1-.28,1.52,1.6975,1.6975,0,0,1-.3.38,2.6146,2.6146,0,0,1-1.24.5,2.8452,2.8452,0,0,1,.7793.28,2.3382,2.3382,0,0,1,1.1406,2.18v2.8809a3.2167,3.2167,0,0,0,.36,1.66.8356.8356,0,0,0,.24.2208Zm-.42-10.9424c0-.54-.08-.7207-.3-.9405a1.2034,1.2034,0,0,0-.9-.22h-1.36v3.86h1.36a1.1955,1.1955,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                        <path className="st8" d="M761.1807,801.7719V787.6488h6.8418v2.1406h-4.2413v3.32h4.001V795.25h-4.001v4.3408h4.4415v2.1807Z" />
                                    </g>
                                    <g>
                                        <path className="st8" d="M654.293,813.6176V825.7h-2.62v-12.082h-2.6006v-2.0411h7.8418v2.0411Z" />
                                        <path className="st8" d="M658.9521,825.7V811.5765h6.8418v2.1407h-4.2412v3.3213h4.001v2.14h-4.001v4.3408h4.4414V825.7Z" />
                                        <path className="st8" d="M675.6934,824.08c-.68,1.96-3.3008,1.9209-3.6,1.9209a6.8532,6.8532,0,0,1-1.3409-.1407,2.9484,2.9484,0,0,1-2.3808-1.6806,3.7819,3.7819,0,0,1-.2-1.4795v-7.6817c0-.24.02-.5009.041-.74a2.6887,2.6887,0,0,1,1-2.1807,4.2972,4.2972,0,0,1,2.92-.82,4.7057,4.7057,0,0,1,2.6406.6407,2.4639,2.4639,0,0,1,.8809,1.04,3.1192,3.1192,0,0,1,.24,1.6v2.1611H673.334v-1.78c0-1.0811-.1807-1.6612-1.3408-1.6612-1.001,0-1.2.62-1.2,1.541v7.5616c0,.92.1992,1.6006,1.28,1.6006,1.16,0,1.2608-.66,1.2608-1.7413v-1.84h2.5605v2A5.2223,5.2223,0,0,1,675.6934,824.08Z" />
                                        <path className="st8" d="M683.4336,825.7v-6.461h-2.3809V825.7h-2.6015V811.5765h2.6015v5.5411h2.3809v-5.5411h2.6V825.7Z" />
                                        <path className="st8" d="M693.7529,825.7l-2.5009-10.582.2207,10.582h-2.4415V811.5765h3.3418l2.54,10.3623-.26-10.3623h2.38V825.7Z" />
                                        <path className="st8" d="M706.9141,825.06a4.3412,4.3412,0,0,1-3.0616.9405,4.5179,4.5179,0,0,1-2.94-.82,2.2932,2.2932,0,0,1-.7-.8809,3.8314,3.8314,0,0,1-.3-1.62v-7.9219a3.3966,3.3966,0,0,1,.88-2.5605,4.5874,4.5874,0,0,1,3.081-.92c2.04,0,3.1211.84,3.5411,1.66a3.9154,3.9154,0,0,1,.3193,1.8808v7.8614A3.0664,3.0664,0,0,1,706.9141,825.06Zm-1.8008-10.0625a2.4839,2.4839,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4347,1.4347,0,0,0-.84.2607c-.58.3995-.5,1.02-.5,1.6006v7.1406a2.2381,2.2381,0,0,0,.2393,1.3008,1.3428,1.3428,0,0,0,1.0595.42,1.5162,1.5162,0,0,0,.82-.24c.4805-.34.461-.9.461-1.42Z" />
                                        <path className="st8" d="M710.6113,825.7V811.5765h2.6006V823.559h3.8213V825.7Z" />
                                        <path className="st8" d="M725.9932,825.06a4.3412,4.3412,0,0,1-3.0616.9405,4.5166,4.5166,0,0,1-2.9394-.82,2.2985,2.2985,0,0,1-.7012-.8809,3.83,3.83,0,0,1-.2988-1.62v-7.9219a3.3984,3.3984,0,0,1,.8789-2.5605,4.5874,4.5874,0,0,1,3.081-.92c2.04,0,3.1211.84,3.5411,1.66a3.9154,3.9154,0,0,1,.3193,1.8808v7.8614A3.0631,3.0631,0,0,1,725.9932,825.06Zm-1.8-10.0625a2.4982,2.4982,0,0,0-.16-1.1406,1.1053,1.1053,0,0,0-1.0811-.62,1.4365,1.4365,0,0,0-.84.2607c-.58.3995-.5,1.02-.5,1.6006v7.1406a2.2381,2.2381,0,0,0,.2393,1.3008,1.3456,1.3456,0,0,0,1.0605.42,1.52,1.52,0,0,0,.82-.24c.4805-.34.461-.9.461-1.42Z" />
                                        <path className="st8" d="M735.1328,825.7v-.84a4.3693,4.3693,0,0,1-.56.54,3.2257,3.2257,0,0,1-1.961.6006,2.8155,2.8155,0,0,1-2.84-1.6407,3.292,3.292,0,0,1-.2-1.24v-8.042a5.42,5.42,0,0,1,.26-1.88c.58-1.56,2.3212-1.9209,3.7607-1.9209,1.1406,0,2.6807.24,3.3809,1.3a2.6645,2.6645,0,0,1,.38,1.501v2.0606H734.752v-1.36c0-.4805.04-.96-.4395-1.2607a1.6279,1.6279,0,0,0-1.6006.02c-.4.28-.501.72-.501,1.6v7.4619a2.0251,2.0251,0,0,0,.1006.74,1.0437,1.0437,0,0,0,1.12.6407,1.2565,1.2565,0,0,0,1.02-.36,1.7382,1.7382,0,0,0,.3-1.2607v-2.2h-1.6v-2.12h4.2V825.7Z" />
                                        <path className="st8" d="M740.2715,825.7V811.5765h2.58V825.7Z" />
                                        <path className="st8" d="M753.252,824.08c-.68,1.96-3.3008,1.9209-3.6,1.9209a6.8529,6.8529,0,0,1-1.3408-.1407,2.9484,2.9484,0,0,1-2.3808-1.6806,3.7819,3.7819,0,0,1-.2-1.4795v-7.6817c0-.24.02-.5009.041-.74a2.6884,2.6884,0,0,1,1-2.1807,4.2972,4.2972,0,0,1,2.92-.82,4.7057,4.7057,0,0,1,2.6406.6407,2.4639,2.4639,0,0,1,.8809,1.04,3.1192,3.1192,0,0,1,.24,1.6v2.1611h-2.56v-1.78c0-1.0811-.1807-1.6612-1.3408-1.6612-1.001,0-1.2.62-1.2,1.541v7.5616c0,.92.1992,1.6006,1.28,1.6006,1.16,0,1.2608-.66,1.2608-1.7413v-1.84h2.56v2A5.2223,5.2223,0,0,1,753.252,824.08Z" />
                                        <path className="st8" d="M760.9316,825.7l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.1231h2.98L763.5723,825.7Zm-1.48-10.542-.9395,6.0615h1.8008Z" />
                                        <path className="st8" d="M765.75,825.7V811.5765h2.6016V823.559h3.82V825.7Z" />
                                        <path className="st8" d="M787.1719,823.8392c-.32,1.1407-1.26,2.1612-3.66,2.1612a4.5724,4.5724,0,0,1-2.9-.8008,2.3835,2.3835,0,0,1-.74-.96,3.1052,3.1052,0,0,1-.2813-1.46v-2.0606h2.5821v1.64c0,.52-.041,1.12.459,1.44a1.4356,1.4356,0,0,0,.8007.2,1.2674,1.2674,0,0,0,.9-.26c.3789-.3.3789-.9209.3789-1.8808a1.8027,1.8027,0,0,0-.8789-1.86l-2.4609-1.66a3.2665,3.2665,0,0,1-1.18-1.16,3.8668,3.8668,0,0,1-.5-2.24,4.7265,4.7265,0,0,1,.34-1.9405c.7989-1.7207,3.2208-1.7207,3.5-1.7207.42,0,2.6817,0,3.4219,1.86a2.4258,2.4258,0,0,1,.2188,1.1806v1.4h-2.6v-1.44c0-.4209-.16-1.02-1.16-1.02-.6016,0-1.16.22-1.16,1.4a2.6373,2.6373,0,0,0,.2792,1.36,2.3244,2.3244,0,0,0,.5411.46l2.5195,1.74a5.2881,5.2881,0,0,1,1.16,1.1006,3.3587,3.3587,0,0,1,.5605,2.44A7.9372,7.9372,0,0,1,787.1719,823.8392Z" />
                                        <path className="st8" d="M797.1113,819.1185c-.42.52-1.0195.7-2.3008.7h-2.1992V825.7H789.99V811.5765h5.24a3.6592,3.6592,0,0,1,.7011.0606,1.9741,1.9741,0,0,1,1.3809,1,5.1707,5.1707,0,0,1,.2988,2.12v1.86C797.6113,817.9379,797.5508,818.5785,797.1113,819.1185Zm-2.0605-4.0205a1.7073,1.7073,0,0,0-.1992-1.1406,1.1207,1.1207,0,0,0-.961-.3h-1.2793v4.1406h1.26c.5391,0,.84-.06,1-.28a1.8345,1.8345,0,0,0,.18-.98Z" />
                                        <path className="st8" d="M805.1309,825.7v-6.461H802.75V825.7h-2.6V811.5765h2.6v5.5411h2.3809v-5.5411h2.6015V825.7Z" />
                                        <path className="st8" d="M810.7305,825.7V811.5765h6.8418v2.1407H813.33v3.3213h4.0019v2.14H813.33v4.3408h4.4414V825.7Z" />
                                        <path className="st8" d="M825.65,825.7c-.0391-.08-.08-.14-.1192-.22a2.5712,2.5712,0,0,1-.26-1.1407v-3.2207c0-.22,0-.4394-.0195-.68-.08-.78-.66-.96-1.3418-.96h-1.24V825.7h-2.6V811.5765h5.2012a2.65,2.65,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52v1.52a3.5319,3.5319,0,0,1-.2813,1.52,1.611,1.611,0,0,1-.2988.38,2.6081,2.6081,0,0,1-1.24.5,2.8532,2.8532,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1806V823.82a3.2133,3.2133,0,0,0,.3594,1.66.8727.8727,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.2989-.94a1.2049,1.2049,0,0,0-.9-.2207H822.67v3.8614h1.3613a1.1933,1.1933,0,0,0,.9805-.3408c.1992-.2393.2188-.44.2188-1Z" />
                                        <path className="st8" d="M830.6484,825.7V811.5765H837.49v2.1407h-4.24v3.3213h4v2.14h-4v4.3408h4.4414V825.7Z" />
                                    </g>
                                    <g>
                                        <path className="st8" d="M649.9746,849.6674V835.5443h6.8418v2.1406h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1807Z" />
                                        <path className="st8" d="M666.7168,848.0472c-.6807,1.96-3.3008,1.92-3.6016,1.92a6.883,6.883,0,0,1-1.34-.14,2.9485,2.9485,0,0,1-2.3809-1.6806,3.8045,3.8045,0,0,1-.1992-1.4805v-7.6817c0-.24.0195-.5.04-.74a2.6881,2.6881,0,0,1,1-2.1807,4.2993,4.2993,0,0,1,2.92-.8193,4.7041,4.7041,0,0,1,2.6406.64,2.4639,2.4639,0,0,1,.8809,1.04,3.1213,3.1213,0,0,1,.24,1.6006v2.16h-2.5606v-1.78c0-1.08-.1806-1.66-1.3408-1.66-1,0-1.2.62-1.2,1.54v7.5616c0,.9209.2,1.6006,1.28,1.6006,1.16,0,1.2607-.66,1.2607-1.74v-1.8408h2.5606v2.001A5.25,5.25,0,0,1,666.7168,848.0472Z" />
                                        <path className="st8" d="M676.3564,849.0267a4.3408,4.3408,0,0,1-3.0615.9405,4.5136,4.5136,0,0,1-2.94-.82,2.2849,2.2849,0,0,1-.7-.88,3.8327,3.8327,0,0,1-.3-1.62V838.725a3.4,3.4,0,0,1,.88-2.5606,4.588,4.588,0,0,1,3.081-.92c2.04,0,3.1211.84,3.541,1.66a3.9117,3.9117,0,0,1,.3194,1.88v7.8624A3.0656,3.0656,0,0,1,676.3564,849.0267Zm-1.8007-10.0615a2.484,2.484,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4336,1.4336,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1416a2.2356,2.2356,0,0,0,.2392,1.3,1.3432,1.3432,0,0,0,1.0606.4209,1.5291,1.5291,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                        <path className="st8" d="M684.7754,849.6674l-2.5-10.5821.22,10.5821h-2.44V835.5443h3.3408l2.54,10.3623-.26-10.3623h2.3808v14.1231Z" />
                                        <path className="st8" d="M697.9365,849.0267a4.3408,4.3408,0,0,1-3.0615.9405,4.5136,4.5136,0,0,1-2.94-.82,2.2839,2.2839,0,0,1-.7-.88,3.8327,3.8327,0,0,1-.3-1.62V838.725a3.4,3.4,0,0,1,.88-2.5606,4.5878,4.5878,0,0,1,3.081-.92c2.04,0,3.1211.84,3.541,1.66a3.9129,3.9129,0,0,1,.3194,1.88v7.8624A3.0656,3.0656,0,0,1,697.9365,849.0267Zm-1.8008-10.0615a2.4835,2.4835,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4336,1.4336,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1416a2.2356,2.2356,0,0,0,.2392,1.3,1.3432,1.3432,0,0,0,1.0606.4209,1.5291,1.5291,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                        <path className="st8" d="M710.7959,849.6674l.24-12.2432-2.28,12.2432H706.335l-2.34-12.2432.1992,12.2432h-2.5605V835.5443h4.2412l1.66,9.8818,1.6806-9.8818h4.1807v14.1231Z" />
                                        <path className="st8" d="M716.3936,849.6674V835.5443h2.581v14.1231Z" />
                                        <path className="st8" d="M729.376,848.0472c-.6807,1.96-3.3008,1.92-3.6016,1.92a6.883,6.883,0,0,1-1.34-.14,2.9484,2.9484,0,0,1-2.3809-1.6806,3.8045,3.8045,0,0,1-.1992-1.4805v-7.6817c0-.24.0195-.5.04-.74a2.6887,2.6887,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.92-.8193,4.7043,4.7043,0,0,1,2.6406.64,2.4636,2.4636,0,0,1,.8808,1.04,3.12,3.12,0,0,1,.24,1.6006v2.16h-2.5606v-1.78c0-1.08-.1806-1.66-1.3408-1.66-1,0-1.2.62-1.2,1.54v7.5616c0,.9209.2,1.6006,1.28,1.6006,1.16,0,1.2607-.66,1.2607-1.74v-1.8408h2.5606v2.001A5.25,5.25,0,0,1,729.376,848.0472Z" />
                                        <path className="st8" d="M745.0547,847.807c-.32,1.14-1.2608,2.16-3.6611,2.16a4.5731,4.5731,0,0,1-2.9-.8,2.3975,2.3975,0,0,1-.74-.961,3.1206,3.1206,0,0,1-.2793-1.46v-2.0606h2.58v1.6406c0,.52-.04,1.12.46,1.4405a1.4432,1.4432,0,0,0,.8.2,1.2672,1.2672,0,0,0,.9-.2608c.38-.3.38-.92.38-1.88a1.7982,1.7982,0,0,0-.88-1.86l-2.46-1.66a3.2865,3.2865,0,0,1-1.1807-1.1611,3.8748,3.8748,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.9405c.8008-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.409,2.409,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6005,0-1.16.2207-1.16,1.4a2.6405,2.6405,0,0,0,.28,1.36,2.3076,2.3076,0,0,0,.54.46l2.52,1.7412a5.2989,5.2989,0,0,1,1.16,1.1,3.3625,3.3625,0,0,1,.5595,2.4405A8.0012,8.0012,0,0,1,745.0547,847.807Z" />
                                        <path className="st8" d="M754.9951,843.0853c-.4209.5205-1.02.7-2.3008.7h-2.2v5.8819H747.873V835.5443h5.2413a3.7061,3.7061,0,0,1,.7.06,1.9793,1.9793,0,0,1,1.3808,1,5.1619,5.1619,0,0,1,.3,2.1211v1.86C755.4951,841.9056,755.4346,842.5453,754.9951,843.0853Zm-2.06-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.1182,1.1182,0,0,0-.96-.3h-1.28v4.1406h1.26c.54,0,.8408-.06,1-.2793a1.8259,1.8259,0,0,0,.1807-.9805Z" />
                                        <path className="st8" d="M763.0146,849.6674v-6.462h-2.3808v6.462h-2.6006V835.5443h2.6006v5.541h2.3808v-5.541h2.6006v14.1231Z" />
                                        <path className="st8" d="M768.6123,849.6674V835.5443h6.8418v2.1406h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1807Z" />
                                        <path className="st8" d="M783.5332,849.6674c-.04-.08-.08-.1407-.12-.2207a2.57,2.57,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.0195-.6807-.08-.78-.66-.96-1.3408-.96h-1.24v6.2217h-2.6006V835.5443h5.2012a2.6406,2.6406,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.5205v1.52a3.5436,3.5436,0,0,1-.28,1.52,1.7327,1.7327,0,0,1-.3.38,2.6146,2.6146,0,0,1-1.24.5,2.8418,2.8418,0,0,1,.7793.28,2.3381,2.3381,0,0,1,1.1406,2.18v2.8809a3.2176,3.2176,0,0,0,.36,1.66.8363.8363,0,0,0,.24.2207Zm-.42-10.9424c0-.54-.08-.7207-.3-.9405a1.2035,1.2035,0,0,0-.9-.22h-1.36v3.86h1.36a1.1957,1.1957,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                        <path className="st8" d="M788.5322,849.6674V835.5443h6.8418v2.1406h-4.2412v3.32h4.001v2.1407h-4.001v4.3408h4.4414v2.1807Z" />
                                    </g>
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M658.5,773.4l-0.1,1.5L658,776l-0.5,0.7l-0.6,0.5l-1.2,0.5l-1.3,0.1l-2-0.3l-0.9-0.5l-0.6-0.8l-0.5-1
			l-0.2-1.3l-0.1-3.7v-2l0.1-1.2l0.2-1.2l0.5-1l0.7-0.8l1.1-0.5l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6
			L658.5,773.4L658.5,773.4z M653.4,762.2h-2v-2.3h2V762.2z M655.5,767.7l-0.1-0.6l-0.2-0.9l-0.6-0.4l-0.4-0.1l-0.6,0.2l-0.3,0.3
			l-0.2,0.6l-0.1,2.9v3.4v1l0.2,1l0.4,0.5l0.6,0.2l0.4-0.1l0.4-0.3l0.3-0.4l0.1-2.6L655.5,767.7z M657.1,762.2H655v-2.3h2.1V762.2z"
                                    />
                                    <path className="st8" d="M667.1,777.7l-3.4-7l0.1,7h-2.9v-14.1h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5H667.1z" />
                                    <path className="st8" d="M679.6,773.4l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L679.6,773.4z
			 M676.7,768.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H676.7z"/>
                                    <path className="st8" d="M682.1,777.7v-14.1h2.8v11.6h4.1v2.5H682.1z" />
                                    <path className="st8" d="M699.2,773.4L699,775l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L699.2,773.4z
			 M696.2,768.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H696.2z"/>
                                    <path className="st8" d="M707.3,777.7v-1.2L707,777l-0.8,0.6l-0.6,0.3h-0.8H704l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4v-2l0.1-3l0.1-1.5
			l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3v0.9H707v-0.6l-0.1-1.4l-0.4-0.4l-0.8-0.3l-0.5,0.1
			l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6v1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1v-1.1V772h-1.5v-2.3h4.2v7.9h-2.3V777.7z"/>
                                    <path className="st8" d="M712.3,777.7v-14.1h2.9v14.1H712.3z" />
                                    <path className="st8" d="M722.5,767.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L722.5,767.7z"/>
                                    <path className="st8" d="M727.4,768.9v-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8l0.5,0.7
			l0.3,0.9l0.1,2.6h-2.8v-0.9l-0.1-1l-0.1-0.6L732,766l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5l0.3,0.2
			l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6l-0.5-0.8
			l-0.4-1.4l-0.1-2.2L727.4,768.9z"/>
                                    <path className="st8" d="M743.2,777.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H743.2z" />
                                    <path className="st8" d="M759,767.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1l1.2,0.8
			l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8L754,775v-1.8h2.8v1.2
			l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L759,767.7z"/>
                                    <path className="st8" d="M764.2,777.7v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2h-1.3H767v5.1h-2.8V777.7z M767.8,770.2l0.7-0.2l0.4-0.6l0.1-1v-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1H767v4.1H767.8z"/>
                                    <path className="st8" d="M779.4,777.7v-6.1h-2.5v6.1H774v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H779.4z" />
                                    <path className="st8" d="M790.5,777.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H790.5z M787.9,762.2h-2v-2.3h2V762.2z
			 M789,768.1l-0.2-2.1l-0.3,2.1l-0.5,3.4h1.6L789,768.1z M791.5,762.2h-2.1v-2.3h2.1V762.2z"/>
                                    <path className="st8" d="M801,777.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1L802,771l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4H801V777.7z
			 M799.8,769.7l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5L799,766h-0.9v3.7H799.8z"/>
                                    <path className="st8" d="M805.8,777.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H805.8z" />
                                    <path className="st8" d="M654.9,791.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L654.9,791.7z"/>
                                    <path className="st8" d="M668.2,797.4l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L668.2,797.4z
			 M665.2,792.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H665.2z"/>
                                    <path className="st8" d="M670.1,801.7v-2.3l5.1-9.4h-4.5v-2.4h7.8v2.3l-5.3,9.4h5v2.4H670.1z" />
                                    <path className="st8" d="M680.5,801.7v-14.1h2.9v14.1H680.5z" />
                                    <path className="st8" d="M691.6,801.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H691.6z M690,792.1l-0.2-2.1l-0.3,2.1l-0.5,3.4
			h1.6L690,792.1z"/>
                                    <path className="st8" d="M696.2,801.7v-14.1h2.8v11.6h4.1v2.5H696.2z" />
                                    <path className="st8" d="M705.2,801.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H705.2z" />
                                    <path className="st8" d="M724.3,791.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7L723,796l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L724.3,791.7z"/>
                                    <path className="st8" d="M729.5,801.7v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8L736,796l-0.8,0.4
			l-0.9,0.2H733h-0.6v5.1H729.5z M733.1,794.2l0.7-0.2l0.4-0.6l0.1-1v-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1H733.1z"/>
                                    <path className="st8" d="M744.7,801.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H744.7z" />
                                    <path className="st8" d="M755.8,801.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H755.8z M753.2,786.2h-2v-2.3h2V786.2z
			 M754.2,792.1L754,790l-0.3,2.1l-0.5,3.4h1.6L754.2,792.1z M756.8,786.2h-2.1v-2.3h2.1V786.2z"/>
                                    <path className="st8" d="M766.3,801.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4h-3.2
			V801.7z M765,793.7l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7h1.6V793.7z"/>
                                    <path className="st8" d="M771,801.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H771z" />
                                    <path className="st8" d="M651.6,825.7V814h-2.3v-2.4h7.5v2.4h-2.3v11.7H651.6z" />
                                    <path className="st8" d="M658.4,825.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H658.4z" />
                                    <path className="st8" d="M667.1,816.9v-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8l0.5,0.7
			l0.3,0.9l0.1,2.6h-2.8v-0.9l-0.1-1l-0.1-0.6l-0.3-0.3l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5l0.3,0.2
			l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6l-0.5-0.8
			l-0.4-1.4l-0.1-2.2L667.1,816.9z"/>
                                    <path className="st8" d="M683,825.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H683z" />
                                    <path className="st8" d="M694.5,825.7l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8v-14.1h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8v14.1L694.5,825.7z"/>
                                    <path className="st8" d="M708.5,821.4l-0.1,1.5L708,824l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L708.5,821.4z
			 M705.6,816.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H705.6z"/>
                                    <path className="st8" d="M711,825.7v-14.1h2.8v11.6h4.1v2.5H711z" />
                                    <path className="st8" d="M728.1,821.4l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L728.1,821.4z
			 M725.1,816.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H725.1z"/>
                                    <path className="st8" d="M736.2,825.7v-1.2l-0.3,0.5l-0.8,0.6l-0.6,0.3h-0.8h-0.8l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4v-2l0.1-3
			l0.1-1.5l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3v0.9h-2.8v-0.6l-0.1-1.4l-0.4-0.4l-0.8-0.3
			l-0.5,0.1l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6v1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1v-1.1V820h-1.5v-2.3h4.2v7.9h-2.3
			V825.7z"/>
                                    <path className="st8" d="M741.2,825.7v-14.1h2.9v14.1H741.2z" />
                                    <path className="st8" d="M751.3,815.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7L750,820l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L751.3,815.7z"/>
                                    <path className="st8" d="M756.3,816.9v-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8l0.5,0.7
			l0.3,0.9l0.1,2.6h-2.8v-0.9l-0.1-1l-0.1-0.6l-0.3-0.3l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5l0.3,0.2
			l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6l-0.5-0.8
			l-0.4-1.4l-0.1-2.2L756.3,816.9z"/>
                                    <path className="st8" d="M772.1,825.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H772.1z" />
                                    <path className="st8" d="M777.7,825.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H777.7z" />
                                    <path className="st8" d="M796.8,815.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L796.8,815.7z"/>
                                    <path className="st8" d="M802,825.7v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2h-1.3h-0.6v5.1H802V825.7z M805.7,818.2l0.7-0.2l0.4-0.6l0.1-1v-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1H805.7z"/>
                                    <path className="st8" d="M817.2,825.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H817.2z" />
                                    <path className="st8" d="M828.4,825.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H828.4z M825.7,810.2h-2v-2.3h2V810.2z
			 M826.8,816.1l-0.2-2.1l-0.3,2.1l-0.5,3.4h1.6L826.8,816.1z M829.4,810.2h-2.1v-2.3h2.1V810.2z"/>
                                    <path className="st8" d="M838.8,825.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2L837,820h-1.1v5.7H833v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4h-3.2
			V825.7z M837.6,817.7l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1H836v3.7h1.6V817.7z"/>
                                    <path className="st8" d="M843.6,825.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H843.6z" />
                                    <path className="st8" d="M658.5,845.4l-0.1,1.5L658,848l-0.5,0.7l-0.6,0.5l-1.2,0.5l-1.3,0.1l-2-0.3l-0.9-0.5l-0.6-0.8l-0.5-1
			l-0.2-1.3l-0.1-3.7v-2l0.1-1.2l0.2-1.2l0.5-1l0.7-0.8l1.1-0.5l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6
			L658.5,845.4L658.5,845.4z M653.4,834.2h-2v-2.3h2V834.2z M655.5,839.7l-0.1-0.6l-0.2-0.9l-0.6-0.4l-0.4-0.1l-0.6,0.2l-0.3,0.3
			l-0.2,0.6l-0.1,2.9v3.4v1l0.2,1l0.4,0.5l0.6,0.2l0.4-0.1l0.4-0.3l0.3-0.4l0.1-2.6L655.5,839.7z M657.1,834.2H655v-2.3h2.1V834.2z"
                                    />
                                    <path className="st8" d="M667.1,849.7l-3.4-7l0.1,7h-2.9v-14.1h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5H667.1z" />
                                    <path className="st8" d="M679.6,845.4l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L679.6,845.4z
			 M676.7,840.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H676.7z"/>
                                    <path className="st8" d="M688.1,849.7l-2.6-7.5l-0.4-1.2l-0.5-1.9l0.2,1.4l0.1,1.5l0.1,7.7h-2.8v-14.1h3.4l2.3,6.9l0.6,1.7l0.4,1.7
			l-0.2-1.6l-0.1-1l-0.1-7.8h2.8v14.1L688.1,849.7z"/>
                                    <path className="st8" d="M702.1,845.4l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6h-1l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1l-0.1-0.7
			l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6L702.1,845.4z
			 M699.1,840.2l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9v2.1v3.2v1.1l0.2,1l0.5,0.5l0.5,0.1l0.6-0.2
			l0.4-0.5l0.1-1.1v-1.7v-3.9H699.1z"/>
                                    <path className="st8" d="M714.3,849.7v-5.8v-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3h-2.7l-1.6-7.1l-0.4-2.4l-0.3-1.5l-0.1-1.1
			v1.4l0.2,2.8v8h-2.7l-0.1-14.1h4.2l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1v14.1L714.3,849.7z"/>
                                    <path className="st8" d="M719.8,849.7v-14.1h2.9v14.1H719.8z" />
                                    <path className="st8" d="M729.9,839.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L729.9,839.7z"/>
                                    <path className="st8" d="M734.9,840.9v-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8l0.5,0.7
			l0.3,0.9l0.1,2.6H740v-0.9l-0.1-1l-0.1-0.6l-0.3-0.3l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5l0.3,0.2
			l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6l-0.5-0.8
			l-0.4-1.4l-0.1-2.2L734.9,840.9z"/>
                                    <path className="st8" d="M750.7,849.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H750.7z" />
                                    <path className="st8" d="M756.3,849.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H756.3z" />
                                    <path className="st8" d="M775.4,839.7v-0.3v-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9v-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3v1.3L775.4,839.7z"/>
                                    <path className="st8" d="M780.6,849.7v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2H784h-0.6v5.1h-2.8V849.7z M784.3,842.2l0.7-0.2l0.4-0.6l0.1-1v-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1H784.3z"/>
                                    <path className="st8" d="M795.8,849.7v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H795.8z" />
                                    <path className="st8" d="M807,849.7l-0.7-3.9H804l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H807z M804.3,834.2h-2v-2.3h2V834.2z M805.4,840.1
			l-0.2-2.1l-0.3,2.1l-0.5,3.4h1.6L805.4,840.1z M808,834.2h-2.1v-2.3h2.1V834.2z"/>
                                    <path className="st8" d="M817.4,849.7l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8v0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4h-3.2
			V849.7z M816.2,841.7l0.4-0.1l0.4-0.5l0.1-1.1v-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7h1.6V841.7z"/>
                                    <path className="st8" d="M822.2,849.7v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H822.2z" />
                                </g>
                            }
                        </g>
                    </g>
                </svg>
                <Zoom onClick={() => this.ToggleFullsreen()} />
                <div className="close-zoom"><FullscreenClose onClick={() => this.ToggleFullsreen()} /></div>
                <div className="close-zoom pos-bottom">
                    <div className={"scroll-indication" + (this.state.showIndicator ? ' show' : '')} onClick={() => this.ToggleFullsreen()}>
                        <div className="scroll-indication-text">Close for details</div>
                        <div className="scroll-indication-arrow"><ArrowDown /></div>
                    </div>
                </div>
                <div className="copyright-malik">&copy; Malik 2023</div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelSet,
        SelectedPath
    } = state.model;

    return {
        SelectedLanguage,
        ModelSet,
        SelectedPath
    };
}

const actionCreators = {
    setSelectedPath: modelActions.setSelectedPath
};

const connectedModel2 = connect(mapState, actionCreators)(Model2);
export { connectedModel2 as Model2 };
