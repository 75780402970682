import React, {useEffect, useState} from "react";
import {authenticationService} from "../../store/_services";

import {ScaleLoader} from "react-spinners";

function ProtectedComponent(props) {    
    const [profileAcquired, setProfileAcquired] = useState(false);

    useEffect(() => {
        authenticationService.getProfile().then(_ => {
            setProfileAcquired(true);
        }).catch(_ => {
            // something is definitely wrong.
            // the easiest way to try to fix it is to reload everything
            window.location.reload()}
        );
    }, []);
    
    if(profileAcquired){
        return <div>{props.children}</div>
    }
    return <div className="container vh-100">
        <div className="d-flex justify-content-center align-items-center vh-100">
            <ScaleLoader color={'lightblue'}/>
        </div>
    </div>
}

export default ProtectedComponent;