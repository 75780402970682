import _ from "lodash";
import { IPublicClientApplication } from "@azure/msal-browser";
import {LanguageLocale} from "./app-bar-state";

export class AppBarLoader {
  constructor() {
    const appbar_config = {
      b2c: {
        tenant: process.env.REACT_APP_B2C_TENANT,
        policies_names_signup_signin:
          process.env.REACT_APP_B2C_POLICIES_NAMES_SIGNUP_SIGNIN,
        client_id: process.env.REACT_APP_B2C_CLIENTID,
        scope: _.last(_.split(process.env.REACT_APP_B2C_SCOPE, "/")),
      },
      holos_url_api: process.env.REACT_APP_URL_API,
      mkp_url: process.env.REACT_APP_URL,
      bot: {
        directline_token: process.env.REACT_APP_WEBCHAT_DIRECTLINE_TOKEN,
      },
      mode: "production",
    };

    const appBarScript = document.querySelector(
      'script[src="libs/app-bar-stencil/app-bar-stencil.esm.js"]'
    );

    if (appBarScript) {
      return;
    }

    (function () {
      const appBarScript = document.createElement("script");

      appBarScript.setAttribute(
        "src",
        "libs/app-bar-stencil/app-bar-stencil.esm.js"
      );

      appBarScript.setAttribute("type", "module");

      appBarScript.onload = () => {
        const customElement = document.querySelector("app-bar");
        // setting up app-bar
        (customElement as any).config = appbar_config;
      };

      document.body.appendChild(appBarScript);
    })();
  }

  public async getMsalInstance(): Promise<IPublicClientApplication> {
    await customElements.whenDefined("app-bar");
    const appBarElement = document.querySelector("app-bar");

    await this.waitUntil(() => {
      return "config" in appBarElement! && "msalInstance" in appBarElement;
    });

    return (appBarElement as any).msalInstance;
  }

  public async getAccessToken(timeout: number = 5000): Promise<any> {
    await this.getMsalInstance();
    const appBarElement = document.querySelector("app-bar");

    for (let i = 0; i < 10; i++) {
      const result = await (appBarElement as any).getAccessToken();

      if (result !== null) {
        return result;
      }

      await this.delay(timeout / 10);
    }

    return null;
  }

  private async waitUntil(condition: Function) {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (condition) {
          resolve("foo");
          clearInterval(interval);
        }
      }, 500);
    });
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public setLanguage(languageLocale: LanguageLocale){
  const appBarElement = document.querySelector("app-bar") as any;
  appBarElement.setLanguage(languageLocale);
  }
}
