import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { mkpActions } from '../../../../../../store/_actions';

import { ReactComponent as FullscreenClose } from './../../../../../../Assets/images/icons/icon_fullscreen_2.svg';
import { ReactComponent as Zoom } from './../../../../../../Assets/images/icons/icon_zoom.svg';

import { ModelActiveSquareL1 as ModelActive } from './ModelActiveSquareL1';

class Model5 extends Component {
    constructor(props) {
        super(props);

        this.appUrl = process.env.REACT_APP_URL;

        // initial state
        this.state = {
            Zoom: false
        }

        // event handlers
        this.ToggleFullsreen = this.ToggleFullsreen.bind(this);
    }

    ToggleFullsreen() {
        this.setState({ Zoom: !this.state.Zoom });
        this.props.handleZoom();
    }

    render() {
        const { SelectedLanguage } = this.props

        return (
            <div className={"slide-container square" + (this.state.Zoom ? ' magnify' : '')}>
                <ModelActive />

                <svg version="1.1" className="model model5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 850.4 850.4" xmlSpace="preserve">
                    <g id="Background_1_">
                        <rect id="Area" x="0" className="st0" width="850.39" height="850.39" />
                    </g>
                    <g id="Shapes">
                        <defs>
                            <path id="SVGID_1_" d="M245.33,671.86c37.78-53.39,68.63-127.75,103.2-163.9c34.57-36.15,55.13-56.07,94.14-76.51
			c39.01-20.44,81.63-38.76,139.91-46.15c-13.76,29.92-34.64,83.79-72.44,134.13c-34.2,39.47-81.7,86.67-125.83,112.07
			C340.18,656.9,294.36,666.8,245.33,671.86z"/>

                            <linearGradient id="SVGID2" gradientUnits="userSpaceOnUse" x1="245.334" y1="528.5731" x2="582.5849" y2="528.5731">
                                <stop offset="0" style={{ stopColor: 'rgba(174, 15, 35,1)' }} />
                                <stop offset="1" style={{ stopColor: 'rgba(8, 111, 68,1)' }} />
                            </linearGradient>
                        </defs>
                        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible', fillRule: 'evenodd', clipRule: 'evenodd', fill: 'none' }} />
                        <clipPath id="SVGID_3_">
                            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                        </clipPath>
                    </g>
                    <g id="Curves_1_">
                        <path className="st1" d="M0.13,737.8c292.82-19.43,232.23-154.43,365.61-272.04c84.61-74.61,127.04-60.95,188.19-108.28
		s70.52-173.79,227.99-220.74"/>
                        <path className="st2" d="M0.66,753.4c98.25-5.29,172.34-22.51,221.46-60.1c49.12-37.59,74.99-119.94,110.89-165.42
		s80.41-83.06,130.82-107.46c50.42-24.4,116.74-38.48,171.67-38.95"/>
                        <path className="st3" d="M748.93,385.38l8.52,0.75c1.18,0.1,2.05,1.14,1.94,2.32c-0.1,1.18-1.14,2.05-2.32,1.94l-8.52-0.75
		c-1.18-0.1-2.05-1.14-1.94-2.32C746.72,386.15,747.76,385.28,748.93,385.38z M731.9,383.88l8.52,0.75c1.18,0.1,2.05,1.14,1.94,2.32
		c-0.1,1.18-1.14,2.05-2.32,1.94l-8.52-0.75c-1.18-0.1-2.05-1.14-1.94-2.32C729.69,384.65,730.72,383.78,731.9,383.88z
		 M714.86,382.39l8.52,0.75c1.18,0.1,2.05,1.14,1.94,2.32c-0.1,1.18-1.14,2.05-2.32,1.94l-8.52-0.75c-1.18-0.1-2.05-1.14-1.94-2.32
		C712.65,383.16,713.69,382.29,714.86,382.39z M697.73,381.18l7.7,0.38l0.92,0.08c1.18,0.1,2.05,1.14,1.94,2.32
		c-0.1,1.18-1.14,2.05-2.32,1.94l-0.76-0.07l-7.7-0.38c-1.18-0.06-2.09-1.06-2.03-2.24C695.55,382.03,696.55,381.12,697.73,381.18z
		 M680.65,380.33l8.54,0.43c1.18,0.06,2.09,1.06,2.03,2.24c-0.06,1.18-1.06,2.09-2.24,2.03l-8.54-0.43
		c-1.18-0.06-2.09-1.06-2.03-2.24C678.47,381.17,679.47,380.27,680.65,380.33z M663.51,379.77l8.55,0.23
		c1.18,0.03,2.11,1.02,2.08,2.2c-0.03,1.18-1.01,2.11-2.2,2.08l-8.55-0.23c-1.18-0.03-2.11-1.02-2.08-2.2
		C661.35,380.67,662.33,379.73,663.51,379.77z M646.42,379.3l8.55,0.23c1.18,0.03,2.11,1.01,2.08,2.2c-0.03,1.18-1.01,2.11-2.2,2.08
		l-8.55-0.23c-1.18-0.03-2.11-1.02-2.08-2.2C644.26,380.2,645.24,379.27,646.42,379.3z"/>
                        <path className="st3" d="M739.86,421.64l6.75,5.25c0.93,0.72,1.1,2.07,0.38,3c-0.72,0.93-2.07,1.1-3,0.38l-6.75-5.25
		c-0.93-0.72-1.1-2.07-0.38-3C737.58,421.09,738.93,420.92,739.86,421.64z M726.1,411.26l3.03,2.04l3.98,3.09
		c0.93,0.72,1.1,2.07,0.38,3c-0.72,0.93-2.07,1.1-3,0.38l-3.75-2.92l-3.03-2.04c-0.98-0.66-1.24-1.99-0.58-2.97
		C723.79,410.86,725.12,410.6,726.1,411.26z M711.52,402.08l6.98,4.07l0.51,0.34c0.98,0.66,1.24,1.99,0.58,2.97
		s-1.99,1.24-2.97,0.58l-0.28-0.19l-6.98-4.07c-1.02-0.59-1.36-1.9-0.77-2.92C709.19,401.83,710.5,401.49,711.52,402.08z
		 M696.18,394.19l7.69,3.73c1.06,0.52,1.51,1.79,0.99,2.86c-0.52,1.06-1.79,1.51-2.86,0.99l-7.69-3.73
		c-1.06-0.52-1.51-1.79-0.99-2.86C693.84,394.11,695.11,393.67,696.18,394.19z M680.14,387.78l7.97,3.08
		c1.1,0.43,1.65,1.66,1.22,2.76c-0.43,1.1-1.66,1.65-2.76,1.22l-7.97-3.08c-1.1-0.43-1.65-1.66-1.22-2.76
		C677.8,387.9,679.04,387.35,680.14,387.78z M663.37,382.92l0.6,0.12l7.8,2.26c1.13,0.33,1.78,1.52,1.45,2.65
		c-0.33,1.13-1.52,1.78-2.65,1.45l-7.44-2.18l-0.6-0.12c-1.16-0.23-1.91-1.36-1.67-2.52C661.08,383.44,662.21,382.69,663.37,382.92z
		 M646.6,379.56l8.38,1.68c1.16,0.23,1.91,1.36,1.67,2.52c-0.23,1.16-1.36,1.91-2.52,1.67l-8.38-1.68
		c-1.16-0.23-1.91-1.36-1.67-2.52C644.32,380.07,645.44,379.32,646.6,379.56z"/>
                        <path className="st4" d="M201.58,676.45c78.83-5.21,134.32-16.74,189.46-49.51c55.14-32.77,90.25-68.45,133.38-131
		s83.01-187.45,125.39-244.29c42.38-56.84,88.64-82.33,128.89-96.75"/>
                        <path className="st5" d="M179,675.22l1.16-0.06l7.37-0.42c1.18-0.07,2.19,0.84,2.25,2.01c0.07,1.18-0.84,2.19-2.01,2.25l-7.39,0.42
		l-1.16,0.06c-1.18,0.06-2.18-0.85-2.24-2.03C176.91,676.28,177.82,675.27,179,675.22z M161.94,676.02l4.71-0.19l3.81-0.19
		c1.18-0.06,2.18,0.85,2.24,2.03c0.06,1.18-0.85,2.18-2.03,2.24l-3.86,0.19l-4.71,0.19c-1.18,0.05-2.17-0.87-2.22-2.05
		C159.84,677.06,160.76,676.06,161.94,676.02z M144.88,676.56l8.55-0.21c1.18-0.03,2.16,0.9,2.19,2.08c0.03,1.18-0.9,2.16-2.08,2.19
		l-8.55,0.21c-1.18,0.03-2.16-0.9-2.19-2.08C142.77,677.57,143.7,676.58,144.88,676.56z M127.84,676.57l8.55,0.03
		c1.18,0,2.13,0.96,2.13,2.15c0,1.18-0.96,2.13-2.15,2.13l-8.55-0.03c-1.18,0-2.13-0.96-2.13-2.15
		C125.7,677.52,126.66,676.57,127.84,676.57z M110.81,676.27l7.85,0.27l0.63,0c1.18,0,2.13,0.96,2.13,2.15
		c0,1.18-0.96,2.13-2.15,2.13l-0.76,0l-7.85-0.27c-1.18-0.04-2.1-1.03-2.06-2.21S109.64,676.23,110.81,676.27z M93.78,675.42
		l8.54,0.47c1.18,0.06,2.08,1.07,2.02,2.25c-0.06,1.18-1.07,2.08-2.25,2.02l-8.54-0.47c-1.18-0.06-2.08-1.07-2.02-2.25
		C91.59,676.26,92.6,675.35,93.78,675.42z"/>
                        <path className="st5" d="M178.75,676.29l1.19-0.17l7.37-0.83c1.17-0.13,2.23,0.71,2.36,1.89c0.13,1.17-0.71,2.23-1.89,2.36l-7.24,0.81
		l-1.19,0.17c-1.17,0.17-2.25-0.64-2.42-1.81C176.77,677.55,177.58,676.46,178.75,676.29z M161.78,678.94l5.03-0.92l3.48-0.5
		c1.17-0.17,2.25,0.64,2.42,1.81c0.17,1.17-0.64,2.25-1.81,2.42l-3.32,0.48l-5.03,0.92c-1.16,0.21-2.27-0.56-2.49-1.72
		C159.84,680.27,160.61,679.15,161.78,678.94z M144.95,682.43l8.34-1.87c1.15-0.26,2.3,0.47,2.55,1.62c0.26,1.15-0.47,2.3-1.62,2.55
		l-8.34,1.87c-1.15,0.26-2.3-0.47-2.55-1.62S143.8,682.69,144.95,682.43z M128.31,686.92l4.38-1.3l3.91-1.03
		c1.14-0.3,2.31,0.38,2.61,1.52c0.3,1.14-0.38,2.31-1.52,2.61l-3.79,1l-4.38,1.29c-1.13,0.33-2.32-0.31-2.66-1.44
		C126.53,688.44,127.17,687.25,128.31,686.92z M111.92,691.95l8.15-2.58c1.13-0.36,2.33,0.27,2.68,1.39
		c0.36,1.13-0.27,2.33-1.39,2.68l-8.15,2.58c-1.13,0.36-2.33-0.27-2.68-1.39C110.17,693.51,110.8,692.31,111.92,691.95z
		 M95.64,697.22l8.13-2.64c1.12-0.37,2.33,0.25,2.69,1.37c0.37,1.12-0.25,2.33-1.37,2.69l-8.13,2.64c-1.12,0.37-2.33-0.25-2.69-1.37
		C93.9,698.79,94.51,697.59,95.64,697.22z"/>
                        <path className="st5" d="M179.4,673.5l1.46,0.16l7,0.59c1.18,0.1,2.05,1.13,1.95,2.31c-0.1,1.18-1.13,2.05-2.31,1.95l-7.11-0.6
		l-1.46-0.16c-1.17-0.13-2.02-1.19-1.89-2.36S178.22,673.37,179.4,673.5z M162.52,671.43l4.69,0.72l3.69,0.41
		c1.17,0.13,2.02,1.19,1.89,2.36s-1.19,2.02-2.36,1.89l-3.87-0.43l-4.69-0.72c-1.17-0.18-1.97-1.27-1.79-2.44
		C160.26,672.06,161.35,671.26,162.52,671.43z M145.83,668.35l8.36,1.78c1.15,0.25,1.89,1.38,1.65,2.54
		c-0.25,1.15-1.38,1.89-2.54,1.65l-8.36-1.78c-1.15-0.25-1.89-1.38-1.65-2.54C143.54,668.84,144.67,668.1,145.83,668.35z
		 M129.72,663.68l2.68,1l5.31,1.52c1.13,0.33,1.79,1.51,1.46,2.65c-0.33,1.13-1.51,1.79-2.65,1.46l-5.62-1.62l-2.68-1
		c-1.11-0.41-1.67-1.64-1.25-2.75C127.38,663.83,128.62,663.27,129.72,663.68z M114.1,657.18l7.55,3.49l0.07,0.02
		c1.11,0.41,1.67,1.64,1.25,2.75c-0.41,1.11-1.64,1.67-2.75,1.25l-0.37-0.14l-7.55-3.49c-1.07-0.5-1.54-1.77-1.04-2.84
		C111.75,657.15,113.03,656.68,114.1,657.18z M99.11,649.27l7.5,4.1c1.04,0.57,1.42,1.87,0.85,2.9c-0.57,1.04-1.87,1.42-2.9,0.85
		l-7.5-4.1c-1.04-0.57-1.42-1.87-0.85-2.9C96.77,649.08,98.07,648.7,99.11,649.27z"/>
                    </g>
                    <g id="Titles">
                        {SelectedLanguage === "en-GB" &&
                            <>
                                <g>
                                    <path className="st6" d="M461.1748,825.2172v4.1611h3.8613v1.54h-3.8613V837.76h-1.78v-14.123h6.2217v1.58Z" />
                                    <path className="st6" d="M474.1963,837.52a4.1816,4.1816,0,0,1-2.041.44,3.7708,3.7708,0,0,1-2.58-.82,2.6474,2.6474,0,0,1-.58-.9609,6.4624,6.4624,0,0,1-.24-1.9395V823.6371h1.78v9.3613a8.694,8.694,0,0,0,.16,2.5606,1.3565,1.3565,0,0,0,.84.78,1.9,1.9,0,0,0,.7.1006,1.4075,1.4075,0,0,0,1.28-.52,1.2707,1.2707,0,0,0,.24-.5,11.7319,11.7319,0,0,0,.08-1.74v-10.042h1.78v10.542C475.6162,835.4994,475.5156,836.8,474.1963,837.52Z" />
                                    <path className="st6" d="M483.0957,825.2172V837.76h-1.74V825.2172h-2.6807v-1.58h7.1016v1.58Z" />
                                    <path className="st6" d="M494.2764,837.52a4.1817,4.1817,0,0,1-2.041.44,3.7706,3.7706,0,0,1-2.58-.82,2.646,2.646,0,0,1-.58-.9609,6.4624,6.4624,0,0,1-.24-1.9395V823.6371h1.78v9.3613a8.6931,8.6931,0,0,0,.16,2.5606,1.3564,1.3564,0,0,0,.84.78,1.9008,1.9008,0,0,0,.7.1006,1.4076,1.4076,0,0,0,1.28-.52,1.2709,1.2709,0,0,0,.24-.5,11.7138,11.7138,0,0,0,.08-1.74v-10.042h1.78v10.542C495.6963,835.4994,495.5957,836.8,494.2764,837.52Z" />
                                    <path className="st6" d="M505.1562,837.76l-2.4-6.2011h-1.461V837.76h-1.74v-14.123h3.081a3.1826,3.1826,0,0,1,3.74,3.7607,10.3211,10.3211,0,0,1-.14,1.76,3.0883,3.0883,0,0,1-.58,1.4805,2.1039,2.1039,0,0,1-1.1806.7207l2.5205,6.4013Zm-.7-11.583c-.36-1.0195-1.44-.9394-2.3008-.9394h-.86v4.94h.9209a4.2906,4.2906,0,0,0,1.54-.16c.88-.3593.86-1.6.86-2.34A4.7371,4.7371,0,0,0,504.4561,826.1771Z" />
                                    <path className="st6" d="M510.2139,837.76v-14.123h6.2216v1.58h-4.4609v4.2011h3.9v1.6006h-3.9v5.16h4.6006v1.581Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M366.2393,831.0785a3.5453,3.5453,0,0,1-2.8.8809H361.959V837.76h-1.7608v-14.123h3.6612a2.6446,2.6446,0,0,1,2.38.94,4.8279,4.8279,0,0,1,.74,3.3408A5.37,5.37,0,0,1,366.2393,831.0785Zm-1.02-4.4209c-.16-1.3994-1.2-1.42-2.3408-1.42h-.92v5.1806h1.18c.78,0,1.581.04,1.9609-.8193a3.9559,3.9559,0,0,0,.16-1.5811C365.26,827.558,365.26,827.1185,365.22,826.6576Z" />
                                    <path className="st6" d="M375.5391,837.76l-.5-2.7207h-3.28l-.4805,2.7207h-1.82l3.041-14.123h1.76L377.38,837.76Zm-2.1807-11.5019-1.3,7.2617h2.6807Z" />
                                    <path className="st6" d="M385.44,837.3607a3.8664,3.8664,0,0,1-2.2012.6,3.1691,3.1691,0,0,1-3.1807-1.94,4.7253,4.7253,0,0,1-.22-1.58v-1.5605h1.66v.7a5.5623,5.5623,0,0,0,.16,1.86,1.5769,1.5769,0,0,0,1.58,1.02,1.71,1.71,0,0,0,1.6211-.9209,1.9756,1.9756,0,0,0,.16-.84,3.2055,3.2055,0,0,0-1.32-2.5l-1.9-1.961a4.4073,4.4073,0,0,1-1.6211-3.541,5.0947,5.0947,0,0,1,.16-1.34c.28-.92,1.06-1.94,3.1211-1.94a2.7122,2.7122,0,0,1,2.86,1.8408,7.445,7.445,0,0,1,.12,1.64v.9h-1.5606c-.0195-.88-.0195-.66-.0195-1.16a1.5339,1.5339,0,0,0-.7608-1.58,1.8061,1.8061,0,0,0-.74-.1406,1.4182,1.4182,0,0,0-1.06.38,1.4542,1.4542,0,0,0-.28.3809,2.407,2.407,0,0,0-.1407.94,2.5326,2.5326,0,0,0,.12.82,5.3092,5.3092,0,0,0,1.28,1.7l2.2207,2.3613c.16.18.3.3594.44.54a3.8636,3.8636,0,0,1,.78,2.52A3.0108,3.0108,0,0,1,385.44,837.3607Z" />
                                    <path className="st6" d="M393.7588,825.2181V837.76h-1.74v-12.542h-2.6807v-1.581H396.44v1.581Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M403.1616,771.7308v13.29h-2.8823v-13.29h-2.86v-2.2441h8.6255v2.2441Z" />
                                    <path className="st6" d="M416.5386,784.3168a4.7775,4.7775,0,0,1-3.3667,1.0342,4.9662,4.9662,0,0,1-3.2344-.9024,2.5137,2.5137,0,0,1-.77-.9678,4.2042,4.2042,0,0,1-.33-1.7822v-8.7139a3.7365,3.7365,0,0,1,.9683-2.8164,5.042,5.042,0,0,1,3.3881-1.0117c2.2447,0,3.4327.9238,3.8946,1.8262a4.2964,4.2964,0,0,1,.352,2.0683v8.6475A3.3728,3.3728,0,0,1,416.5386,784.3168Zm-1.98-11.0674a2.7254,2.7254,0,0,0-.1763-1.2549,1.2118,1.2118,0,0,0-1.188-.6816,1.5788,1.5788,0,0,0-.9243.2861c-.6377.4395-.55,1.1221-.55,1.76v7.8554a2.4564,2.4564,0,0,0,.2642,1.43,1.476,1.476,0,0,0,1.166.4629,1.6784,1.6784,0,0,0,.9023-.2646c.5279-.3741.5059-.99.5059-1.5625Z" />
                                    <path className="st6" d="M429.8037,781.6986a3.2606,3.2606,0,0,1-1.4521,2.8379,4.6007,4.6007,0,0,1-2.4649.4844h-4.5981V769.4867h5.2583a3.2591,3.2591,0,0,1,2.1347.7695,3.8864,3.8864,0,0,1,1.1436,3.3233V780.84C429.8252,781.1263,429.8252,781.4125,429.8037,781.6986Zm-2.8389-8.3613a1.526,1.526,0,0,0-.3955-1.32,1.9146,1.9146,0,0,0-1.2988-.2647h-1.1216v11.002h1.0122a5.0437,5.0437,0,0,0,.8135-.044c.9248-.1758.99-.8584.99-1.6279Z" />
                                    <path className="st6" d="M439.0215,785.0209l-.375-2.53h-2.64l-.4179,2.53h-2.86l3.0137-15.5342h3.2793l2.9043,15.5342Zm-1.6289-11.5957-1.0342,6.667h1.9805Z" />
                                    <path className="st6" d="M449.23,780.4222v4.5987h-2.9268V780.224l-3.1015-10.7373h3.0136l1.585,7.833,1.5176-7.833h3.0146Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M65.2256,255.306H46.168V242.8627h1.6474v10.6347h6.8018v-9.6367H56.291v9.6367h7.2608V242.1879h1.6738Z" />
                                    <path className="st6" d="M65.2256,231.3588v1.62L46.168,239.97V237.972l16.5742-5.8574L46.168,226.1224v-1.917Z" />
                                    <path className="st6" d="M62.9043,207.3851a7.1345,7.1345,0,0,1,2.2939,2.9961,8.1923,8.1923,0,0,1,.5127,2.9961,7.1688,7.1688,0,0,1-2.2129,5.5332c-1.2685,1.2149-3.6982,2.7266-7.8554,2.7266-1.0254,0-4.8584-.0274-7.585-2.5371a7.15,7.15,0,0,1-1.8349-2.6182,8.1835,8.1835,0,0,1-.54-3.1045,7.3026,7.3026,0,0,1,3.1856-6.37,11.6936,11.6936,0,0,1,6.7207-1.7822A12.6909,12.6909,0,0,1,62.9043,207.3851ZM49.2188,209.058a6.1549,6.1549,0,0,0-1.377,1.6738,5.4688,5.4688,0,0,0-.5938,2.6455c0,4.7237,4.5616,6.3428,8.4493,6.3428.6748,0,4.6425-.0264,6.8828-2.3477a5.4593,5.4593,0,0,0,1.5117-3.914,5.1007,5.1007,0,0,0-1.7275-4.1035c-2.3213-2.1319-6.1006-2.2129-6.7754-2.2129A10.3754,10.3754,0,0,0,49.2188,209.058Z" />
                                    <path className="st6" d="M65.2256,200.74H46.168v-1.8349H63.5518V188.3773h1.6738Z" />
                                    <path className="st6" d="M58.7744,171.6381a7.3271,7.3271,0,0,1,4.8047,1.4843c.998.8643,2.1318,2.4834,2.1318,5.5332a8.0183,8.0183,0,0,1-1.4843,4.8858,6.0631,6.0631,0,0,1-1.5655,1.3769c-1.7549.9991-3.6982.918-7.666.918H46.168v-1.7822H58.5312a7.0046,7.0046,0,0,0,3.1309-.5664,3.71,3.71,0,0,0,1.6465-1.6465,6.4669,6.4669,0,0,0,.73-3.1582,5.6528,5.6528,0,0,0-1.5928-4.211c-1.2959-1.08-2.9961-1.08-4.5351-1.08H46.168v-1.8076h8.665C56.1562,171.5844,57.4512,171.5844,58.7744,171.6381Z" />
                                    <path className="st6" d="M47.7607,160.2689H65.2256v1.8086H47.7607V168.07H46.168v-13.82h1.5927Z" />
                                    <path className="st6" d="M65.2256,150.7094H46.168v-1.8086H65.2256Z" />
                                    <path className="st6" d="M62.9043,130.1664a7.1338,7.1338,0,0,1,2.2939,2.9961,8.1918,8.1918,0,0,1,.5127,2.9961,7.1688,7.1688,0,0,1-2.2129,5.5332c-1.2685,1.2148-3.6982,2.7265-7.8554,2.7265-1.0254,0-4.8584-.0273-7.585-2.5371a7.1492,7.1492,0,0,1-1.8349-2.6181,8.1843,8.1843,0,0,1-.54-3.1045,7.3025,7.3025,0,0,1,3.1856-6.37,11.6934,11.6934,0,0,1,6.7207-1.7823A12.691,12.691,0,0,1,62.9043,130.1664Zm-13.6855,1.6728a6.1558,6.1558,0,0,0-1.377,1.6739,5.4685,5.4685,0,0,0-.5938,2.6455c0,4.7236,4.5616,6.3427,8.4493,6.3427.6748,0,4.6425-.0263,6.8828-2.3476a5.4594,5.4594,0,0,0,1.5117-3.9141,5.1008,5.1008,0,0,0-1.7275-4.1035c-2.3213-2.1318-6.1006-2.2129-6.7754-2.2129A10.3768,10.3768,0,0,0,49.2188,131.8392Z" />
                                    <path className="st6" d="M65.2256,110.5648,48.76,121.74H65.2256v1.7813H46.168V121.74l16.3037-11.31-16.3037.1357v-1.7822H65.2256Z" />
                                    <path className="st6" d="M65.2256,90.4769l-5.75,2.1866v8.5292l5.75,2.1866v1.998L46.168,97.6029V96.0912l19.0576-7.585ZM48.2734,96.8744l9.7452,3.7246V93.2035Z" />
                                    <path className="st6" d="M65.2256,72.764l-8.4492,6.02v4.4541h8.4492v1.8349H46.168V79.7015c0-1.0527-.0264-2.1328.0273-3.2129a5.588,5.588,0,0,1,2.5371-4.7236,5.8362,5.8362,0,0,1,2.8887-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2485,8.2485,0,0,1,.4043,2.3213l8.4492-6.1816Zm-15.8184.6211c-1.4843.8907-1.6191,2.4287-1.6191,3.9678v5.8848h7.4228V78.7562c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.7519a3.5806,3.5806,0,0,0-2.24-.6749A4.0516,4.0516,0,0,0,49.4072,73.3851Z" />
                                    <path className="st6" d="M57.3438,60.5326h7.8818v1.7812H57.3438l-11.1758,7.1V67.2806l9.4746-5.8847-9.4746-6.127V53.0824Z" />
                                </g>
                            <g className="st7">
                                    <path className="st6" d="M85.4951,255.9261a3.4535,3.4535,0,0,1,2.8218,3.23,2.551,2.551,0,0,1-1.1221,2.2266,1.8643,1.8643,0,0,1-.4248.2212h5.6607v.9858H80.0215v-.9858h1.3262a1.9387,1.9387,0,0,1-.5269-.3062,2.5672,2.5672,0,0,1-.9692-2.1416,3.0671,3.0671,0,0,1,.4253-1.6826,4.5352,4.5352,0,0,1,3.8242-1.7173A5.9119,5.9119,0,0,1,85.4951,255.9261Zm-2.9575,1.0879a2.3208,2.3208,0,0,0-1.7681,2.3116,2.2442,2.2442,0,0,0,.2549,1.0537,2.4931,2.4931,0,0,0,1.6831,1.105,7.8955,7.8955,0,0,0,1.2578.1191,3.4135,3.4135,0,0,0,2.9068-.8838,2.107,2.107,0,0,0,.476-1.4111c0-1.87-1.8188-2.5157-3.3828-2.5157A4.6381,4.6381,0,0,0,82.5376,257.014Z" />
                                    <path className="st6" d="M87.7051,247.8339a1.3826,1.3826,0,0,1-1.0542.3569,2.2591,2.2591,0,0,1,.6972.4253,3.3052,3.3052,0,0,1,.9517,2.4477,2.5566,2.5566,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6119,1.9818,1.9818,0,0,1-1.2744-.4419,4.7171,4.7171,0,0,1-1.2412-2.4986c-.1357-.51-.289-1.02-.3906-1.53l-.2041-.9521h-.4248a2.0516,2.0516,0,0,0-.7822.085c-.5269.17-1.0879.6289-1.0708,1.853,0,1.0366.289,1.5639.6967,1.8359a2.3437,2.3437,0,0,0,1.2578.3057l-.187,1.105a4.1456,4.1456,0,0,1-1.4106-.4761,3.047,3.047,0,0,1-1.2407-2.7026A2.5424,2.5424,0,0,1,82.98,247.222H85.58c.7822,0,1.3261.0171,1.5639-.2719a1.5274,1.5274,0,0,0,.1871-.9351H88.13C88.13,246.9672,88.0449,247.494,87.7051,247.8339Zm-3.8589.3569.1362.51a15.428,15.428,0,0,0,1.105,3.4336,1.2392,1.2392,0,0,0,.9858.51,1.3432,1.3432,0,0,0,.5777-.1528,1.6333,1.6333,0,0,0,.7651-1.479,2.9677,2.9677,0,0,0-1.53-2.6006,3.1789,3.1789,0,0,0-1.4282-.2212Z" />
                                    <path className="st6" d="M88.2827,241.8329a1.4545,1.4545,0,0,1-.0171.272,1.13,1.13,0,0,1-.7138,1.0708,6.3652,6.3652,0,0,1-1.6148.1191H80.8716v1.7h-.85v-1.7h-3.45v-.9692h3.45v-1.7847h.85v1.7847H86.209c.4761.0171,1.19.0171,1.19-.6626a2.1277,2.1277,0,0,0-.51-1.1221h1.0195A2.1866,2.1866,0,0,1,88.2827,241.8329Z" />
                                    <path className="st6" d="M88.13,233.078H83.71c-.2548,0-.51.0166-.7822.0166-.7988.0171-1.5805.1191-1.9717.9351a2.2718,2.2718,0,0,0-.187.9521,2.1644,2.1644,0,0,0,1.1392,2.04,5.8833,5.8833,0,0,0,2.72.34H88.13v1.02H76.1289v-1.02h5.1846a2.1759,2.1759,0,0,1-.4419-.272,2.7017,2.7017,0,0,1-1.02-2.2436,2.8087,2.8087,0,0,1,1.0029-2.2266c.6123-.459,1.2749-.5273,2.7026-.5273H88.13Z" />
                                    <path className="st6" d="M85.665,217.2684a3.5641,3.5641,0,0,1,2.669,3.5015,3.4078,3.4078,0,0,1-1.36,2.8047,4.5747,4.5747,0,0,1-2.8047.833,5.5277,5.5277,0,0,1-2.0571-.374,3.4154,3.4154,0,0,1-2.2944-3.3316,3.3086,3.3086,0,0,1,.9009-2.38,4.6164,4.6164,0,0,1,3.3144-1.2749A5.4311,5.4311,0,0,1,85.665,217.2684Zm-4.9975,3.4507a2.4849,2.4849,0,0,0,.459,1.4619,3.53,3.53,0,0,0,3.0429,1.1558,3.5931,3.5931,0,0,0,2.8047-1.0708,2.3474,2.3474,0,0,0,.4761-1.5127c0-1.19-.7481-2.6182-3.3149-2.6348C81.5005,218.0844,80.6675,219.4271,80.6675,220.7191Z" />
                                    <path className="st6" d="M80.8716,212.4906H88.13v1.0029H80.8716v1.7847h-.85v-1.7847H78.4746a4.8872,4.8872,0,0,1-1.5635-.102c-.51-.17-1.02-.5611-1.02-1.7a5.2094,5.2094,0,0,1,.1192-1.0371h.9858a2.3678,2.3678,0,0,0-.2212.9692.698.698,0,0,0,.2212.5947c.3227.3228.646.272,1.3935.272h1.6319v-1.8359h.85Z" />
                                    <path className="st6" d="M86.9229,198.0927a2.93,2.93,0,0,1,1.4111,2.77,3.3744,3.3744,0,0,1-1.2241,2.72,4.1231,4.1231,0,0,1-.9517.5439l-.34-.9858A2.1991,2.1991,0,0,0,87.416,200.88a2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.31,1.31,0,0,0-.7139.2212c-.3569.2549-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6348a2.0226,2.0226,0,0,1-1.2749.4248,2.2255,2.2255,0,0,1-1.581-.6968,3.4178,3.4178,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9887,2.2181,2.2181,0,0,1,1.3086-.8838l.2554.9516c-.9351.187-1.207,1.1563-1.207,1.9717a2.4153,2.4153,0,0,0,.3061,1.3262,1.1368,1.1368,0,0,0,.3569.3569,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.853,2.0162,2.0162,0,0,1,1.2408-.3906A2.42,2.42,0,0,1,86.9229,198.0927Z" />
                                    <path className="st6" d="M89.3027,192.6864c1.938.7646,2.941,1.0537,2.941,2.3457a1.7877,1.7877,0,0,1-.3233,1.0708H90.9a1.7524,1.7524,0,0,0,.3911-1.0537c0-.5781-.374-.731-1.2236-1.0879-.2554-.102-.51-.2212-.7652-.3232l-1.1728-.4248-8.1084,2.8046v-.9687l6.9014-2.3628-6.9014-2.6348v-1.02Z" />
                                    <path className="st6" d="M86.9229,181.62a2.93,2.93,0,0,1,1.4111,2.77,3.3742,3.3742,0,0,1-1.2241,2.72,4.1236,4.1236,0,0,1-.9517.544l-.34-.9859a2.1984,2.1984,0,0,0,1.5976-2.2607,2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.3092,1.3092,0,0,0-.7139.2212c-.3569.2548-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6347a2.022,2.022,0,0,1-1.2749.4248,2.2251,2.2251,0,0,1-1.581-.6968,3.4175,3.4175,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9888,2.2186,2.2186,0,0,1,1.3086-.8838l.2554.9517c-.9351.187-1.207,1.1557-1.207,1.9722a2.4144,2.4144,0,0,0,.3061,1.3256,1.1378,1.1378,0,0,0,.3569.357,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.8531,2.0162,2.0162,0,0,1,1.2408-.3906A2.42,2.42,0,0,1,86.9229,181.62Z" />
                                    <path className="st6" d="M88.2827,176.4008a1.4529,1.4529,0,0,1-.0171.2719,1.13,1.13,0,0,1-.7138,1.0708,6.3606,6.3606,0,0,1-1.6148.1192H80.8716v1.7h-.85v-1.7h-3.45v-.9693h3.45v-1.7846h.85v1.7846H86.209c.4761.0171,1.19.0171,1.19-.6626a2.1274,2.1274,0,0,0-.51-1.122h1.0195A2.1853,2.1853,0,0,1,88.2827,176.4008Z" />
                                    <path className="st6" d="M87.042,166.7113a3.4774,3.4774,0,0,1,1.292,2.89,4.0525,4.0525,0,0,1-.4253,1.87c-1.0025,1.938-3.3823,2.0058-3.9092,2.0058a4.8419,4.8419,0,0,1-1.6489-.2548,3.6321,3.6321,0,0,1-2.5327-3.4849,3.4419,3.4419,0,0,1,1.1557-2.6006,4.5781,4.5781,0,0,1,2.8555-1.0537h.2554v6.2891a4.2207,4.2207,0,0,0,1.36-.1529,2.5056,2.5056,0,0,0,1.9717-2.6006,2.7084,2.7084,0,0,0-1.7-2.601l.272-.9346A5.4521,5.4521,0,0,1,87.042,166.7113Zm-6.3232,3.0425a2.51,2.51,0,0,0,1.4106,2.2949,4.4208,4.4208,0,0,0,1.1221.3062v-5.1167A2.4884,2.4884,0,0,0,80.7188,169.7538Z" />
                                    <path className="st6" d="M88.13,153.91H83.7275c-1.394,0-2.1757-.0678-2.6689.5611a1.6472,1.6472,0,0,0-.2891,1.0029,2.0392,2.0392,0,0,0,1.3091,1.9038,5.9577,5.9577,0,0,0,2.6685.3232H88.13v.9859H83.6255a4.93,4.93,0,0,0-2.1587.2549A1.4242,1.4242,0,0,0,80.77,160.25a1.9614,1.9614,0,0,0,1.0879,1.8022,6.0354,6.0354,0,0,0,3.0088.4077H88.13v1.003H80.0215V162.46h1.6831a2.26,2.26,0,0,1-1.853-2.4135,2.0881,2.0881,0,0,1,1.2241-2.0572,2.601,2.601,0,0,1,.7988-.17,2.9012,2.9012,0,0,1-.8159-.3228,2.3569,2.3569,0,0,1-1.207-2.21,2.4771,2.4771,0,0,1,.4082-1.4619c.6631-.9517,1.8188-.9175,3.8755-.9175H88.13Z" />
                                    <path className="st6" d="M86.9229,144.39a2.93,2.93,0,0,1,1.4111,2.7705,3.3742,3.3742,0,0,1-1.2241,2.72,4.1236,4.1236,0,0,1-.9517.544l-.34-.9858a2.1987,2.1987,0,0,0,1.5976-2.2608,2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.3092,1.3092,0,0,0-.7139.2212c-.3569.2549-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6347a2.022,2.022,0,0,1-1.2749.4248,2.2253,2.2253,0,0,1-1.581-.6967,3.4178,3.4178,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9888,2.2186,2.2186,0,0,1,1.3086-.8838l.2554.9517c-.9351.187-1.207,1.1558-1.207,1.9722a2.4139,2.4139,0,0,0,.3061,1.3256,1.1378,1.1378,0,0,0,.3569.357,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.826,2.826,0,0,1,1.0537-1.853,2.0158,2.0158,0,0,1,1.2408-.3907A2.42,2.42,0,0,1,86.9229,144.39Z" />
                                    <path className="st6" d="M85.4951,129.6342a3.4544,3.4544,0,0,1,2.8218,3.2294,2.5531,2.5531,0,0,1-1.1221,2.2276,1.8511,1.8511,0,0,1-.4248.2207h5.6607v.9863H80.0215v-.9863h1.3262a1.956,1.956,0,0,1-.5269-.3057,2.5687,2.5687,0,0,1-.9692-2.1426,3.0725,3.0725,0,0,1,.4253-1.6826,4.5375,4.5375,0,0,1,3.8242-1.7168A5.955,5.955,0,0,1,85.4951,129.6342Zm-2.9575,1.0878a2.3221,2.3221,0,0,0-1.7681,2.3125,2.2487,2.2487,0,0,0,.2549,1.0538,2.4954,2.4954,0,0,0,1.6831,1.1044,7.8266,7.8266,0,0,0,1.2578.1192,3.412,3.412,0,0,0,2.9068-.8838,2.1063,2.1063,0,0,0,.476-1.4111c0-1.87-1.8188-2.5157-3.3828-2.5157A4.6333,4.6333,0,0,0,82.5376,130.722Z" />
                                    <path className="st6" d="M87.7051,121.5424a1.3841,1.3841,0,0,1-1.0542.3564,2.277,2.277,0,0,1,.6972.4248,3.3071,3.3071,0,0,1,.9517,2.4483,2.5561,2.5561,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6123,1.983,1.983,0,0,1-1.2744-.4424,4.7133,4.7133,0,0,1-1.2412-2.4991c-.1357-.51-.289-1.02-.3906-1.5292l-.2041-.9522h-.4248a2.0615,2.0615,0,0,0-.7822.085c-.5269.17-1.0879.6289-1.0708,1.8525,0,1.0371.289,1.5645.6967,1.8359a2.3491,2.3491,0,0,0,1.2578.3067l-.187,1.1045a4.1528,4.1528,0,0,1-1.4106-.4756,3.0474,3.0474,0,0,1-1.2407-2.7031A2.5423,2.5423,0,0,1,82.98,120.93H85.58c.7822,0,1.3261.0166,1.5639-.2725a1.5252,1.5252,0,0,0,.1871-.9346H88.13C88.13,120.6752,88.0449,121.2015,87.7051,121.5424Zm-3.8589.3564.1362.51a15.4035,15.4035,0,0,0,1.105,3.4336,1.2375,1.2375,0,0,0,.9858.5107,1.3342,1.3342,0,0,0,.5777-.1533,1.633,1.633,0,0,0,.7651-1.4785,2.9693,2.9693,0,0,0-1.53-2.6016,3.1875,3.1875,0,0,0-1.4282-.2207Z" />
                                    <path className="st6" d="M80.8374,113.7728a3.6538,3.6538,0,0,0-.0508.6289,2.0809,2.0809,0,0,0,1.9209,2.1934,13.0465,13.0465,0,0,0,1.9209.085H88.13v1.0029H80.0215V116.68h1.292a1.9319,1.9319,0,0,1-.4077-.2217,2.3725,2.3725,0,0,1-.7994-.9512,2.7443,2.7443,0,0,1-.2036-1.1562,4.5619,4.5619,0,0,1,.0337-.5948Z" />
                                    <path className="st6" d="M87.7051,106.4642a1.3838,1.3838,0,0,1-1.0542.3565,2.277,2.277,0,0,1,.6972.4248,3.3066,3.3066,0,0,1,.9517,2.4482,2.5559,2.5559,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6123,1.9824,1.9824,0,0,1-1.2744-.4424,4.7126,4.7126,0,0,1-1.2412-2.499c-.1357-.51-.289-1.0195-.3906-1.5293l-.2041-.9521h-.4248a2.0634,2.0634,0,0,0-.7822.0849c-.5269.17-1.0879.6289-1.0708,1.8526,0,1.0371.289,1.5644.6967,1.8359a2.3491,2.3491,0,0,0,1.2578.3067l-.187,1.1044a4.15,4.15,0,0,1-1.4106-.4755,3.0476,3.0476,0,0,1-1.2407-2.7032,2.5424,2.5424,0,0,1,3.1616-2.9746H85.58c.7822,0,1.3261.0166,1.5639-.2724a1.5252,1.5252,0,0,0,.1871-.9346H88.13C88.13,105.597,88.0449,106.1234,87.7051,106.4642Zm-3.8589.3565.1362.51a15.4,15.4,0,0,0,1.105,3.4336,1.2374,1.2374,0,0,0,.9858.5108,1.3356,1.3356,0,0,0,.5777-.1533,1.6332,1.6332,0,0,0,.7651-1.4786,2.9693,2.9693,0,0,0-1.53-2.6015,3.1861,3.1861,0,0,0-1.4282-.2207Z" />
                                    <path className="st6" d="M88.13,93.0511H83.7275c-1.394,0-2.1757-.0673-2.6689.5616a1.6465,1.6465,0,0,0-.2891,1.0029,2.039,2.039,0,0,0,1.3091,1.9033,5.9647,5.9647,0,0,0,2.6685.3233H88.13v.9853H83.6255a4.9376,4.9376,0,0,0-2.1587.2549,1.4258,1.4258,0,0,0-.6973,1.31,1.9615,1.9615,0,0,0,1.0879,1.8017,6.0331,6.0331,0,0,0,3.0088.4082H88.13v1.003H80.0215v-1.003h1.6831a2.2642,2.2642,0,0,1-1.853-2.414,2.0867,2.0867,0,0,1,1.2241-2.0567,2.5564,2.5564,0,0,1,.7988-.17,2.93,2.93,0,0,1-.8159-.3232,2.3572,2.3572,0,0,1-1.207-2.21,2.4761,2.4761,0,0,1,.4082-1.4619c.6631-.9522,1.8188-.918,3.8755-.918H88.13Z" />
                                    <path className="st6" d="M87.042,82.6293a3.4789,3.4789,0,0,1,1.292,2.89,4.0537,4.0537,0,0,1-.4253,1.87C86.9062,89.3275,84.5264,89.3949,84,89.3949a4.84,4.84,0,0,1-1.6489-.2549,3.6318,3.6318,0,0,1-2.5327-3.4844,3.44,3.44,0,0,1,1.1557-2.6005A4.5761,4.5761,0,0,1,83.8291,82h.2554v6.29a4.2324,4.2324,0,0,0,1.36-.1533,2.5057,2.5057,0,0,0,1.9717-2.6006,2.7075,2.7075,0,0,0-1.7-2.6006L85.9883,82A5.4414,5.4414,0,0,1,87.042,82.6293Zm-6.3232,3.0429a2.5091,2.5091,0,0,0,1.4106,2.295,4.3785,4.3785,0,0,0,1.1221.3056V83.1566A2.4887,2.4887,0,0,0,80.7188,85.6722Z" />
                                    <path className="st6" d="M88.2827,77.24a1.4592,1.4592,0,0,1-.0171.2725,1.1291,1.1291,0,0,1-.7138,1.07,6.32,6.32,0,0,1-1.6148.1191H80.8716v1.7h-.85v-1.7h-3.45v-.9687h3.45V75.9476h.85v1.7852H86.209c.4761.0176,1.19.0176,1.19-.6631a2.1333,2.1333,0,0,0-.51-1.1221h1.0195A2.1905,2.1905,0,0,1,88.2827,77.24Z" />
                                    <path className="st6" d="M87.042,67.55a3.48,3.48,0,0,1,1.292,2.8906,4.0526,4.0526,0,0,1-.4253,1.8691C86.9062,74.2474,84.5264,74.3158,84,74.3158a4.84,4.84,0,0,1-1.6489-.2549,3.6318,3.6318,0,0,1-2.5327-3.4844,3.44,3.44,0,0,1,1.1557-2.6,4.5761,4.5761,0,0,1,2.8555-1.0547h.2554v6.29a4.2324,4.2324,0,0,0,1.36-.1533,2.5057,2.5057,0,0,0,1.9717-2.6006,2.7075,2.7075,0,0,0-1.7-2.6006l.272-.9355A5.4414,5.4414,0,0,1,87.042,67.55Zm-6.3232,3.0429a2.5091,2.5091,0,0,0,1.4106,2.295,4.3785,4.3785,0,0,0,1.1221.3056V68.0775A2.4887,2.4887,0,0,0,80.7188,70.5931Z" />
                                    <path className="st6" d="M80.8374,60.3939a3.6529,3.6529,0,0,0-.0508.6289,2.0809,2.0809,0,0,0,1.9209,2.1934,13.05,13.05,0,0,0,1.9209.0849H88.13v1.003H80.0215v-1.003h1.292a1.927,1.927,0,0,1-.4077-.2216,2.3725,2.3725,0,0,1-.7994-.9512,2.7445,2.7445,0,0,1-.2036-1.1563,4.5614,4.5614,0,0,1,.0337-.5947Z" />
                                    <path className="st6" d="M86.9229,53.016a2.9306,2.9306,0,0,1,1.4111,2.77,3.3737,3.3737,0,0,1-1.2241,2.72,4.1272,4.1272,0,0,1-.9517.544l-.34-.9854a2.2,2.2,0,0,0,1.5976-2.2607,2.4533,2.4533,0,0,0-.4082-1.4961A1.54,1.54,0,0,0,85.835,53.73a1.3129,1.3129,0,0,0-.7139.2207c-.3569.2548-.561.663-.7481,1.53-.1865.7989-.3227,2.0567-1.02,2.6348a2.028,2.028,0,0,1-1.2749.4248,2.2244,2.2244,0,0,1-1.581-.6973,3.4148,3.4148,0,0,1-.68-2.2089,3.6106,3.6106,0,0,1,.5268-1.9893,2.2211,2.2211,0,0,1,1.3086-.8838l.2554.9522c-.9351.1865-1.207,1.1552-1.207,1.9716a2.41,2.41,0,0,0,.3061,1.3252,1.1328,1.1328,0,0,0,.3569.3575,1.3992,1.3992,0,0,0,.6968.1865c.9517,0,1.1729-1.003,1.3086-1.6992l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.8535,2.0128,2.0128,0,0,1,1.2408-.3906A2.4131,2.4131,0,0,1,86.9229,53.016Z" />
                                </g>
                            <g>
                                    <path className="st6" d="M575.3535,173.12a4.3409,4.3409,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2863,2.2863,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.56,4.5878,4.5878,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9144,3.9144,0,0,1,.3194,1.88V170.74A3.0664,3.0664,0,0,1,575.3535,173.12Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4354,1.4354,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2366,2.2366,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M586.0527,167.1786c-.42.52-1.0195.7-2.3.7h-2.2011V173.76h-2.62v-14.123h5.2413a3.6805,3.6805,0,0,1,.7.06,1.9722,1.9722,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C586.5537,165.9984,586.4932,166.6385,586.0527,167.1786Zm-2.0595-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.961-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.28a1.8259,1.8259,0,0,0,.1807-.98Z" />
                                    <path className="st6" d="M593.2324,161.6776V173.76h-2.6211V161.6776h-2.6006v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M597.7705,173.76v-14.123h2.5811V173.76Z" />
                                    <path className="st6" d="M612.3926,173.76l.24-12.2426-2.28,12.2426h-2.4209l-2.34-12.2426.1992,12.2426h-2.5605v-14.123h4.2412l1.66,9.8823,1.6807-9.8823h4.1807V173.76Z" />
                                    <path className="st6" d="M622.792,173.76l-.3408-2.3h-2.4l-.38,2.3H617.07l2.74-14.123h2.9815l2.6406,14.123Zm-1.4805-10.5424-.94,6.0615h1.8008Z" />
                                    <path className="st6" d="M627.49,173.76v-14.123h2.6006V171.62h3.8213V173.76Z" />
                                    <path className="st6" d="M488.6943,192.74a2.9651,2.9651,0,0,1-1.32,2.5806,4.18,4.18,0,0,1-2.24.44h-4.1807v-14.123h4.7813a2.9645,2.9645,0,0,1,1.94.7,3.5313,3.5313,0,0,1,1.04,3.02v6.6016C488.7148,192.22,488.7148,192.4794,488.6943,192.74Zm-2.58-7.6015a1.39,1.39,0,0,0-.36-1.2007,1.7423,1.7423,0,0,0-1.18-.24h-1.0205V193.7h.92a4.5832,4.5832,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                    <path className="st6" d="M491.4131,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.0009v2.14h-4.0009V193.58h4.44V195.76Z" />
                                    <path className="st6" d="M505.1738,195.76h-2.6211l-2.8-14.123h2.7608l1.38,9.3418,1.28-9.3418h2.66Z" />
                                    <path className="st6" d="M509.8525,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V193.58h4.4405V195.76Z" />
                                    <path className="st6" d="M519.0723,195.76v-14.123h2.6006V193.62h3.82V195.76Z" />
                                    <path className="st6" d="M534.334,195.12a4.3386,4.3386,0,0,1-3.0606.94,4.5144,4.5144,0,0,1-2.94-.82,2.2883,2.2883,0,0,1-.7-.88,3.8175,3.8175,0,0,1-.3-1.62v-7.9219a3.3961,3.3961,0,0,1,.88-2.56,4.5823,4.5823,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.88V192.74A3.0638,3.0638,0,0,1,534.334,195.12Zm-1.8-10.062a2.4832,2.4832,0,0,0-.16-1.1406,1.1042,1.1042,0,0,0-1.081-.62,1.4373,1.4373,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2371,2.2371,0,0,0,.24,1.3,1.3429,1.3429,0,0,0,1.0605.42,1.5218,1.5218,0,0,0,.8193-.24c.4805-.34.461-.9.461-1.42Z" />
                                    <path className="st6" d="M545.0332,189.1786c-.42.52-1.0195.7-2.3.7h-2.2012V195.76h-2.62v-14.123h5.2412a3.6814,3.6814,0,0,1,.7.06,1.9722,1.9722,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C545.5342,187.9984,545.4736,188.6385,545.0332,189.1786Zm-2.06-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2608c.54,0,.84-.06,1-.28a1.8268,1.8268,0,0,0,.1806-.98Z" />
                                    <path className="st6" d="M557.1143,195.76l.24-12.2426L555.0732,195.76h-2.42l-2.3408-12.2426.2,12.2426h-2.5606v-14.123h4.2413l1.66,9.8823,1.6807-9.8823h4.1806V195.76Z" />
                                    <path className="st6" d="M562.5918,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V193.58h4.44V195.76Z" />
                                    <path className="st6" d="M576.5322,195.76l-2.5-10.582.22,10.582h-2.44v-14.123h3.3408l2.54,10.3623-.26-10.3623h2.3809V195.76Z" />
                                    <path className="st6" d="M586.9531,183.6776V195.76H584.332V183.6776h-2.6006v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M604.0723,189.1786c-.42.52-1.02.7-2.3.7h-2.2012V195.76h-2.62v-14.123h5.2412a3.6814,3.6814,0,0,1,.7.06,1.9727,1.9727,0,0,1,1.38,1,5.1441,5.1441,0,0,1,.3007,2.1206v1.86C604.5732,187.9984,604.5127,188.6385,604.0723,189.1786Zm-2.06-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.28a1.8259,1.8259,0,0,0,.1807-.98Z" />
                                    <path className="st6" d="M611.9121,195.76l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.123h2.9805l2.6406,14.123Zm-1.4805-10.5424-.9394,6.0615h1.8Z" />
                                    <path className="st6" d="M621.5928,195.76v-6.4614h-2.3809V195.76h-2.6006v-14.123h2.6006v5.541h2.3809v-5.541h2.6006V195.76Z" />
                                    <path className="st6" d="M631.332,183.6776V195.76h-2.6211V183.6776H626.11v-2.0405h7.8417v2.0405Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M681.3418,249.6874v3.5405h4.041v2.1006h-4.041V261.63h-2.62V247.5067h6.9219v2.1807Z" />
                                    <path className="st6" d="M694.4033,260.99a4.3408,4.3408,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2862,2.2862,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.5606,4.5883,4.5883,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.54,1.66a3.9,3.9,0,0,1,.32,1.88v7.8619A3.066,3.066,0,0,1,694.4033,260.99Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2372,2.2372,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M704.9619,260.7094a4.0818,4.0818,0,0,1-3.2607,1.2207,3.8288,3.8288,0,0,1-2.88-1.0206,2.8129,2.8129,0,0,1-.6006-.96,4.2625,4.2625,0,0,1-.26-1.5606V247.5067h2.6006v10.5025a2.8818,2.8818,0,0,0,.14,1.1,1.1483,1.1483,0,0,0,1.08.6,1.2651,1.2651,0,0,0,.7608-.22c.5-.34.4394-1,.4394-1.5V247.5067h2.6211v10.9424A3.3221,3.3221,0,0,1,704.9619,260.7094Z" />
                                    <path className="st6" d="M713.1621,261.63l-2.501-10.5821.22,10.5821H708.44V247.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V261.63Z" />
                                    <path className="st6" d="M727.0625,258.6093a2.9674,2.9674,0,0,1-1.32,2.58,4.1851,4.1851,0,0,1-2.2412.44H719.32V247.5067h4.7813a2.9638,2.9638,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02v6.6016C727.082,258.0892,727.082,258.349,727.0625,258.6093Zm-2.5811-7.6016a1.3866,1.3866,0,0,0-.36-1.2007,1.7412,1.7412,0,0,0-1.18-.24h-1.0205v10.0019h.92a4.5884,4.5884,0,0,0,.74-.04c.8408-.16.9-.78.9-1.48Z" />
                                    <path className="st6" d="M734.7012,261.63l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.1231h2.9805l2.6406,14.1231Zm-1.4795-10.5425-.9405,6.0615h1.8Z" />
                                    <path className="st6" d="M743.6611,249.5472V261.63h-2.62V249.5472H738.44v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M748.2,261.63V247.5067h2.581V261.63Z" />
                                    <path className="st6" d="M760.542,260.99a4.3387,4.3387,0,0,1-3.0606.94,4.5145,4.5145,0,0,1-2.94-.82,2.2781,2.2781,0,0,1-.7-.88,3.8185,3.8185,0,0,1-.3008-1.62v-7.9219a3.3983,3.3983,0,0,1,.8809-2.5606,4.5829,4.5829,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9119,3.9119,0,0,1,.32,1.88v7.8619A3.0667,3.0667,0,0,1,760.542,260.99Zm-1.8008-10.062a2.47,2.47,0,0,0-.16-1.1406,1.1028,1.1028,0,0,0-1.08-.62,1.437,1.437,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2291,2.2291,0,0,0,.24,1.3,1.3411,1.3411,0,0,0,1.06.42,1.5243,1.5243,0,0,0,.82-.24c.4805-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M768.8418,261.63l-2.501-10.5821.22,10.5821h-2.44V247.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V261.63Z" />
                                    <path className="st6" d="M782.3418,259.7694c-.32,1.14-1.2607,2.1607-3.6611,2.1607a4.5718,4.5718,0,0,1-2.9-.8,2.4,2.4,0,0,1-.74-.96,3.1074,3.1074,0,0,1-.28-1.46v-2.0606h2.581v1.64c0,.52-.04,1.12.46,1.44a1.4389,1.4389,0,0,0,.8.2,1.2674,1.2674,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.7993,1.7993,0,0,0-.88-1.86l-2.46-1.66a3.2762,3.2762,0,0,1-1.1806-1.1606,3.8742,3.8742,0,0,1-.5-2.24,4.7422,4.7422,0,0,1,.34-1.9405c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.4112,2.4112,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6006,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3222,2.3222,0,0,0,.54.46l2.52,1.7407a5.29,5.29,0,0,1,1.16,1.1,3.3622,3.3622,0,0,1,.5595,2.44A7.9973,7.9973,0,0,1,782.3418,259.7694Z" />
                                    <path className="st6" d="M685.6035,282.99a4.3409,4.3409,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2862,2.2862,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.5606,4.5883,4.5883,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9139,3.9139,0,0,1,.3194,1.88v7.8619A3.066,3.066,0,0,1,685.6035,282.99Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2364,2.2364,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M691.8018,271.6874v3.54h4.041v2.1006h-4.041V283.63h-2.62V269.5067h6.9209v2.1807Z" />
                                    <path className="st6" d="M706.0615,271.6874v3.54h4.041v2.1006h-4.041V283.63H703.44V269.5067h6.9219v2.1807Z" />
                                    <path className="st6" d="M719.2217,282.7094a4.0819,4.0819,0,0,1-3.2608,1.2207,3.8285,3.8285,0,0,1-2.88-1.0206,2.8113,2.8113,0,0,1-.6006-.96,4.2625,4.2625,0,0,1-.26-1.5606V269.5067h2.6006v10.5025a2.8838,2.8838,0,0,0,.14,1.1,1.1485,1.1485,0,0,0,1.08.6,1.2653,1.2653,0,0,0,.7608-.22c.5-.34.4394-1,.4394-1.5V269.5067h2.6211v10.9424A3.3226,3.3226,0,0,1,719.2217,282.7094Z" />
                                    <path className="st6" d="M726.9609,271.5472V283.63h-2.62V271.5472H721.74v-2.04h7.8418v2.04Z" />
                                    <path className="st6" d="M738.4814,282.7094a4.0818,4.0818,0,0,1-3.2607,1.2207,3.8289,3.8289,0,0,1-2.88-1.0206,2.8129,2.8129,0,0,1-.6006-.96,4.2624,4.2624,0,0,1-.26-1.5606V269.5067h2.6006v10.5025a2.8838,2.8838,0,0,0,.14,1.1,1.1484,1.1484,0,0,0,1.08.6,1.265,1.265,0,0,0,.7607-.22c.5-.34.4395-1,.4395-1.5V269.5067h2.6211v10.9424A3.3221,3.3221,0,0,1,738.4814,282.7094Z" />
                                    <path className="st6" d="M747.541,283.63c-.04-.08-.08-.14-.12-.22a2.5694,2.5694,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.0195-.68-.08-.78-.66-.96-1.3408-.96h-1.24V283.63H741.96V269.5067h5.2011a2.6436,2.6436,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52v1.5205a3.5425,3.5425,0,0,1-.28,1.5205,1.6812,1.6812,0,0,1-.3.38,2.61,2.61,0,0,1-1.24.5,2.8418,2.8418,0,0,1,.7793.28,2.3392,2.3392,0,0,1,1.1407,2.18v2.8809a3.2167,3.2167,0,0,0,.36,1.66.8422.8422,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.2038,1.2038,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1945,1.1945,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st6" d="M752.42,283.63V269.5067h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.4414V283.63Z" />
                                    <path className="st6" d="M678.7217,305.63V291.5067h6.8418v2.1406h-4.2412v3.3208h4.0009v2.14h-4.0009v4.3408h4.44V305.63Z" />
                                    <path className="st6" d="M693.3828,305.63l-1.66-4.9009L690.042,305.63h-2.82l3.12-7.5215-2.78-6.6016h2.8408l1.34,3.981,1.36-3.981h2.84l-2.82,6.6817,3.1211,7.4414Z" />
                                    <path className="st6" d="M698.4,305.63V291.5067h2.581V305.63Z" />
                                    <path className="st6" d="M711.2021,303.7694c-.32,1.14-1.2607,2.1607-3.6611,2.1607a4.572,4.572,0,0,1-2.9-.8,2.4006,2.4006,0,0,1-.74-.96,3.1059,3.1059,0,0,1-.28-1.46v-2.0606h2.5811v1.64c0,.52-.04,1.12.46,1.44a1.4392,1.4392,0,0,0,.8.2,1.2672,1.2672,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.7993,1.7993,0,0,0-.88-1.86l-2.46-1.66a3.2773,3.2773,0,0,1-1.1807-1.1606,3.875,3.875,0,0,1-.5-2.24,4.744,4.744,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4h-2.6005v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6006,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3255,2.3255,0,0,0,.54.46l2.5205,1.7407a5.29,5.29,0,0,1,1.16,1.1,3.3623,3.3623,0,0,1,.56,2.44A7.9918,7.9918,0,0,1,711.2021,303.7694Z" />
                                    <path className="st6" d="M718.1611,293.5472V305.63h-2.62V293.5472H712.94v-2.04h7.8418v2.04Z" />
                                    <path className="st6" d="M722.7,305.63V291.5067h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.4414V305.63Z" />
                                    <path className="st6" d="M736.6416,305.63l-2.501-10.5821.22,10.5821H731.92V291.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V305.63Z" />
                                    <path className="st6" d="M750.2012,304.01c-.68,1.96-3.3,1.9205-3.6006,1.9205a6.8725,6.8725,0,0,1-1.34-.14,2.9469,2.9469,0,0,1-2.3808-1.6806,3.7844,3.7844,0,0,1-.2-1.48v-7.6817c0-.24.02-.5005.04-.74a2.6945,2.6945,0,0,1,1-2.1807,4.3026,4.3026,0,0,1,2.9209-.82,4.707,4.707,0,0,1,2.6406.64,2.4624,2.4624,0,0,1,.88,1.04,3.1092,3.1092,0,0,1,.24,1.6v2.1606h-2.5606v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1,0-1.2.62-1.2,1.5405v7.5616c0,.92.2,1.6,1.28,1.6,1.16,0,1.26-.66,1.26-1.7407v-1.84h2.5606v2A5.2234,5.2234,0,0,1,750.2012,304.01Z" />
                                    <path className="st6" d="M752.84,305.63V291.5067h6.8418v2.1406H755.44v3.3208h4.001v2.14H755.44v4.3408h4.4414V305.63Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M594.4863,424.348v3.541h4.041V429.99h-4.041V436.29h-2.62v-14.123h6.9219v2.1806Z" />
                                    <path className="st6" d="M607.5479,435.6508a4.3416,4.3416,0,0,1-3.0616.94,4.516,4.516,0,0,1-2.9394-.82,2.2978,2.2978,0,0,1-.7012-.8809,3.8289,3.8289,0,0,1-.2988-1.62V425.348a3.3979,3.3979,0,0,1,.8789-2.56,4.5874,4.5874,0,0,1,3.081-.92c2.04,0,3.1211.84,3.54,1.66a3.9,3.9,0,0,1,.32,1.88V433.27A3.0635,3.0635,0,0,1,607.5479,435.6508Zm-1.8-10.0625a2.4987,2.4987,0,0,0-.16-1.1407,1.1055,1.1055,0,0,0-1.0811-.62,1.4346,1.4346,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006V432.83a2.2385,2.2385,0,0,0,.2392,1.3007,1.3459,1.3459,0,0,0,1.0606.42,1.52,1.52,0,0,0,.82-.24c.4805-.34.4609-.9.4609-1.42Z" />
                                    <path className="st6" d="M618.1074,435.3705a4.0841,4.0841,0,0,1-3.2617,1.2207,3.83,3.83,0,0,1-2.8789-1.0205,2.8227,2.8227,0,0,1-.6016-.96,4.2847,4.2847,0,0,1-.26-1.56V422.1674h2.6015V432.67a2.8969,2.8969,0,0,0,.1387,1.1,1.1489,1.1489,0,0,0,1.08.6006,1.2643,1.2643,0,0,0,.7617-.2207c.5-.34.4395-1,.4395-1.5V422.1674h2.621V433.11A3.3223,3.3223,0,0,1,618.1074,435.3705Z" />
                                    <path className="st6" d="M626.3066,436.29l-2.5-10.582.2188,10.582h-2.4395v-14.123h3.34l2.541,10.3623-.26-10.3623h2.3809V436.29Z" />
                                    <path className="st6" d="M640.207,433.27a2.9663,2.9663,0,0,1-1.32,2.5811,4.1828,4.1828,0,0,1-2.24.4394h-4.1817v-14.123h4.7813a2.9637,2.9637,0,0,1,1.9414.7,3.5336,3.5336,0,0,1,1.0391,3.0205V432.49C640.2266,432.75,640.2266,433.01,640.207,433.27Zm-2.58-7.6016a1.387,1.387,0,0,0-.3614-1.2006,1.7411,1.7411,0,0,0-1.18-.24h-1.0195V434.23h.92a4.5832,4.5832,0,0,0,.74-.04c.84-.1592.9-.78.9-1.4795Z" />
                                    <path className="st6" d="M647.8457,436.29l-.34-2.3h-2.4l-.3789,2.3H642.125l2.74-14.123h2.9805l2.6406,14.123Zm-1.4785-10.5425-.9414,6.062h1.8008Z" />
                                    <path className="st6" d="M656.8066,424.2079V436.29h-2.6211V424.2079h-2.6v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M661.3457,436.29v-14.123h2.58V436.29Z" />
                                    <path className="st6" d="M673.6875,435.6508a4.3388,4.3388,0,0,1-3.0605.94,4.5186,4.5186,0,0,1-2.9415-.82,2.2872,2.2872,0,0,1-.6992-.8809,3.8171,3.8171,0,0,1-.3008-1.62V425.348a3.3938,3.3938,0,0,1,.8809-2.56,4.5823,4.5823,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9246,3.9246,0,0,1,.32,1.88V433.27A3.0675,3.0675,0,0,1,673.6875,435.6508Zm-1.8008-10.0625a2.47,2.47,0,0,0-.16-1.1407,1.104,1.104,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006V432.83a2.2234,2.2234,0,0,0,.24,1.3007,1.34,1.34,0,0,0,1.0586.42,1.5182,1.5182,0,0,0,.82-.24c.48-.34.4609-.9.4609-1.42Z" />
                                    <path className="st6" d="M681.9863,436.29l-2.5-10.582.2188,10.582h-2.44v-14.123h3.34l2.541,10.3623-.26-10.3623h2.3809V436.29Z" />
                                    <path className="st6" d="M695.4863,434.43c-.32,1.1406-1.26,2.1611-3.66,2.1611a4.5716,4.5716,0,0,1-2.9-.8008,2.3833,2.3833,0,0,1-.74-.96,3.1064,3.1064,0,0,1-.2812-1.46V431.31h2.582v1.64c0,.5205-.041,1.12.459,1.44a1.4363,1.4363,0,0,0,.8008.2,1.2676,1.2676,0,0,0,.9-.26c.3789-.3.3789-.9209.3789-1.8808a1.8027,1.8027,0,0,0-.8789-1.86l-2.461-1.66a3.2654,3.2654,0,0,1-1.18-1.16,3.8676,3.8676,0,0,1-.5-2.2407,4.7258,4.7258,0,0,1,.34-1.94c.7988-1.72,3.2207-1.72,3.5-1.72.42,0,2.6816,0,3.4219,1.86a2.4254,2.4254,0,0,1,.2187,1.18v1.4h-2.6v-1.44c0-.42-.16-1.02-1.16-1.02-.6016,0-1.16.22-1.16,1.4a2.64,2.64,0,0,0,.2793,1.3609,2.3282,2.3282,0,0,0,.541.46l2.5195,1.74a5.2821,5.2821,0,0,1,1.16,1.1,3.3584,3.3584,0,0,1,.5606,2.4405A7.9325,7.9325,0,0,1,695.4863,434.43Z" />
                                    <path className="st6" d="M598.748,457.6508a4.3387,4.3387,0,0,1-3.0605.94,4.52,4.52,0,0,1-2.9414-.82,2.2872,2.2872,0,0,1-.6992-.8809,3.8156,3.8156,0,0,1-.3008-1.62V447.348a3.3981,3.3981,0,0,1,.8809-2.56,4.5822,4.5822,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.8809V455.27A3.0676,3.0676,0,0,1,598.748,457.6508Zm-1.8007-10.0625a2.4715,2.4715,0,0,0-.16-1.1407,1.1017,1.1017,0,0,0-1.08-.62,1.4379,1.4379,0,0,0-.84.2608c-.58.3994-.5,1.0195-.5,1.6005V454.83a2.239,2.239,0,0,0,.24,1.3007,1.3427,1.3427,0,0,0,1.0606.42,1.5175,1.5175,0,0,0,.8193-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M604.9473,446.348v3.541h4.041V451.99h-4.041V458.29h-2.6211v-14.123h6.9218v2.1806Z" />
                                    <path className="st6" d="M623.707,451.7094c-.42.5195-1.0195.7-2.3008.7H619.207V458.29h-2.6211v-14.123h5.24a3.6651,3.6651,0,0,1,.7011.06,1.9741,1.9741,0,0,1,1.3809,1,5.1707,5.1707,0,0,1,.2988,2.12v1.86C624.207,450.5287,624.1465,451.1693,623.707,451.7094Zm-2.0605-4.0206a1.7074,1.7074,0,0,0-.1992-1.1406,1.1207,1.1207,0,0,0-.961-.3H619.207v4.1406h1.26c.5391,0,.84-.06,1-.28a1.8349,1.8349,0,0,0,.18-.98Z" />
                                    <path className="st6" d="M632.207,458.29c-.041-.08-.08-.14-.1211-.22a2.5707,2.5707,0,0,1-.26-1.1406v-3.2207c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V458.29H626.625v-14.123h5.2012a2.6435,2.6435,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.5205v1.52a3.5523,3.5523,0,0,1-.2793,1.5205,1.6934,1.6934,0,0,1-.3008.38,2.5982,2.5982,0,0,1-1.24.5,2.8318,2.8318,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1807v2.8808a3.2213,3.2213,0,0,0,.3613,1.66.8208.8208,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3008-.9394a1.2025,1.2025,0,0,0-.9-.2207h-1.3593v3.8613h1.3593a1.1929,1.1929,0,0,0,.9805-.3408c.2012-.2393.2207-.44.2207-1Z" />
                                    <path className="st6" d="M637.0859,458.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V456.11h4.4415V458.29Z" />
                                    <path className="st6" d="M653.6465,456.43c-.32,1.1406-1.26,2.1611-3.66,2.1611a4.5718,4.5718,0,0,1-2.9-.8008,2.3828,2.3828,0,0,1-.74-.96,3.1051,3.1051,0,0,1-.2812-1.46V453.31h2.582v1.64c0,.5205-.041,1.12.459,1.44a1.4359,1.4359,0,0,0,.8007.2,1.2672,1.2672,0,0,0,.9-.26c.3789-.3.3789-.9209.3789-1.8808a1.8027,1.8027,0,0,0-.8789-1.86l-2.4609-1.66a3.2665,3.2665,0,0,1-1.18-1.16,3.8668,3.8668,0,0,1-.5-2.24,4.7247,4.7247,0,0,1,.34-1.94c.7988-1.7207,3.2207-1.7207,3.5-1.7207.42,0,2.6816,0,3.4218,1.86a2.4263,2.4263,0,0,1,.2188,1.1807v1.4h-2.6v-1.4405c0-.4209-.16-1.02-1.16-1.02-.6015,0-1.16.22-1.16,1.4a2.6371,2.6371,0,0,0,.2793,1.36,2.3237,2.3237,0,0,0,.541.46l2.5195,1.74a5.2843,5.2843,0,0,1,1.16,1.1,3.3584,3.3584,0,0,1,.5605,2.4405A7.938,7.938,0,0,1,653.6465,456.43Z" />
                                    <path className="st6" d="M656.3457,458.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V456.11h4.4414V458.29Z" />
                                    <path className="st6" d="M670.2871,458.29l-2.5019-10.582.2207,10.582h-2.4414v-14.123h3.3417l2.5411,10.3623-.2618-10.3623h2.3809V458.29Z" />
                                    <path className="st6" d="M680.7051,446.2084V458.29h-2.6192v-12.082h-2.6015v-2.041h7.8418v2.041Z" />
                                    <path className="st6" d="M591.8662,480.29v-14.123h6.8418v2.1406h-4.2412v3.3213h4.001v2.14h-4.001V478.11h4.4414V480.29Z" />
                                    <path className="st6" d="M606.5273,480.29l-1.66-4.9-1.6807,4.9h-2.82l3.12-7.5215-2.78-6.6015h2.8408l1.34,3.9814,1.36-3.9814h2.8408l-2.82,6.6816,3.1211,7.4414Z" />
                                    <path className="st6" d="M611.5449,480.29v-14.123h2.5821V480.29Z" />
                                    <path className="st6" d="M624.3477,478.43c-.32,1.1406-1.2618,2.1611-3.6622,2.1611a4.5713,4.5713,0,0,1-2.9-.8008,2.4208,2.4208,0,0,1-.74-.96,3.1106,3.1106,0,0,1-.2793-1.46V475.31h2.58v1.64c0,.5205-.0391,1.12.4609,1.44a1.4337,1.4337,0,0,0,.7989.2,1.2676,1.2676,0,0,0,.9-.26c.3808-.3.3808-.9209.3808-1.8808a1.7993,1.7993,0,0,0-.8808-1.86l-2.459-1.66a3.2874,3.2874,0,0,1-1.1817-1.16,3.8828,3.8828,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.7207,3.2207-1.7207,3.5019-1.7207.42,0,2.68,0,3.42,1.86a2.4,2.4,0,0,1,.2207,1.1807v1.4h-2.6016v-1.4405c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6461,2.6461,0,0,0,.2812,1.36,2.3494,2.3494,0,0,0,.5391.46l2.5215,1.74a5.2823,5.2823,0,0,1,1.16,1.1,3.3664,3.3664,0,0,1,.5586,2.4405A8.05,8.05,0,0,1,624.3477,478.43Z" />
                                    <path className="st6" d="M631.3066,468.2084V480.29h-2.6211v-12.082h-2.6v-2.041h7.8418v2.041Z" />
                                    <path className="st6" d="M635.8457,480.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V478.11h4.4414V480.29Z" />
                                    <path className="st6" d="M649.7871,480.29l-2.5019-10.582.2207,10.582h-2.4414v-14.123h3.3417l2.5411,10.3623-.2618-10.3623h2.3809V480.29Z" />
                                    <path className="st6" d="M663.3457,478.67c-.68,1.96-3.2988,1.9209-3.6,1.9209a6.8711,6.8711,0,0,1-1.34-.1406,2.9456,2.9456,0,0,1-2.3808-1.6807,3.7642,3.7642,0,0,1-.2012-1.48v-7.6816c0-.24.0215-.501.041-.74a2.6884,2.6884,0,0,1,1-2.1806,4.2968,4.2968,0,0,1,2.92-.82,4.7058,4.7058,0,0,1,2.6406.6406,2.4566,2.4566,0,0,1,.8808,1.04,3.1086,3.1086,0,0,1,.24,1.6v2.1612h-2.5606v-1.78c0-1.0811-.18-1.6611-1.34-1.6611-1,0-1.2012.62-1.2012,1.541V476.97c0,.92.2012,1.6006,1.2813,1.6006,1.16,0,1.26-.66,1.26-1.7412v-1.84h2.5606v2A5.1948,5.1948,0,0,1,663.3457,478.67Z" />
                                    <path className="st6" d="M665.9844,480.29v-14.123h6.8418v2.1406h-4.24v3.3213h4v2.14h-4V478.11h4.4414V480.29Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M146.8613,401.4955c-.8164,2.3525-3.96,2.3042-4.32,2.3042a8.2315,8.2315,0,0,1-1.6084-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.024-.6.0479-.8882a3.2312,3.2312,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,146.8613,401.4955Z" />
                                    <path className="st6" d="M156.4844,403.44c-.0479-.0962-.0957-.1679-.1441-.2641a3.0811,3.0811,0,0,1-.312-1.3682v-3.8643c0-.2641,0-.5278-.0239-.8159-.0957-.936-.792-1.1523-1.6079-1.1523h-1.4883V403.44h-3.12V386.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0165,2.0165,0,0,1-.36.4561,3.1373,3.1373,0,0,1-1.4883.6,3.4192,3.4192,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8639,3.8639,0,0,0,.4321,1.9922,1.0212,1.0212,0,0,0,.2881.2641Zm-.5039-13.1289c0-.6484-.0957-.8642-.36-1.1284a1.4438,1.4438,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4322,1.4322,0,0,0,1.1758-.4082c.24-.288.2642-.5278.2642-1.2Z" />
                                    <path className="st6" d="M162.1,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M173.5239,388.9427V403.44H170.38V388.9427h-3.1206v-2.4482h9.4092v2.4482Z" />
                                    <path className="st6" d="M178.7314,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M193.9243,401.4955c-.8164,2.3525-3.96,2.3042-4.3208,2.3042a8.23,8.23,0,0,1-1.6079-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.0239-.6.0479-.8882a3.23,3.23,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,193.9243,401.4955Z" />
                                    <path className="st6" d="M202.7554,403.44l-.4077-2.76h-2.88l-.4561,2.76h-3.12l3.2881-16.9453h3.5762l3.1684,16.9453ZM200.98,390.7909l-1.1284,7.2725h2.16Z" />
                                    <path className="st6" d="M208.1553,403.44V386.4945h3.12v14.377H215.86V403.44Z" />
                                    <path className="st6" d="M233.5713,399.8153a3.5586,3.5586,0,0,1-1.584,3.0967,5.0169,5.0169,0,0,1-2.6885.5278h-5.0161V386.4945h5.7363a3.5553,3.5553,0,0,1,2.3282.84c1.1523.9843,1.248,2.2324,1.248,3.6245v7.92C233.5952,399.1913,233.5952,399.5033,233.5713,399.8153Zm-3.0962-9.1206a1.6651,1.6651,0,0,0-.4321-1.44,2.087,2.087,0,0,0-1.416-.2881h-1.2242v12.001h1.104a5.5228,5.5228,0,0,0,.8882-.0479c1.0083-.1924,1.08-.936,1.08-1.7764Z" />
                                    <path className="st6" d="M236.5947,403.44V386.4945h8.2085v2.5679h-5.0884v3.9844h4.8v2.5683h-4.8v5.2085h5.3287V403.44Z" />
                                    <path className="st6" d="M256.2993,401.4955c-.8164,2.3525-3.96,2.3042-4.3208,2.3042a8.23,8.23,0,0,1-1.6079-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.0239-.6.0479-.8882a3.23,3.23,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,256.2993,401.4955Z" />
                                    <path className="st6" d="M259.2261,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M274.3467,401.2074c-.3838,1.3682-1.5122,2.5923-4.3921,2.5923a5.4864,5.4864,0,0,1-3.4805-.96,2.8731,2.8731,0,0,1-.8882-1.1518,3.7365,3.7365,0,0,1-.3359-1.7525v-2.4721h3.0962v1.9682c0,.624-.0479,1.3443.5522,1.728a1.7244,1.7244,0,0,0,.96.24,1.5233,1.5233,0,0,0,1.08-.312c.456-.36.456-1.104.456-2.2564a2.7789,2.7789,0,0,0-.3359-1.56,2.8221,2.8221,0,0,0-.72-.6724l-2.9522-1.9922a3.9253,3.9253,0,0,1-1.416-1.3921,4.644,4.644,0,0,1-.6-2.688,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8648-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.882,2.882,0,0,1,.2642,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.3921.2642-1.3921,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8152,2.8152,0,0,0,.648.5523l3.0244,2.0884a6.328,6.328,0,0,1,1.3921,1.32,4.0341,4.0341,0,0,1,.6718,2.9282A9.5691,9.5691,0,0,1,274.3467,401.2074Z" />
                                    <path className="st6" d="M277.3457,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M291.9146,402.6718a5.2079,5.2079,0,0,1-3.6724,1.1279,5.4181,5.4181,0,0,1-3.5283-.9839,2.74,2.74,0,0,1-.84-1.0562,4.5843,4.5843,0,0,1-.36-1.9443v-9.5044a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4483,0,3.7442,1.0083,4.2481,1.9921a4.6892,4.6892,0,0,1,.3842,2.2564v9.4326A3.6786,3.6786,0,0,1,291.9146,402.6718Zm-2.1607-12.0733a2.9679,2.9679,0,0,0-.1919-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.725,1.725,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.6825,2.6825,0,0,0,.2881,1.56,1.6127,1.6127,0,0,0,1.272.5039,1.8318,1.8318,0,0,0,.9843-.2876c.5762-.4082.5518-1.08.5518-1.7045Z" />
                                    <path className="st6" d="M301.6338,403.44l-3-12.6972.2642,12.6972h-2.9283V386.4945h4.0083l3.0484,12.4331-.312-12.4331H305.57V403.44Z" />
                                    <path className="st6" d="M317.8574,400.8h5.4483v2.64h-8.8565v-1.9682l5.4-12.3609H315.145v-2.6162h8.2569v1.68Z" />
                                    <path className="st6" d="M334.1777,402.6718a5.2075,5.2075,0,0,1-3.6723,1.1279,5.4181,5.4181,0,0,1-3.5283-.9839,2.74,2.74,0,0,1-.84-1.0562,4.5843,4.5843,0,0,1-.36-1.9443v-9.5044a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4482,0,3.7441,1.0083,4.248,1.9921a4.6878,4.6878,0,0,1,.3843,2.2564v9.4326A3.6783,3.6783,0,0,1,334.1777,402.6718Zm-2.16-12.0733a2.97,2.97,0,0,0-.1924-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.725,1.725,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.6825,2.6825,0,0,0,.2881,1.56,1.6128,1.6128,0,0,0,1.2724.5039,1.8316,1.8316,0,0,0,.9839-.2876c.5762-.4082.5523-1.08.5523-1.7045Z" />
                                    <path className="st6" d="M343.897,403.44l-3-12.6972.2641,12.6972h-2.9282V386.4945h4.0083l3.0484,12.4331-.312-12.4331h2.8559V403.44Z" />
                                    <path className="st6" d="M351.0483,403.44V386.4945h8.2085v2.5679h-5.0883v3.9844h4.8v2.5683h-4.8v5.2085h5.3286V403.44Z" />
                                    <path className="st6" d="M177.2686,435.2074c-.3838,1.3682-1.5123,2.5918-4.3921,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8682,2.8682,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.5209,1.5209,0,0,0,1.08-.3125c.456-.36.456-1.1035.456-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8193,2.8193,0,0,0-.72-.6719l-2.9522-1.9922a3.9259,3.9259,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8648-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.882,2.882,0,0,1,.2642,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5729,9.5729,0,0,1,177.2686,435.2074Z" />
                                    <path className="st6" d="M186.2441,437.44v-7.7529h-2.8564V437.44h-3.12V420.4945h3.12v6.6484h2.8564v-6.6484h3.12V437.44Z" />
                                    <path className="st6" d="M192.58,437.44V420.4945h3.0962V437.44Z" />
                                    <path className="st6" d="M202.0352,423.1107v4.2481h4.8486v2.5205h-4.8486v7.56h-3.1441V420.4945h8.3047v2.6162Z" />
                                    <path className="st6" d="M215.0913,422.9427V437.44h-3.144V422.9427h-3.1206v-2.4482h9.4091v2.4482Z" />
                                    <path className="st6" d="M234.8677,436.6713a5.2054,5.2054,0,0,1-3.6724,1.1279,5.4208,5.4208,0,0,1-3.5283-.9834,2.7411,2.7411,0,0,1-.84-1.0566,4.5837,4.5837,0,0,1-.36-1.9444v-9.5039a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4988,5.4988,0,0,1,3.6963-1.1044c2.4483,0,3.7442,1.0083,4.2481,1.9921a4.689,4.689,0,0,1,.3843,2.2564v9.4321A3.6786,3.6786,0,0,1,234.8677,436.6713Zm-2.1607-12.0728a2.9679,2.9679,0,0,0-.1919-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.7252,1.7252,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.68,2.68,0,0,0,.2881,1.5595,1.6125,1.6125,0,0,0,1.272.504,1.8309,1.8309,0,0,0,.9844-.2872c.5761-.4082.5517-1.08.5517-1.705Z" />
                                    <path className="st6" d="M242.0664,423.1107v4.2481h4.8486v2.5205h-4.8486v7.56h-3.144V420.4945h8.3047v2.6162Z" />
                                    <path className="st6" d="M262.2505,437.44c-.0479-.0967-.0957-.1679-.1441-.2646a3.0791,3.0791,0,0,1-.312-1.3682v-3.8643c0-.2636,0-.5273-.0239-.8154-.0957-.9365-.792-1.1523-1.6079-1.1523h-1.4883V437.44h-3.12V420.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0277,2.0277,0,0,1-.36.4561,3.1378,3.1378,0,0,1-1.4883.6,3.4357,3.4357,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8623,3.8623,0,0,0,.4321,1.9922,1.03,1.03,0,0,0,.2881.2646Zm-.5039-13.1289c0-.6484-.0962-.8642-.36-1.1284a1.4437,1.4437,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4337,1.4337,0,0,0,1.1758-.4082c.24-.288.2642-.5283.2642-1.2Z" />
                                    <path className="st6" d="M267.8657,437.44V420.4945h8.2085v2.5679h-5.0884v3.9839h4.8v2.5683h-4.8v5.209h5.3287V437.44Z" />
                                    <path className="st6" d="M287.498,435.2074c-.3837,1.3682-1.5122,2.5918-4.392,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8672,2.8672,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.52,1.52,0,0,0,1.08-.3125c.4561-.36.4561-1.1035.4561-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8176,2.8176,0,0,0-.72-.6719l-2.9521-1.9922a3.9251,3.9251,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8647-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.8834,2.8834,0,0,1,.2641,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5673,9.5673,0,0,1,287.498,435.2074Z" />
                                    <path className="st6" d="M298.7544,436.6713a5.2054,5.2054,0,0,1-3.6724,1.1279,5.4208,5.4208,0,0,1-3.5283-.9834,2.7411,2.7411,0,0,1-.84-1.0566,4.5837,4.5837,0,0,1-.36-1.9444v-9.5039a4.0742,4.0742,0,0,1,1.0562-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4482,0,3.7441,1.0083,4.248,1.9921a4.6878,4.6878,0,0,1,.3843,2.2564v9.4321A3.6786,3.6786,0,0,1,298.7544,436.6713Zm-2.1606-12.0728a2.9693,2.9693,0,0,0-.1919-1.3677,1.322,1.322,0,0,0-1.2959-.7441,1.7249,1.7249,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.68,2.68,0,0,0,.288,1.5595,1.6126,1.6126,0,0,0,1.272.504,1.8307,1.8307,0,0,0,.9844-.2872c.5762-.4082.5518-1.08.5518-1.705Z" />
                                    <path className="st6" d="M311.1855,436.3353c-1.2,1.5606-3.5039,1.4639-3.9121,1.4639a4.5974,4.5974,0,0,1-3.4565-1.2236,3.391,3.391,0,0,1-.72-1.1524,5.1054,5.1054,0,0,1-.312-1.8721V420.4945h3.12v12.6006a3.4528,3.4528,0,0,0,.1679,1.32,1.3772,1.3772,0,0,0,1.2959.72,1.5177,1.5177,0,0,0,.9121-.2636c.6-.4082.5284-1.2.5284-1.8V420.4945h3.144v13.1289A3.9879,3.9879,0,0,1,311.1855,436.3353Z" />
                                    <path className="st6" d="M321.8174,437.44c-.0479-.0967-.0957-.1679-.1441-.2646a3.0791,3.0791,0,0,1-.312-1.3682v-3.8643c0-.2636,0-.5273-.0239-.8154-.0957-.9365-.792-1.1523-1.6079-1.1523h-1.4883V437.44h-3.12V420.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0277,2.0277,0,0,1-.36.4561,3.1378,3.1378,0,0,1-1.4883.6,3.4357,3.4357,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8623,3.8623,0,0,0,.4321,1.9922,1.03,1.03,0,0,0,.2881.2646Zm-.5039-13.1289c0-.6484-.0957-.8642-.36-1.1284a1.4438,1.4438,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4338,1.4338,0,0,0,1.1758-.4082c.24-.288.2642-.5283.2642-1.2Z" />
                                    <path className="st6" d="M336.3135,435.4955c-.8164,2.3525-3.96,2.3037-4.32,2.3037a8.19,8.19,0,0,1-1.6084-.168,3.5349,3.5349,0,0,1-2.8565-2.0156,4.5522,4.5522,0,0,1-.24-1.7764v-9.2163c0-.2881.0239-.6.0478-.8882a3.2318,3.2318,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.6513,5.6513,0,0,1,3.168.768,2.9569,2.9569,0,0,1,1.0561,1.2481,3.74,3.74,0,0,1,.2881,1.92v2.5918H333.481v-2.1358c0-1.2959-.2159-1.9922-1.608-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.1036.24,1.92,1.5362,1.92,1.392,0,1.5122-.792,1.5122-2.0879V431.08h3.0722v2.3994A6.288,6.288,0,0,1,336.3135,435.4955Z" />
                                    <path className="st6" d="M339.24,437.44V420.4945h8.2085v2.5679H342.36v3.9839h4.8v2.5683h-4.8v5.209h5.3286V437.44Z" />
                                    <path className="st6" d="M358.873,435.2074c-.3837,1.3682-1.5122,2.5918-4.392,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8672,2.8672,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.52,1.52,0,0,0,1.08-.3125c.4561-.36.4561-1.1035.4561-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8176,2.8176,0,0,0-.72-.6719l-2.9521-1.9922a3.9251,3.9251,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8647-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.8834,2.8834,0,0,1,.2641,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5673,9.5673,0,0,1,358.873,435.2074Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M742.3232,784.306V801.77h-1.8086V784.306h-5.9921v-1.5927h13.82v1.5927Z" />
                                    <path className="st6" d="M751.8828,801.77V782.7133h1.8086V801.77Z" />
                                    <path className="st6" d="M774.2334,801.77l.1348-16.1953L768.16,801.77h-1.2413l-6.3437-16.1953.2158,16.1953H759.01V782.7133h2.1318l6.4522,16.1689,6.1806-16.1689h2.24V801.77Z" />
                                    <path className="st6" d="M781.3389,801.77V782.7133h12.4433V784.36H783.1475v6.8018h9.6367v1.6738h-9.6367v7.2617H794.457V801.77Z" />
                                </g>

                                <g>
                                    <path className="st6" d="M461.1748,825.2172v4.1611h3.8613v1.54h-3.8613V837.76h-1.78v-14.123h6.2217v1.58Z" />
                                    <path className="st6" d="M474.1963,837.52a4.1816,4.1816,0,0,1-2.041.44,3.7708,3.7708,0,0,1-2.58-.82,2.6474,2.6474,0,0,1-.58-.9609,6.4624,6.4624,0,0,1-.24-1.9395V823.6371h1.78v9.3613a8.694,8.694,0,0,0,.16,2.5606,1.3565,1.3565,0,0,0,.84.78,1.9,1.9,0,0,0,.7.1006,1.4075,1.4075,0,0,0,1.28-.52,1.2707,1.2707,0,0,0,.24-.5,11.7319,11.7319,0,0,0,.08-1.74v-10.042h1.78v10.542C475.6162,835.4994,475.5156,836.8,474.1963,837.52Z" />
                                    <path className="st6" d="M483.0957,825.2172V837.76h-1.74V825.2172h-2.6807v-1.58h7.1016v1.58Z" />
                                    <path className="st6" d="M494.2764,837.52a4.1817,4.1817,0,0,1-2.041.44,3.7706,3.7706,0,0,1-2.58-.82,2.646,2.646,0,0,1-.58-.9609,6.4624,6.4624,0,0,1-.24-1.9395V823.6371h1.78v9.3613a8.6931,8.6931,0,0,0,.16,2.5606,1.3564,1.3564,0,0,0,.84.78,1.9008,1.9008,0,0,0,.7.1006,1.4076,1.4076,0,0,0,1.28-.52,1.2709,1.2709,0,0,0,.24-.5,11.7138,11.7138,0,0,0,.08-1.74v-10.042h1.78v10.542C495.6963,835.4994,495.5957,836.8,494.2764,837.52Z" />
                                    <path className="st6" d="M505.1562,837.76l-2.4-6.2011h-1.461V837.76h-1.74v-14.123h3.081a3.1826,3.1826,0,0,1,3.74,3.7607,10.3211,10.3211,0,0,1-.14,1.76,3.0883,3.0883,0,0,1-.58,1.4805,2.1039,2.1039,0,0,1-1.1806.7207l2.5205,6.4013Zm-.7-11.583c-.36-1.0195-1.44-.9394-2.3008-.9394h-.86v4.94h.9209a4.2906,4.2906,0,0,0,1.54-.16c.88-.3593.86-1.6.86-2.34A4.7371,4.7371,0,0,0,504.4561,826.1771Z" />
                                    <path className="st6" d="M510.2139,837.76v-14.123h6.2216v1.58h-4.4609v4.2011h3.9v1.6006h-3.9v5.16h4.6006v1.581Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M366.2393,831.0785a3.5453,3.5453,0,0,1-2.8.8809H361.959V837.76h-1.7608v-14.123h3.6612a2.6446,2.6446,0,0,1,2.38.94,4.8279,4.8279,0,0,1,.74,3.3408A5.37,5.37,0,0,1,366.2393,831.0785Zm-1.02-4.4209c-.16-1.3994-1.2-1.42-2.3408-1.42h-.92v5.1806h1.18c.78,0,1.581.04,1.9609-.8193a3.9559,3.9559,0,0,0,.16-1.5811C365.26,827.558,365.26,827.1185,365.22,826.6576Z" />
                                    <path className="st6" d="M375.5391,837.76l-.5-2.7207h-3.28l-.4805,2.7207h-1.82l3.041-14.123h1.76L377.38,837.76Zm-2.1807-11.5019-1.3,7.2617h2.6807Z" />
                                    <path className="st6" d="M385.44,837.3607a3.8664,3.8664,0,0,1-2.2012.6,3.1691,3.1691,0,0,1-3.1807-1.94,4.7253,4.7253,0,0,1-.22-1.58v-1.5605h1.66v.7a5.5623,5.5623,0,0,0,.16,1.86,1.5769,1.5769,0,0,0,1.58,1.02,1.71,1.71,0,0,0,1.6211-.9209,1.9756,1.9756,0,0,0,.16-.84,3.2055,3.2055,0,0,0-1.32-2.5l-1.9-1.961a4.4073,4.4073,0,0,1-1.6211-3.541,5.0947,5.0947,0,0,1,.16-1.34c.28-.92,1.06-1.94,3.1211-1.94a2.7122,2.7122,0,0,1,2.86,1.8408,7.445,7.445,0,0,1,.12,1.64v.9h-1.5606c-.0195-.88-.0195-.66-.0195-1.16a1.5339,1.5339,0,0,0-.7608-1.58,1.8061,1.8061,0,0,0-.74-.1406,1.4182,1.4182,0,0,0-1.06.38,1.4542,1.4542,0,0,0-.28.3809,2.407,2.407,0,0,0-.1407.94,2.5326,2.5326,0,0,0,.12.82,5.3092,5.3092,0,0,0,1.28,1.7l2.2207,2.3613c.16.18.3.3594.44.54a3.8636,3.8636,0,0,1,.78,2.52A3.0108,3.0108,0,0,1,385.44,837.3607Z" />
                                    <path className="st6" d="M393.7588,825.2181V837.76h-1.74v-12.542h-2.6807v-1.581H396.44v1.581Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M403.1616,771.7308v13.29h-2.8823v-13.29h-2.86v-2.2441h8.6255v2.2441Z" />
                                    <path className="st6" d="M416.5386,784.3168a4.7775,4.7775,0,0,1-3.3667,1.0342,4.9662,4.9662,0,0,1-3.2344-.9024,2.5137,2.5137,0,0,1-.77-.9678,4.2042,4.2042,0,0,1-.33-1.7822v-8.7139a3.7365,3.7365,0,0,1,.9683-2.8164,5.042,5.042,0,0,1,3.3881-1.0117c2.2447,0,3.4327.9238,3.8946,1.8262a4.2964,4.2964,0,0,1,.352,2.0683v8.6475A3.3728,3.3728,0,0,1,416.5386,784.3168Zm-1.98-11.0674a2.7254,2.7254,0,0,0-.1763-1.2549,1.2118,1.2118,0,0,0-1.188-.6816,1.5788,1.5788,0,0,0-.9243.2861c-.6377.4395-.55,1.1221-.55,1.76v7.8554a2.4564,2.4564,0,0,0,.2642,1.43,1.476,1.476,0,0,0,1.166.4629,1.6784,1.6784,0,0,0,.9023-.2646c.5279-.3741.5059-.99.5059-1.5625Z" />
                                    <path className="st6" d="M429.8037,781.6986a3.2606,3.2606,0,0,1-1.4521,2.8379,4.6007,4.6007,0,0,1-2.4649.4844h-4.5981V769.4867h5.2583a3.2591,3.2591,0,0,1,2.1347.7695,3.8864,3.8864,0,0,1,1.1436,3.3233V780.84C429.8252,781.1263,429.8252,781.4125,429.8037,781.6986Zm-2.8389-8.3613a1.526,1.526,0,0,0-.3955-1.32,1.9146,1.9146,0,0,0-1.2988-.2647h-1.1216v11.002h1.0122a5.0437,5.0437,0,0,0,.8135-.044c.9248-.1758.99-.8584.99-1.6279Z" />
                                    <path className="st6" d="M439.0215,785.0209l-.375-2.53h-2.64l-.4179,2.53h-2.86l3.0137-15.5342h3.2793l2.9043,15.5342Zm-1.6289-11.5957-1.0342,6.667h1.9805Z" />
                                    <path className="st6" d="M449.23,780.4222v4.5987h-2.9268V780.224l-3.1015-10.7373h3.0136l1.585,7.833,1.5176-7.833h3.0146Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M65.2256,255.306H46.168V242.8627h1.6474v10.6347h6.8018v-9.6367H56.291v9.6367h7.2608V242.1879h1.6738Z" />
                                    <path className="st6" d="M65.2256,231.3588v1.62L46.168,239.97V237.972l16.5742-5.8574L46.168,226.1224v-1.917Z" />
                                    <path className="st6" d="M62.9043,207.3851a7.1345,7.1345,0,0,1,2.2939,2.9961,8.1923,8.1923,0,0,1,.5127,2.9961,7.1688,7.1688,0,0,1-2.2129,5.5332c-1.2685,1.2149-3.6982,2.7266-7.8554,2.7266-1.0254,0-4.8584-.0274-7.585-2.5371a7.15,7.15,0,0,1-1.8349-2.6182,8.1835,8.1835,0,0,1-.54-3.1045,7.3026,7.3026,0,0,1,3.1856-6.37,11.6936,11.6936,0,0,1,6.7207-1.7822A12.6909,12.6909,0,0,1,62.9043,207.3851ZM49.2188,209.058a6.1549,6.1549,0,0,0-1.377,1.6738,5.4688,5.4688,0,0,0-.5938,2.6455c0,4.7237,4.5616,6.3428,8.4493,6.3428.6748,0,4.6425-.0264,6.8828-2.3477a5.4593,5.4593,0,0,0,1.5117-3.914,5.1007,5.1007,0,0,0-1.7275-4.1035c-2.3213-2.1319-6.1006-2.2129-6.7754-2.2129A10.3754,10.3754,0,0,0,49.2188,209.058Z" />
                                    <path className="st6" d="M65.2256,200.74H46.168v-1.8349H63.5518V188.3773h1.6738Z" />
                                    <path className="st6" d="M58.7744,171.6381a7.3271,7.3271,0,0,1,4.8047,1.4843c.998.8643,2.1318,2.4834,2.1318,5.5332a8.0183,8.0183,0,0,1-1.4843,4.8858,6.0631,6.0631,0,0,1-1.5655,1.3769c-1.7549.9991-3.6982.918-7.666.918H46.168v-1.7822H58.5312a7.0046,7.0046,0,0,0,3.1309-.5664,3.71,3.71,0,0,0,1.6465-1.6465,6.4669,6.4669,0,0,0,.73-3.1582,5.6528,5.6528,0,0,0-1.5928-4.211c-1.2959-1.08-2.9961-1.08-4.5351-1.08H46.168v-1.8076h8.665C56.1562,171.5844,57.4512,171.5844,58.7744,171.6381Z" />
                                    <path className="st6" d="M47.7607,160.2689H65.2256v1.8086H47.7607V168.07H46.168v-13.82h1.5927Z" />
                                    <path className="st6" d="M65.2256,150.7094H46.168v-1.8086H65.2256Z" />
                                    <path className="st6" d="M62.9043,130.1664a7.1338,7.1338,0,0,1,2.2939,2.9961,8.1918,8.1918,0,0,1,.5127,2.9961,7.1688,7.1688,0,0,1-2.2129,5.5332c-1.2685,1.2148-3.6982,2.7265-7.8554,2.7265-1.0254,0-4.8584-.0273-7.585-2.5371a7.1492,7.1492,0,0,1-1.8349-2.6181,8.1843,8.1843,0,0,1-.54-3.1045,7.3025,7.3025,0,0,1,3.1856-6.37,11.6934,11.6934,0,0,1,6.7207-1.7823A12.691,12.691,0,0,1,62.9043,130.1664Zm-13.6855,1.6728a6.1558,6.1558,0,0,0-1.377,1.6739,5.4685,5.4685,0,0,0-.5938,2.6455c0,4.7236,4.5616,6.3427,8.4493,6.3427.6748,0,4.6425-.0263,6.8828-2.3476a5.4594,5.4594,0,0,0,1.5117-3.9141,5.1008,5.1008,0,0,0-1.7275-4.1035c-2.3213-2.1318-6.1006-2.2129-6.7754-2.2129A10.3768,10.3768,0,0,0,49.2188,131.8392Z" />
                                    <path className="st6" d="M65.2256,110.5648,48.76,121.74H65.2256v1.7813H46.168V121.74l16.3037-11.31-16.3037.1357v-1.7822H65.2256Z" />
                                    <path className="st6" d="M65.2256,90.4769l-5.75,2.1866v8.5292l5.75,2.1866v1.998L46.168,97.6029V96.0912l19.0576-7.585ZM48.2734,96.8744l9.7452,3.7246V93.2035Z" />
                                    <path className="st6" d="M65.2256,72.764l-8.4492,6.02v4.4541h8.4492v1.8349H46.168V79.7015c0-1.0527-.0264-2.1328.0273-3.2129a5.588,5.588,0,0,1,2.5371-4.7236,5.8362,5.8362,0,0,1,2.8887-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2485,8.2485,0,0,1,.4043,2.3213l8.4492-6.1816Zm-15.8184.6211c-1.4843.8907-1.6191,2.4287-1.6191,3.9678v5.8848h7.4228V78.7562c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.7519a3.5806,3.5806,0,0,0-2.24-.6749A4.0516,4.0516,0,0,0,49.4072,73.3851Z" />
                                    <path className="st6" d="M57.3438,60.5326h7.8818v1.7812H57.3438l-11.1758,7.1V67.2806l9.4746-5.8847-9.4746-6.127V53.0824Z" />
                                </g>
                                <g className="cls-8">
                                    <path className="st6" d="M85.4951,255.9261a3.4535,3.4535,0,0,1,2.8218,3.23,2.551,2.551,0,0,1-1.1221,2.2266,1.8643,1.8643,0,0,1-.4248.2212h5.6607v.9858H80.0215v-.9858h1.3262a1.9387,1.9387,0,0,1-.5269-.3062,2.5672,2.5672,0,0,1-.9692-2.1416,3.0671,3.0671,0,0,1,.4253-1.6826,4.5352,4.5352,0,0,1,3.8242-1.7173A5.9119,5.9119,0,0,1,85.4951,255.9261Zm-2.9575,1.0879a2.3208,2.3208,0,0,0-1.7681,2.3116,2.2442,2.2442,0,0,0,.2549,1.0537,2.4931,2.4931,0,0,0,1.6831,1.105,7.8955,7.8955,0,0,0,1.2578.1191,3.4135,3.4135,0,0,0,2.9068-.8838,2.107,2.107,0,0,0,.476-1.4111c0-1.87-1.8188-2.5157-3.3828-2.5157A4.6381,4.6381,0,0,0,82.5376,257.014Z" />
                                    <path className="st6" d="M87.7051,247.8339a1.3826,1.3826,0,0,1-1.0542.3569,2.2591,2.2591,0,0,1,.6972.4253,3.3052,3.3052,0,0,1,.9517,2.4477,2.5566,2.5566,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6119,1.9818,1.9818,0,0,1-1.2744-.4419,4.7171,4.7171,0,0,1-1.2412-2.4986c-.1357-.51-.289-1.02-.3906-1.53l-.2041-.9521h-.4248a2.0516,2.0516,0,0,0-.7822.085c-.5269.17-1.0879.6289-1.0708,1.853,0,1.0366.289,1.5639.6967,1.8359a2.3437,2.3437,0,0,0,1.2578.3057l-.187,1.105a4.1456,4.1456,0,0,1-1.4106-.4761,3.047,3.047,0,0,1-1.2407-2.7026A2.5424,2.5424,0,0,1,82.98,247.222H85.58c.7822,0,1.3261.0171,1.5639-.2719a1.5274,1.5274,0,0,0,.1871-.9351H88.13C88.13,246.9672,88.0449,247.494,87.7051,247.8339Zm-3.8589.3569.1362.51a15.428,15.428,0,0,0,1.105,3.4336,1.2392,1.2392,0,0,0,.9858.51,1.3432,1.3432,0,0,0,.5777-.1528,1.6333,1.6333,0,0,0,.7651-1.479,2.9677,2.9677,0,0,0-1.53-2.6006,3.1789,3.1789,0,0,0-1.4282-.2212Z" />
                                    <path className="st6" d="M88.2827,241.8329a1.4545,1.4545,0,0,1-.0171.272,1.13,1.13,0,0,1-.7138,1.0708,6.3652,6.3652,0,0,1-1.6148.1191H80.8716v1.7h-.85v-1.7h-3.45v-.9692h3.45v-1.7847h.85v1.7847H86.209c.4761.0171,1.19.0171,1.19-.6626a2.1277,2.1277,0,0,0-.51-1.1221h1.0195A2.1866,2.1866,0,0,1,88.2827,241.8329Z" />
                                    <path className="st6" d="M88.13,233.078H83.71c-.2548,0-.51.0166-.7822.0166-.7988.0171-1.5805.1191-1.9717.9351a2.2718,2.2718,0,0,0-.187.9521,2.1644,2.1644,0,0,0,1.1392,2.04,5.8833,5.8833,0,0,0,2.72.34H88.13v1.02H76.1289v-1.02h5.1846a2.1759,2.1759,0,0,1-.4419-.272,2.7017,2.7017,0,0,1-1.02-2.2436,2.8087,2.8087,0,0,1,1.0029-2.2266c.6123-.459,1.2749-.5273,2.7026-.5273H88.13Z" />
                                    <path className="st6" d="M85.665,217.2684a3.5641,3.5641,0,0,1,2.669,3.5015,3.4078,3.4078,0,0,1-1.36,2.8047,4.5747,4.5747,0,0,1-2.8047.833,5.5277,5.5277,0,0,1-2.0571-.374,3.4154,3.4154,0,0,1-2.2944-3.3316,3.3086,3.3086,0,0,1,.9009-2.38,4.6164,4.6164,0,0,1,3.3144-1.2749A5.4311,5.4311,0,0,1,85.665,217.2684Zm-4.9975,3.4507a2.4849,2.4849,0,0,0,.459,1.4619,3.53,3.53,0,0,0,3.0429,1.1558,3.5931,3.5931,0,0,0,2.8047-1.0708,2.3474,2.3474,0,0,0,.4761-1.5127c0-1.19-.7481-2.6182-3.3149-2.6348C81.5005,218.0844,80.6675,219.4271,80.6675,220.7191Z" />
                                    <path className="st6" d="M80.8716,212.4906H88.13v1.0029H80.8716v1.7847h-.85v-1.7847H78.4746a4.8872,4.8872,0,0,1-1.5635-.102c-.51-.17-1.02-.5611-1.02-1.7a5.2094,5.2094,0,0,1,.1192-1.0371h.9858a2.3678,2.3678,0,0,0-.2212.9692.698.698,0,0,0,.2212.5947c.3227.3228.646.272,1.3935.272h1.6319v-1.8359h.85Z" />
                                    <path className="st6" d="M86.9229,198.0927a2.93,2.93,0,0,1,1.4111,2.77,3.3744,3.3744,0,0,1-1.2241,2.72,4.1231,4.1231,0,0,1-.9517.5439l-.34-.9858A2.1991,2.1991,0,0,0,87.416,200.88a2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.31,1.31,0,0,0-.7139.2212c-.3569.2549-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6348a2.0226,2.0226,0,0,1-1.2749.4248,2.2255,2.2255,0,0,1-1.581-.6968,3.4178,3.4178,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9887,2.2181,2.2181,0,0,1,1.3086-.8838l.2554.9516c-.9351.187-1.207,1.1563-1.207,1.9717a2.4153,2.4153,0,0,0,.3061,1.3262,1.1368,1.1368,0,0,0,.3569.3569,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.853,2.0162,2.0162,0,0,1,1.2408-.3906A2.42,2.42,0,0,1,86.9229,198.0927Z" />
                                    <path className="st6" d="M89.3027,192.6864c1.938.7646,2.941,1.0537,2.941,2.3457a1.7877,1.7877,0,0,1-.3233,1.0708H90.9a1.7524,1.7524,0,0,0,.3911-1.0537c0-.5781-.374-.731-1.2236-1.0879-.2554-.102-.51-.2212-.7652-.3232l-1.1728-.4248-8.1084,2.8046v-.9687l6.9014-2.3628-6.9014-2.6348v-1.02Z" />
                                    <path className="st6" d="M86.9229,181.62a2.93,2.93,0,0,1,1.4111,2.77,3.3742,3.3742,0,0,1-1.2241,2.72,4.1236,4.1236,0,0,1-.9517.544l-.34-.9859a2.1984,2.1984,0,0,0,1.5976-2.2607,2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.3092,1.3092,0,0,0-.7139.2212c-.3569.2548-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6347a2.022,2.022,0,0,1-1.2749.4248,2.2251,2.2251,0,0,1-1.581-.6968,3.4175,3.4175,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9888,2.2186,2.2186,0,0,1,1.3086-.8838l.2554.9517c-.9351.187-1.207,1.1557-1.207,1.9722a2.4144,2.4144,0,0,0,.3061,1.3256,1.1378,1.1378,0,0,0,.3569.357,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.8531,2.0162,2.0162,0,0,1,1.2408-.3906A2.42,2.42,0,0,1,86.9229,181.62Z" />
                                    <path className="st6" d="M88.2827,176.4008a1.4529,1.4529,0,0,1-.0171.2719,1.13,1.13,0,0,1-.7138,1.0708,6.3606,6.3606,0,0,1-1.6148.1192H80.8716v1.7h-.85v-1.7h-3.45v-.9693h3.45v-1.7846h.85v1.7846H86.209c.4761.0171,1.19.0171,1.19-.6626a2.1274,2.1274,0,0,0-.51-1.122h1.0195A2.1853,2.1853,0,0,1,88.2827,176.4008Z" />
                                    <path className="st6" d="M87.042,166.7113a3.4774,3.4774,0,0,1,1.292,2.89,4.0525,4.0525,0,0,1-.4253,1.87c-1.0025,1.938-3.3823,2.0058-3.9092,2.0058a4.8419,4.8419,0,0,1-1.6489-.2548,3.6321,3.6321,0,0,1-2.5327-3.4849,3.4419,3.4419,0,0,1,1.1557-2.6006,4.5781,4.5781,0,0,1,2.8555-1.0537h.2554v6.2891a4.2207,4.2207,0,0,0,1.36-.1529,2.5056,2.5056,0,0,0,1.9717-2.6006,2.7084,2.7084,0,0,0-1.7-2.601l.272-.9346A5.4521,5.4521,0,0,1,87.042,166.7113Zm-6.3232,3.0425a2.51,2.51,0,0,0,1.4106,2.2949,4.4208,4.4208,0,0,0,1.1221.3062v-5.1167A2.4884,2.4884,0,0,0,80.7188,169.7538Z" />
                                    <path className="st6" d="M88.13,153.91H83.7275c-1.394,0-2.1757-.0678-2.6689.5611a1.6472,1.6472,0,0,0-.2891,1.0029,2.0392,2.0392,0,0,0,1.3091,1.9038,5.9577,5.9577,0,0,0,2.6685.3232H88.13v.9859H83.6255a4.93,4.93,0,0,0-2.1587.2549A1.4242,1.4242,0,0,0,80.77,160.25a1.9614,1.9614,0,0,0,1.0879,1.8022,6.0354,6.0354,0,0,0,3.0088.4077H88.13v1.003H80.0215V162.46h1.6831a2.26,2.26,0,0,1-1.853-2.4135,2.0881,2.0881,0,0,1,1.2241-2.0572,2.601,2.601,0,0,1,.7988-.17,2.9012,2.9012,0,0,1-.8159-.3228,2.3569,2.3569,0,0,1-1.207-2.21,2.4771,2.4771,0,0,1,.4082-1.4619c.6631-.9517,1.8188-.9175,3.8755-.9175H88.13Z" />
                                    <path className="st6" d="M86.9229,144.39a2.93,2.93,0,0,1,1.4111,2.7705,3.3742,3.3742,0,0,1-1.2241,2.72,4.1236,4.1236,0,0,1-.9517.544l-.34-.9858a2.1987,2.1987,0,0,0,1.5976-2.2608,2.4527,2.4527,0,0,0-.4082-1.4956,1.5417,1.5417,0,0,0-1.1728-.5781,1.3092,1.3092,0,0,0-.7139.2212c-.3569.2549-.561.6626-.7481,1.53-.1865.7988-.3227,2.0566-1.02,2.6347a2.022,2.022,0,0,1-1.2749.4248,2.2253,2.2253,0,0,1-1.581-.6967,3.4178,3.4178,0,0,1-.68-2.21,3.61,3.61,0,0,1,.5268-1.9888,2.2186,2.2186,0,0,1,1.3086-.8838l.2554.9517c-.9351.187-1.207,1.1558-1.207,1.9722a2.4139,2.4139,0,0,0,.3061,1.3256,1.1378,1.1378,0,0,0,.3569.357,1.4013,1.4013,0,0,0,.6968.187c.9517,0,1.1729-1.0029,1.3086-1.7l.17-.85a2.826,2.826,0,0,1,1.0537-1.853,2.0158,2.0158,0,0,1,1.2408-.3907A2.42,2.42,0,0,1,86.9229,144.39Z" />
                                    <path className="st6" d="M85.4951,129.6342a3.4544,3.4544,0,0,1,2.8218,3.2294,2.5531,2.5531,0,0,1-1.1221,2.2276,1.8511,1.8511,0,0,1-.4248.2207h5.6607v.9863H80.0215v-.9863h1.3262a1.956,1.956,0,0,1-.5269-.3057,2.5687,2.5687,0,0,1-.9692-2.1426,3.0725,3.0725,0,0,1,.4253-1.6826,4.5375,4.5375,0,0,1,3.8242-1.7168A5.955,5.955,0,0,1,85.4951,129.6342Zm-2.9575,1.0878a2.3221,2.3221,0,0,0-1.7681,2.3125,2.2487,2.2487,0,0,0,.2549,1.0538,2.4954,2.4954,0,0,0,1.6831,1.1044,7.8266,7.8266,0,0,0,1.2578.1192,3.412,3.412,0,0,0,2.9068-.8838,2.1063,2.1063,0,0,0,.476-1.4111c0-1.87-1.8188-2.5157-3.3828-2.5157A4.6333,4.6333,0,0,0,82.5376,130.722Z" />
                                    <path className="st6" d="M87.7051,121.5424a1.3841,1.3841,0,0,1-1.0542.3564,2.277,2.277,0,0,1,.6972.4248,3.3071,3.3071,0,0,1,.9517,2.4483,2.5561,2.5561,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6123,1.983,1.983,0,0,1-1.2744-.4424,4.7133,4.7133,0,0,1-1.2412-2.4991c-.1357-.51-.289-1.02-.3906-1.5292l-.2041-.9522h-.4248a2.0615,2.0615,0,0,0-.7822.085c-.5269.17-1.0879.6289-1.0708,1.8525,0,1.0371.289,1.5645.6967,1.8359a2.3491,2.3491,0,0,0,1.2578.3067l-.187,1.1045a4.1528,4.1528,0,0,1-1.4106-.4756,3.0474,3.0474,0,0,1-1.2407-2.7031A2.5423,2.5423,0,0,1,82.98,120.93H85.58c.7822,0,1.3261.0166,1.5639-.2725a1.5252,1.5252,0,0,0,.1871-.9346H88.13C88.13,120.6752,88.0449,121.2015,87.7051,121.5424Zm-3.8589.3564.1362.51a15.4035,15.4035,0,0,0,1.105,3.4336,1.2375,1.2375,0,0,0,.9858.5107,1.3342,1.3342,0,0,0,.5777-.1533,1.633,1.633,0,0,0,.7651-1.4785,2.9693,2.9693,0,0,0-1.53-2.6016,3.1875,3.1875,0,0,0-1.4282-.2207Z" />
                                    <path className="st6" d="M80.8374,113.7728a3.6538,3.6538,0,0,0-.0508.6289,2.0809,2.0809,0,0,0,1.9209,2.1934,13.0465,13.0465,0,0,0,1.9209.085H88.13v1.0029H80.0215V116.68h1.292a1.9319,1.9319,0,0,1-.4077-.2217,2.3725,2.3725,0,0,1-.7994-.9512,2.7443,2.7443,0,0,1-.2036-1.1562,4.5619,4.5619,0,0,1,.0337-.5948Z" />
                                    <path className="st6" d="M87.7051,106.4642a1.3838,1.3838,0,0,1-1.0542.3565,2.277,2.277,0,0,1,.6972.4248,3.3066,3.3066,0,0,1,.9517,2.4482,2.5559,2.5559,0,0,1-.6968,1.9375,2.1729,2.1729,0,0,1-1.5132.6123,1.9824,1.9824,0,0,1-1.2744-.4424,4.7126,4.7126,0,0,1-1.2412-2.499c-.1357-.51-.289-1.0195-.3906-1.5293l-.2041-.9521h-.4248a2.0634,2.0634,0,0,0-.7822.0849c-.5269.17-1.0879.6289-1.0708,1.8526,0,1.0371.289,1.5644.6967,1.8359a2.3491,2.3491,0,0,0,1.2578.3067l-.187,1.1044a4.15,4.15,0,0,1-1.4106-.4755,3.0476,3.0476,0,0,1-1.2407-2.7032,2.5424,2.5424,0,0,1,3.1616-2.9746H85.58c.7822,0,1.3261.0166,1.5639-.2724a1.5252,1.5252,0,0,0,.1871-.9346H88.13C88.13,105.597,88.0449,106.1234,87.7051,106.4642Zm-3.8589.3565.1362.51a15.4,15.4,0,0,0,1.105,3.4336,1.2374,1.2374,0,0,0,.9858.5108,1.3356,1.3356,0,0,0,.5777-.1533,1.6332,1.6332,0,0,0,.7651-1.4786,2.9693,2.9693,0,0,0-1.53-2.6015,3.1861,3.1861,0,0,0-1.4282-.2207Z" />
                                    <path className="st6" d="M88.13,93.0511H83.7275c-1.394,0-2.1757-.0673-2.6689.5616a1.6465,1.6465,0,0,0-.2891,1.0029,2.039,2.039,0,0,0,1.3091,1.9033,5.9647,5.9647,0,0,0,2.6685.3233H88.13v.9853H83.6255a4.9376,4.9376,0,0,0-2.1587.2549,1.4258,1.4258,0,0,0-.6973,1.31,1.9615,1.9615,0,0,0,1.0879,1.8017,6.0331,6.0331,0,0,0,3.0088.4082H88.13v1.003H80.0215v-1.003h1.6831a2.2642,2.2642,0,0,1-1.853-2.414,2.0867,2.0867,0,0,1,1.2241-2.0567,2.5564,2.5564,0,0,1,.7988-.17,2.93,2.93,0,0,1-.8159-.3232,2.3572,2.3572,0,0,1-1.207-2.21,2.4761,2.4761,0,0,1,.4082-1.4619c.6631-.9522,1.8188-.918,3.8755-.918H88.13Z" />
                                    <path className="st6" d="M87.042,82.6293a3.4789,3.4789,0,0,1,1.292,2.89,4.0537,4.0537,0,0,1-.4253,1.87C86.9062,89.3275,84.5264,89.3949,84,89.3949a4.84,4.84,0,0,1-1.6489-.2549,3.6318,3.6318,0,0,1-2.5327-3.4844,3.44,3.44,0,0,1,1.1557-2.6005A4.5761,4.5761,0,0,1,83.8291,82h.2554v6.29a4.2324,4.2324,0,0,0,1.36-.1533,2.5057,2.5057,0,0,0,1.9717-2.6006,2.7075,2.7075,0,0,0-1.7-2.6006L85.9883,82A5.4414,5.4414,0,0,1,87.042,82.6293Zm-6.3232,3.0429a2.5091,2.5091,0,0,0,1.4106,2.295,4.3785,4.3785,0,0,0,1.1221.3056V83.1566A2.4887,2.4887,0,0,0,80.7188,85.6722Z" />
                                    <path className="st6" d="M88.2827,77.24a1.4592,1.4592,0,0,1-.0171.2725,1.1291,1.1291,0,0,1-.7138,1.07,6.32,6.32,0,0,1-1.6148.1191H80.8716v1.7h-.85v-1.7h-3.45v-.9687h3.45V75.9476h.85v1.7852H86.209c.4761.0176,1.19.0176,1.19-.6631a2.1333,2.1333,0,0,0-.51-1.1221h1.0195A2.1905,2.1905,0,0,1,88.2827,77.24Z" />
                                    <path className="st6" d="M87.042,67.55a3.48,3.48,0,0,1,1.292,2.8906,4.0526,4.0526,0,0,1-.4253,1.8691C86.9062,74.2474,84.5264,74.3158,84,74.3158a4.84,4.84,0,0,1-1.6489-.2549,3.6318,3.6318,0,0,1-2.5327-3.4844,3.44,3.44,0,0,1,1.1557-2.6,4.5761,4.5761,0,0,1,2.8555-1.0547h.2554v6.29a4.2324,4.2324,0,0,0,1.36-.1533,2.5057,2.5057,0,0,0,1.9717-2.6006,2.7075,2.7075,0,0,0-1.7-2.6006l.272-.9355A5.4414,5.4414,0,0,1,87.042,67.55Zm-6.3232,3.0429a2.5091,2.5091,0,0,0,1.4106,2.295,4.3785,4.3785,0,0,0,1.1221.3056V68.0775A2.4887,2.4887,0,0,0,80.7188,70.5931Z" />
                                    <path className="st6" d="M80.8374,60.3939a3.6529,3.6529,0,0,0-.0508.6289,2.0809,2.0809,0,0,0,1.9209,2.1934,13.05,13.05,0,0,0,1.9209.0849H88.13v1.003H80.0215v-1.003h1.292a1.927,1.927,0,0,1-.4077-.2216,2.3725,2.3725,0,0,1-.7994-.9512,2.7445,2.7445,0,0,1-.2036-1.1563,4.5614,4.5614,0,0,1,.0337-.5947Z" />
                                    <path className="st6" d="M86.9229,53.016a2.9306,2.9306,0,0,1,1.4111,2.77,3.3737,3.3737,0,0,1-1.2241,2.72,4.1272,4.1272,0,0,1-.9517.544l-.34-.9854a2.2,2.2,0,0,0,1.5976-2.2607,2.4533,2.4533,0,0,0-.4082-1.4961A1.54,1.54,0,0,0,85.835,53.73a1.3129,1.3129,0,0,0-.7139.2207c-.3569.2548-.561.663-.7481,1.53-.1865.7989-.3227,2.0567-1.02,2.6348a2.028,2.028,0,0,1-1.2749.4248,2.2244,2.2244,0,0,1-1.581-.6973,3.4148,3.4148,0,0,1-.68-2.2089,3.6106,3.6106,0,0,1,.5268-1.9893,2.2211,2.2211,0,0,1,1.3086-.8838l.2554.9522c-.9351.1865-1.207,1.1552-1.207,1.9716a2.41,2.41,0,0,0,.3061,1.3252,1.1328,1.1328,0,0,0,.3569.3575,1.3992,1.3992,0,0,0,.6968.1865c.9517,0,1.1729-1.003,1.3086-1.6992l.17-.85a2.8265,2.8265,0,0,1,1.0537-1.8535,2.0128,2.0128,0,0,1,1.2408-.3906A2.4131,2.4131,0,0,1,86.9229,53.016Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M575.3535,173.12a4.3409,4.3409,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2863,2.2863,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.56,4.5878,4.5878,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9144,3.9144,0,0,1,.3194,1.88V170.74A3.0664,3.0664,0,0,1,575.3535,173.12Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4354,1.4354,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2366,2.2366,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M586.0527,167.1786c-.42.52-1.0195.7-2.3.7h-2.2011V173.76h-2.62v-14.123h5.2413a3.6805,3.6805,0,0,1,.7.06,1.9722,1.9722,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C586.5537,165.9984,586.4932,166.6385,586.0527,167.1786Zm-2.0595-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.961-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.28a1.8259,1.8259,0,0,0,.1807-.98Z" />
                                    <path className="st6" d="M593.2324,161.6776V173.76h-2.6211V161.6776h-2.6006v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M597.7705,173.76v-14.123h2.5811V173.76Z" />
                                    <path className="st6" d="M612.3926,173.76l.24-12.2426-2.28,12.2426h-2.4209l-2.34-12.2426.1992,12.2426h-2.5605v-14.123h4.2412l1.66,9.8823,1.6807-9.8823h4.1807V173.76Z" />
                                    <path className="st6" d="M622.792,173.76l-.3408-2.3h-2.4l-.38,2.3H617.07l2.74-14.123h2.9815l2.6406,14.123Zm-1.4805-10.5424-.94,6.0615h1.8008Z" />
                                    <path className="st6" d="M627.49,173.76v-14.123h2.6006V171.62h3.8213V173.76Z" />
                                    <path className="st6" d="M488.6943,192.74a2.9651,2.9651,0,0,1-1.32,2.5806,4.18,4.18,0,0,1-2.24.44h-4.1807v-14.123h4.7813a2.9645,2.9645,0,0,1,1.94.7,3.5313,3.5313,0,0,1,1.04,3.02v6.6016C488.7148,192.22,488.7148,192.4794,488.6943,192.74Zm-2.58-7.6015a1.39,1.39,0,0,0-.36-1.2007,1.7423,1.7423,0,0,0-1.18-.24h-1.0205V193.7h.92a4.5832,4.5832,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                    <path className="st6" d="M491.4131,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.0009v2.14h-4.0009V193.58h4.44V195.76Z" />
                                    <path className="st6" d="M505.1738,195.76h-2.6211l-2.8-14.123h2.7608l1.38,9.3418,1.28-9.3418h2.66Z" />
                                    <path className="st6" d="M509.8525,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V193.58h4.4405V195.76Z" />
                                    <path className="st6" d="M519.0723,195.76v-14.123h2.6006V193.62h3.82V195.76Z" />
                                    <path className="st6" d="M534.334,195.12a4.3386,4.3386,0,0,1-3.0606.94,4.5144,4.5144,0,0,1-2.94-.82,2.2883,2.2883,0,0,1-.7-.88,3.8175,3.8175,0,0,1-.3-1.62v-7.9219a3.3961,3.3961,0,0,1,.88-2.56,4.5823,4.5823,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.88V192.74A3.0638,3.0638,0,0,1,534.334,195.12Zm-1.8-10.062a2.4832,2.4832,0,0,0-.16-1.1406,1.1042,1.1042,0,0,0-1.081-.62,1.4373,1.4373,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2371,2.2371,0,0,0,.24,1.3,1.3429,1.3429,0,0,0,1.0605.42,1.5218,1.5218,0,0,0,.8193-.24c.4805-.34.461-.9.461-1.42Z" />
                                    <path className="st6" d="M545.0332,189.1786c-.42.52-1.0195.7-2.3.7h-2.2012V195.76h-2.62v-14.123h5.2412a3.6814,3.6814,0,0,1,.7.06,1.9722,1.9722,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C545.5342,187.9984,545.4736,188.6385,545.0332,189.1786Zm-2.06-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2608c.54,0,.84-.06,1-.28a1.8268,1.8268,0,0,0,.1806-.98Z" />
                                    <path className="st6" d="M557.1143,195.76l.24-12.2426L555.0732,195.76h-2.42l-2.3408-12.2426.2,12.2426h-2.5606v-14.123h4.2413l1.66,9.8823,1.6807-9.8823h4.1806V195.76Z" />
                                    <path className="st6" d="M562.5918,195.76v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V193.58h4.44V195.76Z" />
                                    <path className="st6" d="M576.5322,195.76l-2.5-10.582.22,10.582h-2.44v-14.123h3.3408l2.54,10.3623-.26-10.3623h2.3809V195.76Z" />
                                    <path className="st6" d="M586.9531,183.6776V195.76H584.332V183.6776h-2.6006v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M604.0723,189.1786c-.42.52-1.02.7-2.3.7h-2.2012V195.76h-2.62v-14.123h5.2412a3.6814,3.6814,0,0,1,.7.06,1.9727,1.9727,0,0,1,1.38,1,5.1441,5.1441,0,0,1,.3007,2.1206v1.86C604.5732,187.9984,604.5127,188.6385,604.0723,189.1786Zm-2.06-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.28a1.8259,1.8259,0,0,0,.1807-.98Z" />
                                    <path className="st6" d="M611.9121,195.76l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.123h2.9805l2.6406,14.123Zm-1.4805-10.5424-.9394,6.0615h1.8Z" />
                                    <path className="st6" d="M621.5928,195.76v-6.4614h-2.3809V195.76h-2.6006v-14.123h2.6006v5.541h2.3809v-5.541h2.6006V195.76Z" />
                                    <path className="st6" d="M631.332,183.6776V195.76h-2.6211V183.6776H626.11v-2.0405h7.8417v2.0405Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M681.3418,249.6874v3.5405h4.041v2.1006h-4.041V261.63h-2.62V247.5067h6.9219v2.1807Z" />
                                    <path className="st6" d="M694.4033,260.99a4.3408,4.3408,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2862,2.2862,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.5606,4.5883,4.5883,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.54,1.66a3.9,3.9,0,0,1,.32,1.88v7.8619A3.066,3.066,0,0,1,694.4033,260.99Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2372,2.2372,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M704.9619,260.7094a4.0818,4.0818,0,0,1-3.2607,1.2207,3.8288,3.8288,0,0,1-2.88-1.0206,2.8129,2.8129,0,0,1-.6006-.96,4.2625,4.2625,0,0,1-.26-1.5606V247.5067h2.6006v10.5025a2.8818,2.8818,0,0,0,.14,1.1,1.1483,1.1483,0,0,0,1.08.6,1.2651,1.2651,0,0,0,.7608-.22c.5-.34.4394-1,.4394-1.5V247.5067h2.6211v10.9424A3.3221,3.3221,0,0,1,704.9619,260.7094Z" />
                                    <path className="st6" d="M713.1621,261.63l-2.501-10.5821.22,10.5821H708.44V247.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V261.63Z" />
                                    <path className="st6" d="M727.0625,258.6093a2.9674,2.9674,0,0,1-1.32,2.58,4.1851,4.1851,0,0,1-2.2412.44H719.32V247.5067h4.7813a2.9638,2.9638,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02v6.6016C727.082,258.0892,727.082,258.349,727.0625,258.6093Zm-2.5811-7.6016a1.3866,1.3866,0,0,0-.36-1.2007,1.7412,1.7412,0,0,0-1.18-.24h-1.0205v10.0019h.92a4.5884,4.5884,0,0,0,.74-.04c.8408-.16.9-.78.9-1.48Z" />
                                    <path className="st6" d="M734.7012,261.63l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.1231h2.9805l2.6406,14.1231Zm-1.4795-10.5425-.9405,6.0615h1.8Z" />
                                    <path className="st6" d="M743.6611,249.5472V261.63h-2.62V249.5472H738.44v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M748.2,261.63V247.5067h2.581V261.63Z" />
                                    <path className="st6" d="M760.542,260.99a4.3387,4.3387,0,0,1-3.0606.94,4.5145,4.5145,0,0,1-2.94-.82,2.2781,2.2781,0,0,1-.7-.88,3.8185,3.8185,0,0,1-.3008-1.62v-7.9219a3.3983,3.3983,0,0,1,.8809-2.5606,4.5829,4.5829,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9119,3.9119,0,0,1,.32,1.88v7.8619A3.0667,3.0667,0,0,1,760.542,260.99Zm-1.8008-10.062a2.47,2.47,0,0,0-.16-1.1406,1.1028,1.1028,0,0,0-1.08-.62,1.437,1.437,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2291,2.2291,0,0,0,.24,1.3,1.3411,1.3411,0,0,0,1.06.42,1.5243,1.5243,0,0,0,.82-.24c.4805-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M768.8418,261.63l-2.501-10.5821.22,10.5821h-2.44V247.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V261.63Z" />
                                    <path className="st6" d="M782.3418,259.7694c-.32,1.14-1.2607,2.1607-3.6611,2.1607a4.5718,4.5718,0,0,1-2.9-.8,2.4,2.4,0,0,1-.74-.96,3.1074,3.1074,0,0,1-.28-1.46v-2.0606h2.581v1.64c0,.52-.04,1.12.46,1.44a1.4389,1.4389,0,0,0,.8.2,1.2674,1.2674,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.7993,1.7993,0,0,0-.88-1.86l-2.46-1.66a3.2762,3.2762,0,0,1-1.1806-1.1606,3.8742,3.8742,0,0,1-.5-2.24,4.7422,4.7422,0,0,1,.34-1.9405c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.4112,2.4112,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6006,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3222,2.3222,0,0,0,.54.46l2.52,1.7407a5.29,5.29,0,0,1,1.16,1.1,3.3622,3.3622,0,0,1,.5595,2.44A7.9973,7.9973,0,0,1,782.3418,259.7694Z" />
                                    <path className="st6" d="M685.6035,282.99a4.3409,4.3409,0,0,1-3.0615.94,4.5159,4.5159,0,0,1-2.94-.82,2.2862,2.2862,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.5606,4.5883,4.5883,0,0,1,3.0811-.92c2.04,0,3.1211.84,3.541,1.66a3.9139,3.9139,0,0,1,.3194,1.88v7.8619A3.066,3.066,0,0,1,685.6035,282.99Zm-1.8008-10.062a2.4841,2.4841,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2364,2.2364,0,0,0,.2392,1.3,1.344,1.344,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M691.8018,271.6874v3.54h4.041v2.1006h-4.041V283.63h-2.62V269.5067h6.9209v2.1807Z" />
                                    <path className="st6" d="M706.0615,271.6874v3.54h4.041v2.1006h-4.041V283.63H703.44V269.5067h6.9219v2.1807Z" />
                                    <path className="st6" d="M719.2217,282.7094a4.0819,4.0819,0,0,1-3.2608,1.2207,3.8285,3.8285,0,0,1-2.88-1.0206,2.8113,2.8113,0,0,1-.6006-.96,4.2625,4.2625,0,0,1-.26-1.5606V269.5067h2.6006v10.5025a2.8838,2.8838,0,0,0,.14,1.1,1.1485,1.1485,0,0,0,1.08.6,1.2653,1.2653,0,0,0,.7608-.22c.5-.34.4394-1,.4394-1.5V269.5067h2.6211v10.9424A3.3226,3.3226,0,0,1,719.2217,282.7094Z" />
                                    <path className="st6" d="M726.9609,271.5472V283.63h-2.62V271.5472H721.74v-2.04h7.8418v2.04Z" />
                                    <path className="st6" d="M738.4814,282.7094a4.0818,4.0818,0,0,1-3.2607,1.2207,3.8289,3.8289,0,0,1-2.88-1.0206,2.8129,2.8129,0,0,1-.6006-.96,4.2624,4.2624,0,0,1-.26-1.5606V269.5067h2.6006v10.5025a2.8838,2.8838,0,0,0,.14,1.1,1.1484,1.1484,0,0,0,1.08.6,1.265,1.265,0,0,0,.7607-.22c.5-.34.4395-1,.4395-1.5V269.5067h2.6211v10.9424A3.3221,3.3221,0,0,1,738.4814,282.7094Z" />
                                    <path className="st6" d="M747.541,283.63c-.04-.08-.08-.14-.12-.22a2.5694,2.5694,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.0195-.68-.08-.78-.66-.96-1.3408-.96h-1.24V283.63H741.96V269.5067h5.2011a2.6436,2.6436,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52v1.5205a3.5425,3.5425,0,0,1-.28,1.5205,1.6812,1.6812,0,0,1-.3.38,2.61,2.61,0,0,1-1.24.5,2.8418,2.8418,0,0,1,.7793.28,2.3392,2.3392,0,0,1,1.1407,2.18v2.8809a3.2167,3.2167,0,0,0,.36,1.66.8422.8422,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.2038,1.2038,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1945,1.1945,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st6" d="M752.42,283.63V269.5067h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.4414V283.63Z" />
                                    <path className="st6" d="M678.7217,305.63V291.5067h6.8418v2.1406h-4.2412v3.3208h4.0009v2.14h-4.0009v4.3408h4.44V305.63Z" />
                                    <path className="st6" d="M693.3828,305.63l-1.66-4.9009L690.042,305.63h-2.82l3.12-7.5215-2.78-6.6016h2.8408l1.34,3.981,1.36-3.981h2.84l-2.82,6.6817,3.1211,7.4414Z" />
                                    <path className="st6" d="M698.4,305.63V291.5067h2.581V305.63Z" />
                                    <path className="st6" d="M711.2021,303.7694c-.32,1.14-1.2607,2.1607-3.6611,2.1607a4.572,4.572,0,0,1-2.9-.8,2.4006,2.4006,0,0,1-.74-.96,3.1059,3.1059,0,0,1-.28-1.46v-2.0606h2.5811v1.64c0,.52-.04,1.12.46,1.44a1.4392,1.4392,0,0,0,.8.2,1.2672,1.2672,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.7993,1.7993,0,0,0-.88-1.86l-2.46-1.66a3.2773,3.2773,0,0,1-1.1807-1.1606,3.875,3.875,0,0,1-.5-2.24,4.744,4.744,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4h-2.6005v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6006,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3255,2.3255,0,0,0,.54.46l2.5205,1.7407a5.29,5.29,0,0,1,1.16,1.1,3.3623,3.3623,0,0,1,.56,2.44A7.9918,7.9918,0,0,1,711.2021,303.7694Z" />
                                    <path className="st6" d="M718.1611,293.5472V305.63h-2.62V293.5472H712.94v-2.04h7.8418v2.04Z" />
                                    <path className="st6" d="M722.7,305.63V291.5067h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.4414V305.63Z" />
                                    <path className="st6" d="M736.6416,305.63l-2.501-10.5821.22,10.5821H731.92V291.5067h3.3408l2.5411,10.3623-.2608-10.3623h2.3809V305.63Z" />
                                    <path className="st6" d="M750.2012,304.01c-.68,1.96-3.3,1.9205-3.6006,1.9205a6.8725,6.8725,0,0,1-1.34-.14,2.9469,2.9469,0,0,1-2.3808-1.6806,3.7844,3.7844,0,0,1-.2-1.48v-7.6817c0-.24.02-.5005.04-.74a2.6945,2.6945,0,0,1,1-2.1807,4.3026,4.3026,0,0,1,2.9209-.82,4.707,4.707,0,0,1,2.6406.64,2.4624,2.4624,0,0,1,.88,1.04,3.1092,3.1092,0,0,1,.24,1.6v2.1606h-2.5606v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1,0-1.2.62-1.2,1.5405v7.5616c0,.92.2,1.6,1.28,1.6,1.16,0,1.26-.66,1.26-1.7407v-1.84h2.5606v2A5.2234,5.2234,0,0,1,750.2012,304.01Z" />
                                    <path className="st6" d="M752.84,305.63V291.5067h6.8418v2.1406H755.44v3.3208h4.001v2.14H755.44v4.3408h4.4414V305.63Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M594.4863,424.348v3.541h4.041V429.99h-4.041V436.29h-2.62v-14.123h6.9219v2.1806Z" />
                                    <path className="st6" d="M607.5479,435.6508a4.3416,4.3416,0,0,1-3.0616.94,4.516,4.516,0,0,1-2.9394-.82,2.2978,2.2978,0,0,1-.7012-.8809,3.8289,3.8289,0,0,1-.2988-1.62V425.348a3.3979,3.3979,0,0,1,.8789-2.56,4.5874,4.5874,0,0,1,3.081-.92c2.04,0,3.1211.84,3.54,1.66a3.9,3.9,0,0,1,.32,1.88V433.27A3.0635,3.0635,0,0,1,607.5479,435.6508Zm-1.8-10.0625a2.4987,2.4987,0,0,0-.16-1.1407,1.1055,1.1055,0,0,0-1.0811-.62,1.4346,1.4346,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006V432.83a2.2385,2.2385,0,0,0,.2392,1.3007,1.3459,1.3459,0,0,0,1.0606.42,1.52,1.52,0,0,0,.82-.24c.4805-.34.4609-.9.4609-1.42Z" />
                                    <path className="st6" d="M618.1074,435.3705a4.0841,4.0841,0,0,1-3.2617,1.2207,3.83,3.83,0,0,1-2.8789-1.0205,2.8227,2.8227,0,0,1-.6016-.96,4.2847,4.2847,0,0,1-.26-1.56V422.1674h2.6015V432.67a2.8969,2.8969,0,0,0,.1387,1.1,1.1489,1.1489,0,0,0,1.08.6006,1.2643,1.2643,0,0,0,.7617-.2207c.5-.34.4395-1,.4395-1.5V422.1674h2.621V433.11A3.3223,3.3223,0,0,1,618.1074,435.3705Z" />
                                    <path className="st6" d="M626.3066,436.29l-2.5-10.582.2188,10.582h-2.4395v-14.123h3.34l2.541,10.3623-.26-10.3623h2.3809V436.29Z" />
                                    <path className="st6" d="M640.207,433.27a2.9663,2.9663,0,0,1-1.32,2.5811,4.1828,4.1828,0,0,1-2.24.4394h-4.1817v-14.123h4.7813a2.9637,2.9637,0,0,1,1.9414.7,3.5336,3.5336,0,0,1,1.0391,3.0205V432.49C640.2266,432.75,640.2266,433.01,640.207,433.27Zm-2.58-7.6016a1.387,1.387,0,0,0-.3614-1.2006,1.7411,1.7411,0,0,0-1.18-.24h-1.0195V434.23h.92a4.5832,4.5832,0,0,0,.74-.04c.84-.1592.9-.78.9-1.4795Z" />
                                    <path className="st6" d="M647.8457,436.29l-.34-2.3h-2.4l-.3789,2.3H642.125l2.74-14.123h2.9805l2.6406,14.123Zm-1.4785-10.5425-.9414,6.062h1.8008Z" />
                                    <path className="st6" d="M656.8066,424.2079V436.29h-2.6211V424.2079h-2.6v-2.0405h7.8418v2.0405Z" />
                                    <path className="st6" d="M661.3457,436.29v-14.123h2.58V436.29Z" />
                                    <path className="st6" d="M673.6875,435.6508a4.3388,4.3388,0,0,1-3.0605.94,4.5186,4.5186,0,0,1-2.9415-.82,2.2872,2.2872,0,0,1-.6992-.8809,3.8171,3.8171,0,0,1-.3008-1.62V425.348a3.3938,3.3938,0,0,1,.8809-2.56,4.5823,4.5823,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9246,3.9246,0,0,1,.32,1.88V433.27A3.0675,3.0675,0,0,1,673.6875,435.6508Zm-1.8008-10.0625a2.47,2.47,0,0,0-.16-1.1407,1.104,1.104,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006V432.83a2.2234,2.2234,0,0,0,.24,1.3007,1.34,1.34,0,0,0,1.0586.42,1.5182,1.5182,0,0,0,.82-.24c.48-.34.4609-.9.4609-1.42Z" />
                                    <path className="st6" d="M681.9863,436.29l-2.5-10.582.2188,10.582h-2.44v-14.123h3.34l2.541,10.3623-.26-10.3623h2.3809V436.29Z" />
                                    <path className="st6" d="M695.4863,434.43c-.32,1.1406-1.26,2.1611-3.66,2.1611a4.5716,4.5716,0,0,1-2.9-.8008,2.3833,2.3833,0,0,1-.74-.96,3.1064,3.1064,0,0,1-.2812-1.46V431.31h2.582v1.64c0,.5205-.041,1.12.459,1.44a1.4363,1.4363,0,0,0,.8008.2,1.2676,1.2676,0,0,0,.9-.26c.3789-.3.3789-.9209.3789-1.8808a1.8027,1.8027,0,0,0-.8789-1.86l-2.461-1.66a3.2654,3.2654,0,0,1-1.18-1.16,3.8676,3.8676,0,0,1-.5-2.2407,4.7258,4.7258,0,0,1,.34-1.94c.7988-1.72,3.2207-1.72,3.5-1.72.42,0,2.6816,0,3.4219,1.86a2.4254,2.4254,0,0,1,.2187,1.18v1.4h-2.6v-1.44c0-.42-.16-1.02-1.16-1.02-.6016,0-1.16.22-1.16,1.4a2.64,2.64,0,0,0,.2793,1.3609,2.3282,2.3282,0,0,0,.541.46l2.5195,1.74a5.2821,5.2821,0,0,1,1.16,1.1,3.3584,3.3584,0,0,1,.5606,2.4405A7.9325,7.9325,0,0,1,695.4863,434.43Z" />
                                    <path className="st6" d="M598.748,457.6508a4.3387,4.3387,0,0,1-3.0605.94,4.52,4.52,0,0,1-2.9414-.82,2.2872,2.2872,0,0,1-.6992-.8809,3.8156,3.8156,0,0,1-.3008-1.62V447.348a3.3981,3.3981,0,0,1,.8809-2.56,4.5822,4.5822,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.8809V455.27A3.0676,3.0676,0,0,1,598.748,457.6508Zm-1.8007-10.0625a2.4715,2.4715,0,0,0-.16-1.1407,1.1017,1.1017,0,0,0-1.08-.62,1.4379,1.4379,0,0,0-.84.2608c-.58.3994-.5,1.0195-.5,1.6005V454.83a2.239,2.239,0,0,0,.24,1.3007,1.3427,1.3427,0,0,0,1.0606.42,1.5175,1.5175,0,0,0,.8193-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st6" d="M604.9473,446.348v3.541h4.041V451.99h-4.041V458.29h-2.6211v-14.123h6.9218v2.1806Z" />
                                    <path className="st6" d="M623.707,451.7094c-.42.5195-1.0195.7-2.3008.7H619.207V458.29h-2.6211v-14.123h5.24a3.6651,3.6651,0,0,1,.7011.06,1.9741,1.9741,0,0,1,1.3809,1,5.1707,5.1707,0,0,1,.2988,2.12v1.86C624.207,450.5287,624.1465,451.1693,623.707,451.7094Zm-2.0605-4.0206a1.7074,1.7074,0,0,0-.1992-1.1406,1.1207,1.1207,0,0,0-.961-.3H619.207v4.1406h1.26c.5391,0,.84-.06,1-.28a1.8349,1.8349,0,0,0,.18-.98Z" />
                                    <path className="st6" d="M632.207,458.29c-.041-.08-.08-.14-.1211-.22a2.5707,2.5707,0,0,1-.26-1.1406v-3.2207c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V458.29H626.625v-14.123h5.2012a2.6435,2.6435,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.5205v1.52a3.5523,3.5523,0,0,1-.2793,1.5205,1.6934,1.6934,0,0,1-.3008.38,2.5982,2.5982,0,0,1-1.24.5,2.8318,2.8318,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1807v2.8808a3.2213,3.2213,0,0,0,.3613,1.66.8208.8208,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3008-.9394a1.2025,1.2025,0,0,0-.9-.2207h-1.3593v3.8613h1.3593a1.1929,1.1929,0,0,0,.9805-.3408c.2012-.2393.2207-.44.2207-1Z" />
                                    <path className="st6" d="M637.0859,458.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V456.11h4.4415V458.29Z" />
                                    <path className="st6" d="M653.6465,456.43c-.32,1.1406-1.26,2.1611-3.66,2.1611a4.5718,4.5718,0,0,1-2.9-.8008,2.3828,2.3828,0,0,1-.74-.96,3.1051,3.1051,0,0,1-.2812-1.46V453.31h2.582v1.64c0,.5205-.041,1.12.459,1.44a1.4359,1.4359,0,0,0,.8007.2,1.2672,1.2672,0,0,0,.9-.26c.3789-.3.3789-.9209.3789-1.8808a1.8027,1.8027,0,0,0-.8789-1.86l-2.4609-1.66a3.2665,3.2665,0,0,1-1.18-1.16,3.8668,3.8668,0,0,1-.5-2.24,4.7247,4.7247,0,0,1,.34-1.94c.7988-1.7207,3.2207-1.7207,3.5-1.7207.42,0,2.6816,0,3.4218,1.86a2.4263,2.4263,0,0,1,.2188,1.1807v1.4h-2.6v-1.4405c0-.4209-.16-1.02-1.16-1.02-.6015,0-1.16.22-1.16,1.4a2.6371,2.6371,0,0,0,.2793,1.36,2.3237,2.3237,0,0,0,.541.46l2.5195,1.74a5.2843,5.2843,0,0,1,1.16,1.1,3.3584,3.3584,0,0,1,.5605,2.4405A7.938,7.938,0,0,1,653.6465,456.43Z" />
                                    <path className="st6" d="M656.3457,458.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V456.11h4.4414V458.29Z" />
                                    <path className="st6" d="M670.2871,458.29l-2.5019-10.582.2207,10.582h-2.4414v-14.123h3.3417l2.5411,10.3623-.2618-10.3623h2.3809V458.29Z" />
                                    <path className="st6" d="M680.7051,446.2084V458.29h-2.6192v-12.082h-2.6015v-2.041h7.8418v2.041Z" />
                                    <path className="st6" d="M591.8662,480.29v-14.123h6.8418v2.1406h-4.2412v3.3213h4.001v2.14h-4.001V478.11h4.4414V480.29Z" />
                                    <path className="st6" d="M606.5273,480.29l-1.66-4.9-1.6807,4.9h-2.82l3.12-7.5215-2.78-6.6015h2.8408l1.34,3.9814,1.36-3.9814h2.8408l-2.82,6.6816,3.1211,7.4414Z" />
                                    <path className="st6" d="M611.5449,480.29v-14.123h2.5821V480.29Z" />
                                    <path className="st6" d="M624.3477,478.43c-.32,1.1406-1.2618,2.1611-3.6622,2.1611a4.5713,4.5713,0,0,1-2.9-.8008,2.4208,2.4208,0,0,1-.74-.96,3.1106,3.1106,0,0,1-.2793-1.46V475.31h2.58v1.64c0,.5205-.0391,1.12.4609,1.44a1.4337,1.4337,0,0,0,.7989.2,1.2676,1.2676,0,0,0,.9-.26c.3808-.3.3808-.9209.3808-1.8808a1.7993,1.7993,0,0,0-.8808-1.86l-2.459-1.66a3.2874,3.2874,0,0,1-1.1817-1.16,3.8828,3.8828,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.7207,3.2207-1.7207,3.5019-1.7207.42,0,2.68,0,3.42,1.86a2.4,2.4,0,0,1,.2207,1.1807v1.4h-2.6016v-1.4405c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6461,2.6461,0,0,0,.2812,1.36,2.3494,2.3494,0,0,0,.5391.46l2.5215,1.74a5.2823,5.2823,0,0,1,1.16,1.1,3.3664,3.3664,0,0,1,.5586,2.4405A8.05,8.05,0,0,1,624.3477,478.43Z" />
                                    <path className="st6" d="M631.3066,468.2084V480.29h-2.6211v-12.082h-2.6v-2.041h7.8418v2.041Z" />
                                    <path className="st6" d="M635.8457,480.29v-14.123h6.8418v2.1406h-4.2422v3.3213h4.002v2.14h-4.002V478.11h4.4414V480.29Z" />
                                    <path className="st6" d="M649.7871,480.29l-2.5019-10.582.2207,10.582h-2.4414v-14.123h3.3417l2.5411,10.3623-.2618-10.3623h2.3809V480.29Z" />
                                    <path className="st6" d="M663.3457,478.67c-.68,1.96-3.2988,1.9209-3.6,1.9209a6.8711,6.8711,0,0,1-1.34-.1406,2.9456,2.9456,0,0,1-2.3808-1.6807,3.7642,3.7642,0,0,1-.2012-1.48v-7.6816c0-.24.0215-.501.041-.74a2.6884,2.6884,0,0,1,1-2.1806,4.2968,4.2968,0,0,1,2.92-.82,4.7058,4.7058,0,0,1,2.6406.6406,2.4566,2.4566,0,0,1,.8808,1.04,3.1086,3.1086,0,0,1,.24,1.6v2.1612h-2.5606v-1.78c0-1.0811-.18-1.6611-1.34-1.6611-1,0-1.2012.62-1.2012,1.541V476.97c0,.92.2012,1.6006,1.2813,1.6006,1.16,0,1.26-.66,1.26-1.7412v-1.84h2.5606v2A5.1948,5.1948,0,0,1,663.3457,478.67Z" />
                                    <path className="st6" d="M665.9844,480.29v-14.123h6.8418v2.1406h-4.24v3.3213h4v2.14h-4V478.11h4.4414V480.29Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M146.8613,401.4955c-.8164,2.3525-3.96,2.3042-4.32,2.3042a8.2315,8.2315,0,0,1-1.6084-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.024-.6.0479-.8882a3.2312,3.2312,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,146.8613,401.4955Z" />
                                    <path className="st6" d="M156.4844,403.44c-.0479-.0962-.0957-.1679-.1441-.2641a3.0811,3.0811,0,0,1-.312-1.3682v-3.8643c0-.2641,0-.5278-.0239-.8159-.0957-.936-.792-1.1523-1.6079-1.1523h-1.4883V403.44h-3.12V386.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0165,2.0165,0,0,1-.36.4561,3.1373,3.1373,0,0,1-1.4883.6,3.4192,3.4192,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8639,3.8639,0,0,0,.4321,1.9922,1.0212,1.0212,0,0,0,.2881.2641Zm-.5039-13.1289c0-.6484-.0957-.8642-.36-1.1284a1.4438,1.4438,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4322,1.4322,0,0,0,1.1758-.4082c.24-.288.2642-.5278.2642-1.2Z" />
                                    <path className="st6" d="M162.1,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M173.5239,388.9427V403.44H170.38V388.9427h-3.1206v-2.4482h9.4092v2.4482Z" />
                                    <path className="st6" d="M178.7314,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M193.9243,401.4955c-.8164,2.3525-3.96,2.3042-4.3208,2.3042a8.23,8.23,0,0,1-1.6079-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.0239-.6.0479-.8882a3.23,3.23,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,193.9243,401.4955Z" />
                                    <path className="st6" d="M202.7554,403.44l-.4077-2.76h-2.88l-.4561,2.76h-3.12l3.2881-16.9453h3.5762l3.1684,16.9453ZM200.98,390.7909l-1.1284,7.2725h2.16Z" />
                                    <path className="st6" d="M208.1553,403.44V386.4945h3.12v14.377H215.86V403.44Z" />
                                    <path className="st6" d="M233.5713,399.8153a3.5586,3.5586,0,0,1-1.584,3.0967,5.0169,5.0169,0,0,1-2.6885.5278h-5.0161V386.4945h5.7363a3.5553,3.5553,0,0,1,2.3282.84c1.1523.9843,1.248,2.2324,1.248,3.6245v7.92C233.5952,399.1913,233.5952,399.5033,233.5713,399.8153Zm-3.0962-9.1206a1.6651,1.6651,0,0,0-.4321-1.44,2.087,2.087,0,0,0-1.416-.2881h-1.2242v12.001h1.104a5.5228,5.5228,0,0,0,.8882-.0479c1.0083-.1924,1.08-.936,1.08-1.7764Z" />
                                    <path className="st6" d="M236.5947,403.44V386.4945h8.2085v2.5679h-5.0884v3.9844h4.8v2.5683h-4.8v5.2085h5.3287V403.44Z" />
                                    <path className="st6" d="M256.2993,401.4955c-.8164,2.3525-3.96,2.3042-4.3208,2.3042a8.23,8.23,0,0,1-1.6079-.168,3.5355,3.5355,0,0,1-2.8564-2.0161,4.5482,4.5482,0,0,1-.24-1.7759v-9.2168c0-.2881.0239-.6.0479-.8882a3.23,3.23,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.651,5.651,0,0,1,3.1679.768,2.9565,2.9565,0,0,1,1.0562,1.2481,3.7405,3.7405,0,0,1,.2881,1.92v2.5923h-3.0723v-2.1363c0-1.2959-.2158-1.9922-1.6079-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.104.24,1.9205,1.5361,1.9205,1.3921,0,1.5122-.7925,1.5122-2.0884V397.08h3.0723v2.4A6.2867,6.2867,0,0,1,256.2993,401.4955Z" />
                                    <path className="st6" d="M259.2261,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M274.3467,401.2074c-.3838,1.3682-1.5122,2.5923-4.3921,2.5923a5.4864,5.4864,0,0,1-3.4805-.96,2.8731,2.8731,0,0,1-.8882-1.1518,3.7365,3.7365,0,0,1-.3359-1.7525v-2.4721h3.0962v1.9682c0,.624-.0479,1.3443.5522,1.728a1.7244,1.7244,0,0,0,.96.24,1.5233,1.5233,0,0,0,1.08-.312c.456-.36.456-1.104.456-2.2564a2.7789,2.7789,0,0,0-.3359-1.56,2.8221,2.8221,0,0,0-.72-.6724l-2.9522-1.9922a3.9253,3.9253,0,0,1-1.416-1.3921,4.644,4.644,0,0,1-.6-2.688,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8648-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.882,2.882,0,0,1,.2642,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.3921.2642-1.3921,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8152,2.8152,0,0,0,.648.5523l3.0244,2.0884a6.328,6.328,0,0,1,1.3921,1.32,4.0341,4.0341,0,0,1,.6718,2.9282A9.5691,9.5691,0,0,1,274.3467,401.2074Z" />
                                    <path className="st6" d="M277.3457,403.44V386.4945h3.0962V403.44Z" />
                                    <path className="st6" d="M291.9146,402.6718a5.2079,5.2079,0,0,1-3.6724,1.1279,5.4181,5.4181,0,0,1-3.5283-.9839,2.74,2.74,0,0,1-.84-1.0562,4.5843,4.5843,0,0,1-.36-1.9443v-9.5044a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4483,0,3.7442,1.0083,4.2481,1.9921a4.6892,4.6892,0,0,1,.3842,2.2564v9.4326A3.6786,3.6786,0,0,1,291.9146,402.6718Zm-2.1607-12.0733a2.9679,2.9679,0,0,0-.1919-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.725,1.725,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.6825,2.6825,0,0,0,.2881,1.56,1.6127,1.6127,0,0,0,1.272.5039,1.8318,1.8318,0,0,0,.9843-.2876c.5762-.4082.5518-1.08.5518-1.7045Z" />
                                    <path className="st6" d="M301.6338,403.44l-3-12.6972.2642,12.6972h-2.9283V386.4945h4.0083l3.0484,12.4331-.312-12.4331H305.57V403.44Z" />
                                    <path className="st6" d="M317.8574,400.8h5.4483v2.64h-8.8565v-1.9682l5.4-12.3609H315.145v-2.6162h8.2569v1.68Z" />
                                    <path className="st6" d="M334.1777,402.6718a5.2075,5.2075,0,0,1-3.6723,1.1279,5.4181,5.4181,0,0,1-3.5283-.9839,2.74,2.74,0,0,1-.84-1.0562,4.5843,4.5843,0,0,1-.36-1.9443v-9.5044a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4482,0,3.7441,1.0083,4.248,1.9921a4.6878,4.6878,0,0,1,.3843,2.2564v9.4326A3.6783,3.6783,0,0,1,334.1777,402.6718Zm-2.16-12.0733a2.97,2.97,0,0,0-.1924-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.725,1.725,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.6825,2.6825,0,0,0,.2881,1.56,1.6128,1.6128,0,0,0,1.2724.5039,1.8316,1.8316,0,0,0,.9839-.2876c.5762-.4082.5523-1.08.5523-1.7045Z" />
                                    <path className="st6" d="M343.897,403.44l-3-12.6972.2641,12.6972h-2.9282V386.4945h4.0083l3.0484,12.4331-.312-12.4331h2.8559V403.44Z" />
                                    <path className="st6" d="M351.0483,403.44V386.4945h8.2085v2.5679h-5.0883v3.9844h4.8v2.5683h-4.8v5.2085h5.3286V403.44Z" />
                                    <path className="st6" d="M177.2686,435.2074c-.3838,1.3682-1.5123,2.5918-4.3921,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8682,2.8682,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.5209,1.5209,0,0,0,1.08-.3125c.456-.36.456-1.1035.456-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8193,2.8193,0,0,0-.72-.6719l-2.9522-1.9922a3.9259,3.9259,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8648-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.882,2.882,0,0,1,.2642,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5729,9.5729,0,0,1,177.2686,435.2074Z" />
                                    <path className="st6" d="M186.2441,437.44v-7.7529h-2.8564V437.44h-3.12V420.4945h3.12v6.6484h2.8564v-6.6484h3.12V437.44Z" />
                                    <path className="st6" d="M192.58,437.44V420.4945h3.0962V437.44Z" />
                                    <path className="st6" d="M202.0352,423.1107v4.2481h4.8486v2.5205h-4.8486v7.56h-3.1441V420.4945h8.3047v2.6162Z" />
                                    <path className="st6" d="M215.0913,422.9427V437.44h-3.144V422.9427h-3.1206v-2.4482h9.4091v2.4482Z" />
                                    <path className="st6" d="M234.8677,436.6713a5.2054,5.2054,0,0,1-3.6724,1.1279,5.4208,5.4208,0,0,1-3.5283-.9834,2.7411,2.7411,0,0,1-.84-1.0566,4.5837,4.5837,0,0,1-.36-1.9444v-9.5039a4.0744,4.0744,0,0,1,1.0561-3.0723,5.4988,5.4988,0,0,1,3.6963-1.1044c2.4483,0,3.7442,1.0083,4.2481,1.9921a4.689,4.689,0,0,1,.3843,2.2564v9.4321A3.6786,3.6786,0,0,1,234.8677,436.6713Zm-2.1607-12.0728a2.9679,2.9679,0,0,0-.1919-1.3677,1.3218,1.3218,0,0,0-1.2959-.7441,1.7252,1.7252,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.68,2.68,0,0,0,.2881,1.5595,1.6125,1.6125,0,0,0,1.272.504,1.8309,1.8309,0,0,0,.9844-.2872c.5761-.4082.5517-1.08.5517-1.705Z" />
                                    <path className="st6" d="M242.0664,423.1107v4.2481h4.8486v2.5205h-4.8486v7.56h-3.144V420.4945h8.3047v2.6162Z" />
                                    <path className="st6" d="M262.2505,437.44c-.0479-.0967-.0957-.1679-.1441-.2646a3.0791,3.0791,0,0,1-.312-1.3682v-3.8643c0-.2636,0-.5273-.0239-.8154-.0957-.9365-.792-1.1523-1.6079-1.1523h-1.4883V437.44h-3.12V420.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0277,2.0277,0,0,1-.36.4561,3.1378,3.1378,0,0,1-1.4883.6,3.4357,3.4357,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8623,3.8623,0,0,0,.4321,1.9922,1.03,1.03,0,0,0,.2881.2646Zm-.5039-13.1289c0-.6484-.0962-.8642-.36-1.1284a1.4437,1.4437,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4337,1.4337,0,0,0,1.1758-.4082c.24-.288.2642-.5283.2642-1.2Z" />
                                    <path className="st6" d="M267.8657,437.44V420.4945h8.2085v2.5679h-5.0884v3.9839h4.8v2.5683h-4.8v5.209h5.3287V437.44Z" />
                                    <path className="st6" d="M287.498,435.2074c-.3837,1.3682-1.5122,2.5918-4.392,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8672,2.8672,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.52,1.52,0,0,0,1.08-.3125c.4561-.36.4561-1.1035.4561-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8176,2.8176,0,0,0-.72-.6719l-2.9521-1.9922a3.9251,3.9251,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8647-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.8834,2.8834,0,0,1,.2641,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5673,9.5673,0,0,1,287.498,435.2074Z" />
                                    <path className="st6" d="M298.7544,436.6713a5.2054,5.2054,0,0,1-3.6724,1.1279,5.4208,5.4208,0,0,1-3.5283-.9834,2.7411,2.7411,0,0,1-.84-1.0566,4.5837,4.5837,0,0,1-.36-1.9444v-9.5039a4.0742,4.0742,0,0,1,1.0562-3.0723,5.4984,5.4984,0,0,1,3.6963-1.1044c2.4482,0,3.7441,1.0083,4.248,1.9921a4.6878,4.6878,0,0,1,.3843,2.2564v9.4321A3.6786,3.6786,0,0,1,298.7544,436.6713Zm-2.1606-12.0728a2.9693,2.9693,0,0,0-.1919-1.3677,1.322,1.322,0,0,0-1.2959-.7441,1.7249,1.7249,0,0,0-1.0083.312c-.6958.48-.6,1.2241-.6,1.92v8.5689a2.68,2.68,0,0,0,.288,1.5595,1.6126,1.6126,0,0,0,1.272.504,1.8307,1.8307,0,0,0,.9844-.2872c.5762-.4082.5518-1.08.5518-1.705Z" />
                                    <path className="st6" d="M311.1855,436.3353c-1.2,1.5606-3.5039,1.4639-3.9121,1.4639a4.5974,4.5974,0,0,1-3.4565-1.2236,3.391,3.391,0,0,1-.72-1.1524,5.1054,5.1054,0,0,1-.312-1.8721V420.4945h3.12v12.6006a3.4528,3.4528,0,0,0,.1679,1.32,1.3772,1.3772,0,0,0,1.2959.72,1.5177,1.5177,0,0,0,.9121-.2636c.6-.4082.5284-1.2.5284-1.8V420.4945h3.144v13.1289A3.9879,3.9879,0,0,1,311.1855,436.3353Z" />
                                    <path className="st6" d="M321.8174,437.44c-.0479-.0967-.0957-.1679-.1441-.2646a3.0791,3.0791,0,0,1-.312-1.3682v-3.8643c0-.2636,0-.5273-.0239-.8154-.0957-.9365-.792-1.1523-1.6079-1.1523h-1.4883V437.44h-3.12V420.4945h6.24a3.1694,3.1694,0,0,1,2.0645.5518c1.0078.7441.9839,1.92.9839,3.0244v1.8242a4.2468,4.2468,0,0,1-.336,1.8242,2.0277,2.0277,0,0,1-.36.4561,3.1378,3.1378,0,0,1-1.4883.6,3.4357,3.4357,0,0,1,.936.3359,2.808,2.808,0,0,1,1.3682,2.6162v3.4561a3.8623,3.8623,0,0,0,.4321,1.9922,1.03,1.03,0,0,0,.2881.2646Zm-.5039-13.1289c0-.6484-.0957-.8642-.36-1.1284a1.4438,1.4438,0,0,0-1.08-.2642h-1.6323v4.6328h1.6323a1.4338,1.4338,0,0,0,1.1758-.4082c.24-.288.2642-.5283.2642-1.2Z" />
                                    <path className="st6" d="M336.3135,435.4955c-.8164,2.3525-3.96,2.3037-4.32,2.3037a8.19,8.19,0,0,1-1.6084-.168,3.5349,3.5349,0,0,1-2.8565-2.0156,4.5522,4.5522,0,0,1-.24-1.7764v-9.2163c0-.2881.0239-.6.0478-.8882a3.2318,3.2318,0,0,1,1.2-2.6162,5.1579,5.1579,0,0,1,3.5044-.9843,5.6513,5.6513,0,0,1,3.168.768,2.9569,2.9569,0,0,1,1.0561,1.2481,3.74,3.74,0,0,1,.2881,1.92v2.5918H333.481v-2.1358c0-1.2959-.2159-1.9922-1.608-1.9922-1.2,0-1.44.7442-1.44,1.8482v9.0727c0,1.1036.24,1.92,1.5362,1.92,1.392,0,1.5122-.792,1.5122-2.0879V431.08h3.0722v2.3994A6.288,6.288,0,0,1,336.3135,435.4955Z" />
                                    <path className="st6" d="M339.24,437.44V420.4945h8.2085v2.5679H342.36v3.9839h4.8v2.5683h-4.8v5.209h5.3286V437.44Z" />
                                    <path className="st6" d="M358.873,435.2074c-.3837,1.3682-1.5122,2.5918-4.392,2.5918a5.4864,5.4864,0,0,1-3.4805-.96,2.8672,2.8672,0,0,1-.8882-1.1513,3.7389,3.7389,0,0,1-.3359-1.753v-2.4716h3.0962v1.9677c0,.6241-.0479,1.3448.5522,1.7285a1.7244,1.7244,0,0,0,.96.24,1.52,1.52,0,0,0,1.08-.3125c.4561-.36.4561-1.1035.4561-2.2559a2.78,2.78,0,0,0-.3359-1.56,2.8176,2.8176,0,0,0-.72-.6719l-2.9521-1.9922a3.9251,3.9251,0,0,1-1.416-1.3926,4.6413,4.6413,0,0,1-.6-2.6875,5.7035,5.7035,0,0,1,.4077-2.3281c.96-2.0644,3.8647-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.8834,2.8834,0,0,1,.2641,1.416v1.68h-3.12v-1.7281c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.392.2642-1.392,1.68a3.1665,3.1665,0,0,0,.3359,1.6318,2.8163,2.8163,0,0,0,.6479.5523l3.0245,2.0884a6.3222,6.3222,0,0,1,1.392,1.3193,4.0349,4.0349,0,0,1,.6719,2.9287A9.5673,9.5673,0,0,1,358.873,435.2074Z" />
                                </g>
                                <g>
                                    <path className="st6" d="M742.3232,784.306V801.77h-1.8086V784.306h-5.9921v-1.5927h13.82v1.5927Z" />
                                    <path className="st6" d="M751.8828,801.77V782.7133h1.8086V801.77Z" />
                                    <path className="st6" d="M774.2334,801.77l.1348-16.1953L768.16,801.77h-1.2413l-6.3437-16.1953.2158,16.1953H759.01V782.7133h2.1318l6.4522,16.1689,6.1806-16.1689h2.24V801.77Z" />
                                    <path className="st6" d="M781.3389,801.77V782.7133h12.4433V784.36H783.1475v6.8018h9.6367v1.6738h-9.6367v7.2617H794.457V801.77Z" />
                                </g>

                            </>
                        }
                        {SelectedLanguage === "de-DE" && <>
                            <g>
                                <path className="st6" d="M460.94,823.62v1.26l-6.02,11.64h5.86v1.26h-7.32v-1.26l6.04-11.66h-5.18v-1.24H460.94z" />
                                <path className="st6" d="M465.28,823.62v10.6l0.08,0.94l0.44,0.9l0.68,0.48l1,0.18l0.86-0.12l0.56-0.36l0.5-0.68l0.2-1.2l0.08-1.84
			V830l0.04-6.38h1.36l-0.04,9.18l-0.04,1.26l-0.02,0.84l-0.34,1.24l-0.6,0.92l-0.88,0.54l-1.7,0.34l-1.42-0.18l-0.88-0.48
			l-0.74-0.8l-0.48-1.26l-0.04-1.9l0.02-2.92l0.04-6.78H465.28z"/>
                                <path className="st6" d="M476.04,823.66v6.5l4.22-6.5l1.48-0.02l-4.22,6.62l2.34,3.44l1.44,2.2l1.22,1.88h-1.64l-4.86-7.32v7.32h-1.34
			v-14.14L476.04,823.66z"/>
                                <path className="st6" d="M486.02,823.62v10.6l0.08,0.94l0.44,0.9l0.68,0.48l1,0.18l0.86-0.12l0.56-0.36l0.5-0.68l0.2-1.2l0.08-1.84
			V830l0.04-6.38h1.36l-0.04,9.18l-0.04,1.26l-0.02,0.84l-0.34,1.24l-0.6,0.92l-0.88,0.54l-1.7,0.34l-1.42-0.18l-0.88-0.48
			l-0.74-0.8l-0.48-1.26l-0.04-1.9l0.02-2.92l0.04-6.78H486.02z"/>
                                <path className="st6" d="M497.1,823.68l1.46,3.56l1.78,4.78l0.76,1.78l0.8,2.7l-0.08-1.62l-0.04-2.26v-1.18l-0.06-7.8h1.32v14.14h-1.8
			l-1.34-3.54l-1.88-4.98l-0.84-2.26l-0.62-2.12l0.1,2.62v3.62l0.06,6.66h-1.32v-14.12L497.1,823.68z"/>
                                <path className="st6" d="M512.94,823.62v1.26h-4.74v4.94h4.32v1.22h-4.32v6.74h-1.38v-14.16H512.94z" />
                                <path className="st6" d="M521.54,823.66v1.24h-2.7v12.88h-1.38V824.9h-2.76v-1.24H521.54z" />
                            </g>
                            <g>
                                <path className="st6" d="M273.33,823.58l1.18,5.64l0.72,3.34l0.4,1.7l0.38,2.16l0.34-1.7l0.22-1l0.44-1.96l1-4.52l0.8-3.62h1.42
			l-3.36,14.16l-1.66-0.02l-3.3-14.14L273.33,823.58z"/>
                                <path className="st6" d="M289.05,823.64v1.22h-4.86v4.98h4.58v1.2h-4.58v5.46h4.8v1.26h-6.18v-14.12H289.05z" />
                                <path className="st6" d="M293.61,823.62l1.62,0.06l1.52,0.24l0.96,0.58l0.4,0.56l0.26,0.68l0.18,1.9l-0.16,1.52l-0.28,0.72l-0.38,0.42
			l-0.52,0.38l-0.64,0.18l0.64,0.22l0.72,0.68l0.32,0.9l0.24,2.66l0.46,2.46l-1.48-0.02l-0.28-1.86l-0.16-2.64l-0.26-0.84
			l-0.38-0.54l-0.7-0.3l-1-0.12h-1.22v6.32h-1.36v-14.14L293.61,823.62z M293.45,830.24h1.42l0.66-0.06l0.5-0.12l0.76-0.62
			l0.26-0.78l0.14-1.18l-0.04-0.98l-0.32-0.76l-0.42-0.46l-1.26-0.44l-1.7-0.04V830.24z"/>
                                <path className="st6" d="M307.87,827.78l-0.1-1.22l-0.2-0.7l-0.3-0.58l-0.66-0.42l-0.94-0.16l-1.1,0.24l-0.46,0.46l-0.4,0.7l-0.2,3.1
			v3.38l0.04,1.84l0.1,0.84l0.36,0.78l0.68,0.5l1.02,0.2l0.66-0.1l0.56-0.24l0.48-0.44l0.4-1.4l0.1-1.44l-0.02-1.62l-2.3-0.02
			l-0.02-1.2l3.64-0.02l-0.02,7.54l-1.06-0.02l-0.02-1.2l-0.58,0.7l-0.84,0.48l-1.16,0.16l-1.56-0.22l-0.92-0.64l-0.4-0.54
			l-0.44-1.62l-0.08-2.46l0.06-4.3l0.06-1.76l0.34-1.16l0.64-0.94l1.34-0.68l1.18-0.14l0.98,0.1l0.78,0.22l1.06,0.86l0.42,0.78
			l0.2,0.8l0.08,1.54L307.87,827.78z"/>
                                <path className="st6" d="M320.65,837.78h-1.38l-1.12-4.52h-3.76l-1.12,4.52h-1.38l3.66-14.14h1.44L320.65,837.78z M317.87,832.1
			l-1.54-6.58l-0.58,2.46l-0.52,2.04l-0.52,2.06L317.87,832.1z"/>
                                <path className="st6" d="M324.75,823.68l1.46,3.56l1.78,4.78l0.76,1.78l0.8,2.7l-0.08-1.62l-0.04-2.26v-1.18l-0.06-7.8h1.32v14.14
			h-1.8l-1.34-3.54l-1.88-4.98l-0.84-2.26l-0.62-2.12l0.1,2.62v3.62l0.06,6.66h-1.32v-14.12L324.75,823.68z"/>
                                <path className="st6" d="M340.03,827.78l-0.1-1.22l-0.2-0.7l-0.3-0.58l-0.66-0.42l-0.94-0.16l-1.1,0.24l-0.46,0.46l-0.4,0.7l-0.2,3.1
			v3.38l0.04,1.84l0.1,0.84l0.36,0.78l0.68,0.5l1.02,0.2l0.66-0.1l0.56-0.24l0.48-0.44l0.4-1.4l0.1-1.44l-0.02-1.62l-2.3-0.02
			l-0.02-1.2l3.64-0.02l-0.02,7.54l-1.06-0.02l-0.02-1.2l-0.58,0.7l-0.84,0.48l-1.16,0.16l-1.56-0.22l-0.92-0.64l-0.4-0.54
			l-0.44-1.62l-0.08-2.46l0.06-4.3l0.06-1.76l0.34-1.16l0.64-0.94l1.34-0.68l1.18-0.14l0.98,0.1l0.78,0.22l1.06,0.86l0.42,0.78
			l0.2,0.8l0.08,1.54L340.03,827.78z"/>
                                <path className="st6" d="M351.29,823.64v1.22h-4.86v4.98h4.58v1.2h-4.58v5.46h4.8v1.26h-6.18v-14.12H351.29z" />
                                <path className="st6" d="M356.09,823.68l1.46,3.56l1.78,4.78l0.76,1.78l0.8,2.7l-0.08-1.62l-0.04-2.26v-1.18l-0.06-7.8h1.32v14.14
			h-1.8l-1.34-3.54l-1.88-4.98l-0.84-2.26l-0.62-2.12l0.1,2.62v3.62l0.06,6.66h-1.32v-14.12L356.09,823.68z"/>
                                <path className="st6" d="M367.21,823.64v6.2h4.44v-6.2h1.36v14.14h-1.36v-6.76h-4.44v6.76h-1.38v-14.14H367.21z" />
                                <path className="st6" d="M383.05,823.64v1.22h-4.86v4.98h4.58v1.2h-4.58v5.46h4.8v1.26h-6.18v-14.12H383.05z" />
                                <path className="st6" d="M387.51,823.64v14.12h-1.38v-14.12H387.51z" />
                                <path className="st6" d="M396.69,823.66v1.24h-2.7v12.88h-1.38V824.9h-2.76v-1.24H396.69z" />
                            </g>
                            <g>
                                <path className="st6" d="M403.94,785.02v-6.73h-2.79v6.73h-3.17v-15.53h3.17v6.25h2.79v-6.25h3.17v15.53H403.94z" />
                                <path className="st6" d="M411.46,785.02v-15.53h7.33v2.82h-4.16v3.34h3.72v2.75h-3.72v3.78h4.16v2.84H411.46z" />
                                <path className="st6" d="M431.9,779.43l-0.11,2.18l-0.2,1.01l-0.42,0.9l-0.55,0.7l-0.84,0.51l-1.12,0.42l-1.45,0.09l-1.76-0.15
			l-1.19-0.53l-0.95-0.99l-0.48-0.86l-0.2-0.88l-0.07-1.87v-10.47h3.12v9.31l0.02,2.02l0.18,1.23l0.35,0.44l0.37,0.22l0.64,0.11
			l0.55-0.11l0.68-0.53l0.18-0.79l0.09-1.23v-1.41l-0.02-9.26h3.17V779.43z"/>
                                <path className="st6" d="M436.61,785.02v-12.87h-2.55v-2.66h8.25v2.66h-2.55v12.87H436.61z" />
                                <path className="st6" d="M444.57,785.02v-15.53h7.33v2.82h-4.16v3.34h3.72v2.75h-3.72v3.78h4.16v2.84H444.57z" />
                            </g>
                            <g>
                                <path className="st6" d="M738.48,783.27h13.36v1.14l-11.45,15.76h11.34v1.61h-13.87v-1.14l11.45-15.76h-10.84V783.27z" />
                                <path className="st6" d="M755.54,783.27h12.38v1.61h-10.57v6.55h9.54v1.61h-9.54v7.12h11.13v1.61h-12.94V783.27z" />
                                <path className="st6" d="M772.95,783.27h1.81v18.51h-1.81V783.27z" />
                                <path className="st6" d="M778.42,783.27h13.66v1.61h-5.92v16.9h-1.81v-16.9h-5.92V783.27z" />
                            </g>
                            <g>
                                <path className="st6" d="M45.9,250.65v-12.38h1.61v10.57h6.55v-9.54h1.61v9.54h7.12v-11.13h1.61v12.94H45.9z" />
                                <path className="st6" d="M45.9,233.16v-1.78l15.55-10.81v-0.05H45.9v-1.81h18.51v1.78L48.87,231.3v0.05h15.55v1.81H45.9z" />
                                <path className="st6" d="M45.9,214.96v-13.65h1.61v5.92h16.9v1.81h-16.9v5.92H45.9z" />
                                <path className="st6" d="M45.9,199.6v-1.89l15.39-4.38v-0.05l-15.39-4.54v-1.33l15.29-4.68v-0.05l-15.29-4.17v-1.86l18.51,5.39v1.46
			l-14.92,4.6v0.05l14.92,4.38v1.41L45.9,199.6z"/>
                                <path className="st6" d="M45.9,173.19v-1.81h18.51v1.81H45.9z" />
                                <path className="st6" d="M51.11,153.18c-2.57,0.8-4.06,2.12-4.06,5.53c0,4.22,3.25,6.32,8.11,6.32c5.9,0,8.11-2.79,8.11-6.61
			c0-2.98-1.51-4.46-3.93-5.31l0.91-1.73c3.33,1.33,4.63,3.88,4.63,7.39c0,4.49-2.76,8.08-9.72,8.08c-7.02,0-9.72-3.83-9.72-7.94
			c0-3.75,1.3-6.03,4.78-7.52L51.11,153.18z"/>
                                <path className="st6" d="M45.9,147.73v-1.81h10.3l-10.3-10.28v-2.52l7.28,7.39l11.23-8.02v2.21l-9.96,7.12l4.08,4.12h5.88v1.81H45.9z"
                                />
                                <path className="st6" d="M45.9,129.26v-1.81h16.9v-10.44h1.61v12.25H45.9z" />
                                <path className="st6" d="M58.41,99.48c4.24,0,6.47,2.76,6.47,6.96s-2.24,6.96-6.47,6.96H45.9v-1.81h12.45c3.61,0,4.92-2.31,4.92-5.15
			c0-2.84-1.3-5.15-4.92-5.15H45.9v-1.81H58.41z"/>
                                <path className="st6" d="M45.9,94.13v-1.78l15.55-10.81v-0.05H45.9v-1.81h18.51v1.78L48.87,92.28v0.05h15.55v1.81H45.9z" />
                                <path className="st6" d="M51,61.11c-2.76,0.93-3.95,2.55-3.95,5.29c0,4.81,4.26,6.85,8.11,6.85c3.85,0,8.11-1.89,8.11-6.38
			c0-4.14-3.22-5.71-5.96-5.71h-1.01v7.01h-1.61v-8.61h9.72v1.59h-2.68v0.05c2.26,0.9,3.15,3.32,3.15,5.66
			c0,5.53-4.37,8.18-9.72,8.18c-5.02,0-9.72-2.68-9.72-8.53c0-3.59,1.53-5.76,4.65-7.15L51,61.11z"/>
                            </g>
                            <g className="st7">
                                <path className="st6" d="M79.98,250.31v-1.08l6.41-2.19V247l-6.41-2.09v-0.85l6.41-2.09v-0.03l-6.41-2.19v-1.08l8.16,2.85v0.87
			l-6.58,2.09v0.03l6.58,2.07v0.87L79.98,250.31z"/>
                                <path className="st6" d="M76.62,236.82v-1.04h1.6v1.04H76.62z M79.98,236.82v-1.04h8.16v1.04H79.98z" />
                                <path className="st6" d="M82.01,227.16c-0.9,0.42-1.27,1.3-1.27,2.29c0,1.43,1.09,2.83,3.33,2.83c2.24,0,3.33-1.41,3.33-2.73
			c0-1.1-0.58-1.93-1.33-2.29l0.26-1.06c1.34,0.61,2.02,1.82,2.02,3.35c0,2.09-1.72,3.74-4.28,3.77c-2.57-0.03-4.28-1.62-4.28-4.01
			c0-1.04,0.41-2.47,2.02-3.22L82.01,227.16z"/>
                                <path className="st6" d="M76.04,223.98v-1.04h5.15v-0.03c-1.04-0.52-1.41-1.51-1.41-2.49c0-2.05,1.16-2.94,2.84-2.94h5.52v1.04h-5.22
			c-1.65,0-2.19,0.71-2.19,2.03c0,1.25,0.82,2.38,2.65,2.38h4.76v1.04H76.04z"/>
                                <path className="st6" d="M79.98,215.47v-1.7H76.5v-1.04h3.48v-1.81h0.85v1.81h5.39c0.61,0,1.17-0.02,1.17-0.61
			c0-0.49-0.19-0.8-0.41-1.15h1.02c0.22,0.5,0.34,0.96,0.34,1.37c0,0.75-0.37,1.43-1.45,1.43h-6.07v1.7H79.98z"/>
                                <path className="st6" d="M76.62,208.9v-1.04h1.6v1.04H76.62z M79.98,208.9v-1.04h8.16v1.04H79.98z" />
                                <path className="st6" d="M89.21,204.31c1.58-0.17,2.19-1.18,2.19-2.59c0-1.46-0.36-2.62-2.82-2.62H86.9l-0.03,0.02
			c1.04,0.63,1.48,1.62,1.48,2.75c0,1.48-1.16,3.55-4.18,3.55c-2.86,0-4.39-1.58-4.39-3.49c0-1.3,0.54-2.33,1.55-2.78v-0.03h-1.34
			v-1.04h8.52c1.68,0,3.86,0.28,3.86,3.67c0,2.14-0.77,3.42-2.92,3.65L89.21,204.31z M80.73,201.78c0,1.56,1.27,2.59,3.33,2.59
			c2.06,0,3.33-1.03,3.33-2.59c0-2.02-1.38-2.64-3.18-2.64C82.47,199.14,80.73,199.59,80.73,201.78z"/>
                                <path className="st6" d="M84.03,194.43h0.22c1.82,0,3.14-0.82,3.14-2.68c0-1.44-0.65-2.21-1.73-2.71l0.25-0.97
			c1.51,0.63,2.43,1.93,2.43,3.77c0,2.09-1.5,3.77-4.28,3.77c-2.79,0-4.28-1.69-4.28-3.77c0-2.47,1.84-3.68,4.25-3.77V194.43z
			 M83.18,189.25c-1.19,0.23-2.45,0.85-2.45,2.59c0,1.48,1.09,2.42,2.45,2.59V189.25z"/>
                                <path className="st6" d="M79.98,185.46v-1.04h1.14v-0.04c-0.85-0.57-1.34-1.18-1.34-2.26c0-0.28,0.02-0.49,0.03-0.75h0.88
			c-0.05,0.24-0.07,0.47-0.07,0.69c0,1.62,1.16,2.35,2.82,2.35h4.69v1.04H79.98z"/>
                                <path className="st6" d="M84.51,173.3c2.04-0.1,2.89-1.43,2.89-3.48c0-1.95-0.99-3.39-2.46-3.39c-3.71,0-1.39,7.39-6.12,7.39
			c-2.21,0-3.08-2.21-3.08-3.95c0-2.1,0.68-3.7,2.7-4.41l0.58,1.1c-1.58,0.5-2.23,1.77-2.23,3.32c0,1.27,0.7,2.76,2.02,2.76
			c3.59,0,1.27-7.39,5.93-7.39c2.38,0,3.71,1.7,3.71,4.57c0,2.49-0.9,4.21-3.35,4.66L84.51,173.3z"/>
                                <path className="st6" d="M79.98,163.61v-0.97l6.87-2.42v-0.03l-6.87-2.75v-0.99l10.35,4.12c0.94,0.37,2.02,0.75,2.02,2.03
			c0,0.4-0.15,0.76-0.31,1.11H91c0.17-0.31,0.41-0.7,0.41-1.08c0-0.63-0.71-0.89-1.1-1.04l-2.14-0.85L79.98,163.61z"/>
                                <path className="st6" d="M85.88,154.15c1.17-0.45,1.62-1.11,1.62-2.42c0-0.85-0.32-2.03-1.67-2.03c-2.43,0-0.56,4.94-3.81,4.94
			c-1.17,0-2.24-0.99-2.24-2.85c0-1.63,0.48-2.75,1.85-3.15l0.24,0.99c-1.02,0.36-1.24,1.04-1.24,2c0,1.56,0.61,1.96,1.43,1.96
			c2.11,0,0.53-4.94,3.81-4.94c1.34,0,2.48,1.15,2.48,3.23c0,1.25-0.34,2.47-2.16,3.27L85.88,154.15z"/>
                                <path className="st6" d="M79.98,146.96v-1.7H76.5v-1.04h3.48v-1.81h0.85v1.81h5.39c0.61,0,1.17-0.02,1.17-0.61
			c0-0.49-0.19-0.8-0.41-1.15h1.02c0.22,0.5,0.34,0.96,0.34,1.37c0,0.75-0.37,1.43-1.45,1.43h-6.07v1.7H79.98z"/>
                                <path className="st6" d="M84.03,139.73h0.22c1.82,0,3.14-0.82,3.14-2.68c0-1.44-0.65-2.21-1.73-2.71l0.25-0.97
			c1.51,0.63,2.43,1.93,2.43,3.77c0,2.08-1.5,3.77-4.28,3.77c-2.79,0-4.28-1.69-4.28-3.77c0-2.47,1.84-3.68,4.25-3.77V139.73z
			 M83.18,134.55c-1.19,0.23-2.45,0.85-2.45,2.59c0,1.48,1.09,2.42,2.45,2.59V134.55z"/>
                                <path className="st6" d="M79.98,130.84v-1.04h1.31v-0.04c-0.87-0.36-1.51-1.15-1.51-2.31c0-1.23,0.58-2.22,1.79-2.4v-0.03
			c-1.11-0.43-1.79-1.23-1.79-2.61c0-2.42,1.99-2.42,2.97-2.42h5.39v1.04h-5.27c-0.92,0-2.14,0.04-2.14,1.6
			c0,1.46,1.29,2.26,2.87,2.26h4.54v1.04h-5.13c-1.05,0-2.28,0.1-2.28,1.6c0,1.29,1.12,2.26,2.92,2.26h4.49v1.04H79.98z"/>
                                <path className="st6" d="M88.24,109.67c0,1.29,0,2.07-1.43,2.17v0.04c1.11,0.64,1.53,1.65,1.53,2.92c0,1.36-0.66,2.66-2.16,2.66
			c-1.73,0-2.24-1.55-2.6-2.99l-0.65-2.55h-0.82c-1.05,0-1.5,0.76-1.5,2.08c0,1.39,0.66,2.03,1.97,2.07l-0.2,1.18
			c-1.75-0.35-2.62-1.42-2.62-3.28c0-3.09,1.75-3.09,2.55-3.09h4c1.05,0,1.07-0.49,1.07-1.2H88.24z M87.39,114.71
			c0-1.01-0.66-2.8-2.63-2.8h-0.93l0.82,3.01c0.22,0.83,0.61,1.5,1.53,1.5C87.07,116.42,87.39,115.5,87.39,114.71z"/>
                                <path className="st6" d="M85.88,107.45c1.17-0.45,1.62-1.11,1.62-2.42c0-0.85-0.32-2.03-1.67-2.03c-2.43,0-0.56,4.94-3.81,4.94
			c-1.17,0-2.24-0.99-2.24-2.85c0-1.63,0.48-2.75,1.85-3.15l0.24,0.99c-1.02,0.36-1.24,1.04-1.24,2c0,1.56,0.61,1.96,1.43,1.96
			c2.11,0,0.53-4.94,3.81-4.94c1.34,0,2.48,1.15,2.48,3.23c0,1.25-0.34,2.47-2.16,3.27L85.88,107.45z"/>
                                <path className="st6" d="M79.98,99.3v-1.04h1.22v-0.04c-0.78-0.33-1.43-1.29-1.43-2.45c0-1.83,1.41-3.49,4.15-3.49
			c3.38,0,4.42,1.77,4.42,3.42c0,1.22-0.44,2.12-1.36,2.52v0.04h5.37v1.04H79.98z M80.73,95.83c0,2.07,1.77,2.49,3.33,2.49
			c1.6,0,3.33-0.42,3.33-2.47c0-1.49-1.27-2.52-3.33-2.52C82.01,93.32,80.73,94.35,80.73,95.83z"/>
                                <path className="st6" d="M84.03,89.17h0.22c1.82,0,3.14-0.82,3.14-2.68c0-1.44-0.65-2.21-1.73-2.71l0.25-0.97
			c1.51,0.63,2.43,1.93,2.43,3.77c0,2.08-1.5,3.77-4.28,3.77c-2.79,0-4.28-1.69-4.28-3.77c0-2.47,1.84-3.68,4.25-3.77V89.17z
			 M83.18,83.99c-1.19,0.23-2.45,0.85-2.45,2.59c0,1.48,1.09,2.42,2.45,2.59V83.99z"/>
                                <path className="st6" d="M76.04,80.36v-1.04h8.24l-4.3-4.55v-1.32l3.21,3.39l4.95-3.72v1.27l-4.25,3.2l1.65,1.74h2.6v1.04H76.04z" />
                                <path className="st6" d="M79.98,72.18v-1.7H76.5v-1.04h3.48v-1.81h0.85v1.81h5.39c0.61,0,1.17-0.02,1.17-0.61
			c0-0.49-0.19-0.8-0.41-1.15h1.02c0.22,0.5,0.34,0.96,0.34,1.37c0,0.75-0.37,1.43-1.45,1.43h-6.07v1.7H79.98z"/>
                                <path className="st6" d="M84.03,64.95h0.22c1.82,0,3.14-0.82,3.14-2.68c0-1.44-0.65-2.21-1.73-2.71l0.25-0.97
			c1.51,0.63,2.43,1.93,2.43,3.77c0,2.08-1.5,3.77-4.28,3.77c-2.79,0-4.28-1.69-4.28-3.77c0-2.47,1.84-3.68,4.25-3.77V64.95z
			 M83.18,59.77c-1.19,0.23-2.45,0.85-2.45,2.59c0,1.48,1.09,2.42,2.45,2.59V59.77z"/>
                            </g>
                            <g>
                                <path className="st6" d="M558.28,169.44l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L558.28,169.44z M555.31,164.2l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			l0.04-1.74V164.2z"/>
                                <path className="st6" d="M560.57,173.76v-14.14h4.56l1.24,0.26l0.8,0.46l0.64,0.82l0.38,1.16l0.1,1.78l-0.08,1.42l-0.18,1l-0.36,0.84
			l-0.58,0.66l-0.82,0.44l-0.92,0.22l-1.32,0.02h-0.58v5.06H560.57z M564.21,166.18l0.74-0.16l0.36-0.6l0.14-1.04l-0.04-1.32
			l-0.3-0.72l-0.44-0.22l-0.46-0.06h-0.76v4.14L564.21,166.18z"/>
                                <path className="st6" d="M571.39,173.76v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H571.39z" />
                                <path className="st6" d="M578.03,173.76v-14.12h2.88v14.12H578.03z" />
                                <path className="st6" d="M593.17,173.74v-5.84l0.02-3.74l0.14-2.06l-0.14,0.96l-0.26,1.54l-0.32,1.84l-1.56,7.3l-2.7-0.02l-1.58-7.1
			l-0.38-2.4l-0.26-1.52l-0.12-1.08l0.04,1.38l0.16,2.78l-0.04,7.98l-2.68-0.02l-0.08-14.1l4.18-0.02l1.62,7.94l0.38,2l0.12,1.46
			l0.18-1.76l0.2-1.3l1.62-8.32h4.14l0.04,14.12L593.17,173.74z"/>
                                <path className="st6" d="M603.97,173.76l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H603.97z M602.39,164.16
			l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L602.39,164.16z"/>
                                <path className="st6" d="M608.39,173.76v-14.12h2.84v11.6h4.06v2.52H608.39z" />
                                <path className="st6" d="M617.13,173.76v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H617.13z" />
                                <path className="st6" d="M631.71,173.76l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H631.71z M630.47,165.7l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			l-1.1-0.12h-0.92v3.66H630.47z"/>
                                <path className="st6" d="M477.81,195.76v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H477.81z" />
                                <path className="st6" d="M492.47,195.72l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L492.47,195.72z"/>
                                <path className="st6" d="M499.45,195.76v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H499.45z" />
                                <path className="st6" d="M513.92,195.78l-1.24-7.24l-0.26-3.06l-0.2,2.2l-0.26,1.74l-1.18,6.34l-3.28,0.02l-2.46-14.14l2.8,0.02
			l1.32,8.56l0.1,1.1l0.04,1.26l0.12-1.92l0.28-1.96l1.26-7.04l3.1-0.02l1.14,7.38l0.22,2.2l0.04,1.36l0.18-2.24l1.3-8.7h2.68
			l-2.46,14.12L513.92,195.78z"/>
                                <path className="st6" d="M521.07,195.76v-14.12h2.88v14.12H521.07z" />
                                <path className="st6" d="M526.21,186.9l0.04-1.12l0.06-0.92l0.18-1.06l0.32-0.78l0.6-0.68l0.64-0.44l1.34-0.44l0.92-0.06l0.86,0.1
			l0.92,0.26l1.2,0.8l0.5,0.7l0.28,0.86l0.14,2.58h-2.78v-0.92l-0.08-1.02l-0.12-0.64l-0.34-0.32l-0.64-0.22l-0.62,0.22l-0.26,0.38
			l-0.16,0.42l-0.08,1.3v6.18l0.14,1.02l0.26,0.46l0.3,0.2l0.46,0.08l0.48-0.12l0.48-0.58l0.18-2.14v-0.46h2.78l-0.08,2.28
			l-0.44,1.42l-0.9,1.06l-1.06,0.48l-1.56,0.18l-1.26-0.08l-0.9-0.3l-0.8-0.62l-0.52-0.8l-0.38-1.44l-0.08-2.22L526.21,186.9z"/>
                                <path className="st6" d="M542.63,195.76l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8V188l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H542.63z" />
                                <path className="st6" d="M546.85,195.76v-14.12h2.84v11.6h4.06v2.52H546.85z" />
                                <path className="st6" d="M563.99,190.68l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V190.68z"/>
                                <path className="st6" d="M572.33,195.72l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L572.33,195.72z"/>
                                <path className="st6" d="M583.65,195.76l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H583.65z"/>
                                <path className="st6" d="M593.03,185.7l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L593.03,185.7z"/>
                                <path className="st6" d="M598.01,195.76v-14.14h4.56l1.24,0.26l0.8,0.46l0.64,0.82l0.38,1.16l0.1,1.78l-0.08,1.42l-0.18,1l-0.36,0.84
			l-0.58,0.66l-0.82,0.44l-0.92,0.22l-1.32,0.02h-0.58v5.06H598.01z M601.65,188.18l0.74-0.16l0.36-0.6l0.14-1.04l-0.04-1.32
			l-0.3-0.72l-0.44-0.22l-0.46-0.06h-0.76v4.14L601.65,188.18z"/>
                                <path className="st6" d="M607.57,195.76l0.04-14.12h6.62v2.5h-3.72l-0.06,3.02h3.44v2.44h-3.44v6.16H607.57z" />
                                <path className="st6" d="M621.45,195.76l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H621.45z M619.87,186.16
			l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L619.87,186.16z"/>
                                <path className="st6" d="M625.89,181.62h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L625.89,181.62z M629.53,193.34l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H629.53z"/>
                            </g>
                            <g>
                                <path className="st6" d="M684.54,261.41l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H684.54z"/>
                                <path className="st6" d="M695.18,261.41l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H695.18z M693.94,253.35l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H693.94z"/>
                                <path className="st6" d="M708.14,256.33l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V256.33z"/>
                                <path className="st6" d="M716.48,261.37l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L716.48,261.37z"/>
                                <path className="st6" d="M722.22,247.27h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L722.22,247.27z M725.86,258.99l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H725.86z"/>
                                <path className="st6" d="M732.6,261.41v-14.12h2.84v11.6h4.06v2.52H732.6z" />
                                <path className="st6" d="M746.9,261.41l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H746.9z M745.32,251.81l-0.24-2.12
			l-0.26,2.14l-0.54,3.38h1.58L745.32,251.81z"/>
                                <path className="st6" d="M756.92,261.41l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H756.92z"/>
                                <path className="st6" d="M761.72,261.41v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H761.72z" />
                                <path className="st6" d="M776.38,261.37l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L776.38,261.37z"/>
                                <path className="st6" d="M678.96,269.27h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L678.96,269.27z M682.6,280.99l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H682.6z"/>
                                <path className="st6" d="M689.34,283.41v-14.12H696v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44H696v2.58H689.34z" />
                                <path className="st6" d="M703.92,283.41l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H703.92z M702.68,275.35l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12H701v3.66H702.68z"/>
                                <path className="st6" d="M713.26,283.41v-2.28l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H713.26z" />
                                <path className="st6" d="M731.84,278.33l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V278.33z"/>
                                <path className="st6" d="M740.44,283.41l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H740.44z" />
                                <path className="st6" d="M753.06,278.33l-0.12,2.26l-0.48,1.38l-0.96,1.02l-1.12,0.48l-1.58,0.14l-1.04-0.04l-1.44-0.5l-1.02-0.82
			l-0.5-1.04l-0.24-1.18l0.02-1.22v-9.52h2.84v8.46l0.04,1.9l0.18,1.14l0.38,0.42l0.82,0.2l0.6-0.16l0.36-0.26l0.26-0.46l0.14-1.6
			v-1.22l-0.02-8.42h2.88V278.33z M748.02,267.85h-2.04v-2.34h2.04V267.85z M751.66,267.85h-2.06v-2.34h2.06V267.85z"/>
                                <path className="st6" d="M761.4,283.37l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L761.4,283.37z"/>
                                <path className="st6" d="M767.12,283.41l0.04-14.12h6.62v2.5h-3.72l-0.06,3.02h3.44v2.44H770v6.16H767.12z" />
                                <path className="st6" d="M776.7,283.41v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H776.7z" />
                                <path className="st6" d="M783.34,283.41v-14.12h2.88v14.12H783.34z" />
                                <path className="st6" d="M794.34,283.41l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H794.34z"/>
                                <path className="st6" d="M799.14,283.41v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H799.14z" />
                                <path className="st6" d="M813.8,283.37l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76H817v14.12L813.8,283.37z"/>
                                <path className="st6" d="M678.94,305.41v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H678.94z" />
                                <path className="st6" d="M693.18,305.39l-1.36-4.06l-0.46-1.66l-0.28,1.6l-1.32,4.12l-3.14,0.02l3.02-7.48l-2.88-6.64l2.92-0.02
			l1.18,2.88l0.56,2l0.56-2.06l1.08-2.8h2.98l-2.8,6.74l2.96,7.38L693.18,305.39z"/>
                                <path className="st6" d="M697.66,305.41v-14.12h2.88v14.12H697.66z" />
                                <path className="st6" d="M707.64,295.35l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L707.64,295.35z"/>
                                <path className="st6" d="M713.88,305.41v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H713.88z" />
                                <path className="st6" d="M720.52,305.41v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H720.52z" />
                                <path className="st6" d="M735.18,305.37l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L735.18,305.37z"/>
                                <path className="st6" d="M740.28,305.41v-2.28l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H740.28z" />
                            </g>
                            <g>
                                <path className="st6" d="M597.81,436.07l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H597.81z"/>
                                <path className="st6" d="M608.45,436.07l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H608.45z M607.21,428.01l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H607.21z"/>
                                <path className="st6" d="M621.41,430.99l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V430.99z"/>
                                <path className="st6" d="M629.75,436.03l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L629.75,436.03z"/>
                                <path className="st6" d="M635.49,421.92h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L635.49,421.92z M639.13,433.65l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H639.13z"/>
                                <path className="st6" d="M645.87,436.07v-14.12h2.84v11.6h4.06v2.52H645.87z" />
                                <path className="st6" d="M660.17,436.07l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H660.17z M658.59,426.47
			l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L658.59,426.47z"/>
                                <path className="st6" d="M670.19,436.07l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H670.19z"/>
                                <path className="st6" d="M674.99,436.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H674.99z" />
                                <path className="st6" d="M689.65,436.03l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L689.65,436.03z"/>
                                <path className="st6" d="M592.23,443.93h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L592.23,443.93z M595.87,455.65l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H595.87z"/>
                                <path className="st6" d="M602.61,458.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H602.61z" />
                                <path className="st6" d="M617.19,458.07l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H617.19z M615.95,450.01l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H615.95z"/>
                                <path className="st6" d="M632.75,458.07l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H632.75z"/>
                                <path className="st6" d="M637.55,458.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H637.55z" />
                                <path className="st6" d="M651.89,458.07l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H651.89z"/>
                                <path className="st6" d="M656.69,458.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H656.69z" />
                                <path className="st6" d="M671.35,458.03l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L671.35,458.03z"/>
                                <path className="st6" d="M684.89,458.09l-1.24-7.24l-0.26-3.06l-0.2,2.2l-0.26,1.74l-1.18,6.34l-3.28,0.02l-2.46-14.14l2.8,0.02
			l1.32,8.56l0.1,1.1l0.04,1.26l0.12-1.92l0.28-1.96l1.26-7.04l3.1-0.02l1.14,7.38l0.22,2.2l0.04,1.36l0.18-2.24l1.3-8.7h2.68
			l-2.46,14.12L684.89,458.09z"/>
                                <path className="st6" d="M697.61,458.07l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H697.61z M694.97,442.48h-2.04
			v-2.34h2.04V442.48z M696.03,448.47l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L696.03,448.47z M698.61,442.48h-2.06v-2.34h2.06
			V442.48z"/>
                                <path className="st6" d="M707.87,458.07l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H707.87z M706.63,450.01l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			l-0.38-0.5l-1.1-0.12h-0.92v3.66H706.63z"/>
                                <path className="st6" d="M713.69,458.07v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H713.69z" />
                                <path className="st6" d="M720.33,458.07v-14.12h2.88v14.12H720.33z" />
                                <path className="st6" d="M731.33,458.07l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H731.33z"/>
                                <path className="st6" d="M736.13,458.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H736.13z" />
                                <path className="st6" d="M750.79,458.03l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76H754v14.12L750.79,458.03z"/>
                                <path className="st6" d="M592.21,480.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H592.21z" />
                                <path className="st6" d="M606.45,480.05l-1.36-4.06l-0.46-1.66l-0.28,1.6l-1.32,4.12l-3.14,0.02l3.02-7.48l-2.88-6.64l2.92-0.02
			l1.18,2.88l0.56,2l0.56-2.06l1.08-2.8h2.98l-2.8,6.74l2.96,7.38L606.45,480.05z"/>
                                <path className="st6" d="M610.93,480.07v-14.12h2.88v14.12H610.93z" />
                                <path className="st6" d="M620.91,470.01l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L620.91,470.01z"/>
                                <path className="st6" d="M627.15,480.07v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H627.15z" />
                                <path className="st6" d="M633.79,480.07v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H633.79z" />
                                <path className="st6" d="M648.45,480.03l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L648.45,480.03z"/>
                                <path className="st6" d="M653.55,480.07v-2.28l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H653.55z" />
                            </g>
                            <g>
                                <path className="st6" d="M75.97,403.45l-4.06-8.35l0.07,8.35h-3.43v-16.95h3.36v7.63l3.58-7.63h3.84l-4.2,7.94l4.61,9H75.97z" />
                                <path className="st6" d="M88.04,403.45l-0.26-0.6l-0.29-2.26v-0.98L87.42,398l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27v6.84
			h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H88.04z M86.55,393.78l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H86.55z"/>
                                <path className="st6" d="M93.51,403.45v-16.95h3.46v16.95H93.51z" />
                                <path className="st6" d="M101.51,403.45v-14.04h-2.78v-2.9h9v2.9h-2.78v14.04H101.51z" />
                                <path className="st6" d="M114.97,391.38l-0.02-0.31l-0.02-1.13l-0.53-0.96l-0.7-0.26l-0.46,0.1l-0.55,0.31l-0.26,0.48l-0.07,0.7
			l0.07,0.72l0.34,0.53l1.39,1.25l1.46,1.01l1.2,0.91l1.22,1.44l0.43,1.15l0.17,1.51l-0.19,1.73l-0.7,1.56l-1.2,0.96l-1.82,0.62
			h-1.2l-1.7-0.14l-1.15-0.5l-0.94-0.94l-0.53-0.98l-0.22-1.1l-0.05-2.21h3.31v1.46l0.17,1.06l0.46,0.53l0.84,0.26l0.82-0.24
			l0.53-0.65l0.12-1.06l-0.12-1.1l-0.55-0.82l-1.13-0.86l-1.54-1.03l-1.08-0.82l-1.03-1.03l-0.46-1.03l-0.19-1.7l0.14-1.42
			l0.53-1.32l0.62-0.77l0.96-0.6l0.98-0.31l1.56-0.12l2.09,0.36l1.15,0.72l0.6,0.94l0.41,1.56l0.05,1.58L114.97,391.38z"/>
                                <path className="st6" d="M120.63,392.82l0.05-1.34l0.07-1.1l0.22-1.27l0.38-0.94l0.72-0.82l0.77-0.53l1.61-0.53l1.1-0.07l1.03,0.12
			l1.1,0.31l1.44,0.96l0.6,0.84l0.34,1.03l0.17,3.1h-3.34v-1.1l-0.1-1.22l-0.14-0.77l-0.41-0.38l-0.77-0.26l-0.74,0.26l-0.31,0.46
			l-0.19,0.5l-0.1,1.56v7.42l0.17,1.22l0.31,0.55l0.36,0.24l0.55,0.1l0.58-0.14l0.58-0.7l0.22-2.57v-0.55h3.34l-0.1,2.74l-0.53,1.7
			l-1.08,1.27l-1.27,0.58l-1.87,0.22l-1.51-0.1l-1.08-0.36l-0.96-0.74l-0.62-0.96l-0.46-1.73l-0.1-2.66L120.63,392.82z"/>
                                <path className="st6" d="M139.43,403.45v-7.34h-3.05v7.34h-3.46v-16.95h3.46v6.82h3.05v-6.82h3.46v16.95H139.43z" />
                                <path className="st6" d="M145.9,403.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H145.9z" />
                                <path className="st6" d="M162.87,403.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H162.87z" />
                                <path className="st6" d="M180.47,403.4l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.3l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L180.47,403.4z"/>
                                <path className="st6" d="M188.84,403.45v-14.04h-2.78v-2.9h9v2.9h-2.78v14.04H188.84z" />
                                <path className="st6" d="M202.3,391.38l-0.02-0.31l-0.02-1.13l-0.53-0.96l-0.7-0.26l-0.46,0.1l-0.55,0.31l-0.26,0.48l-0.07,0.7
			l0.07,0.72l0.34,0.53l1.39,1.25l1.46,1.01l1.2,0.91l1.22,1.44l0.43,1.15l0.17,1.51l-0.19,1.73l-0.7,1.56l-1.2,0.96l-1.82,0.62
			h-1.2l-1.7-0.14l-1.15-0.5l-0.94-0.94l-0.53-0.98l-0.22-1.1l-0.05-2.21h3.31v1.46l0.17,1.06l0.46,0.53l0.84,0.26l0.82-0.24
			l0.53-0.65l0.12-1.06l-0.12-1.1l-0.55-0.82l-1.13-0.86l-1.54-1.03l-1.08-0.82l-1.03-1.03l-0.46-1.03l-0.19-1.7l0.14-1.42
			l0.53-1.32l0.62-0.77l0.96-0.6l0.98-0.31l1.56-0.12l2.09,0.36l1.15,0.72l0.6,0.94l0.41,1.56l0.05,1.58L202.3,391.38z"/>
                                <path className="st6" d="M207.97,392.82l0.05-1.34l0.07-1.1l0.22-1.27l0.38-0.94l0.72-0.82l0.77-0.53l1.61-0.53l1.1-0.07l1.03,0.12
			l1.1,0.31l1.44,0.96l0.6,0.84l0.34,1.03l0.17,3.1h-3.34v-1.1l-0.1-1.22l-0.14-0.77l-0.41-0.38l-0.77-0.26l-0.74,0.26l-0.31,0.46
			l-0.19,0.5l-0.1,1.56v7.42l0.17,1.22l0.31,0.55l0.36,0.24l0.55,0.1l0.58-0.14l0.58-0.7l0.22-2.57v-0.55h3.34l-0.1,2.74l-0.53,1.7
			l-1.08,1.27l-1.27,0.58l-1.87,0.22l-1.51-0.1l-1.08-0.36l-0.96-0.74l-0.62-0.96l-0.46-1.73l-0.1-2.66L207.97,392.82z"/>
                                <path className="st6" d="M226.76,403.45v-7.34h-3.05v7.34h-3.46v-16.95h3.46v6.82h3.05v-6.82h3.46v16.95H226.76z" />
                                <path className="st6" d="M233.24,403.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H233.24z" />
                                <path className="st6" d="M243.73,403.45v-16.95h3.46v16.95H243.73z" />
                                <path className="st6" d="M250.23,386.48h4.68l1.87,0.22l1.61,0.79l0.77,0.94l0.41,1.06l0.31,2.18l0.07,2.78l-0.1,3.77l-0.31,2.38
			l-0.74,1.34l-0.94,0.82l-0.98,0.46l-2.21,0.24h-4.46L250.23,386.48z M254.6,400.55l0.77-0.1l0.43-0.26l0.38-0.6l0.19-3.94
			l-0.05-3.7l-0.1-1.34l-0.38-0.77l-0.6-0.29l-0.62-0.1h-0.98v11.09H254.6z"/>
                                <path className="st6" d="M272.77,397.36l-0.12,2.38l-0.22,1.1l-0.46,0.98l-0.6,0.77l-0.91,0.55l-1.22,0.46l-1.58,0.1l-1.92-0.17
			l-1.3-0.58l-1.03-1.08l-0.53-0.94l-0.22-0.96l-0.07-2.04v-11.42H266v10.15l0.02,2.21l0.19,1.34l0.38,0.48l0.41,0.24l0.7,0.12
			l0.6-0.12l0.74-0.58l0.19-0.86l0.1-1.34v-1.54l-0.02-10.1h3.46V397.36z"/>
                                <path className="st6" d="M282.78,403.4l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.3l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L282.78,403.4z"/>
                                <path className="st6" d="M296.36,403.45l0.02-1.46l-0.38,0.58l-0.96,0.77l-0.74,0.34l-0.96,0.05l-0.98-0.05l-1.32-0.6l-0.86-0.91
			l-0.7-1.82l-0.1-2.86l-0.02-2.4l0.07-3.6l0.17-1.78l0.67-1.63l1.15-1.08l1.32-0.53l1.85-0.22l1.18,0.1l1.37,0.48l0.89,0.62
			l0.72,0.94l0.36,1.03l0.24,1.56l0.05,1.1H296v-0.72l-0.17-1.63l-0.46-0.53l-0.94-0.34l-0.58,0.12l-0.62,0.53l-0.36,1.46
			l-0.07,2.23v4.34l0.05,1.42l0.31,1.32l0.53,0.5l0.74,0.19l0.72-0.22l0.55-0.53l0.24-1.3l0.05-1.32v-0.91h-1.75v-2.74h5.02v9.48
			H296.36z"/>
                                <path className="st6" d="M307.62,391.38l-0.02-0.31l-0.02-1.13l-0.53-0.96l-0.7-0.26l-0.46,0.1l-0.55,0.31l-0.26,0.48l-0.07,0.7
			l0.07,0.72l0.34,0.53l1.39,1.25l1.46,1.01l1.2,0.91l1.22,1.44l0.43,1.15l0.17,1.51l-0.19,1.73l-0.7,1.56l-1.2,0.96l-1.82,0.62
			h-1.2l-1.7-0.14l-1.15-0.5l-0.94-0.94l-0.53-0.98l-0.22-1.1l-0.05-2.21h3.31v1.46l0.17,1.06l0.46,0.53l0.84,0.26l0.82-0.24
			l0.53-0.65l0.12-1.06l-0.12-1.1l-0.55-0.82l-1.13-0.86l-1.54-1.03l-1.08-0.82l-1.03-1.03l-0.46-1.03l-0.19-1.7l0.14-1.42
			l0.53-1.32l0.62-0.77l0.96-0.6l0.98-0.31l1.56-0.12l2.09,0.36l1.15,0.72l0.6,0.94l0.41,1.56l0.05,1.58L307.62,391.38z"/>
                                <path className="st6" d="M312.85,403.45v-2.74l6.17-11.28h-5.45v-2.93h9.34v2.76l-6.34,11.28h6.05v2.9H312.85z" />
                                <path className="st6" d="M334.78,398.27l-0.17,1.85l-0.43,1.3l-0.58,0.89l-0.77,0.62l-1.82,0.72l-1.2,0.05l-1.75-0.17l-1.56-0.79
			l-0.77-0.77l-0.62-1.3l-0.12-0.84l-0.24-5.47l0.07-2.76l0.1-1.39l0.26-1.3l0.6-1.08l0.86-0.79l1.25-0.5l1.99-0.26l1.46,0.14
			l1.13,0.41l1.32,1.1l0.65,1.3l0.19,0.82l0.17,4.34L334.78,398.27z M331.23,391.98l-0.07-1.32l-0.29-0.91l-0.43-0.55l-0.72-0.26
			l-0.79,0.24l-0.55,1.03l-0.12,1.13l-0.05,2.52l0.05,3.79l0.05,1.34l0.26,1.25l0.65,0.65l0.58,0.14l0.72-0.24l0.5-0.55l0.17-1.37
			l0.05-2.09V391.98z"/>
                                <path className="st6" d="M344.65,403.4l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.3l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L344.65,403.4z"/>
                                <path className="st6" d="M351.51,403.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H351.51z" />
                                <path className="st6" d="M61.31,437.43l-3.5-16.9l3.58-0.05l1.51,8.86l0.26,1.85l0.12,1.85l0.17-2.04l0.22-1.44l1.51-9.05h3.6
			L65,437.45L61.31,437.43z"/>
                                <path className="st6" d="M70.52,437.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H70.52z" />
                                <path className="st6" d="M88.02,437.45l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H88.02z M86.53,427.78l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H86.53z"/>
                                <path className="st6" d="M93.49,437.45v-16.95h3.41v13.92h4.87v3.02H93.49z" />
                                <path className="st6" d="M110.65,437.45l-0.82-4.68h-2.76l-0.79,4.68h-3.58l3.67-16.99h4.27l3.55,16.99H110.65z M108.75,425.93
			l-0.29-2.54l-0.31,2.57l-0.65,4.06h1.9L108.75,425.93z"/>
                                <path className="st6" d="M122.67,437.45l0.02-1.46l-0.38,0.58l-0.96,0.77l-0.74,0.34l-0.96,0.05l-0.98-0.05l-1.32-0.6l-0.86-0.91
			l-0.7-1.82l-0.1-2.86l-0.02-2.4l0.07-3.6l0.17-1.78l0.67-1.63l1.15-1.08l1.32-0.53l1.85-0.22l1.18,0.1l1.37,0.48l0.89,0.62
			l0.72,0.94l0.36,1.03l0.24,1.56l0.05,1.1h-3.38v-0.72l-0.17-1.63l-0.46-0.53l-0.94-0.34l-0.58,0.12l-0.62,0.53l-0.36,1.46
			l-0.07,2.23v4.35l0.05,1.42l0.31,1.32l0.53,0.5l0.74,0.19l0.72-0.22l0.55-0.53l0.24-1.3l0.05-1.32v-0.91h-1.75v-2.74h5.02v9.48
			H122.67z"/>
                                <path className="st6" d="M128.43,437.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H128.43z" />
                                <path className="st6" d="M145.93,437.45l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H145.93z M144.44,427.78l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H144.44z"/>
                                <path className="st6" d="M161.48,431.36l-0.12,2.38l-0.22,1.1l-0.46,0.98l-0.6,0.77l-0.91,0.55l-1.22,0.46l-1.58,0.1l-1.92-0.17
			l-1.3-0.58l-1.03-1.08l-0.53-0.94l-0.22-0.96l-0.07-2.04v-11.43h3.41v10.15l0.02,2.21l0.19,1.34l0.38,0.48l0.41,0.24l0.7,0.12
			l0.6-0.12l0.74-0.58l0.19-0.86l0.1-1.34v-1.54l-0.02-10.1h3.46V431.36z"/>
                                <path className="st6" d="M171.49,437.41l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.31l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L171.49,437.41z"/>
                                <path className="st6" d="M185.07,437.45l0.02-1.46l-0.38,0.58l-0.96,0.77l-0.74,0.34l-0.96,0.05l-0.98-0.05l-1.32-0.6l-0.86-0.91
			l-0.7-1.82l-0.1-2.86l-0.02-2.4l0.07-3.6l0.17-1.78l0.67-1.63l1.15-1.08l1.32-0.53l1.85-0.22l1.18,0.1l1.37,0.48l0.89,0.62
			l0.72,0.94l0.36,1.03l0.24,1.56l0.05,1.1h-3.38v-0.72l-0.17-1.63l-0.46-0.53l-0.94-0.34l-0.58,0.12l-0.62,0.53l-0.36,1.46
			l-0.07,2.23v4.35l0.05,1.42l0.31,1.32l0.53,0.5l0.74,0.19l0.72-0.22l0.55-0.53l0.24-1.3l0.05-1.32v-0.91h-1.75v-2.74h5.02v9.48
			H185.07z"/>
                                <path className="st6" d="M199.57,437.43l-3.5-16.9l3.58-0.05l1.51,8.86l0.26,1.85l0.12,1.85l0.17-2.04l0.22-1.44l1.51-9.05h3.6
			l-3.77,16.95L199.57,437.43z"/>
                                <path className="st6" d="M218.51,432.27l-0.17,1.85l-0.43,1.3l-0.58,0.89l-0.77,0.62l-1.82,0.72l-1.2,0.05l-1.75-0.17l-1.56-0.79
			l-0.77-0.77l-0.62-1.3l-0.12-0.84l-0.24-5.47l0.07-2.76l0.1-1.39l0.26-1.3l0.6-1.08l0.86-0.79l1.25-0.5l1.99-0.26l1.46,0.14
			l1.13,0.41l1.32,1.1l0.65,1.3l0.19,0.82l0.17,4.34L218.51,432.27z M214.95,425.98l-0.07-1.32l-0.29-0.91l-0.43-0.55l-0.72-0.26
			l-0.79,0.24l-0.55,1.03l-0.12,1.13l-0.05,2.52l0.05,3.79l0.05,1.34l0.26,1.25l0.65,0.65l0.58,0.14l0.72-0.24l0.5-0.55l0.17-1.37
			l0.05-2.09V425.98z"/>
                                <path className="st6" d="M228.37,437.41l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.31l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L228.37,437.41z"/>
                                <path className="st6" d="M248.72,437.45l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H248.72z M247.23,427.78l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H247.23z"/>
                                <path className="st6" d="M254.19,437.45v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H254.19z" />
                                <path className="st6" d="M270.18,425.38l-0.02-0.31l-0.02-1.13l-0.53-0.96l-0.7-0.26l-0.46,0.1l-0.55,0.31l-0.26,0.48l-0.07,0.7
			l0.07,0.72l0.34,0.53l1.39,1.25l1.46,1.01l1.2,0.91l1.22,1.44l0.43,1.15l0.17,1.51l-0.19,1.73l-0.7,1.56l-1.2,0.96l-1.82,0.62
			h-1.2l-1.7-0.14l-1.15-0.5l-0.94-0.94l-0.53-0.98l-0.22-1.1l-0.05-2.21h3.31v1.46l0.17,1.06l0.46,0.53l0.84,0.26l0.82-0.24
			l0.53-0.65l0.12-1.06l-0.12-1.1l-0.55-0.82l-1.13-0.86l-1.54-1.03l-1.08-0.82l-1.03-1.03l-0.46-1.03l-0.19-1.7l0.14-1.42
			l0.53-1.32l0.62-0.77l0.96-0.6l0.98-0.31l1.56-0.12l2.09,0.36l1.15,0.72l0.6,0.94l0.41,1.56l0.05,1.58L270.18,425.38z"/>
                                <path className="st6" d="M281.65,425.38l-0.02-0.31l-0.02-1.13l-0.53-0.96l-0.7-0.26l-0.46,0.1l-0.55,0.31l-0.26,0.48l-0.07,0.7
			l0.07,0.72l0.34,0.53l1.39,1.25l1.46,1.01l1.2,0.91l1.22,1.44l0.43,1.15l0.17,1.51l-0.19,1.73l-0.7,1.56l-1.2,0.96l-1.82,0.62
			h-1.2l-1.7-0.14l-1.15-0.5l-0.94-0.94l-0.53-0.98l-0.22-1.1l-0.05-2.21h3.31v1.46l0.17,1.06l0.46,0.53l0.84,0.26l0.82-0.24
			l0.53-0.65l0.12-1.06l-0.12-1.1l-0.55-0.82l-1.13-0.86l-1.54-1.03l-1.08-0.82l-1.03-1.03l-0.46-1.03l-0.19-1.7l0.14-1.42
			l0.53-1.32l0.62-0.77l0.96-0.6l0.98-0.31l1.56-0.12l2.09,0.36l1.15,0.72l0.6,0.94l0.41,1.56l0.05,1.58L281.65,425.38z"/>
                                <path className="st6" d="M297.34,432.27l-0.17,1.85l-0.43,1.3l-0.58,0.89l-0.77,0.62l-1.82,0.72l-1.2,0.05l-1.75-0.17l-1.56-0.79
			l-0.77-0.77l-0.62-1.3l-0.12-0.84l-0.24-5.47l0.07-2.76l0.1-1.39l0.26-1.3l0.6-1.08l0.86-0.79l1.25-0.5l1.99-0.26l1.46,0.14
			l1.13,0.41l1.32,1.1l0.65,1.3l0.19,0.82l0.17,4.34L297.34,432.27z M293.79,425.98l-0.07-1.32l-0.29-0.91L293,423.2l-0.72-0.26
			l-0.79,0.24l-0.55,1.03l-0.12,1.13l-0.05,2.52l0.05,3.79l0.05,1.34l0.26,1.25l0.65,0.65l0.58,0.14l0.72-0.24l0.5-0.55l0.17-1.37
			l0.05-2.09V425.98z"/>
                                <path className="st6" d="M310.18,431.36l-0.12,2.38l-0.22,1.1l-0.46,0.98l-0.6,0.77l-0.91,0.55l-1.22,0.46l-1.58,0.1l-1.92-0.17
			l-1.3-0.58l-1.03-1.08l-0.53-0.94l-0.22-0.96l-0.07-2.04v-11.43h3.41v10.15l0.02,2.21l0.19,1.34l0.38,0.48l0.41,0.24l0.7,0.12
			l0.6-0.12l0.74-0.58l0.19-0.86l0.1-1.34v-1.54l-0.02-10.1h3.46V431.36z"/>
                                <path className="st6" d="M320.1,437.45l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H320.1z M318.61,427.78l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H318.61z"/>
                                <path className="st6" d="M325.26,426.82l0.05-1.34l0.07-1.1l0.22-1.27l0.38-0.94l0.72-0.82l0.77-0.53l1.61-0.53l1.1-0.07l1.03,0.12
			l1.1,0.31l1.44,0.96l0.6,0.84l0.34,1.03l0.17,3.1h-3.34v-1.1l-0.1-1.22l-0.14-0.77l-0.41-0.38l-0.77-0.26l-0.74,0.26l-0.31,0.46
			l-0.19,0.5l-0.1,1.56v7.42l0.17,1.22l0.31,0.55l0.36,0.24l0.55,0.1l0.58-0.14l0.58-0.7l0.22-2.57v-0.55h3.34l-0.1,2.74l-0.53,1.7
			l-1.08,1.27l-1.27,0.58l-1.87,0.22l-1.51-0.1l-1.08-0.36l-0.96-0.74l-0.62-0.96l-0.46-1.73l-0.1-2.66L325.26,426.82z"/>
                                <path className="st6" d="M337.54,437.45v-16.95h7.99v3.07H341v3.65h4.06v3H341v4.13h4.54v3.1H337.54z" />
                                <path className="st6" d="M355.13,437.41l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			l4.03,0.02l2.81,8.31l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L355.13,437.41z"/>
                            </g>
                        </>}
                    </g>
                    <g id="Lines_1_">
                        <line id="Line_-_3_1_" className="st8" x1="425.75" y1="0" x2="425.75" y2="753.83" />
                        <line id="Line_-_3_2_" className="st9" x1="425.75" y1="850.39" x2="425.75" y2="822.75" />
                        <line id="Line_-_2" className="st10" x1="0" y1="0" x2="0" y2="850.39" />
                        <line id="Line_-_1" className="st10" x1="850.39" y1="850.39" x2="0" y2="850.39" />
                    </g>
                </svg>

                <Zoom onClick={() => this.ToggleFullsreen()} />
                <div className="close-zoom"><FullscreenClose onClick={() => this.ToggleFullsreen()} /></div>
                <div className="copyright-malik">&copy; Malik 2023</div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const { indexContainers, indexTopics, indexBites, collapseModelC, modelHasClosed, SelectedModel, indexLoader } = state.mkp;

    return { SelectedLanguage, indexContainers, indexTopics, indexBites, collapseModelC, modelHasClosed, SelectedModel, indexLoader };
}

const actionCreators = {
    getContainersByModel: mkpActions.getContainersByModel,
    getAllContainerTopics: mkpActions.getAllContainerTopics,
    getAllTopicBites: mkpActions.getAllTopicBites,
    SelectModel: mkpActions.SelectModel
};

const connectedMKP = connect(mapState, actionCreators)(Model5);
export { connectedMKP as Model5 };
