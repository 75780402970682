import { authenticationConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user, error: '' } : { loggedIn: false};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.LOGOUT:            
            return {
                loggedIn: false,
                loader: false
            };
        case authenticationConstants.PROFILE_SUCCESS:
            return {
                loggedIn: true,
                fetchProfile:false,
                user: action.user,
                loader: false
            };
        default:
            return state
    }
}

export default authentication