import React, { Component } from 'react';

export class ModelActiveRectangleL2 extends Component {
    render() {
        const { toggleLevel } = this.props
        return (
            <svg version="1.1" className="modelActive" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 132 71" xmlSpace="preserve">
                <g id="Square">
                    <rect className="st0" x="42.166" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.525) rotate(-0.704)" />
                    <polygon className="st0" points="1.143 44.116 1.139 44.433 0.347 44.424 0.352 44.102 1.143 44.116" />
                    <polygon className="st0" points="1.143 42.727 1.139 43.043 0.347 43.034 0.352 42.712 1.143 42.727" />
                    <polygon className="st0" points="1.143 41.337 1.139 41.654 0.347 41.644 0.352 41.322 1.143 41.337" />
                    <polygon className="st0" points="1.143 39.947 1.139 40.264 0.347 40.254 0.352 39.932 1.143 39.947" />
                    <polygon className="st0" points="1.143 38.557 1.139 38.874 0.347 38.864 0.352 38.542 1.143 38.557" />
                    <polygon className="st0" points="1.143 37.167 1.139 37.484 0.347 37.474 0.352 37.152 1.143 37.167" />
                    <polygon className="st0" points="1.143 35.777 1.139 36.094 0.347 36.085 0.352 35.762 1.143 35.777" />
                    <polygon className="st0" points="1.143 34.387 1.139 34.704 0.347 34.695 0.352 34.372 1.143 34.387" />
                    <polygon className="st0" points="1.143 32.997 1.139 33.314 0.347 33.305 0.352 32.982 1.143 32.997" />
                    <polygon className="st0" points="1.143 31.607 1.139 31.924 0.347 31.915 0.352 31.592 1.143 31.607" />
                    <polygon className="st0" points="1.143 30.217 1.139 30.534 0.347 30.525 0.352 30.203 1.143 30.217" />
                    <polygon className="st0" points="1.143 28.827 1.139 29.144 0.347 29.135 0.352 28.813 1.143 28.827" />
                    <polygon className="st0" points="1.143 27.438 1.139 27.754 0.347 27.745 0.352 27.423 1.143 27.438" />
                    <polygon className="st0" points="1.143 26.048 1.139 26.365 0.347 26.355 0.352 26.033 1.143 26.048" />
                    <polygon className="st0" points="1.143 24.658 1.139 24.975 0.347 24.965 0.352 24.643 1.143 24.658" />
                    <polygon className="st0" points="1.143 23.268 1.139 23.585 0.347 23.575 0.352 23.253 1.143 23.268" />
                    <polygon className="st0" points="1.143 21.878 1.139 22.195 0.347 22.186 0.352 21.863 1.143 21.878" />
                    <polygon className="st0" points="1.143 20.488 1.139 20.805 0.347 20.796 0.352 20.473 1.143 20.488" />
                    <polygon className="st0" points="1.143 19.098 1.139 19.415 0.347 19.406 0.352 19.083 1.143 19.098" />
                    <polygon className="st0" points="1.143 17.708 1.139 18.025 0.347 18.016 0.352 17.693 1.143 17.708" />
                    <polygon className="st0" points="1.143 16.318 1.139 16.635 0.347 16.626 0.352 16.304 1.143 16.318" />
                    <polygon className="st0" points="1.143 14.928 1.139 15.245 0.347 15.236 0.352 14.914 1.143 14.928" />
                    <polygon className="st0" points="1.143 13.539 1.139 13.855 0.347 13.846 0.352 13.524 1.143 13.539" />
                    <polygon className="st0" points="1.143 12.149 1.139 12.466 0.347 12.456 0.352 12.134 1.143 12.149" />
                    <polygon className="st0" points="1.143 10.759 1.139 11.076 0.347 11.066 0.352 10.744 1.143 10.759" />
                    <polygon className="st0" points="1.143 9.369 1.139 9.686 0.347 9.676 0.352 9.354 1.143 9.369" />
                    <polygon className="st0" points="1.143 7.979 1.139 8.296 0.347 8.286 0.352 7.964 1.143 7.979" />
                    <polygon className="st0" points="1.143 6.589 1.139 6.906 0.347 6.897 0.352 6.574 1.143 6.589" />
                    <polygon className="st0" points="1.143 2.421 1.139 2.738 0.347 2.729 0.352 2.406 1.143 2.421" />
                    <polygon className="st0" points="1.143 0.894 1.139 1.211 0.347 1.201 0.352 0.879 1.143 0.894" />
                    <rect className="st0" x="41.93" y="0.34" width="0.791" height="0.32" transform="translate(41.24 42.814) rotate(-89.206)" />
                    <rect className="st0" x="43.555" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.542) rotate(-0.704)" />
                    <rect className="st0" x="43.319" y="0.34" width="0.791" height="0.32" transform="translate(42.609 44.203) rotate(-89.206)" />
                    <rect className="st0" x="44.944" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.559) rotate(-0.704)" />
                    <rect className="st0" x="44.708" y="0.34" width="0.791" height="0.32" transform="translate(43.979 45.592) rotate(-89.206)" />
                    <rect className="st0" x="46.333" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.576) rotate(-0.704)" />
                    <rect className="st0" x="46.097" y="0.34" width="0.791" height="0.32" transform="translate(45.349 46.981) rotate(-89.206)" />
                    <rect className="st0" x="47.722" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.593) rotate(-0.704)" />
                    <rect className="st0" x="47.486" y="0.34" width="0.791" height="0.32" transform="translate(46.719 48.37) rotate(-89.206)" />
                    <rect className="st0" x="49.111" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.61) rotate(-0.704)" />
                    <rect className="st0" x="48.875" y="0.34" width="0.791" height="0.32" transform="translate(48.088 49.759) rotate(-89.206)" />
                    <rect className="st0" x="50.5" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.628) rotate(-0.704)" />
                    <rect className="st0" x="50.264" y="0.34" width="0.791" height="0.32" transform="translate(49.458 51.147) rotate(-89.206)" />
                    <rect className="st0" x="51.889" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.645) rotate(-0.704)" />
                    <rect className="st0" x="51.653" y="0.34" width="0.791" height="0.32" transform="translate(50.828 52.536) rotate(-89.206)" />
                    <rect className="st0" x="53.278" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.662) rotate(-0.704)" />
                    <rect className="st0" x="53.042" y="0.34" width="0.791" height="0.32" transform="translate(52.198 53.925) rotate(-89.206)" />
                    <rect className="st0" x="54.667" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.679) rotate(-0.704)" />
                    <rect className="st0" x="54.431" y="0.34" width="0.791" height="0.32" transform="translate(53.567 55.314) rotate(-89.206)" />
                    <rect className="st0" x="56.056" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.696) rotate(-0.704)" />
                    <rect className="st0" x="55.82" y="0.34" width="0.791" height="0.32" transform="translate(54.937 56.703) rotate(-89.206)" />
                    <rect className="st0" x="57.445" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.713) rotate(-0.704)" />
                    <rect className="st0" x="57.209" y="0.34" width="0.791" height="0.32" transform="translate(56.307 58.092) rotate(-89.206)" />
                    <rect className="st0" x="58.834" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.73) rotate(-0.704)" />
                    <rect className="st0" x="58.598" y="0.34" width="0.791" height="0.32" transform="translate(57.677 59.481) rotate(-89.206)" />
                    <rect className="st0" x="60.223" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.747) rotate(-0.704)" />
                    <rect className="st0" x="59.987" y="0.34" width="0.791" height="0.32" transform="translate(59.046 60.869) rotate(-89.206)" />
                    <rect className="st0" x="61.612" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.764) rotate(-0.704)" />
                    <rect className="st0" x="61.376" y="0.34" width="0.791" height="0.32" transform="translate(60.416 62.258) rotate(-89.206)" />
                    <rect className="st0" x="63.001" y="69.896" width="0.32" height="0.791" transform="translate(-0.859 0.781) rotate(-0.704)" />
                    <rect className="st0" x="62.765" y="0.34" width="0.791" height="0.32" transform="translate(61.786 63.647) rotate(-89.206)" />
                    <rect className="st0" x="64.39" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.798) rotate(-0.704)" />
                    <rect className="st0" x="64.154" y="0.34" width="0.791" height="0.32" transform="translate(63.156 65.036) rotate(-89.206)" />
                    <rect className="st0" x="65.779" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.815) rotate(-0.704)" />
                    <rect className="st0" x="65.543" y="0.34" width="0.791" height="0.32" transform="translate(64.525 66.425) rotate(-89.206)" />
                    <rect className="st0" x="67.168" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.832) rotate(-0.704)" />
                    <rect className="st0" x="66.932" y="0.34" width="0.791" height="0.32" transform="translate(65.895 67.814) rotate(-89.206)" />
                    <rect className="st0" x="68.557" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.849) rotate(-0.704)" />
                    <rect className="st0" x="68.321" y="0.34" width="0.791" height="0.32" transform="translate(67.265 69.203) rotate(-89.206)" />
                    <rect className="st0" x="69.946" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.866) rotate(-0.704)" />
                    <rect className="st0" x="69.71" y="0.34" width="0.791" height="0.32" transform="translate(68.635 70.592) rotate(-89.206)" />
                    <rect className="st0" x="71.335" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.883) rotate(-0.704)" />
                    <rect className="st0" x="71.099" y="0.34" width="0.791" height="0.32" transform="translate(70.004 71.98) rotate(-89.206)" />
                    <rect className="st0" x="72.724" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.9) rotate(-0.704)" />
                    <rect className="st0" x="72.488" y="0.34" width="0.791" height="0.32" transform="translate(71.374 73.369) rotate(-89.206)" />
                    <rect className="st0" x="74.113" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.918) rotate(-0.704)" />
                    <rect className="st0" x="73.877" y="0.34" width="0.791" height="0.32" transform="translate(72.744 74.758) rotate(-89.206)" />
                    <rect className="st0" x="75.502" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.935) rotate(-0.704)" />
                    <rect className="st0" x="75.266" y="0.34" width="0.791" height="0.32" transform="translate(74.114 76.147) rotate(-89.206)" />
                    <rect className="st0" x="76.891" y="69.896" width="0.32" height="0.791" transform="translate(-0.858 0.952) rotate(-0.704)" />
                    <rect className="st0" x="76.655" y="0.34" width="0.791" height="0.32" transform="translate(75.483 77.536) rotate(-89.206)" />
                    <rect className="st0" x="78.28" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 0.969) rotate(-0.704)" />
                    <rect className="st0" x="78.044" y="0.34" width="0.791" height="0.32" transform="translate(76.853 78.925) rotate(-89.206)" />
                    <rect className="st0" x="79.669" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 0.986) rotate(-0.704)" />
                    <rect className="st0" x="79.433" y="0.34" width="0.791" height="0.32" transform="translate(78.223 80.314) rotate(-89.206)" />
                    <rect className="st0" x="81.058" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.003) rotate(-0.704)" />
                    <rect className="st0" x="80.822" y="0.34" width="0.791" height="0.32" transform="translate(79.593 81.702) rotate(-89.206)" />
                    <rect className="st0" x="82.447" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.02) rotate(-0.704)" />
                    <rect className="st0" x="82.211" y="0.34" width="0.791" height="0.32" transform="translate(80.962 83.091) rotate(-89.206)" />
                    <rect className="st0" x="83.836" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.037) rotate(-0.704)" />
                    <rect className="st0" x="83.6" y="0.34" width="0.791" height="0.32" transform="translate(82.332 84.48) rotate(-89.206)" />
                    <rect className="st0" x="85.225" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.054) rotate(-0.704)" />
                    <rect className="st0" x="84.989" y="0.34" width="0.791" height="0.32" transform="translate(83.702 85.869) rotate(-89.206)" />
                    <rect className="st0" x="86.614" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.071) rotate(-0.704)" />
                    <rect className="st0" x="86.378" y="0.34" width="0.791" height="0.32" transform="translate(85.072 87.258) rotate(-89.206)" />
                    <rect className="st0" x="88.003" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.088) rotate(-0.704)" />
                    <rect className="st0" x="87.767" y="0.34" width="0.791" height="0.32" transform="translate(86.441 88.647) rotate(-89.206)" />
                    <rect className="st0" x="89.392" y="69.896" width="0.32" height="0.791" transform="translate(-0.857 1.105) rotate(-0.704)" />
                    <rect className="st0" x="89.156" y="0.34" width="0.791" height="0.32" transform="translate(87.811 90.036) rotate(-89.206)" />
                    <rect className="st0" x="90.781" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.122) rotate(-0.704)" />
                    <rect className="st0" x="90.545" y="0.34" width="0.791" height="0.32" transform="translate(89.181 91.425) rotate(-89.206)" />
                    <rect className="st0" x="92.17" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.139) rotate(-0.704)" />
                    <rect className="st0" x="91.934" y="0.34" width="0.791" height="0.32" transform="translate(90.551 92.813) rotate(-89.206)" />
                    <rect className="st0" x="93.559" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.156) rotate(-0.704)" />
                    <rect className="st0" x="93.323" y="0.34" width="0.791" height="0.32" transform="translate(91.92 94.202) rotate(-89.206)" />
                    <rect className="st0" x="94.948" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.173) rotate(-0.704)" />
                    <rect className="st0" x="94.712" y="0.34" width="0.791" height="0.32" transform="translate(93.29 95.591) rotate(-89.206)" />
                    <rect className="st0" x="96.337" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.191) rotate(-0.704)" />
                    <rect className="st0" x="96.101" y="0.34" width="0.791" height="0.32" transform="translate(94.66 96.98) rotate(-89.206)" />
                    <rect className="st0" x="97.726" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.208) rotate(-0.704)" />
                    <rect className="st0" x="97.49" y="0.34" width="0.791" height="0.32" transform="translate(96.03 98.369) rotate(-89.206)" />
                    <rect className="st0" x="99.115" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.225) rotate(-0.704)" />
                    <rect className="st0" x="98.879" y="0.34" width="0.791" height="0.32" transform="translate(97.399 99.758) rotate(-89.206)" />
                    <rect className="st0" x="100.504" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.242) rotate(-0.704)" />
                    <rect className="st0" x="100.268" y="0.34" width="0.791" height="0.32" transform="translate(98.769 101.147) rotate(-89.206)" />
                    <rect className="st0" x="101.893" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.259) rotate(-0.704)" />
                    <rect className="st0" x="101.657" y="0.34" width="0.791" height="0.32" transform="translate(100.139 102.535) rotate(-89.206)" />
                    <rect className="st0" x="103.282" y="69.896" width="0.32" height="0.791" transform="translate(-0.856 1.276) rotate(-0.704)" />
                    <rect className="st0" x="103.046" y="0.34" width="0.791" height="0.32" transform="translate(101.509 103.924) rotate(-89.206)" />
                    <rect className="st0" x="104.671" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.293) rotate(-0.704)" />
                    <rect className="st0" x="104.435" y="0.34" width="0.791" height="0.32" transform="translate(102.878 105.313) rotate(-89.206)" />
                    <rect className="st0" x="106.06" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.31) rotate(-0.704)" />
                    <rect className="st0" x="105.824" y="0.34" width="0.791" height="0.32" transform="translate(104.248 106.702) rotate(-89.206)" />
                    <rect className="st0" x="107.449" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.327) rotate(-0.704)" />
                    <rect className="st0" x="107.213" y="0.34" width="0.791" height="0.32" transform="translate(105.618 108.091) rotate(-89.206)" />
                    <rect className="st0" x="108.838" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.344) rotate(-0.704)" />
                    <rect className="st0" x="108.602" y="0.34" width="0.791" height="0.32" transform="translate(106.988 109.48) rotate(-89.206)" />
                    <rect className="st0" x="110.227" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.361) rotate(-0.704)" />
                    <rect className="st0" x="109.991" y="0.34" width="0.791" height="0.32" transform="translate(108.357 110.869) rotate(-89.206)" />
                    <rect className="st0" x="111.616" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.378) rotate(-0.704)" />
                    <rect className="st0" x="111.38" y="0.34" width="0.791" height="0.32" transform="translate(109.727 112.258) rotate(-89.206)" />
                    <rect className="st0" x="113.005" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.395) rotate(-0.704)" />
                    <rect className="st0" x="112.769" y="0.34" width="0.791" height="0.32" transform="translate(111.097 113.646) rotate(-89.206)" />
                    <rect className="st0" x="114.394" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.412) rotate(-0.704)" />
                    <rect className="st0" x="114.158" y="0.34" width="0.791" height="0.32" transform="translate(112.467 115.035) rotate(-89.206)" />
                    <rect className="st0" x="115.783" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.429) rotate(-0.704)" />
                    <rect className="st0" x="115.547" y="0.34" width="0.791" height="0.32" transform="translate(113.836 116.424) rotate(-89.206)" />
                    <rect className="st0" x="117.172" y="69.896" width="0.32" height="0.791" transform="translate(-0.855 1.446) rotate(-0.704)" />
                    <rect className="st0" x="116.936" y="0.34" width="0.791" height="0.32" transform="translate(115.206 117.813) rotate(-89.206)" />
                    <rect className="st0" x="118.561" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.463) rotate(-0.704)" />
                    <rect className="st0" x="118.325" y="0.34" width="0.791" height="0.32" transform="translate(116.576 119.202) rotate(-89.206)" />
                    <rect className="st0" x="119.95" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.481) rotate(-0.704)" />
                    <rect className="st0" x="119.714" y="0.34" width="0.791" height="0.32" transform="translate(117.946 120.591) rotate(-89.206)" />
                    <rect className="st0" x="121.339" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.498) rotate(-0.704)" />
                    <rect className="st0" x="121.103" y="0.34" width="0.791" height="0.32" transform="translate(119.315 121.98) rotate(-89.206)" />
                    <rect className="st0" x="122.728" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.515) rotate(-0.704)" />
                    <rect className="st0" x="122.492" y="0.34" width="0.791" height="0.32" transform="translate(120.685 123.368) rotate(-89.206)" />
                    <rect className="st0" x="124.117" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.532) rotate(-0.704)" />
                    <rect className="st0" x="123.881" y="0.34" width="0.791" height="0.32" transform="translate(122.055 124.757) rotate(-89.206)" />
                    <rect className="st0" x="125.506" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.549) rotate(-0.704)" />
                    <rect className="st0" x="125.27" y="0.34" width="0.791" height="0.32" transform="translate(123.425 126.146) rotate(-89.206)" />
                    <rect className="st0" x="126.895" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.566) rotate(-0.704)" />
                    <rect className="st0" x="126.659" y="0.34" width="0.791" height="0.32" transform="translate(124.794 127.535) rotate(-89.206)" />
                    <rect className="st0" x="128.284" y="69.896" width="0.32" height="0.791" transform="translate(-0.854 1.583) rotate(-0.704)" />
                    <rect className="st0" x="128.048" y="0.34" width="0.791" height="0.32" transform="translate(126.164 128.924) rotate(-89.206)" />
                    <rect className="st0" x="40.777" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.508) rotate(-0.704)" />
                    <rect className="st0" x="40.541" y="0.34" width="0.791" height="0.32" transform="translate(39.87 41.425) rotate(-89.206)" />
                    <rect className="st0" x="39.388" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.491) rotate(-0.704)" />
                    <rect className="st0" x="39.152" y="0.34" width="0.791" height="0.32" transform="translate(38.5 40.036) rotate(-89.206)" />
                    <rect className="st0" x="37.999" y="69.896" width="0.32" height="0.791" transform="translate(-0.86 0.474) rotate(-0.704)" />
                    <rect className="st0" x="37.763" y="0.34" width="0.791" height="0.32" transform="translate(37.13 38.648) rotate(-89.206)" />
                    <rect className="st0" x="36.61" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.457) rotate(-0.704)" />
                    <rect className="st0" x="36.374" y="0.34" width="0.791" height="0.32" transform="translate(35.761 37.259) rotate(-89.206)" />
                    <rect className="st0" x="35.221" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.44) rotate(-0.704)" />
                    <rect className="st0" x="34.985" y="0.34" width="0.791" height="0.32" transform="translate(34.391 35.87) rotate(-89.206)" />
                    <rect className="st0" x="33.832" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.423) rotate(-0.704)" />
                    <rect className="st0" x="33.596" y="0.34" width="0.791" height="0.32" transform="translate(33.021 34.481) rotate(-89.206)" />
                    <rect className="st0" x="32.443" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.406) rotate(-0.704)" />
                    <rect className="st0" x="32.207" y="0.34" width="0.791" height="0.32" transform="translate(31.651 33.092) rotate(-89.206)" />
                    <rect className="st0" x="31.054" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.389) rotate(-0.704)" />
                    <rect className="st0" x="30.818" y="0.34" width="0.791" height="0.32" transform="translate(30.282 31.703) rotate(-89.206)" />
                    <rect className="st0" x="29.665" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.372) rotate(-0.704)" />
                    <rect className="st0" x="29.429" y="0.34" width="0.791" height="0.32" transform="translate(28.912 30.314) rotate(-89.206)" />
                    <rect className="st0" x="28.276" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.355) rotate(-0.704)" />
                    <rect className="st0" x="28.04" y="0.34" width="0.791" height="0.32" transform="translate(27.542 28.926) rotate(-89.206)" />
                    <rect className="st0" x="26.887" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.338) rotate(-0.704)" />
                    <rect className="st0" x="26.651" y="0.34" width="0.791" height="0.32" transform="translate(26.172 27.537) rotate(-89.206)" />
                    <rect className="st0" x="25.498" y="69.896" width="0.32" height="0.791" transform="translate(-0.861 0.32) rotate(-0.704)" />
                    <rect className="st0" x="25.262" y="0.34" width="0.791" height="0.32" transform="translate(24.803 26.148) rotate(-89.206)" />
                    <rect className="st0" x="24.109" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.303) rotate(-0.704)" />
                    <rect className="st0" x="23.873" y="0.34" width="0.791" height="0.32" transform="translate(23.433 24.759) rotate(-89.206)" />
                    <rect className="st0" x="22.72" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.286) rotate(-0.704)" />
                    <rect className="st0" x="22.484" y="0.34" width="0.791" height="0.32" transform="translate(22.063 23.37) rotate(-89.206)" />
                    <rect className="st0" x="21.331" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.269) rotate(-0.704)" />
                    <rect className="st0" x="21.095" y="0.34" width="0.791" height="0.32" transform="translate(20.693 21.981) rotate(-89.206)" />
                    <rect className="st0" x="19.942" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.252) rotate(-0.704)" />
                    <rect className="st0" x="19.706" y="0.34" width="0.791" height="0.32" transform="translate(19.324 20.592) rotate(-89.206)" />
                    <rect className="st0" x="18.553" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.235) rotate(-0.704)" />
                    <rect className="st0" x="18.317" y="0.34" width="0.791" height="0.32" transform="translate(17.954 19.203) rotate(-89.206)" />
                    <rect className="st0" x="17.164" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.218) rotate(-0.704)" />
                    <rect className="st0" x="16.928" y="0.34" width="0.791" height="0.32" transform="translate(16.584 17.815) rotate(-89.206)" />
                    <rect className="st0" x="15.775" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.201) rotate(-0.704)" />
                    <rect className="st0" x="15.539" y="0.34" width="0.791" height="0.32" transform="translate(15.214 16.426) rotate(-89.206)" />
                    <rect className="st0" x="14.386" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.184) rotate(-0.704)" />
                    <rect className="st0" x="14.15" y="0.34" width="0.791" height="0.32" transform="translate(13.845 15.037) rotate(-89.206)" />
                    <rect className="st0" x="12.997" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.167) rotate(-0.704)" />
                    <rect className="st0" x="12.761" y="0.34" width="0.791" height="0.32" transform="translate(12.475 13.648) rotate(-89.206)" />
                    <rect className="st0" x="11.608" y="69.896" width="0.32" height="0.791" transform="translate(-0.862 0.15) rotate(-0.704)" />
                    <rect className="st0" x="11.372" y="0.34" width="0.791" height="0.32" transform="translate(11.105 12.259) rotate(-89.206)" />
                    <rect className="st0" x="10.219" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.133) rotate(-0.704)" />
                    <rect className="st0" x="9.983" y="0.34" width="0.791" height="0.32" transform="translate(9.735 10.87) rotate(-89.206)" />
                    <rect className="st0" x="8.83" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.116) rotate(-0.704)" />
                    <rect className="st0" x="8.594" y="0.34" width="0.791" height="0.32" transform="translate(8.366 9.481) rotate(-89.206)" />
                    <rect className="st0" x="7.441" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.099) rotate(-0.704)" />
                    <rect className="st0" x="7.205" y="0.34" width="0.791" height="0.32" transform="translate(6.996 8.093) rotate(-89.206)" />
                    <rect className="st0" x="6.052" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.082) rotate(-0.704)" />
                    <rect className="st0" x="5.816" y="0.34" width="0.791" height="0.32" transform="translate(5.626 6.704) rotate(-89.206)" />
                    <rect className="st0" x="4.663" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.065) rotate(-0.704)" />
                    <rect className="st0" x="4.427" y="0.34" width="0.791" height="0.32" transform="translate(4.256 5.315) rotate(-89.206)" />
                    <rect className="st0" x="3.274" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.047) rotate(-0.704)" />
                    <rect className="st0" x="3.038" y="0.34" width="0.791" height="0.32" transform="translate(2.887 3.926) rotate(-89.206)" />
                    <rect className="st0" x="1.885" y="69.896" width="0.32" height="0.791" transform="translate(-0.863 0.03) rotate(-0.704)" />
                    <rect className="st0" x="1.649" y="0.34" width="0.791" height="0.32" transform="translate(1.517 2.537) rotate(-89.206)" />
                    <polygon className="st0" points="1.143 45.505 1.139 45.822 0.347 45.813 0.352 45.491 1.143 45.505" />
                    <polygon className="st0" points="1.143 46.894 1.139 47.211 0.347 47.202 0.352 46.88 1.143 46.894" />
                    <polygon className="st0" points="1.143 48.283 1.139 48.6 0.347 48.591 0.352 48.269 1.143 48.283" />
                    <polygon className="st0" points="1.143 49.672 1.139 49.989 0.347 49.98 0.352 49.658 1.143 49.672" />
                    <polygon className="st0" points="1.143 51.061 1.139 51.378 0.347 51.369 0.352 51.047 1.143 51.061" />
                    <polygon className="st0" points="1.143 52.45 1.139 52.767 0.347 52.758 0.352 52.436 1.143 52.45" />
                    <polygon className="st0" points="1.143 53.839 1.139 54.156 0.347 54.147 0.352 53.825 1.143 53.839" />
                    <polygon className="st0" points="1.143 55.228 1.139 55.545 0.347 55.536 0.352 55.214 1.143 55.228" />
                    <polygon className="st0" points="1.143 56.617 1.139 56.934 0.347 56.925 0.352 56.603 1.143 56.617" />
                    <polygon className="st0" points="1.143 58.006 1.139 58.323 0.347 58.314 0.352 57.992 1.143 58.006" />
                    <polygon className="st0" points="1.143 59.395 1.139 59.712 0.347 59.703 0.352 59.381 1.143 59.395" />
                    <polygon className="st0" points="1.143 60.784 1.139 61.101 0.347 61.092 0.352 60.77 1.143 60.784" />
                    <polygon className="st0" points="1.143 62.173 1.139 62.49 0.347 62.481 0.352 62.159 1.143 62.173" />
                    <polygon className="st0" points="1.143 63.562 1.139 63.879 0.347 63.87 0.352 63.548 1.143 63.562" />
                    <polygon className="st0" points="1.143 67.729 1.139 68.046 0.347 68.037 0.352 67.715 1.143 67.729" />
                    <polygon className="st0" points="1.143 69.118 1.139 69.435 0.347 69.426 0.352 69.104 1.143 69.118" />
                    <polygon className="st0" points="130.115 17.708 130.111 18.025 129.319 18.016 129.324 17.693 130.115 17.708" />
                    <polygon className="st0" points="130.115 19.097 130.111 19.414 129.319 19.405 129.324 19.082 130.115 19.097" />
                    <polygon className="st0" points="130.115 20.486 130.111 20.803 129.319 20.794 129.324 20.471 130.115 20.486" />
                    <polygon className="st0" points="130.115 21.875 130.111 22.192 129.319 22.183 129.324 21.86 130.115 21.875" />
                    <polygon className="st0" points="130.115 23.264 130.111 23.581 129.319 23.572 129.324 23.249 130.115 23.264" />
                    <polygon className="st0" points="130.115 24.653 130.111 24.97 129.319 24.961 129.324 24.638 130.115 24.653" />
                    <polygon className="st0" points="130.115 16.318 130.111 16.635 129.319 16.626 129.324 16.304 130.115 16.318" />
                    <polygon className="st0" points="130.115 14.928 130.111 15.245 129.319 15.236 129.324 14.914 130.115 14.928" />
                    <polygon className="st0" points="130.115 13.539 130.111 13.855 129.319 13.846 129.324 13.524 130.115 13.539" />
                    <polygon className="st0" points="130.115 12.149 130.111 12.466 129.319 12.456 129.324 12.134 130.115 12.149" />
                    <polygon className="st0" points="130.115 10.759 130.111 11.076 129.319 11.066 129.324 10.744 130.115 10.759" />
                    <polygon className="st0" points="130.115 9.369 130.111 9.686 129.319 9.676 129.324 9.354 130.115 9.369" />
                    <polygon className="st0" points="130.115 7.979 130.111 8.296 129.319 8.286 129.324 7.964 130.115 7.979" />
                    <polygon className="st0" points="130.115 6.589 130.111 6.906 129.319 6.897 129.324 6.574 130.115 6.589" />
                    <polygon className="st0" points="1.143 5.201 1.139 5.518 0.347 5.509 0.352 5.186 1.143 5.201" />
                    <polygon className="st0" points="1.143 3.811 1.139 4.128 0.347 4.119 0.352 3.796 1.143 3.811" />
                    <polygon className="st0" points="130.115 5.201 130.111 5.518 129.319 5.509 129.324 5.186 130.115 5.201" />
                    <polygon className="st0" points="130.115 3.811 130.111 4.128 129.319 4.119 129.324 3.796 130.115 3.811" />
                    <polygon className="st0" points="130.115 2.421 130.111 2.738 129.319 2.729 129.324 2.406 130.115 2.421" />
                    <polygon className="st0" points="130.115 0.894 130.111 1.211 129.319 1.201 129.324 0.879 130.115 0.894" />
                    <polygon className="st0" points="130.115 52.45 130.111 52.767 129.319 52.758 129.324 52.436 130.115 52.45" />
                    <polygon className="st0" points="130.115 51.061 130.111 51.378 129.319 51.369 129.324 51.047 130.115 51.061" />
                    <polygon className="st0" points="130.115 49.672 130.111 49.989 129.319 49.98 129.324 49.658 130.115 49.672" />
                    <polygon className="st0" points="130.115 48.283 130.111 48.6 129.319 48.591 129.324 48.269 130.115 48.283" />
                    <polygon className="st0" points="130.115 46.894 130.111 47.211 129.319 47.202 129.324 46.88 130.115 46.894" />
                    <polygon className="st0" points="130.115 45.505 130.111 45.822 129.319 45.813 129.324 45.491 130.115 45.505" />
                    <polygon className="st0" points="130.115 53.839 130.111 54.156 129.319 54.147 129.324 53.825 130.115 53.839" />
                    <polygon className="st0" points="130.115 55.228 130.111 55.545 129.319 55.536 129.324 55.214 130.115 55.228" />
                    <polygon className="st0" points="130.115 56.617 130.111 56.934 129.319 56.925 129.324 56.603 130.115 56.617" />
                    <polygon className="st0" points="130.115 58.006 130.111 58.323 129.319 58.314 129.324 57.992 130.115 58.006" />
                    <polygon className="st0" points="130.115 59.395 130.111 59.712 129.319 59.703 129.324 59.381 130.115 59.395" />
                    <polygon className="st0" points="130.115 60.784 130.111 61.101 129.319 61.092 129.324 60.77 130.115 60.784" />
                    <polygon className="st0" points="130.115 62.173 130.111 62.49 129.319 62.481 129.324 62.159 130.115 62.173" />
                    <polygon className="st0" points="130.115 63.562 130.111 63.879 129.319 63.87 129.324 63.548 130.115 63.562" />
                    <polygon className="st0" points="1.143 64.951 1.139 65.268 0.347 65.259 0.352 64.937 1.143 64.951" />
                    <polygon className="st0" points="1.143 66.34 1.139 66.657 0.347 66.648 0.352 66.326 1.143 66.34" />
                    <polygon className="st0" points="130.115 64.951 130.111 65.268 129.319 65.259 129.324 64.937 130.115 64.951" />
                    <polygon className="st0" points="130.115 66.34 130.111 66.657 129.319 66.648 129.324 66.326 130.115 66.34" />
                    <polygon className="st0" points="130.115 67.729 130.111 68.046 129.319 68.037 129.324 67.715 130.115 67.729" />
                    <polygon className="st0" points="130.115 69.118 130.111 69.435 129.319 69.426 129.324 69.104 130.115 69.118" />
                </g>
                <g id="Navigation">
                    <g id="Line_-_1" data-name="Line - 1">
                        <path className="st0" d="M129.722,40.235l-.088-.09.044.045-.062-.01c.017-.163.03-9.964.015-10.182l.061-.006.059-.022c0,.01.016.039.01,5.126C129.755,40.2,129.747,40.209,129.722,40.235Z" />
                    </g>
                    <g className={"level l1" + (this.props.Level === 1 ? ' active' : '')} onClick={() => toggleLevel(false)}>

                        <path className="st2" d="M129.647,30.1a1.689,1.689,0,0,1-.048-3.378,1.74,1.74,0,0,1,.748.156h0A1.686,1.686,0,0,1,129.7,30.1Z" />
                        <path className="st0" d="M129.554,27.526h.29v.468a2.622,2.622,0,0,1-.013.385c-.011.129-.025.256-.033.386h-.19c-.007-.13-.022-.257-.033-.386a3.348,3.348,0,0,1-.021-.385Zm0,1.671V28.9h.289V29.2Z" />
                    </g>
                    <g className={"rect level" + (this.props.Level === 2 ? ' active' : '')} onClick={() => toggleLevel(true)}>
                        <path className="st1" d="M129.662,43.5575a1.6892,1.6892,0,0,1-.048-3.378,1.74,1.74,0,0,1,.748.156h0a1.686,1.686,0,0,1-.647,3.222Z" />
                        <path className="st0" d="M129.54,42.3a.514.514,0,0,1,.176-.459c.031-.033.057-.067.087-.1a.426.426,0,0,0,.122-.3c0-.132-.06-.276-.225-.276-.234,0-.276.189-.274.37h-.194c-.011-.27.114-.526.442-.526a.41.41,0,0,1,.454.419c0,.3-.176.38-.343.6a.33.33,0,0,0-.036.076.74.74,0,0,0-.011.189Zm0,.384v-.223h.2v.223Z" />
                    </g>
                </g>
            </svg>
        );
    }
}