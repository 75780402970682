import { searchConstants } from '../_constants';

const initialState = {
    SearchingTopics: false,
    SearchingBites: false    
}

export function search(state = initialState, action) {
    switch (action.type) {

        case searchConstants.SEARCH_BITE_REQUEST:
            return {
                ...state,
                SearchingBites: true  
            };
        case searchConstants.SEARCH_BITE_SUCCESS:
            return {
                ...state,
                SearchBites: action.result.data,
                SearchingBites: false  
            };
        case searchConstants.SEARCH_BITE_FAILURE:
            return {
                ...state,
                SearchError: action.error,
                SearchingBites: false  
            };

        case searchConstants.SEARCH_TOPIC_REQUEST:
            return {
                ...state,
                SearchingTopics: true
            };
        case searchConstants.SEARCH_TOPIC_SUCCESS:
            return {
                ...state,
                SearchTopics: action.result.data,
                SearchingTopics: false
            };
        case searchConstants.SEARCH_TOPIC_FAILURE:
            return {
                ...state,
                SearchError: action.error,
                SearchingTopics: false
            };
        default:
            return state
    }
}

export default search