import {authHeader} from '../_helpers';

const api = process.env.REACT_APP_URL_API;

export const modelService = {
    getModels,
    getModelTree,
    getTopic,
    getContainer
};

async function getModels(selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };
    
    const response = await fetch(api + `/models?culture=${selectedLanguage}`, requestOptions);
    return handleResponse(response);
}

async function getModelTree(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };
    
    const response = await fetch(api + `/models/tree?Id=` + id + `&culture=${selectedLanguage}`, requestOptions);
    return handleResponse(response);
}

async function getTopic(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/topics?Id=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(topic => {
            return topic;
        });
}

async function getContainer(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/containers?Id=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(container => {
            return container;
        }); 
}

//response handler
async function handleResponse(response) {
    const text = await response.text();
    const data = text && JSON.parse(text);
    if ((response.success !== undefined && !response.success) || (response.ok !== undefined && !response.ok)) {
        throw (data && data.message) || response.statusText;
    }
    
    return data;
}