import React, { Component } from 'react';
import { connect } from 'react-redux';
// components
import { ModelCarousel } from './ModelCarousel/ModelCarousel';
import { Highlights } from './Highlights/Highlights';
import { TilesFavorites } from './../../../shared/Tiles/TilesFavorites';
import { TilesLikes } from './../../../shared/Tiles/TilesLikes';
import { KnowledgeExplorer } from './KnowledgeExplorer/KnowledgeExplorer';
//styles
import "./Home.scss";
import {layoutConstants} from "../../../../store/_constants";

class Home extends Component {
    render() {
        const isGermanLng = this.props.SelectedLanguage === layoutConstants.GermanLanguage;
        const KnowledgeExplorerTitle = isGermanLng ? 'WISSEN ERFORSCHEN' : 'EXPLORE KNOWLEDGE';
        const FavoritesTitle = isGermanLng ? 'MEINE FAVOURITEN' : 'MY FAVOURITES';
        const LikesTitle = isGermanLng ? 'BELIEBT AUF HOLOS' : 'POPULAR ON HOLOS';

        return (
            <div className={"alias-home" + (this.props.OpenContext.Id !== -1 ? ' context-open' : '')}>
                <ModelCarousel />
                <div className="alias-home-body">
                    <KnowledgeExplorer Title={KnowledgeExplorerTitle} />
                    <Highlights />
                    <TilesFavorites Title={FavoritesTitle} />
                    <TilesLikes Title={LikesTitle} />
                </div>
                {this.props.OpenContext.Id !== -1 && <div className="context-overlay"></div>}
            </div>
        );
    }
}


function mapState(state) {
    const {
        OpenContext
    } = state.mkp;
    const {
        SelectedLanguage
    } = state.layout;

    return {
        OpenContext, SelectedLanguage
    };
}

const connectedHome = connect(mapState, null)(Home);
export { connectedHome as Home };
