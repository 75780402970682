import {authHeader} from '../_helpers';

const api = process.env.REACT_APP_URL_API;

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
}

export const getUserId = () => {
    return getUser().profile.id;
}


export const authenticationService = {
    getProfile
};

async function getProfile() {
    const headers = await authHeader();
    
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    
    try {        
        const response = await fetch(`${api}/users/me`, requestOptions);
        const profile = await handleResponse(response);
        
        const user = JSON.parse(localStorage.getItem('user')) ?? {};
        user.profile = profile.data;

        localStorage.setItem('user', JSON.stringify(user));
        
        return profile;
    } catch(e){
        console.error(e);
    }
}

function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if ((response.success !== undefined && !response.success) || (response.ok !== undefined && !response.ok)) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}