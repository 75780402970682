import React, { Component } from 'react';

export class ModelActiveSquareL1 extends Component {
    render() {
        return (
            <svg version="1.1" className="modelActive" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 87.8 84.2" xmlSpace="preserve">
                <g id="Square">
                    <g>
                        <g>

                            <rect x="41.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0273 0.5216)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,41.2 0.9,41.6 0.1,41.5 0.1,41.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,39.8 0.9,40.2 0.1,40.2 0.1,39.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,38.5 0.9,38.8 0.1,38.8 0.1,38.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,37.1 0.9,37.4 0.1,37.4 0.1,37.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,35.7 0.9,36 0.1,36 0.1,35.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,34.3 0.9,34.6 0.1,34.6 0.1,34.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,32.9 0.9,33.2 0.1,33.2 0.1,32.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,31.5 0.9,31.8 0.1,31.8 0.1,31.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,30.1 0.9,30.4 0.1,30.4 0.1,30.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,28.7 0.9,29 0.1,29 0.1,28.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,27.3 0.9,27.7 0.1,27.6 0.1,27.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,25.9 0.9,26.3 0.1,26.3 0.1,25.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,24.6 0.9,24.9 0.1,24.9 0.1,24.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,23.2 0.9,23.5 0.1,23.5 0.1,23.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,21.8 0.9,22.1 0.1,22.1 0.1,21.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,20.4 0.9,20.7 0.1,20.7 0.1,20.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,19 0.9,19.3 0.1,19.3 0.1,19 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,17.6 0.9,17.9 0.1,17.9 0.1,17.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,16.2 0.9,16.5 0.1,16.5 0.1,16.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,14.8 0.9,15.1 0.1,15.1 0.1,14.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,13.4 0.9,13.8 0.1,13.7 0.1,13.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,12 0.9,12.4 0.1,12.4 0.1,12 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,10.7 0.9,11 0.1,11 0.1,10.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,9.3 0.9,9.6 0.1,9.6 0.1,9.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,7.9 0.9,8.2 0.1,8.2 0.1,7.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,6.5 0.9,6.8 0.1,6.8 0.1,6.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,5.1 0.9,5.4 0.1,5.4 0.1,5.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,3.7 0.9,4 0.1,4 0.1,3.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,2.3 0.9,2.6 0.1,2.6 0.1,2.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,0.8 0.9,1.1 0.1,1.1 0.1,0.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,24.6 86.6,24.9 85.8,24.9 85.8,24.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,26 86.6,26.3 85.8,26.3 85.8,25.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,27.3 86.6,27.7 85.8,27.7 85.8,27.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,28.7 86.6,29.1 85.8,29 85.8,28.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,30.1 86.6,30.4 85.8,30.4 85.8,30.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,31.5 86.6,31.8 85.8,31.8 85.8,31.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,32.9 86.6,33.2 85.8,33.2 85.8,32.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,34.3 86.6,34.6 85.8,34.6 85.8,34.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,35.7 86.6,36 85.8,36 85.8,35.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,37.1 86.6,37.4 85.8,37.4 85.8,37.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,38.5 86.6,38.8 85.8,38.8 85.8,38.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,23.2 86.6,23.5 85.8,23.5 85.8,23.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,21.8 86.6,22.1 85.8,22.1 85.8,21.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,20.4 86.6,20.7 85.8,20.7 85.8,20.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,19 86.6,19.3 85.8,19.3 85.8,19 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,17.6 86.6,17.9 85.8,17.9 85.8,17.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,16.2 86.6,16.6 85.8,16.5 85.8,16.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,14.8 86.6,15.2 85.8,15.2 85.8,14.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,13.5 86.6,13.8 85.8,13.8 85.8,13.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,12.1 86.6,12.4 85.8,12.4 85.8,12.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,10.7 86.6,11 85.8,11 85.8,10.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,9.3 86.6,9.6 85.8,9.6 85.8,9.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,7.9 86.6,8.2 85.8,8.2 85.8,7.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,6.5 86.6,6.8 85.8,6.8 85.8,6.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,5.1 86.6,5.4 85.8,5.4 85.8,5.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,3.7 86.6,4 85.8,4 85.8,3.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,2.3 86.6,2.7 85.8,2.7 85.8,2.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,0.8 86.6,1.1 85.8,1.1 85.8,0.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="41.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 40.9741 42.3391)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="43.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0272 0.5387)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="42.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 42.3438 43.728)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="44.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0271 0.5557)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="44.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 43.7136 45.1168)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="46" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.027 0.5728)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="45.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 45.0833 46.5057)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="47.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0269 0.5898)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="47.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 46.4531 47.8946)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="48.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0268 0.6069)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="48.5" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 47.8228 49.2834)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="50.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0267 0.624)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="49.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 49.1926 50.6723)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="51.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0266 0.641)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="51.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 50.5624 52.0612)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="52.9" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0265 0.6581)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="52.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 51.9321 53.45)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="54.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0264 0.6751)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="54.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 53.3019 54.8389)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="55.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0263 0.6922)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="55.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 54.6716 56.2278)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="57.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0262 0.7093)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="56.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 56.0414 57.6166)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="58.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0261 0.7263)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="58.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 57.4111 59.0055)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="59.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.026 0.7434)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="59.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 58.7809 60.3944)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="61.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0259 0.7605)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="61" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 60.1506 61.7832)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="62.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0258 0.7775)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="62.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 61.5204 63.1721)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="64" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0256 0.7946)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="63.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 62.8901 64.561)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="65.4" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0255 0.8116)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="65.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 64.2599 65.9498)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="66.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0254 0.8287)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="66.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 65.6296 67.3387)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="68.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0253 0.8458)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="67.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 66.9994 68.7276)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="69.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0252 0.8628)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="69.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 68.3691 70.1164)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="71" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0251 0.8799)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="70.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 69.7389 71.5053)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="72.4" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.025 0.8969)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="72.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 71.1086 72.8942)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="73.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0249 0.914)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="73.5" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 72.4784 74.283)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="75.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0248 0.9311)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="74.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 73.8482 75.6719)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="76.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0247 0.9481)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="76.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 75.2179 77.0608)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="77.9" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0246 0.9652)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="77.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 76.5877 78.4496)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="79.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0245 0.9822)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="79.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 77.9574 79.8385)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="80.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0244 0.9993)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="80.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 79.3272 81.2274)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="82.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0243 1.0164)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="81.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 80.6969 82.6162)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="83.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0242 1.0334)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="83.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 82.0667 84.0051)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="84.9" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0241 1.0505)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="84.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 83.4364 85.394)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="40.4" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0274 0.5045)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="40.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 39.6043 40.9502)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="39" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0275 0.4875)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="38.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 38.2346 39.5614)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="37.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0276 0.4704)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="37.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 36.8648 38.1725)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="36.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0277 0.4534)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="36" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 35.4951 36.7836)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="34.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0278 0.4363)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="34.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 34.1253 35.3948)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="33.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.028 0.4192)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="33.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 32.7556 34.0059)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="32.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0281 0.4022)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="31.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 31.3858 32.617)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="30.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0282 0.3851)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="30.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 30.0161 31.2282)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="29.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0283 0.3681)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="29.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 28.6463 29.8393)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="27.9" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0284 0.351)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="27.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 27.2766 28.4504)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="26.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0285 0.3339)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="26.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 25.9068 27.0616)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="25.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0286 0.3169)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="24.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 24.5371 25.6727)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="23.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0287 0.2998)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="23.5" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 23.1673 24.2838)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="22.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0288 0.2828)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="22.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 21.7975 22.895)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="21" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0289 0.2657)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="20.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 20.4278 21.5061)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="19.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.029 0.2486)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="19.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 19.058 20.1172)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="18.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0291 0.2316)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="17.9" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 17.6883 18.7284)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="16.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0292 0.2145)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="16.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 16.3185 17.3395)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="15.4" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0293 0.1975)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="15.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 14.9488 15.9506)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="14" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0294 0.1804)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="13.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 13.579 14.5618)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="12.6" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0295 0.1633)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="12.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 12.2093 13.1729)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="11.2" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0296 0.1463)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="11" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 10.8395 11.784)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="9.8" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0297 0.1292)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="9.6" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 9.4698 10.3952)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="8.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0298 0.1122)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="8.2" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 8.1 9.0063)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="7.1" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0299 9.509471e-02)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="6.8" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 6.7303 7.6174)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="5.7" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.03 7.803427e-02)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="5.4" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 5.3605 6.2286)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="4.3" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0302 6.097382e-02)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="4.1" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 3.9908 4.8397)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="2.9" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0303 4.391338e-02)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="2.7" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 2.621 3.4508)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="1.5" y="83.5" transform="matrix(0.9999 -1.228254e-02 1.228254e-02 0.9999 -1.0304 2.685293e-02)" className="st0" width="0.3" height="0.8" />
                        </g>
                    </g>
                    <g>
                        <g>

                            <rect x="1.3" y="0.2" transform="matrix(1.385694e-02 -0.9999 0.9999 1.385694e-02 1.2513 2.062)" className="st0" width="0.8" height="0.3" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="41.5" x2="0.5" y2="41.5" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,42.6 0.9,42.9 0.1,42.9 0.1,42.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="42.9" x2="0.5" y2="42.9" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,44 0.9,44.3 0.1,44.3 0.1,44 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="44.3" x2="0.5" y2="44.3" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,45.4 0.9,45.7 0.1,45.7 0.1,45.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="45.7" x2="0.5" y2="45.7" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,46.8 0.9,47.1 0.1,47.1 0.1,46.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="47" x2="0.5" y2="47" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,48.2 0.9,48.5 0.1,48.5 0.1,48.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="48.4" x2="0.5" y2="48.4" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,49.6 0.9,49.9 0.1,49.9 0.1,49.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="49.8" x2="0.5" y2="49.8" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,51 0.9,51.3 0.1,51.3 0.1,50.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="51.2" x2="0.5" y2="51.2" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,52.3 0.9,52.7 0.1,52.7 0.1,52.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="52.6" x2="0.5" y2="52.6" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,53.7 0.9,54.1 0.1,54 0.1,53.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="54" x2="0.5" y2="54" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,55.1 0.9,55.4 0.1,55.4 0.1,55.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="55.4" x2="0.5" y2="55.4" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,56.5 0.9,56.8 0.1,56.8 0.1,56.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="56.8" x2="0.5" y2="56.8" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,57.9 0.9,58.2 0.1,58.2 0.1,57.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,57.9 86.6,58.2 85.8,58.2 85.8,57.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,56.5 86.6,56.8 85.8,56.8 85.8,56.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,55.1 86.6,55.4 85.8,55.4 85.8,55.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,53.7 86.6,54.1 85.8,54 85.8,53.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,52.3 86.6,52.7 85.8,52.7 85.8,52.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,51 86.6,51.3 85.8,51.3 85.8,50.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,49.6 86.6,49.9 85.8,49.9 85.8,49.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,48.2 86.6,48.5 85.8,48.5 85.8,48.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,46.8 86.6,47.1 85.8,47.1 85.8,46.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,45.4 86.6,45.7 85.8,45.7 85.8,45.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,44 86.6,44.3 85.8,44.3 85.8,44 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,42.6 86.6,42.9 85.8,42.9 85.8,42.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,41.2 86.6,41.6 85.8,41.5 85.8,41.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,39.8 86.6,40.2 85.8,40.2 85.8,39.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="58.2" x2="0.5" y2="58.2" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,59.3 0.9,59.6 0.1,59.6 0.1,59.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,59.3 86.6,59.6 85.8,59.6 85.8,59.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="59.5" x2="0.5" y2="59.5" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,60.7 0.9,61 0.1,61 0.1,60.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,60.7 86.6,61 85.8,61 85.8,60.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="60.9" x2="0.5" y2="60.9" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,62.1 0.9,62.4 0.1,62.4 0.1,62.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,62.1 86.6,62.4 85.8,62.4 85.8,62.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="62.3" x2="0.5" y2="62.3" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,63.5 0.9,63.8 0.1,63.8 0.1,63.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,63.5 86.6,63.8 85.8,63.8 85.8,63.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="63.7" x2="0.5" y2="63.7" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,64.8 0.9,65.2 0.1,65.2 0.1,64.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,64.8 86.6,65.2 85.8,65.2 85.8,64.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="65.1" x2="0.5" y2="65.1" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,66.2 0.9,66.6 0.1,66.5 0.1,66.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,66.2 86.6,66.6 85.8,66.5 85.8,66.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="66.5" x2="0.5" y2="66.5" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,67.6 0.9,67.9 0.1,67.9 0.1,67.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,67.6 86.6,67.9 85.8,67.9 85.8,67.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="67.9" x2="0.5" y2="67.9" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,69 0.9,69.3 0.1,69.3 0.1,69 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,69 86.6,69.3 85.8,69.3 85.8,69 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="69.3" x2="0.5" y2="69.3" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,70.4 0.9,70.7 0.1,70.7 0.1,70.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,70.4 86.6,70.7 85.8,70.7 85.8,70.4 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="70.7" x2="0.5" y2="70.7" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,71.8 0.9,72.1 0.1,72.1 0.1,71.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,71.8 86.6,72.1 85.8,72.1 85.8,71.8 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="72.1" x2="0.5" y2="72.1" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,73.2 0.9,73.5 0.1,73.5 0.1,73.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,73.2 86.6,73.5 85.8,73.5 85.8,73.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="73.4" x2="0.5" y2="73.4" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,74.6 0.9,74.9 0.1,74.9 0.1,74.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,74.6 86.6,74.9 85.8,74.9 85.8,74.6 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="74.8" x2="0.5" y2="74.8" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,76 0.9,76.3 0.1,76.3 0.1,75.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,76 86.6,76.3 85.8,76.3 85.8,75.9 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="76.2" x2="0.5" y2="76.2" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,77.3 0.9,77.7 0.1,77.7 0.1,77.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,77.3 86.6,77.7 85.8,77.7 85.8,77.3 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="77.6" x2="0.5" y2="77.6" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,78.7 0.9,79.1 0.1,79 0.1,78.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,78.7 86.6,79.1 85.8,79 85.8,78.7 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="79" x2="0.5" y2="79" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,80.1 0.9,80.4 0.1,80.4 0.1,80.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,80.1 86.6,80.4 85.8,80.4 85.8,80.1 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="80.4" x2="0.5" y2="80.4" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,81.5 0.9,81.8 0.1,81.8 0.1,81.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,81.5 86.6,81.8 85.8,81.8 85.8,81.5 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="81.8" x2="0.5" y2="81.8" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="0.9,83.2 0.9,83.5 0.1,83.5 0.1,83.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <polygon className="st0" points="86.6,83.2 86.6,83.5 85.8,83.5 85.8,83.2 				" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <line className="st0" x1="0.5" y1="83.2" x2="0.5" y2="83.2" />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

