import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions } from './../../../../../../store/_actions';
//components
import { ModelActiveL2 as ModelActive } from './ModelActiveL2';
//icons
import { ReactComponent as FullscreenClose } from './../../../../../../Assets/images/icons/icon_fullscreen_2.svg';
import { ReactComponent as Zoom } from './../../../../../../Assets/images/icons/icon_zoom.svg';
import { ReactComponent as ArrowDown } from './../../../../../../Assets/images/icons/icon_arrow_down_1.svg';

class Model3 extends Component {
    constructor(props) {
        super(props);

        this.activeModel = this.props.ModelSet.items[2];
        this.ModelId = this.activeModel.id;
        this.Mapping = this.activeModel.mapping;
        this.Updating = false;

        // initial state
        this.state = {
            activePie: -1,
            hoverPie: -1,
            allTop: false,
            allBottom: false,
            selectTop: false,
            selectBottom: false,
            changed: false,
            switch: false,
            Zoom: false,
            showIndicator: false,
            actionFromPageExecuted: false,
            callFromPage: true
        }

        // event handlers
        this.hoverPie = this.hoverPie.bind(this);
        this.hoverTop = this.hoverTop.bind(this);
        this.hoverBottom = this.hoverBottom.bind(this);
        this.setStyles = this.setStyles.bind(this);
        this.selectPie = this.selectPie.bind(this);
        this.switchLevel = this.switchLevel.bind(this);
        this.ToggleFullsreen = this.ToggleFullsreen.bind(this);
        this.setIndicator = this.setIndicator.bind(this);
    }

    /************************************
    * LIFECYCLE
    ************************************/

    componentDidMount() {
        if (this.props.FromContext) {
            //ste intial path coming from this.props.Tile
            if (this.props.Tile.type === "Bite") {
                this.setSection(this.props.Tile.id, this.props.Tile.Topic.id, this.props.Tile.Container.id);
            } else if (this.props.Tile.type === "Topic") {
                this.setSection(-1, this.props.Tile.id, this.props.Tile.Container.id);
            }
        }

        if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted) {
            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
            if (activeModelSet.containers) {
                //ste intial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }
        }
    }

    componentDidUpdate(prevProps) {
        //if model is selected
        if (this.props.SelectedPath.Model === this.ModelId) {



            //index change incoming
            if (this.props.SelectedPath.LastUpdate === "Index" && !this.Updating) {
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

            //when coming from topic/bite page
            if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted && this.state.callFromPage) {
                //ste intial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

        } else if (this.props.SelectedPath.LastUpdate === "Model" && !this.Updating) {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
   * SELECTED PATH UPDATE
   ***********************************/

    UpdateSelectedPath(container, topic, bite, lastUpdate, model) {
        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);

        if (model) {
            path.Model = model;
        }
        if (container) {
            path.Container = container;
        }
        if (topic) {
            path.Topic = topic;
        }
        if (bite) {
            path.Bite = bite;
        }

        if (lastUpdate != null) {
            path.LastUpdate = lastUpdate;
        }
        this.props.setSelectedPath(path);
        setTimeout(function () {
            this.Updating = false;
        }.bind(this), 300);
    }

    //get parent container
    getTopicParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            if (activeModelSet.containers[i].topics.filter(c => c.id === id).length > 0) {
                return activeModelSet.containers[i].topics.filter(c => c.id === id)[0].containerId;
            }
        }

        return -1;
    }

    //get parent topic
    getBiteParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                if (activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id).length > 0) {
                    return activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id)[0].topicId;
                }
            }
        }

        return -1;
    }

    /************************************
    * SECTIONS HOVER & STYLES
    ***********************************/

    hoverPie(index) {
        if (!this.props.FromContext) {
            this.setState({ hoverPie: index, changed: true });
        }
    }

    hoverTop(val) {
        if (!this.props.FromContext) {
            this.setState({ allTop: val });
        }
    }

    hoverBottom(val) {
        if (!this.props.FromContext) {
            this.setState({ allBottom: val });
        }
    }

    setStyles(index) {
        var styles = "";

        //active
        if (this.state.selectTop && index > 1 && index < 8) {
            styles += " active";
        }
        else if (this.state.selectBottom && index > 7 && index < 15) {
            styles += " active";
        }
        else if (this.state.activePie === index) {
            styles += " active";
        }

        //hover
        if (this.state.hoverPie === index || (this.state.allBottom && index > 7 && index < 15) ||
            (this.state.allTop && index > 1 && index < 8)) {
            styles += " hover";
        }

        return styles;
    }

    /************************************
    * SETTING SELECTED AREA
    ************************************/

    selectPie(e, index) {
        if (this.props.SelectedPath.Model === this.ModelId || this.props.FromContext) {

            var val = true;
            var i = index;
            //console.log("index: " + index);
            if (index === "top" || index === 1) {

                if (this.state.selectTop) {
                    val = false;
                    i = 0;
                }

                this.setState({ selectTop: val, changed: val, activePie: -1, selectBottom: false });
                this.setIndex(i, e);
                this.setIndicator();

            } else if (index === "bottom" || index === 2) {
                if (this.state.selectBottom) {
                    i = 0;
                    val = false;
                }

                this.setState({ selectTop: false, changed: val, activePie: -1, selectBottom: val });
                this.setIndex(i, e);
                this.setIndicator();

            } else if (index === this.state.activePie) {
                this.setIndex(-1, e);
            } else {

                this.setState({
                    activePie: index,
                    changed: true,
                    selectTop: false,
                    selectBottom: false
                });

                this.setIndex(index, e);
                this.setIndicator();
            }

        }
    }

    setIndex(index, e) {
        //console.log("index is : " + index);
        this.setState({ callFromPage: e === null }, function () {
            var container = -1, topic = -1, bite = -1;

            if (index === -1) {
                this.setState({
                    activePie: -1,
                    changed: false,
                    selectTop: false,
                    selectBottom: false
                });
            } else {
                var i = index;
                if (index === "top") {
                    i = 1;
                } else if (index === "bottom") {
                    i = 2;
                }

                //area got selected
                var map = this.Mapping.filter(map => map.id === i);

                if (map.length > 0) {
                    map = map[0];
                    if (map.type === "Container") {
                        container = map.resourceId;
                    } else if (map.type === "Topic") {
                        topic = map.resourceId;

                        //get parent container
                        container = this.getTopicParent(topic);

                    } else if (map.type === "Bite") {
                        bite = map.resourceId;

                        //get parent topic
                        var topicId = this.getBiteParent(map.resourceId);
                        topic = topicId;

                        //get parent container
                        container = this.getTopicParent(topicId);
                    }
                }

            }

            if (this.props.SelectedPath.LastUpdate === "Index") {
                this.UpdateSelectedPath(null, null, null, "", null);

            } else if (!this.state.actionFromPageExecuted && this.props.SelectedPath.LastUpdate === "Page") {
                this.setState({ actionFromPageExecuted: true });

            } else if (!this.state.callFromPage) {
                this.UpdateSelectedPath(container, topic, bite, "Carousel", null);
            }

            //id 16 has to switch level
            if (index === 16) {
                this.switchLevel();
            }
        });
    }

    setSection(bite, topic, container) {
        this.Updating = true;
        var section = [];

        if (bite !== -1) {
            section = this.Mapping.filter(map => map.resourceId === bite && map.type === "Bite");
        }

        //check if bite section was found. If not search topic
        if (section.length === 0) {
            if (topic !== -1) {
                section = this.Mapping.filter(map => map.resourceId === topic && map.type === "Topic");
            }
        }

        //check if topic section was found. If not search Container
        if (section.length === 0) {
            if (container !== -1) {
                section = this.Mapping.filter(map => map.resourceId === container && map.type === "Container");
            }
        }

        //update model
        if (section.length > 0) {
            //set section
            if (section[0].id !== this.state.activePie) {
                this.selectPie(null, section[0].id);
            } else {
                setTimeout(function () {
                    this.Updating = false;
                }.bind(this), 300);
            }
        } else {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
    * LEVEL SWITCH
    ************************************/

    switchLevel(toggleLevel) {
        if (this.props.SelectedPath.Model === this.ModelId) {
            this.setState({ switch: toggleLevel});
        }
    }

    /************************************
    * TOGGLE FULLSCREEN
    ************************************/

    ToggleFullsreen() {
        this.setState({ Zoom: !this.state.Zoom });
        this.props.handleZoom();
    }

    /************************************
    * SHOW "GO TO" INDICATOR
    ************************************/

    setIndicator() {
        if (!this.state.showIndicator && this.state.Zoom) {
            this.setState({ showIndicator: true }, function () {
                setTimeout(function () {
                    this.setState({ showIndicator: false })
                }.bind(this), 3000);
            });
        }
    }

    render() {
        const { SelectedLanguage } = this.props

        return (
            <div className={"slide-container" + (this.state.Zoom ? ' magnify' : '')}>
                <ModelActive Level={this.state.switch ? 2 : 1} toggleLevel={this.switchLevel} />
                <svg version="1.1" className={"model model3" + (this.state.switch ? ' switch' : '')} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 850.39 850.39" xmlSpace="preserve">
                    <g className="lv1">
                        <g id="Segment_-_12" className={"model-option" + (this.state.changed ? this.setStyles(14) : '')}>
                            <path id="Circle_-_2-2_11_" className="st0" d="M560.92,490.62l157.44,75.89c20.62-43.33,32.46-91.44,32.36-141.19l-174.88-0.07 C575.94,448.39,570.46,470.43,560.92,490.62z" />
                            <path id="Circle_-_2-1" className="st1" d="M560.92,490.62l157.44,75.89c20.62-43.33,32.46-91.44,32.36-141.19l-174.88-0.07 C575.94,448.39,570.46,470.43,560.92,490.62z" />
                            <path id="Circle_-_1-2_1_" className="st0" d="M718.36,566.51l89.4,43.1c27.08-56.35,42.22-119.17,42.13-184.25l-99.17-0.04 C750.79,475.16,738.96,523.22,718.36,566.51z" />
                            <path id="Circle_-_1-1_2_" className="st2" d="M718.36,566.51l89.4,43.1c27.08-56.35,42.22-119.17,42.13-184.25l-99.17-0.04 C750.79,475.16,738.96,523.22,718.36,566.51z" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M766.9347,547.1763l-.2427-.0926a8.845,8.845,0,0,0-.8932-.297.9788.9788,0,0,0-1.3213.5977,1.0331,1.0331,0,0,0-.0054.9057,1.0523,1.0523,0,0,0,.3118.3583,2.4185,2.4185,0,0,0,.519.2663c.8024.31,1.2745.1469,2.4766-.6987.4958-.3325.7434-.4993,1.2387-.834a7.5939,7.5939,0,0,1,1.06-.71,3.9867,3.9867,0,0,1,3.75-.2553,4.5371,4.5371,0,0,1,2.28,1.6672,2.7117,2.7117,0,0,1,.4034,1.257,5.7049,5.7049,0,0,1-.4429,2.6251,4.1759,4.1759,0,0,1-2.4151,2.5726,6.0791,6.0791,0,0,1-3.5814-.6778c.4153-1.0539.6212-1.5813,1.03-2.6376.3734.1445.7709.2931,1.1457.4218.73.25,1.06.2547,1.41.0141a1.0971,1.0971,0,0,0,.4716-.614,1.1414,1.1414,0,0,0-.2788-1.3438,2.5283,2.5283,0,0,0-.7858-.4088,3.1747,3.1747,0,0,0-.8962-.2318c-.5377-.05-.9828.22-1.8205.8394-.5092.3654-.7639.5475-1.2738.9106-.3169.2065-.64.4305-.9674.6094a3.6888,3.6888,0,0,1-3.4433.1787,5.2614,5.2614,0,0,1-1.0532-.5451,3.29,3.29,0,0,1-1.4416-2.4671,5.0291,5.0291,0,0,1,.432-2.1165,3.8239,3.8239,0,0,1,1.7973-2.1983,2.7085,2.7085,0,0,1,.9143-.215,6.1089,6.1089,0,0,1,2.59.5592C767.518,545.6409,767.3246,546.1531,766.9347,547.1763Z" />
                                    <path className="st4" d="M779.0443,543.412l-4.3832-1.5855c-4.0194-.0146-6.0268-.036-10.0363-.1067.4238-1.1453.6338-1.7185,1.05-2.8666,1.95.16,2.9264.2373,4.879.3866a12.628,12.628,0,0,1,3.089.6324,14.026,14.026,0,0,1-2.5872-1.3789c-1.6841-1.1618-2.5274-1.74-4.2168-2.8914.415-1.1735.62-1.761,1.0272-2.9373,3.1309,2.5269,4.6891,3.8033,7.7908,6.3817l4.3934,1.5571C779.6514,541.7281,779.45,542.29,779.0443,543.412Z" />
                                    <path className="st4" d="M773.7772,527.9172l-.2466-.0814a8.8424,8.8424,0,0,0-.9058-.2562.9783.9783,0,0,0-1.2925.6562,1.0321,1.0321,0,0,0,.036.9044,1.0539,1.0539,0,0,0,.3278.3435,2.4256,2.4256,0,0,0,.53.2424c.8158.2735,1.28.0891,2.442-.8092.48-.3543.72-.5319,1.1992-.8884a7.5741,7.5741,0,0,1,1.0266-.7569,3.9879,3.9879,0,0,1,3.7336-.4242,4.5392,4.5392,0,0,1,2.3536,1.5614,2.7106,2.7106,0,0,1,.46,1.2365,5.694,5.694,0,0,1-.3226,2.64,4.1692,4.1692,0,0,1-2.2946,2.6767,6.085,6.085,0,0,1-3.6084-.515c.3666-1.0706.5482-1.6064.9082-2.6791.38.1274.7834.2578,1.1637.3693.74.217,1.07.2064,1.4089-.0495a1.095,1.095,0,0,0,.443-.6342,1.1405,1.1405,0,0,0-.34-1.3288,2.5314,2.5314,0,0,0-.8036-.3726,3.1805,3.1805,0,0,0-.9057-.1909c-.5395-.0261-.9718.2636-1.78.92-.4919.3877-.7381.581-1.2308.9664-.307.22-.6193.4585-.9384.6519a3.69,3.69,0,0,1-3.4311.3336,5.2707,5.2707,0,0,1-1.077-.4965,3.29,3.29,0,0,1-1.5527-2.3975,5.0208,5.0208,0,0,1,.3348-2.1321,3.8187,3.8187,0,0,1,1.6948-2.2754,2.7082,2.7082,0,0,1,.9035-.2559,6.1148,6.1148,0,0,1,2.6122.4413C774.29,526.3583,774.12,526.8783,773.7772,527.9172Z" />
                                    <path className="st4" d="M785.3991,524.5811c-4.4664-1.4006-6.7-2.1009-11.166-3.5013-.28.8932-.4213,1.34-.7061,2.2313l-2.3062-.7364c.9157-2.8678,1.3615-4.3054,2.2285-7.1879l2.3183.6973c-.2674.8888-.4022,1.333-.6742,2.22,4.4754,1.3714,6.7131,2.0572,11.1885,3.4288C785.9325,522.8735,785.7559,523.4431,785.3991,524.5811Z" />
                                    <path className="st4" d="M787.5,517.6916c-5.4162-1.6056-8.1242-2.4085-13.54-4.014.76-2.5623,1.13-3.8464,1.8508-6.42l2.4656.6908c-.4117,1.4691-.6206,2.2028-1.0448,3.6685l2.9212.8453c.3818-1.3193.57-1.98.942-3.3018l2.4066.6766c-.3738,1.33-.5632,1.9936-.9472,3.32l3.3057.9566c.4327-1.495.6458-2.2434,1.0658-3.742l2.4844.6961C788.6658,513.7233,788.2839,515.048,787.5,517.6916Z" />
                                    <path className="st4" d="M792.8445,498.0833l-5.7066-1.4206c-1.1846-.2949-2.4124-.5639-3.5868-.9132-.6637-.1976-1.3346-.436-2.0043-.6441.2963.1356.6208.272.9315.3974.46.1855.9565.3861,1.4214.5845.5952.2539,1.1641.542,1.7362.81,2.6768,1.3,4.0135,1.9552,6.6835,3.2742-.2813,1.1076-.4236,1.661-.7117,2.7668-2.915-.11-4.3718-.1691-7.2839-.298-.796-.0416-1.6131-.0909-2.4161-.1767-.5229-.0558-1.0348-.1148-1.5455-.18-.3524-.0452-.7226-.087-1.0863-.1217.4505.1.889.1787,1.3354.2717.9138.1905,1.8307.3925,2.7328.622.3642.0927.7451.1828,1.1077.2727.5726.142,1.1788.3132,1.7781.4725l4.8144,1.2791c-.2892,1.0884-.4355,1.6322-.7313,2.7187-5.4552-1.468-8.1826-2.2022-13.6375-3.6712.4366-1.6029.6511-2.4053,1.0727-4.0121,3.234.16,4.852.2339,8.09.37.6888.0513,1.3663.0811,2.0433.168.4775.0613.9554.1394,1.436.2-.5764-.17-1.1109-.3866-1.6445-.5924-.4028-.1554-.8226-.3381-1.228-.5265-3.06-1.468-4.5924-2.1962-7.661-3.6412.4066-1.6107.6062-2.4171.9978-4.0317,5.4938,1.316,8.2408,1.9737,13.7348,3.2886C793.2509,496.4442,793.1165,496.9908,792.8445,498.0833Z" />
                                    <path className="st4" d="M795.5786,486.4594c-1.5841-.0627-2.376-.0952-3.96-.1627-.2057.9218-.31,1.3825-.52,2.3032,1.4605.6118,2.1905.9189,3.65,1.5351-.2768,1.2025-.4172,1.8032-.702,3.0038-5.22-2.54-7.8364-3.7921-13.0823-6.2586.32-1.3923.4776-2.0892.7866-3.4842,5.788.0653,8.6844.0791,14.4808.0688C795.9744,484.6635,795.8438,485.2625,795.5786,486.4594Zm-9.7-.53c-.7246-.0587-1.45-.1191-2.1652-.2181.6887.2172,1.3693.4753,2.049.7327,1.27.5122,1.9056.7691,3.1752,1.2844.1433-.6292.2144-.944.3555-1.5737C787.9272,486.0662,787.2444,486.0212,785.8788,485.93Z" />
                                    <path className="st4" d="M796.7923,480.813l-11.456-2.3874c-.191.9167-.2878,1.3749-.4836,2.2905l-2.3673-.5063c.63-2.9444.9321-4.4195,1.512-7.3749l2.3756.4662c-.1788.9113-.2694,1.3668-.453,2.2771l11.4714,2.313C797.1555,479.0608,797.0357,479.6451,796.7923,480.813Z" />
                                    <path className="st4" d="M798.2074,473.746l-13.87-2.6642c.2181-1.1355.3253-1.7036.5361-2.8407l13.8866,2.5742C798.5431,471.9884,798.4324,472.5745,798.2074,473.746Z" />
                                    <path className="st4" d="M790.5955,466.1823c-.37-.0862-.7524-.1651-1.0956-.2366-.2851-.0593-.607-.1283-.8986-.2013a3.7389,3.7389,0,0,1-2.2922-1.5116,3.1088,3.1088,0,0,1-.3195-.73,5.3989,5.3989,0,0,1,.1331-3.1571,4.2662,4.2662,0,0,1,1.3913-1.931c.9346-.642,2.0409-.5915,4.2337-.2408-.1771,1.1077-.2674,1.6612-.4514,2.7677l-.9084-.151a3.48,3.48,0,0,0-1.6709-.073,1.102,1.102,0,0,0-.693.8661,1.0308,1.0308,0,0,0,.1085.674c.2874.5807.9077.6265,1.9888.832.2516.0479.5323.0914.7857.156l3.7259.6394c.5358.0706,1.065.18,1.5811.25a4.5117,4.5117,0,0,0,1.0265.0516,1.0972,1.0972,0,0,0,.383-2.0257,8.6926,8.6926,0,0,0-2.0846-.5139l-.4538-.0754c.1857-1.1159.2767-1.6741.4553-2.7913,1.8991.2834,2.7982.4883,3.572,1.0941,1.5509,1.1917,1.1836,3.4051,1.1041,3.8752a4.5039,4.5039,0,0,1-1.5033,2.83,2.4311,2.4311,0,0,1-.8856.41,9.5717,9.5717,0,0,1-3.6865-.1751Z" />
                                    <path className="st4" d="M791.6937,571.6125c-1.5542-.325-2.331-.4885-3.8839-.818-.3814.9155-.5733,1.3729-.96,2.2866,1.3263.87,1.9888,1.3055,3.3128,2.1792-.5062,1.1926-.7613,1.7882-1.2757,2.9777-4.6717-3.47-7.0175-5.1879-11.7285-8.5892.5867-1.3829.8773-2.0755,1.4527-3.4628,5.7114,1.017,8.5725,1.5073,14.3047,2.4513C792.4312,569.8289,792.1868,570.4239,791.6937,571.6125Zm-9.49-2.1383c-.7052-.1793-1.4107-.3605-2.0992-.58.6392.3367,1.2623.7142,1.8847,1.0909,1.1576.7361,1.7359,1.1049,2.8919,1.8439.2635-.6247.3947-.9373.6559-1.5628C784.2034,569.951,783.5367,569.7926,782.2039,569.4742Z" />
                                    <path className="st4" d="M780.7035,561.1646c.6691-1.6675,1-2.5028,1.6524-4.1762.0688-.1764.1454-.373.241-.5623a3.2606,3.2606,0,0,1,1.2429-1.442c.7883-.4887,1.7611-.5713,3.4245.063a3.933,3.933,0,0,1,1.9514,1.339,1.7421,1.7421,0,0,1,.3622,1.3991,2.3838,2.3838,0,0,1,.5766-.7186c.66-.5494,1.6725-.6237,3.2241-.0332,1.4777.5624,2.1445,1.1714,2.4428,1.9035a3.9556,3.9556,0,0,1-.182,2.86c-.7218,1.854-1.0875,2.7794-1.8286,4.6264Zm6.7643-1.2647c.2108-.5375.4164-1.1219-.0473-1.53a5.2777,5.2777,0,0,0-1.2772-.6334,1.625,1.625,0,0,0-.717-.1419c-.64.0466-.9073.5109-1.1535,1.0289-.1237.3155-.1857.4732-.31.7885l3.2007,1.2618C787.2855,560.3644,787.3463,560.21,787.4678,559.9Zm5.6206,1.9947c.329-.73.316-1.0831.066-1.4346a2.8022,2.8022,0,0,0-1.3532-.8266,2.1865,2.1865,0,0,0-1.2385-.18c-.4227.0989-.638.3733-.9353,1.1318-.1439.3673-.216.5509-.3607.918l3.4423,1.357C792.8611,562.474,792.9371,562.2809,793.0884,561.8946Z" />
                                    <path className="st4" d="M800.09,549.9045c-1.568-.25-2.3518-.3758-3.9186-.63-.3363.9317-.5058,1.3972-.8469,2.3272,1.3668.8042,2.05,1.2074,3.4145,2.0157-.4474,1.2141-.6731,1.82-1.1288,3.0316-4.8346-3.2384-7.261-4.84-12.1314-8.0083.5186-1.4077.775-2.1127,1.2821-3.5243,5.7533.7415,8.6345,1.0939,14.4052,1.7613C800.7394,548.09,800.5242,548.695,800.09,549.9045Zm-9.5813-1.6795c-.7131-.1452-1.4265-.2922-2.1247-.4781.6548.3054,1.2954.6523,1.9353.9983,1.1919.6791,1.7874,1.0194,2.9779,1.7014.2327-.6358.3485-.954.5789-1.5906C792.5284,548.6051,791.8549,548.4789,790.5084,548.225Z" />
                                    <path className="st4" d="M804.0056,538.5625c-3.2132.1057-4.8185.15-8.0267.22-.408.0207-.7932.0293-1.1912.0259-.6886-.006-1.3732-.0618-2.06-.0929.4822.0742.9405.16,1.3877.2689.4858.1182.9673.2614,1.4672.408,2.9411.9446,4.4118,1.4166,7.3535,2.36-.39,1.15-.5871,1.7252-.9846,2.8733-5.3384-1.8479-8.0076-2.772-13.346-4.62.46-1.33.688-1.9955,1.1384-3.3286,2.9486-.0533,4.4241-.0873,7.3771-.17.5994-.0265,1.22-.0435,1.8218-.0277.5822.0154,1.19.0308,1.7879.07-.54-.087-1.0842-.1725-1.6057-.3038-.3277-.0825-.6589-.1721-.97-.2516-2.9617-.9324-4.4425-1.399-7.4037-2.3327.3645-1.1041.5451-1.6566.9028-2.7628,5.3752,1.7382,8.0628,2.6072,13.4381,4.3451C804.6625,536.572,804.4451,537.236,804.0056,538.5625Z" />
                                    <path className="st4" d="M792.83,527.2087c.4924-1.5669.735-2.3513,1.2136-3.9223a4.8991,4.8991,0,0,1,1.6663-2.6895,4.72,4.72,0,0,1,3.71-.2708c.7525.1677,1.5014.3531,2.25.5734.9986.2938,2.0041.6362,2.9886.9691a4.3712,4.3712,0,0,1,2.7046,1.7883c.7722,1.2668.5134,2.55.134,3.9311-.4709,1.5437-.71,2.3146-1.1934,3.8544C800.9143,529.7491,798.22,528.9023,792.83,527.2087Zm12.3323-.2645a1.201,1.201,0,0,0-.3579-1.5742,18.185,18.185,0,0,0-3.0846-1.1323c-.9967-.3006-1.9945-.57-2.9682-.829-.8784-.2334-1.3929-.3672-1.8083-.1027a1.6394,1.6394,0,0,0-.62.9406c-.1013.3321-.1522.4981-.2542.83l8.8338,2.7139C805.0074,527.4521,805.0592,527.2828,805.1625,526.9442Z" />
                                    <path className="st4" d="M807.9507,510.2927c2.1971.679,2.9809,1.448,3.2986,2.6763a5.5874,5.5874,0,0,1-.1046,2.8045,5.3372,5.3372,0,0,1-1.5793,2.6647,2.7487,2.7487,0,0,1-.8048.4906,4.1838,4.1838,0,0,1-1.9018.175,32.4548,32.4548,0,0,1-4.4375-1.0742c-.73-.21-1.4853-.44-2.1985-.6762a9.1808,9.1808,0,0,1-1.0916-.402,3.3141,3.3141,0,0,1-2.2339-3.3355,6.1916,6.1916,0,0,1,.2442-1.768,4.2057,4.2057,0,0,1,2.2562-2.9451,3.8519,3.8519,0,0,1,1.8859-.2377,29.1915,29.1915,0,0,1,3.5422.7709C805.8645,509.7066,806.9193,509.974,807.9507,510.2927Zm-3.8348,2.2244c-.695-.1907-1.3834-.3684-2.0455-.5612a5.8631,5.8631,0,0,0-1.0774-.2291c-.1544-.0424-1.4281-.2724-1.7266.8417a1.0785,1.0785,0,0,0,.0058.7261c.2371.6366,1.03.8743,1.5736,1.0558.6383.2132,1.3181.4138,2.0111.6082,1.0212.2864,2.0468.5478,3.0553.8122a5.1834,5.1834,0,0,0,2.1555.3135,1.2452,1.2452,0,0,0,.5877-2.12,10.6954,10.6954,0,0,0-2.7266-.95Z" />
                                    <path className="st4" d="M814.5341,502.0193c-3.1947.3584-4.7917.5287-7.9841.8518-.405.0528-.7883.0917-1.1854.12-.6869.0483-1.3738.0466-2.0608.07.4866.0361.95.0855,1.4045.1587.4936.08.9849.1844,1.4948.2911,3.0066.71,4.51,1.0642,7.5167,1.7723-.2981,1.1772-.449,1.7653-.7545,2.9406l-13.6689-3.553c.3539-1.3613.5283-2.0427.8719-3.4066,2.9353-.2854,4.4034-.4355,7.3406-.7508.5955-.0736,1.2128-.1395,1.8139-.1711.5816-.0306,1.1887-.063,1.7879-.0716-.5451-.0442-1.0945-.0865-1.6247-.1763-.3333-.0564-.67-.12-.9868-.1744-3.026-.6959-4.5389-1.0442-7.5646-1.7414.2763-1.1291.4127-1.6941.682-2.8249l13.7389,3.2719C815.0318,499.9838,814.8676,500.6627,814.5341,502.0193Z" />
                                    <path className="st4" d="M818.5132,484.4167l-5.7594-1.1891c-1.1955-.2468-2.4344-.4644-3.6206-.7687-.67-.1719-1.35-.3865-2.0273-.5694.301.1264.6306.2524.9453.3672.466.17.97.3542,1.4415.5375.6039.2345,1.1827.5063,1.7639.7571,2.7211,1.2207,4.08,1.8357,6.795,3.0752-.2477,1.172-.3734,1.7576-.6283,2.928-2.9235.0378-4.3847.0519-7.3062.07-.7989-.0016-1.6191-.01-2.4261-.0576-.5255-.0308-1.0406-.0655-1.5539-.1071-.3539-.0287-.7266-.053-1.092-.07.4544.08.8962.14,1.3461.2136.9209.1509,1.8466.314,2.7567.5064.3675.0777.7524.1519,1.1181.2268.5776.1182,1.1905.2653,1.7958.4l4.8621,1.0838c-.2568,1.1521-.387,1.7277-.6508,2.8781-5.51-1.246-8.2652-1.8691-13.7751-3.1158.39-1.7.5809-2.55.9549-4.2533,3.245-.0032,4.8681-.0107,8.1156-.0375.6915.0176,1.3713.0133,2.052.0688.48.0391.9612.0958,1.4445.1338-.5826-.1476-1.1251-.3462-1.6655-.5333-.4077-.1412-.8343-.3106-1.2461-.4863-3.11-1.3749-4.6673-2.0564-7.7851-3.4081.3582-1.7068.5334-2.5611.8757-4.2714,5.5427,1.0922,8.314,1.638,13.8569,2.7294C818.8693,482.6826,818.7518,483.2609,818.5132,484.4167Z" />
                                    <path className="st4" d="M819.8676,477.6091l-13.8711-2.6559c.5277-2.7564.7813-4.1369,1.2678-6.9017l2.5218.4437c-.2777,1.5781-.42,2.3665-.7109,3.9419l2.99.5525c.2619-1.4177.39-2.1271.6414-3.547l2.4617.4355c-.2524,1.4274-.3814,2.14-.6447,3.5657l3.3841.6252c.2967-1.6054.4416-2.4089.7245-4.0171l2.541.4471C820.6725,473.3474,820.4113,474.77,819.8676,477.6091Z" />
                                    <path className="st4" d="M822.7927,460.708c-3.1514.6377-4.7275.9476-7.88,1.55-.3989.0881-.7774.1605-1.1706.2232-.68.1084-1.3645.1669-2.0468.25.4878-.0067.954.0019,1.413.0352.4986.0361.9972.0976,1.5146.1593,3.0569.4444,4.5854.6662,7.6427,1.1089-.1944,1.2005-.2935,1.8-.4955,2.9992l-13.9268-2.346c.2339-1.3886.3482-2.0835.5717-3.4742,2.8993-.5419,4.3489-.82,7.2475-1.3925.5868-.1257,1.1961-.2456,1.7922-.33.5767-.0815,1.1787-.1671,1.7748-.2282-.5469.0037-1.0978.01-1.6339-.0334-.3369-.0271-.6783-.0606-.9982-.0875-3.0752-.4288-4.6128-.6437-7.6878-1.0739.1768-1.1507.2635-1.7263.4333-2.8782l13.9721,2.0591C823.1113,458.6336,823.0068,459.3254,822.7927,460.708Z" />
                                    <path className="st4" d="M823.944,452.8421l-11.5923-1.5992c-.1345.9747-.203,1.4619-.3425,2.4357l-2.3965-.3433c.4489-3.1325.66-4.7011,1.0561-7.8424l2.4019.3027c-.1221.9684-.1843,1.4525-.3114,2.42l11.6024,1.5238C824.1986,450.9812,824.1151,451.6017,823.944,452.8421Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" && <>
                            <g>
                                <path className="st4" d="M764.48,557.87l-0.25-0.08l-0.88-0.34l-0.91,0.11l-0.42,0.47l-0.07,0.39l0.07,0.54l0.29,0.36l0.52,0.28
			                        l0.58,0.17l0.51-0.1l1.39-0.71l1.23-0.84l1.08-0.66l1.49-0.52l1.03,0.02l1.22,0.35l1.28,0.7l0.99,1.05l0.37,1.26l-0.08,1.65
			                        l-0.37,0.95l-0.64,1.31l-0.75,0.75l-1.01,0.45l-0.92,0.11l-0.92-0.18l-1.72-0.66l1.03-2.63l1.13,0.46l0.87,0.2l0.55-0.19
			                        l0.46-0.58l0.07-0.72l-0.34-0.62l-0.78-0.43l-0.89-0.25l-0.8,0.18l-1.02,0.62l-1.27,0.89l-0.96,0.6l-1.12,0.49l-0.94,0.04
			                        l-1.38-0.39l-1.05-0.56l-0.86-0.84l-0.4-0.74l-0.17-0.95l0.06-0.88l0.39-1.28l0.92-1.54l0.91-0.69l0.91-0.18l1.33,0.17l1.24,0.46
			                        L764.48,557.87z"/>
                                <path className="st4" d="M776.38,554.86l-4.37-1.65L762,553.02l1.05-2.93l4.87,0.44l1.87,0.29l1.18,0.38l-2.55-1.43l-4.18-3l1.07-2.98
			                        l7.69,6.63l4.37,1.66L776.38,554.86z"/>
                                <path className="st4" d="M770.95,539.7l-0.25-0.07l-0.9-0.29l-0.9,0.16l-0.39,0.49l-0.05,0.4l0.1,0.53l0.31,0.35l0.53,0.25l0.59,0.14
			                        l0.5-0.13l1.36-0.78l1.18-0.91l1.04-0.72l1.46-0.59l1.02-0.03l1.24,0.28l1.31,0.63l1.04,0.99l0.44,1.24l0,1.65l-0.32,0.97
			                        l-0.57,1.34l-0.71,0.79l-0.99,0.5l-0.92,0.16l-0.93-0.13l-1.75-0.57l0.88-2.68l1.15,0.4l0.88,0.15l0.54-0.22l0.43-0.61l0.03-0.73
			                        l-0.37-0.61l-0.8-0.39l-0.9-0.21l-0.79,0.22l-0.98,0.68l-1.22,0.96l-0.93,0.65l-1.09,0.55l-0.93,0.08l-1.4-0.31l-1.08-0.51
			                        l-0.9-0.79l-0.44-0.72l-0.22-0.94l0.02-0.88l0.32-1.3l0.84-1.59l0.88-0.74l0.9-0.23l1.34,0.1l1.26,0.4L770.95,539.7z"/>
                                <path className="st4" d="M782.36,537.06l-11.15-3.61l-0.69,2.27l-2.31-0.75l2.23-7.35l2.31,0.75l-0.69,2.27l11.15,3.61L782.36,537.06z
			                        "/>
                                <path className="st4" d="M784.28,530.66l-13.54-4.06l1.84-6.57l2.45,0.74l-1.04,3.73l2.92,0.87l0.93-3.33l2.4,0.72l-0.93,3.33
			                        l3.3,0.99l1.04-3.73l2.47,0.74L784.28,530.66z"/>
                                <path className="st4" d="M789.07,512.37l-5.64-1.51l-3.61-0.99l-1.96-0.67l0.89,0.39l1.42,0.66l1.7,0.79l6.67,3.44l-0.69,2.68
			                        l-7.25-0.26l-2.41-0.24l-1.53-0.13l-1.07-0.16l1.34,0.32l2.73,0.56l7.7,2.1l-0.68,2.66l-13.64-3.57l1.01-4.16l8.07,0.44l2.03,0.14
			                        l1.44,0.26l-1.66-0.63l-1.21-0.54l-7.64-3.76l1.02-4.12l13.65,3.61L789.07,512.37z"/>
                                <path className="st4" d="M791.46,501.52l-3.94-0.19l-0.49,2.31l3.66,1.53l-0.63,2.99l-13.16-6.24l0.75-3.57l14.43,0.2L791.46,501.52z
			                         M781.77,500.96l-2.12-0.23l2.03,0.74l3.18,1.3l0.33-1.59L781.77,500.96z"/>
                                <path className="st4" d="M792.56,495.93l-11.46-2.34l-0.43,2.34l-2.37-0.49l1.4-7.56l2.37,0.48l-0.43,2.34l11.46,2.34L792.56,495.93z"
                                />
                                <path className="st4" d="M793.75,489.39l-13.87-2.58l0.49-2.91l13.87,2.57L793.75,489.39z" />
                                <path className="st4" d="M785.41,477.8l-0.26-0.02l-0.93-0.13l-0.85,0.32l-0.3,0.55l0.02,0.4l0.19,0.51l0.36,0.29l0.56,0.16l0.6,0.04
			                        l0.47-0.21l1.2-1.01l1.01-1.1l0.9-0.89l1.33-0.84l1-0.21l1.26,0.06l1.39,0.4l1.19,0.8l0.64,1.15l0.29,1.63l-0.15,1.02l-0.33,1.42
			                        l-0.56,0.91l-0.89,0.67l-0.87,0.31l-0.93,0.03l-1.82-0.26l0.41-2.8l1.2,0.2l0.89,0l0.49-0.31l0.32-0.67l-0.09-0.72l-0.47-0.53
			                        l-0.85-0.25l-0.92-0.05l-0.74,0.36l-0.85,0.84l-1.04,1.16l-0.8,0.8l-0.98,0.73l-0.9,0.25l-1.42-0.07l-1.15-0.31l-1.02-0.63
			                        l-0.55-0.63l-0.37-0.89l-0.13-0.88l0.09-1.34l0.56-1.72l0.73-0.88l0.84-0.38l1.33-0.13l1.31,0.17L785.41,477.8z"/>
                                <path className="st4" d="M787.23,473.5l-1.1-0.19l-0.9-0.19l-1.03-0.33l-0.73-0.43l-0.6-0.71l-0.36-0.71l-0.27-1.43l0.05-0.95
			                        l0.2-0.86l0.37-0.9l0.94-1.11l0.75-0.41l0.88-0.17l2.57,0.21l-0.34,2.83l-0.91-0.13l-1.02-0.06L785.1,468l-0.36,0.3l-0.29,0.62
			                        l0.14,0.66l0.34,0.32l0.4,0.22l1.28,0.26l6.11,0.84l1.03,0l0.49-0.2l0.23-0.28l0.14-0.46l-0.06-0.51l-0.52-0.57l-2.09-0.48
			                        l-0.45-0.06l0.34-2.83l2.24,0.39l1.35,0.64l0.94,1.06l0.34,1.15l-0.01,1.62l-0.23,1.27l-0.41,0.88l-0.71,0.73l-0.85,0.42
			                        L793,474.2l-2.2-0.22L787.23,473.5z"/>
                                <path className="st4" d="M797.71,458.78l-6.07-0.66l-0.24,2.6l6.07,0.67l-0.27,2.95l-14-1.53l0.27-2.95l5.63,0.62l0.24-2.6l-5.63-0.62
			                        l0.27-2.95l14,1.53L797.71,458.78z"/>
                                <path className="st4" d="M798.17,453.31l-14.03-1.15l0.44-6.83l2.54,0.21l-0.25,3.88l3.02,0.25l0.23-3.47l2.48,0.2l-0.23,3.47
			                        l3.42,0.28l0.25-3.88l2.57,0.21L798.17,453.31z"/>
                            </g>
                            <g>
                                <path className="st4" d="M802.94,552.68l-5.54-1.85l-3.54-1.2l-1.91-0.79l0.87,0.44l1.38,0.74l1.65,0.89l6.45,3.81l-0.86,2.6
			                        l-7.23-0.72l-2.4-0.39l-1.52-0.23l-1.06-0.22l1.32,0.4l2.69,0.72l7.56,2.56l-0.85,2.58l-13.41-4.38l1.28-4.04l8.04,0.95l2.02,0.27
			                        l1.42,0.34l-1.62-0.73l-1.17-0.6l-7.4-4.19l1.29-4l13.42,4.42L802.94,552.68z"/>
                                <path className="st4" d="M790.53,539.88l-0.57,1.99l-2.25-0.67l0.57-1.99L790.53,539.88z M791.55,536.33l-0.58,2.01l-2.24-0.67
			                        l0.58-2.01L791.55,536.33z M801.99,537.97l2.13,0.76l1.19,0.86l0.71,1.23l0.15,1.23l-0.31,1.58l-0.33,1l-0.88,1.26l-1.07,0.76
			                        l-1.14,0.19l-1.2-0.1l-1.16-0.37l-9.13-2.72l0.79-2.77l8.11,2.42l1.83,0.5l1.14,0.15l0.51-0.25l0.42-0.74l0.01-0.63l-0.15-0.42
			                        l-0.37-0.38l-1.5-0.59l-1.17-0.35l-8.08-2.39l0.8-2.81L801.99,537.97z"/>
                                <path className="st4" d="M807.46,537.18l-13.63-3.72l0.73-2.79l11.2,3.06l1.04-3.99l2.43,0.66L807.46,537.18z" />
                                <path className="st4" d="M809.65,528.72l-13.69-3.43l0.67-2.8l11.25,2.82l0.96-4.01l2.45,0.61L809.65,528.72z" />
                                <path className="st4" d="M812.84,514.95l-3.95-0.25l-0.53,2.27l3.64,1.58l-0.69,2.94l-13.06-6.39l0.82-3.52l14.44,0.44L812.84,514.95z
			                         M803.14,514.23l-2.12-0.27l2.02,0.76l3.16,1.34l0.36-1.56L803.14,514.23z"/>
                                <path className="st4" d="M799.91,507.6l0.82-4.25l0.15-0.57l0.46-1.03l0.49-0.54l0.6-0.38l0.71-0.21l2.05,0.13l1.2,0.35l0.96,0.63
			                        l0.54,0.71l0.08,0.55l0.43-0.76l0.53-0.41l0.67-0.2l1.95,0.11l1.84,0.6l0.9,0.82l0.34,0.67l0.14,0.67l-0.12,1.34l-0.88,4.57
			                        L799.91,507.6z M806.29,505.33l0.05-0.78l-0.12-0.35l-0.26-0.32l-1.37-0.38l-0.72-0.04l-0.67,0.49l-0.27,0.67l-0.15,0.8l3.37,0.68
			                        L806.29,505.33z M812.17,506.32l0.04-0.84l-0.25-0.51l-0.7-0.37l-0.78-0.2l-1.25,0.02l-0.42,0.41l-0.28,0.79l-0.18,0.92l3.62,0.74
			                        L812.17,506.32z"/>
                                <path className="st4" d="M815.61,500.61l-13.88-2.55l1.12-6.63l2.46,0.44l-0.63,3.72l2.96,0.6l0.58-3.44l2.4,0.43l-0.58,3.44
			                        l6.06,1.09L815.61,500.61z"/>
                                <path className="st4" d="M813.21,483.14l1.94,0.41l0.88,0.32l0.75,0.51l0.56,0.6l0.34,0.83l0.23,1.08l-0.12,1.34l-0.37,1.59
			                        l-0.63,1.01l-1.02,0.73l-0.83,0.32l-0.82,0.06l-1.69-0.2l-9.4-1.47l0.42-2.85l8.35,1.31l1.82,0.26l1.13,0.01l0.44-0.26l0.25-0.31
			                        l0.18-0.57l-0.03-0.52l-0.38-0.7l-0.69-0.27l-1.09-0.25l-1.26-0.2l-8.32-1.28l0.42-2.89L813.21,483.14z"/>
                                <path className="st4" d="M819.18,476.13l-6.06-0.79l-0.31,2.56l6.06,0.79l-0.35,2.9l-13.99-1.82l0.35-2.9l5.63,0.73l0.31-2.56
			                        l-5.63-0.73l0.35-2.9l13.99,1.82L819.18,476.13z"/>
                                <path className="st4" d="M820.34,464.84l-0.52,0.17l-1.89,0.05l-0.81-0.08l-1.34-0.08l-0.41,0.06l-0.55,0.27l-0.22,0.45l-0.1,0.44
			                        l-0.1,1.07l5.66,0.59l-0.28,2.95l-14.02-1.47l0.5-5.34l0.25-0.99l0.36-0.64l0.55-0.58l1.11-0.48l1.77-0.04l0.93,0.14l1.17,0.31
			                        l0.91,0.73l0.34,0.85l0.6-0.84l1-0.39l1.04,0.05l0.26,0.03l2.2,0.07l1.4-0.18l0.46-0.2L820.34,464.84z M812.22,465.26l-0.1-0.42
			                        l-0.42-0.45l-1.12-0.22l-0.88-0.05l-0.43,0.1l-0.53,0.33l-0.22,1.1l-0.09,0.93l3.63,0.38L812.22,465.26z"/>
                            </g>
                            </>}
                        </g>
                        <g id="Segment_-_11" className={"model-option" + (this.state.changed ? this.setStyles(13) : '')}>
                            <path id="Circle_-_2-2_10_" className="st0" d="M519.1,543.02L628.03,679.7c37.37-30.1,68.91-68.3,90.41-113.18L560.91,490.6
		C550.96,511.49,536.45,528.97,519.1,543.02z"/>
                            <path id="Circle_-_2-1_1_" className="st1" d="M519.1,543.02L628.03,679.7c37.37-30.1,68.91-68.3,90.41-113.18L560.91,490.6
		C550.96,511.49,536.45,528.97,519.1,543.02z"/>
                            <path id="Circle_-_1-2_2_" className="st0" d="M628.03,679.7l61.86,77.61c48.84-39.02,89.74-89.06,117.89-147.73l-89.34-43.06
		C696.88,611.46,665.38,649.63,628.03,679.7z"/>
                            <path id="Circle_-_1-1_3_" className="st2" d="M628.03,679.7l61.86,77.61c48.84-39.02,89.74-89.06,117.89-147.73l-89.34-43.06
		C696.88,611.46,665.38,649.63,628.03,679.7z"/>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M676.4169,704.5431,666.78,694.2188c1.3409-1.2517,2.0079-1.881,3.3347-3.1466a3.0693,3.0693,0,0,1,3-.7721,6.0025,6.0025,0,0,1,2.3937,1.7757,11.163,11.163,0,0,1,.929,1.0768,3.1347,3.1347,0,0,1,.6121,3.4948,6.0394,6.0394,0,0,1-1.4993,1.7607c-.1728.164-.2592.2459-.4323.41l3.4789,3.6765C677.7278,703.3167,677.2919,703.7265,676.4169,704.5431Zm-2.4687-8.061a1.2308,1.2308,0,0,0,.4521-.6512c.1139-.412-.1689-.9442-.7633-1.5663a8.895,8.895,0,0,0-.9422-.8989,1.412,1.412,0,0,0-.7306-.3259,1.272,1.272,0,0,0-.8521.4254c-.2231.2118-.3347.3176-.5582.5291,1.1384,1.203,1.7076,1.8045,2.8459,3.0076C673.6194,696.7941,673.7291,696.69,673.9482,696.4821Z" />
                                    <path className="st4" d="M684.1468,697.15l-9.8855-10.0865c1.9119-1.8739,2.86-2.8182,4.7417-4.7211.7283.72,1.0925,1.08,1.8209,1.8-1.0745,1.0867-1.6142,1.6277-2.6985,2.7051l2.1433,2.1573c.9764-.97,1.4626-1.4571,2.4311-2.4347l1.7761,1.7593c-.9741.9834-1.4631,1.4732-2.4452,2.4489l2.4255,2.4413c1.1072-1.1,1.6582-1.6523,2.7552-2.7619l1.8347,1.814C687.1022,694.2379,686.1223,695.2136,684.1468,697.15Z" />
                                    <path className="st4" d="M695.596,685.5032a5.37,5.37,0,0,1-.52-.15,5.5287,5.5287,0,0,1-1.55-1.1278l-.5943-.5641a10.675,10.675,0,0,0-1.0145-.8788,1.3628,1.3628,0,0,0-.9634-.3217,1.3205,1.3205,0,0,0-.81.5016c-.3.314-.45.4708-.7511.7841l4.1121,3.9489c-.8392.8739-1.26,1.3094-2.1057,2.1776-4.0476-3.9409-6.0713-5.9114-10.1188-9.8524,1.3227-1.3585,1.98-2.0413,3.2874-3.4141.1243-.1307.2694-.2759.4026-.3937a3.4179,3.4179,0,0,1,2.2564-1.0208,3.9609,3.9609,0,0,1,2.61,1.3409c.2189.2049.4429.4383.6581.6716a3.1253,3.1253,0,0,1,1.0619,2.0556,1.7161,1.7161,0,0,1-.2824.9126,1.6325,1.6325,0,0,1,1.01-.3131,2.7153,2.7153,0,0,1,1.8373.9647l.19.1782a11.3388,11.3388,0,0,0,2.9528,2.0841,2.3216,2.3216,0,0,0,.4918.1187C696.8956,684.1277,696.4636,684.5872,695.596,685.5032Zm-7.2568-4.0684a5.9659,5.9659,0,0,0,.5193-.5737.859.859,0,0,0,.14-.99,3.7511,3.7511,0,0,0-.7563-.8585,8.6633,8.6633,0,0,0-.6669-.5769,1.1837,1.1837,0,0,0-1.0294-.2521,3.527,3.527,0,0,0-.8532.7206c-.257.2688-.3856.4031-.6433.6713,1.0563,1.0144,1.5845,1.5215,2.6407,2.5359C687.95,681.8414,688.08,681.7059,688.3392,681.4348Z" />
                                    <path className="st4" d="M699.6313,681.1848c-4.1422-3.8413-6.2135-5.7618-10.3564-9.6024,1.8013-1.9543,2.6943-2.9384,4.4645-4.92l1.8652,1.6661c-1.0006,1.12-1.5033,1.6781-2.5139,2.79.8775.8308,1.3162,1.2463,2.1935,2.0774.9414-1.0352,1.41-1.5547,2.3429-2.5974l1.8188,1.6272c-.9382,1.0486-1.4094,1.571-2.3562,2.6121l4.5582,4.1452C700.8442,679.8661,700.441,680.3066,699.6313,681.1848Z" />
                                    <path className="st4" d="M708.1285,665.0663c1.6963,1.5525,2.09,2.5648,1.8821,3.7738a5.1468,5.1468,0,0,1-1.2192,2.397,4.93,4.93,0,0,1-2.4837,1.6292,2.6794,2.6794,0,0,1-.9172.0746,4.3076,4.3076,0,0,1-1.7723-.682,33.9512,33.9512,0,0,1-3.54-2.8828c-.5692-.5032-1.1525-1.0346-1.6961-1.5537a9.601,9.601,0,0,1-.8156-.8289,3.2746,3.2746,0,0,1-.6647-3.8809,5.65,5.65,0,0,1,.925-1.43,3.5666,3.5666,0,0,1,4.9808-.9511,30.6345,30.6345,0,0,1,2.8644,2.2212C706.4944,663.6434,707.332,664.3374,708.1285,665.0663Zm-4.3256.2563c-.5461-.47-1.0909-.9264-1.6069-1.3845a6.1382,6.1382,0,0,0-.8731-.6714c-.1212-.1046-1.17-.8624-1.8822-.0252a.9938.9938,0,0,0-.2851.6338c-.0424.6575.5721,1.212.986,1.6084.4862.4656,1.014.9381,1.5565,1.4112.7993.697,1.612,1.3737,2.4094,2.0467a5.4065,5.4065,0,0,0,1.8041,1.2178,1.1655,1.1655,0,0,0,1.3764-1.5882,11.1779,11.1779,0,0,0-2.061-2.0215Z" />
                                    <path className="st4" d="M717.306,660.79a5.3592,5.3592,0,0,1-.53-.1105,5.5344,5.5344,0,0,1-1.63-1.0088l-.6347-.518a10.681,10.681,0,0,0-1.0776-.8,1.3623,1.3623,0,0,0-.9845-.2489,1.3181,1.3181,0,0,0-.77.56c-.2755.3353-.4134.5028-.69.8374l4.3962,3.63c-.7707.9334-1.1577,1.3988-1.9348,2.3269L702.6235,656.39c1.216-1.4523,1.82-2.1817,3.02-3.6473.1141-.1395.2479-.295.3717-.4224a3.4149,3.4149,0,0,1,2.1727-1.1856,3.9635,3.9635,0,0,1,2.7029,1.1422c.2336.1879.4745.4039.7066.62a3.1268,3.1268,0,0,1,1.2131,1.97,1.7128,1.7128,0,0,1-.2128.9307,1.6282,1.6282,0,0,1,.9828-.3873,2.7166,2.7166,0,0,1,1.9041.8247l.2031.1635a11.3493,11.3493,0,0,0,3.1,1.8575,2.3247,2.3247,0,0,0,.4992.0817C718.4981,659.3217,718.1019,659.8119,717.306,660.79Zm-7.54-3.5149a5.9718,5.9718,0,0,0,.4744-.6106.858.858,0,0,0,.0655-.9966,3.7553,3.7553,0,0,0-.8186-.7994,8.6564,8.6564,0,0,0-.7082-.5254,1.1838,1.1838,0,0,0-1.0451-.1746,3.5193,3.5193,0,0,0-.7963.7817c-.2359.287-.354.43-.5906.7169l2.8231,2.3311C709.4089,657.7087,709.528,657.5641,709.766,657.2746Z" />
                                    <path className="st4" d="M727.1816,648.1977c-1.8771-1.4178-2.8156-2.1267-4.6928-3.5444-.9741-.7357-1.9981-1.4645-2.9415-2.2464-.5333-.442-1.0569-.9243-1.5914-1.3784.2195.241.4646.4937.7012.7309.35.351.7284.73,1.0783,1.0951.4479.4669.8582.9552,1.2792,1.4264,1.9523,2.2464,2.9249,3.3733,4.8628,5.6348-.6942.91-1.0429,1.3637-1.7431,2.2691-2.6385-1.2444-3.9552-1.8709-6.583-3.1327-.7159-.3507-1.448-.7169-2.1528-1.1111-.4589-.2567-.9065-.512-1.3505-.7728-.3063-.18-.63-.3639-.9512-.5386.3752.2685.7473.5134,1.1214.7744.7655.534,1.5295,1.08,2.2688,1.6453.2986.2283.6135.4608.9116.6859.4708.3555.961.7511,1.45,1.1329l3.925,3.0674c-.6944.8887-1.0432,1.3319-1.7433,2.2161-4.4391-3.4941-6.6585-5.2413-11.097-8.7361,1.0314-1.3023,1.5439-1.956,2.5627-3.2683,2.9116,1.4168,4.3707,2.12,7.2954,3.5155.6134.3174,1.2249.6106,1.8136.9562.4152.2437.8241.503,1.2425.7471-.4634-.3826-.87-.7916-1.2808-1.191-.309-.3-.6231-.6334-.922-.9658-2.2384-2.5515-3.3621-3.8226-5.618-6.3553,1.0058-1.3223,1.5056-1.986,2.4986-3.3181,4.5391,3.3631,6.8087,5.0445,11.3484,8.4069C728.2,646.8469,727.8612,647.2979,727.1816,648.1977Z" />
                                    <path className="st4" d="M734.2592,638.5563c-1.4338-.6766-2.15-1.0159-3.5813-1.6968-.5505.769-.8267,1.1528-1.3813,1.9187,1.1046,1.1347,1.6562,1.703,2.7575,2.8414-.7263,1-1.0912,1.4986-1.8245,2.4933-3.8071-4.3831-5.7246-6.56-9.5874-10.8819.84-1.1562,1.2568-1.7361,2.0867-2.8994,5.3023,2.3223,7.9633,3.4663,13.3044,5.7192C735.3271,637.0555,734.9723,637.5566,734.2592,638.5563Zm-8.7216-4.2786c-.6439-.3373-1.2878-.6764-1.9077-1.0473.5491.4692,1.0745.973,1.5993,1.4757.969.9684,1.4529,1.4533,2.42,2.4244.3784-.5235.5671-.7857.9435-1.3107C727.37,635.2044,726.7587,634.896,725.5376,634.2777Z" />
                                    <path className="st4" d="M740.7974,629.1326c-3.095-.8065-4.6388-1.2182-7.7185-2.0585-.3946-.096-.7643-.1968-1.1435-.3125-.656-.2-1.2954-.4452-1.9432-.6682.442.2052.8582.4142,1.2583.6414.4347.2469.8589.5158,1.3.7929,2.5748,1.7067,3.8624,2.56,6.4381,4.2651-.6566.9581-.9865,1.436-1.6494,2.39-4.6386-3.2244-6.958-4.8366-11.5968-8.0607.7655-1.1013,1.1461-1.6535,1.903-2.7609,2.8272.7841,4.2441,1.1691,7.0842,1.9247.5789.1444,1.1756.3034,1.7465.4877.5523.1783,1.1289.3638,1.6905.568-.4942-.2328-.9928-.4653-1.4587-.734-.2929-.1689-.5869-.3453-.8644-.5067-2.5988-1.6991-3.898-2.5489-6.496-4.2492.619-.92.927-1.3806,1.54-2.3045,4.7072,3.1235,7.0608,4.6853,11.7678,7.8089C741.9164,627.4694,741.5448,628.0248,740.7974,629.1326Z" />
                                    <path className="st4" d="M736.9582,618.8934c-.3072-.2244-.6277-.4465-.9155-.6466-.2391-.1662-.5084-.3557-.748-.5371a3.7381,3.7381,0,0,1-1.5174-2.2886,3.111,3.111,0,0,1-.0085-.7965,5.4012,5.4012,0,0,1,1.358-2.8543,4.2688,4.2688,0,0,1,2.0358-1.2342c1.1113-.226,2.11.2525,3.9915,1.4319-.5963.9514-.896,1.426-1.4984,2.3733l-.7771-.4942a3.4759,3.4759,0,0,0-1.51-.72,1.1027,1.1027,0,0,0-.9767.5265,1.0255,1.0255,0,0,0-.1639.663c.0371.6471.59.9318,1.5047,1.544.2119.1419.4541.2922.6621.4509l3.1787,2.0459c.4656.2746.91.5826,1.3573.8485a4.5148,4.5148,0,0,0,.9247.449,1.1,1.1,0,0,0,1.1463-1.7173,8.6747,8.6747,0,0,0-1.7176-1.2882l-.3881-.2468c.6079-.9557.91-1.4346,1.5118-2.3944,1.6374,1.0027,2.385,1.5427,2.86,2.4034.9612,1.7043-.2445,3.6015-.502,4.0037a4.5147,4.5147,0,0,1-2.4933,2.02,2.4318,2.4318,0,0,1-.9761.0315,9.5549,9.5549,0,0,1-3.3239-1.6037Z" />
                                    <path className="st4" d="M750.4759,614.1375c-4.8077-2.9664-7.2116-4.45-12.0194-7.416,1.4062-2.2789,2.1005-3.4243,3.4713-5.7263l2.2,1.31c-.7829,1.3147-1.1772,1.97-1.9715,3.2773l2.5988,1.5792c.7153-1.1771,1.0706-1.7671,1.7767-2.95l2.1467,1.2811c-.71,1.19-1.0675,1.7834-1.7868,2.9673l2.941,1.7871c.8109-1.3346,1.2135-2.0038,2.0128-3.3461l2.2168,1.32C752.6461,610.6,751.9287,611.7829,750.4759,614.1375Z" />
                                    <path className="st4" d="M708.6182,703.7181c-1.3283-.87-1.9918-1.3062-3.3171-2.18-.6914.7143-1.0381,1.0705-1.7334,1.7811.9155,1.2951,1.3724,1.9435,2.2842,3.2417-.91.927-1.3666,1.3889-2.2833,2.31-3.0735-4.9425-4.6266-7.4009-7.7647-12.2916,1.0535-1.0736,1.5779-1.6126,2.6221-2.6948,4.9453,3.0337,7.43,4.5348,12.4235,7.5053C709.96,702.3238,709.514,702.79,708.6182,703.7181Zm-8.053-5.4582c-.5908-.4247-1.1813-.8513-1.7419-1.3071.4717.5472.9134,1.1267,1.3547,1.7051.8077,1.1088,1.2109,1.6638,2.016,2.7749.4748-.4859.7117-.7294,1.1845-1.2171C702.2522,699.4347,701.69,699.0435,700.5652,698.26Z" />
                                    <path className="st4" d="M712.4954,699.65c-4.1128-3.8728-6.1693-5.8091-10.2821-9.6818,1.32-1.4021,1.9765-2.1067,3.2813-3.5227a3.2721,3.2721,0,0,1,3.0354-1.0635,5.7526,5.7526,0,0,1,2.5194,1.6A10.6506,10.6506,0,0,1,712.0432,688a3.14,3.14,0,0,1,.77,3.52,6.6251,6.6251,0,0,1-1.4591,1.9367c-.17.1834-.2548.2749-.4249.458l3.7085,3.4447C713.7839,698.2789,713.3555,698.737,712.4954,699.65Zm-2.8589-8.0182a1.3422,1.3422,0,0,0,.4352-.707c.0993-.4318-.2117-.9506-.8449-1.5331a8.5008,8.5008,0,0,0-1-.8341,1.06,1.06,0,0,0-1.6141.2456c-.2193.2371-.3291.3554-.5488.5921l3.0337,2.8179C709.3132,691.9813,709.4211,691.865,709.6365,691.6322Z" />
                                    <path className="st4" d="M720.0836,691.3926c-4.2057-3.7716-6.3086-5.6574-10.5146-9.4289,1.2853-1.4332,1.924-2.1533,3.1936-3.6a3.2706,3.2706,0,0,1,3.0081-1.1364,5.7542,5.7542,0,0,1,2.5574,1.5382,10.6524,10.6524,0,0,1,1.0183.9933,3.14,3.14,0,0,1,.8557,3.5,6.6189,6.6189,0,0,1-1.4111,1.971c-.1652.1874-.2479.2809-.4134.4681l3.7911,3.3535C721.3379,689.9907,720.9209,690.4589,720.0836,691.3926Zm-3.0539-7.9452a1.3418,1.3418,0,0,0,.4176-.7172c.0888-.434-.2349-.9451-.882-1.512a8.4835,8.4835,0,0,0-1.02-.81,1.06,1.06,0,0,0-1.6073.2845c-.2135.2422-.32.3632-.5342.605l3.1015,2.7433C716.7151,683.8041,716.82,683.6852,717.03,683.4474Z" />
                                    <path className="st4" d="M731.5607,678.0924a3.4373,3.4373,0,0,1-2.1643-1.1207l-.63-.5241a10.1833,10.1833,0,0,0-1.0717-.8083,1.3306,1.3306,0,0,0-.9961-.2416,1.4393,1.4393,0,0,0-.8051.5887c-.2928.35-.4395.5242-.7331.873l4.3612,3.6719c-.8188.9724-1.23,1.4572-2.0552,2.424l-10.7407-9.17c1.2935-1.5152,1.9361-2.2763,3.2127-3.8057.1214-.1455.2633-.3078.3944-.4411a3.6993,3.6993,0,0,1,2.2594-1.256,3.8335,3.8335,0,0,1,2.7182,1.136c.2319.19.47.4092.6993.6288a3.0365,3.0365,0,0,1,1.1692,2.0119,1.8438,1.8438,0,0,1-.25.9623,1.7418,1.7418,0,0,1,1.0162-.4139,2.6223,2.6223,0,0,1,1.9139.8212l.2015.1655a10.853,10.853,0,0,0,3.0982,1.8678,2.2975,2.2975,0,0,0,.5064.0768C732.8273,676.5631,732.4063,677.0738,731.5607,678.0924Zm-7.5724-3.5072a6.6088,6.6088,0,0,0,.5055-.6363.9033.9033,0,0,0,.1017-1.0279,3.5774,3.5774,0,0,0-.8075-.8112,8.244,8.244,0,0,0-.7046-.53,1.1675,1.1675,0,0,0-1.0606-.1639,3.8828,3.8828,0,0,0-.84.8178c-.251.2995-.3767.4491-.6283.7479,1.12.9432,1.68,1.4149,2.8007,2.358C723.6085,675.0378,723.7352,674.887,723.9883,674.5852Z" />
                                    <path className="st4" d="M738.9793,668.92c-1.4086-.7332-2.1122-1.1008-3.5181-1.8381-.6158.779-.9248,1.1678-1.5449,1.9435,1.0408,1.1967,1.56,1.796,2.5976,2.9962-.8116,1.0122-1.2193,1.5168-2.0381,2.5233-3.5537-4.6087-5.3456-6.8987-8.9582-11.45.94-1.1722,1.4071-1.76,2.3368-2.9405,5.2232,2.5248,7.8453,3.77,13.11,6.2271C740.1741,667.4,739.777,667.9079,738.9793,668.92Zm-8.5575-4.6265c-.63-.3636-1.26-.729-1.8638-1.1266.5241.4973,1.0217,1.03,1.5187,1.5608.9148,1.0223,1.3715,1.534,2.2839,2.5588.4233-.53.6344-.796,1.0557-1.328C732.2175,665.2943,731.6187,664.9612,730.4218,664.2939Z" />
                                    <path className="st4" d="M742.4273,664.4888c-4.48-3.4418-6.7195-5.1627-11.199-8.6046.74-.9638,1.109-1.4469,1.8429-2.4157l11.2574,8.5284C743.5714,662.9963,743.1912,663.4946,742.4273,664.4888Z" />
                                    <path className="st4" d="M741.2909,649.25l-.21-.1531a8.3965,8.3965,0,0,0-.7849-.5195,1.01,1.01,0,0,0-1.4627.2718,1.1188,1.1188,0,0,0-.2688.9071,1.0379,1.0379,0,0,0,.2.4367,2.3054,2.3054,0,0,0,.4289.3958c.6926.51,1.2009.4638,2.6194-.0862.58-.2106.87-.3165,1.4508-.5295a7.9965,7.9965,0,0,1,1.2407-.45,3.9244,3.9244,0,0,1,3.7314.673,4.3752,4.3752,0,0,1,1.74,2.2386,2.83,2.83,0,0,1,.0286,1.3615,6.2555,6.2555,0,0,1-1.195,2.5239c-.232.3165-1.4457,1.94-3.1047,1.98a5.83,5.83,0,0,1-3.2974-1.5688c.7121-.9531,1.0666-1.4308,1.7726-2.3886.3222.2376.667.4854.9951.7073.6388.4319.96.5184,1.3714.364a1.19,1.19,0,0,0,.6387-.4988,1.2026,1.2026,0,0,0,.1186-1.4171,2.4105,2.4105,0,0,0-.6478-.6051,3.0385,3.0385,0,0,0-.8077-.4553c-.5091-.1834-1.0218-.0227-2.0193.3909-.6029.24-.9045.36-1.5076.5971-.3692.1285-.7495.2729-1.1212.3709a3.6063,3.6063,0,0,1-3.4118-.6759,5.0225,5.0225,0,0,1-.869-.8079,3.3111,3.3111,0,0,1-.6883-2.8314,5.5258,5.5258,0,0,1,1.037-2.0154,4.142,4.142,0,0,1,2.3928-1.76,2.701,2.701,0,0,1,.9536.01,5.8394,5.8394,0,0,1,2.3635,1.2031C742.306,647.8537,741.9686,648.32,741.2909,649.25Z" />
                                    <path className="st4" d="M756.26,645.5029c-1.4522-.6426-2.1777-.965-3.6274-1.612-.5659.8169-.85,1.2247-1.42,2.0384,1.1141,1.1287,1.67,1.6941,2.7813,2.8266-.7466,1.0619-1.1218,1.5916-1.8758,2.6482-3.8373-4.3757-5.77-6.5484-9.6619-10.863.8643-1.23,1.2939-1.8466,2.148-3.0838,5.3725,2.1894,8.0683,3.2666,13.4786,5.3852C757.3574,643.9093,756.9928,644.4413,756.26,645.5029Zm-8.8332-4.0763c-.652-.323-1.304-.6479-1.9312-1.0066.5543.4633,1.0844.9631,1.614,1.462.9773.9626,1.4655,1.4446,2.4407,2.41.3891-.5564.5832-.835.97-1.3929C749.2823,642.3115,748.6636,642.017,747.4269,641.4266Z" />
                                    <path className="st4" d="M759.424,640.859c-4.688-3.1521-7.032-4.7282-11.72-7.88.6693-.9954,1.0023-1.4943,1.665-2.4942l9.6715,6.4094c.9725-1.4674,1.4552-2.2034,2.4137-3.68l2.1144,1.372C761.931,637.1088,761.102,638.3636,759.424,640.859Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                    <path className="st4" d="M689.2,690.5l-9.93-10.07l2.03-2.07l8.16,8.28l2.91-2.97l1.77,1.8L689.2,690.5z" />
                                    <path className="st4" d="M695.36,684.17l-10.16-9.83l4.65-4.98l1.84,1.78l-2.64,2.83l2.19,2.12l2.36-2.53l1.8,1.74l-2.36,2.53
			l2.48,2.4l2.64-2.83l1.86,1.8L695.36,684.17z"/>
                                    <path className="st4" d="M701.31,677.75l-10.34-9.64l1.97-2.19l10.34,9.64L701.31,677.75z" />
                                    <path className="st4" d="M700.48,663.48l-0.21-0.16l-0.71-0.61l-0.89-0.19l-0.55,0.3l-0.2,0.35l-0.11,0.53l0.15,0.44l0.39,0.43
			l0.49,0.35l0.52,0.08l1.55-0.2l1.44-0.38l1.24-0.27l1.58,0.01l0.96,0.36l1.03,0.73l0.97,1.09l0.58,1.32l-0.07,1.31l-0.63,1.52
			l-0.67,0.77l-1.04,1.02l-0.96,0.46l-1.1,0.08l-0.91-0.21l-0.81-0.48l-1.4-1.2l1.85-2.14l0.91,0.81l0.75,0.48l0.58,0l0.63-0.39
			l0.31-0.66l-0.11-0.7l-0.59-0.67l-0.75-0.54l-0.81-0.1l-1.17,0.25l-1.5,0.42l-1.11,0.24l-1.22,0.09l-0.89-0.28l-1.17-0.82
			l-0.8-0.88l-0.53-1.08l-0.13-0.83l0.16-0.95l0.36-0.81l0.8-1.07l1.39-1.15l1.09-0.34l0.92,0.13l1.2,0.61l1.01,0.85L700.48,663.48z
			"/>
                                    <path className="st4" d="M712.01,665.43l-8.87-7.63l-1.52,1.83l-1.83-1.58l4.9-5.92l1.84,1.58l-1.52,1.83l8.87,7.63L712.01,665.43z" />
                                    <path className="st4" d="M717.67,650.19l1.48,1.32l0.6,0.72l0.4,0.82l0.19,0.81l-0.12,0.9l-0.34,1.06l-0.77,1.12l-1.11,1.21
			l-1.05,0.57l-1.24,0.13l-0.88-0.13l-0.74-0.36l-1.36-1.02l-7.42-5.97l1.78-2.3l6.59,5.3l1.45,1.14l0.97,0.57l0.51-0.01l0.37-0.15
			l0.44-0.41l0.24-0.47l0.02-0.8l-0.46-0.58l-0.82-0.77l-1-0.8l-6.57-5.26l1.81-2.33L717.67,650.19z"/>
                                    <path className="st4" d="M726.66,646.58l-7.51-2.41l-1.16-0.42l-1.85-0.72l1.19,0.68l1.27,0.87l6.17,4.59l-1.67,2.31l-11.24-8.57
			l2.06-2.76l6.92,2.27l1.73,0.58l1.62,0.67l-1.39-0.81l-0.83-0.56l-6.22-4.63l1.68-2.28l11.21,8.57L726.66,646.58z"/>
                                    <path className="st4" d="M733.38,637.14l-0.98-0.73l0.2,0.55l0.05,1.05l-0.13,0.69l-0.43,0.7l-0.51,0.67l-1.05,0.64l-1.04,0.17
			l-1.57-0.4l-1.98-1.32l-1.63-1.15l-2.4-1.8l-1.12-0.98l-0.78-1.26l-0.17-1.33l0.28-1.18l0.75-1.41l0.64-0.78l0.99-0.73l0.85-0.32
			l0.98-0.05l0.87,0.25l1.17,0.59l0.77,0.5l-1.64,2.38l-0.49-0.35l-1.19-0.67l-0.58,0.06l-0.68,0.5l-0.2,0.46l0.05,0.69l0.81,0.96
			l1.47,1.13l2.94,2.11l0.98,0.65l1.04,0.42l0.6-0.13l0.49-0.43l0.2-0.61l-0.09-0.65l-0.76-0.8l-0.87-0.67l-0.62-0.44l-0.85,1.23
			l-1.85-1.33l2.44-3.53l6.41,4.6L733.38,637.14z"/>
                                    <path className="st4" d="M730.37,623.55l-0.23-0.13l-0.79-0.51l-0.91-0.07l-0.51,0.38l-0.15,0.37l-0.04,0.54l0.21,0.41l0.45,0.38
			l0.53,0.28l0.52,0.01l1.51-0.42l1.38-0.58l1.19-0.43l1.56-0.21l0.99,0.23l1.12,0.58l1.1,0.94l0.75,1.23l0.1,1.31l-0.42,1.6
			l-0.56,0.86l-0.9,1.15l-0.88,0.59l-1.08,0.23l-0.92-0.08l-0.86-0.36l-1.54-1l1.55-2.37l1.01,0.68l0.81,0.37l0.58-0.08l0.57-0.48
			l0.22-0.7l-0.2-0.68l-0.67-0.58l-0.82-0.43l-0.82,0.02l-1.12,0.4l-1.43,0.62l-1.07,0.39l-1.19,0.26l-0.92-0.16l-1.26-0.66
			l-0.91-0.76l-0.66-0.99l-0.24-0.81l0.04-0.97l0.25-0.85l0.65-1.17l1.22-1.33l1.03-0.49l0.92,0.01l1.27,0.44l1.11,0.71
			L730.37,623.55z"/>
                                    <path className="st4" d="M737.04,622.76l-1.73-1.12l2.5-4.01l1.73,1.12L737.04,622.76z" />
                                </g>
                                <g>
                                    <path className="st4" d="M693.39,701.08l2.99-3.12l0.44-0.4l0.94-0.61l0.71-0.19l0.71,0.01l0.71,0.21l1.65,1.22l0.82,0.94l0.46,1.05
			l0.06,0.89l-0.24,0.51l0.78-0.4l0.67-0.06l0.67,0.2l1.58,1.16l1.22,1.51l0.31,1.18l-0.08,0.75l-0.25,0.64l-0.83,1.06l-3.22,3.35
			L693.39,701.08z M699.98,702.65l0.46-0.63l0.1-0.36l-0.05-0.41l-0.95-1.07l-0.58-0.43l-0.82,0.05l-0.59,0.41l-0.56,0.58l2.46,2.41
			L699.98,702.65z M704.38,706.68l0.49-0.68l0.06-0.56l-0.39-0.69l-0.54-0.59l-1.05-0.67l-0.58,0.11l-0.66,0.52l-0.65,0.67
			l2.65,2.59L704.38,706.68z"/>
                                    <path className="st4" d="M710.39,703.76l-10.31-9.66l4.57-4.98l1.87,1.75l-2.59,2.82l2.22,2.08l2.32-2.53l1.82,1.71l-2.32,2.53
			l2.51,2.35l2.59-2.82l1.88,1.77L710.39,703.76z"/>
                                    <path className="st4" d="M718.12,687.5l1.41,1.4l0.57,0.75l0.36,0.84l0.14,0.81l-0.16,0.89l-0.4,1.03l-0.82,1.06l-1.17,1.13l-1.08,0.5
			l-1.25,0.06l-0.88-0.18l-0.72-0.4l-1.31-1.09l-7.1-6.35l1.9-2.17l6.31,5.64l1.39,1.21l0.94,0.62l0.51,0.02l0.38-0.13l0.46-0.38
			l0.26-0.45l0.06-0.79l-0.43-0.6l-0.78-0.81l-0.96-0.85l-6.3-5.6l1.92-2.2L718.12,687.5z"/>
                                    <path className="st4" d="M727.24,684.53l-0.52-0.15l-1.59-1.03l-0.63-0.53l-1.06-0.82l-0.37-0.18l-0.6-0.09l-0.44,0.24l-0.33,0.3
			l-0.69,0.83l4.34,3.69l-1.89,2.28l-10.76-9.15l3.43-4.12l0.77-0.68l0.66-0.32l0.78-0.16l1.18,0.23l1.48,0.97l0.69,0.64l0.8,0.92
			l0.34,1.12l-0.2,0.9l0.97-0.35l1.04,0.25l0.83,0.63l0.2,0.17l1.78,1.3l1.26,0.65l0.49,0.1L727.24,684.53z M720.29,680.27l0.15-0.4
			l-0.09-0.61l-0.8-0.82l-0.7-0.54l-0.41-0.16l-0.63-0.03l-0.81,0.78l-0.6,0.72l2.79,2.37L720.29,680.27z"/>
                                    <path className="st4" d="M730.83,680.09l-9.04-7.44l-1.48,1.83l-1.87-1.54l4.77-5.93l1.87,1.54l-1.48,1.83l9.04,7.44L730.83,680.09z"
                                    />
                                    <path className="st4" d="M735.02,674.84l-11.19-8.62l4.07-5.4l2.03,1.56l-2.31,3.06l2.41,1.86l2.07-2.74l1.98,1.53l-2.06,2.74
			l2.72,2.1l2.31-3.06l2.04,1.57L735.02,674.84z"/>
                                    <path className="st4" d="M740.2,667.98l-11.26-8.51l1.74-2.35l11.26,8.51L740.2,667.98z" />
                                    <path className="st4" d="M743.34,663.71l-11.41-8.32l1.67-2.35l9.37,6.84l2.39-3.35l2.04,1.48L743.34,663.71z" />
                                    <path className="st4" d="M749.02,646.64l1.57,1.21l0.65,0.67l0.46,0.79l0.24,0.79l-0.05,0.9l-0.27,1.07l-0.69,1.16l-1.03,1.26
			l-1.01,0.63l-1.23,0.21l-0.89-0.07l-0.76-0.31l-1.43-0.92l-7.82-5.42l1.62-2.39l6.95,4.82l1.52,1.03l1.01,0.5l0.51-0.04l0.36-0.17
			l0.41-0.43l0.2-0.48l-0.04-0.79l-0.5-0.54l-0.87-0.71l-1.05-0.73l-6.92-4.78l1.64-2.42L749.02,646.64z"/>
                                    <path className="st4" d="M757.72,642.46l-7.66-1.89l-1.18-0.34l-1.9-0.59l1.24,0.59l1.33,0.77l6.49,4.13l-1.5,2.39l-11.83-7.72
			l1.85-2.86l7.06,1.79l1.77,0.46l1.66,0.55l-1.45-0.71l-0.87-0.5l-6.54-4.16l1.51-2.36l11.8,7.73L757.72,642.46z"/>
                                    <path className="st4" d="M763.76,632.74l-1.03-0.66l0.24,0.53l0.12,1.03l-0.09,0.69l-0.38,0.72l-0.46,0.69l-1,0.69l-1.02,0.23
			l-1.6-0.29l-2.06-1.18l-1.71-1.03l-2.52-1.62l-1.18-0.9l-0.86-1.2l-0.26-1.31l0.2-1.19l0.65-1.43l0.58-0.81l0.94-0.78l0.83-0.37
			l0.98-0.11l0.89,0.19l1.21,0.51l0.8,0.45l-1.48,2.45l-0.51-0.31l-1.23-0.59l-0.57,0.1l-0.65,0.53l-0.17,0.47l0.1,0.68l0.88,0.9
			l1.55,1.03l3.08,1.9l1.02,0.58l1.07,0.35l0.59-0.16l0.46-0.46l0.16-0.62l-0.13-0.63l-0.81-0.74l-0.91-0.61l-0.65-0.4l-0.77,1.27
			l-1.94-1.2l2.19-3.63l6.71,4.14L763.76,632.74z"/>
                                </g>
                            </>}
                        </g>
                        <g id="Segment_-_10" className={"model-option" + (this.state.changed ? this.setStyles(12) : '')}>
                            <path id="Circle_-_2-2_9_" className="st0" d="M458.69,572.1l38.85,170.41c46.73-10.9,91.72-31.65,130.55-62.75L519.1,543
		C501.07,557.51,480.42,566.96,458.69,572.1z"/>
                            <path id="Circle_-_2-1_7_" className="st1" d="M458.69,572.1l38.85,170.41c46.73-10.9,91.72-31.65,130.55-62.75L519.1,543
		C501.07,557.51,480.42,566.96,458.69,572.1z"/>
                            <path id="Circle_-_1-2_3_" className="st0" d="M497.54,742.5l22.06,96.77c60.93-13.97,119.49-41.31,170.31-81.97l-61.82-77.55
		C589.18,710.89,544.24,731.61,497.54,742.5z"/>
                            <path id="Circle_-_1-1_4_" className="st2" d="M497.54,742.5l22.06,96.77c60.93-13.97,119.49-41.31,170.31-81.97l-61.82-77.55
		C589.18,710.89,544.24,731.61,497.54,742.5z"/>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M546.0309,763.5583c1.6272-.5369,2.4388-.8108,4.0579-1.3692a5.8043,5.8043,0,0,1,.5577-.1712,3.0357,3.0357,0,0,1,1.8255.0387c.8514.294,1.5284.986,2.1268,2.6627a4.0624,4.0624,0,0,1,.2711,2.3457,1.6692,1.6692,0,0,1-.7848,1.1678,2.2328,2.2328,0,0,1,.888-.016c.8186.1557,1.5172.8822,2.0767,2.4452a3.8282,3.8282,0,0,1,.17,3.0828,3.7241,3.7241,0,0,1-2.2082,1.6857c-1.8173.6289-2.7285.9368-4.5555,1.54Zm5.181,4.4c.5238-.1793,1.0785-.3928,1.0807-1.0075a5.5011,5.5011,0,0,0-.35-1.3816,1.6914,1.6914,0,0,0-.3512-.64,1.3348,1.3348,0,0,0-1.4736-.2329c-.3065.1049-.46.1571-.7666.2612.4422,1.3032.6633,1.9548,1.1056,3.258C550.759,768.1132,550.91,768.0617,551.2119,767.9585Zm2.1075,5.579c.7428-.2123.9924-.4472,1.09-.8626a2.8914,2.8914,0,0,0-.2567-1.5629,2.2573,2.2573,0,0,0-.6551-1.0636c-.34-.261-.6764-.2516-1.4171.0022-.3587.1229-.5382.184-.8973.3059.4757,1.4016.7135,2.1024,1.1891,3.5039C552.7515,773.7318,552.9409,773.6672,553.3194,773.5375Z" />
                                    <path className="st4" d="M567.809,765.2409a6.8944,6.8944,0,0,1,.76,3.7026c-.1814.8887-.9632,2.0648-2.9422,2.8252-.3721.143-2.49.968-4.0056-.1118a5.7307,5.7307,0,0,1-1.8017-2.8307c-1.3189-3.5734-1.9783-5.36-3.2971-8.9335,1.063-.3923,1.5936-.5908,2.6529-.9925l3,7.9121a11.431,11.431,0,0,0,1.2446,2.6947,1.4117,1.4117,0,0,0,1.4563.193,1.3932,1.3932,0,0,0,.879-.9616,4.3789,4.3789,0,0,0-.4349-1.806c-.1524-.3913-.3319-.794-.4842-1.1853l-3.055-7.8483c1.0708-.4168,1.6053-.6277,2.6723-1.0541Z" />
                                    <path className="st4" d="M567.7991,755.4854c1.4474-.5976,2.1693-.9009,3.6092-1.5162a4.5285,4.5285,0,0,1,3.0006-.4046,4.859,4.859,0,0,1,2.5512,2.6793c.3569.6838.6984,1.375,1.0145,2.0884.4218.9517.8125,1.9393,1.1967,2.9048a4.4682,4.4682,0,0,1,.415,3.2059c-.4364,1.394-1.5444,2.0072-2.8015,2.5871-1.4348.6122-2.1539.9141-3.596,1.5094C571.033,763.3179,569.955,760.7071,567.7991,755.4854Zm7.9777,9.3427a1.1489,1.1489,0,0,0,.9271-1.2678,18.8506,18.8506,0,0,0-1.13-3.0852c-.4128-.9557-.8486-1.8922-1.2775-2.8045-.3868-.823-.6156-1.3024-1.07-1.456a1.52,1.52,0,0,0-1.0749.113c-.3053.13-.4581.1951-.7637.3244,1.4413,3.404,2.1618,5.106,3.6031,8.51C575.3057,765.0288,575.4628,764.9621,575.7768,764.8281Z" />
                                    <path className="st4" d="M588.8246,761.6085c-.2125-.4483-.3189-.6724-.5315-1.1206a2.9288,2.9288,0,0,1-.069.586,3.05,3.05,0,0,1-1.6977,1.8564,4.1519,4.1519,0,0,1-2.053.3518,2.4288,2.4288,0,0,1-1.0009-.3786c-.9242-.5689-1.3842-1.4374-2.2353-3.27-.2834-.61-.57-1.2126-.8422-1.8142-.412-.9112-.8208-1.8372-1.181-2.76a5.555,5.555,0,0,1-.5278-2.8789c.343-1.8265,2.322-2.7448,2.6483-2.8977a5.1408,5.1408,0,0,1,.9238-.3463,3.616,3.616,0,0,1,2.9836.46,6.1726,6.1726,0,0,1,1.3951,1.7251c.15.259.28.5315.4374.81-1.0262.4968-1.54.743-2.5709,1.2307-.1027-.2167-.154-.3251-.2566-.5419a4.1956,4.1956,0,0,0-.7285-1.16,1.2736,1.2736,0,0,0-2.0559.7648,6.6083,6.6083,0,0,0,.9644,2.9479l1.5215,3.286a5.2787,5.2787,0,0,0,1.2409,1.9381,1.2759,1.2759,0,0,0,1.237.0418,1.2584,1.2584,0,0,0,.742-1.0126,5.0731,5.0731,0,0,0-.707-2.0779c-.1028-.2171-.1948-.4609-.3059-.6957-.5515.261-.8275.3908-1.38.6492l-.9659-2.0655c1.55-.7247,2.3222-1.0922,3.8622-1.8372,1.3764,2.8451,2.0646,4.2677,3.4409,7.1128C590.199,760.9543,589.7415,761.1735,588.8246,761.6085Z" />
                                    <path className="st4" d="M594.4412,758.8792c-2.5166-5.0576-3.775-7.5865-6.2915-12.6442,2.3938-1.1911,3.5846-1.7993,5.9533-3.0405.4753.9072.713,1.3608,1.1884,2.268-1.3554.71-2.0352,1.0613-3.3988,1.7553l1.3793,2.71c1.2308-.6264,1.8446-.9429,3.0687-1.5824.463.8863.6945,1.33,1.1576,2.2158-1.2336.6445-1.8521.9634-3.0923,1.5946l1.5609,3.067c1.4018-.7134,2.1005-1.0742,3.4938-1.8044.479.9142.7185,1.3712,1.1976,2.2854C598.1846,757,596.941,757.6354,594.4412,758.8792Z" />
                                    <path className="st4" d="M604.0443,753.9047c-2.22-4.1208-3.33-6.1811-5.5506-10.3019-.8264.4452-1.24.6664-2.07,1.1055l-1.1327-2.14c2.6619-1.4092,3.9849-2.13,6.6136-3.6019l1.1829,2.1122c-.8123.455-1.2193.681-2.0348,1.13,2.2575,4.1,3.3863,6.1507,5.6439,10.2511C605.6382,753.0428,605.1078,753.3317,604.0443,753.9047Z" />
                                    <path className="st4" d="M618.963,739.3763c-.0239-.7759-.0368-1.1636-.0646-1.9383,1.0118-.632,1.5163-.95,2.5226-1.5917.038,1.8356.0512,2.7551.0661,4.597.449.1668.6936.2035,1.4515-.2762l.2468-.1564,1.2531,1.976c-1.1847.7987-1.7345,1.1877-2.4766,1.2264a1.4319,1.4319,0,0,1-.8407-.1605,4.6947,4.6947,0,0,1-.3335.9353,5.4428,5.4428,0,0,1-1.8085,1.833,4.4188,4.4188,0,0,1-3.0272.581,4.3644,4.3644,0,0,1-2.5669-2.0833,3.906,3.906,0,0,1-.5883-2.0924,7.4663,7.4663,0,0,1,.41-2.5255,6.5052,6.5052,0,0,1-3.0432-2.2391,2.9711,2.9711,0,0,1-.2765-2.5583,3.8486,3.8486,0,0,1,1.7489-1.9654,3.5066,3.5066,0,0,1,1.7-.5718,2.7405,2.7405,0,0,1,2.3806.8553,4.6082,4.6082,0,0,1,.4922.6369,3.7081,3.7081,0,0,1,.4658,1.1709,6.2509,6.2509,0,0,1-.2793,3.2053C617.4191,738.6949,617.9327,738.9233,618.963,739.3763Zm-3.4709,1.33a2.6114,2.6114,0,0,0,.2712,2.1721,1.8789,1.8789,0,0,0,1.4065,1.045,1.4679,1.4679,0,0,0,.9054-.1981,1.4927,1.4927,0,0,0,.4187-.3956,2.3249,2.3249,0,0,0,.3683-1.2341C617.509,741.546,616.8349,741.2682,615.4921,740.706Zm-1.425-5.6434a1.0153,1.0153,0,1,0-1.7629,1.0022c.2892.479.7972.7341,1.8051,1.1752A2.6977,2.6977,0,0,0,614.0671,735.0626Z" />
                                    <path className="st4" d="M547.0058,786.3436c1.7506-.5424,2.624-.82,4.3663-1.3852.1837-.06.3883-.1262.5993-.1735a3.3757,3.3757,0,0,1,1.9374.0348c.8923.2939,1.5831.9893,2.1508,2.6766a3.8548,3.8548,0,0,1,.1939,2.3618,1.7842,1.7842,0,0,1-.8789,1.1775,2.4931,2.4931,0,0,1,.9426-.0178c.862.1551,1.5737.8852,2.1047,2.4581a3.6766,3.6766,0,0,1,.0581,3.1041,4.1475,4.1475,0,0,1-2.4059,1.7c-1.95.6354-2.928.9461-4.8882,1.5535C549.5139,794.4377,548.6779,791.74,547.0058,786.3436Zm5.3308,4.4209c.5631-.1812,1.16-.3971,1.1868-1.016a5.1633,5.1633,0,0,0-.3166-1.39,1.5963,1.5963,0,0,0-.3475-.6436,1.4535,1.4535,0,0,0-1.5557-.2318c-.33.1062-.4945.159-.8244.2643l1.046,3.2776C551.85,790.921,552.0122,790.8689,552.3366,790.7645Zm2.0176,5.6127c.7958-.2146,1.07-.4515,1.1895-.87a2.7321,2.7321,0,0,0-.2109-1.573,2.1412,2.1412,0,0,0-.6529-1.07c-.3507-.2624-.7075-.2521-1.5033.0044-.3855.1242-.5782.1861-.964.3092l1.125,3.525C553.7444,796.5735,553.9478,796.5082,554.3542,796.3772Z" />
                                    <path className="st4" d="M570.0386,787.9794a6.5216,6.5216,0,0,1,.65,3.7316c-.229.8971-1.1037,2.0859-3.2287,2.8583-.3994.1452-2.6738.9825-4.2354-.1016A5.4375,5.4375,0,0,1,561.43,791.62c-1.2535-3.5968-1.88-5.3952-3.1339-8.992,1.1444-.3988,1.7156-.6008,2.8561-1.01,1.1424,3.1861,1.7135,4.7792,2.8559,7.9654a10.7279,10.7279,0,0,0,1.2083,2.7122,1.6611,1.6611,0,0,0,2.5036-.7821,4.126,4.126,0,0,0-.3862-1.8194c-.1452-.394-.3187-.7995-.4639-1.1935-1.1646-3.1611-1.7469-4.7416-2.9116-7.9026,1.1532-.4249,1.7288-.64,2.8779-1.0751C568.1171,782.9059,568.7576,784.5971,570.0386,787.9794Z" />
                                    <path className="st4" d="M570.44,778.1353c1.5593-.6108,2.3369-.9209,3.8882-1.5507a5.09,5.09,0,0,1,3.1938-.4266,4.6767,4.6767,0,0,1,2.5815,2.6921c.3472.6882.6783,1.3853.9814,2.1043.4044.9593.7745,1.9549,1.1385,2.9284a4.2871,4.2871,0,0,1,.3,3.2365c-.5207,1.41-1.7167,2.0353-3.0691,2.6266-1.5415.6248-2.3142.9327-3.8633,1.5395C573.5308,786.0254,572.5005,783.3954,570.44,778.1353Zm8.0429,9.3923c.2883-.1168,1.1291-.3509,1.0337-1.2849a17.68,17.68,0,0,0-1.0616-3.11c-.3953-.9631-.8162-1.9075-1.23-2.8257-.3733-.8285-.5944-1.312-1.0692-1.4648a1.7035,1.7035,0,0,0-1.1425.12c-.3288.1332-.4932.1995-.8223.3319,1.379,3.43,2.0686,5.1444,3.4475,8.574C577.9764,787.7325,578.1453,787.6643,578.4828,787.5276Z" />
                                    <path className="st4" d="M592.3948,784.2c-.2045-.452-.3067-.678-.5111-1.13a2.907,2.907,0,0,1-.0986.5931,3.3489,3.3489,0,0,1-1.8718,1.8881,4.6611,4.6611,0,0,1-2.1809.3679,2.5225,2.5225,0,0,1-1.04-.3768c-.9507-.57-1.399-1.4459-2.2174-3.2922-.2726-.6147-.5494-1.2222-.81-1.8286-.3954-.9186-.787-1.853-1.1269-2.7822a5.2734,5.2734,0,0,1-.4306-2.9073c.4437-1.8492,2.5773-2.7925,2.9291-2.95a5.8685,5.8685,0,0,1,.9919-.3572,3.8994,3.8994,0,0,1,3.1313.443,5.8527,5.8527,0,0,1,1.3949,1.7357c.1464.261.2719.5359.4251.8169-1.1055.5107-1.6594.7635-2.7694,1.2644l-.2466-.5466a3.9476,3.9476,0,0,0-.7174-1.1687,1.2531,1.2531,0,0,0-1.4378-.144,1.2833,1.2833,0,0,0-.7685.9324c-.1245.6817.2824,1.6026.8879,2.975.5846,1.3252.8768,1.9878,1.4614,3.3131a4.9754,4.9754,0,0,0,1.2244,1.952,1.39,1.39,0,0,0,1.3032.0344,1.3653,1.3653,0,0,0,.8274-1.029,4.7825,4.7825,0,0,0-.654-2.0972c-.0987-.219-.185-.465-.2918-.7018-.5935.2678-.8906.401-1.4854.6661-.3712-.8332-.5569-1.25-.9282-2.0829,1.6686-.7435,2.5-1.1208,4.159-1.8862,1.3244,2.87,1.9866,4.3045,3.3109,7.1742C593.8725,783.5292,593.3805,783.7539,592.3948,784.2Z" />
                                    <path className="st4" d="M598.4344,781.4c-2.4241-5.1026-3.6362-7.654-6.06-12.7566,2.5816-1.2265,3.8662-1.8533,6.4217-3.1337l1.147,2.2892c-1.4616.7323-2.1945,1.0941-3.6646,1.8089.5319,1.094.7978,1.641,1.33,2.7349,1.3262-.6448,1.9875-.9707,3.3067-1.63l1.1171,2.2365c-1.3287.6637-1.9949.992-3.3305,1.6414.6019,1.238.9029,1.857,1.5049,3.0949,1.5085-.7335,2.2607-1.1047,3.76-1.8562.4623.9227.6934,1.384,1.1558,2.3067C602.4608,779.47,601.1231,780.1223,598.4344,781.4Z" />
                                    <path className="st4" d="M608.7657,776.2842c-2.1448-4.1606-3.2172-6.2408-5.3621-10.4013-.8914.46-1.3379.6876-2.2325,1.1406l-1.0936-2.16c2.8721-1.4541,4.3-2.1982,7.1373-3.72l1.1443,2.1334c-.8765.47-1.3156.7036-2.1953,1.1673,2.1826,4.1409,3.2738,6.2113,5.4563,10.3522C610.481,775.3966,609.91,775.6942,608.7657,776.2842Z" />
                                    <path className="st4" d="M615.639,772.6449l-6.745-12.4083c1.0861-.59,1.6279-.8881,2.7087-1.4884,2.743,4.9386,4.1145,7.4079,6.8574,12.3466C617.3344,771.72,616.77,772.03,615.639,772.6449Z" />
                                    <path className="st4" d="M627.7458,765.7524c-2.5278-2.0057-3.7825-3.0169-6.2729-5.0553-.3246-.25-.6237-.4951-.9242-.7575-.52-.4539-1.0028-.9438-1.5038-1.4162.3164.3718.6069.7368.8734,1.112.2895.4077.559.8318.84,1.27,1.5985,2.6433,2.3982,3.9647,3.9988,6.6069-1.0821.63-1.6245.9429-2.712,1.5635-2.8-4.9065-4.2-7.36-7-12.2662,1.246-.711,1.8672-1.07,3.1058-1.7941,2.2766,1.8852,3.4229,2.821,5.7313,4.6785.4751.37.96.76,1.4089,1.1635.4341.39.8878.7971,1.3186,1.2153-.3536-.4178-.7116-.8373-1.021-1.2771-.1945-.2765-.3867-.5607-.57-.824-1.631-2.6422-2.446-3.9635-4.0753-6.6067,1.018-.6084,1.5258-.9148,2.5387-1.5321l7.35,12.06C629.6009,764.6433,628.9837,765.0152,627.7458,765.7524Z" />
                                    <path className="st4" d="M639.2241,758.6165l-.6748-1.0406a2.9056,2.9056,0,0,1-.0091.6016,3.3594,3.3594,0,0,1-1.5717,2.15,4.674,4.674,0,0,1-2.1043.6928,2.5254,2.5254,0,0,1-1.0856-.216c-1.0262-.42-1.6011-1.2194-2.6869-2.922-.3615-.5668-.7263-1.126-1.0752-1.6864-.5285-.849-1.0557-1.7141-1.531-2.5819a5.2674,5.2674,0,0,1-.8607-2.8106c.163-1.8957,2.1348-3.1506,2.46-3.3591a5.8887,5.8887,0,0,1,.929-.503,3.91,3.91,0,0,1,3.1673-.0349,5.84,5.84,0,0,1,1.64,1.5062c.1839.2361.3493.4891.5429.7439-1.019.6723-1.53,1.0061-2.5544,1.6692l-.3258-.5034a3.9388,3.9388,0,0,0-.8848-1.0478,1.2559,1.2559,0,0,0-1.4453.0746,1.287,1.287,0,0,0-.6218,1.0382c-.0215.693.5189,1.5425,1.3232,2.8087.7766,1.2226,1.165,1.8339,1.9416,3.0566a4.9658,4.9658,0,0,0,1.5037,1.746,1.3934,1.3934,0,0,0,1.2955-.1626,1.3686,1.3686,0,0,0,.6658-1.1427,4.7726,4.7726,0,0,0-.9605-1.9757c-.13-.2017-.2526-.4321-.3937-.65-.5478.3545-.8222.5312-1.3716.8831-.4919-.7681-.7379-1.1521-1.23-1.92,1.5413-.9872,2.3089-1.4861,3.8377-2.494l4.3492,6.5966C640.5876,757.73,640.1339,758.0266,639.2241,758.6165Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                    <path className="st4" d="M549.34,763.17l4.07-1.56l0.57-0.17l1.12-0.15l0.72,0.13l0.64,0.31l0.55,0.5l0.96,1.82l0.33,1.2l-0.04,1.15
			l-0.33,0.83l-0.43,0.36l0.88-0.04l0.63,0.23l0.52,0.47l0.93,1.72l0.45,1.89l-0.24,1.21l-0.4,0.65l-0.5,0.47l-1.21,0.62l-4.38,1.68
			L549.34,763.17z M554.62,767.4l0.69-0.37l0.24-0.29l0.14-0.4l-0.39-1.37l-0.34-0.64l-0.76-0.31l-0.72,0.13l-0.76,0.29l1.18,3.24
			L554.62,767.4z M556.85,772.94l0.74-0.41l0.3-0.48l-0.05-0.8l-0.23-0.77l-0.67-1.05l-0.58-0.14l-0.83,0.19l-0.88,0.34l1.27,3.48
			L556.85,772.94z"/>
                                    <path className="st4" d="M569.72,764.78l0.63,1.88l0.17,0.93l-0.06,0.91l-0.24,0.79l-0.55,0.73l-0.82,0.76l-1.22,0.59l-1.56,0.5
			l-1.2-0.02l-1.14-0.5l-0.7-0.55l-0.46-0.67l-0.68-1.56l-3.5-8.86l2.68-1.12l3.12,7.87l0.7,1.7l0.56,0.98l0.45,0.25l0.39,0.05
			l0.59-0.13l0.43-0.29l0.41-0.69l-0.11-0.73l-0.34-1.07l-0.47-1.19l-3.12-7.83l2.72-1.13L569.72,764.78z"/>
                                    <path className="st4" d="M568.28,755.52l3.64-1.64l1.53-0.49l1.51,0.04l0.91,0.45l0.67,0.67l0.96,1.56l0.97,2.11l1.16,2.92l0.54,1.93
			l-0.14,1.29l-0.46,0.95l-0.62,0.7l-1.64,0.96l-3.47,1.56L568.28,755.52z M576.3,764.77l0.57-0.34l0.25-0.35l0.1-0.59l-1.14-3.08
			l-1.25-2.82l-0.52-1l-0.55-0.45l-0.56-0.01l-0.52,0.15l-0.77,0.34l3.64,8.5L576.3,764.77z"/>
                                    <path className="st4" d="M588.75,761.58l-0.51-1.11l-0.09,0.58l-0.46,0.94l-0.45,0.54l-0.72,0.4l-0.77,0.34l-1.22,0.05l-0.99-0.36
			l-1.19-1.11l-1.1-2.11l-0.88-1.8l-1.24-2.74l-0.51-1.4l-0.07-1.48l0.49-1.25l0.82-0.9l1.33-0.87l0.93-0.38l1.22-0.16l0.9,0.13
			l0.89,0.43l0.65,0.64l0.74,1.08l0.43,0.81l-2.59,1.29l-0.26-0.54l-0.71-1.16l-0.54-0.22l-0.84,0.1l-0.4,0.31l-0.29,0.63l0.25,1.24
			l0.75,1.71l1.56,3.27l0.54,1.05l0.71,0.88l0.58,0.18l0.64-0.14l0.47-0.44l0.23-0.61l-0.28-1.07l-0.44-1.01l-0.33-0.69l-1.34,0.67
			l-0.98-2.06l3.83-1.91l3.4,7.13L588.75,761.58z"/>
                                    <path className="st4" d="M592.97,759.51l-6.25-12.65l6.07-3.13l1.13,2.29l-3.44,1.78l1.34,2.72l3.08-1.59l1.11,2.24l-3.08,1.59
			l1.52,3.08l3.44-1.78l1.14,2.31L592.97,759.51z"/>
                                    <path className="st4" d="M601.93,754.82l-5.4-10.36l-2.09,1.14l-1.12-2.14l6.76-3.68l1.12,2.14l-2.09,1.14l5.4,10.36L601.93,754.82z"
                                    />
                                    <path className="st4" d="M615.88,741.01l-0.01-1.95l2.55-1.53l-0.09,4.62l0.59,0.11l0.84-0.34l0.25-0.15l1.17,2.02l-1.38,0.9
			l-1.11,0.22l-0.78-0.16l-0.35,0.91l-0.99,1.25l-0.8,0.55l-1.12,0.56l-0.9,0.12l-0.93-0.14l-0.8-0.29l-0.7-0.51l-1.01-1.3
			l-0.4-0.81l-0.17-1.29l0.06-1.01l0.38-1.53l-1.37-0.6l-0.92-0.7l-0.71-0.95l-0.4-0.99l-0.05-0.74l0.22-0.85l0.54-0.95l1.27-1.04
			l1.03-0.48l0.7-0.09l1.32,0.14l1.02,0.74l0.5,0.66l0.4,1.18l0.06,1.67l-0.43,1.56L615.88,741.01z M611.33,737.67l-0.2-1.09
			l-0.64-0.5l-0.77,0.07l-0.48,0.68l0.1,0.75l0.66,0.61l1.12,0.59L611.33,737.67z M612.42,742.27l-0.11,0.97l0.07,0.57l0.26,0.66
			l0.47,0.6l0.41,0.29l0.53,0.15l0.87-0.17l0.42-0.39l0.39-1.21L612.42,742.27z"/>
                                </g>
                            <g>
                                <path className="st4" d="M541.62,792.08l4.07-1.45l0.57-0.16l1.12-0.12l0.72,0.15l0.63,0.33l0.54,0.51l0.93,1.83l0.31,1.21l-0.05,1.15
			l-0.34,0.82l-0.44,0.35l0.88-0.01l0.62,0.25l0.51,0.48l0.9,1.74l0.42,1.89l-0.25,1.2l-0.4,0.63l-0.51,0.46l-1.22,0.58l-4.37,1.56
			L541.62,792.08z M546.81,796.44l0.69-0.35l0.25-0.28l0.14-0.39l-0.37-1.38l-0.33-0.65l-0.76-0.33l-0.71,0.11l-0.76,0.27l1.12,3.26
			L546.81,796.44z M548.95,802.01l0.74-0.39l0.31-0.47l-0.04-0.79l-0.22-0.77l-0.65-1.07l-0.57-0.16l-0.82,0.17l-0.88,0.31l1.21,3.5
			L548.95,802.01z"/>
                                <path className="st4" d="M561.84,794.25l0.6,1.89l0.15,0.93l-0.07,0.91l-0.25,0.78l-0.56,0.71l-0.83,0.73l-1.22,0.56l-1.56,0.45
			l-1.19-0.06l-1.13-0.53l-0.69-0.57l-0.45-0.68l-0.65-1.57l-3.33-8.93l2.69-1.03l2.96,7.94l0.66,1.72l0.54,0.99l0.44,0.26
			l0.39,0.06l0.58-0.12l0.44-0.27l0.42-0.68l-0.1-0.73l-0.32-1.08l-0.45-1.2l-2.96-7.89l2.72-1.05L561.84,794.25z"/>
                                <path className="st4" d="M560.54,785l3.65-1.52l1.53-0.44l1.5,0.09l0.89,0.47l0.65,0.68l0.92,1.59l0.92,2.13l1.1,2.95l0.5,1.94
			l-0.16,1.28l-0.48,0.93l-0.63,0.67l-1.65,0.9l-3.48,1.45L560.54,785z M568.33,794.47l0.57-0.32l0.25-0.34l0.11-0.59l-1.08-3.11
			l-1.19-2.84l-0.49-1.01l-0.54-0.47l-0.56-0.03l-0.52,0.13l-0.77,0.32l3.45,8.58L568.33,794.47z"/>
                                <path className="st4" d="M580.66,791.79l-0.46-1.13l-0.11,0.57l-0.49,0.92l-0.46,0.51l-0.72,0.37l-0.78,0.3l-1.22-0.01l-0.97-0.4
			l-1.14-1.16l-1.02-2.15l-0.81-1.83l-1.13-2.78l-0.46-1.42l-0.02-1.48l0.53-1.22l0.84-0.86l1.36-0.8l0.94-0.33l1.21-0.1l0.89,0.17
			l0.87,0.47l0.62,0.67l0.7,1.11l0.4,0.83l-2.61,1.16l-0.24-0.55l-0.67-1.19l-0.53-0.25l-0.83,0.06l-0.4,0.29l-0.31,0.62l0.21,1.25
			l0.68,1.73l1.43,3.33l0.5,1.07l0.68,0.9l0.57,0.2l0.64-0.11l0.48-0.41l0.25-0.59l-0.24-1.08l-0.4-1.03l-0.3-0.7l-1.35,0.6
			l-0.9-2.1l3.88-1.72l3.13,7.26L580.66,791.79z"/>
                                <path className="st4" d="M584.98,789.83L579.1,777l6.11-2.88l1.07,2.33l-3.47,1.64l1.27,2.76l3.1-1.46l1.04,2.27l-3.1,1.46l1.43,3.13
			l3.47-1.64l1.08,2.34L584.98,789.83z"/>
                                <path className="st4" d="M593.76,785.61l-4.92-10.62l-2.12,1.01l-1.02-2.2l6.87-3.27l1.02,2.2l-2.12,1.01l4.92,10.62L593.76,785.61z"
                                />
                                <path className="st4" d="M599.82,782.61l-6.34-12.62l2.6-1.34l6.34,12.62L599.82,782.61z" />
                                <path className="st4" d="M604.51,780.18l-6.54-12.51l5.96-3.19l1.19,2.27l-3.38,1.81l1.41,2.69l3.02-1.62l1.16,2.21l-3.02,1.62
			l1.59,3.05l3.38-1.81l1.2,2.29L604.51,780.18z"/>
                                <path className="st4" d="M617.37,773.12l-0.44-0.33l-1.12-1.53l-0.4-0.72l-0.7-1.14l-0.28-0.3l-0.53-0.3l-0.49,0.07l-0.42,0.17
			l-0.94,0.53l2.75,4.99l-2.58,1.46l-6.81-12.36l4.67-2.64l0.96-0.36l0.73-0.07l0.79,0.13l1.02,0.64l1.04,1.43l0.42,0.84l0.42,1.14
			l-0.07,1.17l-0.5,0.77l1.03,0.02l0.89,0.6l0.55,0.88l0.12,0.23l1.2,1.85l0.95,1.05l0.42,0.27L617.37,773.12z M612.39,766.68
			l0.29-0.32l0.13-0.6l-0.46-1.05l-0.46-0.75l-0.33-0.3l-0.58-0.25l-1.03,0.45l-0.81,0.46l1.77,3.2L612.39,766.68z"/>
                                <path className="st4" d="M626.08,762.08l0.91,1.76l0.31,0.89l0.08,0.9l-0.11,0.81l-0.43,0.79l-0.7,0.86l-1.11,0.76l-1.46,0.71
			l-1.18,0.15l-1.2-0.33l-0.78-0.44l-0.56-0.6l-0.91-1.44l-4.81-8.21l2.47-1.48l4.27,7.3l0.95,1.58l0.71,0.88l0.48,0.18l0.4,0
			l0.55-0.22l0.38-0.35l0.3-0.74l-0.22-0.71l-0.5-1.01l-0.65-1.1l-4.27-7.25l2.51-1.5L626.08,762.08z"/>
                                <path className="st4" d="M635.74,761.93l-6.16-4.93l-0.93-0.8l-1.48-1.33l0.87,1.05l0.88,1.26l4.15,6.47l-2.37,1.53l-7.48-11.98
			l2.89-1.82l5.66,4.59l1.42,1.16l1.27,1.2l-1.02-1.25l-0.58-0.82l-4.18-6.52l2.36-1.51l7.45,11.97L635.74,761.93z"/>
                                <path className="st4" d="M645.3,755.66l-0.65-1.03l0,0.58l-0.32,0.99l-0.37,0.59l-0.65,0.49l-0.71,0.43l-1.2,0.21l-1.02-0.22
			l-1.33-0.94l-1.38-1.94l-1.12-1.66l-1.6-2.53l-0.7-1.31l-0.28-1.45l0.31-1.29l0.68-0.99l1.2-1.02l0.87-0.49l1.18-0.31l0.91,0.01
			l0.93,0.31l0.73,0.54l0.88,0.97l0.54,0.74l-2.38,1.6l-0.33-0.5l-0.87-1.05l-0.56-0.15l-0.81,0.21l-0.35,0.36l-0.2,0.66l0.42,1.19
			l0.97,1.58l1.99,3.02l0.68,0.96l0.83,0.77l0.6,0.1l0.61-0.22l0.41-0.49l0.15-0.63l-0.43-1.01l-0.57-0.94l-0.42-0.63l-1.23,0.83
			l-1.26-1.9l3.52-2.38l4.35,6.58L645.3,755.66z"/>
                            </g>
                            </>}
                        </g>
                        <g id="Segment_-_9" className={"model-option" + (this.state.changed ? this.setStyles(11) : '')}>
                            <path id="Circle_-_2-2_8_" className="st0" d="M391.65,572.08l-38.92,170.39c46.83,10.45,96.37,11.28,144.85,0.1l-38.89-170.5
		C436.16,577.32,413.45,576.88,391.65,572.08z"/>
                            <path id="Circle_-_2-1_8_" className="st1" d="M391.65,572.08l-38.92,170.39c46.83,10.45,96.37,11.28,144.85,0.1l-38.89-170.5
		C436.16,577.32,413.45,576.88,391.65,572.08z"/>
                            <path id="Circle_-_1-2_4_" className="st0" d="M352.73,742.47l-22.1,96.76c60.96,13.84,125.58,14.61,189.01,0.03l-22.05-96.69
		C449.01,753.74,399.53,752.91,352.73,742.47z"/>
                            <path id="Circle_-_1-1_5_" className="st2" d="M352.73,742.47l-22.1,96.76c60.96,13.84,125.58,14.61,189.01,0.03l-22.05-96.69
		C449.01,753.74,399.53,752.91,352.73,742.47z"/>
                            {SelectedLanguage === "en-GB" && <>
                                <g>
                                    <path className="st4" d="M383.2949,798.2959l1.0814-14.0816c1.8257.14,2.7388.2064,4.5654.3308a3.0591,3.0591,0,0,1,2.5884,1.6936,6.0138,6.0138,0,0,1,.3,2.965,11.1939,11.1939,0,0,1-.1692,1.4121,3.1386,3.1386,0,0,1-2.1583,2.809,5.9868,5.9868,0,0,1-2.2968.0834c-.2362-.0166-.3543-.0249-.5906-.0418l-.36,5.0487C385.0707,798.4307,384.4787,798.3868,383.2949,798.2959Zm4.2481-7.27a1.22,1.22,0,0,0,.7814-.1064c.38-.1943.5808-.7622.6387-1.6206a8.8964,8.8964,0,0,0,.0271-1.3019,1.4119,1.4119,0,0,0-.2523-.7591,1.2638,1.2638,0,0,0-.886-.3411c-.3058-.0214-.4587-.0322-.7645-.054-.1179,1.6521-.1768,2.4781-.2946,4.13C387.0928,790.9941,387.2429,791.0047,387.543,791.0257Z" />
                                    <path className="st4" d="M393.8812,799.0055l.8075-14.1c2.6691.1529,4.0041.2209,6.6745.34-.0457,1.0232-.0685,1.5348-.1142,2.558-1.5229-.068-2.2843-.1048-3.8068-.1837l-.1573,3.037c1.3692.0709,2.0538.1042,3.4234.1664l-.1135,2.4975c-1.3759-.0625-2.0637-.096-3.4393-.1673l-.1781,3.4368c1.5481.08,2.3223.1176,3.8707.1868-.046,1.031-.069,1.5465-.1151,2.5775C397.9916,799.2322,396.6212,799.1624,393.8812,799.0055Z" />
                                    <path className="st4" d="M410.0758,799.6919a5.1333,5.1333,0,0,1-.2333-.4871,5.5527,5.5527,0,0,1-.1931-1.9066l.0233-.82a10.576,10.576,0,0,0-.0232-1.3411,1.3669,1.3669,0,0,0-.4016-.9319,1.3085,1.3085,0,0,0-.91-.2678c-.4318-.0134-.6477-.02-1.08-.0346-.0753,2.2792-.1129,3.4189-.1882,5.6981-1.2024-.04-1.8036-.0613-3.0058-.1076.2177-5.645.3265-8.4675.5441-14.1126,1.8929.073,2.8395.1053,4.7329.1614.1806.0054.385.0164.5609.0368a3.409,3.409,0,0,1,2.2642,1,3.9628,3.9628,0,0,1,.7391,2.8394c-.0068.3-.0316.6227-.0618.9383a3.1322,3.1322,0,0,1-.8215,2.1619,1.7081,1.7081,0,0,1-.8644.3984,1.6212,1.6212,0,0,1,.9021.5432,2.7235,2.7235,0,0,1,.5038,2.0125l-.0061.2607a11.4129,11.4129,0,0,0,.4109,3.59,2.3482,2.3482,0,0,0,.237.4457C411.9535,799.7418,411.3276,799.7263,410.0758,799.6919Zm-1.8068-8.1181a5.9647,5.9647,0,0,0,.7716.003.8575.8575,0,0,0,.8279-.5562,3.7678,3.7678,0,0,0,.1335-1.1363,8.6684,8.6684,0,0,0-.0157-.8817c-.0179-.0806-.0409-.7014-.4978-.9346a3.516,3.516,0,0,0-1.1049-.1536c-.3706-.0116-.5559-.0175-.9264-.03-.0484,1.4637-.0726,2.1955-.1209,3.6592C407.7094,791.5561,407.8959,791.5621,408.269,791.5738Z" />
                                    <path className="st4" d="M420.295,789.835l.0024-.26a8.843,8.843,0,0,0-.032-.9408c-.101-.8723-.6149-1.0263-1.0173-1.0307a1.03,1.03,0,0,0-.85.31,1.042,1.042,0,0,0-.2267.417,2.4487,2.4487,0,0,0-.0681.5793c-.0113.86.3053,1.2456,1.5125,2.0783.4827.349.7244.5233,1.2086.8713a7.5407,7.5407,0,0,1,1.0308.7474,3.9894,3.9894,0,0,1,1.5333,3.428,4.5536,4.5536,0,0,1-.7686,2.7168,2.7035,2.7035,0,0,1-1.0331.8146,5.647,5.647,0,0,1-2.5992.4977,4.1385,4.1385,0,0,1-3.2324-1.3662,6.1088,6.1088,0,0,1-.6124-3.5925c1.127.0207,1.69.03,2.8176.0444-.0053.4-.0057.8251.0044,1.2209.02.7706.1288,1.082.4748,1.3265a1.0867,1.0867,0,0,0,.7362.2282,1.1339,1.1339,0,0,0,1.1555-.7289,2.5289,2.5289,0,0,0,.11-.879,3.1657,3.1657,0,0,0-.0943-.9207c-.1385-.5218-.5441-.8452-1.4139-1.4148-.5176-.35-.776-.5254-1.2919-.8767-.3018-.2249-.6241-.4494-.9044-.694a3.698,3.698,0,0,1-1.365-3.1641,5.27,5.27,0,0,1,.1424-1.1773,3.2855,3.2855,0,0,1,1.8059-2.2121,5.0006,5.0006,0,0,1,2.131-.3325,3.8105,3.8105,0,0,1,2.6818.9211,2.7173,2.7173,0,0,1,.5191.783,6.1324,6.1324,0,0,1,.372,2.6226C421.9326,789.8483,421.3867,789.8448,420.295,789.835Z" />
                                    <path className="st4" d="M434.3805,795.4976c-.0449,2.2992-.5418,3.2632-1.58,3.9061a5.0936,5.0936,0,0,1-2.5867.671,4.8815,4.8815,0,0,1-2.8467-.7815,2.688,2.688,0,0,1-.66-.6373,4.33,4.33,0,0,1-.6607-1.7787,34.1263,34.1263,0,0,1-.19-4.5612c-.0011-.76.0094-1.5494.0372-2.3a9.7731,9.7731,0,0,1,.079-1.16,3.2694,3.2694,0,0,1,2.4623-3.07,5.6231,5.6231,0,0,1,1.6833-.2535,3.9193,3.9193,0,0,1,3.2906,1.3593,3.9715,3.9715,0,0,1,.7128,1.7484,30.6639,30.6639,0,0,1,.2237,3.6178C434.37,793.331,434.4016,794.4182,434.3805,795.4976Zm-3.04-3.0767c-.0084-.7206-.0263-1.43-.0246-2.1209a6.2607,6.2607,0,0,0-.0729-1.0989c-.0018-.16-.127-1.4481-1.2248-1.4278a.9915.9915,0,0,0-.663.2059c-.5209.4036-.5287,1.2309-.552,1.804-.0274.6732-.031,1.3811-.0261,2.1008.0073,1.06.0377,2.1184.0625,3.1608a5.45,5.45,0,0,0,.2821,2.1581,1.1564,1.1564,0,0,0,2.09-.0195,11.2081,11.2081,0,0,0,.1507-2.8827C431.3532,793.5488,431.3489,793.1728,431.34,792.4209Z" />
                                    <path className="st4" d="M443.6549,799.5829c-1.1566-2.9792-1.7276-4.47-2.8552-7.4555-.1528-.3755-.2881-.7337-.4175-1.1085-.2241-.6489-.4019-1.31-.6013-1.9644.0939.478.1687.9375.22,1.3948.056.4967.0891.9979.1235,1.5177.135,3.0861.2029,4.6291.3393,7.7152-1.1531.0324-1.73.0471-2.8829.0735-.1292-5.6477-.1939-8.4716-.323-14.1193,1.34-.0307,2.01-.0481,3.35-.0872,1.0275,2.7477,1.5474,4.12,2.6,6.8631.2222.5534.4439,1.13.629,1.7.179.5518.3664,1.1276.53,1.7022-.101-.5368-.2068-1.0777-.2616-1.6119-.0344-.3357-.0646-.6778-.0957-.9972-.15-3.1013-.2244-4.652-.3729-7.7534,1.1071-.0386,1.6605-.0594,2.7674-.1038.2262,5.6447.3394,8.467.5658,14.1117C445.6436,799.5133,444.9807,799.5378,443.6549,799.5829Z" />
                                    <path className="st4" d="M456.0411,799.0207c-.368-1.5418-.551-2.3128-.9151-3.8551-.94.0521-1.4095.0771-2.3493.1252-.19,1.5719-.2859,2.3578-.48,3.93-1.2263.0612-1.8395.09-3.0661.1443,1.009-5.7154,1.4971-8.5743,2.4406-14.2938,1.427-.0714,2.14-.11,3.5669-.1905,1.5074,5.5888,2.2768,8.3806,3.8472,13.9589C457.8679,798.9153,457.259,798.9516,456.0411,799.0207Zm-2.12-9.4794c-.1394-.7133-.2793-1.4275-.3775-2.143-.0217.7219-.0848,1.4469-.1475,2.171-.1469,1.362-.2211,2.0429-.3707,3.4049.6426-.0333.964-.05,1.6066-.0857C454.3465,791.55,454.2043,790.88,453.9213,789.5413Z" />
                                    <path className="st4" d="M461.3442,798.6937c-.3751-5.6367-.5627-8.4551-.9377-14.0919,1.1377-.0757,1.7065-.1151,2.844-.1969.3329,4.6291.4994,6.9436.8324,11.5727,1.6617-.12,2.4924-.1825,4.1533-.3148.08,1.005.12,1.5075.2,2.5125C465.6006,798.4012,464.182,798.5049,461.3442,798.6937Z" />
                                    <path className="st4" d="M354.5237,817.4968c-.1608-2.9314-.2378-4.397-.3852-7.3279a15.8017,15.8017,0,0,1,.0968-3.0927c-.1538.7059-.289,1.4361-.4892,2.1554-.1569.564-.3161,1.1291-.4957,1.6908-.8224,2.4595-1.2364,3.6887-2.07,6.1456-1.3966-.1833-2.0946-.2772-3.49-.4692-.2768-5.74-.402-8.61-.6262-14.3482,1.1613.1652,1.7421.2463,2.904.4054.0821,3.4761.1274,5.2144.2266,8.6914-.0058.3628-.01.7266-.0387,1.0845-.0349.4372-.0933.8556-.1279,1.2746.1063-.6321.2166-1.2834.3845-1.9062.173-.6415.3622-1.263.552-1.8839.8946-2.73,1.3387-4.0963,2.22-6.8295,1.2954.17,1.9433.2525,3.2394.4145.1,2.98.1532,4.47.2658,7.4505.0151.7477.0235,1.5082-.0169,2.2353-.025.4506-.0669.8989-.1228,1.3554.1323-.73.309-1.4734.4615-2.2005.97-3.3883,1.4508-5.0834,2.404-8.4751,1.1139.133,1.671.1981,2.7855.3255-1.6645,5.4958-2.51,8.241-4.2259,13.7258C356.5944,817.7539,355.904,817.67,354.5237,817.4968Z" />
                                    <path className="st4" d="M371.5452,815.0275c-.3058,2.2791-.9289,3.1794-2.0757,3.6978a5.4836,5.4836,0,0,1-2.7531.366,5.2437,5.2437,0,0,1-2.8591-1.1081,2.7491,2.7491,0,0,1-.612-.71,4.2174,4.2174,0,0,1-.4846-1.8446,32.7962,32.7962,0,0,1,.3143-4.5547c.0841-.7551.1836-1.5389.2965-2.281a9.3588,9.3588,0,0,1,.2121-1.1437,3.3042,3.3042,0,0,1,2.8965-2.7635,6.0915,6.0915,0,0,1,1.7724-.0557,4.1579,4.1579,0,0,1,3.2562,1.7334,3.8689,3.8689,0,0,1,.5418,1.82,29.5318,29.5318,0,0,1-.1764,3.6207C371.779,812.873,371.6888,813.9575,371.5452,815.0275Zm-2.8009-3.41c.0724-.7171.1335-1.4251.2132-2.11a5.9582,5.9582,0,0,0,.0477-1.1c.0161-.1593.0318-1.4536-1.1082-1.5613a1.0644,1.0644,0,0,0-.71.1274c-.5847.34-.686,1.162-.7742,1.7282-.1036.6653-.1873,1.3688-.2631,2.0845-.1116,1.0546-.1986,2.11-.29,3.148a5.2377,5.2377,0,0,0,.049,2.1774,1.2274,1.2274,0,0,0,2.1665.2239,10.8116,10.8116,0,0,0,.4805-2.847Z" />
                                    <path className="st4" d="M380.6174,820.1542a5.0608,5.0608,0,0,1-.2184-.4992,5.3526,5.3526,0,0,1-.1059-1.9147l.065-.8177a10.1942,10.1942,0,0,0,.0425-1.3407,1.3406,1.3406,0,0,0-.3714-.9526,1.4047,1.4047,0,0,0-.9331-.3168c-.4484-.0368-.6727-.0555-1.1211-.0932-.1911,2.2724-.2867,3.4087-.4778,5.6811-1.2481-.105-1.872-.1592-3.12-.271.5045-5.6267.7568-8.44,1.2613-14.0666,1.9664.1763,2.95.26,4.9175.4188.1877.0151.4.0373.5819.0672a3.6387,3.6387,0,0,1,2.3062,1.1209,3.8564,3.8564,0,0,1,.6275,2.8759c-.0221.299-.0641.62-.1112.9338a3.0569,3.0569,0,0,1-.9624,2.1149,1.8121,1.8121,0,0,1-.9188.3513,1.71,1.71,0,0,1,.9112.5913,2.6452,2.6452,0,0,1,.4234,2.0371l-.0192.26a10.9958,10.9958,0,0,0,.2479,3.608,2.3279,2.3279,0,0,0,.224.458C382.5669,820.3055,381.917,820.2563,380.6174,820.1542Zm-1.4758-8.2063a6.4538,6.4538,0,0,0,.8024.0449.8939.8939,0,0,0,.8888-.5107,3.6206,3.6206,0,0,0,.1953-1.1277,8.32,8.32,0,0,0,.0276-.8815,1.1678,1.1678,0,0,0-.4716-.96,3.8079,3.8079,0,0,0-1.142-.2134c-.385-.0317-.5774-.0477-.9624-.08l-.3068,3.6483C378.56,811.9,378.7541,811.916,379.1416,811.9479Z" />
                                    <path className="st4" d="M393.32,821.0089c-1.291-2.8614-1.9274-4.294-3.1825-7.1626-.1482,2.781-.2221,4.1714-.37,6.9524-1.2239-.0763-1.8357-.116-3.0593-.1989.3819-5.6363.5728-8.4545.9547-14.0908,1.168.0792,1.7521.1172,2.9205.19l-.3965,6.349c1.4162-2.4611,2.1173-3.6938,3.5052-6.1636,1.3355.0758,2.0034.1117,3.3392.1795-1.5952,2.5709-2.4014,3.8535-4.0312,6.4128,1.45,3.0889,2.1859,4.6311,3.68,7.71C395.336,821.12,394.664,821.0842,393.32,821.0089Z" />
                                    <path className="st4" d="M398.6251,821.2813c.2633-5.6431.3949-8.4646.6582-14.1077,1.2026.0561,1.8039.0826,3.0067.1321-.2327,5.6445-.349,8.4667-.5816,14.1111C400.4749,821.3659,399.8583,821.3388,398.6251,821.2813Z" />
                                    <path className="st4" d="M411.9655,821.7467c-1.027-3.0413-1.5332-4.5632-2.531-7.609-.1374-.3839-.2565-.7494-.3692-1.1311-.1951-.6606-.3415-1.3307-.5106-1.9962.0693.4828.1207.946.1477,1.4054.0292.4991.034,1.0014.04,1.5223-.0394,3.0888-.0588,4.6333-.0968,7.7221-1.2-.0347-1.7993-.0537-2.9986-.0948.1934-5.6459.29-8.4688.4836-14.1147,1.3951.0478,2.0927.07,3.4879.1085.9082,2.803,1.3685,4.2036,2.3015,7.0029.1985.5653.3953,1.1534.5541,1.7339.1535.5613.3141,1.1469.4507,1.73-.0736-.5417-.1514-1.0876-.1774-1.6243-.0164-.3374-.0275-.6805-.0417-1.0012.0261-3.1049.0395-4.6573.0667-7.7621,1.1532.0256,1.73.037,2.883.0566l-.2408,14.121C414.0349,821.7925,413.345,821.7786,411.9655,821.7467Z" />
                                    <path className="st4" d="M424.903,821.8985c0-.4961,0-.7442,0-1.24a2.9542,2.9542,0,0,1-.3214.5,3.1576,3.1576,0,0,1-2.38.9528,4.3341,4.3341,0,0,1-2.0529-.5542,2.4566,2.4566,0,0,1-.7633-.7669c-.611-.9066-.6633-1.8885-.6615-3.9087.0006-.6726-.006-1.34.0015-2,.0113-1,.0328-2.0121.0972-3a5.3682,5.3682,0,0,1,.7649-2.8319c1.1248-1.5094,3.3979-1.497,3.7741-1.4959a5.5362,5.5362,0,0,1,1.023.0824,3.7651,3.7651,0,0,1,2.5962,1.6974,5.9643,5.9643,0,0,1,.5331,2.1581c.0235.2977.0231.6005.0461.92-1.18.0053-1.77.0063-2.95.0053l0-.6a4.047,4.047,0,0,0-.1669-1.3605,1.2134,1.2134,0,0,0-1.2151-.7225,1.23,1.23,0,0,0-1.051.5355c-.3823.5721-.3874,1.5779-.3963,3.0779-.0086,1.4484-.0129,2.1726-.0214,3.621a5.1605,5.1605,0,0,0,.3066,2.2821,1.3194,1.3194,0,0,0,1.1282.5654,1.3,1.3,0,0,0,1.132-.5979,4.9557,4.9557,0,0,0,.2581-2.18c0-.24.0217-.5.0219-.76-.628,0-.942-.0013-1.57-.0036l.0083-2.28c1.7662.0065,2.6492.0062,4.4154-.0013.0133,3.1606.02,4.7408.0333,7.9013C426.4581,821.8978,425.94,821.8988,424.903,821.8985Z" />
                                    <path className="st4" d="M446.9412,821.4782c-.09-2.3506-.1357-3.5259-.2261-5.8765-.047-1.22-.1313-2.4746-.1208-3.6993.0059-.6923.0507-1.4034.0655-2.1047-.05.3225-.0939.6727-.1294,1.0058-.0527.4931-.109,1.0264-.173,1.5282-.0819.6424-.2055,1.2694-.3092,1.8939-.5252,2.9341-.7919,4.4009-1.3338,7.3339-1.1822.0409-1.7733.06-2.9557.0945-.7347-2.8281-1.098-4.2428-1.8165-7.0732-.19-.7755-.3776-1.5734-.5258-2.3679-.0965-.5174-.1874-1.0259-.2712-1.5336-.0578-.35-.1226-.7179-.1951-1.0765.033.46.0811.9042.118,1.3578.0755.9287.1423,1.868.1765,2.7972.0138.3752.0333.7671.05,1.14.0255.5886.0308,1.2194.0465,1.8393l.126,4.98c-1.1653.0295-1.748.0428-2.9134.0663-.1311-5.6477-.1964-8.4716-.3267-14.1193,1.73-.0348,2.5949-.0556,4.3246-.1039.7818,3.1482,1.1777,4.7216,1.9792,7.867.1483.6754.3147,1.3341.4239,2.0082.077.4754.1374.9561.2163,1.4342.0016-.6009.0638-1.1752.1144-1.7448.0383-.43.099-.8845.17-1.3265.578-3.35.8619-5.0247,1.42-8.3756,1.729-.0618,2.5934-.0961,4.322-.1714.2626,5.6431.3941,8.4647.6574,14.1078C448.6886,821.4094,448.1062,821.4333,446.9412,821.4782Z" />
                                    <path className="st4" d="M453.7881,821.1732c-.2853-5.642-.428-8.463-.7135-14.105,2.7806-.1406,4.1705-.22,6.95-.3953l.1616,2.5554c-1.5847.1-2.3771.1474-3.9622.2362.0681,1.2145.1021,1.8218.17,3.0363,1.425-.08,2.1374-.1221,3.562-.211l.1558,2.4951c-1.431.0893-2.1466.1317-3.578.2119.0771,1.3745.1155,2.0616.1926,3.4361,1.6107-.09,2.4159-.1383,4.0261-.24.0651,1.03.0976,1.545.1627,2.575C458.065,820.9479,456.64,821.029,453.7881,821.1732Z" />
                                    <path className="st4" d="M464.8265,820.507c-.3274-4.6694-.4911-7.0041-.8187-11.6735-.9718.0682-1.4577.1012-2.43.1651-.0635-.9663-.0953-1.4494-.1588-2.4157,3.1286-.2056,4.6923-.3195,7.8185-.5695l.193,2.4132c-.9627.0769-1.4441.1144-2.407.1872.3529,4.6675.5293,7.0013.8821,11.6688C466.6743,820.3757,466.0584,820.4206,464.8265,820.507Z" />
                                    <path className="st4" d="M478.0535,819.4239c-.2283-2.4378-.3425-3.6566-.5708-6.0944-1.0729.1-1.61.1494-2.6828.2448l.5416,6.0971c-1.2306.1093-1.846.1622-3.077.2648l-1.173-14.0742c1.2-.1,1.8-.1517,3-.2583.2011,2.2638.3017,3.3956.5027,5.6594,1.0689-.095,1.6032-.1437,2.6718-.2438-.212-2.2628-.3179-3.3941-.53-5.6569,1.2-.1123,1.7993-.17,2.9984-.289.5574,5.6216.8361,8.4324,1.3934,14.0541C479.8986,819.2494,479.2837,819.3087,478.0535,819.4239Z" />
                                    <path className="st4" d="M492.7222,813.5051c.1885,2.2919-.2282,3.3048-1.2382,4.0562a5.49,5.49,0,0,1-2.6122.9463,5.2435,5.2435,0,0,1-3.03-.4713,2.7652,2.7652,0,0,1-.75-.563,4.2211,4.2211,0,0,1-.867-1.6984,32.7856,32.7856,0,0,1-.6666-4.5166c-.0792-.7556-.1494-1.5419-.198-2.2917a9.2275,9.2275,0,0,1-.0373-1.1626,3.3032,3.3032,0,0,1,2.2382-3.3187,6.0977,6.0977,0,0,1,1.72-.4335,4.16,4.16,0,0,1,3.553.9971,3.8692,3.8692,0,0,1,.9183,1.6622,29.3943,29.3943,0,0,1,.6019,3.5747C492.4894,811.3507,492.6338,812.4291,492.7222,813.5051Zm-3.4673-2.7324c-.0825-.716-.174-1.42-.2428-2.1072a6.0158,6.0158,0,0,0-.1882-1.0852c-.0183-.1591-.28-1.4268-1.4167-1.2883a1.065,1.065,0,0,0-.6666.2763c-.499.4575-.4221,1.2815-.3874,1.8538.0407.6724.11,1.3772.1886,2.0926.1164,1.0541.2566,2.1039.3892,3.1373a5.2465,5.2465,0,0,0,.5139,2.1165,1.2276,1.2276,0,0,0,2.1644-.2445,10.7677,10.7677,0,0,0-.1392-2.8838C489.384,811.8932,489.3409,811.52,489.2549,810.7727Z" />
                                    <path className="st4" d="M494.7,803.3838c1.62-.2053,2.43-.3109,4.0489-.5281a4.8431,4.8431,0,0,1,3.1217.4,4.7356,4.7356,0,0,1,1.7776,3.2643c.1591.7545.3007,1.5128.4106,2.2853.1467,1.0306.2524,2.0875.358,3.1213a4.4,4.4,0,0,1-.4969,3.2016c-.8228,1.227-2.0854,1.5228-3.4838,1.75-1.5839.212-2.376.3152-3.961.516Zm5.3508,11.1041a1.188,1.188,0,0,0,1.27-.9784,18.4552,18.4552,0,0,0-.2538-3.276c-.1409-1.0315-.31-2.05-.48-3.0441-.1531-.8965-.2442-1.42-.6544-1.6879a1.62,1.62,0,0,0-1.1063-.1732c-.342.0457-.5129.0684-.8549.1136.4843,3.6646.7264,5.497,1.2107,9.1616C499.5294,814.5575,499.7032,814.5344,500.0508,814.4879Z" />
                                </g>
                            </>}
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                    <path className="st4" d="M374.74,796.14l1.95-14.03l4.63,0.56l1.22,0.41l0.75,0.56l0.54,0.89l0.23,1.2l-0.14,1.78l-0.28,1.4
			l-0.32,0.97l-0.48,0.79l-0.68,0.58l-0.89,0.33l-0.96,0.11l-1.34-0.14l-0.59-0.07l-0.7,5.02L374.74,796.14z M379.48,789.07
			l0.77-0.07l0.45-0.55l0.29-1.02l0.14-1.31l-0.21-0.75l-0.42-0.27l-0.46-0.12l-0.77-0.09l-0.57,4.11L379.48,789.07z"/>
                                    <path className="st4" d="M384.34,797.27l1.61-14.05l6.78,0.66l-0.29,2.55l-3.85-0.37l-0.35,3.03l3.44,0.33l-0.28,2.49l-3.44-0.33
			l-0.39,3.42l3.85,0.37l-0.29,2.57L384.34,797.27z"/>
                                    <path className="st4" d="M399.15,798.53l-0.18-0.51l-0.08-1.89l0.07-0.82l0.06-1.34l-0.07-0.41l-0.28-0.54l-0.45-0.21l-0.44-0.09
			l-1.08-0.08l-0.51,5.69l-2.98-0.21l1.25-14.09l5.39,0.38l1.01,0.23l0.65,0.35l0.59,0.54l0.5,1.1l0.07,1.77l-0.12,0.93l-0.29,1.18
			l-0.72,0.93l-0.85,0.36l0.85,0.58l0.4,0.99l-0.03,1.04l-0.02,0.26l-0.03,2.21l0.2,1.4l0.21,0.46L399.15,798.53z M398.6,790.4
			l0.42-0.11l0.45-0.43l0.2-1.13l0.04-0.88l-0.11-0.43l-0.34-0.53l-1.11-0.2l-0.94-0.07l-0.33,3.65L398.6,790.4z"/>
                                    <path className="st4" d="M409.02,788.97l0-0.26l0.04-0.94l-0.4-0.82l-0.58-0.25l-0.39,0.06l-0.49,0.24l-0.25,0.39l-0.1,0.58l0.02,0.6
			l0.26,0.45l1.12,1.09l1.2,0.89l0.98,0.8l0.97,1.24l0.31,0.98l0.07,1.26l-0.25,1.43l-0.67,1.27l-1.07,0.75l-1.59,0.45l-1.02-0.04
			l-1.45-0.18l-0.96-0.46l-0.75-0.81l-0.4-0.84l-0.13-0.93l0.07-1.84l2.82,0.12l-0.08,1.22l0.09,0.88l0.36,0.46l0.7,0.25l0.71-0.17
			l0.48-0.52l0.16-0.88l-0.05-0.92l-0.43-0.7l-0.92-0.76l-1.26-0.92l-0.88-0.72l-0.83-0.9l-0.33-0.88l-0.08-1.43l0.2-1.17l0.52-1.08
			l0.57-0.62l0.85-0.46l0.86-0.22l1.34-0.04l1.76,0.38l0.94,0.64l0.46,0.8l0.27,1.31l-0.04,1.32L409.02,788.97z"/>
                                    <path className="st4" d="M421.9,795.05l-0.2,1.54l-0.41,1.07l-0.52,0.73l-0.67,0.51l-1.29,0.51l-1.31,0.08l-2.08-0.36l-0.86-0.52
			l-0.59-0.79l-0.43-1.05l-0.14-1.34l0.06-3.74l0.09-1.96l0.11-1.2l0.21-1.2l0.53-0.95l0.79-0.77l1.19-0.46l1.71-0.19l1.25,0.14
			l0.95,0.36l1.09,0.94l0.51,1.09l0.14,0.68l0.01,3.62L421.9,795.05z M417.17,783.71l-2.09-0.04l0.09-2.34l2.09,0.04L417.17,783.71z
			 M419.1,789.27l-0.06-0.62l-0.21-0.92l-0.56-0.43l-0.39-0.11l-0.6,0.17l-0.32,0.27l-0.27,0.6l-0.25,2.92l-0.08,3.4l-0.02,1.04
			l0.19,1l0.41,0.49l0.61,0.19l0.37-0.05l0.44-0.27l0.3-0.39l0.24-2.56L419.1,789.27z M420.9,783.78l-2.11-0.04l0.09-2.34l2.11,0.04
			L420.9,783.78z"/>
                                    <path className="st4" d="M430.14,799.24l-2.61-7.44l-0.36-1.17l-0.55-1.91l0.18,1.36l0.08,1.54l0.07,7.7l-2.85,0.06l0.04-14.14
			l3.44-0.03l2.37,6.89l0.59,1.73l0.44,1.69l-0.2-1.6l-0.06-1l-0.07-7.76l2.83-0.04l-0.06,14.12L430.14,799.24z"/>
                                    <path className="st4" d="M435.91,799.16l-0.31-14.11l2.91-0.12l0.25,11.59l4.16-0.17l0.06,2.52L435.91,799.16z" />
                                    <path className="st4" d="M444.72,798.77l-0.58-14.1l2.95-0.17l0.58,14.1L444.72,798.77z" />
                                    <path className="st4" d="M449.28,789.65l-0.03-1.12l0-0.92l0.12-1.07l0.28-0.8l0.57-0.73l0.63-0.49l1.34-0.55l0.94-0.13l0.89,0.03
			l0.96,0.18l1.28,0.7l0.55,0.66l0.34,0.83l0.3,2.56l-2.84,0.23l-0.06-0.92l-0.14-1.01l-0.16-0.63l-0.37-0.29l-0.67-0.17l-0.62,0.27
			l-0.24,0.4l-0.14,0.43l0,1.3l0.38,6.16l0.21,1l0.29,0.44l0.32,0.17l0.48,0.04l0.48-0.16l0.46-0.62l0.05-2.15l-0.03-0.46l2.84-0.23
			l0.06,2.28l-0.36,1.45l-0.86,1.13l-1.06,0.57l-1.58,0.31l-1.29,0.02l-0.94-0.23l-0.86-0.55l-0.58-0.75l-0.48-1.4l-0.22-2.21
			L449.28,789.65z"/>
                                    <path className="st4" d="M465.78,797.02l-0.54-6.08l-2.59,0.28l0.55,6.08l-2.94,0.32l-1.26-14.04l2.94-0.32l0.51,5.65l2.59-0.28
			l-0.51-5.65l2.94-0.32l1.26,14.04L465.78,797.02z"/>
                                    <path className="st4" d="M471.2,796.39l-1.64-13.99l6.78-0.91l0.3,2.54l-3.85,0.52l0.35,3.01l3.44-0.46l0.29,2.48l-3.44,0.46l0.4,3.41
			l3.85-0.52l0.3,2.56L471.2,796.39z"/>
                                </g>
                                <g>
                                    <path className="st4" d="M357.28,804.68l3.16,0.46l1.47,14.01l-3.46-0.51l-0.06-2.75l-2.62-0.38l-0.92,2.6l-3.26-0.48L357.28,804.68z
			 M358.22,808.58l-1.62,4.58l1.73,0.25L358.22,808.58z"/>
                                    <path className="st4" d="M364.92,805.78l4.92,0.6c1.11,0.13,1.98,0.53,2.59,1.18c0.62,0.65,0.86,1.5,0.72,2.55
			c-0.11,0.86-0.46,1.56-1.03,2.11c-0.57,0.54-1.38,0.93-2.41,1.16c1.21,0.34,1.88,1.21,1.99,2.61l0.37,4.41l-3.5-0.42l-0.02-3.8
			c-0.01-0.64-0.1-1.12-0.27-1.44c-0.17-0.32-0.48-0.5-0.92-0.55l-0.29-0.04l-0.73,5.56l-3.19-0.39L364.92,805.78z M367.77,808.79
			l-0.38,2.9l0.7,0.08c0.5,0.06,0.9-0.04,1.2-0.3c0.3-0.26,0.48-0.61,0.54-1.06c0.06-0.45-0.01-0.81-0.21-1.06
			c-0.2-0.25-0.53-0.41-1-0.47L367.77,808.79z"/>
                                    <path className="st4" d="M375.06,806.98l4.89,0.46c1.03,0.1,1.84,0.47,2.42,1.11c0.58,0.64,0.82,1.44,0.72,2.39
			c-0.08,0.72-0.33,1.32-0.77,1.8c-0.44,0.48-1.06,0.85-1.87,1.09c1.07,0.26,1.84,0.7,2.32,1.31c0.48,0.62,0.67,1.41,0.56,2.38
			c-0.13,1.19-0.66,2.1-1.62,2.72c-0.95,0.62-2.17,0.86-3.66,0.72l-4.41-0.42L375.06,806.98z M377.45,815.01l-0.33,3.17l1.03,0.1
			c0.51,0.05,0.95-0.06,1.32-0.32c0.37-0.26,0.58-0.63,0.63-1.09c0.05-0.48-0.08-0.88-0.39-1.2c-0.31-0.32-0.72-0.5-1.23-0.55
			L377.45,815.01z M377.99,809.92l-0.27,2.58l0.35,0.03c0.48,0.04,0.87-0.04,1.18-0.26s0.48-0.55,0.53-0.98
			c0.04-0.4-0.04-0.7-0.23-0.92c-0.2-0.22-0.51-0.35-0.95-0.39L377.99,809.92z"/>
                                    <path className="st4" d="M385.55,807.94l7.85,0.55l-0.22,2.74l-4.64-0.33l-0.2,2.5l4.33,0.3l-0.21,2.65l-4.33-0.3l-0.24,2.92
			l4.64,0.32l-0.22,2.78l-7.85-0.55L385.55,807.94z"/>
                                    <path className="st4" d="M395.04,808.57l3.24,0.17l-0.85,13.61l-3.24-0.17L395.04,808.57z" />
                                    <path className="st4" d="M399.76,808.83l8.72,0.29l-0.12,2.76l-2.8-0.09l-0.47,10.85l-3.22-0.11l0.47-10.85l-2.7-0.09L399.76,808.83z"
                                    />
                                    <path className="st4" d="M409.41,818.68c1.04,0.99,2.04,1.49,2.98,1.49c0.48,0.01,0.9-0.14,1.25-0.45c0.35-0.3,0.53-0.67,0.54-1.11
			c0.01-0.35-0.11-0.67-0.35-0.94c-0.24-0.28-0.71-0.59-1.39-0.93c-0.96-0.48-1.69-1.01-2.19-1.6c-0.5-0.58-0.75-1.32-0.73-2.21
			c0.02-1.13,0.45-2.08,1.27-2.85s1.9-1.15,3.22-1.13c0.91,0.01,1.85,0.26,2.83,0.75l-0.07,3.25c-0.86-0.83-1.68-1.26-2.48-1.26
			c-0.4,0-0.74,0.11-1.04,0.34c-0.3,0.23-0.45,0.51-0.45,0.84c-0.01,0.33,0.11,0.61,0.34,0.84c0.23,0.24,0.65,0.5,1.27,0.77
			c1.03,0.47,1.8,1,2.32,1.59c0.52,0.6,0.77,1.4,0.75,2.4c-0.03,1.26-0.51,2.34-1.45,3.22c-0.94,0.88-2.07,1.32-3.4,1.3
			c-1.03-0.01-2.13-0.33-3.31-0.95L409.41,818.68z"/>
                                    <path className="st4" d="M419.19,809.28l3.53-0.1l2.02,5.16l1.84-5.27l3.54-0.1l0.23,13.62l-3.15,0.09l-0.09-5.1
			c-0.02-1.29,0.01-2.6,0.09-3.93l-0.24,0.01c-0.13,0.66-0.38,1.57-0.78,2.74l-1.35,4.01l-1.47-3.93c-0.43-1.15-0.72-2.04-0.87-2.69
			l-0.25,0.01c0.13,1.23,0.21,2.53,0.24,3.92l0.09,5.1l-3.15,0.09L419.19,809.28z"/>
                                    <path className="st4" d="M432.08,808.96l7.87-0.35l0.09,2.74l-4.65,0.21l0.08,2.51l4.34-0.19l0.09,2.65l-4.34,0.19l0.1,2.93l4.65-0.21
			l0.09,2.78l-7.87,0.35L432.08,808.96z"/>
                                    <path className="st4" d="M441.07,808.55l8.71-0.59l0.16,2.76l-2.8,0.19l0.62,10.84l-3.21,0.22l-0.62-10.83l-2.7,0.18L441.07,808.55z"
                                    />
                                    <path className="st4" d="M451.22,807.84l3.19-0.3l0.43,5.24l2.55-0.24l-0.44-5.24l3.22-0.3l1.13,13.56l-3.22,0.3l-0.47-5.68
			l-2.55,0.24l0.47,5.68l-3.19,0.3L451.22,807.84z"/>
                                    <path className="st4" d="M466.43,806.05c1.66-0.2,3.04,0.26,4.13,1.38s1.76,2.75,2,4.89c0.24,2.15-0.06,3.92-0.88,5.31
			c-0.82,1.39-2,2.18-3.54,2.37c-1.59,0.19-2.9-0.32-3.92-1.54c-1.02-1.22-1.64-2.82-1.86-4.8c-0.15-1.37-0.07-2.62,0.23-3.77
			c0.31-1.14,0.81-2.04,1.52-2.7C464.82,806.53,465.59,806.15,466.43,806.05z M467.09,808.85c-0.64,0.08-1.1,0.5-1.36,1.27
			c-0.26,0.77-0.32,1.79-0.18,3.06c0.14,1.29,0.42,2.28,0.83,2.97c0.41,0.69,0.91,1,1.5,0.93c0.58-0.07,1-0.5,1.26-1.3
			c0.26-0.8,0.33-1.83,0.19-3.09c-0.14-1.31-0.43-2.29-0.86-2.95S467.59,808.79,467.09,808.85z"/>
                                    <path className="st4" d="M473.26,805.41l3.99-0.6c1.2-0.18,2.23-0.05,3.1,0.39c0.87,0.44,1.54,1.14,2.02,2.11
			c0.48,0.97,0.8,2.08,0.97,3.32c0.29,2.08,0.04,3.82-0.73,5.22c-0.78,1.4-2.09,2.23-3.93,2.51l-3.56,0.53L473.26,805.41z
			 M476.82,807.56l1.14,8.22l0.23-0.03c1.65-0.25,2.28-1.85,1.87-4.79c-0.16-1.14-0.46-2.03-0.92-2.67c-0.45-0.64-1.08-0.9-1.9-0.78
			L476.82,807.56z"/>
                                    <path className="st4" d="M483.92,803.78l3.21-0.54l2.13,13.43l-3.21,0.54L483.92,803.78z" />
                                    <path className="st4" d="M488.97,802.93l3.17-0.6l0.86,4.83l1.54-5.28l3.14-0.59l-1.84,5.06l4.64,8.25l-3.56,0.67l-2.49-5.04
			l-0.59,1.63l0.69,3.86l-3.17,0.6L488.97,802.93z"/>
                                </g>
                            </>}
                        </g>
                        <g id="Segment_-_8" className={"model-option" + (this.state.changed ? this.setStyles(10) : '')}>
                            <path id="Circle_-_2-2_7_" className="st0" d="M331.25,542.99L222.26,679.63c37.66,29.73,81.94,51.96,130.47,62.93l38.93-170.49
		C369.08,567.02,348.81,556.77,331.25,542.99z"/>
                            <path id="Circle_-_2-1_9_" className="st1" d="M331.25,542.99L222.26,679.63c37.66,29.73,81.94,51.96,130.47,62.93l38.93-170.49
		C369.08,567.02,348.81,556.77,331.25,542.99z"/>
                            <path id="Circle_-_1-2_5_" className="st0" d="M222.26,679.63l-61.89,77.59c48.92,38.92,106.81,67.64,170.28,82.02l22.08-96.69
		C304.12,731.55,259.9,709.33,222.26,679.63z"/>
                            <path id="Circle_-_1-1" className="st2" d="M222.26,679.63l-61.89,77.59c48.92,38.92,106.81,67.64,170.28,82.02l22.08-96.69
		C304.12,731.55,259.9,709.33,222.26,679.63z"/>
                            {SelectedLanguage === "en-GB" && <>
                                <g>
                                    <path className="st4" d="M225.2849,736.0354l-.76,1.09-.85.88-1.06.65-1.28.1-1.03-.19-.85-.5-.85-.6-.46-.61-.41-1.08.01-1.19.4-1.1.6-.97.71-1.02,2.33,1.52-.42.66-.97,1.62.08.43.27.29.46.13.57-.33.59-.76,5.7-8.44,2.45,1.59Z" />
                                    <path className="st4" d="M234.0249,741.9154l-.95,1.23-.9.72-.82.37-.84.1-1.65-.31-.89-.5-1.2-.9-.78-1.25-.21-.88.13-1.19.29-.64,2.28-3.96,1.29-1.91.7-.94.77-.79.92-.49.98-.17,1.13.2,1.57.7,1,.76.64.79.46,1.37-.11,1.2-.23.66-1.83,3.13Zm.24-6.01.54-.96.2-.77-.07-.58-.4-.51-.68-.18-.86.48-.59.74-1.17,1.75-1.67,2.69-.57.97-.37,1,.18.75.35.36.63.15.61-.16.74-.89.97-1.45Z" />
                                    <path className="st4" d="M240.9449,734.5854l3.78,2.18.49.33.82.78.35.64.16.69-.04.74-.8,1.89-.73,1.01-.92.7-.85.27-.55-.11.58.66.22.63-.04.7-.75,1.81-1.18,1.54-1.08.58-.75.1-.68-.09-1.24-.55-4.06-2.34Zm-2.85,11.99.79.32.57-.07.59-.54.45-.67.4-1.18-.25-.54-.67-.52-.82-.47-1.91,3.18Zm2.9-5.22.73.3.38.01.4-.14.82-1.17.28-.67-.24-.79-.55-.48-.71-.41-1.77,2.95Z" />
                                    <path className="st4" d="M253.9149,741.8854l3.54,1.85,1.33.9.9,1.21.21.99-.11.93-.64,1.72-1.06,2.07-1.58,2.72-1.18,1.62-1.1.69-1.04.23-.93-.06-1.77-.7-3.38-1.76Zm-2.31,12.02.62.23.43-.02.53-.29,1.72-2.81,1.44-2.73.46-1.02.02-.71-.34-.45-.43-.32-.74-.39-4.43,8.12Z" />
                                    <path className="st4" d="M256.4149,759.0654l6.59-12.49,6.09,3.08-1.2,2.26-3.46-1.75-1.42,2.69,3.09,1.56-1.17,2.21-3.09-1.56-1.61,3.04,3.46,1.75-1.2,2.28Z" />
                                    <path className="st4" d="M273.1149,755.9354l.09-.24.39-.85-.06-.91-.45-.45-.39-.09-.54.04-.38.27-.31.5-.2.57.07.52.63,1.43.77,1.28.6,1.11.43,1.52-.08,1.02-.42,1.19-.77,1.21-1.11.92-1.28.29-1.64-.18-.93-.43-1.27-.72-.71-.79-.39-1.04-.05-.93.23-.91.76-1.67,2.57,1.18-.53,1.1-.25.85.16.56.56.5.72.11.64-.3.48-.75.31-.87-.13-.81-.56-1.05-.82-1.32-.54-1-.43-1.14.02-.94.47-1.35.62-1.01.89-.8.76-.35.96-.11.88.12,1.25.47,1.49,1.02.63.95.13.92-.25,1.32-.54,1.21Z" />
                                    <path className="st4" d="M273.2549,767.0254l5.88-12.83,2.71,1.18-5.88,12.83Z" />
                                    <path className="st4" d="M283.5149,771.3754l.5-1.11-.5.31-1.01.27-.7.01-.77-.28-.76-.36-.85-.89-.38-.98.05-1.62.87-2.21.78-1.84,1.25-2.73.72-1.3,1.07-1.03,1.27-.45,1.22.03,1.53.44.9.46.92.81.5.77.26.95-.06.91-.33,1.27-.33.86-2.67-1.1.24-.55.41-1.3-.19-.55-.63-.56-.5-.1-.67.2-.77,1-.79,1.68-1.44,3.32-.43,1.1-.19,1.11.25.56.53.39.64.07.61-.22.62-.91.47-.99.3-.7-1.38-.57.9-2.09,3.97,1.63-3.13,7.24Z" />
                                    <path className="st4" d="M293.7549,775.3154l.28-7.87.09-1.22.19-1.98-.33,1.33-.49,1.46-2.74,7.18-2.68-.99,5.2-13.12,3.22,1.23-.3,7.27-.08,1.82-.2,1.74.4-1.56.31-.95,2.77-7.24,2.65,1-5.21,13.1Z" />
                                    <path className="st4" d="M312.4849,776.5054l1.46-1.29,2.83.92-3.54,2.96.3.52.81.41.27.09-.76,2.21-1.58-.45-.89-.69-.39-.69-.92.33-1.59.07-.94-.24-1.16-.48-.68-.6-.51-.8-.31-.79-.07-.86.32-1.62.35-.83.86-.98.8-.62,1.4-.72-.44-1.42-.08-1.16.25-1.16.48-.96.53-.52.79-.39,1.07-.22,1.62.27,1.04.46.53.47.76,1.09.11,1.25-.17.8-.63,1.08-1.22,1.14-1.46.7Zm-3.23-1.79-.8.56-.38.42-.33.63-.15.75.04.5.24.5.7.54.57.06,1.17-.5Zm2.76-3.84.69-.87-.05-.81-.55-.54-.83.09-.5.57-.02.89.29,1.23Z" />
                                </g>
                                <g>
                                    <path className="st4" d="M186.4849,745.7054l1.74-3.56-1.9-1.35-2.84,2.77-2.46-1.75,10.86-9.68,2.94,2.09-5.89,13.21Zm4.35-8.71,1.05-1.86-1.47,1.58-2.44,2.42,1.3.93Z" />
                                    <path className="st4" d="M199.2949,742.2854l.13-.22.52-.79.09-.91-.36-.51-.36-.15-.53-.05-.41.21-.38.44-.29.53-.02.52.38,1.52.54,1.38.41,1.19.17,1.57-.25,1-.6,1.12-.96,1.09-1.23.74-1.29.09-1.57-.44-.84-.57-1.12-.91-.57-.89-.21-1.09.1-.92.37-.86,1.01-1.54,2.31,1.57-.7,1-.38.8.07.58.46.58.68.22.68-.19.59-.67.44-.81v-.82l-.38-1.13-.58-1.43-.37-1.07-.23-1.2.17-.92.67-1.26.77-.9,1-.66.8-.23.96.04.83.25,1.15.66,1.29,1.23.46,1.04-.03.93-.46,1.26-.72,1.11Z" />
                                    <path className="st4" d="M206.7749,747.1954l.13-.23.5-.8.07-.91-.37-.5-.37-.14-.54-.04-.41.21-.37.45-.28.53v.52l.41,1.51.58,1.37.43,1.18.21,1.56-.22,1-.58,1.13-.93,1.12-1.21.77-1.29.12-1.58-.4-.85-.55-1.14-.88-.59-.88-.23-1.08.08-.93.35-.87.98-1.56,2.35,1.52-.67,1.02-.37.81.08.58.47.57.69.21.67-.21.57-.68.42-.82-.02-.82-.4-1.12-.62-1.42-.39-1.06-.26-1.19.15-.93.65-1.28.75-.92.98-.68.8-.25.96.02.84.23,1.16.63,1.32,1.21.49,1.03-.01.93-.43,1.27-.7,1.12Z" />
                                    <path className="st4" d="M204.9649,757.9854l7.59-11.92,2.48,1.54-7.59,11.92Z" />
                                    <path className="st4" d="M213.9649,763.4754l.65-1.03-.53.24-1.03.13-.68-.08-.72-.38-.69-.46-.7-1-.23-1.02.29-1.6,1.17-2.07,1.03-1.72,1.62-2.53.89-1.19,1.2-.87,1.31-.27,1.19.19,1.43.65.81.58.78.94.37.83.11.98-.19.89-.51,1.21-.45.81-2.45-1.47.31-.51.59-1.23-.1-.57-.53-.64-.47-.17-.68.1-.9.89-1.03,1.56-1.89,3.09-.58,1.03-.35,1.07.16.59.46.46.62.16.63-.14.74-.82.61-.92.4-.65-1.27-.76,1.19-1.95,3.64,2.17-4.13,6.75Z" />
                                    <path className="st4" d="M222.9849,768.6154l1.46-7.77.27-1.2.48-1.93-.52,1.27-.7,1.38-3.76,6.73-2.46-1.37,7.05-12.27,2.96,1.69-1.39,7.17-.36,1.8-.46,1.69.62-1.49.45-.9,3.79-6.78,2.44,1.37-7.06,12.24Z" />
                                    <path className="st4" d="M236.4349,775.8454l2.76-5.15,1.79-3.29,1.1-1.75-.58.78-.96,1.24-1.16,1.47-4.86,5.7-2.42-1.29,1.94-7,.79-2.29.49-1.46.4-1.01-.62,1.23-1.17,2.52-3.81,7.01-2.4-1.28,6.6-12.46,3.77,1.95-2.3,7.76-.6,1.94-.58,1.34.99-1.47.79-1.05,5.39-6.57,3.72,1.94-6.64,12.46Z" />
                                    <path className="st4" d="M240.7649,778.0554l6.34-12.63,6.07,2.97-1.15,2.29-3.45-1.68-1.36,2.72,3.08,1.51-1.12,2.24-3.08-1.51-1.54,3.08,3.45,1.68-1.16,2.31Z" />
                                    <path className="st4" d="M253.8649,784.2254l.74-7.87.16-1.22.3-1.97-.4,1.31-.57,1.43-3.13,7.04-2.58-1.14,5.9-12.85,3.1,1.41-.73,7.26-.19,1.82-.3,1.73.48-1.54.36-.93,3.15-7.09,2.56,1.15-5.91,12.83Z" />
                                    <path className="st4" d="M259.6949,786.8954l4.76-10.68-2.16-.94.98-2.21,6.98,3.03-.99,2.21-2.16-.94-4.76,10.68Z" />
                                    <path className="st4" d="M272.9349,782.8954l.46-1.02.41-.83.57-.91.6-.6.82-.4.77-.17,1.43.09.89.29.77.42.77.58.83,1.19.21.83-.06.9-.84,2.44-2.62-1.04.35-.85.31-.97.13-.64-.2-.42-.52-.44-.67-.03-.39.25-.31.33-.57,1.17-2.34,5.71-.25,1,.07.52.21.3.4.25.5.07.67-.36.98-1.91.17-.43,2.62,1.04-.94,2.08-.95,1.15-1.25.64-1.18.05-1.54-.42-1.16-.54-.74-.61-.52-.88-.19-.93.19-1.47.76-2.08Z" />
                                    <path className="st4" d="M288.0449,793.5254l-.68,1.39-.73.88-.72.52-.8.26-1.66.03-.97-.31-1.34-.64-1-1.07-.38-.82-.11-1.19.15-.69,1.43-4.33.88-2.13.49-1.05.59-.93.8-.66.92-.36,1.14-.03,1.66.38,1.12.54.77.65.72,1.25.13,1.2-.09.69-1.16,3.43Zm-.96-5.93.33-1.05.04-.79-.18-.56-.49-.42-.7-.05-.74.64-.43.84-.79,1.95-1.09,2.96-.36,1.06-.16,1.05.32.69.42.28.64.02.56-.28.54-1.02.66-1.61Z" />
                                    <path className="st4" d="M293.9349,800.0854v-7.89l.05-1.23.12-1.99-.28,1.34-.43,1.48-2.46,7.29-2.68-.89,4.68-13.33,3.22,1.12-.05,7.29-.02,1.83-.14,1.75.34-1.57.28-.96,2.48-7.34,2.65.9-4.69,13.3Z" />
                                    <path className="st4" d="M300.1549,802.1654l3.63-11.1-2.25-.71.75-2.3,7.26,2.29-.75,2.3-2.25-.71-3.63,11.1Z" />
                                    <path className="st4" d="M311.7649,805.6954l-.07-.54.31-1.87.24-.78.33-1.3.02-.41-.16-.59-.4-.3-.41-.18-1.04-.3-1.66,5.45-2.85-.83,4.1-13.49,5.15,1.51.93.44.56.48.46.65.26,1.18-.3,1.75-.31.89-.52,1.09-.89.76-.9.17.71.75.19,1.05-.24,1.01-.08.25-.48,2.15-.09,1.41.11.49Zm1.13-8.05.43-.02.52-.33.43-1.06.22-.85-.01-.44-.23-.59-1.04-.43-.9-.26-1.06,3.5Z" />
                                    <path className="st4" d="M324.8449,804.8054l-.55,1.44-.64.94-.67.59-.77.33-1.65.18-.99-.22-1.4-.52-1.1-.97-.46-.78-.22-1.18.09-.7,1.02-4.44.67-2.2.39-1.09.5-.98.73-.73.88-.45,1.13-.13,1.69.22,1.17.44.83.57.84,1.17.24,1.18-.02.7-.83,3.52Zm-1.51-5.82.24-1.07-.03-.79-.23-.54-.53-.37-.7.02-.68.71-.35.88-.6,2.01-.8,3.05-.26,1.09-.06,1.06.39.66.44.24.64-.04.54-.33.44-1.06.5-1.66Z" />
                                    <path className="st4" d="M325.4449,809.3954l3.46-13.66,2.81.68-2.85,11.22,4.01.98-.62,2.44Z" />
                                </g>
                            </>}
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                    <path className="st4" d="M225.29,735.98l-0.76,1.09l-0.85,0.88l-1.06,0.65l-1.28,0.1l-1.03-0.19l-0.85-0.5l-0.85-0.6l-0.46-0.61
			l-0.41-1.08l0.01-1.19l0.4-1.1l0.6-0.97l0.71-1.02l2.33,1.52l-0.42,0.66l-0.97,1.62l0.08,0.43l0.27,0.29l0.46,0.13l0.57-0.33
			l0.59-0.76l5.7-8.44l2.45,1.59L225.29,735.98z"/>
                                    <path className="st4" d="M234.03,741.86l-0.95,1.23l-0.9,0.72l-0.82,0.37l-0.84,0.1l-1.65-0.31l-0.89-0.5l-1.2-0.9l-0.78-1.25
			l-0.21-0.88l0.13-1.19l0.29-0.64l2.28-3.96l1.29-1.91l0.7-0.94l0.77-0.79l0.92-0.49l0.98-0.17l1.13,0.2l1.57,0.7l1,0.76l0.64,0.79
			l0.46,1.37l-0.11,1.2l-0.23,0.66l-1.83,3.13L234.03,741.86z M234.27,735.85l0.54-0.96l0.2-0.77l-0.07-0.58l-0.4-0.51l-0.68-0.18
			l-0.86,0.48l-0.59,0.74l-1.17,1.75l-1.67,2.69l-0.57,0.97l-0.37,1l0.18,0.75l0.35,0.36l0.63,0.15l0.61-0.16l0.74-0.89l0.97-1.45
			L234.27,735.85z"/>
                                    <path className="st4" d="M240.95,734.53l3.78,2.18l0.49,0.33l0.82,0.78l0.35,0.64l0.16,0.69l-0.04,0.74l-0.8,1.89l-0.73,1.01
			l-0.92,0.7l-0.85,0.27l-0.55-0.11l0.58,0.66l0.22,0.63l-0.04,0.7l-0.75,1.81l-1.18,1.54l-1.08,0.58l-0.75,0.1l-0.68-0.09
			l-1.24-0.55l-4.06-2.34L240.95,734.53z M238.1,746.52l0.79,0.32l0.57-0.07l0.59-0.54l0.45-0.67l0.4-1.18l-0.25-0.54l-0.67-0.52
			l-0.82-0.47l-1.91,3.18L238.1,746.52z M241,741.3l0.73,0.3l0.38,0.01l0.4-0.14l0.82-1.17l0.28-0.67l-0.24-0.79l-0.55-0.48
			l-0.71-0.41l-1.77,2.95L241,741.3z"/>
                                    <path className="st4" d="M253.92,741.83l3.54,1.85l1.33,0.9l0.9,1.21l0.21,0.99l-0.11,0.93l-0.64,1.72l-1.06,2.07l-1.58,2.72
			l-1.18,1.62l-1.1,0.69l-1.04,0.23l-0.93-0.06l-1.77-0.7l-3.38-1.76L253.92,741.83z M251.61,753.85l0.62,0.23l0.43-0.02l0.53-0.29
			l1.72-2.81l1.44-2.73l0.46-1.02l0.02-0.71l-0.34-0.45l-0.43-0.32l-0.74-0.39l-4.43,8.12L251.61,753.85z"/>
                                    <path className="st4" d="M256.42,759.01l6.59-12.49l6.09,3.08l-1.2,2.26l-3.46-1.75l-1.42,2.69l3.09,1.56l-1.17,2.21l-3.09-1.56
			l-1.61,3.04l3.46,1.75l-1.2,2.28L256.42,759.01z"/>
                                    <path className="st4" d="M273.12,755.88l0.09-0.24l0.39-0.85l-0.06-0.91l-0.45-0.45l-0.39-0.09l-0.54,0.04l-0.38,0.27l-0.31,0.5
			l-0.2,0.57l0.07,0.52l0.63,1.43l0.77,1.28l0.6,1.11l0.43,1.52l-0.08,1.02l-0.42,1.19L272.5,764l-1.11,0.92l-1.28,0.29l-1.64-0.18
			l-0.93-0.43l-1.27-0.72l-0.71-0.79l-0.39-1.04l-0.05-0.93l0.23-0.91l0.76-1.67l2.57,1.18l-0.53,1.1l-0.25,0.85l0.16,0.56l0.56,0.5
			l0.72,0.11l0.64-0.3l0.48-0.75l0.31-0.87l-0.13-0.81l-0.56-1.05l-0.82-1.32l-0.54-1l-0.43-1.14l0.02-0.94l0.47-1.35l0.62-1.01
			l0.89-0.8l0.76-0.35l0.96-0.11l0.88,0.12l1.25,0.47l1.49,1.02l0.63,0.95l0.13,0.92l-0.25,1.32l-0.54,1.21L273.12,755.88z"/>
                                    <path className="st4" d="M273.26,766.97l5.88-12.83l2.71,1.18l-5.88,12.83L273.26,766.97z" />
                                    <path className="st4" d="M283.52,771.32l0.5-1.11l-0.5,0.31l-1.01,0.27l-0.7,0.01l-0.77-0.28l-0.76-0.36l-0.85-0.89l-0.38-0.98
			l0.05-1.62l0.87-2.21l0.78-1.84l1.25-2.73l0.72-1.3l1.07-1.03l1.27-0.45l1.22,0.03l1.53,0.44l0.9,0.46l0.92,0.81l0.5,0.77
			l0.26,0.95l-0.06,0.91l-0.33,1.27l-0.33,0.86l-2.67-1.1l0.24-0.55l0.41-1.3l-0.19-0.55l-0.63-0.56l-0.5-0.1l-0.67,0.2l-0.77,1
			l-0.79,1.68l-1.44,3.32l-0.43,1.1l-0.19,1.11l0.25,0.56l0.53,0.39l0.64,0.07l0.61-0.22l0.62-0.91l0.47-0.99l0.3-0.7l-1.38-0.57
			l0.9-2.09l3.97,1.63l-3.13,7.24L283.52,771.32z"/>
                                    <path className="st4" d="M293.76,775.26l0.28-7.87l0.09-1.22l0.19-1.98l-0.33,1.33l-0.49,1.46l-2.74,7.18l-2.68-0.99l5.2-13.12
			l3.22,1.23l-0.3,7.27l-0.08,1.82l-0.2,1.74l0.4-1.56l0.31-0.95l2.77-7.24l2.65,1l-5.21,13.1L293.76,775.26z"/>
                                    <path className="st4" d="M312.49,776.45l1.46-1.29l2.83,0.92l-3.54,2.96l0.3,0.52l0.81,0.41l0.27,0.09l-0.76,2.21l-1.58-0.45
			l-0.89-0.69l-0.39-0.69l-0.92,0.33l-1.59,0.07l-0.94-0.24l-1.16-0.48l-0.68-0.6l-0.51-0.8l-0.31-0.79l-0.07-0.86l0.32-1.62
			l0.35-0.83l0.86-0.98l0.8-0.62l1.4-0.72l-0.44-1.42l-0.08-1.16l0.25-1.16l0.48-0.96l0.53-0.52l0.79-0.39l1.07-0.22l1.62,0.27
			l1.04,0.46l0.53,0.47l0.76,1.09l0.11,1.25l-0.17,0.8l-0.63,1.08l-1.22,1.14l-1.46,0.7L312.49,776.45z M309.26,774.66l-0.8,0.56
			l-0.38,0.42l-0.33,0.63l-0.15,0.75l0.04,0.5l0.24,0.5l0.7,0.54l0.57,0.06l1.17-0.5L309.26,774.66z M312.02,770.82l0.69-0.87
			l-0.05-0.81l-0.55-0.54l-0.83,0.09l-0.5,0.57l-0.02,0.89l0.29,1.23L312.02,770.82z"/>
                                </g>
                                <g>
                                    <path className="st4" d="M186.49,745.65l1.74-3.56l-1.9-1.35l-2.84,2.77l-2.46-1.75l10.86-9.68l2.94,2.09l-5.89,13.21L186.49,745.65z
			 M190.84,736.94l1.05-1.86l-1.47,1.58l-2.44,2.42l1.3,0.93L190.84,736.94z"/>
                                    <path className="st4" d="M199.3,742.23l0.13-0.22l0.52-0.79l0.09-0.91l-0.36-0.51l-0.36-0.15l-0.53-0.05l-0.41,0.21l-0.38,0.44
			l-0.29,0.53l-0.02,0.52l0.38,1.52l0.54,1.38l0.41,1.19l0.17,1.57l-0.25,1l-0.6,1.12l-0.96,1.09l-1.23,0.74l-1.29,0.09l-1.57-0.44
			l-0.84-0.57l-1.12-0.91l-0.57-0.89l-0.21-1.09l0.1-0.92l0.37-0.86l1.01-1.54l2.31,1.57l-0.7,1l-0.38,0.8l0.07,0.58l0.46,0.58
			l0.68,0.22l0.68-0.19l0.59-0.67l0.44-0.81l0-0.82l-0.38-1.13l-0.58-1.43l-0.37-1.07l-0.23-1.2l0.17-0.92l0.67-1.26l0.77-0.9
			l1-0.66l0.8-0.23l0.96,0.04l0.83,0.25l1.15,0.66l1.29,1.23l0.46,1.04l-0.03,0.93l-0.46,1.26l-0.72,1.11L199.3,742.23z"/>
                                    <path className="st4" d="M206.78,747.14l0.13-0.23l0.5-0.8l0.07-0.91l-0.37-0.5l-0.37-0.14l-0.54-0.04l-0.41,0.21l-0.37,0.45
			l-0.28,0.53l0,0.52l0.41,1.51l0.58,1.37l0.43,1.18l0.21,1.56l-0.22,1l-0.58,1.13l-0.93,1.12l-1.21,0.77l-1.29,0.12l-1.58-0.4
			l-0.85-0.55l-1.14-0.88l-0.59-0.88l-0.23-1.08l0.08-0.93l0.35-0.87l0.98-1.56l2.35,1.52l-0.67,1.02l-0.37,0.81l0.08,0.58
			l0.47,0.57l0.69,0.21l0.67-0.21l0.57-0.68l0.42-0.82l-0.02-0.82l-0.4-1.12l-0.62-1.42l-0.39-1.06l-0.26-1.19l0.15-0.93l0.65-1.28
			l0.75-0.92l0.98-0.68l0.8-0.25l0.96,0.02l0.84,0.23l1.16,0.63l1.32,1.21l0.49,1.03l-0.01,0.93l-0.43,1.27l-0.7,1.12L206.78,747.14
			z"/>
                                    <path className="st4" d="M204.97,757.93l7.59-11.92l2.48,1.54l-7.59,11.92L204.97,757.93z" />
                                    <path className="st4" d="M213.97,763.42l0.65-1.03l-0.53,0.24l-1.03,0.13l-0.68-0.08l-0.72-0.38l-0.69-0.46l-0.7-1l-0.23-1.02
			l0.29-1.6l1.17-2.07l1.03-1.72l1.62-2.53l0.89-1.19l1.2-0.87l1.31-0.27l1.19,0.19l1.43,0.65l0.81,0.58l0.78,0.94l0.37,0.83
			l0.11,0.98l-0.19,0.89l-0.51,1.21l-0.45,0.81l-2.45-1.47l0.31-0.51l0.59-1.23l-0.1-0.57l-0.53-0.64l-0.47-0.17l-0.68,0.1
			l-0.9,0.89l-1.03,1.56l-1.89,3.09l-0.58,1.03l-0.35,1.07l0.16,0.59l0.46,0.46l0.62,0.16l0.63-0.14l0.74-0.82l0.61-0.92l0.4-0.65
			l-1.27-0.76l1.19-1.95l3.64,2.17l-4.13,6.75L213.97,763.42z"/>
                                    <path className="st4" d="M222.99,768.56l1.46-7.77l0.27-1.2l0.48-1.93l-0.52,1.27l-0.7,1.38l-3.76,6.73l-2.46-1.37l7.05-12.27
			l2.96,1.69l-1.39,7.17l-0.36,1.8l-0.46,1.69l0.62-1.49l0.45-0.9l3.79-6.78l2.44,1.37l-7.06,12.24L222.99,768.56z"/>
                                    <path className="st4" d="M236.44,775.79l2.76-5.15l1.79-3.29l1.1-1.75l-0.58,0.78l-0.96,1.24l-1.16,1.47l-4.86,5.7l-2.42-1.29l1.94-7
			l0.79-2.29l0.49-1.46l0.4-1.01l-0.62,1.23l-1.17,2.52l-3.81,7.01l-2.4-1.28l6.6-12.46l3.77,1.95l-2.3,7.76l-0.6,1.94l-0.58,1.34
			l0.99-1.47l0.79-1.05l5.39-6.57l3.72,1.94l-6.64,12.46L236.44,775.79z"/>
                                    <path className="st4" d="M240.77,778l6.34-12.63l6.07,2.97l-1.15,2.29l-3.45-1.68l-1.36,2.72l3.08,1.51l-1.12,2.24l-3.08-1.51
			l-1.54,3.08l3.45,1.68l-1.16,2.31L240.77,778z"/>
                                    <path className="st4" d="M253.87,784.17l0.74-7.87l0.16-1.22l0.3-1.97l-0.4,1.31l-0.57,1.43l-3.13,7.04l-2.58-1.14l5.9-12.85l3.1,1.41
			l-0.73,7.26l-0.19,1.82l-0.3,1.73l0.48-1.54l0.36-0.93l3.15-7.09l2.56,1.15l-5.91,12.83L253.87,784.17z"/>
                                    <path className="st4" d="M259.7,786.84l4.76-10.68l-2.16-0.94l0.98-2.21l6.98,3.03l-0.99,2.21l-2.16-0.94l-4.76,10.68L259.7,786.84z"
                                    />
                                    <path className="st4" d="M272.94,782.84l0.46-1.02l0.41-0.83l0.57-0.91l0.6-0.6l0.82-0.4l0.77-0.17L278,779l0.89,0.29l0.77,0.42
			l0.77,0.58l0.83,1.19l0.21,0.83l-0.06,0.9l-0.84,2.44l-2.62-1.04l0.35-0.85l0.31-0.97l0.13-0.64l-0.2-0.42l-0.52-0.44l-0.67-0.03
			l-0.39,0.25l-0.31,0.33l-0.57,1.17l-2.34,5.71l-0.25,1l0.07,0.52l0.21,0.3l0.4,0.25l0.5,0.07l0.67-0.36l0.98-1.91l0.17-0.43
			l2.62,1.04l-0.94,2.08l-0.95,1.15l-1.25,0.64l-1.18,0.05l-1.54-0.42l-1.16-0.54l-0.74-0.61l-0.52-0.88l-0.19-0.93l0.19-1.47
			l0.76-2.08L272.94,782.84z"/>
                                    <path className="st4" d="M288.05,793.47l-0.68,1.39l-0.73,0.88l-0.72,0.52l-0.8,0.26l-1.66,0.03l-0.97-0.31l-1.34-0.64l-1-1.07
			l-0.38-0.82l-0.11-1.19l0.15-0.69l1.43-4.33l0.88-2.13l0.49-1.05l0.59-0.93l0.8-0.66l0.92-0.36l1.14-0.03l1.66,0.38l1.12,0.54
			l0.77,0.65l0.72,1.25l0.13,1.2l-0.09,0.69l-1.16,3.43L288.05,793.47z M287.09,787.54l0.33-1.05l0.04-0.79l-0.18-0.56l-0.49-0.42
			l-0.7-0.05l-0.74,0.64l-0.43,0.84l-0.79,1.95l-1.09,2.96l-0.36,1.06l-0.16,1.05l0.32,0.69l0.42,0.28l0.64,0.02l0.56-0.28
			l0.54-1.02l0.66-1.61L287.09,787.54z"/>
                                    <path className="st4" d="M293.94,800.03l0-7.89l0.05-1.23l0.12-1.99l-0.28,1.34l-0.43,1.48l-2.46,7.29l-2.68-0.89l4.68-13.33
			l3.22,1.12l-0.05,7.29l-0.02,1.83l-0.14,1.75l0.34-1.57l0.28-0.96l2.48-7.34l2.65,0.9l-4.69,13.3L293.94,800.03z"/>
                                    <path className="st4" d="M300.16,802.11l3.63-11.1l-2.25-0.71l0.75-2.3l7.26,2.29l-0.75,2.3l-2.25-0.71l-3.63,11.1L300.16,802.11z" />
                                    <path className="st4" d="M311.77,805.64l-0.07-0.54l0.31-1.87l0.24-0.78l0.33-1.3l0.02-0.41l-0.16-0.59l-0.4-0.3l-0.41-0.18l-1.04-0.3
			l-1.66,5.45l-2.85-0.83l4.1-13.49l5.15,1.51l0.93,0.44l0.56,0.48l0.46,0.65l0.26,1.18l-0.3,1.75l-0.31,0.89l-0.52,1.09l-0.89,0.76
			l-0.9,0.17l0.71,0.75l0.19,1.05l-0.24,1.01l-0.08,0.25l-0.48,2.15l-0.09,1.41l0.11,0.49L311.77,805.64z M312.9,797.59l0.43-0.02
			l0.52-0.33l0.43-1.06l0.22-0.85l-0.01-0.44l-0.23-0.59l-1.04-0.43l-0.9-0.26l-1.06,3.5L312.9,797.59z"/>
                                    <path className="st4" d="M324.85,804.75l-0.55,1.44l-0.64,0.94l-0.67,0.59l-0.77,0.33l-1.65,0.18l-0.99-0.22l-1.4-0.52l-1.1-0.97
			l-0.46-0.78l-0.22-1.18l0.09-0.7l1.02-4.44l0.67-2.2l0.39-1.09l0.5-0.98l0.73-0.73l0.88-0.45l1.13-0.13l1.69,0.22l1.17,0.44
			l0.83,0.57l0.84,1.17l0.24,1.18l-0.02,0.7l-0.83,3.52L324.85,804.75z M323.34,798.93l0.24-1.07l-0.03-0.79l-0.23-0.54l-0.53-0.37
			l-0.7,0.02l-0.68,0.71l-0.35,0.88l-0.6,2.01l-0.8,3.05l-0.26,1.09l-0.06,1.06l0.39,0.66l0.44,0.24l0.64-0.04l0.54-0.33l0.44-1.06
			l0.5-1.66L323.34,798.93z"/>
                                    <path className="st4" d="M325.45,809.34l3.46-13.66l2.81,0.68l-2.85,11.22l4.01,0.98l-0.62,2.44L325.45,809.34z" />
                                </g>
                            </>}
                        </g>
                        <g id="Segment_-_7" className={"model-option" + (this.state.changed ? this.setStyles(9) : '')}>
                            <path id="Circle_-_2-2_6_" className="st0" d="M289.45,490.57L131.98,566.4c21.04,43.13,51.28,82.37,90.26,113.3l109.03-136.72
		C313.11,528.63,299.29,510.61,289.45,490.57z"/>
                            <path id="Circle_-_2-1_10_" className="st1" d="M289.45,490.57L131.98,566.4c21.04,43.13,51.28,82.37,90.26,113.3l109.03-136.72
		C313.11,528.63,299.29,510.61,289.45,490.57z"/>
                            <path id="Circle_-_1-2_6_" className="st0" d="M131.98,566.4l-89.42,43.06c27.19,56.29,66.89,107.28,117.84,147.77l61.83-77.54
		C183.21,648.69,153,609.5,131.98,566.4z"/>
                            <path id="Circle_-_1-1_7_" className="st2" d="M131.98,566.4l-89.42,43.06c27.19,56.29,66.89,107.28,117.84,147.77l61.83-77.54
		C183.21,648.69,153,609.5,131.98,566.4z"/>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M91.3531,599.8744a5.15,5.15,0,0,1,.2817-.4611,5.5486,5.5486,0,0,1,1.4949-1.199l.7009-.4263a10.565,10.565,0,0,0,1.1128-.7488,1.3655,1.3655,0,0,0,.5633-.8445,1.3122,1.3122,0,0,0-.271-.9108c-.224-.37-.3358-.5557-.5591-.9266l-4.8843,2.9405c-.6217-1.0326-.9311-1.55-1.5472-2.586l12.1391-7.2181c.97,1.632,1.4591,2.4457,2.4436,4.0686.0939.1548.1959.3327.2747.4918a3.42,3.42,0,0,1,.3945,2.4476,3.9578,3.9578,0,0,1-1.98,2.1655c-.2553.1572-.54.3119-.8212.4581a3.1284,3.1284,0,0,1-2.2618.4848,1.7116,1.7116,0,0,1-.8051-.51,1.6258,1.6258,0,0,1,.0354,1.054,2.72,2.72,0,0,1-1.4146,1.5177l-.2221.1367a11.3993,11.3993,0,0,0-2.7895,2.2975,2.36,2.36,0,0,0-.245.4416C92.3339,601.48,92.006,600.9456,91.3531,599.8744Zm5.8287-5.9338a5.98,5.98,0,0,0,.4177.65.8592.8592,0,0,0,.9178.3937,3.761,3.761,0,0,0,1.0264-.5057,8.6408,8.6408,0,0,0,.7314-.4925c.0579-.059.5663-.4159.5131-.9269a3.5259,3.5259,0,0,0-.4731-1.0128c-.1921-.318-.2881-.477-.48-.7953L96.6988,593.14C96.8917,593.46,96.9883,593.6205,97.1818,593.9406Z" />
                                    <path className="st4" d="M94.438,604.8758c4.7923-2.9912,7.1885-4.4868,11.9807-7.4781,1.4176,2.2711,2.1333,3.4021,3.5785,5.6546l-2.1551,1.3827c-.8241-1.2845-1.2339-1.9282-2.0491-3.2188l-2.571,1.6241c.733,1.1605,1.1014,1.74,1.8416,2.8952l-2.1051,1.3485c-.7437-1.161-1.1137-1.7427-1.85-2.9086l-2.91,1.8379c.8288,1.312,1.2454,1.9664,2.0832,3.2723l-2.1715,1.3932C96.6273,608.3672,95.8928,607.2066,94.438,604.8758Z" />
                                    <path className="st4" d="M99.9212,613.4824l11.8405-7.6983c.9986,1.536,1.5012,2.3018,2.5126,3.8289a3.0608,3.0608,0,0,1,.2283,3.0859,6.0106,6.0106,0,0,1-2.1728,2.0395,11.1776,11.1776,0,0,1-1.2245.7236,3.1388,3.1388,0,0,1-3.5427-.0081A5.9938,5.9938,0,0,1,106.1,613.68c-.13-.1978-.1955-.2967-.3257-.4946l-4.2286,2.7817C100.8935,614.9749,100.5685,614.478,99.9212,613.4824Zm8.356-1.043c.235.3562.3583.47.5595.5562.385.1836.9583-.0016,1.6753-.4772a8.9027,8.9027,0,0,0,1.05-.7695,1.4113,1.4113,0,0,0,.45-.6617,1.2647,1.2647,0,0,0-.2676-.9113c-.169-.256-.2533-.3841-.4219-.64l-3.4592,2.2756C108.0287,612.0626,108.1114,612.1883,108.2772,612.4394Z" />
                                    <path className="st4" d="M114.065,626.7166c-1.9309,1.2488-3.0078,1.3761-4.1217.8752a5.097,5.097,0,0,1-2.0039-1.7694,4.8867,4.8867,0,0,1-.9457-2.7977,2.6913,2.6913,0,0,1,.1586-.904,4.3287,4.3287,0,0,1,1.1043-1.5432,34.1293,34.1293,0,0,1,3.6737-2.71c.6291-.4261,1.2894-.8592,1.9269-1.2561a9.7538,9.7538,0,0,1,1.0059-.5836,3.27,3.27,0,0,1,3.9237.3247,5.6215,5.6215,0,0,1,1.1523,1.2539,3.9222,3.9222,0,0,1,.7154,3.49,3.9683,3.9683,0,0,1-1.05,1.57,30.76,30.76,0,0,1-2.8736,2.2093C115.8553,625.4959,114.9715,626.13,114.065,626.7166Zm.849-4.2419c.5926-.41,1.1708-.8224,1.7441-1.207a6.1969,6.1969,0,0,0,.87-.6756c.1318-.0911,1.1292-.9155.4979-1.8146a.9927.9927,0,0,0-.5419-.4346c-.6259-.2059-1.3163.25-1.804.5518-.5729.3541-1.162.7471-1.7558,1.1538-.8749.5994-1.7341,1.2166-2.5847,1.82a5.4412,5.4412,0,0,0-1.6312,1.441,1.1576,1.1576,0,0,0,1.1863,1.7225,11.2449,11.2449,0,0,0,2.4737-1.4879Z" />
                                    <path className="st4" d="M115.8305,636.6406a5.1414,5.1414,0,0,1,.2442-.4818,5.5559,5.5559,0,0,1,1.3946-1.3143l.6645-.481a10.5754,10.5754,0,0,0,1.05-.8352,1.3671,1.3671,0,0,0,.4944-.8862,1.307,1.307,0,0,0-.3419-.8848c-.2524-.3507-.3784-.5262-.63-.8774l-4.6337,3.3216c-.7009-.9777-1.05-1.4676-1.7456-2.4492l11.5232-8.1656c1.0956,1.5461,1.6467,2.3167,2.7557,3.8528.1058.1466.2215.3154.3125.4675a3.4094,3.4094,0,0,1,.5865,2.4049,3.9622,3.9622,0,0,1-1.8018,2.3157c-.2419.1771-.5132.3541-.7819.5224a3.132,3.132,0,0,1-2.2151.665,1.7077,1.7077,0,0,1-.8424-.4429,1.6214,1.6214,0,0,1,.1186,1.0464,2.7234,2.7234,0,0,1-1.2894,1.6252l-.21.154a11.4135,11.4135,0,0,0-2.5974,2.5124,2.3564,2.3564,0,0,0-.2093.4595C116.9345,638.16,116.5655,637.6543,115.8305,636.6406Zm5.338-6.3777a5.9351,5.9351,0,0,0,.4675.6138.8575.8575,0,0,0,.9455.3179,3.7684,3.7684,0,0,0,.9825-.5862,8.67,8.67,0,0,0,.69-.5494,1.181,1.181,0,0,0,.438-.9642,3.5164,3.5164,0,0,0-.5513-.97c-.2166-.301-.3247-.4516-.5408-.753l-2.9756,2.133C120.8415,629.8082,120.95,629.96,121.1685,630.2629Z" />
                                    <path className="st4" d="M119.5575,641.7194l9.3947-6.9771c-.5576-.7508-.8356-1.1268-1.39-1.88l1.95-1.4349c1.7841,2.4242,2.6846,3.63,4.5023,6.0288l-1.9295,1.4621c-.56-.7389-.839-1.1089-1.3958-1.85l-9.357,7.0274C120.62,643.1469,120.2648,642.6717,119.5575,641.7194Z" />
                                    <path className="st4" d="M134.6613,645.0044l.2052-.1593a8.8392,8.8392,0,0,0,.718-.6087.9772.9772,0,0,0,.1773-1.4373,1.03,1.03,0,0,0-.7706-.4739,1.0423,1.0423,0,0,0-.4677.081,2.446,2.446,0,0,0-.4966.306c-.6816.5249-.7874,1.0122-.6916,2.4755.0256.5951.0389.8928.0663,1.4884a7.5318,7.5318,0,0,1,.0532,1.2721,3.99,3.99,0,0,1-1.7377,3.3289A4.5554,4.5554,0,0,1,129.11,652.36a2.7026,2.7026,0,0,1-1.28-.3048,5.6415,5.6415,0,0,1-2.0024-1.7293,4.1366,4.1366,0,0,1-.9334-3.382,6.11,6.11,0,0,1,2.4375-2.7091c.6829.8965,1.0255,1.3439,1.713,2.2369-.3173.2443-.6506.5074-.9548.7609-.5922.4935-.7687.7723-.7458,1.1953a1.0866,1.0866,0,0,0,.2776.7188,1.1337,1.1337,0,0,0,1.2884.4539,2.53,2.53,0,0,0,.7577-.459,3.1706,3.1706,0,0,0,.6637-.6451c.3232-.4323.3254-.951.2325-1.9865-.0465-.623-.0693-.9344-.1138-1.557-.0109-.3762-.0347-.7682-.0168-1.14a3.6988,3.6988,0,0,1,1.6345-3.0335,5.2535,5.2535,0,0,1,1.0117-.6188,3.2856,3.2856,0,0,1,2.8553.0438,4.9982,4.9982,0,0,1,1.5829,1.4651,3.8094,3.8094,0,0,1,.9413,2.6748,2.7189,2.7189,0,0,1-.292.8929,6.1328,6.1328,0,0,1-1.8264,1.9186C135.6667,646.2969,135.3309,645.8667,134.6613,645.0044Z" />
                                    <path className="st4" d="M132.5475,656.8945a3.8076,3.8076,0,0,1-2.7538,1.04,3.4052,3.4052,0,0,1-1.2648-.3674,3.965,3.965,0,0,1-.635-.4469l1.4428-1.1432a2.8628,2.8628,0,0,0,.3645.1061.9139.9139,0,0,0,.7916-.1289c-.1742-.2193-.2612-.329-.4351-.5484l2.1325-1.69c.6718.8479,1.0088,1.271,1.6847,2.1156Z" />
                                    <path className="st4" d="M142.4187,670.4543c1.8738-2.2552,2.813-3.38,4.6962-5.6263a16.4558,16.4558,0,0,1,2.1668-2.2074c-.5882.4191-1.18.8679-1.81,1.267-.4942.3129-.9908.6251-1.5.9217-2.2535,1.2756-3.3818,1.9111-5.6417,3.1774-.87-1.0336-1.3029-1.5517-2.1661-2.5906,3.6975-4.3895,5.5555-6.5751,9.29-10.9276.7142.8675,1.0723,1.3,1.791,2.164-2.3,2.6028-3.4471,3.9072-5.7354,6.5221-.25.2621-.5.5266-.7633.7709-.3215.2984-.6468.5677-.9557.8529.5045-.3954,1.0249-.8023,1.5669-1.1512.5583-.3594,1.1144-.6937,1.6708-1.0271,2.4888-1.4278,3.7313-2.1443,6.2126-3.5828.8063.9578,1.2108,1.4355,2.0227,2.3885-1.95,2.2515-2.9229,3.38-4.8643,5.64-.4964.5585-1.0058,1.1218-1.528,1.63-.3237.3149-.6573.6167-1.0067.9159.5889-.45,1.2191-.8826,1.8207-1.3181,2.9881-1.8625,4.48-2.7971,7.4577-4.6732.7018.815,1.0538,1.2216,1.76,2.0331-4.91,2.9629-7.3729,4.4338-12.3135,7.3545C143.7177,671.9769,143.2837,671.47,142.4187,670.4543Z" />
                                    <path className="st4" d="M151.7247,681.1051a5.11,5.11,0,0,1,.1931-.5044,5.5562,5.5562,0,0,1,1.2513-1.4515l.6112-.547a10.5668,10.5668,0,0,0,.9575-.9393,1.3659,1.3659,0,0,0,.4-.9325,1.3075,1.3075,0,0,0-.4315-.8447c-.2873-.3227-.4308-.4842-.7174-.8075l-4.2656,3.7827c-.7981-.9-1.1959-1.351-1.989-2.2552l10.6175-9.3128c1.2492,1.4241,1.877,2.1335,3.1388,3.5468.12.1348.2529.2908.3591.4326a3.41,3.41,0,0,1,.832,2.3315,3.9621,3.9621,0,0,1-1.5528,2.49c-.2222.2011-.4738.4052-.7237.6a3.1314,3.1314,0,0,1-2.1345.89,1.7086,1.7086,0,0,1-.8838-.3536,1.6239,1.6239,0,0,1,.2262,1.0287,2.7232,2.7232,0,0,1-1.1144,1.75l-.1934.1749a11.4115,11.4115,0,0,0-2.3238,2.7675,2.3465,2.3465,0,0,0-.1606.4786C152.98,682.5024,152.5606,682.0374,151.7247,681.1051Zm4.65-6.8952a5.9488,5.9488,0,0,0,.5284.5622.8577.8577,0,0,0,.9733.2185,3.77,3.77,0,0,0,.9167-.6847,8.6634,8.6634,0,0,0,.6292-.6177,1.1809,1.1809,0,0,0,.3359-1.0043,3.5135,3.5135,0,0,0-.6485-.9078c-.2465-.277-.37-.4156-.6156-.693l-2.7393,2.4291C156.0028,673.7915,156.1268,673.931,156.375,674.21Z" />
                                    <path className="st4" d="M155.6649,685.4532l10.4147-9.5391c.781.8526,1.1727,1.2779,1.9583,2.1265l-10.3633,9.5949C156.8684,686.7647,156.4664,686.3282,155.6649,685.4532Z" />
                                    <path className="st4" d="M160.5546,690.7224l8.5259-8.0156c-.641-.6818-.9607-1.0233-1.5986-1.7077l1.771-1.6507c2.0531,2.2029,3.0875,3.2973,5.1719,5.4718l-1.7477,1.6752c-.642-.67-.9623-1.0054-1.6014-1.6778l-8.4827,8.0613C161.7753,692.0184,161.3676,691.5871,160.5546,690.7224Z" />
                                    <path className="st4" d="M166.7034,697.1578l8.3956-8.152c-.6521-.6716-.9774-1.008-1.6265-1.6823l1.7441-1.6789c2.0891,2.17,3.1414,3.2481,5.2613,5.3895l-1.72,1.7032c-.653-.66-.9788-.99-1.6289-1.6524l-8.3515,8.197C167.9453,698.4344,167.5305,698.01,166.7034,697.1578Z" />
                                    <path className="st4" d="M171.7328,702.2705l10.0037-9.9693c1.889,1.8956,2.84,2.8377,4.7528,4.71l-1.7911,1.83c-1.0909-1.0679-1.6345-1.6037-2.7177-2.679l-2.1424,2.1582c.974.967,1.4626,1.449,2.443,2.41l-1.75,1.7853c-.9848-.9654-1.4757-1.45-2.4542-2.421l-2.4246,2.4423c1.1012,1.0932,1.6538,1.6379,2.7629,2.7235l-1.8048,1.8438C174.647,705.1826,173.6714,704.2158,171.7328,702.2705Z" />
                                    <path className="st4" d="M183.4024,713.64c1.3055-2.9177,1.9644-4.3725,3.2945-7.2739.1592-.3731.3192-.7208.4948-1.0765.3038-.6157.6487-1.2066.9742-1.809-.2743.4026-.5483.7792-.8373,1.1371-.3141.389-.6476.7644-.9929,1.1545-2.1007,2.2648-3.1507,3.3975-5.25,5.6633-.8348-.7989-1.2512-1.1994-2.0817-2.0026l9.8178-10.1524c.9653.9335,1.4494,1.3988,2.4207,2.3264-1.2314,2.6633-1.842,3.9985-3.0526,6.6757-.2374.5472-.4911,1.11-.767,1.643-.2668.5154-.5446,1.0531-.8381,1.5736.3108-.4492.6212-.9041.9627-1.319.2147-.2608.4368-.5224.6418-.7693,2.1015-2.2857,3.1524-3.4284,5.2547-5.7133.8071.7621,1.2117,1.1422,2.0228,1.9l-9.6432,10.3182C184.852,715.0084,184.3678,714.5532,183.4024,713.64Z" />
                                    <path className="st4" d="M80.0079,606.5035c.3355-.18.6742-.373.9787-.5465.2529-.144.5406-.3045.8076-.4425a3.6582,3.6582,0,0,1,2.7421-.3456,3.2607,3.2607,0,0,1,.7367.3593,5.8282,5.8282,0,0,1,2.055,2.5675,4.5173,4.5173,0,0,1,.2766,2.4436c-.2619,1.1264-1.1242,1.8214-3.0035,3.0044-.6206-.9859-.93-1.48-1.5453-2.4689l.7819-.4866a3.3417,3.3417,0,0,0,1.2984-1.0557,1.1535,1.1535,0,0,0-.0728-1.1417,1.1013,1.1013,0,0,0-.54-.4559c-.6109-.2644-1.1058.114-2.0528.6733-.22.13-.46.2834-.6928.4022l-3.2181,1.9835c-.4485.3015-.92.5688-1.3513.858a4.3924,4.3924,0,0,0-.8015.6446,1.1511,1.1511,0,0,0,1.09,1.8364,8.3574,8.3574,0,0,0,1.9077-.9858l.39-.2431c.62.996.931,1.4932,1.5558,2.4858-1.6135,1.0407-2.4259,1.4774-3.416,1.5186-1.9692.1-3.1954-1.8693-3.4551-2.2888a4.783,4.783,0,0,1-.8-3.2087,2.4365,2.4365,0,0,1,.3821-.9107,9.28,9.28,0,0,1,2.87-2.3236Z" />
                                    <path className="st4" d="M86.9822,625.9951c-1.9672,1.1909-3.0591,1.27-4.1814.7026a5.4771,5.4771,0,0,1-2.01-1.9134,5.2377,5.2377,0,0,1-.9285-2.92,2.7476,2.7476,0,0,1,.17-.9217,4.2188,4.2188,0,0,1,1.1332-1.5338,32.8009,32.8009,0,0,1,3.7449-2.6115c.6409-.408,1.3138-.8221,1.9626-1.2a9.3807,9.3807,0,0,1,1.0238-.5521,3.3035,3.3035,0,0,1,3.9683.5221,6.0869,6.0869,0,0,1,1.1544,1.3449,4.155,4.155,0,0,1,.6906,3.6222,3.87,3.87,0,0,1-1.0784,1.5629A29.546,29.546,0,0,1,89.7,624.23C88.8072,624.8264,87.9058,625.4361,86.9822,625.9951Zm.9015-4.3186c.604-.3931,1.1938-.79,1.7777-1.1571a5.9627,5.9627,0,0,0,.8871-.6528c.1342-.0874,1.1523-.8867.5217-1.8418a1.063,1.063,0,0,0-.5443-.4729c-.6316-.2417-1.3352.1946-1.8315.481-.5832.3364-1.1837.7124-1.7887,1.1022-.8915.5744-1.7679,1.1683-2.6343,1.7478a5.2424,5.2424,0,0,0-1.6654,1.4034,1.2262,1.2262,0,0,0,1.1833,1.8268,10.816,10.816,0,0,0,2.5189-1.411Z" />
                                    <path className="st4" d="M91.04,639.7133l4.8325-3.3514c1.003-.6956,2.0152-1.4421,3.0533-2.0919.5868-.3674,1.2107-.7117,1.81-1.0764-.2991.1309-.6176.2827-.9175.4319-.4442.2208-.9243.46-1.3817.6758-.5856.2765-1.18.51-1.7626.7584-2.7561,1.1353-4.1362,1.6993-6.9,2.82-.67-.9743-1.0039-1.4623-1.669-2.44,1.9887-2.1407,2.9858-3.208,4.9851-5.3364.5515-.5774,1.123-1.1652,1.713-1.7176.3842-.36.7642-.7094,1.1471-1.0533.264-.237.5388-.49.8025-.7436-.3705.275-.7183.5545-1.0811.83-.7426.5639-1.4981,1.1245-2.2633,1.6531-.309.2135-.6282.4413-.9338.6553-.4828.3382-1.0113.682-1.5254,1.0288l-4.13,2.7853c-.6518-.9665-.9765-1.4506-1.6235-2.42,4.69-3.1492,7.0352-4.7236,11.7258-7.872.96,1.44,1.4434,2.1581,2.4147,3.5905-2.2331,2.3527-3.3465,3.5328-5.5669,5.9-.49.4884-.9545.9834-1.4645,1.4381-.36.3206-.7324.63-1.0928.9538.5075-.3219,1.0247-.5788,1.5321-.8425.383-.199.799-.3923,1.21-.5707,3.1346-1.3145,4.7-1.9762,7.8243-3.3085.9823,1.4246,1.4761,2.1349,2.4692,3.5517-4.6163,3.2563-6.9244,4.8846-11.54,8.1415C92.0377,641.149,91.7044,640.671,91.04,639.7133Z" />
                                    <path className="st4" d="M101.1,653.7628l4.7293-3.4953c.9817-.7255,1.971-1.502,2.9891-2.1829.5754-.3848,1.1886-.7478,1.7766-1.13-.295.14-.6088.3012-.9041.4593-.4372.2341-.91.4875-1.3606.7172-.577.294-1.1644.5454-1.7389.8112-2.7206,1.218-4.0829,1.8234-6.8117,3.0272-.6989-.9533-1.047-1.4308-1.741-2.3877,1.9237-2.1994,2.8882-3.2961,4.8228-5.4834.5339-.5937,1.0874-1.1983,1.6606-1.7682.3733-.3711.7426-.7321,1.115-1.0872.2567-.2449.5239-.506.78-.7674-.3621.286-.7013.5758-1.0557.8621-.7253.586-1.4635,1.169-2.2125,1.7205-.3025.2227-.6146.46-.9137.6831-.4724.3525-.99.7121-1.4937,1.0741l-4.0443,2.9084c-.68-.946-1.0192-1.42-1.6947-2.3693,4.5931-3.2889,6.89-4.9331,11.4835-8.2213,1.0028,1.41,1.5069,2.1125,2.52,3.5143-2.1616,2.4186-3.239,3.6316-5.3873,6.0648-.4747.5028-.9246,1.0115-1.4208,1.4813-.35.3312-.713.6514-1.0636.9862.4976-.3371,1.0069-.61,1.5061-.8883.3768-.2105.7867-.4163,1.1917-.6069,3.0934-1.4085,4.6376-2.1171,7.7207-3.5431,1.0243,1.3938,1.539,2.0887,2.5739,3.4744-4.5163,3.3937-6.7743,5.0907-11.29,8.485C102.14,655.1672,101.7927,654.7,101.1,653.7628Z" />
                                    <path className="st4" d="M114.6779,663.2222a6.7711,6.7711,0,0,1-3.351,1.7544c-.9109.0665-2.2724-.3722-3.5921-2.0864-.2484-.3227-1.6723-2.1491-1.0921-3.9314a5.6378,5.6378,0,0,1,2.176-2.5593l7.5908-5.75c.7112.939,1.0681,1.4076,1.7842,2.3429l-6.7184,5.1445a11.1586,11.1586,0,0,0-2.2211,1.97,1.4576,1.4576,0,0,0,.25,1.4749,1.441,1.441,0,0,0,1.1888.591,4.28,4.28,0,0,0,1.6123-.9243c.332-.2571.6673-.5435.9994-.8005l6.66-5.1548c.7311.9446,1.0979,1.4159,1.834,2.3565Z" />
                                    <path className="st4" d="M117.0127,674.3043c1.6518-2.7521,2.4832-4.1234,4.1572-6.8564.2033-.3534.4046-.6808.6223-1.0139.3769-.5765.7925-1.1221,1.19-1.6821-.3228.3656-.6423.7049-.9748,1.023-.3613.3456-.7408.6748-1.1337,1.0168-2.3782,1.9714-3.5671,2.9574-5.9444,4.93-.75-.9354-1.124-1.404-1.8693-2.3433L124.1235,660.6c.8671,1.0928,1.3023,1.6379,2.1762,2.7253-1.5469,2.5075-2.3157,3.7654-3.8438,6.2893-.3022.5173-.6227,1.0482-.962,1.5451-.3282.4806-.67.9822-1.0261,1.4639.3651-.4069.7305-.82,1.1225-1.1874.2464-.2311.5005-.4618.7357-.68,2.3819-1.9918,3.5731-2.9874,5.9558-4.9783.7272.8945,1.0919,1.3408,1.8237,2.2316l-10.9127,8.965C118.3176,675.9089,117.8815,675.3749,117.0127,674.3043Z" />
                                    <path className="st4" d="M121.7018,680.0072l10.851-9.04c.77.9243,1.1563,1.3854,1.9313,2.3055l-10.8024,9.0978C122.8872,681.4276,122.4912,680.9548,121.7018,680.0072Z" />
                                    <path className="st4" d="M132.5166,679.09c.3028-.23.6067-.475.88-.6943.227-.1822.4857-.3862.7276-.5646a3.661,3.661,0,0,1,2.6516-.7762,3.2526,3.2526,0,0,1,.7832.2374,5.8083,5.8083,0,0,1,2.4311,2.2062,4.5074,4.5074,0,0,1,.6568,2.3665c-.0815,1.1527-.8233,1.9751-2.4923,3.44-.7674-.8742-1.15-1.3124-1.9128-2.1905l.6952-.6039a3.3482,3.3482,0,0,0,1.1156-1.2473,1.1511,1.1511,0,0,0-.2512-1.1145,1.0982,1.0982,0,0,0-.6048-.364c-.6444-.1638-1.0734.2879-1.92.99-.1969.1633-.4092.3524-.62.5066l-2.8644,2.4669c-.3953.3685-.8183.7069-1.1988,1.0607a4.4031,4.4031,0,0,0-.69.7629,1.1483,1.1483,0,0,0,1.3642,1.6383,8.3718,8.3718,0,0,0,1.7279-1.275l.3472-.3017c.768.8841,1.1532,1.3252,1.9259,2.2054-1.4289,1.2825-2.1621,1.842-3.1328,2.04-1.9277.411-3.4471-1.337-3.7692-1.71a4.7682,4.7682,0,0,1-1.2931-3.0376,2.438,2.438,0,0,1,.2342-.9587,9.2952,9.2952,0,0,1,2.4677-2.7471Z" />
                                    <path className="st4" d="M137.1428,697.7878c.9613-1.2629,1.4426-1.8937,2.4068-3.1539-.6558-.7265-.9829-1.09-1.6355-1.82-1.35.8313-2.0253,1.2461-3.3774,2.0742-.85-.9521-1.2737-1.4293-2.1183-2.386,5.1143-2.767,7.6622-4.1638,12.7392-6.9839.9907,1.1093,1.488,1.6624,2.4861,2.7653-3.3706,4.7184-5.0441,7.0881-8.3673,11.8482C138.42,699.1967,137.9936,698.7278,137.1428,697.7878Zm6.0037-7.65c.4645-.56.93-1.12,1.4233-1.6478-.577.4347-1.1834.8382-1.7893,1.2406-1.1581.7344-1.7376,1.101-2.8974,1.8332.4468.4985.67.7474,1.1187,1.2446C141.8587,691.74,142.2877,691.2055,143.1465,690.1382Z" />
                                    <path className="st4" d="M141.1741,702.1957l8.5942-7.9423c-.6608-.7151-.99-1.0733-1.6481-1.7912l1.785-1.6353c2.1171,2.3108,3.1838,3.4589,5.3336,5.74l-1.762,1.66c-.6621-.7026-.9923-1.0546-1.6513-1.76l-8.5514,7.9884C142.4317,703.554,142.0117,703.102,141.1741,702.1957Z" />
                                    <path className="st4" d="M146.2687,707.6371l10.2467-9.7193c.8286.8735,1.244,1.3091,2.0774,2.1783L148.3984,709.87C147.544,708.9792,147.1181,708.5326,146.2687,707.6371Z" />
                                    <path className="st4" d="M159.9708,715.5112c-1.6867,1.563-2.74,1.8608-3.9542,1.531a5.4777,5.4777,0,0,1-2.3549-1.4688,5.2345,5.2345,0,0,1-1.4979-2.6726,2.7486,2.7486,0,0,1-.02-.9368,4.2226,4.2226,0,0,1,.8008-1.7306,32.84,32.84,0,0,1,3.1415-3.3129c.5455-.5289,1.121-1.07,1.68-1.5707a9.3753,9.3753,0,0,1,.8914-.7472,3.303,3.303,0,0,1,3.9915-.29,6.082,6.082,0,0,1,1.4017,1.0843,4.1565,4.1565,0,0,1,1.407,3.4087,3.87,3.87,0,0,1-.741,1.7485,29.5414,29.5414,0,0,1-2.441,2.68C161.5226,713.9984,160.7627,714.7774,159.9708,715.5112Zm.0118-4.4121c.5123-.5069,1.01-1.014,1.5078-1.4918a5.9653,5.9653,0,0,0,.7373-.8183c.1138-.1126.95-1.1008.14-1.9091a1.0632,1.0632,0,0,0-.6284-.3534c-.6673-.1094-1.2684.46-1.6968.84-.5034.4471-1.0157.9364-1.53,1.44-.7574.7424-1.4961,1.5008-2.2278,2.2431a5.2439,5.2439,0,0,0-1.3483,1.71,1.2261,1.2261,0,0,0,1.5273,1.55,10.8139,10.8139,0,0,0,2.1827-1.89Z" />
                                    <path className="st4" d="M163.75,725.2859c1.3074-2.9322,1.9672-4.3943,3.299-7.31.1593-.3754.32-.7248.4955-1.0818.3046-.6179.6514-1.21.9783-1.8137-.2764.4019-.5527.7772-.8445,1.1331-.317.3866-.6542.759-1.0031,1.1458-2.1239,2.2431-3.1856,3.3649-5.3085,5.609-.8587-.84-1.2869-1.2609-2.141-2.1051,3.9712-4.0179,5.9568-6.0268,9.9281-10.0446.9937.9822,1.4922,1.4718,2.4922,2.448-1.2329,2.6766-1.8442,4.0184-3.0564,6.709-.2375.55-.4916,1.1161-.7685,1.65-.2678.5168-.5468,1.0561-.8418,1.5774.3133-.4481.6264-.902.9712-1.3141.2168-.259.4413-.5187.6484-.764,2.1252-2.2637,3.188-3.3954,5.314-5.6584.8311.8022,1.2477,1.2022,2.083,2L166.24,727.6793C165.2409,726.7248,164.7428,726.2462,163.75,725.2859Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                    <path className="st4" d="M97.99,607.09l0.33-0.43l1.54-1.1l0.73-0.39l1.16-0.69l0.3-0.28l0.3-0.53l-0.07-0.5l-0.17-0.42l-0.53-0.95
			l-5.04,2.69l-1.45-2.61l12.5-6.66l2.62,4.72l0.35,0.97l0.06,0.73l-0.13,0.79l-0.65,1.02l-1.45,1.03l-0.85,0.41l-1.15,0.4
			l-1.17-0.09l-0.77-0.52l-0.02,1.03l-0.61,0.88l-0.89,0.54l-0.23,0.12l-1.87,1.18l-1.06,0.94l-0.27,0.42L97.99,607.09z
			 M104.51,602.18l0.32,0.29l0.61,0.14l1.06-0.45l0.76-0.45l0.3-0.32l0.25-0.58l-0.44-1.04l-0.46-0.82l-3.24,1.73L104.51,602.18z"/>
                                    <path className="st4" d="M100.03,610.68l12.32-6.96l3.45,5.87l-2.23,1.26l-1.96-3.33l-2.65,1.5l1.75,2.98l-2.18,1.23l-1.75-2.98
			l-3,1.7l1.96,3.33l-2.25,1.27L100.03,610.68z"/>
                                    <path className="st4" d="M104.31,617.93l12.18-7.26l2.46,3.96l0.44,1.21l0.04,0.93l-0.36,0.98l-0.79,0.93l-1.48,1l-1.27,0.66
			l-0.96,0.36l-0.92,0.12l-0.88-0.17l-0.82-0.49l-0.69-0.69l-0.73-1.14l-0.31-0.5l-4.36,2.6L104.31,617.93z M112.8,617.2l0.54,0.56
			l0.71,0l0.97-0.41l1.12-0.71l0.46-0.63l-0.05-0.5l-0.2-0.43l-0.41-0.66l-3.56,2.12L112.8,617.2z"/>
                                    <path className="st4" d="M117.44,630.44l-1.38,0.7l-1.12,0.27l-0.9-0.01l-0.8-0.27l-1.36-0.98l-0.59-0.83l-0.7-1.32l-0.17-1.46
			l0.18-0.89l0.62-1.02l0.54-0.46l3.75-2.61l1.98-1.18l1.03-0.55l1.04-0.39l1.04-0.05l0.96,0.26l0.94,0.67l1.12,1.3l0.58,1.11
			l0.24,0.99l-0.16,1.43l-0.61,1.04l-0.49,0.5l-2.99,2.05L117.44,630.44z M120.21,625.1l0.9-0.64l0.51-0.61l0.19-0.55l-0.15-0.63
			l-0.54-0.46l-0.99,0.07l-0.85,0.42l-1.8,1.09l-2.65,1.72l-0.93,0.63l-0.76,0.75l-0.15,0.75l0.17,0.47l0.51,0.41l0.62,0.11
			l1.04-0.49l1.5-0.9L120.21,625.1z"/>
                                    <path className="st4" d="M118.24,639.2l0.29-0.46l1.42-1.25l0.68-0.46l1.08-0.8l0.27-0.31l0.25-0.56l-0.12-0.49l-0.21-0.4l-0.62-0.89
			l-4.74,3.17l-1.71-2.45l11.74-7.86l3.09,4.44l0.45,0.93l0.14,0.72l-0.05,0.8l-0.54,1.08l-1.34,1.17l-0.81,0.49l-1.1,0.52
			l-1.18,0.02l-0.82-0.44l0.08,1.03l-0.52,0.94l-0.83,0.63l-0.22,0.14l-1.74,1.36l-0.96,1.04l-0.23,0.45L118.24,639.2z
			 M124.22,633.67l0.35,0.26l0.62,0.08l1.01-0.55l0.71-0.52l0.27-0.35l0.19-0.6l-0.54-0.99l-0.54-0.77l-3.04,2.04L124.22,633.67z"/>
                                    <path className="st4" d="M121.41,643.64l9.57-6.74l-1.4-1.92l1.98-1.39l4.53,6.2l-1.98,1.39l-1.4-1.92l-9.57,6.74L121.41,643.64z" />
                                    <path className="st4" d="M136.06,646.43l0.2-0.17l0.74-0.57l0.37-0.83l-0.18-0.6l-0.3-0.26l-0.5-0.22l-0.46,0.06l-0.5,0.29l-0.45,0.4
			l-0.18,0.49l-0.12,1.56l0.08,1.49l0.01,1.26l-0.33,1.54l-0.55,0.86l-0.93,0.86l-1.26,0.72l-1.41,0.3l-1.27-0.34l-1.37-0.93
			l-0.62-0.81l-0.79-1.23l-0.26-1.03l0.14-1.1l0.39-0.84l0.63-0.69l1.46-1.12l1.72,2.25l-0.98,0.72l-0.62,0.64l-0.12,0.57l0.26,0.7
			l0.58,0.43l0.71,0.04l0.77-0.44l0.68-0.63l0.26-0.78l0-1.19l-0.11-1.55l-0.01-1.13l0.16-1.21l0.46-0.82l1.04-0.97l1.03-0.6
			l1.16-0.29l0.84,0.04l0.9,0.35l0.72,0.51l0.89,1l0.84,1.59l0.12,1.14l-0.32,0.87l-0.84,1.05l-1.04,0.82L136.06,646.43z"/>
                                    <path className="st4" d="M133.3,657.58l-1.34,0.83l-0.69,0.2l-0.71,0.02l-1.3-0.36l-0.6-0.44l1.43-1.13l0.38,0.09l0.42,0.01l0.38-0.14
			l-0.44-0.54l2.13-1.69l1.72,2.09L133.3,657.58z"/>
                                    <path className="st4" d="M147.45,660.51l0.19-0.18l0.71-0.61l0.33-0.85l-0.21-0.59l-0.31-0.25l-0.51-0.2l-0.46,0.08l-0.49,0.32
			l-0.42,0.43l-0.16,0.5l-0.04,1.57l0.16,1.49l0.07,1.26l-0.25,1.56l-0.5,0.89l-0.88,0.91l-1.22,0.78l-1.39,0.37l-1.28-0.28
			l-1.41-0.86l-0.66-0.78l-0.85-1.19l-0.31-1.02l0.09-1.1l0.34-0.86l0.59-0.72l1.4-1.19l1.83,2.16l-0.95,0.77l-0.59,0.67l-0.09,0.57
			l0.29,0.69l0.61,0.41l0.71,0l0.75-0.48l0.65-0.66l0.22-0.79l-0.06-1.19l-0.18-1.54l-0.07-1.13l0.1-1.21l0.41-0.84l0.99-1.02
			l0.99-0.65l1.14-0.35l0.84,0l0.92,0.31l0.74,0.48l0.94,0.96l0.92,1.55l0.17,1.13l-0.27,0.88l-0.78,1.09l-1,0.86L147.45,660.51z"/>
                                    <path className="st4" d="M149.19,664.39l0.88-0.7l0.74-0.55l0.93-0.55l0.81-0.26l0.92,0.02l0.77,0.2l1.25,0.74l0.67,0.67l0.51,0.72
			l0.43,0.88l0.21,1.44l-0.19,0.84l-0.46,0.77l-1.86,1.79l-1.9-2.13l0.7-0.6l0.72-0.72l0.4-0.51l0.01-0.47l-0.27-0.63l-0.59-0.33
			l-0.47,0.05l-0.43,0.15l-1.04,0.79l-4.69,4.02l-0.68,0.77l-0.17,0.5l0.05,0.36l0.25,0.41l0.42,0.29l0.77-0.01l1.75-1.25l0.35-0.3
			l1.9,2.13l-1.78,1.42l-1.38,0.59l-1.42,0l-1.09-0.5l-1.2-1.08l-0.8-1.02l-0.39-0.89l-0.08-1.02l0.25-0.92l0.83-1.23l1.63-1.51
			L149.19,664.39z"/>
                                    <path className="st4" d="M152.98,681.69l4.53-4.11l-1.78-1.9l-4.53,4.11l-2.02-2.16l10.45-9.47l2.02,2.16l-4.2,3.81l1.79,1.9l4.2-3.81
			l2.02,2.16L155,683.85L152.98,681.69z"/>
                                    <path className="st4" d="M160.7,689.61l0.2-0.51l1.18-1.47l0.59-0.57l0.92-0.97l0.22-0.35l0.14-0.59l-0.2-0.46l-0.28-0.36l-0.77-0.77
			l-4.11,3.94l-2.11-2.13l10.17-9.75l3.82,3.84l0.61,0.84l0.26,0.69l0.09,0.8l-0.34,1.15l-1.11,1.38l-0.71,0.62l-1,0.7l-1.16,0.23
			l-0.88-0.29l0.26,1l-0.34,1.01l-0.71,0.76l-0.19,0.18l-1.47,1.64l-0.76,1.19l-0.14,0.48L160.7,689.61z M165.61,683.14l0.39,0.19
			l0.62-0.03l0.89-0.71l0.61-0.64l0.2-0.39l0.08-0.62l-0.71-0.88l-0.67-0.67l-2.64,2.53L165.61,683.14z"/>
                                    <path className="st4" d="M163.62,692.49l9.97-9.96l2.13,2.05l-9.97,9.96L163.62,692.49z" />
                                    <path className="st4" d="M167.2,695.95l9.82-10.1l4.97,4.64l-1.73,1.79l-2.79-2.61l-2.14,2.12l2.58,2.41l-1.69,1.75l-2.58-2.41
			l-4.27,4.42L167.2,695.95z"/>
                                    <path className="st4" d="M174.02,702.25l7.93-8.55l-1.78-1.59l1.64-1.77l5.74,5.14l-1.64,1.77l-1.78-1.59l-7.93,8.56L174.02,702.25z"
                                    />
                                    <path className="st4" d="M181.55,702.47l1.37-1.53l3.58,3.09l-1.37,1.53L181.55,702.47z" />
                                </g>
                                <g>
                                    <path className="st4" d="M67.27,603.38l12.51-6.62l1.37,2.53l-10.28,5.44l1.96,3.62l-2.23,1.18L67.27,603.38z" />
                                    <path className="st4" d="M71.11,610.42l12.4-6.81l1.43,2.54l-12.4,6.81L71.11,610.42z" />
                                    <path className="st4" d="M80.98,609.89l1-0.52l0.83-0.4l1.01-0.37l0.84-0.11l0.9,0.19l0.71,0.34l1.07,0.95l0.52,0.78l0.35,0.8
			l0.24,0.93l-0.08,1.45l-0.35,0.79l-0.61,0.67l-2.17,1.4l-1.42-2.43l0.8-0.46l0.85-0.58l0.5-0.42l0.1-0.46l-0.14-0.67l-0.51-0.43
			l-0.46-0.04l-0.45,0.07l-1.17,0.58l-5.38,3.07l-0.81,0.63l-0.27,0.46l-0.02,0.36l0.17,0.44l0.35,0.36l0.75,0.13l1.95-0.91
			l0.4-0.23l1.42,2.43l-2.02,1.06l-1.46,0.32l-1.38-0.26l-0.96-0.69l-0.96-1.27l-0.58-1.14l-0.2-0.94l0.13-1.01l0.43-0.85l1.06-1.05
			l1.89-1.17L80.98,609.89z"/>
                                    <path className="st4" d="M81.04,627.28l5.25-3.17l-1.35-2.19l-5.25,3.17l-1.53-2.48l12.1-7.3l1.53,2.48l-4.87,2.94l1.35,2.19
			l4.87-2.94l1.53,2.48l-12.1,7.3L81.04,627.28z"/>
                                    <path className="st4" d="M83.55,631.29l11.95-7.56l3.67,5.67L97,630.76l-2.08-3.22l-2.57,1.63l1.86,2.88l-2.12,1.34l-1.86-2.88
			l-2.91,1.84l2.08,3.22l-2.18,1.38L83.55,631.29z"/>
                                    <path className="st4" d="M94.11,647.05l3.81-6.73l-5.73,3.97l-1.66-2.38l11.69-7.95l1.62,2.33l-5.26,3.58l6.99-1.1l1.85,2.66
			l-7.5,0.81l-3.99,7.42L94.11,647.05z"/>
                                    <path className="st4" d="M104.54,654.09l-1.34,0.78l-1.1,0.33l-0.89,0.04l-0.81-0.22l-1.4-0.9l-0.63-0.79l-0.76-1.28l-0.24-1.45
			l0.14-0.9l0.57-1.05l0.51-0.49l3.59-2.82l1.91-1.29l0.99-0.61l1.01-0.45l1.03-0.12l0.97,0.21l0.96,0.61l1.17,1.23l0.63,1.07
			l0.29,0.97l-0.09,1.44l-0.56,1.07l-0.46,0.53l-2.86,2.22L104.54,654.09z M107.03,648.62l0.86-0.69l0.48-0.64l0.16-0.56l-0.18-0.62
			l-0.56-0.43l-0.98,0.12l-0.83,0.46l-1.73,1.19l-2.55,1.87l-0.89,0.68l-0.72,0.79l-0.12,0.76l0.19,0.46l0.52,0.38l0.63,0.08
			l1.01-0.55l1.44-0.98L107.03,648.62z"/>
                                    <path className="st4" d="M108.09,665.77l4.65-3.53l2.99-2.25l1.73-1.13l-0.85,0.47l-1.39,0.72l-1.66,0.86l-6.78,3.16l-1.66-2.17
			l4.67-5.56l1.68-1.76l1.05-1.13l0.79-0.75l-1.07,0.87l-2.12,1.81l-6.38,4.79l-1.65-2.16l11.18-8.59l2.62,3.34l-5.32,6.1
			l-1.36,1.51l-1.09,0.98l1.51-0.92l1.16-0.63l7.64-3.73l2.57,3.32l-11.22,8.57L108.09,665.77z"/>
                                    <path className="st4" d="M117.42,677.13l4.5-3.72l2.9-2.37l1.68-1.2l-0.83,0.5l-1.36,0.78l-1.63,0.92l-6.65,3.44l-1.75-2.11l4.44-5.75
			l1.6-1.82l1-1.17l0.75-0.78l-1.04,0.91l-2.04,1.9l-6.18,5.05l-1.74-2.09l10.82-9.04l2.75,3.23l-5.06,6.31l-1.29,1.57l-1.05,1.02
			l1.47-0.98l1.13-0.67l7.48-4.04l2.71,3.21l-10.86,9.02L117.42,677.13z"/>
                                    <path className="st4" d="M129.85,683.71l-1.56,1.22l-0.82,0.46l-0.88,0.25l-0.82,0.04l-0.86-0.28l-0.97-0.53l-0.95-0.95l-0.97-1.31
			l-0.36-1.14l0.1-1.24l0.29-0.84l0.48-0.66l1.25-1.16l7.2-6.23l1.91,2.16l-6.4,5.53l-1.38,1.22l-0.74,0.85l-0.09,0.51l0.08,0.39
			l0.31,0.51l0.41,0.32l0.78,0.16l0.65-0.35l0.9-0.67l0.97-0.84l6.35-5.52l1.94,2.19L129.85,683.71z"/>
                                    <path className="st4" d="M131.33,692.72l3.74-6.95l0.62-1.06l1.04-1.69l-0.88,1.05l-1.08,1.1l-5.62,5.26l-1.94-2.05l10.43-9.54
			l2.31,2.51l-3.49,6.4l-0.88,1.6l-0.95,1.47l1.04-1.23l0.7-0.72l5.66-5.3l1.91,2.05l-10.43,9.51L131.33,692.72z"/>
                                    <path className="st4" d="M134.76,696.4l10.25-9.69l2.03,2.1l-10.25,9.69L134.76,696.4z" />
                                    <path className="st4" d="M142.43,704.2l2.54-7.29l-4.92,4.91l-2.05-2.05l10.07-9.87l2.01,2.01l-4.54,4.45l6.68-2.31l2.3,2.3
			l-7.24,2.12l-2.59,8L142.43,704.2z"/>
                                    <path className="st4" d="M149.07,710.62l2.22-3.27l-1.69-1.61l-3.2,2.33l-2.19-2.09l12.12-7.98l2.62,2.5l-7.7,12.21L149.07,710.62z
			 M154.6,702.64l1.3-1.69l-1.68,1.35l-2.75,2.04l1.16,1.11L154.6,702.64z"/>
                                    <path className="st4" d="M152.73,714.04l7.98-8.53l-1.74-1.59l1.65-1.76l5.61,5.15l-1.65,1.76l-1.74-1.59l-7.98,8.53L152.73,714.04z"
                                    />
                                    <path className="st4" d="M157.1,718.02l9.48-10.42l2.18,1.95l-9.48,10.42L157.1,718.02z" />
                                    <path className="st4" d="M169.68,723.27l-1.12,1.06l-0.99,0.57l-0.86,0.24l-0.84-0.03l-1.57-0.56l-0.8-0.63l-1.03-1.07l-0.57-1.36
			l-0.07-0.9l0.31-1.16l0.38-0.59l2.85-3.55l1.56-1.69l0.83-0.82l0.88-0.66l0.98-0.34l0.99-0.02l1.08,0.38l1.42,0.94l0.86,0.9
			l0.5,0.88l0.24,1.42l-0.3,1.17l-0.32,0.62l-2.28,2.81L169.68,723.27z M170.85,717.38l0.68-0.86l0.32-0.73l0.03-0.58l-0.32-0.56
			l-0.64-0.29l-0.92,0.34l-0.7,0.64l-1.41,1.55l-2.05,2.4l-0.71,0.87l-0.51,0.93l0.06,0.76l0.29,0.41l0.59,0.25l0.63-0.07l0.86-0.76
			l1.18-1.28L170.85,717.38z"/>
                                    <path className="st4" d="M172.76,731.34l2.75-7.39l0.47-1.14l0.8-1.82l-0.73,1.16l-0.92,1.23l-4.84,5.96l-2.2-1.77l9.02-10.84
			l2.63,2.18l-2.58,6.81l-0.66,1.71l-0.74,1.59l0.87-1.35l0.59-0.81l4.88-6.01l2.17,1.78l-9.03,10.81L172.76,731.34z"/>
                                </g>
                            </>}
                        </g>
                        <g id="Segment_-_6" className={"model-option" + (this.state.changed ? this.setStyles(8) : '')}>
                            <path id="Circle_-_2-2_5_" className="st0" d="M274.53,425.2H99.75c0.24,47.98,10.47,96.46,32.16,141.24l157.56-75.88
		C279.33,469.76,274.7,447.52,274.53,425.2z"/>
                            <path id="Circle_-_2-1_11_" className="st1" d="M274.53,425.2H99.75c0.24,47.98,10.47,96.46,32.16,141.24l157.56-75.88
		C279.33,469.76,274.7,447.52,274.53,425.2z"/>
                            <path id="Circle_-_1-2" className="st0" d="M99.75,425.2H0.5c0.08,62.51,13.72,125.68,42.06,184.27l89.35-43.03
		C110.2,521.57,99.99,473.15,99.75,425.2z"/>
                            <path id="Circle_-_1-1_1_" className="st2" d="M99.75,425.2H0.5c0.08,62.51,13.72,125.68,42.06,184.27l89.35-43.03
		C110.2,521.57,99.99,473.15,99.75,425.2z"/>

                            <path id="Title_x5F_-_x5F_2_x5F_1_x5F_" className="st5" d="M503.65,772.61c-192.36,43.42-383.51-77.32-426.93-269.68
		S154.04,119.42,346.4,76s383.51,77.32,426.93,269.68S696.01,729.19,503.65,772.61z"/>
                            <text id="Title_-_2_6_">	<textPath xlinkHref="#Title_x5F_-_x5F_2_x5F_1_x5F_" startOffset="100%">
                            </textPath>
                            </text>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M31.3625,482.6009l5.8167-.8663c1.2073-.18,2.4449-.4,3.6637-.5256.6888-.0708,1.4-.1054,2.0986-.1689-.3258-.0132-.6779-.0155-1.013-.0129-.4959.0039-1.0315.0078-1.537.0015-.647-.0079-1.2829-.0571-1.9143-.0886-2.971-.1851-4.4568-.2845-7.429-.4968-.163-1.1451-.242-1.7181-.3952-2.8648,2.739-1.0091,4.11-1.5071,6.8554-2.49.7529-.2627,1.5283-.5257,2.3043-.7492.5054-.1455,1.0023-.2836,1.5-.4157.343-.0911.7022-.1916,1.0522-.2964-.4549.0773-.8908.1691-1.34.2509-.9183.1676-1.8439.3258-2.7658.4526-.3723.0512-.7592.1112-1.1289.1644-.5839.084-1.21.1535-1.8252.2315l-4.9419.6269c-.1435-1.1317-.2129-1.698-.347-2.8311,5.6083-.68,8.4122-1.02,14.0206-1.6989.1952,1.6525.2982,2.4779.5145,4.1271-3.0551,1.0735-4.5807,1.6182-7.628,2.7235-.6567.2143-1.2938.4461-1.954.6226-.4655.1244-.9369.2331-1.4043.3612.5977-.0621,1.1747-.0635,1.7464-.075.4317-.0086.89.002,1.3362.0231,3.3891.2021,5.0833.2948,8.4711.4632.2375,1.6461.3616,2.4683.62,4.1107-5.5781.8946-8.3667,1.342-13.9446,2.238C31.6168,484.292,31.53,483.7285,31.3625,482.6009Z" />
                                    <path className="st4" d="M32.4152,489.2182c5.57-.943,8.3548-1.4142,13.925-2.3573.4462,2.636.6829,3.9515,1.1833,6.5768l-2.5153.4794c-.2862-1.5017-.425-2.2534-.6936-3.7586l-2.9937.5343c.2425,1.3587.3673,2.0374.624,3.3933l-2.4563.465c-.2587-1.3662-.3844-2.05-.6288-3.419l-3.3878.6047c.276,1.5466.4186,2.3191.7127,3.8621l-2.5345.4831C33.128,493.3421,32.881,491.9692,32.4152,489.2182Z" />
                                    <path className="st4" d="M34.3016,499.4085c5.5386-1.1135,8.3074-1.67,13.846-2.7835.5267,2.62.8035,3.927,1.3841,6.5349l-2.4994.5564c-.3321-1.4918-.4938-2.2386-.8084-3.7343l-2.9758.6259c.2839,1.35.4295,2.0244.7276,3.3714l-2.4409.54c-.3-1.3571-.447-2.0366-.7332-3.3969l-3.3677.7083c.3233,1.5369.4894,2.3043.8307,3.8372l-2.5185.5606C35.14,503.5068,34.8513,502.1427,34.3016,499.4085Z" />
                                    <path className="st4" d="M36.5993,509.9616l11.393-2.672c-.2141-.9129-.3195-1.37-.5271-2.284l2.3609-.5359c.6659,2.934,1.0159,4.3971,1.75,7.3146l-2.3478.5905c-.2267-.9012-.3384-1.3522-.5587-2.255l-11.3686,2.7737C37.015,511.722,36.8747,511.1356,36.5993,509.9616Z" />
                                    <path className="st4" d="M38.3388,517.0339l13.6771-3.5207c.288,1.1189.4346,1.6778.7326,2.7941L39.1033,519.95C38.7923,518.785,38.6394,518.2017,38.3388,517.0339Z" />
                                    <path className="st4" d="M41.8328,529.5973c2.5833-1.8914,3.88-2.8263,6.4851-4.6741.3247-.2441.6376-.4646.9679-.6844.5717-.3807,1.1667-.7187,1.7509-1.0761-.44.21-.8656.3982-1.2954.5625-.4669.1785-.9441.3351-1.4384.4994-2.9543.9025-4.4312,1.3543-7.3849,2.2591-.3251-1.1266-.4852-1.6905-.8-2.82L53.72,519.8658c.36,1.2907.5439,1.9351.9177,3.2221-2.4025,1.6836-3.5991,2.5347-5.9832,4.2554-.4785.3568-.9785.7194-1.4833,1.0449-.4881.3148-.9966.6435-1.51.9505.493-.2354.9884-.4752,1.4914-.6655.3163-.12.639-.2355.94-.3479,2.9612-.9339,4.4419-1.4,7.4037-2.3323.3189,1.0607.4806,1.59.8085,2.6484l-13.49,4.1811C42.4156,531.5343,42.2192,530.8893,41.8328,529.5973Z" />
                                    <path className="st4" d="M45.6826,541.6445l1.1741-.4a2.9886,2.9886,0,0,1-.5745-.1365,3.0433,3.0433,0,0,1-1.6488-1.897,4.1473,4.1473,0,0,1-.1143-2.0793,2.4183,2.4183,0,0,1,.4912-.95c.671-.8526,1.5865-1.21,3.5046-1.846.6386-.2117,1.27-.4287,1.8982-.63.9524-.3049,1.919-.6035,2.8768-.8566a5.5414,5.5414,0,0,1,2.92-.1951c1.7749.5485,2.46,2.62,2.5754,2.9622a5.1412,5.1412,0,0,1,.2377.9565,3.6176,3.6176,0,0,1-.7987,2.9124,6.16,6.16,0,0,1-1.8739,1.188c-.2741.1184-.5605.2166-.8551.3415-.3759-1.0769-.5615-1.6161-.928-2.696l.5678-.1927a4.1943,4.1943,0,0,0,1.2362-.5906,1.2726,1.2726,0,0,0-.5238-2.1289,6.5966,6.5966,0,0,0-3.0393.62l-3.4385,1.135a5.2813,5.2813,0,0,0-2.0676,1.0106,1.2759,1.2759,0,0,0-.1834,1.2234,1.2579,1.2579,0,0,0,.9207.8527,5.0627,5.0627,0,0,0,2.1452-.4642c.2275-.0772.48-.1406.7262-.2241-.196-.5775-.2933-.8665-.4866-1.4449l2.1626-.7229c.5422,1.6221.8186,2.4315,1.3819,4.0469l-7.4608,2.6017C46.1744,543.0836,46.0092,542.6043,45.6826,541.6445Z" />
                                    <path className="st4" d="M58.6488,548.0756l.2432-.0913a8.8146,8.8146,0,0,0,.866-.3687.9806.9806,0,0,0,.5946-1.3244.9861.9861,0,0,0-1.0661-.7479,2.4676,2.4676,0,0,0-.5656.1429c-.8074.2971-1.0544.7321-1.3944,2.1665-.1511.5794-.2259.87-.3744,1.4507a7.6808,7.6808,0,0,1-.3211,1.24,3.9839,3.9839,0,0,1-2.6321,2.6888,4.4935,4.4935,0,0,1-2.8144.26,2.7336,2.7336,0,0,1-1.14-.6852,5.8038,5.8038,0,0,1-1.4169-2.2894,4.2377,4.2377,0,0,1,.1034-3.5594,6.0149,6.0149,0,0,1,3.1384-1.8561c.3846,1.0741.5793,1.61.9732,2.6815-.3758.1382-.7724.2892-1.1387.44-.7132.2942-.9638.509-1.0661.9252a1.1111,1.1111,0,0,0,.0547.7793,1.15,1.15,0,0,0,1.1018.8328,2.4821,2.4821,0,0,0,.86-.212,3.11,3.11,0,0,0,.8243-.4214c.4364-.3185.59-.8187.8061-1.844.1392-.6142.2095-.9208.3516-1.533.1012-.3651.195-.7491.323-1.1a3.6725,3.6725,0,0,1,2.4692-2.41,5.2523,5.2523,0,0,1,1.1515-.2838,3.2981,3.2981,0,0,1,2.7111.9049,5.0458,5.0458,0,0,1,1.0741,1.8738,3.838,3.838,0,0,1,.1093,2.8375,2.7084,2.7084,0,0,1-.541.769,6.12,6.12,0,0,1-2.3082,1.3C59.23,549.6173,59.0351,549.1039,58.6488,548.0756Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st4" d="M41.86,486.6l0.25-0.06l0.92-0.18l0.71-0.57l0.11-0.62l-0.15-0.37l-0.34-0.42l-0.43-0.15l-0.58,0.04
			l-0.58,0.16l-0.38,0.35l-0.82,1.33l-0.61,1.36l-0.57,1.12l-1,1.22l-0.88,0.52l-1.22,0.35l-1.45,0.08l-1.39-0.36l-0.97-0.87
			l-0.79-1.43l-0.18-1l-0.14-1.44l0.24-1.03l0.63-0.91l0.73-0.58l0.88-0.33l1.81-0.35l0.5,2.75l-1.2,0.2l-0.84,0.29l-0.37,0.45
			l-0.09,0.74l0.32,0.65l0.61,0.35l0.89-0.05l0.89-0.25l0.59-0.57l0.54-1.06l0.62-1.42l0.51-1.01l0.69-1l0.78-0.52l1.37-0.4
			l1.19-0.08l1.17,0.25l0.72,0.41l0.64,0.72l0.4,0.77l0.33,1.28l0.02,1.79l-0.42,1.06l-0.68,0.63l-1.22,0.56l-1.29,0.26L41.86,486.6
			z"/>
                                    <path className="st4" d="M32.83,493.05l13.89-2.6l0.57,2.87l-13.89,2.6L32.83,493.05z" />
                                    <path className="st4" d="M34.13,499.47l11.47-2.33l-0.49-2.3l2.37-0.48l1.6,7.44l-2.37,0.48l-0.49-2.3l-11.47,2.33L34.13,499.47z" />
                                    <path className="st4" d="M35.4,505.13l2.23-0.47l10.34,3.13l-1.01-4.5l2.39-0.51l1.72,7.7l-2.25,0.48l-10.37-3.27l1.12,4.99l-2.37,0.5
			L35.4,505.13z"/>
                                    <path className="st4" d="M44.85,522.28l-1.95,0.38l-0.94,0.05l-0.89-0.17l-0.75-0.33l-0.64-0.63l-0.63-0.91l-0.42-1.28l-0.28-1.6
			l0.19-1.18l0.65-1.06l0.64-0.62l0.73-0.37l1.63-0.47l9.23-2.31l0.73,2.79l-8.2,2.05l-1.78,0.47l-1.04,0.43l-0.31,0.41l-0.11,0.38
			l0.05,0.59l0.23,0.47l0.62,0.49l0.74-0.02l1.11-0.19l1.24-0.31l8.16-2.06l0.74,2.83L44.85,522.28z"/>
                                    <path className="st4" d="M42.26,531.56l6.47-4.52l1.03-0.67l1.7-1.04l-1.26,0.54l-1.46,0.49l-7.38,2.17l-0.81-2.7l13.6-3.82l0.93,3.28
			l-6,4.14l-1.51,1.03l-1.51,0.89l1.48-0.62l0.94-0.33l7.44-2.18l0.78,2.69l-13.59,3.79L42.26,531.56z"/>
                                    <path className="st4" d="M45.6,542.5l1.17-0.34l-0.56-0.17l-0.86-0.58l-0.46-0.52l-0.29-0.76l-0.21-0.81l0.14-1.21l0.5-0.92l1.27-1.01
			l2.25-0.78l1.9-0.61l2.88-0.82l1.46-0.3l1.47,0.14l1.16,0.66l0.76,0.93l0.65,1.44l0.23,0.97l-0.03,1.22l-0.27,0.87l-0.56,0.81
			l-0.73,0.54l-1.18,0.58l-0.87,0.31l-0.87-2.73l0.57-0.18l1.26-0.54l0.3-0.5l0.03-0.84l-0.24-0.43l-0.58-0.37l-1.26,0.07
			l-1.79,0.49l-3.46,1.06l-1.11,0.39l-0.97,0.58l-0.27,0.55l0.04,0.65l0.36,0.53l0.56,0.32l1.09-0.12l1.06-0.28l0.73-0.22
			l-0.45-1.41l2.18-0.67l1.29,4.04l-7.54,2.32L45.6,542.5z"/>
                                </g>
                            }
                        </g>
                        <g id="Segment_-_5" className={"model-option" + (this.state.changed ? this.setStyles(7) : '')}>
                            <path id="Circle_-_2-2" className="st0" d="M575.84,425.2l174.84,0.1c-0.2-66.11-19.81-132.62-61.38-190.27
		c-0.27-0.38-0.56-0.74-0.83-1.11l-141.4,102.75C566.67,364.14,576.02,394.29,575.84,425.2z"/>
                            <path id="Circle_-_2-1_2_" className="st6" d="M575.84,425.2l174.84,0.1c-0.2-66.11-19.81-132.62-61.38-190.27
		c-0.27-0.38-0.56-0.74-0.83-1.11l-141.4,102.75C566.67,364.14,576.02,394.29,575.84,425.2z"/>
                            <path id="Circle_-_1_5_" className="st7" d="M750.65,425.3l99.25-0.1c0.07-86.76-25.79-174.16-81.13-249.52l-80.31,58.24
		c0.27,0.37,0.56,0.74,0.83,1.11C731.11,292.6,750.44,359.19,750.65,425.3z"/>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M777.1317,250.9919c.7358,1.5457,1.1,2.32,1.82,3.8728a5.1405,5.1405,0,0,1,.5809,3.23,4.58,4.58,0,0,1-2.6128,2.6673c-.6795.3641-1.3688.7122-2.08,1.0328-.9482.4273-1.9346.8226-2.8986,1.2121a4.4491,4.4491,0,0,1-3.2122.4219c-1.4061-.4366-2.0436-1.5422-2.67-2.7954-.6649-1.4318-1.0007-2.1462-1.6794-3.5719Zm-8.9047,8.564a1.1911,1.1911,0,0,0,1.3094.9248,18.356,18.356,0,0,0,3.061-1.1963c.945-.4346,1.872-.8943,2.7718-1.346.8126-.4079,1.2861-.6493,1.4166-1.132a1.715,1.715,0,0,0-.1767-1.1442c-.1508-.3245-.2263-.4867-.3778-.8109l-8.3739,3.9113C768.0056,259.08,768.08,259.2385,768.227,259.5559Z" />
                                    <path className="st4" d="M769.304,267.8c5.1682-2.2812,7.7523-3.4218,12.9205-5.7028,1.18,2.6725,1.7579,4.0137,2.8913,6.705l-2.36.9937c-.6395-1.5186-.9629-2.2764-1.6173-3.7891l-2.7906,1.2071c.5808,1.3429.8683,2.0154,1.4374,3.3629l-2.3035.9728c-.5658-1.3395-.8515-2.0081-1.4289-3.3429l-3.1582,1.3661c.64,1.4807.9571,2.2224,1.5831,3.7089L772.1,274.2835C771.0037,271.6812,770.4446,270.3844,769.304,267.8Z" />
                                    <path className="st4" d="M773.9966,278.865c4.7657-3.2764,7.1385-4.9334,11.8633-8.2844.5016,1.2064.75,1.81,1.2424,3.02-2.512,1.6185-3.77,2.4236-6.291,4.0251-.4686.2819-.9206.5555-1.3774.8094-.44.2443-.9147.46-1.3627.6868.5274-.19,1.0813-.3547,1.6153-.4879.3838-.0958.8088-.1858,1.2047-.2744,3.0017-.62,4.5039-.9248,7.5113-1.5256.4856,1.2221.7261,1.8342,1.2023,3.06-5.7642.7627-8.6407,1.1663-14.3819,2.018C774.7372,280.6915,774.492,280.082,773.9966,278.865Z" />
                                    <path className="st4" d="M777.2773,287.1812c5.2773-2.0161,7.9159-3.0241,13.1932-5.04,1.0416,2.7264,1.5506,4.094,2.5453,6.8374l-2.4072.8727c-.5613-1.548-.8457-2.3207-1.422-3.8633l-2.8482,1.064c.5116,1.3694.7643,2.0552,1.264,3.4288-.94.3419-1.41.5128-2.35.8548-.4967-1.3656-.748-2.0473-1.2565-3.4086l-3.2234,1.2042c.5641,1.51.8425,2.2663,1.3919,3.7816l-2.4261.88C778.7767,291.14,778.2844,289.8174,777.2773,287.1812Z" />
                                    <path className="st4" d="M780.8939,297.0214c5.3267-1.8815,7.99-2.8222,13.3169-4.7034.4143,1.173.6193,1.76,1.025,2.9362l-10.968,3.7842c.5645,1.6362.8426,2.4557,1.39,4.0977l-2.3911.7973C782.342,301.16,781.8675,299.7778,780.8939,297.0214Z" />
                                    <path className="st4" d="M790.57,312.5958c-2.223.587-3.2911.3568-4.2107-.4874a5.4679,5.4679,0,0,1-1.41-2.3559,5.248,5.248,0,0,1-.1323-3.0294,2.7666,2.7666,0,0,1,.3982-.8438,4.279,4.279,0,0,1,1.4794-1.2024,32.91,32.91,0,0,1,4.2725-1.6081c.7222-.2377,1.4761-.4727,2.1991-.6781a8.9136,8.9136,0,0,1,1.1292-.28,3.3109,3.3109,0,0,1,3.7408,1.6057,6.51,6.51,0,0,1,.7891,1.68,4.3594,4.3594,0,0,1-.2726,3.8224,3.738,3.738,0,0,1-1.4654,1.2247,28.3126,28.3126,0,0,1-3.4121,1.2247C792.6506,311.9889,791.6141,312.32,790.57,312.5958Zm2.0464-3.94c.6872-.2157,1.3624-.4391,2.0234-.635a5.7233,5.7233,0,0,0,1.03-.3923c.1527-.0479,1.35-.5479.98-1.679a1.1292,1.1292,0,0,0-.409-.6247c-.5531-.4176-1.3468-.178-1.9-.03-.6495.173-1.3275.38-2.0133.6-1.0093.324-2.0091.6716-2.9963,1.0069a5.2514,5.2514,0,0,0-1.9724.9236,1.2264,1.2264,0,0,0,.6576,2.0725,10.8457,10.8457,0,0,0,2.8059-.6789Z" />
                                    <path className="st4" d="M787.366,316.9391c5.4152-1.6089,8.1229-2.4133,13.5381-4.0224.569,1.9152.848,2.8745,1.3947,4.7964a3.3881,3.3881,0,0,1-.6717,3.233,5.5841,5.5841,0,0,1-2.6949,1.2871,10.6048,10.6048,0,0,1-1.3923.2941,3.1738,3.1738,0,0,1-3.3932-1.2232,6.8,6.8,0,0,1-.8886-2.2462c-.0687-.2391-.1031-.3585-.1723-.5975l-4.8619,1.4056C787.885,318.6939,787.7134,318.1086,787.366,316.9391Zm8.343,1.594a1.37,1.37,0,0,0,.38.7449c.3181.3126.9232.3209,1.75.0864a8.3031,8.3031,0,0,0,1.2333-.417,1.3547,1.3547,0,0,0,.623-.5158,1.4346,1.4346,0,0,0,0-1.02c-.0914-.3186-.1373-.4778-.2293-.7962l-3.9782,1.15C795.5767,318.0727,795.6208,318.2261,795.709,318.5331Z" />
                                    <path className="st4" d="M790.3347,327.4362c5.4558-1.4653,8.1838-2.198,13.64-3.6635.3273,1.2188.4888,1.8289.8072,3.05l-13.6664,3.5627C790.8073,329.2046,790.6512,328.6147,790.3347,327.4362Z" />
                                    <path className="st4" d="M793.5554,340.2414c2.689-1.7459,4.0295-2.628,6.7023-4.4107.3341-.2368.6542-.4515.9919-.6635.584-.3666,1.1911-.69,1.7859-1.0372-.4457.1984-.8782.3732-1.3137.522-.4736.1618-.9568.2994-1.4568.4438-2.9891.78-4.4836,1.17-7.4731,1.9483-.28-1.1532-.4224-1.7293-.7109-2.88l13.6995-3.4334c.356,1.42.531,2.1315.8748,3.5552-2.456,1.6578-3.6875,2.4788-6.1573,4.1055-.4958.3383-1.0159.6782-1.538.9789-.5051.2909-1.0322.5937-1.5635.8705.5067-.2057,1.0161-.4157,1.53-.5752.3229-.1.6527-.1962.9592-.29,3.0139-.7465,4.5208-1.12,7.5344-1.8682.2751,1.1789.41,1.7688.6773,2.95l-13.7761,3.1118C794.02,342.2361,793.8672,341.5707,793.5554,340.2414Z" />
                                    <path className="st4" d="M796.2867,352.7539l1.2157-.2456a2.9572,2.9572,0,0,1-.5533-.2133,3.1573,3.1573,0,0,1-1.416-2.1148,4.33,4.33,0,0,1,.1072-2.1039,2.49,2.49,0,0,1,.5824-.9082c.75-.7929,1.6943-1.0654,3.6629-1.5158.6561-.15,1.3053-.3056,1.95-.4461.9776-.2129,1.9694-.4193,2.9455-.5752a5.1715,5.1715,0,0,1,2.9381.1441c1.7308.8128,2.222,3.1592,2.3016,3.5455a5.9749,5.9749,0,0,1,.1373,1.07,3.93,3.93,0,0,1-1.1345,2.9978,5.7353,5.7353,0,0,1-2.0148.9508c-.2888.0805-.5847.137-.895.2192-.2331-1.193-.3517-1.7889-.5933-2.98l.5881-.1193a3.9118,3.9118,0,0,0,1.2985-.4415,1.26,1.26,0,0,0,.4516-1.3846,1.2988,1.2988,0,0,0-.7509-.9595c-.6409-.2678-1.6272-.0587-3.0952.25l-3.5438.7443a5.168,5.168,0,0,0-2.166.7819,1.3249,1.3249,0,0,0-.3182,1.2215,1.3046,1.3046,0,0,0,.8148.9869,4.9642,4.9642,0,0,0,2.1878-.1822c.2354-.0478.4949-.078.7494-.13-.1265-.624-.19-.9359-.3194-1.5593l2.2336-.4619c.3661,1.77.5444,2.6564.8916,4.4309l-7.755,1.5171C796.5905,354.2646,796.49,353.7608,796.2867,352.7539Z" />
                                    <path className="st4" d="M752.9083,285.4226l13.0551-5.3873c.7232,1.7527,1.08,2.6311,1.7823,4.3918a3.1579,3.1579,0,0,1-.3927,3.1446,5.8451,5.8451,0,0,1-2.5589,1.5309,11.1515,11.1515,0,0,1-1.3532.4393,3.1688,3.1688,0,0,1-3.4606-.7677,6.118,6.118,0,0,1-1.0672-2.0265c-.088-.2187-.132-.328-.22-.5466L754,288.097C753.5675,287.0257,753.3491,286.4908,752.9083,285.4226Zm8.3857.5438a1.2438,1.2438,0,0,0,.438.6625c.3409.26.94.1976,1.7393-.12a8.74,8.74,0,0,0,1.1854-.5379,1.39,1.39,0,0,0,.5731-.5613,1.3106,1.3106,0,0,0-.0854-.9673c-.1172-.2917-.176-.4375-.2937-.729l-3.8395,1.5514C761.1246,285.545,761.1812,285.6855,761.294,285.9664Z" />
                                    <path className="st4" d="M756.7222,295.0226c5.2771-2.0161,7.9156-3.0243,13.1928-5.0406.988,2.5859,1.4709,3.8831,2.4143,6.4852l-2.4071.8729c-.5322-1.4678-.8018-2.2-1.3483-3.663l-2.8482,1.0642c.4849,1.2979.7245,1.9478,1.1981,3.25l-2.35.8548c-.4707-1.2938-.7088-1.94-1.1906-3.2294l-3.2233,1.2042c.5343,1.43.7979,2.1463,1.3182,3.5813l-2.4259.88C758.1418,298.77,757.6758,297.5184,756.7222,295.0226Z" />
                                    <path className="st4" d="M766.7411,310.2657c-2.2072.6429-3.2713.4646-4.1921-.3035a4.9479,4.9479,0,0,1-1.4184-2.1878,4.77,4.77,0,0,1-.1517-2.8784,2.6908,2.6908,0,0,1,.3905-.8189,4.4332,4.4332,0,0,1,1.463-1.2023,34.729,34.729,0,0,1,4.2373-1.6976c.7164-.2544,1.4639-.5073,2.1818-.7312a9.3926,9.3926,0,0,1,1.1209-.31,3.26,3.26,0,0,1,3.7312,1.3873,5.8816,5.8816,0,0,1,.797,1.5722,4.039,4.039,0,0,1-.2427,3.6533,3.858,3.858,0,0,1-1.4486,1.222,29.9649,29.9649,0,0,1-3.3855,1.2952C768.8066,309.6093,767.7781,309.9636,766.7411,310.2657Zm2.0071-3.8292c.682-.2315,1.3515-.47,2.008-.6819a6.0275,6.0275,0,0,0,1.0216-.4129c.1516-.0515,1.3382-.5741.9629-1.6372a1.03,1.03,0,0,0-.4113-.58c-.5531-.3769-1.34-.1183-1.89.0435-.6452.19-1.3176.4134-1.998.65-1.0016.3474-1.9923.7166-2.9728,1.0741a5.526,5.526,0,0,0-1.9552.9555,1.1339,1.1339,0,0,0,.6683,1.9477,11.4418,11.4418,0,0,0,2.786-.7536Z" />
                                    <path className="st4" d="M763.5863,314.52l13.4408-4.3362c.5818,1.8033.8674,2.7067,1.4281,4.5168a3.1564,3.1564,0,0,1-.6407,3.1026,5.8482,5.8482,0,0,1-2.6722,1.3233,11.1767,11.1767,0,0,1-1.3838.3307,3.1689,3.1689,0,0,1-3.3887-1.0388,6.1125,6.1125,0,0,1-.9031-2.1037c-.07-.2249-.1056-.3373-.1763-.5621l-4.8278,1.5183C764.1161,316.1693,763.9409,315.6192,763.5863,314.52Zm8.3157,1.2043a1.2436,1.2436,0,0,0,.384.6949c.3192.2856.9218.2712,1.7434.0175a8.7734,8.7734,0,0,0,1.2243-.4423,1.39,1.39,0,0,0,.6157-.514,1.31,1.31,0,0,0-.0084-.9707c-.0937-.3-.1406-.45-.2349-.75l-3.95,1.2423C771.7665,315.2912,771.8118,315.4356,771.902,315.7244Z" />
                                    <path className="st4" d="M766.6264,324.3873c5.42-1.592,8.13-2.388,13.5506-3.98.3326,1.1322.4968,1.699.8211,2.8337L769.8423,326.43c.4506,1.5764.6717,2.3657,1.1059,3.9469l-2.4305.6674C767.7847,328.3744,767.4064,327.043,766.6264,324.3873Z" />
                                    <path className="st4" d="M769.0853,333.1335c5.4556-1.4657,8.1834-2.1984,13.6393-3.6639.7183,2.6739,1.066,4.0141,1.7387,6.7006l-2.4839.6219c-.3794-1.5154-.5728-2.2721-.9668-3.7834l-2.9422.767c.35,1.3411.5216,2.0125.86,3.3568l-2.4249.61c-.336-1.336-.5068-2.0031-.8543-3.3358l-3.33.8681c.3852,1.4776.5743,2.2174.9452,3.699l-2.5032.6268C770.1141,337.0077,769.7784,335.7142,769.0853,333.1335Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" && <>
                            <g>
                                <path className="st4" d="M741.47,259.63l11.21-8.89l1.44,2.7l-6,4.41l-1.27,0.88l-1.33,0.77l1.59-0.62l1.16-0.37l7.33-2.18l1.41,2.74
			l-14.1,3.38L741.47,259.63z"/>
                                <path className="st4" d="M752.36,271.91l-1.46,0.51l-1.14,0.12l-0.89-0.13l-0.76-0.38l-1.22-1.16l-0.48-0.91l-0.53-1.41l0.02-1.48
			l0.29-0.86l0.75-0.93l0.59-0.38l4.04-2.09l2.11-0.9l1.09-0.41l1.08-0.25l1.04,0.09l0.92,0.39l0.85,0.79l0.94,1.44l0.44,1.18
			l0.11,1.01l-0.34,1.4l-0.74,0.95l-0.55,0.43l-3.22,1.64L752.36,271.91z M755.78,266.99l0.97-0.51l0.58-0.54l0.25-0.52l-0.07-0.65
			l-0.48-0.53l-0.98-0.07l-0.9,0.3l-1.92,0.84l-2.85,1.36l-1,0.5l-0.84,0.64l-0.25,0.73l0.11,0.49l0.45,0.47l0.6,0.2l1.1-0.35
			l1.59-0.69L755.78,266.99z"/>
                                <path className="st4" d="M751.73,280.92l5.8-5.34l0.93-0.8l1.54-1.25l-1.17,0.7l-1.38,0.68l-7.04,3.1l-1.18-2.6l12.99-5.54l1.38,3.16
			l-5.38,4.9l-1.36,1.22l-1.38,1.08l1.39-0.81l0.89-0.45l7.09-3.12l1.15,2.59l-12.98,5.51L751.73,280.92z"/>
                                <path className="st4" d="M759.42,299.83l5.48-2l3.52-1.27l1.98-0.57l-0.95,0.2l-1.54,0.28l-1.84,0.33l-7.42,1.01l-0.98-2.59l6.08-3.95
			l2.11-1.19l1.33-0.77l0.97-0.49l-1.28,0.51l-2.55,1.11l-7.5,2.7l-0.97-2.57l13.2-4.92l1.56,3.99l-6.85,4.28l-1.74,1.05l-1.32,0.62
			l1.72-0.43l1.29-0.26l8.4-1.31l1.53,3.96l-13.23,4.89L759.42,299.83z"/>
                                <path className="st4" d="M761.07,304.39l13.41-4.42l2.25,6.44l-2.43,0.8l-1.28-3.66l-2.89,0.95l1.14,3.27l-2.38,0.78l-1.14-3.27
			l-3.27,1.08l1.28,3.66l-2.45,0.81L761.07,304.39z"/>
                                <path className="st4" d="M765.71,318.11l6.41-4.59l1.03-0.68l1.68-1.06l-1.25,0.55l-1.46,0.5l-7.37,2.23l-0.85-2.72l13.59-3.93
			l0.99,3.3l-5.95,4.21l-1.5,1.05l-1.5,0.9l1.48-0.64l0.94-0.33l7.43-2.24l0.83,2.7l-13.57,3.9L765.71,318.11z"/>
                                <path className="st4" d="M778.31,325.16l0.25-0.09l0.9-0.26l0.65-0.64l0.05-0.63l-0.18-0.35l-0.38-0.38l-0.45-0.11l-0.58,0.09L778,323
			l-0.35,0.39l-0.68,1.41l-0.47,1.42l-0.45,1.18l-0.87,1.32l-0.83,0.6l-1.18,0.46l-1.44,0.22l-1.42-0.23l-1.06-0.78l-0.93-1.36
			l-0.28-0.98l-0.29-1.43l0.13-1.05l0.53-0.97l0.67-0.65l0.84-0.42l1.77-0.52l0.78,2.72l-1.18,0.32l-0.81,0.37l-0.32,0.49
			l-0.02,0.75l0.39,0.62l0.65,0.29l0.88-0.13l0.86-0.34l0.53-0.63l0.43-1.11l0.47-1.48l0.4-1.06l0.59-1.07l0.72-0.6l1.33-0.53
			l1.17-0.19l1.19,0.15l0.77,0.35l0.71,0.66l0.48,0.74l0.46,1.25l0.2,1.79l-0.31,1.1l-0.61,0.69l-1.16,0.67l-1.27,0.38
			L778.31,325.16z"/>
                                <path className="st4" d="M778.4,329.87l1.1-0.22l0.91-0.16l1.08-0.07l0.84,0.13l0.82,0.43l0.59,0.53l0.77,1.22l0.29,0.9l0.12,0.88
			l-0.02,0.97l-0.47,1.38l-0.55,0.66l-0.76,0.48l-2.48,0.74l-0.72-2.75l0.89-0.22l0.97-0.32l0.59-0.27l0.22-0.41l0.05-0.69
			l-0.37-0.56l-0.44-0.17l-0.45-0.06l-1.29,0.23l-6.02,1.45l-0.96,0.38l-0.38,0.37l-0.12,0.34l0.04,0.47l0.24,0.45l0.69,0.34
			l2.13-0.32l0.45-0.11l0.72,2.75l-2.24,0.46l-1.5-0.1l-1.26-0.64l-0.74-0.94l-0.58-1.5l-0.25-1.27l0.06-0.96l0.4-0.94l0.65-0.7
			l1.3-0.71l2.14-0.6L778.4,329.87z"/>
                                <path className="st4" d="M773.5,347.04l6-1.27l-0.58-2.53l-6,1.27l-0.66-2.87l13.84-2.92l0.66,2.87l-5.57,1.18l0.58,2.53l5.57-1.18
			l0.66,2.87l-13.84,2.93L773.5,347.04z"/>
                                <path className="st4" d="M774.59,351.95l13.92-2.56l1.35,6.67l-2.52,0.47l-0.77-3.79l-3,0.55l0.69,3.39l-2.46,0.45l-0.69-3.39
			l-3.39,0.62l0.77,3.79l-2.54,0.47L774.59,351.95z"/>
                                <path className="st4" d="M777.33,366.16l6.96-3.75l1.11-0.54l1.81-0.84l-1.32,0.39l-1.51,0.32l-7.61,1.29l-0.5-2.8l14.01-2.2
			l0.57,3.39l-6.45,3.43l-1.62,0.85l-1.61,0.71l1.55-0.45l0.98-0.21l7.67-1.3l0.48,2.78l-13.99,2.18L777.33,366.16z"/>
                            </g>
                            <g>
                                <path className="st4" d="M774.55,275.06l13.01-5.45l2.68,6.16l-2.3,0.97l-1.5-3.46l-2.8,1.12l1.39,3.2l-2.25,0.95l-1.39-3.2
			l-5.67,2.39L774.55,275.06z"/>
                                <path className="st4" d="M784.84,288.43l-1.48,0.43l-1.14,0.06l-0.87-0.18l-0.73-0.41l-0.98-0.97l-0.58-1.17l-0.48-2.04l0.13-0.99
			l0.5-0.85l0.79-0.81l1.17-0.66l3.44-1.45l1.83-0.7l1.14-0.38l1.18-0.29l1.08,0.1l1.01,0.41l0.88,0.9l0.84,1.48l0.36,1.19
			l0.04,1.01l-0.43,1.37l-0.8,0.91l-0.57,0.4l-3.31,1.46L784.84,288.43z M789.03,283.56l0.54-0.3l0.76-0.56l0.17-0.68l-0.05-0.39
			l-0.39-0.48l-0.37-0.18l-0.65-0.01l-2.77,0.94l-3.14,1.29l-0.96,0.4l-0.84,0.57l-0.29,0.57l0.06,0.63l0.19,0.32l0.42,0.29
			l0.48,0.12l2.43-0.81L789.03,283.56z M793.36,279.58l-0.78-1.92l2.17-0.86l0.78,1.92L793.36,279.58z M794.76,283l-0.79-1.94
			l2.17-0.86l0.79,1.94L794.76,283z"/>
                                <path className="st4" d="M783.59,297.24l0.39-0.38l1.68-0.88l0.77-0.28l1.24-0.52l0.34-0.23l0.37-0.48l0-0.5l-0.1-0.44l-0.38-1.01
			l-5.35,1.96l-1.05-2.77l13.25-4.87l1.9,5.01l0.21,1l-0.05,0.73l-0.25,0.76l-0.79,0.92l-1.57,0.82l-0.9,0.29l-1.19,0.24l-1.14-0.25
			l-0.68-0.61l-0.17,1.01l-0.73,0.79l-0.95,0.42l-0.24,0.09l-2.01,0.91l-1.18,0.78l-0.33,0.38L783.59,297.24z M790.71,293.29
			l0.28,0.33l0.58,0.22l1.11-0.3l0.81-0.34l0.34-0.28l0.33-0.53l-0.28-1.09l-0.33-0.87l-3.43,1.26L790.71,293.29z"/>
                                <path className="st4" d="M798.39,296.53l1.33,3.73l0.36,1.55l-0.17,1.5l-0.52,0.87l-0.72,0.61l-1.63,0.84l-2.17,0.82l-2.99,0.95
			l-1.96,0.4l-1.27-0.23l-0.91-0.52l-0.64-0.66l-0.82-1.69l-1.27-3.56L798.39,296.53z M788.55,303.84l0.29,0.59l0.33,0.27l0.58,0.14
			l3.16-0.92l2.9-1.04l1.03-0.44l0.49-0.52l0.06-0.56l-0.1-0.52l-0.28-0.78l-8.73,3.02L788.55,303.84z"/>
                                <path className="st4" d="M788.35,310.63l13.49-4.19l2.07,6.43l-2.45,0.76l-1.18-3.65l-2.9,0.9l1.05,3.26l-2.39,0.74l-1.05-3.26
			l-3.29,1.02l1.18,3.65l-2.46,0.77L788.35,310.63z"/>
                                <path className="st4" d="M792.62,324.26l0.42-0.35l1.74-0.76l0.79-0.23l1.27-0.43l0.36-0.21l0.41-0.46l0.04-0.5l-0.07-0.44l-0.31-1.03
			l-5.48,1.59l-0.85-2.84l13.57-3.93l1.54,5.13l0.14,1.02l-0.09,0.72l-0.3,0.74l-0.85,0.86l-1.63,0.7l-0.92,0.22l-1.21,0.16
			l-1.12-0.33l-0.64-0.66l-0.24,1l-0.78,0.73l-0.98,0.35l-0.25,0.07l-2.07,0.77l-1.23,0.69l-0.35,0.36L792.62,324.26z
			 M800.01,320.82l0.25,0.35l0.56,0.26l1.13-0.22l0.83-0.28l0.36-0.25l0.37-0.51l-0.21-1.1l-0.27-0.89l-3.52,1.02L800.01,320.82z"/>
                                <path className="st4" d="M795.41,334.02l6.55-4.41l1.05-0.65l1.71-1.01l-1.27,0.52l-1.47,0.47l-7.43,2.04l-0.76-2.71l13.68-3.59
			l0.88,3.29l-6.08,4.04l-1.53,1.01l-1.53,0.86l1.5-0.6l0.95-0.31l7.49-2.06l0.74,2.7l-13.67,3.56L795.41,334.02z"/>
                            </g></>}
                        </g>
                        <g id="Segment_-_4" className={"model-option" + (this.state.changed ? this.setStyles(6) : '')}>
                            <path id="Circle_-_2-2_1_" className="st0" d="M547,336.58l141.46-102.61c-39.02-53.36-93.62-95.12-161.3-118
		c-0.44-0.15-0.89-0.27-1.33-0.41l-54.04,166.32C503.83,292.52,528.73,311.74,547,336.58z"/>
                            <path id="Circle_-_2-1_5_" className="st6" d="M547,336.58l141.46-102.61c-39.02-53.36-93.62-95.12-161.3-118
		c-0.44-0.15-0.89-0.27-1.33-0.41l-54.04,166.32C503.83,292.52,528.73,311.74,547,336.58z"/>
                            <path id="Circle_-_1_4_" className="st7" d="M688.46,233.97l80.32-58.53C717.84,105.21,645.21,50.45,556.53,21.16l-30.72,94.39
		c0.44,0.14,0.89,0.27,1.33,0.41C594.73,138.35,649.44,180.6,688.46,233.97z"/>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M610.9138,92.4141c1.2606-1.9863,1.8908-2.98,3.1513-4.9659.6538-1.03,1.296-2.1115,2-3.1135.3982-.5663.8394-1.1259,1.2505-1.6943-.225.2366-.46.5-.6784.7544-.3239.3756-.6738.7817-1.0113,1.1585-.4317.4822-.8888.9288-1.3266,1.3855-2.0924,2.1225-3.136,3.1859-5.2178,5.3168-.9732-.6131-1.4605-.9187-2.4364-1.5278.9711-2.7518,1.4535-4.1292,2.4124-6.8869.2683-.7518.5484-1.5214.86-2.2676.2026-.4852.4055-.96.6138-1.4319.1433-.3244.2887-.6684.4244-1.0077-.2245.402-.4265.8007-.644,1.2013-.445.82-.904,1.64-1.385,2.4361-.1946.322-.3908.66-.5823.981-.3021.5071-.6426,1.0373-.9691,1.5645l-2.6231,4.2342c-.9638-.597-1.4464-.8946-2.4127-1.4876,2.94-4.8235,4.4105-7.2354,7.35-12.0595,1.5018.9214,2.2511,1.3845,3.7467,2.3155-1.0749,3.0635-1.616,4.5934-2.7057,7.65-.2539.6435-.484,1.2823-.77,1.9016-.2022.4377-.4206.8694-.6228,1.3091.3369-.497.7073-.94,1.0668-1.385.271-.3354.5746-.6788.88-1.0066,2.3467-2.4569,3.5233-3.6829,5.8833-6.13,1.4894.94,2.2326,1.413,3.7158,2.3629-3.0325,4.7665-4.5488,7.15-7.5817,11.9156C612.3483,93.3257,611.8705,93.0212,610.9138,92.4141Z" />
                                    <path className="st4" d="M625.6008,96.8079c-1.3451,1.8645-2.3089,2.37-3.54,2.3081a5.2784,5.2784,0,0,1-2.5439-.91,5.0712,5.0712,0,0,1-1.97-2.243,2.7328,2.7328,0,0,1-.2107-.9,4.3188,4.3188,0,0,1,.4068-1.8579,33.5362,33.5362,0,0,1,2.32-3.9313c.4131-.6383.8522-1.2944,1.2856-1.9086a9.1316,9.1316,0,0,1,.7016-.9279,3.29,3.29,0,0,1,3.8562-1.1435,6.1868,6.1868,0,0,1,1.6269.7779,4.1942,4.1942,0,0,1,2.12,3.0774,3.8086,3.8086,0,0,1-.3743,1.8648,29.1524,29.1524,0,0,1-1.8656,3.1083C626.8244,95.0188,626.2328,95.9319,625.6008,96.8079Zm-.8516-4.3073c.3964-.6013.7786-1.2007,1.1671-1.7706a5.8671,5.8671,0,0,0,.5519-.9538c.0881-.1337.7021-1.2753-.27-1.9018a1.0785,1.0785,0,0,0-.695-.2163c-.68.033-1.1449.7188-1.4813,1.1824-.3949.5442-.79,1.1323-1.1843,1.7348-.58.8878-1.138,1.7868-1.6941,2.6691a5.3433,5.3433,0,0,0-.9546,1.9569,1.1921,1.1921,0,0,0,1.783,1.17,11.045,11.045,0,0,0,1.7429-2.301Z" />
                                    <path className="st4" d="M630.918,105.6157c.827-3.0908,1.235-4.6387,2.04-7.74.0915-.3978.1872-.77.2977-1.152.1913-.6609.4279-1.3054.64-1.959-.1992.4448-.4038.8636-.6278,1.2657-.2434.4372-.51.8632-.7838,1.3057-1.6928,2.584-2.5394,3.8758-4.2331,6.4592-.9648-.655-1.4478-.9813-2.4154-1.6321,3.1526-4.6876,4.7289-7.0315,7.8815-11.7194,1.1789.7927,1.7673,1.1907,2.9419,1.99-.7315,2.86-1.102,4.2885-1.8524,7.1405-.1429.581-.3022,1.18-.4886,1.7515-.18.5533-.3687,1.13-.5769,1.6908.2369-.4925.4731-.99.7461-1.4538.1714-.2913.3506-.5843.5139-.86,1.7239-2.5825,2.5857-3.8739,4.3087-6.4571.9676.6647,1.4506.998,2.4153,1.6669-3.2187,4.6428-4.828,6.9641-8.0467,11.6067C632.5762,106.755,632.024,106.3743,630.918,105.6157Z" />
                                    <path className="st4" d="M636.8185,109.7094c3.245-4.6241,4.8676-6.9362,8.1128-11.5607,1.0022.7034,1.5025,1.0562,2.5017,1.764-3.2658,4.61-4.8987,6.9147-8.1644,11.5243C638.29,110.7438,637.8,110.3983,636.8185,109.7094Z" />
                                    <path className="st4" d="M642.7429,113.915l6.8258-9.5059c-.7984-.5733-1.1982-.8594-1.9988-1.43.5619-.7884.8429-1.1827,1.4047-1.9712,2.5975,1.851,3.8908,2.784,6.4664,4.665l-1.4275,1.9547c-.7871-.5748-1.1812-.8616-1.97-1.4336l-6.8682,9.4754C644.2037,114.9656,643.7173,114.6147,642.7429,113.915Z" />
                                    <path className="st4" d="M657.9724,119.7582c-1.4523,1.7824-2.4441,2.23-3.6691,2.0965a5.2759,5.2759,0,0,1-2.4853-1.0579,5.0671,5.0671,0,0,1-1.8335-2.3541,2.73,2.73,0,0,1-.1571-.9108,4.3192,4.3192,0,0,1,.5155-1.8307,33.5371,33.5371,0,0,1,2.5474-3.7881c.45-.6129.9267-1.242,1.3954-1.83a9.1716,9.1716,0,0,1,.7548-.8851,3.29,3.29,0,0,1,3.9159-.9157,6.1816,6.1816,0,0,1,1.5778.8719,4.1921,4.1921,0,0,1,1.9342,3.1959,3.8072,3.8072,0,0,1-.4834,1.84,29.0759,29.0759,0,0,1-2.045,2.9932C659.2988,118.0441,658.6547,118.9209,657.9724,119.7582Zm-.5964-4.3493c.4311-.577.8478-1.1529,1.2691-1.699a5.871,5.871,0,0,0,.6071-.92c.0958-.1283.7759-1.232-.1578-1.9142a1.0773,1.0773,0,0,0-.6809-.2565c-.68-.007-1.185.65-1.548,1.0934-.4262.52-.8557,1.0839-1.2842,1.6623-.6313.8522-1.2412,1.7168-1.8481,2.565a5.3467,5.3467,0,0,0-1.068,1.8973,1.1915,1.1915,0,0,0,1.7105,1.2727,11.05,11.05,0,0,0,1.8751-2.1947Z" />
                                    <path className="st4" d="M662.712,128.8236c.0409-.2217.04-.3236.0965-.5326a5.5242,5.5242,0,0,1,.9661-1.6556l.5024-.6485a10.451,10.451,0,0,0,.7715-1.0977,1.3418,1.3418,0,0,0,.2149-.997,1.3725,1.3725,0,0,0-.6012-.7679c-.3526-.272-.529-.4079-.8821-.6793-1.39,1.8081-2.0848,2.7121-3.4746,4.52-.9639-.741-1.4467-1.11-2.414-1.847,3.4225-4.4944,5.1338-6.7416,8.5564-11.2363,1.5968,1.216,2.3929,1.8269,3.9808,3.0545.1511.1169.32.2532.459.38a3.6948,3.6948,0,0,1,1.335,2.229,3.8091,3.8091,0,0,1-1.0686,2.7442c-.185.2365-.3982.4795-.6128.7146a3.0818,3.0818,0,0,1-1.9731,1.2253,1.8066,1.8066,0,0,1-.9561-.212,1.6765,1.6765,0,0,1,.4269.9919,2.6562,2.6562,0,0,1-.7848,1.9254l-.16.2047a11.35,11.35,0,0,0-1.8112,3.13,2.3847,2.3847,0,0,0-.0744.5006C664.2118,129.9892,663.7125,129.6,662.712,128.8236Zm3.2754-7.6582a6.405,6.405,0,0,0,.6458.4733.8928.8928,0,0,0,1.0217.0571,3.6313,3.6313,0,0,0,.7826-.8365,8.2775,8.2775,0,0,0,.5056-.7215,1.166,1.166,0,0,0,.1279-1.065,3.8276,3.8276,0,0,0-.8482-.8069c-.309-.2383-.4636-.3573-.7729-.595l-2.2313,2.9027C665.5262,120.81,665.68,120.9285,665.9874,121.1654Z" />
                                    <path className="st4" d="M667.3835,132.4814c3.5025-4.4323,5.2537-6.6486,8.7563-11.0812.9608.7592,1.44,1.14,2.3977,1.9034-3.5224,4.4169-5.2835,6.6252-8.8057,11.042C668.7941,133.5978,668.3245,133.225,667.3835,132.4814Z" />
                                    <path className="st4" d="M677.4846,140.6231c1.0919-3.0075,1.6325-4.5142,2.7031-7.5337.1257-.3884.2533-.7508.3966-1.1218.2478-.6419.5394-1.2634.8077-1.8962-.2372.4259-.4773.8255-.7353,1.2066-.28.4144-.5827.8157-.8941,1.2327-1.9106,2.4274-2.8662,3.6409-4.7776,6.0675-.9044-.7362-1.3573-1.1033-2.2648-1.8355l8.8687-10.9915c1.1056.8921,1.6573,1.34,2.7583,2.2375-.9768,2.7862-1.47,4.1768-2.4646,6.953-.1928.5665-.4034,1.1491-.6386,1.7026-.2276.5356-.4653,1.0939-.7214,1.6344.2787-.47.5572-.945.8694-1.3835.196-.2754.4-.5517.5866-.8123,1.9414-2.4232,2.912-3.635,4.8526-6.0589.9065.7463,1.3589,1.12,2.2622,1.8706L680.07,142.7594C679.038,141.9023,678.5209,141.475,677.4846,140.6231Z" />
                                    <path className="st4" d="M687.1266,148.7025l.8054-.9432a2.954,2.954,0,0,1-.5624.1773,3.0659,3.0659,0,0,1-2.385-.7671,4.1759,4.1759,0,0,1-1.1789-1.7034,2.4537,2.4537,0,0,1-.0859-1.0651c.116-1.0786.6971-1.8706,1.979-3.4318.4272-.52.8455-1.04,1.27-1.5462.6428-.7666,1.3005-1.5367,1.9761-2.2588a5.2981,5.2981,0,0,1,2.3946-1.6989c1.84-.44,3.6123,1.0609,3.9036,1.3086a5.6439,5.6439,0,0,1,.7422.737,3.802,3.802,0,0,1,.8961,3.0058,5.879,5.879,0,0,1-1.0069,1.9833c-.1776.2415-.3751.4689-.5684.7252-.8966-.7719-1.3458-1.1568-2.2457-1.9247l.3895-.4564a4.0126,4.0126,0,0,0,.7539-1.145,1.2237,1.2237,0,0,0-.4694-1.3455,1.2487,1.2487,0,0,0-1.1584-.2746c-.6619.19-1.3116.9606-2.28,2.1057l-2.339,2.7644a5.2629,5.2629,0,0,0-1.2336,1.9433,1.2891,1.2891,0,0,0,.483,1.1442,1.27,1.27,0,0,0,1.2324.2608,5.0569,5.0569,0,0,0,1.6062-1.4955c.1559-.1827.3412-.3667.51-.5644-.4732-.4036-.71-.6053-1.1841-1.0079l1.4765-1.7384c1.3432,1.1408,2.013,1.7135,3.3489,2.863l-5.1542,5.99C688.2765,149.6857,687.8936,149.3575,687.1266,148.7025Z" />
                                    <path className="st4" d="M694.18,153.455a3.8074,3.8074,0,0,1-2.534,1.5037,3.4455,3.4455,0,0,1-1.3137-.137,4.0235,4.0235,0,0,1-.708-.324l1.2069-1.3892a3.0116,3.0116,0,0,0,.38.0385.9123.9123,0,0,0,.758-.2686c-.2137-.186-.32-.279-.5343-.4647l1.7843-2.0538c.8341.7246,1.25,1.0877,2.0815,1.8158Z" />
                                    <path className="st4" d="M567.5183,92.1663l2.8648-5.1363c.5943-1.0657,1.1757-2.18,1.8208-3.2218.3646-.5887.7714-1.1732,1.1485-1.7646-.2095.25-.4278.5265-.631.7936-.3.3947-.6234.8214-.9367,1.218-.4008.5075-.8279.9815-1.2365,1.4637-1.9513,2.2475-2.9241,3.373-4.8642,5.6283-.9748-.54-1.4628-.8083-2.44-1.3437.83-2.7931,1.2426-4.1909,2.0606-6.9892.23-.7633.4713-1.5446.7436-2.3054.1771-.4947.3544-.98.5371-1.4615.1257-.3313.2541-.6816.3711-1.0276-.201.4143-.3819.8234-.5771,1.2347-.3992.8414-.8117,1.6878-1.2466,2.51-.176.3325-.3546.68-.5272,1.0117-.2727.5232-.5827,1.0721-.8789,1.6169l-2.3788,4.3761c-.9649-.5244-1.448-.7857-2.4152-1.3062,2.6627-4.9823,3.9938-7.4735,6.6559-12.4562,1.5045.8094,2.2554,1.2166,3.7544,2.0356-.9185,3.109-1.3815,4.6619-2.3153,7.7646-.22.6549-.4178,1.3034-.6708,1.9369-.1788.4475-.3736.89-.5522,1.34.3068-.5161.65-.98.982-1.4458.25-.3513.5318-.7121.8156-1.058,2.1852-2.5963,3.2812-3.892,5.48-6.4787,1.493.8282,2.2381,1.2447,3.7254,2.0824-2.7579,4.93-4.1369,7.3953-6.8953,12.3252C568.956,92.97,568.4771,92.701,567.5183,92.1663Z" />
                                    <path className="st4" d="M573.1381,95.3373,580.14,83.0722c2.3844,1.3611,3.5729,2.0478,5.9424,3.4331l-1.2923,2.21c-1.34-.7832-2.0105-1.1728-3.3548-1.9482-.6077,1.0534-.9115,1.58-1.5192,2.6337,1.1968.69,1.7942,1.037,2.9871,1.7335l-1.2606,2.1593c-1.1884-.6938-1.7836-1.0392-2.9758-1.7268l-1.7191,2.9806c1.326.7648,1.9879,1.1492,3.3092,1.9217l-1.3025,2.2277C576.635,97.3415,575.4717,96.6694,573.1381,95.3373Z" />
                                    <path className="st4" d="M586.2184,103.0081c.57-1.478.8546-2.2174,1.4219-3.6968-.8-.4806-1.2-.72-2.0019-1.198-1.0288,1.2034-1.5426,1.8054-2.5688,3.0105-1.033-.6142-1.55-.92-2.5861-1.5295,3.9364-4.2569,5.9154-6.3774,9.895-10.6019,1.2608.75,1.89,1.1266,3.1466,1.8833-1.8778,5.4822-2.8286,8.2179-4.754,13.6778C587.7515,103.933,587.241,103.624,586.2184,103.0081Zm3.576-9.0295c.2818-.6707.5641-1.3411.8806-1.991-.4223.5866-.88,1.1518-1.3375,1.7177-.8836,1.0473-1.3249,1.5713-2.2066,2.62.5523.33.8283.4947,1.38.8256C589.0251,95.8824,589.2819,95.248,589.7944,93.9786Z" />
                                    <path className="st4" d="M599.5156,99.3744l.1375-.221a8.6991,8.6991,0,0,0,.4616-.82.9871.9871,0,0,0-.351-1.4192,1.0577,1.0577,0,0,0-.9016-.1812,1.0423,1.0423,0,0,0-.4125.2387,2.3831,2.3831,0,0,0-.3575.461c-.452.732-.371,1.2273.238,2.5706.2351.551.3522.8266.5858,1.3776a7.4022,7.4022,0,0,1,.4907,1.1785,3.9651,3.9651,0,0,1-.5085,3.7189,4.639,4.639,0,0,1-2.0809,1.9059,2.6949,2.6949,0,0,1-1.29.16,5.5092,5.5092,0,0,1-2.4314-.9,4.0471,4.0471,0,0,1-2.0211-2.7963,6.2246,6.2246,0,0,1,1.2995-3.403c.9563.5846,1.4338.878,2.3875,1.4667-.21.3407-.4291.7042-.624,1.0487-.38.6716-.4465.9942-.28,1.3788a1.0626,1.0626,0,0,0,.5026.57,1.1227,1.1227,0,0,0,1.3536-.0307,2.5746,2.5746,0,0,0,.5506-.6937,3.2112,3.2112,0,0,0,.4005-.8348c.1537-.5168-.0217-1.0037-.47-1.94-.2615-.5665-.3928-.85-.6561-1.4162-.145-.3481-.3066-.7061-.4245-1.06a3.7228,3.7228,0,0,1,.4222-3.4256,5.1709,5.1709,0,0,1,.7209-.9425,3.26,3.26,0,0,1,2.7131-.9632,5.1424,5.1424,0,0,1,2.0473.8415,3.916,3.916,0,0,1,1.8652,2.2231,2.6776,2.6776,0,0,1,.0382.9447,5.9915,5.9915,0,0,1-1.0631,2.4267C600.9224,100.251,600.4539,99.9581,599.5156,99.3744Z" />
                                    <path className="st4" d="M608.9843,111.2925a7.1418,7.1418,0,0,1-2.5758,2.7621c-.8171.3741-2.1842.438-3.8951-.6631-.3217-.2071-2.1628-1.3631-2.188-3.2047a5.9342,5.9342,0,0,1,1.1783-3.1382l5.1005-8.04c.9806.622,1.47.9341,2.4479,1.56l-4.564,7.1254a11.7744,11.7744,0,0,0-1.4262,2.6021,1.3646,1.3646,0,0,0,.6765,1.2668,1.342,1.342,0,0,0,1.2659.1261,4.4972,4.4972,0,0,0,1.2034-1.4134c.228-.3526.45-.7338.6777-1.0864l4.573-7.072c.9891.64,1.4829.96,2.4692,1.6045C611.95,106.75,610.9616,108.2642,608.9843,111.2925Z" />
                                    <path className="st4" d="M614.127,120.802c.0288-.2221.0229-.3226.0675-.5333a5.6939,5.6939,0,0,1,.86-1.7118l.458-.6806a10.8206,10.8206,0,0,0,.6982-1.1457,1.3641,1.3641,0,0,0,.16-1.0012,1.2954,1.2954,0,0,0-.6256-.709c-.358-.24-.5372-.36-.8956-.5988l-3.165,4.7419c-.9784-.6531-1.4683-.9785-2.45-1.6271,3.1149-4.7129,4.6723-7.0692,7.7872-11.7819,1.6211,1.0714,2.43,1.61,4.0426,2.6932.1535.1031.325.2241.4675.3384a3.508,3.508,0,0,1,1.4165,2.0915,3.89,3.89,0,0,1-.9056,2.7939c-.1688.2484-.3647.5054-.5625.7548a3.143,3.143,0,0,1-1.8655,1.3686,1.7226,1.7226,0,0,1-.9444-.1317,1.6086,1.6086,0,0,1,.4668.9441,2.72,2.72,0,0,1-.67,1.9631l-.146.2149A11.7062,11.7062,0,0,0,616.71,122.02a2.4109,2.4109,0,0,0-.0475.5C615.65,121.83,615.1428,121.4866,614.127,120.802ZM616.94,112.98a6.039,6.039,0,0,0,.6546.4147.863.863,0,0,0,1.0007-.0263,3.7509,3.7509,0,0,0,.7221-.8888,8.51,8.51,0,0,0,.4574-.7527,1.1761,1.1761,0,0,0,.0713-1.0613,3.5959,3.5959,0,0,0-.8692-.7278c-.3138-.21-.4708-.315-.7849-.5247l-2.0325,3.0451C616.472,112.6669,616.6282,112.7712,616.94,112.98Z" />
                                    <path className="st4" d="M618.8715,124.0307l7.997-11.6407c.9769.6712,1.4645,1.0078,2.4384,1.6832l-8.049,11.6048C620.3048,125.017,619.8276,124.6875,618.8715,124.0307Z" />
                                    <path className="st4" d="M629.1434,131.2347c.9174-3.0532,1.3706-4.5824,2.266-7.6461.1034-.3931.21-.7608.3315-1.1382.21-.6532.464-1.2894.6944-1.9351-.21.4392-.4251.8525-.6581,1.2493-.2533.4314-.5286.8516-.8121,1.288-1.7456,2.5485-2.6187,3.8226-4.3652,6.3705-.92-.6519-1.3808-.9769-2.3036-1.6248,3.246-4.6236,4.869-6.9352,8.115-11.5587,1.1252.79,1.6868,1.1864,2.8078,1.9822-.8152,2.8263-1.2275,4.2372-2.0612,7.0546-.16.5741-.3368,1.1654-.5392,1.73-.1958.5463-.4,1.1159-.6236,1.67.249-.486.4975-.9766.7807-1.4344.1778-.2876.3634-.5764.5328-.8485,1.7764-2.5465,2.6645-3.82,4.44-6.367.9232.6619,1.3842.9938,2.3046,1.66-3.3112,4.577-4.9668,6.8655-8.2779,11.4426C630.7245,132.3685,630.1981,131.99,629.1434,131.2347Z" />
                                    <path className="st4" d="M638.9652,138.4057l.7407-.9948a2.9845,2.9845,0,0,1-.541.2207,2.9221,2.9221,0,0,1-2.3689-.56A3.93,3.93,0,0,1,635.56,135.49a2.4081,2.4081,0,0,1-.1366-1.0421c.06-1.0721.5881-1.8995,1.7639-3.5428.3918-.5477.7746-1.094,1.1641-1.627.59-.8078,1.1945-1.62,1.8191-2.3868a5.4361,5.4361,0,0,1,2.2563-1.8693c1.7769-.5833,3.5844.7508,3.8816.971a5.294,5.294,0,0,1,.7624.6655,3.6612,3.6612,0,0,1,1.0255,2.8876,6.0377,6.0377,0,0,1-.8862,2.0355c-.1617.2523-.3435.4925-.52.7607-.915-.6869-1.3731-1.0293-2.2911-1.7121l.3582-.4815a4.1377,4.1377,0,0,0,.68-1.1893,1.2954,1.2954,0,0,0-1.6721-1.4631c-.6378.2411-1.2344,1.0534-2.1247,2.2606-.86,1.1657-1.29,1.7485-2.1492,2.9142a5.43,5.43,0,0,0-1.11,2.0152,1.2337,1.2337,0,0,0,.5287,1.0874,1.2188,1.2188,0,0,0,1.2174.1561,5.2148,5.2148,0,0,0,1.4961-1.6043c.1434-.1928.3154-.3892.4705-.5976-.4825-.3589-.724-.5382-1.2074-.896l1.3571-1.833c1.37,1.014,2.0529,1.5232,3.4159,2.5461-1.8972,2.528-2.8457,3.792-4.7428,6.32C640.1376,139.28,639.7473,138.988,638.9652,138.4057Z" />
                                    <path className="st4" d="M646.0947,142.5064a3.8949,3.8949,0,0,1-2.4035,1.6883,3.3306,3.3306,0,0,1-1.291-.0269,3.7916,3.7916,0,0,1-.708-.2608l1.1115-1.4668a2.8671,2.8671,0,0,0,.3733.0067.92.92,0,0,0,.7278-.3266c-.2179-.1655-.327-.2483-.5452-.4136l1.6433-2.1684c.8512.645,1.276.9684,2.1245,1.6169Z" />
                                    <path className="st4" d="M660.1309,148.7914c-1.0259,1.28-2.2021,2.7445-4.1025,2.3808a3.9782,3.9782,0,0,1-1.7725-.8457,3.4991,3.4991,0,0,1-1.4815-2.4173,2.6588,2.6588,0,0,1,.11-1.1851,6.78,6.78,0,0,1,1.1671-1.983l.7647-.9765c.8764.6864,1.3139,1.03,2.1874,1.72l-.4836.6123a6.4123,6.4123,0,0,0-1.07,1.5508.7448.7448,0,0,0,.278.7559.8422.8422,0,0,0,.4359.1667c.4587.0061.7293-.3662,1.227-.993l6.3189-7.9585c.9219.732,1.382,1.099,2.3007,1.8351Z" />
                                    <path className="st4" d="M669.7751,156.2026a7.1374,7.1374,0,0,1-2.8773,2.4464,4.074,4.074,0,0,1-3.7923-1.108c-.2956-.2427-1.9907-1.6033-1.803-3.4353a5.9361,5.9361,0,0,1,1.5327-2.9811c2.3977-2.9593,3.5966-4.4389,5.9944-7.3981.9019.7307,1.3521,1.0972,2.2508,1.8319-2.1423,2.62-3.2135,3.9307-5.3557,6.5512a11.7778,11.7778,0,0,0-1.7171,2.42,1.3639,1.3639,0,0,0,.5257,1.3362,1.3419,1.3419,0,0,0,1.2428.2713,4.4968,4.4968,0,0,0,1.3584-1.2651c.2672-.324.5314-.677.7985-1.0009l5.3586-6.4972c.9087.75,1.3621,1.1252,2.2675,1.8787Z" />
                                    <path className="st4" d="M678.3985,151.6292c1.2285,1.0341,1.8412,1.553,3.0636,2.5946a4.6262,4.6262,0,0,1,1.7332,2.5636,4.7517,4.7517,0,0,1-1.3083,3.4668c-.4652.6151-.9448,1.22-1.4548,1.81-.68.7871-1.4056,1.5629-2.114,2.3239a4.6009,4.6009,0,0,1-2.6989,1.767,4.2421,4.2421,0,0,1-3.455-1.2994c-1.1415-.9721-1.7136-1.4564-2.8605-2.4219C672.9417,158.112,674.7606,155.951,678.3985,151.6292Zm-4.7115,11.3442a1.1172,1.1172,0,0,0,1.5206.2456,19.358,19.358,0,0,0,2.271-2.3748c.6761-.79,1.3262-1.5941,1.9562-2.38.5689-.71.8982-1.1266.8322-1.6044a1.5463,1.5463,0,0,0-.5883-.9226c-.2562-.2182-.3844-.3272-.6409-.545l-5.9827,7.0443C673.308,162.6509,673.4344,162.7584,673.687,162.9734Z" />
                                    <path className="st4" d="M681.7484,173.1814l.8224-.9283a2.9843,2.9843,0,0,1-.558.1737,2.9251,2.9251,0,0,1-2.3143-.7608,3.9342,3.9342,0,0,1-1.0989-1.6817,2.4128,2.4128,0,0,1-.0481-1.05c.15-1.0634.7468-1.8432,2.0578-3.38.437-.5125.8648-1.0243,1.298-1.5224.6567-.7548,1.3277-1.5128,2.0153-2.2237a5.4319,5.4319,0,0,1,2.4072-1.6705c1.8207-.43,3.51,1.0543,3.7876,1.2991a5.3034,5.3034,0,0,1,.7039.7286A3.6651,3.6651,0,0,1,691.6,165.13a6.0329,6.0329,0,0,1-1.0554,1.9533c-.1824.2377-.384.4616-.5825.7138-.8542-.7629-1.2821-1.1433-2.14-1.9024l.3978-.4493a4.1348,4.1348,0,0,0,.7786-1.1274,1.2972,1.2972,0,0,0-1.5434-1.6012c-.6561.1859-1.32.9446-2.3092,2.0718l-2.3888,2.7214a5.4239,5.4239,0,0,0-1.2763,1.9138,1.235,1.235,0,0,0,.4352,1.1291,1.22,1.22,0,0,0,1.2005.26,5.2122,5.2122,0,0,0,1.6271-1.4714c.1592-.18.3473-.3609.5195-.5554-.4507-.399-.6764-.5983-1.128-.9962l1.5078-1.7112c1.28,1.1276,1.918,1.6937,3.1906,2.83l-5.2623,5.8946C682.8434,174.1528,682.4788,173.8286,681.7484,173.1814Z" />
                                    <path className="st4" d="M686.2261,177.1858l9.463-10.4836c.8808.7951,1.32,1.1935,2.1976,1.9926-3.8041,4.1764-5.706,6.2646-9.51,10.4411C687.5182,178.3539,687.0881,177.9639,686.2261,177.1858Z" />
                                    <path className="st4" d="M695.4705,185.6943c1.311-2.9066,1.9614-4.3632,3.2522-7.283.1541-.3761.3082-.7268.4782-1.0849.2943-.62.63-1.2173.9431-1.8271-.2664.4076-.5336.7891-.817,1.1516-.3081.3943-.6363.7745-.9749,1.17-2.0666,2.2959-3.1,3.4436-5.1676,5.7388-.8273-.7689-1.2416-1.1524-2.0721-1.9175,3.8277-4.1547,5.7416-6.2319,9.5694-10.3866,1.0125.9329,1.5177,1.4006,2.5254,2.3389-1.18,2.695-1.7742,4.04-2.9712,6.7237-.2342.5481-.4872,1.1111-.7622,1.6446-.266.5159-.5436,1.0537-.8379,1.5733.3109-.4491.6218-.9028.9629-1.3192.2142-.2615.4363-.5234.64-.7707,2.0969-2.29,3.1453-3.435,5.2414-5.7255.8293.7794,1.2431,1.17,2.0692,1.9529l-9.7158,10.25C696.8906,187.0292,696.418,186.5834,695.4705,185.6943Z" />
                                    <path className="st4" d="M704.2777,194.1163l.8655-.8883a2.9842,2.9842,0,0,1-.5659.1468,2.9313,2.9313,0,0,1-2.2778-.8716,3.9445,3.9445,0,0,1-1.02-1.7337,2.4131,2.4131,0,0,1,.001-1.0522c.2-1.0556.8328-1.8062,2.2151-3.28.4606-.491.9123-.9821,1.3687-1.4591.6917-.7229,1.3979-1.4482,2.1184-2.1257a5.4263,5.4263,0,0,1,2.4843-1.554c1.84-.3421,3.4592,1.2224,3.7253,1.4805a5.307,5.307,0,0,1,.67.762,3.6705,3.6705,0,0,1,.64,3.0019,6.0268,6.0268,0,0,1-1.1463,1.9016c-.1935.2289-.4055.4429-.6156.6854-.8183-.8036-1.2282-1.2044-2.05-2.0043l.4186-.43a4.1313,4.1313,0,0,0,.8311-1.0894,1.3,1.3,0,0,0-1.4679-1.6746c-.6647.1542-1.3633.8807-2.4051,1.96-1.0061,1.042-1.5091,1.563-2.5151,2.6051a5.418,5.418,0,0,0-1.3654,1.8514,1.2374,1.2374,0,0,0,.3822,1.1494,1.2222,1.2222,0,0,0,1.1879.3172,5.204,5.204,0,0,0,1.6953-1.3924c.1675-.1721.364-.3441.5452-.53-.4319-.42-.6481-.63-1.0809-1.05l1.5872-1.6379c1.2265,1.1886,1.838,1.7851,3.057,2.9819l-5.5357,5.6385C705.327,195.14,704.9777,194.7983,704.2777,194.1163Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <>
                                    <g>
                                        <path className="st4" d="M575.26,101.28l2.48-5.26l1.61-3.36l1.01-1.8l-0.54,0.81l-0.9,1.28l-1.08,1.52l-4.57,5.92l-2.52-1.16
			l1.54-7.07l0.67-2.32l0.4-1.48l0.35-1.02l-0.55,1.26l-1.03,2.57l-3.43,7.17l-2.5-1.15l5.93-12.74l3.93,1.75l-1.86,7.84l-0.5,1.96
			l-0.51,1.37l0.92-1.51l0.74-1.09l5.06-6.81l3.88,1.75l-5.97,12.74L575.26,101.28z"/>
                                        <path className="st4" d="M579.63,103.38l6.44-12.52l6.14,3.02l-1.17,2.27l-3.49-1.72l-1.39,2.7l3.12,1.54l-1.14,2.22l-3.12-1.53
			l-1.57,3.05l3.49,1.72l-1.18,2.29L579.63,103.38z"/>
                                        <path className="st4" d="M596.22,100.53l0.1-0.24l0.43-0.83l-0.02-0.91l-0.42-0.47l-0.38-0.11l-0.54,0.01L595,98.22l-0.33,0.48
			l-0.23,0.55l0.04,0.52l0.56,1.46l0.71,1.32l0.55,1.14l0.36,1.54l-0.13,1.01l-0.47,1.17l-0.83,1.18l-1.15,0.86l-1.29,0.22
			l-1.63-0.27l-0.91-0.48l-1.24-0.78l-0.67-0.83l-0.34-1.06l-0.01-0.93l0.28-0.89l0.84-1.63l2.51,1.32l-0.58,1.07l-0.29,0.84
			l0.14,0.57l0.53,0.53l0.71,0.15l0.66-0.26l0.51-0.72l0.35-0.85l-0.09-0.81l-0.51-1.08l-0.75-1.36l-0.49-1.02L591.4,100l0.06-0.93
			l0.53-1.32l0.67-0.98l0.93-0.75l0.78-0.31l0.97-0.06l0.87,0.16l1.23,0.53l1.44,1.09l0.59,0.98l0.08,0.92l-0.31,1.3l-0.59,1.17
			L596.22,100.53z"/>
                                        <path className="st4" d="M604.63,105.09l0.11-0.23l0.45-0.82l0.01-0.91l-0.41-0.48l-0.38-0.12l-0.54-0.01l-0.4,0.24l-0.35,0.47
			l-0.25,0.55l0.03,0.52l0.52,1.48l0.67,1.33l0.52,1.16l0.31,1.55l-0.16,1.01l-0.51,1.16l-0.87,1.16l-1.17,0.83l-1.3,0.19
			l-1.62-0.31l-0.9-0.5l-1.21-0.81l-0.65-0.84l-0.31-1.06l0.02-0.93l0.3-0.88l0.88-1.61l2.48,1.38l-0.61,1.05l-0.31,0.83l0.12,0.57
			l0.52,0.54l0.71,0.17l0.67-0.25l0.53-0.71l0.37-0.84l-0.07-0.82l-0.48-1.09l-0.72-1.38l-0.47-1.04l-0.34-1.17l0.09-0.93l0.57-1.31
			l0.7-0.96l0.95-0.73l0.79-0.29l0.97-0.03l0.87,0.19l1.22,0.56l1.41,1.13l0.56,1l0.06,0.92l-0.35,1.29l-0.62,1.16L604.63,105.09z"
                                        />
                                        <path className="st4" d="M603.62,116.07l7.38-12.01l5.89,3.48l-1.34,2.18l-3.34-1.97l-1.59,2.59l2.99,1.77l-1.31,2.13l-2.99-1.77
			l-1.8,2.92l3.34,1.98l-1.35,2.19L603.62,116.07z"/>
                                        <path className="st4" d="M616.08,123.58l1.85-7.66l0.33-1.18l0.58-1.9l-0.59,1.24l-0.77,1.33l-4.12,6.49l-2.43-1.5l7.71-11.83
			l2.91,1.85l-1.75,7.06l-0.45,1.77l-0.55,1.66l0.7-1.45l0.49-0.87l4.15-6.54l2.4,1.51l-7.72,11.81L616.08,123.58z"/>
                                        <path className="st4" d="M623.3,127.16l-1.04,1.19l-0.6,0.4l-0.68,0.22l-1.35,0.04l-0.7-0.25l1.03-1.5l0.39-0.03l0.4-0.12l0.32-0.25
			l-0.58-0.38l1.54-2.24l2.26,1.49L623.3,127.16z"/>
                                        <path className="st4" d="M637.02,120.56l3.56,2.53l0.46,0.38l0.75,0.85l0.29,0.67l0.09,0.7l-0.11,0.73l-0.98,1.81l-0.82,0.94
			l-0.98,0.6l-0.88,0.19l-0.54-0.16l0.52,0.71l0.16,0.65l-0.1,0.69l-0.92,1.73L636.2,135l-1.14,0.47l-0.76,0.03l-0.67-0.16
			l-1.19-0.67l-3.83-2.72L637.02,120.56z M633.06,132.22l0.76,0.39l0.57-0.01l0.63-0.48l0.51-0.62l0.51-1.14l-0.2-0.56l-0.61-0.58
			l-0.77-0.55l-2.2,2.98L633.06,132.22z M636.43,127.3l0.7,0.37l0.37,0.04l0.41-0.1l0.93-1.08l0.34-0.64l-0.17-0.81l-0.5-0.53
			l-0.67-0.47l-2.04,2.77L636.43,127.3z"/>
                                        <path className="st4" d="M636.55,137.57l8.77-11.07l5.42,4.14l-1.59,2.01l-3.08-2.35l-1.89,2.38l2.75,2.1l-1.55,1.96l-2.75-2.1
			l-2.14,2.7l3.08,2.35l-1.6,2.02L636.55,137.57z"/>
                                        <path className="st4" d="M653.27,144.08l-1.34,1.47l-0.73,0.6l-0.83,0.39l-0.81,0.18l-0.9-0.13l-1.06-0.35l-1.11-0.78l-1.2-1.12
			l-0.56-1.06l-0.12-1.24l0.14-0.88l0.36-0.73l1.03-1.35l6.04-7.37l2.28,1.8l-5.37,6.55l-1.15,1.44l-0.58,0.97l0,0.51l0.15,0.37
			l0.4,0.44l0.47,0.24l0.8,0.02l0.59-0.46l0.78-0.82l0.81-0.99l5.33-6.53l2.31,1.83L653.27,144.08z"/>
                                        <path className="st4" d="M656.16,153.1l0.16-0.52l1.04-1.58l0.54-0.62l0.83-1.05l0.18-0.37l0.09-0.6l-0.24-0.44l-0.31-0.33l-0.83-0.7
			l-3.74,4.31l-2.29-1.92l9.26-10.67l4.14,3.47l0.68,0.78l0.32,0.66l0.16,0.79l-0.24,1.18l-0.98,1.48l-0.65,0.68l-0.93,0.79
			l-1.13,0.33l-0.9-0.21l0.35,0.97l-0.25,1.04l-0.63,0.83l-0.17,0.2l-1.32,1.77l-0.65,1.25l-0.1,0.49L656.16,153.1z M660.48,146.19
			l0.41,0.16l0.62-0.08l0.83-0.8l0.55-0.69l0.17-0.41l0.03-0.63l-0.78-0.81l-0.72-0.6l-2.4,2.77L660.48,146.19z"/>
                                        <path className="st4" d="M660.31,156.67l7.91-8.66l-1.78-1.57l1.63-1.79l5.76,5.07l-1.63,1.79l-1.78-1.57l-7.9,8.66L660.31,156.67z" />
                                        <path className="st4" d="M665.04,160.86l9.77-10.24l5.01,4.61l-1.77,1.86l-2.85-2.62l-2.1,2.2l2.54,2.34l-1.73,1.81l-2.54-2.34
			l-2.38,2.49l2.84,2.62l-1.79,1.87L665.04,160.86z"/>
                                        <path className="st4" d="M671.25,166.63l9.96-10.06l2.13,2.04l-9.96,10.06L671.25,166.63z" />
                                        <path className="st4" d="M674.88,170.11l10.14-9.87l2.06,2.05l-8.33,8.11l2.94,2.93l-1.81,1.76L674.88,170.11z" />
                                        <path className="st4" d="M680.85,176.07l10.38-9.64l4.71,4.91l-1.88,1.75l-2.67-2.79l-2.24,2.07l2.39,2.49l-1.84,1.71l-2.39-2.49
			l-2.53,2.35l2.67,2.79l-1.9,1.76L680.85,176.07z"/>
                                        <path className="st4" d="M690.79,186.63l3.87-6.9l0.64-1.05l1.08-1.68l-0.9,1.04l-1.11,1.08l-5.74,5.17l-1.92-2.09l10.65-9.38
			l2.29,2.55l-3.61,6.35l-0.92,1.59l-0.98,1.46l1.07-1.21l0.71-0.71l5.79-5.21l1.89,2.09l-10.65,9.35L690.79,186.63z"/>
                                    </g>
                                    <g>
                                        <path className="st4" d="M603.03,89.31l0.17-7.72l-3.17,6.18l-2.59-1.32l6.55-12.48l2.53,1.29l-2.95,5.62l5.65-4.24l2.89,1.48
			l-6.24,4.23l-0.01,8.41L603.03,89.31z"/>
                                        <path className="st4" d="M615.74,91.24l-0.87,1.28l-0.85,0.77l-0.79,0.41l-0.82,0.14l-1.65-0.22l-0.91-0.45l-1.23-0.83l-0.84-1.21
			l-0.26-0.87l0.06-1.2l0.25-0.66l2.04-4.08l1.17-1.98l0.64-0.97l0.72-0.84l0.88-0.54l0.96-0.23l1.13,0.14l1.59,0.61l1.03,0.7
			l0.67,0.75l0.53,1.34l-0.04,1.2l-0.19,0.67l-1.64,3.23L615.74,91.24z M615.64,85.23l0.48-0.99l0.16-0.78l-0.1-0.58l-0.43-0.48
			l-0.69-0.15l-0.83,0.53l-0.55,0.77l-1.06,1.81l-1.5,2.78l-0.51,1l-0.31,1.01l0.22,0.73l0.37,0.34l0.63,0.12l0.6-0.2l0.68-0.93
			l0.88-1.5L615.64,85.23z"/>
                                        <path className="st4" d="M620.46,98.88l1.54-7.74l0.28-1.2l0.5-1.92l-0.54,1.26l-0.71,1.36l-3.84,6.66l-2.45-1.4l7.19-12.15l2.94,1.73
			l-1.47,7.13l-0.38,1.79l-0.48,1.68l0.64-1.48l0.46-0.89l3.87-6.72l2.43,1.41l-7.2,12.13L620.46,98.88z"/>
                                        <path className="st4" d="M626.09,102.28l6.2-9.91l-2.01-1.23l1.28-2.05l6.5,3.97l-1.28,2.05l-2.01-1.23l-6.2,9.91L626.09,102.28z" />
                                        <path className="st4" d="M636.44,108.77l0.09-0.54l0.83-1.7l0.45-0.69l0.68-1.15l0.13-0.39l0.01-0.61l-0.29-0.4l-0.34-0.29l-0.91-0.58
			l-3.13,4.76l-2.5-1.6l7.74-11.8l4.51,2.89l0.77,0.68l0.4,0.61l0.26,0.76l-0.09,1.2l-0.78,1.59l-0.55,0.76l-0.81,0.9l-1.07,0.48
			l-0.91-0.09l0.47,0.92l-0.12,1.07l-0.52,0.9l-0.14,0.22l-1.07,1.93l-0.48,1.33l-0.04,0.5L636.44,108.77z M639.8,101.35l0.42,0.1
			l0.59-0.16l0.71-0.9l0.45-0.76l0.11-0.43l-0.05-0.63l-0.87-0.7l-0.79-0.5l-2.01,3.06L639.8,101.35z"/>
                                        <path className="st4" d="M649.16,112.07l-0.99,1.19l-0.92,0.68l-0.82,0.33l-0.83,0.06l-1.62-0.37l-0.86-0.54l-1.15-0.95l-0.72-1.28
			l-0.17-0.89l0.18-1.18l0.32-0.63l2.43-3.86l1.36-1.85l0.73-0.91l0.8-0.76l0.93-0.45l0.98-0.13l1.11,0.25l1.52,0.76l0.96,0.79
			l0.6,0.81l0.4,1.38l-0.16,1.19l-0.25,0.65l-1.94,3.05L649.16,112.07z M649.66,106.08l0.58-0.94l0.23-0.76l-0.04-0.58l-0.38-0.52
			l-0.67-0.21l-0.88,0.45l-0.62,0.72l-1.23,1.7l-1.77,2.62l-0.6,0.94l-0.41,0.98l0.15,0.75l0.33,0.37l0.62,0.18l0.61-0.14l0.77-0.86
			l1.02-1.41L649.66,106.08z"/>
                                        <path className="st4" d="M648.23,116.74l8.18-11.51l2.37,1.64l-6.72,9.46l3.38,2.35l-1.46,2.05L648.23,116.74z" />
                                        <path className="st4" d="M655.04,121.56l8.54-11.25l2.31,1.72l-7.02,9.24l3.31,2.46l-1.52,2.01L655.04,121.56z" />
                                        <path className="st4" d="M661.77,126.61l8.72-11.11l2.32,1.78l-8.72,11.11L661.77,126.61z" />
                                        <path className="st4" d="M665.75,129.67l8.91-10.96l5.28,4.2l-1.61,1.99l-3-2.39l-1.92,2.36l2.68,2.13l-1.58,1.94l-2.68-2.13
			l-2.17,2.67l3,2.39l-1.63,2L665.75,129.67z"/>
                                        <path className="st4" d="M676.86,138.69l0.15-0.52l1.03-1.59l0.53-0.62l0.82-1.06l0.18-0.37l0.09-0.6l-0.24-0.44l-0.3-0.33l-0.83-0.69
			l-3.7,4.34l-2.27-1.89l9.16-10.76l4.11,3.43l0.67,0.77l0.32,0.66l0.16,0.78l-0.24,1.18l-0.97,1.48l-0.64,0.69l-0.92,0.8
			l-1.12,0.34l-0.89-0.2l0.35,0.97l-0.25,1.04l-0.63,0.83l-0.17,0.2l-1.3,1.78l-0.65,1.26l-0.1,0.49L676.86,138.69z M681.12,131.75
			l0.4,0.15l0.61-0.09l0.82-0.8l0.54-0.7l0.16-0.41l0.03-0.63l-0.78-0.8l-0.72-0.6l-2.37,2.79L681.12,131.75z"/>
                                        <path className="st4" d="M680.02,141.36l9.41-10.55l5.08,4.44l-1.71,1.91l-2.88-2.52l-2.03,2.27l2.58,2.25l-1.67,1.87l-2.58-2.25
			l-2.29,2.57l2.89,2.52l-1.72,1.93L680.02,141.36z"/>
                                        <path className="st4" d="M690.75,150.89l3.19-7.23l0.54-1.11l0.91-1.77l-0.79,1.12l-0.99,1.18l-5.2,5.69l-2.09-1.89l9.66-10.34
			l2.49,2.31l-2.99,6.66l-0.76,1.67l-0.83,1.54l0.95-1.31l0.64-0.77l5.24-5.74l2.06,1.89l-9.66,10.31L690.75,150.89z"/>
                                        <path className="st4" d="M696.9,156.44l-0.65,0.55l-1.05,0.47l-0.89,0.07l-1.28-0.23l-0.96-0.47l0.77-0.79l0.73,0.2l0.75,0.02
			l0.6-0.18l0.75-0.5l-1.08-1.04l2.1-2.15l2.15,2.06L696.9,156.44z"/>
                                    </g>
                                </>}
                        </g>
                        <g id="Segment_-_3" className={"model-option" + (this.state.changed ? this.setStyles(5) : '')}>
                            <path id="Circle_-_2-2_2_" className="st0" d="M471.79,281.8l54.02-166.26c-62.93-20.23-132.28-22.44-199.95-0.45
		c-0.44,0.14-0.88,0.3-1.32,0.45l54.04,166.32C410.53,271.66,442.41,272.44,471.79,281.8z"/>
                            <path id="Circle_-_2-1_4_" className="st6" d="M471.79,281.8l54.02-166.26c-62.93-20.23-132.28-22.44-199.95-0.45
		c-0.44,0.14-0.88,0.3-1.32,0.45l54.04,166.32C410.53,271.66,442.41,272.44,471.79,281.8z"/>
                            <path id="Circle_-_1_3_" className="st7" d="M525.81,115.53l30.66-94.36c-82.5-26.87-173.72-28.86-262.55,0l30.62,94.35
		c0.44-0.15,0.87-0.3,1.32-0.45C393.53,93.1,462.88,95.3,525.81,115.53z"/>

                            <path id="Title_x5F_-_x5F_2_x5F_4_x5F_" className="st5" d="M68.13,425.33C68.13,228.12,228,68.26,425.2,68.26
		c197.2,0,357.07,159.86,357.07,357.07S622.41,782.4,425.2,782.4C228,782.4,68.13,622.53,68.13,425.33z"/>
                            <text id="Title_-_2_3_" className="st4 st8 st9">	<textPath xlinkHref="#Title_x5F_-_x5F_2_x5F_4_x5F_" startOffset="100%">
                            </textPath>
                            </text>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M397.894,43.3051c-.1579-2.3473-.2369-3.5209-.3949-5.8681-.0819-1.2175-.2043-2.47-.2265-3.6939-.0126-.692.0158-1.4047.0132-2.1063-.0447.3241-.0808.6759-.109,1.01-.0418.4944-.0863,1.0292-.1386,1.5329-.0668.6444-.1758,1.2752-.2637,1.9029-.4519,2.9509-.6714,4.4266-1.0974,7.3785-1.1762.0864-1.7642.132-2.94.2281-.88-2.7863-1.3256-4.1783-2.23-6.96-.2409-.7625-.4829-1.5462-.6851-2.33-.1314-.51-.2569-1.012-.3759-1.5134-.0817-.3447-.1732-.7078-.271-1.06.0626.4562.1411.8969.2079,1.3473.1367.9217.2632,1.8552.3555,2.78.0374.3741.0823.763.1218,1.1344.0624.5863.107,1.2155.1614,1.8332l.4369,4.9619c-1.1584.102-1.7374.1553-2.8952.2667-.5577-5.6218-.8369-8.4326-1.3961-14.0542,1.831-.1763,2.7469-.2588,4.5794-.4127,1,3.0961,1.4919,4.6456,2.4609,7.7477.1836.6679.3845,1.3173.5257,1.9856.1.472.1823.9486.2839,1.4222-.0255-.6.0112-1.1772.0376-1.7484.02-.43.0621-.8878.1153-1.3331.4476-3.3726.68-5.0587,1.159-8.4305,1.8338-.1314,2.751-.1914,4.5859-.3.3526,5.6384.5284,8.4576.8794,14.096C399.6338,43.19,399.0538,43.2271,397.894,43.3051Z" />
                                    <path className="st4" d="M410.2,42.6873c-.342-1.55-.5145-2.3247-.8628-3.8738-.9926.0349-1.4888.054-2.4812.0956-.2193,1.571-.3275,2.3565-.5408,3.9278-1.2717.0558-1.9075.0865-3.1788.1536,1.0072-5.7263,1.5366-8.588,2.6465-14.3072,1.579-.069,2.3686-.0993,3.9481-.1516,1.5007,5.6188,2.2264,8.4319,3.6283,14.0647C412.0955,42.6273,411.4636,42.6454,410.2,42.6873Zm-2.0265-9.5164c-.1368-.7156-.2743-1.4311-.3677-2.1483-.0384.7223-.12,1.4463-.2018,2.17-.1835,1.3607-.2742,2.041-.4533,3.4018.6858-.028,1.0287-.0412,1.7147-.066C408.5907,35.1852,408.4523,34.5137,408.1735,33.1709Z" />
                                    <path className="st4" d="M422.3038,42.489c-1.4427-2.7851-2.1779-4.1742-3.6761-6.9445.0529,2.7842.0792,4.1764.1311,6.9606-1.2215.0116-1.8323.02-3.0538.0418-.101-5.6485-.1515-8.4726-.2526-14.1211,1.2425-.0222,1.8638-.0307,3.1064-.0427.0245,2.5445.0367,3.8167.0612,6.3612,1.2755-2.557,1.9244-3.8328,3.2447-6.3785,1.42-.0012,2.13.0016,3.55.0138-1.5759,2.6352-2.35,3.957-3.8714,6.6083,1.6794,2.9978,2.5021,4.5015,4.1134,7.5178C424.3157,42.4928,423.6451,42.4894,422.3038,42.489Z" />
                                    <path className="st4" d="M427.5969,42.53c.0847-5.6486.127-8.473.2118-14.1217,1.278.0192,1.917.0315,3.195.0615-.1328,5.6478-.1992,8.4717-.3319,14.1194C429.4419,42.56,428.8269,42.548,427.5969,42.53Z" />
                                    <path className="st4" d="M440.894,42.9735c-.9366-3.0692-1.4164-4.6023-2.3988-7.6653-.1352-.3868-.2537-.7538-.3654-1.1372-.1932-.6636-.3378-1.336-.5077-2.0038.068.4833.1158.9473.1385,1.407.0246.5.024,1.0024.0233,1.5228-.0782,3.0882-.1178,4.6322-.1981,7.72-1.1952-.0509-1.7928-.074-2.9882-.1151.194-5.646.291-8.469.4852-14.115,1.4816.051,2.2222.08,3.7034.1456.9241,2.82,1.3765,4.2318,2.2617,7.0573.1891.5705.3728,1.1647.5177,1.75.14.5663.2857,1.1569.4047,1.7443-.057-.5438-.1175-1.0916-.1277-1.63-.0065-.338-.007-.6816-.0122-1.0021.1226-3.1026.1835-4.6539.3043-7.7567,1.2236.0651,1.8352.1,3.0584.1752-.3461,5.6387-.5191,8.458-.8651,14.0967C442.9547,43.0836,442.2678,43.0447,440.894,42.9735Z" />
                                    <path className="st4" d="M453.7646,43.8711l.1083-1.2355a2.96,2.96,0,0,1-.3635.47,3.1953,3.1953,0,0,1-2.446.7595,4.3917,4.3917,0,0,1-2.0036-.6971,2.496,2.496,0,0,1-.7136-.8143c-.555-.9428-.5521-1.9259-.4317-3.9416.04-.6717.073-1.3385.1193-1.9971.07-.998.1506-2.0081.2757-2.9885a5.1224,5.1224,0,0,1,.9723-2.7786c1.28-1.4315,3.6992-1.2389,4.097-1.2057a6.0858,6.0858,0,0,1,1.0784.1717,3.9823,3.9823,0,0,1,2.5856,1.94,5.6814,5.6814,0,0,1,.346,2.2025c-.0045.3-.0344.5995-.0434.9205-1.2227-.1165-1.8343-.1722-3.0577-.2784l.0519-.5979a3.87,3.87,0,0,0-.0581-1.37,1.2748,1.2748,0,0,0-1.2148-.8257,1.3186,1.3186,0,0,0-1.1447.4486c-.4421.539-.5194,1.5443-.6373,3.04L451,38.7028a5.13,5.13,0,0,0,.1423,2.2988,1.34,1.34,0,0,0,1.0914.6507,1.3184,1.3184,0,0,0,1.186-.502,4.9278,4.9278,0,0,0,.4461-2.15c.0208-.2393.0655-.4967.0879-.7554-.64-.0556-.96-.0827-1.6006-.1355.0749-.9093.1124-1.3639.1874-2.2732,1.82.15,2.7289.2307,4.547.4033l-.7465,7.8666C455.3106,44.0085,454.7953,43.9615,453.7646,43.8711Z" />
                                    <path className="st4" d="M377.84,53.1553c1.62-.2046,2.43-.3022,4.0515-.488a4.7218,4.7218,0,0,1,3.1019.4832,4.7007,4.7007,0,0,1,1.6409,3.3284c.1282.7608.2386,1.5242.3178,2.3.1055,1.0347.171,2.0949.2377,3.1326a4.6158,4.6158,0,0,1-.567,3.1758,4.249,4.249,0,0,1-3.3022,1.6311c-1.485.171-2.2271.2606-3.7106.4479C378.9016,61.5623,378.5476,58.76,377.84,53.1553Zm5.1433,11.166a1.1187,1.1187,0,0,0,1.218-.9435,19.4128,19.4128,0,0,0-.1807-3.2808c-.1146-1.0337-.2574-2.0575-.4012-3.0549-.13-.9007-.2082-1.4258-.6067-1.7034a1.5752,1.5752,0,0,0-1.0884-.1986c-.3386.0391-.5079.0588-.8464.0987.4326,3.6715.6488,5.5072,1.0813,9.1786C382.4884,64.3785,382.6532,64.3593,382.9829,64.3213Z" />
                                    <path className="st4" d="M390.728,65.9479c-.5232-5.6247-.7847-8.4372-1.3078-14.0621,2.7736-.2579,4.1614-.3732,6.9384-.5765.0747,1.0215.1121,1.5323.1869,2.5537-1.5647.1145-2.3468.1762-3.91.3082l.2557,3.03c1.3862-.117,2.0794-.172,3.4664-.2752.0742.9974.1113,1.4961.1854,2.4936-1.377.1024-2.0652.157-3.4414.2732l.2894,3.4286c1.5236-.1286,2.2858-.1887,3.81-.3.0753,1.0294.113,1.5442.1883,2.5736C394.7232,65.59,393.3908,65.7,390.728,65.9479Z" />
                                    <path className="st4" d="M399.39,56.3729c-.0043-.3809-.0192-.77-.032-1.12-.0107-.2914-.02-.62-.0187-.9211a3.6308,3.6308,0,0,1,.96-2.5867,3.169,3.169,0,0,1,.6606-.4812,5.6638,5.6638,0,0,1,3.2322-.58,4.4309,4.4309,0,0,1,2.2607.9538c.8462.7828,1.0171,1.8782,1.11,4.0964-1.1357.0476-1.7035.0738-2.8389.1307l-.0461-.9188a3.38,3.38,0,0,0-.2905-1.648,1.1318,1.1318,0,0,0-1.0238-.4874,1.0716,1.0716,0,0,0-.6518.256c-.5181.4092-.4206,1.0253-.3784,2.1247.01.2552.0307.54.0249.8.0859,1.51.1289,2.2648.2149,3.7746.051.5381.0629,1.0786.1112,1.5968a4.6416,4.6416,0,0,0,.1791,1.0115,1.0707,1.0707,0,0,0,2.0212-.0682,8.8266,8.8266,0,0,0,.0545-2.146l-.023-.4594c1.1229-.0562,1.6845-.0821,2.8077-.1292.1008,1.9178.0775,2.84-.3481,3.7188-.8314,1.7584-2.9893,1.8657-3.45,1.8905a4.3434,4.3434,0,0,1-3.0054-.8272,2.4528,2.4528,0,0,1-.5932-.7643,9.8623,9.8623,0,0,1-.7019-3.6227C399.5312,58.5286,399.4841,57.81,399.39,56.3729Z" />
                                    <path className="st4" d="M410.9467,64.6744c-.1869-5.646-.28-8.469-.467-14.1151,1.2029-.04,1.8043-.0571,3.0074-.0866.1388,5.6475.2082,8.4712.347,14.1185C412.679,64.62,412.1016,64.6362,410.9467,64.6744Z" />
                                    <path className="st4" d="M421.6589,54.4474l0-.26a8.5908,8.5908,0,0,0-.0428-.94c-.1131-.87-.6458-1.0192-1.0612-1.0173a1.0767,1.0767,0,0,0-.8706.3255,1.0248,1.0248,0,0,0-.2248.4216,2.3786,2.3786,0,0,0-.0577.5805c.0068.86.3425,1.2356,1.58,2.0516.4951.3425.742.5142,1.2346.8583a7.3715,7.3715,0,0,1,1.045.7414,3.95,3.95,0,0,1,1.5305,3.4292,4.6542,4.6542,0,0,1-.7648,2.7157,2.6992,2.6992,0,0,1-1.0085.8158,5.4914,5.4914,0,0,1-2.5267.5236,4.0338,4.0338,0,0,1-3.1856-1.29,6.2479,6.2479,0,0,1-.7222-3.57c1.1224-.0183,1.6835-.0251,2.806-.0342.0032.4.0118.825.03,1.22.0357.77.15,1.0793.4949,1.3167a1.0594,1.0594,0,0,0,.7276.2162,1.1205,1.1205,0,0,0,1.1286-.7434,2.5652,2.5652,0,0,0,.1-.88,3.1827,3.1827,0,0,0-.1025-.92c-.1429-.52-.5494-.84-1.43-1.3957-.5242-.3411-.7869-.5112-1.3136-.85-.3085-.2174-.6386-.4342-.9281-.67a3.7249,3.7249,0,0,1-1.4844-3.1209,5.0876,5.0876,0,0,1,.1058-1.182,3.2526,3.2526,0,0,1,1.8025-2.2637,5.2359,5.2359,0,0,1,2.2121-.3763,3.9823,3.9823,0,0,1,2.7971.9,2.6825,2.6825,0,0,1,.5383.7827,5.9173,5.9173,0,0,1,.378,2.6229C423.3316,54.4471,422.774,54.4457,421.6589,54.4474Z" />
                                    <path className="st4" d="M427.4276,64.5294c.0874-5.6484.1311-8.4726.2184-14.1211,1.2032.0186,1.8048.03,3.0079.0592-.1354,5.6477-.2031,8.4714-.3385,14.1188C429.16,64.5587,428.5828,64.5473,427.4276,64.5294Z" />
                                    <path className="st4" d="M441.9458,60.7365c-.2158,2.2888-.7744,3.2125-1.8271,3.7858a4.9618,4.9618,0,0,1-2.5579.5154,4.78,4.78,0,0,1-2.7347-.9138,2.69,2.69,0,0,1-.62-.663,4.4341,4.4341,0,0,1-.5848-1.8014,34.6934,34.6934,0,0,1-.0317-4.5645c.025-.76.0624-1.55.1169-2.2981a9.5972,9.5972,0,0,1,.1211-1.1568,3.2606,3.2606,0,0,1,2.67-2.9641,5.9168,5.9168,0,0,1,1.7637-.1647,4.0632,4.0632,0,0,1,3.3268,1.5694,3.8431,3.8431,0,0,1,.6071,1.7971,29.9239,29.9239,0,0,1-.0427,3.6246C442.0981,58.5743,442.0472,59.6611,441.9458,60.7365Zm-2.83-3.2755c.0352-.7194.06-1.4291.1036-2.118a6.1124,6.1124,0,0,0-.0079-1.1018c.0078-.16-.0438-1.4559-1.1761-1.4971a1.0383,1.0383,0,0,0-.6936.17c-.556.3763-.6039,1.2035-.6549,1.7741-.06.67-.0985,1.3775-.1282,2.0966-.0439,1.06-.0635,2.1168-.09,3.16a5.52,5.52,0,0,0,.1727,2.1693,1.1371,1.1371,0,0,0,2.0608.0933,11.39,11.39,0,0,0,.322-2.8681Z" />
                                    <path className="st4" d="M450.6437,65.6837c-.7712-3.0921-1.1683-4.6372-1.9855-7.7251-.1148-.39-.2139-.76-.3053-1.1465-.1583-.6681-.27-1.3443-.4084-2.0164.0473.4848.0773.95.0827,1.41.0058.5-.0131,1.0022-.0307,1.5224-.1809,3.0837-.2719,4.6255-.4549,7.709-1.12-.084-1.6808-.1236-2.8017-.1982.3754-5.6366.563-8.4549.9384-14.0916,1.3931.0928,2.09.1426,3.4819.2492.772,2.8437,1.1482,4.2666,1.8812,7.114.1578.5752.31,1.1737.4252,1.7626.1119.57.2278,1.1638.3189,1.7539-.0343-.545-.0718-1.0937-.0625-1.6319.0059-.3381.0175-.6812.024-1.0016.2247-3.0968.3367-4.6452.56-7.7422,1.15.0984,1.7248.15,2.8742.2577-.5271,5.6245-.7906,8.4367-1.3178,14.0611C452.5758,65.8493,451.9319,65.792,450.6437,65.6837Z" />
                                    <path className="st4" d="M462.8368,56.8192l.03-.2585a8.5708,8.5708,0,0,0,.0681-.9384.9946.9946,0,0,0-.9363-1.1354,1.0786,1.0786,0,0,0-.9037.2206,1.0357,1.0357,0,0,0-.2732.3921,2.3578,2.3578,0,0,0-.1263.57c-.0945.8551.1956,1.2673,1.33,2.2237.4521.3984.6775.5981,1.127.9979a7.377,7.377,0,0,1,.9523.86,3.947,3.947,0,0,1,1.1194,3.5859,4.651,4.651,0,0,1-1.0813,2.6065,2.7019,2.7019,0,0,1-1.0989.6912,5.5119,5.5119,0,0,1-2.5751.2219A4.0435,4.0435,0,0,1,457.4528,65.2a6.2458,6.2458,0,0,1-.2976-3.6306c1.1181.1143,1.677.1737,2.7944.297-.044.398-.0858.82-.1142,1.215-.0555.77.0229,1.0894.3376,1.3659a1.0629,1.0629,0,0,0,.6978.3,1.1238,1.1238,0,0,0,1.211-.605,2.5716,2.5716,0,0,0,.203-.8621,3.2017,3.2017,0,0,0,.0069-.9259c-.0815-.5331-.4486-.9-1.2579-1.5547-.4812-.4006-.7225-.6-1.2063-.9994-.2823-.2524-.584-.5064-.8445-.7754a3.7224,3.7224,0,0,1-1.1086-3.2741,5.1081,5.1081,0,0,1,.2444-1.1613,3.2528,3.2528,0,0,1,2.0585-2.0354,5.2554,5.2554,0,0,1,2.2448-.1128,3.9953,3.9953,0,0,1,2.677,1.2238,2.6667,2.6667,0,0,1,.4424.8407,5.8936,5.8936,0,0,1,.0682,2.65C464.5009,57.0163,463.9464,56.9491,462.8368,56.8192Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st4" d="M373.66,48.61l-1.78-13.99l6.7-0.92l0.32,2.54l-3.8,0.52l0.38,3.01l3.4-0.47l0.31,2.48l-3.4,0.47l0.43,3.41
			l3.8-0.52l0.32,2.56L373.66,48.61z"/>
                                    <path className="st4" d="M387.94,46.76l-3.36-7.14l-0.48-1.13l-0.74-1.85l0.32,1.33l0.24,1.52l0.88,7.64l-2.8,0.33l-1.45-14.05
			l3.39-0.36l3.06,6.61l0.76,1.66l0.62,1.64l-0.36-1.57l-0.16-0.99l-0.89-7.7l2.79-0.31l1.43,14.04L387.94,46.76z"/>
                                    <path className="st4" d="M394.51,46.14l-0.91-11.65l-2.35,0.21l-0.19-2.41l7.58-0.67l0.19,2.41l-2.35,0.21l0.91,11.65L394.51,46.14z"
                                    />
                                    <path className="st4" d="M404.81,35.25l-0.04-0.26l-0.07-0.94l-0.49-0.77l-0.6-0.18l-0.38,0.11l-0.45,0.29l-0.2,0.41l-0.03,0.58
			l0.09,0.6l0.31,0.42l1.23,0.96l1.28,0.75l1.06,0.69l1.1,1.13l0.42,0.93l0.21,1.25l-0.08,1.45l-0.51,1.34l-0.97,0.87l-1.51,0.62
			l-1.01,0.07l-1.45-0.02l-1-0.35l-0.83-0.72l-0.49-0.79l-0.23-0.91l-0.15-1.83l2.8-0.19l0.07,1.22l0.19,0.87l0.41,0.41l0.72,0.17
			l0.68-0.25l0.42-0.57l0.05-0.88l-0.15-0.91l-0.5-0.65l-0.99-0.65l-1.35-0.77l-0.95-0.62l-0.92-0.8l-0.43-0.83l-0.24-1.41
			l0.05-1.18l0.38-1.13l0.49-0.67l0.78-0.55l0.82-0.32l1.31-0.19l1.78,0.18l1.01,0.53l0.55,0.74l0.42,1.27l0.12,1.32L404.81,35.25z"
                                    />
                                    <path className="st4" d="M409.39,36.16l0-1.12l0.03-0.92l0.15-1.07l0.3-0.79l0.59-0.71l0.63-0.47l1.34-0.5l0.93-0.1l0.88,0.06
			l0.94,0.22l1.24,0.75l0.53,0.68l0.31,0.85l0.23,2.57l-2.82,0.12l-0.03-0.92l-0.12-1.02l-0.14-0.63l-0.36-0.3l-0.66-0.19
			l-0.62,0.25l-0.25,0.39l-0.15,0.43l-0.04,1.3l0.21,6.17l0.18,1.01l0.28,0.45l0.31,0.19l0.47,0.06l0.48-0.14l0.47-0.6l0.11-2.15
			l-0.02-0.46l2.82-0.12l0,2.28l-0.4,1.44l-0.88,1.1l-1.06,0.53l-1.58,0.25l-1.28-0.02l-0.92-0.26l-0.83-0.58l-0.55-0.77l-0.43-1.42
			l-0.16-2.21L409.39,36.16z"/>
                                    <path className="st4" d="M425.12,44.47L425,38.35l-2.58,0.08l0.13,6.12l-2.92,0.09l-0.29-14.12l2.92-0.09l0.12,5.68l2.58-0.08
			l-0.12-5.68l2.92-0.09l0.29,14.12L425.12,44.47z"/>
                                    <path className="st4" d="M430.07,44.42l0.2-14.12l6.76,0.02l-0.04,2.56l-3.84-0.01l-0.04,3.04l3.43,0.01l-0.04,2.5l-3.43-0.01
			l-0.05,3.44l3.84,0.01l-0.04,2.58L430.07,44.42z"/>
                                    <path className="st4" d="M438.48,44.48l0.43-14.12l2.92,0.06l-0.43,14.12L438.48,44.48z" />
                                    <path className="st4" d="M444.22,30.44l3.95,0.16l1.57,0.24l1.33,0.71l0.61,0.81l0.3,0.89l0.17,1.83l-0.05,2.32l-0.24,3.14l-0.36,1.97
			l-0.68,1.1l-0.82,0.65l-0.85,0.35l-1.87,0.13l-3.77-0.15L444.22,30.44z M447.32,42.3l0.65-0.05l0.38-0.21l0.35-0.49l0.33-3.27
			l0.11-3.08l-0.03-1.12l-0.29-0.65l-0.49-0.26l-0.52-0.1l-0.83-0.03l-0.46,9.24L447.32,42.3z"/>
                                    <path className="st4" d="M453.53,45l1.02-14.1l6.74,0.42l-0.19,2.56l-3.82-0.24l-0.22,3.03l3.42,0.21l-0.18,2.5l-3.42-0.21l-0.25,3.43
			l3.83,0.24l-0.19,2.58L453.53,45z"/>
                                    <path className="st4" d="M467.89,46.01l-1.88-7.68l-0.25-1.21l-0.36-1.96l0.05,1.37l-0.07,1.54l-0.65,7.68l-2.81-0.22l1.35-14.09
			l3.39,0.31l1.69,7.1l0.42,1.79l0.28,1.73l-0.05-1.61l0.04-1l0.65-7.74l2.79,0.24l-1.37,14.07L467.89,46.01z"/>
                                </g>
                            }
                        </g>
                        <g id="Segment_-_2" className={"model-option" + (this.state.changed ? this.setStyles(4) : '')}>
                            <path id="Circle_-_2-2_3_" className="st0" d="M378.64,281.92l-54.11-166.16c-62.81,20.62-120.08,59.38-161.9,116.94
		c-0.27,0.38-0.53,0.76-0.8,1.14l141.31,102.79C323.13,309.44,349.37,291.62,378.64,281.92z"/>
                            <path id="Circle_-_2-1_3_" className="st6" d="M378.64,281.92l-54.11-166.16c-62.81,20.62-120.08,59.38-161.9,116.94
		c-0.27,0.38-0.53,0.76-0.8,1.14l141.31,102.79C323.13,309.44,349.37,291.62,378.64,281.92z"/>
                            <path id="Circle_-_1_1_" className="st7" d="M324.52,115.76L293.8,21.22C211.27,47.96,136.71,99.81,81.81,175.38l80.33,58.46
		c0.27-0.38,0.53-0.76,0.8-1.14C204.78,175.13,261.72,136.38,324.52,115.76z"/>

                            <path id="Title_x5F_-_x5F_2_x5F_5_x5F_" className="st5" d="M136.31,635.18C20.39,475.64,55.76,252.34,215.3,136.43
		s382.84-80.55,498.75,78.99s80.55,382.84-78.99,498.75S252.22,794.72,136.31,635.18z"/>
                            <text id="Title_-_2_2_" className="st4 st8 st9">	<textPath xlinkHref="#Title_x5F_-_x5F_2_x5F_5_x5F_" startOffset="100%">
                            </textPath>
                            </text>
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M162.1215,144.572c1.52,1.7254,1.81,2.7648,1.5042,3.926a4.96,4.96,0,0,1-1.3816,2.2159,4.7818,4.7818,0,0,1-2.5457,1.3618,2.7061,2.7061,0,0,1-.9083-.0053,4.4324,4.4324,0,0,1-1.71-.8148,34.6818,34.6818,0,0,1-3.3344-3.1178c-.5341-.5409-1.0809-1.1111-1.5872-1.6659a9.5154,9.5154,0,0,1-.7563-.8836,3.26,3.26,0,0,1-.3244-3.9686,5.8827,5.8827,0,0,1,1.0856-1.3889,4.0408,4.0408,0,0,1,3.4113-1.3318,3.8606,3.8606,0,0,1,1.722.7919,29.9528,29.9528,0,0,1,2.61,2.5147C160.65,142.98,161.4076,143.7615,162.1215,144.572Zm-4.3214-.1891c-.4992-.5191-.9988-1.0241-1.47-1.5285a6.0661,6.0661,0,0,0-.807-.75c-.111-.1154-1.0877-.9681-1.8916-.1764a1.0317,1.0317,0,0,0-.3508.6187c-.1072.6612.4609,1.264.84,1.6934.4456.5041.9333,1.0178,1.4357,1.5338.74.76,1.4942,1.5007,2.234,2.2363a5.5234,5.5234,0,0,0,1.6953,1.3646,1.1366,1.1366,0,0,0,1.4826-1.434,11.4317,11.4317,0,0,0-1.8656-2.2023Z" />
                                    <path className="st4" d="M171.6289,141.65a5.3427,5.3427,0,0,1-.5008-.1936,5.7064,5.7064,0,0,1-1.4571-1.244l-.5518-.6071a10.6045,10.6045,0,0,0-.9467-.9508,1.3634,1.3634,0,0,0-.9347-.3931,1.297,1.297,0,0,0-.8383.44c-.3182.2913-.4772.4371-.7947.7293l3.86,4.1956c-.8628.794-1.2928,1.1925-2.15,1.9928-3.8553-4.13-5.7827-6.1947-9.6374-10.3237,1.4337-1.3384,2.1543-2.0033,3.6031-3.3245.1382-.1261.2976-.2648.4434-.3768a3.5472,3.5472,0,0,1,2.3849-.8888,3.8663,3.8663,0,0,1,2.4981,1.5474c.2.2237.4026.4762.5968.7279a3.1381,3.1381,0,0,1,.8773,2.142,1.7317,1.7317,0,0,1-.3558.8864,1.61,1.61,0,0,1,1.03-.2277,2.7146,2.7146,0,0,1,1.7452,1.1217l.1734.1934a11.7439,11.7439,0,0,0,2.75,2.3449,2.4363,2.4363,0,0,0,.4726.1677C172.9863,140.4219,172.5328,140.83,171.6289,141.65ZM164.7,137.0571a6.0754,6.0754,0,0,0,.5607-.54.8685.8685,0,0,0,.2154-.9807,3.7389,3.7389,0,0,0-.691-.9132,8.4739,8.4739,0,0,0-.6221-.6236,1.1709,1.1709,0,0,0-1.0159-.32,3.6359,3.6359,0,0,0-.92.6776c-.28.2566-.42.385-.7.6423l2.4789,2.694C164.2837,137.4388,164.4224,137.3115,164.7,137.0571Z" />
                                    <path className="st4" d="M180.13,134.1531l-.8073-.9416a3.0311,3.0311,0,0,1,.0965.576,2.9116,2.9116,0,0,1-1.0589,2.1832,3.9164,3.9164,0,0,1-1.8067.8594,2.41,2.41,0,0,1-1.0455-.0938c-1.0328-.2907-1.7276-.9843-3.0795-2.4858-.4507-.5-.9023-.9915-1.3391-1.4864-.6621-.75-1.3247-1.5151-1.9393-2.29a5.3826,5.3826,0,0,1-1.3358-2.6089c-.18-1.8661,1.5314-3.3536,1.8146-3.5982a5.3527,5.3527,0,0,1,.823-.6034,3.6865,3.6865,0,0,1,3.0588-.3763,6,6,0,0,1,1.79,1.3142c.21.2136.4035.4446.6261.6758-.8774.7428-1.3149,1.1158-2.1872,1.8648l-.3909-.4553a4.1048,4.1048,0,0,0-1.0114-.9245,1.1893,1.1893,0,0,0-1.3772.236,1.2034,1.2034,0,0,0-.4266,1.0842c.0945.6777.7569,1.4362,1.7415,2.5677l2.3769,2.7318a5.4408,5.4408,0,0,0,1.7257,1.5214,1.2318,1.2318,0,0,0,1.1749-.2771,1.2174,1.2174,0,0,0,.4182-1.1527,5.2208,5.2208,0,0,0-1.2363-1.8122c-.1565-.1822-.3106-.3929-.48-.59-.4568.3923-.6849.5889-1.14.9827l-1.492-1.725c1.2933-1.1186,1.9429-1.6746,3.2475-2.78l5.1075,6.03C181.237,133.2062,180.8675,133.5211,180.13,134.1531Z" />
                                    <path className="st4" d="M188.6781,127.0251c-1.1947-1.04-1.7931-1.5593-2.9916-2.5958-.7225.588-1.083.8831-1.8022,1.4752.7853,1.3746,1.1791,2.0611,1.9687,3.4329-.9217.7613-1.3811,1.1436-2.2973,1.9117-2.6992-5.13-4.0309-7.7074-6.6581-12.8845,1.14-.941,1.7116-1.409,2.8594-2.34,4.5225,3.6261,6.7693,5.454,11.2332,9.1393C190.0627,125.905,189.6,126.2773,188.6781,127.0251Zm-7.2776-6.4313c-.5353-.4926-1.0711-.9848-1.5748-1.5032.4082.5966.7848,1.2191,1.1618,1.8412.6857,1.1865,1.0293,1.7793,1.718,2.9637.4971-.4084.7461-.6122,1.2447-1.0187C182.9316,121.9623,182.4216,121.5058,181.4005,120.5938Z" />
                                    <path className="st4" d="M197.384,120.1593c-2.65-1.7692-3.9823-2.6467-6.6588-4.3874-.3463-.2126-.6671-.4209-.9942-.6458-.5662-.3893-1.1027-.8146-1.6546-1.2213.36.328.6937.6528,1.0065.99.34.3669.6637.7512,1,1.1488,1.9375,2.4058,2.9059,3.6091,4.8421,6.0163-.886.69-1.3279,1.0369-2.2091,1.7332L183.96,112.7118c1.0877-.8594,1.6333-1.2869,2.728-2.1373,2.4767,1.5918,3.71,2.3938,6.1643,4.01.503.32,1.0181.66,1.5,1.0184.4657.3467.9505.7085,1.4152,1.0835-.3921-.38-.79-.76-1.1451-1.1646-.2228-.254-.4454-.5157-.6559-.7574-1.9181-2.4416-2.8775-3.6622-4.7967-6.1027.91-.696,1.3659-1.0425,2.2805-1.7323l8.5044,11.2755C198.923,118.9825,198.409,119.3734,197.384,120.1593Z" />
                                    <path className="st4" d="M202.9078,115.998c-3.3609-4.5412-5.0413-6.8115-8.4019-11.3521.9609-.7111,1.4426-1.065,2.4085-1.7694,3.3282,4.5644,4.9925,6.8466,8.3211,11.4115C204.302,114.9687,203.8364,115.3107,202.9078,115.998Z" />
                                    <path className="st4" d="M207.7205,112.49l-1.33-1.8527c-.5085-4.2645-.74-6.4055-1.1546-10.7033-1.536,1.0731-2.3009,1.6139-3.8246,2.7041l-1.42-1.9847c2.6264-1.879,3.9487-2.806,6.6112-4.6346.5209.7583.7814,1.1375,1.3023,1.896.3321,4.3343.5226,6.4936.9516,10.7949,1.6616-1.1733,2.4961-1.7549,4.1723-2.9077l1.3716,1.9945C211.71,109.6476,210.3734,110.5864,207.7205,112.49Z" />
                                    <path className="st4" d="M217.05,105.99l-7.9108-11.7c.9907-.67,1.4872-1.003,2.4828-1.6658L219.45,104.38C218.4876,105.0206,218.0077,105.3427,217.05,105.99Z" />
                                    <path className="st4" d="M227.5121,99.1479c-2.481-2.0006-3.7286-2.9943-6.2379-4.9688-.3259-.2429-.6268-.4793-.9322-.7326-.5289-.4387-1.025-.9106-1.5381-1.3653.3289.359.6321.7127.9133,1.0769.3057.396.5932.8079.8918,1.2341,1.7126,2.5708,2.5686,3.8565,4.28,6.4285-.9454.6079-1.4171.9136-2.3582,1.528-3.0885-4.7308-4.6326-7.0958-7.72-11.8258,1.1616-.7584,1.744-1.1351,2.9119-1.884,2.3237,1.8081,3.48,2.7178,5.7792,4.5481.4723.3638.9548.7489,1.4022,1.1493.4327.3873.883.7911,1.3122,1.2064-.3564-.4138-.7188-.8283-1.0356-1.263-.199-.273-.3971-.5537-.5849-.8134-1.69-2.6047-2.5355-3.9067-4.2266-6.51.97-.6117,1.4559-.916,2.43-1.5211,2.9806,4.7981,4.4711,7.1974,7.4524,11.9964C229.1526,98.1137,228.6049,98.457,227.5121,99.1479Z" />
                                    <path className="st4" d="M237.8573,92.8206l-.6313-1.0677a3.03,3.03,0,0,1-.0055.5844,2.9176,2.9176,0,0,1-1.4246,1.9673,3.9243,3.9243,0,0,1-1.93.5333,2.4115,2.4115,0,0,1-1.0138-.2738c-.967-.4656-1.53-1.2694-2.6011-2.9831-.3567-.5711-.7161-1.1332-1.06-1.6965-.5216-.8537-1.041-1.7223-1.5115-2.5926a5.3783,5.3783,0,0,1-.8614-2.8017c.1476-1.8694,2.0941-3.0381,2.4158-3.23a5.38,5.38,0,0,1,.9166-.4519,3.69,3.69,0,0,1,3.08.1594,5.98,5.98,0,0,1,1.5356,1.6046c.1695.2469.32.508.4991.7744-.9948.58-1.4912.8717-2.4818,1.4584l-.3058-.5163a4.1012,4.1012,0,0,0-.8354-1.0861,1.191,1.191,0,0,0-1.3985-.0061,1.2049,1.2049,0,0,0-.61.9941c-.0252.6841.4953,1.5461,1.2682,2.8316l1.8658,3.1034a5.433,5.433,0,0,0,1.4352,1.798,1.2338,1.2338,0,0,0,1.2063-.0691,1.22,1.22,0,0,0,.6134-1.0633,5.2134,5.2134,0,0,0-.9023-2c-.1224-.2067-.2375-.441-.37-.6645-.5188.3072-.7779.4614-1.2954.77L232.2855,86.94c1.47-.8779,2.2072-1.3131,3.6865-2.176l3.9816,6.826C239.1135,92.08,238.6943,92.3257,237.8573,92.8206Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st4" d="M160.46,146.35l0.97,1.21l0.49,1.03l0.17,0.87l-0.1,0.83l-0.68,1.52l-0.7,0.74l-1.15,0.94l-1.4,0.45l-0.91,0
			l-1.13-0.41l-0.56-0.43l-3.31-3.13l-1.55-1.7l-0.75-0.89l-0.59-0.93l-0.26-1l0.06-0.99l0.46-1.04l1.05-1.34l0.97-0.78l0.92-0.43
			l1.43-0.12l1.14,0.39l0.59,0.37l2.61,2.5L160.46,146.35z M154.68,144.69l-0.81-0.75l-0.7-0.38l-0.58-0.07l-0.59,0.27l-0.34,0.62
			l0.26,0.95l0.58,0.75l1.43,1.54l2.22,2.24l0.81,0.78l0.88,0.59l0.77,0l0.43-0.26l0.29-0.57l-0.02-0.63l-0.69-0.92l-1.18-1.28
			L154.68,144.69z"/>
                                <path className="st4" d="M169.12,144.11l-0.5-0.21l-1.45-1.21l-0.55-0.6l-0.95-0.94l-0.34-0.23l-0.59-0.16l-0.46,0.19l-0.37,0.26
			l-0.79,0.74l3.85,4.19l-2.16,2.03l-9.54-10.38l3.91-3.67l0.85-0.58l0.69-0.24l0.8-0.06l1.15,0.38l1.35,1.14l0.6,0.72l0.68,1.01
			l0.2,1.15l-0.31,0.86l1-0.23l1,0.37l0.75,0.72l0.17,0.19l1.61,1.51l1.17,0.79l0.48,0.16L169.12,144.11z M162.75,139.05l0.2-0.38
			l-0.01-0.62l-0.7-0.91l-0.62-0.62l-0.39-0.21l-0.62-0.1l-0.9,0.68l-0.68,0.64l2.47,2.69L162.75,139.05z"/>
                                <path className="st4" d="M176.4,137.52l-0.79-0.93l0.07,0.58l-0.19,1.02l-0.29,0.63l-0.58,0.57l-0.65,0.52l-1.16,0.37l-1.05-0.08
			l-1.44-0.75l-1.63-1.74l-1.33-1.49l-1.93-2.3l-0.87-1.21l-0.47-1.4l0.14-1.33l0.54-1.07l1.05-1.18l0.8-0.6l1.13-0.47l0.9-0.11
			l0.97,0.18l0.79,0.44l1.01,0.84l0.64,0.67l-2.14,1.91l-0.39-0.45l-1-0.93l-0.58-0.07l-0.78,0.32l-0.3,0.4l-0.1,0.68l0.57,1.12
			l1.18,1.44l2.38,2.73l0.81,0.86l0.92,0.65l0.61,0.02l0.58-0.3l0.34-0.54l0.06-0.64l-0.56-0.95l-0.69-0.86l-0.5-0.57l-1.11,0.99
			l-1.5-1.72l3.17-2.83l5.19,5.95L176.4,137.52z"/>
                                <path className="st4" d="M183.99,130.97l-3.02-2.55l-1.78,1.51l1.98,3.43l-2.31,1.96l-6.68-12.9l2.76-2.34l11.34,8.94L183.99,130.97z
			 M176.63,124.62l-1.54-1.47l1.17,1.82l1.74,2.95l1.22-1.04L176.63,124.62z"/>
                                <path className="st4" d="M191.7,124.6l-6.67-4.23l-1.01-0.7l-1.62-1.16l0.98,0.95l1.02,1.16l4.84,5.98l-2.19,1.78l-8.77-11.09
			l2.67-2.12l6.13,3.94l1.53,1l1.4,1.05l-1.15-1.13l-0.67-0.75l-4.88-6.03l2.18-1.76l8.74,11.08L191.7,124.6z"/>
                                <path className="st4" d="M195.92,121.33l-8.59-11.21l2.3-1.8l8.59,11.21L195.92,121.33z" />
                                <path className="st4" d="M197.72,107.32l-0.17-0.2l-0.56-0.75l-0.83-0.39l-0.6,0.17l-0.26,0.29l-0.23,0.49l0.05,0.46l0.29,0.51
			l0.4,0.45l0.49,0.19l1.55,0.15l1.49-0.05l1.26,0.02l1.53,0.36l0.85,0.56l0.85,0.94l0.71,1.27l0.28,1.4l-0.35,1.25l-0.94,1.33
			l-0.82,0.6l-1.23,0.75l-1.03,0.24l-1.09-0.17l-0.84-0.4l-0.68-0.64l-1.1-1.47l2.26-1.66l0.71,0.99l0.63,0.63l0.57,0.13l0.7-0.24
			l0.44-0.57l0.05-0.7l-0.43-0.78l-0.62-0.69l-0.77-0.28l-1.19-0.02l-1.55,0.07l-1.13-0.01l-1.2-0.18l-0.81-0.47l-0.96-1.06
			l-0.59-1.03l-0.28-1.16l0.05-0.83l0.36-0.89l0.52-0.7l1-0.86l1.6-0.8l1.14-0.09l0.86,0.33l1.04,0.85l0.8,1.05L197.72,107.32z"/>
                                <path className="st4" d="M207.3,112.77l-8.12-11.55l2.37-1.7l8.12,11.55L207.3,112.77z" />
                                <path className="st4" d="M211.37,109.85l-7.93-11.7l5.55-3.85l1.44,2.12l-3.15,2.18l1.71,2.52l2.82-1.95l1.4,2.07l-2.82,1.95
			l1.93,2.85l3.15-2.18l1.45,2.14L211.37,109.85z"/>
                                <path className="st4" d="M223.22,101.82l-0.46-0.3l-1.22-1.45l-0.44-0.69l-0.78-1.09l-0.3-0.28l-0.55-0.26l-0.49,0.1l-0.4,0.2
			l-0.9,0.59l3.09,4.79l-2.47,1.63l-7.66-11.88l4.47-2.95l0.93-0.42l0.72-0.12l0.8,0.07l1.07,0.57l1.14,1.36l0.48,0.81l0.5,1.11
			l0.01,1.17l-0.45,0.8l1.03-0.05l0.93,0.54l0.62,0.84l0.14,0.22l1.33,1.76l1.02,0.98l0.44,0.24L223.22,101.82z M217.8,95.74
			l0.26-0.34l0.09-0.61l-0.53-1.01l-0.51-0.72l-0.35-0.27l-0.59-0.21l-1,0.51l-0.78,0.51l1.99,3.08L217.8,95.74z"/>
                                <path className="st4" d="M226.69,99.57l-7.39-12.05l5.72-3.59l1.34,2.19l-3.25,2.04l1.59,2.59l2.9-1.82l1.31,2.13l-2.9,1.82l1.8,2.94
			l3.25-2.04l1.35,2.2L226.69,99.57z"/>
                                <path className="st4" d="M238.93,92.03l-6-5.14l-0.91-0.84l-1.43-1.38l0.84,1.08l0.84,1.29l3.95,6.62l-2.41,1.46l-7.12-12.23
			l2.94-1.72l5.51,4.78l1.38,1.21l1.24,1.24l-0.98-1.28l-0.55-0.83l-3.98-6.67l2.41-1.43l7.09,12.23L238.93,92.03z"/>
                            </g>}
                        </g>
                        <g id="Segment_-_1" className={"model-option" + (this.state.changed ? this.setStyles(3) : '')}>
                            <path id="Circle_-_2-2_4_" className="st0" d="M303.21,336.63L161.82,233.88c-38.69,53.6-62.08,118.77-62.08,189.92
		c0,0.46,0.02,0.93,0.02,1.39h174.83C274.58,391.78,285.23,361.68,303.21,336.63z"/>
                            <path id="Circle_-_2-1_6_" className="st6" d="M303.21,336.63L161.82,233.88c-38.69,53.6-62.08,118.77-62.08,189.92
		c0,0.46,0.02,0.93,0.02,1.39h174.83C274.58,391.78,285.23,361.68,303.21,336.63z"/>
                            <path id="Circle_-_1" className="st7" d="M161.82,233.88l-80.23-58.61C30.54,245.43,0.5,331.79,0.5,425.2h99.26
		c0-0.46-0.02-0.93-0.02-1.39C99.74,352.65,123.13,287.48,161.82,233.88z"/>
                            {SelectedLanguage === "en-GB" && <>
                                <g>
                                    <path className="st4" d="M52.3353,353.1842,38.4008,350.883c.3315-2.0073.5042-3.01.8634-5.0112a3.4646,3.4646,0,0,1,2.034-2.6578,5.4993,5.4993,0,0,1,2.9878.0236,10.4083,10.4083,0,0,1,1.3793.3505,3.18,3.18,0,0,1,2.5032,2.6213,7.0065,7.0065,0,0,1-.1939,2.44c-.0437.2484-.0655.3727-.1088.6212l4.986.8684C52.64,351.3562,52.5366,351.9652,52.3353,353.1842Zm-6.7929-5.14a1.4055,1.4055,0,0,0-.0122-.8472c-.1473-.4273-.6868-.7032-1.533-.8559a8.1256,8.1256,0,0,0-1.2914-.1657,1.3418,1.3418,0,0,0-.7873.1956,1.4727,1.4727,0,0,0-.45.9317c-.0586.3316-.0877.4975-.1454.8292l4.08.7105C45.458,348.5226,45.486,348.3629,45.5424,348.0437Z" />
                                    <path className="st4" d="M55.57,336.1969a5.2367,5.2367,0,0,1-.5234.15,5.37,5.37,0,0,1-1.9122-.1466l-.8021-.172a9.9362,9.9362,0,0,0-1.3242-.2174,1.32,1.32,0,0,0-.9949.2539,1.459,1.459,0,0,0-.4377.9085c-.0948.4516-.1419.6775-.2353,1.1294l5.5831,1.1541c-.2533,1.2254-.3772,1.8387-.62,3.0666l-13.8555-2.7361c.4077-2.0641.6188-3.0946,1.0558-5.1519.0417-.1963.0936-.4168.15-.6059a3.9644,3.9644,0,0,1,1.4587-2.2782,3.6974,3.6974,0,0,1,2.9452-.2506c.2931.0656.6044.1549.9081.2485a3.0183,3.0183,0,0,1,1.9471,1.2865,1.9155,1.9155,0,0,1,.2134.9866,1.7607,1.7607,0,0,1,.7188-.8467,2.5876,2.5876,0,0,1,2.08-.1389l.2536.0567a11.0639,11.0639,0,0,0,3.6074.2785,2.4054,2.4054,0,0,0,.4857-.1538C55.9856,334.2882,55.8452,334.924,55.57,336.1969Zm-8.3359.4415a7.0277,7.0277,0,0,0,.1522-.8191.9333.9333,0,0,0-.3843-.979,3.5053,3.5053,0,0,0-1.0921-.347,7.9183,7.9183,0,0,0-.87-.1417,1.1514,1.1514,0,0,0-1.02.3692,4.1594,4.1594,0,0,0-.3693,1.1664c-.0835.3985-.125.5978-.2074.9966l3.5853.7411C47.1106,337.23,47.1517,337.0328,47.2344,336.6384Z" />
                                    <path className="st4" d="M54.8083,320.7394c2.2009.6651,2.9884,1.4262,3.3279,2.6381a5.5868,5.5868,0,0,1-.0359,2.773,5.36,5.36,0,0,1-1.4744,2.6846,2.8026,2.8026,0,0,1-.7813.5209,4.2585,4.2585,0,0,1-1.8908.2638,32.5357,32.5357,0,0,1-4.4858-.8476c-.74-.1728-1.5074-.3646-2.2305-.5658a8.8919,8.8919,0,0,1-1.11-.35,3.3242,3.3242,0,0,1-2.3468-3.3612,6.6874,6.6874,0,0,1,.2107-1.8744,4.4524,4.4524,0,0,1,2.2444-3.1718,3.7049,3.7049,0,0,1,1.8927-.2879,27.998,27.998,0,0,1,3.5488.741C52.7178,320.1656,53.7744,320.4269,54.8083,320.7394Zm-3.8154,2.3277c-.6986-.1752-1.3907-.3374-2.0569-.5157a5.6779,5.6779,0,0,0-1.0832-.2033c-.1553-.039-1.438-.2365-1.7177.9392a1.1594,1.1594,0,0,0,.02.7573c.2518.6538,1.0539.8632,1.6028,1.0267.6445.192,1.331.3683,2.0309.5381,1.03.25,2.0651.4748,3.0818.7029a5.2026,5.2026,0,0,0,2.1655.2369,1.2484,1.2484,0,0,0,.5273-2.1364,10.7348,10.7348,0,0,0-2.7468-.8884Z" />
                                    <path className="st4" d="M60.2282,317.1983c-5.7786-.2917-8.6719-.4127-14.4655-.6046.34-1.2824.5132-1.9228.865-3.202,2.98.2378,4.47.3622,7.4458.6222.5437.0611,1.069.1186,1.5863.1945.4977.073,1.0061.1914,1.4993.2847-.5335-.172-1.0725-.3792-1.5773-.6-.3627-.1587-.7541-.3469-1.1218-.5183-2.7552-1.3457-4.1307-2.0239-6.8775-3.3912.3666-1.2844.5528-1.9257.931-3.2067,5.0221,2.94,7.5441,4.3856,12.6088,7.2263C60.7586,315.28,60.58,315.9185,60.2282,317.1983Z" />
                                    <path className="st4" d="M62.7318,308.5234l-13.518-4.0895c.3712-1.2273.56-1.84.9415-3.0641l13.4818,4.2075C63.27,306.7542,63.0887,307.3434,62.7318,308.5234Z" />
                                    <path className="st4" d="M51.3966,297.4693c.5357-1.6516.8084-2.4758,1.3637-4.1209a5.2607,5.2607,0,0,1,1.8207-2.785,4.5428,4.5428,0,0,1,3.7377-.1457c.7435.2048,1.4824.4281,2.2185.6871.9811.3452,1.9674.74,2.9338,1.1242a4.4254,4.4254,0,0,1,2.6127,1.9207c.7137,1.2949.41,2.5461-.0034,3.8971-.5085,1.5093-.7584,2.2653-1.25,3.78Zm12.3693-.1339a1.2055,1.2055,0,0,0-.3078-1.585,18.2292,18.2292,0,0,0-3.0419-1.2445c-.9842-.3363-1.9728-.6413-2.9364-.9336-.87-.2639-1.3794-.4158-1.8083-.1509a1.7523,1.7523,0,0,0-.6646.9649c-.1158.3437-.1734.5157-.2883.86l8.7664,2.9273C63.5969,297.838,63.6531,297.67,63.7659,297.3354Z" />
                                    <path className="st4" d="M68.6993,290.5527c-5.3116-1.9235-7.9674-2.8854-13.2789-4.809.4367-1.2059.6578-1.808,1.1051-3.01l13.2364,4.9249C69.3318,288.8143,69.1193,289.3932,68.6993,290.5527Z" />
                                    <path className="st4" d="M73.4776,278.0993c-3.2076-.1173-4.8139-.1643-8.031-.235-.41.0023-.7955-.006-1.1946-.0254-.69-.0337-1.3734-.1165-2.06-.1737.4788.094.9332.2,1.3751.3286.4805.14.955.3055,1.4468.4758,2.8927,1.0838,4.3389,1.6263,7.2308,2.7122-.44,1.1157-.658,1.6745-1.0888,2.7938L57.975,278.9032c.5344-1.3886.8051-2.0815,1.3536-3.4649,2.9685.0461,4.4509.0791,7.4117.1647.6012.0075,1.2233.0265,1.8242.0809.5813.0527,1.1869.1083,1.7813.1873-.5327-.124-1.0712-.2447-1.5825-.4113-.3212-.1047-.6463-.2164-.9507-.317-2.8916-1.1312-4.3376-1.6964-7.23-2.8259.4638-1.1391.6981-1.7079,1.1715-2.8433L74.79,274.9092C74.2585,276.1828,73.9961,276.8208,73.4776,278.0993Z" />
                                    <path className="st4" d="M78.5605,266.1976l-1.1314-.5079a2.9864,2.9864,0,0,1,.3241.4991,3.2089,3.2089,0,0,1-.0881,2.5662,4.4189,4.4189,0,0,1-1.318,1.6705,2.4969,2.4969,0,0,1-1.0044.4087c-1.0735.2169-2.0014-.108-3.8662-.8824-.6214-.2581-1.2409-.5072-1.8478-.7669-.92-.3935-1.8472-.8012-2.7327-1.2409a5.0959,5.0959,0,0,1-2.305-1.8322c-.9311-1.6824.0484-3.9132.2113-4.2806a6.15,6.15,0,0,1,.5178-.9659,4,4,0,0,1,2.6861-1.8155,5.6554,5.6554,0,0,1,2.1951.3942c.2812.1028.5548.23.8552.3432-.5139,1.1214-.7684,1.6831-1.2725,2.8085l-.5476-.2454a3.8506,3.8506,0,0,0-1.314-.3942,1.2784,1.2784,0,0,0-1.1806.881,1.3243,1.3243,0,0,0,.0468,1.2316c.3639.597,1.2874,1,2.6613,1.6016l3.3167,1.4533a5.1149,5.1149,0,0,0,2.2188.6183,1.3459,1.3459,0,0,0,.9741-.82,1.3246,1.3246,0,0,0-.0832-1.2891,4.9047,4.9047,0,0,0-1.8838-1.1279c-.2192-.0982-.4478-.2244-.6849-.3306-.2636.5885-.3948.883-.6557,1.4727l-2.0857-.923c.7416-1.6758,1.1178-2.5116,1.8811-4.1786l7.1847,3.2893C79.2,264.7782,78.9856,265.2509,78.5605,266.1976Z" />
                                    <path className="st4" d="M70.7628,350.4047c2.245.4959,3.0922,1.1645,3.5318,2.2829a4.9813,4.9813,0,0,1,.1966,2.6064,4.803,4.803,0,0,1-1.2432,2.609,2.7118,2.7118,0,0,1-.7342.5361,4.4325,4.4325,0,0,1-1.86.3615,34.5839,34.5839,0,0,1-4.5342-.5261c-.7511-.1177-1.53-.2515-2.2666-.3972a9.4388,9.4388,0,0,1-1.1333-.262,3.263,3.263,0,0,1-2.6136-3.0216,5.9584,5.9584,0,0,1,.054-1.7772,4.08,4.08,0,0,1,1.968-3.12,3.837,3.837,0,0,1,1.8584-.383,29.74,29.74,0,0,1,3.5918.4878C68.6358,349.9883,69.7081,350.1718,70.7628,350.4047Zm-3.6,2.4139c-.71-.1232-1.4111-.2346-2.0893-.3627a6.0308,6.0308,0,0,0-1.0945-.1268c-.1578-.0274-1.45-.1362-1.6307.9864a1.0446,1.0446,0,0,0,.0831.7114c.3049.6,1.12.7489,1.68.87.6577.142,1.355.2666,2.0655.3843,1.0458.1733,2.0931.3228,3.1248.4764a5.5151,5.5151,0,0,0,2.1743.0933,1.1409,1.1409,0,0,0,.3462-2.0386,11.41,11.41,0,0,0-2.8068-.672Z" />
                                    <path className="st4" d="M61.7072,345.5139c.3373-1.7532.5115-2.6286.8713-4.3767.038-.1843.08-.39.1426-.5908a3.1722,3.1722,0,0,1,.9925-1.6084c.7-.5989,1.6492-.819,3.3891-.4423a4.07,4.07,0,0,1,2.1355,1.01,1.69,1.69,0,0,1,.5864,1.28,2.1883,2.1883,0,0,1,.4491-.7642c.5607-.6138,1.5474-.8171,3.17-.4643a3.9483,3.9483,0,0,1,2.7141,1.456,3.5493,3.5493,0,0,1,.319,2.6571c-.3727,1.802-.5528,2.7044-.9,4.512Zm6.408-2.396c.1107-.5448.2119-1.1333-.3142-1.4533a5.4679,5.4679,0,0,0-1.3655-.41,1.6689,1.6689,0,0,0-.73-.0246c-.6229.1466-.8065.64-.9577,1.1823-.0663.3262-.0993.4894-.1649.8158l3.3736.6775C68.0194,343.59,68.0512,343.4328,68.1152,343.1179Zm5.8782,1.009c.1887-.725.1136-1.05-.1938-1.342a2.9746,2.9746,0,0,0-1.4735-.5762,2.2729,2.2729,0,0,0-1.2486.01c-.3978.1556-.5636.4469-.7188,1.2086-.0752.3689-.1125.5535-.1866.9226l3.6287.7287C73.8773,344.6981,73.9158,344.5077,73.9934,344.1269Z" />
                                    <path className="st4" d="M74.55,330.7774c1.5934.3906,3.4143.8419,3.8913,2.7124a3.9776,3.9776,0,0,1-.0108,1.9579,3.5046,3.5046,0,0,1-1.5489,2.3669,2.6736,2.6736,0,0,1-1.1169.4108,6.79,6.79,0,0,1-2.2922-.1993l-1.2107-.269c.2425-1.0916.366-1.6368.6176-2.7262l.76.1756a6.4207,6.4207,0,0,0,1.8595.3047.7461.7461,0,0,0,.5652-.5747.8435.8435,0,0,0-.0349-.4655c-.19-.4183-.6417-.5044-1.4206-.6882l-9.89-2.3342c.275-1.1653.415-1.7474.7-2.91Z" />
                                    <path className="st4" d="M80.0143,328.3993,66.335,324.8865c.6934-2.7,1.0537-4.0467,1.8014-6.7318l2.4668.6869c-.4212,1.5126-.6275,2.27-1.0314,3.7876l2.9383.782c.3579-1.345.54-2.0165.912-3.3576l2.41.6679c-.3689,1.3312-.55,1.9977-.9053,3.3328l3.3252.8849c.3932-1.4777.5941-2.2154,1.0042-3.6883l2.486.6922C81.0249,324.5183,80.6793,325.81,80.0143,328.3993Z" />
                                    <path className="st4" d="M73.9274,317.01c-.36-.1247-.7317-.2424-1.0653-.3486-.278-.0885-.59-.191-.8733-.2938a3.6277,3.6277,0,0,1-2.11-1.7789,3.1687,3.1687,0,0,1-.2292-.7851,5.6732,5.6732,0,0,1,.5484-3.2419,4.4393,4.4393,0,0,1,1.6635-1.8072c1.0235-.5321,2.1121-.3221,4.2308.3415-.34,1.0858-.5079,1.63-.8389,2.7181-.3521-.1069-.5281-.1605-.88-.2675a3.376,3.376,0,0,0-1.6492-.2842,1.1332,1.1332,0,0,0-.8054.8,1.0733,1.0733,0,0,0,.02.7007c.21.6265.8223.7427,1.8713,1.0752.2435.0772.5182.1537.7614.2473l3.6249,1.0746c.5235.1339,1.0363.3057,1.54.4354a4.642,4.642,0,0,0,1.0125.1735,1.0712,1.0712,0,0,0,.62-1.9258,8.8287,8.8287,0,0,0-2.001-.7776l-.44-.1338c.3273-1.0764.4932-1.6139.8294-2.6875,1.8388.5546,2.6982.8888,3.3813,1.5871,1.3729,1.3781.7435,3.4453.611,3.8869a4.3447,4.3447,0,0,1-1.7951,2.549,2.4583,2.4583,0,0,1-.9194.3,9.8663,9.8663,0,0,1-3.6473-.564Z" />
                                    <path className="st4" d="M85.9127,308.137l-11.1262-3.6277c-.2988.9165-.4466,1.3753-.739,2.2939l-2.3064-.7341c.9519-2.9907,1.4449-4.4808,2.4647-7.4494l2.2891.7864c-.3105.9039-.4642,1.3564-.7684,2.2624l11.094,3.7248C86.4526,306.4893,86.271,307.038,85.9127,308.137Z" />
                                    <path className="st4" d="M88.1469,301.5224l-13.332-4.6605c.3979-1.138.5993-1.7062,1.0071-2.8408l13.2907,4.7769C88.7216,299.8861,88.5284,300.431,88.1469,301.5224Z" />
                                    <path className="st4" d="M90.8878,293.9769c-5.6995-.8961-8.5558-1.32-14.28-2.1186.43-1.171.6473-1.7556,1.0876-2.9229,2.9339.5429,4.3994.82,7.3272,1.3846.5338.1159,1.05.2266,1.5563.3536.4875.1222.9823.2885,1.4638.4293-.5158-.2192-1.0338-.4717-1.5185-.7344-.348-.1886-.7217-.4075-1.0735-.6084-2.6312-1.563-3.9443-2.35-6.5653-3.9336.4548-1.1712.6849-1.7558,1.15-2.9229,4.76,3.3144,7.1531,4.9477,11.9641,8.166C91.5493,292.2305,91.327,292.812,90.8878,293.9769Z" />
                                    <path className="st4" d="M93.9779,286.0888l-13.0828-5.32c1.0508-2.5843,1.5894-3.8716,2.6928-6.4355l2.352,1.0122c-.6216,1.4444-.9282,2.1682-1.533,3.6191l2.8065,1.17c.536-1.286.8073-1.9276,1.3567-3.2083l2.298.9857c-.5453,1.2712-.8147,1.9082-1.3467,3.1846l3.176,1.3239c.5889-1.4129.8875-2.1177,1.4928-3.5241l2.37,1.02C95.5023,282.3757,94.9857,283.61,93.9779,286.0888Z" />
                                    <path className="st4" d="M90.4355,268.9633l-.2366-.1082a8.5432,8.5432,0,0,0-.8722-.353.9955.9955,0,0,0-1.3687.5436,1.0826,1.0826,0,0,0-.0686.93,1.0289,1.0289,0,0,0,.2891.3808,2.3719,2.3719,0,0,0,.5036.2947c.7848.3525,1.2662.2029,2.5258-.5853.5184-.3086.7778-.4621,1.2968-.7678a7.3862,7.3862,0,0,1,1.1114-.6435,3.9444,3.9444,0,0,1,3.7572.0355,4.6448,4.6448,0,0,1,2.1477,1.8307,2.7127,2.7127,0,0,1,.32,1.26,5.5255,5.5255,0,0,1-.5815,2.5209,4.05,4.05,0,0,1-2.5038,2.365,6.2356,6.2356,0,0,1-3.5471-.83c.4523-1.03.6807-1.5448,1.1418-2.5717.3653.164.7547.3332,1.1215.4813.7148.2885,1.0436.3134,1.4037.0981a1.0648,1.0648,0,0,0,.5007-.573,1.1241,1.1241,0,0,0-.2035-1.3388,2.5612,2.5612,0,0,0-.7578-.4586,3.1753,3.1753,0,0,0-.8793-.2905c-.5322-.0865-.9937.15-1.8668.7215-.5293.3355-.7938.504-1.3223.8429-.3267.1906-.6617.4011-.9976.5664a3.72,3.72,0,0,1-3.4569.0526,5.0733,5.0733,0,0,1-1.0306-.5893,3.253,3.253,0,0,1-1.3038-2.5877,5.2673,5.2673,0,0,1,.5837-2.1747,4.007,4.007,0,0,1,1.99-2.1763,2.678,2.678,0,0,1,.9369-.1644,5.8962,5.8962,0,0,1,2.5419.75C91.1356,267.4377,90.9009,267.9457,90.4355,268.9633Z" />
                                </g>
                            </>}
                            {SelectedLanguage === "de-DE" && <>
                            <g>
                                <path className="st4" d="M68.48,338.3l-0.26-0.05l-0.91-0.22l-0.88,0.24l-0.35,0.52l-0.01,0.4l0.14,0.52l0.33,0.32l0.55,0.21
			l0.59,0.09l0.49-0.17l1.28-0.9l1.11-1.01l0.98-0.81l1.4-0.72l1.02-0.12l1.25,0.18l1.35,0.52l1.12,0.91l0.53,1.2l0.14,1.65l-0.24,1
			l-0.46,1.39l-0.64,0.85l-0.94,0.58l-0.9,0.23l-0.93-0.05l-1.79-0.42l0.66-2.75l1.18,0.31l0.88,0.08l0.52-0.27l0.38-0.64
			l-0.03-0.73l-0.42-0.57l-0.83-0.32l-0.91-0.13l-0.77,0.29l-0.92,0.76l-1.14,1.06l-0.88,0.73l-1.04,0.64l-0.92,0.16l-1.41-0.2
			l-1.11-0.42l-0.96-0.71l-0.49-0.68l-0.29-0.92l-0.05-0.88l0.21-1.32l0.71-1.66l0.81-0.81l0.87-0.3l1.34-0.01l1.29,0.29
			L68.48,338.3z"/>
                                <path className="st4" d="M77.33,327.12l1.44,0.57l0.94,0.66l0.58,0.68l0.33,0.78l0.17,1.67l-0.23,1l-0.52,1.41l-0.98,1.1l-0.79,0.46
			l-1.18,0.22l-0.7-0.1l-4.43-1.07l-2.19-0.7l-1.09-0.4l-0.98-0.52l-0.73-0.75l-0.44-0.9l-0.12-1.15l0.23-1.7l0.44-1.17l0.58-0.84
			l1.18-0.83l1.18-0.23l0.69,0.03l3.51,0.87L77.33,327.12z M71.51,328.6l-1.07-0.25l-0.79,0.03l-0.54,0.23l-0.37,0.53l0.02,0.71
			l0.7,0.69l0.88,0.36l2,0.62l3.05,0.84l1.09,0.27l1.06,0.07l0.66-0.38l0.24-0.44l-0.03-0.65l-0.33-0.54l-1.06-0.45l-1.66-0.52
			L71.51,328.6z"/>
                                <path className="st4" d="M83.7,320.83l-0.54,0.06l-1.86-0.34l-0.78-0.25l-1.29-0.35l-0.41-0.02l-0.59,0.16l-0.31,0.4l-0.19,0.41
			l-0.31,1.04l5.43,1.74l-0.86,2.87l-13.45-4.3l1.55-5.18l0.45-0.93l0.48-0.56l0.66-0.46l1.18-0.25l1.74,0.32l0.88,0.33l1.09,0.54
			l0.75,0.91l0.17,0.91l0.75-0.71l1.06-0.18l1.01,0.26l0.25,0.08l2.14,0.51l1.41,0.11l0.49-0.1L83.7,320.83z M75.66,319.59
			l-0.02-0.44l-0.32-0.53l-1.06-0.45l-0.85-0.23l-0.44,0.01l-0.59,0.22l-0.44,1.04l-0.27,0.9l3.49,1.12L75.66,319.59z"/>
                                <path className="st4" d="M86.74,311.43l-1.14-0.44l0.35,0.47l0.34,0.99l0.06,0.7l-0.23,0.79l-0.31,0.78l-0.84,0.9l-0.95,0.44
			l-1.62,0.04l-2.26-0.73l-1.89-0.66l-2.8-1.08l-1.34-0.64L73,311.97l-0.53-1.24l-0.05-1.22l0.34-1.55l0.4-0.92l0.76-0.97l0.73-0.54
			l0.93-0.32l0.91,0l1.29,0.25l0.88,0.28l-0.94,2.73l-0.56-0.21l-1.33-0.33l-0.54,0.22l-0.52,0.66l-0.07,0.5l0.24,0.65l1.05,0.71
			l1.73,0.69l3.4,1.24l1.12,0.36l1.12,0.12l0.54-0.28l0.36-0.55l0.03-0.64l-0.26-0.6l-0.95-0.56l-1.02-0.41l-0.71-0.26l-0.49,1.41
			l-2.14-0.78l1.39-4.05l7.43,2.7L86.74,311.43z"/>
                                <path className="st4" d="M88.16,307.2l-13.21-5.02l2.31-6.42l2.4,0.91l-1.31,3.64l2.84,1.08l1.17-3.26l2.34,0.89l-1.17,3.26l3.22,1.22
			l1.31-3.64l2.41,0.92L88.16,307.2z"/>
                                <path className="st4" d="M93.19,293.58l-7.89-0.39l-1.23-0.11l-1.98-0.22l1.33,0.35l1.46,0.51l7.17,2.84l-1.02,2.66l-13.1-5.37
			l1.27-3.19l7.28,0.41l1.83,0.11l1.74,0.23l-1.56-0.42l-0.95-0.32l-7.22-2.86l1.03-2.63l13.08,5.38L93.19,293.58z"/>
                            </g>
                            <g>
                                <path className="st4" d="M53.19,346.11l-13.77-3.08l1.38-6.58l2.44,0.54l-0.77,3.7l2.93,0.71l0.72-3.42l2.38,0.53l-0.72,3.42
			l6.01,1.33L53.19,346.11z"/>
                                <path className="st4" d="M40.62,330.79l-0.47,2.02l-2.27-0.56l0.47-2.02L40.62,330.79z M41.46,327.19l-0.48,2.04l-2.27-0.56l0.48-2.04
			L41.46,327.19z M51.96,328.31l2.17,0.66l1.23,0.8l0.77,1.19l0.21,1.22l-0.23,1.6l-0.28,1.02L55,336.1l-1.03,0.81l-1.12,0.25
			l-1.2-0.04l-1.18-0.31l-9.24-2.27l0.66-2.81l8.21,2.02l1.85,0.41l1.15,0.09l0.5-0.28l0.38-0.76l-0.02-0.63l-0.17-0.42l-0.39-0.37
			l-1.52-0.52l-1.19-0.29L42.52,329l0.67-2.85L51.96,328.31z"/>
                                <path className="st4" d="M59,321.39l-0.54,0.08l-1.88-0.26l-0.79-0.22l-1.31-0.3l-0.41-0.01l-0.58,0.18l-0.29,0.4l-0.17,0.42
			l-0.27,1.04l5.5,1.51l-0.75,2.87l-13.61-3.74l1.36-5.18l0.41-0.94l0.46-0.57l0.64-0.48l1.17-0.29l1.75,0.25l0.9,0.29l1.11,0.49
			l0.79,0.87l0.2,0.9l0.73-0.73l1.05-0.22l1.02,0.22l0.25,0.07l2.16,0.42l1.41,0.05l0.49-0.12L59,321.39z M50.91,320.47l-0.03-0.43
			l-0.34-0.51l-1.07-0.4l-0.86-0.19l-0.44,0.03l-0.58,0.24l-0.4,1.05l-0.24,0.9l3.53,0.97L50.91,320.47z"/>
                                <path className="st4" d="M61.63,312.16l-2.18-0.66l-7.53-7.74l-1.29,4.42l-2.34-0.71l2.21-7.58l2.2,0.67l7.49,7.87l1.44-4.91l2.31,0.7
			L61.63,312.16z"/>
                                <path className="st4" d="M64.64,302.28l-13.38-4.53l0.91-2.78l13.38,4.53L64.64,302.28z" />
                                <path className="st4" d="M66.35,297.05l-13.3-4.76l2.2-6.38l2.41,0.86l-1.25,3.62l2.86,1.02l1.12-3.24l2.36,0.84l-1.12,3.24l3.24,1.16
			l1.25-3.62l2.43,0.87L66.35,297.05z"/>
                                <path className="st4" d="M69.28,288.66l-13.2-5.05l1-2.7l10.84,4.15l1.43-3.86l2.35,0.9L69.28,288.66z" />
                                <path className="st4" d="M72.4,280.33L59.31,275l2.48-6.28l2.37,0.97l-1.41,3.56l2.82,1.15l1.26-3.19l2.32,0.94l-1.26,3.19l3.19,1.3
			l1.41-3.56l2.39,0.97L72.4,280.33z"/>
                            </g>
                            </>}
                        </g>

                        <g id="Circle_-_3_1_" className={"model-circle" + (this.state.changed ? this.setStyles(17) : '')}>
                            <circle id="Circle_1-1_1_" className="st10" cx="425.2" cy="425.2" r="75.42" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st4" d="M369.0181,429.6449c-.04-.08-.08-.14-.12-.22a2.5643,2.5643,0,0,1-.26-1.1407v-3.22c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24v6.2207h-2.6006V415.5223h5.2012a2.6436,2.6436,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52v1.5205a3.54,3.54,0,0,1-.28,1.52,1.6793,1.6793,0,0,1-.3.38,2.6038,2.6038,0,0,1-1.24.5,2.85,2.85,0,0,1,.78.28,2.3407,2.3407,0,0,1,1.14,2.18v2.8808a3.2175,3.2175,0,0,0,.36,1.66.8564.8564,0,0,0,.24.22Zm-.42-10.9419c0-.54-.08-.72-.3-.94a1.2029,1.2029,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1939,1.1939,0,0,0,.98-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st4" d="M373.8965,429.6449V415.5223h6.8418v2.1407h-4.2412v3.3208h4.0009v2.14h-4.0009v4.34h4.4409v2.1807Z" />
                                    <path className="st4" d="M390.458,427.7845c-.32,1.1407-1.26,2.1612-3.6606,2.1612a4.573,4.573,0,0,1-2.9009-.8008,2.3973,2.3973,0,0,1-.74-.96,3.1163,3.1163,0,0,1-.28-1.46v-2.0606h2.58v1.64c0,.52-.04,1.12.46,1.44a1.434,1.434,0,0,0,.8.2,1.2678,1.2678,0,0,0,.9-.26c.38-.3.38-.9209.38-1.88a1.8027,1.8027,0,0,0-.88-1.86l-2.4605-1.66a3.28,3.28,0,0,1-1.18-1.1607,3.8708,3.8708,0,0,1-.5-2.24,4.751,4.751,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4046,2.4046,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6,0-1.16.22-1.16,1.4a2.6449,2.6449,0,0,0,.28,1.36,2.3359,2.3359,0,0,0,.54.46l2.5205,1.7407a5.2916,5.2916,0,0,1,1.1607,1.1,3.3628,3.3628,0,0,1,.56,2.44A7.9648,7.9648,0,0,1,390.458,427.7845Z" />
                                    <path className="st4" d="M400.2773,423.0638c-.42.52-1.02.7-2.3.7h-2.2007v5.8809h-2.6206V415.5223h5.2412a3.6727,3.6727,0,0,1,.7.06,1.9732,1.9732,0,0,1,1.38,1,5.15,5.15,0,0,1,.3,2.1206v1.86C400.7778,421.8837,400.7178,422.5238,400.2773,423.0638Zm-2.06-4.0205a1.7071,1.7071,0,0,0-.2-1.1406,1.1192,1.1192,0,0,0-.9605-.3h-1.28v4.1406h1.26c.54,0,.84-.06,1-.28a1.83,1.83,0,0,0,.18-.98Z" />
                                    <path className="st4" d="M410.0776,429.0052a4.34,4.34,0,0,1-3.061.94,4.518,4.518,0,0,1-2.94-.82,2.2922,2.2922,0,0,1-.7-.8809,3.8222,3.8222,0,0,1-.3-1.62V418.703a3.3978,3.3978,0,0,1,.88-2.56,4.5846,4.5846,0,0,1,3.0805-.92c2.04,0,3.1211.84,3.541,1.66a3.9059,3.9059,0,0,1,.32,1.88v7.8613A3.065,3.065,0,0,1,410.0776,429.0052Zm-1.8007-10.062a2.4823,2.4823,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0806-.62,1.436,1.436,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2381,2.2381,0,0,0,.24,1.3,1.3439,1.3439,0,0,0,1.06.42,1.5193,1.5193,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st4" d="M418.377,429.6449l-2.5-10.5815.22,10.5815h-2.4409V415.5223h3.3408l2.5405,10.3623-.26-10.3623h2.38v14.1226Z" />
                                    <path className="st4" d="M431.877,427.7845c-.32,1.1407-1.26,2.1612-3.66,2.1612a4.5744,4.5744,0,0,1-2.9014-.8008,2.4,2.4,0,0,1-.74-.96,3.1148,3.1148,0,0,1-.28-1.46v-2.0606h2.58v1.64c0,.52-.04,1.12.46,1.44a1.4363,1.4363,0,0,0,.8008.2,1.2662,1.2662,0,0,0,.8994-.26c.3809-.3.3809-.9209.3809-1.88a1.8019,1.8019,0,0,0-.8809-1.86l-2.46-1.66a3.28,3.28,0,0,1-1.18-1.1607,3.8708,3.8708,0,0,1-.5-2.24,4.7528,4.7528,0,0,1,.34-1.94c.8-1.72,3.22-1.72,3.5005-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.0205-1.16-1.0205-.6,0-1.16.22-1.16,1.4a2.6412,2.6412,0,0,0,.28,1.36,2.3258,2.3258,0,0,0,.54.46l2.52,1.7407a5.2927,5.2927,0,0,1,1.16,1.1,3.3631,3.3631,0,0,1,.5606,2.44A7.9372,7.9372,0,0,1,431.877,427.7845Z" />
                                    <path className="st4" d="M434.5752,429.6449V415.5223h2.581v14.1226Z" />
                                    <path className="st4" d="M447.7363,427.265a2.3351,2.3351,0,0,1-1.24,2.1,4.8547,4.8547,0,0,1-2,.28h-4.4609V415.5223h4.3808a3.4376,3.4376,0,0,1,2.34.54,2.59,2.59,0,0,1,.8408,2.3408v1.26a5.9524,5.9524,0,0,1-.08,1,1.671,1.671,0,0,1-1.56,1.38,2.0654,2.0654,0,0,1,1.4.68,2.8931,2.8931,0,0,1,.4,1.9v2C447.7568,426.8441,447.7363,427.0648,447.7363,427.265Zm-2.7207-8.4219a2.276,2.276,0,0,0-.06-.62c-.18-.66-.74-.68-1.28-.68h-1.04v3.5606h.94a4.9843,4.9843,0,0,0,.7-.04c.501-.1.74-.4.74-1.26Zm.1807,5.6812a1.9479,1.9479,0,0,0-.08-.78c-.2-.58-.8408-.6-1.36-.6h-1.12v4.461h1.1a3.5387,3.5387,0,0,0,.66-.04c.5606-.08.8008-.42.8008-1.38Z" />
                                    <path className="st4" d="M450.4941,429.6449V415.5223h2.5811v14.1226Z" />
                                    <path className="st4" d="M455.9541,429.6449V415.5223h2.6006v11.982h3.8213v2.1406Z" />
                                    <path className="st4" d="M464.334,429.6449V415.5223h2.581v14.1226Z" />
                                    <path className="st4" d="M474.0547,417.5629v12.082h-2.62v-12.082H468.834v-2.0406h7.8418v2.0406Z" />
                                    <path className="st4" d="M483.7148,425.4647v4.18h-2.66v-4.36l-2.8213-9.7622h2.7412l1.44,7.1216,1.38-7.1216h2.7412Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st4" d="M361.03,429.57l-2.92-14.08l2.98-0.04l1.26,7.38l0.22,1.54l0.1,1.54l0.14-1.7l0.18-1.2l1.26-7.54h3
			                        l-3.14,14.12L361.03,429.57z"/>
                                <path className="st4" d="M368.71,429.59v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H368.71z" />
                                <path className="st4" d="M383.29,429.59l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H383.29z M382.05,421.53l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			                        l-0.38-0.5l-1.1-0.12h-0.92v3.66H382.05z"/>
                                <path className="st4" d="M393.41,429.59l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H393.41z M391.83,419.99
			                        l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L391.83,419.99z"/>
                                <path className="st4" d="M403.75,429.55l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L403.75,429.55z"/>
                                <path className="st4" d="M410.73,429.59v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H410.73z" />
                                <path className="st4" d="M425.19,429.61l-1.24-7.24l-0.26-3.06l-0.2,2.2l-0.26,1.74l-1.18,6.34l-3.28,0.02l-2.46-14.14l2.8,0.02
			                        l1.32,8.56l0.1,1.1l0.04,1.26l0.12-1.92l0.28-1.96l1.26-7.04l3.1-0.02l1.14,7.38l0.22,2.2l0.04,1.36l0.18-2.24l1.3-8.7h2.68
			                        l-2.46,14.12L425.19,429.61z"/>
                                <path className="st4" d="M440.45,425.27l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			                        l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			                        l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L440.45,425.27z M437.49,420.03l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			                        l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			                        l0.04-1.74V420.03z"/>
                                <path className="st4" d="M448.59,429.59l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H448.59z M447.35,421.53l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			                        l-0.38-0.5l-1.1-0.12h-0.92v3.66H447.35z"/>
                                <path className="st4" d="M454.41,429.59v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H454.41z" />
                                <path className="st4" d="M469.45,424.51l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V424.51z"/>
                                <path className="st4" d="M477.79,429.55l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L477.79,429.55z"/>
                                <path className="st4" d="M489.11,429.59l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			                        l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			                        l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			                        l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H489.11z"/>
                            </g>
                            }
                        </g>
                        <g id="Circle_-_2_1_" className={"model-circle" + (this.state.changed ? this.setStyles(16) : '')}>
                            <path id="Circle_1_" className="st11" d="M425.2,312.57c-62.2,0-112.62,50.42-112.62,112.62S363,537.82,425.2,537.82
		                        S537.82,487.4,537.82,425.2S487.4,312.57,425.2,312.57z M425.2,500.62c-41.65,0-75.42-33.77-75.42-75.42
		                        c0-41.65,33.77-75.42,75.42-75.42c41.65,0,75.42,33.77,75.42,75.42C500.62,466.85,466.85,500.62,425.2,500.62z"/>
                            {SelectedLanguage === "en-GB" &&
                                <>
                                <g>
                                    <path className="st4" d="M336.9209,471.5385l12.2915-6.96,3.2827,5.7978-2.2285,1.2618-1.8633-3.29-2.6465,1.4981,1.6661,2.9424-2.1763,1.2324-1.666-2.9424-2.9942,1.6953,1.8628,3.29-2.2461,1.2715Z" />
                                    <path className="st4" d="M342.4648,480.8148l11.5069-8.1875,3.8535,5.3848-2.0332,1.4551-2.1655-3.0254-2.4908,1.709,2.0025,2.7978-1.9844,1.42-2.0029-2.7968-5.01,3.5859Z" />
                                    <path className="st4" d="M348.41,488.764l10.6372-9.288,4.3691,4.9746-1.8789,1.65-2.4551-2.7959-2.3091,1.9482,2.27,2.585-1.8335,1.61-2.27-2.585-4.6294,4.0655Z" />
                                    <path className="st4" d="M355.0962,496.08l9.62-10.3418,4.8779,4.5381-1.7441,1.875-2.7686-2.5752-2.0712,2.2266,2.4755,2.3027-1.7031,1.8311-2.4756-2.3027-2.3437,2.5195,2.7685,2.5742-1.7578,1.89Z" />
                                    <path className="st4" d="M367.9644,495.7924c.2421-.293.4687-.5987.6992-.875.1787-.2413.3857-.4864.5805-.7149a3.7335,3.7335,0,0,1,2.3682-1.3789,3.0769,3.0769,0,0,1,.792.04,4.8863,4.8863,0,0,1,2.1.961,4.056,4.056,0,0,1,.6426.5771,3.9732,3.9732,0,0,1,1.07,2.1133c.1533,1.1348-.4219,2.0772-1.7446,3.8594l-2.2154-1.6445.5484-.7383a3.4915,3.4915,0,0,0,.8286-1.4522,1.0872,1.0872,0,0,0-.4487-1.0049,1.0139,1.0139,0,0,0-.6446-.205c-.644-.0039-.9375.5244-1.6367,1.3994-.1548.209-.3218.4336-.4927.6308l-2.2524,3.0332c-.3057.4454-.6157.8633-.9375,1.2959a4.473,4.473,0,0,0-.5112.8907,1.0541,1.0541,0,0,0,1.5815,1.2236,9.0838,9.0838,0,0,0,1.4033-1.6221l.274-.3691,2.2153,1.6445c-1.1279,1.5528-1.7241,2.2559-2.606,2.6719-1.747.8437-3.4809-.4434-3.85-.7168a4.2743,4.2743,0,0,1-1.7636-2.5547,2.4322,2.4322,0,0,1,.0434-.9639,9.8852,9.8852,0,0,1,1.8115-3.2119Z" />
                                    <path className="st4" d="M373.06,510.1508,379.0493,500.1l-1.9927-1.1875,1.2388-2.0791,6.4429,3.8388L383.5,502.7513l-1.9761-1.1767-5.9892,10.0508Z" />
                                    <path className="st4" d="M379.7065,513.8978l6.27-12.6474,2.58,1.2793-6.27,12.6464Z" />
                                    <path className="st4" d="M387.6924,517.5707l2.3735-14.2178,2.7779,1.0752L391.37,511.726c-.1206.5322-.2334,1.0459-.3652,1.5527-.1245.4873-.2935.98-.4365,1.46.2207-.5146.4785-1.0146.7368-1.5156.2-.3515.4077-.7207.6079-1.0722l3.8955-6.5752,2.7969,1.083-8.023,12.0312Z" />
                                    <path className="st4" d="M396.5166,520.7181l3.6309-13.6494,6.4375,1.7129-.6582,2.4746-3.6539-.9717-.7817,2.9385,3.2676.8692-.6431,2.416-3.2671-.8692-.8847,3.3262,3.6543.9717-.6636,2.4941Z" />
                                    <path className="st4" d="M422.36,524.8793l.3681-5.8682c.0762-1.2168.1338-2.456.2515-3.69.0835-.6963.1875-1.3916.271-2.0869-.08.3154-.1612.65-.2222.9873-.1113.4941-.2036,1.01-.3149,1.5029-.14.6328-.3179,1.2432-.4766,1.8535l-1.9521,7.1719-2.7544-.1729-1.0718-7.2c-.1109-.789-.22-1.5966-.29-2.4033-.0473-.5234-.0756-1.0263-.123-1.55-.0176-.3623-.0547-.7246-.0923-1.0879-.0088.46.0039.9014-.0054,1.3623.0015.9414-.0361,1.86-.0752,2.8-.0239.38-.0273.76-.0512,1.14-.0176.6-.0762,1.2168-.1153,1.8359l-.3115,4.97-2.7143-.1708.8437-14.0928,4.1314.2588,1.1606,8.0078c.0776.6855.1758,1.3535.2134,2.0371.03.4824.0395.9639.0693,1.4473a16.5144,16.5144,0,0,1,.2686-1.7266c.0674-.4365.1748-.8711.2822-1.3047l2.1372-8.2021,4.1318.26-.8437,14.0928Z" />
                                    <path className="st4" d="M435.1133,524.3881l-.99-3.8311-2.291.1836-.3457,3.94-2.9687.2382,1.914-14.3545,3.5469-.2851,4.084,13.8721Zm-2.3223-9.4209c-.1572-.709-.3144-1.419-.4316-2.1309-.002.7217-.044,1.4473-.086,2.1729l-.2675,3.4111,1.5742-.127Z" />
                                    <path className="st4" d="M447.1113,522.4486l-4.0586-6.8535c-.2158-.3428-.4072-.6709-.6025-1.0176-.332-.6055-.625-1.2178-.9365-1.8271.1767.4531.33.8916.4648,1.333.1416.4814.2647.9668.3906,1.4717l1.708,7.5322-2.7412.5762-2.9082-13.8213,3.27-.6875,3.7392,6.3066c.3115.5068.627,1.0342.9024,1.5684.2724.5156.5488,1.05.8047,1.5888-.19-.5117-.379-1.0244-.5293-1.5439-.09-.3291-.1788-.6582-.2647-.9668l-1.6963-7.5752,2.7012-.5684,2.9092,13.8213Z" />
                                    <path className="st4" d="M459.7178,518.7338l-1.9248-3.46-2.1729.7559.6592,3.9013-2.8145.9795L451.6992,506.53l3.3623-1.17,7.4522,12.4Zm-4.624-8.5352c-.3311-.6475-.6622-1.2949-.9561-1.9551.1807.7.3232,1.4131.4648,2.126l.6016,3.37,1.4922-.5195Z" />
                                    <path className="st4" d="M470.1055,514.1976l-.541-1.1152a2.95,2.95,0,0,1-.0518.58,2.8788,2.8788,0,0,1-1.5772,1.833,3.8615,3.8615,0,0,1-1.9628.3515,2.375,2.375,0,0,1-.9795-.3691c-.9141-.5566-1.3692-1.4023-2.2959-3.22-.2969-.6123-.6026-1.1973-.8907-1.791q-.6548-1.3492-1.2548-2.7246a5.6429,5.6429,0,0,1-.61-2.8594c.29-1.83,2.251-2.7813,2.5752-2.9385a5.4663,5.4663,0,0,1,.916-.3564,3.5814,3.5814,0,0,1,2.9727.4472,6.2634,6.2634,0,0,1,1.3926,1.7246c.1494.2618.28.5313.4375.8106l-2.5186,1.2217-.2617-.54a4.46,4.46,0,0,0-.7373-1.1533,1.2485,1.2485,0,0,0-2.0215.7812,6.6814,6.6814,0,0,0,1.02,2.9268l1.5791,3.2568a5.5085,5.5085,0,0,0,1.2656,1.92,1.2583,1.2583,0,0,0,1.8887-.961,5.3451,5.3451,0,0,0-.7354-2.0664c-.1044-.2158-.2-.458-.3134-.6924l-1.3311.6465-.9951-2.0508,3.76-1.8242,3.4482,7.1055Z" />
                                    <path className="st4" d="M476.2275,510.8636,468.6113,498.97l5.6094-3.5918,1.3809,2.1553-3.1836,2.039,1.64,2.5606,2.8477-1.8233,1.3476,2.1065-2.8467,1.8232,1.8555,2.8975,3.1836-2.0391,1.3916,2.1729Z" />
                                    <path className="st4" d="M492.9209,498.37l-3.8447-4.45c-.7979-.9228-1.6231-1.8486-2.4043-2.8135-.4268-.5556-.8389-1.125-1.2666-1.6806.164.2812.3408.5781.5322.8613.2666.4307.5615.8643.8281,1.2949.3428.55.6416,1.11.9561,1.6582l3.625,6.49-2.0889,1.8046-5.8057-4.3955c-.6308-.4853-1.2753-.9863-1.89-1.5127-.4-.3418-.7725-.6806-1.1729-1.0224-.2656-.2461-.5468-.48-.8271-.7119.3154.3349.6338.6416.9492.9765.66.6719,1.2774,1.3555,1.9072,2.0537.2481.2872.5118.5616.76.85.4082.44.7979.9228,1.2031,1.3925l3.2559,3.7686-2.0586,1.7793-9.2617-10.66,3.1328-2.707,6.4346,4.9082c.5361.4365,1.0732.8437,1.5781,1.3066.3594.3243.7041.6612,1.0625.9854a16.8311,16.8311,0,0,1-1.0166-1.4219c-.2568-.3594-.4844-.7441-.7109-1.13l-4.2139-7.3554,3.1328-2.7071,9.2627,10.66Z" />
                                    <path className="st4" d="M498.3076,492.9652,487.6533,483.69l4.375-5.0254,1.9317,1.6817-2.4834,2.8525,2.2939,1.9971,2.2207-2.5508,1.8858,1.6426-2.22,2.5508,2.5957,2.26,2.4825-2.8525,1.9472,1.6943Z" />
                                    <path className="st4" d="M508.6582,479.6429l-7.6865-2.08c-.3936-.0957-.76-.1973-1.1416-.31-.6592-.2022-1.2969-.4375-1.9453-.6563.4433.2012.8584.4082,1.2627.6319.4375.2451.8632.5078,1.3066.7812l6.4746,4.2061-1.5615,2.3242-11.7207-7.875,1.8633-2.7725,7.0771,1.9112c.5762.1465,1.169.3037,1.7393.4951.5547.1787,1.125.37,1.6845.5771-.4931-.2353-.9853-.47-1.456-.7373-.294-.1728-.5869-.3466-.8633-.5078l-6.4971-4.2451,1.5391-2.291,11.7207,7.875Z" />
                                    <path className="st4" d="M512.8916,472.5355,502.52,467.1263l-1.0722,2.0567-2.1455-1.1192,3.4668-6.6484,2.1455,1.1182-1.0635,2.039,10.373,5.41Z" />
                                </g>
                                <g>
                                    <path className="st4" d="M371.9844,359.767l-8.4458-11.3111,3.6528-2.7275a3.0259,3.0259,0,0,1,3.0562-.3853,6.028,6.028,0,0,1,2.143,2.0679,11.249,11.249,0,0,1,.7852,1.1856,3.147,3.147,0,0,1,.2046,3.5156,5.8212,5.8212,0,0,1-1.6509,1.5317l-.4644.3472,3.0264,4.0532Zm-1.6221-8.2212c.3364-.2515.4409-.3789.5132-.583.1567-.3916-.062-.9517-.5762-1.6407a9.2544,9.2544,0,0,0-.8257-1.0053,1.4243,1.4243,0,0,0-.6831-.4136,1.2461,1.2461,0,0,0-.8886.314l-.6084.4546,2.476,3.3164Z" />
                                    <path className="st4" d="M385.0747,351.0677a5.2516,5.2516,0,0,1-.4463-.2978,5.7122,5.7122,0,0,1-1.1509-1.53l-.4062-.7119c-.2183-.3823-.4541-.7544-.7168-1.1338a1.3294,1.3294,0,0,0-.8208-.59,1.2139,1.2139,0,0,0-.9.2378l-.92.5254,2.8267,4.9492-2.5352,1.4477-7.0024-12.2592,4.0981-2.34a5.3431,5.3431,0,0,1,.4961-.2607,3.3239,3.3239,0,0,1,2.4458-.3145,3.9659,3.9659,0,0,1,2.0928,2.0518c.1489.26.29.5483.4316.8359a3.195,3.195,0,0,1,.4214,2.27,1.6766,1.6766,0,0,1-.521.7812,1.5776,1.5776,0,0,1,1.0391.0054,2.7559,2.7559,0,0,1,1.46,1.4687l.1294.2256a11.7316,11.7316,0,0,0,2.1919,2.87,2.3883,2.3883,0,0,0,.4268.2632Zm-5.7334-6.0058a5.379,5.379,0,0,0,.65-.3945.8433.8433,0,0,0,.4067-.9,3.8173,3.8173,0,0,0-.4785-1.0391,8.57,8.57,0,0,0-.4712-.7441c-.0566-.06-.3989-.5782-.9072-.5411a3.4344,3.4344,0,0,0-1.0146.4415l-.7989.456,1.815,3.1777Z" />
                                    <path className="st4" d="M389.8564,348.6771l-5.7094-12.9165,2.6342-1.1645,5.7095,12.9165Z" />
                                    <path className="st4" d="M401.1553,344.4164l-4.7022-6.4229c-.2475-.3208-.47-.6279-.6982-.9546-.3887-.5693-.7393-1.1508-1.1089-1.726.22.4336.4155.8545.5913,1.2812.1885.4649.3574.9356.5322,1.4253l2.4292,7.3267-2.67.8393L391.2949,332.72l3.1851-1.0015,4.3315,5.9112c.3589.4736.7232.9668,1.05,1.4721.32.4859.647.9913.9546,1.5025-.2383-.4912-.4765-.982-.6767-1.4849-.1211-.3183-.2417-.6367-.357-.936l-2.4218-7.37,2.6318-.8277,4.2339,13.4658Z" />
                                    <path className="st4" d="M405.2358,334.15c-.0454-.378-.11-.752-.1518-1.11-.0513-.2954-.0864-.6138-.1182-.9126a3.7369,3.7369,0,0,1,.6333-2.667,3.0844,3.0844,0,0,1,.5747-.5459,4.8889,4.8889,0,0,1,2.1441-.8589,4.0545,4.0545,0,0,1,.8608-.0683,3.9748,3.9748,0,0,1,2.2691.6816c.9282.6709,1.2148,1.7373,1.5942,3.9239l-2.7188.4716-.1572-.9062a3.4923,3.4923,0,0,0-.4805-1.6006,1.0871,1.0871,0,0,0-1.0376-.3677,1.0058,1.0058,0,0,0-.5927.3257c-.4473.4634-.2661,1.0405-.1143,2.1509.0445.2558.0923.5317.1172.791l.646,3.7231c.1118.5289.2007,1.0406.293,1.5728a4.468,4.468,0,0,0,.2925.9839,1.0545,1.0545,0,0,0,1.977-.3023,9.0868,9.0868,0,0,0-.2085-2.1352l-.0786-.4531,2.7187-.4717c.3477,1.8877.4458,2.8042.14,3.73-.5923,1.8476-2.72,2.2168-3.1729,2.2954a4.2749,4.2749,0,0,1-3.0669-.4825,2.4412,2.4412,0,0,1-.6684-.6958,9.8859,9.8859,0,0,1-1.0786-3.5258Z" />
                                    <path className="st4" d="M417.24,341.3075l-1.0776-14.08,2.8721-.22,1.0771,14.08Z" />
                                    <path className="st4" d="M423.4609,340.8944l.2906-14.1123,4.5581.0938a3.028,3.028,0,0,1,2.6474,1.5747,6.0491,6.0491,0,0,1,.419,2.9482,11.3537,11.3537,0,0,1-.1094,1.4175,3.1467,3.1467,0,0,1-2,2.8984,5.8337,5.8337,0,0,1-2.2432.1939l-.58-.0122-.1036,5.0571Zm3.7735-7.4814a1.1908,1.1908,0,0,0,.7636-.1441c.3643-.2129.5362-.789.5538-1.6484a9.4376,9.4376,0,0,0-.0332-1.3008,1.4285,1.4285,0,0,0-.2852-.7456,1.2427,1.2427,0,0,0-.8936-.2983l-.76-.0157-.085,4.1377Z" />
                                    <path className="st4" d="M433.376,341.2836,435.25,327.29l2.8145.377-1.54,11.4966,4.0244.5385-.3349,2.4976Z" />
                                    <path className="st4" d="M442.0049,342.5663l3.3076-13.7241,6.4727,1.56-.6,2.4883-3.6738-.8857L446.8,334.96l3.2852.7915-.585,2.43-3.2861-.792-.8057,3.3438,3.6748.8852-.6045,2.5078Z" />
                                    <path className="st4" d="M458.5439,336.972l.0889-.2441c.0205-.0561.1992-.5444.2852-.8965.1894-.8677-.2325-1.1709-.6075-1.3081a1.0162,1.0162,0,0,0-.8994.0122,1.0652,1.0652,0,0,0-.3506.3189,2.5206,2.5206,0,0,0-.2558.5239c-.2949.8076-.125,1.2744.7207,2.4565l.832,1.22a7.4121,7.4121,0,0,1,.7041,1.0454,4.0022,4.0022,0,0,1,.253,3.7339,4.6519,4.6519,0,0,1-1.629,2.3,2.6656,2.6656,0,0,1-1.2207.4267,5.3864,5.3864,0,0,1-2.5449-.3765,3.9313,3.9313,0,0,1-2.5146-2.3247,6.1856,6.1856,0,0,1,.6093-3.5888l2.5918.9472c-.1376.376-.2812.7705-.4,1.1529-.2491.7392-.2393,1.0625-.002,1.4048a1.04,1.04,0,0,0,.6006.4536,1.1065,1.1065,0,0,0,1.3057-.3106,2.5933,2.5933,0,0,0,.3964-.792,3.27,3.27,0,0,0,.2217-.8984c.0469-.5366-.2187-.9741-.834-1.7954l-.9072-1.2471c-.2061-.31-.4307-.6265-.6113-.9477a3.7428,3.7428,0,0,1-.2373-3.43,5.0624,5.0624,0,0,1,.5175-1.0669,3.2535,3.2535,0,0,1,2.4229-1.5,4.8968,4.8968,0,0,1,2.1143.39,3.78,3.78,0,0,1,2.208,1.7652,2.8216,2.8216,0,0,1,.2207.9111,6.1414,6.1414,0,0,1-.543,2.5913Z" />
                                    <path className="st4" d="M473.793,351.0135c-1.24,1.94-2.1485,2.4957-3.3535,2.5279a4.927,4.927,0,0,1-2.4961-.7315,4.6832,4.6832,0,0,1-1.97-2.0932,2.6625,2.6625,0,0,1-.2256-.877,4.418,4.418,0,0,1,.3545-1.8589,34.831,34.831,0,0,1,2.1651-4.0195c.3867-.6548.8008-1.3164,1.2051-1.9609a10.9586,10.9586,0,0,1,.6591-.9585,3.2531,3.2531,0,0,1,3.6758-1.3838,5.5439,5.5439,0,0,1,1.5694.6484,3.5343,3.5343,0,0,1,1.7861,4.7266,33.6934,33.6934,0,0,1-1.7041,3.2C474.9092,349.163,474.377,350.1034,473.793,351.0135Zm-.9453-4.1835c.3662-.62.705-1.2334,1.0791-1.8262a6.7541,6.7541,0,0,0,.5078-.978c.082-.1377.6572-1.3086-.3008-1.8511a.96.96,0,0,0-.67-.1636c-.6514.08-1.086.7759-1.3985,1.2656-.3808.5655-.7373,1.168-1.1035,1.7886-.539.9126-1.0342,1.8291-1.5732,2.7422a5.6077,5.6077,0,0,0-.875,1.9927,1.1411,1.1411,0,0,0,.542,1.0874,1.1556,1.1556,0,0,0,1.2148-.0493,11.5546,11.5546,0,0,0,1.6211-2.3892Z" />
                                    <path className="st4" d="M473.7539,356.2709l8.5645-11.2334,5.2773,4-1.51,1.9931-2.9658-2.2475-1.873,2.3716,2.7422,2.0786-1.4737,1.9453L479.7725,353.1l-3.7217,4.9111Z" />
                                </g>
                                </>}
                            {SelectedLanguage === "de-DE" && <>
                                <g>
                                <path className="st4" d="M350.4,490.18l4.74-5.61l2.17-2.17l-1.81,1.26l-1.5,0.92l-5.61,3.18l-2.13-2.5l9.24-10.99l1.79,2.16
			                        l-5.7,6.52l-0.78,0.79l-0.94,0.84l1.55-1.14l1.68-1.05l6.2-3.57l2.01,2.36l-4.91,5.62l-1.54,1.58l-1.02,0.91l1.83-1.3l7.49-4.61
			                        l1.73,2.05l-12.39,7.21L350.4,490.18z"/>
                                <path className="st4" d="M356.03,496.1l9.72-10.25l2.09,1.98l-9.72,10.24L356.03,496.1z" />
                                <path className="st4" d="M365.03,503.9l0.14-0.53l0.98-1.62l0.51-0.64l0.79-1.08l0.17-0.38l0.07-0.61l-0.25-0.43l-0.31-0.32
			                        l-0.83-0.66l-3.55,4.46l-2.28-1.82l8.79-11.04l4.13,3.29l0.68,0.75l0.33,0.65l0.17,0.78l-0.21,1.19l-0.92,1.51l-0.62,0.71
			                        l-0.89,0.83l-1.09,0.38l-0.89-0.17l0.36,0.96l-0.22,1.05l-0.6,0.85l-0.16,0.2l-1.24,1.82l-0.61,1.28l-0.09,0.49L365.03,503.9z
			                         M369.07,496.82l0.4,0.14l0.6-0.11l0.79-0.83l0.52-0.71l0.15-0.42l0.01-0.63l-0.79-0.78l-0.72-0.57l-2.28,2.86L369.07,496.82z"/>
                                <path className="st4" d="M374.81,510.51l0.81-7.7l-3.63,5.94l-2.43-1.51l7.47-11.99l2.38,1.48l-3.36,5.4l5.89-3.83l2.72,1.69
			                        l-6.47,3.77l-0.71,8.4L374.81,510.51z"/>
                                <path className="st4" d="M387.84,505.99l0.09-0.24l0.39-0.86l-0.05-0.91l-0.43-0.45l-0.38-0.09l-0.53,0.04l-0.37,0.27l-0.3,0.5
			                        l-0.2,0.57l0.06,0.52l0.6,1.44l0.74,1.28l0.58,1.12l0.4,1.52l-0.09,1.02l-0.42,1.2l-0.77,1.23l-1.08,0.92l-1.25,0.29l-1.6-0.19
			                        l-0.9-0.43l-1.23-0.72l-0.69-0.79l-0.37-1.04l-0.04-0.93l0.23-0.91l0.76-1.68l2.49,1.19l-0.53,1.1l-0.25,0.85l0.15,0.56l0.54,0.5
			                        l0.7,0.11l0.63-0.3l0.47-0.75l0.31-0.87l-0.12-0.81l-0.54-1.06l-0.78-1.33l-0.52-1l-0.41-1.15l0.03-0.94l0.47-1.35l0.62-1.01
			                        l0.87-0.8l0.75-0.35l0.94-0.11l0.85,0.12l1.22,0.47l1.44,1.02l0.61,0.96l0.12,0.92l-0.25,1.32l-0.53,1.21L387.84,505.99z"/>
                                <path className="st4" d="M394.19,519.3l0.65-3.91l-2.17-0.76l-1.91,3.46l-2.81-0.98l7.57-12.35l3.36,1.18l-1.88,14.34L394.19,519.3z
			                         M395.87,509.72l0.47-2.08l-0.95,1.93l-1.63,3.01l1.49,0.52L395.87,509.72z"/>
                                <path className="st4" d="M409.31,523.02l1.14-5.73l0.75-3.67l0.54-1.99l-0.32,0.91l-0.55,1.46l-0.67,1.74l-2.95,6.86l-2.65-0.54
			                        l-0.17-7.27l0.09-2.43l0.04-1.54l0.09-1.08l-0.23,1.36l-0.38,2.76l-1.59,7.82l-2.63-0.54l2.67-13.85l4.1,0.79l0.04,8.11
			                        l-0.02,2.04l-0.17,1.46l0.52-1.69l0.45-1.24l3.21-7.85l4.06,0.8l-2.71,13.86L409.31,523.02z"/>
                                <path className="st4" d="M415.75,523.84l0.89-14.09l6.65,0.42l-0.16,2.55l-3.77-0.24l-0.19,3.03l3.37,0.21l-0.16,2.5l-3.37-0.21
			                        l-0.22,3.43l3.77,0.24l-0.16,2.58L415.75,523.84z"/>
                                <path className="st4" d="M431.23,524.08l-0.24-0.49l-0.32-1.87l-0.03-0.82l-0.12-1.34l-0.12-0.4l-0.34-0.51l-0.47-0.16l-0.44-0.04
			                        l-1.06,0.04l0.24,5.7l-2.92,0.12l-0.58-14.11l5.28-0.22l1.01,0.12l0.67,0.27l0.64,0.47l0.62,1.04l0.29,1.75l0,0.94l-0.13,1.21
			                        l-0.58,1l-0.78,0.45l0.9,0.48l0.52,0.94l0.1,1.04l0.01,0.26l0.25,2.19l0.38,1.37l0.26,0.43L431.23,524.08z M429.66,516.07
			                        l0.39-0.16l0.38-0.48l0.05-1.14l-0.08-0.88l-0.16-0.41l-0.4-0.48l-1.1-0.07l-0.92,0.04l0.15,3.66L429.66,516.07z"/>
                                <path className="st4" d="M442.37,522.8l-2.8-13.84l6.49-1.33l0.5,2.45l-3.64,0.75l0.55,2.97l3.37-0.69l0.49,2.39l-3.37,0.69l1.24,6.03
			                        L442.37,522.8z"/>
                                <path className="st4" d="M457.9,513.42l0.57,2.19l-0.04,1.46l-0.6,1.26l-0.92,0.8l-1.46,0.61l-1,0.28l-1.52-0.04l-1.22-0.47
			                        l-0.79-0.84l-0.59-1.05l-0.35-1.17l-2.9-9.06l2.7-0.87l2.58,8.06l0.62,1.8l0.52,1.03l0.49,0.28l0.84-0.06l0.52-0.33l0.26-0.36
			                        l0.11-0.52l-0.35-1.57l-0.37-1.16l-2.58-8.01l2.74-0.88L457.9,513.42z M449.92,504.97l-1.94,0.62l-0.71-2.23l1.94-0.62
			                        L449.92,504.97z M453.38,503.86l-1.96,0.63l-0.71-2.23l1.96-0.63L453.38,503.86z"/>
                                <path className="st4" d="M467.67,514.71l-2.55-5.56l-2.31,1.06l2.55,5.56l-2.62,1.2l-5.89-12.83l2.62-1.2l2.37,5.16l2.31-1.06
			                        l-2.37-5.16l2.62-1.2l5.89,12.83L467.67,514.71z"/>
                                <path className="st4" d="M478.48,508.73l-0.45-0.31l-1.19-1.48l-0.43-0.7l-0.75-1.11l-0.29-0.29l-0.54-0.28l-0.49,0.09l-0.41,0.18
			                        l-0.9,0.55l2.97,4.86l-2.49,1.52l-7.37-12.04l4.5-2.76l0.94-0.38l0.72-0.09l0.79,0.1l1.05,0.6l1.11,1.39l0.46,0.82l0.47,1.12
			                        l-0.02,1.16l-0.46,0.78l1.02-0.02l0.91,0.57l0.59,0.86l0.14,0.22l1.28,1.79l0.99,1.01l0.43,0.25L478.48,508.73z M473.21,502.5
			                        l0.27-0.33l0.1-0.6l-0.51-1.02l-0.49-0.73l-0.34-0.29l-0.58-0.23l-1,0.47l-0.79,0.48l1.91,3.12L473.21,502.5z"/>
                                <path className="st4" d="M486.64,496.42l1.15,1.62l0.43,0.83l0.21,0.88l0,0.81l-0.31,0.83l-0.57,0.93l-0.99,0.88l-1.34,0.88
			                        l-1.15,0.29l-1.23-0.17l-0.83-0.34l-0.64-0.52l-1.1-1.3l-5.89-7.48l2.23-1.76l5.23,6.65l1.15,1.43l0.82,0.78l0.5,0.12l0.39-0.05
			                        l0.52-0.28l0.33-0.39l0.19-0.76l-0.32-0.67l-0.63-0.93l-0.79-1.01l-5.23-6.6l2.26-1.78L486.64,496.42z"/>
                                <path className="st4" d="M496.63,493.76l-7.14-3.42l-1.09-0.57l-1.75-0.96l1.09,0.83l1.15,1.03l5.55,5.34l-1.94,1.99l-10.08-9.92
			                        l2.38-2.38l6.57,3.2l1.65,0.81l1.52,0.88l-1.28-0.98l-0.75-0.66l-5.59-5.38l1.94-1.96l10.05,9.92L496.63,493.76z"/>
                                <path className="st4" d="M504.72,484.28l-0.95-0.76l0.18,0.55l0.02,1.02l-0.16,0.66l-0.46,0.66l-0.53,0.62l-1.07,0.57l-1.04,0.11
			                        l-1.56-0.47l-1.93-1.39l-1.6-1.2l-2.34-1.88l-1.09-1.01l-0.74-1.27l-0.13-1.31l0.32-1.14l0.8-1.33l0.66-0.73l1.01-0.66l0.86-0.27
			                        l0.98,0l0.86,0.29l1.15,0.63l0.75,0.53l-1.72,2.23l-0.47-0.37l-1.16-0.72l-0.58,0.03l-0.7,0.45l-0.21,0.44l0.03,0.68l0.78,0.98
			                        l1.44,1.18l2.87,2.21l0.96,0.69l1.03,0.46l0.6-0.09l0.5-0.39l0.22-0.58l-0.07-0.63l-0.73-0.82l-0.85-0.7l-0.6-0.46l-0.89,1.16
			                        l-1.81-1.39l2.55-3.31l6.26,4.82L504.72,484.28z"/>
                            </g>
                            <g>
                                <path className="st4" d="M386.53,349.11l-0.56-1.09l-0.06,0.57l-0.4,0.94l-0.42,0.54l-0.69,0.41l-0.74,0.35l-1.21,0.08l-0.99-0.33
			                        l-1.23-1.07l-1.19-2.06l-0.96-1.75l-1.36-2.67l-0.57-1.37l-0.15-1.46l0.42-1.25l0.76-0.91l1.27-0.88l0.9-0.39l1.19-0.18l0.9,0.11
			                        l0.9,0.41l0.67,0.62l0.79,1.05l0.47,0.79l-2.49,1.33l-0.28-0.53l-0.76-1.13l-0.54-0.21l-0.82,0.12l-0.38,0.31l-0.25,0.63
			                        l0.31,1.22l0.82,1.67l1.7,3.19l0.59,1.02l0.75,0.85l0.59,0.16l0.62-0.15l0.44-0.44l0.2-0.6l-0.33-1.05l-0.48-0.99l-0.36-0.67
			                        l-1.29,0.69l-1.07-2.01l3.69-1.97l3.72,6.97L386.53,349.11z"/>
                                <path className="st4" d="M395.56,345.15l-0.39-0.38l-0.92-1.66l-0.3-0.76l-0.55-1.22l-0.24-0.34l-0.49-0.37l-0.49,0l-0.43,0.11
			                        l-0.99,0.39l2.1,5.3l-2.72,1.08l-5.2-13.13l4.91-1.95l0.99-0.22l0.72,0.04l0.76,0.24l0.93,0.77l0.85,1.56l0.31,0.89l0.28,1.18
			                        l-0.22,1.14l-0.59,0.69l1.01,0.16l0.8,0.72l0.44,0.95l0.1,0.24l0.96,1.99l0.81,1.17l0.39,0.32L395.56,345.15z M391.44,338.11
			                        l0.32-0.28l0.2-0.58l-0.33-1.1l-0.36-0.8l-0.29-0.34l-0.54-0.32l-1.07,0.29l-0.86,0.34l1.35,3.4L391.44,338.11z"/>
                                <path className="st4" d="M405.87,336.77l0.42,1.94l0.06,0.94l-0.15,0.89l-0.32,0.75l-0.62,0.64l-0.89,0.63l-1.25,0.42l-1.58,0.28
			                        l-1.17-0.18l-1.06-0.65l-0.63-0.64l-0.38-0.73l-0.5-1.63l-2.46-9.2l2.74-0.73l2.19,8.17l0.5,1.77l0.44,1.04l0.41,0.3l0.38,0.11
			                        l0.59-0.05l0.46-0.23l0.47-0.62l-0.03-0.74l-0.21-1.1l-0.33-1.24l-2.2-8.13l2.78-0.75L405.87,336.77z"/>
                                <path className="st4" d="M414.64,340.48l-3.59-7.05l-0.52-1.12l-0.8-1.83l0.37,1.32l0.29,1.51l1.16,7.61l-2.75,0.4l-1.97-14l3.33-0.44
			                        l3.27,6.53l0.81,1.64l0.67,1.62l-0.42-1.56l-0.2-0.98l-1.17-7.67l2.73-0.38l1.95,13.98L414.64,340.48z"/>
                                <path className="st4" d="M419.27,325.85l3.9-0.08l1.56,0.15l1.35,0.63l0.65,0.77l0.36,0.87l0.3,1.81l0.11,2.32l-0.02,3.14l-0.22,1.98
			                        l-0.6,1.13l-0.77,0.7l-0.81,0.4l-1.83,0.24l-3.72,0.07L419.27,325.85z M423.14,337.49l0.64-0.09l0.36-0.23l0.31-0.51l0.09-3.28
			                        l-0.1-3.08l-0.1-1.12l-0.33-0.63l-0.5-0.23l-0.52-0.07l-0.82,0.02l0.18,9.23L423.14,337.49z"/>
                                <path className="st4" d="M434.6,330.27l0-0.26l0.06-0.94l-0.37-0.83l-0.56-0.27l-0.39,0.05l-0.48,0.22l-0.25,0.38l-0.11,0.57l0.01,0.6
			                        l0.24,0.46l1.07,1.14l1.14,0.94l0.93,0.84l0.91,1.28l0.28,0.99l0.03,1.27l-0.28,1.42l-0.69,1.25l-1.07,0.71l-1.56,0.39l-1-0.09
			                        l-1.41-0.24l-0.92-0.5l-0.71-0.84l-0.37-0.85l-0.1-0.93l0.12-1.84l2.75,0.24l-0.11,1.22l0.06,0.89l0.34,0.47l0.68,0.28l0.69-0.14
			                        l0.49-0.5l0.18-0.87l-0.02-0.93l-0.4-0.72l-0.87-0.8l-1.2-0.97l-0.84-0.76l-0.78-0.93l-0.3-0.89l-0.04-1.43l0.22-1.17l0.53-1.06
			                        l0.57-0.59l0.84-0.43l0.84-0.19l1.3,0.01l1.71,0.45l0.91,0.68l0.43,0.82l0.23,1.32l-0.07,1.32L434.6,330.27z"/>
                                <path className="st4" d="M443.41,341.81l0.08-3.96l-2.26-0.44l-1.4,3.7l-2.93-0.57l5.72-13.32l3.5,0.68l0.19,14.47L443.41,341.81z
			                         M443.8,326.01l-2-0.39l0.45-2.3l2,0.39L443.8,326.01z M443.69,332.08l0.17-2.13l-0.67,2.05l-1.18,3.22l1.55,0.3L443.69,332.08z
			                         M447.37,326.71l-2.02-0.39l0.45-2.3l2.02,0.39L447.37,326.71z"/>
                                <path className="st4" d="M448.19,343.01l3.34-11.21l-2.22-0.66l0.69-2.32l7.19,2.14l-0.69,2.32l-2.22-0.66l-3.34,11.21L448.19,343.01z
			                        "/>
                                <path className="st4" d="M453.28,344.51l0.85-2.11l8.29-6.79l-4.21-1.7l0.91-2.26l7.21,2.91l-0.86,2.13l-8.42,6.74l4.67,1.89
			                        l-0.91,2.24L453.28,344.51z"/>
                                <path className="st4" d="M462,348.15l6.56-12.51l5.9,3.09l-1.19,2.27l-3.35-1.76l-1.41,2.69l2.99,1.57l-1.16,2.21l-2.99-1.57
			                        l-1.6,3.05l3.35,1.76l-1.2,2.29L462,348.15z"/>
                            </g>
                            </>}
                        </g>
                        <g id="Circle_-_1_6_" className={"model-circle" + (this.state.changed ? this.setStyles(15) : '')}>
                            <path id="Circle" className="st12" d="M425.2,275.03c-82.93,0-150.17,67.23-150.17,150.17s67.23,150.17,150.17,150.17
		                        s150.17-67.23,150.17-150.17S508.13,275.03,425.2,275.03z M425.2,537.82c-62.2,0-112.62-50.42-112.62-112.62
		                        S363,312.57,425.2,312.57S537.82,363,537.82,425.2S487.4,537.82,425.2,537.82z"/>
                            {SelectedLanguage === "en-GB" &&
                                <>
                                <g>
                                    <path className="st4" d="M359.55,537.8373c.1992-.3242.3809-.6582.5708-.9649.1435-.2636.314-.5351.4751-.789a3.7324,3.7324,0,0,1,2.1538-1.6963,3.141,3.141,0,0,1,.79-.07,4.8908,4.8908,0,0,1,2.2144.6592,4.0956,4.0956,0,0,1,.7168.4824,3.969,3.969,0,0,1,1.355,1.9443c.31,1.1035-.1284,2.1172-1.19,4.0664l-2.4233-1.32.44-.8076a3.4952,3.4952,0,0,0,.6182-1.5537,1.0867,1.0867,0,0,0-.5845-.9336,1.0081,1.0081,0,0,0-.6674-.1133c-.6382.085-.855.65-1.4258,1.6152-.1245.2286-.2583.4737-.4.6924l-1.8081,3.3194c-.2407.4843-.49.94-.7481,1.415a4.4216,4.4216,0,0,0-.3823.9531,1.0184,1.0184,0,0,0,.5415,1.1377,1.0305,1.0305,0,0,0,1.1958-.1455,9.14,9.14,0,0,0,1.1641-1.8027l.22-.4043,2.4233,1.32c-.9009,1.6953-1.3935,2.4746-2.209,3.01-1.6127,1.08-3.51.0468-3.9135-.1729a4.2792,4.2792,0,0,1-2.1035-2.2852,2.4351,2.4351,0,0,1-.0914-.9609,9.8913,9.8913,0,0,1,1.3467-3.4336Z" />
                                    <path className="st4" d="M374.8022,550.37c-1,2.0742-1.8364,2.7334-3.0278,2.9082a4.9236,4.9236,0,0,1-2.5649-.43,4.6877,4.6877,0,0,1-2.2046-1.8437,2.663,2.663,0,0,1-.3281-.8438,4.4308,4.4308,0,0,1,.1308-1.8877,34.9425,34.9425,0,0,1,1.6724-4.247c.3061-.6954.6387-1.4024.9634-2.09a10.8989,10.8989,0,0,1,.54-1.0293,3.252,3.252,0,0,1,3.4854-1.8105,5.542,5.542,0,0,1,1.6347.457,3.5335,3.5335,0,0,1,2.334,4.4805,33.5348,33.5348,0,0,1-1.3115,3.3779C375.69,548.4008,375.2739,549.3969,374.8022,550.37Zm-1.435-4.04c.29-.6592.5537-1.3086.8545-1.9414a6.75,6.75,0,0,0,.3877-1.0313c.0644-.1465.497-1.3769-.5186-1.8017a.96.96,0,0,0-.6846-.0821c-.6372.1563-.9858.8985-1.2377,1.4219-.3106.6064-.5923,1.2471-.8824,1.9062-.4272.9708-.8095,1.9395-1.2363,2.9092a5.6392,5.6392,0,0,0-.6323,2.083,1.1407,1.1407,0,0,0,.6679,1.0147,1.1569,1.1569,0,0,0,1.2-.1934A11.5274,11.5274,0,0,0,372.61,548.05Z" />
                                    <path className="st4" d="M385.9756,558.7982l1.7988-5.5986c.3735-1.1611.7339-2.3477,1.1509-3.5166.2524-.6543.5239-1.3018.7759-1.9561-.1548.2862-.3159.5908-.458.9024-.23.4521-.4453.9287-.6748,1.3808-.2911.5782-.6138,1.126-.918,1.68l-3.6553,6.4727-2.6279-.8448.7309-7.2441c.0865-.792.1788-1.6025.3091-2.4.083-.5195.1787-1.0146.2617-1.5342.0723-.3545.125-.7158.1783-1.0771-.1216.4443-.2183.875-.34,1.3193-.23.9131-.4931,1.7959-.7617,2.6963-.1162.3623-.2134.7305-.33,1.0918-.1645.5772-.373,1.1621-.563,1.752l-1.5234,4.7422-2.59-.8321,4.2818-13.457,3.9419,1.2666-.8423,8.0488c-.0938.6846-.1626,1.3555-.2944,2.0274-.09.4755-.1983.9443-.2881,1.42a16.6047,16.6047,0,0,1,.6845-1.6074c.1729-.4073.3833-.8018.5943-1.1953l4.0879-7.4268,3.9419,1.2666-4.2818,13.457Z" />
                                    <path className="st4" d="M402.7642,562.598,403.85,556.82c.2256-1.1982.4346-2.4209.7031-3.6318.1685-.6807.3565-1.3575.5254-2.0381-.1181.3037-.24.6269-.3423.9531-.1709.4766-.3256.9766-.4965,1.4531-.2164.61-.4688,1.1944-.7012,1.7813l-2.8179,6.8769-2.7124-.51-.1787-7.2774c-.0132-.7959-.0229-1.6113.0068-2.42.0176-.5264.0508-1.0283.0684-1.5537.0269-.3613.0347-.7266.042-1.0908-.0654.455-.1069.8955-.1724,1.3506-.1147.9355-.2646,1.8427-.4189,2.77-.07.373-.1206.75-.19,1.124-.0914.5928-.2256,1.1982-.34,1.8076l-.9194,4.8936-2.6729-.502L395.8,546.9242l4.0684.7646.1689,8.0889c-.0078.69.0083,1.3652-.0386,2.0479-.03.4824-.0791.9619-.1089,1.4443a16.5078,16.5078,0,0,1,.4786-1.68c.1206-.4248.28-.8428.44-1.2607l3.1279-7.876,4.0684.7637L405.437,563.1Z" />
                                    <path className="st4" d="M418.78,559.1918a7.2054,7.2054,0,0,1-.9307,3.6592,4.0093,4.0093,0,0,1-3.7124,1.2734c-.3789-.03-2.5342-.18-3.4106-1.794a5.97,5.97,0,0,1-.42-3.3242l.7481-9.4931,2.812.2216-.665,8.4356a11.75,11.75,0,0,0-.0332,2.9678,1.3328,1.3328,0,0,0,1.1816.7949,1.3168,1.3168,0,0,0,1.1626-.49,4.5447,4.5447,0,0,0,.3838-1.8154c.0327-.4189.0473-.8594.0806-1.2783l.6616-8.3955,2.852.2246Z" />
                                    <path className="st4" d="M428.6729,564.32l-2.6817-7.501c-.1455-.3779-.2715-.7353-.3974-1.1142-.211-.6563-.3819-1.3145-.5723-1.9717.0879.48.1553.9385.2031,1.3974.0479.5.0752.9991.1035,1.52l.2442,7.72-2.8008.0449-.2261-14.123,3.34-.0537,2.4717,6.9033c.209.5566.419,1.1338.5879,1.7119.169.5576.3389,1.1348.4883,1.7129-.0889-.5391-.1777-1.0781-.2266-1.6172-.0254-.34-.0508-.68-.0761-.999l-.2237-7.7608,2.76-.0439.2266,14.123Z" />
                                    <path className="st4" d="M436.1445,563.9388,434.9053,549.87l2.8691-.2529,1.2393,14.0693Z" />
                                    <path className="st4" d="M441.2441,554.5834c-.038-.3789-.0957-.7539-.1308-1.1133-.0449-.2959-.0742-.6152-.1-.915a3.7331,3.7331,0,0,1,.6845-2.6534,3.06,3.06,0,0,1,.585-.5351,4.8821,4.8821,0,0,1,2.16-.8174,4.0954,4.0954,0,0,1,.8623-.0518,3.9737,3.9737,0,0,1,2.2548.7256c.9151.6885,1.1807,1.7608,1.5176,3.9541l-2.7265.419-.14-.9092a3.49,3.49,0,0,0-.4492-1.6094,1.0885,1.0885,0,0,0-1.0313-.3877,1.0025,1.0025,0,0,0-.5986.3145c-.456.4541-.2861,1.0351-.1553,2.1474.0391.2569.0821.5332.1016.793l.5742,3.7354c.1016.53.1807,1.0439.2627,1.5771a4.38,4.38,0,0,0,.2735.99,1.0177,1.0177,0,0,0,1.12.5762,1.028,1.028,0,0,0,.8613-.8408,9.0484,9.0484,0,0,0-.166-2.1387l-.07-.4541,2.7265-.4189c.3116,1.8935.3916,2.8115.0684,3.7314-.6279,1.836-2.7627,2.1641-3.2168,2.2334a4.2732,4.2732,0,0,1-3.0566-.541,2.4435,2.4435,0,0,1-.6553-.709,9.874,9.874,0,0,1-1.01-3.5459Z" />
                                    <path className="st4" d="M459.0752,560.2142l-1.5576-3.6406-2.2383.5283.2529,3.9483-2.9013.6845-.2725-14.4873,3.4658-.8173,6.1328,13.1044Zm-3.7188-8.9677c-.2626-.6778-.5253-1.3555-.749-2.043.1074.7148.1748,1.4385.2432,2.1621l.251,3.415,1.538-.3632Z" />
                                    <path className="st4" d="M465.0146,558.58l-3.4628-11.1757-2.2168.6865-.7159-2.3115,7.1641-2.2207.7158,2.3125-2.1963.6806,3.4629,11.1758Z" />
                                    <path className="st4" d="M472.0713,556.2689,467.1807,543.03l2.7-.9981,4.8906,13.24Z" />
                                    <path className="st4" d="M483.9648,546.6566c.8663,2.1309.794,3.1934.1241,4.1934a4.9177,4.9177,0,0,1-2.0381,1.6123,4.6768,4.6768,0,0,1-2.8447.3965,2.6719,2.6719,0,0,1-.8457-.3223,4.4148,4.4148,0,0,1-1.3106-1.3633,34.8184,34.8184,0,0,1-2.0254-4.0869c-.31-.6934-.61-1.4141-.9023-2.1152a11.0887,11.0887,0,0,1-.4-1.0909,3.2489,3.2489,0,0,1,.9961-3.7968,5.5491,5.5491,0,0,1,1.4345-.9043,3.53,3.53,0,0,1,4.8848,1.2754,33.5821,33.5821,0,0,1,1.624,3.2373C483.1016,544.6771,483.5605,545.6547,483.9648,546.6566Zm-3.956-1.6474c-.294-.6563-.5977-1.2872-.8652-1.9336a6.626,6.626,0,0,0-.504-.98c-.0654-.1465-.6865-1.292-1.6826-.8242a.9628.9628,0,0,0-.52.4521,2.3278,2.3278,0,0,0,.2237,1.8711c.2412.6367.5273,1.2754.82,1.9326.4326.9668.8945,1.8994,1.3271,2.8662a5.6333,5.6333,0,0,0,1.1182,1.8653,1.1393,1.1393,0,0,0,1.2.1855,1.1515,1.1515,0,0,0,.6611-1.0186,11.4807,11.4807,0,0,0-1.0107-2.7011Z" />
                                    <path className="st4" d="M494.3232,545.9984l-5.92-5.3232c-.3076-.2637-.5888-.5186-.8789-.791-.4971-.4776-.96-.9756-1.4394-1.4629.3046.38.582.7509.8418,1.1328.2793.417.541.8428.8134,1.2861l3.88,6.6738-2.4414,1.3692L482.2734,536.57l2.9121-1.6328,5.4522,4.8975c.4482.3906.9062.7988,1.3291,1.2265.4141.41.8369.8369,1.2422,1.2744-.333-.4316-.667-.8633-.9658-1.3144-.1846-.2871-.3682-.5733-.542-.8428l-3.8819-6.7187,2.4063-1.35,6.9053,12.3135Z" />
                                </g>
                                <g>
                                    <path className="st4" d="M355.5122,312.8436c-.1733-.3388-.3643-.6675-.5278-.9887-.15-.26-.294-.5469-.4273-.8164a3.7339,3.7339,0,0,1-.33-2.7222,3.0657,3.0657,0,0,1,.35-.7119,4.8881,4.8881,0,0,1,1.7143-1.55,4.0616,4.0616,0,0,1,.7842-.3623,3.976,3.976,0,0,1,2.3662-.147c1.104.3076,1.7427,1.209,2.8569,3.13l-2.3877,1.3852-.4619-.7959a3.4959,3.4959,0,0,0-1.0063-1.3354,1.0876,1.0876,0,0,0-1.1016.0146,1.0077,1.0077,0,0,0-.4433.5118c-.2593.59.1113,1.0688.6386,2.0576.1309.2251.2715.4673.3848.7021l1.8975,3.2705c.288.4571.5493.9068.82,1.3741a4.4455,4.4455,0,0,0,.6162.8222,1.0546,1.0546,0,0,0,1.75-.9692,9.076,9.076,0,0,0-.9356-1.9317l-.2309-.3984,2.3877-1.3853c.9814,1.6509,1.3911,2.4771,1.4248,3.4522.0849,1.94-1.7837,3.0244-2.1817,3.2554a4.2771,4.2771,0,0,1-3.0459.61,2.4408,2.4408,0,0,1-.8686-.4209,9.891,9.891,0,0,1-2.2354-2.9355Z" />
                                    <path className="st4" d="M374.7388,307.9071c.9048,2.1152.852,3.1782.2011,4.1909a4.9148,4.9148,0,0,1-2.0083,1.65,4.6868,4.6868,0,0,1-2.8374.4492,2.6723,2.6723,0,0,1-.851-.3071,4.4214,4.4214,0,0,1-1.3355-1.3389,34.8685,34.8685,0,0,1-2.1006-4.0493c-.3232-.688-.6367-1.4023-.9414-2.0981a10.8641,10.8641,0,0,1-.42-1.084,3.25,3.25,0,0,1,.9253-3.814,5.5526,5.5526,0,0,1,1.4185-.9311,3.5309,3.5309,0,0,1,4.9077,1.185,33.4352,33.4352,0,0,1,1.6831,3.2076C373.8389,305.9442,374.3159,306.913,374.7388,307.9071Zm-3.9864-1.5737c-.3056-.6514-.6215-1.2764-.9008-1.918a6.7558,6.7558,0,0,0-.5215-.97c-.0684-.145-.711-1.2788-1.6978-.7935a.9634.9634,0,0,0-.5122.4614,2.3281,2.3281,0,0,0,.2583,1.8667c.2525.6324.55,1.2662.856,1.9175.4507.959.9287,1.8833,1.3789,2.8423a5.6283,5.6283,0,0,0,1.1533,1.8438,1.1405,1.1405,0,0,0,1.2036.164,1.1545,1.1545,0,0,0,.6421-1.0307,11.54,11.54,0,0,0-1.06-2.6827Z" />
                                    <path className="st4" d="M388.2266,307.52l-1.92-5.5581c-.3984-1.1533-.8222-2.3189-1.1894-3.5039-.19-.6748-.3623-1.356-.5532-2.0308.0478.3218.102.6631.1753.9976.0874.4985.2006,1.01.288,1.5083.1148.6377.1846,1.2695.274,1.895l.959,7.3716-2.6089.9009-3.7618-6.2339c-.4057-.6851-.8183-1.3892-1.1928-2.106-.2456-.4658-.4658-.9184-.711-1.3843-.1552-.3271-.33-.6479-.5039-.9682.169.4282.3506.831.52,1.2593.3637.8691.6831,1.7319,1.0088,2.6142.124.3589.267.7119.3911,1.0708.2148.5606.3984,1.1533.601,1.7393l1.626,4.7075-2.5708.8882-4.6489-13.334,3.9136-1.3521,4.1552,6.9449c.3355.6035.6836,1.1816.981,1.7983.2139.4341.4082.8745.6216,1.3091a16.6216,16.6216,0,0,1-.417-1.6973c-.106-.4292-.1738-.8711-.2417-1.313l-1.1856-8.394,3.9136-1.3516,4.6485,13.3345Z" />
                                    <path className="st4" d="M403.4712,303.7914l-1.1934-5.7554c-.2475-1.1943-.519-2.4043-.7314-3.626-.103-.6933-.186-1.3906-.2891-2.084.0064.3252.0166.6705.0464,1.0113.023.5058.07,1.0268.0928,1.5327.0322.6465.021,1.2817.0293,1.9131l.0093,7.4306-2.7017.56-2.9326-6.6611c-.315-.731-.6343-1.4815-.9141-2.24-.1836-.4926-.3437-.97-.5278-1.4629-.1123-.3442-.2437-.6845-.3755-1.0244.1128.4458.2412.8687.354,1.315.25.9077.4561,1.8042.666,2.72.0772.3721.1738.74.251,1.1118.1416.5835.2476,1.1943.3735,1.8013l1.0108,4.8745-2.6626.5522-2.9048-13.8134,4.0527-.84,3.232,7.4155c.2553.6412.5263,1.2588.7426,1.9082.1563.4576.293.92.4493,1.377a16.454,16.454,0,0,1-.1968-1.7358c-.05-.4385-.0611-.8858-.0718-1.3326l-.103-8.4726,4.0527-.84,2.9043,13.8135Z" />
                                    <path className="st4" d="M417.2983,297.0258a7.2077,7.2077,0,0,1-.2905,3.7631,4.0083,4.0083,0,0,1-3.4394,1.8887c-.3785.0352-2.5269.2564-3.6661-1.1841a5.972,5.972,0,0,1-.9824-3.2021l-.8857-9.4785,2.8076-.2627.7871,8.4233a11.74,11.74,0,0,0,.4751,2.9282,1.4116,1.4116,0,0,0,2.3608-.1,4.5359,4.5359,0,0,0,.0674-1.8545c-.039-.4179-.1-.8544-.1391-1.2724l-.7837-8.3833,2.8476-.2661Z" />
                                    <path className="st4" d="M426.9619,301.9559l-2.4394-7.5825c-.1343-.3823-.2486-.7441-.3623-1.1265-.1895-.663-.3389-1.3256-.5083-1.9887.0722.4814.125.9424.1572,1.4033.0322.5.0444,1.001.0556,1.5215l-.0034,7.7236-2.8008-.0449.2266-14.1231,3.34.0533,2.25,6.98c.1914.5634.3818,1.1464.5322,1.7295.1513.5625.3017,1.145.4326,1.7275-.0713-.5415-.1426-1.0825-.1738-1.6235-.0147-.34-.0293-.6807-.044-1.001l.0244-7.7632,2.7608.0439-.2266,14.1231Z" />
                                    <path className="st4" d="M433.59,302.2089l1.11-14.08,2.8711.2261-1.1094,14.08Z" />
                                    <path className="st4" d="M440.51,293.8939c.0742-.373.13-.749.2011-1.1025.043-.2969.1094-.6109.1719-.9048a3.7376,3.7376,0,0,1,1.4326-2.3379,3.0736,3.0736,0,0,1,.7168-.34,4.8867,4.8867,0,0,1,2.3047-.149,4.0377,4.0377,0,0,1,.84.2032,3.9763,3.9763,0,0,1,1.9453,1.3545c.6728.9267.6133,2.03.2929,4.2265l-2.73-.3979.1328-.91a3.5021,3.5021,0,0,0,.041-1.6714,1.088,1.088,0,0,0-.8721-.6728,1.0038,1.0038,0,0,0-.665.1255c-.5693.3008-.5772.9062-.7774,2.0088-.038.2568-.0781.5341-.1357.7885l-.5449,3.74c-.0576.5376-.1328,1.0517-.211,1.5864a4.4412,4.4412,0,0,0-.0283,1.0264,1.0186,1.0186,0,0,0,.9024.8794,1.03,1.03,0,0,0,1.0713-.5513,9.0855,9.0855,0,0,0,.4668-2.0947l.0664-.4551,2.73.3979c-.2568,1.9029-.4492,2.8047-1.0283,3.5894-1.1387,1.5723-3.2754,1.2607-3.7305,1.1943a4.2756,4.2756,0,0,1-2.7656-1.4136,2.45,2.45,0,0,1-.4189-.87,9.8906,9.8906,0,0,1,.0732-3.688Z" />
                                    <path className="st4" d="M454.8018,305.5013l.2158-3.9526-2.2412-.5176-1.5205,3.6514-2.9034-.6709,6.168-13.1069,3.4688.8012-.3028,14.4619Zm.6367-9.6845c.0644-.7237.1289-1.4478.2324-2.1626-.22.688-.48,1.3667-.7393,2.0459l-1.2861,3.1714,1.5391.3554Z" />
                                    <path className="st4" d="M459.9531,306.8949l3.4278-11.1846-2.2178-.68.709-2.3134,7.17,2.1972-.709,2.3135-2.1992-.6738-3.4277,11.1845Z" />
                                    <path className="st4" d="M466.3975,308.9784l4.8691-13.2505,2.7022.9932L469.1,309.9716Z" />
                                    <path className="st4" d="M481.0986,310.4076c-1.0136,2.0664-1.8545,2.72-3.0468,2.8862a4.9229,4.9229,0,0,1-2.5616-.4463,4.6868,4.6868,0,0,1-2.19-1.8589,2.6254,2.6254,0,0,1-.3223-.8457,4.4168,4.4168,0,0,1,.1436-1.8857,34.8628,34.8628,0,0,1,1.7-4.2344c.3115-.6938.6484-1.3974.9775-2.0825a10.9282,10.9282,0,0,1,.5469-1.0259,3.2521,3.2521,0,0,1,3.4961-1.7861,5.54,5.54,0,0,1,1.6309.4683,3.5333,3.5333,0,0,1,2.3037,4.4946,33.5057,33.5057,0,0,1-1.335,3.3682C482,308.4452,481.5762,309.4388,481.0986,310.4076Zm-1.4072-4.0488c.2949-.6573.5625-1.3042.8672-1.9346a6.7757,6.7757,0,0,0,.3955-1.0283c.0654-.146.5059-1.3731-.5068-1.8047a.96.96,0,0,0-.6836-.0874c-.6377.1523-.9922.8926-1.2471,1.4136-.3145.604-.6006,1.2426-.8955,1.9-.4336.9672-.8223,1.9326-1.2559,2.9a5.62,5.62,0,0,0-.6455,2.0771,1.14,1.14,0,0,0,.66,1.0191,1.1562,1.1562,0,0,0,1.2012-.1851,11.5306,11.5306,0,0,0,1.3418-2.5542Z" />
                                    <path className="st4" d="M486.91,318.4637l1.4824-7.8232c.0644-.4.1377-.772.22-1.1621.1514-.6729.3369-1.3262.5049-1.9893-.167.4571-.3408.8872-.5322,1.3072-.212.455-.44.9-.6788,1.3628l-3.6943,6.7793-2.4375-1.378,6.9483-12.2915,2.9072,1.6436-1.36,7.2026c-.1015.5859-.2129,1.19-.3593,1.773-.1358.5664-.2823,1.15-.4454,1.7236.1963-.5093.3916-1.019.6231-1.5088.1494-.3057.3-.6113.4394-.9l3.7315-6.8042,2.4033,1.3579-6.9482,12.292Z" />
                                </g>
                                </>}
                            {SelectedLanguage === "de-DE" &&
                                <>
                            <g>
                                <path className="st4" d="M362.01,548.74l0.29-7.73l-3.22,6.17l-2.52-1.34l6.64-12.46l2.47,1.32l-2.99,5.61l5.62-4.21l2.82,1.5
			                        l-6.2,4.2l-0.14,8.42L362.01,548.74z"/>
                                <path className="st4" d="M375.88,550.33l-0.74,1.36l-0.76,0.85l-0.73,0.49l-0.79,0.22l-1.63-0.05l-0.93-0.36l-1.29-0.71l-0.93-1.12
			                        l-0.33-0.84l-0.05-1.2l0.19-0.68l1.62-4.27l0.97-2.09l0.53-1.03l0.63-0.91l0.82-0.63l0.92-0.32l1.12,0.03l1.61,0.46l1.07,0.59
			                        l0.73,0.68l0.65,1.28l0.07,1.21l-0.12,0.69l-1.3,3.38L375.88,550.33z M375.23,544.34l0.38-1.03l0.08-0.79l-0.15-0.56l-0.46-0.44
			                        l-0.69-0.08l-0.76,0.61l-0.46,0.82l-0.87,1.91l-1.21,2.92l-0.41,1.04l-0.21,1.04l0.28,0.71l0.39,0.3l0.63,0.05l0.57-0.26
			                        l0.58-0.99l0.73-1.58L375.23,544.34z"/>
                                <path className="st4" d="M386.82,558.57l1.76-5.57l1.14-3.56l0.75-1.92l-0.42,0.87l-0.71,1.39l-0.86,1.66l-3.68,6.49l-2.57-0.83
			                        l0.63-7.25l0.36-2.4l0.21-1.53l0.21-1.07l-0.38,1.33l-0.68,2.7l-2.44,7.6l-2.55-0.83l4.16-13.47l3.99,1.24l-0.84,8.06l-0.24,2.02
			                        l-0.32,1.43l0.7-1.62l0.58-1.18l4.05-7.45l3.95,1.24l-4.21,13.48L386.82,558.57z"/>
                                <path className="st4" d="M403.37,562.24l1.06-5.74l0.7-3.67l0.51-2l-0.31,0.92l-0.54,1.47l-0.65,1.75l-2.86,6.9l-2.65-0.51l-0.26-7.27
			                        l0.06-2.43l0.02-1.54l0.08-1.08l-0.21,1.37l-0.35,2.76l-1.49,7.84l-2.63-0.51l2.48-13.88l4.11,0.74l0.15,8.1l0.01,2.04l-0.15,1.46
			                        l0.5-1.7l0.43-1.24l3.1-7.89l4.07,0.75l-2.52,13.89L403.37,562.24z"/>
                                <path className="st4" d="M419.11,558.77l-0.25,1.97l-0.25,0.9l-0.44,0.79l-0.55,0.6l-0.79,0.4l-1.05,0.3l-1.32-0.02l-1.58-0.26
			                        l-1.04-0.56l-0.79-0.96l-0.38-0.81l-0.12-0.81l0.07-1.7l0.73-9.49l2.83,0.22l-0.65,8.44l-0.12,1.84l0.07,1.13l0.29,0.42l0.32,0.23
			                        l0.57,0.14l0.51-0.06l0.66-0.43l0.21-0.71l0.17-1.11l0.1-1.28l0.62-8.4l2.87,0.22L419.11,558.77z"/>
                                <path className="st4" d="M428.65,563.84l-2.7-7.44l-0.38-1.17l-0.57-1.91l0.2,1.36l0.1,1.54l0.22,7.7l-2.78,0.06l-0.25-14.14
			                        l3.36-0.03l2.45,6.88l0.61,1.73l0.47,1.69l-0.23-1.6l-0.08-1l-0.22-7.76l2.76-0.04l0.23,14.12L428.65,563.84z"/>
                                <path className="st4" d="M435.68,563.54l-1.2-14.07l2.87-0.24l1.2,14.07L435.68,563.54z" />
                                <path className="st4" d="M448.46,562l-4.39-6.37l1.11,6.87l-2.83,0.43l-2.12-13.96l2.77-0.42l0.96,6.29l1.99-6.74l3.16-0.48
			                        l-2.47,7.07l4.92,6.84L448.46,562z"/>
                                <path className="st4" d="M459.49,559.66l-1.57-3.63l-2.24,0.54l0.27,3.95l-2.9,0.7l-0.33-14.48l3.46-0.83l6.18,13.07L459.49,559.66z
			                         M455.72,550.69l-0.73-2l0.25,2.14l0.26,3.41l1.54-0.37L455.72,550.69z"/>
                                <path className="st4" d="M464.97,558.14l-3.46-11.18l-2.22,0.69l-0.72-2.31l7.16-2.22l0.72,2.31l-2.22,0.69l3.46,11.18L464.97,558.14z
			                        "/>
                                <path className="st4" d="M471.49,556.01l-4.85-13.26l2.71-0.99l4.85,13.26L471.49,556.01z" />
                                <path className="st4" d="M483.02,546.63l0.49,1.47l0.1,1.13l-0.14,0.87l-0.38,0.73l-1.15,1.16l-0.9,0.44l-1.39,0.46l-1.46-0.08
			                        l-0.84-0.33l-0.91-0.78l-0.37-0.6l-2.02-4.09l-0.87-2.13l-0.39-1.09l-0.23-1.08l0.1-1.03l0.39-0.89l0.78-0.8l1.43-0.87l1.17-0.38
			                        l1-0.07l1.38,0.4l0.93,0.77l0.42,0.56l1.58,3.26L483.02,546.63z M478.2,543.03l-0.5-0.98l-0.53-0.6l-0.51-0.28l-0.64,0.04
			                        l-0.52,0.45l-0.08,0.97l0.29,0.9l0.81,1.94l1.31,2.88l0.49,1.01l0.62,0.86l0.71,0.28l0.49-0.08l0.47-0.42l0.2-0.59l-0.33-1.1
			                        l-0.66-1.61L478.2,543.03z"/>
                                <path className="st4" d="M493.01,546.2l-5.86-5.32l-0.88-0.86l-1.4-1.42l0.81,1.11l0.81,1.31l3.79,6.7l-2.43,1.35l-6.81-12.39
			                        l2.96-1.6l5.38,4.94l1.34,1.25l1.2,1.28l-0.94-1.31l-0.53-0.85l-3.82-6.75l2.42-1.33l6.79,12.38L493.01,546.2z"/>
                            </g>
                            <g>
                                <path className="st4" d="M367.72,316.52l-6.33-4.45l3.42,6.06l-2.5,1.39l-6.84-12.36l2.45-1.36l3.08,5.57l-0.47-7.01l2.8-1.55
			                        l0.14,7.49l6.99,4.7L367.72,316.52z"/>
                                <path className="st4" d="M376.83,307.43l0.5,1.46l0.12,1.13l-0.13,0.87l-0.37,0.74l-1.14,1.17l-0.9,0.45l-1.39,0.47l-1.46-0.07
			                        l-0.85-0.32l-0.92-0.77l-0.38-0.6l-2.05-4.08l-0.89-2.12l-0.4-1.09l-0.24-1.07l0.09-1.03l0.39-0.9l0.78-0.81l1.42-0.88l1.16-0.39
			                        l1-0.08l1.38,0.39l0.94,0.76l0.42,0.55l1.61,3.24L376.83,307.43z M371.98,303.86l-0.51-0.98l-0.53-0.59l-0.52-0.27l-0.64,0.05
			                        l-0.52,0.45l-0.07,0.97l0.29,0.9l0.83,1.93l1.33,2.86l0.5,1l0.63,0.86l0.71,0.27l0.49-0.09l0.46-0.43l0.19-0.59l-0.34-1.1
			                        l-0.68-1.6L371.98,303.86z"/>
                                <path className="st4" d="M389.64,307.46l-1.84-5.54l-1.16-3.55l-0.52-2l0.17,0.95l0.24,1.54l0.28,1.85l0.83,7.42l-2.57,0.83
			                        l-3.74-6.23l-1.12-2.16l-0.73-1.36l-0.45-0.99l0.47,1.3l1.03,2.59l2.48,7.58l-2.55,0.83l-4.53-13.35l3.96-1.34l4.04,7.02
			                        l0.99,1.78l0.57,1.35l-0.39-1.73l-0.22-1.3l-1.09-8.4l3.93-1.31l4.5,13.38L389.64,307.46z"/>
                                <path className="st4" d="M404.24,304.01l-1.15-5.73l-0.72-3.67l-0.27-2.05l0.05,0.97l0.05,1.56l0.05,1.87l-0.09,7.46l-2.65,0.51
			                        l-2.95-6.65l-0.85-2.28l-0.55-1.44l-0.33-1.04l0.31,1.35l0.71,2.69l1.54,7.83l-2.63,0.51l-2.86-13.81l4.09-0.85l3.16,7.46
			                        l0.77,1.89l0.41,1.41l-0.17-1.76l-0.06-1.31l-0.06-8.48l4.06-0.82l2.83,13.83L404.24,304.01z"/>
                                <path className="st4" d="M417.41,297.38l0.08,1.98l-0.09,0.93l-0.3,0.85l-0.44,0.68l-0.71,0.53l-0.98,0.47l-1.31,0.2l-1.61,0.01
			                        l-1.12-0.38l-0.94-0.82l-0.51-0.74l-0.25-0.78l-0.22-1.69l-0.89-9.48l2.83-0.26l0.79,8.42l0.19,1.83l0.26,1.1l0.36,0.37l0.36,0.17
			                        l0.59,0.05l0.49-0.15l0.57-0.54l0.09-0.73l-0.02-1.12l-0.12-1.27l-0.8-8.38l2.87-0.27L417.41,297.38z"/>
                                <path className="st4" d="M426.36,302.24l-2.7-7.44l-0.38-1.17l-0.57-1.91l0.2,1.36l0.1,1.54l0.22,7.7l-2.78,0.06l-0.25-14.14
			                        l3.36-0.03l2.45,6.88l0.61,1.73l0.47,1.69l-0.23-1.6l-0.08-1l-0.22-7.76l2.76-0.04l0.23,14.12L426.36,302.24z"/>
                                <path className="st4" d="M432.26,302.49l0.96-14.09l2.87,0.2l-0.96,14.09L432.26,302.49z" />
                                <path className="st4" d="M444,303.71l-2.42-7.35l-0.87,6.91l-2.83-0.38l1.88-13.99l2.78,0.37l-0.85,6.3l3.8-5.91l3.17,0.43l-4.35,6.09
			                        l2.8,7.95L444,303.71z"/>
                                <path className="st4" d="M453.76,305.63l0.18-3.96l-2.25-0.5l-1.49,3.67l-2.91-0.65l6.06-13.17l3.48,0.77l-0.18,14.47L453.76,305.63z
			                         M454.3,295.91l0.22-2.12l-0.72,2.03l-1.26,3.18l1.54,0.34L454.3,295.91z"/>
                                <path className="st4" d="M458.24,306.79l3.27-11.23l-2.23-0.65l0.68-2.32l7.2,2.09l-0.68,2.32l-2.23-0.65l-3.27,11.23L458.24,306.79z"
                                />
                                <path className="st4" d="M464.05,308.56l4.62-13.35l2.72,0.94l-4.62,13.35L464.05,308.56z" />
                                <path className="st4" d="M478.11,309.4l-0.72,1.37l-0.75,0.86l-0.73,0.5l-0.79,0.23l-1.63-0.03l-0.94-0.35l-1.29-0.69l-0.94-1.11
			                        l-0.34-0.84l-0.06-1.2l0.18-0.68l1.58-4.28l0.94-2.1l0.52-1.04l0.62-0.91l0.81-0.64l0.92-0.33l1.12,0.01l1.62,0.44l1.08,0.58
			                        l0.74,0.68l0.66,1.27l0.08,1.2l-0.12,0.69l-1.27,3.39L478.11,309.4z M477.4,303.43l0.37-1.04l0.07-0.79l-0.15-0.56l-0.47-0.43
			                        l-0.69-0.07l-0.76,0.62l-0.46,0.83l-0.85,1.92l-1.18,2.93l-0.4,1.05l-0.2,1.04l0.29,0.71l0.4,0.3l0.63,0.05l0.57-0.26l0.57-1
			                        l0.71-1.59L477.4,303.43z"/>
                                <path className="st4" d="M483.54,316.96l1.2-7.82l0.23-1.21l0.42-1.95l-0.47,1.29l-0.65,1.4l-3.5,6.86l-2.47-1.28l6.56-12.52
			                        l2.96,1.58l-1.15,7.21l-0.3,1.81l-0.4,1.71l0.57-1.51l0.41-0.91l3.52-6.91l2.44,1.28l-6.57,12.5L483.54,316.96z"/>
                            </g>
                            </>}
                        </g>

                        <g id="Titles">
                            {SelectedLanguage === "en-GB" &&
                                <>
                                    <g>
                                        <path className="st4" d="M788.6846,417.5458l-2.76-12.2427.2393,12.2427h-1.74V403.4227h2.4209l2.86,12.3628-.24-12.3628h1.7v14.1231Z" />
                                        <path className="st4" d="M795.8037,417.5458V403.4227h6.2217v1.5806h-4.4609v4.2007h3.9v1.6006h-3.9v5.1606h4.6005v1.5806Z" />
                                        <path className="st4" d="M815.4854,417.5458h-2.32l-1.54-11.7027-1.6006,11.7027h-2.36l-2.3008-14.1231h1.74l1.74,12.3828,1.66-12.3828h2.2607l1.62,12.3828,1.7607-12.3828h1.7207Z" />
                                    </g>
                                    <g>
                                        <path className="st4" d="M667.86,417.5155l-2.501-7.2217-1.1406,1.7007v5.521h-1.74v-14.123h1.74v6.0815l3.6416-6.0815h1.96l-3.3408,5.3212,3.3408,8.8018Z" />
                                        <path className="st4" d="M677.7188,417.5155l-2.76-12.2427.2392,12.2427h-1.74v-14.123h2.4209l2.86,12.3627-.24-12.3627h1.7v14.123Z" />
                                        <path className="st4" d="M691.64,412.7543c0,.42-.02.84-.04,1.26a4.2811,4.2811,0,0,1-1.0605,2.961,3.4827,3.4827,0,0,1-2.44.74,3.1681,3.1681,0,0,1-2.74-1.1406,2.5061,2.5061,0,0,1-.42-.9,22.5469,22.5469,0,0,1-.2207-4.461c0-1.28.02-2.5405.04-3.8208a4.7992,4.7992,0,0,1,.7608-3.06,3.37,3.37,0,0,1,2.86-1.1607,3.0911,3.0911,0,0,1,2.9,1.5606,3.4706,3.4706,0,0,1,.34,1.26c.12,1,.12,3.2808.1006,4.2608C691.72,411.0941,691.6592,411.9144,691.64,412.7543Zm-1.74-5.4409a4.3792,4.3792,0,0,0-.28-1.9605,1.509,1.509,0,0,0-1.36-.7,1.632,1.632,0,0,0-1.04.34c-.6807.54-.7,1.48-.7,3.1206v.98c0,1.36,0,2.7207.0195,4.0811a7.61,7.61,0,0,0,.1406,2.0605,1.6034,1.6034,0,0,0,2.9.22,10.1844,10.1844,0,0,0,.32-3.3208c.0195-.62.0195-1.24.0195-1.88C689.9189,409.2738,689.9189,408.2933,689.8994,407.3134Z" />
                                        <path className="st4" d="M705.34,417.5155h-2.32l-1.54-11.7026-1.6006,11.7026h-2.36l-2.3008-14.123h1.74l1.74,12.3828,1.66-12.3828h2.2607l1.62,12.3828L706,403.3925h1.7207Z" />
                                        <path className="st4" d="M715.6191,417.5155l-2.76-12.2427.2392,12.2427h-1.74v-14.123h2.4209l2.86,12.3627-.24-12.3627h1.7v14.123Z" />
                                    </g>
                                    <g>
                                        <path className="st4" d="M167.6947,556.7856l15.74-7.5651c.3149.6551.4732.9823.7916,1.6358l-15.7,7.6488c1.1089,2.2761,1.6735,3.4095,2.823,5.6668l-1.4194.7228c-2.6629-5.2294-3.94-7.87-6.3857-13.1976l1.4475-.6644C166.0526,553.3429,166.5931,554.4936,167.6947,556.7856Z" />
                                        <path className="st4" d="M172.5677,530.3876a7.3211,7.3211,0,0,1,3.2684,1.9206,8.4589,8.4589,0,0,1,1.6376,2.5716,7.3715,7.3715,0,0,1,.1918,5.9862c-.6556,1.6338-2.2374,4.04-6.0207,5.7609-.9343.4249-4.4351,2.0073-8.0031.7a7.38,7.38,0,0,1-2.797-1.7915,8.9139,8.9139,0,0,1-1.7995-2.81,7.762,7.762,0,0,1,.3643-7.4368,11.3442,11.3442,0,0,1,5.5486-4.2238A12.8217,12.8217,0,0,1,172.5677,530.3876Zm-12.02,6.7657a6.311,6.311,0,0,0-.5979,2.1543,5.9323,5.9323,0,0,0,.5369,2.8046c1.9553,4.5925,6.8462,4.2779,10.3932,2.6889.6158-.2758,4.2122-1.9333,5.3176-4.985a5.6252,5.6252,0,0,0-.1822-4.2265,5.2435,5.2435,0,0,0-3.194-3.1538c-2.968-1.1111-6.5085.2293-7.1267.4654C165.0255,533.1566,161.916,534.4455,160.5474,537.1533Z" />
                                        <path className="st4" d="M165.5165,511.389a7.3206,7.3206,0,0,1,3.1492,2.1094,8.4528,8.4528,0,0,1,1.4829,2.6626,7.3644,7.3644,0,0,1-.1625,5.9843c-.7511,1.5916-2.4724,3.9-6.3507,5.394-.9577.3691-4.5457,1.7424-8.03.2286a7.3762,7.3762,0,0,1-2.6859-1.9518,8.9043,8.9043,0,0,1-1.63-2.9094,7.76,7.76,0,0,1,.8027-7.4006,11.3456,11.3456,0,0,1,5.788-3.8892A12.8238,12.8238,0,0,1,165.5165,511.389Zm-12.3984,6.0449a6.314,6.314,0,0,0-.724,2.1149,5.9277,5.9277,0,0,0,.37,2.83c1.68,4.6979,6.5808,4.6708,10.2153,3.2937.6311-.239,4.3191-1.6814,5.6029-4.6614a5.62,5.62,0,0,0,.068-4.228,5.2419,5.2419,0,0,0-3.0021-3.3353c-2.8972-1.2836-6.5106-.1544-7.1416.0448C157.8241,513.7086,154.6441,514.812,153.1181,517.4339Z" />
                                        <path className="st4" d="M165.6425,504.3075l-18.1694,5.7471c-.2345-.7416-.3508-1.1127-.5814-1.8555l16.6025-5.1531c-1.2589-4.0562-1.8572-6.0946-2.99-10.19l1.6123-.4462C163.44,497.1956,164.1459,499.5758,165.6425,504.3075Z" />
                                        <path className="st4" d="M157.6352,478.2483a8.0229,8.0229,0,0,1,2.4187,4.3351c.8713,3.5515.0506,6.5324-3.3421,8.266-.5554-.614-.8324-.9224-1.385-1.5414a4.9366,4.9366,0,0,0,1.9513-.965c1.0121-.8292,1.9227-2.552,1.2289-5.38a8,8,0,0,0-.7741-2.0531,4.04,4.04,0,0,0-4.397-2.2072,3.3724,3.3724,0,0,0-1.6719.9223c-.8437.8784-1.0651,2.079-1.0885,4.424a25.7271,25.7271,0,0,1-.2115,4.5079,4.55,4.55,0,0,1-3.3787,3.842,4.2207,4.2207,0,0,1-2.9872-.2122c-2.51-1.17-3.3081-4.4692-3.4376-4.9982a7.2774,7.2774,0,0,1,.8261-6.13,6.2591,6.2591,0,0,1,1.6651-1.5716c.5016.6079.753.9108,1.2571,1.5144a5.2707,5.2707,0,0,0-1.4193,1.1718,5.178,5.178,0,0,0-.8725,4.7182,5.8834,5.8834,0,0,0,2.099,3.3821,2.7911,2.7911,0,0,0,2.3509.3609,2.8549,2.8549,0,0,0,1.5775-1.02c.8545-1.12.83-2.6247.8433-3.9457.0195-1.9007-.07-4.446.7518-6.177a4.6187,4.6187,0,0,1,3.231-2.6042A5.3,5.3,0,0,1,157.6352,478.2483Z" />
                                    </g>
                                    <g>
                                        <path className="st4" d="M142.2426,368.56c6.8228,1.5,10.2343,2.25,17.057,3.75-.1561.71-.2332,1.0651-.3854,1.776l-17.0775-3.6554c-.5311,2.4812-.7853,3.7245-1.2708,6.2164l-1.5634-.3046c1.125-5.7738,1.7487-8.6474,3.1164-14.3618l1.549.3707C143.0749,364.8292,142.79,366.071,142.2426,368.56Z" />
                                        <path className="st4" d="M164.2653,352.6534c-2.4522.2118-3.6791.3256-6.1341.5689-.9354,3.3568-1.3812,5.0418-2.2287,8.4232,2.0134,1.4259,3.0235,2.1318,5.05,3.5292-.19.78-.2836,1.17-.4687,1.9512-6.6971-4.7971-10.0078-7.28-16.5426-12.41.163-.6215.2452-.932.4112-1.5526,8.1968-1.1453,12.2914-1.6269,20.4624-2.41C164.5927,351.5126,164.4828,351.8926,164.2653,352.6534Zm-18.0915,1.8526c3.3654,2.5184,5.0583,3.7574,8.4631,6.1942.7434-2.9434,1.1317-4.4107,1.9415-7.3355C152.42,353.7765,150.3387,354.0047,146.1738,354.506Z" />
                                        <path className="st4" d="M168.3644,336.6808a8.0036,8.0036,0,0,1-.2269,4.9523c-1.1286,3.4729-3.3928,5.5744-7.19,5.2642-.1493-.8143-.2227-1.2222-.3669-2.0393a4.942,4.942,0,0,0,2.167.2063c1.2969-.1722,2.9773-1.157,3.8762-3.9232a7.9928,7.9928,0,0,0,.423-2.15,4.0406,4.0406,0,0,0-2.5742-4.19,3.373,3.373,0,0,0-1.9067-.0983c-1.18.3011-2,1.2034-3.2561,3.1839a25.9241,25.9241,0,0,1-2.5538,3.7209,4.5516,4.5516,0,0,1-4.8954,1.4907,4.2163,4.2163,0,0,1-2.4284-1.7532c-1.5185-2.3178-.4579-5.5471-.2891-6.0656a7.2785,7.2785,0,0,1,3.9338-4.7779,6.2568,6.2568,0,0,1,2.244-.4572c.1055.7811.1593,1.171.2694,1.95a5.253,5.253,0,0,0-1.8239.2475,5.1782,5.1782,0,0,0-3.2293,3.5524,5.8919,5.8919,0,0,0,.0028,3.9843,2.7875,2.7875,0,0,0,1.8088,1.545,2.85,2.85,0,0,0,1.8771-.0374c1.3169-.5024,2.0883-1.7965,2.7949-2.9117,1.0171-1.6049,2.2839-3.8153,3.8949-4.8516a4.6181,4.6181,0,0,1,4.1171-.5072A5.3034,5.3034,0,0,1,168.3644,336.6808Z" />
                                        <path className="st4" d="M176.0091,318.7433c-4.9011,1.0873-7.3547,1.6783-12.2629,2.9559.9454,2.207,1.4272,3.3026,2.4082,5.4771l5.7486,2.1223c-.2551.6911-.3817,1.037-.6329,1.7294l-17.9147-6.4994c.2659-.7328.4-1.0989.67-1.83l9.9771,3.6834c-2.5125-5.4774-3.7143-8.2689-6-13.9508.3969-.9781.5972-1.4664,1.0016-2.4416,1.58,4.0832,2.4,6.1,4.0979,10.08,5.5058-1.4734,8.2584-2.1493,13.7555-3.3809C176.5155,317.5094,176.3458,317.92,176.0091,318.7433Z" />
                                        <path className="st4" d="M181.5424,303.941a8.0259,8.0259,0,0,1-.7612,4.9058c-1.4975,3.336-3.9752,5.1837-7.7174,4.4676-.0607-.8262-.09-1.24-.145-2.0683a4.9389,4.9389,0,0,0,2.1324.4377c1.3081-.0322,3.0851-.8319,4.2778-3.4889a8.0131,8.0131,0,0,0,.653-2.0952,4.0424,4.0424,0,0,0-2.1065-4.4472,3.3691,3.3691,0,0,0-1.8855-.302c-1.2058.1734-2.1189.9842-3.5822,2.8211a25.9714,25.9714,0,0,1-2.9412,3.43,4.5518,4.5518,0,0,1-5.0283.9582,4.2138,4.2138,0,0,1-2.2256-2.0049c-1.26-2.469.1435-5.5694.3673-6.0674a7.2973,7.2973,0,0,1,4.4286-4.3355,6.2553,6.2553,0,0,1,2.2811-.2151c.02.7891.0316,1.1832.0567,1.97a5.2518,5.2518,0,0,0-1.84.0512,5.19,5.19,0,0,0-3.5953,3.1907,5.9034,5.9034,0,0,0-.4274,3.9656,2.7861,2.7861,0,0,0,1.6317,1.7314,2.8472,2.8472,0,0,0,1.87.1641c1.3637-.3587,2.27-1.5639,3.0935-2.5981,1.1847-1.4886,2.6835-3.5534,4.3978-4.4129a4.6154,4.6154,0,0,1,4.1492-.0647A5.3011,5.3011,0,0,1,181.5424,303.941Z" />
                                    </g>
                                </>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <>
                                    <g>
                                        <path className="st4" d="M771.4,417.49l-4.04-10.32l-0.52-1.56l0.1,2.36l0.14,9.52l-1.86-0.02l0.02-14.1l2.3-0.02l3.86,9.94l0.68,1.98
			                        l-0.1-2.52l-0.16-9.4h1.88v14.12L771.4,417.49z"/>
                                        <path className="st4" d="M777.9,417.47v-14.12h6.5v1.62h-4.7v4.38h4.38v1.6h-4.38v4.88h4.7v1.64H777.9z" />
                                        <path className="st4" d="M795.84,412.19l-0.06,2.16l-0.26,1.04l-0.44,0.9l-0.48,0.52l-0.66,0.46l-0.9,0.32l-1.16,0.06l-1.26-0.08
			                        l-1.12-0.58l-0.84-0.84l-0.48-0.96l-0.18-1.04l-0.04-2.08v-8.72h1.8v9.74l0.28,1.84l0.58,0.78l0.52,0.28l0.8,0.1l0.82-0.12
			                        l0.54-0.4l0.54-0.88l0.2-3.14v-8.2h1.8V412.19z"/>
                                        <path className="st4" d="M799.92,417.47v-14.12h6.5v1.62h-4.7v4.38h4.38v1.6h-4.38v4.88h4.7v1.64H799.92z" />
                                        <path className="st4" d="M811.04,413.23l0.08,1.2l0.24,0.78l0.32,0.38l0.48,0.3l0.84,0.12l1.12-0.26l0.58-0.6l0.3-1.32l-0.18-0.9
			                        l-0.32-0.6l-1.18-1.02l-0.62-0.36l-1.24-0.8l-1.12-0.94l-0.58-0.96l-0.24-0.88v-0.8l0.18-1.4l0.3-0.58l0.5-0.52l0.96-0.58
			                        l1.64-0.32l1.26,0.18l1.14,0.5l0.82,1.02l0.26,0.8l0.1,1.04l-0.04,0.64h-1.78l-0.08-1.12l-0.14-0.68l-0.58-0.54l-1.02-0.28
			                        l-0.6,0.08l-0.66,0.42l-0.32,0.48l-0.16,0.84l0.18,0.88l0.92,1.08l1.14,0.78l0.82,0.52l1.02,0.7l0.82,0.88l0.42,0.92l0.18,1.42
			                        l-0.18,1.64l-0.34,0.82l-0.68,0.72l-1.02,0.58l-1.48,0.16l-1.52-0.12l-0.7-0.3l-0.54-0.42l-0.74-0.82l-0.26-0.94l-0.08-1.3v-0.52
			                        H811.04z"/>
                                    </g>
                                    <g>
                                        <path className="st4" d="M643.66,403.34l2.36-0.02l2.1,0.1l1.08,0.32l0.64,0.4l0.62,0.68l0.36,0.88l0.12,1.38l-0.02,0.88l-0.4,1.26
			                        l-0.46,0.56l-0.54,0.34l0.58,0.2l0.68,0.62l0.34,0.82l0.16,1.78l-0.26,1.9l-0.42,0.84l-0.72,0.6l-1.08,0.46l-1.66,0.12h-3.48
			                        V403.34z M646.76,409.41l1.04-0.06l0.5-0.18l0.44-0.3l0.32-0.92l0.08-0.98l-0.06-0.52l-0.26-0.8l-0.74-0.52l-1.32-0.14h-1.26v4.42
			                        H646.76z M646.6,415.85l1.68-0.1l0.52-0.26l0.4-0.42l0.22-0.76l0.08-0.76l-0.1-1.06l-0.2-0.66l-0.42-0.42l-0.48-0.26l-1.66-0.16
			                        h-1.14v4.86H646.6z"/>
                                        <path className="st4" d="M655.08,417.47v-14.12h6.5v1.62h-4.7v4.38h4.38v1.6h-4.38v4.88h4.7v1.64H655.08z" />
                                        <path className="st4" d="M671.47,417.47l-4.36-6.78v6.78h-1.84v-14.12h1.84v6.72l3.98-6.72h2.14l-4.26,6.84l4.78,7.28H671.47z" />
                                        <path className="st4" d="M681.48,417.47l-0.88-4.34h-3.1l-0.9,4.34h-1.94l3.42-14.12h2.1l3.24,14.12H681.48z M679.84,409.51
			                        l-0.48-2.38l-0.28-1.78l-0.32,1.88l-0.52,2.24l-0.4,2.04h2.42L679.84,409.51z"/>
                                        <path className="st4" d="M692.18,417.49l-4.04-10.32l-0.52-1.56l0.1,2.36l0.14,9.52l-1.86-0.02l0.02-14.1l2.3-0.02l3.86,9.94
			                        l0.68,1.98l-0.1-2.52l-0.16-9.4h1.88v14.12L692.18,417.49z"/>
                                        <path className="st4" d="M704.86,417.49l-4.04-10.32l-0.52-1.56l0.1,2.36l0.14,9.52l-1.86-0.02l0.02-14.1l2.3-0.02l3.86,9.94
			                        l0.68,1.98l-0.1-2.52l-0.16-9.4h1.88v14.12L704.86,417.49z"/>
                                        <path className="st4" d="M712.6,417.47v-12.5h-2.72v-1.62h7.26v1.62h-2.72v12.5H712.6z" />
                                        <path className="st4" d="M719.86,417.47v-14.12h6.5v1.62h-4.7v4.38h4.38v1.6h-4.38v4.88h4.7v1.64H719.86z" />
                                        <path className="st4" d="M730.98,413.23l0.08,1.2l0.24,0.78l0.32,0.38l0.48,0.3l0.84,0.12l1.12-0.26l0.58-0.6l0.3-1.32l-0.18-0.9
			                        l-0.32-0.6l-1.18-1.02l-0.62-0.36l-1.24-0.8l-1.12-0.94l-0.58-0.96l-0.24-0.88v-0.8l0.18-1.4l0.3-0.58l0.5-0.52l0.96-0.58
			                        l1.64-0.32l1.26,0.18l1.14,0.5l0.82,1.02l0.26,0.8l0.1,1.04l-0.04,0.64h-1.78l-0.08-1.12l-0.14-0.68L734,405l-1.02-0.28l-0.6,0.08
			                        l-0.66,0.42l-0.32,0.48l-0.16,0.84l0.18,0.88l0.92,1.08l1.14,0.78l0.82,0.52l1.02,0.7l0.82,0.88l0.42,0.92l0.18,1.42l-0.18,1.64
			                        l-0.34,0.82l-0.68,0.72l-1.02,0.58l-1.48,0.16l-1.52-0.12l-0.7-0.3l-0.54-0.42l-0.74-0.82l-0.26-0.94l-0.08-1.3v-0.52H730.98z"/>
                                    </g>
                                    <g>
                                        <path className="st4" d="M192.23,601.78l-1.04-1.57l10.36-12.18l-0.03-0.04l-15.31,4.77l-0.74-1.1l10.12-12.37l-0.03-0.04l-15.02,5.02
			                        l-1.03-1.54l18.37-5.79l0.81,1.21l-9.86,12.1l0.03,0.04l14.83-4.64l0.78,1.17L192.23,601.78z"/>
                                        <path className="st4" d="M177.49,579.04l-6.08-10.76l1.4-0.79l5.19,9.19l5.7-3.22l-4.68-8.29l1.4-0.79l4.68,8.29l6.19-3.51l-5.46-9.67
			                        l1.4-0.79l6.35,11.24L177.49,579.04z"/>
                                        <path className="st4" d="M168.83,563.37l-3.66-7.51c-1.71-3.51-0.13-6.08,2.21-7.22c2.85-1.39,5.27-0.21,6.98,2.76l4.8-9.07l1,2.05
			                        l-4.75,8.99l1.88,3.86l7.38-3.6l0.79,1.62L168.83,563.37z M175.84,557.95l-2.45-5.03c-1.67-3.43-3.75-3.37-5.22-2.65
			                        c-1.94,0.94-2.66,2.6-1.26,5.48l2.58,5.29L175.84,557.95z"/>
                                        <path className="st4" d="M160.93,546.74l-0.69-1.67l9.51-3.92l-13.41-5.57l-0.96-2.33l9.53,4.05l7.33-11.68l0.84,2.04l-6.5,10.36
			                        l5.33,2.25l5.43-2.24l0.69,1.67L160.93,546.74z"/>
                                        <path className="st4" d="M154.09,529.63l-4.31-12.62l1.08-0.37l18.58,5.73l-3.66-10.72l1.52-0.52l4.47,13.1l-1.08,0.37l-18.58-5.73
			                        l3.5,10.24L154.09,529.63z"/>
                                        <path className="st4" d="M148.47,512.68l-3.29-11.92l1.55-0.43l2.81,10.18l6.31-1.75l-2.53-9.18l1.55-0.43l2.53,9.18l6.86-1.9
			                        l-2.96-10.72l1.55-0.43l3.44,12.46L148.47,512.68z"/>
                                        <path className="st4" d="M153.18,478.98c4.14-0.87,6.89,1.37,7.75,5.48c0.86,4.1-0.77,7.26-4.91,8.13l-12.22,2.56l-0.37-1.77
			                        l12.17-2.55c3.53-0.74,4.33-3.27,3.75-6.05c-0.58-2.78-2.32-4.77-5.85-4.03l-12.17,2.55l-0.37-1.77L153.18,478.98z"/>
                                        <path className="st4" d="M143.13,461.81c-2.6,1.3-3.56,3.07-3.18,5.78c0.66,4.76,5.16,6.19,8.97,5.65c3.81-0.54,7.76-2.99,7.15-7.43
			                        c-0.57-4.1-3.98-5.2-6.68-4.82l-1,0.14l0.97,6.94l-1.59,0.22l-1.19-8.51l9.62-1.35l0.22,1.58l-2.65,0.37l0.01,0.05
			                        c2.36,0.58,3.57,2.85,3.89,5.16c0.76,5.46-3.19,8.7-8.49,9.45c-4.96,0.7-9.99-1.3-10.79-7.08c-0.49-3.55,0.72-5.92,3.62-7.71
			                        L143.13,461.81z"/>
                                        <path className="st4" d="M137.34,455.54l-0.93-12.33l1.6-0.12l0.8,10.54l6.52-0.5l-0.72-9.5l1.61-0.12l0.72,9.5l7.09-0.54l-0.84-11.09
			                        l1.6-0.12l0.98,12.89L137.34,455.54z"/>
                                    </g>
                                    <g>
                                        <path className="st4" d="M136.98,402.72l18.96-5.95l-0.14,1.93l-5.69,1.71l-0.59,8.28l5.38,2.66l-0.13,1.85l-17.89-9.03L136.98,402.72
			                        z M148.62,400.89l-9.34,2.83l8.83,4.27L148.62,400.89z"/>
                                        <path className="st4" d="M152.18,379.4c4.19,0.58,6.03,3.61,5.47,7.77c-0.56,4.15-3.14,6.59-7.33,6.01l-12.37-1.7l0.24-1.79
			                        l12.32,1.69c3.57,0.49,5.17-1.62,5.55-4.43c0.38-2.81-0.6-5.28-4.17-5.77l-12.32-1.69l0.24-1.79L152.18,379.4z"/>
                                        <path className="st4" d="M140.79,372l2.39-12.07l1.58,0.31l-2.04,10.31l6.06,1.21l1.82-9.21l1.58,0.32l-1.82,9.21l8.91,1.78
			                        l-0.35,1.77L140.79,372z"/>
                                        <path className="st4" d="M152.54,343.75c-2.9,0.19-4.46,1.45-5.16,4.09c-1.22,4.64,2.37,7.71,6.09,8.69c3.71,0.99,8.31,0.26,9.45-4.08
			                        c1.05-4-1.66-6.34-4.29-7.04l-0.98-0.26l-1.79,6.77l-1.56-0.41l2.19-8.31l9.39,2.49l-0.41,1.54l-2.58-0.69l-0.01,0.05
			                        c1.95,1.45,2.19,4.01,1.6,6.27c-1.41,5.33-6.3,6.78-11.47,5.41c-4.84-1.29-8.7-5.08-7.22-10.72c0.91-3.46,2.95-5.17,6.31-5.71
			                        L152.54,343.75z"/>
                                        <path className="st4" d="M151.94,328.79l19.85-1.04l-0.61,1.84l-5.94,0.24l-2.63,7.87l4.55,3.91l-0.59,1.76l-15.08-13.19
			                        L151.94,328.79z M163.67,329.91l-9.75,0.41l7.5,6.33L163.67,329.91z"/>
                                        <path className="st4" d="M155.63,318.28l3.06-7.51c1.88-4.62,5.18-3.96,6.14-3.56c2.24,0.91,3.01,2.38,2.52,4.76l0.05,0.02
			                        c1.29-2.48,3.77-2.93,6.03-2c2.48,1.01,4.08,3.62,2.45,7.62l-3.13,7.69L155.63,318.28z M163.52,319.57l2.18-5.35
			                        c1.04-2.55,0.63-4.44-1.24-5.21c-2.12-0.86-3.36,0.12-4.42,2.72l-2.24,5.5L163.52,319.57z M171.94,323.01l2.46-6.04
			                        c1.16-2.85-0.01-4.65-1.65-5.32c-2.16-0.89-4.31,0.13-5.52,3.07l-2.22,5.45L171.94,323.01z"/>
                                        <path className="st4" d="M163.13,300.4l5.38-11.12l1.45,0.7l-4.59,9.5l5.89,2.86l4.14-8.57l1.45,0.7l-4.14,8.57l6.41,3.11l4.83-10
			                        l1.45,0.7l-5.62,11.63L163.13,300.4z"/>
                                        <path className="st4" d="M171.28,283.93l0.88-1.54l18.83-1.71l0.03-0.05l-13.5-7.68l0.89-1.57l16.08,9.14l-0.88,1.54l-18.83,1.7
			                        l-0.03,0.05l13.51,7.68l-0.89,1.57L171.28,283.93z"/>
                                    </g>
                                </>}
                        </g>
                        <g id="Lines_-_Bottom">
                            <line id="Line_-_6" className="st13" x1="560.58" y1="490.4" x2="807.83" y2="609.48" />
                            <line id="Line_-_5" className="st13" x1="518.87" y1="542.66" x2="689.98" y2="757.24" />
                            <line id="Line_-_4" className="st13" x1="458.63" y1="571.7" x2="519.69" y2="839.25" />
                            <line id="Line_-_3" className="st13" x1="391.76" y1="571.7" x2="330.69" y2="839.24" />
                            <line id="Line_-_2" className="st13" x1="331.52" y1="542.66" x2="160.4" y2="757.24" />
                            <line id="Line_-_1" className="st13" x1="289.81" y1="490.4" x2="42.56" y2="609.46" />
                        </g>
                        <g id="Lines_-_Top">
                            <line id="Line_-_6_1_" className="st14 stroke-double" x1="849.89" y1="425.2" x2="575.43" y2="425.2" />
                            <line id="Line_-_5_1_" className="st13" x1="768.78" y1="175.57" x2="546.75" y2="336.88" />
                            <line id="Line_-_4_1_" className="st13" x1="556.47" y1="21.36" x2="471.67" y2="282.29" />
                            <line id="Line_-_3_1_" className="st13" x1="293.92" y1="21.18" x2="378.76" y2="282.28" />
                            <line id="Line_-_2_1_" className="st13" x1="81.53" y1="175.36" x2="303.72" y2="336.79" />
                            <line id="Line_-_1_1_" className="st14 stroke-double" x1="274.96" y1="425.2" x2="0.5" y2="425.2" />
                        </g>
                        <g id="Hoover_-_Area">
                            <circle id="Circle_-_3" className="st15" cx="425.2" cy="425.31" r="71.4"
                                onMouseEnter={() => this.hoverPie(17)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 17)} />
                            <path id="Circle_-_2" className="st14" d="M425.2,312.68c-62.2,0-112.62,50.42-112.62,112.62S363,537.93,425.2,537.93   s112.62-50.42,112.62-112.62S487.4,312.68,425.2,312.68z M425.2,496.7c-39.43,0-71.4-31.96-71.4-71.4c0-39.43,31.96-71.4,71.4-71.4   c39.43,0,71.4,31.96,71.4,71.4C496.59,464.74,464.63,496.7,425.2,496.7z"
                                onMouseEnter={() => this.hoverPie(16)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 16)} />
                            <path id="Circle_-_1_11_" className="st14" d="M425.2,275.14c-82.93,0-150.17,67.23-150.17,150.17s67.23,150.17,150.17,150.17   s150.17-67.23,150.17-150.17S508.13,275.14,425.2,275.14z M425.2,537.93c-62.2,0-112.62-50.42-112.62-112.62   S363,312.68,425.2,312.68s112.62,50.42,112.62,112.62S487.4,537.93,425.2,537.93z"
                                onMouseEnter={() => this.hoverPie(15)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 15)} />
                            <path id="Segment_-_12_1_" className="st14" d="M575.84,425.36c0.1,23.14-5.39,45.18-14.92,65.37l246.84,118.99   c27.08-56.35,42.22-119.17,42.13-184.25L575.84,425.36z"
                                onMouseEnter={() => this.hoverPie(14)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 14)} />
                            <path id="Segment_-_11_1_" className="st14" d="M560.9,490.71c-9.95,20.89-24.45,38.37-41.8,52.42l170.79,214.29   c48.84-39.02,89.74-89.06,117.89-147.73L560.9,490.71z"
                                onMouseEnter={() => this.hoverPie(13)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 13)} />
                            <path id="Segment_-_10_1_" className="st14" d="M519.1,543.11c-18.03,14.51-38.68,23.96-60.41,29.1l60.92,267.17   c60.93-13.97,119.49-41.31,170.31-81.97L519.1,543.11z"
                                onMouseEnter={() => this.hoverPie(12)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 12)} />
                            <path id="Segment_-_9_1_" className="st14" d="M458.7,572.18c-22.54,5.25-45.24,4.81-67.05,0.01l-61.02,267.15   c60.96,13.84,125.58,14.61,189.01,0.03L458.7,572.18z"
                                onMouseEnter={() => this.hoverPie(11)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 11)} />
                            <path id="Segment_-_8_1_" className="st14" d="M331.25,543.1L160.38,757.33c48.92,38.92,106.81,67.64,170.28,82.02l61-267.17   C369.08,567.13,348.81,556.88,331.25,543.1z"
                                onMouseEnter={() => this.hoverPie(10)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 10)} />
                            <path id="Segment_-_7_1_" className="st14" d="M289.45,490.68L42.56,609.57c27.19,56.29,66.89,107.28,117.84,147.77l170.87-214.26   C313.11,528.74,299.29,510.72,289.45,490.68z"
                                onMouseEnter={() => this.hoverPie(9)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 9)} />
                            <path id="Segment_-_6_1_" className="st14" d="M274.53,425.31H0.5c0.08,62.51,13.72,125.68,42.06,184.27l246.91-118.9   C279.33,469.87,274.7,447.63,274.53,425.31z"
                                onMouseEnter={() => this.hoverPie(8)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 8)} />
                            <path id="Segment_-_5_1_" className="st14" d="M849.89,425.31c0.07-86.76-25.79-174.16-81.13-249.52L547.05,336.77   c19.62,27.47,28.96,57.63,28.78,88.53L849.89,425.31z"
                                onMouseEnter={() => this.hoverPie(7)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 7)} />
                            <path id="Segment_-_4_1_" className="st14" d="M556.53,21.27l-30.72,94.39c0,0-54.02,166.32-54.02,166.32   c32.04,10.65,56.94,29.87,75.21,54.71l221.78-161.14C717.84,105.32,645.21,50.55,556.53,21.27z"
                                onMouseEnter={() => this.hoverPie(6)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 6)} />
                            <path id="Segment_-_3_1_" className="st14" d="M293.92,21.29l84.66,260.67c31.95-10.19,63.83-9.4,93.21-0.05l84.68-260.62   C473.97-5.59,382.75-7.58,293.92,21.29z"
                                onMouseEnter={() => this.hoverPie(5)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 5)} />
                            <path id="Segment_-_2_1_" className="st14" d="M293.8,21.32C211.27,48.07,136.71,99.92,81.81,175.48l221.32,161.25   c20-27.19,46.24-45.01,75.51-54.7L293.8,21.32z"
                                onMouseEnter={() => this.hoverPie(4)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 4)} />
                            <path id="Segment_-_1_1_" className="st14" d="M81.59,175.38C30.54,245.53,0.5,331.9,0.5,425.31h274.08   c0-33.41,10.65-63.52,28.62-88.57L81.59,175.38z"
                                onMouseEnter={() => this.hoverPie(3)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 3)} />
                        </g>
                        <g id="Hoovers_-_Text">
                            <path id="Text_-_2" className="st5" d="M160.53,425.2c0-58.13,18.74-111.88,50.51-155.55l-28.95-21.1 C146,298.13,124.71,359.18,124.71,425.2H160.53z"
                                onMouseEnter={() => this.hoverTop(true)} onMouseLeave={() => this.hoverTop(false)} onClick={(e) => this.selectPie(e, "top")} />
                            <path id="Text_-_1" className="st5" d="M223.28,596.31c-39.14-46.14-62.75-105.87-62.75-171.12h-35.82 c0,73.58,26.45,140.98,70.35,193.22L223.28,596.31z"
                                onMouseEnter={() => this.hoverBottom(true)} onMouseLeave={() => this.hoverBottom(false)} onClick={(e) => this.selectPie(e, "bottom")} />
                        </g>
                    </g>
                    <g className="lv2">
                        <g id="Outer_Circle_-_4" onClick={() => this.switchLevel()}>
                            <g id="Circle_2_3_">
                                <path className="st0" d="M425.2,112.21c-173.07,0-313.37,140.3-313.37,313.37s140.73,312.2,313.37,312.2s313.37-139.13,313.37-312.2
			                        S598.27,112.21,425.2,112.21z M425.2,680.1c-140.78,0-254.91-114.13-254.91-254.91S284.42,170.29,425.2,170.29
			                        S680.1,284.42,680.1,425.2S565.98,680.1,425.2,680.1z"/>
                            </g>
                            <g id="Circle_1">
                                <path className="st1" d="M425.2,112.21c-173.07,0-313.37,140.3-313.37,313.37s140.73,312.2,313.37,312.2s313.37-139.13,313.37-312.2
			                        S598.27,112.21,425.2,112.21z M425.2,680.1c-140.78,0-254.91-114.13-254.91-254.91S284.42,170.29,425.2,170.29
			                        S680.1,284.42,680.1,425.2S565.98,680.1,425.2,680.1z"/>
                            </g>
                        </g>
                        <g id="Outer_Circle_-_3" onClick={() => this.switchLevel()}>
                            <path id="Circle_2_2_" className="st2" d="M425.2,75.25c-193.27,0-349.95,156.68-349.95,349.95S231.93,775.15,425.2,775.15
		                        S775.15,618.47,775.15,425.2S618.47,75.25,425.2,75.25z M425.2,738.46c-173.01,0-313.27-140.25-313.27-313.26
		                        S252.19,111.93,425.2,111.93c173.01,0,313.27,140.25,313.27,313.26S598.21,738.46,425.2,738.46z"/>
                            <path id="Circle_1_1_" className="st3" d="M425.2,75.25c-193.27,0-349.95,156.68-349.95,349.95S231.93,775.15,425.2,775.15
		                        S775.15,618.47,775.15,425.2S618.47,75.25,425.2,75.25z M425.2,738.46c-173.01,0-313.27-140.25-313.27-313.26
		                        S252.19,111.93,425.2,111.93c173.01,0,313.27,140.25,313.27,313.26S598.21,738.46,425.2,738.46z"/>
                        </g>
                        <g id="Outer_Circle_-_2" onClick={() => this.switchLevel()}>
                            <path id="Circle_2_1_" className="st4" d="M425.2,37.53c-214.1,0-387.67,173.57-387.67,387.67S211.09,812.87,425.2,812.87
		                        S812.87,639.3,812.87,425.2S639.3,37.53,425.2,37.53z M425.2,775.38c-193.4,0-350.18-156.78-350.18-350.18
		                        c0-193.4,156.78-350.18,350.18-350.18S775.38,231.8,775.38,425.2C775.38,618.6,618.6,775.38,425.2,775.38z"/>
                            <path id="Circle_1_5_" className="st5" d="M425.2,37.53c-214.1,0-387.67,173.57-387.67,387.67S211.09,812.87,425.2,812.87
		                        S812.87,639.3,812.87,425.2S639.3,37.53,425.2,37.53z M425.2,775.38c-193.4,0-350.18-156.78-350.18-350.18
		                        c0-193.4,156.78-350.18,350.18-350.18S775.38,231.8,775.38,425.2C775.38,618.6,618.6,775.38,425.2,775.38z"/>
                        </g>
                        <g id="Circle_-_1_6_" onClick={() => this.switchLevel()}>
                            <path id="Circle_2" className="st2" d="M425.2,0C190.37,0,0,190.37,0,425.2s190.37,425.2,425.2,425.2s425.2-190.37,425.2-425.2
		                        S660.03,0,425.2,0z M425.2,812.75c-214.04,0-387.55-173.51-387.55-387.55S211.16,37.64,425.2,37.64S812.75,211.16,812.75,425.2
		                        S639.24,812.75,425.2,812.75z"/>
                            <path id="Circle_1_4_" className="st6" d="M425.2,0C190.37,0,0,190.37,0,425.2s190.37,425.2,425.2,425.2s425.2-190.37,425.2-425.2
		                        S660.03,0,425.2,0z M425.2,812.75c-214.04,0-387.55-173.51-387.55-387.55S211.16,37.64,425.2,37.64S812.75,211.16,812.75,425.2
		                        S639.24,812.75,425.2,812.75z"/>
                        </g>
                        <g id="Element_-_6"
                            className={"model-circle model-option" + (this.state.changed ? this.setStyles(23) : '')}
                            onMouseEnter={() => this.hoverPie(23)}
                            onMouseLeave={() => this.hoverPie(-1)}
                            onClick={(e) => this.selectPie(e, 23)}>
                            <circle id="Circle_7_" className="st7" cx="130.78" cy="255.02" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M104.9927,240.9081c-.42.52-1.02.7-2.3.7h-2.2007V247.49H97.8711v-14.123h5.2412a3.6786,3.6786,0,0,1,.7.06,1.9739,1.9739,0,0,1,1.38,1,5.15,5.15,0,0,1,.3,2.1206v1.86C105.4932,239.7279,105.4331,240.368,104.9927,240.9081Zm-2.06-4.02a1.7074,1.7074,0,0,0-.2-1.1407,1.1193,1.1193,0,0,0-.96-.3h-1.28v4.1407h1.26c.5405,0,.84-.06,1-.28a1.83,1.83,0,0,0,.18-.9805Z" />
                                    <path className="st8" d="M114.793,246.85a4.34,4.34,0,0,1-3.0611.94,4.5151,4.5151,0,0,1-2.94-.82,2.2883,2.2883,0,0,1-.7-.88,3.8252,3.8252,0,0,1-.3-1.62v-7.9219a3.3978,3.3978,0,0,1,.88-2.56,4.5849,4.5849,0,0,1,3.0806-.92c2.0405,0,3.121.84,3.541,1.66a3.9074,3.9074,0,0,1,.32,1.88v7.8618A3.0652,3.0652,0,0,1,114.793,246.85Zm-1.8-10.062a2.4846,2.4846,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0805-.62,1.4364,1.4364,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2369,2.2369,0,0,0,.24,1.3,1.3433,1.3433,0,0,0,1.0605.42,1.5231,1.5231,0,0,0,.82-.24c.48-.34.46-.9.46-1.4205Z" />
                                    <path className="st8" d="M125.7124,245.6293c-.32,1.14-1.26,2.1606-3.6606,2.1606a4.5726,4.5726,0,0,1-2.9009-.8,2.3945,2.3945,0,0,1-.74-.96,3.1153,3.1153,0,0,1-.28-1.46v-2.06h2.5805v1.64c0,.52-.04,1.12.46,1.44a1.4383,1.4383,0,0,0,.8.2,1.2682,1.2682,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.8024,1.8024,0,0,0-.88-1.86l-2.46-1.66a3.28,3.28,0,0,1-1.18-1.1606,3.8712,3.8712,0,0,1-.5-2.24,4.75,4.75,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4049,2.4049,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6449,2.6449,0,0,0,.28,1.36,2.3381,2.3381,0,0,0,.54.46l2.52,1.7407a5.2865,5.2865,0,0,1,1.1607,1.1,3.3625,3.3625,0,0,1,.56,2.44A7.97,7.97,0,0,1,125.7124,245.6293Z" />
                                    <path className="st8" d="M128.4106,247.49v-14.123h2.5806V247.49Z" />
                                    <path className="st8" d="M138.1313,235.4071V247.49h-2.6206V235.4071H132.91v-2.0405h7.8418v2.0405Z" />
                                    <path className="st8" d="M142.67,247.49v-14.123h2.5806V247.49Z" />
                                    <path className="st8" d="M152.6714,247.49H150.05l-2.8-14.123h2.76l1.3808,9.3418,1.28-9.3418h2.66Z" />
                                    <path className="st8" d="M157.35,247.49v-14.123h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3409h4.4414V247.49Z" />
                                    <path className="st8" d="M100.2622,257.4071V269.49H97.6416V257.4071H95.041v-2.0405h7.8418v2.0405Z" />
                                    <path className="st8" d="M109.7822,269.49v-6.4614h-2.3808V269.49h-2.6006v-14.123h2.6006v5.541h2.3808v-5.541h2.6006V269.49Z" />
                                    <path className="st8" d="M115.2607,269.49v-14.123h2.5806V269.49Z" />
                                    <path className="st8" d="M125.4414,269.49l-2.5005-10.582.22,10.582H120.72v-14.123h3.3408l2.5406,10.3623-.26-10.3623h2.38V269.49Z" />
                                    <path className="st8" d="M137.002,269.49l-2.7608-6.9013V269.49h-2.6406v-14.123h2.6406v5.501l2.8208-5.501h2.6807l-3.2207,6.1015,3.3008,8.0215Z" />
                                    <path className="st8" d="M141.64,269.49v-14.123h2.5806V269.49Z" />
                                    <path className="st8" d="M151.8218,269.49l-2.501-10.582.22,10.582H147.1v-14.123h3.3408l2.541,10.3623-.2607-10.3623h2.3809V269.49Z" />
                                    <path className="st8" d="M163.4214,269.49v-.84a4.2044,4.2044,0,0,1-.5606.54,3.2194,3.2194,0,0,1-1.96.6006,2.8169,2.8169,0,0,1-2.8408-1.6406,3.2908,3.2908,0,0,1-.2-1.24v-8.0415a5.4021,5.4021,0,0,1,.2607-1.88c.58-1.5606,2.32-1.92,3.7607-1.92,1.14,0,2.6807.24,3.3809,1.3a2.6712,2.6712,0,0,1,.38,1.5v2.06h-2.6006v-1.36c0-.48.04-.96-.44-1.26a1.63,1.63,0,0,0-1.6006.02c-.3994.28-.5.72-.5,1.6v7.4619a2.0256,2.0256,0,0,0,.1006.74,1.0434,1.0434,0,0,0,1.12.64,1.2585,1.2585,0,0,0,1.02-.36,1.7377,1.7377,0,0,0,.3-1.26v-2.2h-1.6006V261.828h4.2012V269.49Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st8" d="M68.51,258.15v-14.14h4.56l1.24,0.26l0.8,0.46l0.64,0.82l0.38,1.16l0.1,1.78l-0.08,1.42l-0.18,1l-0.36,0.84
			                        l-0.58,0.66l-0.82,0.44l-0.92,0.22l-1.32,0.02H71.4v5.06H68.51z M72.16,250.57l0.74-0.16l0.36-0.6l0.14-1.04l-0.04-1.32l-0.3-0.72
			                        l-0.44-0.22l-0.46-0.06H71.4v4.14L72.16,250.57z"/>
                                <path className="st8" d="M86.18,253.83l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			                        l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			                        l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L86.18,253.83z M83.22,248.59l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			                        l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			                        l0.04-1.74V248.59z"/>
                                <path className="st8" d="M93.06,248.09l-0.02-0.26l-0.02-0.94l-0.44-0.8L92,245.87l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			                        l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			                        l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			                        l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			                        l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L93.06,248.09z"/>
                                <path className="st8" d="M98.03,258.15v-14.12h2.88v14.12H98.03z" />
                                <path className="st8" d="M104.69,258.15v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H104.69z" />
                                <path className="st8" d="M111.33,258.15v-14.12h2.88v14.12H111.33z" />
                                <path className="st8" d="M118.61,258.13l-2.92-14.08l2.98-0.04l1.26,7.38l0.22,1.54l0.1,1.54l0.14-1.7l0.18-1.2l1.26-7.54h3
			                        l-3.14,14.12L118.61,258.13z"/>
                                <path className="st8" d="M131.71,244.01h3.9l1.56,0.18l1.34,0.66l0.64,0.78l0.34,0.88l0.26,1.82l0.06,2.32l-0.08,3.14l-0.26,1.98
			                        l-0.62,1.12l-0.78,0.68l-0.82,0.38l-1.84,0.2h-3.72L131.71,244.01z M135.35,255.73l0.64-0.08l0.36-0.22l0.32-0.5l0.16-3.28
			                        l-0.04-3.08l-0.08-1.12l-0.32-0.64l-0.5-0.24l-0.52-0.08h-0.82v9.24H135.35z"/>
                                <path className="st8" d="M142.09,258.15v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H142.09z" />
                                <path className="st8" d="M156.76,258.11l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L156.76,258.11z"/>
                                <path className="st8" d="M168.66,258.15l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H168.66z" />
                                <path className="st8" d="M172.87,258.15v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H172.87z" />
                                <path className="st8" d="M187.54,258.11l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L187.54,258.11z"/>
                            </g>
                            }
                        </g>
                        <g id="Element_-_5" className={"model-circle model-option" + (this.state.changed ? this.setStyles(22) : '')}
                            onMouseEnter={() => this.hoverPie(22)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 22)}>
                            <circle id="Circle_8_" className="st7" cx="130.78" cy="594.98" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M108.1211,589.1674V601.25h-2.6206v-12.083H102.9v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M118.2412,601.25c-.04-.08-.08-.1407-.12-.2207a2.5627,2.5627,0,0,1-.26-1.1407v-3.2207c0-.2187,0-.4394-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V601.25H112.66V587.1273h5.2011a2.6456,2.6456,0,0,1,1.7207.459,2.7967,2.7967,0,0,1,.82,2.5215v1.5195a3.5451,3.5451,0,0,1-.28,1.5215,1.6861,1.6861,0,0,1-.3.3789,2.6,2.6,0,0,1-1.24.5,2.8635,2.8635,0,0,1,.78.2813,2.34,2.34,0,0,1,1.14,2.18V599.37a3.2172,3.2172,0,0,0,.36,1.66.861.861,0,0,0,.24.2207Zm-.42-10.9434c0-.5391-.08-.72-.3-.9394a1.2031,1.2031,0,0,0-.9-.2207h-1.36v3.8613h1.36a1.1952,1.1952,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st8" d="M130.1016,600.33a4.0848,4.0848,0,0,1-3.2608,1.2207,3.8291,3.8291,0,0,1-2.8808-1.02,2.8386,2.8386,0,0,1-.6-.96,4.2644,4.2644,0,0,1-.26-1.5605V587.1273H125.7v10.502a2.87,2.87,0,0,0,.14,1.1,1.15,1.15,0,0,0,1.0806.6006,1.2641,1.2641,0,0,0,.76-.22c.5-.34.44-1,.44-1.5V587.1273h2.6206v10.9414A3.3254,3.3254,0,0,1,130.1016,600.33Z" />
                                    <path className="st8" d="M140.9209,599.389c-.32,1.1407-1.26,2.1612-3.6606,2.1612a4.5728,4.5728,0,0,1-2.9009-.8,2.3993,2.3993,0,0,1-.74-.961,3.1163,3.1163,0,0,1-.28-1.46v-2.0606H135.92v1.64c0,.52-.04,1.1211.46,1.4414a1.4437,1.4437,0,0,0,.8.1992,1.2678,1.2678,0,0,0,.9-.26c.38-.3.38-.92.38-1.8808a2.3113,2.3113,0,0,0-.28-1.3,2.3383,2.3383,0,0,0-.6-.56l-2.46-1.66a3.2883,3.2883,0,0,1-1.18-1.1611,3.8708,3.8708,0,0,1-.5-2.24,4.751,4.751,0,0,1,.34-1.9405c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.68,0,3.42,1.8614a2.4024,2.4024,0,0,1,.22,1.18v1.4H138.32v-1.44c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.2207-1.16,1.4a2.6439,2.6439,0,0,0,.28,1.36,2.3209,2.3209,0,0,0,.54.46l2.5205,1.74a5.3335,5.3335,0,0,1,1.1607,1.1006,3.3627,3.3627,0,0,1,.56,2.44A7.9606,7.9606,0,0,1,140.9209,599.389Z" />
                                    <path className="st8" d="M147.88,589.1674V601.25h-2.62v-12.083h-2.6006v-2.04h7.8418v2.04Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st8" d="M87.06,601.23l-2.92-14.08l2.98-0.04l1.26,7.38l0.22,1.54l0.1,1.54l0.14-1.7l0.18-1.2l1.26-7.54h3
			                        l-3.14,14.12L87.06,601.23z"/>
                                <path className="st8" d="M94.74,601.25v-14.12h6.66v2.56h-3.78v3.04H101v2.5h-3.38v3.44h3.78v2.58H94.74z" />
                                <path className="st8" d="M109.32,601.25l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H109.32z M108.08,593.18l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			                        l-0.38-0.5l-1.1-0.12h-0.92v3.66H108.08z"/>
                                <path className="st8" d="M115.14,601.25v-11.7h-2.32v-2.42h7.5v2.42H118v11.7H115.14z" />
                                <path className="st8" d="M127.62,601.25l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H127.62z M126.38,593.18l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			                        l-0.38-0.5l-1.1-0.12h-0.92v3.66H126.38z"/>
                                <path className="st8" d="M137.74,601.25l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H137.74z M136.16,591.64
			                        l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L136.16,591.64z"/>
                                <path className="st8" d="M150.56,596.16l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V596.16z"/>
                                <path className="st8" d="M152.98,601.25v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H152.98z" />
                                <path className="st8" d="M167.64,601.21l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L167.64,601.21z"/>
                            </g>
                            }
                        </g>
                        <g id="Element_-_4" className={"model-circle model-option" + (this.state.changed ? this.setStyles(21) : '')}
                            onMouseEnter={() => this.hoverPie(21)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 21)}>
                            <circle id="Circle_1_" className="st7" cx="425.2" cy="764.96" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M376.7349,776.44a4.0848,4.0848,0,0,1-3.2608,1.2207,3.8327,3.8327,0,0,1-2.8808-1.02,2.8379,2.8379,0,0,1-.6-.96,4.2649,4.2649,0,0,1-.26-1.5606V763.2367h2.6006V773.74a2.87,2.87,0,0,0,.14,1.1,1.15,1.15,0,0,0,1.0806.6006,1.26,1.26,0,0,0,.76-.2207c.5-.34.44-1,.44-1.5V763.2367h2.6206v10.9424A3.325,3.325,0,0,1,376.7349,776.44Z" />
                                    <path className="st8" d="M387.5547,775.4994c-.32,1.1406-1.26,2.1611-3.6607,2.1611a4.5729,4.5729,0,0,1-2.9008-.8008,2.3972,2.3972,0,0,1-.74-.96,3.1166,3.1166,0,0,1-.28-1.46v-2.0605h2.5806v1.64c0,.52-.04,1.12.46,1.44a1.435,1.435,0,0,0,.8.2,1.2684,1.2684,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8809a1.8036,1.8036,0,0,0-.88-1.86l-2.4605-1.66a3.2792,3.2792,0,0,1-1.18-1.16,3.8712,3.8712,0,0,1-.5-2.24,4.751,4.751,0,0,1,.34-1.94c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.68,0,3.42,1.86a2.4072,2.4072,0,0,1,.22,1.1807v1.4h-2.6006v-1.44c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6457,2.6457,0,0,0,.28,1.36,2.3481,2.3481,0,0,0,.54.46l2.5205,1.74a5.28,5.28,0,0,1,1.1607,1.1006,3.3625,3.3625,0,0,1,.56,2.44A7.9652,7.9652,0,0,1,387.5547,775.4994Z" />
                                    <path className="st8" d="M390.2524,777.36v-14.123h6.8418v2.1406H392.853v3.3213h4.001v2.14h-4.001v4.3408h4.4409V777.36Z" />
                                    <path className="st8" d="M412.2739,775.4994c-.32,1.1406-1.26,2.1611-3.6606,2.1611a4.573,4.573,0,0,1-2.9009-.8008,2.3967,2.3967,0,0,1-.74-.96,3.1153,3.1153,0,0,1-.28-1.46v-2.0605h2.58v1.64c0,.52-.04,1.12.46,1.44a1.4353,1.4353,0,0,0,.8.2,1.2682,1.2682,0,0,0,.9-.26c.38-.3.38-.9209.38-1.8809a1.8038,1.8038,0,0,0-.88-1.86l-2.46-1.66a3.2776,3.2776,0,0,1-1.18-1.16,3.8712,3.8712,0,0,1-.5-2.24,4.7522,4.7522,0,0,1,.34-1.94c.8-1.7207,3.2207-1.7207,3.501-1.7207.42,0,2.68,0,3.42,1.86a2.4072,2.4072,0,0,1,.22,1.1807v1.4h-2.6006v-1.44c0-.4209-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6457,2.6457,0,0,0,.28,1.36,2.3492,2.3492,0,0,0,.54.46l2.52,1.74a5.2822,5.2822,0,0,1,1.1606,1.1006,3.3625,3.3625,0,0,1,.56,2.44A7.96,7.96,0,0,1,412.2739,775.4994Z" />
                                    <path className="st8" d="M419.2334,765.2777V777.36h-2.6206v-12.082h-2.6006v-2.041h7.8418v2.041Z" />
                                    <path className="st8" d="M429.3535,777.36c-.04-.08-.08-.14-.12-.22a2.57,2.57,0,0,1-.26-1.1406v-3.2207c0-.22,0-.4395-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V777.36h-2.601v-14.123h5.2016a2.6457,2.6457,0,0,1,1.7207.46,2.7935,2.7935,0,0,1,.8194,2.5205v1.52a3.5365,3.5365,0,0,1-.2793,1.52,1.6709,1.6709,0,0,1-.3008.38,2.595,2.595,0,0,1-1.24.5,2.8467,2.8467,0,0,1,.78.28,2.3423,2.3423,0,0,1,1.1407,2.1807V775.48a3.2167,3.2167,0,0,0,.36,1.66.8545.8545,0,0,0,.2393.22Zm-.42-10.9423c0-.54-.08-.72-.3-.94a1.2041,1.2041,0,0,0-.9-.2207h-1.36v3.8613h1.36a1.1932,1.1932,0,0,0,.9805-.3408c.1992-.2392.22-.4394.22-1Z" />
                                    <path className="st8" d="M434.2324,777.36v-14.123h6.8418v2.1406H436.833v3.3213h4.001v2.14h-4.001v4.3408h4.44V777.36Z" />
                                    <path className="st8" d="M448.1738,777.36l-2.5009-10.582.22,10.582h-2.4405v-14.123h3.3409l2.541,10.3623-.2608-10.3623h2.3809V777.36Z" />
                                    <path className="st8" d="M459.7734,777.36v-.84a4.2319,4.2319,0,0,1-.56.54,3.2233,3.2233,0,0,1-1.96.6006,2.8171,2.8171,0,0,1-2.8408-1.6406,3.2943,3.2943,0,0,1-.2-1.24v-8.042a5.3993,5.3993,0,0,1,.2608-1.88c.58-1.5606,2.32-1.9209,3.7607-1.9209,1.14,0,2.6807.24,3.3809,1.3a2.6723,2.6723,0,0,1,.38,1.501v2.06h-2.6005v-1.36c0-.4805.04-.96-.44-1.2608a1.6279,1.6279,0,0,0-1.6006.02c-.3994.28-.5.72-.5,1.6v7.462a2.0253,2.0253,0,0,0,.1006.74,1.0437,1.0437,0,0,0,1.12.6406,1.2583,1.2583,0,0,0,1.0206-.36,1.7389,1.7389,0,0,0,.3-1.2608v-2.2H457.793v-2.12h4.2011V777.36Z" />
                                    <path className="st8" d="M469.0527,765.2777V777.36h-2.62v-12.082H463.832v-2.041h7.8418v2.041Z" />
                                    <path className="st8" d="M478.5732,777.36v-6.4609h-2.3808V777.36h-2.6006v-14.123h2.6006v5.541h2.3808v-5.541h2.6006V777.36Z" />
                                </g>
                            }
                                { SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st8" d="M364.95,767.3l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			                        l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			                        l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			                        l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			                        l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L364.95,767.3z"/>
                                <path className="st8" d="M371.19,777.36v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H371.19z" />
                                <path className="st8" d="M383.39,777.36l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H383.39z M380.75,761.78h-2.04
			                        v-2.34h2.04V761.78z M381.81,767.76l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L381.81,767.76z M384.39,761.78h-2.06v-2.34h2.06
			                        V761.78z"/>
                                <path className="st8" d="M393.65,777.36l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H393.65z M392.41,769.3l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			                        l-1.1-0.12h-0.92v3.66H392.41z"/>
                                <path className="st8" d="M404.39,777.36l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H404.39z" />
                                <path className="st8" d="M408.61,777.36v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H408.61z" />
                                <path className="st8" d="M423.27,777.32l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L423.27,777.32z"/>
                                <path className="st8" d="M440.31,777.32l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L440.31,777.32z"/>
                                <path className="st8" d="M454.43,772.28l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V772.28z"/>
                                <path className="st8" d="M458.11,777.36v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H458.11z" />
                                <path className="st8" d="M464.13,777.36v-2.28l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H464.13z" />
                                <path className="st8" d="M474.31,777.36v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H474.31z" />
                                <path className="st8" d="M488.97,777.32l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L488.97,777.32z"/>
                            </g>
                            }
                        </g>
                        <g id="Element_-_3" className={"model-circle model-option" + (this.state.changed ? this.setStyles(20) : '')}
                            onMouseEnter={() => this.hoverPie(20)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 20)}>
                            <circle id="Circle_2_" className="st7" cx="719.61" cy="594.98" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M700.7412,580.3773v3.54h4.041v2.1005h-4.041V592.32h-2.62v-14.123h6.9219v2.1806Z" />
                                    <path className="st8" d="M713.8027,591.6791a4.3407,4.3407,0,0,1-3.0615.94,4.513,4.513,0,0,1-2.94-.82,2.2842,2.2842,0,0,1-.7-.88,3.8327,3.8327,0,0,1-.3-1.62v-7.9219a3.3994,3.3994,0,0,1,.88-2.5605,4.5872,4.5872,0,0,1,3.081-.92c2.04,0,3.1211.84,3.541,1.66a3.9122,3.9122,0,0,1,.3194,1.88v7.8623A3.0659,3.0659,0,0,1,713.8027,591.6791Zm-1.8007-10.0615a2.4845,2.4845,0,0,0-.16-1.1407,1.1027,1.1027,0,0,0-1.08-.62,1.4332,1.4332,0,0,0-.84.26c-.58.4-.5,1.0205-.5,1.6006v7.1416a2.2357,2.2357,0,0,0,.2392,1.3,1.3432,1.3432,0,0,0,1.0606.4209,1.5286,1.5286,0,0,0,.82-.24c.4795-.34.46-.9.46-1.4209Z" />
                                    <path className="st8" d="M724.7822,590.7c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8843,6.8843,0,0,1-1.34-.14,2.9459,2.9459,0,0,1-2.3809-1.6807,3.7866,3.7866,0,0,1-.2-1.4805v-7.6816c0-.24.02-.5.04-.74a2.6943,2.6943,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.9209-.8193,4.7068,4.7068,0,0,1,2.6406.64,2.4621,2.4621,0,0,1,.88,1.04,3.1108,3.1108,0,0,1,.24,1.6006v2.16h-2.56v-1.78c0-1.08-.18-1.66-1.34-1.66-1.0009,0-1.2.62-1.2,1.54v7.5615c0,.9209.1993,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.74v-1.8409h2.56v2.001A5.2225,5.2225,0,0,1,724.7822,590.7Z" />
                                    <path className="st8" d="M734.4023,591.3988a4.0815,4.0815,0,0,1-3.2607,1.2207,3.8277,3.8277,0,0,1-2.8809-1.02,2.8171,2.8171,0,0,1-.6005-.96,4.26,4.26,0,0,1-.26-1.5605V578.1967h2.6006v10.5019a2.8871,2.8871,0,0,0,.14,1.1006,1.15,1.15,0,0,0,1.0811.6,1.2651,1.2651,0,0,0,.76-.22c.5-.34.4405-1,.4405-1.5V578.1967h2.62V589.139A3.3257,3.3257,0,0,1,734.4023,591.3988Z" />
                                    <path className="st8" d="M745.2217,590.4594c-.3194,1.14-1.26,2.16-3.66,2.16a4.575,4.575,0,0,1-2.9013-.8,2.3967,2.3967,0,0,1-.74-.9609,3.12,3.12,0,0,1-.2793-1.46v-2.0605h2.58v1.6406c0,.5195-.04,1.12.4609,1.44a1.4384,1.4384,0,0,0,.8.2,1.2709,1.2709,0,0,0,.9-.2607c.38-.3.38-.92.38-1.88a2.3137,2.3137,0,0,0-.28-1.3008,2.3548,2.3548,0,0,0-.6-.56l-2.4609-1.66a3.2876,3.2876,0,0,1-1.1807-1.1612,3.8748,3.8748,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.72,3.2217-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.3979,2.3979,0,0,1,.22,1.18v1.4h-2.6v-1.44c0-.42-.16-1.0206-1.1612-1.0206-.6,0-1.16.2207-1.16,1.4a2.64,2.64,0,0,0,.28,1.36,2.31,2.31,0,0,0,.54.46l2.5205,1.7413a5.2739,5.2739,0,0,1,1.16,1.1,3.3627,3.3627,0,0,1,.5605,2.44A7.9466,7.9466,0,0,1,745.2217,590.4594Z" />
                                    <path className="st8" d="M691.6729,613.6791a4.3411,4.3411,0,0,1-3.0616.94,4.513,4.513,0,0,1-2.94-.82,2.2842,2.2842,0,0,1-.7-.88,3.8327,3.8327,0,0,1-.3-1.62v-7.9219a3.3994,3.3994,0,0,1,.88-2.5605,4.5874,4.5874,0,0,1,3.081-.92c2.04,0,3.1211.84,3.5411,1.66a3.9136,3.9136,0,0,1,.3193,1.88v7.8623A3.0662,3.0662,0,0,1,691.6729,613.6791Zm-1.8008-10.0615a2.4845,2.4845,0,0,0-.16-1.1407,1.1027,1.1027,0,0,0-1.08-.62,1.4332,1.4332,0,0,0-.84.26c-.58.4-.5,1.0205-.5,1.6006v7.1416a2.2357,2.2357,0,0,0,.2392,1.3,1.3432,1.3432,0,0,0,1.0606.4209,1.5286,1.5286,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                    <path className="st8" d="M699.9717,614.32l-2.5-10.582.22,10.582h-2.44v-14.123h3.3408l2.54,10.3623-.26-10.3623h2.3808V614.32Z" />
                                    <path className="st8" d="M711.59,614.32v-14.123H714.19v11.9824h3.8213V614.32Z" />
                                    <path className="st8" d="M719.9707,614.32v-14.123h2.58V614.32Z" />
                                    <path className="st8" d="M729.6914,602.2367V614.32H727.07v-12.083H724.47v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M739.1113,602.2367V614.32H736.49v-12.083H733.89v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M743.65,614.32v-14.123h2.6006v11.9824h3.8213V614.32Z" />
                                    <path className="st8" d="M752.03,614.32v-14.123h6.8418v2.1406h-4.2412v3.32h4.0009v2.1406h-4.0009v4.3408h4.4414V614.32Z" />
                                </g>
                                }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st8" d="M665.9,592.12l-3.38-6.96l0.06,6.96h-2.86v-14.12h2.8v6.36l2.98-6.36h3.2l-3.5,6.62l3.84,7.5H665.9z" />
                                <path className="st8" d="M678.22,587.8l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			                        l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			                        l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L678.22,587.8z M675.26,582.55l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			                        l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			                        l0.04-1.74V582.55z"/>
                                <path className="st8" d="M686.44,592.08l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L686.44,592.08z"/>
                                <path className="st8" d="M691.54,592.12v-2.28l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H691.54z" />
                                <path className="st8" d="M701.72,592.12v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H701.72z" />
                                <path className="st8" d="M716.38,592.08l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L716.38,592.08z"/>
                                <path className="st8" d="M723.36,592.12v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H723.36z" />
                                <path className="st8" d="M735.84,592.12l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H735.84z M734.6,584.05l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			                        l-1.1-0.12h-0.92v3.66H734.6z"/>
                                <path className="st8" d="M745.96,592.12l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H745.96z M744.38,582.51
			                        l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L744.38,582.51z"/>
                                <path className="st8" d="M751.64,592.12v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H751.64z" />
                                <path className="st8" d="M758.28,592.12v-14.12h2.88v14.12H758.28z" />
                                <path className="st8" d="M771.78,587.8l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			                        l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			                        l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L771.78,587.8z M768.82,582.55l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			                        l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			                        l0.04-1.74V582.55z"/>
                                <path className="st8" d="M780,592.08l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14l3.36,0.02
			                        l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L780,592.08z"/>
                                <path className="st8" d="M676.29,614.12l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H676.29z M674.71,604.51
			                        l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L674.71,604.51z"/>
                                <path className="st8" d="M689.11,609.04l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V609.04z"/>
                                <path className="st8" d="M691.53,614.12l0.04-14.12h6.62v2.5h-3.72l-0.06,3.02h3.44v2.44h-3.44v6.16H691.53z" />
                                <path className="st8" d="M713.07,614.14l-1.24-7.24l-0.26-3.06l-0.2,2.2l-0.26,1.74l-1.18,6.34l-3.28,0.02l-2.46-14.14l2.8,0.02
			                        l1.32,8.56l0.1,1.1l0.04,1.26l0.12-1.92l0.28-1.96l1.26-7.04l3.1-0.02l1.14,7.38l0.22,2.2l0.04,1.36l0.18-2.24l1.3-8.7h2.68
			                        l-2.46,14.12L713.07,614.14z"/>
                                <path className="st8" d="M720.23,614.12v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H720.23z" />
                                <path className="st8" d="M734.89,614.08l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L734.89,614.08z"/>
                                <path className="st8" d="M740.61,614.12v-14.12h2.88v14.12H740.61z" />
                                <path className="st8" d="M751.61,614.12l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			                        l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			                        l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			                        l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H751.61z"/>
                                <path className="st8" d="M756.41,614.12v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H756.41z" />
                                <path className="st8" d="M769.73,604.05l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			                        l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			                        l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			                        l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			                        l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L769.73,604.05z"/>
                            </g>
                            }
                        </g>
                        <g id="Element_-_2" className={"model-circle model-option" + (this.state.changed ? this.setStyles(19) : '')}
                            onMouseEnter={() => this.hoverPie(19)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 19)}>
                            <circle id="Circle_9_" className="st7" cx="719.61" cy="255.02" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M672.043,241.66c-.6807,1.96-3.3008,1.92-3.6006,1.92a6.8836,6.8836,0,0,1-1.3408-.14,2.9483,2.9483,0,0,1-2.3809-1.6807,3.8012,3.8012,0,0,1-.1992-1.48V232.598c0-.24.02-.5005.04-.74a2.6916,2.6916,0,0,1,1-2.1807,4.2994,4.2994,0,0,1,2.92-.82,4.7055,4.7055,0,0,1,2.6407.64,2.4645,2.4645,0,0,1,.8808,1.04,3.1188,3.1188,0,0,1,.24,1.6v2.1606h-2.5606v-1.78c0-1.0805-.1806-1.6606-1.3408-1.6606-1,0-1.2.62-1.2,1.54v7.5616c0,.92.2,1.6005,1.28,1.6005,1.16,0,1.2607-.66,1.2607-1.7407v-1.84h2.5606v2A5.2485,5.2485,0,0,1,672.043,241.66Z" />
                                    <path className="st8" d="M681.5625,242.64a4.3384,4.3384,0,0,1-3.0605.94,4.5145,4.5145,0,0,1-2.94-.82,2.2882,2.2882,0,0,1-.7-.88,3.8177,3.8177,0,0,1-.3-1.62v-7.9218a3.3964,3.3964,0,0,1,.88-2.5606,4.5829,4.5829,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9,3.9,0,0,1,.32,1.88V240.26A3.0633,3.0633,0,0,1,681.5625,242.64Zm-1.8-10.062a2.4832,2.4832,0,0,0-.16-1.1406,1.1042,1.1042,0,0,0-1.081-.62,1.4372,1.4372,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2371,2.2371,0,0,0,.24,1.3,1.3429,1.3429,0,0,0,1.06.42,1.522,1.522,0,0,0,.8194-.24c.48-.34.4609-.9.4609-1.42Z" />
                                    <path className="st8" d="M689.8613,243.28l-2.5-10.5821.22,10.5821h-2.4405V229.1571h3.3408l2.54,10.3623-.26-10.3623h2.3809V243.28Z" />
                                    <path className="st8" d="M700.2812,231.1976V243.28h-2.62V231.1976h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M710.4023,243.28c-.04-.08-.08-.14-.12-.22a2.5531,2.5531,0,0,1-.2607-1.14v-3.2207c0-.22,0-.44-.0195-.68-.08-.78-.66-.96-1.3409-.96h-1.24V243.28H704.82V229.1571h5.2012a2.6436,2.6436,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52v1.52a3.5433,3.5433,0,0,1-.28,1.52,1.6774,1.6774,0,0,1-.3.38,2.609,2.609,0,0,1-1.24.5,2.8583,2.8583,0,0,1,.78.28,2.34,2.34,0,0,1,1.14,2.18V241.4a3.2172,3.2172,0,0,0,.36,1.66.8529.8529,0,0,0,.24.22Zm-.4209-10.9424c0-.54-.0791-.72-.3-.94a1.2025,1.2025,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1932,1.1932,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st8" d="M715.28,243.28V229.1571h2.58V243.28Z" />
                                    <path className="st8" d="M728.4414,240.9a2.3363,2.3363,0,0,1-1.24,2.1,4.8547,4.8547,0,0,1-2,.28H720.74V229.1571h4.3809a3.4418,3.4418,0,0,1,2.3408.54,2.5918,2.5918,0,0,1,.84,2.3409v1.26a5.9517,5.9517,0,0,1-.08,1,1.67,1.67,0,0,1-1.5606,1.38,2.0653,2.0653,0,0,1,1.4.68,2.893,2.893,0,0,1,.4,1.9v2C728.4619,240.4794,728.4414,240.7,728.4414,240.9Zm-2.72-8.4219a2.2618,2.2618,0,0,0-.0606-.62c-.18-.66-.74-.68-1.28-.68h-1.04v3.5606h.94a5,5,0,0,0,.7-.04c.5-.1.74-.4.74-1.26Zm.18,5.6812a1.9477,1.9477,0,0,0-.08-.78c-.2-.58-.84-.6-1.36-.6h-1.12V241.24h1.1006a3.5538,3.5538,0,0,0,.66-.04c.56-.08.8-.42.8-1.38Z" />
                                    <path className="st8" d="M738.1816,242.36a4.0824,4.0824,0,0,1-3.2607,1.2207,3.8325,3.8325,0,0,1-2.8809-1.02,2.83,2.83,0,0,1-.6-.96,4.2637,4.2637,0,0,1-.2607-1.5606V229.1571H733.78V239.66a2.8641,2.8641,0,0,0,.1406,1.1,1.1482,1.1482,0,0,0,1.08.6,1.2614,1.2614,0,0,0,.76-.22c.501-.34.44-1,.44-1.5V229.1571h2.6211V240.1A3.3217,3.3217,0,0,1,738.1816,242.36Z" />
                                    <path className="st8" d="M745.9209,231.1976V243.28h-2.62V231.1976H740.7v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M750.46,243.28V229.1571h2.581V243.28Z" />
                                    <path className="st8" d="M762.8018,242.64a4.3386,4.3386,0,0,1-3.0606.94,4.5143,4.5143,0,0,1-2.94-.82,2.2781,2.2781,0,0,1-.7-.88,3.8187,3.8187,0,0,1-.3008-1.62v-7.9218a3.3979,3.3979,0,0,1,.8809-2.5606,4.5827,4.5827,0,0,1,3.08-.92c2.0411,0,3.1211.84,3.5411,1.66a3.9116,3.9116,0,0,1,.32,1.88V240.26A3.0673,3.0673,0,0,1,762.8018,242.64Zm-1.8008-10.062a2.4706,2.4706,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4369,1.4369,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.23,2.23,0,0,0,.24,1.3,1.3412,1.3412,0,0,0,1.06.42,1.5245,1.5245,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M771.1006,243.28,768.6,232.6981l.22,10.5821h-2.44V229.1571H769.72l2.541,10.3623L772,229.1571h2.3809V243.28Z" />
                                    <path className="st8" d="M669.1025,253.1976V265.28h-2.6211V253.1976h-2.6005v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M680.5225,264.64a4.3386,4.3386,0,0,1-3.0606.94,4.5143,4.5143,0,0,1-2.94-.82,2.2882,2.2882,0,0,1-.7-.88,3.8177,3.8177,0,0,1-.3-1.62v-7.9218a3.3959,3.3959,0,0,1,.88-2.5606,4.5827,4.5827,0,0,1,3.08-.92c2.0411,0,3.1211.84,3.5411,1.66a3.9,3.9,0,0,1,.32,1.88V262.26A3.0636,3.0636,0,0,1,680.5225,264.64Zm-1.8-10.062a2.4846,2.4846,0,0,0-.16-1.1406,1.1043,1.1043,0,0,0-1.0811-.62,1.4369,1.4369,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2379,2.2379,0,0,0,.24,1.3,1.343,1.343,0,0,0,1.0606.42,1.5218,1.5218,0,0,0,.8193-.24c.4805-.34.461-.9.461-1.42Z" />
                                    <path className="st8" d="M693.8213,253.1976V265.28h-2.62V253.1976h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M703.3418,265.28v-6.4615h-2.3809V265.28H698.36V251.1571h2.6005v5.541h2.3809v-5.541h2.6006V265.28Z" />
                                    <path className="st8" d="M708.82,265.28V251.1571h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V263.1h4.44V265.28Z" />
                                    <path className="st8" d="M733.7217,265.28h-2.74l-1.36-9.6421-1.52,9.6421h-2.78l-2.58-14.1231h2.7l1.42,10.6421,1.52-10.6421h2.4609l1.4805,10.6421,1.4795-10.6421h2.6406Z" />
                                    <path className="st8" d="M743.5215,265.28v-6.4615h-2.3809V265.28H738.54V251.1571h2.6006v5.541h2.3809v-5.541h2.6006V265.28Z" />
                                    <path className="st8" d="M755.8818,264.64a4.3382,4.3382,0,0,1-3.0605.94,4.5141,4.5141,0,0,1-2.94-.82,2.2771,2.2771,0,0,1-.7-.88,3.8172,3.8172,0,0,1-.3008-1.62v-7.9218a3.3981,3.3981,0,0,1,.8808-2.5606,4.5831,4.5831,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.91,3.91,0,0,1,.32,1.88V262.26A3.0669,3.0669,0,0,1,755.8818,264.64Zm-1.8007-10.062a2.4706,2.4706,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4369,1.4369,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.23,2.23,0,0,0,.24,1.3,1.3412,1.3412,0,0,0,1.06.42,1.5245,1.5245,0,0,0,.82-.24c.4805-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M759.46,265.28V251.1571h2.6005V263.14h3.8213V265.28Z" />
                                    <path className="st8" d="M767.84,265.28V251.1571h6.8418v2.1406H770.44v3.3208h4.001v2.14H770.44V263.1h4.4414V265.28Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                <path className="st8" d="M690.7,229.14h4.26l0.58,0.04l1.08,0.26l0.62,0.38l0.48,0.52l0.34,0.66l0.28,2.04l-0.1,1.24l-0.42,1.06
			                        l-0.58,0.66l-0.52,0.18l0.82,0.28l0.5,0.44l0.32,0.62l0.28,1.94l-0.22,1.92l-0.62,1.04l-0.58,0.46l-0.62,0.26l-1.32,0.14h-4.58
			                        V229.14z M694.15,234.98l0.76-0.1l0.32-0.18l0.26-0.32l0.1-1.42l-0.1-0.72l-0.6-0.56l-0.7-0.14h-0.8v3.44H694.15z M694.35,240.94
			                        l0.82-0.12l0.44-0.34l0.22-0.76l0.04-0.8l-0.26-1.22l-0.48-0.34l-0.82-0.12h-0.92v3.7H694.35z"/>
                                <path className="st8" d="M700.68,243.28v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H700.68z" />
                                <path className="st8" d="M709.42,243.28v-14.12h2.88v14.12H709.42z" />
                                <path className="st8" d="M716.08,243.28v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H716.08z" />
                                <path className="st8" d="M728.56,243.28l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92v-14.12h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H728.56z M727.32,235.22l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42
			                        l-0.38-0.5l-1.1-0.12h-0.92v3.66H727.32z"/>
                                <path className="st8" d="M738.68,243.28l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H738.68z M737.1,233.68l-0.24-2.12
			                        l-0.26,2.14l-0.54,3.38h1.58L737.1,233.68z"/>
                                <path className="st8" d="M748.71,243.28l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			                        l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			                        l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			                        l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H748.71z"/>
                                <path className="st8" d="M670.12,265.28V263l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H670.12z" />
                                <path className="st8" d="M688.71,260.2l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V260.2z"/>
                                <path className="st8" d="M700.87,265.26v-5.84l0.02-3.74l0.14-2.06l-0.14,0.96l-0.26,1.54l-0.32,1.84l-1.56,7.3l-2.7-0.02l-1.58-7.1
			                        l-0.38-2.4l-0.26-1.52l-0.12-1.08l0.04,1.38l0.16,2.78l-0.04,7.98l-2.68-0.02l-0.08-14.1l4.18-0.02l1.62,7.94l0.38,2l0.12,1.46
			                        l0.18-1.76l0.2-1.3l1.62-8.32h4.14l0.04,14.12L700.87,265.26z"/>
                                <path className="st8" d="M717.1,265.28l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			                        l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			                        l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			                        l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H717.1z"/>
                                <path className="st8" d="M727.47,265.28l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H727.47z M725.88,255.68
			                        l-0.24-2.12l-0.26,2.14l-0.54,3.38h1.58L725.88,255.68z"/>
                                <path className="st8" d="M737.81,265.24l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L737.81,265.24z"/>
                                <path className="st8" d="M742.9,265.28V263l5.14-9.4h-4.54v-2.44h7.78v2.3l-5.28,9.4h5.04v2.42H742.9z" />
                                <path className="st8" d="M753.09,265.28v-14.12h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H753.09z" />
                                <path className="st8" d="M767.75,265.24l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L767.75,265.24z"/>
                            </g>}
                        </g>
                        <g id="Element_-_1" className={"model-circle model-option" + (this.state.changed ? this.setStyles(18) : '')}
                            onMouseEnter={() => this.hoverPie(18)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 18)}>
                            <circle id="Circle_3_" className="st7" cx="425.2" cy="85.04" r="85.04" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M399.144,73.43c-.04-.08-.08-.14-.12-.22a2.5614,2.5614,0,0,1-.26-1.14V68.849c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V73.43h-2.6006V59.307h5.2012a2.6436,2.6436,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52v1.52a3.54,3.54,0,0,1-.28,1.5205,1.6837,1.6837,0,0,1-.3.38,2.6043,2.6043,0,0,1-1.24.5,2.8537,2.8537,0,0,1,.78.28,2.3407,2.3407,0,0,1,1.14,2.18V71.55a3.2178,3.2178,0,0,0,.36,1.66.8525.8525,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.203,1.203,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1937,1.1937,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st8" d="M404.0225,73.43V59.307h6.8418v2.1406H406.623v3.3208h4.001v2.14h-4.001v4.3408h4.441V73.43Z" />
                                    <path className="st8" d="M420.584,71.57c-.32,1.14-1.26,2.1606-3.6607,2.1606a4.573,4.573,0,0,1-2.9008-.8,2.3953,2.3953,0,0,1-.74-.96,3.1151,3.1151,0,0,1-.28-1.46V68.4491h2.5806v1.64c0,.52-.04,1.12.46,1.4405a1.4383,1.4383,0,0,0,.8.2,1.2677,1.2677,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.8022,1.8022,0,0,0-.88-1.86l-2.4605-1.66a3.28,3.28,0,0,1-1.18-1.1606,3.8712,3.8712,0,0,1-.5-2.24,4.7515,4.7515,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4045,2.4045,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6435,2.6435,0,0,0,.28,1.36,2.3334,2.3334,0,0,0,.54.46l2.5205,1.7407a5.2865,5.2865,0,0,1,1.1607,1.1,3.3625,3.3625,0,0,1,.56,2.44A7.97,7.97,0,0,1,420.584,71.57Z" />
                                    <path className="st8" d="M430.2637,72.51a4.0825,4.0825,0,0,1-3.2608,1.2207,3.8314,3.8314,0,0,1-2.8808-1.02,2.8261,2.8261,0,0,1-.6-.96,4.2632,4.2632,0,0,1-.26-1.5606V59.307h2.6006V69.8094a2.8641,2.8641,0,0,0,.1406,1.1,1.1483,1.1483,0,0,0,1.08.6,1.2616,1.2616,0,0,0,.76-.22c.501-.34.44-1,.44-1.5V59.307h2.6211V70.2494A3.3222,3.3222,0,0,1,430.2637,72.51Z" />
                                    <path className="st8" d="M433.7422,73.43V59.307h2.6006V71.2894h3.82V73.43Z" />
                                    <path className="st8" d="M445.6631,61.3475V73.43h-2.62V61.3475h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M457.5439,71.57c-.32,1.14-1.2607,2.1606-3.6611,2.1606a4.5719,4.5719,0,0,1-2.9-.8,2.4006,2.4006,0,0,1-.74-.96,3.1064,3.1064,0,0,1-.28-1.46V68.4491h2.5811v1.64c0,.52-.04,1.12.46,1.4405a1.4392,1.4392,0,0,0,.8.2,1.2672,1.2672,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.7993,1.7993,0,0,0-.88-1.86l-2.46-1.66a3.2765,3.2765,0,0,1-1.1807-1.1606,3.8752,3.8752,0,0,1-.5-2.24,4.7438,4.7438,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.6807,0,3.4209,1.86a2.41,2.41,0,0,1,.22,1.18v1.4h-2.6005v-1.44c0-.42-.16-1.02-1.16-1.02-.6006,0-1.16.22-1.16,1.4a2.6413,2.6413,0,0,0,.28,1.36,2.3255,2.3255,0,0,0,.54.46l2.5205,1.7407a5.2876,5.2876,0,0,1,1.16,1.1,3.3623,3.3623,0,0,1,.56,2.44A7.9918,7.9918,0,0,1,457.5439,71.57Z" />
                                    <path className="st8" d="M383.8442,94.79a4.34,4.34,0,0,1-3.061.94,4.5151,4.5151,0,0,1-2.94-.82,2.2882,2.2882,0,0,1-.7-.88,3.8238,3.8238,0,0,1-.3-1.62V84.4877a3.3981,3.3981,0,0,1,.88-2.5606,4.5852,4.5852,0,0,1,3.0805-.92c2.0406,0,3.1211.84,3.541,1.66a3.9052,3.9052,0,0,1,.32,1.88V92.41A3.0648,3.0648,0,0,1,383.8442,94.79Zm-1.8007-10.062a2.4821,2.4821,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0806-.62,1.436,1.436,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2376,2.2376,0,0,0,.24,1.3,1.3432,1.3432,0,0,0,1.06.42,1.5235,1.5235,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M393.0039,95.43c-.04-.08-.08-.14-.12-.22a2.5611,2.5611,0,0,1-.26-1.14V90.849c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V95.43h-2.6005V81.307h5.2011a2.6434,2.6434,0,0,1,1.7207.46,2.7939,2.7939,0,0,1,.82,2.52V85.808a3.54,3.54,0,0,1-.28,1.52,1.69,1.69,0,0,1-.3.38,2.6048,2.6048,0,0,1-1.24.5,2.8523,2.8523,0,0,1,.78.28,2.3408,2.3408,0,0,1,1.14,2.18V93.55a3.2167,3.2167,0,0,0,.36,1.66.8525.8525,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.2032,1.2032,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1939,1.1939,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                    <path className="st8" d="M397.8818,95.43V81.307h2.5806V95.43Z" />
                                    <path className="st8" d="M403.3418,95.43V81.307h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.4409V95.43Z" />
                                    <path className="st8" d="M417.2827,95.43l-2.5-10.5821.22,10.5821h-2.4409V81.307h3.3408l2.5406,10.3623-.26-10.3623h2.38V95.43Z" />
                                    <path className="st8" d="M427.7031,83.3475V95.43H425.082V83.3475h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M436.543,95.43l-.34-2.3h-2.4l-.38,2.3h-2.6006l2.74-14.1231h2.9805L439.1836,95.43Zm-1.4805-10.5425-.94,6.0615h1.8Z" />
                                    <path className="st8" d="M445.5029,83.3475V95.43h-2.62V83.3475h-2.6006v-2.04h7.8418v2.04Z" />
                                    <path className="st8" d="M450.042,95.43V81.307h2.581V95.43Z" />
                                    <path className="st8" d="M462.3838,94.79a4.34,4.34,0,0,1-3.0606.94,4.5144,4.5144,0,0,1-2.94-.82,2.2781,2.2781,0,0,1-.7-.88,3.8187,3.8187,0,0,1-.3008-1.62V84.4877a3.3983,3.3983,0,0,1,.8809-2.5606,4.5829,4.5829,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9116,3.9116,0,0,1,.32,1.88V92.41A3.0669,3.0669,0,0,1,462.3838,94.79Zm-1.8008-10.062a2.47,2.47,0,0,0-.16-1.1406,1.1028,1.1028,0,0,0-1.08-.62,1.437,1.437,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2293,2.2293,0,0,0,.24,1.3,1.3411,1.3411,0,0,0,1.06.42,1.5243,1.5243,0,0,0,.82-.24c.4805-.34.46-.9.46-1.42Z" />
                                    <path className="st8" d="M470.6836,95.43l-2.501-10.5821.22,10.5821h-2.44V81.307h3.3408l2.5411,10.3623L471.583,81.307h2.3809V95.43Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                            <g>
                                <path className="st8" d="M393.23,73.43l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92V59.31h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H393.23z M391.99,65.37l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			                        l-1.1-0.12h-0.92v3.66H391.99z"/>
                                <path className="st8" d="M397.79,73.43V59.31h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H397.79z" />
                                <path className="st8" d="M411.11,63.37l-0.02-0.26l-0.02-0.94l-0.44-0.8l-0.58-0.22l-0.38,0.08l-0.46,0.26l-0.22,0.4l-0.06,0.58
			                        l0.06,0.6l0.28,0.44l1.16,1.04l1.22,0.84l1,0.76l1.02,1.2l0.36,0.96l0.14,1.26l-0.16,1.44l-0.58,1.3l-1,0.8l-1.52,0.52h-1
			                        l-1.42-0.12l-0.96-0.42l-0.78-0.78l-0.44-0.82l-0.18-0.92l-0.04-1.84h2.76v1.22l0.14,0.88l0.38,0.44l0.7,0.22l0.68-0.2l0.44-0.54
			                        l0.1-0.88l-0.1-0.92l-0.46-0.68l-0.94-0.72l-1.28-0.86l-0.9-0.68l-0.86-0.86l-0.38-0.86l-0.16-1.42l0.12-1.18l0.44-1.1l0.52-0.64
			                        l0.8-0.5l0.82-0.26l1.3-0.1l1.74,0.3l0.96,0.6l0.5,0.78l0.34,1.3l0.04,1.32L411.11,63.37z"/>
                                <path className="st8" d="M424.49,68.35l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V68.35z"/>
                                <path className="st8" d="M426.91,73.43V59.31h2.84v11.6h4.06v2.52H426.91z" />
                                <path className="st8" d="M436.91,73.43v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H436.91z" />
                                <path className="st8" d="M449.11,73.43l-0.68-3.9h-2.3l-0.66,3.9h-2.98l3.06-14.16h3.56l2.96,14.16H449.11z M447.53,63.83l-0.24-2.12
			                        l-0.26,2.14l-0.54,3.38h1.58L447.53,63.83z"/>
                                <path className="st8" d="M454.79,73.43v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H454.79z" />
                                <path className="st8" d="M460.99,68.57v-2.06h4.6v2.06H460.99z" />
                                <path className="st8" d="M379.69,91.11l-0.14,1.54l-0.36,1.08l-0.48,0.74l-0.64,0.52l-1.52,0.6l-1,0.04l-1.46-0.14l-1.3-0.66
			                        l-0.64-0.64l-0.52-1.08l-0.1-0.7l-0.2-4.56l0.06-2.3l0.08-1.16l0.22-1.08l0.5-0.9l0.72-0.66l1.04-0.42l1.66-0.22l1.22,0.12
			                        l0.94,0.34l1.1,0.92l0.54,1.08l0.16,0.68l0.14,3.62L379.69,91.11z M376.73,85.87l-0.06-1.1l-0.24-0.76l-0.36-0.46l-0.6-0.22
			                        l-0.66,0.2l-0.46,0.86l-0.1,0.94l-0.04,2.1l0.04,3.16l0.04,1.12l0.22,1.04l0.54,0.54l0.48,0.12l0.6-0.2l0.42-0.46l0.14-1.14
			                        l0.04-1.74V85.87z"/>
                                <path className="st8" d="M387.83,95.43l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92V81.31h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H387.83z M386.59,87.37l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			                        l-1.1-0.12h-0.92v3.66H386.59z"/>
                                <path className="st8" d="M392.39,95.43V81.31h2.88v14.12H392.39z" />
                                <path className="st8" d="M397.79,95.43V81.31h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H397.79z" />
                                <path className="st8" d="M412.45,95.39l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L412.45,95.39z"/>
                                <path className="st8" d="M419.43,95.43v-11.7h-2.32v-2.42h7.5v2.42h-2.32v11.7H419.43z" />
                                <path className="st8" d="M426.07,95.43V81.31h2.88v14.12H426.07z" />
                                <path className="st8" d="M431.47,95.43V81.31h6.66v2.56h-3.78v3.04h3.38v2.5h-3.38v3.44h3.78v2.58H431.47z" />
                                <path className="st8" d="M446.05,95.43l-0.22-0.5l-0.24-1.88v-0.82l-0.06-1.34l-0.1-0.4l-0.32-0.52l-0.46-0.18l-0.44-0.06h-1.06v5.7
			                        h-2.92V81.31h5.28l1,0.16l0.66,0.3l0.62,0.5l0.58,1.06l0.22,1.76l-0.04,0.94l-0.18,1.2l-0.62,0.98l-0.8,0.42l0.88,0.52l0.48,0.96
			                        l0.06,1.04v0.26l0.16,2.2l0.32,1.38l0.24,0.44H446.05z M444.81,87.37l0.4-0.14l0.4-0.46l0.1-1.14l-0.04-0.88l-0.14-0.42l-0.38-0.5
			                        l-1.1-0.12h-0.92v3.66H444.81z"/>
                                <path className="st8" d="M459.01,90.35l-0.1,1.98l-0.18,0.92l-0.38,0.82l-0.5,0.64l-0.76,0.46l-1.02,0.38l-1.32,0.08l-1.6-0.14
			                        l-1.08-0.48l-0.86-0.9l-0.44-0.78l-0.18-0.8l-0.06-1.7v-9.52h2.84v8.46l0.02,1.84l0.16,1.12l0.32,0.4l0.34,0.2l0.58,0.1l0.5-0.1
			                        l0.62-0.48l0.16-0.72l0.08-1.12v-1.28l-0.02-8.42h2.88V90.35z"/>
                                <path className="st8" d="M467.35,95.39l-2.58-7.48l-0.36-1.18l-0.54-1.92l0.18,1.36l0.08,1.54l0.1,7.7l-2.78,0.02l-0.02-14.14
			                        l3.36,0.02l2.34,6.92l0.58,1.74l0.44,1.7l-0.2-1.6l-0.06-1l-0.1-7.76h2.76v14.12L467.35,95.39z"/>
                                <path className="st8" d="M478.67,95.43l0.02-1.22l-0.32,0.48l-0.8,0.64l-0.62,0.28l-0.8,0.04l-0.82-0.04l-1.1-0.5l-0.72-0.76
			                        l-0.58-1.52l-0.08-2.38l-0.02-2l0.06-3l0.14-1.48l0.56-1.36l0.96-0.9l1.1-0.44l1.54-0.18l0.98,0.08l1.14,0.4l0.74,0.52l0.6,0.78
			                        l0.3,0.86l0.2,1.3l0.04,0.92h-2.82v-0.6l-0.14-1.36l-0.38-0.44l-0.78-0.28l-0.48,0.1l-0.52,0.44l-0.3,1.22l-0.06,1.86v3.62
			                        l0.04,1.18l0.26,1.1l0.44,0.42l0.62,0.16l0.6-0.18l0.46-0.44l0.2-1.08l0.04-1.1v-0.76h-1.46v-2.28h4.18v7.9H478.67z"/>
                            </g>}
                        </g>
                        <g id="Inner_Cicle_-_3" className={"model-circle model-option" + (this.state.changed ? this.setStyles(17) : '')}
                            onMouseEnter={() => this.hoverPie(17)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 17)}>
                            <circle id="Circle" className="st9" cx="425.2" cy="425.2" r="158.01" />
                            {SelectedLanguage === "en-GB" &&
                                <g>
                                    <path className="st8" d="M358.731,433.28c-.0479-.0957-.0957-.168-.1441-.2637a3.083,3.083,0,0,1-.312-1.3682v-3.8642c0-.2647,0-.5284-.0239-.8164-.0957-.9356-.792-1.1519-1.6079-1.1519h-1.4883V433.28h-3.12V416.3348h6.24a3.17,3.17,0,0,1,2.0645.5518c1.0078.7441.9838,1.92.9838,3.0244v1.8242a4.2462,4.2462,0,0,1-.3359,1.8242,2.0178,2.0178,0,0,1-.36.4561,3.1363,3.1363,0,0,1-1.4882.6,3.4192,3.4192,0,0,1,.936.3359,2.8083,2.8083,0,0,1,1.3682,2.6162v3.4561a3.8652,3.8652,0,0,0,.4321,1.9922,1.0128,1.0128,0,0,0,.2881.2637Zm-.5039-13.1285c0-.6484-.0962-.8642-.36-1.1284a1.4438,1.4438,0,0,0-1.08-.2641h-1.6323v4.6328h1.6323a1.4325,1.4325,0,0,0,1.1758-.4082c.24-.2881.2642-.5279.2642-1.2Z" />
                                    <path className="st8" d="M364.5864,433.28V416.3348h8.2085v2.5679h-5.0884v3.9844h4.8v2.5683h-4.8v5.2081h5.3287V433.28Z" />
                                    <path className="st8" d="M384.459,431.0472c-.3838,1.3682-1.5122,2.5928-4.3921,2.5928a5.4869,5.4869,0,0,1-3.48-.96,2.8772,2.8772,0,0,1-.8882-1.1524,3.7339,3.7339,0,0,1-.3359-1.7519v-2.4727h3.0962v1.9688c0,.624-.0479,1.3437.5522,1.7275a1.7254,1.7254,0,0,0,.96.24,1.5259,1.5259,0,0,0,1.08-.3115c.456-.36.456-1.1045.456-2.2568a2.7777,2.7777,0,0,0-.3359-1.56,2.8127,2.8127,0,0,0-.72-.6724l-2.9522-1.9922a3.9258,3.9258,0,0,1-1.416-1.392,4.6445,4.6445,0,0,1-.6-2.688,5.7037,5.7037,0,0,1,.4077-2.3282c.96-2.0644,3.8648-2.0644,4.2007-2.0644.5039,0,3.2163,0,4.104,2.2324a2.882,2.882,0,0,1,.2642,1.416v1.68h-3.12v-1.728c0-.5039-.1919-1.2241-1.3921-1.2241-.72,0-1.3921.2641-1.3921,1.68a3.167,3.167,0,0,0,.3359,1.6319,2.8155,2.8155,0,0,0,.648.5522l3.0244,2.0884a6.328,6.328,0,0,1,1.3921,1.32,4.033,4.033,0,0,1,.6719,2.9277A9.559,9.559,0,0,1,384.459,431.0472Z" />
                                    <path className="st8" d="M396.2427,425.3837c-.5039.624-1.2241.84-2.76.84h-2.64V433.28h-3.1441V416.3348h6.2886a4.4271,4.4271,0,0,1,.84.0718,2.3672,2.3672,0,0,1,1.6562,1.2,6.1865,6.1865,0,0,1,.36,2.5444v2.232C396.8428,423.9672,396.771,424.7352,396.2427,425.3837Zm-2.4722-4.8247a2.0459,2.0459,0,0,0-.24-1.3682,1.3422,1.3422,0,0,0-1.1524-.36h-1.5361v4.9683h1.5122c.6479,0,1.0083-.0718,1.2-.3359a2.1989,2.1989,0,0,0,.2158-1.1763Z" />
                                    <path className="st8" d="M408.0029,432.5121a5.21,5.21,0,0,1-3.6723,1.1279,5.4161,5.4161,0,0,1-3.5284-.9844,2.741,2.741,0,0,1-.84-1.0556,4.5858,4.5858,0,0,1-.36-1.9444v-9.5044a4.0741,4.0741,0,0,1,1.0561-3.0722,5.4978,5.4978,0,0,1,3.6963-1.1045c2.4482,0,3.7441,1.0083,4.248,1.9922a4.6873,4.6873,0,0,1,.3843,2.2563v9.4326A3.6786,3.6786,0,0,1,408.0029,432.5121Zm-2.16-12.0733a2.97,2.97,0,0,0-.1924-1.3676,1.3219,1.3219,0,0,0-1.2959-.7442,1.7258,1.7258,0,0,0-1.0083.312c-.6958.48-.6,1.2242-.6,1.92v8.5683a2.6854,2.6854,0,0,0,.2881,1.5606,1.6128,1.6128,0,0,0,1.2724.5039,1.8336,1.8336,0,0,0,.9839-.2881c.5762-.4082.5523-1.08.5523-1.7041Z" />
                                    <path className="st8" d="M417.9619,433.28l-3-12.6968.2642,12.6968h-2.9282V416.3348h4.0083l3.0483,12.4331-.312-12.4331h2.8559V433.28Z" />
                                    <path className="st8" d="M434.1621,431.0472c-.3838,1.3682-1.5117,2.5928-4.3916,2.5928a5.4871,5.4871,0,0,1-3.4805-.96,2.8734,2.8734,0,0,1-.8886-1.1524,3.74,3.74,0,0,1-.336-1.7519v-2.4727h3.0967v1.9688c0,.624-.0478,1.3437.5518,1.7275a1.7273,1.7273,0,0,0,.96.24,1.5249,1.5249,0,0,0,1.08-.3115c.4561-.36.4561-1.1045.4561-2.2568a2.778,2.778,0,0,0-.336-1.56,2.8128,2.8128,0,0,0-.72-.6724l-2.9522-1.9922a3.9219,3.9219,0,0,1-1.416-1.392,4.6438,4.6438,0,0,1-.6006-2.688,5.7056,5.7056,0,0,1,.4083-2.3282c.96-2.0644,3.8642-2.0644,4.2-2.0644.504,0,3.2168,0,4.1045,2.2324a2.8888,2.8888,0,0,1,.2637,1.416v1.68h-3.12v-1.728c0-.5039-.1914-1.2241-1.3916-1.2241-.7207,0-1.3926.2641-1.3926,1.68a3.1728,3.1728,0,0,0,.336,1.6319,2.8293,2.8293,0,0,0,.6484.5522l3.0244,2.0884a6.329,6.329,0,0,1,1.3916,1.32,4.033,4.033,0,0,1,.6719,2.9277A9.559,9.559,0,0,1,434.1621,431.0472Z" />
                                    <path className="st8" d="M437.4014,433.28V416.3348h3.0966V433.28Z" />
                                    <path className="st8" d="M453.1934,430.4232a2.8038,2.8038,0,0,1-1.4873,2.52,5.8337,5.8337,0,0,1-2.4.336h-5.3526V416.3348H449.21a4.1265,4.1265,0,0,1,2.8076.648,3.1082,3.1082,0,0,1,1.0078,2.8081v1.5122a7.1777,7.1777,0,0,1-.0957,1.2,2.0044,2.0044,0,0,1-1.8721,1.6562,2.4749,2.4749,0,0,1,1.68.816,3.4718,3.4718,0,0,1,.4805,2.28v2.4C453.2178,429.9193,453.1934,430.184,453.1934,430.4232Zm-3.2637-10.104a2.7253,2.7253,0,0,0-.0723-.7441c-.2158-.792-.8877-.8164-1.5361-.8164h-1.2481v4.2724h1.128a6,6,0,0,0,.84-.0478c.6006-.12.8887-.48.8887-1.5122Zm.2158,6.8159a2.3408,2.3408,0,0,0-.0957-.9355c-.24-.6963-1.0088-.72-1.6328-.72h-1.3438v5.352h1.32a4.2785,4.2785,0,0,0,.7919-.0478c.6719-.0957.96-.5039.96-1.6563Z" />
                                    <path className="st8" d="M456.5049,433.28V416.3348h3.0957V433.28Z" />
                                    <path className="st8" d="M463.0566,433.28V416.3348h3.12v14.3765h4.5839V433.28Z" />
                                    <path className="st8" d="M473.1123,433.28V416.3348h3.0957V433.28Z" />
                                    <path className="st8" d="M484.7764,418.7831V433.28h-3.1446V418.7831h-3.12v-2.4483h9.4092v2.4483Z" />
                                    <path className="st8" d="M496.3682,428.2631V433.28h-3.1924v-5.2325l-3.3838-11.7124H493.08l1.7275,8.5445,1.6563-8.5445h3.289Z" />
                                </g>
                            }
                            {SelectedLanguage === "de-DE" &&
                                <g>
                                    <path className="st8" d="M348.25,433.26l-3.5-16.9l3.58-0.05l1.51,8.86l0.26,1.85l0.12,1.85l0.17-2.04l0.22-1.44l1.51-9.05h3.6
			                        l-3.77,16.95L348.25,433.26z"/>
                                    <path className="st8" d="M357.46,433.29v-16.95h7.99v3.07h-4.54v3.65h4.06v3h-4.06v4.13h4.54v3.1H357.46z" />
                                    <path className="st8" d="M374.96,433.29l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			                        v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			                        l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H374.96z M373.47,423.61l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			                        l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H373.47z"/>
                                    <path className="st8" d="M387.1,433.29l-0.82-4.68h-2.76l-0.79,4.68h-3.58l3.67-16.99h4.27l3.55,16.99H387.1z M385.2,421.77
			                        l-0.29-2.54l-0.31,2.57l-0.65,4.06h1.9L385.2,421.77z"/>
                                    <path className="st8" d="M399.51,433.24l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			                        l4.03,0.02l2.81,8.3l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L399.51,433.24z"/>
                                    <path className="st8" d="M407.88,433.29v-14.04h-2.78v-2.9h9v2.9h-2.78v14.04H407.88z" />
                                    <path className="st8" d="M425.24,433.31l-1.49-8.69l-0.31-3.67l-0.24,2.64l-0.31,2.09l-1.42,7.61l-3.94,0.02l-2.95-16.97l3.36,0.02
			                        l1.58,10.27l0.12,1.32l0.05,1.51l0.14-2.3l0.34-2.35l1.51-8.45l3.72-0.02l1.37,8.86l0.26,2.64l0.05,1.63l0.22-2.69l1.56-10.44
			                        h3.22l-2.95,16.95L425.24,433.31z"/>
                                    <path className="st8" d="M443.55,428.1l-0.17,1.85l-0.43,1.3l-0.58,0.89l-0.77,0.62l-1.82,0.72l-1.2,0.05l-1.75-0.17l-1.56-0.79
			                        l-0.77-0.77l-0.62-1.3l-0.12-0.84l-0.24-5.47l0.07-2.76l0.1-1.39l0.26-1.3l0.6-1.08l0.86-0.79l1.25-0.5l1.99-0.26l1.46,0.14
			                        l1.13,0.41l1.32,1.1l0.65,1.3l0.19,0.82l0.17,4.34L443.55,428.1z M440,421.81l-0.07-1.32l-0.29-0.91l-0.43-0.55l-0.72-0.26
			                        l-0.79,0.24l-0.55,1.03l-0.12,1.13l-0.05,2.52l0.05,3.79l0.05,1.34l0.26,1.25l0.65,0.65l0.58,0.14l0.72-0.24l0.5-0.55l0.17-1.37
			                        l0.05-2.09V421.81z"/>
                                    <path className="st8" d="M453.32,433.29l-0.26-0.6l-0.29-2.26v-0.98l-0.07-1.61l-0.12-0.48l-0.38-0.62l-0.55-0.22l-0.53-0.07h-1.27
			                        v6.84h-3.5v-16.95h6.34l1.2,0.19l0.79,0.36l0.74,0.6l0.7,1.27l0.26,2.11l-0.05,1.13l-0.22,1.44l-0.74,1.18l-0.96,0.5l1.06,0.62
			                        l0.58,1.15l0.07,1.25v0.31l0.19,2.64l0.38,1.66l0.29,0.53H453.32z M451.83,423.61l0.48-0.17l0.48-0.55l0.12-1.37l-0.05-1.06
			                        l-0.17-0.5l-0.46-0.6l-1.32-0.14h-1.1v4.39H451.83z"/>
                                    <path className="st8" d="M460.3,433.29v-14.04h-2.78v-2.9h9v2.9h-2.78v14.04H460.3z" />
                                    <path className="st8" d="M478.35,427.19l-0.12,2.38l-0.22,1.1l-0.46,0.98l-0.6,0.77l-0.91,0.55l-1.22,0.46l-1.58,0.1l-1.92-0.17
			                        l-1.3-0.58l-1.03-1.08l-0.53-0.94l-0.22-0.96l-0.07-2.04v-11.42h3.41v10.15l0.02,2.21l0.19,1.34l0.38,0.48l0.41,0.24l0.7,0.12
			                        l0.6-0.12l0.74-0.58l0.19-0.86l0.1-1.34v-1.54l-0.02-10.1h3.46V427.19z"/>
                                    <path className="st8" d="M488.36,433.24l-3.1-8.98l-0.43-1.42l-0.65-2.3l0.22,1.63l0.1,1.85l0.12,9.24l-3.34,0.02l-0.02-16.97
			                        l4.03,0.02l2.81,8.3l0.7,2.09l0.53,2.04l-0.24-1.92l-0.07-1.2l-0.12-9.31h3.31v16.95L488.36,433.24z"/>
                                    <path className="st8" d="M501.94,433.29l0.02-1.46l-0.38,0.58l-0.96,0.77l-0.74,0.34l-0.96,0.05l-0.98-0.05l-1.32-0.6l-0.86-0.91
			                        l-0.7-1.82l-0.1-2.86l-0.02-2.4l0.07-3.6l0.17-1.78l0.67-1.63l1.15-1.08l1.32-0.53l1.85-0.22l1.18,0.1l1.37,0.48l0.89,0.62
			                        l0.72,0.94l0.36,1.03l0.24,1.56l0.05,1.1h-3.38v-0.72l-0.17-1.63l-0.46-0.53l-0.94-0.34l-0.58,0.12l-0.62,0.53l-0.36,1.46
			                        l-0.07,2.23v4.34l0.05,1.42l0.31,1.32l0.53,0.5l0.74,0.19l0.72-0.22l0.55-0.53l0.24-1.3l0.05-1.32v-0.91h-1.75v-2.74h5.02v9.48
			                        H501.94z"/>
                                </g>}
                        </g>
                        <g id="Inner_Cicle_-_1" onClick={() => this.switchLevel()}>
                            <path id="Cercle" className="st10" d="M425.2,170.29c-140.78,0-254.91,114.13-254.91,254.91S284.42,680.1,425.2,680.1
		                        S680.1,565.98,680.1,425.2S565.98,170.29,425.2,170.29z M425.2,582.87c-87.08,0-157.67-70.59-157.67-157.67
		                        c0-87.08,70.59-157.67,157.67-157.67c87.08,0,157.67,70.59,157.67,157.67C582.87,512.28,512.28,582.87,425.2,582.87z"/>
                        </g>
                        <g id="Lines">
                            <g id="Lines_-_3">
                                <polyline className="st11" points="582.03,408.82 646.04,298.1 518.66,297.95 		" />
                                <polyline className="st11" points="361.26,569.36 425.2,680.1 489.18,569.43 		" />
                                <polyline className="st11" points="331.72,297.73 204.35,297.59 268.4,408.52 		" />
                                <polyline className="st11" points="332.1,552.46 204.43,552.46 268.38,441.75 		" />
                                <polyline className="st11" points="582.01,441.75 645.97,552.46 518.28,552.46 		" />
                                <polyline className="st11" points="361.21,281.05 425.2,170.29 489.18,281.05 		" />
                            </g>
                            <g id="Lines_-_2">
                                <polyline className="st11" points="204.35,297.59 425.2,170.29 646.04,298.1 645.97,552.46 425.2,680.1 204.35,552.6 204.35,297.59
			                        "/>
                            </g>
                            <g id="Lines_-_1">
                                <line className="st11" x1="646.04" y1="297.59" x2="562.26" y2="345.96" />
                                <line className="st11" x1="288.58" y1="503.97" x2="204.35" y2="552.6" />
                                <path className="st11" d="M425.2,680.1" />
                                <path className="st11" d="M425.2,170.08" />
                                <line className="st11" x1="425.2" y1="170.08" x2="425.2" y2="267.52" />
                                <line className="st11" x1="425.2" y1="582.57" x2="425.2" y2="680.1" />
                                <line className="st11" x1="646.04" y1="552.6" x2="561.81" y2="503.97" />
                                <line className="st11" x1="288.67" y1="346.27" x2="204.35" y2="297.59" />
                            </g>
                        </g>
                    </g>

                </svg>
                <Zoom onClick={() => this.ToggleFullsreen()} />
                <div className="close-zoom"><FullscreenClose onClick={() => this.ToggleFullsreen()} /></div>

                <div className="close-zoom pos-bottom">
                    <div className={"scroll-indication" + (this.state.showIndicator ? ' show' : '')} onClick={() => this.ToggleFullsreen()}>
                        <div className="scroll-indication-text">Close for details</div>
                        <div className="scroll-indication-arrow"><ArrowDown /></div>
                    </div>
                </div>
                <div className="copyright-malik">&copy; Malik 2023</div>
                {/*<div className="close-zoom pos-bottom"><FullscreenClose onClick={() => this.ToggleFullsreen()} /></div>*/}
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelSet,
        SelectedPath
    } = state.model;

    return {
        SelectedLanguage,
        ModelSet,
        SelectedPath
    };
}

const actionCreators = {
    setSelectedPath: modelActions.setSelectedPath
};

const connectedModel3 = connect(mapState, actionCreators)(Model3);
export { connectedModel3 as Model3 };
