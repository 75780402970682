import {AppBarLoader} from "../../components/layout/AppBar/app-bar-loader";

export async function authHeader(type?: string) {
    
    const appBarLoader = new AppBarLoader()
    const accessToken = await appBarLoader.getAccessToken()

    if (accessToken !== null) {
        const idToken = accessToken.idToken;
        const result: any = {
            'Accept-Language': 'de-CH',
            'Authorization': `Bearer ${idToken}`
        }
        
        if (type) {
            result['Content-Type'] = 'application/json';
        }
        
        return result;
    }
    
    return '';
}