import { mkpConstants } from '../_constants';

const initialState = {
    SelectedModel: null,
    SelectedIndexContainerId: -1,
    SelectedIndexTopicId: -1,
    SelectedIndexBiteId: -1,
    OpenContext: {
        Type: "",
        Id: -1
    },


    ModelState: -1,
    indexTopics: [],
    indexBites: [],
    collapseModelC: false,
}

export function mkp(state = initialState, action) {
    switch (action.type) {

        case mkpConstants.SELECT_MODEL:
            return {
                ...state,
                SelectedModel: action.model
            };

        case mkpConstants.SET_SELECTED_INDEX_CONTAINER_ID:
            return {
                ...state,
                SelectedIndexContainerId: action.id
            };
        case mkpConstants.SET_SELECTED_INDEX_TOPIC_ID:
            return {
                ...state,
                SelectedIndexTopicId: action.id
            };
        case mkpConstants.SET_SELECTED_INDEX_BITE_ID:
            return {
                ...state,
                SelectedIndexBiteId: action.id
            };
        case mkpConstants.SET_OPEN_CONTEXT:
            return {
                ...state,
                OpenContext: action.id
            };

        //check bottom

        case mkpConstants.SET_MODEL_STATE:
            return {
                ...state,
                ModelState: action.model
            };

        /*case mkpConstants.MODEL_CLOSE:
            return {
                ...state,
                collapseModelC: true,
                modelHasClosed: false
            };
        case mkpConstants.MODEL_OPEN:
            return {
                ...state,
                collapseModelC: false,
                modelHasClosed: true
            };*/

        

        case mkpConstants.CONTAINERS_REQUEST:
            return {
                ...state,
                indexLoader: true
            };
        case mkpConstants.CONTAINERS_SUCCESS:
            return {
                ...state,
                indexContainers: action.containers.data,
                indexTopics: [],
                indexBites: [],
                indexLoader: false

            };
        case mkpConstants.CONTAINERS_FAILURE:
            return {
                ...state,
                indexLoader: false
            };

        case mkpConstants.MODEL_CONTAINERS_REQUEST:
            return {
                ...state,
                indexLoader: true
            };
        case mkpConstants.MODEL_CONTAINERS_SUCCESS:
            return {
                ...state,
                indexContainers: action.containers.data,
                indexTopics: [],
                indexBites: [],
                indexLoader: false

            };
        case mkpConstants.MODEL_CONTAINERS_FAILURE:
            return {
                ...state,
                indexLoader: false
            };

        case mkpConstants.CONTAINER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mkpConstants.CONTAINER_SUCCESS:
            return {
                ...state,
                Container: action.container.data,
                loading: false

            };
        case mkpConstants.CONTAINER_FAILURE:
            return {
                ...state,
                loading: false
            };

        case mkpConstants.CONTAINER_TOPICS_REQUEST:
            return {
                ...state,
                loadingTopics: true
            };
        case mkpConstants.CONTAINER_TOPICS_SUCCESS:
            return {
                ...state,
                ContainerTopics: action.containerTopics.data,
                loadingTopics: false

            };
        case mkpConstants.CONTAINER_TOPICS_FAILURE:
            return {
                ...state,
                loadingTopics: false
            };

        case mkpConstants.TOPICS_INDEX_REQUEST:
            return {
                ...state,
                indexLoader: true
            };
        case mkpConstants.TOPICS_INDEX_SUCCESS:
            return {
                ...state,
                indexTopics: action.topics.data,
                indexBites: [],
                indexLoader: false

            };
        case mkpConstants.TOPICS_INDEX_FAILURE:
            return {
                ...state,
                indexLoader: false
            };

        case mkpConstants.TOPIC_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mkpConstants.TOPIC_SUCCESS:
            return {
                ...state,
                Topic: action.topic.data,
                loading: false

            };
        case mkpConstants.TOPIC_FAILURE:
            return {
                ...state,
                loading: false
            };

        case mkpConstants.TOPIC_NAME_REQUEST:
            return {
                ...state,
            };
        case mkpConstants.TOPIC_NAME_SUCCESS:
            return {
                ...state,
                topicName: (action.topic.data.items.length > 0 ? action.topic.data.items[0].title : ''),
                topicId: (action.topic.data.items.length > 0 ? action.topic.data.items[0].id : -1),

            };
        case mkpConstants.TOPIC_NAME_FAILURE:
            return {
                ...state,
            };
        case mkpConstants.TOPIC_BITES_REQUEST:
            return {
                ...state,
                loadingBites: true,
            };
        case mkpConstants.TOPIC_BITES_SUCCESS:
            return {
                ...state,
                TopicBites: action.topicBites.data,
                loadingBites: false

            };
        case mkpConstants.TOPIC_BITES_FAILURE:
            return {
                ...state,
                loadingBites: false
            };

        case mkpConstants.TOPIC_BITES_LIST_REQUEST:
            return {
                ...state,
                loadingBitesList: true,
            };
        case mkpConstants.TOPIC_BITES_LIST_SUCCESS:
            return {
                ...state,
                TopicBitesList: action.topicBites.data,
                loadingBitesList: false

            };
        case mkpConstants.TOPIC_BITES_LIST_FAILURE:
            return {
                ...state,
                loadingBitesList: false
            };

        case mkpConstants.TOPIC_BITES_INDEX_REQUEST:
            return {
                ...state,
                indexLoader: true
            };
        case mkpConstants.TOPIC_BITES_INDEX_SUCCESS:
            return {
                ...state,
                indexBites: action.bites.data,
                indexLoader: false

            };
        case mkpConstants.TOPIC_BITES_INDEX_FAILURE:
            return {
                ...state,
                indexLoader: false
            };

        case mkpConstants.BITE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case mkpConstants.BITE_SUCCESS:
            return {
                ...state,
                Bite: action.bite.data,
                loading: false

            };
        case mkpConstants.BITE_FAILURE:
            return {
                ...state,
                loading: false
            };

        case mkpConstants.BITE_PAGES_REQUEST:
            return {
                ...state,
                //Pages: [],
                loading: true,
                loadingPages: true
            };
        case mkpConstants.BITE_PAGES_SUCCESS:
            return {
                ...state,
                Pages: action.pages.data,
                loading: false,
                loadingPages: false
            };
        case mkpConstants.BITE_PAGES_FAILURE:
            return {
                ...state,
                loading: false,
                loadingPages: false
            };

        case mkpConstants.BITE_PAGE_COMPLETED_REQUEST:
            return {
                ...state,
            };
        case mkpConstants.BITE_PAGE_COMPLETED_SUCCESS:
            return {
                ...state,
                PageCompleted: action.data
            };
        case mkpConstants.BITE_PAGE_COMPLETED_FAILURE:
            return {
                ...state,
                PageCompleted: action.data
            };
        case mkpConstants.GET_RELATED_BITES_REQUEST:
            return {
                ...state,
                loadingBites: true
            };
        case mkpConstants.GET_RELATED_BITES_SUCCESS:
            return {
                ...state,
                TopicBites: action.topicBites.data,
                loadingBites: false
            };
        case mkpConstants.GET_RELATED_BITES_FAILURE:
            return {
                ...state,
                loadingBites: false
            };
        case mkpConstants.GET_RELATED_TOPICS_REQUEST:
            return {
                ...state,
                loadingTopics: true
            };
        case mkpConstants.GET_RELATED_TOPICS_SUCCESS:
            return {
                ...state,
                ContainerTopics: action.containerTopics.data,
                loadingTopics: false
            };
        case mkpConstants.GET_RELATED_TOPICS_FAILURE:
            return {
                ...state,
                loadingTopics: false
            };
        //likes
        case mkpConstants.LIKE_REQUEST:
            return {
                ...state,
                loadingLikes: true
            };
        case mkpConstants.LIKE_SUCCESS:
            return {
                ...state,
                likes: action.data,
                loadingLikes: false

            };
        case mkpConstants.LIKE_FAILURE:
            return {
                ...state,
                loadingLikes: false
            };
        case mkpConstants.LIKE_ADD_REQUEST:
            return {
                ...state,
                isLiked: false,
                likeChange: true
            };
        case mkpConstants.LIKE_ADD_SUCCESS:
            return {
                ...state,
                isLiked: true,
                ContentId: action.like.data.id,
                likeChange: false

            };
        case mkpConstants.LIKE_ADD_FAILURE:
            return {
                ...state,
                isLiked: false,
                likeChange: false
            };
        case mkpConstants.LIKE_DEL_REQUEST:
            return {
                ...state,
                isLiked: true,
                likeChange: true
            };
        case mkpConstants.LIKE_DEL_SUCCESS:
            return {
                ...state,
                isLiked: false,
                likeChange: false

            };
        case mkpConstants.LIKE_DEL_FAILURE:
            return {
                ...state,
                likeChange: false
            };
        //favorites
        case mkpConstants.FAVORITE_REQUEST:
            return {
                ...state,
                loadingFavorites: true
            };
        case mkpConstants.FAVORITE_SUCCESS:
            return {
                ...state,
                favorites: action.data,
                loadingFavorites: false

            };
        case mkpConstants.FAVORITE_FAILURE:
            return {
                ...state,
                loadingFavorites: false
            };
        case mkpConstants.FAVORITE_ADD_REQUEST:
            return {
                ...state,
                isFavorite: false,
                favoriteChange: true
            };
        case mkpConstants.FAVORITE_ADD_SUCCESS:
            return {
                ...state,
                isFavorite: true,
                ContentId: action.favorite.data.id,
                favoriteChange: false

            };
        case mkpConstants.FAVORITE_ADD_FAILURE:
            return {
                ...state,
                isFavorite: false,
                favoriteChange: false
            };
        case mkpConstants.FAVORITE_DEL_REQUEST:
            return {
                ...state,
                isFavorite: true,
                favoriteChange: true
            };
        case mkpConstants.FAVORITE_DEL_SUCCESS:
            return {
                ...state,
                isFavorite: false,
                favoriteChange: false

            };
        case mkpConstants.FAVORITEE_DEL_FAILURE:
            return {
                ...state,
                favoriteChange: false
            };
        default:
            return state
    }
}

export default mkp