import {applyMiddleware, compose, createStore} from 'redux';
import rootReducer from '../_reducers';

import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reduxThunk from "redux-thunk";
import {layoutConstants, themeConstants} from "../_constants";
import AppBarStateObservable from "../../components/layout/AppBar/AppBarStateObservable";
import {Theme} from "../../components/layout/AppBar/app-bar-state";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["layout", "theme"],
};

export const appBarStateObservable = new AppBarStateObservable();

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

export default () => {
    let store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(reduxThunk))
    );
    let persistor = persistStore(store);

    appBarStateObservable.attach(appBarState => {
        // process language changing
        const state = store.getState();
        if (state.layout.SelectedLanguage !== appBarState.language.locale) {
            store.dispatch({type: layoutConstants.SET_LANGUAGE, language: appBarState.language.locale})
        }

        // process theme change
        const currentModeIsLight = state.theme.Theme === 'lightMode';


        if (currentModeIsLight && appBarState.theme === Theme.Dark) {
            store.dispatch({ type: themeConstants.UPDATE_THEME, theme: 'darkMode' })

        } else if (!currentModeIsLight && appBarState.theme === Theme.Light) {
            store.dispatch({ type: themeConstants.UPDATE_THEME, theme: 'lightMode' })
        }
    })

    return {store, persistor};
};