import React, {Component} from 'react';

//css
import './Assets/scss/main.scss';

import 'bootstrap/dist/css/bootstrap.min.css';

//components
import {Layout} from './components/layout/Layout';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }
    
    render(){
        if(this.state.hasError){
            return <div className="container vh-100">
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="text-danger font-weight-bold">Error has been acquired. Please refresh the page or contact admin.</div>
                </div>
            </div>
        } else
        {
            return <Layout/>
        }
    }
}

export default App;