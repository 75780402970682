export const mkpConstants = {

    // caroussel
    SELECT_MODEL: 'SELECT_MODEL',
    MODEL_CLOSE: 'MODEL_CLOSE',
    MODEL_OPEN: 'MODEL_OPEN',
    SET_MODEL_STATE: 'SET_MODEL_STATE',
    SET_OPEN_CONTEXT: 'SET_OPEN_CONTEXT',

    //index
    SET_SELECTED_INDEX_CONTAINER_ID: 'SET_SELECTED_INDEX_CONTAINER_ID',
    SET_SELECTED_INDEX_TOPIC_ID: 'SET_SELECTED_INDEX_TOPIC_ID',
    SET_SELECTED_INDEX_BITE_ID: 'SET_SELECTED_INDEX_BITE_ID',

    //get all containers
    CONTAINERS_REQUEST: 'CONTAINERS_GET_REQUEST',
    CONTAINERS_SUCCESS: 'CONTAINERS_GET_SUCCESS',
    CONTAINERS_FAILURE: 'CONTAINERS_GET_FAILURE',

    //get specific container
    CONTAINER_REQUEST: 'CONTAINER_GET_REQUEST',
    CONTAINER_SUCCESS: 'CONTAINER_GET_SUCCESS',
    CONTAINER_FAILURE: 'CONTAINER_GET_FAILURE',

    //Index: get specific model containers
    MODEL_CONTAINERS_REQUEST: 'MODEL_CONTAINERS_GET_REQUEST',
    MODEL_CONTAINERS_SUCCESS: 'MODEL_CONTAINERS_GET_SUCCESS',
    MODEL_CONTAINERS_FAILURE: 'MODEL_CONTAINERS_GET_FAILURE',

    //get container topics
    CONTAINER_TOPICS_REQUEST: 'CONTAINER_TOPICS_GET_REQUEST',
    CONTAINER_TOPICS_SUCCESS: 'CONTAINER_TOPICS_GET_SUCCESS',
    CONTAINER_TOPICS_FAILURE: 'CONTAINER_TOPICS_GET_FAILURE',

    //get container Topics for idnex
    TOPICS_INDEX_REQUEST: 'TOPICS_INDEX_GET_REQUEST',
    TOPICS_INDEX_SUCCESS: 'TOPICS_INDEX_GET_SUCCESS',
    TOPICS_INDEX_FAILURE: 'TOPICS_INDEX_GET_FAILURE',

    //get specific Topic
    TOPIC_REQUEST: 'TOPIC_GET_REQUEST',
    TOPIC_SUCCESS: 'TOPIC_GET_SUCCESS',
    TOPIC_FAILURE: 'TOPIC_GET_FAILURE',

    //get Topic name
    TOPIC_NAME_REQUEST: 'TOPIC_NAME_GET_REQUEST',
    TOPIC_NAME_SUCCESS: 'TOPIC_NAME_GET_SUCCESS',
    TOPIC_NAME_FAILURE: 'TOPIC_NAME_GET_FAILURE',

    //get topic bites
    TOPIC_BITES_REQUEST: 'TOPIC_BITES_GET_REQUEST',
    TOPIC_BITES_SUCCESS: 'TOPIC_BITES_GET_SUCCESS',
    TOPIC_BITES_FAILURE: 'TOPIC_BITES_GET_FAILURE',

    //get topic bites List for topic page
    TOPIC_BITES_LIST_REQUEST: 'TOPIC_BITES_LIST_GET_REQUEST',
    TOPIC_BITES_LIST_SUCCESS: 'TOPIC_BITES_LIST_GET_SUCCESS',
    TOPIC_BITES_LIST_FAILURE: 'TOPIC_BITES_LIST_GET_FAILURE',

    //get topic bites for index
    TOPIC_BITES_INDEX_REQUEST: 'TOPIC_BITES_INDEX_GET_REQUEST',
    TOPIC_BITES_INDEX_SUCCESS: 'TOPIC_BITES_INDEX_GET_SUCCESS',
    TOPIC_BITES_INDEX_FAILURE: 'TOPIC_BITES_INDEX_GET_FAILURE',

    //get specifi bite
    BITE_REQUEST: 'BITE_GET_REQUEST',
    BITE_SUCCESS: 'BITE_GET_SUCCESS',
    BITE_FAILURE: 'BITE_GET_FAILURE',

    //get bite pages
    BITE_PAGES_REQUEST: 'BITE_PAGES_GET_REQUEST',
    BITE_PAGES_SUCCESS: 'BITE_PAGES_GET_SUCCESS',
    BITE_PAGES_FAILURE: 'BITE_PAGES_GET_FAILURE',

    //set bite pages completed
    BITE_PAGE_COMPLETED_REQUEST: 'BITE_PAGE_COMPLETED_REQUEST',
    BITE_PAGE_COMPLETED_SUCCESS: 'BITE_PAGE_COMPLETED_SUCCESS',
    BITE_PAGE_COMPLETED_FAILURE: 'BITE_PAGE_COMPLETED_FAILURE',

    //get related bites
    GET_RELATED_BITES_REQUEST: 'GET_RELATED_BITES_REQUEST',
    GET_RELATED_BITES_SUCCESS: 'GET_RELATED_BITES_SUCCESS',
    GET_RELATED_BITES_FAILURE: 'GET_RELATED_BITES_FAILURE',

    //get related topics
    GET_RELATED_TOPICS_REQUEST: 'GET_RELATED_TOPICS_REQUEST',
    GET_RELATED_TOPICS_SUCCESS: 'GET_RELATED_TOPICS_SUCCESS',
    GET_RELATED_TOPICS_FAILURE: 'GET_RELATED_TOPICS_FAILURE',

    //likes
    LIKE_REQUEST: 'LIKE_GET_REQUEST',
    LIKE_SUCCESS: 'LIKE_GET_SUCCESS',
    LIKE_FAILURE: 'LIKE_GET_FAILURE',

    LIKE_ADD_REQUEST: 'LIKE_ADD_REQUEST',
    LIKE_ADD_SUCCESS: 'LIKE_ADD_SUCCESS',
    LIKE_ADD_FAILURE: 'LIKE_ADD_FAILURE',

    LIKE_DEL_REQUEST: 'LIKE_DEL_REQUEST',
    LIKE_DEL_SUCCESS: 'LIKE_DEL_SUCCESS',
    LIKE_DEL_FAILURE: 'LIKE_DEL_FAILURE',

    //favorites
    FAVORITE_REQUEST: 'FAVORITE_GET_REQUEST',
    FAVORITE_SUCCESS: 'FAVORITE_GET_SUCCESS',
    FAVORITE_FAILURE: 'FAVORITE_GET_FAILURE',

    FAVORITE_ADD_REQUEST: 'FAVORITE_ADD_REQUEST',
    FAVORITE_ADD_SUCCESS: 'FAVORITE_ADD_SUCCESS',
    FAVORITE_ADD_FAILURE: 'FAVORITE_ADD_FAILURE',

    FAVORITE_DEL_REQUEST: 'FAVORITE_DEL_REQUEST',
    FAVORITE_DEL_SUCCESS: 'FAVORITE_DEL_SUCCESS',
    FAVORITE_DEL_FAILURE: 'FAVORITE_DEL_FAILURE',
};