import React, { Component } from 'react';
import { connect } from 'react-redux';
//icons
import { ReactComponent as IconShadow } from './../../../Assets/images/icons/icon_shadow.svg';
import { ReactComponent as IconShadowLight } from './../../../Assets/images/icons/icon_shadow_light.svg';
import "./TileShadow.scss";

class TileShadow extends Component {

    render() {
        const { Theme } = this.props

        return (
            <div className={`tile shadow-tile ${this.props.class}`}>
                {Theme === "darkMode" && <IconShadow />}
                {Theme === "lightMode" && <IconShadowLight />}
            </div>
        );
    }
}

function mapState(state) {
    const {
        Theme
    } = state.theme;

    return {
        Theme
    };
}

const connectedTileShadow = connect(mapState, null)(TileShadow);
export { connectedTileShadow as TileShadow }; 