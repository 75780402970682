import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import {layoutConstants} from "../../../store/_constants";
//styles
import "./Loader.scss";

class Loader extends Component {
    //  INHALT WIRD GELADEN ...
    render() {
        const { SelectedLanguage } = this.props;
        const isEnglish = SelectedLanguage === layoutConstants.EnglishLanguage;

        const styles = {
            stopColor1: { stopColor: '#D20423' },
            stopColor2: { stopColor: '#2E61B2' },
            gradient: { fill: 'url(#FirstGradient)' }
        };

        return (
            <div className="loader-container">
                <div className="loader">
                    <svg>
                        <defs>
                            <linearGradient id="FirstGradient" x2="100%" y2="0">
                                <stop offset="50%" style={styles.stopColor1} />
                                <stop offset="130%" style={styles.stopColor2} />
                                <animate attributeName="x2" dur="3s" values="0%; 130%; 0%" repeatCount="indefinite" />
                            </linearGradient>
                        </defs>
                        <rect width="600" height="5" style={styles.gradient} />
                    </svg>
                    {this.props.Text ? this.props.Text : isEnglish ? 'LOADING CONTENT...' : 'INHALT WIRD GELADEN...'}
                    </div>
            </div>
        );
    }
}

function mapState(state) {
    const { SelectedLanguage } = state.layout;

    return { SelectedLanguage };
}

const connectedMKP = connect(mapState, null)(Loader);
export { connectedMKP as Loader };
