import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { mkpActions } from '../../../store/_actions';
//svg's
import { ReactComponent as ActionFavorite } from './../../../Assets/images/icons/icon_favorite.svg';
import { ReactComponent as ActionLike } from './../../../Assets/images/icons/icon_like.svg';
import { ReactComponent as ActionRate } from './../../../Assets/images/icons/icon_rate.svg';
import { ReactComponent as ActionSave } from './../../../Assets/images/icons/icon_save.svg';
import { ReactComponent as ActionShare } from './../../../Assets/images/icons/icon_share.svg';
//styles
import "./Actions.scss";
import {getUserId} from "../../../store/_services";
import {layoutConstants} from "../../../store/_constants";

class Actions extends Component {

    constructor(props) {
        super(props)

        this.isFavoriteDisplayed = true;
        this.isSaveDisplayed = false;
        this.isShareDisplayed = false;
        this.isRateDisplayed = false;
        this.isLikeDisplayed = false;

        this.state = {
            id: this.props.Id,
            type: this.props.Type,

            loading: true,
            isLiked: false,
            likedId: -1,
            isFavorited: false,
            favoritedId: -1,
            Changing: false
        }

        this.hanldeLike = this.handleLike.bind(this);
        this.handleFavorite = this.handleFavorite.bind(this);
    }
    //since isliked comes from reducer, all Actions are accessing the same field and behaving like clones
    componentDidMount() {
        //console.log("Mounted component: Actions");
        this.checkLike();
        this.checkFavorite();
    }

    componentDidUpdate(prevProps) {
        //likes
        if (prevProps.likes !== this.props.likes && this.props.likes !== undefined) {
            this.checkLike();
        }

        if (!prevProps.isLiked && this.props.isLiked && this.props.ContentId !== undefined && !this.state.isLiked && this.state.Changing) {
            this.setState({ likedId: this.props.ContentId, isLiked: true, Changing: false });
        }

        if (prevProps.isLiked && !this.props.isLiked && this.state.isLiked && this.state.Changing) {
            this.setState({ likedId: undefined, isLiked: false, Changing: false });
        }

        //favorites
        if (prevProps.favorites !== this.props.favorites && this.props.favorites !== undefined) {
            this.checkFavorite();
        }

        if (!prevProps.isFavorite && this.props.isFavorite && this.props.ContentId !== undefined && !this.state.isFavorited && this.state.Changing) {
            this.setState({ favoritedId: this.props.ContentId, isFavorited: true, Changing: false });
        }

        if (prevProps.isFavorite && !this.props.isFavorite && this.state.isFavorited && this.state.Changing) {
            this.setState({ favoritedId: undefined, isFavorited: false, Changing: false });
        }

        if (this.state.id !== this.props.Id) {
            this.setState({ id: this.props.Id });
            this.checkFavorite();
            this.checkLike()
        }
    }

    // like functionalities
    checkLike() {
        if (this.props.likes !== undefined) {
            const liked = this.props.likes.data.items.filter(e => e.resource.id === this.state.id && e.resourceType === this.state.type + "Like");
            if (liked.length > 0) {
                this.setState({ likedId: liked[0].id, isLiked: true });
            } else {
                this.setState({ likedId: -1, isLiked: false });
            }
        }
    }

    handleLike(e) {
        e.preventDefault();
        this.setState({ Changing: true });
        if (this.state.isLiked) {
            this.props.delLike(this.state.likedId);
        } else {

            this.props.addLike(getUserId(), this.state.type + "Like", this.state.id);
        }
    }

    // favorite functionalities
    checkFavorite() {
        if (this.props.favorites !== undefined) {
            var favorites = this.props.favorites.data.items.filter(e => e.resource.id === this.state.id && e.resourceType === this.state.type + "Favorite");
            if (favorites.length > 0) {
                this.setState({ favoritedId: favorites[0].id, isFavorited: true });
            } else {
                this.setState({ favoritedId: -1, isFavorited: false });
            }
        }
    }

    handleFavorite(e) {
        e.preventDefault();
        this.setState({ Changing: true });
        if (this.state.isFavorited) {
            this.props.delFavorite(this.state.favoritedId);
        } else {
            this.props.addFavorite(getUserId(), this.state.type + "Favorite", this.state.id);
        }
    }

    render() {
        const { SelectedLanguage } = this.props
        const isEnglish = SelectedLanguage === layoutConstants.EnglishLanguage;

        return (
            <div className="bb-icons">
                {this.isFavoriteDisplayed && <div className={"bb-icon i-favorite" + (this.state.isFavorited ? ' active' : '')} onClick={(e) => { this.handleFavorite(e) }}>
                    <ActionFavorite />
                    <p>{isEnglish ? 'FAVOURITES': 'FAVORITEN'}</p>
                </div>}
                {this.isSaveDisplayed && <div className="bb-icon i-save separator" onClick={(e) => { e.preventDefault() }}><ActionSave /><p>OFFLINE</p></div>}
                {this.isLikeDisplayed && <div className={"bb-icon i-like" + (this.state.isLiked ? ' active' : '')} onClick={(e) => { this.handleLike(e) }}>
                    <ActionLike />
                    <div>
                        <p>LIKE</p>
                        <p className="val">1'286</p>
                    </div>
                </div>}
                {this.isRateDisplayed && <div className="bb-icon i-rate" onClick={(e) => { e.preventDefault() }}>
                    <ActionRate />
                    <div>
                        <p>{isEnglish ? 'RATE' : 'BEWERTEN'}</p>
                        <p className="val">4,55</p>
                    </div>
                </div>}
                {this.isShareDisplayed && <div className="bb-icon i-share" onClick={(e) => { e.preventDefault() }}>
                    <ActionShare />
                    <div>
                        <p>{isEnglish ? 'SHARE' : 'TEILEN'}</p>
                        <p className="val">324</p>
                    </div>
                </div>}
            </div>
        );
    }
}


function mapState(state) {
    const { loading, likes, isLiked, ContentId, favorites, isFavorite } = state.mkp;
    const { SelectedLanguage } = state.layout;
    return { loading, likes, isLiked, ContentId, favorites, isFavorite, SelectedLanguage };
}

const actionCreators = {
    addLike: mkpActions.addLike,
    delLike: mkpActions.delLike,
    addFavorite: mkpActions.addFavorite,
    delFavorite: mkpActions.delFavorite,
};

const connectedActions = connect(mapState, actionCreators)(Actions);
export { connectedActions as Actions };
