import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions } from './../../../../../../store/_actions';
//components
import { ModelActiveRectangleL2 as ModelActive } from './ModelActiveRectangleL2';
//icons
import { ReactComponent as FullscreenClose } from './../../../../../../Assets/images/icons/icon_fullscreen_2.svg';
import { ReactComponent as Zoom } from './../../../../../../Assets/images/icons/icon_zoom.svg';
import { ReactComponent as ArrowDown } from './../../../../../../Assets/images/icons/icon_arrow_down_1.svg';

class Model4 extends Component {
    constructor(props) {
        super(props);

        this.activeModel = this.props.ModelSet.items[3];
        this.ModelId = this.activeModel.id;
        this.Mapping = this.activeModel.mapping;

        // initial state
        this.state = {
            activePie: -1,
            hoverPie: -1,
            changed: false,
            switch: false,
            Zoom: false,
            showIndicator: false,
            actionFromPageExecuted: false,
            callFromPage: true
        }

        // event handlers
        this.hoverPie = this.hoverPie.bind(this);
        this.setStyles = this.setStyles.bind(this);
        this.selectPie = this.selectPie.bind(this);
        this.switchLevel = this.switchLevel.bind(this);
        this.ToggleFullsreen = this.ToggleFullsreen.bind(this);
        this.setIndicator = this.setIndicator.bind(this);
    }

    /************************************
    * LIFECYCLE
    ************************************/

    componentDidMount() {
        if (this.props.FromContext) {
            //ste intial path coming from this.props.Tile
            if (this.props.Tile.type === "Bite") {
                this.setSection(this.props.Tile.id, this.props.Tile.Topic.id, this.props.Tile.Container.id);
            } else if (this.props.Tile.type === "Topic") {
                this.setSection(-1, this.props.Tile.id, this.props.Tile.Container.id);
            }
        }

        if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted) {
            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
            if (activeModelSet.containers) {
                //ste intial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }
        }
    }

    componentDidUpdate(prevProps) {
        //if model is selected
        if (this.props.SelectedPath.Model === this.ModelId) {



            //index change incoming
            if (this.props.SelectedPath.LastUpdate === "Index" && !this.Updating) {
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

            //when coming from topic/bite page
            if (this.props.SelectedPath.LastUpdate === "Page" && !this.state.actionFromPageExecuted && this.state.callFromPage) {
                //ste intial path coming from this.props.Tile
                this.setSection(this.props.SelectedPath.Bite, this.props.SelectedPath.Topic, this.props.SelectedPath.Container);
            }

        } else if (this.props.SelectedPath.LastUpdate === "Model" && !this.Updating) {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
  * SELECTED PATH UPDATE
  ***********************************/

    UpdateSelectedPath(container, topic, bite, lastUpdate, model) {

        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);

        if (model) {
            path.Model = model;
        }
        if (container) {
            path.Container = container;
        }
        if (topic) {
            path.Topic = topic;
        }
        if (bite) {
            path.Bite = bite;
        }

        if (lastUpdate != null) {
            path.LastUpdate = lastUpdate;
        }
        this.props.setSelectedPath(path);
        setTimeout(function () {
            this.Updating = false;
        }.bind(this), 300);
    }

    //get parent container
    getTopicParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            if (activeModelSet.containers[i].topics.filter(c => c.id === id).length > 0) {
                return activeModelSet.containers[i].topics.filter(c => c.id === id)[0].containerId;
            }
        }

        return -1;
    }

    //get parent topic
    getBiteParent(id) {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        //search for parent container
        for (var i = 0; i < activeModelSet.containers.length; i++) {
            for (var j = 0; j < activeModelSet.containers[i].topics.length; j++) {
                if (activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id).length > 0) {
                    return activeModelSet.containers[i].topics[j].bites.filter(c => c.id === id)[0].topicId;
                }
            }
        }

        return -1;
    }

    /************************************
    * SECTIONS HOVER & STYLES
    ***********************************/

    hoverPie(index) {
        if (!this.props.FromContext) {
            this.setState({ hoverPie: index, changed: true });
        }
    }

    setStyles(index) {
        var styles = "";

        //active
        if (this.state.activePie === index) {
            styles += " active";
        }

        //hover
        if (this.state.hoverPie === index) {
            styles += " hover";
        }

        return styles;
    }

    /************************************
    * SETTING SELECTED AREA
    ************************************/

    selectPie(e, index) {
        if (this.props.SelectedPath.Model === this.ModelId || this.props.FromContext) {

            if (index === this.state.activePie) {
                this.setIndex(e, -1);
            } else {

                this.setState({
                    activePie: index,
                    changed: true
                });
                this.setIndex(e, index);
                this.setIndicator();
            }
        }
    }

    setIndex(e, index) {
        //console.log("index: " + index);
        this.setState({ callFromPage: e === null }, function () {
            var container = -1, topic = -1, bite = -1;

            if (index === -1) {
                this.setState({
                    activePie: -1,
                    changed: false
                });
            } else {
                //area got selected
                var map = this.Mapping.filter(map => map.id === index);

                if (map.length > 0) {
                    map = map[0];
                    if (map.type === "Container") {
                        container = map.resourceId;
                    } else if (map.type === "Topic") {
                        topic = map.resourceId;

                        //get parent container
                        container = this.getTopicParent(topic);
                    } else if (map.type === "Bite") {
                        bite = map.resourceId;

                        //get parent topic
                        var topicId = this.getBiteParent(map.resourceId);
                        topic = topicId;

                        //get parent container
                        container = this.getTopicParent(topicId);
                    }
                }
            }

            //console.log("this.props.SelectedPath.LastUpdate-> " + this.props.SelectedPath.LastUpdate);
            if (this.props.SelectedPath.LastUpdate === "Index") {
                this.UpdateSelectedPath(null, null, null, "", null);

            } else if (!this.state.actionFromPageExecuted && this.props.SelectedPath.LastUpdate === "Page") {
                this.setState({ actionFromPageExecuted: true });

            } else if (!this.state.callFromPage) {
                this.UpdateSelectedPath(container, topic, bite, "Carousel", null);
            }
        });
    }

    setSection(bite, topic, container) {
        this.Updating = true;
        //console.log(this.props.SelectedPath);

        var section = [];

        if (bite !== -1) {
            section = this.Mapping.filter(map => map.resourceId === bite && map.type === "Bite");
        }

        //check if bite section was found. If not search topic
        if (section.length === 0) {
            if (topic !== -1) {
                section = this.Mapping.filter(map => map.resourceId === topic && map.type === "Topic");
            }
        }

        //check if topic section was found. If not search Container
        if (section.length === 0) {
            if (container !== -1) {
                section = this.Mapping.filter(map => map.resourceId === container && map.type === "Container");
            }
        }

        //update model
        if (section.length > 0) {
            //set section
            if (section[0].id !== this.state.activePie) {
                this.selectPie(null, section[0].id);
            } else {
                setTimeout(function () {
                    this.Updating = false;
                }.bind(this), 300);
            }
        } else {
            this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
    * LEVEL SWITCH
    ************************************/

    switchLevel(toggleLevel) {
        if (this.props.SelectedPath.Model === this.ModelId) {
            this.setState({ switch: toggleLevel });
        }
    }

    /************************************
    * TOGGLE FULLSCREEN
    ************************************/

    ToggleFullsreen() {
        this.setState({ Zoom: !this.state.Zoom });
        this.props.handleZoom();
    }

    /************************************
    * SHOW "GO TO" INDICATOR
    ************************************/

    setIndicator() {
        if (!this.state.showIndicator && this.state.Zoom) {
            this.setState({ showIndicator: true }, function () {
                setTimeout(function () {
                    this.setState({ showIndicator: false })
                }.bind(this), 3000);
            });
        }
    }

    render() {
        const { SelectedLanguage } = this.props;

        return (
            <div className={"slide-container rectangle" + (this.state.Zoom ? ' magnify' : '')}>

                <div className="model4-container"><div className="model4-container-inner">
                    <ModelActive Level={this.state.switch ? 2 : 1} toggleLevel={this.switchLevel} />

                    <svg version="1.1" className={"model model4" + (!this.state.switch ? ' switch' : '')} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1674.8 891" xmlSpace="preserve">
                        <g className="lv2">
                            <g id="Background" className="st0">
                                <rect id="Background_3_" y="0" className="st1" width="1673.4" height="891" />
                            </g>
                            <g id="Fields">
                                <rect id="Rectangle_-_4" x="747.9" y="465.8" className={"st2 model-option" + (this.state.changed ? this.setStyles(28) : '')} width="926.9" height="425.2" />
                                <rect id="Rectangle_-_3" x="40.6" y="465.8" className={"st3 model-option" + (this.state.changed ? this.setStyles(27) : '')} width="707.2" height="425.2" />
                                <rect id="Rectangle_-_2-2" x="747.9" y="40.6" className="st4" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_2-1" x="747.9" y="40.6" className={"st5 model-option" + (this.state.changed ? this.setStyles(26) : '')} width="926.9" height="425.2" />
                                <rect id="Rectangle_-_1" x="40.6" y="40.6" className={"st6 model-option" + (this.state.changed ? this.setStyles(25) : '')} width="707.2" height="425.2" />
                            </g>
                            <g id="Corridor">
                                <polygon id="Arrow_-_9" className="st7" points="252.4,122 106.8,184.9 186.9,221.3 332.5,158.4 	" />
                                <polygon id="Arrow_-_8" className="st8" points="373.4,174.5 227.7,237.5 307.9,273.9 453.5,210.9 	" />
                                <polygon id="Arrow_-_7" className="st9" points="494.3,227.1 348.7,290 428.9,326.4 574.5,263.5 	" />
                                <polygon id="Arrow_-_6" className="st9" points="615.3,279.6 469.7,342.6 549.8,379 695.4,316 	" />
                                <polygon id="Arrow_-_5" className="st10" points="740.1,335.4 594.4,398.3 674.6,434.7 820.2,371.8 	" />
                                <polygon id="Arrow_-_4" className="st11" points="864.8,391.2 719.2,454.1 799.4,490.5 945,427.5 	" />
                                <polygon id="Arrow_-_3" className="st12" points="989.6,446.9 844,509.8 924.2,546.3 1069.8,483.3 	" />
                                <polygon id="Arrow_-_2" className="st13" points="1114.4,502.7 968.8,565.6 1048.9,602 1194.5,539.1 	" />
                                <polygon id="Arrow_-_1" className="st14" points="1595.4,783 1485.7,554.7 1441.8,651.4 1239.5,559.5 1093.9,622.4 1392,757.8
		1348.1,854.5 	"/>
                            </g>
                            <g id="Lines">
                                <line id="Line_-_2" className="st15" x1="26.1" y1="466.2" x2="-1.5" y2="466.2" />
                                <line id="Line_-_1" className="st15" x1="749.1" y1="27.8" x2="749.1" y2="0.1" />
                            </g>
                            <g id="Boxes">
                                <path id="Rectangle_-_24" className={"st16 model-option" + (this.state.changed ? this.setStyles(24) : '')} d="M290.8,651.9H58.1c-3.1,0-5.7-2.5-5.7-5.7V431.9c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v214.3C296.4,649.3,293.9,651.9,290.8,651.9z" />
                                <path id="Rectangle_-_23" className={"st16 model-option" + (this.state.changed ? this.setStyles(23) : '')} d="M598,757H365.4c-3.1,0-5.7-2.5-5.7-5.7V537c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v214.3C603.7,754.4,601.2,757,598,757z" />
                                <path id="Rectangle_-_22" className={"st16 model-option" + (this.state.changed ? this.setStyles(22) : '')} d="M865.9,858.1H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,855.5,869,858.1,865.9,858.1z" />
                                <path id="Rectangle_-_21" className={"st16 model-option" + (this.state.changed ? this.setStyles(21) : '')} d="M1230.8,489.7c17,3.1,11.1-2.6,24.1-5.1c17.6,0.5,35.8-5.1,53.4-3.3 c10,1.2,3.7,5.2,13.4,6.1c18.8,4.7,3,3.5,44.3,6.6c5.8,0.5,16.4,4.2,16.4,4.2c9.4,11,5.7,5.2,10.9,16.9c0.6,1.4,1.3,19.2,1.3,19.2 c-4.6,11.7-9.6,8.5-22.7,14.8c-4.9,5.6-20.3,8.1-28.8,10.9c-11.2,3.2-16.1-0.8-24.5,2.9c-5.5,2.4-41.3,5.2-41.3,5.2 c-19.4-0.5-15.8-1.4-35.2-2.4c-8.8-0.5-22.8-10.1-24.9-10.3c-13.7-1.9-19.4-4.7-33.4-5.2c-17.3-4.5-13.4-7.5-25.5-16.9 c-8.2-18.8,13.5-34.8,33.8-39.9C1223.7,495.2,1213.8,486.7,1230.8,489.7z" />
                                <path id="Rectangle_-_20" className={"st16 model-option" + (this.state.changed ? this.setStyles(20) : '')} d="M1129.1,448.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7V443C1134.8,446.2,1132.3,448.7,1129.1,448.7z" />
                                <path id="Rectangle_-_19" className={"st16 model-option" + (this.state.changed ? this.setStyles(19) : '')} d="M1392.9,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,354.2,1396.1,356.7,1392.9,356.7z" />
                                <path id="Rectangle_-_18" className={"st16 model-option" + (this.state.changed ? this.setStyles(18) : '')} d="M1129.1,356.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1134.8,354.2,1132.3,356.7,1129.1,356.7z" />
                                <path id="Rectangle_-_17" className={"st16 model-option" + (this.state.changed ? this.setStyles(17) : '')} d="M865.9,356.7H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,354.2,869,356.7,865.9,356.7z" />
                                <path id="Rectangle_-_16" className={"st16 model-option" + (this.state.changed ? this.setStyles(16) : '')} d="M865.9,253.2H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,250.6,869,253.2,865.9,253.2z" />
                                <path id="Rectangle_-_15" className={"st16 model-option" + (this.state.changed ? this.setStyles(15) : '')} d="M1392.9,145h-232.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,142.5,1396.1,145,1392.9,145z" />
                                <path id="Rectangle_-_14" className={"st16 model-option" + (this.state.changed ? this.setStyles(14) : '')} d="M1392.9,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,250.6,1396.1,253.2,1392.9,253.2z" />
                                <path id="Rectangle_-_13" className={"st16 model-option" + (this.state.changed ? this.setStyles(13) : '')} d="M1655.7,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,250.6,1658.9,253.2,1655.7,253.2z" />
                                <path id="Rectangle_-_12" className={"st16 model-option" + (this.state.changed ? this.setStyles(12) : '')} d="M1655.7,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,354.2,1658.9,356.7,1655.7,356.7z" />
                                <path id="Rectangle_-_11" className={"st16 model-option" + (this.state.changed ? this.setStyles(11) : '')} d="M1655.7,504.3h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,501.8,1658.9,504.3,1655.7,504.3z" />
                                <path id="Rectangle_-_10" className={"st16 model-option" + (this.state.changed ? this.setStyles(10) : '')} d="M1655.7,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1661.4,754,1658.9,756.5,1655.7,756.5z" />
                                <path id="Rectangle_-_9" className={"st16 model-option" + (this.state.changed ? this.setStyles(9) : '')} d="M1655.7,858.1h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,855.5,1658.9,858.1,1655.7,858.1z" />
                                <path id="Rectangle_-_8" className={"st16 model-option" + (this.state.changed ? this.setStyles(8) : '')} d="M1394,859.1H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H1394 c3.1,0,5.7,2.5,5.7,5.7v66.7C1399.6,856.5,1397.1,859.1,1394,859.1z" />
                                <path id="Rectangle_-_7" className={"st16 model-option" + (this.state.changed ? this.setStyles(7) : '')} d="M1394.2,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1399.8,754,1397.3,756.5,1394.2,756.5z" />
                                <path id="Rectangle_-_6" className={"st16 model-option" + (this.state.changed ? this.setStyles(6) : '')} d="M865.9,504.3H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,501.8,869,504.3,865.9,504.3z" />
                                <path id="Rectangle_-_5" className={"st16 model-option" + (this.state.changed ? this.setStyles(5) : '')} d="M598,504.3H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,501.8,601.2,504.3,598,504.3z" />
                                <path id="Rectangle_-_4_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(4) : '')} d="M330.2,356.7H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,354.2,333.3,356.7,330.2,356.7z" />
                                <path id="Rectangle_-_3_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(3) : '')} d="M598,356.7H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,354.2,601.2,356.7,598,356.7z" />
                                <path id="Rectangle_-_2_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(2) : '')} d="M330.2,253.2H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,250.6,333.3,253.2,330.2,253.2z" />
                                <path id="Rectangle_-_1_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(1) : '')} d="M330.2,145H97.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,142.5,333.3,145,330.2,145z" />
                            </g>
                            <g id="Areas">
                                <g className="st17">
                                    <path className="st18" d="M63.1,865.2v9.3h1.4v1.1h-1.4v3.5h-1.3v-3.5h-4.6v-1.1l4.8-9.3H63.1z M61.7,874.5l0.1-2.3l0-1.9l0.1-1.4
			l0-0.8l0-0.3l0-0.3l0-0.1l-0.7,1.5l-1.3,2.6l-1.5,2.9L61.7,874.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1661.1,875.6l-0.1,1.5l-0.3,0.9l-0.6,0.7l-0.9,0.5l-1.3,0.1l-0.9,0l-0.6-0.2l-0.6-0.4l-0.6-0.6l-0.3-0.8
			l-0.1-2.3h1.3v1.4l0.2,0.8l0.5,0.7l0.6,0.3l0.6,0.1l0.7-0.1l0.8-0.5l0.4-0.9l0.1-1.3l-0.1-1.4l-0.3-0.9l-0.4-0.4l-1.2-0.4
			l-0.7-0.1v-1.1l1.7-0.3l0.4-0.3l0.4-0.6l0.1-1.4l-0.1-1.2l-0.3-0.7l-0.5-0.4l-0.8-0.2l-0.7,0.1l-0.5,0.2l-0.4,0.6l-0.2,2.2h-1.3
			l0.1-1.9l0.3-1l0.5-0.6l0.8-0.5l1.2-0.2l1.1,0.1l0.8,0.4l0.6,0.5l0.4,0.6l0.2,0.8l0,1.2l-0.1,1.4l-0.4,0.9l-0.9,0.7l0.5,0.3
			l0.6,0.6l0.3,0.9l0.1,1.3L1661.1,875.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1661.4,65.6v1.1l-6.5,0v-0.9l1.7-2.8l1.3-2.1l0.6-1l1.1-2.3l0.2-1.5l-0.1-0.9l-0.4-0.9l-0.5-0.3l-0.8-0.1
			l-0.7,0.1l-0.5,0.2l-0.5,0.8l-0.1,1.2l0,1.4h-1.3l0-2l0.3-1.3l0.7-0.9l0.9-0.5l1.3-0.2l0.6,0.1l1,0.3l0.4,0.3l0.4,0.5l0.3,0.6
			l0.2,1.8l-0.3,1.8l-0.9,1.9l-1.7,2.8l-1.8,2.9L1661.4,65.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M62.1,52.9v13.9l-1.3,0V56.1l-2.1,0v-0.9l1.2-0.3l0.6-0.6l0.5-1.4H62.1z" />
                                </g>
                            </g>
                            <g id="Numbers">
                                <g className="st17">
                                    <path className="st18" d="M66.8,445.9v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L66.8,445.9z"/>
                                    <path className="st18" d="M73.7,435.1v7.9h1.2v1h-1.2v3h-1.1v-3h-3.9V443l4-7.9H73.7z M72.5,443l0.1-2l0-1.6l0.1-1.2l0-0.7l0-0.2
			l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L72.5,443z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.9,552.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L375.9,552.3z"/>
                                    <path className="st18" d="M383.6,550.3l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L383.6,550.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M641.5,799.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2H636l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L641.5,799.3z"/>
                                    <path className="st18" d="M649.4,799.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L649.4,799.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1173.6,526.2v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L1173.6,526.2z"/>
                                    <path className="st18" d="M1178.2,515.4v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1178.2z" />
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M905.7,390.4v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L905.7,390.4z"/>
                                    <path className="st18" d="M913.4,388l0,1.1l-0.3,1.1l-0.5,0.7l-0.5,0.3l-1.4,0.3l-1.4-0.3l-0.5-0.3l-0.3-0.4l-0.3-0.6l-0.1-0.8l0-2.1
			v-4l0-1.3l0.4-1.2l0.3-0.4l0.5-0.4l0.5-0.2l0.8-0.1l1.3,0.2l0.5,0.3l0.6,0.6l0.3,0.7l0.1,2.1V388z M909.2,388.2l0.1,1l0.2,0.7
			l0.4,0.5l0.8,0.3l0.6-0.1l0.4-0.3l0.3-0.5l0.2-0.5l0.1-1.5l0-5.5l-0.1-0.4l-0.2-0.7l-0.4-0.4l-1-0.3l-0.4,0.1l-0.5,0.3l-0.3,0.3
			l-0.2,1.2l0,1.1L909.2,388.2z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1170.9,295.5l0.2,1.3l0.4,0.5l0.8,0.2l0.6-0.1l0.4-0.2l0.3-0.3l0.2-0.9l0-0.9v-2l-0.5,0.6l-0.7,0.4
			l-0.7,0.1l-0.8-0.1l-0.5-0.3l-0.4-0.4l-0.3-0.4l-0.3-1l-0.1-1.5l0.1-1.8l0.1-0.8l0.5-0.8l0.4-0.4l0.6-0.3l0.9-0.1l1.2,0.2l0.5,0.3
			l0.4,0.4l0.4,0.7l0.2,1.2l0.1,1.9l-0.1,4.1l-0.1,1.4l-0.2,0.7l-0.5,0.6l-0.7,0.5l-1,0.1l-1.1-0.1l-0.7-0.4l-0.4-0.4l-0.2-0.5
			l-0.2-1.5L1170.9,295.5z M1171.8,287.3l-0.5,0.3l-0.3,0.4l-0.1,0.4l-0.1,1.3l0,1.5l0.1,0.8l0.2,0.5l0.4,0.4l0.7,0.2l0.7-0.2
			l0.5-0.4l0.2-0.3l0.2-0.9l0.1-1.3l-0.1-1.3l-0.1-0.8l-0.6-0.6l-0.9-0.2L1171.8,287.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M903.8,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H903.8z" />
                                    <path className="st18" d="M911.3,292.1l0.4,0.4l0.2,0.4l0.2,1l0,1.5l-0.2,1.6l-0.3,0.6l-0.5,0.5l-0.7,0.3l-1,0.1l-0.8,0l-0.6-0.2
			l-0.5-0.4l-0.5-0.7l-0.2-0.9l0-1.3v-1.3l0.2-0.8l0.5-0.7l0.4-0.3l-0.4-0.4l-0.5-0.9l-0.1-1.3v-0.6l0.2-1l0.5-0.6l0.4-0.3l0.5-0.2
			l0.8-0.1l1,0.1l0.6,0.3l0.4,0.5l0.3,0.8l0.1,1.2l-0.1,1.1l-0.3,0.7l-0.3,0.4l-0.4,0.3L911.3,292.1z M908.9,292.3l-0.5,0.3
			l-0.3,0.4l-0.2,0.4l-0.1,1.3l0.1,1.4l0.2,0.6l0.3,0.4l0.6,0.3l0.4,0l0.4-0.1l0.6-0.3l0.3-0.4l0.1-0.8l0.1-1.2l-0.1-1.1l-0.3-0.9
			l-0.5-0.4l-0.8-0.2L908.9,292.3z M908.6,287.5l-0.4,0.6l-0.1,0.9l0.1,1.3l0.2,0.4l0.3,0.3l0.4,0.2l0.4,0l0.8-0.3l0.3-0.4l0.2-0.4
			l0.1-0.9l-0.1-1.1l-0.3-0.7l-0.4-0.3l-0.7-0.1L908.6,287.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M639.6,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H639.6z" />
                                    <path className="st18" d="M647.9,286.5l0,0.9l-0.5,1L647,289l-0.4,1l-0.5,1.3l-0.8,3.5l-0.4,3.5h-1.2l0.3-2.6l0.5-2.5l0.6-1.9l0.8-2
			l0.9-1.9h-4.2v-1L647.9,286.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M639.6,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H639.6z" />
                                    <path className="st18" d="M646.6,185.6l-0.1-0.9l-0.2-0.4l-0.3-0.4l-0.4-0.2l-0.5-0.1l-0.6,0.1l-0.4,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.2v2
			l0.6-0.7l0.7-0.3l0.6-0.1l0.6,0.1l0.9,0.4l0.5,0.6l0.3,0.8l0.1,2.2l-0.1,1.7l-0.1,0.7l-0.4,0.6l-0.5,0.4l-0.7,0.3l-1,0.1l-1-0.1
			l-0.7-0.4l-0.5-0.6l-0.4-1.5l0-1.5v-4.9l0.1-1.4l0.5-0.9l0.5-0.4l0.5-0.2l1-0.2l1.2,0.2l0.8,0.5l0.5,0.8l0.1,1.4H646.6z
			 M643.6,191.6l0.1,0.7l0.2,0.8l0.4,0.4l0.9,0.3l0.4,0l0.6-0.3l0.3-0.4l0.2-0.7l0.1-1.1v-0.4l-0.1-1.3l-0.2-0.8l-0.3-0.5l-0.4-0.2
			l-0.6-0.1l-0.8,0.3l-0.3,0.2l-0.2,0.5l-0.2,0.7l-0.1,1.1V191.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,77.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1174.7,77.8v1h-3.6l-0.2,3.6l0.5-0.5l0.8-0.3l0.5,0l0.6,0.1l0.6,0.3l0.4,0.4l0.4,0.6l0.2,0.9l0.1,1.3
			l-0.1,1.9l-0.1,1l-0.5,0.9l-0.4,0.5l-0.4,0.2l-1.2,0.3l-0.9,0l-0.6-0.2l-0.4-0.3l-0.6-0.8l-0.2-1.3l0-1.1h1.1l0,1.4l0.2,0.6
			l0.4,0.4l0.4,0.2l0.5,0.1l0.7-0.1l0.4-0.3l0.4-0.6l0.1-2.4l-0.1-1.2l-0.2-1l-0.5-0.4l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.5,0.6
			l-0.1,0.7h-1l0.3-6.3L1174.7,77.8z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1174.1,182.8v7.9h1.2v1h-1.2v3h-1.1v-3h-3.9v-0.9l4-7.9H1174.1z M1172.9,190.7l0.1-2l0-1.6l0.1-1.2l0-0.7
			l0-0.2l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L1172.9,190.7z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.4,191.6l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L1438.4,191.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.7,297.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L1438.7,297.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,435.1v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1436.8,435.1v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1436.8z" />
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,594.3v11.8l-1.1,0V597l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.5,602.7l0,1.1l-0.3,1.1l-0.5,0.7l-0.5,0.3l-1.4,0.3l-1.4-0.3l-0.5-0.3l-0.3-0.4l-0.3-0.6l-0.1-0.8
			l0-2.1v-4l0-1.3l0.4-1.2l0.3-0.4l0.5-0.4l0.5-0.2l0.8-0.1l1.3,0.2l0.5,0.3l0.6,0.6l0.3,0.7l0.1,2.1V602.7z M1434.3,602.9l0.1,1
			l0.2,0.7l0.4,0.5l0.8,0.3l0.6-0.1l0.4-0.3l0.3-0.5l0.2-0.5l0.1-1.5l0-5.5l-0.1-0.4l-0.2-0.7l-0.4-0.4l-1-0.3l-0.4,0.1l-0.5,0.3
			l-0.3,0.3l-0.2,1.2l0,1.1L1434.3,602.9z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1432.9,797.5l0.2,1.3l0.4,0.5l0.8,0.2l0.6-0.1l0.4-0.2l0.3-0.3l0.2-0.9l0-0.9v-2l-0.5,0.6l-0.7,0.4
			l-0.7,0.1l-0.8-0.1l-0.5-0.3l-0.4-0.4l-0.3-0.4l-0.3-1l-0.1-1.5l0.1-1.8l0.1-0.8l0.5-0.8l0.4-0.4l0.6-0.3l0.9-0.1l1.2,0.2l0.5,0.3
			l0.4,0.4l0.4,0.7l0.2,1.2l0.1,1.9l-0.1,4.1l-0.1,1.4l-0.2,0.7l-0.5,0.6l-0.7,0.5l-1,0.1l-1.1-0.1l-0.7-0.4l-0.4-0.4l-0.2-0.5
			l-0.2-1.5L1432.9,797.5z M1433.8,789.4l-0.5,0.3l-0.3,0.4l-0.1,0.4l-0.1,1.3l0,1.5l0.1,0.8l0.2,0.5l0.4,0.4l0.7,0.2l0.7-0.2
			l0.5-0.4l0.2-0.3l0.2-0.8l0.1-1.3l-0.1-1.3l-0.1-0.8l-0.6-0.6l-0.9-0.2L1433.8,789.4z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M906.7,794.1l0.4,0.4l0.2,0.4l0.2,1l0,1.5l-0.2,1.6l-0.3,0.6l-0.5,0.5l-0.7,0.3l-1,0.1l-0.8,0l-0.6-0.2
			l-0.5-0.4l-0.5-0.7l-0.2-0.9l0-1.3v-1.3l0.2-0.8l0.5-0.7l0.4-0.3l-0.4-0.4l-0.5-0.9l-0.1-1.3v-0.6l0.2-1l0.5-0.6l0.4-0.3l0.5-0.2
			l0.8-0.1l1,0.1l0.6,0.3l0.4,0.5l0.3,0.8l0.1,1.2l-0.1,1.1l-0.3,0.7l-0.3,0.4l-0.4,0.3L906.7,794.1z M904.4,794.4l-0.5,0.3
			l-0.3,0.4l-0.2,0.4l-0.1,1.3l0.1,1.4l0.2,0.6l0.3,0.4l0.6,0.3l0.4,0l0.4-0.1l0.6-0.3l0.3-0.4l0.1-0.8l0.1-1.2l-0.1-1.1l-0.3-0.9
			l-0.5-0.4l-0.8-0.2L904.4,794.4z M904,789.5l-0.4,0.6l-0.1,0.9l0.1,1.3l0.2,0.4l0.3,0.3l0.4,0.2l0.4,0l0.8-0.3l0.3-0.4l0.2-0.4
			l0.1-0.9l-0.1-1.1l-0.3-0.7l-0.4-0.3l-0.7-0.1L904,789.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1168.5,594.3l0,0.9l-0.5,1l-0.3,0.6l-0.4,1l-0.5,1.3l-0.8,3.5l-0.4,3.5h-1.2l0.3-2.6l0.5-2.5l0.6-1.9l0.8-2
			l0.9-1.9h-4.2v-1L1168.5,594.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M640,437.9L640,437l-0.2-0.4l-0.3-0.4l-0.4-0.2l-0.5-0.1L638,436l-0.4,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.2v2
			l0.6-0.7l0.7-0.3l0.6-0.1l0.6,0.1l0.9,0.4l0.5,0.6l0.3,0.8l0.1,2.2l-0.1,1.7l-0.1,0.7l-0.4,0.6l-0.5,0.4l-0.7,0.3l-1,0.1l-1-0.1
			l-0.7-0.4l-0.5-0.6l-0.4-1.5l0-1.5V438l0.1-1.4l0.5-0.9l0.5-0.4l0.5-0.2l1-0.2l1.2,0.2l0.8,0.5l0.5,0.8l0.1,1.4H640z M637.1,444
			l0.1,0.7l0.2,0.8l0.4,0.4l0.9,0.3l0.4,0l0.6-0.3l0.3-0.4l0.2-0.7l0.1-1.1v-0.4l-0.1-1.3L640,441l-0.3-0.5l-0.4-0.2l-0.6-0.1
			l-0.8,0.3l-0.3,0.2l-0.2,0.5l-0.2,0.7l-0.1,1.1V444z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.5,435.1v1h-3.6l-0.2,3.6l0.5-0.5l0.8-0.3l0.5,0l0.6,0.1l0.6,0.3l0.4,0.4l0.4,0.6l0.2,0.9l0.1,1.3
			l-0.1,1.9l-0.1,1l-0.5,0.9l-0.4,0.5l-0.4,0.2l-1.2,0.3l-0.9,0l-0.6-0.2l-0.4-0.3l-0.6-0.8l-0.2-1.3l0-1.1h1.1l0,1.4l0.2,0.6
			l0.4,0.4l0.4,0.2l0.5,0.1l0.7-0.1l0.4-0.3l0.4-0.6l0.1-2.4l-0.1-1.2l-0.2-1l-0.5-0.4l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.5,0.6
			l-0.1,0.7h-1l0.3-6.3L375.5,435.1z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M106,286.5v7.9h1.2v1H106v3h-1.1v-3H101v-0.9l4-7.9H106z M104.9,294.4l0.1-2l0-1.6l0.1-1.2l0-0.7l0-0.2
			l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L104.9,294.4z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.6,295.3l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L375.6,295.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M107.1,193.6v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L107.1,193.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M105.2,77.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H105.2z" />
                                </g>
                            </g>
                            <g id="Text">
                                {SelectedLanguage === "en-GB" && <>
                                    <g>
                                        <path className="st18" d="M141.3228,532.78c-.68,1.96-3.3008,1.9209-3.6006,1.9209a6.8569,6.8569,0,0,1-1.34-.1407,2.9469,2.9469,0,0,1-2.3808-1.6806,3.7907,3.7907,0,0,1-.2-1.4795v-7.6817c0-.24.02-.5009.04-.74a2.6928,2.6928,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.92-.82,4.7057,4.7057,0,0,1,2.6406.6407,2.4627,2.4627,0,0,1,.88,1.04,3.1138,3.1138,0,0,1,.24,1.6v2.1611h-2.56v-1.78c0-1.0811-.18-1.6612-1.34-1.6612-1,0-1.2.62-1.2,1.541V531.08c0,.92.2,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7413v-1.84h2.56v2A5.2362,5.2362,0,0,1,141.3228,532.78Z" />
                                        <path className="st18" d="M149.5425,533.56a3.4128,3.4128,0,0,1-2.7207,1.04,4.8512,4.8512,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7229,3.7229,0,0,1-.18-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9837,2.9837,0,0,1,3.0405-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9461,2.9461,0,0,1,.24,1.36v4.94A2.4526,2.4526,0,0,1,149.5425,533.56Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.746.746,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12V531.9c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M156.8618,534.4v-7.541c0-.6407-.04-.9-.36-1.1006a.7526.7526,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808V534.4h-2.38V524.1576h2.42l-.02.74a2.6361,2.6361,0,0,1,2.04-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V534.4Z" />
                                        <path className="st18" d="M165.0215,534.5c-1.38,0-2.1006-.3008-2.4009-.961a2.6273,2.6273,0,0,1-.2-1.18v-6.6211h-1.3v-1.581h1.28v-3.7207h2.36v3.7207h1.42v1.581h-1.42V531.58a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6371,2.6371,0,0,0,.4-.04V534.38A5.0715,5.0715,0,0,1,165.0215,534.5Z" />
                                        <path className="st18" d="M172.8413,526.3383a1.6845,1.6845,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94V534.4h-2.36V524.1576h2.38l-.02,1.3008a4.1032,4.1032,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M180.582,533.56a3.4128,3.4128,0,0,1-2.7207,1.04,4.8521,4.8521,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1737,3.1737,0,0,1,.2-1.3,2.9848,2.9848,0,0,1,3.0411-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9476,2.9476,0,0,1,.24,1.36v4.94A2.4526,2.4526,0,0,1,180.582,533.56Zm-1.7807-6.8818c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7459.7459,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12V531.9c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M183.6807,534.4V520.2767h2.4V534.4Z" />
                                        <path className="st18" d="M188.72,534.4V520.2767h2.4V534.4Z" />
                                        <path className="st18" d="M193.76,522.6771v-2.4h2.4v2.4Zm0,11.7227V524.1576h2.4V534.4Z" />
                                        <path className="st18" d="M203.021,534.4v-7.541c0-.6407-.04-.9-.36-1.1006a.7525.7525,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808V534.4h-2.3809V524.1576H201.22l-.02.74a2.6378,2.6378,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1V534.4Z" />
                                        <path className="st18" d="M214.561,535.42a2.5188,2.5188,0,0,1-1.04,2.02,4.3574,4.3574,0,0,1-2.4209.6,6.1138,6.1138,0,0,1-.82-.06,2.59,2.59,0,0,1-2.16-1.48,3.814,3.814,0,0,1-.16-1.26h2.36a1.5445,1.5445,0,0,0,.04.48.8372.8372,0,0,0,.88.6006.9731.9731,0,0,0,.54-.1406c.4-.22.4209-.68.4209-1.08v-1.58a2.52,2.52,0,0,1-2.0811,1,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.2607v-5.4209a3.5106,3.5106,0,0,1,.1407-1.12,2.044,2.044,0,0,1,2.04-1.4609,2.9163,2.9163,0,0,1,2.1211,1.02v-.82h2.4V534.76C214.6011,534.98,214.5806,535.2006,214.561,535.42Zm-2.3808-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.041v4.5605a1.9874,1.9874,0,0,0,.0791.6006.8515.8515,0,0,0,.86.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M126.4019,556.4c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3808a2.2531,2.2531,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6464,3.6464,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8725,1.8725,0,0,1,.24.9805V553.9a5.0665,5.0665,0,0,0,.3,2.32c.0195.06.0595.1.16.18Zm-.34-5.1612-1.16.7608c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M135.3018,556.4v-7.541c0-.6407-.04-.9-.36-1.1006a.7524.7524,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808V556.4h-2.38V546.1576h2.42l-.02.74a2.6364,2.6364,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V556.4Z" />
                                        <path className="st18" d="M144.4614,556.4l.02-1a2.2686,2.2686,0,0,1-2,1.2,2.4506,2.4506,0,0,1-1.4205-.4405c-.76-.5595-.86-1.22-.86-2.84v-4.6611a2.5411,2.5411,0,0,1,1.04-2.3809,2.3633,2.3633,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0605,1.04v-4.7012h2.38V556.4Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2031,1.2031,0,0,0-.58-.16.9371.9371,0,0,0-.52.16c-.4.28-.38.7-.38,1.1406V553.44c0,.6.06.8194.34,1a1.1205,1.1205,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.31,1.31,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M160.6816,556.4c-.04-.08-.08-.14-.12-.22a2.5629,2.5629,0,0,1-.26-1.1407v-3.2207c0-.22,0-.4394-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24V556.4H155.1V542.2767h5.2012a2.6465,2.6465,0,0,1,1.7207.46,2.7941,2.7941,0,0,1,.82,2.52v1.52a3.5392,3.5392,0,0,1-.28,1.52,1.6575,1.6575,0,0,1-.3.38,2.5992,2.5992,0,0,1-1.24.5,2.8515,2.8515,0,0,1,.78.28,2.3417,2.3417,0,0,1,1.14,2.1806V554.52a3.2183,3.2183,0,0,0,.36,1.66.8582.8582,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.9395a1.2033,1.2033,0,0,0-.9-.2207h-1.36v3.8614h1.36a1.1925,1.1925,0,0,0,.9805-.3409c.2-.2392.22-.4394.22-1Z" />
                                        <path className="st18" d="M167.7007,551.7992V553.48a1.9934,1.9934,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9892,4.9892,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.6,6.6,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M180.6016,555.62a1.9107,1.9107,0,0,1-.44.54,2.0154,2.0154,0,0,1-1.32.4405,3.1326,3.1326,0,0,1-2.22-1.0606v4.4014h-2.38V546.1576h2.38v.78a3.2659,3.2659,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0558,3.0558,0,0,1,.48,1.8809V554.1A3.516,3.516,0,0,1,180.6016,555.62Zm-2.0606-6.7607c0-.58-.14-.8008-.42-.9805a1.0936,1.0936,0,0,0-.5205-.14,1.0334,1.0334,0,0,0-.54.14,1.0519,1.0519,0,0,0-.44,1.04v4.6006a1.4279,1.4279,0,0,0,.08.5811.9346.9346,0,0,0,.9.5595.86.86,0,0,0,.84-.48,1.6072,1.6072,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M189.1611,555.56a3.4128,3.4128,0,0,1-2.7207,1.04,4.8521,4.8521,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1716,3.1716,0,0,1,.2-1.3,2.9847,2.9847,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9476,2.9476,0,0,1,.24,1.36v4.94A2.4526,2.4526,0,0,1,189.1611,555.56Zm-1.7807-6.8818c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7459.7459,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12V553.9c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M196.9409,548.3383a1.6912,1.6912,0,0,0-.78-.16,1.6054,1.6054,0,0,0-.9.24,2.13,2.13,0,0,0-.6407,1.94V556.4h-2.36V546.1576H194.64l-.02,1.3008a4.12,4.12,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M202.14,556.5c-1.3808,0-2.1-.3008-2.4-.961a2.6152,2.6152,0,0,1-.2-1.18v-6.6211h-1.3008v-1.581h1.28v-3.7207H201.88v3.7207h1.42v1.581h-1.42V553.58a1.35,1.35,0,0,0,.0595.56c.14.48.6006.56,1.02.56a2.6255,2.6255,0,0,0,.4-.04V556.38A5.0635,5.0635,0,0,1,202.14,556.5Z" />
                                        <path className="st18" d="M205.28,544.6771v-2.4h2.4v2.4Zm0,11.7227V546.1576h2.4V556.4Z" />
                                        <path className="st18" d="M214.5405,556.4v-7.541c0-.6407-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808V556.4h-2.3809V546.1576H212.74l-.02.74a2.6381,2.6381,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V556.4Z" />
                                        <path className="st18" d="M226.0815,557.42a2.5185,2.5185,0,0,1-1.04,2.02,4.3574,4.3574,0,0,1-2.4209.6,6.1138,6.1138,0,0,1-.82-.06,2.59,2.59,0,0,1-2.16-1.48,3.814,3.814,0,0,1-.16-1.26h2.36a1.5445,1.5445,0,0,0,.04.48.8372.8372,0,0,0,.88.6006.973.973,0,0,0,.54-.1406c.4-.22.4209-.68.4209-1.08v-1.58a2.52,2.52,0,0,1-2.0811,1,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.2607v-5.4209a3.5106,3.5106,0,0,1,.1407-1.12,2.044,2.044,0,0,1,2.04-1.4609,2.9163,2.9163,0,0,1,2.1211,1.02v-.82h2.4V556.76C226.1216,556.98,226.1011,557.2006,226.0815,557.42Zm-2.3808-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.041v4.5605a1.9522,1.9522,0,0,0,.08.6006.85.85,0,0,0,.8594.54.96.96,0,0,0,.9805-1.1407Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M400.3037,591.36a4.34,4.34,0,0,1-3.061.9405,4.5188,4.5188,0,0,1-2.94-.82,2.2917,2.2917,0,0,1-.7-.8809,3.8223,3.8223,0,0,1-.3-1.62V581.057a3.3987,3.3987,0,0,1,.88-2.5605,4.5849,4.5849,0,0,1,3.0806-.92c2.0405,0,3.1211.84,3.541,1.66a3.9084,3.9084,0,0,1,.32,1.8809v7.8613A3.065,3.065,0,0,1,400.3037,591.36Zm-1.8-10.0625a2.4848,2.4848,0,0,0-.16-1.1406,1.1037,1.1037,0,0,0-1.0806-.62,1.4373,1.4373,0,0,0-.84.2607c-.58.3995-.5,1.02-.5,1.6006v7.1407a2.238,2.238,0,0,0,.24,1.3007,1.3441,1.3441,0,0,0,1.06.42,1.5192,1.5192,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M410.1235,591.2191a1.91,1.91,0,0,1-.44.54,2.016,2.016,0,0,1-1.32.44,3.1326,3.1326,0,0,1-2.22-1.0606V595.54h-2.38V581.7572h2.38v.78a3.2659,3.2659,0,0,1,2.1406-.9805,2.18,2.18,0,0,1,1.7.82,3.0559,3.0559,0,0,1,.48,1.8809V589.7A3.515,3.515,0,0,1,410.1235,591.2191Zm-2.06-6.7607c0-.58-.14-.8008-.42-.9805a1.0939,1.0939,0,0,0-.5205-.14,1.0333,1.0333,0,0,0-.54.14,1.0519,1.0519,0,0,0-.44,1.04v4.6006a1.4278,1.4278,0,0,0,.08.5811.9347.9347,0,0,0,.9.56.86.86,0,0,0,.84-.4805,1.6072,1.6072,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M415.2422,587.3988V589.08a1.9944,1.9944,0,0,0,.06.68.8112.8112,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.6,6.6,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M426.4624,583.9379a1.6845,1.6845,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.041h-2.36V581.7572h2.38l-.02,1.3008a4.0846,4.0846,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M432.6221,591.9994c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2534,2.2534,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.05,2.05,0,0,1,.58-1.461,3.6466,3.6466,0,0,1,2.5806-.76c.3,0,2.0805-.02,2.8,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M440.4624,592.1c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.4205v1.5811h-1.4205v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.6485,2.6485,0,0,0,.4-.04V591.98A5.0776,5.0776,0,0,1,440.4624,592.1Z" />
                                        <path className="st18" d="M443.6016,580.2767v-2.4h2.4v2.4Zm0,11.7227V581.7572h2.4v10.2422Z" />
                                        <path className="st18" d="M454.3423,591.16a3.4128,3.4128,0,0,1-2.7207,1.04,4.8512,4.8512,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9837,2.9837,0,0,1,3.0405-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.4526,2.4526,0,0,1,454.3423,591.16Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.7466.7466,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M461.6621,591.9994v-7.541c0-.6407-.04-.9-.36-1.1006a.752.752,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.38V581.7572h2.42l-.02.74a2.6361,2.6361,0,0,1,2.0405-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M470.7817,591.9994c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2538,2.2538,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2083,3.2083,0,0,1,1.0805-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6463,3.6463,0,0,1,2.5805-.76c.3,0,2.08-.02,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2794,1.2794,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M475.4609,591.9994V577.8763h2.4v14.1231Z" />
                                        <path className="st18" d="M495.2422,591.9994l.24-12.2422-2.2807,12.2422h-2.4205l-2.34-12.2422.2,12.2422h-2.56V577.8763h4.2412l1.66,9.8829,1.6807-9.8829h4.1807v14.1231Z" />
                                        <path className="st18" d="M504.7212,591.9994c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3594.3809a2.2556,2.2556,0,0,1-1.7006.74,2.093,2.093,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.3194-1.08a.9625.9625,0,0,0-.5205-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.05,2.05,0,0,1,.58-1.461,3.6466,3.6466,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8008,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0676,5.0676,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2819,1.2819,0,0,0,.8609-.36l.26-.22Z" />
                                        <path className="st18" d="M513.6216,591.9994v-7.541c0-.6407-.04-.9-.36-1.1006a.7525.7525,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809H509.4V581.7572h2.4209l-.0205.74a2.6378,2.6378,0,0,1,2.041-.94,1.9964,1.9964,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M522.7407,591.9994c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3593.3809a2.2574,2.2574,0,0,1-1.7012.74,2.0952,2.0952,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4805-2.1006,3.2142,3.2142,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.3193-1.08a.9645.9645,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38H518.58v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0676,5.0676,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2823,1.2823,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M533.9614,593.02a2.5188,2.5188,0,0,1-1.04,2.02,4.3574,4.3574,0,0,1-2.4209.6,6.1138,6.1138,0,0,1-.82-.06,2.59,2.59,0,0,1-2.16-1.48,3.814,3.814,0,0,1-.16-1.26h2.36a1.5445,1.5445,0,0,0,.04.48.8372.8372,0,0,0,.88.6006.9731.9731,0,0,0,.54-.1406c.4-.22.4209-.68.4209-1.08v-1.58a2.5193,2.5193,0,0,1-2.0811,1,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.2607v-5.4209a3.5134,3.5134,0,0,1,.1406-1.12,2.0443,2.0443,0,0,1,2.04-1.4609,2.9164,2.9164,0,0,1,2.1211,1.02v-.82h2.4V592.36C534.0015,592.58,533.981,592.8,533.9614,593.02Zm-2.3808-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.041v4.5605a1.9515,1.9515,0,0,0,.08.6006.85.85,0,0,0,.8594.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M538.9,587.3988V589.08a2.0072,2.0072,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7526.7526,0,0,0,.7607-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.04-3.08-1.96a6.574,6.574,0,0,1-.1807-1.3408v-4.32a3.8135,3.8135,0,0,1,.2608-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8288,2.8288,0,0,1,.2792,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M553.8013,591.9994v-7.5811a1.12,1.12,0,0,0-.36-1.041.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3008v7.4609h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7911.7911,0,0,0-.5606-.18c-.9794,0-.92,1.04-.92,1.2207v7.5811h-2.36V581.7572h2.38v.6807a2.92,2.92,0,0,1,2.0205-.9,2.442,2.442,0,0,1,1.9209,1.02,3.14,3.14,0,0,1,1.6806-.94,3.2,3.2,0,0,1,.66-.0606,1.9274,1.9274,0,0,1,1.96,1.4,2.7094,2.7094,0,0,1,.1.9209v8.1211Z" />
                                        <path className="st18" d="M561.0806,587.3988V589.08a1.9786,1.9786,0,0,0,.0605.68.81.81,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1005,0-2.8-1.04-3.08-1.96a6.5667,6.5667,0,0,1-.1807-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M571.8413,591.9994v-7.541c0-.6407-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809H567.62V581.7572h2.4209l-.02.74a2.6378,2.6378,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M580.0005,592.1c-1.3809,0-2.1006-.3008-2.4-.961a2.6138,2.6138,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5811h1.28v-3.7207h2.3613v3.7207h1.42v1.5811h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.5605c.14.48.6006.56,1.02.56a2.6367,2.6367,0,0,0,.3994-.04V591.98A5.07,5.07,0,0,1,580.0005,592.1Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M661.0835,800.2172V812.3h-2.6206v-12.083h-2.6006v-2.04h7.8418v2.04Z" />
                                        <path className="st18" d="M670.3638,811.4594a3.4112,3.4112,0,0,1-2.7207,1.041,4.9237,4.9237,0,0,1-1.02-.1,2.4984,2.4984,0,0,1-1.9-1.6211,3.7229,3.7229,0,0,1-.18-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9842,2.9842,0,0,1,3.04-1.66c.32,0,2.28-.0195,2.92,1.5a2.9538,2.9538,0,0,1,.24,1.3613v4.94A2.4506,2.4506,0,0,1,670.3638,811.4594Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.746.746,0,0,0-.48.1406c-.38.2793-.36.6992-.36,1.1191v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M676.623,812.4008c-1.38,0-2.1005-.3008-2.4008-.961a2.6275,2.6275,0,0,1-.2-1.18V803.639h-1.3v-1.581h1.28v-3.7207h2.36v3.7207h1.42v1.581h-1.42v5.84a1.3372,1.3372,0,0,0,.06.5605c.14.4805.6.5606,1.02.5606a2.6512,2.6512,0,0,0,.4-.04v1.72A5.0122,5.0122,0,0,1,676.623,812.4008Z" />
                                        <path className="st18" d="M683.8833,812.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9759,2.9759,0,0,1,.48-2.1006,3.1938,3.1938,0,0,1,1.0806-.76l2.4805-1.24v-.6006c0-.6006-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.9562,2.9562,0,0,0-.08.96v.3808h-2.18v-1.16a2.0481,2.0481,0,0,1,.58-1.461,3.6467,3.6467,0,0,1,2.5806-.76c.3,0,2.08-.0206,2.8,1.2793a1.8764,1.8764,0,0,1,.24.98v5.7012a5.0663,5.0663,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2787,1.2787,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M688.5625,812.3V798.1771h2.4V812.3Z" />
                                        <path className="st18" d="M704.7632,811.4594a3.4112,3.4112,0,0,1-2.7207,1.041,4.9217,4.9217,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9842,2.9842,0,0,1,3.04-1.66c.32,0,2.28-.0195,2.92,1.5a2.9536,2.9536,0,0,1,.24,1.3613v4.94A2.4507,2.4507,0,0,1,704.7632,811.4594Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.746.746,0,0,0-.48.1406c-.38.2793-.36.6992-.36,1.1191v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M711.0825,800.1176c-.44.1-.44.58-.44.68v1.2608h1.06v1.581h-1.08V812.3h-2.36V803.639h-1.06v-1.581h1.06a10.5962,10.5962,0,0,1,.06-2.28,1.7184,1.7184,0,0,1,.6-1.1406,2.8475,2.8475,0,0,1,1.98-.5c.3,0,.6.0195.88.04v1.96A2.1331,2.1331,0,0,0,711.0825,800.1176Z" />
                                        <path className="st18" d="M722.3623,812.4008c-1.38,0-2.1006-.3008-2.4009-.961a2.6275,2.6275,0,0,1-.2-1.18V803.639h-1.3v-1.581h1.28v-3.7207h2.36v3.7207h1.42v1.581h-1.42v5.84a1.3392,1.3392,0,0,0,.06.5605c.14.4805.6.5606,1.02.5606a2.6486,2.6486,0,0,0,.4-.04v1.72A5.0112,5.0112,0,0,1,722.3623,812.4008Z" />
                                        <path className="st18" d="M729.7026,812.3v-7.2823a2.0525,2.0525,0,0,0-.08-.7988.8559.8559,0,0,0-.8-.4609.8887.8887,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209V812.3h-2.38V798.1771h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0236,2.0236,0,0,1,2,1.4,2.938,2.938,0,0,1,.14,1.02V812.3Z" />
                                        <path className="st18" d="M736.9819,807.7v1.68a1.9968,1.9968,0,0,0,.06.6806.8111.8111,0,0,0,.82.58.7529.7529,0,0,0,.76-.56,4.9944,4.9944,0,0,0,.0795-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.041-3.0806-1.961a6.59,6.59,0,0,1-.18-1.34v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8205,2.8205,0,0,1,.28,1.46V807.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M756.2227,805.7191c-.42.5195-1.02.6992-2.3.6992h-2.2007V812.3h-2.6206V798.1771h5.2412a3.7028,3.7028,0,0,1,.7.06,1.9764,1.9764,0,0,1,1.38,1,5.1555,5.1555,0,0,1,.3,2.1211v1.86C756.7231,804.5385,756.6631,805.1781,756.2227,805.7191Zm-2.06-4.0215a1.7071,1.7071,0,0,0-.2-1.1406,1.1207,1.1207,0,0,0-.96-.2988h-1.28v4.1406h1.26c.5406,0,.84-.0605,1-.28a1.83,1.83,0,0,0,.18-.98Z" />
                                        <path className="st18" d="M761.2812,807.7v1.68a1.9968,1.9968,0,0,0,.06.6806.8112.8112,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9952,4.9952,0,0,0,.08-1.16h2.3007v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.041-3.0805-1.961a6.5849,6.5849,0,0,1-.18-1.34v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8205,2.8205,0,0,1,.28,1.46V807.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M772.5024,804.2386a1.6926,1.6926,0,0,0-.78-.16,1.6055,1.6055,0,0,0-.9.24,2.1291,2.1291,0,0,0-.6406,1.9395V812.3h-2.36V802.058h2.3809l-.02,1.3a4.1627,4.1627,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.9805-.8809Z" />
                                        <path className="st18" d="M777.7612,800.1176c-.4394.1-.4394.58-.4394.68v1.2608h1.0595v1.581h-1.08V812.3h-2.36V803.639H773.88v-1.581h1.0605a10.7135,10.7135,0,0,1,.06-2.28,1.7184,1.7184,0,0,1,.6006-1.1406,2.8475,2.8475,0,0,1,1.98-.5c.3,0,.6.0195.88.04v1.96A2.1334,2.1334,0,0,0,777.7612,800.1176Z" />
                                        <path className="st18" d="M786.1216,811.4594a3.41,3.41,0,0,1-2.7207,1.041,4.922,4.922,0,0,1-1.02-.1,2.4977,2.4977,0,0,1-1.9-1.6211,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9849,2.9849,0,0,1,3.041-1.66c.32,0,2.28-.0195,2.9209,1.5a2.9688,2.9688,0,0,1,.2393,1.3613v4.94A2.4486,2.4486,0,0,1,786.1216,811.4594Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7466.7466,0,0,0-.4795.1406c-.38.2793-.36.6992-.36,1.1191v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M793.9019,804.2386a1.6934,1.6934,0,0,0-.78-.16,1.6057,1.6057,0,0,0-.9.24,2.1291,2.1291,0,0,0-.6406,1.9395V812.3h-2.36V802.058h2.3809l-.02,1.3a4.1627,4.1627,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M804.3022,812.3v-7.5811a1.119,1.119,0,0,0-.36-1.041.9343.9343,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3V812.3h-2.36v-7.5215c0-.4209.04-.8-.32-1.1006a.7911.7911,0,0,0-.5606-.18c-.9795,0-.92,1.04-.92,1.2207V812.3h-2.36V802.058h2.38v.6806a2.9206,2.9206,0,0,1,2.0205-.9,2.4442,2.4442,0,0,1,1.9209,1.0195,3.1381,3.1381,0,0,1,1.6807-.9395,3.2042,3.2042,0,0,1,.66-.0605,1.9263,1.9263,0,0,1,1.96,1.4,2.7,2.7,0,0,1,.1.92V812.3Z" />
                                        <path className="st18" d="M813.4409,812.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0922,2.0922,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9782,2.9782,0,0,1,.4805-2.1006,3.2,3.2,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6006-.06-.9-.3193-1.08a.9645.9645,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.9562,2.9562,0,0,0-.08.96v.3808H809.28v-1.16a2.0479,2.0479,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.581-.76c.3,0,2.08-.0206,2.8008,1.2793a1.8764,1.8764,0,0,1,.24.98v5.7012a5.066,5.066,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.1806.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2814,1.2814,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M822.3413,812.3v-7.542c0-.64-.04-.9-.36-1.1a.746.746,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.38V812.3H818.12V802.058h2.4209l-.02.74a2.6346,2.6346,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V812.3Z" />
                                        <path className="st18" d="M833.2222,811.4594a3.3226,3.3226,0,0,1-2.6612,1.041,3.4411,3.4411,0,0,1-2.7207-.961,2.8815,2.8815,0,0,1-.62-2.0195v-4.541a2.9748,2.9748,0,0,1,.5606-1.961,3.585,3.585,0,0,1,2.78-1.16c.24,0,2.3809,0,3.0411,1.541a3.2762,3.2762,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5429,2.5429,0,0,0-.04-.5.8339.8339,0,0,0-.86-.64,1.0788,1.0788,0,0,0-.56.1386c-.32.2207-.42.541-.42,1.2412V809.4a2.1418,2.1418,0,0,0,.06.7.8613.8613,0,0,0,.88.64,1.1788,1.1788,0,0,0,.5205-.14,1.3554,1.3554,0,0,0,.4-1.1807v-1.1807h2.36V809.4A3.1806,3.1806,0,0,1,833.2222,811.4594Z" />
                                        <path className="st18" d="M838.4014,807.7v1.68a2.0161,2.0161,0,0,0,.06.6806.8118.8118,0,0,0,.82.58.7526.7526,0,0,0,.7608-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1,0-2.8-1.041-3.0805-1.961a6.5668,6.5668,0,0,1-.1807-1.34v-4.32a3.8113,3.8113,0,0,1,.2608-1.3809,3.5868,3.5868,0,0,1,5.9018-.18,2.8276,2.8276,0,0,1,.2793,1.46V807.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M658.9834,834.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9759,2.9759,0,0,1,.48-2.1006,3.1938,3.1938,0,0,1,1.0806-.76l2.4805-1.24v-.6006c0-.6006-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.9562,2.9562,0,0,0-.08.96v.3808h-2.18v-1.16a2.0481,2.0481,0,0,1,.58-1.461,3.6467,3.6467,0,0,1,2.5806-.76c.3,0,2.0806-.0206,2.8,1.2793a1.8764,1.8764,0,0,1,.24.98v5.7012a5.0663,5.0663,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2787,1.2787,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M667.8833,834.3v-7.542c0-.64-.04-.9-.36-1.1a.7452.7452,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.38V834.3h-2.38V824.058h2.42l-.02.74a2.6329,2.6329,0,0,1,2.0405-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V834.3Z" />
                                        <path className="st18" d="M677.043,834.3l.02-1a2.2706,2.2706,0,0,1-2,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.5595-.86-1.22-.86-2.8408v-4.66a2.541,2.541,0,0,1,1.04-2.3809,2.3629,2.3629,0,0,1,1.18-.34,2.9921,2.9921,0,0,1,2.0606,1.04v-4.7012h2.38V834.3Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1922,1.1922,0,0,0-.58-.16.927.927,0,0,0-.52.16c-.4.28-.38.7-.38,1.1406V831.34c0,.6.06.8193.34,1a1.1206,1.1206,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.31,1.31,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M696.8438,834.3l.24-12.2432L694.8032,834.3h-2.42l-2.34-12.2432.2,12.2432h-2.5606V820.1771h4.2413l1.66,9.8819,1.6807-9.8819h4.1806V834.3Z" />
                                        <path className="st18" d="M706.3228,834.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.1938,3.1938,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6006-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.9562,2.9562,0,0,0-.08.96v.3808h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.461,3.6464,3.6464,0,0,1,2.5806-.76c.3,0,2.08-.0206,2.8,1.2793a1.8758,1.8758,0,0,1,.24.98v5.7012a5.0649,5.0649,0,0,0,.3,2.32c.0195.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2789,1.2789,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M715.2227,834.3v-7.542c0-.64-.04-.9-.36-1.1a.7455.7455,0,0,0-.4605-.12c-1.12,0-1.02,1.1806-1.02,1.38V834.3h-2.38V824.058h2.42l-.02.74a2.6332,2.6332,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V834.3Z" />
                                        <path className="st18" d="M724.3423,834.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.38a2.2533,2.2533,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.1938,3.1938,0,0,1,1.0806-.76l2.4805-1.24v-.6006c0-.6006-.06-.9-.32-1.08a.959.959,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.9562,2.9562,0,0,0-.08.96v.3808h-2.18v-1.16a2.0483,2.0483,0,0,1,.58-1.461,3.6466,3.6466,0,0,1,2.5806-.76c.3,0,2.08-.0206,2.8,1.2793a1.8764,1.8764,0,0,1,.24.98v5.7012a5.0663,5.0663,0,0,0,.3,2.32c.0195.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2787,1.2787,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M735.5625,835.3207a2.5126,2.5126,0,0,1-1.04,2.0195,4.3442,4.3442,0,0,1-2.42.6006,6.1068,6.1068,0,0,1-.82-.06,2.5906,2.5906,0,0,1-2.1606-1.48,3.8376,3.8376,0,0,1-.16-1.26h2.36a1.5337,1.5337,0,0,0,.04.48.8391.8391,0,0,0,.88.6006.9745.9745,0,0,0,.54-.1407c.4-.22.42-.68.42-1.08v-1.58a2.5206,2.5206,0,0,1-2.0805,1,2.6334,2.6334,0,0,1-1.22-.3008c-.94-.5-1-1.3594-1-2.26v-5.4218a3.5069,3.5069,0,0,1,.14-1.1192,2.0437,2.0437,0,0,1,2.04-1.4609,2.91,2.91,0,0,1,2.1206,1.02v-.82h2.4v10.6025C735.6025,834.88,735.5825,835.1,735.5625,835.3207Zm-2.38-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.041V831.44a1.9439,1.9439,0,0,0,.08.6.8508.8508,0,0,0,.86.541.96.96,0,0,0,.98-1.1406Z" />
                                        <path className="st18" d="M740.502,829.7v1.68a2,2,0,0,0,.06.6806.8112.8112,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9952,4.9952,0,0,0,.08-1.16h2.3007v.82a2.8359,2.8359,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0406,1.84-2.1006,0-2.8008-1.041-3.0805-1.961a6.5849,6.5849,0,0,1-.18-1.34v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8205,2.8205,0,0,1,.28,1.46V829.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.871.871,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M755.4033,834.3v-7.5811a1.119,1.119,0,0,0-.36-1.041.9358.9358,0,0,0-.5-.14c-.8,0-.9605.66-.9605,1.3V834.3h-2.36v-7.5215c0-.4209.04-.8-.32-1.1006a.7915.7915,0,0,0-.56-.18c-.98,0-.92,1.04-.92,1.2207V834.3h-2.36V824.058h2.38v.6806a2.9168,2.9168,0,0,1,2.02-.9,2.4439,2.4439,0,0,1,1.92,1.0195,3.1351,3.1351,0,0,1,1.68-.9395,3.2064,3.2064,0,0,1,.66-.0605,1.9266,1.9266,0,0,1,1.96,1.4,2.6972,2.6972,0,0,1,.1.92V834.3Z" />
                                        <path className="st18" d="M762.6816,829.7v1.68a1.9968,1.9968,0,0,0,.06.6806.8112.8112,0,0,0,.82.58.7529.7529,0,0,0,.76-.56,4.9952,4.9952,0,0,0,.08-1.16h2.3007v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.041-3.0806-1.961a6.59,6.59,0,0,1-.18-1.34v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8257,2.8257,0,0,1,.28,1.46V829.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M773.4429,834.3v-7.542c0-.64-.04-.9-.36-1.1a.7459.7459,0,0,0-.46-.12c-1.12,0-1.0205,1.1806-1.0205,1.38V834.3h-2.3809V824.058h2.4209l-.0205.74a2.6346,2.6346,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V834.3Z" />
                                        <path className="st18" d="M781.6021,834.4008c-1.3809,0-2.1006-.3008-2.4-.961a2.6142,2.6142,0,0,1-.2-1.18V825.639h-1.3008v-1.581h1.28v-3.7207h2.3613v3.7207h1.42v1.581h-1.42v5.84a1.3476,1.3476,0,0,0,.06.5605c.14.4805.6005.5606,1.0205.5606a2.6382,2.6382,0,0,0,.3994-.04v1.72A5.0037,5.0037,0,0,1,781.6021,834.4008Z" />
                                        <path className="st18" d="M795.9019,834.3c-.04-.08-.08-.14-.12-.22a2.5742,2.5742,0,0,1-.26-1.1406v-3.2207c0-.22,0-.4394-.02-.68-.08-.7812-.66-.9609-1.3408-.9609h-1.24V834.3h-2.6006V820.1771h5.2012a2.6406,2.6406,0,0,1,1.7207.46,2.7944,2.7944,0,0,1,.82,2.52v1.52a3.5441,3.5441,0,0,1-.28,1.5205,1.7264,1.7264,0,0,1-.3.38,2.6141,2.6141,0,0,1-1.24.5,2.843,2.843,0,0,1,.7793.28,2.34,2.34,0,0,1,1.1406,2.1806V832.42a3.2208,3.2208,0,0,0,.36,1.66.8457.8457,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.7207-.3-.9395a1.2039,1.2039,0,0,0-.9-.2207h-1.36v3.8614h1.36a1.1962,1.1962,0,0,0,.9805-.3409c.2-.2392.22-.44.22-1Z" />
                                        <path className="st18" d="M802.92,829.7v1.68a2.0128,2.0128,0,0,0,.06.6806.8118.8118,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8286,2.8286,0,0,1,.2793,1.46V829.7Zm1.7-2.9414c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M814.8618,833.58a3.0736,3.0736,0,0,1-2.62.92c-1.1406,0-2.4-.2207-2.9014-1.42a3.5854,3.5854,0,0,1-.22-1.32V830.7h2.2v.8a2.4287,2.4287,0,0,0,.0605.64.7978.7978,0,0,0,.86.66.9723.9723,0,0,0,.54-.1387,1.1064,1.1064,0,0,0,.3995-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.4209a2.5264,2.5264,0,0,1-1.2207-2.46c.02-2.041,1.2607-2.6211,3.1015-2.6211,1.5,0,2.32.52,2.64,1.1006a2.2984,2.2984,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.1191-.88-1.1191a.7778.7778,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7244,2.7244,0,0,1,.38,1.6611A3.3045,3.3045,0,0,1,814.8618,833.58Z" />
                                        <path className="st18" d="M822.022,834.3v-.8408a3.2425,3.2425,0,0,1-2.1807,1.0214,2.2543,2.2543,0,0,1-1.2-.3613,2.5144,2.5144,0,0,1-.8008-2.34V824.058h2.4v7.3613a1.27,1.27,0,0,0,.2207.9805.8722.8722,0,0,0,.64.2607,1.0285,1.0285,0,0,0,.6006-.2011c.3994-.34.32-1.1.32-1.16V824.058h2.4V834.3Z" />
                                        <path className="st18" d="M827.06,834.3V820.1771h2.4V834.3Z" />
                                        <path className="st18" d="M835.2612,834.4008c-1.3808,0-2.1006-.3008-2.4-.961a2.6155,2.6155,0,0,1-.2-1.18V825.639H831.36v-1.581h1.28v-3.7207h2.3614v3.7207h1.42v1.581h-1.42v5.84a1.35,1.35,0,0,0,.06.5605c.14.4805.6006.5606,1.02.5606a2.6382,2.6382,0,0,0,.3995-.04v1.72A5.0047,5.0047,0,0,1,835.2612,834.4008Z" />
                                        <path className="st18" d="M843.8018,833.58a3.0739,3.0739,0,0,1-2.62.92c-1.1406,0-2.4-.2207-2.9013-1.42a3.5839,3.5839,0,0,1-.22-1.32V830.7h2.2v.8a2.4234,2.4234,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9726.9726,0,0,0,.54-.1387,1.1064,1.1064,0,0,0,.3994-.98,1.2467,1.2467,0,0,0-.7-1.32l-2-1.4209a2.5264,2.5264,0,0,1-1.2207-2.46c.02-2.041,1.2607-2.6211,3.1016-2.6211,1.5,0,2.32.52,2.64,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.1191-.88-1.1191a.7778.7778,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7244,2.7244,0,0,1,.38,1.6611A3.305,3.305,0,0,1,843.8018,833.58Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1260.3018,513.1l-.34-2.3008h-2.4l-.38,2.3008h-2.6l2.74-14.1231h2.98l2.6406,14.1231Zm-1.48-10.543-.9395,6.0615h1.8Z" />
                                        <path className="st18" d="M1269.042,513.1l.02-1a2.27,2.27,0,0,1-2,1.2,2.451,2.451,0,0,1-1.4209-.4405c-.76-.56-.8594-1.2207-.8594-2.8408v-4.6611a2.5393,2.5393,0,0,1,1.04-2.38,2.347,2.347,0,0,1,1.18-.3408,2.9921,2.9921,0,0,1,2.0605,1.041v-4.7012h2.3809V513.1Zm.02-7.4414c0-.461-.02-.78-.4394-1.02a1.1927,1.1927,0,0,0-.58-.16.9278.9278,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6006.06.82.34,1a1.1145,1.1145,0,0,0,.58.16.9686.9686,0,0,0,.82-.4,1.3088,1.3088,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1283.7607,513.1v-7.2823a2.061,2.061,0,0,0-.08-.8.8561.8561,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.4262,1.4262,0,0,0-.04.42V513.1h-2.38V498.9769h2.34l.0205,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0246,2.0246,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.0205V513.1Z" />
                                        <path className="st18" d="M1294.4814,512.2592a3.4106,3.4106,0,0,1-2.7207,1.041,4.8315,4.8315,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3008,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.0205,2.92,1.5a2.9532,2.9532,0,0,1,.24,1.36v4.9415A2.4485,2.4485,0,0,1,1294.4814,512.2592Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7514.7514,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1303.4619,512.2592a3.3224,3.3224,0,0,1-2.6611,1.041,3.4408,3.4408,0,0,1-2.7207-.961,3.59,3.59,0,0,1-.42-.72,3.5386,3.5386,0,0,1-.2-1.3008v-4.541a2.9737,2.9737,0,0,1,.5605-1.96,3.5852,3.5852,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.2828,3.2828,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5336,2.5336,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.0686,1.0686,0,0,0-.56.14c-.32.2207-.42.5411-.42,1.2412V510.2a2.1379,2.1379,0,0,0,.06.6992.8609.8609,0,0,0,.88.6406,1.1782,1.1782,0,0,0,.52-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.1806h2.36V510.2A3.1808,3.1808,0,0,1,1303.4619,512.2592Z" />
                                        <path className="st18" d="M1212.3926,528.5179c-.42.5206-1.02.7-2.3.7h-2.2012V535.1h-2.62V520.9769h5.2412a3.7056,3.7056,0,0,1,.7.06,1.9752,1.9752,0,0,1,1.38,1,5.1461,5.1461,0,0,1,.3008,2.1211v1.86C1212.8936,527.3383,1212.833,527.9779,1212.3926,528.5179Zm-2.06-4.0205a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2607c.54,0,.84-.0595,1-.2793a1.8257,1.8257,0,0,0,.1807-.98Z" />
                                        <path className="st18" d="M1219.8721,527.0385a1.6837,1.6837,0,0,0-.7793-.16,1.6088,1.6088,0,0,0-.9.2393,2.1316,2.1316,0,0,0-.6406,1.94V535.1h-2.36V524.8578h2.3809l-.0205,1.3a4.1627,4.1627,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1227.6123,534.2592a3.4107,3.4107,0,0,1-2.7207,1.041,4.8315,4.8315,0,0,1-1.0195-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9836,2.9836,0,0,1,3.041-1.66c.3194,0,2.28-.02,2.92,1.5a2.9517,2.9517,0,0,1,.24,1.36v4.9415A2.4485,2.4485,0,0,1,1227.6123,534.2592Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7516.7516,0,0,0-.4795.14c-.3808.28-.36.7-.36,1.12v5.2012c0,.1807-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1232.2109,538.3a2.8953,2.8953,0,0,1-1.58.34,6.8244,6.8244,0,0,1-.96-.06V536.6c.48,0,.64-.02.84-.2.2608-.24.2207-.44.2207-1.02V524.8578h2.38V536.06A2.5947,2.5947,0,0,1,1232.2109,538.3Zm-1.48-14.9434v-2.38h2.38v2.38Z" />
                                        <path className="st18" d="M1238.0117,530.4984v1.6807a2.0128,2.0128,0,0,0,.06.6806.8122.8122,0,0,0,.82.58.7533.7533,0,0,0,.7607-.5605,4.9859,4.9859,0,0,0,.0791-1.16h2.3008v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1006,0-2.8008-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8233,3.8233,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8215,2.8215,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1250.4316,534.2592a3.3207,3.3207,0,0,1-2.66,1.041,3.4392,3.4392,0,0,1-2.7207-.961,2.8819,2.8819,0,0,1-.62-2.02v-4.541a2.9737,2.9737,0,0,1,.5605-1.96,3.5842,3.5842,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.2828,3.2828,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.6067,2.6067,0,0,0-.04-.5.8352.8352,0,0,0-.86-.6406,1.073,1.073,0,0,0-.5606.14c-.3193.2207-.42.5411-.42,1.2412V532.2a2.1041,2.1041,0,0,0,.0606.6992.8609.8609,0,0,0,.88.6406,1.1788,1.1788,0,0,0,.5206-.14,1.356,1.356,0,0,0,.3994-1.1807v-1.1806h2.3613V532.2A3.1827,3.1827,0,0,1,1250.4316,534.2592Z" />
                                        <path className="st18" d="M1256.5117,535.2c-1.3808,0-2.1006-.3-2.4013-.96a2.6332,2.6332,0,0,1-.1993-1.1806v-6.6211H1252.61v-1.58h1.28v-3.7207h2.36v3.7207h1.4209v1.58h-1.4209v5.8408a1.3289,1.3289,0,0,0,.0605.5605c.14.4805.6.5606,1.02.5606a2.7559,2.7559,0,0,0,.4-.04v1.72A5.0169,5.0169,0,0,1,1256.5117,535.2Z" />
                                        <path className="st18" d="M1265.0518,534.3793a3.0721,3.0721,0,0,1-2.6211.9209c-1.14,0-2.4-.2207-2.9-1.4209a3.5789,3.5789,0,0,1-.22-1.32v-1.06h2.2v.8a2.4285,2.4285,0,0,0,.0606.64.7964.7964,0,0,0,.8594.66.9657.9657,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2485,1.2485,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.0205-2.04,1.2607-2.62,3.1006-2.62,1.501,0,2.32.52,2.6406,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.22.8008.68,1.1211l2.2012,1.54a3.6482,3.6482,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1265.0518,534.3793Z" />
                                        <path className="st18" d="M1277.6113,535.1c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2528,2.2528,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9738,2.9738,0,0,1,.48-2.1006,3.1937,3.1937,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.32-1.08a.9475.9475,0,0,0-.52-.1406.7755.7755,0,0,0-.74.4,2.9531,2.9531,0,0,0-.08.96v.38H1273.45v-1.16a2.0461,2.0461,0,0,1,.58-1.46,3.6472,3.6472,0,0,1,2.581-.7607c.3,0,2.08-.0195,2.8,1.28a1.8758,1.8758,0,0,1,.24.98v5.7012a5.0664,5.0664,0,0,0,.3007,2.32c.02.0606.06.1006.1592.1807Zm-.34-5.1612-1.16.76c-.08.0606-.1806.1406-.2.16-.3007.28-.3408.6806-.3408,1.48,0,.76.1006,1.2.58,1.18a1.2817,1.2817,0,0,0,.86-.3594l.2608-.2207Z" />
                                        <path className="st18" d="M1286.5117,535.1v-7.542c0-.64-.04-.9-.36-1.1a.744.744,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V535.1h-2.38V524.8578h2.42l-.02.74a2.6326,2.6326,0,0,1,2.04-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V535.1Z" />
                                        <path className="st18" d="M1295.6709,535.1l.02-1a2.2714,2.2714,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.2207-.86-2.8408v-4.6611a2.5413,2.5413,0,0,1,1.04-2.38,2.3552,2.3552,0,0,1,1.1807-.3408,2.9941,2.9941,0,0,1,2.06,1.041v-4.7012h2.3809V535.1Zm.02-7.4414c0-.461-.02-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.9251.9251,0,0,0-.52.16c-.4.28-.3809.7-.3809,1.14v4.3613c0,.6006.0606.82.3408,1a1.1081,1.1081,0,0,0,.58.16.9668.9668,0,0,0,.8193-.4,1.3016,1.3016,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1313.1914,534.4594a4.34,4.34,0,0,1-3.06.94,4.5122,4.5122,0,0,1-2.9405-.82,2.2748,2.2748,0,0,1-.7-.88,3.82,3.82,0,0,1-.3007-1.62v-7.9219a3.3976,3.3976,0,0,1,.8808-2.5606,4.5829,4.5829,0,0,1,3.08-.92c2.041,0,3.1211.84,3.541,1.66a3.9111,3.9111,0,0,1,.32,1.88V532.08A3.0666,3.0666,0,0,1,1313.1914,534.4594Zm-1.8008-10.0616a2.47,2.47,0,0,0-.16-1.1406,1.1027,1.1027,0,0,0-1.08-.62,1.4352,1.4352,0,0,0-.84.26c-.58.4-.5,1.0205-.5,1.6005v7.1416a2.2274,2.2274,0,0,0,.24,1.3,1.34,1.34,0,0,0,1.06.4209,1.5291,1.5291,0,0,0,.82-.24c.48-.34.46-.9.46-1.4209Z" />
                                        <path className="st18" d="M1321.3311,527.0385a1.6919,1.6919,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.2393,2.1316,2.1316,0,0,0-.6406,1.94V535.1h-2.36V524.8578h2.3809l-.02,1.3a4.1627,4.1627,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1327.53,535.1l.0205-1a2.2717,2.2717,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.7608-.56-.86-1.2207-.86-2.8408v-4.6611a2.5391,2.5391,0,0,1,1.04-2.38,2.3515,2.3515,0,0,1,1.1806-.3408,2.9962,2.9962,0,0,1,2.0606,1.041v-4.7012h2.38V535.1Zm.0205-7.4414c0-.461-.0205-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.9278.9278,0,0,0-.52.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6006.0605.82.34,1a1.1142,1.1142,0,0,0,.58.16.9674.9674,0,0,0,.82-.4,1.3022,1.3022,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1334.85,530.4984v1.6807a2.0128,2.0128,0,0,0,.06.6806.8129.8129,0,0,0,.82.58.7525.7525,0,0,0,.7607-.5605,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6807,1.8408-3.041,1.8408-2.1,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8084,3.8084,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8347,2.8347,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1346.07,527.0385a1.6915,1.6915,0,0,0-.78-.16,1.6107,1.6107,0,0,0-.9.2393,2.1318,2.1318,0,0,0-.6406,1.94V535.1h-2.36V524.8578h2.3808l-.0205,1.3a4.1759,4.1759,0,0,1,.36-.6,2.218,2.218,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1353.5107,534.3793a3.0709,3.0709,0,0,1-2.62.9209c-1.1406,0-2.4-.2207-2.9013-1.4209a3.5789,3.5789,0,0,1-.22-1.32v-1.06h2.2v.8a2.4285,2.4285,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9675.9675,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.9805,1.2485,1.2485,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.02-2.04,1.2607-2.62,3.1015-2.62,1.5,0,2.32.52,2.64,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14H1351.87v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.2207.8008.6807,1.1211l2.2,1.54a3.6124,3.6124,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3036,3.3036,0,0,1,1353.5107,534.3793Z" />
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st18" d="M982.1318,394.3l-2.7607-6.9014V394.3h-2.6406V380.1771h2.6406v5.501l2.82-5.501h2.6807l-3.2207,6.1016,3.3007,8.0215Z" />
                                            <path className="st18" d="M988.9111,389.6991V391.38a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.56,4.9886,4.9886,0,0,0,.0791-1.16h2.3007v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8255,3.8255,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8188,2.8188,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                            <path className="st18" d="M999.291,395.04a4.6436,4.6436,0,0,1-.8,2.0606,2.3209,2.3209,0,0,1-2.0605.74,8.089,8.089,0,0,1-1.5411-.18l.36-1.88a3.2017,3.2017,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28L994.59,384.058h2.36l1.32,6.5815,1.0205-6.5815h2.3408Z" />
                                            <path className="st18" d="M1012.03,394.4c-1.38,0-2.1006-.3-2.4-.9605a2.6309,2.6309,0,0,1-.2-1.18v-6.6211h-1.3V384.058h1.28v-3.7207h2.36v3.7207h1.42v1.5805h-1.42v5.8409a1.3476,1.3476,0,0,0,.06.56c.1406.48.6006.56,1.02.56a2.716,2.716,0,0,0,.4-.04v1.72A5.06,5.06,0,0,1,1012.03,394.4Z" />
                                            <path className="st18" d="M1019.29,394.3c-.2-.22-.2392-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2547,2.2547,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.8409-.62-.78-1.84-.78-2.74a2.9783,2.9783,0,0,1,.48-2.1006,3.2037,3.2037,0,0,1,1.081-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9566.9566,0,0,0-.52-.14.7773.7773,0,0,0-.74.4,2.9578,2.9578,0,0,0-.08.96v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6445,3.6445,0,0,1,2.58-.76c.3,0,2.08-.02,2.8008,1.28a1.8744,1.8744,0,0,1,.24.9805V391.8a5.0656,5.0656,0,0,0,.3,2.32c.0195.06.0595.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2818,1.2818,0,0,0,.86-.36l.26-.22Z" />
                                            <path className="st18" d="M1029.3711,393.58a3.072,3.072,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5834,3.5834,0,0,1-.22-1.32V390.7h2.2v.8a2.4362,2.4362,0,0,0,.0605.64.7966.7966,0,0,0,.8594.66.965.965,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.4205a2.5265,2.5265,0,0,1-1.2207-2.46c.02-2.0405,1.2608-2.6206,3.1006-2.6206,1.501,0,2.32.5205,2.6406,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14H1027.73v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7227,2.7227,0,0,1,.3808,1.6606A3.3048,3.3048,0,0,1,1029.3711,393.58Z" />
                                            <path className="st18" d="M1036.91,394.3l-2.2012-5.2212V394.3h-2.36V380.1771h2.36v7.3819l1.9609-3.501h2.6406l-2.621,4.2607,2.9013,5.9815Z" />
                                            <path className="st18" d="M1046.5508,393.58a3.071,3.071,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9014-1.42a3.5834,3.5834,0,0,1-.22-1.32V390.7h2.2v.8a2.4362,2.4362,0,0,0,.0605.64.7977.7977,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.4205a2.5265,2.5265,0,0,1-1.2207-2.46c.0205-2.0405,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.5205,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14H1044.91v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6806,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1046.5508,393.58Z" />
                                            <path className="st18" d="M980.4023,415.46a3.4122,3.4122,0,0,1-2.7207,1.0406,4.8452,4.8452,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7279,3.7279,0,0,1-.18-1.38v-4.5811a3.1872,3.1872,0,0,1,.1992-1.3,2.9847,2.9847,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.95,2.95,0,0,1,.24,1.36V413.66A2.4536,2.4536,0,0,1,980.4023,415.46Zm-1.7812-6.8813c0-.64-.06-.92-.38-1.12a.7255.7255,0,0,0-.4394-.12.7513.7513,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12V413.8c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                            <path className="st18" d="M986.7217,404.1176c-.4405.1-.4405.58-.4405.68v1.26h1.0606v1.5805h-1.0811V416.3H983.9v-8.6617h-1.06V406.058h1.06a10.5815,10.5815,0,0,1,.0605-2.28,1.7214,1.7214,0,0,1,.6-1.1406,2.8517,2.8517,0,0,1,1.9805-.5c.3,0,.6006.02.88.04v1.9605A2.103,2.103,0,0,0,986.7217,404.1176Z" />
                                            <path className="st18" d="M998.001,416.4c-1.38,0-2.1006-.3-2.4-.9605a2.6309,2.6309,0,0,1-.2-1.18v-6.6211h-1.3V406.058h1.28v-3.7207h2.36v3.7207h1.42v1.5805h-1.42v5.8409a1.3476,1.3476,0,0,0,.06.56c.1406.48.6006.56,1.02.56a2.716,2.716,0,0,0,.4-.04v1.72A5.06,5.06,0,0,1,998.001,416.4Z" />
                                            <path className="st18" d="M1005.3408,416.3v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8554.8554,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42V416.3h-2.38V402.1771h2.34l.02,4.7012a2.7193,2.7193,0,0,1,2.08-1.0205,2.0251,2.0251,0,0,1,2.0009,1.4,2.9473,2.9473,0,0,1,.14,1.0205V416.3Z" />
                                            <path className="st18" d="M1012.6211,411.6991V413.38a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9886,4.9886,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.824,3.824,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                            <path className="st18" d="M1030.501,414.6c-.6006,2.1-3.0205,2-3.44,2a4.2808,4.2808,0,0,1-2.3408-.6206,2.1533,2.1533,0,0,1-.78-1.08,4.1351,4.1351,0,0,1-.18-1.18v-1.9h2.42v1.34c0,.72.08,1.06.44,1.26a1.0659,1.0659,0,0,0,.48.12.9374.9374,0,0,0,.56-.18c.32-.24.38-.58.38-1.28V402.1771h2.6211V413.1A6.61,6.61,0,0,1,1030.501,414.6Z" />
                                            <path className="st18" d="M1039.12,415.46a3.41,3.41,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.964,2.964,0,0,1,.2393,1.36V413.66A2.4494,2.4494,0,0,1,1039.12,415.46Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V413.8c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                            <path className="st18" d="M1048.7607,415.1a2.1414,2.1414,0,0,1-2.0605,1.42,2.5448,2.5448,0,0,1-1.7207-.7,2.267,2.267,0,0,1-.36-.4605l-.0195.9405h-2.3808V402.1771H1044.6v4.6812c.14-.12.26-.24.4-.34a3.3,3.3,0,0,1,1.74-.66,2.1491,2.1491,0,0,1,2,1.36,2.61,2.61,0,0,1,.1807,1.0205V414.04A2.9067,2.9067,0,0,1,1048.7607,415.1Zm-2.2607-6.2613a1.1631,1.1631,0,0,0-.28-.92.8122.8122,0,0,0-.62-.24.9065.9065,0,0,0-.94.62,2.0255,2.0255,0,0,0-.08.7V413.14a4.4871,4.4871,0,0,0,.0205.46c.08.6.34.9.98.9a.7749.7749,0,0,0,.66-.28,1.5629,1.5629,0,0,0,.26-1.08Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M970.4678,441.5209a10.4609,10.4609,0,0,1-1.56-2.2808,13.6845,13.6845,0,0,1-1.9209-6.7012c0-3.3408,1.4-6.1616,3.3213-8.8022l.8594.76a12.8158,12.8158,0,0,0-2.38,7.8619,14.4441,14.4441,0,0,0,1.84,7.5014c.2.32.4209.62.6407.94Z" />
                                            <path className="st18" d="M978.0488,438.1l-.5009-2.7207h-3.28l-.4805,2.7207h-1.82l3.041-14.1231h1.7608l3.12,14.1231Zm-2.1806-11.5025-1.3008,7.2618h2.6806Z" />
                                            <path className="st18" d="M984.6074,438.12a3.9908,3.9908,0,0,1-1.18.16,2.4149,2.4149,0,0,1-2.1806-.9205c-.4405-.6-.4805-1.32-.5-2.7207h1.54c0,.34.02.66.04,1a1.6135,1.6135,0,0,0,.1407.76,1.2137,1.2137,0,0,0,1.0595.58,1.1232,1.1232,0,0,0,1.16-1.2c0-.88-.4395-1.24-1.04-1.7608l-1.24-1.08a3.0077,3.0077,0,0,1-.86-4.2207,2.1528,2.1528,0,0,1,.86-.6,3.4074,3.4074,0,0,1,1.1807-.2,2.8342,2.8342,0,0,1,1.88.62,1.9618,1.9618,0,0,1,.4.68,4.7144,4.7144,0,0,1,.2,1.82h-1.4209a4.1905,4.1905,0,0,0-.0791-1.12.9337.9337,0,0,0-.9805-.72.989.989,0,0,0-1.0606,1.1,1.0284,1.0284,0,0,0,.12.52,4.0119,4.0119,0,0,0,1.12,1.2007l1.1406.94c.2.18.3994.34.58.52a2.8562,2.8562,0,0,1,.82,2.06A2.4881,2.4881,0,0,1,984.6074,438.12Z" />
                                            <path className="st18" d="M991.3877,438.12a3.9964,3.9964,0,0,1-1.1807.16,2.4132,2.4132,0,0,1-2.18-.9205c-.44-.6-.48-1.32-.5009-2.7207h1.541c0,.34.0195.66.04,1a1.613,1.613,0,0,0,.14.76,1.2151,1.2151,0,0,0,1.06.58,1.1237,1.1237,0,0,0,1.16-1.2c0-.88-.4405-1.24-1.04-1.7608l-1.24-1.08a3.0055,3.0055,0,0,1-.86-4.2207,2.147,2.147,0,0,1,.86-.6,3.4005,3.4005,0,0,1,1.18-.2,2.8348,2.8348,0,0,1,1.8808.62,1.9577,1.9577,0,0,1,.3995.68,4.6966,4.6966,0,0,1,.2,1.82h-1.42a4.1906,4.1906,0,0,0-.08-1.12.9337.9337,0,0,0-.9805-.72.9887.9887,0,0,0-1.06,1.1,1.0291,1.0291,0,0,0,.12.52,4.0008,4.0008,0,0,0,1.12,1.2007l1.14.94c.2.18.4.34.58.52a2.8518,2.8518,0,0,1,.82,2.06A2.4886,2.4886,0,0,1,991.3877,438.12Z" />
                                            <path className="st18" d="M994.8467,426.3373v-2.06h1.58v2.06Zm0,11.7627V428.098h1.58V438.1Z" />
                                            <path className="st18" d="M1003.2871,441a3.8164,3.8164,0,0,1-1.9394.46,2.8771,2.8771,0,0,1-1.9405-.64,1.9759,1.9759,0,0,1-.581-.76,2.8522,2.8522,0,0,1-.1992-1.16h1.4794a1.9017,1.9017,0,0,0,.12.7,1.319,1.319,0,0,0,1.2.58,1.6636,1.6636,0,0,0,.8808-.22c.6-.4.54-1.16.54-1.74v-1.16l-.1807.24a2.2992,2.2992,0,0,1-1.84.86,2.14,2.14,0,0,1-1.78-.88,4.9113,4.9113,0,0,1-.5-2.72c0-.66-.0205-1.32-.0205-1.98,0-.36,0-.72.0205-1.0806a5.2729,5.2729,0,0,1,.5-2.5806,2.1867,2.1867,0,0,1,1.98-1,2.0924,2.0924,0,0,1,1.9405,1.06v-.88h1.48V437.6c0,.36.02.72,0,1.08A2.3467,2.3467,0,0,1,1003.2871,441Zm-.3994-7.7813c0-.64.0195-1.28-.04-1.94a4.8957,4.8957,0,0,0-.1407-1.32,1.2546,1.2546,0,0,0-1.92-.7c-.58.44-.62,1.42-.62,2.86v1.5606c0,.62,0,1.24.04,1.88a1.47,1.47,0,0,0,.4805,1.14,1.2775,1.2775,0,0,0,.76.24,1.291,1.291,0,0,0,1.22-.8,6.6767,6.6767,0,0,0,.2207-2.36Z" />
                                            <path className="st18" d="M1010.8477,438.1v-7.3218c0-.24.08-1.6-1.1807-1.6a1.2357,1.2357,0,0,0-1.2.84,4.3326,4.3326,0,0,0-.14,1.62V438.1h-1.6005V428.098h1.5205v.92c.12-.14.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8986,1.8986,0,0,1,1.7207.86,4.1959,4.1959,0,0,1,.34,2.28V438.1Z" />
                                            <path className="st18" d="M1023.0283,438.1v-6.5615a4.659,4.659,0,0,0-.16-1.7,1.2277,1.2277,0,0,0-1.88-.4c-.5606.5-.4805,1.46-.4805,2.1V438.1h-1.62v-6.5615a4.05,4.05,0,0,0-.2-1.84,1.3139,1.3139,0,0,0-1.9209-.22c-.3994.42-.42,1.0205-.42,2.1006V438.1h-1.6V428.098h1.5v.98a4.5169,4.5169,0,0,1,.44-.52,2.4234,2.4234,0,0,1,1.7-.64,2.0965,2.0965,0,0,1,1.72.78,2.9167,2.9167,0,0,1,.2608.38,4.026,4.026,0,0,1,.42-.5,2.2478,2.2478,0,0,1,1.6406-.66,2.282,2.282,0,0,1,1.26.36,2.7431,2.7431,0,0,1,.98,2.5205V438.1Z" />
                                            <path className="st18" d="M1031.8271,437.38a2.5575,2.5575,0,0,1-2.2.9,2.5078,2.5078,0,0,1-2.501-1.34c-.38-.74-.3194-1.34-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.3409,16.3409,0,0,1,.08-1.72,2.5854,2.5854,0,0,1,.8594-1.9805,2.7741,2.7741,0,0,1,1.8408-.56,2.85,2.85,0,0,1,1.9209.54,2,2,0,0,1,.6.7,6.3957,6.3957,0,0,1,.3408,2.7407v1.48h-4.0215c-.0205.58-.0205,1.1807,0,1.7607a4.2686,4.2686,0,0,0,.1.94,1.1191,1.1191,0,0,0,1.2012.88,1.0428,1.0428,0,0,0,.74-.22c.3994-.3.5-.8.48-1.76h1.501A3.2678,3.2678,0,0,1,1031.8271,437.38Zm-.92-5.6812c0-.38,0-.78-.02-1.16-.04-.78-.36-1.38-1.24-1.38a1.0609,1.0609,0,0,0-1.0606.64,4.6693,4.6693,0,0,0-.2,1.9v.42h2.5205Z" />
                                            <path className="st18" d="M1038.6475,438.1v-7.3218c0-.24.08-1.6-1.18-1.6a1.2361,1.2361,0,0,0-1.2012.84,4.3546,4.3546,0,0,0-.14,1.62V438.1h-1.6006V428.098h1.5205v.92c.12-.14.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1959,4.1959,0,0,1,.34,2.28V438.1Z" />
                                            <path className="st18" d="M1046.0869,438.14a2.6191,2.6191,0,0,1-.9.14,2.7093,2.7093,0,0,1-1.7-.5,3.0627,3.0627,0,0,1-.5205-2.2c0-.24.0205-.5.0205-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6006v2.6806h1.48v1.2h-1.48V435.76a1.4009,1.4009,0,0,0,.18.84c.12.18.28.32.74.32a1.74,1.74,0,0,0,.5605-.08Z" />
                                            <path className="st18" d="M1050.9668,438.12a3.9964,3.9964,0,0,1-1.1807.16,2.4162,2.4162,0,0,1-2.1806-.9205c-.4395-.6-.4795-1.32-.5-2.7207h1.541c0,.34.0195.66.04,1a1.613,1.613,0,0,0,.14.76,1.2151,1.2151,0,0,0,1.0605.58,1.1237,1.1237,0,0,0,1.16-1.2c0-.88-.4405-1.24-1.041-1.7608l-1.24-1.08a3.0062,3.0062,0,0,1-.8594-4.2207,2.1455,2.1455,0,0,1,.8594-.6,3.4074,3.4074,0,0,1,1.1807-.2,2.8362,2.8362,0,0,1,1.8808.62,1.9733,1.9733,0,0,1,.3995.68,4.6966,4.6966,0,0,1,.2,1.82h-1.42a4.1906,4.1906,0,0,0-.08-1.12.9337.9337,0,0,0-.98-.72.989.989,0,0,0-1.0606,1.1,1.0284,1.0284,0,0,0,.12.52,4.0246,4.0246,0,0,0,1.12,1.2007l1.1406.94c.2.18.4.34.58.52a2.8562,2.8562,0,0,1,.82,2.06A2.4886,2.4886,0,0,1,1050.9668,438.12Z" />
                                            <path className="st18" d="M1056.0664,439.24a10.9112,10.9112,0,0,1-1.54,2.2808l-.82-.72a13.6564,13.6564,0,0,0,2.5-8.482,12.627,12.627,0,0,0-2.4-7.8218l.86-.76a14.836,14.836,0,0,1,3.3408,8.8022A13.4334,13.4334,0,0,1,1056.0664,439.24Z" />
                                        </g>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1218.5117,310.1127c-.6006,2.1-3.0205,2-3.44,2a4.2806,4.2806,0,0,1-2.3408-.6206,2.1528,2.1528,0,0,1-.78-1.08,4.1351,4.1351,0,0,1-.18-1.18v-1.9h2.42v1.34c0,.72.08,1.06.4405,1.26a1.0655,1.0655,0,0,0,.48.12.9372.9372,0,0,0,.56-.18c.32-.24.38-.58.38-1.28V297.69h2.6211v10.9224A6.6065,6.6065,0,0,1,1218.5117,310.1127Z" />
                                        <path className="st18" d="M1227.1318,310.9725a3.4124,3.4124,0,0,1-2.7207,1.0406,4.8452,4.8452,0,0,1-1.0205-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1868,3.1868,0,0,1,.1993-1.3,2.9847,2.9847,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.95,2.95,0,0,1,.24,1.36v4.9409A2.4536,2.4536,0,0,1,1227.1318,310.9725Zm-1.7812-6.8813c0-.64-.06-.92-.38-1.12a.7258.7258,0,0,0-.44-.12.7512.7512,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1236.7715,310.6127a2.1414,2.1414,0,0,1-2.0606,1.42,2.545,2.545,0,0,1-1.7207-.7,2.2466,2.2466,0,0,1-.3593-.46l-.0205.94h-2.38V297.69h2.38v4.6812c.1406-.12.26-.24.4-.34a3.3009,3.3009,0,0,1,1.74-.66,2.1492,2.1492,0,0,1,2,1.36,2.6114,2.6114,0,0,1,.1806,1.0205v5.8013A2.9073,2.9073,0,0,1,1236.7715,310.6127Zm-2.2608-6.2613a1.16,1.16,0,0,0-.28-.92.8122.8122,0,0,0-.62-.24.9048.9048,0,0,0-.9395.62,2.0034,2.0034,0,0,0-.08.7v4.1411c0,.08,0,.26.02.46.08.6.34.9.98.9a.7742.7742,0,0,0,.66-.28,1.5629,1.5629,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M1249.9512,311.8129l-.3408-2.3h-2.4l-.38,2.3H1244.23l2.74-14.1231h2.9815l2.6406,14.1231Zm-1.48-10.5425-.94,6.0615h1.8008Z" />
                                        <path className="st18" d="M1259.9307,311.0927a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5685,3.5685,0,0,1-.2207-1.32v-1.06h2.2011v.8a2.445,2.445,0,0,0,.06.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2484,1.2484,0,0,0-.7-1.32l-2.001-1.42a2.5256,2.5256,0,0,1-1.22-2.46c.02-2.0405,1.26-2.6206,3.1006-2.6206,1.5,0,2.32.5205,2.6406,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.7795.7795,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1259.9307,311.0927Z" />
                                        <path className="st18" d="M1268.3105,311.0927a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5685,3.5685,0,0,1-.2207-1.32v-1.06h2.2012v.8a2.4407,2.4407,0,0,0,.06.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2.001-1.42a2.5256,2.5256,0,0,1-1.22-2.46c.0195-2.0405,1.26-2.6206,3.1005-2.6206,1.5,0,2.32.5205,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.8809-1.12a.7794.7794,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1268.3105,311.0927Z" />
                                        <path className="st18" d="M1273.55,307.2118v1.6807a1.9778,1.9778,0,0,0,.0606.68.8116.8116,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9961,4.9961,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.9605a6.6215,6.6215,0,0,1-.18-1.34v-4.3208a3.8035,3.8035,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.0205-.8193-1.0205a.8719.8719,0,0,0-.8809,1.0205v1.26h1.7Z" />
                                        <path className="st18" d="M1285.4912,311.0927a3.0719,3.0719,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5834,3.5834,0,0,1-.22-1.32v-1.06h2.2v.8a2.4362,2.4362,0,0,0,.0605.64.7966.7966,0,0,0,.8594.66.9654.9654,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2484,1.2484,0,0,0-.7-1.32l-2-1.42a2.5264,2.5264,0,0,1-1.2208-2.46c.0206-2.0405,1.2608-2.6206,3.1006-2.6206,1.501,0,2.32.5205,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2011,1.54a3.6633,3.6633,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1285.4912,311.0927Z" />
                                        <path className="st18" d="M1293.8711,311.0927a3.072,3.072,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5834,3.5834,0,0,1-.22-1.32v-1.06h2.2v.8a2.4362,2.4362,0,0,0,.0605.64.7966.7966,0,0,0,.8594.66.965.965,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2484,1.2484,0,0,0-.7-1.32l-2-1.42a2.5265,2.5265,0,0,1-1.2207-2.46c.02-2.0405,1.2608-2.6206,3.1006-2.6206,1.5,0,2.32.5205,2.6406,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.7227,2.7227,0,0,1,.3808,1.6606A3.3048,3.3048,0,0,1,1293.8711,311.0927Z" />
                                        <path className="st18" d="M1305.2119,311.8129v-7.5816a1.1185,1.1185,0,0,0-.36-1.04.9342.9342,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.4615h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.79.79,0,0,0-.5605-.18c-.98,0-.92,1.04-.92,1.22v7.5816h-2.36V301.5707h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.1376,3.1376,0,0,1,1.6807-.94,3.23,3.23,0,0,1,.66-.06,1.927,1.927,0,0,1,1.96,1.4,2.7048,2.7048,0,0,1,.1.92v8.1216Z" />
                                        <path className="st18" d="M1312.49,307.2118v1.6807a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.9953,4.9953,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.81,3.81,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8717.8717,0,0,0-.88,1.0205v1.26h1.7Z" />
                                        <path className="st18" d="M1323.251,311.8129v-7.5415c0-.64-.04-.9-.36-1.1a.7492.7492,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3809V301.5707h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1331.41,311.913c-1.3809,0-2.1006-.3-2.4-.9605a2.6172,2.6172,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5805h1.28V297.85h2.3613v3.7207h1.42v1.5805h-1.42v5.8409a1.3476,1.3476,0,0,0,.06.56c.14.48.6005.56,1.02.56a2.6906,2.6906,0,0,0,.3994-.04v1.72A5.037,5.037,0,0,1,1331.41,311.913Z" />
                                        <path className="st18" d="M1335.0693,314.5135h-.88l1.36-2.7006h-1.1611v-2.5806h2.3408v2.5806Z" />
                                        <path className="st18" d="M1230.4219,332.1927c-.68,1.96-3.3008,1.9205-3.6006,1.9205a6.8621,6.8621,0,0,1-1.34-.14,2.9455,2.9455,0,0,1-2.3808-1.6806,3.7844,3.7844,0,0,1-.2-1.48v-7.6817c0-.24.0205-.5.04-.74a2.6945,2.6945,0,0,1,1-2.1807,4.3006,4.3006,0,0,1,2.9209-.82,4.7068,4.7068,0,0,1,2.6407.64,2.462,2.462,0,0,1,.88,1.04,3.1094,3.1094,0,0,1,.24,1.6v2.1606h-2.5606v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1.001,0-1.2.62-1.2,1.5405v7.5616c0,.92.1992,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7408v-1.84h2.5606v2A5.2234,5.2234,0,0,1,1230.4219,332.1927Z" />
                                        <path className="st18" d="M1238.6416,332.9725a3.4114,3.4114,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.3194,0,2.28-.02,2.92,1.5a2.9486,2.9486,0,0,1,.24,1.36v4.9409A2.4494,2.4494,0,0,1,1238.6416,332.9725Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7478.7478,0,0,0-.4795.14c-.3808.28-.36.7-.36,1.12v5.2012c0,.18-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1250.1025,333.8129v-7.5816a1.1185,1.1185,0,0,0-.36-1.04.9372.9372,0,0,0-.5-.14c-.8008,0-.961.66-.961,1.3v7.4615h-2.36v-7.5215c0-.42.04-.8-.3193-1.1006a.7922.7922,0,0,0-.5606-.18c-.98,0-.92,1.04-.92,1.22v7.5816h-2.36V323.5707h2.38v.68a2.9178,2.9178,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.136,3.136,0,0,1,1.68-.94,3.2353,3.2353,0,0,1,.66-.06,1.9273,1.9273,0,0,1,1.9609,1.4,2.7048,2.7048,0,0,1,.1.92v8.1216Z" />
                                        <path className="st18" d="M1261.4814,333.0326a1.9141,1.9141,0,0,1-.44.54,2.017,2.017,0,0,1-1.32.44,3.1334,3.1334,0,0,1-2.22-1.0606v4.4009H1255.12V323.5707h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1807,2.1807,0,0,1,1.7.82,3.0547,3.0547,0,0,1,.48,1.88v5.4414A3.51,3.51,0,0,1,1261.4814,333.0326Zm-2.06-6.7612c0-.58-.14-.8-.42-.9805a1.0917,1.0917,0,0,0-.5205-.14,1.0382,1.0382,0,0,0-.54.14,1.0529,1.0529,0,0,0-.4394,1.04v4.6006a1.4168,1.4168,0,0,0,.08.5806.9338.9338,0,0,0,.8994.56.86.86,0,0,0,.8408-.48,1.6149,1.6149,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1266.6006,329.2118v1.6807a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9886,4.9886,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8237,3.8237,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8711.8711,0,0,0-.88,1.0205v1.26h1.7Z" />
                                        <path className="st18" d="M1277.3613,333.8129v-7.5415c0-.64-.04-.9-.36-1.1a.7472.7472,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.38V323.5707h2.42l-.02.74a2.6342,2.6342,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1287.7607,333.0927a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5685,3.5685,0,0,1-.2207-1.32v-1.06h2.2012v.8a2.4407,2.4407,0,0,0,.06.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2.001-1.42a2.5256,2.5256,0,0,1-1.22-2.46c.0195-2.0405,1.26-2.6206,3.1005-2.6206,1.5,0,2.32.5205,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.8809-1.12a.7794.7794,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1287.7607,333.0927Z" />
                                        <path className="st18" d="M1294.86,333.8129c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2566,2.2566,0,0,1-1.7012.74,2.09,2.09,0,0,1-1.2392-.38c-.8408-.62-.78-1.84-.78-2.74a2.9788,2.9788,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.3193-1.08a.9592.9592,0,0,0-.5205-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.76c.3,0,2.08-.02,2.8007,1.28a1.8806,1.8806,0,0,1,.2393.9805v5.7012a5.066,5.066,0,0,0,.3008,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1302.7,333.913c-1.3809,0-2.1006-.3-2.4-.9605a2.6172,2.6172,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5805h1.28V319.85h2.3613v3.7207h1.42v1.5805h-1.42v5.8409a1.3476,1.3476,0,0,0,.06.56c.14.48.6006.56,1.0205.56a2.6879,2.6879,0,0,0,.3994-.04v1.72A5.0363,5.0363,0,0,1,1302.7,333.913Z" />
                                        <path className="st18" d="M1305.8389,322.09v-2.4h2.4v2.4Zm0,11.7227V323.5707h2.4v10.2422Z" />
                                        <path className="st18" d="M1316.58,332.9725a3.41,3.41,0,0,1-2.7207,1.0406,4.8421,4.8421,0,0,1-1.02-.1,2.4968,2.4968,0,0,1-1.9-1.6206,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9637,2.9637,0,0,1,.2393,1.36v4.9409A2.45,2.45,0,0,1,1316.58,332.9725Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1323.9,333.8129v-7.5415c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3809V323.5707H1322.1l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9964,1.9964,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1v8.002Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M977.9023,312.5c-.6005,2.1-3.02,2-3.44,2a4.2806,4.2806,0,0,1-2.3408-.6206,2.1534,2.1534,0,0,1-.78-1.08,4.1388,4.1388,0,0,1-.18-1.18v-1.9h2.42v1.34c0,.72.08,1.06.44,1.26a1.0656,1.0656,0,0,0,.4805.12.937.937,0,0,0,.56-.18c.32-.24.38-.58.38-1.28V300.077h2.6211v10.9224A6.6065,6.6065,0,0,1,977.9023,312.5Z" />
                                        <path className="st18" d="M986.5225,313.36a3.4124,3.4124,0,0,1-2.7207,1.0406,4.8455,4.8455,0,0,1-1.0206-.1,2.4981,2.4981,0,0,1-1.9-1.6206,3.7253,3.7253,0,0,1-.18-1.38v-4.5811a3.1872,3.1872,0,0,1,.1992-1.3,2.9848,2.9848,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9491,2.9491,0,0,1,.24,1.36v4.9409A2.4536,2.4536,0,0,1,986.5225,313.36Zm-1.7813-6.8813c0-.64-.06-.92-.38-1.12a.7254.7254,0,0,0-.4394-.12.7513.7513,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12V311.7c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M996.1621,313a2.1414,2.1414,0,0,1-2.0605,1.42,2.5448,2.5448,0,0,1-1.7207-.7,2.2481,2.2481,0,0,1-.3594-.4605l-.02.9405h-2.38V300.077h2.38v4.6812c.1406-.12.26-.24.4-.34a3.3,3.3,0,0,1,1.74-.66,2.1491,2.1491,0,0,1,2,1.36,2.6115,2.6115,0,0,1,.1807,1.0205V311.94A2.9067,2.9067,0,0,1,996.1621,313Zm-2.2607-6.2613a1.16,1.16,0,0,0-.28-.92.8122.8122,0,0,0-.62-.24.9048.9048,0,0,0-.9395.62,2.0038,2.0038,0,0,0-.08.7v4.1411c0,.08,0,.26.02.46.08.6.34.9.98.9a.7741.7741,0,0,0,.66-.28,1.5629,1.5629,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M1012.1621,311.18a2.9653,2.9653,0,0,1-1.32,2.5805,4.1789,4.1789,0,0,1-2.24.44H1004.42V300.077h4.7813a2.9637,2.9637,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.0205v6.6016C1012.1816,310.66,1012.1816,310.9193,1012.1621,311.18Zm-2.581-7.6016a1.389,1.389,0,0,0-.3594-1.2007,1.745,1.745,0,0,0-1.1807-.24h-1.0205V312.14h.9209a4.5933,4.5933,0,0,0,.74-.04c.84-.16.8995-.78.8995-1.48Z" />
                                        <path className="st18" d="M1017.0205,309.599V311.28a1.9778,1.9778,0,0,0,.0606.68.8116.8116,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.9605a6.6215,6.6215,0,0,1-.18-1.34v-4.3208a3.8038,3.8038,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8719.8719,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1028.9609,313.48a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5685,3.5685,0,0,1-.2207-1.32V310.6h2.2012v.8a2.4407,2.4407,0,0,0,.06.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2482,1.2482,0,0,0-.7-1.32l-2.001-1.4205a2.5256,2.5256,0,0,1-1.22-2.46c.0195-2.0405,1.26-2.6206,3.1005-2.6206,1.5,0,2.32.5205,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.8809-1.12a.7794.7794,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1028.9609,313.48Z" />
                                        <path className="st18" d="M1031.94,302.4774v-2.4h2.4v2.4Zm0,11.7227V303.9579h2.4V314.2Z" />
                                        <path className="st18" d="M1043.5215,315.22a2.5181,2.5181,0,0,1-1.04,2.02,4.3563,4.3563,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5875,2.5875,0,0,1-2.16-1.48,3.8171,3.8171,0,0,1-.16-1.26h2.36a1.5472,1.5472,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9767.9767,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V313.32a2.5192,2.5192,0,0,1-2.081,1,2.6366,2.6366,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.26v-5.4213a3.5152,3.5152,0,0,1,.1406-1.12,2.0445,2.0445,0,0,1,2.04-1.46,2.9143,2.9143,0,0,1,2.1211,1.0205v-.82h2.4V314.56C1043.5615,314.78,1043.541,315,1043.5215,315.22Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.0405v4.56a1.9484,1.9484,0,0,0,.08.6006.8491.8491,0,0,0,.8594.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1050.4209,314.2v-7.5415c0-.64-.04-.9-.36-1.1a.7491.7491,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V314.2h-2.3809V303.9579h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1V314.2Z" />
                                        <path className="st18" d="M971.3018,336.2c-.2-.22-.2393-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2546,2.2546,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.8408-.62-.78-1.84-.78-2.74a2.9788,2.9788,0,0,1,.4795-2.1006,3.2041,3.2041,0,0,1,1.0811-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9566.9566,0,0,0-.52-.14.7771.7771,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.76c.3,0,2.08-.02,2.8007,1.28a1.8744,1.8744,0,0,1,.24.9805V333.7a5.0656,5.0656,0,0,0,.3,2.32c.0195.06.0595.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2818,1.2818,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M980.2021,336.2v-7.5415c0-.64-.04-.9-.36-1.1a.7471.7471,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V336.2h-2.38V325.9579h2.42l-.02.74a2.6345,2.6345,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V336.2Z" />
                                        <path className="st18" d="M989.3613,336.2l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5419,2.5419,0,0,1,1.04-2.38,2.3613,2.3613,0,0,1,1.1807-.34,2.9932,2.9932,0,0,1,2.0605,1.0405V322.077h2.38V336.2Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.196,1.196,0,0,0-.58-.16.934.934,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14V333.24c0,.6.0606.82.3409,1a1.1125,1.1125,0,0,0,.58.16.9682.9682,0,0,0,.8194-.4,1.3042,1.3042,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1007.3418,334.34c-.32,1.14-1.26,2.1607-3.66,2.1607a4.5745,4.5745,0,0,1-2.9013-.8,2.4,2.4,0,0,1-.74-.9605,3.1221,3.1221,0,0,1-.2793-1.46v-2.0606h2.58v1.64c0,.52-.04,1.12.46,1.44a1.44,1.44,0,0,0,.8008.2,1.2662,1.2662,0,0,0,.8994-.26c.3808-.3.3808-.92.3808-1.88a1.8016,1.8016,0,0,0-.8808-1.86l-2.46-1.66a3.284,3.284,0,0,1-1.1806-1.1606,3.874,3.874,0,0,1-.5-2.24,4.76,4.76,0,0,1,.34-1.94c.8008-1.72,3.2207-1.72,3.501-1.72.42,0,2.6806,0,3.4209,1.86a2.4112,2.4112,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6411,2.6411,0,0,0,.28,1.36,2.32,2.32,0,0,0,.54.46l2.52,1.7407a5.29,5.29,0,0,1,1.16,1.1,3.363,3.363,0,0,1,.56,2.4405A7.9415,7.9415,0,0,1,1007.3418,334.34Z" />
                                        <path className="st18" d="M1013.0811,336.3c-1.38,0-2.1006-.3-2.4-.9605a2.6309,2.6309,0,0,1-.2-1.18v-6.621h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.1406.48.6005.56,1.02.56a2.7173,2.7173,0,0,0,.4-.04v1.72A5.06,5.06,0,0,1,1013.0811,336.3Z" />
                                        <path className="st18" d="M1020.3408,336.2c-.2-.22-.2392-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2547,2.2547,0,0,1-1.7.74,2.0907,2.0907,0,0,1-1.24-.38c-.8408-.62-.78-1.84-.78-2.74a2.9783,2.9783,0,0,1,.48-2.1006,3.2027,3.2027,0,0,1,1.081-.76l2.48-1.24v-.6c0-.6-.0606-.9-.32-1.08a.9566.9566,0,0,0-.52-.14.7773.7773,0,0,0-.74.4,2.9578,2.9578,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.645,3.645,0,0,1,2.58-.76c.3,0,2.08-.02,2.8008,1.28a1.875,1.875,0,0,1,.24.9805V333.7a5.0669,5.0669,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.282,1.282,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1028.2412,324.0175c-.44.1-.44.58-.44.68v1.26h1.0605v1.5806h-1.081V336.2h-2.36v-8.6616h-1.06v-1.5806h1.06a10.567,10.567,0,0,1,.0606-2.28,1.7205,1.7205,0,0,1,.6-1.1406,2.8513,2.8513,0,0,1,1.98-.5c.3,0,.6006.02.88.04v1.9605A2.103,2.103,0,0,0,1028.2412,324.0175Z" />
                                        <path className="st18" d="M1034.12,324.0175c-.4394.1-.4394.58-.4394.68v1.26h1.06v1.5806h-1.08V336.2h-2.36v-8.6616h-1.0605v-1.5806H1031.3a10.7135,10.7135,0,0,1,.06-2.28,1.7211,1.7211,0,0,1,.6006-1.1406,2.8513,2.8513,0,0,1,1.98-.5c.3,0,.6.02.88.04v1.9605A2.1092,2.1092,0,0,0,1034.12,324.0175Z" />
                                        <path className="st18" d="M1036.7793,324.4774v-2.4h2.4v2.4Zm0,11.7227V325.9579h2.4V336.2Z" />
                                        <path className="st18" d="M1046.041,336.2v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V336.2h-2.3809V325.9579h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V336.2Z" />
                                        <path className="st18" d="M1057.5811,337.22a2.5184,2.5184,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5878,2.5878,0,0,1-2.16-1.48,3.817,3.817,0,0,1-.16-1.26h2.36a1.5482,1.5482,0,0,0,.04.48.8376.8376,0,0,0,.88.6.977.977,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V335.32a2.5192,2.5192,0,0,1-2.0811,1,2.6366,2.6366,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.26v-5.4213a3.5153,3.5153,0,0,1,.1407-1.12,2.0443,2.0443,0,0,1,2.04-1.46,2.9144,2.9144,0,0,1,2.1211,1.0205v-.82h2.4V336.56C1057.6211,336.78,1057.6006,337,1057.5811,337.22Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.0405v4.56a1.9874,1.9874,0,0,0,.0791.6006.8508.8508,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st18" d="M685.5229,291.0575v3.5405h4.0411v2.1006h-4.0411V303h-2.6206V288.8768h6.9219v2.1807Z" />
                                            <path className="st18" d="M695.7632,303v-.84a3.2373,3.2373,0,0,1-2.1807,1.0206,2.2544,2.2544,0,0,1-1.2-.36,2.5158,2.5158,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2712,1.2712,0,0,0,.22.98.8744.8744,0,0,0,.64.26,1.0276,1.0276,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V303Z" />
                                            <path className="st18" d="M705.0229,303v-7.5415c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.4605-.12c-1.12,0-1.02,1.18-1.02,1.38V303h-2.38V292.7577h2.4205l-.02.74a2.6348,2.6348,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V303Z" />
                                            <path className="st18" d="M715.9033,302.16a3.3219,3.3219,0,0,1-2.66,1.0406,3.4393,3.4393,0,0,1-2.7207-.9605,2.8786,2.8786,0,0,1-.62-2.02v-4.541a2.9735,2.9735,0,0,1,.56-1.96,3.5873,3.5873,0,0,1,2.7808-1.16c.24,0,2.38,0,3.04,1.5405a3.2811,3.2811,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5811,2.5811,0,0,0-.04-.5.8339.8339,0,0,0-.86-.64,1.0715,1.0715,0,0,0-.5606.14c-.32.22-.42.5406-.42,1.2407V300.1a2.1226,2.1226,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1713,1.1713,0,0,0,.52-.14,1.3556,1.3556,0,0,0,.4-1.1807v-1.18h2.3609V300.1A3.1829,3.1829,0,0,1,715.9033,302.16Z" />
                                            <path className="st18" d="M721.9829,303.1c-1.38,0-2.1006-.3-2.4009-.9605a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V289.037h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7012,2.7012,0,0,0,.4-.04v1.72A5.0512,5.0512,0,0,1,721.9829,303.1Z" />
                                            <path className="st18" d="M725.1221,291.2772v-2.4h2.4v2.4Zm0,11.7227V292.7577h2.4V303Z" />
                                            <path className="st18" d="M735.8633,302.16a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8418,4.8418,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.9489,2.9489,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,735.8633,302.16Zm-1.7808-6.8813c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                            <path className="st18" d="M743.1826,303v-7.5415c0-.64-.04-.9-.36-1.1a.7492.7492,0,0,0-.4605-.12c-1.12,0-1.02,1.18-1.02,1.38V303h-2.38V292.7577h2.42l-.02.74a2.6345,2.6345,0,0,1,2.04-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V303Z" />
                                            <path className="st18" d="M761.5029,299.9794a2.9649,2.9649,0,0,1-1.32,2.5805,4.1787,4.1787,0,0,1-2.24.44h-4.1812V288.8768h4.7813a2.9643,2.9643,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.0205v6.6016C761.5229,299.4594,761.5229,299.7191,761.5029,299.9794Zm-2.58-7.6016a1.3893,1.3893,0,0,0-.36-1.2007,1.7436,1.7436,0,0,0-1.18-.24h-1.02v10.0019h.92a4.5945,4.5945,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                            <path className="st18" d="M764.1016,291.2772v-2.4h2.4v2.4Zm0,11.7227V292.7577h2.4V303Z" />
                                            <path className="st18" d="M773.2622,303c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0937,2.0937,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9794,2.9794,0,0,1,.48-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3193-1.08a.96.96,0,0,0-.5205-.14.7765.7765,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1806v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0669,5.0669,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                            <path className="st18" d="M784.4829,304.02a2.5182,2.5182,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5877,2.5877,0,0,1-2.16-1.48,3.8171,3.8171,0,0,1-.16-1.26h2.36a1.55,1.55,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9781.9781,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V302.12a2.52,2.52,0,0,1-2.0811,1,2.6366,2.6366,0,0,1-1.22-.3c-.9405-.5-1.001-1.36-1.001-2.26v-5.4213a3.5152,3.5152,0,0,1,.1406-1.12,2.0445,2.0445,0,0,1,2.04-1.46,2.9146,2.9146,0,0,1,2.1211,1.02v-.82h2.4V303.36C784.5229,303.58,784.5024,303.8,784.4829,304.02Zm-2.3808-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.0405v4.56a1.9515,1.9515,0,0,0,.08.6006.8491.8491,0,0,0,.8594.54.96.96,0,0,0,.9805-1.1407Z" />
                                            <path className="st18" d="M791.8423,294.9384a1.6919,1.6919,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.1313,2.1313,0,0,0-.6406,1.94V303h-2.36V292.7577h2.3809l-.0205,1.3a4.1471,4.1471,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                            <path className="st18" d="M798.0015,303c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9592.9592,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38H793.84v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8007,1.28a1.8808,1.8808,0,0,1,.2393.9805v5.7012a5.066,5.066,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2842,1.2842,0,0,0,.86-.36l.26-.22Z" />
                                            <path className="st18" d="M811.0425,303v-7.5816a1.1184,1.1184,0,0,0-.36-1.0405.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3V303h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7909.7909,0,0,0-.5605-.18c-.9805,0-.92,1.04-.92,1.22V303h-2.36V292.7577h2.38v.68a2.92,2.92,0,0,1,2.02-.9,2.4418,2.4418,0,0,1,1.9209,1.02,3.1368,3.1368,0,0,1,1.6806-.94,3.2254,3.2254,0,0,1,.66-.06,1.9271,1.9271,0,0,1,1.96,1.4,2.7074,2.7074,0,0,1,.1.92V303Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M690.5522,328.5209a10.4626,10.4626,0,0,1-1.56-2.2808,13.6839,13.6839,0,0,1-1.92-6.7012c0-3.3408,1.4-6.1616,3.3208-8.8022l.86.76a12.8135,12.8135,0,0,0-2.38,7.8619,14.4445,14.4445,0,0,0,1.84,7.5014c.2.32.42.62.64.94Z" />
                                            <path className="st18" d="M696.6724,312.5575V325.1h-1.7408V312.5575h-2.68v-1.5806h7.1015v1.5806Z" />
                                            <path className="st18" d="M703.7524,325.1v-1.2a2.122,2.122,0,0,1-2.08,1.38,2.3,2.3,0,0,1-1.2-.32,2.3673,2.3673,0,0,1-1.0405-2.28,2.726,2.726,0,0,1,.8-2.1206,14.1149,14.1149,0,0,1,1.9605-1.4205,10.7229,10.7229,0,0,0,1.46-1.2v-.34c0-.36,0-1.4-1.22-1.4-1.28,0-1.2,1.02-1.2,1.42,0,.26.02.5.04.76h-1.4605c0-.28,0-.58.02-.86a2.4123,2.4123,0,0,1,.5-1.76,2.7355,2.7355,0,0,1,2.26-.84,2.5886,2.5886,0,0,1,2.02.72,2.3349,2.3349,0,0,1,.5.84,6.1719,6.1719,0,0,1,.12,1.7007v4.8008a4.8638,4.8638,0,0,0,.18,1.7807,1.3744,1.3744,0,0,0,.3.34Zm-.1-5.7012c-.18.18-.38.36-.56.52-.16.12-.32.24-.5.36a6.1226,6.1226,0,0,0-1.04.88,2.2071,2.2071,0,0,0-.48,1.46c0,.16-.06,1.5,1.1,1.4805a1.3657,1.3657,0,0,0,1.1-.5806,2.6583,2.6583,0,0,0,.38-1.66Z" />
                                            <path className="st18" d="M710.8521,325.12a3.9939,3.9939,0,0,1-1.18.16,2.4139,2.4139,0,0,1-2.18-.9205c-.44-.6-.4805-1.32-.5-2.7207h1.54c0,.34.02.66.04,1a1.6132,1.6132,0,0,0,.14.76,1.2144,1.2144,0,0,0,1.06.58,1.1234,1.1234,0,0,0,1.16-1.2c0-.88-.44-1.24-1.04-1.7608l-1.24-1.08a3.0067,3.0067,0,0,1-.86-4.2207,2.15,2.15,0,0,1,.86-.6,3.4038,3.4038,0,0,1,1.18-.2,2.8346,2.8346,0,0,1,1.88.62,1.9588,1.9588,0,0,1,.4.68,4.7045,4.7045,0,0,1,.2,1.82h-1.42a4.1905,4.1905,0,0,0-.08-1.12.9337.9337,0,0,0-.9805-.72.9888.9888,0,0,0-1.06,1.1,1.0327,1.0327,0,0,0,.12.52,4.0135,4.0135,0,0,0,1.1206,1.2007l1.14.94c.2.18.4.34.58.52a2.8535,2.8535,0,0,1,.82,2.06A2.4883,2.4883,0,0,1,710.8521,325.12Z" />
                                            <path className="st18" d="M717.9917,318.9789l2.56,6.1211h-1.7l-1.94-4.6411-1.02,1.4V325.1h-1.58V310.9769h1.58v8.6621l2.961-4.541h1.84Z" />
                                            <path className="st18" d="M725.2314,325.12a3.9934,3.9934,0,0,1-1.18.16,2.4139,2.4139,0,0,1-2.18-.9205c-.44-.6-.4805-1.32-.5-2.7207h1.5405c0,.34.02.66.04,1a1.6132,1.6132,0,0,0,.14.76,1.2144,1.2144,0,0,0,1.06.58,1.1234,1.1234,0,0,0,1.16-1.2c0-.88-.44-1.24-1.04-1.7608l-1.24-1.08a3.0067,3.0067,0,0,1-.86-4.2207,2.15,2.15,0,0,1,.86-.6,3.405,3.405,0,0,1,1.18-.2,2.8346,2.8346,0,0,1,1.88.62,1.9588,1.9588,0,0,1,.4.68,4.7045,4.7045,0,0,1,.2,1.82h-1.42a4.17,4.17,0,0,0-.08-1.12.9326.9326,0,0,0-.98-.72.9888.9888,0,0,0-1.06,1.1,1.0291,1.0291,0,0,0,.12.52,4.0055,4.0055,0,0,0,1.12,1.2007l1.14.94c.2.18.4.34.58.52a2.8535,2.8535,0,0,1,.82,2.06A2.4883,2.4883,0,0,1,725.2314,325.12Z" />
                                            <path className="st18" d="M728.6309,327.8407V326.8c.68-.16.76-.74.76-1.34a2.1743,2.1743,0,0,0-.02-.36h-.74v-2.36h1.7v2.44C730.3311,326.5,730.0508,327.54,728.6309,327.8407Z" />
                                            <path className="st18" d="M742.4316,325.1l-.5-2.7207H738.65l-.48,2.7207h-1.82l3.04-14.1231h1.7608L744.272,325.1Zm-2.1806-11.5025-1.3,7.2618h2.6806Z" />
                                            <path className="st18" d="M749.8711,325.1l-.02-.92a2.4735,2.4735,0,0,1-2.1206,1.1006,2.0357,2.0357,0,0,1-1.3-.44c-.8-.66-.76-1.62-.76-3.46V315.098h1.6v6.5611c0,.28,0,.56.02.84a2.3653,2.3653,0,0,0,.14.92,1.0771,1.0771,0,0,0,1.04.66,1.22,1.22,0,0,0,.78-.26c.52-.4.58-1.02.58-2.2007v-6.521h1.5805V325.1Z" />
                                            <path className="st18" d="M757.231,325.14a2.62,2.62,0,0,1-.9.14,2.71,2.71,0,0,1-1.7007-.5,3.06,3.06,0,0,1-.52-2.2c0-.24.02-.5.02-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6v2.6806h1.4805v1.2h-1.4805V322.76a1.3971,1.3971,0,0,0,.18.84c.12.18.28.32.74.32a1.7357,1.7357,0,0,0,.56-.08Z" />
                                            <path className="st18" d="M762.9507,325.1v-6.0215c0-.48,0-.96-.02-1.44a1.5921,1.5921,0,0,0-.4-1.1606,1.1014,1.1014,0,0,0-.82-.3,1.1625,1.1625,0,0,0-.88.34c-.48.5206-.46,1.26-.46,2.5806V325.1h-1.58V310.9769h1.58v5.021a2.4681,2.4681,0,0,1,1.32-1,3.7241,3.7241,0,0,1,.7-.08,2.173,2.173,0,0,1,1.22.36c.96.64.92,1.92.92,2.9409V325.1Z" />
                                            <path className="st18" d="M772.251,322.12a6.1252,6.1252,0,0,1-.1,1.12c-.16.94-.66,2.0406-2.7207,2.0406a2.5827,2.5827,0,0,1-2.36-1.1006,4.58,4.58,0,0,1-.4209-2.3v-1.3c0-.88,0-1.74.04-2.6006a3.4354,3.4354,0,0,1,.7207-2.34,2.7627,2.7627,0,0,1,2.1406-.72c2.7,0,2.7207,2.26,2.74,3.0806.02.74.02,1.4605,0,2.2007C772.291,320.8392,772.291,321.4794,772.251,322.12Zm-1.6406-4.1411c-.02-.72-.06-1.78-1.08-1.76a1.086,1.086,0,0,0-1.1.94,15.1311,15.1311,0,0,0-.1006,2.4809c0,.8.02,1.62.02,2.42a4.8235,4.8235,0,0,0,.1,1.26.9526.9526,0,0,0,.9805.7c.7,0,.98-.4,1.1-1.0405a22.7822,22.7822,0,0,0,.1006-2.8C770.6309,319.4388,770.6309,318.7186,770.61,317.9784Z" />
                                            <path className="st18" d="M777.51,316.6981c-1.66.44-1.46,2.36-1.46,3.6206V325.1h-1.6406V315.098h1.541v1.88a6.228,6.228,0,0,1,.46-.9,2.4751,2.4751,0,0,1,1.98-1.16h.26v1.68A4.4043,4.4043,0,0,0,777.51,316.6981Z" />
                                            <path className="st18" d="M779.93,313.3373v-2.06h1.581v2.06Zm0,11.7627V315.098h1.581V325.1Z" />
                                            <path className="st18" d="M787.33,325.14a2.6191,2.6191,0,0,1-.9.14,2.7091,2.7091,0,0,1-1.7-.5,3.0623,3.0623,0,0,1-.5205-2.2c0-.24.0205-.5.0205-.74v-5.541h-1.2v-1.2h1.24v-2.6806H785.87v2.6806h1.4805v1.2H785.87V322.76a1.4,1.4,0,0,0,.18.84c.12.18.28.32.74.32a1.74,1.74,0,0,0,.5606-.08Z" />
                                            <path className="st18" d="M788.8887,313.3373v-2.06h1.581v2.06Zm0,11.7627V315.098h1.581V325.1Z" />
                                            <path className="st18" d="M797.65,324.38a2.5606,2.5606,0,0,1-2.2.9,2.5078,2.5078,0,0,1-2.501-1.34c-.38-.74-.32-1.34-.34-2.8808-.0205-.62-.0205-1.24-.0205-1.86a16.6425,16.6425,0,0,1,.08-1.72,2.5893,2.5893,0,0,1,.86-1.9805,2.7742,2.7742,0,0,1,1.8408-.56,2.8486,2.8486,0,0,1,1.92.54,1.9986,1.9986,0,0,1,.6006.7,6.4106,6.4106,0,0,1,.34,2.7407v1.48h-4.02c-.0205.58-.0205,1.1807,0,1.7607a4.2624,4.2624,0,0,0,.1.94,1.1181,1.1181,0,0,0,1.2.88,1.0419,1.0419,0,0,0,.74-.22c.4-.3.5-.8.4805-1.76h1.5A3.2626,3.2626,0,0,1,797.65,324.38Zm-.92-5.6812c0-.38,0-.78-.02-1.16-.04-.78-.36-1.38-1.24-1.38a1.06,1.06,0,0,0-1.0595.64,4.6693,4.6693,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M803.67,325.12a3.9962,3.9962,0,0,1-1.1806.16,2.4163,2.4163,0,0,1-2.1807-.9205c-.4395-.6-.4795-1.32-.5-2.7207h1.541c0,.34.0195.66.0391,1a1.6135,1.6135,0,0,0,.1406.76,1.2151,1.2151,0,0,0,1.06.58,1.1237,1.1237,0,0,0,1.16-1.2c0-.88-.44-1.24-1.041-1.7608l-1.24-1.08a3.0062,3.0062,0,0,1-.8594-4.2207,2.1448,2.1448,0,0,1,.8594-.6,3.4072,3.4072,0,0,1,1.1806-.2,2.8364,2.8364,0,0,1,1.8809.62,1.973,1.973,0,0,1,.3994.68,4.6947,4.6947,0,0,1,.2,1.82h-1.42a4.1906,4.1906,0,0,0-.08-1.12.9337.9337,0,0,0-.9805-.72.9889.9889,0,0,0-1.0605,1.1,1.0291,1.0291,0,0,0,.12.52,4.0246,4.0246,0,0,0,1.12,1.2007l1.1406.94c.2.18.4.34.58.52a2.8557,2.8557,0,0,1,.82,2.06A2.4885,2.4885,0,0,1,803.67,325.12Z" />
                                            <path className="st18" d="M807.0693,327.8407V326.8c.68-.16.76-.74.76-1.34a2.2294,2.2294,0,0,0-.0195-.36h-.74v-2.36h1.7v2.44C808.77,326.5,808.4893,327.54,807.0693,327.8407Z" />
                                            <path className="st18" d="M687.9829,347.1l-2.4009-6.2012h-1.46V347.1h-1.7408V332.9769h3.0811a3.1836,3.1836,0,0,1,3.7407,3.7608,10.2713,10.2713,0,0,1-.14,1.76,3.094,3.094,0,0,1-.58,1.4805,2.1,2.1,0,0,1-1.18.72l2.5205,6.4014Zm-.7-11.5825c-.36-1.02-1.44-.94-2.3008-.94h-.86v4.9409h.92a4.3091,4.3091,0,0,0,1.54-.16c.88-.36.86-1.6.86-2.34A4.7305,4.7305,0,0,0,687.2827,335.5175Z" />
                                            <path className="st18" d="M696.4229,346.38a2.56,2.56,0,0,1-2.2007.9,2.5075,2.5075,0,0,1-2.5-1.34c-.38-.74-.32-1.34-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.4767,16.4767,0,0,1,.08-1.72,2.5872,2.5872,0,0,1,.86-1.9805,2.7736,2.7736,0,0,1,1.8408-.56,2.8509,2.8509,0,0,1,1.92.54,2.0056,2.0056,0,0,1,.6.7,6.4028,6.4028,0,0,1,.34,2.7407v1.48h-4.021c-.02.58-.02,1.1807,0,1.7607a4.267,4.267,0,0,0,.1.94,1.1185,1.1185,0,0,0,1.2.88,1.0421,1.0421,0,0,0,.74-.22c.4-.3.5-.8.48-1.76h1.5A3.2676,3.2676,0,0,1,696.4229,346.38Zm-.9205-5.6812c0-.38,0-.78-.02-1.16-.04-.78-.36-1.38-1.24-1.38a1.0611,1.0611,0,0,0-1.06.64,4.6693,4.6693,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M702.4419,347.12a3.9936,3.9936,0,0,1-1.18.16,2.4142,2.4142,0,0,1-2.18-.9205c-.44-.6-.48-1.32-.5-2.7207h1.54c0,.34.02.66.04,1a1.612,1.612,0,0,0,.14.76,1.2144,1.2144,0,0,0,1.06.58,1.1235,1.1235,0,0,0,1.16-1.2c0-.88-.44-1.24-1.04-1.7608l-1.24-1.08a3.0067,3.0067,0,0,1-.86-4.2207,2.15,2.15,0,0,1,.86-.6,3.404,3.404,0,0,1,1.18-.2,2.8344,2.8344,0,0,1,1.88.62,1.9573,1.9573,0,0,1,.4.68,4.7045,4.7045,0,0,1,.2,1.82h-1.42a4.1864,4.1864,0,0,0-.08-1.12.9335.9335,0,0,0-.98-.72.9888.9888,0,0,0-1.06,1.1,1.0327,1.0327,0,0,0,.12.52,4.0151,4.0151,0,0,0,1.1207,1.2007l1.14.94c.2.18.4.34.58.52a2.854,2.854,0,0,1,.82,2.06A2.4884,2.4884,0,0,1,702.4419,347.12Z" />
                                            <path className="st18" d="M711.7422,344.1a5.0321,5.0321,0,0,1-.36,2.06,2.1388,2.1388,0,0,1-1.96,1.1207,2.1693,2.1693,0,0,1-1.5205-.58,2.303,2.303,0,0,1-.36-.4405v4.021h-1.64V337.098h1.58l.02.96a2.3414,2.3414,0,0,1,2.0205-1.14,2.0973,2.0973,0,0,1,1.34.44,2.3776,2.3776,0,0,1,.86,1.6807,21.2,21.2,0,0,1,.0795,2.3Q711.8022,342.7195,711.7422,344.1Zm-1.62-3.8408a4.871,4.871,0,0,0-.02-.54c-.04-.74-.32-1.52-1.2-1.52a1.2442,1.2442,0,0,0-1.2,1.1,12.6522,12.6522,0,0,0-.14,2.8008c0,.66,0,1.34.04,2a3.3014,3.3014,0,0,0,.28,1.32,1.166,1.166,0,0,0,1.06.66,1.03,1.03,0,0,0,1.02-.82,9.9568,9.9568,0,0,0,.14-1.92c.02-.48.04-.98.04-1.46C710.1421,341.3387,710.1421,340.7987,710.1221,340.2587Z" />
                                            <path className="st18" d="M719.3623,344.12a6.0955,6.0955,0,0,1-.1,1.12c-.16.94-.66,2.0406-2.72,2.0406a2.5839,2.5839,0,0,1-2.3608-1.1006,4.5831,4.5831,0,0,1-.42-2.3v-1.3c0-.88,0-1.74.04-2.6006a3.435,3.435,0,0,1,.72-2.34,2.7622,2.7622,0,0,1,2.14-.72c2.7007,0,2.7207,2.26,2.7407,3.0806.02.74.02,1.4605,0,2.2007C719.4023,342.8392,719.4023,343.4794,719.3623,344.12Zm-1.64-4.1411c-.02-.72-.06-1.78-1.0806-1.76a1.0872,1.0872,0,0,0-1.1.94,15.2059,15.2059,0,0,0-.1,2.4809c0,.8.02,1.62.02,2.42a4.818,4.818,0,0,0,.1,1.26.953.953,0,0,0,.9805.7c.7,0,.98-.4,1.1-1.0405a22.8122,22.8122,0,0,0,.1-2.8C717.7422,341.4388,717.7422,340.7186,717.7222,339.9784Z" />
                                            <path className="st18" d="M725.6416,347.1v-7.3218c0-.24.08-1.6-1.18-1.6a1.2346,1.2346,0,0,0-1.2.84,4.3327,4.3327,0,0,0-.14,1.62V347.1h-1.6V337.098h1.52v.92c.12-.14.22-.28.34-.4a2.6814,2.6814,0,0,1,1.82-.7,1.8978,1.8978,0,0,1,1.72.86,4.1952,4.1952,0,0,1,.34,2.28V347.1Z" />
                                            <path className="st18" d="M732.8613,347.12a3.9934,3.9934,0,0,1-1.18.16,2.4139,2.4139,0,0,1-2.18-.9205c-.44-.6-.4805-1.32-.5-2.7207h1.5405c0,.34.02.66.04,1a1.6132,1.6132,0,0,0,.14.76,1.2144,1.2144,0,0,0,1.06.58,1.1234,1.1234,0,0,0,1.16-1.2c0-.88-.44-1.24-1.04-1.7608l-1.24-1.08a3.0067,3.0067,0,0,1-.86-4.2207,2.15,2.15,0,0,1,.86-.6,3.404,3.404,0,0,1,1.18-.2,2.8346,2.8346,0,0,1,1.88.62,1.9588,1.9588,0,0,1,.4.68,4.7045,4.7045,0,0,1,.2,1.82h-1.42a4.17,4.17,0,0,0-.08-1.12.9326.9326,0,0,0-.98-.72.9888.9888,0,0,0-1.06,1.1,1.0284,1.0284,0,0,0,.12.52,4.0055,4.0055,0,0,0,1.12,1.2007l1.14.94c.2.18.4.34.58.52a2.8535,2.8535,0,0,1,.82,2.06A2.4883,2.4883,0,0,1,732.8613,347.12Z" />
                                            <path className="st18" d="M736.3208,335.3373v-2.06h1.58v2.06Zm0,11.7627V337.098h1.58V347.1Z" />
                                            <path className="st18" d="M746.0415,344.12a5.2489,5.2489,0,0,1-.32,1.94,2.1407,2.1407,0,0,1-2.0205,1.22,2.5225,2.5225,0,0,1-2.02-1.0606v.88h-1.5V332.9769h1.58v4.9209a2.7466,2.7466,0,0,1,.36-.38,2.3136,2.3136,0,0,1,1.6-.6,2.13,2.13,0,0,1,2.0405,1.24,9.4162,9.4162,0,0,1,.32,2.98C746.0815,342.139,746.0815,343.119,746.0415,344.12Zm-1.6-3.501c-.02-.4-.02-.8-.06-1.18-.04-.24-.12-1.36-1.2-1.36a1.3947,1.3947,0,0,0-1.24.84,4.8414,4.8414,0,0,0-.18,2v2.28a5.1758,5.1758,0,0,0,.22,2.1206,1.5556,1.5556,0,0,0,1.28.72,1.009,1.009,0,0,0,1.08-.94,18.6435,18.6435,0,0,0,.12-2.8C744.4614,341.7386,744.4614,341.1786,744.4414,340.6185Z" />
                                            <path className="st18" d="M748.2007,335.3373v-2.06h1.58v2.06Zm0,11.7627V337.098h1.58V347.1Z" />
                                            <path className="st18" d="M752.0405,347.1V332.9769h1.62V347.1Z" />
                                            <path className="st18" d="M755.92,335.3373v-2.06h1.58v2.06Zm0,11.7627V337.098h1.58V347.1Z" />
                                            <path className="st18" d="M763.3208,347.14a2.62,2.62,0,0,1-.9.14,2.71,2.71,0,0,1-1.7007-.5,3.06,3.06,0,0,1-.52-2.2c0-.24.02-.5.02-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6v2.6806h1.48v1.2h-1.48V344.76a1.3977,1.3977,0,0,0,.18.84c.12.18.28.32.74.32a1.7355,1.7355,0,0,0,.56-.08Z" />
                                            <path className="st18" d="M768.6807,345.82c-.08.36-.1407.72-.22,1.08a5.3451,5.3451,0,0,1-1.1211,2.8608,2.4314,2.4314,0,0,1-1.9.6h-1.04v-1.38a9.3348,9.3348,0,0,0,1.0606,0,1.0591,1.0591,0,0,0,1.12-1.16,5.152,5.152,0,0,0-.2-1.22l-2.26-9.502h1.72l1.58,8.0415,1.28-8.0415h1.7Z" />
                                            <path className="st18" d="M776.3594,342.0389v-1.56h6.4218v1.56Z" />
                                            <path className="st18" d="M793.1,334.5575V347.1h-1.74V334.5575h-2.6807v-1.5806H795.78v1.5806Z" />
                                            <path className="st18" d="M801.78,347.1l-.5-2.7207H798L797.52,347.1h-1.82l3.041-14.1231h1.76L803.6211,347.1ZM799.6,335.5975l-1.3,7.2618h2.6807Z" />
                                            <path className="st18" d="M810.74,347.1l-2.4-6.2012h-1.4609V347.1h-1.74V332.9769h3.081a3.1827,3.1827,0,0,1,3.74,3.7608,10.3217,10.3217,0,0,1-.14,1.76,3.093,3.093,0,0,1-.58,1.4805,2.0993,2.0993,0,0,1-1.1806.72l2.52,6.4014Zm-.7-11.5825c-.36-1.02-1.44-.94-2.3007-.94h-.86v4.9409H807.8a4.3041,4.3041,0,0,0,1.54-.16c.88-.36.86-1.6.86-2.34A4.7305,4.7305,0,0,0,810.04,335.5175Z" />
                                            <path className="st18" d="M815.92,348.24a10.9112,10.9112,0,0,1-1.54,2.2808l-.82-.72a13.6558,13.6558,0,0,0,2.5-8.482,12.627,12.627,0,0,0-2.4-7.8218l.86-.76a14.8361,14.8361,0,0,1,3.3409,8.8022A13.4335,13.4335,0,0,1,815.92,348.24Z" />
                                        </g>
                                    </g>
                                    <g>
                                        <path className="st18" d="M701.3154,208.26a4.34,4.34,0,0,1-3.061.9405,4.5151,4.5151,0,0,1-2.94-.82,2.2862,2.2862,0,0,1-.7-.88,3.8236,3.8236,0,0,1-.3-1.62v-7.9219a3.3983,3.3983,0,0,1,.88-2.5606,4.5857,4.5857,0,0,1,3.0806-.92c2.04,0,3.1211.84,3.541,1.66a3.9067,3.9067,0,0,1,.32,1.88V205.88A3.0646,3.0646,0,0,1,701.3154,208.26Zm-1.8008-10.062a2.4816,2.4816,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0806-.62,1.436,1.436,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2375,2.2375,0,0,0,.24,1.3,1.3431,1.3431,0,0,0,1.06.42,1.5235,1.5235,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M709.4546,200.8387a1.686,1.686,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.9405V208.9h-2.36V198.6581h2.38l-.02,1.3a4.115,4.115,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M718.0347,209.92a2.5139,2.5139,0,0,1-1.04,2.02,4.3532,4.3532,0,0,1-2.42.6,6.0007,6.0007,0,0,1-.82-.06,2.5887,2.5887,0,0,1-2.1606-1.48,3.8405,3.8405,0,0,1-.16-1.26h2.36a1.5324,1.5324,0,0,0,.04.48.8382.8382,0,0,0,.88.6.98.98,0,0,0,.54-.14c.4-.22.42-.68.42-1.08V208.02a2.5183,2.5183,0,0,1-2.0805,1,2.6416,2.6416,0,0,1-1.22-.3c-.94-.5-1-1.36-1-2.26v-5.4213a3.5146,3.5146,0,0,1,.14-1.12,2.0438,2.0438,0,0,1,2.04-1.46,2.9116,2.9116,0,0,1,2.1206,1.02v-.82h2.4V209.26C718.0747,209.48,718.0547,209.7006,718.0347,209.92Zm-2.38-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.0405v4.56a1.9484,1.9484,0,0,0,.08.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M724.834,208.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.253,2.253,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9765,2.9765,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.954.954,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8748,1.8748,0,0,1,.24.9805V206.4a5.0671,5.0671,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2811,1.2811,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M733.7339,208.9v-7.5415c0-.64-.04-.9-.36-1.1a.7487.7487,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V208.9h-2.38V198.6581h2.42l-.02.74a2.6345,2.6345,0,0,1,2.0405-.94,1.9958,1.9958,0,0,1,2.06,1.44,3.2407,3.2407,0,0,1,.08,1V208.9Z" />
                                        <path className="st18" d="M738.7334,197.1776v-2.4h2.4v2.4Zm0,11.7227V198.6581h2.4V208.9Z" />
                                        <path className="st18" d="M743.3726,208.9v-2l3.581-6.3213h-3.2007v-1.92h5.6411v1.92L745.853,206.9h3.7012v2Z" />
                                        <path className="st18" d="M755.8535,208.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.2007,3.2007,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8748,1.8748,0,0,1,.24.9805V206.4a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M763.6934,209c-1.38,0-2.1006-.3-2.4009-.9605a2.6306,2.6306,0,0,1-.2-1.18v-6.6211h-1.3v-1.5805h1.28v-3.7207h2.36v3.7207h1.42v1.5805h-1.42V206.08a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6905,2.6905,0,0,0,.4-.04v1.72A5.037,5.037,0,0,1,763.6934,209Z" />
                                        <path className="st18" d="M766.832,197.1776v-2.4h2.4v2.4Zm0,11.7227V198.6581h2.4V208.9Z" />
                                        <path className="st18" d="M777.5732,208.06a3.41,3.41,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1681,3.1681,0,0,1,.2-1.3,2.9841,2.9841,0,0,1,3.0411-1.6606c.32,0,2.28-.02,2.9209,1.5005a2.9653,2.9653,0,0,1,.2392,1.36V206.26A2.4494,2.4494,0,0,1,777.5732,208.06Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V206.4c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M784.8936,208.9v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V208.9h-2.3808V198.6581h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V208.9Z" />
                                        <path className="st18" d="M794.0127,208.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2566,2.2566,0,0,1-1.7011.74,2.0941,2.0941,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9788,2.9788,0,0,1,.48-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9589.9589,0,0,0-.5205-.14.7764.7764,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.1806v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.8813,1.8813,0,0,1,.2392.9805V206.4a5.0674,5.0674,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.284,1.284,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M798.6914,208.9V194.7772h2.4V208.9Z" />
                                        <path className="st18" d="M708.4951,227.88a2.9651,2.9651,0,0,1-1.32,2.5805,4.18,4.18,0,0,1-2.24.44h-4.1812V216.7772h4.7813a2.9645,2.9645,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02V227.1C708.5151,227.36,708.5151,227.62,708.4951,227.88Zm-2.5805-7.6016a1.3893,1.3893,0,0,0-.36-1.2007,1.7436,1.7436,0,0,0-1.18-.24h-1.02V228.84h.92a4.5933,4.5933,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                        <path className="st18" d="M713.354,226.2992V227.98a1.9935,1.9935,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7527.7527,0,0,0,.76-.5605,4.9923,4.9923,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0806-1.9605a6.5959,6.5959,0,0,1-.18-1.34v-4.3208a3.8147,3.8147,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8252,2.8252,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M723.6143,230.9h-2.3208l-2.26-10.2422h2.38l1.0605,7.1015,1.22-7.1015h2.38Z" />
                                        <path className="st18" d="M730.1133,226.2992V227.98a1.9967,1.9967,0,0,0,.06.68.8118.8118,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9923,4.9923,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.9605a6.5885,6.5885,0,0,1-.18-1.34v-4.3208a3.8124,3.8124,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8239,2.8239,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M736.6533,230.9V216.7772h2.4V230.9Z" />
                                        <path className="st18" d="M747.394,230.06a3.4118,3.4118,0,0,1-2.7207,1.0406,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38v-4.5811a3.1765,3.1765,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5005a2.95,2.95,0,0,1,.24,1.36V228.26A2.4515,2.4515,0,0,1,747.394,230.06Zm-1.7807-6.8813c0-.64-.06-.92-.38-1.12a.727.727,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V228.4c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M756.8545,230.12a1.9244,1.9244,0,0,1-.44.54,2.0186,2.0186,0,0,1-1.32.4405,3.1348,3.1348,0,0,1-2.22-1.0606v4.4009h-2.38V220.6581h2.38v.78a3.2641,3.2641,0,0,1,2.1407-.9805,2.18,2.18,0,0,1,1.7.82,3.0551,3.0551,0,0,1,.48,1.88V228.6A3.5191,3.5191,0,0,1,756.8545,230.12Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0932,1.0932,0,0,0-.52-.14,1.0376,1.0376,0,0,0-.54.14,1.0523,1.0523,0,0,0-.44,1.0405v4.6006a1.4258,1.4258,0,0,0,.08.5806.9343.9343,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6075,1.6075,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M768.0742,230.9v-7.5816a1.12,1.12,0,0,0-.3594-1.0405.9405.9405,0,0,0-.5009-.14c-.8,0-.96.66-.96,1.3V230.9h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.791.791,0,0,0-.5605-.18c-.98,0-.92,1.04-.92,1.22V230.9h-2.36V220.6581h2.38v.68a2.9162,2.9162,0,0,1,2.02-.9,2.4374,2.4374,0,0,1,1.92,1.02,3.141,3.141,0,0,1,1.6807-.9405,3.23,3.23,0,0,1,.66-.06,1.9282,1.9282,0,0,1,1.961,1.4,2.7306,2.7306,0,0,1,.1.92V230.9Z" />
                                        <path className="st18" d="M775.3535,226.2992V227.98a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7525.7525,0,0,0,.7607-.5605,4.9906,4.9906,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.81,3.81,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M786.1143,230.9v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V230.9h-2.3808V220.6581h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V230.9Z" />
                                        <path className="st18" d="M794.2734,231c-1.3808,0-2.1005-.3-2.4-.9605a2.6185,2.6185,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5805h1.28v-3.7207h2.3614v3.7207h1.42v1.5805h-1.42V228.08a1.35,1.35,0,0,0,.06.56c.14.48.6006.56,1.0206.56a2.6906,2.6906,0,0,0,.3994-.04v1.72A5.0373,5.0373,0,0,1,794.2734,231Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1209.8682,109.9l-2.501-10.5821.2207,10.5821h-2.4414V95.7772h3.3408l2.541,10.3623-.26-10.3623h2.38V109.9Z" />
                                        <path className="st18" d="M1218.167,105.2992V106.98a1.9809,1.9809,0,0,0,.0605.68.8118.8118,0,0,0,.82.58.7522.7522,0,0,0,.76-.5605,4.9961,4.9961,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.96a6.6141,6.6141,0,0,1-.18-1.34v-4.3208a3.8012,3.8012,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8291,2.8291,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8719.8719,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1226.9668,105.2992V106.98a1.9809,1.9809,0,0,0,.0605.68.8118.8118,0,0,0,.82.58.7522.7522,0,0,0,.76-.5605,4.9961,4.9961,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.96a6.6215,6.6215,0,0,1-.18-1.34v-4.3208a3.8012,3.8012,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8291,2.8291,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8719.8719,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1237.668,109.9l.02-1a2.2687,2.2687,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.4405c-.76-.56-.8594-1.22-.8594-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.353,2.353,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0605,1.04V95.7772h2.3809V109.9Zm.02-7.4414c0-.46-.02-.78-.4395-1.02a1.2,1.2,0,0,0-.58-.16.932.932,0,0,0-.52.16c-.4.28-.38.7-.38,1.14V106.94c0,.6.06.82.34,1a1.119,1.119,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3107,1.3107,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1251.4072,97.7177c-.44.1-.44.58-.44.68v1.26h1.0605v1.5805h-1.08V109.9h-2.36v-8.6617h-1.0605V99.6581h1.0605a10.6257,10.6257,0,0,1,.06-2.28,1.7211,1.7211,0,0,1,.6006-1.1406,2.8487,2.8487,0,0,1,1.98-.5c.3,0,.6.02.88.04v1.9605A2.109,2.109,0,0,0,1251.4072,97.7177Z" />
                                        <path className="st18" d="M1259.7676,109.06a3.4114,3.4114,0,0,1-2.7207,1.0406,4.8421,4.8421,0,0,1-1.02-.1,2.4968,2.4968,0,0,1-1.9-1.6206,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5005a2.9486,2.9486,0,0,1,.24,1.36V107.26A2.45,2.45,0,0,1,1259.7676,109.06Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7478.7478,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12V107.4c0,.18-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1267.5469,101.8387a1.6852,1.6852,0,0,0-.7793-.16,1.6068,1.6068,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.9405V109.9h-2.36V99.6581h2.3809l-.0205,1.3a4.1321,4.1321,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1284.3281,109.9l.24-12.2427L1282.2871,109.9h-2.42l-2.3408-12.2427.2,12.2427h-2.5606V95.7772h4.2412l1.66,9.8823,1.6806-9.8823h4.1807V109.9Z" />
                                        <path className="st18" d="M1293.8066,109.9c-.2-.22-.24-.4-.28-.9-.12.12-.2207.26-.36.38a2.2525,2.2525,0,0,1-1.7.74,2.0921,2.0921,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9743,2.9743,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0605-.9-.32-1.08a.9525.9525,0,0,0-.5195-.14.7758.7758,0,0,0-.74.4,2.9578,2.9578,0,0,0-.08.96v.38h-2.1807v-1.16a2.0475,2.0475,0,0,1,.58-1.4605,3.6475,3.6475,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8742,1.8742,0,0,1,.24.98V107.4a5.079,5.079,0,0,0,.3,2.32c.02.06.0605.1.16.18Zm-.3408-5.1612-1.1592.76c-.08.06-.1806.14-.2.16-.3007.28-.3408.68-.3408,1.48,0,.76.1006,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1302.707,109.9v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V109.9h-2.3808V99.6581h2.4208l-.02.74a2.6364,2.6364,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V109.9Z" />
                                        <path className="st18" d="M1311.8262,109.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2566,2.2566,0,0,1-1.7011.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9793,2.9793,0,0,1,.4805-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9592.9592,0,0,0-.5205-.14.7764.7764,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.1807v-1.16a2.0479,2.0479,0,0,1,.58-1.4605,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.8748,1.8748,0,0,1,.24.98V107.4a5.0656,5.0656,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.284,1.284,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1323.0469,110.92a2.5181,2.5181,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5875,2.5875,0,0,1-2.16-1.48,3.8145,3.8145,0,0,1-.16-1.26h2.36a1.5472,1.5472,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9767.9767,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V109.02a2.5194,2.5194,0,0,1-2.081,1,2.6375,2.6375,0,0,1-1.22-.3c-.94-.5-1.0009-1.36-1.0009-2.26v-5.4213a3.5148,3.5148,0,0,1,.1406-1.12,2.0443,2.0443,0,0,1,2.04-1.46,2.9143,2.9143,0,0,1,2.1211,1.02v-.82h2.4V110.26C1323.0869,110.48,1323.0664,110.7006,1323.0469,110.92Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.04v4.5605a1.9484,1.9484,0,0,0,.08.6006.8491.8491,0,0,0,.8593.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1327.9854,105.2992V106.98a2.0127,2.0127,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7526.7526,0,0,0,.7608-.5605,4.9961,4.9961,0,0,0,.08-1.16h2.3v.82a2.841,2.841,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.5631,6.5631,0,0,1-.1807-1.34v-4.3208a3.81,3.81,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1339.2061,101.8387a1.6934,1.6934,0,0,0-.78-.16,1.6083,1.6083,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.9405V109.9h-2.36V99.6581h2.3809l-.02,1.3a4.1321,4.1321,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1346.6455,109.18a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5834,3.5834,0,0,1-.22-1.32V106.3h2.2v.8a2.4362,2.4362,0,0,0,.0605.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.4205a2.5265,2.5265,0,0,1-1.2207-2.46c.0206-2.0405,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6272,3.6272,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1346.6455,109.18Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1190.7822,211.7l.24-12.2427-2.28,12.2427h-2.4209l-2.34-12.2427.1993,12.2427H1181.62V197.577h4.2412l1.66,9.8824,1.6806-9.8824h4.1807V211.7Z" />
                                        <path className="st18" d="M1200.2607,211.7c-.2-.22-.2392-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2546,2.2546,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.8409-.62-.78-1.84-.78-2.74a2.9788,2.9788,0,0,1,.4795-2.1006,3.2037,3.2037,0,0,1,1.081-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9566.9566,0,0,0-.5205-.14.7771.7771,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.4605,3.6445,3.6445,0,0,1,2.58-.76c.3,0,2.08-.02,2.8008,1.28a1.8744,1.8744,0,0,1,.24.9805V209.2a5.0656,5.0656,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2818,1.2818,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1209.1611,211.7v-7.5415c0-.64-.04-.9-.36-1.1a.7471.7471,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V211.7h-2.38V201.4579h2.42l-.02.74a2.6342,2.6342,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V211.7Z" />
                                        <path className="st18" d="M1218.2812,211.7c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2524,2.2524,0,0,1-1.7.74,2.0921,2.0921,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9743,2.9743,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9522.9522,0,0,0-.5195-.14.7756.7756,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38H1214.12v-1.16a2.0479,2.0479,0,0,1,.58-1.4605,3.6475,3.6475,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805V209.2a5.066,5.066,0,0,0,.3008,2.32c.0195.06.0595.1.1591.18Zm-.34-5.1612-1.16.76c-.08.06-.1806.14-.2.16-.3008.28-.3409.68-.3409,1.48,0,.76.1006,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.2607-.22Z" />
                                        <path className="st18" d="M1229.501,212.72a2.5124,2.5124,0,0,1-1.04,2.02,4.35,4.35,0,0,1-2.42.6,5.9936,5.9936,0,0,1-.82-.06,2.59,2.59,0,0,1-2.1611-1.48,3.84,3.84,0,0,1-.1592-1.26h2.36a1.5169,1.5169,0,0,0,.04.48.8376.8376,0,0,0,.88.6.977.977,0,0,0,.54-.14c.4-.22.42-.68.42-1.08V210.82a2.517,2.517,0,0,1-2.08,1,2.6421,2.6421,0,0,1-1.2207-.3c-.9394-.5-1-1.36-1-2.26v-5.4213a3.5153,3.5153,0,0,1,.1407-1.12,2.0433,2.0433,0,0,1,2.04-1.46,2.9106,2.9106,0,0,1,2.12,1.02v-.82h2.4V212.06C1229.541,212.28,1229.5215,212.5,1229.501,212.72Zm-2.38-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.3809.44-.3809,1.04v4.5605a1.9484,1.9484,0,0,0,.08.6006.8508.8508,0,0,0,.86.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M1234.44,207.099V208.78a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.56,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8255,3.8255,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8188,2.8188,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1249.3418,211.7v-7.5816a1.1184,1.1184,0,0,0-.36-1.0405.9367.9367,0,0,0-.5-.14c-.8007,0-.9609.66-.9609,1.3V211.7h-2.36v-7.5215c0-.42.04-.8-.3194-1.1006a.7922.7922,0,0,0-.5605-.18c-.98,0-.92,1.04-.92,1.22V211.7H1241V201.4579h2.38v.68a2.9173,2.9173,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.1357,3.1357,0,0,1,1.68-.94,3.235,3.235,0,0,1,.66-.06,1.9275,1.9275,0,0,1,1.961,1.4,2.7074,2.7074,0,0,1,.1.92V211.7Z" />
                                        <path className="st18" d="M1256.62,207.099V208.78a1.9778,1.9778,0,0,0,.0606.68.8116.8116,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.99,4.99,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.96a6.6215,6.6215,0,0,1-.18-1.34v-4.3208a3.8038,3.8038,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8293,2.8293,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8719.8719,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1267.3809,211.7v-7.5415c0-.64-.04-.9-.36-1.1a.7471.7471,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V211.7h-2.38V201.4579h2.42l-.02.74a2.6345,2.6345,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V211.7Z" />
                                        <path className="st18" d="M1275.54,211.8c-1.38,0-2.1005-.3-2.4-.96a2.6322,2.6322,0,0,1-.2-1.18v-6.621h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.35,1.35,0,0,0,.06.56c.1407.48.6006.56,1.0206.56a2.7146,2.7146,0,0,0,.4-.04v1.72A5.0589,5.0589,0,0,1,1275.54,211.8Z" />
                                        <path className="st18" d="M1292.001,208.68a2.9653,2.9653,0,0,1-1.32,2.5805,4.1792,4.1792,0,0,1-2.24.44h-4.1816V197.577h4.7812a2.9639,2.9639,0,0,1,1.94.7,3.5317,3.5317,0,0,1,1.04,3.02v6.6016C1292.0205,208.16,1292.0205,208.4193,1292.001,208.68Zm-2.5811-7.6016a1.389,1.389,0,0,0-.3594-1.2007,1.7447,1.7447,0,0,0-1.1806-.24h-1.02V209.64h.9209a4.5933,4.5933,0,0,0,.74-.04c.84-.16.8994-.78.8994-1.48Z" />
                                        <path className="st18" d="M1296.8594,207.099V208.78a1.9809,1.9809,0,0,0,.0605.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8007-1.0406-3.081-1.96a6.6141,6.6141,0,0,1-.18-1.34v-4.3208a3.8016,3.8016,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8719.8719,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1307.1191,211.7h-2.32l-2.2607-10.2422h2.3808l1.0606,7.1015,1.22-7.1015h2.3809Z" />
                                        <path className="st18" d="M1313.6191,207.099V208.78a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1320.1582,211.7V197.577h2.4V211.7Z" />
                                        <path className="st18" d="M1330.8994,210.86a3.41,3.41,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.964,2.964,0,0,1,.2393,1.36v4.9409A2.4494,2.4494,0,0,1,1330.8994,210.86Zm-1.78-6.8813c0-.64-.0595-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12V209.2c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1340.3594,210.92a1.9284,1.9284,0,0,1-.4395.54,2.0216,2.0216,0,0,1-1.32.44,3.1338,3.1338,0,0,1-2.2207-1.0606v4.4009h-2.3809V201.4579h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0556,3.0556,0,0,1,.48,1.88V209.4A3.517,3.517,0,0,1,1340.3594,210.92Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0908,1.0908,0,0,0-.5195-.14,1.0332,1.0332,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4168,1.4168,0,0,0,.08.5806.9349.9349,0,0,0,.9.56.8592.8592,0,0,0,.84-.48,1.6149,1.6149,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1351.58,211.7v-7.5816a1.1184,1.1184,0,0,0-.36-1.0405.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3V211.7h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7909.7909,0,0,0-.56-.18c-.98,0-.92,1.04-.92,1.22V211.7h-2.36V201.4579h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4418,2.4418,0,0,1,1.9209,1.02,3.1411,3.1411,0,0,1,1.6806-.94,3.2307,3.2307,0,0,1,.66-.06,1.9271,1.9271,0,0,1,1.96,1.4,2.7074,2.7074,0,0,1,.1.92V211.7Z" />
                                        <path className="st18" d="M1358.8584,207.099V208.78a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1369.6191,211.7v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V211.7h-2.3808V201.4579h2.4209l-.0205.74a2.6361,2.6361,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V211.7Z" />
                                        <path className="st18" d="M1377.7783,211.8c-1.3808,0-2.1006-.3-2.4-.96a2.6186,2.6186,0,0,1-.2-1.18v-6.621h-1.3007v-1.5806h1.28v-3.7207h2.3614v3.7207h1.42v1.5806h-1.42v5.8408a1.35,1.35,0,0,0,.0595.56c.14.48.6006.56,1.0205.56a2.6879,2.6879,0,0,0,.3994-.04v1.72A5.0429,5.0429,0,0,1,1377.7783,211.8Z" />
                                        <path className="st18" d="M1381.4375,214.4008h-.88l1.36-2.7007h-1.1612V209.12h2.3409V211.7Z" />
                                        <path className="st18" d="M1190.8818,232c-.6,2.1-3.0205,2.0005-3.44,2.0005a4.2806,4.2806,0,0,1-2.3408-.6206,2.1531,2.1531,0,0,1-.7793-1.08,4.1466,4.1466,0,0,1-.1807-1.18v-1.9h2.4209v1.34c0,.72.08,1.06.4395,1.26a1.0655,1.0655,0,0,0,.48.12.9414.9414,0,0,0,.5606-.18c.3193-.24.38-.58.38-1.28V219.577h2.62v10.9224A6.6065,6.6065,0,0,1,1190.8818,232Z" />
                                        <path className="st18" d="M1197.9814,233.7v-.84a3.2368,3.2368,0,0,1-2.1806,1.02,2.2551,2.2551,0,0,1-1.2-.36,2.5168,2.5168,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2689,1.2689,0,0,0,.2207.9805.8732.8732,0,0,0,.64.26,1.0287,1.0287,0,0,0,.6006-.2c.3994-.34.3193-1.1.3193-1.16v-7.2417h2.4V233.7Z" />
                                        <path className="st18" d="M1207.2412,233.7v-7.5415c0-.64-.04-.9-.3594-1.1a.75.75,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.38V233.7h-2.3809V223.4579h2.4209l-.02.74a2.6347,2.6347,0,0,1,2.041-.94,1.9952,1.9952,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V233.7Z" />
                                        <path className="st18" d="M1212.2412,221.9774v-2.4h2.4v2.4Zm0,11.7227V223.4579h2.4V233.7Z" />
                                        <path className="st18" d="M1222.9814,232.86a3.4113,3.4113,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.95,2.95,0,0,1,.24,1.36v4.9409A2.4494,2.4494,0,0,1,1222.9814,232.86Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7478.7478,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12V231.2c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1230.7617,225.6385a1.6868,1.6868,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.9405V233.7h-2.36V223.4579h2.38l-.02,1.3a4.1108,4.1108,0,0,1,.3594-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1238.38,233.7V219.577h6.8418v2.1407h-4.2412v3.3208h4.0009v2.14h-4.0009v4.3408h4.44V233.7Z" />
                                        <path className="st18" d="M1251.4609,233.7l-1.16-4.1411-1.16,4.1411H1246.68l2.2207-5.5611-1.8008-4.6811h2.4609l.88,3.4209.9209-3.4209h2.3l-1.84,4.6811,2.2,5.5611Z" />
                                        <path className="st18" d="M1258.12,229.099V230.78a1.9778,1.9778,0,0,0,.0606.68.8116.8116,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.99,4.99,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0811-1.96a6.6215,6.6215,0,0,1-.18-1.34v-4.3208a3.8038,3.8038,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8293,2.8293,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8719.8719,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1270.541,232.86a3.3223,3.3223,0,0,1-2.66,1.0406,3.4393,3.4393,0,0,1-2.7207-.96,2.8721,2.8721,0,0,1-.62-2.02v-4.541a2.9734,2.9734,0,0,1,.56-1.96,3.5888,3.5888,0,0,1,2.7813-1.16c.2392,0,2.38,0,3.04,1.5405a3.2811,3.2811,0,0,1,.2,1.32v1.22H1268.78v-1.24a2.5408,2.5408,0,0,0-.04-.5.8328.8328,0,0,0-.8593-.64,1.07,1.07,0,0,0-.5606.14c-.32.22-.42.54-.42,1.2407V230.8a2.14,2.14,0,0,0,.06.7.8614.8614,0,0,0,.8808.64,1.1715,1.1715,0,0,0,.52-.14,1.3561,1.3561,0,0,0,.4-1.1807v-1.18h2.36V230.8A3.1817,3.1817,0,0,1,1270.541,232.86Z" />
                                        <path className="st18" d="M1277.6406,233.7v-.84a3.2393,3.2393,0,0,1-2.1806,1.02,2.2527,2.2527,0,0,1-1.2-.36,2.5146,2.5146,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2726,1.2726,0,0,0,.22.9805.8754.8754,0,0,0,.6406.26,1.0262,1.0262,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V233.7Z" />
                                        <path className="st18" d="M1285.8408,233.8c-1.3808,0-2.1006-.3-2.4013-.96a2.63,2.63,0,0,1-.1993-1.18v-6.621H1281.94v-1.5806h1.28v-3.7207h2.36v3.7207h1.4209v1.5806H1285.58v5.8408a1.3289,1.3289,0,0,0,.0605.56c.14.48.6.56,1.02.56a2.6879,2.6879,0,0,0,.4-.04v1.72A5.0429,5.0429,0,0,1,1285.8408,233.8Z" />
                                        <path className="st18" d="M1288.98,221.9774v-2.4h2.4v2.4Zm0,11.7227V223.4579h2.4V233.7Z" />
                                        <path className="st18" d="M1297.74,233.7h-2.3213l-2.26-10.2422h2.38l1.0605,7.1015,1.2207-7.1015h2.38Z" />
                                        <path className="st18" d="M1304.2393,229.099V230.78a2.0127,2.0127,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7526.7526,0,0,0,.7608-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.5631,6.5631,0,0,1-.1807-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1323.4805,227.1185c-.4209.52-1.02.7-2.3008.7h-2.2V233.7h-2.6211V219.577H1321.6a3.6754,3.6754,0,0,1,.7.06,1.9724,1.9724,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C1323.9805,225.9384,1323.92,226.5785,1323.4805,227.1185Zm-2.0606-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.1181,1.1181,0,0,0-.96-.3h-1.28v4.1406h1.26c.54,0,.8408-.06,1-.28a1.8266,1.8266,0,0,0,.1806-.98Z" />
                                        <path className="st18" d="M1326.2783,233.7V219.577h2.4V233.7Z" />
                                        <path className="st18" d="M1335.44,233.7c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9793,2.9793,0,0,1,.4805-2.1006,3.21,3.21,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0595-.9-.3193-1.08a.9592.9592,0,0,0-.5205-.14.7764.7764,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.1807v-1.16a2.0479,2.0479,0,0,1,.58-1.4605,3.6494,3.6494,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8007,1.28a1.8744,1.8744,0,0,1,.24.9805V231.2a5.0656,5.0656,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1344.34,233.7v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V233.7h-2.3808V223.4579h2.4209l-.02.74a2.6361,2.6361,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V233.7Z" />
                                        <path className="st18" d="M1353.56,233.7v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V233.7h-2.3809V223.4579h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V233.7Z" />
                                        <path className="st18" d="M1358.5576,221.9774v-2.4h2.4v2.4Zm0,11.7227V223.4579h2.4V233.7Z" />
                                        <path className="st18" d="M1367.8193,233.7v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V233.7h-2.3808V223.4579h2.4209l-.0206.74a2.6364,2.6364,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V233.7Z" />
                                        <path className="st18" d="M1379.3594,234.72a2.5181,2.5181,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5875,2.5875,0,0,1-2.16-1.48,3.8145,3.8145,0,0,1-.16-1.26h2.36a1.5472,1.5472,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9767.9767,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V232.82a2.5192,2.5192,0,0,1-2.081,1,2.6375,2.6375,0,0,1-1.22-.3c-.94-.5-1.0009-1.36-1.0009-2.26v-5.4213a3.5152,3.5152,0,0,1,.1406-1.12,2.0443,2.0443,0,0,1,2.04-1.46,2.9143,2.9143,0,0,1,2.1211,1.02v-.82h2.4V234.06C1379.3994,234.28,1379.3789,234.5,1379.3594,234.72Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.04v4.5605a1.9484,1.9484,0,0,0,.08.6006.8491.8491,0,0,0,.8593.54.96.96,0,0,0,.98-1.1407Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1500.6309,208.9V194.7772h2.58V208.9Z" />
                                        <path className="st18" d="M1510.1914,208.9v-7.5415c0-.64-.04-.9-.3594-1.1a.75.75,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.38V208.9h-2.3809V198.6581h2.4209l-.02.74a2.6347,2.6347,0,0,1,2.041-.94,1.9952,1.9952,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V208.9Z" />
                                        <path className="st18" d="M1519.3516,208.9l.02-1a2.2687,2.2687,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.4405c-.76-.56-.8594-1.22-.8594-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3526,2.3526,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0606,1.04v-4.7012h2.3809V208.9Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1979,1.1979,0,0,0-.58-.16.9317.9317,0,0,0-.52.16c-.4.28-.38.7-.38,1.14V205.94c0,.6.06.82.34,1a1.119,1.119,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3107,1.3107,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1524.41,197.1776v-2.4h2.4v2.4Zm0,11.7227V198.6581h2.4V208.9Z" />
                                        <path className="st18" d="M1533.1709,208.9h-2.32l-2.2608-10.2422h2.3809l1.06,7.1015,1.22-7.1015h2.3808Z" />
                                        <path className="st18" d="M1537.41,197.1776v-2.4h2.4v2.4Zm0,11.7227V198.6581h2.4V208.9Z" />
                                        <path className="st18" d="M1546.61,208.9l.0205-1a2.2708,2.2708,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5419,2.5419,0,0,1,1.04-2.38,2.3613,2.3613,0,0,1,1.1807-.34,2.9936,2.9936,0,0,1,2.0606,1.04v-4.7012h2.38V208.9Zm.0205-7.4414c0-.46-.0205-.78-.4405-1.02a1.1954,1.1954,0,0,0-.58-.16.9293.9293,0,0,0-.52.16c-.4.28-.3808.7-.3808,1.14V205.94c0,.6.0605.82.3408,1a1.1126,1.1126,0,0,0,.58.16.9681.9681,0,0,0,.8193-.4,1.3041,1.3041,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1555.8506,208.9v-.84a3.2373,3.2373,0,0,1-2.1807,1.02,2.2529,2.2529,0,0,1-1.2-.36,2.5146,2.5146,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.273,1.273,0,0,0,.22.9805.877.877,0,0,0,.6407.26,1.0247,1.0247,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V208.9Z" />
                                        <path className="st18" d="M1565.0107,208.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2566,2.2566,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9788,2.9788,0,0,1,.4795-2.1006,3.2089,3.2089,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0595-.9-.3193-1.08a.9592.9592,0,0,0-.5205-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38H1560.85v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8806,1.8806,0,0,1,.2393.9805V206.4a5.066,5.066,0,0,0,.3008,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1806.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1569.69,208.9V194.7772h2.4V208.9Z" />
                                        <path className="st18" d="M1451.0117,230.9V216.7772h6.8418v2.1406h-4.2412v3.3208h4.001v2.14h-4.001V228.72h4.44V230.9Z" />
                                        <path className="st18" d="M1468.4736,230.9v-7.5816a1.1185,1.1185,0,0,0-.36-1.0405.9372.9372,0,0,0-.5-.14c-.8008,0-.961.66-.961,1.3V230.9h-2.36v-7.5215c0-.42.04-.8-.3193-1.1006a.7922.7922,0,0,0-.5606-.18c-.9805,0-.92,1.04-.92,1.22V230.9h-2.36V220.6581h2.38v.68a2.9176,2.9176,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.136,3.136,0,0,1,1.68-.9405,3.2353,3.2353,0,0,1,.66-.06,1.9273,1.9273,0,0,1,1.9609,1.4,2.7048,2.7048,0,0,1,.1.92V230.9Z" />
                                        <path className="st18" d="M1479.8525,230.12a1.9141,1.9141,0,0,1-.44.54,2.017,2.017,0,0,1-1.32.4405,3.1334,3.1334,0,0,1-2.22-1.0606v4.4009h-2.3809V220.6581h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1807,2.1807,0,0,1,1.7.82,3.0547,3.0547,0,0,1,.4795,1.88V228.6A3.51,3.51,0,0,1,1479.8525,230.12Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.0917,1.0917,0,0,0-.5205-.14,1.0382,1.0382,0,0,0-.54.14,1.0529,1.0529,0,0,0-.4394,1.0405v4.6006a1.418,1.418,0,0,0,.08.5806.9339.9339,0,0,0,.8995.56.86.86,0,0,0,.8408-.48,1.6149,1.6149,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1482.7119,230.9V216.7772h2.4V230.9Z" />
                                        <path className="st18" d="M1493.4521,230.06a3.4113,3.4113,0,0,1-2.7207,1.0406,4.8418,4.8418,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5005a2.95,2.95,0,0,1,.24,1.36V228.26A2.4494,2.4494,0,0,1,1493.4521,230.06Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7478.7478,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12V228.4c0,.18-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1500.3926,231.6405a4.6543,4.6543,0,0,1-.8008,2.06,2.3185,2.3185,0,0,1-2.0606.74,8.087,8.087,0,0,1-1.54-.18l.36-1.88a3.2,3.2,0,0,0,.9.14c.8193,0,1.04-.88,1.08-1.34l.02-.28-2.66-10.2422h2.36l1.32,6.5815,1.02-6.5815h2.34Z" />
                                        <path className="st18" d="M1506.7715,226.2992V227.98a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7534.7534,0,0,0,.7607-.5605,4.9886,4.9886,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8237,3.8237,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1515.5713,226.2992V227.98a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.5605,4.9886,4.9886,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8237,3.8237,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1535.4316,227.88a2.9649,2.9649,0,0,1-1.32,2.5805,4.18,4.18,0,0,1-2.24.44H1527.69V216.7772h4.7813a2.9651,2.9651,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02V227.1C1535.4521,227.36,1535.4521,227.62,1535.4316,227.88Zm-2.58-7.6016a1.39,1.39,0,0,0-.36-1.2007,1.7422,1.7422,0,0,0-1.18-.24h-1.0205V228.84h.92a4.5945,4.5945,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                        <path className="st18" d="M1540.291,226.2992V227.98a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.5605,4.9886,4.9886,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8252,3.8252,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8188,2.8188,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1550.5508,230.9h-2.32l-2.2608-10.2422h2.3809l1.0605,7.1015,1.22-7.1015h2.3808Z" />
                                        <path className="st18" d="M1557.0508,226.2992V227.98a1.9809,1.9809,0,0,0,.0605.68.8107.8107,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.5605,4.9961,4.9961,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8088,3.8088,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1563.59,230.9V216.7772h2.4V230.9Z" />
                                        <path className="st18" d="M1574.3311,230.06a3.4106,3.4106,0,0,1-2.7207,1.0406,4.8421,4.8421,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5005a2.9639,2.9639,0,0,1,.2392,1.36V228.26A2.45,2.45,0,0,1,1574.3311,230.06Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12V228.4c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1583.791,230.12a1.93,1.93,0,0,1-.4394.54,2.0219,2.0219,0,0,1-1.32.4405,3.134,3.134,0,0,1-2.2207-1.0606v4.4009H1577.43V220.6581h2.3808v.78a3.2641,3.2641,0,0,1,2.1407-.9805,2.18,2.18,0,0,1,1.7.82,3.0555,3.0555,0,0,1,.48,1.88V228.6A3.517,3.517,0,0,1,1583.791,230.12Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.09,1.09,0,0,0-.52-.14,1.0332,1.0332,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4168,1.4168,0,0,0,.08.5806.935.935,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6149,1.6149,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1595.0117,230.9v-7.5816a1.1185,1.1185,0,0,0-.36-1.0405.9342.9342,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3V230.9h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.79.79,0,0,0-.5605-.18c-.9795,0-.92,1.04-.92,1.22V230.9h-2.36V220.6581h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.141,3.141,0,0,1,1.6807-.9405,3.23,3.23,0,0,1,.66-.06,1.9269,1.9269,0,0,1,1.96,1.4,2.7048,2.7048,0,0,1,.1.92V230.9Z" />
                                        <path className="st18" d="M1602.29,226.2992V227.98a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7527.7527,0,0,0,.7608-.5605,4.9953,4.9953,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.81,3.81,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1613.0508,230.9v-7.5415c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V230.9h-2.3809V220.6581h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9964,1.9964,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1V230.9Z" />
                                        <path className="st18" d="M1621.21,231c-1.3809,0-2.1006-.3-2.4-.9605a2.6172,2.6172,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5805h1.28v-3.7207h2.3613v3.7207h1.42v1.5805h-1.42V228.08a1.3476,1.3476,0,0,0,.06.56c.14.48.6006.56,1.02.56a2.6906,2.6906,0,0,0,.3994-.04v1.72A5.037,5.037,0,0,1,1621.21,231Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1503.33,312.8V298.6766h2.581V312.8Z" />
                                        <path className="st18" d="M1512.8916,312.8v-7.5415c0-.64-.04-.9-.36-1.1a.7468.7468,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V312.8h-2.38V302.5575h2.42l-.0195.74a2.6342,2.6342,0,0,1,2.04-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V312.8Z" />
                                        <path className="st18" d="M1522.0508,312.8l.0205-1a2.2706,2.2706,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.22-.86-2.84V305.058a2.5418,2.5418,0,0,1,1.04-2.38,2.3609,2.3609,0,0,1,1.1806-.34,2.9936,2.9936,0,0,1,2.0606,1.04v-4.7012h2.38V312.8Zm.0205-7.4414c0-.46-.0205-.78-.44-1.02a1.196,1.196,0,0,0-.58-.16.934.934,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6.0605.82.3408,1a1.1126,1.1126,0,0,0,.58.16.9679.9679,0,0,0,.8193-.4,1.304,1.304,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1527.11,301.077v-2.4h2.4v2.4Zm0,11.7227V302.5575h2.4V312.8Z" />
                                        <path className="st18" d="M1535.8711,312.8H1533.55l-2.26-10.2422h2.38l1.0606,7.1016,1.2207-7.1016h2.38Z" />
                                        <path className="st18" d="M1540.1094,301.077v-2.4h2.4v2.4Zm0,11.7227V302.5575h2.4V312.8Z" />
                                        <path className="st18" d="M1549.3105,312.8l.02-1a2.2689,2.2689,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.4405c-.76-.56-.8594-1.22-.8594-2.84V305.058a2.54,2.54,0,0,1,1.04-2.38,2.3534,2.3534,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0606,1.04v-4.7012h2.3808V312.8Zm.02-7.4414c0-.46-.02-.78-.4395-1.02a1.2005,1.2005,0,0,0-.58-.16.9322.9322,0,0,0-.5205.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.119,1.119,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3112,1.3112,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1558.55,312.8v-.84a3.2375,3.2375,0,0,1-2.1807,1.02,2.2533,2.2533,0,0,1-1.2-.36,2.517,2.517,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2727,1.2727,0,0,0,.22.9805.8767.8767,0,0,0,.6406.26,1.0247,1.0247,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V312.8Z" />
                                        <path className="st18" d="M1567.71,312.8c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.4795-2.1006,3.2082,3.2082,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.3193-1.08a.9592.9592,0,0,0-.5205-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1807v-1.16a2.0481,2.0481,0,0,1,.58-1.46,3.6494,3.6494,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8007,1.28a1.88,1.88,0,0,1,.2393.98v5.7012a5.0659,5.0659,0,0,0,.3008,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2846,1.2846,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1572.3887,312.8V298.6766h2.4V312.8Z" />
                                        <path className="st18" d="M1494.6123,333.18c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8608,6.8608,0,0,1-1.34-.14,2.9456,2.9456,0,0,1-2.3809-1.6806,3.7841,3.7841,0,0,1-.2-1.48v-7.6816c0-.24.0205-.5.04-.74a2.6936,2.6936,0,0,1,1-2.1806,4.3,4.3,0,0,1,2.9209-.82,4.7075,4.7075,0,0,1,2.6406.64,2.4615,2.4615,0,0,1,.88,1.04,3.11,3.11,0,0,1,.24,1.6v2.1606h-2.5605v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1.001,0-1.2.62-1.2,1.5406v7.5615c0,.92.1992,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7408v-1.84h2.5605v2A5.2209,5.2209,0,0,1,1494.6123,333.18Z" />
                                        <path className="st18" d="M1501.252,334.8c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2524,2.2524,0,0,1-1.7.74,2.0921,2.0921,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9744,2.9744,0,0,1,.4805-2.1006,3.1984,3.1984,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0595-.9-.32-1.08a.9522.9522,0,0,0-.52-.14.7756.7756,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1807v-1.16a2.0481,2.0481,0,0,1,.58-1.46,3.6476,3.6476,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.0659,5.0659,0,0,0,.3008,2.32c.02.06.06.1.1592.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3008.28-.3409.68-.3409,1.48,0,.76.1006,1.2.58,1.18a1.2807,1.2807,0,0,0,.86-.36l.2607-.22Z" />
                                        <path className="st18" d="M1510.6113,326.7382a1.6837,1.6837,0,0,0-.7793-.16,1.6068,1.6068,0,0,0-.9.24,2.1313,2.1313,0,0,0-.6406,1.94V334.8h-2.36V324.5575h2.3808l-.0205,1.3a4.1505,4.1505,0,0,1,.36-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1514.9111,330.1986v1.6807a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.56,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8255,3.8255,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8188,2.8188,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1523.7109,330.1986v1.6807a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.56,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8255,3.8255,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8188,2.8188,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1534.9316,326.7382a1.6845,1.6845,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94V334.8h-2.36V324.5575h2.38l-.02,1.3a4.1162,4.1162,0,0,1,.3594-.6,2.2175,2.2175,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1550.291,331.7792a2.9653,2.9653,0,0,1-1.32,2.58,4.1789,4.1789,0,0,1-2.24.44H1542.55V320.6766h4.7813a2.9654,2.9654,0,0,1,1.94.7,3.5317,3.5317,0,0,1,1.04,3.02v6.6016C1550.3115,331.2592,1550.3115,331.5189,1550.291,331.7792Zm-2.58-7.6016a1.39,1.39,0,0,0-.36-1.2007,1.7423,1.7423,0,0,0-1.18-.24H1545.15v10.0019h.92a4.5933,4.5933,0,0,0,.74-.04c.84-.16.9-.78.9-1.48Z" />
                                        <path className="st18" d="M1555.15,330.1986v1.6807a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9412,4.9412,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.824,3.824,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8293,2.8293,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1567.0908,334.08a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5832,3.5832,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3995-.98,1.247,1.247,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.0205-2.04,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14H1565.45v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1567.0908,334.08Z" />
                                        <path className="st18" d="M1570.0693,323.077v-2.4h2.4v2.4Zm0,11.7227V324.5575h2.4V334.8Z" />
                                        <path className="st18" d="M1581.6514,335.82a2.518,2.518,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5875,2.5875,0,0,1-2.16-1.48,3.8145,3.8145,0,0,1-.16-1.26h2.36a1.5471,1.5471,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9777.9777,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08v-1.5806a2.5192,2.5192,0,0,1-2.081,1,2.6375,2.6375,0,0,1-1.22-.3c-.94-.5-1.0009-1.36-1.0009-2.26v-5.4214a3.5152,3.5152,0,0,1,.1406-1.12,2.0443,2.0443,0,0,1,2.04-1.46,2.9144,2.9144,0,0,1,2.1211,1.02v-.82h2.4V335.16C1581.6914,335.38,1581.6709,335.6,1581.6514,335.82Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.0405v4.56a1.9874,1.9874,0,0,0,.0791.6006.8509.8509,0,0,0,.86.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M1588.5508,334.8v-7.5415c0-.64-.04-.9-.36-1.1a.7491.7491,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V334.8h-2.3809V324.5575h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9964,1.9964,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1V334.8Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1499.9814,453.6185c-.42.5205-1.0195.7-2.3.7h-2.2011v5.8819h-2.62V446.077h5.2412a3.676,3.676,0,0,1,.7.06,1.9733,1.9733,0,0,1,1.38,1,5.1461,5.1461,0,0,1,.3008,2.1211v1.86C1500.4824,452.4388,1500.4219,453.0785,1499.9814,453.6185Zm-2.0595-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.961-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.2793a1.8257,1.8257,0,0,0,.1807-.98Z" />
                                        <path className="st18" d="M1505.041,455.599V457.28a2.0128,2.0128,0,0,0,.06.6806.8122.8122,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9914,4.9914,0,0,0,.0791-1.16h2.3008v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.44-1.6807,1.8409-3.041,1.8409-2.1006,0-2.8008-1.0411-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.823,3.823,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8222,2.8222,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1516.2607,452.139a1.6846,1.6846,0,0,0-.7793-.16,1.609,1.609,0,0,0-.9.2392,2.1319,2.1319,0,0,0-.6407,1.94v6.042h-2.36V449.9584h2.3808l-.0205,1.3a4.1759,4.1759,0,0,1,.36-.6,2.218,2.218,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1521.5205,448.0179c-.44.1-.44.58-.44.68v1.2608h1.0605v1.58h-1.08v8.6621h-2.36v-8.6621H1517.64v-1.58H1518.7a10.625,10.625,0,0,1,.06-2.28,1.7208,1.7208,0,0,1,.6006-1.1411,2.8487,2.8487,0,0,1,1.98-.5c.3,0,.6.02.88.04v1.9605A2.1086,2.1086,0,0,0,1521.5205,448.0179Z" />
                                        <path className="st18" d="M1529.8809,459.36a3.4106,3.4106,0,0,1-2.7207,1.0411,4.8327,4.8327,0,0,1-1.02-.1006,2.4961,2.4961,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3008,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.02,2.92,1.5a2.9516,2.9516,0,0,1,.24,1.36V457.56A2.4485,2.4485,0,0,1,1529.8809,459.36Zm-1.78-6.8808c0-.6406-.06-.92-.38-1.12a.7277.7277,0,0,0-.44-.12.7513.7513,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12V457.7c0,.1806-.02,1.0605.82,1.0605.8995,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1537.6611,452.139a1.6867,1.6867,0,0,0-.78-.16,1.6126,1.6126,0,0,0-.9.2392,2.1313,2.1313,0,0,0-.64,1.94v6.042h-2.36V449.9584h2.38l-.02,1.3a4.141,4.141,0,0,1,.3594-.6,2.2182,2.2182,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1548.0615,460.2006v-7.5821a1.1172,1.1172,0,0,0-.36-1.04.9342.9342,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.462h-2.36v-7.5215c0-.4209.04-.8008-.32-1.1006a.79.79,0,0,0-.5605-.18c-.9795,0-.92,1.04-.92,1.22v7.5821h-2.36V449.9584h2.3809v.68a2.9152,2.9152,0,0,1,2.0205-.9,2.4411,2.4411,0,0,1,1.92,1.02,3.141,3.141,0,0,1,1.6807-.94,3.2563,3.2563,0,0,1,.66-.06,1.9264,1.9264,0,0,1,1.96,1.4,2.6947,2.6947,0,0,1,.1006.92v8.1221Z" />
                                        <path className="st18" d="M1557.2012,460.2006c-.2-.2207-.24-.4-.28-.9-.12.12-.2207.26-.36.38a2.2543,2.2543,0,0,1-1.7.74,2.0892,2.0892,0,0,1-1.24-.38c-.8408-.62-.78-1.8408-.78-2.74a2.9782,2.9782,0,0,1,.48-2.1006,3.1984,3.1984,0,0,1,1.0811-.7608l2.4805-1.24v-.6c0-.6006-.0606-.9-.32-1.08a.951.951,0,0,0-.5205-.1406.7768.7768,0,0,0-.74.4,2.9492,2.9492,0,0,0-.08.96v.38h-2.18v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.7607c.3,0,2.081-.02,2.8008,1.28a1.8767,1.8767,0,0,1,.24.9805V457.7a5.0781,5.0781,0,0,0,.3,2.32c.0205.06.0606.1006.16.1807Zm-.3408-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2839,1.2839,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M1566.1016,460.2006v-7.542c0-.64-.04-.9-.36-1.1a.7459.7459,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819H1561.88V449.9584h2.4209l-.0205.74a2.6346,2.6346,0,0,1,2.041-.94,1.9961,1.9961,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1576.9814,459.36a3.3223,3.3223,0,0,1-2.6611,1.0411,3.4408,3.4408,0,0,1-2.7207-.961,3.5959,3.5959,0,0,1-.42-.72,3.539,3.539,0,0,1-.2-1.3008v-4.541a2.9741,2.9741,0,0,1,.5605-1.96,3.5853,3.5853,0,0,1,2.78-1.16c.24,0,2.3809,0,3.041,1.54a3.2825,3.2825,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5349,2.5349,0,0,0-.04-.5.8345.8345,0,0,0-.86-.6406,1.0689,1.0689,0,0,0-.56.14c-.32.2207-.42.541-.42,1.2412V457.3a2.1382,2.1382,0,0,0,.06.6992.861.861,0,0,0,.88.6406,1.1788,1.1788,0,0,0,.5205-.14,1.3564,1.3564,0,0,0,.4-1.1807V456.139h2.36V457.3A3.18,3.18,0,0,1,1576.9814,459.36Z" />
                                        <path className="st18" d="M1582.16,455.599V457.28a1.9842,1.9842,0,0,0,.0605.6806.8113.8113,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.44-1.6807,1.8409-3.0411,1.8409-2.1005,0-2.8-1.0411-3.08-1.961a6.56,6.56,0,0,1-.1807-1.34v-4.3213a3.8081,3.8081,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1500.9414,482.2006l-.3408-2.3008h-2.4l-.38,2.3008H1495.22l2.74-14.1231h2.9814l2.6406,14.1231Zm-1.48-10.543-.94,6.0615h1.8008Z" />
                                        <path className="st18" d="M1510.9209,481.48a3.07,3.07,0,0,1-2.62.9209c-1.1406,0-2.4-.2207-2.9-1.4209a3.5658,3.5658,0,0,1-.2207-1.32V478.6h2.2012v.8a2.4442,2.4442,0,0,0,.0595.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2.001-1.42a2.5265,2.5265,0,0,1-1.22-2.4609c.0195-2.04,1.26-2.62,3.1006-2.62,1.5,0,2.32.5205,2.6406,1.1006a2.2976,2.2976,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.78.78,0,0,0-.82.92c0,.6006.2207.8008.6806,1.1211l2.2,1.54a3.6092,3.6092,0,0,1,.62.62,2.721,2.721,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1510.9209,481.48Z" />
                                        <path className="st18" d="M1519.3008,481.48a3.07,3.07,0,0,1-2.62.9209c-1.1407,0-2.4-.2207-2.9-1.4209a3.5658,3.5658,0,0,1-.2207-1.32V478.6h2.2011v.8a2.4432,2.4432,0,0,0,.06.64.7977.7977,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2.001-1.42a2.5265,2.5265,0,0,1-1.22-2.4609c.02-2.04,1.26-2.62,3.1006-2.62,1.5,0,2.32.5205,2.6406,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.78.78,0,0,0-.82.92c0,.6006.2207.8008.6806,1.1211l2.2,1.54a3.6088,3.6088,0,0,1,.62.62,2.72,2.72,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1519.3008,481.48Z" />
                                        <path className="st18" d="M1524.54,477.599V479.28a1.981,1.981,0,0,0,.0606.6806.8122.8122,0,0,0,.82.58.7524.7524,0,0,0,.76-.56,4.9982,4.9982,0,0,0,.08-1.16h2.3008v.82a2.8138,2.8138,0,0,1-.1006.92c-.3994,1.44-1.68,1.8409-3.04,1.8409-2.1006,0-2.8008-1.0411-3.0811-1.961a6.6156,6.6156,0,0,1-.18-1.34v-4.3213a3.8006,3.8006,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8325,2.8325,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8721.8721,0,0,0-.8809,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1536.4805,481.48a3.07,3.07,0,0,1-2.62.9209c-1.1407,0-2.4-.2207-2.9-1.4209a3.5658,3.5658,0,0,1-.2207-1.32V478.6h2.2011v.8a2.4432,2.4432,0,0,0,.06.64.7976.7976,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2.001-1.42a2.5263,2.5263,0,0,1-1.22-2.4609c.02-2.04,1.26-2.62,3.1006-2.62,1.5,0,2.32.5205,2.6406,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.78.78,0,0,0-.82.92c0,.6006.2208.8008.6807,1.1211l2.2,1.54a3.6088,3.6088,0,0,1,.62.62,2.72,2.72,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1536.4805,481.48Z" />
                                        <path className="st18" d="M1544.8613,481.48a3.0718,3.0718,0,0,1-2.6211.9209c-1.14,0-2.4-.2207-2.9-1.4209a3.5806,3.5806,0,0,1-.22-1.32V478.6h2.2v.8a2.4291,2.4291,0,0,0,.0606.64.7964.7964,0,0,0,.8593.66.9653.9653,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2486,1.2486,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.02-2.04,1.2607-2.62,3.1006-2.62,1.5009,0,2.32.5205,2.6406,1.1006a2.2984,2.2984,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.22.8008.68,1.1211l2.2012,1.54a3.6446,3.6446,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3036,3.3036,0,0,1,1544.8613,481.48Z" />
                                        <path className="st18" d="M1556.2021,482.2006v-7.5821a1.1172,1.1172,0,0,0-.36-1.04.9372.9372,0,0,0-.5-.14c-.8008,0-.9609.66-.9609,1.3v7.462h-2.36v-7.5215c0-.4209.04-.8008-.3193-1.1006a.792.792,0,0,0-.5606-.18c-.98,0-.92,1.04-.92,1.22v7.5821h-2.36V471.9584h2.38v.68a2.9178,2.9178,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.136,3.136,0,0,1,1.68-.94,3.2649,3.2649,0,0,1,.6612-.06,1.9263,1.9263,0,0,1,1.96,1.4,2.7,2.7,0,0,1,.1.92v8.1221Z" />
                                        <path className="st18" d="M1563.4805,477.599V479.28a1.9842,1.9842,0,0,0,.0605.6806.8113.8113,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.44-1.6807,1.8409-3.041,1.8409-2.1006,0-2.8-1.0411-3.08-1.961a6.56,6.56,0,0,1-.1807-1.34v-4.3213a3.8066,3.8066,0,0,1,.2608-1.38,3.587,3.587,0,0,1,5.9013-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1574.2412,482.2006v-7.542c0-.64-.04-.9-.36-1.1a.7459.7459,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3819H1570.02V471.9584h2.4209l-.02.74a2.6346,2.6346,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1582.4,482.3c-1.3809,0-2.1006-.3-2.4-.96a2.6205,2.6205,0,0,1-.2-1.1807v-6.621h-1.3008v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.14.4805.6006.5606,1.0205.5606a2.7563,2.7563,0,0,0,.3994-.04v1.72A5.0169,5.0169,0,0,1,1582.4,482.3Z" />
                                    </g>
                                    <g>
                                        <g>
                                            <path className="st18" d="M1519.082,660.08c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8737,6.8737,0,0,1-1.34-.14,2.9458,2.9458,0,0,1-2.3809-1.6806,3.7868,3.7868,0,0,1-.2-1.4805v-7.6817c0-.24.0206-.5.04-.74a2.6943,2.6943,0,0,1,1-2.1807,4.3007,4.3007,0,0,1,2.9209-.8193,4.7069,4.7069,0,0,1,2.6406.64,2.4615,2.4615,0,0,1,.88,1.04,3.1106,3.1106,0,0,1,.24,1.6006v2.16h-2.5605v-1.78c0-1.08-.18-1.66-1.34-1.66-1.0009,0-1.2.62-1.2,1.54v7.5616c0,.9209.1993,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.74v-1.8408h2.5605V658.4A5.2219,5.2219,0,0,1,1519.082,660.08Z" />
                                            <path className="st18" d="M1527.3018,660.86a3.4106,3.4106,0,0,1-2.7207,1.0411,4.8327,4.8327,0,0,1-1.02-.1006,2.4961,2.4961,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3008,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.02,2.92,1.5a2.9516,2.9516,0,0,1,.24,1.36V659.06A2.4485,2.4485,0,0,1,1527.3018,660.86Zm-1.78-6.8808c0-.6406-.06-.92-.38-1.12a.7279.7279,0,0,0-.44-.12.7513.7513,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12V659.2c0,.1806-.0205,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                            <path className="st18" d="M1534.6211,661.7006v-7.542c0-.64-.04-.9-.3594-1.1a.7471.7471,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819H1530.4V651.4584h2.4209l-.0205.74a2.6331,2.6331,0,0,1,2.041-.94,1.9951,1.9951,0,0,1,2.06,1.44,3.2407,3.2407,0,0,1,.08,1v8.002Z" />
                                            <path className="st18" d="M1542.7812,661.8c-1.3808,0-2.1005-.3-2.4013-.96a2.6336,2.6336,0,0,1-.1992-1.1807v-6.621H1538.88v-1.58h1.28v-3.7207h2.36v3.7207h1.4209v1.58h-1.4209v5.8408a1.3269,1.3269,0,0,0,.0606.5605c.14.4805.6.5606,1.0195.5606a2.7587,2.7587,0,0,0,.4-.04v1.72A5.0179,5.0179,0,0,1,1542.7812,661.8Z" />
                                            <path className="st18" d="M1550.6006,653.639a1.6852,1.6852,0,0,0-.7793-.16,1.61,1.61,0,0,0-.9.2392,2.1319,2.1319,0,0,0-.6406,1.94v6.042h-2.36V651.4584h2.3809l-.0205,1.3a4.1627,4.1627,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.9805-.8809Z" />
                                            <path className="st18" d="M1558.3408,660.86a3.41,3.41,0,0,1-2.7207,1.0411,4.8315,4.8315,0,0,1-1.02-.1006,2.4975,2.4975,0,0,1-1.9-1.62,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1931,3.1931,0,0,1,.1992-1.3008,2.9844,2.9844,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9666,2.9666,0,0,1,.2393,1.36V659.06A2.4482,2.4482,0,0,1,1558.3408,660.86Zm-1.78-6.8808c0-.6406-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7538.7538,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12V659.2c0,.1806-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                            <path className="st18" d="M1561.44,661.7006V647.5775h2.4v14.1231Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M1498.44,687.4213a10.4662,10.4662,0,0,1-1.56-2.28,13.6878,13.6878,0,0,1-1.9209-6.7012c0-3.3408,1.4-6.1621,3.3213-8.8027l.8593.7607a12.8147,12.8147,0,0,0-2.38,7.8614,14.4473,14.4473,0,0,0,1.84,7.5019c.2.32.4209.62.6406.94Z" />
                                            <path className="st18" d="M1506.08,683.601a3.8637,3.8637,0,0,1-2.2.6,3.1692,3.1692,0,0,1-3.1807-1.9405,4.7031,4.7031,0,0,1-.2207-1.58V679.12h1.6611v.7a5.5965,5.5965,0,0,0,.1592,1.86,1.5776,1.5776,0,0,0,1.5811,1.02,1.71,1.71,0,0,0,1.62-.9209,1.9751,1.9751,0,0,0,.16-.84,3.2027,3.2027,0,0,0-1.32-2.5l-1.9-1.9609a4.4068,4.4068,0,0,1-1.62-3.541,5.1255,5.1255,0,0,1,.1592-1.34c.28-.92,1.0606-1.94,3.1211-1.94a2.7112,2.7112,0,0,1,2.86,1.8408,7.4519,7.4519,0,0,1,.12,1.64v.9H1505.52c-.02-.88-.02-.66-.02-1.16a1.5349,1.5349,0,0,0-.76-1.58,1.8086,1.8086,0,0,0-.74-.1406,1.42,1.42,0,0,0-1.0605.38,1.4511,1.4511,0,0,0-.28.3808,2.4115,2.4115,0,0,0-.14.9395,2.5323,2.5323,0,0,0,.12.82,5.2937,5.2937,0,0,0,1.28,1.7l2.22,2.3613c.16.18.3008.3594.44.54a3.8633,3.8633,0,0,1,.78,2.52A3.0108,3.0108,0,0,1,1506.08,683.601Z" />
                                            <path className="st18" d="M1514.16,683.2806a2.5626,2.5626,0,0,1-2.2012.9,2.5067,2.5067,0,0,1-2.5-1.3408c-.38-.74-.32-1.34-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.6051,16.6051,0,0,1,.08-1.72,2.5889,2.5889,0,0,1,.86-1.98,2.7741,2.7741,0,0,1,1.8408-.5605,2.8488,2.8488,0,0,1,1.92.54,2.0147,2.0147,0,0,1,.6006.7,6.4128,6.4128,0,0,1,.34,2.7412v1.48h-4.0214c-.02.58-.02,1.1807,0,1.7607a4.26,4.26,0,0,0,.1005.9405,1.1181,1.1181,0,0,0,1.2.88,1.0409,1.0409,0,0,0,.74-.22c.4-.3.5-.8008.4795-1.7607h1.5009A3.2727,3.2727,0,0,1,1514.16,683.2806Zm-.9209-5.6816c0-.38,0-.78-.02-1.16-.04-.78-.36-1.38-1.24-1.38a1.0621,1.0621,0,0,0-1.0606.64,4.6726,4.6726,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M1516.8389,684V669.8773h1.62V684Z" />
                                            <path className="st18" d="M1522.6992,675.1986V684h-1.581v-8.8018h-1.18v-1.2h1.18v-1.08a4.1162,4.1162,0,0,1,.4-2.24,2.1156,2.1156,0,0,1,1.96-.86,2.06,2.06,0,0,1,.8408.12v1.38a1.8955,1.8955,0,0,0-.6406-.12.8325.8325,0,0,0-.62.2c-.38.32-.3594.74-.3594,1.6006v1h1.62v1.2Z" />
                                            <path className="st18" d="M1525.4385,678.94v-1.5605h4.32V678.94Z" />
                                            <path className="st18" d="M1535.1992,684.04a2.6152,2.6152,0,0,1-.9.1406,2.71,2.71,0,0,1-1.7-.5009,3.0574,3.0574,0,0,1-.5205-2.2c0-.24.0205-.5.0205-.74v-5.541h-1.2v-1.2h1.24v-2.6807h1.6v2.6807h1.4805v1.2h-1.4805v6.4619a1.3953,1.3953,0,0,0,.1806.84c.12.18.28.32.74.32a1.7319,1.7319,0,0,0,.56-.08Z" />
                                            <path className="st18" d="M1541.6592,683.2806a2.5625,2.5625,0,0,1-2.2012.9,2.5067,2.5067,0,0,1-2.5-1.3408c-.38-.74-.32-1.34-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.6328,16.6328,0,0,1,.08-1.72,2.5893,2.5893,0,0,1,.86-1.98,2.7741,2.7741,0,0,1,1.8408-.5605,2.8486,2.8486,0,0,1,1.92.54,2.0137,2.0137,0,0,1,.6006.7,6.4129,6.4129,0,0,1,.34,2.7412v1.48h-4.0215c-.02.58-.02,1.1807,0,1.7607a4.2522,4.2522,0,0,0,.1006.9405,1.118,1.118,0,0,0,1.2.88,1.0408,1.0408,0,0,0,.74-.22c.4-.3.5-.8008.4795-1.7607h1.501A3.2733,3.2733,0,0,1,1541.6592,683.2806Zm-.9209-5.6816c0-.38,0-.78-.0195-1.16-.04-.78-.36-1.38-1.24-1.38a1.062,1.062,0,0,0-1.0605.64,4.67,4.67,0,0,0-.2,1.9v.42h2.5205Z" />
                                            <path className="st18" d="M1547.6777,684.0209a3.9932,3.9932,0,0,1-1.18.16,2.415,2.415,0,0,1-2.1806-.9209c-.44-.6-.48-1.32-.5-2.7207h1.54c0,.3408.02.66.04,1.001a1.61,1.61,0,0,0,.1406.76,1.2136,1.2136,0,0,0,1.06.58,1.1228,1.1228,0,0,0,1.16-1.2c0-.88-.4394-1.24-1.04-1.7608l-1.24-1.08a3.0079,3.0079,0,0,1-.86-4.2207,2.1456,2.1456,0,0,1,.86-.6,3.4055,3.4055,0,0,1,1.1807-.2,2.8345,2.8345,0,0,1,1.88.62,1.9586,1.9586,0,0,1,.4.68,4.7156,4.7156,0,0,1,.2,1.82h-1.4209a4.1912,4.1912,0,0,0-.0791-1.12.9338.9338,0,0,0-.98-.72.9884.9884,0,0,0-1.0605,1.1,1.0317,1.0317,0,0,0,.12.52,4.01,4.01,0,0,0,1.12,1.2l1.1406.94c.2.18.3994.34.58.52a2.8557,2.8557,0,0,1,.82,2.0605A2.4892,2.4892,0,0,1,1547.6777,684.0209Z" />
                                            <path className="st18" d="M1554.6787,684.04a2.6182,2.6182,0,0,1-.9.1406,2.7114,2.7114,0,0,1-1.7012-.5009,3.0616,3.0616,0,0,1-.5195-2.2c0-.24.0195-.5.0195-.74v-5.541h-1.2v-1.2h1.24v-2.6807h1.6006v2.6807h1.48v1.2h-1.48v6.4619a1.401,1.401,0,0,0,.18.84c.12.18.28.32.74.32a1.7363,1.7363,0,0,0,.5605-.08Z" />
                                            <path className="st18" d="M1556.2373,672.2377v-2.06h1.5811v2.06Zm0,11.7627v-10.002h1.5811V684Z" />
                                            <path className="st18" d="M1564.2178,684v-7.3213c0-.24.08-1.6006-1.18-1.6006a1.2358,1.2358,0,0,0-1.2012.8408,4.3519,4.3519,0,0,0-.14,1.62V684h-1.6006v-10.002h1.52v.92c.12-.14.22-.2793.34-.3994a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.193,4.193,0,0,1,.34,2.28V684Z" />
                                            <path className="st18" d="M1572.6982,686.9008a3.8142,3.8142,0,0,1-1.94.4609,2.878,2.878,0,0,1-1.94-.6406,1.9831,1.9831,0,0,1-.58-.76,2.8363,2.8363,0,0,1-.2-1.16h1.48a1.9054,1.9054,0,0,0,.12.7,1.32,1.32,0,0,0,1.2.58,1.6581,1.6581,0,0,0,.88-.2207c.6006-.3994.541-1.16.541-1.74v-1.16l-.1807.24a2.3039,2.3039,0,0,1-1.84.86,2.14,2.14,0,0,1-1.78-.8808,4.91,4.91,0,0,1-.501-2.72c0-.66-.02-1.32-.02-1.98,0-.36,0-.7207.02-1.0811a5.2729,5.2729,0,0,1,.501-2.58,2.1866,2.1866,0,0,1,1.98-1,2.0889,2.0889,0,0,1,1.9394,1.06v-.88h1.4805V683.5c0,.36.02.7207,0,1.08A2.3474,2.3474,0,0,1,1572.6982,686.9008Zm-.3994-7.7813c0-.64.02-1.28-.04-1.94a4.9,4.9,0,0,0-.1406-1.32,1.2554,1.2554,0,0,0-1.92-.7c-.58.44-.62,1.4209-.62,2.86V679.58c0,.62,0,1.24.04,1.8808a1.4713,1.4713,0,0,0,.48,1.14,1.3282,1.3282,0,0,0,1.98-.56,6.6767,6.6767,0,0,0,.2207-2.36Z" />
                                            <path className="st18" d="M1577.7773,685.141a10.9041,10.9041,0,0,1-1.54,2.28l-.82-.72a13.6558,13.6558,0,0,0,2.5-8.4824,12.6244,12.6244,0,0,0-2.4-7.8213l.86-.7607a14.8374,14.8374,0,0,1,3.3408,8.8027A13.435,13.435,0,0,1,1577.7773,685.141Z" />
                                        </g>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1514.6611,807.7181c-.42.52-1.02.7-2.3.7H1510.16V814.3h-2.62V800.1771h5.2412a3.705,3.705,0,0,1,.7.06,1.9747,1.9747,0,0,1,1.38,1,5.1461,5.1461,0,0,1,.3008,2.1211v1.86C1515.1621,806.5385,1515.1016,807.1781,1514.6611,807.7181Zm-2.06-4.0205a1.7076,1.7076,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.961-.3h-1.28v4.1406h1.2607c.54,0,.84-.0595,1-.2793a1.8257,1.8257,0,0,0,.1807-.98Z" />
                                        <path className="st18" d="M1519.7207,809.6986v1.6807a2.0128,2.0128,0,0,0,.06.6806.8122.8122,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9914,4.9914,0,0,0,.0791-1.16h2.3008v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6807,1.8409-3.041,1.8409-2.1006,0-2.8008-1.041-3.08-1.961a6.5668,6.5668,0,0,1-.1807-1.34v-4.3213a3.8241,3.8241,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8234,2.8234,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1530.94,806.2386a1.6852,1.6852,0,0,0-.7793-.16,1.61,1.61,0,0,0-.9.2392,2.1321,2.1321,0,0,0-.6406,1.9405V814.3h-2.36V804.058h2.3808l-.02,1.3a4.1759,4.1759,0,0,1,.36-.6,2.218,2.218,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1538.3809,813.58a3.0721,3.0721,0,0,1-2.6211.9209c-1.14,0-2.4-.2207-2.9-1.4209a3.5789,3.5789,0,0,1-.22-1.32V810.7h2.2v.8a2.4329,2.4329,0,0,0,.0606.64.7964.7964,0,0,0,.8594.66.9652.9652,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2485,1.2485,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.0205-2.04,1.2607-2.62,3.1006-2.62,1.501,0,2.32.52,2.6406,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14H1536.74v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.22.8008.68,1.1211l2.2012,1.54a3.6446,3.6446,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1538.3809,813.58Z" />
                                        <path className="st18" d="M1547.0605,813.4594a3.4106,3.4106,0,0,1-2.7207,1.041,4.8324,4.8324,0,0,1-1.0195-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3008,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.02,2.92,1.5a2.9532,2.9532,0,0,1,.24,1.36V811.66A2.4486,2.4486,0,0,1,1547.0605,813.4594Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7514.7514,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.1807-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1554.3809,814.3v-7.542c0-.64-.04-.9-.36-1.1a.7437.7437,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V814.3h-2.38V804.058h2.42l-.02.74a2.6329,2.6329,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V814.3Z" />
                                        <path className="st18" d="M1563.5,814.3c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0926,2.0926,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9787,2.9787,0,0,1,.48-2.1006,3.2026,3.2026,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.3193-1.08a.9546.9546,0,0,0-.5206-.1406.7761.7761,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.1806v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6494,3.6494,0,0,1,2.5811-.7607c.3,0,2.08-.02,2.8008,1.28a1.8764,1.8764,0,0,1,.24.98v5.7012a5.066,5.066,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.1807.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2859,1.2859,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M1568.18,814.3V800.1771h2.4V814.3Z" />
                                        <path className="st18" d="M1495.5723,836.3h-2.74l-1.36-9.6426-1.5205,9.6426h-2.78l-2.5811-14.1231h2.7l1.4209,10.6416,1.52-10.6416h2.46l1.4805,10.6416,1.48-10.6416h2.6407Z" />
                                        <path className="st18" d="M1505.9717,835.4594a3.4117,3.4117,0,0,1-2.7207,1.041,4.8349,4.8349,0,0,1-1.0205-.1006,2.4973,2.4973,0,0,1-1.9-1.62,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9822,2.9822,0,0,1,3.04-1.66c.32,0,2.28-.02,2.9209,1.5a2.9517,2.9517,0,0,1,.24,1.36V833.66A2.4528,2.4528,0,0,1,1505.9717,835.4594Zm-1.78-6.8809c0-.6406-.06-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7551.7551,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1513.751,828.2386a1.6868,1.6868,0,0,0-.78-.16,1.6129,1.6129,0,0,0-.9.2392,2.1315,2.1315,0,0,0-.64,1.9405V836.3h-2.36V826.058h2.38l-.02,1.3a4.1281,4.1281,0,0,1,.3593-.6,2.2183,2.2183,0,0,1,1.9805-.8809Z" />
                                        <path className="st18" d="M1520.3506,836.3l-2.2-5.2217V836.3h-2.36V822.1771h2.36v7.3819l1.96-3.501h2.6406l-2.62,4.2607,2.9,5.9815Z" />
                                        <path className="st18" d="M1539.3311,836.3l.24-12.2432-2.28,12.2432H1534.87l-2.34-12.2432L1532.73,836.3h-2.5606V822.1771h4.2413l1.66,9.8819,1.6807-9.8819h4.1806V836.3Z" />
                                        <path className="st18" d="M1546.9492,831.6986v1.6807a1.981,1.981,0,0,0,.0606.6806.8122.8122,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9934,4.9934,0,0,0,.08-1.16h2.3008v.82a2.8138,2.8138,0,0,1-.1006.92c-.3994,1.4405-1.68,1.8409-3.04,1.8409-2.1006,0-2.8008-1.041-3.0811-1.961a6.6179,6.6179,0,0,1-.18-1.34v-4.3213a3.8016,3.8016,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8327,2.8327,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8721.8721,0,0,0-.8809,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1556.6494,836.4c-1.38,0-2.1006-.3-2.4-.96a2.634,2.634,0,0,1-.2-1.1806v-6.6211h-1.3v-1.58h1.28v-3.7207h2.36v3.7207h1.42v1.58h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.5605c.1406.4805.6006.5606,1.0205.5606a2.7559,2.7559,0,0,0,.4-.04v1.72A5.0269,5.0269,0,0,1,1556.6494,836.4Z" />
                                        <path className="st18" d="M1563.99,836.3v-7.2823a2.061,2.061,0,0,0-.08-.8.8561.8561,0,0,0-.8-.46.8885.8885,0,0,0-.9.7,1.4262,1.4262,0,0,0-.04.42V836.3h-2.3808V822.1771h2.3408l.02,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0225,2.0225,0,0,1,2,1.4,2.92,2.92,0,0,1,.1406,1.02V836.3Z" />
                                        <path className="st18" d="M1574.71,835.4594a3.41,3.41,0,0,1-2.7207,1.041,4.8327,4.8327,0,0,1-1.02-.1006,2.496,2.496,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9835,2.9835,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9671,2.9671,0,0,1,.2392,1.36V833.66A2.4489,2.4489,0,0,1,1574.71,835.4594Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7282.7282,0,0,0-.44-.12.7537.7537,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1581.97,836.3l.02-1a2.2714,2.2714,0,0,1-2.0009,1.2,2.45,2.45,0,0,1-1.42-.4405c-.7607-.56-.86-1.2207-.86-2.8408V828.558a2.5392,2.5392,0,0,1,1.04-2.38,2.3516,2.3516,0,0,1,1.1807-.3408,2.9937,2.9937,0,0,1,2.0605,1.041v-4.7012h2.38V836.3Zm.02-7.4414c0-.461-.02-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.9278.9278,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6006.06.82.34,1a1.1142,1.1142,0,0,0,.58.16.9674.9674,0,0,0,.82-.4,1.3023,1.3023,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1592.43,835.58a3.071,3.071,0,0,1-2.62.9209c-1.1407,0-2.4-.2207-2.9014-1.4209a3.58,3.58,0,0,1-.22-1.32V832.7h2.2v.8a2.4339,2.4339,0,0,0,.0605.64.7978.7978,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2485,1.2485,0,0,0-.7-1.32l-2-1.42a2.5273,2.5273,0,0,1-1.2207-2.4609c.0205-2.04,1.2608-2.62,3.1016-2.62,1.5,0,2.32.52,2.64,1.1006a2.2976,2.2976,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.2207.8008.6806,1.1211l2.2,1.54a3.6089,3.6089,0,0,1,.62.62,2.72,2.72,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1592.43,835.58Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1063.1055,823.9193a2.3393,2.3393,0,0,1-1.24,2.1006,4.8589,4.8589,0,0,1-2.0009.28h-4.461V812.1771h4.3819a3.4359,3.4359,0,0,1,2.34.54,2.59,2.59,0,0,1,.8408,2.3408v1.26a5.9511,5.9511,0,0,1-.08,1,1.6717,1.6717,0,0,1-1.5605,1.3809,2.0684,2.0684,0,0,1,1.4.68,2.899,2.899,0,0,1,.3994,1.9v2.001C1063.125,823.4994,1063.1055,823.7191,1063.1055,823.9193Zm-2.7207-8.4219a2.28,2.28,0,0,0-.06-.62c-.1807-.66-.74-.68-1.28-.68h-1.041v3.5606h.94a5.053,5.053,0,0,0,.7-.04c.5-.1.74-.4.74-1.2607Zm.18,5.6817a1.9479,1.9479,0,0,0-.08-.78c-.1992-.58-.84-.6006-1.3594-.6006h-1.1211v4.461h1.1006a3.5435,3.5435,0,0,0,.66-.04c.5606-.08.8-.42.8-1.38Z" />
                                        <path className="st18" d="M1068.0039,821.6986v1.6807a1.981,1.981,0,0,0,.0606.6806.8122.8122,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3008v.82a2.8138,2.8138,0,0,1-.1006.92c-.3994,1.4405-1.68,1.8409-3.04,1.8409-2.1006,0-2.8008-1.041-3.0811-1.961a6.6179,6.6179,0,0,1-.18-1.34v-4.3213a3.8016,3.8016,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8338,2.8338,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.8193-1.02a.8721.8721,0,0,0-.8809,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1078.7441,826.3v-7.2823a2.061,2.061,0,0,0-.08-.8.8561.8561,0,0,0-.8-.46.8894.8894,0,0,0-.9.7,1.4262,1.4262,0,0,0-.04.42V826.3h-2.38V812.1771h2.34l.02,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0246,2.0246,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02V826.3Z" />
                                        <path className="st18" d="M1087.8838,826.3c-.2-.2207-.2393-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2544,2.2544,0,0,1-1.7.74,2.0891,2.0891,0,0,1-1.24-.38c-.8408-.62-.78-1.8408-.78-2.74a2.9782,2.9782,0,0,1,.4795-2.1006,3.1968,3.1968,0,0,1,1.0811-.7607l2.48-1.24v-.6c0-.6006-.0606-.9-.32-1.08a.951.951,0,0,0-.52-.1406.7768.7768,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.18v-1.16a2.0464,2.0464,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.7607c.3,0,2.08-.02,2.8008,1.28a1.8764,1.8764,0,0,1,.24.98v5.7012a5.066,5.066,0,0,0,.3,2.32c.0195.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2839,1.2839,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M1096.2842,826.3h-2.3213l-2.26-10.2422h2.38l1.0606,7.1015,1.2207-7.1015h2.38Z" />
                                        <path className="st18" d="M1100.5234,814.5775v-2.4h2.4v2.4Zm0,11.7227V816.058h2.4V826.3Z" />
                                        <path className="st18" d="M1111.2646,825.4594a3.4115,3.4115,0,0,1-2.7207,1.041,4.8357,4.8357,0,0,1-1.02-.1006,2.4973,2.4973,0,0,1-1.9-1.62,3.7279,3.7279,0,0,1-.18-1.38v-4.5811a3.1917,3.1917,0,0,1,.1992-1.3008,2.9843,2.9843,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9532,2.9532,0,0,1,.24,1.36V823.66A2.4528,2.4528,0,0,1,1111.2646,825.4594Zm-1.7812-6.8809c0-.6406-.06-.92-.38-1.12a.7255.7255,0,0,0-.4394-.12.7549.7549,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1118.5439,826.3v-.8408a3.24,3.24,0,0,1-2.1806,1.0205,2.2533,2.2533,0,0,1-1.2-.36,2.5137,2.5137,0,0,1-.8-2.34V816.058h2.4v7.3613a1.2731,1.2731,0,0,0,.22.9805.8769.8769,0,0,0,.6406.26,1.0229,1.0229,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16V816.058h2.4V826.3Z" />
                                        <path className="st18" d="M1128.2637,818.2386a1.6868,1.6868,0,0,0-.78-.16,1.6129,1.6129,0,0,0-.9.2392,2.1315,2.1315,0,0,0-.64,1.9405V826.3h-2.36V816.058h2.38l-.0195,1.3a4.1281,4.1281,0,0,1,.3593-.6,2.2183,2.2183,0,0,1,1.9805-.8809Z" />
                                        <path className="st18" d="M1137.1025,826.3h-1.98l2.6006-14.1231h1.96Z" />
                                        <path className="st18" d="M1147.2217,826.3V812.1771h6.8418v2.1406h-4.2412v3.32h4.0009v2.1406h-4.0009V824.12h4.4414V826.3Z" />
                                        <path className="st18" d="M1160.3027,826.3l-1.16-4.1416-1.16,4.1416h-2.4609l2.2207-5.5616-1.8008-4.6806h2.4609l.88,3.4209.92-3.4209h2.3008l-1.8408,4.6806,2.2012,5.5616Z" />
                                        <path className="st18" d="M1166.9619,821.6986v1.6807a1.981,1.981,0,0,0,.0606.6806.8112.8112,0,0,0,.8193.58.7525.7525,0,0,0,.7607-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6807,1.8409-3.041,1.8409-2.1006,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8084,3.8084,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1179.3828,825.4594a3.3224,3.3224,0,0,1-2.6611,1.041,3.4408,3.4408,0,0,1-2.7207-.961,3.5928,3.5928,0,0,1-.42-.72,3.5386,3.5386,0,0,1-.2-1.3008v-4.541a2.9736,2.9736,0,0,1,.56-1.96,3.5852,3.5852,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.2828,3.2828,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5328,2.5328,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.0686,1.0686,0,0,0-.56.14c-.32.2207-.42.541-.42,1.2412V823.4a2.1379,2.1379,0,0,0,.06.6992.8609.8609,0,0,0,.88.6406,1.1782,1.1782,0,0,0,.5205-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.1807h2.36V823.4A3.1808,3.1808,0,0,1,1179.3828,825.4594Z" />
                                        <path className="st18" d="M1186.4824,826.3v-.8408a3.2423,3.2423,0,0,1-2.1806,1.0205,2.2511,2.2511,0,0,1-1.2-.36,2.5141,2.5141,0,0,1-.8008-2.34V816.058h2.4v7.3613a1.2694,1.2694,0,0,0,.2207.9805.8747.8747,0,0,0,.64.26,1.0254,1.0254,0,0,0,.6006-.2c.3994-.34.32-1.1.32-1.16V816.058h2.4V826.3Z" />
                                        <path className="st18" d="M1194.6816,826.4c-1.3808,0-2.1-.3-2.4-.96a2.6216,2.6216,0,0,1-.2-1.1806v-6.6211H1190.78v-1.58h1.28v-3.7207h2.3614v3.7207h1.42v1.58h-1.42v5.8408a1.35,1.35,0,0,0,.0595.5605c.14.4805.6006.5606,1.0206.5606a2.7591,2.7591,0,0,0,.3994-.04v1.72A5.0179,5.0179,0,0,1,1194.6816,826.4Z" />
                                        <path className="st18" d="M1197.8213,814.5775v-2.4h2.4v2.4Zm0,11.7227V816.058h2.4V826.3Z" />
                                        <path className="st18" d="M1208.5615,825.4594a3.41,3.41,0,0,1-2.7207,1.041,4.8315,4.8315,0,0,1-1.0195-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9836,2.9836,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9669,2.9669,0,0,1,.2393,1.36V823.66A2.4486,2.4486,0,0,1,1208.5615,825.4594Zm-1.78-6.8809c0-.6406-.0595-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7539.7539,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1215.8828,826.3v-7.542c0-.64-.04-.9-.36-1.1a.746.746,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V826.3h-2.3809V816.058h2.4209l-.0205.74a2.6346,2.6346,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V826.3Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1227.832,656.7191c-.42.5195-1.0205.7-2.3008.7h-2.2V663.3H1220.71V649.1771h5.2412a3.6409,3.6409,0,0,1,.7.0606,1.9717,1.9717,0,0,1,1.3808,1,5.1548,5.1548,0,0,1,.3,2.12v1.86C1228.332,655.5385,1228.2725,656.1791,1227.832,656.7191Zm-2.0605-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.1182,1.1182,0,0,0-.96-.3h-1.28v4.1406h1.26c.541,0,.8408-.0605,1.001-.28a1.8345,1.8345,0,0,0,.18-.98Z" />
                                        <path className="st18" d="M1232.8906,658.7V660.38a1.9752,1.9752,0,0,0,.0606.68.8111.8111,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9874,4.9874,0,0,0,.08-1.16h2.3008v.82a2.82,2.82,0,0,1-.1006.9209c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.04-3.0811-1.96a6.6252,6.6252,0,0,1-.18-1.3408v-4.32a3.806,3.806,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8259,2.8259,0,0,1,.28,1.46V658.7Zm1.7-2.94c0-.2207.04-1.0206-.8193-1.0206a.8718.8718,0,0,0-.8809,1.0206v1.26h1.7Z" />
                                        <path className="st18" d="M1244.1113,655.2386a1.6867,1.6867,0,0,0-.78-.16,1.6072,1.6072,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94V663.3h-2.36V653.058h2.38l-.02,1.3008a4.0811,4.0811,0,0,1,.3594-.6006,2.2167,2.2167,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1251.5508,662.58a3.07,3.07,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9-1.42a3.5705,3.5705,0,0,1-.2207-1.32V659.7h2.2011v.8a2.4469,2.4469,0,0,0,.06.6406.7978.7978,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.248,1.248,0,0,0-.7-1.32l-2.001-1.4209a2.525,2.525,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1406h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.7786.7786,0,0,0-.82.92c0,.6.2207.8.6806,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.305,3.305,0,0,1,1251.5508,662.58Z" />
                                        <path className="st18" d="M1260.2314,662.46a3.4131,3.4131,0,0,1-2.7207,1.04,4.8546,4.8546,0,0,1-1.0205-.1,2.499,2.499,0,0,1-1.9-1.6211,3.7279,3.7279,0,0,1-.18-1.38v-4.5811a3.1615,3.1615,0,0,1,.2-1.3,2.9827,2.9827,0,0,1,3.04-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9476,2.9476,0,0,1,.24,1.36v4.94A2.455,2.455,0,0,1,1260.2314,662.46Zm-1.78-6.8818c0-.64-.0606-.92-.3809-1.12a.7255.7255,0,0,0-.4394-.12.7478.7478,0,0,0-.4805.1406c-.38.2793-.36.7-.36,1.12V660.8c0,.18-.0195,1.0595.82,1.0595.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1267.5508,663.3v-7.541c0-.6407-.04-.9-.3594-1.1006a.754.754,0,0,0-.4609-.12c-1.12,0-1.02,1.1806-1.02,1.3808V663.3H1263.33V653.058h2.4209l-.0205.74a2.6363,2.6363,0,0,1,2.041-.94,1.9952,1.9952,0,0,1,2.06,1.44,3.2407,3.2407,0,0,1,.08,1V663.3Z" />
                                        <path className="st18" d="M1276.6709,663.3c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3808a2.2525,2.2525,0,0,1-1.7.74,2.0939,2.0939,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9748,2.9748,0,0,1,.48-2.1006,3.2057,3.2057,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9576.9576,0,0,0-.52-.14.776.776,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38H1272.51v-1.16a2.0494,2.0494,0,0,1,.58-1.461,3.6482,3.6482,0,0,1,2.5811-.76c.3,0,2.08-.0206,2.8,1.28a1.8732,1.8732,0,0,1,.24.9805V660.8a5.0682,5.0682,0,0,0,.3008,2.32c.0195.06.06.1.1592.18Zm-.34-5.1612-1.16.7608c-.08.06-.1807.14-.2.16-.3008.2793-.3408.68-.3408,1.48,0,.7608.1006,1.2.58,1.1807a1.2788,1.2788,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1281.35,663.3V649.1771h2.4V663.3Z" />
                                        <path className="st18" d="M1302.1914,663.3h-2.74l-1.36-9.6416-1.52,9.6416h-2.78l-2.581-14.1231h2.7012l1.42,10.6426,1.5205-10.6426h2.46l1.48,10.6426,1.4805-10.6426h2.6406Z" />
                                        <path className="st18" d="M1312.59,662.46a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.0195-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1615,3.1615,0,0,1,.2-1.3,2.9841,2.9841,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9627,2.9627,0,0,1,.2392,1.36v4.94A2.4507,2.4507,0,0,1,1312.59,662.46Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7464.7464,0,0,0-.4795.1406c-.38.2793-.36.7-.36,1.12V660.8c0,.18-.02,1.0595.82,1.0595.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1320.3711,655.2386a1.6934,1.6934,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.6406,1.94V663.3h-2.36V653.058h2.3808l-.0205,1.3008a4.1148,4.1148,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1326.9707,663.3,1324.77,658.08V663.3h-2.36V649.1771h2.36v7.3819l1.961-3.501h2.6406l-2.6211,4.2607,2.9014,5.9815Z" />
                                        <path className="st18" d="M1252.502,685.3l.24-12.2422L1250.4609,685.3h-2.42L1245.7,673.058l.2,12.2422H1243.34V671.1771h4.2413l1.66,9.8828,1.6807-9.8828h4.1806V685.3Z" />
                                        <path className="st18" d="M1260.12,680.7V682.38a2.0069,2.0069,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.98,4.98,0,0,0,.0791-1.16h2.3008v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8277,3.8277,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8154,2.8154,0,0,1,.28,1.46V680.7Zm1.7-2.94c0-.2207.04-1.0206-.82-1.0206a.871.871,0,0,0-.88,1.0206v1.26h1.7Z" />
                                        <path className="st18" d="M1269.82,685.4008c-1.3808,0-2.1006-.3008-2.4014-.961a2.6286,2.6286,0,0,1-.1992-1.18V676.639h-1.3008v-1.581h1.28v-3.7207h2.36v3.7207h1.4209v1.581H1269.56V682.48a1.3292,1.3292,0,0,0,.0605.56c.14.48.6.56,1.0195.56a2.6358,2.6358,0,0,0,.4-.04v1.7207A5.0625,5.0625,0,0,1,1269.82,685.4008Z" />
                                        <path className="st18" d="M1277.16,685.3v-7.2813a2.0558,2.0558,0,0,0-.08-.8.8552.8552,0,0,0-.8-.4609.8894.8894,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209V685.3h-2.38V671.1771h2.34l.0205,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0258,2.0258,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02V685.3Z" />
                                        <path className="st18" d="M1287.8809,684.46a3.4133,3.4133,0,0,1-2.7207,1.04,4.855,4.855,0,0,1-1.0206-.1,2.4989,2.4989,0,0,1-1.9-1.6211,3.7253,3.7253,0,0,1-.18-1.38v-4.5811a3.1615,3.1615,0,0,1,.2-1.3,2.9828,2.9828,0,0,1,3.04-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.4549,2.4549,0,0,1,1287.8809,684.46Zm-1.78-6.8818c0-.64-.0606-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7478.7478,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12V682.8c0,.18-.02,1.0595.82,1.0595.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1295.1406,685.3l.02-1a2.2679,2.2679,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.4405c-.76-.5595-.86-1.22-.86-2.84V677.559a2.5413,2.5413,0,0,1,1.041-2.3809,2.3592,2.3592,0,0,1,1.18-.34,2.9931,2.9931,0,0,1,2.0606,1.04v-4.7012h2.3808V685.3Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2013,1.2013,0,0,0-.58-.16.9355.9355,0,0,0-.5195.16c-.4.28-.38.7-.38,1.1406V682.34c0,.6.06.8193.34,1a1.1239,1.1239,0,0,0,.58.16.9725.9725,0,0,0,.82-.4,1.3131,1.3131,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1305.6006,684.58a3.0708,3.0708,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9014-1.42a3.5854,3.5854,0,0,1-.22-1.32V681.7h2.2v.8a2.438,2.438,0,0,0,.0605.6406.7978.7978,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2467,1.2467,0,0,0-.7-1.32l-2-1.4209a2.5257,2.5257,0,0,1-1.2207-2.46c.0205-2.041,1.2608-2.6211,3.1016-2.6211,1.5,0,2.32.52,2.64,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1406H1303.96v-.74c0-.16.04-1.12-.88-1.12a.7777.7777,0,0,0-.82.92c0,.6.2207.8.6806,1.12l2.2,1.54a3.6449,3.6449,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.305,3.305,0,0,1,1305.6006,684.58Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M424.2622,461.7006l-.34-2.3008h-2.4l-.38,2.3008H418.541l2.74-14.1231h2.981l2.6406,14.1231Zm-1.4805-10.543-.94,6.0615h1.8Z" />
                                        <path className="st18" d="M433.062,461.7006v-7.542c0-.64-.04-.9-.36-1.1a.7451.7451,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819h-2.38V451.4584h2.42l-.02.74a2.6329,2.6329,0,0,1,2.0405-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M442.2817,461.7006v-7.542c0-.64-.04-.9-.36-1.1a.7454.7454,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819h-2.38V451.4584h2.42l-.02.74a2.6329,2.6329,0,0,1,2.0406-.94,1.9955,1.9955,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M451.4614,461.7006V460.86a3.2378,3.2378,0,0,1-2.18,1.0205,2.254,2.254,0,0,1-1.2006-.36,2.5147,2.5147,0,0,1-.8-2.34v-7.7217h2.4V458.82a1.2712,1.2712,0,0,0,.22.9805.8758.8758,0,0,0,.64.26,1.0237,1.0237,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2412h2.4v10.2422Z" />
                                        <path className="st18" d="M460.6211,461.7006c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9759,2.9759,0,0,1,.48-2.1006,3.1964,3.1964,0,0,1,1.0806-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.95.95,0,0,0-.52-.1406.7761.7761,0,0,0-.74.4,2.9492,2.9492,0,0,0-.08.96v.38h-2.18v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.7607c.3,0,2.08-.02,2.8,1.28a1.8767,1.8767,0,0,1,.24.9805V459.2a5.0663,5.0663,0,0,0,.3,2.32c.0195.06.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2829,1.2829,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M465.3008,461.7006V447.5775h2.4v14.1231Z" />
                                        <path className="st18" d="M483.0415,455.1185c-.42.5205-1.02.7-2.3.7h-2.2007v5.8819H475.92V447.5775h5.2412a3.7028,3.7028,0,0,1,.7.06,1.9756,1.9756,0,0,1,1.38,1,5.1539,5.1539,0,0,1,.3,2.1211v1.86C483.542,453.9388,483.4819,454.5785,483.0415,455.1185Zm-2.06-4.02a1.7071,1.7071,0,0,0-.2-1.1406,1.1189,1.1189,0,0,0-.96-.3h-1.28v4.1406h1.26c.5405,0,.84-.06,1.0005-.2793a1.831,1.831,0,0,0,.18-.98Z" />
                                        <path className="st18" d="M485.84,461.7006V447.5775h2.4v14.1231Z" />
                                        <path className="st18" d="M495.0005,461.7006c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.1964,3.1964,0,0,1,1.0806-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.95.95,0,0,0-.52-.1406.7761.7761,0,0,0-.74.4,2.9492,2.9492,0,0,0-.08.96v.38h-2.18v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.7607c.3,0,2.0806-.02,2.8,1.28a1.8767,1.8767,0,0,1,.24.9805V459.2a5.0663,5.0663,0,0,0,.3,2.32c.02.06.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2829,1.2829,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M503.9014,461.7006v-7.542c0-.64-.04-.9-.36-1.1a.7458.7458,0,0,0-.46-.12c-1.12,0-1.0206,1.18-1.0206,1.38v7.3819H499.68V451.4584h2.4209l-.02.74a2.6346,2.6346,0,0,1,2.041-.94,1.9961,1.9961,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M513.1211,461.7006v-7.542c0-.64-.04-.9-.36-1.1a.7455.7455,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819h-2.3809V451.4584H511.32l-.02.74a2.6346,2.6346,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M518.1191,449.9779v-2.4h2.4v2.4Zm0,11.7227V451.4584h2.4v10.2422Z" />
                                        <path className="st18" d="M527.3809,461.7006v-7.542c0-.64-.04-.9-.36-1.1a.7459.7459,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3819h-2.3808V451.4584H525.58l-.02.74a2.6346,2.6346,0,0,1,2.041-.94,1.9961,1.9961,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M538.9209,462.72a2.5176,2.5176,0,0,1-1.04,2.02,4.3558,4.3558,0,0,1-2.4209.6006,5.9221,5.9221,0,0,1-.82-.0606,2.5857,2.5857,0,0,1-2.16-1.4795,3.8191,3.8191,0,0,1-.16-1.2607h2.36a1.5542,1.5542,0,0,0,.04.4805.8378.8378,0,0,0,.88.6.9818.9818,0,0,0,.54-.14c.4-.22.4209-.6806.4209-1.08V460.82a2.5193,2.5193,0,0,1-2.081,1.001,2.629,2.629,0,0,1-1.22-.3008c-.9405-.5-1.001-1.36-1.001-2.26v-5.4218a3.5166,3.5166,0,0,1,.1406-1.12,2.0446,2.0446,0,0,1,2.04-1.46,2.9124,2.9124,0,0,1,2.121,1.0205v-.82h2.4V462.06C538.9609,462.2806,538.94,462.5,538.9209,462.72Zm-2.3809-8.4209c0-.2.08-1.24-.96-1.24a.9206.9206,0,0,0-.58.18c-.2607.2-.38.44-.38,1.04v4.56a1.9906,1.9906,0,0,0,.0791.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M542.1191,464.4008h-.88l1.36-2.7h-1.1611V459.12h2.3408v2.5811Z" />
                                        <path className="st18" d="M459.7422,481.32a2.3384,2.3384,0,0,1-1.24,2.1006,4.8573,4.8573,0,0,1-2,.28h-4.461V469.5775h4.3814a3.4372,3.4372,0,0,1,2.34.54,2.5905,2.5905,0,0,1,.84,2.3408v1.26a5.9584,5.9584,0,0,1-.08,1,1.6711,1.6711,0,0,1-1.5606,1.3809,2.0669,2.0669,0,0,1,1.4.68,2.8964,2.8964,0,0,1,.4,1.9v2.001C459.7622,480.9,459.7422,481.12,459.7422,481.32Zm-2.72-8.4219a2.2713,2.2713,0,0,0-.06-.62c-.18-.66-.74-.68-1.28-.68h-1.0405v3.5606h.94a5.0589,5.0589,0,0,0,.7-.04c.5-.1.74-.4.74-1.2607Zm.18,5.6817a1.9592,1.9592,0,0,0-.08-.78c-.2-.58-.84-.6006-1.36-.6006h-1.1206V481.66h1.1006a3.5538,3.5538,0,0,0,.66-.04c.56-.08.8-.42.8-1.38Z" />
                                        <path className="st18" d="M466.5615,483.7006V482.86a3.2368,3.2368,0,0,1-2.1806,1.0205,2.2538,2.2538,0,0,1-1.2-.36,2.5147,2.5147,0,0,1-.8-2.34v-7.7217h2.4V480.82a1.2713,1.2713,0,0,0,.22.9805.8758.8758,0,0,0,.64.26,1.0237,1.0237,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2412h2.4v10.2422Z" />
                                        <path className="st18" d="M475.7612,483.7006l.02-1a2.27,2.27,0,0,1-2,1.2,2.45,2.45,0,0,1-1.42-.44c-.76-.56-.86-1.2207-.86-2.8408v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3511,2.3511,0,0,1,1.18-.3408,2.9923,2.9923,0,0,1,2.06,1.041v-4.7012h2.38v14.1231Zm.02-7.4414c0-.461-.02-.78-.44-1.0206a1.193,1.193,0,0,0-.58-.16.927.927,0,0,0-.52.16c-.4.28-.38.7-.38,1.14V480.74c0,.6006.06.82.34,1a1.1109,1.1109,0,0,0,.58.16.9678.9678,0,0,0,.82-.4,1.306,1.306,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M487.3613,484.72a2.5131,2.5131,0,0,1-1.04,2.02,4.3525,4.3525,0,0,1-2.42.6006,5.9147,5.9147,0,0,1-.82-.0606,2.5869,2.5869,0,0,1-2.1607-1.4795,3.8452,3.8452,0,0,1-.16-1.2607h2.36a1.5382,1.5382,0,0,0,.04.4805.8385.8385,0,0,0,.88.6.985.985,0,0,0,.54-.14c.4-.22.42-.6806.42-1.08V482.82a2.5184,2.5184,0,0,1-2.0806,1.001,2.6334,2.6334,0,0,1-1.22-.3008c-.94-.5-1-1.36-1-2.26v-5.4218a3.5163,3.5163,0,0,1,.14-1.12,2.0441,2.0441,0,0,1,2.04-1.46,2.91,2.91,0,0,1,2.1206,1.0205v-.82h2.4V484.06C487.4014,484.2806,487.3813,484.5,487.3613,484.72Zm-2.38-8.4209c0-.2.08-1.24-.96-1.24a.9206.9206,0,0,0-.58.18c-.26.2-.38.44-.38,1.04v4.56a1.9515,1.9515,0,0,0,.08.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M492.3,479.099V480.78a1.9968,1.9968,0,0,0,.06.6806.8122.8122,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9952,4.9952,0,0,0,.08-1.16h2.3008v.82a2.8272,2.8272,0,0,1-.1.92c-.4,1.44-1.68,1.8409-3.0405,1.8409-2.1006,0-2.8008-1.0411-3.0806-1.961a6.5922,6.5922,0,0,1-.18-1.34v-4.3213a3.81,3.81,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8286,2.8286,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M502.001,483.8c-1.3809,0-2.1006-.3-2.4-.96a2.6205,2.6205,0,0,1-.2-1.1807v-6.621h-1.3v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.14.4805.6006.5606,1.0205.5606a2.7577,2.7577,0,0,0,.3994-.04v1.72A5.0176,5.0176,0,0,1,502.001,483.8Z" />
                                        <path className="st18" d="M510.541,482.98a3.0709,3.0709,0,0,1-2.62.9209c-1.1406,0-2.4-.2207-2.9014-1.4209a3.5806,3.5806,0,0,1-.22-1.32V480.1H507v.8a2.4344,2.4344,0,0,0,.06.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.4-.98,1.2486,1.2486,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.02-2.04,1.2607-2.62,3.1015-2.62,1.5,0,2.32.5205,2.64,1.1006a2.2984,2.2984,0,0,1,.28,1.24v1.14H508.9v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.2207.8008.6807,1.1211l2.2,1.54a3.6124,3.6124,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3036,3.3036,0,0,1,510.541,482.98Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M142.0933,323.36a4.34,4.34,0,0,1-3.0611.9405,4.5156,4.5156,0,0,1-2.94-.82,2.289,2.289,0,0,1-.7-.88,3.8252,3.8252,0,0,1-.3-1.62v-7.9219a3.3981,3.3981,0,0,1,.88-2.5606,4.5854,4.5854,0,0,1,3.0805-.92c2.0406,0,3.1211.84,3.5411,1.66a3.9071,3.9071,0,0,1,.32,1.88v7.8618A3.0649,3.0649,0,0,1,142.0933,323.36Zm-1.8008-10.062a2.4823,2.4823,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0806-.62,1.436,1.436,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2373,2.2373,0,0,0,.24,1.3,1.3432,1.3432,0,0,0,1.06.42,1.5235,1.5235,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M151.9131,323.22a1.9244,1.9244,0,0,1-.44.54,2.0187,2.0187,0,0,1-1.32.4405,3.1347,3.1347,0,0,1-2.22-1.0606V327.54h-2.38V313.7577h2.38v.78a3.2641,3.2641,0,0,1,2.1407-.9805,2.1793,2.1793,0,0,1,1.7.82,3.0545,3.0545,0,0,1,.48,1.88V321.7A3.5191,3.5191,0,0,1,151.9131,323.22Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0935,1.0935,0,0,0-.52-.14,1.0375,1.0375,0,0,0-.54.14,1.0523,1.0523,0,0,0-.44,1.0405v4.6006a1.4256,1.4256,0,0,0,.08.5806.9343.9343,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6075,1.6075,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M157.0317,319.3988V321.08a1.9935,1.9935,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7529.7529,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0406-3.0806-1.9605a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8239,2.8239,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M168.252,315.9384a1.6845,1.6845,0,0,0-.78-.16,1.6082,1.6082,0,0,0-.9.24,2.1308,2.1308,0,0,0-.64,1.94V324h-2.36V313.7577h2.38l-.02,1.3a4.1333,4.1333,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M174.4116,324c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0911,2.0911,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9762,2.9762,0,0,1,.48-2.1006,3.2007,3.2007,0,0,1,1.0805-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.9605v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.646,3.646,0,0,1,2.5805-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M182.252,324.1c-1.38,0-2.1006-.3-2.4009-.9605a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V310.037h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7039,2.7039,0,0,0,.4-.04v1.72A5.0519,5.0519,0,0,1,182.252,324.1Z" />
                                        <path className="st18" d="M185.3911,312.2772v-2.4h2.4v2.4Zm0,11.7227V313.7577h2.4V324Z" />
                                        <path className="st18" d="M196.1318,323.16a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1761,3.1761,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.95,2.95,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,196.1318,323.16Zm-1.7807-6.8813c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7494.7494,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M203.4517,324v-7.5415c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V324h-2.38V313.7577h2.42l-.02.74a2.6348,2.6348,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V324Z" />
                                        <path className="st18" d="M212.5713,324c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9547.9547,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.0806-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2811,1.2811,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M217.2505,324V309.8768h2.4V324Z" />
                                        <path className="st18" d="M234.9917,317.4183c-.4209.52-1.02.7-2.3008.7h-2.2V324H227.87V309.8768h5.2412a3.6749,3.6749,0,0,1,.7.06,1.9768,1.9768,0,0,1,1.3809,1,5.1583,5.1583,0,0,1,.3,2.1206v1.86C235.4917,316.2382,235.4312,316.8783,234.9917,317.4183Zm-2.0605-4.02a1.7076,1.7076,0,0,0-.2-1.1406,1.1182,1.1182,0,0,0-.96-.3h-1.28v4.1406h1.26c.54,0,.8408-.06,1-.28a1.8257,1.8257,0,0,0,.1807-.98Z" />
                                        <path className="st18" d="M237.79,324V309.8768h2.4V324Z" />
                                        <path className="st18" d="M246.9507,324c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2569,2.2569,0,0,1-1.7011.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9592.9592,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38H242.79v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.8815,1.8815,0,0,1,.2392.9805v5.7012a5.0674,5.0674,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.284,1.284,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M255.8511,324v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V324h-2.3809V313.7577H254.05l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V324Z" />
                                        <path className="st18" d="M265.0708,324v-7.5415c0-.64-.04-.9-.36-1.1a.7491.7491,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V324h-2.3809V313.7577H263.27l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V324Z" />
                                        <path className="st18" d="M270.0688,312.2772v-2.4h2.4v2.4Zm0,11.7227V313.7577h2.4V324Z" />
                                        <path className="st18" d="M279.3306,324v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.0206,1.18-1.0206,1.38V324h-2.3808V313.7577H277.53l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V324Z" />
                                        <path className="st18" d="M290.8706,325.02a2.5182,2.5182,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0065,6.0065,0,0,1-.82-.06,2.5877,2.5877,0,0,1-2.16-1.48,3.8171,3.8171,0,0,1-.16-1.26h2.36a1.55,1.55,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9784.9784,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V323.12a2.52,2.52,0,0,1-2.0811,1,2.6366,2.6366,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.26v-5.4213a3.5152,3.5152,0,0,1,.1406-1.12,2.0445,2.0445,0,0,1,2.04-1.46,2.9146,2.9146,0,0,1,2.1211,1.02v-.82h2.4V324.36C290.9106,324.58,290.89,324.8,290.8706,325.02Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.0405v4.56a1.9906,1.9906,0,0,0,.0791.6006.8509.8509,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M434.94,312.36a4.34,4.34,0,0,1-3.0611.9405,4.5156,4.5156,0,0,1-2.94-.82,2.289,2.289,0,0,1-.7-.88,3.8236,3.8236,0,0,1-.3-1.62v-7.9219a3.3981,3.3981,0,0,1,.88-2.5606,4.5854,4.5854,0,0,1,3.08-.92c2.0406,0,3.1211.84,3.5411,1.66a3.9071,3.9071,0,0,1,.32,1.88v7.8618A3.0649,3.0649,0,0,1,434.94,312.36Zm-1.8008-10.062a2.4823,2.4823,0,0,0-.16-1.1406,1.1034,1.1034,0,0,0-1.0806-.62,1.436,1.436,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2373,2.2373,0,0,0,.24,1.3,1.3432,1.3432,0,0,0,1.06.42,1.5235,1.5235,0,0,0,.82-.24c.48-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M443.0786,304.9384a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.94V313h-2.36V302.7577h2.38l-.02,1.3a4.1333,4.1333,0,0,1,.36-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M451.6587,314.02a2.5137,2.5137,0,0,1-1.04,2.02,4.3536,4.3536,0,0,1-2.4205.6,6.0007,6.0007,0,0,1-.82-.06,2.5887,2.5887,0,0,1-2.1606-1.48,3.8405,3.8405,0,0,1-.16-1.26h2.36a1.5314,1.5314,0,0,0,.04.48.8383.8383,0,0,0,.88.6.9805.9805,0,0,0,.54-.14c.4-.22.42-.68.42-1.08V312.12a2.518,2.518,0,0,1-2.08,1,2.6411,2.6411,0,0,1-1.22-.3c-.94-.5-1-1.36-1-2.26v-5.4213a3.5149,3.5149,0,0,1,.14-1.12,2.044,2.044,0,0,1,2.04-1.46,2.9116,2.9116,0,0,1,2.1206,1.02v-.82h2.4V313.36C451.6987,313.58,451.6787,313.8,451.6587,314.02Zm-2.38-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.0405v4.56a1.9484,1.9484,0,0,0,.08.6006.8505.8505,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M458.458,313c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9766,2.9766,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2811,1.2811,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M467.3579,313v-7.5415c0-.64-.04-.9-.36-1.1a.7486.7486,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V313h-2.38V302.7577h2.42l-.02.74a2.6345,2.6345,0,0,1,2.0405-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V313Z" />
                                        <path className="st18" d="M472.3574,301.2772v-2.4h2.4v2.4Zm0,11.7227V302.7577h2.4V313Z" />
                                        <path className="st18" d="M476.9966,313v-2.0005l3.581-6.3213H477.377v-1.92h5.6411v1.92l-3.541,6.3213h3.7011V313Z" />
                                        <path className="st18" d="M489.4775,313c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2026,3.2026,0,0,1,1.0805-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.9605v.38h-2.18v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.646,3.646,0,0,1,2.5805-.76c.3,0,2.08-.02,2.8,1.28a1.8744,1.8744,0,0,1,.24.9805v5.7012a5.0658,5.0658,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M497.3174,313.1c-1.38,0-2.1006-.3-2.4009-.9605a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V299.037h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6892,2.6892,0,0,0,.4-.04v1.72A5.0436,5.0436,0,0,1,497.3174,313.1Z" />
                                        <path className="st18" d="M500.4561,301.2772v-2.4h2.4v2.4Zm0,11.7227V302.7577h2.4V313Z" />
                                        <path className="st18" d="M511.1973,312.16a3.41,3.41,0,0,1-2.7207,1.0406,4.8421,4.8421,0,0,1-1.02-.1,2.4969,2.4969,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9642,2.9642,0,0,1,.2392,1.36v4.941A2.45,2.45,0,0,1,511.1973,312.16Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M518.5176,313v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38V313h-2.3809V302.7577h2.4209l-.0205.74a2.6362,2.6362,0,0,1,2.041-.94,1.9964,1.9964,0,0,1,2.0606,1.44,3.2407,3.2407,0,0,1,.08,1V313Z" />
                                        <path className="st18" d="M527.6367,313c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0937,2.0937,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9784,2.9784,0,0,1,.48-2.1006,3.2085,3.2085,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3193-1.08a.96.96,0,0,0-.5205-.14.7765.7765,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1806v-1.16a2.0475,2.0475,0,0,1,.58-1.46,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8808,1.8808,0,0,1,.2393.9805v5.7012a5.0673,5.0673,0,0,0,.3007,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1806.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M532.3154,313V298.8768h2.4V313Z" />
                                        <path className="st18" d="M454.0488,333.14c-.32,1.14-1.26,2.1607-3.6606,2.1607a4.5732,4.5732,0,0,1-2.9009-.8,2.3948,2.3948,0,0,1-.74-.9605,3.1133,3.1133,0,0,1-.28-1.46v-2.0606h2.5806v1.64c0,.52-.04,1.12.46,1.44a1.4385,1.4385,0,0,0,.8.2,1.2675,1.2675,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.8027,1.8027,0,0,0-.88-1.86l-2.46-1.66a3.2783,3.2783,0,0,1-1.18-1.1606,3.8708,3.8708,0,0,1-.5-2.24,4.7528,4.7528,0,0,1,.34-1.9405c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4035,2.4035,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6445,2.6445,0,0,0,.28,1.36,2.3343,2.3343,0,0,0,.54.46l2.5205,1.7407a5.2915,5.2915,0,0,1,1.1606,1.1,3.3628,3.3628,0,0,1,.56,2.4405A7.9635,7.9635,0,0,1,454.0488,333.14Z" />
                                        <path className="st18" d="M459.7881,335.1c-1.38,0-2.1006-.3-2.4009-.9605a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V321.037h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7025,2.7025,0,0,0,.4-.04v1.72A5.0519,5.0519,0,0,1,459.7881,335.1Z" />
                                        <path className="st18" d="M467.6079,326.9384a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.94V335h-2.36V324.7577h2.38l-.02,1.3a4.1333,4.1333,0,0,1,.36-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M473.8276,335v-.84a3.2378,3.2378,0,0,1-2.18,1.0206,2.2544,2.2544,0,0,1-1.2007-.36,2.5158,2.5158,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2708,1.2708,0,0,0,.22.98.8743.8743,0,0,0,.64.26,1.0275,1.0275,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V335Z" />
                                        <path className="st18" d="M484.7476,334.16a3.3221,3.3221,0,0,1-2.66,1.0406,3.4394,3.4394,0,0,1-2.7207-.9605,2.878,2.878,0,0,1-.62-2.02v-4.541a2.974,2.974,0,0,1,.56-1.96,3.5873,3.5873,0,0,1,2.7808-1.16c.24,0,2.38,0,3.0405,1.5405a3.2811,3.2811,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5833,2.5833,0,0,0-.04-.5.834.834,0,0,0-.86-.64,1.071,1.071,0,0,0-.56.14c-.32.22-.42.5406-.42,1.2407V332.1a2.1226,2.1226,0,0,0,.06.7.8613.8613,0,0,0,.88.64,1.1716,1.1716,0,0,0,.52-.14,1.3556,1.3556,0,0,0,.4-1.1807v-1.18h2.3608V332.1A3.1827,3.1827,0,0,1,484.7476,334.16Z" />
                                        <path className="st18" d="M490.8276,335.1c-1.38,0-2.1005-.3-2.4008-.9605a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V321.037h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7039,2.7039,0,0,0,.4-.04v1.72A5.0522,5.0522,0,0,1,490.8276,335.1Z" />
                                        <path className="st18" d="M498.1475,335v-.84a3.2373,3.2373,0,0,1-2.1807,1.0206,2.2544,2.2544,0,0,1-1.2-.36,2.5158,2.5158,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2708,1.2708,0,0,0,.22.98.8743.8743,0,0,0,.64.26,1.0275,1.0275,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4V335Z" />
                                        <path className="st18" d="M507.8682,326.9384a1.6919,1.6919,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.94V335h-2.36V324.7577h2.3809l-.0205,1.3a4.1375,4.1375,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M512.167,330.3988V332.08a1.9809,1.9809,0,0,0,.06.68.8107.8107,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M189.292,218.0394c-.32,1.14-1.26,2.1607-3.6607,2.1607a4.5731,4.5731,0,0,1-2.9008-.8,2.3953,2.3953,0,0,1-.74-.96,3.1146,3.1146,0,0,1-.28-1.46v-2.0606h2.5806v1.64c0,.52-.04,1.12.46,1.44a1.4383,1.4383,0,0,0,.8.2,1.2677,1.2677,0,0,0,.9-.26c.38-.3.38-.92.38-1.88a1.8025,1.8025,0,0,0-.88-1.86l-2.46-1.66a3.2809,3.2809,0,0,1-1.18-1.1607,3.8716,3.8716,0,0,1-.5-2.24,4.7516,4.7516,0,0,1,.34-1.94c.8-1.72,3.2207-1.72,3.501-1.72.42,0,2.68,0,3.42,1.86a2.4046,2.4046,0,0,1,.22,1.18v1.4h-2.6006v-1.44c0-.42-.16-1.02-1.16-1.02-.6,0-1.16.22-1.16,1.4a2.6449,2.6449,0,0,0,.28,1.36,2.3359,2.3359,0,0,0,.54.46l2.52,1.7407a5.2916,5.2916,0,0,1,1.1607,1.1,3.3627,3.3627,0,0,1,.56,2.44A7.9691,7.9691,0,0,1,189.292,218.0394Z" />
                                        <path className="st18" d="M195.0312,220c-1.38,0-2.1005-.3-2.4008-.96a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7039,2.7039,0,0,0,.4-.04v1.72A5.0522,5.0522,0,0,1,195.0312,220Z" />
                                        <path className="st18" d="M202.8511,211.8383a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.1307,2.1307,0,0,0-.64,1.9405V219.9h-2.36V209.6576h2.38l-.02,1.3a4.12,4.12,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M209.0107,219.9c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2536,2.2536,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2026,3.2026,0,0,1,1.0805-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.18v-1.16a2.0476,2.0476,0,0,1,.58-1.46,3.646,3.646,0,0,1,2.5805-.76c.3,0,2.08-.02,2.8,1.28a1.8739,1.8739,0,0,1,.24.98v5.7012a5.0656,5.0656,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2812,1.2812,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M216.8506,220c-1.38,0-2.1006-.3-2.4009-.96a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7025,2.7025,0,0,0,.4-.04v1.72A5.0519,5.0519,0,0,1,216.8506,220Z" />
                                        <path className="st18" d="M222.2505,215.2987v1.6807a1.9967,1.9967,0,0,0,.06.68.8118.8118,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.96a6.5959,6.5959,0,0,1-.18-1.34v-4.3208a3.8127,3.8127,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8239,2.8239,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8715.8715,0,0,0-.88,1.0205v1.26h1.7Z" />
                                        <path className="st18" d="M235.3311,220.92a2.5125,2.5125,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,5.9752,5.9752,0,0,1-.8193-.06A2.5884,2.5884,0,0,1,228.89,222a3.8431,3.8431,0,0,1-.16-1.26h2.3608a1.5381,1.5381,0,0,0,.0391.48.8389.8389,0,0,0,.8808.6.9809.9809,0,0,0,.54-.14c.4-.22.42-.68.42-1.08v-1.5806a2.5186,2.5186,0,0,1-2.08,1.0005,2.6417,2.6417,0,0,1-1.2207-.3c-.94-.5-1-1.36-1-2.26V212.038a3.5121,3.5121,0,0,1,.14-1.12,2.044,2.044,0,0,1,2.04-1.46,2.9106,2.9106,0,0,1,2.12,1.02v-.82h2.4V220.26C235.3711,220.48,235.3516,220.7,235.3311,220.92Zm-2.38-8.4214c0-.2.08-1.24-.961-1.24a.9216.9216,0,0,0-.58.18c-.26.2-.38.44-.38,1.04v4.5605a1.9484,1.9484,0,0,0,.08.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M241.8506,220.64a4.6442,4.6442,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0606.74,8.0777,8.0777,0,0,1-1.54-.18l.3594-1.88a3.2074,3.2074,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6611-10.2422h2.3613l1.32,6.5815,1.0195-6.5815h2.3408Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M162.063,99.48c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8675,6.8675,0,0,1-1.34-.14,2.9469,2.9469,0,0,1-2.3809-1.6806,3.7931,3.7931,0,0,1-.2-1.48V90.4178c0-.24.02-.5.04-.74a2.6931,2.6931,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.9205-.82,4.7065,4.7065,0,0,1,2.6406.64,2.4625,2.4625,0,0,1,.88,1.04,3.1137,3.1137,0,0,1,.24,1.6V92.118h-2.5606v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1.0005,0-1.2.62-1.2,1.5406v7.5615c0,.92.2,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7408v-1.84h2.5606v2.0005A5.2348,5.2348,0,0,1,162.063,99.48Z" />
                                        <path className="st18" d="M170.2827,100.26A3.4119,3.4119,0,0,1,167.562,101.3a4.8426,4.8426,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38V93.6185a3.1677,3.1677,0,0,1,.2-1.3,2.9833,2.9833,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.9205,1.5005a2.95,2.95,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,170.2827,100.26Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V98.6c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M181.7432,101.1V93.5184a1.1184,1.1184,0,0,0-.36-1.04.9358.9358,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3V101.1h-2.36V93.5785c0-.42.04-.8-.32-1.1006a.7917.7917,0,0,0-.5606-.18c-.98,0-.92,1.04-.92,1.22V101.1h-2.36V90.8578h2.38v.68a2.9166,2.9166,0,0,1,2.02-.9,2.4418,2.4418,0,0,1,1.9205,1.02,3.1374,3.1374,0,0,1,1.68-.94,3.228,3.228,0,0,1,.66-.06,1.9271,1.9271,0,0,1,1.96,1.4,2.7023,2.7023,0,0,1,.1.92V101.1Z" />
                                        <path className="st18" d="M193.1226,100.32a1.9247,1.9247,0,0,1-.4405.54,2.0181,2.0181,0,0,1-1.32.4405,3.1345,3.1345,0,0,1-2.22-1.0606v4.4009h-2.38V90.8578h2.38v.78a3.2635,3.2635,0,0,1,2.1406-.98,2.1794,2.1794,0,0,1,1.7.82,3.0551,3.0551,0,0,1,.48,1.88V98.8A3.5191,3.5191,0,0,1,193.1226,100.32Zm-2.0606-6.7612c0-.58-.14-.8-.42-.98a1.0939,1.0939,0,0,0-.52-.14,1.038,1.038,0,0,0-.54.14,1.0523,1.0523,0,0,0-.44,1.04v4.6006a1.4258,1.4258,0,0,0,.08.5806.9343.9343,0,0,0,.9.56.86.86,0,0,0,.84-.48,1.6075,1.6075,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M200.1016,101.1c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.76c.3,0,2.0805-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805V98.6a5.0658,5.0658,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2813,1.2813,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M209.002,101.1V93.5585c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V101.1h-2.38V90.8578h2.42l-.02.74a2.6348,2.6348,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V101.1Z" />
                                        <path className="st18" d="M217.8418,101.84a4.65,4.65,0,0,1-.8,2.0606,2.32,2.32,0,0,1-2.0605.74,8.0787,8.0787,0,0,1-1.54-.18l.36-1.88a3.2,3.2,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6607-10.2422h2.36l1.32,6.5815,1.02-6.5815h2.34Z" />
                                        <path className="st18" d="M234.6616,94.5184c-.42.52-1.02.7-2.3008.7h-2.2V101.1H227.54V86.9769h5.2417a3.6749,3.6749,0,0,1,.7.06,1.972,1.972,0,0,1,1.38,1,5.1583,5.1583,0,0,1,.3,2.1206v1.86C235.1616,93.3383,235.1021,93.9784,234.6616,94.5184Zm-2.06-4.02a1.7018,1.7018,0,0,0-.2-1.1406,1.1176,1.1176,0,0,0-.96-.3h-1.28v4.1406h1.2608c.54,0,.84-.06,1-.28a1.8345,1.8345,0,0,0,.18-.98Z" />
                                        <path className="st18" d="M243.1606,100.26A3.41,3.41,0,0,1,240.44,101.3a4.8409,4.8409,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38V93.6185a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5005a2.9655,2.9655,0,0,1,.2392,1.36v4.941A2.4493,2.4493,0,0,1,243.1606,100.26Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V98.6c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M246.2593,101.1V86.9769h2.4V101.1Z" />
                                        <path className="st18" d="M251.2993,89.3773v-2.4h2.4v2.4Zm0,11.7227V90.8578h2.4V101.1Z" />
                                        <path className="st18" d="M262.2212,100.26A3.3232,3.3232,0,0,1,259.56,101.3a3.4409,3.4409,0,0,1-2.7207-.9605,3.5929,3.5929,0,0,1-.42-.72,3.54,3.54,0,0,1-.2-1.3v-4.541a2.9744,2.9744,0,0,1,.5606-1.96,3.5871,3.5871,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.2811,3.2811,0,0,1,.2,1.32v1.22H260.46v-1.24a2.5429,2.5429,0,0,0-.04-.5.8342.8342,0,0,0-.86-.64,1.0686,1.0686,0,0,0-.56.14c-.32.22-.42.5406-.42,1.2407V98.2a2.1435,2.1435,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1714,1.1714,0,0,0,.5205-.14,1.3559,1.3559,0,0,0,.4-1.1807v-1.18h2.36V98.2A3.1817,3.1817,0,0,1,262.2212,100.26Z" />
                                        <path className="st18" d="M268.98,101.84a4.6448,4.6448,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0606.74,8.0777,8.0777,0,0,1-1.54-.18l.3594-1.88a3.2074,3.2074,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6611-10.2422H266.64l1.32,6.5815,1.02-6.5815h2.3408Z" />
                                        <path className="st18" d="M117.9727,123.1c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.76c.3,0,2.0805-.02,2.8,1.28a1.8744,1.8744,0,0,1,.24.9805V120.6a5.0658,5.0658,0,0,0,.3,2.32c.02.06.0595.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2813,1.2813,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M126.8726,123.1v-7.5415c0-.64-.04-.9-.36-1.1a.7489.7489,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V123.1h-2.38V112.8578h2.42l-.02.74a2.6348,2.6348,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V123.1Z" />
                                        <path className="st18" d="M136.0322,123.1l.02-1a2.27,2.27,0,0,1-2,1.2,2.4506,2.4506,0,0,1-1.42-.4405c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5408,2.5408,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9922,2.9922,0,0,1,2.06,1.04v-4.7012h2.38V123.1Zm.02-7.4414c0-.4605-.02-.78-.44-1.0205a1.198,1.198,0,0,0-.58-.16.9306.9306,0,0,0-.52.16c-.4.28-.38.7-.38,1.14V120.14c0,.6.06.82.34,1a1.1157,1.1157,0,0,0,.58.16.9691.9691,0,0,0,.82-.4,1.3078,1.3078,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M154.0728,121.48c-.68,1.96-3.3008,1.92-3.6006,1.92a6.8688,6.8688,0,0,1-1.34-.14,2.9469,2.9469,0,0,1-2.3808-1.6806,3.7931,3.7931,0,0,1-.2-1.48v-7.6817c0-.24.02-.5.04-.74a2.6928,2.6928,0,0,1,1-2.1807,4.3,4.3,0,0,1,2.92-.82,4.7061,4.7061,0,0,1,2.6406.64,2.4627,2.4627,0,0,1,.88,1.04,3.1149,3.1149,0,0,1,.24,1.6v2.1606h-2.56v-1.78c0-1.0806-.18-1.6607-1.34-1.6607-1,0-1.2.62-1.2,1.5406v7.5615c0,.92.2,1.6006,1.28,1.6006,1.16,0,1.26-.66,1.26-1.7408v-1.84h2.56v2.0005A5.2375,5.2375,0,0,1,154.0728,121.48Z" />
                                        <path className="st18" d="M162.2925,122.26a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8418,4.8418,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7229,3.7229,0,0,1-.18-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9833,2.9833,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.92,1.5005a2.9489,2.9489,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,162.2925,122.26Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V120.6c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M170.0718,115.0385a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.1307,2.1307,0,0,0-.64,1.9405V123.1h-2.36V112.8578h2.38l-.02,1.3a4.115,4.115,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M178.4722,122.32a1.923,1.923,0,0,1-.44.54,2.0181,2.0181,0,0,1-1.32.4405,3.1345,3.1345,0,0,1-2.22-1.0606v4.4009h-2.38V112.8578h2.38v.78a3.2635,3.2635,0,0,1,2.1406-.98,2.1794,2.1794,0,0,1,1.7.82,3.0545,3.0545,0,0,1,.48,1.88V120.8A3.5191,3.5191,0,0,1,178.4722,122.32Zm-2.0606-6.7612c0-.58-.14-.8-.42-.98a1.0939,1.0939,0,0,0-.52-.14,1.0375,1.0375,0,0,0-.54.14,1.0521,1.0521,0,0,0-.44,1.04v4.6006a1.4258,1.4258,0,0,0,.08.5806.9343.9343,0,0,0,.9.56.86.86,0,0,0,.84-.48,1.6075,1.6075,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M187.0322,122.26a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8426,4.8426,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.0411-1.6606c.32,0,2.28-.02,2.92,1.5005a2.95,2.95,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,187.0322,122.26Zm-1.7807-6.8813c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7492.7492,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V120.6c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M194.8115,115.0385a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.9405V123.1h-2.36V112.8578h2.38l-.02,1.3a4.1278,4.1278,0,0,1,.36-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M200.9712,123.1c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9766,2.9766,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9547.9547,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805V120.6a5.0671,5.0671,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2811,1.2811,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M208.811,123.2c-1.38,0-2.1006-.3-2.4008-.9605a2.6307,2.6307,0,0,1-.2-1.18v-6.621h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7012,2.7012,0,0,0,.4-.04v1.72A5.0506,5.0506,0,0,1,208.811,123.2Z" />
                                        <path className="st18" d="M214.2109,118.4989V120.18a1.9935,1.9935,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7529.7529,0,0,0,.76-.5605,4.992,4.992,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.9605a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8239,2.8239,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M231.771,123.1v-.84a4.2133,4.2133,0,0,1-.5606.54,3.2209,3.2209,0,0,1-1.96.6006A2.8174,2.8174,0,0,1,226.41,121.76a3.3,3.3,0,0,1-.2-1.24v-8.0415a5.4254,5.4254,0,0,1,.26-1.88c.58-1.56,2.3208-1.92,3.7613-1.92,1.14,0,2.6806.24,3.3808,1.3a2.6712,2.6712,0,0,1,.38,1.5005v2.0606h-2.6006v-1.36c0-.48.04-.96-.44-1.26a1.6286,1.6286,0,0,0-1.6.02c-.4.28-.5.72-.5,1.6v7.4619a2.0324,2.0324,0,0,0,.1.74,1.0442,1.0442,0,0,0,1.1206.64,1.2556,1.2556,0,0,0,1.02-.36,1.7332,1.7332,0,0,0,.3008-1.26v-2.2h-1.6006v-2.1206h4.2012V123.1Z" />
                                        <path className="st18" d="M242.3706,122.26A3.41,3.41,0,0,1,239.65,123.3a4.8409,4.8409,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5005a2.964,2.964,0,0,1,.2393,1.36v4.941A2.4493,2.4493,0,0,1,242.3706,122.26Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V120.6c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M249.19,123.1h-2.32l-2.2607-10.2422H246.99l1.0606,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M255.69,118.4989V120.18a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M266.9106,115.0385a1.691,1.691,0,0,0-.78-.16,1.6082,1.6082,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.9405V123.1h-2.36V112.8578H264.61l-.02,1.3a4.1321,4.1321,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M273.17,123.1v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V123.1h-2.3809V112.8578H271.37l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1V123.1Z" />
                                        <path className="st18" d="M282.29,123.1c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9794,2.9794,0,0,1,.4805-2.1006,3.21,3.21,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.3193-1.08a.9592.9592,0,0,0-.5205-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.1807v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6494,3.6494,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8007,1.28a1.8744,1.8744,0,0,1,.24.9805V120.6a5.0656,5.0656,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M291.19,123.1v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V123.1h-2.3808V112.8578h2.4209l-.0205.74a2.6361,2.6361,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V123.1Z" />
                                        <path className="st18" d="M302.0708,122.26A3.3232,3.3232,0,0,1,299.41,123.3a3.4409,3.4409,0,0,1-2.7207-.9605,3.5929,3.5929,0,0,1-.42-.72,3.54,3.54,0,0,1-.2-1.3v-4.541a2.9744,2.9744,0,0,1,.5606-1.96,3.5871,3.5871,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.2811,3.2811,0,0,1,.2,1.32v1.22H300.31v-1.24a2.5408,2.5408,0,0,0-.04-.5.8342.8342,0,0,0-.86-.64,1.0686,1.0686,0,0,0-.56.14c-.32.22-.42.5406-.42,1.2407V120.2a2.1435,2.1435,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1714,1.1714,0,0,0,.5205-.14,1.3559,1.3559,0,0,0,.4-1.1807v-1.18h2.36V120.2A3.1817,3.1817,0,0,1,302.0708,122.26Z" />
                                        <path className="st18" d="M307.25,118.4989V120.18a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7525.7525,0,0,0,.7607-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.832,2.832,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M399.5,619.3l-.4.6-.5.4-.6.2h-.6l-1-.2-.6-.3-.5-.5-.3-.5v-.7l.2-1,.5-.8.8-.5.9-.2.9.2.7.5.5.8.2.9Z" />
                                        <path className="st18" d="M399.5,641.3l-.4.6-.5.4-.6.2h-.6l-1-.2-.6-.3-.5-.5-.3-.5v-.7l.2-1,.5-.8.8-.5.9-.2.9.2.7.5.5.8.2.9Z" />
                                        <path className="st18" d="M399.5,663.3l-.4.6-.5.4-.6.2h-.6l-1-.2-.6-.3-.5-.5-.3-.5v-.7l.2-1,.5-.8.8-.5.9-.2.9.2.7.5.5.8.2.9Z" />
                                        <path className="st18" d="M399.5,685.3l-.4.6-.5.4-.6.2h-.6l-1-.2-.6-.3-.5-.5-.3-.5v-.7l.2-1,.5-.8.8-.5.9-.2.9.2.7.5.5.8.2.9Z" />
                                        <path className="st18" d="M399.5,707.3l-.4.6-.5.4-.6.2h-.6l-1-.2-.6-.3-.5-.5-.3-.5v-.7l.2-1,.5-.8.8-.5.9-.2.9.2.7.5.5.8.2.9Z" />
                                        <path className="st18" d="M408.4,713.4h-1.5v-1.7h1.5Z" />
                                        <path className="st18" d="M412.2,713.4h-1.5v-1.7h1.5Z" />
                                        <path className="st18" d="M415.9,713.4h-1.5v-1.7h1.5Z" />
                                        <g>
                                            <path className="st18" d="M414.3779,620.5385c0,.4209-.02.8408-.04,1.2607a4.2831,4.2831,0,0,1-1.06,2.9609,3.487,3.487,0,0,1-2.4409.74,3.17,3.17,0,0,1-2.74-1.1407,2.5112,2.5112,0,0,1-.42-.9,22.6357,22.6357,0,0,1-.22-4.461c0-1.28.02-2.54.04-3.82a4.7975,4.7975,0,0,1,.76-3.06,3.3732,3.3732,0,0,1,2.8609-1.1612,3.0916,3.0916,0,0,1,2.9,1.5606,3.4739,3.4739,0,0,1,.34,1.2607c.12,1,.12,3.28.1,4.2608C414.458,618.8783,414.3979,619.6986,414.3779,620.5385Zm-1.74-5.4405a4.3742,4.3742,0,0,0-.28-1.9609,1.5091,1.5091,0,0,0-1.36-.7,1.6329,1.6329,0,0,0-1.04.3408c-.68.54-.7,1.48-.7,3.12v.9805c0,1.36,0,2.7207.02,4.0811a7.6385,7.6385,0,0,0,.14,2.0605,1.6034,1.6034,0,0,0,2.9.22,10.1753,10.1753,0,0,0,.32-3.3213c.02-.62.02-1.24.02-1.88C412.6577,617.058,412.6577,616.0775,412.6377,615.098Z" />
                                            <path className="st18" d="M419.8169,616.8978c-1.6607.4405-1.46,2.36-1.46,3.6211V625.3h-1.64v-10.002h1.54v1.88a6.3936,6.3936,0,0,1,.46-.9,2.4783,2.4783,0,0,1,1.9805-1.16h.26v1.6806A4.4185,4.4185,0,0,0,419.8169,616.8978Z" />
                                            <path className="st18" d="M426.4771,625.3v-1.04a3.4269,3.4269,0,0,1-.34.46,2.3124,2.3124,0,0,1-1.7607.76,2.1419,2.1419,0,0,1-2.16-2.04,29.4444,29.4444,0,0,1-.14-3.0605c0-.7207.02-1.44.04-2.1612a4.8212,4.8212,0,0,1,.3-1.72,2.1554,2.1554,0,0,1,2.0405-1.3808,2.3659,2.3659,0,0,1,1.5.52,2.21,2.21,0,0,1,.4.4394v-4.9h1.62V625.3Zm-.12-6.9014a3.12,3.12,0,0,0-.34-1.5205,1.2471,1.2471,0,0,0-1.66-.34c-.6806.42-.6406,1.46-.66,2.1-.02.4609-.02.9209-.02,1.3808,0,.76-.02,1.54.02,2.3008a3.3314,3.3314,0,0,0,.16,1.14,1.2386,1.2386,0,0,0,1.14.7607,1.4605,1.4605,0,0,0,.44-.0606c.28-.08.58-.3.76-.92a15.1956,15.1956,0,0,0,.2-2.9209C426.397,619.6791,426.397,619.0385,426.3569,618.3988Z" />
                                            <path className="st18" d="M435.1577,624.58a2.5591,2.5591,0,0,1-2.2.9,2.5083,2.5083,0,0,1-2.501-1.34c-.38-.74-.32-1.3408-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.6243,16.6243,0,0,1,.08-1.7207,2.5893,2.5893,0,0,1,.86-1.9805,2.7725,2.7725,0,0,1,1.8408-.5595,2.8489,2.8489,0,0,1,1.92.54,1.9948,1.9948,0,0,1,.6005.7,6.4068,6.4068,0,0,1,.34,2.74v1.4805h-4.02c-.0205.58-.0205,1.1807,0,1.7607a4.2568,4.2568,0,0,0,.1.94,1.1181,1.1181,0,0,0,1.2.8809,1.0432,1.0432,0,0,0,.74-.2207c.4-.3.5-.8.4805-1.76h1.5A3.2608,3.2608,0,0,1,435.1577,624.58Zm-.92-5.6807c0-.38,0-.78-.02-1.16-.04-.78-.3594-1.3808-1.24-1.3808a1.0593,1.0593,0,0,0-1.06.6406,4.668,4.668,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M440.9565,616.8978c-1.66.4405-1.46,2.36-1.46,3.6211V625.3H437.856v-10.002h1.541v1.88a6.2377,6.2377,0,0,1,.46-.9,2.4755,2.4755,0,0,1,1.9805-1.16h.26v1.6806A4.4269,4.4269,0,0,0,440.9565,616.8978Z" />
                                            <path className="st18" d="M453.897,622.2992a5.0522,5.0522,0,0,1-.36,2.06,2.1377,2.1377,0,0,1-1.96,1.12,2.1672,2.1672,0,0,1-1.5205-.58,2.26,2.26,0,0,1-.36-.44v4.0214h-1.6406V615.2982h1.581l.02.96a2.339,2.339,0,0,1,2.0205-1.1406,2.0979,2.0979,0,0,1,1.34.44,2.3768,2.3768,0,0,1,.86,1.6806,20.8941,20.8941,0,0,1,.08,2.3Q453.9565,620.92,453.897,622.2992Zm-1.62-3.8408a4.7344,4.7344,0,0,0-.0206-.54c-.04-.74-.32-1.5205-1.2-1.5205a1.2442,1.2442,0,0,0-1.2,1.1006,12.6606,12.6606,0,0,0-.14,2.8008c0,.66,0,1.34.0391,2a3.3093,3.3093,0,0,0,.28,1.32,1.1677,1.1677,0,0,0,1.0606.66,1.03,1.03,0,0,0,1.02-.82,9.9614,9.9614,0,0,0,.14-1.92c.0206-.48.04-.98.04-1.4609C452.2964,619.5385,452.2964,618.9984,452.2769,618.4584Z" />
                                            <path className="st18" d="M459.1763,616.8978c-1.66.4405-1.46,2.36-1.46,3.6211V625.3h-1.6406v-10.002h1.541v1.88a6.228,6.228,0,0,1,.46-.9,2.4752,2.4752,0,0,1,1.98-1.16h.26v1.6806A4.4258,4.4258,0,0,0,459.1763,616.8978Z" />
                                            <path className="st18" d="M467.0366,622.32a6.1167,6.1167,0,0,1-.1,1.12c-.16.9395-.66,2.04-2.7207,2.04a2.5814,2.5814,0,0,1-2.36-1.1006,4.5776,4.5776,0,0,1-.4209-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4341,3.4341,0,0,1,.7207-2.34,2.7607,2.7607,0,0,1,2.1406-.7207c2.7,0,2.7207,2.2607,2.74,3.081.02.74.02,1.46,0,2.2C467.0767,621.0394,467.0767,621.6791,467.0366,622.32Zm-1.6406-4.1416c-.0195-.72-.06-1.78-1.08-1.76a1.0858,1.0858,0,0,0-1.1.9395,15.1415,15.1415,0,0,0-.1006,2.4814c0,.8.02,1.62.02,2.42a4.832,4.832,0,0,0,.1,1.2607.9527.9527,0,0,0,.9805.7c.7,0,.98-.4,1.1-1.041a22.7736,22.7736,0,0,0,.1006-2.8C465.4165,619.639,465.4165,618.9183,465.396,618.1781Z" />
                                            <path className="st18" d="M474.0767,624.64a2.7511,2.7511,0,0,1-2.28.84,2.4016,2.4016,0,0,1-2.4-1.22,9.668,9.668,0,0,1-.36-3.5215c0-.92.0206-1.84.08-2.7607a3.7851,3.7851,0,0,1,.78-2.2,2.8,2.8,0,0,1,2.041-.66,2.5214,2.5214,0,0,1,2.34,1c.4209.6806.44,1.32.44,2.8007h-1.5205c0-.42,0-.86-.04-1.3a1.6477,1.6477,0,0,0-.2-.82,1.1171,1.1171,0,0,0-1.0195-.4805,1.1,1.1,0,0,0-1.1807,1.0206,24.4845,24.4845,0,0,0-.12,2.98c0,.7.0205,1.4.04,2.1006a2.9633,2.9633,0,0,0,.1806,1.2207,1.0818,1.0818,0,0,0,1.06.58,1.1526,1.1526,0,0,0,1.2-1.1807c.0205-.16.08-.86.08-1.46h1.5205C474.7173,623.2,474.6372,623.98,474.0767,624.64Z" />
                                            <path className="st18" d="M481.6968,624.58a2.5594,2.5594,0,0,1-2.2.9,2.5082,2.5082,0,0,1-2.501-1.34c-.38-.74-.32-1.3408-.34-2.8808-.0205-.62-.0205-1.24-.0205-1.86a16.6521,16.6521,0,0,1,.08-1.7207,2.5893,2.5893,0,0,1,.86-1.9805,2.7723,2.7723,0,0,1,1.8408-.5595,2.8488,2.8488,0,0,1,1.92.54,1.9948,1.9948,0,0,1,.6006.7,6.4084,6.4084,0,0,1,.34,2.74v1.4805h-4.0206c-.0205.58-.0205,1.1807,0,1.7607a4.2615,4.2615,0,0,0,.1.94,1.1181,1.1181,0,0,0,1.2.8809,1.0432,1.0432,0,0,0,.74-.2207c.4-.3.5-.8.4805-1.76h1.5A3.2608,3.2608,0,0,1,481.6968,624.58Zm-.92-5.6807c0-.38,0-.78-.0206-1.16-.04-.78-.3593-1.3808-1.24-1.3808a1.0594,1.0594,0,0,0-1.06.6406,4.668,4.668,0,0,0-.2,1.9v.42h2.5206Z" />
                                            <path className="st18" d="M487.7163,625.32a3.9964,3.9964,0,0,1-1.1807.16,2.4161,2.4161,0,0,1-2.1806-.92c-.44-.6005-.48-1.32-.5-2.7207h1.541c0,.34.0195.66.04,1a1.6162,1.6162,0,0,0,.14.7608,1.2151,1.2151,0,0,0,1.06.58,1.1239,1.1239,0,0,0,1.16-1.2c0-.8809-.44-1.24-1.041-1.7608l-1.24-1.08a3.0063,3.0063,0,0,1-.8593-4.2207,2.1532,2.1532,0,0,1,.8593-.6006,3.4085,3.4085,0,0,1,1.1807-.2,2.8369,2.8369,0,0,1,1.8809.62,1.9766,1.9766,0,0,1,.3994.6806,4.6949,4.6949,0,0,1,.2,1.82h-1.42a4.1886,4.1886,0,0,0-.08-1.12.9335.9335,0,0,0-.9805-.7207.9893.9893,0,0,0-1.0605,1.1006,1.0265,1.0265,0,0,0,.12.52,4.0264,4.0264,0,0,0,1.12,1.2012l1.1406.9395c.2.1806.4.3408.58.52a2.8557,2.8557,0,0,1,.82,2.0605A2.4873,2.4873,0,0,1,487.7163,625.32Z" />
                                            <path className="st18" d="M494.4956,625.32a3.9964,3.9964,0,0,1-1.1807.16,2.4161,2.4161,0,0,1-2.1806-.92c-.44-.6005-.4795-1.32-.5-2.7207h1.541c0,.34.0195.66.04,1a1.6162,1.6162,0,0,0,.14.7608,1.2151,1.2151,0,0,0,1.06.58,1.1239,1.1239,0,0,0,1.16-1.2c0-.8809-.44-1.24-1.041-1.7608l-1.24-1.08a3.0063,3.0063,0,0,1-.8593-4.2207,2.1532,2.1532,0,0,1,.8593-.6006,3.4085,3.4085,0,0,1,1.1807-.2,2.8369,2.8369,0,0,1,1.8809.62,1.9766,1.9766,0,0,1,.3994.6806,4.6949,4.6949,0,0,1,.2,1.82h-1.42a4.1886,4.1886,0,0,0-.08-1.12.9335.9335,0,0,0-.9805-.7207.9893.9893,0,0,0-1.06,1.1006,1.0265,1.0265,0,0,0,.12.52,4.0264,4.0264,0,0,0,1.12,1.2012l1.1406.9395c.2.1806.4.3408.58.52a2.8557,2.8557,0,0,1,.82,2.0605A2.4873,2.4873,0,0,1,494.4956,625.32Z" />
                                            <path className="st18" d="M497.9546,613.5375v-2.0606h1.581v2.0606Zm0,11.7627v-10.002h1.581V625.3Z" />
                                            <path className="st18" d="M505.9351,625.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3576,4.3576,0,0,0-.14,1.62V625.3H501.814v-10.002h1.52v.92c.12-.1406.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1979,4.1979,0,0,1,.34,2.28V625.3Z" />
                                            <path className="st18" d="M514.4155,628.2006a3.8211,3.8211,0,0,1-1.94.46,2.88,2.88,0,0,1-1.94-.64,1.9878,1.9878,0,0,1-.58-.7608,2.8362,2.8362,0,0,1-.2-1.16h1.4805a1.9,1.9,0,0,0,.12.7,1.318,1.318,0,0,0,1.2.58,1.6686,1.6686,0,0,0,.8808-.22c.6-.4.54-1.16.54-1.74v-1.16l-.1807.2393a2.3009,2.3009,0,0,1-1.84.86,2.1386,2.1386,0,0,1-1.78-.88,4.9135,4.9135,0,0,1-.501-2.7207c0-.66-.0195-1.32-.0195-1.9805,0-.36,0-.72.0195-1.08a5.2762,5.2762,0,0,1,.501-2.581,2.186,2.186,0,0,1,1.98-1,2.09,2.09,0,0,1,1.94,1.06v-.88h1.4805V624.8c0,.3593.02.72,0,1.08A2.3488,2.3488,0,0,1,514.4155,628.2006Zm-.3994-7.7813c0-.6406.0195-1.28-.04-1.94a4.8942,4.8942,0,0,0-.1407-1.32,1.2538,1.2538,0,0,0-1.92-.7c-.58.4394-.62,1.42-.62,2.86v1.5606c0,.62,0,1.24.04,1.88a1.4722,1.4722,0,0,0,.48,1.1406,1.3279,1.3279,0,0,0,1.98-.5606,6.6767,6.6767,0,0,0,.2207-2.36Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M407.5762,647.3V633.1771h1.8008v12.542h4.1406V647.3Z" />
                                            <path className="st18" d="M420.4971,644.32a6.1144,6.1144,0,0,1-.1,1.12c-.16.9395-.66,2.04-2.72,2.04a2.5829,2.5829,0,0,1-2.3609-1.1006,4.5822,4.5822,0,0,1-.42-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4347,3.4347,0,0,1,.72-2.34,2.76,2.76,0,0,1,2.14-.7207c2.7007,0,2.7207,2.2607,2.7407,3.081.02.74.02,1.46,0,2.2C420.5371,643.0394,420.5371,643.6791,420.4971,644.32Zm-1.64-4.1416c-.02-.72-.06-1.78-1.08-1.76a1.0867,1.0867,0,0,0-1.1.9395,15.2164,15.2164,0,0,0-.1,2.4814c0,.8.02,1.62.02,2.42a4.822,4.822,0,0,0,.1,1.2607.953.953,0,0,0,.9805.7c.7,0,.98-.4,1.1-1.041a22.8244,22.8244,0,0,0,.1-2.8C418.877,641.639,418.877,640.9183,418.8569,640.1781Z" />
                                            <path className="st18" d="M427.2368,650.2006a3.8211,3.8211,0,0,1-1.94.46,2.88,2.88,0,0,1-1.94-.64,1.9789,1.9789,0,0,1-.5805-.7608,2.8442,2.8442,0,0,1-.2-1.16h1.48a1.9,1.9,0,0,0,.12.7,1.318,1.318,0,0,0,1.2.58,1.6637,1.6637,0,0,0,.88-.22c.6006-.4.5411-1.16.5411-1.74v-1.16l-.1807.2393a2.3009,2.3009,0,0,1-1.84.86,2.14,2.14,0,0,1-1.7808-.88,4.9172,4.9172,0,0,1-.5-2.7207c0-.66-.02-1.32-.02-1.9805,0-.36,0-.72.02-1.08a5.28,5.28,0,0,1,.5-2.581,2.1879,2.1879,0,0,1,1.981-1,2.0894,2.0894,0,0,1,1.9394,1.06v-.88h1.4805V646.8c0,.3593.0205.72,0,1.08A2.3488,2.3488,0,0,1,427.2368,650.2006Zm-.3994-7.7813c0-.6406.0195-1.28-.04-1.94a4.8942,4.8942,0,0,0-.1407-1.32,1.2538,1.2538,0,0,0-1.92-.7c-.58.4394-.62,1.42-.62,2.86v1.5606c0,.62,0,1.24.04,1.88a1.4722,1.4722,0,0,0,.4795,1.1406,1.3279,1.3279,0,0,0,1.9805-.5606,6.6767,6.6767,0,0,0,.2207-2.36Z" />
                                            <path className="st18" d="M430.6753,635.5375v-2.0606h1.581v2.0606Zm0,11.7627v-10.002h1.581V647.3Z" />
                                            <path className="st18" d="M437.8569,647.32a3.9962,3.9962,0,0,1-1.1806.16,2.4162,2.4162,0,0,1-2.1807-.92c-.4394-.6005-.48-1.32-.5-2.7207h1.541c0,.34.02.66.04,1a1.6162,1.6162,0,0,0,.14.7608,1.2151,1.2151,0,0,0,1.0606.58,1.1239,1.1239,0,0,0,1.16-1.2c0-.8809-.44-1.24-1.041-1.7608l-1.24-1.08a3.0062,3.0062,0,0,1-.8594-4.2207,2.1527,2.1527,0,0,1,.8594-.6006,3.4078,3.4078,0,0,1,1.1806-.2,2.8368,2.8368,0,0,1,1.8809.62,1.9751,1.9751,0,0,1,.3994.6806,4.6929,4.6929,0,0,1,.2,1.82h-1.42a4.1886,4.1886,0,0,0-.08-1.12.9335.9335,0,0,0-.98-.7207.9894.9894,0,0,0-1.0605,1.1006,1.0265,1.0265,0,0,0,.12.52,4.0264,4.0264,0,0,0,1.12,1.2012l1.1406.9395c.2.1806.4.3408.58.52a2.8557,2.8557,0,0,1,.82,2.0605A2.4873,2.4873,0,0,1,437.8569,647.32Z" />
                                            <path className="st18" d="M444.856,647.34a2.6284,2.6284,0,0,1-.9.14,2.7077,2.7077,0,0,1-1.7-.5,3.0627,3.0627,0,0,1-.5205-2.2c0-.24.0205-.5.0205-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6006v2.6806h1.4805v1.2H443.396v6.461a1.3992,1.3992,0,0,0,.18.84c.12.1807.28.32.74.32a1.74,1.74,0,0,0,.5606-.08Z" />
                                            <path className="st18" d="M446.4146,635.5375v-2.0606h1.581v2.0606Zm0,11.7627v-10.002h1.581V647.3Z" />
                                            <path className="st18" d="M455.1567,646.64a2.7539,2.7539,0,0,1-2.2812.84,2.4016,2.4016,0,0,1-2.4-1.22,9.668,9.668,0,0,1-.36-3.5215c0-.92.0206-1.84.08-2.7607a3.7851,3.7851,0,0,1,.78-2.2,2.8,2.8,0,0,1,2.041-.66,2.5214,2.5214,0,0,1,2.34,1c.4209.6806.44,1.32.44,2.8007h-1.5205c0-.42,0-.86-.04-1.3a1.6477,1.6477,0,0,0-.2-.82,1.1171,1.1171,0,0,0-1.0195-.4805,1.1,1.1,0,0,0-1.1807,1.0206,24.4845,24.4845,0,0,0-.12,2.98c0,.7.02,1.4.04,2.1006a2.9633,2.9633,0,0,0,.1806,1.2207,1.0818,1.0818,0,0,0,1.06.58,1.1526,1.1526,0,0,0,1.2-1.1807c.02-.16.08-.86.08-1.46h1.5205C455.7964,645.2,455.7163,645.98,455.1567,646.64Z" />
                                            <path className="st18" d="M461.1958,647.32a3.9964,3.9964,0,0,1-1.1807.16,2.4161,2.4161,0,0,1-2.1806-.92c-.44-.6005-.4795-1.32-.5-2.7207h1.541c0,.34.0195.66.0391,1a1.6167,1.6167,0,0,0,.1406.7608,1.2151,1.2151,0,0,0,1.06.58,1.124,1.124,0,0,0,1.16-1.2c0-.8809-.44-1.24-1.041-1.7608l-1.24-1.08a3.0061,3.0061,0,0,1-.8593-4.2207,2.1532,2.1532,0,0,1,.8593-.6006,3.408,3.408,0,0,1,1.1807-.2,2.8369,2.8369,0,0,1,1.8809.62,1.9766,1.9766,0,0,1,.3994.6806,4.6949,4.6949,0,0,1,.2,1.82h-1.42a4.1886,4.1886,0,0,0-.08-1.12.9335.9335,0,0,0-.9805-.7207.9894.9894,0,0,0-1.0606,1.1006,1.0258,1.0258,0,0,0,.12.52,4.0264,4.0264,0,0,0,1.12,1.2012l1.1406.9395c.2.1806.4.3408.58.52a2.8562,2.8562,0,0,1,.82,2.0605A2.4874,2.4874,0,0,1,461.1958,647.32Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M413.6177,662.6185a3.55,3.55,0,0,1-2.8008.88h-1.48V669.3h-1.7607V655.1771h3.6611a2.643,2.643,0,0,1,2.38.9405,4.8251,4.8251,0,0,1,.74,3.34A5.3729,5.3729,0,0,1,413.6177,662.6185Zm-1.02-4.4209c-.16-1.4-1.2007-1.42-2.3409-1.42h-.92v5.1807h1.18c.78,0,1.58.04,1.96-.82a3.9506,3.9506,0,0,0,.16-1.58C412.6377,659.098,412.6377,658.6576,412.5977,658.1976Z" />
                                            <path className="st18" d="M419.417,660.8978c-1.6607.4405-1.46,2.36-1.46,3.6211V669.3h-1.64v-10.002h1.54v1.88a6.3429,6.3429,0,0,1,.46-.9,2.4767,2.4767,0,0,1,1.98-1.16h.26v1.6806A4.4185,4.4185,0,0,0,419.417,660.8978Z" />
                                            <path className="st18" d="M427.2778,666.32a6.094,6.094,0,0,1-.1006,1.12c-.16.9395-.66,2.04-2.7207,2.04a2.5825,2.5825,0,0,1-2.36-1.1006,4.5822,4.5822,0,0,1-.42-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4337,3.4337,0,0,1,.72-2.34,2.76,2.76,0,0,1,2.14-.7207c2.7011,0,2.7207,2.2607,2.7412,3.081.0195.74.0195,1.46,0,2.2C427.3179,665.0394,427.3179,665.6791,427.2778,666.32Zm-1.6406-4.1416c-.0205-.72-.06-1.78-1.08-1.76a1.0866,1.0866,0,0,0-1.1006.9395,15.2164,15.2164,0,0,0-.1,2.4814c0,.8.02,1.62.02,2.42a4.822,4.822,0,0,0,.1,1.2607.9531.9531,0,0,0,.98.7c.7,0,.9805-.4,1.1006-1.041a22.8336,22.8336,0,0,0,.1-2.8C425.6567,663.639,425.6567,662.9183,425.6372,662.1781Z" />
                                            <path className="st18" d="M433.6772,669.3v-1.04a3.42,3.42,0,0,1-.34.46,2.3124,2.3124,0,0,1-1.7607.76,2.1423,2.1423,0,0,1-2.16-2.04,29.2873,29.2873,0,0,1-.1406-3.0605c0-.7207.0205-1.44.04-2.1612a4.807,4.807,0,0,1,.3008-1.72,2.1533,2.1533,0,0,1,2.04-1.3808,2.3659,2.3659,0,0,1,1.5.52,2.21,2.21,0,0,1,.4.4394v-4.9h1.62V669.3Zm-.12-6.9014a3.12,3.12,0,0,0-.34-1.5205,1.2471,1.2471,0,0,0-1.66-.34c-.6806.42-.6406,1.46-.66,2.1-.02.4609-.02.9209-.02,1.3808,0,.76-.02,1.54.02,2.3008a3.3314,3.3314,0,0,0,.16,1.14,1.2386,1.2386,0,0,0,1.14.7607,1.4605,1.4605,0,0,0,.44-.0606c.28-.08.58-.3.76-.92a15.1956,15.1956,0,0,0,.2-2.9209C433.5972,663.6791,433.5972,663.0385,433.5571,662.3988Z" />
                                            <path className="st18" d="M441.6567,669.3l-.0195-.9209a2.4752,2.4752,0,0,1-2.1211,1.1006,2.03,2.03,0,0,1-1.3-.4405c-.8008-.66-.7607-1.62-.7607-3.46v-6.2813h1.6006v6.5606c0,.28,0,.5605.0205.8408a2.3607,2.3607,0,0,0,.14.92,1.076,1.076,0,0,0,1.04.66,1.2193,1.2193,0,0,0,.78-.26c.52-.4.58-1.02.58-2.2012v-6.5205h1.58V669.3Z" />
                                            <path className="st18" d="M450.3579,668.64a2.7541,2.7541,0,0,1-2.2812.84,2.4014,2.4014,0,0,1-2.4-1.22,9.668,9.668,0,0,1-.36-3.5215c0-.92.0205-1.84.08-2.7607a3.7851,3.7851,0,0,1,.78-2.2,2.8,2.8,0,0,1,2.041-.66,2.5212,2.5212,0,0,1,2.34,1c.4209.6806.44,1.32.44,2.8007h-1.52c0-.42,0-.86-.04-1.3a1.6477,1.6477,0,0,0-.2-.82,1.1171,1.1171,0,0,0-1.0195-.4805,1.1,1.1,0,0,0-1.1807,1.0206,24.4634,24.4634,0,0,0-.12,2.98c0,.7.0205,1.4.04,2.1006a2.964,2.964,0,0,0,.1807,1.2207,1.0818,1.0818,0,0,0,1.06.58,1.1526,1.1526,0,0,0,1.2-1.1807c.0205-.16.08-.86.08-1.46h1.52C450.9976,667.2,450.9175,667.98,450.3579,668.64Z" />
                                            <path className="st18" d="M456.6167,669.34a2.6278,2.6278,0,0,1-.9.14,2.7078,2.7078,0,0,1-1.7-.5,3.0627,3.0627,0,0,1-.52-2.2c0-.24.02-.5.02-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6005v2.6806h1.4805v1.2h-1.4805v6.461a1.3992,1.3992,0,0,0,.18.84c.12.1807.28.32.74.32a1.74,1.74,0,0,0,.56-.08Z" />
                                            <path className="st18" d="M458.1753,657.5375v-2.0606h1.581v2.0606Zm0,11.7627v-10.002h1.581V669.3Z" />
                                            <path className="st18" d="M467.4761,666.32a6.135,6.135,0,0,1-.1,1.12c-.16.9395-.66,2.04-2.7207,2.04a2.5816,2.5816,0,0,1-2.36-1.1006,4.5776,4.5776,0,0,1-.4209-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4341,3.4341,0,0,1,.7207-2.34,2.7607,2.7607,0,0,1,2.1406-.7207c2.7,0,2.7207,2.2607,2.74,3.081.0205.74.0205,1.46,0,2.2C467.5161,665.0394,467.5161,665.6791,467.4761,666.32Zm-1.6407-4.1416c-.0195-.72-.06-1.78-1.08-1.76a1.0856,1.0856,0,0,0-1.1.9395,15.1415,15.1415,0,0,0-.1006,2.4814c0,.8.02,1.62.02,2.42a4.8275,4.8275,0,0,0,.1,1.2607.9526.9526,0,0,0,.9805.7c.7,0,.9795-.4,1.1-1.041a22.7944,22.7944,0,0,0,.1006-2.8C465.856,663.639,465.856,662.9183,465.8354,662.1781Z" />
                                            <path className="st18" d="M473.7563,669.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3571,4.3571,0,0,0-.14,1.62V669.3h-1.6005v-10.002h1.5205v.92c.12-.1406.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1979,4.1979,0,0,1,.34,2.28V669.3Z" />
                                            <path className="st18" d="M487.2153,668.64a2.7509,2.7509,0,0,1-2.28.84,2.4014,2.4014,0,0,1-2.4-1.22,9.668,9.668,0,0,1-.36-3.5215c0-.92.02-1.84.08-2.7607a3.7851,3.7851,0,0,1,.78-2.2,2.8,2.8,0,0,1,2.041-.66,2.5212,2.5212,0,0,1,2.34,1c.4209.6806.44,1.32.44,2.8007h-1.5206c0-.42,0-.86-.04-1.3a1.6477,1.6477,0,0,0-.2-.82,1.1171,1.1171,0,0,0-1.0195-.4805,1.1,1.1,0,0,0-1.1807,1.0206,24.4634,24.4634,0,0,0-.12,2.98c0,.7.02,1.4.04,2.1006a2.964,2.964,0,0,0,.1807,1.2207,1.0817,1.0817,0,0,0,1.06.58,1.1526,1.1526,0,0,0,1.2-1.1807c.02-.16.08-.86.08-1.46h1.5206C487.856,667.2,487.7759,667.98,487.2153,668.64Z" />
                                            <path className="st18" d="M495.3755,666.32a6.1258,6.1258,0,0,1-.1,1.12c-.16.9395-.66,2.04-2.7207,2.04a2.5816,2.5816,0,0,1-2.36-1.1006,4.5776,4.5776,0,0,1-.4209-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4341,3.4341,0,0,1,.7207-2.34,2.7607,2.7607,0,0,1,2.1406-.7207c2.7,0,2.7207,2.2607,2.74,3.081.02.74.02,1.46,0,2.2C495.4155,665.0394,495.4155,665.6791,495.3755,666.32Zm-1.6406-4.1416c-.02-.72-.06-1.78-1.08-1.76a1.0856,1.0856,0,0,0-1.1.9395,15.1415,15.1415,0,0,0-.1006,2.4814c0,.8.02,1.62.02,2.42a4.8275,4.8275,0,0,0,.1,1.2607.9527.9527,0,0,0,.9805.7c.7,0,.98-.4,1.1-1.041a22.7736,22.7736,0,0,0,.1006-2.8C493.7554,663.639,493.7554,662.9183,493.7349,662.1781Z" />
                                            <path className="st18" d="M501.6558,669.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3576,4.3576,0,0,0-.14,1.62V669.3h-1.6006v-10.002h1.5205v.92c.12-.1406.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8984,1.8984,0,0,1,1.7207.86,4.1979,4.1979,0,0,1,.34,2.28V669.3Z" />
                                            <path className="st18" d="M509.0952,669.34a2.6278,2.6278,0,0,1-.9.14,2.7078,2.7078,0,0,1-1.7-.5,3.0632,3.0632,0,0,1-.52-2.2c0-.24.02-.5.02-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6006v2.6806h1.48v1.2h-1.48v6.461a1.4,1.4,0,0,0,.18.84c.12.1807.28.32.74.32a1.74,1.74,0,0,0,.56-.08Z" />
                                            <path className="st18" d="M513.7544,660.8978c-1.66.4405-1.46,2.36-1.46,3.6211V669.3h-1.6406v-10.002h1.541v1.88a6.2381,6.2381,0,0,1,.46-.9,2.4754,2.4754,0,0,1,1.9805-1.16h.26v1.6806A4.4261,4.4261,0,0,0,513.7544,660.8978Z" />
                                            <path className="st18" d="M521.6157,666.32a6.1167,6.1167,0,0,1-.1,1.12c-.16.9395-.66,2.04-2.7207,2.04a2.5814,2.5814,0,0,1-2.36-1.1006,4.5776,4.5776,0,0,1-.4209-2.3v-1.3008c0-.88,0-1.74.04-2.6006a3.4341,3.4341,0,0,1,.7207-2.34,2.7607,2.7607,0,0,1,2.1406-.7207c2.7,0,2.7207,2.2607,2.74,3.081.02.74.02,1.46,0,2.2C521.6558,665.0394,521.6558,665.6791,521.6157,666.32Zm-1.6406-4.1416c-.0195-.72-.06-1.78-1.08-1.76a1.0858,1.0858,0,0,0-1.1.9395,15.1415,15.1415,0,0,0-.1006,2.4814c0,.8.02,1.62.02,2.42a4.832,4.832,0,0,0,.1,1.2607.9527.9527,0,0,0,.9805.7c.7,0,.9805-.4,1.1-1.041a22.7736,22.7736,0,0,0,.1006-2.8C519.9956,663.639,519.9956,662.9183,519.9751,662.1781Z" />
                                            <path className="st18" d="M523.7544,669.3V655.1771h1.62V669.3Z" />
                                            <path className="st18" d="M527.6138,669.3V655.1771h1.62V669.3Z" />
                                            <path className="st18" d="M531.4937,657.5375v-2.0606h1.581v2.0606Zm0,11.7627v-10.002h1.581V669.3Z" />
                                            <path className="st18" d="M539.4741,669.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2362,1.2362,0,0,0-1.2011.84,4.3571,4.3571,0,0,0-.14,1.62V669.3H535.353v-10.002h1.5205v.92c.12-.1406.22-.28.34-.4a2.6834,2.6834,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1981,4.1981,0,0,1,.34,2.28V669.3Z" />
                                            <path className="st18" d="M547.9546,672.2006a3.8211,3.8211,0,0,1-1.94.46,2.88,2.88,0,0,1-1.94-.64,1.9878,1.9878,0,0,1-.58-.7608,2.8362,2.8362,0,0,1-.2-1.16h1.48a1.9,1.9,0,0,0,.12.7,1.3182,1.3182,0,0,0,1.2.58,1.6687,1.6687,0,0,0,.8809-.22c.6-.4.54-1.16.54-1.74v-1.16l-.1806.2393a2.3012,2.3012,0,0,1-1.84.86,2.1384,2.1384,0,0,1-1.78-.88,4.9126,4.9126,0,0,1-.501-2.7207c0-.66-.02-1.32-.02-1.9805,0-.36,0-.72.02-1.08a5.2751,5.2751,0,0,1,.501-2.581,2.1858,2.1858,0,0,1,1.9794-1,2.09,2.09,0,0,1,1.9405,1.06v-.88h1.48V668.8c0,.3593.0206.72,0,1.08A2.3486,2.3486,0,0,1,547.9546,672.2006Zm-.3994-7.7813c0-.6406.0195-1.28-.04-1.94a4.8926,4.8926,0,0,0-.1406-1.32,1.2538,1.2538,0,0,0-1.92-.7c-.58.4394-.62,1.42-.62,2.86v1.5606c0,.62,0,1.24.04,1.88a1.4718,1.4718,0,0,0,.48,1.1406,1.3279,1.3279,0,0,0,1.9805-.5606,6.679,6.679,0,0,0,.2207-2.36Z" />
                                        </g>
                                        <g>
                                            <path className="st18" d="M414.1577,687.8392a8.4227,8.4227,0,0,1-.08,1.2207,2.522,2.522,0,0,1-1.66,2.2,4.7266,4.7266,0,0,1-1.5606.24,3.2853,3.2853,0,0,1-2.14-.62,3.4774,3.4774,0,0,1-.66-.7207c-.54-.82-.6-1.86-.6-3.82v-2.2607c0-.8,0-1.58.02-2.3809a6.411,6.411,0,0,1,.74-3.58,3.481,3.481,0,0,1,2.7808-1.1612,3.1846,3.1846,0,0,1,2.36.9c.5405.5606.74,1.28.74,2.8408,0,.3,0,.62-.02.92h-1.62c0-.5,0-1.02-.02-1.54a1.499,1.499,0,0,0-.54-1.36,1.4378,1.4378,0,0,0-.9-.28,1.498,1.498,0,0,0-1.6,1.24,29.0552,29.0552,0,0,0-.2,4.6416c0,1.06.02,2.1006.06,3.16a4.1431,4.1431,0,0,0,.28,1.8408,1.61,1.61,0,0,0,1.42.7,1.4491,1.4491,0,0,0,.94-.3008c.66-.5.64-1.62.64-2.34v-1.04h1.64C414.1777,686.8392,414.1777,687.3392,414.1577,687.8392Z" />
                                            <path className="st18" d="M416.2964,691.3V677.1771h1.62V691.3Z" />
                                            <path className="st18" d="M420.1763,679.5375v-2.0606h1.58v2.0606Zm0,11.7627v-10.002h1.58V691.3Z" />
                                            <path className="st18" d="M428.937,690.58a2.5591,2.5591,0,0,1-2.2.9,2.5082,2.5082,0,0,1-2.501-1.34c-.38-.74-.32-1.3408-.34-2.8808-.0205-.62-.0205-1.24-.0205-1.86a16.6243,16.6243,0,0,1,.08-1.7207,2.5893,2.5893,0,0,1,.86-1.9805,2.7725,2.7725,0,0,1,1.8408-.5595,2.8489,2.8489,0,0,1,1.92.54,1.9948,1.9948,0,0,1,.6005.7,6.4068,6.4068,0,0,1,.34,2.74v1.4805h-4.0205c-.0205.58-.0205,1.1807,0,1.7607a4.2568,4.2568,0,0,0,.1.94,1.1181,1.1181,0,0,0,1.2.8809,1.0432,1.0432,0,0,0,.74-.2207c.4-.3.5-.8.4805-1.76h1.5A3.2608,3.2608,0,0,1,428.937,690.58Zm-.92-5.6807c0-.38,0-.78-.0205-1.16-.04-.78-.3594-1.3808-1.24-1.3808a1.0592,1.0592,0,0,0-1.06.6406,4.668,4.668,0,0,0-.2,1.9v.42h2.5205Z" />
                                            <path className="st18" d="M435.7563,691.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3571,4.3571,0,0,0-.14,1.62V691.3h-1.6005v-10.002h1.5205v.92c.12-.1406.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1979,4.1979,0,0,1,.34,2.28V691.3Z" />
                                            <path className="st18" d="M443.1968,691.34a2.6278,2.6278,0,0,1-.9.14,2.7077,2.7077,0,0,1-1.7-.5,3.0627,3.0627,0,0,1-.5205-2.2c0-.24.0205-.5.0205-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6006v2.6806h1.4805v1.2h-1.4805v6.461a1.3992,1.3992,0,0,0,.18.84c.12.1807.28.32.74.32a1.74,1.74,0,0,0,.5606-.08Z" />
                                            <path className="st18" d="M457.7173,691.3v-6.5616a4.653,4.653,0,0,0-.16-1.7,1.2283,1.2283,0,0,0-1.8808-.4c-.56.5-.48,1.46-.48,2.1005V691.3h-1.62v-6.5616a4.0524,4.0524,0,0,0-.2-1.8408,1.3132,1.3132,0,0,0-1.9209-.22c-.3994.42-.42,1.0205-.42,2.1006V691.3h-1.6005v-10.002h1.5009v.98a4.5627,4.5627,0,0,1,.44-.5195,2.4273,2.4273,0,0,1,1.7-.6406,2.0972,2.0972,0,0,1,1.7207.78,2.8679,2.8679,0,0,1,.26.38,4.2029,4.2029,0,0,1,.4209-.5,2.2448,2.2448,0,0,1,1.64-.66,2.2839,2.2839,0,0,1,1.2607.36,2.7427,2.7427,0,0,1,.98,2.52V691.3Z" />
                                            <path className="st18" d="M465.436,691.3v-1.2a2.123,2.123,0,0,1-2.081,1.38,2.2964,2.2964,0,0,1-1.2-.32,2.368,2.368,0,0,1-1.04-2.28,2.7261,2.7261,0,0,1,.8008-2.12,14.0574,14.0574,0,0,1,1.96-1.4209,10.6142,10.6142,0,0,0,1.46-1.2v-.34c0-.36,0-1.4-1.22-1.4-1.28,0-1.2,1.0205-1.2,1.4209,0,.26.02.5.04.76h-1.461c0-.28,0-.58.0205-.86a2.406,2.406,0,0,1,.5-1.76,2.7368,2.7368,0,0,1,2.2608-.8408,2.59,2.59,0,0,1,2.0205.7207,2.3505,2.3505,0,0,1,.5.84,6.1428,6.1428,0,0,1,.12,1.7v4.8008a4.8681,4.8681,0,0,0,.18,1.7812,1.3654,1.3654,0,0,0,.3.34Zm-.1006-5.7012c-.18.18-.38.3594-.56.5195-.16.12-.32.24-.5.36a6.134,6.134,0,0,0-1.04.88,2.21,2.21,0,0,0-.48,1.4609c0,.16-.0606,1.5,1.1006,1.4805a1.3634,1.3634,0,0,0,1.1-.5811,2.6572,2.6572,0,0,0,.38-1.66Z" />
                                            <path className="st18" d="M473.3354,691.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3571,4.3571,0,0,0-.14,1.62V691.3h-1.6005v-10.002h1.52v.92c.12-.1406.22-.28.34-.4a2.6836,2.6836,0,0,1,1.82-.7,1.8985,1.8985,0,0,1,1.7207.86,4.1979,4.1979,0,0,1,.34,2.28V691.3Z" />
                                            <path className="st18" d="M481.0562,691.3v-1.2a2.1222,2.1222,0,0,1-2.0811,1.38,2.2985,2.2985,0,0,1-1.2-.32,2.3679,2.3679,0,0,1-1.04-2.28,2.726,2.726,0,0,1,.8007-2.12,14.0574,14.0574,0,0,1,1.96-1.4209,10.6232,10.6232,0,0,0,1.46-1.2v-.34c0-.36,0-1.4-1.22-1.4-1.28,0-1.2,1.0205-1.2,1.4209,0,.26.02.5.04.76h-1.461c0-.28,0-.58.0206-.86a2.4051,2.4051,0,0,1,.5-1.76,2.7365,2.7365,0,0,1,2.2607-.8408,2.59,2.59,0,0,1,2.02.7207,2.349,2.349,0,0,1,.5.84,6.1428,6.1428,0,0,1,.12,1.7v4.8008a4.87,4.87,0,0,0,.18,1.7812,1.3654,1.3654,0,0,0,.3.34Zm-.1006-5.7012c-.18.18-.38.3594-.56.5195-.16.12-.32.24-.5.36a6.128,6.128,0,0,0-1.04.88,2.21,2.21,0,0,0-.4805,1.4609c0,.16-.0606,1.5,1.1006,1.4805a1.3634,1.3634,0,0,0,1.1-.5811,2.6573,2.6573,0,0,0,.38-1.66Z" />
                                            <path className="st18" d="M489.4155,694.2006a3.8211,3.8211,0,0,1-1.94.46,2.88,2.88,0,0,1-1.94-.64,1.9878,1.9878,0,0,1-.58-.7608,2.8362,2.8362,0,0,1-.2-1.16h1.4805a1.9,1.9,0,0,0,.12.7,1.318,1.318,0,0,0,1.2.58,1.6686,1.6686,0,0,0,.8808-.22c.6-.4.54-1.16.54-1.74v-1.16l-.1807.2393a2.3009,2.3009,0,0,1-1.84.86,2.1386,2.1386,0,0,1-1.78-.88,4.9135,4.9135,0,0,1-.501-2.7207c0-.66-.0195-1.32-.0195-1.9805,0-.36,0-.72.0195-1.08a5.2762,5.2762,0,0,1,.501-2.581,2.186,2.186,0,0,1,1.98-1,2.09,2.09,0,0,1,1.94,1.06v-.88h1.4805V690.8c0,.3593.0205.72,0,1.08A2.3488,2.3488,0,0,1,489.4155,694.2006Zm-.3994-7.7813c0-.6406.0195-1.28-.04-1.94a4.8942,4.8942,0,0,0-.1407-1.32,1.2538,1.2538,0,0,0-1.92-.7c-.58.4394-.62,1.42-.62,2.86v1.5606c0,.62,0,1.24.04,1.88a1.4722,1.4722,0,0,0,.48,1.1406,1.3279,1.3279,0,0,0,1.98-.5606,6.6767,6.6767,0,0,0,.2207-2.36Z" />
                                            <path className="st18" d="M497.7554,690.58a2.5594,2.5594,0,0,1-2.2.9,2.5082,2.5082,0,0,1-2.501-1.34c-.38-.74-.32-1.3408-.34-2.8808-.0206-.62-.0206-1.24-.0206-1.86a16.6522,16.6522,0,0,1,.08-1.7207,2.5893,2.5893,0,0,1,.86-1.9805,2.7723,2.7723,0,0,1,1.8408-.5595,2.8488,2.8488,0,0,1,1.92.54,1.9959,1.9959,0,0,1,.6006.7,6.4083,6.4083,0,0,1,.34,2.74v1.4805h-4.02c-.0205.58-.0205,1.1807,0,1.7607a4.2615,4.2615,0,0,0,.1.94,1.118,1.118,0,0,0,1.2.8809,1.0433,1.0433,0,0,0,.74-.2207c.4-.3.5-.8.48-1.76h1.5A3.26,3.26,0,0,1,497.7554,690.58Zm-.92-5.6807c0-.38,0-.78-.02-1.16-.04-.78-.3593-1.3808-1.24-1.3808a1.0594,1.0594,0,0,0-1.06.6406,4.668,4.668,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M508.7368,691.3v-6.5616a4.6558,4.6558,0,0,0-.16-1.7,1.2284,1.2284,0,0,0-1.8809-.4c-.56.5-.48,1.46-.48,2.1005V691.3h-1.62v-6.5616a4.0524,4.0524,0,0,0-.2-1.8408,1.3132,1.3132,0,0,0-1.9209-.22c-.3994.42-.42,1.0205-.42,2.1006V691.3h-1.6006v-10.002h1.501v.98a4.5476,4.5476,0,0,1,.4394-.5195,2.4275,2.4275,0,0,1,1.7-.6406,2.0971,2.0971,0,0,1,1.7207.78,2.8679,2.8679,0,0,1,.26.38,4.2029,4.2029,0,0,1,.4209-.5,2.2448,2.2448,0,0,1,1.64-.66,2.2842,2.2842,0,0,1,1.2608.36,2.7427,2.7427,0,0,1,.98,2.52V691.3Z" />
                                            <path className="st18" d="M517.5356,690.58a2.5591,2.5591,0,0,1-2.2.9,2.5081,2.5081,0,0,1-2.5009-1.34c-.38-.74-.32-1.3408-.34-2.8808-.02-.62-.02-1.24-.02-1.86a16.6243,16.6243,0,0,1,.08-1.7207,2.5889,2.5889,0,0,1,.86-1.9805,2.7735,2.7735,0,0,1,1.8408-.5595,2.8488,2.8488,0,0,1,1.92.54,1.9959,1.9959,0,0,1,.6006.7,6.4083,6.4083,0,0,1,.34,2.74v1.4805h-4.02c-.02.58-.02,1.1807,0,1.7607a4.2631,4.2631,0,0,0,.1.94,1.1182,1.1182,0,0,0,1.2.8809,1.0434,1.0434,0,0,0,.74-.2207c.4-.3.5-.8.48-1.76h1.5A3.2608,3.2608,0,0,1,517.5356,690.58Zm-.92-5.6807c0-.38,0-.78-.02-1.16-.04-.78-.36-1.3808-1.24-1.3808a1.0593,1.0593,0,0,0-1.06.6406,4.668,4.668,0,0,0-.2,1.9v.42h2.52Z" />
                                            <path className="st18" d="M524.355,691.3v-7.3223c0-.2393.08-1.6-1.18-1.6a1.2364,1.2364,0,0,0-1.2012.84,4.3576,4.3576,0,0,0-.14,1.62V691.3h-1.6006v-10.002h1.5205v.92c.12-.1406.22-.28.34-.4a2.6837,2.6837,0,0,1,1.82-.7,1.8986,1.8986,0,0,1,1.7207.86,4.1981,4.1981,0,0,1,.34,2.28V691.3Z" />
                                            <path className="st18" d="M531.7944,691.34a2.6278,2.6278,0,0,1-.9.14,2.7078,2.7078,0,0,1-1.7-.5,3.0632,3.0632,0,0,1-.52-2.2c0-.24.02-.5.02-.74v-5.541h-1.2v-1.2h1.24v-2.6806h1.6006v2.6806h1.48v1.2h-1.48v6.461a1.3992,1.3992,0,0,0,.18.84c.12.1807.28.32.74.32a1.7394,1.7394,0,0,0,.56-.08Z" />
                                        </g>
                                    </g>
                                    <g>
                                        <path className="st18" d="M706.8335,471.2006l-.34-2.3008h-2.4l-.38,2.3008h-2.6006l2.74-14.1231h2.981l2.6406,14.1231Zm-1.4805-10.543-.94,6.0615h1.8Z" />
                                        <path className="st18" d="M715.6333,471.2006v-7.542c0-.64-.04-.9-.36-1.1a.7451.7451,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819h-2.38V460.9584h2.42l-.02.74a2.6329,2.6329,0,0,1,2.0405-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M724.853,471.2006v-7.542c0-.64-.04-.9-.36-1.1a.7456.7456,0,0,0-.4605-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3819h-2.38V460.9584h2.42l-.02.74a2.6329,2.6329,0,0,1,2.0405-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M734.0327,471.2006V470.36a3.2378,3.2378,0,0,1-2.18,1.0205,2.2538,2.2538,0,0,1-1.2006-.36,2.5147,2.5147,0,0,1-.8-2.34v-7.7217h2.4V468.32a1.2713,1.2713,0,0,0,.22.9805.8756.8756,0,0,0,.64.26,1.0237,1.0237,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2412h2.4v10.2422Z" />
                                        <path className="st18" d="M743.1924,471.2006c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2533,2.2533,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.1964,3.1964,0,0,1,1.0806-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.9493.9493,0,0,0-.52-.1406.7761.7761,0,0,0-.74.4,2.9492,2.9492,0,0,0-.08.96v.38h-2.18v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.7607c.3,0,2.08-.02,2.8,1.28a1.8767,1.8767,0,0,1,.24.9805V468.7a5.0663,5.0663,0,0,0,.3,2.32c.0195.06.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2829,1.2829,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M747.8721,471.2006V457.0775h2.4v14.1231Z" />
                                        <path className="st18" d="M763.9326,471.2006V470.36a4.2212,4.2212,0,0,1-.56.54,3.22,3.22,0,0,1-1.9605.6006,2.8177,2.8177,0,0,1-2.8408-1.6407,3.2986,3.2986,0,0,1-.2-1.24v-8.041a5.4282,5.4282,0,0,1,.26-1.8809c.58-1.5605,2.3208-1.92,3.7608-1.92,1.1406,0,2.6807.2393,3.3808,1.3a2.6646,2.6646,0,0,1,.38,1.5v2.0606h-2.6v-1.36c0-.4795.04-.96-.44-1.26a1.63,1.63,0,0,0-1.6.02c-.4.28-.5.7207-.5,1.6006V468.1a2.0324,2.0324,0,0,0,.1.74,1.044,1.044,0,0,0,1.1206.64,1.2564,1.2564,0,0,0,1.02-.36,1.7337,1.7337,0,0,0,.3-1.26v-2.2h-1.6006v-2.121h4.2007v7.6621Z" />
                                        <path className="st18" d="M774.5322,470.36a3.41,3.41,0,0,1-2.7207,1.0411,4.8315,4.8315,0,0,1-1.0195-.1006,2.4961,2.4961,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1719,3.1719,0,0,1,.2-1.3008,2.9836,2.9836,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9666,2.9666,0,0,1,.2393,1.36V468.56A2.4482,2.4482,0,0,1,774.5322,470.36Zm-1.78-6.8808c0-.6406-.06-.92-.38-1.12a.728.728,0,0,0-.4405-.12.7538.7538,0,0,0-.48.14c-.38.28-.36.7-.36,1.12V468.7c0,.1806-.0195,1.0605.82,1.0605.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M781.752,471.2006c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0924,2.0924,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9787,2.9787,0,0,1,.4805-2.1006,3.2042,3.2042,0,0,1,1.08-.7608l2.48-1.24v-.6c0-.6006-.0595-.9-.3193-1.08a.9542.9542,0,0,0-.52-.1406.776.776,0,0,0-.74.4,2.9492,2.9492,0,0,0-.08.96v.38h-2.1807v-1.16a2.0462,2.0462,0,0,1,.58-1.46,3.6492,3.6492,0,0,1,2.5811-.7607c.3,0,2.08-.02,2.8007,1.28a1.8761,1.8761,0,0,1,.24.9805V468.7a5.0647,5.0647,0,0,0,.3,2.32c.0195.06.0595.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.1807.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2856,1.2856,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M786.4307,471.2006V457.0775h2.4v14.1231Z" />
                                        <path className="st18" d="M796.8721,470.48a3.0712,3.0712,0,0,1-2.62.9209c-1.1407,0-2.4-.2207-2.9014-1.4209a3.5806,3.5806,0,0,1-.22-1.32V467.6h2.2v.8a2.4344,2.4344,0,0,0,.0605.64.7976.7976,0,0,0,.86.66.9669.9669,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2472,1.2472,0,0,0-.7-1.32l-2-1.42a2.5271,2.5271,0,0,1-1.2207-2.4609c.0205-2.04,1.2607-2.62,3.1016-2.62,1.5,0,2.32.5205,2.64,1.1006a2.2976,2.2976,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.2208.8008.6807,1.1211l2.2,1.54a3.6088,3.6088,0,0,1,.62.62,2.72,2.72,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,796.8721,470.48Z" />
                                    </g>

                                </>}
                                {SelectedLanguage === "de-DE" && <>
                                    <g>
                                        <path className="st18" d="M117.6,525.1l0-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8
			l0.5,0.7l0.3,0.9l0.1,2.6h-2.8V524l-0.1-1l-0.1-0.6l-0.3-0.3l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5
			l0.3,0.2l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6
			l-0.5-0.8l-0.4-1.4l-0.1-2.2L117.6,525.1z"/>
                                        <path className="st18" d="M134.9,530.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L134.9,530.6z
			 M130.1,529.4l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L130.1,529.4z"/>
                                        <path className="st18" d="M141.3,533.9V528l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H141.3z"/>
                                        <path className="st18" d="M145.3,525.4v-1.9h1V521h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H145.3z"/>
                                        <path className="st18" d="M151.7,533.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H151.7z"/>
                                        <path className="st18" d="M165.6,530.6l-0.3,1.6L165,533l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L165.6,530.6z M160.9,529.4
			l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6
			L160.9,529.4z"/>
                                        <path className="st18" d="M167.5,533.9v-14.1h2.7v14.1H167.5z" />
                                        <path className="st18" d="M172,533.9v-14.1h2.7v14.1H172z" />
                                        <path className="st18" d="M176.6,522.5v-2.7h2.6v2.7H176.6z M176.6,533.9v-10.3h2.6v10.3H176.6z" />
                                        <path className="st18" d="M185.7,533.9V528l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H185.7z"/>
                                        <path className="st18" d="M197.6,534l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2L191,533l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L197.6,534z M192.9,528.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L192.9,528.6z"/>
                                        <path className="st18" d="M209.5,533.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H209.5z"/>
                                        <path className="st18" d="M218.6,533.9V528l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H218.6z"/>
                                        <path className="st18" d="M227.9,534l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L227.9,534z M227.7,527.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L227.7,527.3z"/>
                                        <path className="st18" d="M119.7,541.8h4.3l0.6,0l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2l0.8,0.3
			l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5l-0.6,0.3l-1.3,0.1h-4.6V541.8z M123.2,547.6l0.8-0.1l0.3-0.2l0.3-0.3l0.1-1.4
			l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4H123.2z M123.4,553.6l0.8-0.1l0.4-0.3l0.2-0.8l0-0.8l-0.3-1.2l-0.5-0.3l-0.8-0.1h-0.9v3.7
			H123.4z"/>
                                        <path className="st18" d="M136.8,551.4H132l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V551.4z M134.2,548.8L134,548l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V548.8z"/>
                                        <path className="st18" d="M138.6,555.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H138.6z"/>
                                        <path className="st18" d="M145.2,544.5v-2.7h2.6v2.7H145.2z M145.2,555.9v-10.3h2.6v10.3H145.2z" />
                                        <path className="st18" d="M149.7,550.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V549l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L149.7,550.1z"/>
                                        <path className="st18" d="M163.1,555.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H163.1z"/>
                                        <path className="st18" d="M167.1,547.4v-1.9h1V543h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H167.1z"/>
                                        <path className="st18" d="M177.5,548.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H177.5z"/>
                                        <path className="st18" d="M188.5,555.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H188.5z"/>
                                        <path className="st18" d="M202.1,551.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V551.4z M199.5,548.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V548.8z"/>
                                        <path className="st18" d="M207.8,548.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H207.8z"/>
                                        <path className="st18" d="M219.6,551.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V551.4z M216.9,548.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V548.8z"/>
                                        <path className="st18" d="M225.9,555.9V550l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L224,550v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H225.9z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M412.4,587.6l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6l-1,0l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1
			l-0.1-0.7l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6
			L412.4,587.6z M409.4,582.4l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9l0,2.1l0,3.2l0,1.1l0.2,1l0.5,0.5
			l0.5,0.1l0.6-0.2l0.4-0.5l0.1-1.1l0-1.7V582.4z"/>
                                        <path className="st18" d="M414.4,595.7v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L414.4,595.7z M417,587.3l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V587.3z"/>
                                        <path className="st18" d="M430.9,587.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V587.4z M428.3,584.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V584.8z"/>
                                        <path className="st18" d="M432.6,592l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H432.6z"/>
                                        <path className="st18" d="M443.8,592l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2L442,584l0,0.8h-2.5l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H443.8z M442.5,587.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L442.5,587.6z"/>
                                        <path className="st18" d="M447.4,583.5v-1.9h1V579h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H447.4z"/>
                                        <path className="st18" d="M453.9,580.5v-2.7h2.6v2.7H453.9z M453.9,592v-10.3h2.6V592H453.9z" />
                                        <path className="st18" d="M460.1,592l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7L463,592H460.1z" />
                                        <path className="st18" d="M474.2,587.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V587.4z M471.5,584.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V584.8z"/>
                                        <path className="st18" d="M486.2,581.9l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4L484,581l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L486.2,581.9z"/>
                                        <path className="st18" d="M490.2,583.5v-1.9h1V579h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H490.2z"/>
                                        <path className="st18" d="M504.1,587.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V587.4z M501.4,584.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V584.8z"/>
                                        <path className="st18" d="M510.5,592v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V592H510.5z"/>
                                        <path className="st18" d="M522.4,587.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V587.4z M519.7,584.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V584.8z"/>
                                        <path className="st18" d="M524.1,592l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H524.1z"/>
                                        <path className="st18" d="M535.4,592v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V592H535.4z"/>
                                        <path className="st18" d="M544.5,592V586l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H544.5z"/>
                                        <path className="st18" d="M556.3,592l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L556.3,592z M551.7,586.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L551.7,586.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M668.6,802.2l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L668.6,802.2z"/>
                                        <path className="st18" d="M678,812.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V802h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V802h2.7v10.3H678z"/>
                                        <path className="st18" d="M691.6,812.3v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6V802h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L691.6,812.3z"/>
                                        <path className="st18" d="M705.4,812.3v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6V802h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L705.4,812.3z"/>
                                        <path className="st18" d="M717.3,807.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V807.7z M714.7,805.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V805.2z"/>
                                        <path className="st18" d="M729.3,812.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V806
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L729.3,812.3z M729.1,805.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L729.1,805.7z"/>
                                        <path className="st18" d="M741,807.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V807.7z M738.4,805.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V805.2z"/>
                                        <path className="st18" d="M742.8,812.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H742.8z"/>
                                        <path className="st18" d="M755.1,812.3v-14.1h2.8v11.6h4.1v2.5H755.1z" />
                                        <path className="st18" d="M771,807.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V807.7z M768.3,805.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V805.2z"/>
                                        <path className="st18" d="M772.7,800.9v-2.7h2.6v2.7H772.7z M772.7,812.3V802h2.6v10.3H772.7z" />
                                        <path className="st18" d="M781.3,804.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H781.3z"/>
                                        <path className="st18" d="M785,803.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H785z"/>
                                        <path className="st18" d="M796,812.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V802h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V802h2.7v10.3H796z"/>
                                        <path className="st18" d="M805.1,812.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V802l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H805.1z"/>
                                        <path className="st18" d="M817,812.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8L814,811l-0.7,0.7l-0.6,0.3L812,812l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L817,812.3z M812.3,807l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L812.3,807z"/>
                                        <path className="st18" d="M822.8,804.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H822.8z"/>
                                        <path className="st18" d="M827,807.4v-2.1h4.6v2.1H827z" />
                                        <path className="st18" d="M660.9,834.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V824h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V824h2.7v10.3H660.9z"/>
                                        <path className="st18" d="M670,834.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V824l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H670z"/>
                                        <path className="st18" d="M679.4,834.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V828
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L679.4,834.3z M679.2,827.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L679.2,827.7z"/>
                                        <path className="st18" d="M689.4,834.3l0-14.1h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H689.4z" />
                                        <path className="st18" d="M702.1,834.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V824h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V824h2.7v10.3H702.1z M700.6,822.3h-2v-2.1h2V822.3z M703.7,822.3h-2v-2.1h2
			V822.3z"/>
                                        <path className="st18" d="M711.2,834.3V828l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H711.2z"/>
                                        <path className="st18" d="M715.7,834.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H715.7z"/>
                                        <path className="st18" d="M727,834.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V824h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V824h2.7v10.3H727z"/>
                                        <path className="st18" d="M736.1,834.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V824l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H736.1z"/>
                                        <path className="st18" d="M747.9,834.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3L743,834l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L747.9,834.3z M743.3,829l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L743.3,829z"/>
                                        <path className="st18" d="M753.8,826.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H753.8z"/>
                                        <path className="st18" d="M765.5,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M762.9,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M767.3,834.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H767.3z"/>
                                        <path className="st18" d="M781.2,834.3L781,836l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7L777,834l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L781.2,834.3z M776.6,829l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L776.6,829z"/>
                                        <path className="st18" d="M790.5,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M787.8,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M792.2,834.3l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H792.2z M796.8,827.7
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L796.8,827.7z"/>
                                        <path className="st18" d="M805.9,834.3v-5.9l-0.2-1.9l-0.3-0.3L805,826l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V824l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H805.9z"/>
                                        <path className="st18" d="M810.5,822.9v-2.7h2.6v2.7H810.5z M810.5,834.3V824h2.6v10.3H810.5z" />
                                        <path className="st18" d="M819,826.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5L815,833l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H819z"/>
                                        <path className="st18" d="M827.4,826.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H827.4z"/>
                                        <path className="st18" d="M839.1,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M836.4,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1260.8,513.1l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1260.8z M1259.2,503.5l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1259.2,503.5z"/>
                                        <path className="st18" d="M1269.7,513.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1269.7,513.1z M1269.5,506.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1269.5,506.5z"/>
                                        <path className="st18" d="M1284.1,513.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1284.1z"/>
                                        <path className="st18" d="M1295.9,509.7l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1295.9,509.7z
			 M1291.2,508.6l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1291.2,508.6z"/>
                                        <path className="st18" d="M1297.7,507.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1297.7,507.2z"/>
                                        <path className="st18" d="M1198.1,535.1v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7
			l-0.8,0.4l-0.9,0.2l-1.3,0h-0.6v5.1H1198.1z M1201.8,527.5l0.7-0.2l0.4-0.6l0.1-1l0-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1
			L1201.8,527.5z"/>
                                        <path className="st18" d="M1207.4,535.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1207.4z"/>
                                        <path className="st18" d="M1221.3,531.7l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1221.3,531.7z
			 M1216.6,530.6l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1216.6,530.6z"/>
                                        <path className="st18" d="M1225.8,535.8l-0.1,1.2l-0.4,0.9l-0.6,0.5l-0.6,0.2l-1.4,0.1l-0.9-0.1l0-2.2l0.9-0.1l0.4-0.4l0.1-0.8v-10.4
			h2.6V535.8z M1223.2,523.6v-2.7h2.6v2.7H1223.2z"/>
                                        <path className="st18" d="M1235.1,530.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V530.5z M1232.5,527.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V527.9z"/>
                                        <path className="st18" d="M1242,535.1l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1242z" />
                                        <path className="st18" d="M1245.4,526.5v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1245.4z"/>
                                        <path className="st18" d="M1259.2,530.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V530.5z M1256.6,527.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V527.9z"/>
                                        <path className="st18" d="M1271,535.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1271z"/>
                                        <path className="st18" d="M1280.1,535.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1280.1z"/>
                                        <path className="st18" d="M1289.4,535.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1289.4,535.1z M1289.2,528.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1289.2,528.5z"/>
                                        <path className="st18" d="M1305.1,535.1l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1305.1z M1303.5,525.4l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1303.5,525.4z"/>
                                        <path className="st18" d="M1313.8,535.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1313.8z"/>
                                        <path className="st18" d="M1318.7,535.1v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1318.7z"/>
                                        <path className="st18" d="M1323.5,526.5v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1323.5z"/>
                                        <path className="st18" d="M1330,535.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1330z"/>
                                        <path className="st18" d="M1341.1,535.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1341.1z M1339.7,523h-2v-2.1h2V523z M1339.8,530.7
			l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1339.8,530.7z M1342.8,523h-2v-2.1h2V523z"/>
                                        <path className="st18" d="M1352.6,535.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1352.6,535.1z M1348,529.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1348,529.7z"/>
                                        <path className="st18" d="M1361.9,530.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V530.5z M1359.3,527.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V527.9z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M967.8,394.1l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H967.8z M966.2,384.5l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L966.2,384.5z"/>
                                        <path className="st18" d="M976.5,394.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H976.5z"/>
                                        <path className="st18" d="M981.4,394.1v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H981.4z"/>
                                        <path className="st18" d="M994.1,394.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L994.1,394.1z M989.5,388.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L989.5,388.7z"/>
                                        <path className="st18" d="M1000.5,394.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1000.5z M999.2,389.7l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L999.2,389.7z"/>
                                        <path className="st18" d="M1004.8,394.1l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1004.8z M1009.4,387.4
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1009.4,387.4z"/>
                                        <path className="st18" d="M1021.3,389.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V389.5z M1018.6,387l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V387z"/>
                                        <path className="st18" d="M1027.6,394.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1027.6z"/>
                                        <path className="st18" d="M1042.2,394.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1042.2z"/>
                                        <path className="st18" d="M1051.3,394.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1051.3z"/>
                                        <path className="st18" d="M1060.6,394.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1V380h2.6v14.1L1060.6,394.1z M1060.4,387.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1060.4,387.5z"/>
                                        <path className="st18" d="M932.1,406l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L932.1,406z"/>
                                        <path className="st18" d="M936.7,410.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8L942,416l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L936.7,410.2z"/>
                                        <path className="st18" d="M950.1,416.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H950.1z"/>
                                        <path className="st18" d="M954.6,416.1V402h2.7v14.1H954.6z" />
                                        <path className="st18" d="M963.9,416.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H963.9z M962.4,404.1h-2V402h2V404.1z M965.4,404.1h-2V402h2
			V404.1z"/>
                                        <path className="st18" d="M972.4,408.7l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H972.4z"/>
                                        <path className="st18" d="M980.7,408.7l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H980.7z"/>
                                        <path className="st18" d="M992.4,411.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V411.5z M989.8,409l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V409z"/>
                                        <path className="st18" d="M994.1,416.1V402h2.7v14.1H994.1z" />
                                        <path className="st18" d="M998.7,419.8v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L998.7,419.8z M1001.3,411.5l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V411.5z"/>
                                        <path className="st18" d="M1007.8,416.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1007.8z"/>
                                        <path className="st18" d="M1021.8,412.7l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1021.8,412.7z
			 M1017,411.6l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1017,411.6z"/>
                                        <path className="st18" d="M1023.7,416.1l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1023.7z M1028.3,409.4
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1028.3,409.4z"/>
                                        <path className="st18" d="M1032.8,416.1V402h2.7v14.1H1032.8z" />
                                        <path className="st18" d="M1044.7,411.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V411.5z M1042.1,409l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V409z"/>
                                        <path className="st18" d="M1055.6,416.1v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1055.6,416.1z"/>
                                        <path className="st18" d="M1067.6,411.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V411.5z M1065,409l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V409z"/>
                                        <path className="st18" d="M1079.5,416.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1V402h2.6v14.1L1079.5,416.1z M1079.3,409.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1079.3,409.5z"/>
                                        <path className="st18" d="M1091.3,411.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V411.5z M1088.7,409l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V409z"/>
                                        <path className="st18" d="M1093,416.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1093z"/>
                                        <path className="st18" d="M950.4,428l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4L946,437l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L950.4,428z"/>
                                        <path className="st18" d="M954.5,429.6v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H954.5z"/>
                                        <path className="st18" d="M968.3,433.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V433.5z M965.7,431l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V431z"/>
                                        <path className="st18" d="M970,438.1V424h2.7v14.1H970z" />
                                        <path className="st18" d="M974.6,438.1V424h2.7v14.1H974.6z" />
                                        <path className="st18" d="M986.6,433.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V433.5z M983.9,431l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V431z"/>
                                        <path className="st18" d="M997.7,424l-1.4,3.3l-0.5,2.2l-0.3,3.1l0.1,1.7l0.4,2.4l0.7,2.3l0.6,1.6l0.5,0.8l-1.1,0l-0.9-1.8l-1.1-2.8
			l-0.4-2l-0.1-2.3l0.2-2.7l0.5-2l0.9-2.2l1-1.8L997.7,424z"/>
                                        <path className="st18" d="M1007.3,438.1h-1.4l-1.1-4.5h-3.8l-1.1,4.5h-1.4l3.7-14.1h1.4L1007.3,438.1z M1004.5,432.4l-1.5-6.6
			l-0.6,2.5l-0.5,2l-0.5,2.1L1004.5,432.4z"/>
                                        <path className="st18" d="M1012.2,430.6l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8
			l1,0.7l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3
			l0,1.3l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7
			l0.5-0.5l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H1012.2z"/>
                                        <path className="st18" d="M1019.2,430.6l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8
			l1,0.7l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3
			l0,1.3l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7
			l0.5-0.5l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H1019.2z"/>
                                        <path className="st18" d="M1023.9,423.9v1.7h-1.3v-1.7H1023.9z M1023.9,427.7v10.4h-1.3v-10.4H1023.9z" />
                                        <path className="st18" d="M1030.6,427.7h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L1030.6,427.7z M1027.6,434
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V434z"/>
                                        <path className="st18" d="M1039.8,438.1h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2
			v0.7l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V438.1z"/>
                                        <path className="st18" d="M1051.8,438.1h-1.3v-7.5l-0.1-0.9l-0.4-0.6l-0.8-0.3l-0.9,0.3l-0.4,0.6l-0.2,1.3v7.2h-1.3v-7.7l-0.3-0.9
			l-0.4-0.5l-0.8-0.2l-0.4,0.1l-0.4,0.3l-0.4,0.5l-0.2,1.5v7h-1.3v-10.3h1.2v0.8l0.3-0.3l0.7-0.4l1-0.2l0.6,0.1l1,0.6l0.3,0.5
			l0.4-0.4l1.1-0.6l0.7-0.1l0.6,0.1l0.9,0.5l0.4,0.5l0.2,0.5l0.2,1.6V438.1z"/>
                                        <path className="st18" d="M1055.5,433.2l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7V432l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H1055.5z M1058.6,432.1l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H1058.6z"/>
                                        <path className="st18" d="M1067.6,438.1h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2
			v0.7l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V438.1z"/>
                                        <path className="st18" d="M1071.6,424.9l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H1071.6z"/>
                                        <path className="st18" d="M1078.2,430.6l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8
			l1,0.7l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3
			l0,1.3l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7
			l0.5-0.5l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H1078.2z"/>
                                        <path className="st18" d="M1080.8,441.4l0.5-0.9l0.4-1l0.9-2.6l0.3-1.6l0.2-2.4l-0.1-1.7l-0.3-1.7l-0.6-2.5l-0.6-1.6l-0.7-1.4l1.1,0
			l1.2,2.4l0.9,2.6l0.3,1.7l0.1,2.1l-0.2,2.8l-0.5,1.9l-0.9,2.2l-1,1.8H1080.8z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1214.5,301.9l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L1214.5,301.9z"/>
                                        <path className="st18" d="M1218.6,303.4v-1.9h1V299h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1218.6z"/>
                                        <path className="st18" d="M1232.4,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1229.8,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1234.2,312v-14.1h2.7V312H1234.2z" />
                                        <path className="st18" d="M1238.7,312v-14.1h2.7V312H1238.7z" />
                                        <path className="st18" d="M1250.7,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1248.1,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1257,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1257z"/>
                                        <path className="st18" d="M1261.6,312l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1261.6z M1266.2,305.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1266.2,305.3z"/>
                                        <path className="st18" d="M1278.1,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1275.5,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1286.5,312l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1286.5z"/>
                                        <path className="st18" d="M1300.2,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1297.5,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1301.9,312l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1301.9z"/>
                                        <path className="st18" d="M1308,303.4v-1.9h1V299h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1308z"/>
                                        <path className="st18" d="M1319,312v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V312H1319z"/>
                                        <path className="st18" d="M1328.1,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1328.1z"/>
                                        <path className="st18" d="M1339.9,312l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1339.9,312z M1335.3,306.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1335.3,306.6z"/>
                                        <path className="st18" d="M1225.1,334v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V334H1225.1z"/>
                                        <path className="st18" d="M1234.2,334V328l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1234.2z"/>
                                        <path className="st18" d="M1243.5,334l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V334L1243.5,334z M1243.3,327.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1243.3,327.3z"/>
                                        <path className="st18" d="M1253.6,319.8h4.3l0.6,0l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2
			l0.8,0.3l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5l-0.6,0.3l-1.3,0.1h-4.6V319.8z M1257,325.6l0.8-0.1l0.3-0.2l0.3-0.3
			l0.1-1.4l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4H1257z M1257.2,331.6l0.8-0.1l0.4-0.3l0.2-0.8l0-0.8l-0.3-1.2l-0.5-0.3l-0.8-0.1
			h-0.9v3.7H1257.2z"/>
                                        <path className="st18" d="M1270.7,329.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V329.4z M1268,326.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V326.8z"/>
                                        <path className="st18" d="M1272.1,334V332l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H1272.1z" />
                                        <path className="st18" d="M1285.6,334l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1285.6z M1284.3,329.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1284.3,329.6z"/>
                                        <path className="st18" d="M1294.5,334v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1294.5z"/>
                                        <path className="st18" d="M1299,334v-14.1h2.7V334H1299z" />
                                        <path className="st18" d="M1308.2,334v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V334H1308.2z"/>
                                        <path className="st18" d="M1317.3,334V328l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1317.3z"/>
                                        <path className="st18" d="M1329.1,334l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1329.1,334z M1324.5,328.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1324.5,328.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M954.6,292.9l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1L952,303l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L954.6,292.9z"/>
                                        <path className="st18" d="M958.7,294.4v-1.9h1V290h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H958.7z"/>
                                        <path className="st18" d="M972.5,298.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V298.4z M969.9,295.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V295.8z"/>
                                        <path className="st18" d="M974.2,303v-14.1h2.7V303H974.2z" />
                                        <path className="st18" d="M978.8,303v-14.1h2.7V303H978.8z" />
                                        <path className="st18" d="M990.8,298.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H987l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V298.4z M988.1,295.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V295.8z"/>
                                        <path className="st18" d="M997.1,303V297l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H997.1z"/>
                                        <path className="st18" d="M1008.9,303l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1008.9,303z M1004.3,297.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1004.3,297.6z"/>
                                        <path className="st18" d="M1018.2,298.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V298.4z M1015.5,295.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V295.8z"/>
                                        <path className="st18" d="M1023.9,295.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1023.9z"/>
                                        <path className="st18" d="M1027.6,294.4v-1.9h1V290h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1027.6z"/>
                                        <path className="st18" d="M1038.6,303l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1038.6z M1037.2,298.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1037.2,298.6z"/>
                                        <path className="st18" d="M1042.8,303v-14.1h2.7V303H1042.8z" />
                                        <path className="st18" d="M1046.8,294.4v-1.9h1V290h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1046.8z"/>
                                        <path className="st18" d="M1057.8,303v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V303H1057.8z"/>
                                        <path className="st18" d="M1066.9,303V297l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1066.9z"/>
                                        <path className="st18" d="M1078.7,303l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1078.7,303z M1074.1,297.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1074.1,297.6z"/>
                                        <path className="st18" d="M961,310.8l-1.4,3.3l-0.5,2.2l-0.3,3.1l0.1,1.7l0.4,2.4l0.7,2.3l0.6,1.6l0.5,0.8l-1.1,0l-0.9-1.8l-1.1-2.8
			l-0.4-2l-0.1-2.3l0.2-2.7l0.5-2l0.9-2.2l1-1.8L961,310.8z"/>
                                        <path className="st18" d="M967.7,310.8v10.9l-0.1,1l-0.3,1l-0.6,0.7l-0.7,0.4l-1.4,0.2l-0.7-0.1l-0.9-0.3l-0.5-0.4l-0.4-0.7l-0.3-1.1
			l0-1.7h1.4l0,1.2l0.2,1.1l0.4,0.5l0.8,0.2l0.9-0.2l0.3-0.3l0.3-0.4l0.1-1.2l0-1.8v-4.5l0-4.8H967.7z"/>
                                        <path className="st18" d="M975.7,322.1l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L975.7,322.1z M971.3,320.9l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L971.3,320.9z"/>
                                        <path className="st18" d="M979.3,310.8v4.5l0.7-0.7l1.2-0.3l1.1,0.2l0.6,0.5l0.4,0.6l0.2,0.7l0.1,2v3.1l-0.1,1.5l-0.3,1l-0.5,0.6
			l-0.8,0.4l-0.6,0.1l-0.7-0.1l-1-0.6l-0.4-0.4v0.8H978v-14.1H979.3z M979.2,321.7l0.2,1.5l0.5,0.6l0.4,0.2l0.5,0.1l0.8-0.3l0.3-0.4
			l0.2-0.8l0.1-1.1l0-1.2l0-2l-0.1-1.3l-0.3-0.9l-0.7-0.4l-0.4-0.1l-0.4,0.1l-0.5,0.3l-0.3,0.5l-0.2,1.1l-0.1,1.6L979.2,321.7z"/>
                                        <path className="st18" d="M998.5,320.2l-0.1,1.8l-0.3,1.1l-0.5,0.7l-0.5,0.4l-0.8,0.4l-1.5,0.3l-2.1,0l-1.7-0.1v-14.1l3.5,0l1.8,0.2
			l1.2,0.7l0.5,0.6l0.3,0.9l0.1,1v1.8V320.2z M992.5,323.7h2.1l1.1-0.1l0.7-0.5l0.4-0.8l0.2-1.1l0.1-1.1l0-2l0-2.3l-0.1-1.9
			l-0.2-0.6l-0.3-0.6l-0.6-0.4l-0.5-0.2l-0.7-0.1h-2.3V323.7z"/>
                                        <path className="st18" d="M1002.1,320.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H1002.1z M1005.3,318.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H1005.3z"/>
                                        <path className="st18" d="M1012.4,317.5l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8
			l1,0.7l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3
			l0,1.3l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7
			l0.5-0.5l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H1012.4z"/>
                                        <path className="st18" d="M1017.2,310.8v1.7h-1.3v-1.7H1017.2z M1017.2,314.6V325h-1.3v-10.4H1017.2z" />
                                        <path className="st18" d="M1023.9,314.6h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L1023.9,314.6z M1020.8,320.9
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V320.9z"/>
                                        <path className="st18" d="M1033,325h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V325z"/>
                                        <path className="st18" d="M1034.5,328.3l0.5-0.9l0.4-1l0.9-2.6l0.3-1.6l0.2-2.4l-0.1-1.7l-0.3-1.7l-0.6-2.5l-0.6-1.6l-0.7-1.4l1.1,0
			l1.2,2.4l0.9,2.6l0.3,1.7l0.1,2.1l-0.2,2.8l-0.5,1.9l-0.9,2.2l-1,1.8H1034.5z"/>
                                        <path className="st18" d="M1050.6,325v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V325H1050.6z"/>
                                        <path className="st18" d="M1059.6,325V319l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1059.6z"/>
                                        <path className="st18" d="M1069,325l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V325L1069,325z M1068.8,318.3l-0.2-1.2
			l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3L1068.8,318.3z
			"/>
                                        <path className="st18" d="M955.2,336.9l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4L953,336l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L955.2,336.9z"/>
                                        <path className="st18" d="M959.3,338.4v-1.9h1V334h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H959.3z"/>
                                        <path className="st18" d="M973.1,342.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V342.4z M970.5,339.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V339.8z"/>
                                        <path className="st18" d="M974.8,347v-14.1h2.7V347H974.8z" />
                                        <path className="st18" d="M979.4,347v-14.1h2.7V347H979.4z" />
                                        <path className="st18" d="M991.4,342.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V342.4z M988.7,339.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V339.8z"/>
                                        <path className="st18" d="M997.7,347V341l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H997.7z"/>
                                        <path className="st18" d="M1002.3,347l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1002.3z M1006.9,340.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1006.9,340.3z"/>
                                        <path className="st18" d="M1018.8,342.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V342.4z M1016.2,339.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V339.8z"/>
                                        <path className="st18" d="M1024.5,339.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1024.5z"/>
                                        <path className="st18" d="M1036.3,342.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V342.4z M1033.6,339.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V339.8z"/>
                                        <path className="st18" d="M1037.4,338.4v-1.9h1V334h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1037.4z"/>
                                        <path className="st18" d="M1043.5,347V345l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H1043.5z" />
                                        <path className="st18" d="M1057.2,347v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V347H1057.2z"/>
                                        <path className="st18" d="M1066.3,347V341l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1066.3z"/>
                                        <path className="st18" d="M1078.1,347l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1078.1,347z M1073.5,341.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1073.5,341.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M671.3,303l0-14.1h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H671.3z" />
                                        <path className="st18" d="M684,303v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V303H684z"/>
                                        <path className="st18" d="M693.1,303V297l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H693.1z"/>
                                        <path className="st18" d="M702.7,303l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H702.7z" />
                                        <path className="st18" d="M706.2,294.4v-1.9h1V290h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H706.2z"/>
                                        <path className="st18" d="M712.6,291.5v-2.7h2.6v2.7H712.6z M712.6,303v-10.3h2.6V303H712.6z" />
                                        <path className="st18" d="M724.5,299.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L724.5,299.6z
			 M719.7,298.5l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L719.7,298.5z"/>
                                        <path className="st18" d="M730.9,303V297l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L729,297v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H730.9z"/>
                                        <path className="st18" d="M742.8,298.4H738l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V298.4z M740.2,295.8L740,295l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V295.8z"/>
                                        <path className="st18" d="M749.2,303V297l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H749.2z"/>
                                        <path className="st18" d="M758.5,303l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4L754,302l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V303L758.5,303z M758.3,296.3l-0.2-1.2
			l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3L758.3,296.3z"
                                        />
                                        <path className="st18" d="M762.9,291.5v-2.7h2.6v2.7H762.9z M762.9,303v-10.3h2.6V303H762.9z" />
                                        <path className="st18" d="M771.9,303l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H771.9z M770.6,298.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L770.6,298.6z"/>
                                        <path className="st18" d="M783.4,303l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L783.4,303z M778.8,297.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L778.8,297.6z"/>
                                        <path className="st18" d="M785.3,303l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H785.3z"/>
                                        <path className="st18" d="M796.5,303l0-1.4l-0.4,0.8l-0.4,0.4L795,303l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H796.5z M795.1,298.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L795.1,298.6z"/>
                                        <path className="st18" d="M809.9,302.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L809.9,302.9z"/>
                                        <path className="st18" d="M823.6,302.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2H819l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L823.6,302.9z"/>
                                        <path className="st18" d="M670.3,310.8l-1.4,3.3l-0.5,2.2l-0.3,3.1l0.1,1.7l0.4,2.4l0.7,2.3l0.6,1.6l0.5,0.8l-1.1,0l-0.9-1.8l-1.1-2.8
			l-0.4-2l-0.1-2.3l0.2-2.7l0.5-2l0.9-2.2l1-1.8L670.3,310.8z"/>
                                        <path className="st18" d="M679.8,325h-1.4l-1.1-4.5h-3.8l-1.1,4.5h-1.4l3.7-14.1h1.4L679.8,325z M677.1,319.3l-1.5-6.6l-0.6,2.5
			l-0.5,2l-0.5,2.1L677.1,319.3z"/>
                                        <path className="st18" d="M682.4,314.6v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4v-1.4l0-5h1.3
			V325h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6v-6.2H682.4z"/>
                                        <path className="st18" d="M691.9,311.9l-1,0.1l-0.4,0.4l0,2.1h1.4v1.1h-1.4v9.3h-1.3v-9.3h-1.1v-1.1h1.1v-1.9l0-0.5l0.4-0.8l0.6-0.4
			l0.9-0.1l0.9,0V311.9z"/>
                                        <path className="st18" d="M697.8,314.6h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L697.8,314.6z M694.7,320.9
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V320.9z"/>
                                        <path className="st18" d="M705.6,324.8l-0.1-0.8l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2l-0.6-0.9l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1l-0.4-0.4l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2v-1.2l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L705.6,324.8z M704.5,319.6l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L704.5,319.6z"/>
                                        <path className="st18" d="M710.6,310.8v4.5l0.7-0.7l1.2-0.3l1.1,0.2l0.6,0.5l0.4,0.6l0.2,0.7l0.1,2v3.1l-0.1,1.5l-0.3,1l-0.5,0.6
			l-0.8,0.4l-0.6,0.1l-0.7-0.1l-1-0.6l-0.4-0.4v0.8h-1.2v-14.1H710.6z M710.5,321.7l0.2,1.5l0.5,0.6l0.4,0.2l0.5,0.1l0.8-0.3
			l0.3-0.4l0.2-0.8l0.1-1.1l0-1.2l0-2l-0.1-1.3l-0.3-0.9l-0.7-0.4l-0.4-0.1l-0.4,0.1l-0.5,0.3l-0.3,0.5l-0.2,1.1l-0.1,1.6
			L710.5,321.7z"/>
                                        <path className="st18" d="M718.5,320.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H718.5z M721.6,318.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H721.6z"/>
                                        <path className="st18" d="M730.6,325h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V325z"/>
                                        <path className="st18" d="M734.4,323.2v2.2l-0.4,1.4l-0.6,0.7l-0.4,0.2v-1.1l0.2-0.3l0.2-0.5l0.1-0.8h-0.6v-1.8H734.4z" />
                                        <path className="st18" d="M743.3,310.8v6.5l4.2-6.5l1.5,0l-4.2,6.6l2.3,3.4l1.4,2.2l1.2,1.9h-1.6l-4.9-7.3v7.3h-1.3v-14.1L743.3,310.8
			z"/>
                                        <path className="st18" d="M756.5,322.1l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L756.5,322.1z M752,320.9l0,1.4l0.2,0.9
			l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3l-0.3,0.3
			l-0.3,1.3l0,1.9L752,320.9z"/>
                                        <path className="st18" d="M768.4,325h-1.3v-7.5l-0.1-0.9l-0.4-0.6l-0.8-0.3l-0.9,0.3l-0.4,0.6l-0.2,1.3v7.2h-1.3v-7.7l-0.3-0.9
			l-0.4-0.5l-0.8-0.2l-0.4,0.1l-0.4,0.3l-0.4,0.5l-0.2,1.5v7h-1.3v-10.3h1.2v0.8l0.3-0.3l0.7-0.4l1-0.2l0.6,0.1l1,0.6l0.3,0.5
			l0.4-0.4l1.1-0.6l0.7-0.1l0.6,0.1l0.9,0.5l0.4,0.5l0.2,0.5l0.2,1.6V325z"/>
                                        <path className="st18" d="M772.1,328.5h-1.3v-13.9h1.2v0.8l0.4-0.4l0.7-0.4l1-0.2l0.8,0.1l0.6,0.3l0.6,0.8l0.3,1.3l0,1.9v2.8l-0.2,2
			l-0.2,0.5l-0.6,0.6l-0.7,0.4l-0.6,0l-0.6,0l-0.8-0.5l-0.5-0.5V328.5z M772.1,320.3l0,1.7l0.3,1.4l0.7,0.6l0.5,0.1l0.8-0.3l0.4-0.5
			l0.2-1.9l0-1.9l0-1.9l-0.1-0.8l-0.2-0.6l-0.5-0.4l-0.6-0.1l-0.4,0.1l-0.6,0.3l-0.3,0.5l-0.2,0.5l-0.1,1.4V320.3z"/>
                                        <path className="st18" d="M780,320.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H780z M783.1,318.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3l-0.3,0.5
			l-0.1,0.5l-0.1,1.4v0.8H783.1z"/>
                                        <path className="st18" d="M788.2,311.7l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			L787,324l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H788.2z"/>
                                        <path className="st18" d="M792.5,320.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H792.5z M795.6,318.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H795.6z"/>
                                        <path className="st18" d="M804.6,325h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V325z"/>
                                        <path className="st18" d="M811.8,314.6v1l-4.2,8.2h4.2v1.1h-5.6v-1l4.2-8.2h-3.6v-1.2H811.8z" />
                                        <path className="st18" d="M814.9,320.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H814.9z M818.1,318.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H818.1z"/>
                                        <path className="st18" d="M827.1,325h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V325z"/>
                                        <path className="st18" d="M830.8,323.2v2.2l-0.4,1.4l-0.6,0.7l-0.4,0.2v-1.1l0.2-0.3l0.2-0.5l0.1-0.8h-0.6v-1.8H830.8z" />
                                        <path className="st18" d="M677.8,332.7l1.2,5.6l0.7,3.3l0.4,1.7l0.4,2.2l0.3-1.7l0.2-1l0.4-2l1-4.5l0.8-3.6h1.4l-3.4,14.2l-1.7,0
			l-3.3-14.1L677.8,332.7z"/>
                                        <path className="st18" d="M687.3,342.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5L690,347l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H687.3z M690.5,340.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H690.5z"/>
                                        <path className="st18" d="M697.5,337.8l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V337.8z"/>
                                        <path className="st18" d="M703.2,346.8L703,346l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2l-0.6-0.9l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1l-0.4-0.4l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2v-1.2l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L703.2,346.8z M702,341.6l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L702,341.6z"/>
                                        <path className="st18" d="M712.4,347H711v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V347z"/>
                                        <path className="st18" d="M716.3,333.7l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H716.3z"/>
                                        <path className="st18" d="M719.7,336.6l0.7,3.6l0.4,2.7l0.4,2.1l0.2-1.8l0.6-2.1l0.9-4.5l1.4,0l0.9,4.4l0.5,2.1l0.3,1.8l0.4-2.6l0.5-3
			l0.5-2.7l1.2-0.1l-2,10.4l-1.4-0.1l-1-4.4l-0.4-1.7l-0.4-2.2l-0.2,1.2l-0.2,1.1l-0.3,1.3l-1,4.6h-1.3l-2-10.3L719.7,336.6z"/>
                                        <path className="st18" d="M735.7,344.1l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L735.7,344.1z M731.3,342.9l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L731.3,342.9z"/>
                                        <path className="st18" d="M741.6,337.8l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V337.8z"/>
                                        <path className="st18" d="M744.6,333.7l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H744.6z"/>
                                        <path className="st18" d="M748.9,336.6v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4v-1.4l0-5h1.3
			V347h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6v-6.2H748.9z"/>
                                        <path className="st18" d="M761,347h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V347z"/>
                                        <path className="st18" d="M767.7,336.6h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L767.7,336.6z M764.6,342.9
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V342.9z"/>
                                        <path className="st18" d="M782.9,340.5v1.2h-7.1v-1.2H782.9z" />
                                        <path className="st18" d="M797.9,347h-1.4l-1.1-4.5h-3.8l-1.1,4.5h-1.4l3.7-14.1h1.4L797.9,347z M795.1,341.3l-1.5-6.6l-0.6,2.5
			l-0.5,2l-0.5,2.1L795.1,341.3z"/>
                                        <path className="st18" d="M800.6,332.8v6.5l4.2-6.5l1.5,0l-4.2,6.6l2.3,3.4l1.4,2.2l1.2,1.9h-1.6l-4.9-7.3v7.3h-1.3v-14.1L800.6,332.8
			z"/>
                                        <path className="st18" d="M808.7,332.7l1.2,5.6l0.7,3.3l0.4,1.7l0.4,2.2l0.3-1.7l0.2-1l0.4-2l1-4.5l0.8-3.6h1.4l-3.4,14.2l-1.7,0
			l-3.3-14.1L808.7,332.7z"/>
                                        <path className="st18" d="M816.2,350.3l0.5-0.9l0.4-1l0.9-2.6l0.3-1.6l0.2-2.4l-0.1-1.7l-0.3-1.7l-0.6-2.5l-0.6-1.6l-0.7-1.4l1.1,0
			l1.2,2.4l0.9,2.6l0.3,1.7l0.1,2.1l-0.2,2.8l-0.5,1.9l-0.9,2.2l-1,1.8H816.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M699.4,203.8l-0.1,2l-0.2,0.9l-0.4,0.8l-0.5,0.6l-0.8,0.5l-1,0.4l-1.3,0.1l-1.6-0.1l-1.1-0.5l-0.9-0.9
			l-0.4-0.8L691,206l-0.1-1.7v-9.5h2.8v8.5l0,1.8l0.2,1.1l0.3,0.4l0.3,0.2l0.6,0.1l0.5-0.1l0.6-0.5l0.2-0.7l0.1-1.1v-1.3l0-8.4h2.9
			V203.8z"/>
                                        <path className="st18" d="M706.1,208.9V203l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H706.1z"/>
                                        <path className="st18" d="M710.1,200.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H710.1z"/>
                                        <path className="st18" d="M723.9,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M721.3,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M725.7,208.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H725.7z"/>
                                        <path className="st18" d="M736.9,208.9V203l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L735,203v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H736.9z"/>
                                        <path className="st18" d="M748.9,204.3H744l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M746.2,201.8L746,201l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M755.2,208.9v-6.3L755,201l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H755.2z"/>
                                        <path className="st18" d="M768.9,208.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L768.9,208.9z"/>
                                        <path className="st18" d="M780.9,204.3H776l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M778.2,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M787.2,208.9V203l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H787.2z"/>
                                        <path className="st18" d="M795.8,201.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H795.8z"/>
                                        <path className="st18" d="M799.9,204V202h4.6v2.1H799.9z" />
                                        <path className="st18" d="M710.2,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L710,229l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L703,229l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M707.6,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M716.5,230.9V225l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H716.5z"/>
                                        <path className="st18" d="M720.5,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H720.5z"/>
                                        <path className="st18" d="M733.6,230.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H733.6z"/>
                                        <path className="st18" d="M739.8,219.5v-2.7h2.6v2.7H739.8z M739.8,230.9v-10.3h2.6v10.3H739.8z" />
                                        <path className="st18" d="M744.3,225l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L744.3,225z"/>
                                        <path className="st18" d="M758.2,230.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H758.2z" />
                                        <path className="st18" d="M762.2,230.9v-14.1h2.7v14.1H762.2z" />
                                        <path className="st18" d="M771.5,230.9v-1l-0.8,0.8L770,231l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H771.5z"/>
                                        <path className="st18" d="M780.5,230.9V225l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H780.5z"/>
                                        <path className="st18" d="M792.4,230.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L792.4,230.9z M787.7,225.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L787.7,225.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1194.6,109.9l0-14.1h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H1194.6z" />
                                        <path className="st18" d="M1207.2,109.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1207.2z M1205.7,97.9h-2v-2.1h2V97.9z M1208.8,97.9h-2v-2.1h2
			V97.9z"/>
                                        <path className="st18" d="M1216.3,109.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1216.3z"/>
                                        <path className="st18" d="M1220.9,109.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1220.9z"/>
                                        <path className="st18" d="M1232.2,109.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1232.2z"/>
                                        <path className="st18" d="M1241.2,109.9V104l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V99.6l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1241.2z"/>
                                        <path className="st18" d="M1253.1,109.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1253.1,109.9z M1248.4,104.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1248.4,104.6z"/>
                                        <path className="st18" d="M1258.9,102.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1258.9z"/>
                                        <path className="st18" d="M1268.3,109.9l-2.4-5.4v5.4h-2.6V95.8h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1268.3z" />
                                        <path className="st18" d="M1272.4,109.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1272.4z"/>
                                        <path className="st18" d="M1283.6,109.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1283.6z M1282.2,97.9h-2v-2.1h2V97.9z M1282.2,105.5
			l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1282.2,105.5z M1285.3,97.9h-2v-2.1h2V97.9z"/>
                                        <path className="st18" d="M1288.2,109.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0V98l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1288.2z"/>
                                        <path className="st18" d="M1293,101.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1293z"/>
                                        <path className="st18" d="M1306.8,105.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V105.3z M1304.2,102.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V102.8z"/>
                                        <path className="st18" d="M1308.6,109.9l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1308.6z M1313.2,103.3
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1313.2,103.3z"/>
                                        <path className="st18" d="M1325.1,105.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V105.3z M1322.5,102.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V102.8z"/>
                                        <path className="st18" d="M1331.6,109.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1331.6,109.9z M1331.4,103.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1331.4,103.3z"/>
                                        <path className="st18" d="M1340.5,109.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1340.5z M1339.2,105.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1339.2,105.5z"/>
                                        <path className="st18" d="M1344.7,109.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1344.7z"/>
                                        <path className="st18" d="M1351.8,109.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0V98l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1351.8z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1233.8,199.9V194l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1233.8,199.9z"/>
                                        <path className="st18" d="M1243.2,199.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1243.2z M1241.9,195.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1241.9,195.5z"/>
                                        <path className="st18" d="M1252.1,199.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1252.1z"/>
                                        <path className="st18" d="M1261.1,199.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1261.1z M1259.8,195.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1259.8,195.5z"/>
                                        <path className="st18" d="M1272.6,199.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1272.6,199.9z M1268,194.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1268,194.5z"/>
                                        <path className="st18" d="M1281.9,195.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V195.3z M1279.3,192.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V192.8z"/>
                                        <path className="st18" d="M1292.8,199.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1292.8,199.9z"/>
                                        <path className="st18" d="M1304.8,195.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V195.3z M1302.1,192.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V192.8z"/>
                                        <path className="st18" d="M1311.1,199.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1311.1z"/>
                                        <path className="st18" d="M1315.1,191.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1315.1z"/>
                                        <path className="st18" d="M1321.3,195V193h4.6v2.1H1321.3z" />
                                        <path className="st18" d="M1221,217.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V217.3z M1218.4,214.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V214.8z"/>
                                        <path className="st18" d="M1227.3,221.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1227.3z"/>
                                        <path className="st18" d="M1231.3,213.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1231.3z"/>
                                        <path className="st18" d="M1244.4,221.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1244.4z"/>
                                        <path className="st18" d="M1250.6,210.5v-2.7h2.6v2.7H1250.6z M1250.6,221.9v-10.3h2.6v10.3H1250.6z" />
                                        <path className="st18" d="M1255.1,216l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1255.1,216z"/>
                                        <path className="st18" d="M1269,221.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1269z" />
                                        <path className="st18" d="M1273,221.9v-14.1h2.7v14.1H1273z" />
                                        <path className="st18" d="M1282.3,221.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1282.3z"/>
                                        <path className="st18" d="M1291.3,221.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1291.3z"/>
                                        <path className="st18" d="M1303.2,221.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1303.2,221.9z M1298.5,216.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1298.5,216.5z"/>
                                        <path className="st18" d="M1315.1,221.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1315.1z"/>
                                        <path className="st18" d="M1324.2,221.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1324.2z"/>
                                        <path className="st18" d="M1333.5,221.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1333.5,221.9z M1333.3,215.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1333.3,215.3z"/>
                                        <path className="st18" d="M1201,239V237h4.6v2.1H1201z" />
                                        <path className="st18" d="M1211.9,243.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1211.9z"/>
                                        <path className="st18" d="M1221,243.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1221z M1219.7,239.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1219.7,239.5z"/>
                                        <path className="st18" d="M1225.1,238l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1225.1,238z"/>
                                        <path className="st18" d="M1238.6,243.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1238.6z"/>
                                        <path className="st18" d="M1249.8,243.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1249.8z"/>
                                        <path className="st18" d="M1260.7,243.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1260.7z"/>
                                        <path className="st18" d="M1265.1,238l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1265.1,238z"/>
                                        <path className="st18" d="M1278.5,243.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1278.5z"/>
                                        <path className="st18" d="M1287,236.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1287z"/>
                                        <path className="st18" d="M1291.4,247.6v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L1291.4,247.6z M1294,239.3l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V239.3z"/>
                                        <path className="st18" d="M1300.5,243.9v-14.1h2.7v14.1H1300.5z" />
                                        <path className="st18" d="M1309.6,243.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1309.6z M1308.2,239.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1308.2,239.5z"/>
                                        <path className="st18" d="M1318.4,243.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1318.4z"/>
                                        <path className="st18" d="M1327.6,243.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1327.6z"/>
                                        <path className="st18" d="M1336.7,243.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1336.7z"/>
                                        <path className="st18" d="M1348.5,243.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1348.5,243.9z M1343.9,238.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1343.9,238.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1494.2,208.9v-14.1h2.9v14.1H1494.2z" />
                                        <path className="st18" d="M1503.9,208.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1503.9z"/>
                                        <path className="st18" d="M1513.2,208.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1513.2,208.9z M1513,202.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1513,202.3z"/>
                                        <path className="st18" d="M1517.6,197.5v-2.7h2.6v2.7H1517.6z M1517.6,208.9v-10.3h2.6v10.3H1517.6z" />
                                        <path className="st18" d="M1523.8,208.9l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H1523.8z" />
                                        <path className="st18" d="M1530.4,197.5v-2.7h2.6v2.7H1530.4z M1530.4,208.9v-10.3h2.6v10.3H1530.4z" />
                                        <path className="st18" d="M1539.8,208.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1539.8,208.9z M1539.6,202.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1539.6,202.3z"/>
                                        <path className="st18" d="M1548.8,208.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1548.8z"/>
                                        <path className="st18" d="M1560.7,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M1558,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M1562.4,208.9v-14.1h2.7v14.1H1562.4z" />
                                        <path className="st18" d="M1567,208.9v-14.1h2.7v14.1H1567z" />
                                        <path className="st18" d="M1578.9,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M1576.3,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M1459,230.9V225l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1459,230.9z"/>
                                        <path className="st18" d="M1463.9,219.5v-2.7h2.6v2.7H1463.9z M1463.9,230.9v-10.3h2.6v10.3H1463.9z" />
                                        <path className="st18" d="M1467.9,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1467.9z"/>
                                        <path className="st18" d="M1478.8,230.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1478.8z M1477.5,226.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1477.5,226.5z"/>
                                        <path className="st18" d="M1483.1,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1483.1z"/>
                                        <path className="st18" d="M1489.8,230.9l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1489.8z M1494.4,224.2
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1494.4,224.2z"/>
                                        <path className="st18" d="M1506.3,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1503.6,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1508,219.5v-2.7h2.6v2.7H1508z M1508,230.9v-10.3h2.6v10.3H1508z" />
                                        <path className="st18" d="M1512,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1512z"/>
                                        <path className="st18" d="M1525.8,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1523.1,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1527.5,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1527.5z"/>
                                        <path className="st18" d="M1541.6,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1538.9,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1547.9,230.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1547.9z"/>
                                        <path className="st18" d="M1551.9,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1551.9z"/>
                                        <path className="st18" d="M1565,230.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1565z"/>
                                        <path className="st18" d="M1571.2,219.5v-2.7h2.6v2.7H1571.2z M1571.2,230.9v-10.3h2.6v10.3H1571.2z" />
                                        <path className="st18" d="M1575.6,225l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1575.6,225z"/>
                                        <path className="st18" d="M1589.6,230.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1589.6z" />
                                        <path className="st18" d="M1593.6,230.9v-14.1h2.7v14.1H1593.6z" />
                                        <path className="st18" d="M1602.8,230.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1602.8z"/>
                                        <path className="st18" d="M1611.9,230.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1611.9z"/>
                                        <path className="st18" d="M1623.7,230.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1623.7,230.9z M1619.1,225.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1619.1,225.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1452.7,301v-14.1h2.9V301H1452.7z" />
                                        <path className="st18" d="M1462.4,301V295l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1462.4z"/>
                                        <path className="st18" d="M1471.7,301l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V301L1471.7,301z M1471.5,294.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1471.5,294.3z"/>
                                        <path className="st18" d="M1476.1,289.5v-2.7h2.6v2.7H1476.1z M1476.1,301v-10.3h2.6V301H1476.1z" />
                                        <path className="st18" d="M1482.3,301l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H1482.3z" />
                                        <path className="st18" d="M1488.9,289.5v-2.7h2.6v2.7H1488.9z M1488.9,301v-10.3h2.6V301H1488.9z" />
                                        <path className="st18" d="M1498.3,301l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V301L1498.3,301z M1498.1,294.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1498.1,294.3z"/>
                                        <path className="st18" d="M1507.3,301v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V301H1507.3z"/>
                                        <path className="st18" d="M1519.2,296.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V296.4z M1516.5,293.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V293.8z"/>
                                        <path className="st18" d="M1520.9,301v-14.1h2.7V301H1520.9z" />
                                        <path className="st18" d="M1525.5,301v-14.1h2.7V301H1525.5z" />
                                        <path className="st18" d="M1537.4,296.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V296.4z M1534.8,293.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V293.8z"/>
                                        <path className="st18" d="M1544.9,301v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2l-1.3,0h-0.6v5.1H1544.9z M1548.5,293.4l0.7-0.2l0.4-0.6l0.1-1l0-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1L1548.5,293.4z
			"/>
                                        <path className="st18" d="M1561.4,297.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1561.4,297.6z
			 M1556.7,296.5l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1556.7,296.5z"/>
                                        <path className="st18" d="M1562.7,292.4v-1.9h1V288h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1562.7z"/>
                                        <path className="st18" d="M1576.5,296.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V296.4z M1573.9,293.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V293.8z"/>
                                        <path className="st18" d="M1582.8,301V295l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1582.8z"/>
                                        <path className="st18" d="M1587.1,301V299l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H1587.1z" />
                                        <path className="st18" d="M1596.1,289.5v-2.7h2.6v2.7H1596.1z M1596.1,301v-10.3h2.6V301H1596.1z" />
                                        <path className="st18" d="M1605.2,301l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1605.2z M1603.8,296.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1603.8,296.6z"/>
                                        <path className="st18" d="M1609.4,301v-14.1h2.7V301H1609.4z" />
                                        <path className="st18" d="M1613.8,296.1V294h4.6v2.1H1613.8z" />
                                        <path className="st18" d="M1477.1,323l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1477.1z M1481.7,316.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1481.7,316.3z"/>
                                        <path className="st18" d="M1493.6,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1491,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1500,323v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V323H1500z"/>
                                        <path className="st18" d="M1504.5,323l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1504.5z"/>
                                        <path className="st18" d="M1510.6,314.4v-1.9h1V310h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1510.6z"/>
                                        <path className="st18" d="M1524.4,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1521.8,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1526.1,311.5v-2.7h2.6v2.7H1526.1z M1526.1,323v-10.3h2.6V323H1526.1z" />
                                        <path className="st18" d="M1530.7,323v-14.1h2.7V323H1530.7z" />
                                        <path className="st18" d="M1539.9,323v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V323H1539.9z"/>
                                        <path className="st18" d="M1549,323V317l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1549z"/>
                                        <path className="st18" d="M1560.8,323l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1560.8,323z M1556.2,317.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1556.2,317.6z"/>
                                        <path className="st18" d="M1572.7,323v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V323H1572.7z"/>
                                        <path className="st18" d="M1581.8,323V317l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1581.8z"/>
                                        <path className="st18" d="M1591.1,323l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V323L1591.1,323z M1590.9,316.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1590.9,316.3z"/>
                                        <path className="st18" d="M1462.8,345v-14.1h2.8v11.6h4.1v2.5H1462.8z" />
                                        <path className="st18" d="M1475.8,345l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1475.8z M1474.4,340.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1474.4,340.6z"/>
                                        <path className="st18" d="M1484.6,345v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V345H1484.6z"/>
                                        <path className="st18" d="M1489.5,345v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1489.5z"/>
                                        <path className="st18" d="M1495,345l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1495z M1499.6,338.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1499.6,338.3z"/>
                                        <path className="st18" d="M1508.6,345l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1508.6z M1507.3,340.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1507.3,340.6z"/>
                                        <path className="st18" d="M1517.4,345v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1517.4z"/>
                                        <path className="st18" d="M1526.6,345V339l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1526.6z"/>
                                        <path className="st18" d="M1538.4,345l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1538.4,345z M1533.8,339.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1533.8,339.6z"/>
                                        <path className="st18" d="M1547.7,340.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V340.4z M1545,337.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V337.8z"/>
                                        <path className="st18" d="M1553.4,337.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1553.4z"/>
                                        <path className="st18" d="M1557.1,336.4v-1.9h1V332h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1557.1z"/>
                                        <path className="st18" d="M1568,345l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1568z M1566.7,340.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1566.7,340.6z"/>
                                        <path className="st18" d="M1572.3,345v-14.1h2.7V345H1572.3z" />
                                        <path className="st18" d="M1576.2,336.4v-1.9h1V332h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1576.2z"/>
                                        <path className="st18" d="M1587.3,345v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V345H1587.3z"/>
                                        <path className="st18" d="M1596.4,345V339l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1596.4z"/>
                                        <path className="st18" d="M1608.2,345l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1608.2,345z M1603.6,339.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1603.6,339.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1460.3,471.6v-14.1h2.8v11.6h4.1v2.5H1460.3z" />
                                        <path className="st18" d="M1476.2,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M1473.5,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M1477.9,460.2v-2.7h2.6v2.7H1477.9z M1477.9,471.6v-10.3h2.6v10.3H1477.9z" />
                                        <path className="st18" d="M1486.5,464.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1486.5z"/>
                                        <path className="st18" d="M1490.2,463.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1490.2z"/>
                                        <path className="st18" d="M1501.2,471.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1501.2z"/>
                                        <path className="st18" d="M1510.3,471.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1510.3z"/>
                                        <path className="st18" d="M1522.2,471.6l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1522.2,471.6z M1517.5,466.2l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1517.5,466.2z"/>
                                        <path className="st18" d="M1528,464.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1528z"/>
                                        <path className="st18" d="M1532.4,471.6l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1532.4z M1537,464.9
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1537,464.9z"/>
                                        <path className="st18" d="M1548.9,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M1546.2,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M1557.3,471.6l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1557.3z"/>
                                        <path className="st18" d="M1570.9,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M1568.3,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M1572.7,471.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1572.7z"/>
                                        <path className="st18" d="M1578.7,463.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1578.7z"/>
                                        <path className="st18" d="M1589.8,471.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1589.8z"/>
                                        <path className="st18" d="M1598.9,471.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1598.9z"/>
                                        <path className="st18" d="M1610.7,471.6l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1610.7,471.6z M1606.1,466.2l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1606.1,466.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1510.5,661.7l-3.4-7l0.1,7h-2.9v-14.1h2.8v6.4l3-6.4h3.2l-3.5,6.6l3.8,7.5H1510.5z" />
                                        <path className="st18" d="M1521.7,658.3l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1521.7,658.3z
			 M1517,657.2l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1517,657.2z"/>
                                        <path className="st18" d="M1528.1,661.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1528.1z"/>
                                        <path className="st18" d="M1532.1,653.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1532.1z"/>
                                        <path className="st18" d="M1538.5,661.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1538.5z"/>
                                        <path className="st18" d="M1552.5,658.3l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1552.5,658.3z
			 M1547.7,657.2l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1547.7,657.2z"/>
                                        <path className="st18" d="M1554.3,661.7v-14.1h2.7v14.1H1554.3z" />
                                        <path className="st18" d="M1558.9,661.7v-14.1h2.7v14.1H1558.9z" />
                                        <path className="st18" d="M1570.8,657.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V657.1z M1568.2,654.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V654.6z"/>
                                        <path className="st18" d="M1489.1,669.6l-1.4,3.3l-0.5,2.2l-0.3,3.1l0.1,1.7l0.4,2.4l0.7,2.3l0.6,1.6l0.5,0.8l-1.1,0l-0.9-1.8
			l-1.1-2.8l-0.4-2l-0.1-2.3l0.2-2.7l0.5-2l0.9-2.2l1-1.8L1489.1,669.6z"/>
                                        <path className="st18" d="M1495.8,673l-0.1-1.1l-0.3-0.7l-0.5-0.5l-0.5-0.2l-0.5,0l-0.9,0.2l-0.6,0.4l-0.3,0.6l-0.1,1l0.1,0.9l0.7,1.1
			l1.1,0.8l1.8,1.2l1,1.1l0.4,1l0.2,1.5l-0.1,0.9l-0.3,1.2l-0.6,0.8l-0.7,0.4l-0.7,0.2l-1.4,0.2l-1.1-0.1l-0.8-0.3l-0.7-0.7
			l-0.4-0.6l-0.2-0.7l-0.1-1.9h1.4l0.1,1.5l0.2,0.6l0.3,0.4l0.6,0.4l1,0.1l0.6-0.1l0.9-0.4l0.6-1l0.1-1l-0.1-1l-0.7-1.1l-1-0.9
			l-1.2-0.8l-1.4-1.1l-0.6-0.7l-0.3-0.7l-0.1-1.4l0.2-1.4l0.5-0.9l0.6-0.5l0.8-0.3l1.2-0.1l1.1,0.1l1.2,0.5l0.4,0.5l0.3,0.8l0.2,0.8
			l0,0.9H1495.8z"/>
                                        <path className="st18" d="M1500.7,678.8l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H1500.7z M1503.9,677.7l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H1503.9z"/>
                                        <path className="st18" d="M1508.7,669.5v14.1h-1.3v-14.1H1508.7z" />
                                        <path className="st18" d="M1512.4,669.5v4.5l0.7-0.7l1.2-0.3l1.1,0.2l0.6,0.5l0.4,0.6l0.2,0.7l0.1,2v3.1l-0.1,1.5l-0.3,1l-0.5,0.6
			l-0.8,0.4l-0.6,0.1l-0.7-0.1l-1-0.6l-0.4-0.4v0.8h-1.2v-14.1H1512.4z M1512.4,680.4l0.2,1.5l0.5,0.6l0.4,0.2l0.5,0.1l0.8-0.3
			l0.3-0.4l0.2-0.8l0.1-1.1l0-1.2l0-2l-0.1-1.3l-0.3-0.9l-0.7-0.4l-0.4-0.1l-0.4,0.1l-0.5,0.3l-0.3,0.5l-0.2,1.1l-0.1,1.6
			L1512.4,680.4z"/>
                                        <path className="st18" d="M1522.7,676.2l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8
			l1,0.7l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3
			l0,1.3l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7
			l0.5-0.5l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H1522.7z"/>
                                        <path className="st18" d="M1527.7,670.5l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H1527.7z"/>
                                        <path className="st18" d="M1531.9,669.5v8.4l2.8-4.6h1.5l-2.8,4.7l3,5.7h-1.5l-3-5.7v5.7h-1.3v-14.1H1531.9z" />
                                        <path className="st18" d="M1543.3,680.9l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L1543.3,680.9z M1538.9,679.7l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L1538.9,679.7z"/>
                                        <path className="st18" d="M1551.1,683.7h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2
			v0.7l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V683.7z"/>
                                        <path className="st18" d="M1555.1,670.5l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H1555.1z"/>
                                        <path className="st18" d="M1561.6,674.5l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V674.5z"/>
                                        <path className="st18" d="M1568.7,680.9l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L1568.7,680.9z M1564.2,679.7l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L1564.2,679.7z"/>
                                        <path className="st18" d="M1572.3,669.5v14.1h-1.3v-14.1H1572.3z" />
                                        <path className="st18" d="M1576,669.5v14.1h-1.3v-14.1H1576z" />
                                        <path className="st18" d="M1579.7,678.8l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H1579.7z M1582.8,677.7l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H1582.8z"/>
                                        <path className="st18" d="M1585.5,687l0.5-0.9l0.4-1l0.9-2.6l0.3-1.6l0.2-2.4l-0.1-1.7l-0.3-1.7l-0.6-2.5l-0.6-1.6l-0.7-1.4l1.1,0
			l1.2,2.4l0.9,2.6l0.3,1.7l0.1,2.1l-0.2,2.8l-0.5,1.9l-0.9,2.2l-1,1.8H1585.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1457.2,814.3v-14.1h2.9v14.1H1457.2z" />
                                        <path className="st18" d="M1466.9,814.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V804l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1466.9z"/>
                                        <path className="st18" d="M1476.2,814.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V808
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1476.2,814.3z M1476,807.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1476,807.7z"/>
                                        <path className="st18" d="M1480.6,802.9v-2.7h2.6v2.7H1480.6z M1480.6,814.3V804h2.6v10.3H1480.6z" />
                                        <path className="st18" d="M1486.8,814.3l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H1486.8z" />
                                        <path className="st18" d="M1493.5,802.9v-2.7h2.6v2.7H1493.5z M1493.5,814.3V804h2.6v10.3H1493.5z" />
                                        <path className="st18" d="M1502.8,814.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V808
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1502.8,814.3z M1502.6,807.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1502.6,807.7z"/>
                                        <path className="st18" d="M1511.8,814.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V804h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V804h2.7v10.3H1511.8z"/>
                                        <path className="st18" d="M1523.7,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1521.1,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1525.4,814.3v-14.1h2.7v14.1H1525.4z" />
                                        <path className="st18" d="M1530,814.3v-14.1h2.7v14.1H1530z" />
                                        <path className="st18" d="M1542,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1539.3,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1549.4,814.3v-14.1h2.8v11.6h4.1v2.5H1549.4z" />
                                        <path className="st18" d="M1565.3,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1562.6,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1567,802.9v-2.7h2.6v2.7H1567z M1567,814.3V804h2.6v10.3H1567z" />
                                        <path className="st18" d="M1575.6,806.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1575.6z"/>
                                        <path className="st18" d="M1579.3,805.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1579.3z"/>
                                        <path className="st18" d="M1590.3,814.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V804h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V804h2.7v10.3H1590.3z"/>
                                        <path className="st18" d="M1599.4,814.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V804l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1599.4z"/>
                                        <path className="st18" d="M1611.3,814.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1611.3,814.3z M1606.6,809l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1606.6,809z"/>
                                        <path className="st18" d="M1617.1,806.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1617.1z"/>
                                        <path className="st18" d="M1621.3,809.4v-2.1h4.6v2.1H1621.3z" />
                                        <path className="st18" d="M1454.7,836.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V826h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V826h2.7v10.3H1454.7z"/>
                                        <path className="st18" d="M1463.8,836.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V826l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1463.8z"/>
                                        <path className="st18" d="M1473.1,836.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V830
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1473.1,836.3z M1472.9,829.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1472.9,829.7z"/>
                                        <path className="st18" d="M1483.2,836.3l0-14.1h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H1483.2z" />
                                        <path className="st18" d="M1495.8,836.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V826h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V826h2.7v10.3H1495.8z M1494.3,824.3h-2v-2.1h2V824.3z M1497.4,824.3h-2v-2.1h2
			V824.3z"/>
                                        <path className="st18" d="M1504.9,836.3V830l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1504.9z"/>
                                        <path className="st18" d="M1509.5,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1509.5z"/>
                                        <path className="st18" d="M1520.8,836.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V826h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V826h2.7v10.3H1520.8z"/>
                                        <path className="st18" d="M1529.8,836.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V826l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1529.8z"/>
                                        <path className="st18" d="M1541.7,836.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1541.7,836.3z M1537,831l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1537,831
			z"/>
                                        <path className="st18" d="M1547.5,828.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1547.5z"/>
                                        <path className="st18" d="M1559.3,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1556.6,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1561,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1561z"/>
                                        <path className="st18" d="M1574.9,836.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1574.9,836.3z M1570.3,831l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1570.3,831z"/>
                                        <path className="st18" d="M1584.2,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1581.6,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1586,836.3l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1586z M1590.6,829.7l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1590.6,829.7z"/>
                                        <path className="st18" d="M1599.7,836.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V826l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1599.7z"/>
                                        <path className="st18" d="M1604.2,824.9v-2.7h2.6v2.7H1604.2z M1604.2,836.3V826h2.6v10.3H1604.2z" />
                                        <path className="st18" d="M1612.8,828.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1612.8z"/>
                                        <path className="st18" d="M1621.1,828.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1621.1z"/>
                                        <path className="st18" d="M1632.8,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1630.2,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1064.2,826.3v-6.1h-2.5v6.1h-2.9v-14.1h2.9v5.7h2.5v-5.7h2.9v14.1H1064.2z" />
                                        <path className="st18" d="M1073.8,826.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1073.8z M1072.5,821.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1072.5,821.9z"/>
                                        <path className="st18" d="M1082.6,826.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V816l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1082.6z"/>
                                        <path className="st18" d="M1092,826.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V820
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1092,826.3z M1091.8,819.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1091.8,819.7z"/>
                                        <path className="st18" d="M1103.7,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1101.1,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1105.5,826.3v-14.1h2.7v14.1H1105.5z" />
                                        <path className="st18" d="M1114.6,826.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V816l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1114.6z"/>
                                        <path className="st18" d="M1124.2,826.3l3.7-14.1h2.4l-3.7,14.1H1124.2z" />
                                        <path className="st18" d="M1142.9,826.3l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1142.9z M1141.3,816.7l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1141.3,816.7z"/>
                                        <path className="st18" d="M1151.7,826.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V816h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V816h2.7v10.3H1151.7z"/>
                                        <path className="st18" d="M1160.2,818.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1160.2z"/>
                                        <path className="st18" d="M1164.9,826.3v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1164.9z"/>
                                        <path className="st18" d="M1175,826.3v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8V816h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8V816h2.7v10.3H1175z M1173.4,814.3h-2v-2.1h2V814.3z M1176.5,814.3h-2v-2.1h2
			V814.3z"/>
                                        <path className="st18" d="M1184,826.3V820l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1184z"/>
                                        <path className="st18" d="M1188.6,826.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1188.6z"/>
                                        <path className="st18" d="M1202.6,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1200,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1209,826.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V816l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1209z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1219.9,674.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1219.9z M1218.3,665.1l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1218.3,665.1z"/>
                                        <path className="st18" d="M1224,674.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1224z"/>
                                        <path className="st18" d="M1230.7,674.7l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1230.7z M1235.3,668
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1235.3,668z"/>
                                        <path className="st18" d="M1247.2,670.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V670.1z M1244.6,667.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V667.6z"/>
                                        <path className="st18" d="M1248.9,663.3v-2.7h2.6v2.7H1248.9z M1248.9,674.7v-10.3h2.6v10.3H1248.9z" />
                                        <path className="st18" d="M1252.9,666.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1252.9z"/>
                                        <path className="st18" d="M1263.3,667.3l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1263.3z"/>
                                        <path className="st18" d="M1276.8,674.7v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1276.8,674.7z"/>
                                        <path className="st18" d="M1288.8,670.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V670.1z M1286.1,667.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V667.6z"/>
                                        <path className="st18" d="M1289.9,666.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1289.9z"/>
                                        <path className="st18" d="M1300.9,674.7v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1300.9z"/>
                                        <path className="st18" d="M1312.8,671.3l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1312.8,671.3z
			 M1308,670.2l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1308,670.2z"/>
                                        <path className="st18" d="M1319.4,674.7l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1319.4,674.7z M1319.2,668.1
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1319.2,668.1z"/>
                                        <path className="st18" d="M1323.8,663.3v-2.7h2.6v2.7H1323.8z M1323.8,674.7v-10.3h2.6v10.3H1323.8z" />
                                        <path className="st18" d="M1333.4,674.7l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1333.4z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M705.9,461.6v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7
			l-0.8,0.4l-0.9,0.2l-1.3,0h-0.6v5.1H705.9z M709.5,454l0.7-0.2l0.4-0.6l0.1-1l0-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1
			L709.5,454z"/>
                                        <path className="st18" d="M722.5,457h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M719.9,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M724.3,461.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H724.3z"/>
                                        <path className="st18" d="M734.9,454.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H734.9z"/>
                                        <path className="st18" d="M746.5,458.6l-0.3,1.4l-0.8,1.1l-1,0.6l-1.5,0.2l-1.1,0l-0.9-0.4l-0.7-0.4l-0.4-0.5l-0.4-0.8l-0.2-0.9l0-2.7
			l0-1.2l0.3-1.8l0.5-0.8l0.8-0.6l1.1-0.4l1.2-0.1l1.5,0.2l1.3,0.7l0.6,1l0.3,1.4l0,2L746.5,458.6z M742.3,449.5h-2v-2.1h2V449.5z
			 M743.9,454.5l-0.2-1.1l-0.3-0.3l-0.5-0.2l-0.6,0.3l-0.2,0.4l-0.1,3.5l0,1.5l0.2,0.9l0.3,0.2l0.5,0.1l0.7-0.3l0.2-0.5l0.1-2.9
			L743.9,454.5z M745.4,449.5h-2v-2.1h2V449.5z"/>
                                        <path className="st18" d="M753,461.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H753z"/>
                                        <path className="st18" d="M757.5,461.6v-14.1h2.7v14.1H757.5z" />
                                        <path className="st18" d="M762.1,450.2v-2.7h2.6v2.7H762.1z M762.1,461.6v-10.3h2.6v10.3H762.1z" />
                                        <path className="st18" d="M766.5,455.7l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L766.5,455.7z"/>
                                        <path className="st18" d="M780,461.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H780z"/>
                                        <path className="st18" d="M791.9,457h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M789.3,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M712.8,478.9l0,1.3l-0.2,1.2l-0.5,1.1l-1,0.8l-0.9,0.4l-1,0l-1,0l-0.7-0.3l-0.9-0.7l-0.6-1l-0.3-1.1l0-1.1
			l0-1.2h2.7l0,0.8l0.1,1.9l0.3,0.3l0.4,0.1l0.4-0.1l0.3-0.6l0.1-1v-10.2h2.9L712.8,478.9z"/>
                                        <path className="st18" d="M719.4,483.6l0-1.4L719,483l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H719.4z M718.1,479.2l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L718.1,479.2z"/>
                                        <path className="st18" d="M728.2,483.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H728.2z"/>
                                        <path className="st18" d="M732.8,483.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H732.8z"/>
                                        <path className="st18" d="M746.9,479H742l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M744.2,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M752.6,476.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2L749,483l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H752.6z"/>
                                        <path className="st18" d="M756.6,483.6v-1.9l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H756.6z" />
                                        <path className="st18" d="M765.6,472.2v-2.7h2.6v2.7H765.6z M765.6,483.6v-10.3h2.6v10.3H765.6z" />
                                        <path className="st18" d="M777.6,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M775,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M779.3,483.6v-14.1h2.7v14.1H779.3z" />
                                        <path className="st18" d="M791.3,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M788.7,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M433.8,456.9l0,1.3l-0.2,1.2l-0.5,1.1l-1,0.8l-0.9,0.4l-1,0l-1,0l-0.7-0.3l-0.9-0.7l-0.6-1l-0.3-1.1l0-1.1
			l0-1.2h2.7l0,0.8l0.1,1.9l0.3,0.3l0.4,0.1l0.4-0.1l0.3-0.6l0.1-1v-10.2h2.9L433.8,456.9z"/>
                                        <path className="st18" d="M440.4,461.6l0-1.4L440,461l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H440.4z M439.1,457.2l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L439.1,457.2z"/>
                                        <path className="st18" d="M449.3,461.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H449.3z"/>
                                        <path className="st18" d="M453.8,461.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H453.8z"/>
                                        <path className="st18" d="M467.9,457H463l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M465.2,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M473.6,454.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H473.6z"/>
                                        <path className="st18" d="M477.9,465.3v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L477.9,465.3z M480.6,457l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V457z"/>
                                        <path className="st18" d="M487.1,461.6v-14.1h2.7v14.1H487.1z" />
                                        <path className="st18" d="M496.1,461.6l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H496.1z M494.8,457.2l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L494.8,457.2z"/>
                                        <path className="st18" d="M505,461.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H505z"/>
                                        <path className="st18" d="M514.2,461.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H514.2z"/>
                                        <path className="st18" d="M523.2,461.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H523.2z"/>
                                        <path className="st18" d="M535.1,461.6l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L535.1,461.6z M530.4,456.2l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L530.4,456.2z"/>
                                        <path className="st18" d="M440,483.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H440z"/>
                                        <path className="st18" d="M449,483.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H449z"/>
                                        <path className="st18" d="M458.4,483.6l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L458.4,483.6z M458.2,477
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L458.2,477z"/>
                                        <path className="st18" d="M468.4,469.4h4.3l0.6,0l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2l0.8,0.3
			l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5l-0.6,0.3l-1.3,0.1h-4.6V469.4z M471.9,475.3l0.8-0.1l0.3-0.2l0.3-0.3l0.1-1.4
			l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4H471.9z M472.1,481.2l0.8-0.1l0.4-0.3l0.2-0.8l0-0.8l-0.3-1.2l-0.5-0.3l-0.8-0.1h-0.9v3.7
			H472.1z"/>
                                        <path className="st18" d="M482.8,483.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H482.8z"/>
                                        <path className="st18" d="M492,483.6l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L492,483.6z M491.8,477l-0.2-1.2
			l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3L491.8,477z"/>
                                        <path className="st18" d="M503.7,483.6l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5L500,483l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L503.7,483.6z M499,478.2l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L499,478.2z"/>
                                        <path className="st18" d="M512.9,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M510.3,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M514.1,475.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H514.1z"/>
                                        <path className="st18" d="M524.5,476.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H524.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M153.6,319.6l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6l-1,0L148,324l-1.3-0.7l-0.6-0.6l-0.5-1.1
			l-0.1-0.7l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6
			L153.6,319.6z M150.6,314.4l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9l0,2.1l0,3.2l0,1.1l0.2,1l0.5,0.5
			l0.5,0.1l0.6-0.2l0.4-0.5l0.1-1.1l0-1.7V314.4z"/>
                                        <path className="st18" d="M155.6,327.7v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L155.6,327.7z M158.2,319.3l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V319.3z"/>
                                        <path className="st18" d="M172.1,319.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V319.4z M169.5,316.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V316.8z"/>
                                        <path className="st18" d="M173.8,324l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H173.8z"/>
                                        <path className="st18" d="M185,324l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H185z M183.7,319.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L183.7,319.6z"/>
                                        <path className="st18" d="M188.6,315.4v-1.9h1V311h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H188.6z"/>
                                        <path className="st18" d="M195.1,312.5v-2.7h2.6v2.7H195.1z M195.1,324v-10.3h2.6V324H195.1z" />
                                        <path className="st18" d="M201.3,324l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H201.3z" />
                                        <path className="st18" d="M215.3,319.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V319.4z M212.7,316.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V316.8z"/>
                                        <path className="st18" d="M222.8,324v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2l-1.3,0h-0.6v5.1H222.8z M226.4,316.4l0.7-0.2l0.4-0.6l0.1-1l0-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1L226.4,316.4z"/>
                                        <path className="st18" d="M232,324v-14.1h2.7V324H232z" />
                                        <path className="st18" d="M241.1,324l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H241.1z M239.8,319.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L239.8,319.6z"/>
                                        <path className="st18" d="M249.9,324V318l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L248,318v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H249.9z"/>
                                        <path className="st18" d="M259.1,324v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V324H259.1z"/>
                                        <path className="st18" d="M268.2,324V318l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H268.2z"/>
                                        <path className="st18" d="M280,324l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L280,324z M275.4,318.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L275.4,318.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M443.3,308.6l-0.1,1.5l-0.4,1.1l-0.5,0.7l-0.6,0.5l-1.5,0.6l-1,0l-1.5-0.1l-1.3-0.7l-0.6-0.6l-0.5-1.1
			l-0.1-0.7l-0.2-4.6l0.1-2.3l0.1-1.2l0.2-1.1l0.5-0.9l0.7-0.7l1-0.4l1.7-0.2l1.2,0.1l0.9,0.3l1.1,0.9l0.5,1.1l0.2,0.7l0.1,3.6
			L443.3,308.6z M440.3,303.4l-0.1-1.1l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.2l-0.5,0.9l-0.1,0.9l0,2.1l0,3.2l0,1.1l0.2,1l0.5,0.5
			l0.5,0.1l0.6-0.2l0.4-0.5l0.1-1.1l0-1.7V303.4z"/>
                                        <path className="st18" d="M445.3,313l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H445.3z"/>
                                        <path className="st18" d="M459.2,313l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L459.2,313z M454.6,307.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1L455,305l-0.3,0.7l-0.1,1.1L454.6,307.6z"/>
                                        <path className="st18" d="M465.6,313l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H465.6z M464.2,308.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L464.2,308.6z"/>
                                        <path className="st18" d="M474.4,313V307l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H474.4z"/>
                                        <path className="st18" d="M479,301.5v-2.7h2.6v2.7H479z M479,313v-10.3h2.6V313H479z" />
                                        <path className="st18" d="M487.5,305.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H487.5z"/>
                                        <path className="st18" d="M496.3,313l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8H492l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H496.3z M495,308.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L495,308.6z"/>
                                        <path className="st18" d="M500,304.4v-1.9h1V300h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H500z"/>
                                        <path className="st18" d="M506.4,301.5v-2.7h2.6v2.7H506.4z M506.4,313v-10.3h2.6V313H506.4z" />
                                        <path className="st18" d="M518.3,309.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L518.3,309.6z
			 M513.5,308.5l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L513.5,308.5z"/>
                                        <path className="st18" d="M524.7,313V307l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H524.7z"/>
                                        <path className="st18" d="M401.5,320.8l-1.4,3.3l-0.5,2.2l-0.3,3.1l0.1,1.7l0.4,2.4l0.7,2.3l0.6,1.6l0.5,0.8l-1.1,0l-0.9-1.8l-1.1-2.8
			l-0.4-2l-0.1-2.3l0.2-2.7l0.5-2l0.9-2.2l1-1.8L401.5,320.8z"/>
                                        <path className="st18" d="M408.2,324.3l-0.1-1.1l-0.3-0.7l-0.5-0.5l-0.5-0.2l-0.5,0l-0.9,0.2l-0.6,0.4l-0.3,0.6l-0.1,1l0.1,0.9
			l0.7,1.1l1.1,0.8l1.8,1.2l1,1.1l0.4,1l0.2,1.5l-0.1,0.9l-0.3,1.2l-0.6,0.8l-0.7,0.4l-0.7,0.2l-1.4,0.2l-1.1-0.1l-0.8-0.3l-0.7-0.7
			l-0.4-0.6l-0.2-0.7l-0.1-1.9h1.4l0.1,1.5l0.2,0.6l0.3,0.4l0.6,0.4l1,0.1l0.6-0.1l0.9-0.4l0.6-1l0.1-1l-0.1-1l-0.7-1.1l-1-0.9
			l-1.2-0.8l-1.4-1.1l-0.6-0.7l-0.3-0.7l-0.1-1.4l0.2-1.4l0.5-0.9l0.6-0.5l0.8-0.3l1.2-0.1l1.1,0.1l1.2,0.5l0.4,0.5l0.3,0.8l0.2,0.8
			l0,0.9H408.2z"/>
                                        <path className="st18" d="M413.5,321.7l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5H411v-1.2h1.1v-2.8H413.5z"/>
                                        <path className="st18" d="M420,325.8l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V325.8z"/>
                                        <path className="st18" d="M422.7,324.6v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4v-1.4l0-5h1.3
			V335h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6v-6.2H422.7z"/>
                                        <path className="st18" d="M430.6,320.8v8.4l2.8-4.6h1.5l-2.8,4.7l3,5.7h-1.5l-3-5.7v5.7h-1.3v-14.1H430.6z" />
                                        <path className="st18" d="M438,321.7l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1v-1.2h1.1v-2.8H438z"/>
                                        <path className="st18" d="M442.2,324.6v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4v-1.4l0-5h1.3
			V335h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6v-6.2H442.2z"/>
                                        <path className="st18" d="M452.4,325.8l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V325.8z"/>
                                        <path className="st18" d="M455.1,330.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H455.1z M458.2,328.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H458.2z"/>
                                        <path className="st18" d="M467.2,335h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V335z"/>
                                        <path className="st18" d="M475.9,324.6v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4v-1.4l0-5h1.3
			V335h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6v-6.2H475.9z"/>
                                        <path className="st18" d="M488,335h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3v-10.4h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V335z"/>
                                        <path className="st18" d="M495.9,320.8V335h-1.2v-0.8l-0.9,0.7l-0.6,0.3l-0.7,0l-0.9-0.1l-0.6-0.4l-0.5-0.6l-0.4-1.4l0-1.4l0-2.4
			l0.1-2l0.2-1.1l0.5-0.7l0.6-0.4l0.9-0.2l1.2,0.3l0.7,0.6v-4.5H495.9z M491.6,330.4l0,1.7l0.2,1.2l0.4,0.6l0.3,0.2l0.5,0.1l0.9-0.3
			l0.5-0.7l0.2-1.1l0-1.2l-0.1-3.8l-0.4-0.9l-0.5-0.3l-0.7-0.2l-0.8,0.4l-0.3,0.5l-0.2,2.1V330.4z"/>
                                        <path className="st18" d="M506,320.8l2.1,0.2l1,0.6l0.4,0.6l0.3,0.7l0.2,2l-0.1,1.6l-0.3,1.2l-0.6,0.8l-0.9,0.5l-1.5,0.2h-1.8v5.8
			h-1.4v-14.2H506z M504.9,328h1.8l0.9-0.1l0.6-0.3l0.5-0.9l0.2-1.3l0-1.3l-0.2-0.9l-0.2-0.4l-0.5-0.4l-0.5-0.2l-2.6-0.1V328z"/>
                                        <path className="st18" d="M515.6,325.8l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V325.8z"/>
                                        <path className="st18" d="M522.6,332.1l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L522.6,332.1z M518.2,330.9l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L518.2,330.9z"/>
                                        <path className="st18" d="M529.7,324.6v1l-4.2,8.2h4.2v1.1h-5.6v-1l4.2-8.2h-3.6v-1.2H529.7z" />
                                        <path className="st18" d="M532.8,330.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H532.8z M536,328.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3l-0.3,0.5
			l-0.1,0.5l-0.1,1.4v0.8H536z"/>
                                        <path className="st18" d="M543.1,327.5l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H543.1z"/>
                                        <path className="st18" d="M550.2,327.5l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H550.2z"/>
                                        <path className="st18" d="M554.8,330.1l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H554.8z M558,328.9l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3l-0.3,0.5
			l-0.1,0.5l-0.1,1.4v0.8H558z"/>
                                        <path className="st18" d="M560.7,338.3l0.5-0.9l0.4-1l0.9-2.6l0.3-1.6l0.2-2.4l-0.1-1.7l-0.3-1.7l-0.6-2.5l-0.6-1.6l-0.7-1.4l1.1,0
			l1.2,2.4l0.9,2.6l0.3,1.7l0.1,2.1l-0.2,2.8l-0.5,1.9l-0.9,2.2l-1,1.8H560.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M184.1,209.8l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L184.1,209.8z"/>
                                        <path className="st18" d="M188.2,211.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H188.2z"/>
                                        <path className="st18" d="M194.6,219.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H194.6z"/>
                                        <path className="st18" d="M205.8,219.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H205.8z M204.4,215.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L204.4,215.5z"/>
                                        <path className="st18" d="M209.4,211.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9H213v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H209.4z"/>
                                        <path className="st18" d="M223.2,215.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L223,218l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L216,218l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V215.3z M220.6,212.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V212.8z"/>
                                        <path className="st18" d="M232.3,219.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L232.3,219.9z M227.6,214.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L227.6,214.6z"/>
                                        <path className="st18" d="M234.1,208.5v-2.7h2.6v2.7H234.1z M234.1,219.9v-10.3h2.6v10.3H234.1z" />
                                        <path className="st18" d="M246.1,215.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V215.3z M243.4,212.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V212.8z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M144.1,95.8l-0.1,2l-0.2,0.9l-0.4,0.8l-0.5,0.6l-0.8,0.5l-1,0.4l-1.3,0.1l-1.6-0.1l-1.1-0.5l-0.9-0.9
			l-0.4-0.8l-0.2-0.8l-0.1-1.7v-9.5h2.8v8.5l0,1.8l0.2,1.1l0.3,0.4l0.3,0.2l0.6,0.1l0.5-0.1l0.6-0.5l0.2-0.7l0.1-1.1v-1.3l0-8.4h2.9
			V95.8z"/>
                                        <path className="st18" d="M150.8,100.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V90.5l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H150.8z"/>
                                        <path className="st18" d="M154.8,92.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H154.8z"/>
                                        <path className="st18" d="M168.6,96.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V96.3z M165.9,93.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V93.7z"/>
                                        <path className="st18" d="M170.3,100.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H170.3z"/>
                                        <path className="st18" d="M181.6,100.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V90.5l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H181.6z"/>
                                        <path className="st18" d="M193.5,96.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V96.3z M190.9,93.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V93.7z"/>
                                        <path className="st18" d="M199.8,100.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H199.8z"/>
                                        <path className="st18" d="M213.6,100.8v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2H209l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6V90.5h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L213.6,100.8z"/>
                                        <path className="st18" d="M225.5,96.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V96.3z M222.9,93.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V93.7z"/>
                                        <path className="st18" d="M231.9,100.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V90.5l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H231.9z"/>
                                        <path className="st18" d="M240.4,93.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H240.4z"/>
                                        <path className="st18" d="M244.7,104.6V90.5h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4l-0.1,1.1
			l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L244.7,104.6z M247.4,96.2l0.1,1.2l0.2,0.9
			l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V96.2z"/>
                                        <path className="st18" d="M261.2,97.5l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L261.2,97.5z M256.4,96.4l0.1,1.5
			l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6L256.4,96.4z"/>
                                        <path className="st18" d="M263,100.9V86.7h2.7v14.1H263z" />
                                        <path className="st18" d="M267.6,89.4v-2.7h2.6v2.7H267.6z M267.6,100.9V90.6h2.6v10.3H267.6z" />
                                        <path className="st18" d="M271.5,92.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H271.5z"/>
                                        <path className="st18" d="M278,89.4v-2.7h2.6v2.7H278z M278,100.9V90.6h2.6v10.3H278z" />
                                        <path className="st18" d="M287.6,100.9l-2.4-5.4v5.4h-2.6V86.7h2.6V95l2.4-4.5h3l-2.7,4.8l2.6,5.5H287.6z" />
                                        <path className="st18" d="M117.6,122.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H117.6z"/>
                                        <path className="st18" d="M126.6,122.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H126.6z"/>
                                        <path className="st18" d="M136,122.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L136,122.9z M135.8,116.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L135.8,116.3z"/>
                                        <path className="st18" d="M145.8,114l0-1.1l0.1-0.9l0.2-1.1l0.3-0.8l0.6-0.7l0.6-0.4l1.3-0.4l0.9-0.1l0.9,0.1l0.9,0.3l1.2,0.8l0.5,0.7
			l0.3,0.9l0.1,2.6H151v-0.9l-0.1-1l-0.1-0.6l-0.3-0.3l-0.6-0.2l-0.6,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.3v6.2l0.1,1l0.3,0.5l0.3,0.2
			l0.5,0.1l0.5-0.1l0.5-0.6l0.2-2.1v-0.5h2.8l-0.1,2.3l-0.4,1.4l-0.9,1.1l-1.1,0.5l-1.6,0.2l-1.3-0.1l-0.9-0.3l-0.8-0.6l-0.5-0.8
			l-0.4-1.4l-0.1-2.2L145.8,114z"/>
                                        <path className="st18" d="M163,119.5l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L163,119.5z M158.3,118.4l0.1,1.5
			l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6L158.3,118.4z"
                                        />
                                        <path className="st18" d="M164.9,122.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H164.9z"/>
                                        <path className="st18" d="M171.5,126.6v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L171.5,126.6z M174.1,118.2l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V118.2z"/>
                                        <path className="st18" d="M188,119.5l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L188,119.5z M183.2,118.4l0.1,1.5
			l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6L183.2,118.4z"
                                        />
                                        <path className="st18" d="M189.8,122.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H189.8z"/>
                                        <path className="st18" d="M201,122.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H201z M199.6,118.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L199.6,118.5z"/>
                                        <path className="st18" d="M204.6,114.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H204.6z"/>
                                        <path className="st18" d="M218.4,118.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V118.3z M215.8,115.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V115.7z"/>
                                        <path className="st18" d="M231.5,122.9l0-1.2l-0.3,0.5l-0.8,0.6l-0.6,0.3l-0.8,0l-0.8,0l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4l0-2
			l0.1-3l0.1-1.5l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3l0,0.9h-2.8v-0.6l-0.1-1.4l-0.4-0.4
			l-0.8-0.3l-0.5,0.1l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6l0,1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1l0-1.1v-0.8h-1.5V115
			h4.2v7.9H231.5z"/>
                                        <path className="st18" d="M243.3,119.5l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L243.3,119.5z
			 M238.5,118.4l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L238.5,118.4z"/>
                                        <path className="st18" d="M246.8,122.9l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H246.8z" />
                                        <path className="st18" d="M260.8,118.3H256l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V118.3z M258.2,115.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V115.7z"/>
                                        <path className="st18" d="M262.6,122.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H262.6z"/>
                                        <path className="st18" d="M273.8,122.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H273.8z"/>
                                        <path className="st18" d="M282.9,122.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H282.9z M281.5,118.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L281.5,118.5z"/>
                                        <path className="st18" d="M291.7,122.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H291.7z"/>
                                        <path className="st18" d="M296.1,117l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L296.1,117z"/>
                                        <path className="st18" d="M312.4,118.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V118.3z M309.7,115.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V115.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M409.5,619.3l-0.4,0.6l-0.5,0.4l-0.6,0.2l-0.6,0l-1-0.2l-0.6-0.3l-0.5-0.5L405,619l0-0.7l0.2-1l0.5-0.8
			l0.8-0.5l0.9-0.2l0.9,0.2l0.7,0.5l0.5,0.8l0.2,0.9L409.5,619.3z"/>
                                        <path className="st18" d="M425,625.4h-1.4l-1.1-4.5h-3.8l-1.1,4.5h-1.4l3.7-14.1h1.4L425,625.4z M422.2,619.7l-1.5-6.6l-0.6,2.5
			l-0.5,2l-0.5,2.1L422.2,619.7z"/>
                                        <path className="st18" d="M427.5,615v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V620l0-5h1.3v10.4
			h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V615H427.5z"/>
                                        <path className="st18" d="M437.1,612.3l-1,0.1l-0.4,0.4l0,2.1h1.4v1.1h-1.4v9.3h-1.3v-9.3h-1.1V615h1.1v-1.9l0-0.5l0.4-0.8l0.6-0.4
			l0.9-0.1l0.9,0V612.3z"/>
                                        <path className="st18" d="M440.2,612.1l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1V615h1.1v-2.8H440.2z"/>
                                        <path className="st18" d="M446.8,616.2l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V616.2z"/>
                                        <path className="st18" d="M452.4,625.2l-0.1-0.8l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2l-0.6-0.9l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1l-0.4-0.4l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2V617l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L452.4,625.2z M451.2,620l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L451.2,620z"/>
                                        <path className="st18" d="M460.4,615h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L460.4,615z M457.3,621.3
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V621.3z"/>
                                        <path className="st18" d="M467.7,617.9l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H467.7z"/>
                                        <path className="st18" d="M475.3,625.2l-0.1-0.8l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2L471,624l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1l-0.4-0.4l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2V617l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L475.3,625.2z M474.1,620l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L474.1,620z"/>
                                        <path className="st18" d="M480.2,611.2v4.5l0.7-0.7l1.2-0.3l1.1,0.2l0.6,0.5l0.4,0.6l0.2,0.7l0.1,2v3.1l-0.1,1.5l-0.3,1l-0.5,0.6
			l-0.8,0.4l-0.6,0.1l-0.7-0.1l-1-0.6l-0.4-0.4v0.8H479v-14.1H480.2z M480.2,622.1l0.2,1.5l0.5,0.6l0.4,0.2l0.5,0.1l0.8-0.3l0.3-0.4
			l0.2-0.8l0.1-1.1l0-1.2l0-2l-0.1-1.3l-0.3-0.9l-0.7-0.4l-0.4-0.1l-0.4,0.1l-0.5,0.3l-0.3,0.5l-0.2,1.1l-0.1,1.6L480.2,622.1z"/>
                                        <path className="st18" d="M487.2,615l0.7,3.6l0.4,2.7l0.4,2.1l0.2-1.8l0.6-2.1l0.9-4.5l1.4,0l0.9,4.4l0.5,2.1l0.3,1.8l0.4-2.6l0.5-3
			l0.5-2.7l1.2-0.1l-2,10.4l-1.4-0.1l-1-4.4l-0.4-1.7l-0.4-2.2l-0.2,1.2l-0.2,1.1l-0.3,1.3l-1,4.6H488l-2-10.3L487.2,615z"/>
                                        <path className="st18" d="M499,611.2v1.7h-1.3v-1.7H499z M499,615v10.4h-1.3V615H499z" />
                                        <path className="st18" d="M505.7,618.3l-0.2-1.5l-0.5-0.6l-0.8-0.3l-0.7,0.1l-0.4,0.3l-0.4,1l-0.1,1.4l0,3.5l0.1,1.4l0.3,0.5l0.4,0.3
			l0.6,0.2l0.4,0l0.5-0.2l0.6-0.7l0.1-1.7h1.3l-0.1,1.5l-0.3,0.9l-0.5,0.8l-0.7,0.4l-1.3,0.2h-0.6l-0.9-0.4l-0.3-0.2l-0.6-0.7
			l-0.4-1.3l0-1.9v-1.5l0.1-2.3l0.2-1l0.4-0.7l0.9-0.7l1.4-0.2l0.7,0l1.1,0.5l0.5,0.6l0.3,0.6l0.1,1.8H505.7z"/>
                                        <path className="st18" d="M510.2,611.2v8.4l2.8-4.6h1.5l-2.8,4.7l3,5.7h-1.5l-3-5.7v5.7h-1.3v-14.1H510.2z" />
                                        <path className="st18" d="M517.3,611.2v14.1H516v-14.1H517.3z" />
                                        <path className="st18" d="M521,615v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V620l0-5h1.3v10.4
			H524v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V615H521z"/>
                                        <path className="st18" d="M533.1,625.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V615h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V625.4z"/>
                                        <path className="st18" d="M539.8,615h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L539.8,615z M536.7,621.3
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V621.3z"/>
                                        <path className="st18" d="M409.5,641.3l-0.4,0.6l-0.5,0.4l-0.6,0.2l-0.6,0l-1-0.2l-0.6-0.3l-0.5-0.5L405,641l0-0.7l0.2-1l0.5-0.8
			l0.8-0.5l0.9-0.2l0.9,0.2l0.7,0.5l0.5,0.8l0.2,0.9L409.5,641.3z"/>
                                        <path className="st18" d="M418.6,633.2v12.9h4.4v1.2h-5.8v-14.1H418.6z" />
                                        <path className="st18" d="M430.6,644.5l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L430.6,644.5z M426.1,643.3l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L426.1,643.3z"/>
                                        <path className="st18" d="M437.2,637h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L437.2,637z M434.1,643.3
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V643.3z"/>
                                        <path className="st18" d="M442.1,633.2v1.7h-1.3v-1.7H442.1z M442.1,637v10.4h-1.3V637H442.1z" />
                                        <path className="st18" d="M448.2,639.9l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H448.2z"/>
                                        <path className="st18" d="M453.1,634.1l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1V637h1.1v-2.8H453.1z"/>
                                        <path className="st18" d="M457.5,633.2v1.7h-1.3v-1.7H457.5z M457.5,637v10.4h-1.3V637H457.5z" />
                                        <path className="st18" d="M461.2,633.2v8.4l2.8-4.6h1.5l-2.8,4.7l3,5.7h-1.5l-3-5.7v5.7h-1.3v-14.1H461.2z" />
                                        <path className="st18" d="M409.5,663.3l-0.4,0.6l-0.5,0.4l-0.6,0.2l-0.6,0l-1-0.2l-0.6-0.3l-0.5-0.5L405,663l0-0.7l0.2-1l0.5-0.8
			l0.8-0.5l0.9-0.2l0.9,0.2l0.7,0.5l0.5,0.8l0.2,0.9L409.5,663.3z"/>
                                        <path className="st18" d="M419.8,655.2l2.1,0.2l1,0.6l0.4,0.6l0.3,0.7l0.2,2l-0.1,1.6l-0.3,1.2l-0.6,0.8l-0.9,0.5l-1.5,0.2h-1.8v5.8
			h-1.4v-14.2H419.8z M418.6,662.4h1.8l0.9-0.1l0.6-0.3l0.5-0.9l0.2-1.3l0-1.3l-0.2-0.9l-0.2-0.4l-0.5-0.4l-0.5-0.2l-2.6-0.1V662.4z
			"/>
                                        <path className="st18" d="M429.4,660.2l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V660.2z"/>
                                        <path className="st18" d="M436.4,666.5l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L436.4,666.5z M431.9,665.3l0,1.4
			l0.2,0.9l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.3l-0.3,1.3l0,1.9L431.9,665.3z"/>
                                        <path className="st18" d="M444.2,655.2v14.1h-1.2v-0.8l-0.9,0.7l-0.6,0.3l-0.7,0l-0.9-0.1l-0.6-0.4l-0.5-0.6l-0.4-1.4l0-1.4l0-2.4
			l0.1-2l0.2-1.1l0.5-0.7l0.6-0.4l0.9-0.2l1.2,0.3l0.7,0.6v-4.5H444.2z M439.8,664.8l0,1.7l0.2,1.2l0.4,0.6l0.3,0.2l0.5,0.1l0.9-0.3
			l0.5-0.7l0.2-1.1l0-1.2l-0.1-3.8l-0.4-0.9l-0.5-0.3l-0.7-0.2l-0.8,0.4l-0.3,0.5l-0.2,2.1V664.8z"/>
                                        <path className="st18" d="M447.9,659v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V664l0-5h1.3v10.4
			h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V659H447.9z"/>
                                        <path className="st18" d="M455.8,655.2v8.4l2.8-4.6h1.5l-2.8,4.7l3,5.7h-1.5l-3-5.7v5.7h-1.3v-14.1H455.8z" />
                                        <path className="st18" d="M463.1,656.1l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1V659h1.1v-2.8H463.1z"/>
                                        <path className="st18" d="M467.4,655.2v1.7h-1.3v-1.7H467.4z M467.4,659v10.4h-1.3V659H467.4z" />
                                        <path className="st18" d="M475.5,666.5l-0.3,1.5l-0.6,0.9l-1,0.5l-1,0.1l-1.3-0.3l-0.4-0.2l-0.5-0.6l-0.4-0.6l-0.1-0.8l0-2.1l0-3
			l0.1-1.3l0.3-0.7l0.5-0.6l0.9-0.6l0.9-0.1l0.9,0.1l0.8,0.2l0.8,0.6l0.3,0.6l0.3,1.2l0,1.7L475.5,666.5z M471,665.3l0,1.4l0.2,0.9
			l0.3,0.4l0.5,0.3l0.5,0l0.6-0.1l0.5-0.4l0.3-0.5l0.2-2.5v-1.8l0-1.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3l-0.3,0.3
			l-0.3,1.3l0,1.9L471,665.3z"/>
                                        <path className="st18" d="M483.3,669.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V659h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V669.4z"/>
                                        <path className="st18" d="M489.3,661.9l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H489.3z"/>
                                        <path className="st18" d="M496.4,661.9l-0.1-1l-0.3-0.7l-0.4-0.2l-0.6-0.1l-0.4,0.1l-0.4,0.3l-0.3,0.4l-0.1,0.7l0.1,0.7l0.8,0.8l1,0.7
			l1.2,0.9l0.7,1l0.2,1.5l-0.2,1.1l-0.4,0.7l-0.5,0.5l-0.7,0.3l-1,0.1l-1.1-0.1l-0.7-0.3l-0.5-0.4l-0.5-1.1l-0.1-1.6h1.3l0,1.3
			l0.1,0.4l0.5,0.6l0.8,0.2l0.4-0.1l0.6-0.4l0.3-1.1l-0.2-1l-0.4-0.6l-0.6-0.5l-2-1.4l-0.7-1.2l-0.1-0.8l0.1-0.8l0.2-0.7l0.5-0.5
			l0.7-0.4l1.1-0.1l1.1,0.2l0.8,0.4l0.6,0.8l0.2,1.7H496.4z"/>
                                        <path className="st18" d="M501.3,656.1l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1V659h1.1v-2.8H501.3z"/>
                                        <path className="st18" d="M505.6,664.5l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			L509,669l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H505.6z M508.7,663.3l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H508.7z"/>
                                        <path className="st18" d="M513.5,659v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V664l0-5h1.3v10.4
			h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V659H513.5z"/>
                                        <path className="st18" d="M521.4,664.5l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H521.4z M524.5,663.3l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H524.5z"/>
                                        <path className="st18" d="M531.6,660.2l-0.9,0.1l-0.6,0.3l-0.4,0.5l-0.2,0.6l-0.2,1.6l0,6l-1.3,0l0-10.4l1.2,0l0,1.8l0.1-0.6l0.4-0.7
			l0.7-0.5l1.1-0.1V660.2z"/>
                                        <path className="st18" d="M534.3,659v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V664l0-5h1.3v10.4
			h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V659H534.3z"/>
                                        <path className="st18" d="M546.4,669.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V659h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V669.4z"/>
                                        <path className="st18" d="M553.1,659h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L553.1,659z M550,665.3l0.1,1.2
			l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2L551,660l-0.5,0.3
			l-0.3,0.5l-0.2,1.8V665.3z"/>
                                        <path className="st18" d="M409.5,685.3l-0.4,0.6l-0.5,0.4l-0.6,0.2l-0.6,0l-1-0.2l-0.6-0.3l-0.5-0.5L405,685l0-0.7l0.2-1l0.5-0.8
			l0.8-0.5l0.9-0.2l0.9,0.2l0.7,0.5l0.5,0.8l0.2,0.9L409.5,685.3z"/>
                                        <path className="st18" d="M418.6,677.2v6.5l4.2-6.5l1.5,0l-4.2,6.6l2.3,3.4l1.4,2.2l1.2,1.9h-1.6l-4.9-7.3v7.3h-1.3v-14.1L418.6,677.2
			z"/>
                                        <path className="st18" d="M427.5,681v7.7l0.1,0.9l0.3,0.5l0.5,0.3l0.5,0.1l0.5-0.1l0.4-0.2l0.3-0.4l0.2-0.9l0.1-1.4V686l0-5h1.3v10.4
			h-1.3v-0.8l-0.5,0.5l-0.6,0.4l-0.9,0.1l-1.1-0.3l-0.8-0.7l-0.3-0.9l-0.1-2.6V681H427.5z"/>
                                        <path className="st18" d="M439.6,691.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V681h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V691.4z"/>
                                        <path className="st18" d="M447.5,677.2v14.1h-1.2v-0.8l-0.9,0.7l-0.6,0.3l-0.7,0l-0.9-0.1l-0.6-0.4l-0.5-0.6l-0.4-1.4l0-1.4l0-2.4
			l0.1-2l0.2-1.1l0.5-0.7l0.6-0.4l0.9-0.2l1.2,0.3l0.7,0.6v-4.5H447.5z M443.2,686.8l0,1.7l0.2,1.2l0.4,0.6l0.3,0.2l0.5,0.1l0.9-0.3
			l0.5-0.7l0.2-1.1l0-1.2l-0.1-3.8l-0.4-0.9l-0.5-0.3l-0.7-0.2l-0.8,0.4l-0.3,0.5l-0.2,2.1V686.8z"/>
                                        <path className="st18" d="M451.2,686.5l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H451.2z M454.3,685.3l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H454.3z"/>
                                        <path className="st18" d="M463.3,691.4H462v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V681h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V691.4z"/>
                                        <path className="st18" d="M475.3,691.4H474v-7.5l-0.1-0.9l-0.4-0.6l-0.8-0.3l-0.9,0.3l-0.4,0.6l-0.2,1.3v7.2h-1.3v-7.7l-0.3-0.9
			l-0.4-0.5l-0.8-0.2l-0.4,0.1l-0.4,0.3l-0.4,0.5l-0.2,1.5v7h-1.3V681h1.2v0.8l0.3-0.3l0.7-0.4l1-0.2l0.6,0.1l1,0.6l0.3,0.5l0.4-0.4
			l1.1-0.6l0.7-0.1l0.6,0.1l0.9,0.5l0.4,0.5l0.2,0.5l0.2,1.6V691.4z"/>
                                        <path className="st18" d="M482,691.2l-0.1-0.8l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2l-0.6-0.9l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1l-0.4-0.4l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2V683l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L482,691.2z M480.8,686l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L480.8,686z"/>
                                        <path className="st18" d="M491.2,691.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4l-0.7-0.2l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V681h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V691.4z"/>
                                        <path className="st18" d="M497.8,691.2l-0.1-0.8l-0.3,0.3l-0.7,0.5l-1.1,0.3l-1.2-0.3l-0.3-0.2l-0.6-0.9l-0.2-1.5l0.2-1.6l0.7-1l2.2-1
			l0.8-0.4l0.4-0.7l0.1-0.7l-0.3-1L497,682l-0.7-0.1l-0.4,0l-0.5,0.3l-0.4,0.8l0,1.2h-1.2V683l0.2-0.8l0.2-0.6l0.5-0.4l0.7-0.4
			l1-0.1l1.5,0.3l0.3,0.2l0.5,0.7l0.3,1.2l0,1.1v6.3l0.2,0.7L497.8,691.2z M496.6,686l-1.4,0.6l-0.5,0.7l-0.2,1.1l0.1,0.9l0.4,0.6
			l0.4,0.2l0.5,0.1l0.4-0.1l0.7-0.3l0.3-0.5l0.2-0.6l0.1-1.8l0-1.6L496.6,686z"/>
                                        <path className="st18" d="M505.8,681h1.2v10.1l-0.1,1.9l-0.3,0.9l-0.4,0.5l-0.8,0.5l-1.1,0.1l-0.7,0l-1.1-0.4l-0.6-0.5l-0.2-0.7
			l-0.1-1.3l1.2,0v0.4l0.2,0.9l0.6,0.5l0.7,0.1l0.5-0.1l0.7-0.5l0.2-0.5l0.1-0.9l0-1.8l-0.5,0.4l-0.6,0.4l-0.8,0.1l-0.7,0l-0.4-0.2
			l-0.4-0.4l-0.7-1.1l-0.1-1.7v-2.6l0.1-2l0.1-0.8l0.5-0.7l0.6-0.5l0.9-0.2l0.8,0.1l0.5,0.2l0.8,0.7L505.8,681z M502.7,687.3
			l0.1,1.2l0.2,0.8l0.4,0.5l0.7,0.2l0.5-0.2l0.4-0.3l0.3-0.5l0.2-1.2l0-0.8l0-2.2l-0.2-1.9l-0.2-0.4l-0.5-0.4l-0.7-0.2l-0.4,0.1
			l-0.5,0.3l-0.3,0.5l-0.2,1.8V687.3z"/>
                                        <path className="st18" d="M510.6,686.5l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			l-0.5,0.5l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H510.6z M513.8,685.3l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H513.8z"/>
                                        <path className="st18" d="M526.9,691.4h-1.3v-7.5l-0.1-0.9l-0.4-0.6l-0.8-0.3l-0.9,0.3l-0.4,0.6l-0.2,1.3v7.2h-1.3v-7.7l-0.3-0.9
			l-0.4-0.5L520,682l-0.4,0.1l-0.4,0.3l-0.4,0.5l-0.2,1.5v7h-1.3V681h1.2v0.8l0.3-0.3l0.7-0.4l1-0.2l0.6,0.1l1,0.6l0.3,0.5l0.4-0.4
			l1.1-0.6l0.7-0.1l0.6,0.1l0.9,0.5l0.4,0.5l0.2,0.5l0.2,1.6V691.4z"/>
                                        <path className="st18" d="M530.6,686.5l0,2l0.1,0.6l0.3,0.9l0.5,0.4l0.8,0.1l0.6-0.1l0.4-0.3l0.4-0.8l0.1-1.2h1.3l-0.1,1.5l-0.3,1
			L534,691l-0.7,0.5l-1.1,0.1l-1.4-0.2l-0.8-0.6l-0.5-0.8l-0.2-1.1l0-1.7v-1.8l0-1.5l0.1-1.2l0.3-0.6l0.5-0.6l0.8-0.5l1.2-0.2
			l1.4,0.2l0.6,0.3l0.5,0.6l0.4,1.5l0,1.4v1.7H530.6z M533.7,685.3l0-1.1v-0.7l-0.2-0.8l-0.3-0.5l-0.5-0.2l-0.6-0.1l-0.9,0.3
			l-0.3,0.5l-0.1,0.5l-0.1,1.4v0.8H533.7z"/>
                                        <path className="st18" d="M542.7,691.4h-1.3v-7.4l-0.1-0.8l-0.1-0.6l-0.4-0.4L540,682l-1,0.3l-0.4,0.8l-0.1,1.2v7h-1.3V681h1.2v0.7
			l0.8-0.6l1.2-0.3l0.6,0l0.8,0.4l0.5,0.6l0.2,0.6l0.1,1.2V691.4z"/>
                                        <path className="st18" d="M546.7,678.1l0,2.8l1.3,0l0,1.2l-1.4,0l0,7.3l0.2,0.6l0.3,0.2l0.3,0l0.5,0v1.1l-0.9,0l-0.5,0l-0.7-0.4
			l-0.4-0.6l-0.1-0.8v-7.5h-1.1V681h1.1v-2.8H546.7z"/>
                                        <path className="st18" d="M409.5,707.3l-0.4,0.6l-0.5,0.4l-0.6,0.2l-0.6,0l-1-0.2l-0.6-0.3l-0.5-0.5L405,707l0-0.7l0.2-1l0.5-0.8
			l0.8-0.5l0.9-0.2l0.9,0.2l0.7,0.5l0.5,0.8l0.2,0.9L409.5,707.3z"/>
                                        <path className="st18" d="M418.4,713.4h-1.5v-1.7h1.5V713.4z" />
                                        <path className="st18" d="M422.2,713.4h-1.5v-1.7h1.5V713.4z" />
                                        <path className="st18" d="M425.9,713.4h-1.5v-1.7h1.5V713.4z" />
                                    </g>
                                </>}
                            </g>
                            <g id="Titles" className="outer-titles">
                                {SelectedLanguage === "en-GB" && <>
                                    <g>
                                        <path className="st18" d="M21.3608,766.52a7.7529,7.7529,0,0,1,1.35,4.751c0,3.6445-1.5654,6.3164-5.2905,7.1l-.9448-1.8086a4.9848,4.9848,0,0,0,2.1323-.4326c1.188-.5391,2.51-1.97,2.51-4.8584a7.7005,7.7005,0,0,0-.27-2.1592,3.9938,3.9938,0,0,0-3.7793-3.1045,3.4115,3.4115,0,0,0-1.8354.5127c-1.0259.6484-1.4844,1.7275-2.0781,3.9414a22.7724,22.7724,0,0,1-1.2959,4.1836,4.5141,4.5141,0,0,1-4.2378,2.7539,4.3743,4.3743,0,0,1-2.8345-.918c-2.1323-1.6738-2.1055-4.8593-2.1055-5.372a6.7858,6.7858,0,0,1,2.2134-5.4522A6.4419,6.4419,0,0,1,6.8657,764.55l.8906,1.6728a5.4452,5.4452,0,0,0-1.6464.7832,4.8829,4.8829,0,0,0-1.9434,4.1573,5.4256,5.4256,0,0,0,1.2144,3.59,2.8612,2.8612,0,0,0,2.1865.8906,2.9241,2.9241,0,0,0,1.7817-.5674,5.9489,5.9489,0,0,0,1.7813-3.4814c.4858-1.7813.9721-4.1836,2.16-5.6416a4.6841,4.6841,0,0,1,3.7251-1.7813A5.2555,5.2555,0,0,1,21.3608,766.52Z" />
                                        <path className="st18" d="M22.2246,746.7572H13.29V758.472h8.9346v1.7813H3.1675V758.472H11.67V746.7572H3.1675v-1.8086H22.2246Z" />
                                        <path className="st18" d="M19.9033,726.21a7.13,7.13,0,0,1,2.2945,2.9961,8.1949,8.1949,0,0,1,.5126,2.9961,7.17,7.17,0,0,1-2.2133,5.5332c-1.2686,1.2149-3.6983,2.7266-7.855,2.7266-1.0259,0-4.8584-.0274-7.585-2.5371a7.1434,7.1434,0,0,1-1.8354-2.6182,8.1835,8.1835,0,0,1-.54-3.1045,7.3028,7.3028,0,0,1,3.1856-6.37,11.6955,11.6955,0,0,1,6.7212-1.7822A12.6881,12.6881,0,0,1,19.9033,726.21ZM6.2178,727.8832a6.1651,6.1651,0,0,0-1.3765,1.6738,5.4691,5.4691,0,0,0-.5937,2.6455c0,4.7236,4.5615,6.3428,8.4487,6.3428.6748,0,4.6426-.0264,6.8828-2.3477a5.4593,5.4593,0,0,0,1.5117-3.914,5.0991,5.0991,0,0,0-1.7275-4.1035c-2.3213-2.1319-6.1-2.2129-6.7749-2.2129A10.3767,10.3767,0,0,0,6.2178,727.8832Z" />
                                        <path className="st18" d="M22.2246,707.2562l-8.4487,6.02V717.73h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129a5.5867,5.5867,0,0,1,2.5371-4.7236,5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1817Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678V717.73H12.21v-4.4815c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,707.8773Z" />
                                        <path className="st18" d="M4.76,695.1586H22.2246v1.8086H4.76v5.9922H3.1675v-13.82H4.76Z" />
                                        <path className="st18" d="M4.76,671.6693H22.2246v1.8086H4.76V679.47H3.1675V665.65H4.76Z" />
                                        <path className="st18" d="M22.2246,662.11H3.1675V649.6664h1.647v10.6347h6.8017v-9.6367H13.29v9.6367h7.2613v-11.31h1.6733Z" />
                                        <path className="st18" d="M22.2246,632.5482l-8.4487,6.0195v4.4542h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129a5.5867,5.5867,0,0,1,2.5371-4.7236,5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1817Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678v5.8848H12.21V638.54c0-.4853,0-.999-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,633.1693Z" />
                                        <path className="st18" d="M22.2246,611.2465l-16.1958-.1348,16.1958,6.208v1.2412L6.0288,624.9047l16.1958-.2159V626.47H3.1675v-2.1318l16.1689-6.4522L3.1675,611.7054v-2.24H22.2246Z" />
                                        <path className="st18" d="M26.5977,590.6976a16.09,16.09,0,0,1-13.1456,6.5586,17.6575,17.6575,0,0,1-5.3989-.8906,14.0458,14.0458,0,0,1-5.2094-3.0771,15.25,15.25,0,0,1-2.1324-2.51l.8907-.6485A13.08,13.08,0,0,0,4.14,592.5873a15.6116,15.6116,0,0,0,9.2042,2.6992,18.4774,18.4774,0,0,0,8.1518-1.917,12.9919,12.9919,0,0,0,2.5107-1.6465,8.0761,8.0761,0,0,0,1.5923-1.7Z" />
                                        <path className="st18" d="M21.7656,585.2416H20.0112l-5.6147-11.04-5.5606,11.04H7.1084L13.479,572.69h1.8086Z" />
                                        <path className="st18" d="M7.6279,567.5082c0-3.71-1.82-4.4805-3.85-4.62v-1.9258H22.2246v2.206H8.293a6.7858,6.7858,0,0,1,1.12,3.99v.35Z" />
                                        <path className="st18" d="M14.3428,547.3334h7.8818v1.7812H14.3428l-11.1753,7.1v-2.1328l9.4746-5.8847L3.1675,542.07v-2.1865Z" />
                                        <path className="st18" d="M20.4971,529.19a5.521,5.521,0,0,1,2.0512,4.5889,6.4357,6.4357,0,0,1-.6748,2.9687c-1.5923,3.0772-5.3715,3.1856-6.208,3.1856a7.6776,7.6776,0,0,1-2.6186-.4053,5.7676,5.7676,0,0,1-4.022-5.5332,5.4636,5.4636,0,0,1,1.8359-4.13,7.2734,7.2734,0,0,1,4.5347-1.6738H15.8v9.9873a6.6683,6.6683,0,0,0,2.16-.2432,3.9782,3.9782,0,0,0,3.1308-4.13,4.3,4.3,0,0,0-2.6992-4.13l.4321-1.4844A8.6456,8.6456,0,0,1,20.4971,529.19Zm-10.0415,4.8321a3.9834,3.9834,0,0,0,2.2407,3.6435,6.9727,6.9727,0,0,0,1.7812.4863v-8.125C11.1577,530.4047,10.4556,532.6176,10.4556,534.0219Z" />
                                        <path className="st18" d="M21.55,515.7152a2.1933,2.1933,0,0,1-1.6733.5674,3.5893,3.5893,0,0,1,1.1064.6748,5.2466,5.2466,0,0,1,1.5117,3.8867,4.0607,4.0607,0,0,1-1.1069,3.0772,3.4487,3.4487,0,0,1-2.4023.9716,3.1519,3.1519,0,0,1-2.0245-.7011c-1.1875-.9727-1.5654-2.5645-1.97-3.9688-.2163-.8095-.459-1.6191-.6211-2.4287l-.3237-1.5117h-.6748a3.2546,3.2546,0,0,0-1.2417.1348c-.8369.2695-1.7275.999-1.7007,2.9423,0,1.6465.459,2.4834,1.1069,2.9151a3.7168,3.7168,0,0,0,1.9971.4863l-.2969,1.7539a6.5793,6.5793,0,0,1-2.24-.7558,4.8391,4.8391,0,0,1-1.9707-4.292,5.3635,5.3635,0,0,1,1.377-3.9405c1.0258-.8642,2.4023-.7832,3.644-.7832h4.13c1.2417,0,2.1054.0274,2.4834-.4316a2.4276,2.4276,0,0,0,.2969-1.4844h1.2685C22.2246,514.3392,22.09,515.1761,21.55,515.7152Zm-6.1274.5674.2158.81c.6748,2.5107,1.188,4.67,1.7549,5.4521a1.9672,1.9672,0,0,0,1.5654.8106,2.1362,2.1362,0,0,0,.918-.2432,2.5934,2.5934,0,0,0,1.2143-2.3486,4.7137,4.7137,0,0,0-2.4292-4.13,5.0391,5.0391,0,0,0-2.2676-.3506Z" />
                                        <path className="st18" d="M10.6445,503.431a5.7709,5.7709,0,0,0-.081.9981,3.3036,3.3036,0,0,0,3.05,3.4824,20.5319,20.5319,0,0,0,3.05.1348h5.56v1.5927H9.3491v-1.5927H11.4a3.0322,3.0322,0,0,1-.648-.3506,3.7861,3.7861,0,0,1-1.2685-1.5117,4.3443,4.3443,0,0,1-.3237-1.836,7.2146,7.2146,0,0,1,.0537-.9443Z" />
                                        <path className="st18" d="M19.1743,495.7631a14.1093,14.1093,0,0,1,5.21,3.1035,15.4816,15.4816,0,0,1,2.1055,2.5107l-.8638.6475a13.9111,13.9111,0,0,0-2.5371-2.4561,15.6032,15.6032,0,0,0-9.2583-2.7265,18.429,18.429,0,0,0-8.125,1.89,16.6519,16.6519,0,0,0-2.51,1.62,8.8532,8.8532,0,0,0-1.5927,1.7l-.9986-.6748a16.16,16.16,0,0,1,13.1182-6.5322A17.8939,17.8939,0,0,1,19.1743,495.7631Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M22.2246,437.015H3.1675V435.18H20.5513V424.6527h1.6733Z" />
                                        <path className="st18" d="M19.9033,408.8578a7.129,7.129,0,0,1,2.2945,2.9961A8.1945,8.1945,0,0,1,22.71,414.85a7.17,7.17,0,0,1-2.2133,5.5332c-1.2686,1.2148-3.6983,2.7265-7.855,2.7265-1.0259,0-4.8584-.0273-7.585-2.5371a7.1428,7.1428,0,0,1-1.8354-2.6181,8.1839,8.1839,0,0,1-.54-3.1045,7.3027,7.3027,0,0,1,3.1856-6.37,11.6962,11.6962,0,0,1,6.7212-1.7823A12.6882,12.6882,0,0,1,19.9033,408.8578ZM6.2178,410.5306a6.166,6.166,0,0,0-1.3765,1.6739,5.4691,5.4691,0,0,0-.5937,2.6455c0,4.7236,4.5615,6.3427,8.4487,6.3427.6748,0,4.6426-.0263,6.8828-2.3476a5.4594,5.4594,0,0,0,1.5117-3.9141,5.0992,5.0992,0,0,0-1.7275-4.1035c-2.3213-2.1318-6.1-2.2129-6.7749-2.2129A10.3781,10.3781,0,0,0,6.2178,410.5306Z" />
                                        <path className="st18" d="M22.2246,389.2562,5.7588,400.431H22.2246v1.7813H3.1675V400.431l16.3037-11.31-16.3037.1357V387.474H22.2246Z" />
                                        <path className="st18" d="M8.4312,369.1146a6.1034,6.1034,0,0,0-3.293,2.0244,5.2277,5.2277,0,0,0-.9448,3.212,6.1809,6.1809,0,0,0,.5669,2.6718c.8637,1.89,3.2661,4.0489,7.99,4.0489,4.6968,0,7.0723-1.9434,7.9092-3.9131a5.9384,5.9384,0,0,0,.4316-2.376,5.0128,5.0128,0,0,0-1.5386-3.8867c-1.0258-.9444-2.645-1.7813-5.6416-1.7813v7.0176H12.2642v-8.583h9.96v1.5654H19.04a5.6933,5.6933,0,0,1,2.1591,1.4571,6.21,6.21,0,0,1,1.5117,4.2919,7.99,7.99,0,0,1-.8906,3.752c-1.7544,3.2666-5.5337,4.373-8.9887,4.373-1.0528,0-6.4512-.0273-8.9888-4.13a8.1179,8.1179,0,0,1-1.1607-4.4268c0-4.8047,3.1582-6.2891,4.8321-7.0185Z" />
                                        <path className="st18" d="M4.76,348.1605H22.2246v1.8086H4.76v5.9922H3.1675v-13.82H4.76Z" />
                                        <path className="st18" d="M22.2246,338.601H3.1675V326.1576h1.647v10.6348h6.8017v-9.6368H13.29v9.6368h7.2613v-11.31h1.6733Z" />
                                        <path className="st18" d="M22.2246,309.0394l-8.4487,6.02v4.4541h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129A5.5867,5.5867,0,0,1,5.7319,308.04a5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1816Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678v5.8848H12.21v-4.4815c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,309.6605Z" />
                                        <path className="st18" d="M22.2246,287.7377l-16.1958-.1348,16.1958,6.208v1.2412L6.0288,301.3959l16.1958-.2158v1.7812H3.1675v-2.1328l16.1689-6.4512L3.1675,288.1967v-2.24H22.2246Z" />
                                        <path className="st18" d="M26.5977,267.1888a16.09,16.09,0,0,1-13.1456,6.5586,17.6575,17.6575,0,0,1-5.3989-.8906A14.0458,14.0458,0,0,1,2.8438,269.78a15.2459,15.2459,0,0,1-2.1324-2.51l.8907-.6484a13.0819,13.0819,0,0,0,2.5375,2.457,15.6116,15.6116,0,0,0,9.2042,2.6992,18.4774,18.4774,0,0,0,8.1518-1.917,12.9919,12.9919,0,0,0,2.5107-1.6465,8.0736,8.0736,0,0,0,1.5923-1.7Z" />
                                        <path className="st18" d="M21.7656,261.7328H20.0112l-5.6147-11.04-5.5606,11.04H7.1084L13.479,249.181h1.8086Z" />
                                        <path className="st18" d="M7.6279,243.9994c0-3.71-1.82-4.4805-3.85-4.62v-1.9258H22.2246v2.206H8.293a6.7859,6.7859,0,0,1,1.12,3.99v.35Z" />
                                        <path className="st18" d="M14.3428,223.8246h7.8818v1.7812H14.3428l-11.1753,7.1v-2.1328l9.4746-5.8847-9.4746-6.127v-2.1865Z" />
                                        <path className="st18" d="M20.4971,205.681a5.5226,5.5226,0,0,1,2.0512,4.5889,6.4357,6.4357,0,0,1-.6748,2.9687c-1.5923,3.0772-5.3715,3.1856-6.208,3.1856a7.6776,7.6776,0,0,1-2.6186-.4053,5.7676,5.7676,0,0,1-4.022-5.5332,5.4636,5.4636,0,0,1,1.8359-4.13,7.2734,7.2734,0,0,1,4.5347-1.6738H15.8v9.9873a6.6654,6.6654,0,0,0,2.16-.2432,3.9782,3.9782,0,0,0,3.1308-4.13,4.3,4.3,0,0,0-2.6992-4.13l.4321-1.4844A8.6456,8.6456,0,0,1,20.4971,205.681Zm-10.0415,4.8321a3.9834,3.9834,0,0,0,2.2407,3.6435,6.9727,6.9727,0,0,0,1.7812.4863v-8.125C11.1577,206.8959,10.4556,209.1088,10.4556,210.5131Z" />
                                        <path className="st18" d="M21.55,192.2064a2.1929,2.1929,0,0,1-1.6733.5674,3.5893,3.5893,0,0,1,1.1064.6748,5.2466,5.2466,0,0,1,1.5117,3.8867,4.0607,4.0607,0,0,1-1.1069,3.0772,3.4487,3.4487,0,0,1-2.4023.9717,3.1521,3.1521,0,0,1-2.0245-.7012c-1.1875-.9727-1.5654-2.5645-1.97-3.9688-.2163-.81-.459-1.6191-.6211-2.4287l-.3237-1.5117h-.6748a3.2566,3.2566,0,0,0-1.2417.1348c-.8369.2695-1.7275.999-1.7007,2.9424,0,1.6464.459,2.4834,1.1069,2.915a3.7168,3.7168,0,0,0,1.9971.4863l-.2969,1.7539a6.5793,6.5793,0,0,1-2.24-.7558,4.8391,4.8391,0,0,1-1.9707-4.292,5.3636,5.3636,0,0,1,1.377-3.9405c1.0258-.8642,2.4023-.7832,3.644-.7832h4.13c1.2417,0,2.1054.0274,2.4834-.4316a2.4276,2.4276,0,0,0,.2969-1.4844h1.2685C22.2246,190.83,22.09,191.6674,21.55,192.2064Zm-6.1274.5674.2158.81c.6748,2.5107,1.188,4.67,1.7549,5.4521a1.967,1.967,0,0,0,1.5654.8106,2.1362,2.1362,0,0,0,.918-.2432,2.5933,2.5933,0,0,0,1.2143-2.3486,4.7137,4.7137,0,0,0-2.4292-4.13,5.0391,5.0391,0,0,0-2.2676-.3506Z" />
                                        <path className="st18" d="M10.6445,179.9222a5.7717,5.7717,0,0,0-.081.9981,3.3036,3.3036,0,0,0,3.05,3.4824,20.5319,20.5319,0,0,0,3.05.1348h5.56V186.13H9.3491v-1.5927H11.4a3.0278,3.0278,0,0,1-.648-.3506,3.7861,3.7861,0,0,1-1.2685-1.5117,4.3443,4.3443,0,0,1-.3237-1.836,7.2146,7.2146,0,0,1,.0537-.9443Z" />
                                        <path className="st18" d="M19.1743,172.2543a14.1093,14.1093,0,0,1,5.21,3.1035,15.4816,15.4816,0,0,1,2.1055,2.5107l-.8638.6475a13.9111,13.9111,0,0,0-2.5371-2.4561,15.6039,15.6039,0,0,0-9.2583-2.7265,18.429,18.429,0,0,0-8.125,1.89,16.66,16.66,0,0,0-2.51,1.62,8.8532,8.8532,0,0,0-1.5927,1.7l-.9986-.6748a16.16,16.16,0,0,1,13.1182-6.5322A17.8939,17.8939,0,0,1,19.1743,172.2543Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M791.4927,9.6093a4.0912,4.0912,0,0,1-1.4033,1.1606c-1.35.6479-2.9151.6479-5.9385.6479H779.94v8.5835h-1.7275V.9442h6.5322c1.1065,0,2.2129-.0268,3.3467.0811a4.3105,4.3105,0,0,1,3.2393,1.4306,5.5321,5.5321,0,0,1,1.2422,3.6983A5.7075,5.7075,0,0,1,791.4927,9.6093Zm-1.2149-5.6416c-.8906-1.4576-2.24-1.4576-3.7246-1.4849-.6748,0-1.35-.0269-1.998-.0269H779.94V9.8251h4.0762c.836,0,1.6465-.0269,2.4561-.0269,1.35-.0273,2.6455-.081,3.5625-1.2148a3.536,3.536,0,0,0,.7832-2.4566A4.1286,4.1286,0,0,0,790.2778,3.9677Z" />
                                        <path className="st18" d="M796.0327,20.0013V.9442h12.4434v1.647H797.8413V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M825.6743,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,816.5776.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6962,11.6962,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,825.6743,17.68ZM824.0015,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,824.0015,3.9945Z" />
                                        <path className="st18" d="M845.6006,9.6093a4.0912,4.0912,0,0,1-1.4033,1.1606c-1.35.6479-2.9151.6479-5.9385.6479h-4.2114v8.5835H832.32V.9442h6.5327c1.1065,0,2.2129-.0268,3.3467.0811a4.31,4.31,0,0,1,3.2393,1.4306,5.5321,5.5321,0,0,1,1.2422,3.6983A5.7075,5.7075,0,0,1,845.6006,9.6093Zm-1.2149-5.6416c-.8906-1.4576-2.24-1.4576-3.7246-1.4849-.6748,0-1.35-.0269-1.998-.0269h-4.6157V9.8251h4.0766c.836,0,1.6465-.0269,2.4561-.0269,1.35-.0273,2.6455-.081,3.5625-1.2148a3.536,3.536,0,0,0,.7832-2.4566A4.1286,4.1286,0,0,0,844.3857,3.9677Z" />
                                        <path className="st18" d="M850.1406,20.0013V.9442h1.835V18.328h10.5273v1.6733Z" />
                                        <path className="st18" d="M866.26,20.0013V.9442h12.4433v1.647H868.0684V9.3929h9.6367v1.6739h-9.6367V18.328h11.3095v1.6733Z" />
                                        <path className="st18" d="M891.4229,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M907.542,20.0013V.9442h12.4434v1.647H909.3506V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M931.4893,20.0013h-1.62L922.8779.9442h1.9981l5.8574,16.5737L936.7256.9442h1.917Z" />
                                        <path className="st18" d="M942.0469,20.0013V.9442H954.49v1.647H943.8555V9.3929h9.6367v1.6739h-9.6367V18.328H955.165v1.6733Z" />
                                        <path className="st18" d="M959.3,20.0013V.9442h1.835V18.328h10.5273v1.6733Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M410.22,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,401.1235.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6962,11.6962,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,410.22,17.68ZM408.5474,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,408.5474,3.9945Z" />
                                        <path className="st18" d="M429.1743,20.0013l-6.0195-8.4487h-4.4541v8.4487h-1.835V.9442h5.3711c1.0528,0,2.1328-.0268,3.2129.0274a5.5863,5.5863,0,0,1,4.7236,2.5371,5.8305,5.8305,0,0,1,.7286,2.8882,4.73,4.73,0,0,1-3.32,4.7509,8.2169,8.2169,0,0,1-2.3212.4048l6.1816,8.4487Zm-.6211-15.8178c-.8906-1.4844-2.4287-1.62-3.9678-1.62h-5.8847V9.9872h4.4814c.4854,0,.9991,0,1.5117-.0274,1.376-.0537,2.834-.1347,3.752-1.4033a3.5835,3.5835,0,0,0,.6748-2.2407A4.0477,4.0477,0,0,0,428.5532,4.1835Z" />
                                        <path className="st18" d="M448.29,6.2079a6.1038,6.1038,0,0,0-2.0245-3.293,5.2272,5.2272,0,0,0-3.2119-.9448,6.1813,6.1813,0,0,0-2.6719.5669c-1.89.8638-4.0488,3.2661-4.0488,7.99,0,4.6968,1.9434,7.0723,3.9131,7.9092a5.9358,5.9358,0,0,0,2.376.4317,5.0133,5.0133,0,0,0,3.8867-1.5386c.9443-1.0259,1.7813-2.645,1.7813-5.6416H441.272V10.0409h8.583v9.96H448.29v-3.185a5.6948,5.6948,0,0,1-1.4571,2.1592,6.2105,6.2105,0,0,1-4.292,1.5117,7.99,7.99,0,0,1-3.7519-.8906c-3.2666-1.7544-4.3731-5.5337-4.3731-8.9888,0-1.0527.0274-6.4512,4.13-8.9888A8.1186,8.1186,0,0,1,442.9722.4584c4.8047,0,6.289,3.1582,7.0185,4.832Z" />
                                        <path className="st18" d="M468.2974,20.0013l-2.1866-5.75h-8.5293l-2.1865,5.75h-1.998L461.1714.9442h1.5117l7.585,19.0571ZM461.9,3.05l-3.7246,9.7446h7.3955Z" />
                                        <path className="st18" d="M486.6567,20.0013,475.4819,3.5355V20.0013h-1.7812V.9442h1.7812l11.3106,16.3037L486.6567.9442h1.7823V20.0013Z" />
                                        <path className="st18" d="M493.7612,20.0013V.9442H495.57V20.0013Z" />
                                        <path className="st18" d="M502.0493,18.3817h11.4453v1.62H499.4858V18.8138l11.7413-16.25h-11.04V.9442h13.47V2.1859Z" />
                                        <path className="st18" d="M530.5581,20.0013l-2.1865-5.75h-8.5293l-2.1865,5.75h-1.9981L523.4321.9442h1.5117l7.585,19.0571ZM524.1606,3.05l-3.7246,9.7446h7.3955Z" />
                                        <path className="st18" d="M539.605,2.537V20.0013h-1.8086V2.537h-5.9922V.9442h13.82V2.537Z" />
                                        <path className="st18" d="M549.1646,20.0013V.9442h1.8085V20.0013Z" />
                                        <path className="st18" d="M569.7075,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,560.6108.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6954,11.6954,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,569.7075,17.68ZM568.0347,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,568.0347,3.9945Z" />
                                        <path className="st18" d="M589.3091,20.0013,578.1343,3.5355V20.0013H576.353V.9442h1.7813l11.31,16.3037L589.3091.9442h1.7822V20.0013Z" />
                                        <path className="st18" d="M609.397,20.0013l-2.1866-5.75h-8.5292l-2.1866,5.75h-1.998L602.271.9442h1.5117l7.585,19.0571ZM603,3.05l-3.7246,9.7446H606.67Z" />
                                        <path className="st18" d="M614.8013,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M638.8306,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M654.95,20.0013V.9442h12.4434v1.647H656.7583V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M678.897,20.0013h-1.62L670.2856.9442h1.9981l5.8574,16.5737L684.1333.9442h1.917Z" />
                                        <path className="st18" d="M689.4546,20.0013V.9442h12.4433v1.647H691.2632V9.3929H700.9v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M706.7075,20.0013V.9442h1.835V18.328H719.07v1.6733Z" />
                                    </g>

                                </>}
                                {SelectedLanguage === "de-DE" && <>
                                    <g>
                                        <path className="st18" d="M1.7,775.6v-1.8H12L1.7,763.5V761l7.3,7.4l11.2-8v2.2l-10,7.1l4.1,4.1h5.9v1.8H1.7z" />
                                        <path className="st18" d="M14.2,743.4c4.2,0,6.5,2.8,6.5,7s-2.2,7-6.5,7H1.7v-1.8h12.5c3.6,0,4.9-2.3,4.9-5.2c0-2.8-1.3-5.2-4.9-5.2
			H1.7v-1.8H14.2z"/>
                                        <path className="st18" d="M1.7,738.5v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,736.7v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,721.4V708h1.1l15.8,11.4v-11.3h1.6V722H19L3.3,710.6v10.8H1.7z" />
                                        <path className="st18" d="M1.7,704.3V692h1.6v10.5h6.2v-9.4h1.6v9.4h9.1v1.8H1.7z" />
                                        <path className="st18" d="M1.7,688.2v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,686.4v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,670.4v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M14.6,662.3c3.1-0.2,4.4-2.2,4.4-5.3c0-3-1.5-5.2-3.8-5.2c-5.7,0-2.1,11.3-9.4,11.3c-3.4,0-4.7-3.4-4.7-6
			c0-3.2,1-5.7,4.1-6.7l0.9,1.7c-2.4,0.8-3.4,2.7-3.4,5.1c0,1.9,1.1,4.2,3.1,4.2c5.5,0,2-11.3,9.1-11.3c3.6,0,5.7,2.6,5.7,7
			c0,3.8-1.4,6.4-5.1,7.1L14.6,662.3z"/>
                                        <path className="st18" d="M1.7,647.3v-13.7h1.6v5.9h16.9v1.8H3.3v5.9H1.7z" />
                                        <path className="st18" d="M1.7,630v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,609.7c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,609.7z"/>
                                        <path className="st18" d="M25.1,588.9c-2.4,4.4-7.4,6.5-12.4,6.5s-10-1.9-12.4-6.5h1.4c2.7,3.5,7.1,4.6,11,4.6s8.3-1.1,11-4.6H25.1z"
                                        />
                                        <path className="st18" d="M20.4,572.7l-6.1,13.4h-1.4l-6.1-13.4h1.4l5.4,11.8l5.4-11.8H20.4z" />
                                        <path className="st18" d="M4.5,566.2c0.1-1.5-0.4-3.5-2.8-3.6v-1.5h18.5v1.6h-15c0.6,1.1,0.8,2.3,0.8,3.5H4.5z" />
                                        <path className="st18" d="M1.7,543.3h13c3.6,0,6,1.4,6,5.4c0,3.5-1.7,5.6-5.1,5.6h-0.5l-0.3-1.8h0.7c2.1,0,3.6-1.2,3.6-3.8
			c0-2.7-1.7-3.6-4.3-3.6H1.7V543.3z"/>
                                        <path className="st18" d="M20.3,527.1c0,2,0,3.2-2.2,3.3v0.1c1.7,1,2.3,2.5,2.3,4.5c0,2.1-1,4.1-3.3,4.1c-2.7,0-3.4-2.4-4-4.6l-1-3.9
			H11c-1.6,0-2.3,1.2-2.3,3.2c0,2.1,1,3.1,3,3.2l-0.3,1.8c-2.7-0.5-4-2.2-4-5c0-4.7,2.7-4.7,3.9-4.7h6.1c1.6,0,1.6-0.7,1.6-1.8H20.3
			z M19,534.8c0-1.5-1-4.3-4-4.3h-1.4l1.2,4.6c0.3,1.3,0.9,2.3,2.3,2.3C18.5,537.4,19,536,19,534.8z"/>
                                        <path className="st18" d="M1.7,524.1v-1.6h7.9v-0.1c-1.6-0.8-2.2-2.3-2.2-3.8c0-3.1,1.8-4.5,4.3-4.5h8.5v1.6h-8
			c-2.5,0-3.4,1.1-3.4,3.1c0,1.9,1.2,3.6,4.1,3.6h7.3v1.6H1.7z"/>
                                        <path className="st18" d="M7.7,509.2v-1.6h1.7v-0.1c-1.3-0.9-2.1-1.8-2.1-3.5c0-0.4,0-0.7,0.1-1.1h1.4c-0.1,0.4-0.1,0.7-0.1,1.1
			c0,2.5,1.8,3.6,4.3,3.6h7.2v1.6H7.7z"/>
                                        <path className="st18" d="M23.7,501.8c-2.7-3.5-7.1-4.6-11-4.6s-8.3,1.1-11,4.6H0.3c2.4-4.4,7.4-6.5,12.4-6.5s10,1.9,12.4,6.5H23.7z"
                                        />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1.7,437v-1.8h16.9v-10.4h1.6V437H1.7z" />
                                        <path className="st18" d="M1.7,413.9l18.5-7.3v1.9l-5.6,2.1v8.3l5.6,2.3v1.9l-18.5-7.7V413.9z M13.2,411.3L4,414.8l9.1,3.6V411.3z" />
                                        <path className="st18" d="M1.7,403.3v-1.8l15.5-10.8v-0.1H1.7v-1.8h18.5v1.8L4.6,401.4v0.1h15.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,370.3c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,370.3z"/>
                                        <path className="st18" d="M1.7,364.1v-12.3h1.6v10.5h6.2v-9.4h1.6v9.4h9.1v1.8H1.7z" />
                                        <path className="st18" d="M1.7,348v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,346.2v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,330.1v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M14.6,322.1c3.1-0.2,4.4-2.2,4.4-5.3c0-3-1.5-5.2-3.8-5.2c-5.7,0-2.1,11.3-9.4,11.3c-3.4,0-4.7-3.4-4.7-6
			c0-3.2,1-5.7,4.1-6.7l0.9,1.7c-2.4,0.8-3.4,2.7-3.4,5.1c0,1.9,1.1,4.2,3.1,4.2c5.5,0,2-11.3,9.1-11.3c3.6,0,5.7,2.6,5.7,7
			c0,3.8-1.4,6.4-5.1,7.1L14.6,322.1z"/>
                                        <path className="st18" d="M1.7,307.1v-13.7h1.6v5.9h16.9v1.8H3.3v5.9H1.7z" />
                                        <path className="st18" d="M1.7,289.8V288h18.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,269.5c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,269.5z"/>
                                        <path className="st18" d="M25.1,248.7c-2.4,4.4-7.4,6.5-12.4,6.5s-10-1.9-12.4-6.5h1.4c2.7,3.5,7.1,4.6,11,4.6s8.3-1.1,11-4.6H25.1z"
                                        />
                                        <path className="st18" d="M14.3,232.5l6.1,13.4H19l-5.4-11.8l-5.4,11.8H6.8l6.1-13.4H14.3z" />
                                        <path className="st18" d="M4.5,226c0.1-1.5-0.4-3.5-2.8-3.6v-1.5h18.5v1.6h-15c0.6,1.1,0.8,2.3,0.8,3.5H4.5z" />
                                        <path className="st18" d="M1.7,203h13c3.6,0,6,1.4,6,5.4c0,3.5-1.7,5.6-5.1,5.6h-0.5l-0.3-1.8h0.7c2.1,0,3.6-1.2,3.6-3.8
			c0-2.7-1.7-3.6-4.3-3.6H1.7V203z"/>
                                        <path className="st18" d="M20.3,186.9c0,2,0,3.2-2.2,3.3v0.1c1.7,1,2.3,2.5,2.3,4.5c0,2.1-1,4.1-3.3,4.1c-2.7,0-3.4-2.4-4-4.6l-1-3.9
			H11c-1.6,0-2.3,1.2-2.3,3.2c0,2.1,1,3.1,3,3.2l-0.3,1.8c-2.7-0.5-4-2.2-4-5c0-4.7,2.7-4.7,3.9-4.7h6.1c1.6,0,1.6-0.7,1.6-1.8H20.3
			z M19,194.6c0-1.5-1-4.3-4-4.3h-1.4l1.2,4.6c0.3,1.3,0.9,2.3,2.3,2.3C18.5,197.2,19,195.8,19,194.6z"/>
                                        <path className="st18" d="M1.7,183.9v-1.6h7.9v-0.1c-1.6-0.8-2.2-2.3-2.2-3.8c0-3.1,1.8-4.5,4.3-4.5h8.5v1.6h-8
			c-2.5,0-3.4,1.1-3.4,3.1c0,1.9,1.2,3.6,4.1,3.6h7.3v1.6H1.7z"/>
                                        <path className="st18" d="M7.7,169v-1.6h1.7v-0.1c-1.3-0.9-2.1-1.8-2.1-3.5c0-0.4,0-0.7,0.1-1.1h1.4c-0.1,0.4-0.1,0.7-0.1,1.1
			c0,2.5,1.8,3.6,4.3,3.6h7.2v1.6H7.7z"/>
                                        <path className="st18" d="M23.7,161.6c-2.7-3.5-7.1-4.6-11-4.6s-8.3,1.1-11,4.6H0.3c2.4-4.4,7.4-6.5,12.4-6.5s10,1.9,12.4,6.5H23.7z"
                                        />
                                    </g>
                                    <g>
                                        <path className="st18" d="M778.2,0.5h2.2l6.1,15.5h0.1l6.1-15.5h2.2V19h-1.8V3.9h-0.1L787.2,19h-1.2L780,3.9H780V19h-1.8V0.5z" />
                                        <path className="st18" d="M800.3,0.5h1.8V19h-1.8V0.5z" />
                                        <path className="st18" d="M805.7,0.5h13.7v1.6h-5.9V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M830,0.5l7.3,18.5h-1.9l-2.1-5.6H825l-2.3,5.6h-1.9l7.7-18.5H830z M832.7,12l-3.5-9.1l-3.6,9.1H832.7z" />
                                        <path className="st18" d="M840.3,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M842.1,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M857.9,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			h-8.3V0.5z M859.7,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M859.7,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M876.4,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M893.8,0.5h1.8V19h-1.8V0.5z" />
                                        <path className="st18" d="M899.3,0.5H913v1.6H907V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M916.3,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M933.4,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M935.2,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M951,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			H951V0.5z M952.8,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M952.8,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M969.5,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M986.1,0.5h13.4v1.1L988,17.4h11.3V19h-13.9v-1.1L997,2.1h-10.8V0.5z" />
                                        <path className="st18" d="M1010.8,0c5.6,0,8.2,4.8,8.2,9.7c0,4.9-2.6,9.7-8.2,9.7c-5.6,0-8.2-4.8-8.2-9.7
			C1002.6,4.9,1005.2,0,1010.8,0z M1010.8,17.9c4.5,0,6.4-4.3,6.4-8.1c0-3.8-1.9-8.1-6.4-8.1c-4.5,0-6.4,4.3-6.4,8.1
			C1004.4,13.6,1006.3,17.9,1010.8,17.9z"/>
                                        <path className="st18" d="M1036.6,5.6c-0.9-2.8-2.6-4-5.3-4c-4.8,0-6.9,4.3-6.9,8.1c0,3.8,1.9,8.1,6.4,8.1c4.1,0,5.7-3.2,5.7-6v-1h-7
			V9.3h8.6V19h-1.6v-2.7h-0.1c-0.9,2.3-3.3,3.1-5.7,3.1c-5.5,0-8.2-4.4-8.2-9.7c0-5,2.7-9.7,8.5-9.7c3.6,0,5.8,1.5,7.1,4.7
			L1036.6,5.6z"/>
                                        <path className="st18" d="M1042.7,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M1060.2,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8l-10.8-15.5h-0.1V19h-1.8V0.5z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M385.8,13c0,4.2-2.8,6.5-7,6.5s-7-2.2-7-6.5V0.5h1.8V13c0,3.6,2.3,4.9,5.2,4.9c2.8,0,5.2-1.3,5.2-4.9V0.5
			h1.8V13z"/>
                                        <path className="st18" d="M391.2,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L393,3.5H393V19h-1.8V0.5z" />
                                        <path className="st18" d="M409.4,0.5H423v1.6h-5.9V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M426.4,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M443.5,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M445.3,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M461.4,0.5h1.8L474,16.1h0.1V0.5h1.8V19h-1.8L463.3,3.5h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M481.1,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1H494V19h-12.9V0.5z" />
                                        <path className="st18" d="M498.5,0.5h1.8v8.2h11.3V0.5h1.8V19h-1.8v-8.7h-11.3V19h-1.8V0.5z" />
                                        <path className="st18" d="M518.9,0.5h2.2l6.1,15.5h0.1l6.1-15.5h2.2V19h-1.8V3.9h-0.1L527.9,19h-1.2l-5.9-15.1h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M540.7,0.5H553v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M558.1,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L560,3.5h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M578.9,13.5c0.2,3.1,2.2,4.4,5.3,4.4c3,0,5.2-1.5,5.2-3.8c0-5.7-11.3-2.1-11.3-9.4c0-3.4,3.4-4.7,6-4.7
			c3.2,0,5.7,1,6.7,4.1l-1.7,0.9c-0.8-2.4-2.7-3.4-5.1-3.4c-1.9,0-4.2,1.1-4.2,3.1c0,5.5,11.3,2,11.3,9.1c0,3.6-2.6,5.7-7,5.7
			c-3.8,0-6.4-1.4-7.1-5.1L578.9,13.5z"/>
                                        <path className="st18" d="M595.3,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			h-8.3V0.5z M597.2,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M597.2,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M613.9,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M630.5,0.5h13.4v1.1l-11.5,15.8h11.3V19h-13.9v-1.1l11.4-15.8h-10.8V0.5z" />
                                        <path className="st18" d="M655.2,0c5.6,0,8.2,4.8,8.2,9.7c0,4.9-2.6,9.7-8.2,9.7c-5.6,0-8.2-4.8-8.2-9.7C647,4.9,649.6,0,655.2,0z
			 M655.2,17.9c4.5,0,6.4-4.3,6.4-8.1c0-3.8-1.9-8.1-6.4-8.1c-4.5,0-6.4,4.3-6.4,8.1C648.8,13.6,650.7,17.9,655.2,17.9z"/>
                                        <path className="st18" d="M681,5.6c-0.9-2.8-2.6-4-5.3-4c-4.8,0-6.9,4.3-6.9,8.1c0,3.8,1.9,8.1,6.4,8.1c4.1,0,5.7-3.2,5.7-6v-1h-7V9.3
			h8.6V19h-1.6v-2.7h-0.1c-0.9,2.3-3.3,3.1-5.7,3.1c-5.5,0-8.2-4.4-8.2-9.7c0-5,2.7-9.7,8.5-9.7c3.6,0,5.8,1.5,7.1,4.7L681,5.6z"/>
                                        <path className="st18" d="M687.1,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M704.6,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L706.5,3.5h-0.1V19h-1.8V0.5z" />
                                    </g>
                                </>}
                            </g>
                            <g id="Paths">
                                <line id="Path_9_" className="st19" x1="1539.5" y1="253.2" x2="1539.5" y2="278.7" />
                                <line id="Path_10_" className="st19" x1="1539.5" y1="356.7" x2="1539.5" y2="426.2" />
                                <line id="Path_11_" className="st19" x1="1539.5" y1="504.3" x2="1539.5" y2="585.9" />
                                <line id="Path_12_" className="st19" x1="1539.5" y1="756.6" x2="1539.5" y2="779.9" />
                                <line id="Path_13_" className="st19" x1="1417.4" y1="819" x2="1399.6" y2="819" />
                                <line id="Path_14_" className="st19" x1="890.9" y1="819" x2="871.6" y2="819" />
                                <line id="Path_34_" className="st19" x1="359.7" y1="317.8" x2="335.8" y2="317.8" />
                                <line id="Path_35_" className="st19" x1="627.5" y1="318" x2="603.8" y2="318" />
                                <line id="Path_36_" className="st19" x1="890.8" y1="318" x2="871.6" y2="318" />
                                <line id="Path_37_" className="st19" x1="1134.7" y1="318" x2="1154.7" y2="318" />
                                <line id="Path_38_" className="st19" x1="1398.6" y1="214.9" x2="1417.4" y2="215" />
                                <line id="Path_43_" className="st19" x1="1399.7" y1="671.4" x2="1417.4" y2="671.4" />
                                <line id="Path_42_" className="st19" x1="1278" y1="568.1" x2="1277.9" y2="586" />
                                <line id="Path_41_" className="st19" x1="1277.8" y1="756.4" x2="1277.7" y2="781.1" />
                                <line id="Path_40_" className="st19" x1="1012.3" y1="356.8" x2="1012.3" y2="370.5" />
                                <line id="Path_39_" className="st19" x1="787" y1="253.2" x2="786.9" y2="278.6" />
                                <path id="Path_46_" className="st19" d="M786.9,174.5" />
                                <path id="Path_45_" className="st19" d="M787,149" />
                                <line id="Path_44_" className="st19" x1="711.6" y1="253.2" x2="711.5" y2="278.6" />
                                <line id="Path_53_" className="st19" x1="519.7" y1="253.2" x2="519.6" y2="278.6" />
                                <line id="Path_51_" className="st19" x1="1316.1" y1="145" x2="1316.1" y2="175" />
                                <line id="Path_52_" className="st19" x1="1240.8" y1="145" x2="1240.7" y2="175" />
                                <line id="Path_50_" className="st19" x1="213.8" y1="144.9" x2="213.7" y2="175.1" />
                                <line id="Path_49_" className="st19" x1="213.8" y1="278.6" x2="213.8" y2="253.2" />
                                <line id="Path_48_" className="st19" x1="359.7" y1="466.9" x2="296.4" y2="466.8" />
                                <line id="Path_47_" className="st19" x1="359.7" y1="569.1" x2="296.4" y2="569.1" />
                                <line id="Path_54_" className="st19" x1="627.5" y1="466.9" x2="603.7" y2="466.8" />
                                <line id="Path_55_" className="st19" x1="1155.8" y1="720" x2="605.8" y2="719.9" />
                                <line id="Path_56_" className="st19" x1="481.4" y1="356.8" x2="481.3" y2="426.2" />
                                <line id="Path_57_" className="st19" x1="481.2" y1="504.3" x2="481.1" y2="531.3" />
                                <line id="Path_58_" className="st19" x1="627.6" y1="214.9" x2="335.9" y2="214.9" />
                                <line id="Path_59_" className="st19" x1="1154.7" y1="214.9" x2="871.5" y2="214.9" />
                                <line id="Path_60_" className="st19" x1="1154.7" y1="227.7" x2="871.5" y2="227.7" />
                                <path id="Path_18_" className="st19" d="M335.8,106.7h370.3c3.1,0,5.7,2.6,5.7,5.7v62.8" />
                                <path id="Path_17_" className="st19" d="M71.3,426.2V323.5c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_15_" className="st19" d="M627.5,819.4H486.9c-3.1,0-5.7-2.6-5.7-5.7v-56.6" />
                                <path id="Path_16_" className="st19" d="M490.9,819.4H179.6c-3.1,0-5.7-2.6-5.7-5.7V651.8" />
                                <path id="Path_33_" className="st19" d="M1012.7,448.6v12.5c0,3.1-2.6,5.7-5.7,5.7H871.7" />
                                <path id="Path_32_" className="st19" d="M71.2,329.8v-110c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_31_" className="st19" d="M519.7,278.6v-45.1c0-3.1,2.6-5.7,5.7-5.7h102.2" />
                                <path id="Path_30_" className="st19" d="M444.6,278.6v-45.1c0-3.1-2.6-5.7-5.7-5.7h-103" />
                                <path id="Path_29_" className="st19" d="M1154.7,92.1l-629.2,0.1c-3.1,0-5.7,2.6-5.7,5.7l-0.1,98.5c0,3.1-2.6,5.7-5.7,5.7H335.9" />
                                <path id="Path_28_" className="st19" d="M787.3,504.4l0.2,46.3c0,3.1,2.6,5.7,5.7,5.7l382.2-0.4c3.1,0,5.7,2.6,5.7,5.7v24.6" />
                                <path id="Path_27_" className="st19" d="M603.7,556.4l102.2,0.4c3.1,0,5.7-2.6,5.7-5.7v-46.8" />
                                <path id="Path_26_" className="st19" d="M444.6,426.2v-26.8c0-3.1-2.6-5.7-5.7-5.7l-219.2,0.1c-3.1,0-5.7-2.6-5.7-5.7v-31.5" />
                                <path id="Path_25_" className="st19" d="M359.6,555.9l-39.9,0.1c-3.1,0-5.7-2.6-5.7-5.7V356.8" />
                                <path id="Path_24_" className="st19" d="M1501.4,586v-57.4c0-3.1-2.6-5.7-5.7-5.7h-101.6" />
                                <path id="Path_23_" className="st19" d="M1501.4,426.2v-10.9c0-3.1-2.6-5.7-5.7-5.7h-361" />
                                <path id="Path_22_" className="st19" d="M1520.4,426.2v-24.1c0-3.1-2.6-5.7-5.7-5.7h-154.2H1320c-3.1,0-5.7-2.6-5.7-5.7v-34" />
                                <path id="Path_21_" className="st19" d="M1238.9,356.7V460c0,3.1-2.6,5.7-5.7,5.7H1155h-13.1c-3.1,0-5.7,2.6-5.7,5.7V781" />
                                <path id="Path_20_" className="st19" d="M71.3,224.8v-113c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_19_" className="st19" d="M1012.7,278.8l-0.3-32.1c0-3.1,2.6-5.7,5.7-5.7h136.1" />
                                <rect id="Path_8_" x="1031.1" y="209.3" className="st17" width="1.3" height="0.4" />
                                <path id="Path_7_" className="st19" d="M871.5,106.5" />
                                <path id="Path_6_" className="st19" d="M1154.7,106.5" />
                                <path id="Path_5_" className="st19" d="M799.9,175.1v-49.6c0-3.1,2.6-5.7,5.7-5.7h348.8" />
                                <path id="Path_4_" className="st19" d="M786.9,175.1v-63.4c0-3.1,2.6-5.7,5.7-5.7h361.8" />
                                <rect id="Path_3_" x="1031.1" y="88.1" className="st17" width="1.3" height="0.4" />
                                <path id="Path_2_" className="st19" d="M1314.4,381.1" />
                                <path id="Path" className="st19" d="M1314.4,355.6" />
                            </g>
                            <g id="Arrows">
                                <polyline id="Arrow_9_" className="st20" points="1406.7,828.3 1412.7,838 1406.7,846.8 	" />
                                <polyline id="Arrow_10_" className="st20" points="614,477.8 620,487.5 614,496.3 	" />
                                <polyline id="Arrow_11_" className="st20" points="344.8,477.8 350.8,487.5 344.8,496.3 	" />
                                <polyline id="Arrow_12_" className="st20" points="344.8,577.8 350.8,587.5 344.8,596.4 	" />
                                <polyline id="Arrow_13_" className="st20" points="344.8,530.4 350.8,540.1 344.8,549 	" />
                                <polyline id="Arrow_14_" className="st20" points="80,328 86,337.7 80,346.5 	" />
                                <polyline id="Arrow_15_" className="st20" points="80,222.5 86,232.2 80,241 	" />
                                <polyline id="Arrow_16_" className="st20" points="80,114.1 86,123.8 80,132.6 	" />
                                <polyline id="Arrow_23_" className="st20" points="1144.8,729.3 1150.8,739 1144.8,747.8 	" />
                                <polyline id="Arrow_22_" className="st20" points="613,329.5 619,339.2 613,348.1 	" />
                                <polyline id="Arrow_21_" className="st20" points="879.3,329.5 885.3,339.2 879.3,348.1 	" />
                                <polyline id="Arrow_20_" className="st20" points="1142.2,329.5 1148.2,339.2 1142.2,348.1 	" />
                                <polyline id="Arrow_19_" className="st20" points="1142.2,218.3 1148.2,228 1142.2,236.8 	" />
                                <polyline id="Arrow_18_" className="st20" points="1140.2,97 1146.2,106.7 1140.2,115.6 	" />
                                <polyline id="Arrow_17_" className="st20" points="1140.2,65.7 1146.2,75.4 1140.2,84.2 	" />
                                <polyline id="Arrow_24_" className="st20" points="1548.7,770.5 1558.4,764.5 1567.2,770.5 	" />
                                <polyline id="Arrow_25_" className="st20" points="721.1,519.5 730.8,513.5 739.6,519.5 	" />
                                <polyline id="Arrow_26_" className="st20" points="453.9,770.1 463.6,764.1 472.5,770.1 	" />
                                <polyline id="Arrow_27_" className="st20" points="146.6,666.1 156.3,660.1 165.1,666.1 	" />
                                <polyline id="Arrow_34_" className="st20" points="721.1,268.8 730.8,262.8 739.6,268.8 	" />
                                <polyline id="Arrow_33_" className="st20" points="776,262.8 766.3,268.8 757.5,262.8 	" />
                                <polyline id="Arrow_47_" className="st20" points="702.9,162.5 693.2,168.5 684.4,162.5 	" />
                                <polyline id="Arrow_48_" className="st20" points="827.2,162.5 817.5,168.5 808.7,162.5 	" />
                                <polyline id="Arrow_35_" className="st20" points="437,263.8 427.3,269.8 418.4,263.8 	" />
                                <polyline id="Arrow_49_" className="st20" points="203.8,262.8 194.1,268.8 185.2,262.8 	" />
                                <polyline id="Arrow_50_" className="st20" points="203.8,157.6 194.1,163.6 185.2,157.6 	" />
                                <polyline id="Arrow_36_" className="st20" points="512.1,263.8 502.4,269.8 493.5,263.8 	" />
                                <polyline id="Arrow_37_" className="st20" points="473.3,412.6 463.6,418.6 454.8,412.6 	" />
                                <polyline id="Arrow_40_" className="st20" points="434.1,412.6 424.4,418.6 415.6,412.6 	" />
                                <polyline id="Arrow_38_" className="st20" points="473.3,515.3 463.6,521.3 454.8,515.3 	" />
                                <polyline id="Arrow_39_" className="st20" points="1267.7,765.5 1258,771.5 1249.2,765.5 	" />
                                <polyline id="Arrow_46_" className="st20" points="1126.6,765.5 1117,771.5 1108.1,765.5 	" />
                                <polyline id="Arrow_45_" className="st20" points="1267.7,570.9 1258,576.9 1249.2,570.9 	" />
                                <polyline id="Arrow_44_" className="st20" points="1002.4,360.9 992.7,366.9 983.8,360.9 	" />
                                <polyline id="Arrow_43_" className="st20" points="1002.4,264.6 992.7,270.6 983.8,264.6 	" />
                                <polyline id="Arrow_42_" className="st20" points="1172.4,570.9 1162.8,576.9 1153.9,570.9 	" />
                                <polyline id="Arrow_41_" className="st20" points="1548.7,517.9 1558.4,511.9 1567.2,517.9 	" />
                                <polyline id="Arrow_30_" className="st20" points="1548.7,370.9 1558.4,364.9 1567.2,370.9 	" />
                                <polyline id="Arrow_31_" className="st20" points="1326.8,370.9 1336.5,364.9 1345.3,370.9 	" />
                                <polyline id="Arrow_32_" className="st20" points="1548.7,267.2 1558.4,261.2 1567.2,267.2 	" />
                                <polyline id="Arrow_29_" className="st20" points="1326.5,162.2 1336.2,156.2 1345,162.2 	" />
                                <polyline id="Arrow_28_" className="st20" points="1227.3,156.2 1217.6,162.2 1208.8,156.2 	" />
                                <polyline id="Arrow_7_" className="st20" points="883.7,810.7 877.7,801 883.7,792.1 	" />
                                <polyline id="Arrow_8_" className="st20" points="1148.6,398.9 1142.6,389.2 1148.6,380.4 	" />
                                <polyline id="Arrow_5_" className="st20" points="888.3,206.1 882.3,196.4 888.3,187.5 	" />
                                <polyline id="Arrow_6_" className="st20" points="350.8,308.3 344.8,298.6 350.8,289.8 	" />
                                <polyline id="Arrow_4_" className="st20" points="884,458 878,448.3 884,439.5 	" />
                                <polyline id="Arrow_3_" className="st20" points="1409.2,514.3 1403.2,504.6 1409.2,495.8 	" />
                                <polyline id="Arrow_2_" className="st20" points="1411.2,663.4 1405.2,653.7 1411.2,644.9 	" />
                                <polyline id="Arrow_1_" className="st20" points="1411.5,205.7 1405.5,196 1411.5,187.1 	" />
                                <polyline id="Arrow" className="st20" points="612.6,185.3 618.6,195 612.6,203.9 	" />
                            </g>
                            <g className="hover">
                                <rect id="Rectangle_-_4" x="747.9" y="465.8" className="st-transparent" width="926.9" height="425.2"
                                    onMouseEnter={() => this.hoverPie(28)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 28)} />
                                <rect id="Rectangle_-_3" x="40.6" y="465.8" className="st-transparent" width="707.2" height="425.2"
                                    onMouseEnter={() => this.hoverPie(27)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 27)} />
                                <rect id="Rectangle_-_2-2" x="747.9" y="40.6" className="st-transparent" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_2-1" x="747.9" y="40.6" className="st-transparent" width="926.9" height="425.2"
                                    onMouseEnter={() => this.hoverPie(26)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 26)} />
                                <rect id="Rectangle_-_1" x="40.6" y="40.6" className="st-transparent" width="707.2" height="425.2"
                                    onMouseEnter={() => this.hoverPie(25)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 25)} />
                                <path id="Rectangle_-_24" className="st-transparent" d="M290.8,651.9H58.1c-3.1,0-5.7-2.5-5.7-5.7V431.9c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v214.3C296.4,649.3,293.9,651.9,290.8,651.9z"
                                    onMouseEnter={() => this.hoverPie(24)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 24)} />
                                <path id="Rectangle_-_23" className="st-transparent" d="M598,757H365.4c-3.1,0-5.7-2.5-5.7-5.7V537c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v214.3C603.7,754.4,601.2,757,598,757z"
                                    onMouseEnter={() => this.hoverPie(23)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 23)} />
                                <path id="Rectangle_-_22" className="st-transparent" d="M865.9,858.1H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,855.5,869,858.1,865.9,858.1z"
                                    onMouseEnter={() => this.hoverPie(22)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 22)} />
                                <path id="Rectangle_-_21" className="st-transparent" d="M1230.8,489.7c17,3.1,11.1-2.6,24.1-5.1c17.6,0.5,35.8-5.1,53.4-3.3 c10,1.2,3.7,5.2,13.4,6.1c18.8,4.7,3,3.5,44.3,6.6c5.8,0.5,16.4,4.2,16.4,4.2c9.4,11,5.7,5.2,10.9,16.9c0.6,1.4,1.3,19.2,1.3,19.2 c-4.6,11.7-9.6,8.5-22.7,14.8c-4.9,5.6-20.3,8.1-28.8,10.9c-11.2,3.2-16.1-0.8-24.5,2.9c-5.5,2.4-41.3,5.2-41.3,5.2 c-19.4-0.5-15.8-1.4-35.2-2.4c-8.8-0.5-22.8-10.1-24.9-10.3c-13.7-1.9-19.4-4.7-33.4-5.2c-17.3-4.5-13.4-7.5-25.5-16.9 c-8.2-18.8,13.5-34.8,33.8-39.9C1223.7,495.2,1213.8,486.7,1230.8,489.7z"
                                    onMouseEnter={() => this.hoverPie(21)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 21)} />
                                <path id="Rectangle_-_20" className="st-transparent" d="M1129.1,448.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7V443C1134.8,446.2,1132.3,448.7,1129.1,448.7z"
                                    onMouseEnter={() => this.hoverPie(20)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 20)} />
                                <path id="Rectangle_-_19" className="st-transparent" d="M1392.9,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,354.2,1396.1,356.7,1392.9,356.7z"
                                    onMouseEnter={() => this.hoverPie(19)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 19)} />
                                <path id="Rectangle_-_18" className="st-transparent" d="M1129.1,356.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1134.8,354.2,1132.3,356.7,1129.1,356.7z"
                                    onMouseEnter={() => this.hoverPie(18)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 18)} />
                                <path id="Rectangle_-_17" className="st-transparent" d="M865.9,356.7H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,354.2,869,356.7,865.9,356.7z"
                                    onMouseEnter={() => this.hoverPie(17)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 17)} />
                                <path id="Rectangle_-_16" className="st-transparent" d="M865.9,253.2H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,250.6,869,253.2,865.9,253.2z"
                                    onMouseEnter={() => this.hoverPie(16)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 16)} />
                                <path id="Rectangle_-_15" className="st-transparent" d="M1392.9,145h-232.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,142.5,1396.1,145,1392.9,145z"
                                    onMouseEnter={() => this.hoverPie(15)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 15)} />
                                <path id="Rectangle_-_14" className="st-transparent" d="M1392.9,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,250.6,1396.1,253.2,1392.9,253.2z"
                                    onMouseEnter={() => this.hoverPie(14)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 14)} />
                                <path id="Rectangle_-_13" className="st-transparent" d="M1655.7,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,250.6,1658.9,253.2,1655.7,253.2z"
                                    onMouseEnter={() => this.hoverPie(13)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 13)} />
                                <path id="Rectangle_-_12" className="st-transparent" d="M1655.7,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,354.2,1658.9,356.7,1655.7,356.7z"
                                    onMouseEnter={() => this.hoverPie(12)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 12)} />
                                <path id="Rectangle_-_11" className="st-transparent" d="M1655.7,504.3h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,501.8,1658.9,504.3,1655.7,504.3z"
                                    onMouseEnter={() => this.hoverPie(11)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 11)} />
                                <path id="Rectangle_-_10" className="st-transparent" d="M1655.7,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1661.4,754,1658.9,756.5,1655.7,756.5z"
                                    onMouseEnter={() => this.hoverPie(10)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 10)} />
                                <path id="Rectangle_-_9" className="st-transparent" d="M1655.7,858.1h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,855.5,1658.9,858.1,1655.7,858.1z"
                                    onMouseEnter={() => this.hoverPie(9)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 9)} />
                                <path id="Rectangle_-_8" className="st-transparent" d="M1394,859.1H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H1394 c3.1,0,5.7,2.5,5.7,5.7v66.7C1399.6,856.5,1397.1,859.1,1394,859.1z"
                                    onMouseEnter={() => this.hoverPie(8)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 8)} />
                                <path id="Rectangle_-_7" className="st-transparent" d="M1394.2,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1399.8,754,1397.3,756.5,1394.2,756.5z"
                                    onMouseEnter={() => this.hoverPie(7)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 7)} />
                                <path id="Rectangle_-_6" className="st-transparent" d="M865.9,504.3H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,501.8,869,504.3,865.9,504.3z"
                                    onMouseEnter={() => this.hoverPie(6)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 6)} />
                                <path id="Rectangle_-_5" className="st-transparent" d="M598,504.3H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,501.8,601.2,504.3,598,504.3z"
                                    onMouseEnter={() => this.hoverPie(5)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 5)} />
                                <path id="Rectangle_-_4_1_" className="st-transparent" d="M330.2,356.7H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,354.2,333.3,356.7,330.2,356.7z"
                                    onMouseEnter={() => this.hoverPie(4)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 4)} />
                                <path id="Rectangle_-_3_1_" className="st-transparent" d="M598,356.7H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,354.2,601.2,356.7,598,356.7z"
                                    onMouseEnter={() => this.hoverPie(3)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 3)} />
                                <path id="Rectangle_-_2_1_" className="st-transparent" d="M330.2,253.2H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,250.6,333.3,253.2,330.2,253.2z"
                                    onMouseEnter={() => this.hoverPie(2)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 2)} />
                                <path id="Rectangle_-_1_1_" className="st-transparent" d="M330.2,145H97.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,142.5,333.3,145,330.2,145z"
                                    onMouseEnter={() => this.hoverPie(1)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 1)} />
                            </g>
                        </g>
                        <g className="lv1">
                            <g id="Background" className="st0">
                                <rect id="Background_3_" y="0" className="st1" width="1673.4" height="891" />
                            </g>
                            <g id="Fields">
                                <rect id="Rectangle_-_4" className={"st2 model-option" + (this.state.changed ? this.setStyles(28) : '')} x="747.9" y="465.8" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_3" className={"st3 model-option" + (this.state.changed ? this.setStyles(27) : '')} x="40.6" y="465.8" width="707.2" height="425.2" />
                                <rect id="Rectangle_-_2-2" x="747.9" y="40.6" className="st4" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_2-1" className={"st5 model-option" + (this.state.changed ? this.setStyles(26) : '')} x="747.9" y="40.6" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_1" className={"st6 model-option" + (this.state.changed ? this.setStyles(25) : '')} x="40.6" y="40.6" width="707.2" height="425.2" />
                            </g>
                            <g id="Corridor">
                                <polygon id="Arrow_-_9" className="st7" points="252.4,122 106.8,184.9 186.9,221.3 332.5,158.4 	" />
                                <polygon id="Arrow_-_8" className="st8" points="373.4,174.5 227.7,237.5 307.9,273.9 453.5,210.9 	" />
                                <polygon id="Arrow_-_7" className="st9" points="494.3,227.1 348.7,290 428.9,326.4 574.5,263.5 	" />
                                <polygon id="Arrow_-_6" className="st9" points="615.3,279.6 469.7,342.6 549.8,379 695.4,316 	" />
                                <polygon id="Arrow_-_5" className="st10" points="740.1,335.4 594.4,398.3 674.6,434.7 820.2,371.8 	" />
                                <polygon id="Arrow_-_4" className="st11" points="864.8,391.2 719.2,454.1 799.4,490.5 945,427.5 	" />
                                <polygon id="Arrow_-_3" className="st12" points="989.6,446.9 844,509.8 924.2,546.3 1069.8,483.3 	" />
                                <polygon id="Arrow_-_2" className="st13" points="1114.4,502.7 968.8,565.6 1048.9,602 1194.5,539.1 	" />
                                <polygon id="Arrow_-_1" className="st14" points="1595.4,783 1485.7,554.7 1441.8,651.4 1239.5,559.5 1093.9,622.4 1392,757.8
		1348.1,854.5 	"/>
                            </g>
                            <g id="Lines">
                                <line id="Line_-_2" className="st15" x1="26.1" y1="466.2" x2="-1.5" y2="466.2" />
                                <line id="Line_-_1" className="st15" x1="749.1" y1="27.8" x2="749.1" y2="0.1" />
                            </g>
                            <g id="Boxes">
                                <path id="Rectangle_-_24" className={"st16 model-option" + (this.state.changed ? this.setStyles(24) : '')} d="M290.8,651.9H58.1c-3.1,0-5.7-2.5-5.7-5.7V431.9c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v214.3C296.4,649.3,293.9,651.9,290.8,651.9z" />
                                <path id="Rectangle_-_23" className={"st16 model-option" + (this.state.changed ? this.setStyles(23) : '')} d="M598,757H365.4c-3.1,0-5.7-2.5-5.7-5.7V537c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v214.3C603.7,754.4,601.2,757,598,757z" />
                                <path id="Rectangle_-_22" className={"st16 model-option" + (this.state.changed ? this.setStyles(22) : '')} d="M865.9,858.1H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,855.5,869,858.1,865.9,858.1z" />
                                <path id="Rectangle_-_21" className={"st16 model-option" + (this.state.changed ? this.setStyles(21) : '')} d="M1230.8,489.7c17,3.1,11.1-2.6,24.1-5.1c17.6,0.5,35.8-5.1,53.4-3.3 c10,1.2,3.7,5.2,13.4,6.1c18.8,4.7,3,3.5,44.3,6.6c5.8,0.5,16.4,4.2,16.4,4.2c9.4,11,5.7,5.2,10.9,16.9c0.6,1.4,1.3,19.2,1.3,19.2 c-4.6,11.7-9.6,8.5-22.7,14.8c-4.9,5.6-20.3,8.1-28.8,10.9c-11.2,3.2-16.1-0.8-24.5,2.9c-5.5,2.4-41.3,5.2-41.3,5.2 c-19.4-0.5-15.8-1.4-35.2-2.4c-8.8-0.5-22.8-10.1-24.9-10.3c-13.7-1.9-19.4-4.7-33.4-5.2c-17.3-4.5-13.4-7.5-25.5-16.9 c-8.2-18.8,13.5-34.8,33.8-39.9C1223.7,495.2,1213.8,486.7,1230.8,489.7z" />
                                <path id="Rectangle_-_20" className={"st16 model-option" + (this.state.changed ? this.setStyles(20) : '')} d="M1129.1,448.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7V443C1134.8,446.2,1132.3,448.7,1129.1,448.7z" />
                                <path id="Rectangle_-_19" className={"st16 model-option" + (this.state.changed ? this.setStyles(19) : '')} d="M1392.9,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,354.2,1396.1,356.7,1392.9,356.7z" />
                                <path id="Rectangle_-_18" className={"st16 model-option" + (this.state.changed ? this.setStyles(18) : '')} d="M1129.1,356.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1134.8,354.2,1132.3,356.7,1129.1,356.7z" />
                                <path id="Rectangle_-_17" className={"st16 model-option" + (this.state.changed ? this.setStyles(17) : '')} d="M865.9,356.7H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,354.2,869,356.7,865.9,356.7z" />
                                <path id="Rectangle_-_16" className={"st16 model-option" + (this.state.changed ? this.setStyles(16) : '')} d="M865.9,253.2H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,250.6,869,253.2,865.9,253.2z" />
                                <path id="Rectangle_-_15" className={"st16 model-option" + (this.state.changed ? this.setStyles(15) : '')} d="M1392.9,145h-232.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,142.5,1396.1,145,1392.9,145z" />
                                <path id="Rectangle_-_14" className={"st16 model-option" + (this.state.changed ? this.setStyles(14) : '')} d="M1392.9,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,250.6,1396.1,253.2,1392.9,253.2z" />
                                <path id="Rectangle_-_13" className={"st16 model-option" + (this.state.changed ? this.setStyles(13) : '')} d="M1655.7,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,250.6,1658.9,253.2,1655.7,253.2z" />
                                <path id="Rectangle_-_12" className={"st16 model-option" + (this.state.changed ? this.setStyles(12) : '')} d="M1655.7,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,354.2,1658.9,356.7,1655.7,356.7z" />
                                <path id="Rectangle_-_11" className={"st16 model-option" + (this.state.changed ? this.setStyles(11) : '')} d="M1655.7,504.3h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,501.8,1658.9,504.3,1655.7,504.3z" />
                                <path id="Rectangle_-_10" className={"st16 model-option" + (this.state.changed ? this.setStyles(10) : '')} d="M1655.7,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1661.4,754,1658.9,756.5,1655.7,756.5z" />
                                <path id="Rectangle_-_9" className={"st16 model-option" + (this.state.changed ? this.setStyles(9) : '')} d="M1655.7,858.1h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,855.5,1658.9,858.1,1655.7,858.1z" />
                                <path id="Rectangle_-_8" className={"st16 model-option" + (this.state.changed ? this.setStyles(8) : '')} d="M1394,859.1H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H1394 c3.1,0,5.7,2.5,5.7,5.7v66.7C1399.6,856.5,1397.1,859.1,1394,859.1z" />
                                <path id="Rectangle_-_7" className={"st16 model-option" + (this.state.changed ? this.setStyles(7) : '')} d="M1394.2,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1399.8,754,1397.3,756.5,1394.2,756.5z" />
                                <path id="Rectangle_-_6" className={"st16 model-option" + (this.state.changed ? this.setStyles(6) : '')} d="M865.9,504.3H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,501.8,869,504.3,865.9,504.3z" />
                                <path id="Rectangle_-_5" className={"st16 model-option" + (this.state.changed ? this.setStyles(5) : '')} d="M598,504.3H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,501.8,601.2,504.3,598,504.3z" />
                                <path id="Rectangle_-_4_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(4) : '')} d="M330.2,356.7H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,354.2,333.3,356.7,330.2,356.7z" />
                                <path id="Rectangle_-_3_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(3) : '')} d="M598,356.7H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,354.2,601.2,356.7,598,356.7z" />
                                <path id="Rectangle_-_2_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(2) : '')} d="M330.2,253.2H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,250.6,333.3,253.2,330.2,253.2z" />
                                <path id="Rectangle_-_1_1_" className={"st16 model-option" + (this.state.changed ? this.setStyles(1) : '')} d="M330.2,145H97.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,142.5,333.3,145,330.2,145z" />
                            </g>
                            <g id="Areas">
                                <g className="st17">
                                    <path className="st18" d="M63.1,865.2v9.3h1.4v1.1h-1.4v3.5h-1.3v-3.5h-4.6v-1.1l4.8-9.3H63.1z M61.7,874.5l0.1-2.3l0-1.9l0.1-1.4
			l0-0.8l0-0.3l0-0.3l0-0.1l-0.7,1.5l-1.3,2.6l-1.5,2.9L61.7,874.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1661.1,875.6l-0.1,1.5l-0.3,0.9l-0.6,0.7l-0.9,0.5l-1.3,0.1l-0.9,0l-0.6-0.2l-0.6-0.4l-0.6-0.6l-0.3-0.8
			l-0.1-2.3h1.3v1.4l0.2,0.8l0.5,0.7l0.6,0.3l0.6,0.1l0.7-0.1l0.8-0.5l0.4-0.9l0.1-1.3l-0.1-1.4l-0.3-0.9l-0.4-0.4l-1.2-0.4
			l-0.7-0.1v-1.1l1.7-0.3l0.4-0.3l0.4-0.6l0.1-1.4l-0.1-1.2l-0.3-0.7l-0.5-0.4l-0.8-0.2l-0.7,0.1l-0.5,0.2l-0.4,0.6l-0.2,2.2h-1.3
			l0.1-1.9l0.3-1l0.5-0.6l0.8-0.5l1.2-0.2l1.1,0.1l0.8,0.4l0.6,0.5l0.4,0.6l0.2,0.8l0,1.2l-0.1,1.4l-0.4,0.9l-0.9,0.7l0.5,0.3
			l0.6,0.6l0.3,0.9l0.1,1.3L1661.1,875.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1661.4,65.6v1.1l-6.5,0v-0.9l1.7-2.8l1.3-2.1l0.6-1l1.1-2.3l0.2-1.5l-0.1-0.9l-0.4-0.9l-0.5-0.3l-0.8-0.1
			l-0.7,0.1l-0.5,0.2l-0.5,0.8l-0.1,1.2l0,1.4h-1.3l0-2l0.3-1.3l0.7-0.9l0.9-0.5l1.3-0.2l0.6,0.1l1,0.3l0.4,0.3l0.4,0.5l0.3,0.6
			l0.2,1.8l-0.3,1.8l-0.9,1.9l-1.7,2.8l-1.8,2.9L1661.4,65.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M62.1,52.9v13.9l-1.3,0V56.1l-2.1,0v-0.9l1.2-0.3l0.6-0.6l0.5-1.4H62.1z" />
                                </g>
                            </g>
                            <g id="Numbers">
                                <g className="st17">
                                    <path className="st18" d="M66.8,445.9v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L66.8,445.9z"/>
                                    <path className="st18" d="M73.7,435.1v7.9h1.2v1h-1.2v3h-1.1v-3h-3.9V443l4-7.9H73.7z M72.5,443l0.1-2l0-1.6l0.1-1.2l0-0.7l0-0.2
			l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L72.5,443z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.9,552.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L375.9,552.3z"/>
                                    <path className="st18" d="M383.6,550.3l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L383.6,550.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M641.5,799.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2H636l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L641.5,799.3z"/>
                                    <path className="st18" d="M649.4,799.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L649.4,799.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1173.6,526.2v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L1173.6,526.2z"/>
                                    <path className="st18" d="M1178.2,515.4v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1178.2z" />
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M905.7,390.4v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L905.7,390.4z"/>
                                    <path className="st18" d="M913.4,388l0,1.1l-0.3,1.1l-0.5,0.7l-0.5,0.3l-1.4,0.3l-1.4-0.3l-0.5-0.3l-0.3-0.4l-0.3-0.6l-0.1-0.8l0-2.1
			v-4l0-1.3l0.4-1.2l0.3-0.4l0.5-0.4l0.5-0.2l0.8-0.1l1.3,0.2l0.5,0.3l0.6,0.6l0.3,0.7l0.1,2.1V388z M909.2,388.2l0.1,1l0.2,0.7
			l0.4,0.5l0.8,0.3l0.6-0.1l0.4-0.3l0.3-0.5l0.2-0.5l0.1-1.5l0-5.5l-0.1-0.4l-0.2-0.7l-0.4-0.4l-1-0.3l-0.4,0.1l-0.5,0.3l-0.3,0.3
			l-0.2,1.2l0,1.1L909.2,388.2z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1170.9,295.5l0.2,1.3l0.4,0.5l0.8,0.2l0.6-0.1l0.4-0.2l0.3-0.3l0.2-0.9l0-0.9v-2l-0.5,0.6l-0.7,0.4
			l-0.7,0.1l-0.8-0.1l-0.5-0.3l-0.4-0.4l-0.3-0.4l-0.3-1l-0.1-1.5l0.1-1.8l0.1-0.8l0.5-0.8l0.4-0.4l0.6-0.3l0.9-0.1l1.2,0.2l0.5,0.3
			l0.4,0.4l0.4,0.7l0.2,1.2l0.1,1.9l-0.1,4.1l-0.1,1.4l-0.2,0.7l-0.5,0.6l-0.7,0.5l-1,0.1l-1.1-0.1l-0.7-0.4l-0.4-0.4l-0.2-0.5
			l-0.2-1.5L1170.9,295.5z M1171.8,287.3l-0.5,0.3l-0.3,0.4l-0.1,0.4l-0.1,1.3l0,1.5l0.1,0.8l0.2,0.5l0.4,0.4l0.7,0.2l0.7-0.2
			l0.5-0.4l0.2-0.3l0.2-0.9l0.1-1.3l-0.1-1.3l-0.1-0.8l-0.6-0.6l-0.9-0.2L1171.8,287.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M903.8,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H903.8z" />
                                    <path className="st18" d="M911.3,292.1l0.4,0.4l0.2,0.4l0.2,1l0,1.5l-0.2,1.6l-0.3,0.6l-0.5,0.5l-0.7,0.3l-1,0.1l-0.8,0l-0.6-0.2
			l-0.5-0.4l-0.5-0.7l-0.2-0.9l0-1.3v-1.3l0.2-0.8l0.5-0.7l0.4-0.3l-0.4-0.4l-0.5-0.9l-0.1-1.3v-0.6l0.2-1l0.5-0.6l0.4-0.3l0.5-0.2
			l0.8-0.1l1,0.1l0.6,0.3l0.4,0.5l0.3,0.8l0.1,1.2l-0.1,1.1l-0.3,0.7l-0.3,0.4l-0.4,0.3L911.3,292.1z M908.9,292.3l-0.5,0.3
			l-0.3,0.4l-0.2,0.4l-0.1,1.3l0.1,1.4l0.2,0.6l0.3,0.4l0.6,0.3l0.4,0l0.4-0.1l0.6-0.3l0.3-0.4l0.1-0.8l0.1-1.2l-0.1-1.1l-0.3-0.9
			l-0.5-0.4l-0.8-0.2L908.9,292.3z M908.6,287.5l-0.4,0.6l-0.1,0.9l0.1,1.3l0.2,0.4l0.3,0.3l0.4,0.2l0.4,0l0.8-0.3l0.3-0.4l0.2-0.4
			l0.1-0.9l-0.1-1.1l-0.3-0.7l-0.4-0.3l-0.7-0.1L908.6,287.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M639.6,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H639.6z" />
                                    <path className="st18" d="M647.9,286.5l0,0.9l-0.5,1L647,289l-0.4,1l-0.5,1.3l-0.8,3.5l-0.4,3.5h-1.2l0.3-2.6l0.5-2.5l0.6-1.9l0.8-2
			l0.9-1.9h-4.2v-1L647.9,286.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M639.6,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H639.6z" />
                                    <path className="st18" d="M646.6,185.6l-0.1-0.9l-0.2-0.4l-0.3-0.4l-0.4-0.2l-0.5-0.1l-0.6,0.1l-0.4,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.2v2
			l0.6-0.7l0.7-0.3l0.6-0.1l0.6,0.1l0.9,0.4l0.5,0.6l0.3,0.8l0.1,2.2l-0.1,1.7l-0.1,0.7l-0.4,0.6l-0.5,0.4l-0.7,0.3l-1,0.1l-1-0.1
			l-0.7-0.4l-0.5-0.6l-0.4-1.5l0-1.5v-4.9l0.1-1.4l0.5-0.9l0.5-0.4l0.5-0.2l1-0.2l1.2,0.2l0.8,0.5l0.5,0.8l0.1,1.4H646.6z
			 M643.6,191.6l0.1,0.7l0.2,0.8l0.4,0.4l0.9,0.3l0.4,0l0.6-0.3l0.3-0.4l0.2-0.7l0.1-1.1v-0.4l-0.1-1.3l-0.2-0.8l-0.3-0.5l-0.4-0.2
			l-0.6-0.1l-0.8,0.3l-0.3,0.2l-0.2,0.5l-0.2,0.7l-0.1,1.1V191.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,77.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1174.7,77.8v1h-3.6l-0.2,3.6l0.5-0.5l0.8-0.3l0.5,0l0.6,0.1l0.6,0.3l0.4,0.4l0.4,0.6l0.2,0.9l0.1,1.3
			l-0.1,1.9l-0.1,1l-0.5,0.9l-0.4,0.5l-0.4,0.2l-1.2,0.3l-0.9,0l-0.6-0.2l-0.4-0.3l-0.6-0.8l-0.2-1.3l0-1.1h1.1l0,1.4l0.2,0.6
			l0.4,0.4l0.4,0.2l0.5,0.1l0.7-0.1l0.4-0.3l0.4-0.6l0.1-2.4l-0.1-1.2l-0.2-1l-0.5-0.4l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.5,0.6
			l-0.1,0.7h-1l0.3-6.3L1174.7,77.8z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1166.7,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1166.7z" />
                                    <path className="st18" d="M1174.1,182.8v7.9h1.2v1h-1.2v3h-1.1v-3h-3.9v-0.9l4-7.9H1174.1z M1172.9,190.7l0.1-2l0-1.6l0.1-1.2l0-0.7
			l0-0.2l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L1172.9,190.7z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,182.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.4,191.6l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L1438.4,191.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,286.5v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.7,297.3v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L1438.7,297.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,435.1v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1436.8,435.1v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1436.8z" />
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1430.3,594.3v11.8l-1.1,0V597l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H1430.3z" />
                                    <path className="st18" d="M1438.5,602.7l0,1.1l-0.3,1.1l-0.5,0.7l-0.5,0.3l-1.4,0.3l-1.4-0.3l-0.5-0.3l-0.3-0.4l-0.3-0.6l-0.1-0.8
			l0-2.1v-4l0-1.3l0.4-1.2l0.3-0.4l0.5-0.4l0.5-0.2l0.8-0.1l1.3,0.2l0.5,0.3l0.6,0.6l0.3,0.7l0.1,2.1V602.7z M1434.3,602.9l0.1,1
			l0.2,0.7l0.4,0.5l0.8,0.3l0.6-0.1l0.4-0.3l0.3-0.5l0.2-0.5l0.1-1.5l0-5.5l-0.1-0.4l-0.2-0.7l-0.4-0.4l-1-0.3l-0.4,0.1l-0.5,0.3
			l-0.3,0.3l-0.2,1.2l0,1.1L1434.3,602.9z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1432.9,797.5l0.2,1.3l0.4,0.5l0.8,0.2l0.6-0.1l0.4-0.2l0.3-0.3l0.2-0.9l0-0.9v-2l-0.5,0.6l-0.7,0.4
			l-0.7,0.1l-0.8-0.1l-0.5-0.3l-0.4-0.4l-0.3-0.4l-0.3-1l-0.1-1.5l0.1-1.8l0.1-0.8l0.5-0.8l0.4-0.4l0.6-0.3l0.9-0.1l1.2,0.2l0.5,0.3
			l0.4,0.4l0.4,0.7l0.2,1.2l0.1,1.9l-0.1,4.1l-0.1,1.4l-0.2,0.7l-0.5,0.6l-0.7,0.5l-1,0.1l-1.1-0.1l-0.7-0.4l-0.4-0.4l-0.2-0.5
			l-0.2-1.5L1432.9,797.5z M1433.8,789.4l-0.5,0.3l-0.3,0.4l-0.1,0.4l-0.1,1.3l0,1.5l0.1,0.8l0.2,0.5l0.4,0.4l0.7,0.2l0.7-0.2
			l0.5-0.4l0.2-0.3l0.2-0.8l0.1-1.3l-0.1-1.3l-0.1-0.8l-0.6-0.6l-0.9-0.2L1433.8,789.4z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M906.7,794.1l0.4,0.4l0.2,0.4l0.2,1l0,1.5l-0.2,1.6l-0.3,0.6l-0.5,0.5l-0.7,0.3l-1,0.1l-0.8,0l-0.6-0.2
			l-0.5-0.4l-0.5-0.7l-0.2-0.9l0-1.3v-1.3l0.2-0.8l0.5-0.7l0.4-0.3l-0.4-0.4l-0.5-0.9l-0.1-1.3v-0.6l0.2-1l0.5-0.6l0.4-0.3l0.5-0.2
			l0.8-0.1l1,0.1l0.6,0.3l0.4,0.5l0.3,0.8l0.1,1.2l-0.1,1.1l-0.3,0.7l-0.3,0.4l-0.4,0.3L906.7,794.1z M904.4,794.4l-0.5,0.3
			l-0.3,0.4l-0.2,0.4l-0.1,1.3l0.1,1.4l0.2,0.6l0.3,0.4l0.6,0.3l0.4,0l0.4-0.1l0.6-0.3l0.3-0.4l0.1-0.8l0.1-1.2l-0.1-1.1l-0.3-0.9
			l-0.5-0.4l-0.8-0.2L904.4,794.4z M904,789.5l-0.4,0.6l-0.1,0.9l0.1,1.3l0.2,0.4l0.3,0.3l0.4,0.2l0.4,0l0.8-0.3l0.3-0.4l0.2-0.4
			l0.1-0.9l-0.1-1.1l-0.3-0.7l-0.4-0.3l-0.7-0.1L904,789.5z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M1168.5,594.3l0,0.9l-0.5,1l-0.3,0.6l-0.4,1l-0.5,1.3l-0.8,3.5l-0.4,3.5h-1.2l0.3-2.6l0.5-2.5l0.6-1.9l0.8-2
			l0.9-1.9h-4.2v-1L1168.5,594.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M640,437.9L640,437l-0.2-0.4l-0.3-0.4l-0.4-0.2l-0.5-0.1L638,436l-0.4,0.2l-0.3,0.4l-0.2,0.4l-0.1,1.2v2
			l0.6-0.7l0.7-0.3l0.6-0.1l0.6,0.1l0.9,0.4l0.5,0.6l0.3,0.8l0.1,2.2l-0.1,1.7l-0.1,0.7l-0.4,0.6l-0.5,0.4l-0.7,0.3l-1,0.1l-1-0.1
			l-0.7-0.4l-0.5-0.6l-0.4-1.5l0-1.5V438l0.1-1.4l0.5-0.9l0.5-0.4l0.5-0.2l1-0.2l1.2,0.2l0.8,0.5l0.5,0.8l0.1,1.4H640z M637.1,444
			l0.1,0.7l0.2,0.8l0.4,0.4l0.9,0.3l0.4,0l0.6-0.3l0.3-0.4l0.2-0.7l0.1-1.1v-0.4l-0.1-1.3L640,441l-0.3-0.5l-0.4-0.2l-0.6-0.1
			l-0.8,0.3l-0.3,0.2l-0.2,0.5l-0.2,0.7l-0.1,1.1V444z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.5,435.1v1h-3.6l-0.2,3.6l0.5-0.5l0.8-0.3l0.5,0l0.6,0.1l0.6,0.3l0.4,0.4l0.4,0.6l0.2,0.9l0.1,1.3
			l-0.1,1.9l-0.1,1l-0.5,0.9l-0.4,0.5l-0.4,0.2l-1.2,0.3l-0.9,0l-0.6-0.2l-0.4-0.3l-0.6-0.8l-0.2-1.3l0-1.1h1.1l0,1.4l0.2,0.6
			l0.4,0.4l0.4,0.2l0.5,0.1l0.7-0.1l0.4-0.3l0.4-0.6l0.1-2.4l-0.1-1.2l-0.2-1l-0.5-0.4l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.5,0.6
			l-0.1,0.7h-1l0.3-6.3L375.5,435.1z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M106,286.5v7.9h1.2v1H106v3h-1.1v-3H101v-0.9l4-7.9H106z M104.9,294.4l0.1-2l0-1.6l0.1-1.2l0-0.7l0-0.2
			l0-0.3l0-0.1l-0.6,1.3l-1.1,2.2l-1.3,2.4L104.9,294.4z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M375.6,295.3l-0.1,1.3l-0.3,0.8l-0.5,0.6l-0.7,0.4l-1.1,0.1l-0.7,0l-0.5-0.2l-0.5-0.3l-0.5-0.5l-0.2-0.7
			l-0.1-2h1.1v1.2l0.1,0.6l0.4,0.6l0.5,0.3l0.5,0.1l0.6-0.1l0.6-0.4l0.3-0.7l0.1-1.1l-0.1-1.2l-0.3-0.8l-0.4-0.4l-1-0.3l-0.6-0.1
			v-0.9l1.4-0.2l0.3-0.3l0.4-0.5l0.1-1.2l-0.1-1l-0.3-0.6l-0.4-0.3l-0.7-0.2l-0.6,0.1l-0.4,0.2l-0.3,0.5l-0.1,1.9h-1.1l0.1-1.6
			l0.2-0.9l0.4-0.5l0.7-0.4l1.1-0.2l0.9,0.1l0.7,0.3l0.5,0.4l0.3,0.5l0.2,0.7l0,1l-0.1,1.2l-0.3,0.7l-0.8,0.6l0.4,0.3l0.5,0.5
			l0.3,0.8l0.1,1.1L375.6,295.3z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M107.1,193.6v1l-5.5,0v-0.8l1.4-2.4l1.1-1.8l0.5-0.8l0.9-2l0.2-1.3l-0.1-0.8l-0.4-0.8l-0.4-0.3l-0.7-0.1
			l-0.6,0.1l-0.4,0.2l-0.4,0.7l-0.1,1l0,1.2h-1.1l0-1.7l0.2-1.1l0.6-0.7l0.7-0.4l1.1-0.1l0.5,0.1l0.9,0.3l0.3,0.3l0.4,0.4l0.3,0.5
			l0.2,1.5l-0.2,1.5l-0.7,1.6l-1.4,2.4l-1.5,2.5L107.1,193.6z"/>
                                </g>
                                <g className="st17">
                                    <path className="st18" d="M105.2,77.8v11.8l-1.1,0v-9.1l-1.8,0v-0.7l1-0.3l0.5-0.5l0.4-1.2H105.2z" />
                                </g>
                            </g>
                            <g id="Text">
                                {SelectedLanguage === "en-GB" && <>
                                    <g>
                                        <path className="st18" d="M110.1055,522.3744h-2.74l-1.36-9.6416-1.5205,9.6416h-2.78l-2.5806-14.1231h2.7l1.42,10.6426,1.52-10.6426h2.46l1.4805,10.6426,1.48-10.6426h2.6407Z" />
                                        <path className="st18" d="M119.0044,522.3744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8559.8559,0,0,0-.8-.4609.889.889,0,0,0-.9.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.38V508.2513h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0247,2.0247,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M128.144,522.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2536,2.2536,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6463,3.6463,0,0,1,2.5805-.76c.3,0,2.08-.0205,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.0195.06.0595.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2794,1.2794,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M135.9839,522.475c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.65,2.65,0,0,0,.4-.04v1.7207A5.0782,5.0782,0,0,1,135.9839,522.475Z" />
                                        <path className="st18" d="M144.583,510.6517v-2.4h2.4v2.4Zm0,11.7227V512.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M155.0239,521.6547a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606h2.2007v.8a2.4375,2.4375,0,0,0,.06.6406.797.797,0,0,0,.86.66.9661.9661,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2479,1.2479,0,0,0-.7-1.32l-2-1.4209a2.5254,2.5254,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1005-2.6211,1.5,0,2.32.52,2.6407,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1407h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7782.7782,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2007,1.54a3.6668,3.6668,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,155.0239,521.6547Z" />
                                        <path className="st18" d="M166.623,522.475c-1.38,0-2.1-.3008-2.4008-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.651,2.651,0,0,0,.4-.04v1.7207A5.0786,5.0786,0,0,1,166.623,522.475Z" />
                                        <path className="st18" d="M173.9634,522.3744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.856.856,0,0,0-.8-.4609.889.889,0,0,0-.9.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.38V508.2513h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0248,2.0248,0,0,1,2.0005,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M181.2427,517.7738v1.6807a1.9944,1.9944,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0406,1.84-2.1,0-2.8007-1.04-3.0805-1.96a6.5921,6.5921,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M199.1235,521.5345a3.324,3.324,0,0,1-2.6611,1.04,3.4409,3.4409,0,0,1-2.7207-.96,3.5823,3.5823,0,0,1-.42-.7207,3.5366,3.5366,0,0,1-.2-1.3v-4.541a2.975,2.975,0,0,1,.56-1.9609,3.5889,3.5889,0,0,1,2.78-1.16c.24,0,2.3809,0,3.041,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5509,2.5509,0,0,0-.04-.5.8339.8339,0,0,0-.86-.64,1.0686,1.0686,0,0,0-.56.14c-.32.22-.42.54-.42,1.24v4.4609a2.1415,2.1415,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1648,1.1648,0,0,0,.52-.14,1.3554,1.3554,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1823,3.1823,0,0,1,199.1235,521.5345Z" />
                                        <path className="st18" d="M206.2231,522.3744v-.84a3.2418,3.2418,0,0,1-2.1806,1.0206,2.2511,2.2511,0,0,1-1.2-.36,2.5162,2.5162,0,0,1-.8008-2.3408v-7.7217h2.4v7.3613c0,.36-.04.7207.2207.9805a.8717.8717,0,0,0,.64.2607,1.0324,1.0324,0,0,0,.6006-.2c.3994-.3408.32-1.1005.32-1.16v-7.2422h2.4v10.2422Z" />
                                        <path className="st18" d="M215.9429,514.3129a1.6919,1.6919,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.6406,1.94v6.041h-2.36V512.1322h2.3809l-.02,1.3008a4.1074,4.1074,0,0,1,.36-.6006,2.2169,2.2169,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M222.6626,514.3129a1.6919,1.6919,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.6406,1.94v6.041h-2.36V512.1322h2.3808l-.02,1.3008a4.12,4.12,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M226.9614,517.7738v1.6807a1.9755,1.9755,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M237.7222,522.3744v-7.541c0-.6407-.04-.9-.36-1.1006a.7527.7527,0,0,0-.46-.12c-1.12,0-1.0206,1.1806-1.0206,1.3808v7.3809h-2.3808V512.1322h2.4209l-.02.74a2.6378,2.6378,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M245.8823,522.475c-1.3808,0-2.1006-.3008-2.4-.961a2.6151,2.6151,0,0,1-.2-1.18v-6.6211H241.981v-1.5811h1.28v-3.7207h2.3614v3.7207h1.42v1.5811h-1.42v5.8408a1.35,1.35,0,0,0,.0595.5605c.14.48.6006.56,1.02.56a2.638,2.638,0,0,0,.4-.04v1.7207A5.0709,5.0709,0,0,1,245.8823,522.475Z" />
                                        <path className="st18" d="M73.915,543.6547a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606h2.2007v.8a2.4375,2.4375,0,0,0,.06.6406.797.797,0,0,0,.86.66.9661.9661,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2479,1.2479,0,0,0-.7-1.32l-2.0005-1.4209a2.5254,2.5254,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1407H72.2749v-.74c0-.16.04-1.12-.88-1.12a.7782.7782,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2007,1.54a3.6668,3.6668,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,73.915,543.6547Z" />
                                        <path className="st18" d="M80.0547,544.475c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.65,2.65,0,0,0,.4-.04v1.7207A5.0782,5.0782,0,0,1,80.0547,544.475Z" />
                                        <path className="st18" d="M87.3145,544.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2534,2.2534,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6464,3.6464,0,0,1,2.5806-.76c.3,0,2.0805-.02,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.02.06.0595.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M95.1548,544.475c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.65,2.65,0,0,0,.4-.04v1.7207A5.0782,5.0782,0,0,1,95.1548,544.475Z" />
                                        <path className="st18" d="M100.5542,539.7738v1.6807a1.9912,1.9912,0,0,0,.06.68.811.811,0,0,0,.82.58.7527.7527,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.6,6.6,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8218,2.8218,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M118.2549,543.5345a3.4128,3.4128,0,0,1-2.7207,1.04,4.8512,4.8512,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1737,3.1737,0,0,1,.2-1.3,2.9847,2.9847,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.4526,2.4526,0,0,1,118.2549,543.5345Zm-1.7808-6.8818c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.7466.7466,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M124.5742,532.1918c-.44.1006-.44.58-.44.6806v1.26h1.0605v1.5811h-1.0805v8.6611h-2.36v-8.6611h-1.06v-1.5811h1.06a10.5962,10.5962,0,0,1,.06-2.28,1.7237,1.7237,0,0,1,.6-1.1406,2.8526,2.8526,0,0,1,1.98-.5c.3,0,.6.02.88.04v1.961A2.11,2.11,0,0,0,124.5742,532.1918Z" />
                                        <path className="st18" d="M136.814,544.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2534,2.2534,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.05,2.05,0,0,1,.58-1.461,3.6466,3.6466,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M144.7139,532.1918c-.44.1006-.44.58-.44.6806v1.26h1.0606v1.5811h-1.0806v8.6611h-2.36v-8.6611h-1.06v-1.5811h1.06a10.6021,10.6021,0,0,1,.06-2.28,1.7237,1.7237,0,0,1,.6-1.1406,2.853,2.853,0,0,1,1.9805-.5c.3,0,.6.02.88.04v1.961A2.11,2.11,0,0,0,144.7139,532.1918Z" />
                                        <path className="st18" d="M150.5938,532.1918c-.44.1006-.44.58-.44.6806v1.26h1.0606v1.5811h-1.0806v8.6611h-2.36v-8.6611h-1.06v-1.5811h1.06a10.5962,10.5962,0,0,1,.06-2.28,1.7237,1.7237,0,0,1,.6-1.1406,2.853,2.853,0,0,1,1.9805-.5c.3,0,.6.02.88.04v1.961A2.11,2.11,0,0,0,150.5938,532.1918Z" />
                                        <path className="st18" d="M157.374,544.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2538,2.2538,0,0,1-1.7.74,2.0926,2.0926,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2064,3.2064,0,0,1,1.0805-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6463,3.6463,0,0,1,2.5805-.76c.3,0,2.0806-.02,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0678,5.0678,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2794,1.2794,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M162.0532,532.6517v-2.4h2.4v2.4Zm0,11.7227V534.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M171.7734,536.3129a1.6837,1.6837,0,0,0-.78-.16,1.6055,1.6055,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.041h-2.36V534.1322h2.38l-.02,1.3008a4.1032,4.1032,0,0,1,.36-.6006,2.2167,2.2167,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M179.2139,543.6547a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606h2.2006v.8a2.4419,2.4419,0,0,0,.06.6406.797.797,0,0,0,.86.66.966.966,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5254,2.5254,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1407h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7782.7782,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2007,1.54a3.6668,3.6668,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,179.2139,543.6547Z" />
                                        <path className="st18" d="M191.7739,544.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.2208.2607-.36.3809a2.2528,2.2528,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2083,3.2083,0,0,1,1.0805-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9575.9575,0,0,0-.5195-.14.7771.7771,0,0,0-.7408.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6457,3.6457,0,0,1,2.58-.76c.3008,0,2.0811-.02,2.8008,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.08,5.08,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.3408-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M200.6733,544.3744v-7.541c0-.6407-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.3808V534.1322h2.4209l-.02.74a2.6377,2.6377,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M209.8335,544.3744l.0205-1a2.27,2.27,0,0,1-2.001,1.2,2.4506,2.4506,0,0,1-1.42-.44c-.7607-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3638,2.3638,0,0,1,1.1807-.34,2.993,2.993,0,0,1,2.0605,1.04v-4.7012h2.38v14.1231Zm.0205-7.4414c0-.46-.0205-.78-.44-1.02a1.2018,1.2018,0,0,0-.58-.16.9361.9361,0,0,0-.52.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1242,1.1242,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3051,1.3051,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M228.3335,544.3744h-2.2412l-1-6.0811-1.2,6.0811h-2.2608l-2.08-10.2422h2.32l1.08,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.1611-6.7617h2.32Z" />
                                        <path className="st18" d="M236.6724,544.3744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8554.8554,0,0,0-.8-.4609.8887.8887,0,0,0-.9.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.3809V530.2513h2.3408l.0195,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0238,2.0238,0,0,1,2,1.4,2.918,2.918,0,0,1,.1406,1.02v8.0215Z" />
                                        <path className="st18" d="M245.812,544.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3593.3809a2.2574,2.2574,0,0,1-1.7012.74,2.0952,2.0952,0,0,1-1.24-.3809c-.84-.62-.7793-1.84-.7793-2.74a2.979,2.979,0,0,1,.48-2.1006,3.2142,3.2142,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.3193-1.08a.9645.9645,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1806v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8791,1.8791,0,0,1,.2393.9805v5.7012a5.068,5.068,0,0,0,.3007,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2823,1.2823,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M253.6528,544.475c-1.3808,0-2.1006-.3008-2.4-.961a2.6151,2.6151,0,0,1-.2-1.18v-6.6211h-1.3007v-1.5811h1.28v-3.7207h2.3614v3.7207h1.42v1.5811h-1.42v5.8408a1.35,1.35,0,0,0,.0595.5605c.14.48.6006.56,1.02.56a2.638,2.638,0,0,0,.4-.04v1.7207A5.0709,5.0709,0,0,1,253.6528,544.475Z" />
                                        <path className="st18" d="M266.4116,544.3744l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.451,2.451,0,0,1-1.42-.44c-.7607-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3638,2.3638,0,0,1,1.1807-.34,2.9926,2.9926,0,0,1,2.06,1.04v-4.7012h2.38v14.1231Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2018,1.2018,0,0,0-.58-.16.9361.9361,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3058,1.3058,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M277.1724,543.5345a3.4114,3.4114,0,0,1-2.7207,1.04,4.8515,4.8515,0,0,1-1.02-.1,2.4978,2.4978,0,0,1-1.9-1.6211,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9617,2.9617,0,0,1,.2392,1.36v4.94A2.4508,2.4508,0,0,1,277.1724,543.5345Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7472.7472,0,0,0-.4795.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M125.165,566.3744h-2.24l-1.0005-6.0811-1.2,6.0811h-2.26l-2.0806-10.2422h2.32l1.0806,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.3208Z" />
                                        <path className="st18" d="M131.564,561.7738v1.6807a1.9944,1.9944,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0406,1.84-2.1005,0-2.8007-1.04-3.0805-1.96a6.5921,6.5921,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M147.7637,566.3744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.856.856,0,0,0-.8-.4609.889.889,0,0,0-.9.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.38V552.2513h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0248,2.0248,0,0,1,2.0005,1.4,2.9356,2.9356,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M156.9038,566.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2538,2.2538,0,0,1-1.7.74,2.093,2.093,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.0494,2.0494,0,0,1,.58-1.461,3.6467,3.6467,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0678,5.0678,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M165.3037,566.3744h-2.3208l-2.26-10.2422h2.38l1.0606,7.1016,1.22-7.1016h2.38Z" />
                                        <path className="st18" d="M171.8032,561.7738v1.6807a1.9912,1.9912,0,0,0,.06.68.811.811,0,0,0,.82.58.7527.7527,0,0,0,.76-.5605,4.9833,4.9833,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.5972,6.5972,0,0,1-.18-1.3408v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M186.9629,566.475c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.6485,2.6485,0,0,0,.4-.04v1.7207A5.0776,5.0776,0,0,1,186.9629,566.475Z" />
                                        <path className="st18" d="M195.8032,565.5345a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.02-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1737,3.1737,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.45,2.45,0,0,1,195.8032,565.5345Zm-1.78-6.8818c0-.64-.0595-.92-.38-1.12a.7285.7285,0,0,0-.4405-.12.7474.7474,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M208.5229,566.3744l.0206-1a2.27,2.27,0,0,1-2.001,1.2,2.4506,2.4506,0,0,1-1.42-.44c-.7608-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3638,2.3638,0,0,1,1.1807-.34,2.9931,2.9931,0,0,1,2.0606,1.04v-4.7012h2.38v14.1231Zm.0206-7.4414c0-.46-.0206-.78-.44-1.02a1.2013,1.2013,0,0,0-.58-.16.9363.9363,0,0,0-.52.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.0595.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3057,1.3057,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M219.2827,565.5345a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.02-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.45,2.45,0,0,1,219.2827,565.5345Zm-1.78-6.8818c0-.64-.0595-.92-.38-1.12a.7285.7285,0,0,0-.4405-.12.7474.7474,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M228.8228,557.8324l-1.36,1.9805a3.7751,3.7751,0,0,0-.82,1.6211,4.3183,4.3183,0,0,0-.0391.7v.4394h-2.2607v-.7a5.6249,5.6249,0,0,1,.0195-.66,3.1518,3.1518,0,0,1,.66-1.6l1.32-2.041a2.4034,2.4034,0,0,0,.5605-1.6406v-1.26a.8865.8865,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.3408h-2.2207v-1.4805a10.1883,10.1883,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2413-2.2207a3.8769,3.8769,0,0,1,2.3007.6,1.9422,1.9422,0,0,1,.6.7207,4.0946,4.0946,0,0,1,.26,1.7607v.8A3.7619,3.7619,0,0,1,228.8228,557.8324Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M415.0112,622.974v-6.4609H412.63v6.4609H410.03V608.851H412.63v5.541h2.3808v-5.541h2.6006v14.123Z" />
                                        <path className="st18" d="M426.0713,622.1342a3.413,3.413,0,0,1-2.7207,1.04,4.8521,4.8521,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.581a3.1743,3.1743,0,0,1,.2-1.3,2.9847,2.9847,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.453,2.453,0,0,1,426.0713,622.1342Zm-1.7808-6.8819c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.7466.7466,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M437.1514,622.974h-2.24l-1-6.0811-1.2,6.0811h-2.26L428.37,612.7318h2.32l1.0806,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.3208Z" />
                                        <path className="st18" d="M450.91,622.974l.02-1a2.2689,2.2689,0,0,1-2,1.2,2.4511,2.4511,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.541,2.541,0,0,1,1.04-2.3809,2.3629,2.3629,0,0,1,1.18-.34,2.9921,2.9921,0,0,1,2.0606,1.04V608.851h2.38v14.123Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2038,1.2038,0,0,0-.58-.16.935.935,0,0,0-.52.16c-.4.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1206,1.1206,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3093,1.3093,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M461.67,622.1342a3.413,3.413,0,0,1-2.7207,1.04,4.8521,4.8521,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.581a3.1637,3.1637,0,0,1,.2-1.3,2.9837,2.9837,0,0,1,3.0405-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9478,2.9478,0,0,1,.24,1.36v4.94A2.453,2.453,0,0,1,461.67,622.1342Zm-1.78-6.8819c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7469.7469,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M478.21,622.974h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0806-10.2422h2.32l1.0806,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.3208Z" />
                                        <path className="st18" d="M484.6094,618.3734v1.6807a1.9941,1.9941,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16H488.63v.82a2.8339,2.8339,0,0,1-.1.9209c-.4,1.4394-1.68,1.84-3.0406,1.84-2.1005,0-2.8007-1.04-3.0805-1.96a6.5921,6.5921,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.82,2.82,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M504.97,622.974v-7.5811a1.1216,1.1216,0,0,0-.3594-1.041.9415.9415,0,0,0-.5009-.14c-.8,0-.96.66-.96,1.3008v7.4609h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.79.79,0,0,0-.56-.18c-.9805,0-.9209,1.04-.9209,1.2207v7.5811h-2.36V612.7318h2.38v.6807a2.9173,2.9173,0,0,1,2.0205-.9,2.44,2.44,0,0,1,1.92,1.02,3.14,3.14,0,0,1,1.6807-.94,3.1987,3.1987,0,0,1,.66-.0606,1.9268,1.9268,0,0,1,1.96,1.4,2.7048,2.7048,0,0,1,.1006.9209v8.1211Z" />
                                        <path className="st18" d="M514.1089,622.974c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2608-.3594.3809a2.2569,2.2569,0,0,1-1.7011.74,2.0954,2.0954,0,0,1-1.24-.3809c-.84-.62-.7793-1.84-.7793-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2154,3.2154,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.3194-1.08a.9629.9629,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1806v-1.16a2.0493,2.0493,0,0,1,.58-1.4609,3.6493,3.6493,0,0,1,2.5811-.76c.3,0,2.08-.0205,2.8008,1.28a1.8793,1.8793,0,0,1,.2392.98v5.7012a5.0681,5.0681,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7607.1,1.2.58,1.1806a1.2826,1.2826,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M523.01,622.974v-7.541c0-.6406-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.3808V612.7318H521.21l-.0205.74a2.6378,2.6378,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M532.1294,622.974c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2608-.3594.3809a2.2569,2.2569,0,0,1-1.7011.74,2.0956,2.0956,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2154,3.2154,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.3194-1.08a.9629.9629,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1806v-1.16a2.0493,2.0493,0,0,1,.58-1.4609,3.6493,3.6493,0,0,1,2.5811-.76c.3,0,2.08-.0205,2.8008,1.28a1.8729,1.8729,0,0,1,.24.98v5.7012a5.0676,5.0676,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7607.1,1.2.58,1.1806a1.2826,1.2826,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M543.35,623.9945a2.5187,2.5187,0,0,1-1.04,2.02,4.3574,4.3574,0,0,1-2.4209.6,6.1358,6.1358,0,0,1-.82-.0595,2.59,2.59,0,0,1-2.16-1.4805,3.8141,3.8141,0,0,1-.16-1.26h2.36a1.5466,1.5466,0,0,0,.04.48.8374.8374,0,0,0,.88.6006.9727.9727,0,0,0,.54-.1406c.4-.22.4209-.68.4209-1.08v-1.58a2.5191,2.5191,0,0,1-2.081,1,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.2607v-5.4209a3.5124,3.5124,0,0,1,.1406-1.12,2.0442,2.0442,0,0,1,2.04-1.461,2.9161,2.9161,0,0,1,2.121,1.02v-.82h2.4v10.6026C543.39,623.5541,543.37,623.7748,543.35,623.9945Zm-2.3809-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.041v4.5605a1.9515,1.9515,0,0,0,.08.6006.85.85,0,0,0,.8594.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M548.2886,618.3734v1.6807a2.01,2.01,0,0,0,.06.68.8118.8118,0,0,0,.82.58.7526.7526,0,0,0,.7607-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8474,2.8474,0,0,1-.1.9209c-.4,1.4394-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.04-3.08-1.96a6.574,6.574,0,0,1-.1807-1.3408v-4.32a3.8113,3.8113,0,0,1,.2608-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8269,2.8269,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M432.2812,644.1342a3.4129,3.4129,0,0,1-2.7207,1.04,4.8529,4.8529,0,0,1-1.02-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7255,3.7255,0,0,1-.18-1.38v-4.581a3.1726,3.1726,0,0,1,.2-1.3,2.9847,2.9847,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.92,1.501a2.9478,2.9478,0,0,1,.24,1.36v4.94A2.453,2.453,0,0,1,432.2812,644.1342Zm-1.7807-6.8819c0-.64-.06-.92-.38-1.12a.727.727,0,0,0-.44-.12.7466.7466,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M439.5605,644.974v-.84a3.2382,3.2382,0,0,1-2.18,1.0205,2.2547,2.2547,0,0,1-1.2007-.36,2.5167,2.5167,0,0,1-.8-2.3408v-7.7217h2.4v7.3613c0,.36-.04.7207.22.9805a.8729.8729,0,0,0,.64.2608,1.032,1.032,0,0,0,.6-.2c.4-.3409.32-1.1006.32-1.16v-7.2422h2.4V644.974Z" />
                                        <path className="st18" d="M449.28,636.9125a1.6845,1.6845,0,0,0-.78-.16,1.6053,1.6053,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.041H444.6V634.7318h2.38l-.02,1.3008a4.0906,4.0906,0,0,1,.36-.6006,2.2169,2.2169,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M460.94,644.974l.02-1a2.2689,2.2689,0,0,1-2,1.2,2.4511,2.4511,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5411,2.5411,0,0,1,1.04-2.3809,2.3633,2.3633,0,0,1,1.18-.34,2.9921,2.9921,0,0,1,2.0606,1.04V630.851h2.38v14.123Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2025,1.2025,0,0,0-.58-.16.9371.9371,0,0,0-.5205.16c-.4.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1206,1.1206,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3093,1.3093,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M470.12,644.974c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2608-.36.3809a2.2538,2.2538,0,0,1-1.7.74,2.0926,2.0926,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2061,3.2061,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9586.9586,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.4609,3.6457,3.6457,0,0,1,2.58-.76c.3,0,2.0806-.0205,2.8,1.28a1.8729,1.8729,0,0,1,.24.98v5.7012a5.0678,5.0678,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7607.1,1.2.58,1.1806a1.2794,1.2794,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M478.64,645.7142a4.643,4.643,0,0,1-.8,2.0606,2.32,2.32,0,0,1-2.0605.74,8.1279,8.1279,0,0,1-1.54-.18l.36-1.8808a3.2065,3.2065,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6606-10.2422h2.36l1.32,6.582,1.0205-6.582h2.34Z" />
                                        <path className="st18" d="M482.5386,640.1332v-1.9805H487.5v1.9805Z" />
                                        <path className="st18" d="M493.08,645.0746c-1.38,0-2.1006-.3008-2.4009-.961a2.6268,2.6268,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.48.6.56,1.02.56a2.6485,2.6485,0,0,0,.4-.04v1.7207A5.0776,5.0776,0,0,1,493.08,645.0746Z" />
                                        <path className="st18" d="M501.9194,644.1342a3.4115,3.4115,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.0195-.1,2.4983,2.4983,0,0,1-1.9-1.6211,3.7251,3.7251,0,0,1-.18-1.38v-4.581a3.1743,3.1743,0,0,1,.2-1.3,2.9848,2.9848,0,0,1,3.0411-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.963,2.963,0,0,1,.2392,1.36v4.94A2.4508,2.4508,0,0,1,501.9194,644.1342Zm-1.78-6.8819c0-.64-.06-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7469.7469,0,0,0-.4794.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M504.7983,640.1332v-1.9805h4.961v1.9805Z" />
                                        <path className="st18" d="M516.3394,644.974l.0205-1a2.27,2.27,0,0,1-2.001,1.2,2.4506,2.4506,0,0,1-1.42-.44c-.7608-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.363,2.363,0,0,1,1.1806-.34,2.9931,2.9931,0,0,1,2.0606,1.04V630.851h2.38v14.123Zm.0205-7.4414c0-.46-.0205-.78-.4405-1.02a1.2012,1.2012,0,0,0-.58-.16.9364.9364,0,0,0-.5206.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3057,1.3057,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M525.519,644.974c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2608-.3594.3809a2.257,2.257,0,0,1-1.7012.74,2.0953,2.0953,0,0,1-1.24-.3809c-.84-.62-.7793-1.84-.7793-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2144,3.2144,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.3193-1.08a.9632.9632,0,0,0-.5205-.14.7766.7766,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1807v-1.16a2.0491,2.0491,0,0,1,.58-1.4609,3.649,3.649,0,0,1,2.581-.76c.3,0,2.08-.0205,2.8008,1.28a1.8787,1.8787,0,0,1,.2393.98v5.7012a5.0667,5.0667,0,0,0,.3008,2.32c.0195.06.0595.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7607.1,1.2.58,1.1806a1.2823,1.2823,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M534.0386,645.7142a4.64,4.64,0,0,1-.8,2.0606,2.3188,2.3188,0,0,1-2.0606.74,8.118,8.118,0,0,1-1.54-.18l.3594-1.8808a3.212,3.212,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6611-10.2422h2.3613l1.32,6.582,1.02-6.582h2.3408Z" />
                                        <path className="st18" d="M451.6411,664.5941a2.3361,2.3361,0,0,1-1.24,2.1,4.8571,4.8571,0,0,1-2,.28H443.94V652.851h4.3813a3.4426,3.4426,0,0,1,2.34.54,2.5906,2.5906,0,0,1,.84,2.3408v1.2608a5.9524,5.9524,0,0,1-.08,1,1.6709,1.6709,0,0,1-1.5606,1.38,2.0667,2.0667,0,0,1,1.4.6807,2.8958,2.8958,0,0,1,.4,1.9v2C451.6611,664.1732,451.6411,664.3939,451.6411,664.5941Zm-2.72-8.4219a2.2615,2.2615,0,0,0-.06-.62c-.18-.66-.74-.6806-1.28-.6806H446.54v3.5605h.94a4.9233,4.9233,0,0,0,.7-.04c.5-.1.74-.3994.74-1.26Zm.18,5.6807a1.9594,1.9594,0,0,0-.08-.78c-.2-.58-.84-.6-1.36-.6H446.54v4.461h1.1006a3.5446,3.5446,0,0,0,.66-.04c.56-.08.8-.42.8-1.3808Z" />
                                        <path className="st18" d="M458.46,666.974v-.84a3.2372,3.2372,0,0,1-2.1806,1.0205,2.2544,2.2544,0,0,1-1.2-.36,2.5167,2.5167,0,0,1-.8-2.3408v-7.7217h2.4v7.3613c0,.36-.04.7207.22.9805a.8729.8729,0,0,0,.64.2608,1.032,1.032,0,0,0,.6-.2c.4-.3409.32-1.1006.32-1.16v-7.2422h2.4V666.974Z" />
                                        <path className="st18" d="M468.9,666.2543a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606H465.36v.8a2.4424,2.4424,0,0,0,.06.6406.7972.7972,0,0,0,.86.66.9664.9664,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5256,2.5256,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1407H467.26v-.74c0-.16.04-1.12-.88-1.12a.7781.7781,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2007,1.54a3.6631,3.6631,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,468.9,666.2543Z" />
                                        <path className="st18" d="M471.8794,655.2513v-2.4h2.4v2.4Zm0,11.7227V656.7318h2.4V666.974Z" />
                                        <path className="st18" d="M481.14,666.974v-7.541c0-.6406-.04-.9-.36-1.1006a.7524.7524,0,0,0-.4605-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.38V656.7318h2.42l-.02.74a2.6361,2.6361,0,0,1,2.0406-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M488.3994,662.3734v1.6807a1.9909,1.9909,0,0,0,.06.68.811.811,0,0,0,.82.58.7527.7527,0,0,0,.76-.5605,4.9888,4.9888,0,0,0,.08-1.16H492.42v.82a2.8339,2.8339,0,0,1-.1.9209c-.4,1.4394-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.6,6.6,0,0,1-.18-1.3408v-4.32a3.8172,3.8172,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M500.34,666.2543a3.0719,3.0719,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606h2.2007v.8a2.437,2.437,0,0,0,.06.6406.797.797,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5254,2.5254,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1407H498.7v-.74c0-.16.04-1.12-.88-1.12a.7782.7782,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7254,2.7254,0,0,1,.3809,1.6611A3.3049,3.3049,0,0,1,500.34,666.2543Z" />
                                        <path className="st18" d="M508.72,666.2543a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5857,3.5857,0,0,1-.22-1.32v-1.0606h2.2v.8a2.4374,2.4374,0,0,0,.0605.6406.7978.7978,0,0,0,.86.66.9672.9672,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3995-.9805,1.2466,1.2466,0,0,0-.7-1.32l-2-1.4209a2.5258,2.5258,0,0,1-1.2207-2.46c.0205-2.041,1.2607-2.6211,3.1015-2.6211,1.5,0,2.32.52,2.64,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1407h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6522,3.6522,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,508.72,666.2543Z" />
                                        <path className="st18" d="M518.14,658.432l-1.36,1.9805a3.7744,3.7744,0,0,0-.82,1.6211,4.3183,4.3183,0,0,0-.0391.7v.4394H513.66v-.7a5.6249,5.6249,0,0,1,.0195-.66,3.1511,3.1511,0,0,1,.66-1.6l1.32-2.0411a2.4034,2.4034,0,0,0,.56-1.6406v-1.26a.8866.8866,0,0,0-.9-.9805c-.76,0-.98.44-.98,1.08v2.3408h-2.2207v-1.4805a10.1883,10.1883,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2412-2.2207a3.877,3.877,0,0,1,2.3008.6,1.9536,1.9536,0,0,1,.6.7207,4.0946,4.0946,0,0,1,.26,1.7607v.8A3.7605,3.7605,0,0,1,518.14,658.432Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M707.043,811.3754h-2.74l-1.36-9.6426-1.52,9.6426h-2.78l-2.5806-14.1231h2.7l1.42,10.6416,1.52-10.6416h2.4605l1.4805,10.6416,1.48-10.6416h2.6407Z" />
                                        <path className="st18" d="M715.9419,811.3754v-7.2823a2.0585,2.0585,0,0,0-.08-.8.8566.8566,0,0,0-.8-.46.8889.8889,0,0,0-.9.7,1.43,1.43,0,0,0-.04.42v7.4219h-2.38V797.2523h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0235,2.0235,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M725.0815,811.3754c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2536,2.2536,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.197,3.197,0,0,1,1.0805-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.95.95,0,0,0-.52-.1406.776.776,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.18v-1.16a2.0458,2.0458,0,0,1,.58-1.46,3.6458,3.6458,0,0,1,2.5805-.7607c.3,0,2.08-.02,2.8,1.28a1.8761,1.8761,0,0,1,.24.9805v5.7012a5.0649,5.0649,0,0,0,.3,2.32c.0195.06.0595.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2827,1.2827,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M732.9214,811.475c-1.38,0-2.1006-.3-2.4009-.96a2.6337,2.6337,0,0,1-.2-1.1806v-6.6211h-1.3v-1.58h1.28v-3.7207h2.36v3.7207h1.42v1.58h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.5605c.14.4805.6.5606,1.02.5606a2.7713,2.7713,0,0,0,.4-.04v1.72A5.0258,5.0258,0,0,1,732.9214,811.475Z" />
                                        <path className="st18" d="M745.7212,811.3754v-7.2823a2.0585,2.0585,0,0,0-.08-.8.8566.8566,0,0,0-.8-.46.8889.8889,0,0,0-.9.7,1.43,1.43,0,0,0-.04.42v7.4219h-2.38V797.2523h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0236,2.0236,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M754.8608,811.3754c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.976,2.976,0,0,1,.48-2.1006,3.197,3.197,0,0,1,1.0805-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.95.95,0,0,0-.52-.1406.7762.7762,0,0,0-.74.4,2.9531,2.9531,0,0,0-.08.96v.38H750.7v-1.16a2.0458,2.0458,0,0,1,.58-1.46,3.6458,3.6458,0,0,1,2.5805-.7607c.3,0,2.08-.02,2.8,1.28a1.8761,1.8761,0,0,1,.24.9805v5.7012a5.0649,5.0649,0,0,0,.3,2.32c.0195.06.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2827,1.2827,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M763.2607,811.3754H760.94l-2.26-10.2422h2.38l1.06,7.1015,1.22-7.1015h2.38Z" />
                                        <path className="st18" d="M769.76,806.7738v1.6807a2.0161,2.0161,0,0,0,.0595.6806.813.813,0,0,0,.82.58.7526.7526,0,0,0,.7608-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6807,1.8409-3.0411,1.8409-2.1,0-2.8-1.0411-3.08-1.961a6.56,6.56,0,0,1-.1807-1.34v-4.3213a3.8081,3.8081,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M789.7417,811.3754h-2.2412l-1-6.0811-1.2,6.0811H783.04l-2.08-10.2422h2.32l1.08,6.7607,1.2-6.7607h1.94l1.08,6.7607,1.1611-6.7607h2.32Z" />
                                        <path className="st18" d="M796.14,806.7738v1.6807a1.981,1.981,0,0,0,.0606.6806.8113.8113,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.9982,4.9982,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6806,1.8409-3.041,1.8409-2.1006,0-2.8-1.0411-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8081,3.8081,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M669.9624,833.3754c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9759,2.9759,0,0,1,.48-2.1006,3.1964,3.1964,0,0,1,1.0806-.7608l2.4805-1.24v-.6c0-.6006-.06-.9-.32-1.08a.95.95,0,0,0-.52-.1406.7761.7761,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.18v-1.16a2.046,2.046,0,0,1,.58-1.46,3.6462,3.6462,0,0,1,2.5806-.7607c.3,0,2.08-.02,2.8,1.28a1.8767,1.8767,0,0,1,.24.9805v5.7012a5.0663,5.0663,0,0,0,.3,2.32c.02.06.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.06-.18.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2829,1.2829,0,0,0,.86-.3594l.26-.2207Z" />
                                        <path className="st18" d="M680.5225,832.5345a3.3213,3.3213,0,0,1-2.66,1.0411,3.4394,3.4394,0,0,1-2.7207-.961,2.8754,2.8754,0,0,1-.62-2.02v-4.541a2.9737,2.9737,0,0,1,.56-1.96,3.5856,3.5856,0,0,1,2.7808-1.16c.24,0,2.38,0,3.04,1.54a3.2825,3.2825,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5751,2.5751,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.071,1.071,0,0,0-.5605.14c-.32.2207-.42.541-.42,1.2412v4.461a2.1208,2.1208,0,0,0,.06.6992.8612.8612,0,0,0,.88.6406,1.1787,1.1787,0,0,0,.52-.14,1.3561,1.3561,0,0,0,.4-1.1807v-1.1807h2.3608v1.1612A3.1816,3.1816,0,0,1,680.5225,832.5345Z" />
                                        <path className="st18" d="M687.6421,833.3754v-7.2823a2.0585,2.0585,0,0,0-.08-.8.8566.8566,0,0,0-.8-.46.8889.8889,0,0,0-.9.7,1.43,1.43,0,0,0-.04.42v7.4219h-2.38V819.2523h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0236,2.0236,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M692.6616,821.6527v-2.4h2.4v2.4Zm0,11.7227V823.1332h2.4v10.2422Z" />
                                        <path className="st18" d="M699.9614,828.7738v1.6807a1.9968,1.9968,0,0,0,.06.6806.8122.8122,0,0,0,.82.58.7529.7529,0,0,0,.76-.56,4.9944,4.9944,0,0,0,.08-1.16h2.3008v.82a2.8272,2.8272,0,0,1-.1.92c-.4,1.4405-1.68,1.8409-3.04,1.8409-2.1006,0-2.8008-1.0411-3.0806-1.961a6.59,6.59,0,0,1-.18-1.34v-4.3213a3.8117,3.8117,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8274,2.8274,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M710.2222,833.3754h-2.3208l-2.26-10.2422h2.38l1.0605,7.1015,1.22-7.1015h2.38Z" />
                                        <path className="st18" d="M716.7212,828.7738v1.6807a2,2,0,0,0,.06.6806.8124.8124,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9952,4.9952,0,0,0,.08-1.16h2.3008v.82a2.8272,2.8272,0,0,1-.1.92c-.4,1.4405-1.68,1.8409-3.0405,1.8409-2.1006,0-2.8008-1.0411-3.0806-1.961a6.5922,6.5922,0,0,1-.18-1.34v-4.3213a3.81,3.81,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1807,2.8286,2.8286,0,0,1,.28,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M727.4214,833.3754l.02-1a2.27,2.27,0,0,1-2,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.2207-.86-2.8408v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3511,2.3511,0,0,1,1.18-.3408,2.9931,2.9931,0,0,1,2.0605,1.041v-4.7012h2.38v14.1231Zm.02-7.4414c0-.461-.02-.78-.44-1.02a1.1915,1.1915,0,0,0-.58-.16.9289.9289,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6006.06.82.34,1a1.1113,1.1113,0,0,0,.58.16.9678.9678,0,0,0,.82-.4,1.3052,1.3052,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M743.8213,832.5345a3.3212,3.3212,0,0,1-2.66,1.0411,3.44,3.44,0,0,1-2.7207-.961,2.8754,2.8754,0,0,1-.62-2.02v-4.541a2.9733,2.9733,0,0,1,.56-1.96,3.5853,3.5853,0,0,1,2.7807-1.16c.24,0,2.38,0,3.0406,1.54a3.2825,3.2825,0,0,1,.2,1.32v1.2207H742.061v-1.24a2.5751,2.5751,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.0713,1.0713,0,0,0-.5605.14c-.32.2207-.42.541-.42,1.2412v4.461a2.1242,2.1242,0,0,0,.06.6992.8612.8612,0,0,0,.88.6406,1.1784,1.1784,0,0,0,.52-.14,1.3561,1.3561,0,0,0,.4-1.1807v-1.1807h2.3609v1.1612A3.1818,3.1818,0,0,1,743.8213,832.5345Z" />
                                        <path className="st18" d="M752.4419,832.5345a3.411,3.411,0,0,1-2.7207,1.0411,4.8332,4.8332,0,0,1-1.02-.1006,2.4966,2.4966,0,0,1-1.9-1.62,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1722,3.1722,0,0,1,.2-1.3008,2.9829,2.9829,0,0,1,3.0405-1.66c.32,0,2.28-.02,2.92,1.5a2.9514,2.9514,0,0,1,.24,1.36v4.9414A2.45,2.45,0,0,1,752.4419,832.5345Zm-1.78-6.8808c0-.6406-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.7534.7534,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M755.5405,833.3754V819.2523h2.4v14.1231Z" />
                                        <path className="st18" d="M760.58,833.3754V819.2523h2.4v14.1231Z" />
                                        <path className="st18" d="M767.88,828.7738v1.6807a1.9842,1.9842,0,0,0,.0605.6806.8113.8113,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6807,1.8409-3.041,1.8409-2.1006,0-2.8-1.0411-3.08-1.961a6.5668,6.5668,0,0,1-.1807-1.34v-4.3213a3.8066,3.8066,0,0,1,.2608-1.38,3.587,3.587,0,0,1,5.9013-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M780.3013,832.5345a3.3225,3.3225,0,0,1-2.6612,1.0411,3.4411,3.4411,0,0,1-2.7207-.961,3.6009,3.6009,0,0,1-.42-.72,3.5407,3.5407,0,0,1-.2-1.3008v-4.541a2.9736,2.9736,0,0,1,.5606-1.96,3.585,3.585,0,0,1,2.78-1.16c.24,0,2.3809,0,3.0411,1.54a3.2829,3.2829,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5349,2.5349,0,0,0-.04-.5.8345.8345,0,0,0-.86-.6406,1.0682,1.0682,0,0,0-.5595.14c-.32.2207-.42.541-.42,1.2412v4.461a2.1379,2.1379,0,0,0,.06.6992.861.861,0,0,0,.88.6406,1.1788,1.1788,0,0,0,.52-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.1807h2.36v1.1612A3.18,3.18,0,0,1,780.3013,832.5345Z" />
                                        <path className="st18" d="M786.38,833.475c-1.3809,0-2.1006-.3-2.4-.96a2.62,2.62,0,0,1-.2-1.1806v-6.6211H782.479v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.5605c.14.4805.6006.5606,1.02.5606a2.7591,2.7591,0,0,0,.3994-.04v1.72A5.0176,5.0176,0,0,1,786.38,833.475Z" />
                                        <path className="st18" d="M789.519,821.6527v-2.4h2.4v2.4Zm0,11.7227V823.1332h2.4v10.2422Z" />
                                        <path className="st18" d="M798.28,833.3754h-2.32l-2.2608-10.2422H796.08l1.06,7.1015,1.22-7.1015h2.3808Z" />
                                        <path className="st18" d="M804.78,828.7738v1.6807a2.0128,2.0128,0,0,0,.06.6806.8128.8128,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3v.82a2.8407,2.8407,0,0,1-.1.92c-.4,1.4405-1.6807,1.8409-3.041,1.8409-2.1,0-2.8-1.0411-3.08-1.961a6.5668,6.5668,0,0,1-.1807-1.34v-4.3213a3.8066,3.8066,0,0,1,.2608-1.38,3.587,3.587,0,0,1,5.9013-.1807,2.8344,2.8344,0,0,1,.2793,1.461v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M811.3188,833.3754V819.2523h2.4v14.1231Z" />
                                        <path className="st18" d="M820.2,834.1156a4.649,4.649,0,0,1-.8,2.0605,2.3184,2.3184,0,0,1-2.0605.7393,8.0378,8.0378,0,0,1-1.54-.18l.3594-1.88a3.2074,3.2074,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6612-10.2422H817.86l1.32,6.581,1.0195-6.581h2.3408Z" />
                                        <path className="st18" d="M830.76,824.8334l-1.36,1.98a3.7683,3.7683,0,0,0-.82,1.62,4.33,4.33,0,0,0-.0391.7v.44H826.28v-.7a5.6269,5.6269,0,0,1,.0195-.66,3.1548,3.1548,0,0,1,.66-1.6006l1.32-2.04a2.4053,2.4053,0,0,0,.56-1.6406v-1.2608a.8859.8859,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.1929,10.1929,0,0,1,.08-1.3408c.2-1.18.86-2.2207,3.2413-2.2207a3.8727,3.8727,0,0,1,2.3007.6005,1.9476,1.9476,0,0,1,.6.72,4.1,4.1,0,0,1,.26,1.7607v.8A3.7631,3.7631,0,0,1,830.76,824.8334Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1226.3848,509.3744h-2.74l-1.36-9.6416-1.5205,9.6416h-2.78l-2.58-14.1231h2.7l1.42,10.6426,1.52-10.6426h2.461l1.4805,10.6426,1.4794-10.6426h2.6407Z" />
                                        <path className="st18" d="M1235.2842,509.3744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8568.8568,0,0,0-.8008-.4609.8884.8884,0,0,0-.8994.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.3809V495.2513h2.3408l.02,4.7012a2.7218,2.7218,0,0,1,2.081-1.02,2.0238,2.0238,0,0,1,2,1.4,2.9229,2.9229,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1244.4238,509.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2528,2.2528,0,0,1-1.7.74,2.0934,2.0934,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.975,2.975,0,0,1,.48-2.1006,3.2053,3.2053,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9575.9575,0,0,0-.52-.14.7761.7761,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.1807v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6477,3.6477,0,0,1,2.581-.76c.3,0,2.08-.0205,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.068,5.068,0,0,0,.3007,2.32c.02.06.06.1.1592.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3007.2793-.3408.68-.3408,1.4794,0,.7608.1006,1.2.58,1.1807a1.2785,1.2785,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1252.2637,509.475c-1.3809,0-2.1006-.3008-2.4014-.961a2.6268,2.6268,0,0,1-.1992-1.18v-6.6211h-1.3008v-1.5811h1.28v-3.7207h2.36v3.7207h1.4209v1.5811h-1.4209v5.8408a1.3269,1.3269,0,0,0,.0606.56c.14.48.6.56,1.02.56a2.637,2.637,0,0,0,.4-.04v1.7207A5.07,5.07,0,0,1,1252.2637,509.475Z" />
                                        <path className="st18" d="M1264.9834,509.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2528,2.2528,0,0,1-1.7.74,2.0939,2.0939,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9749,2.9749,0,0,1,.48-2.1006,3.2057,3.2057,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9576.9576,0,0,0-.52-.14.776.776,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1806v-1.16a2.0494,2.0494,0,0,1,.58-1.461,3.6482,3.6482,0,0,1,2.5811-.76c.3,0,2.08-.0205,2.8,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0681,5.0681,0,0,0,.3008,2.32c.02.06.06.1.1592.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2.16-.3008.2793-.3408.68-.3408,1.4794,0,.7608.1006,1.2.58,1.1807a1.2786,1.2786,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1273.8232,509.3744l.02-1a2.2679,2.2679,0,0,1-2,1.2,2.4516,2.4516,0,0,1-1.4209-.44c-.76-.56-.8594-1.22-.8594-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3592,2.3592,0,0,1,1.18-.34,2.991,2.991,0,0,1,2.0606,1.04v-4.7012h2.3808v14.1231Zm.02-7.4414c0-.46-.02-.78-.4395-1.02a1.2064,1.2064,0,0,0-.58-.16.9363.9363,0,0,0-.5205.16c-.4.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.971.971,0,0,0,.82-.4,1.3129,1.3129,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1283.043,509.3744l.02-1a2.2678,2.2678,0,0,1-2,1.2,2.4516,2.4516,0,0,1-1.4209-.44c-.76-.56-.8594-1.22-.8594-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3592,2.3592,0,0,1,1.18-.34,2.991,2.991,0,0,1,2.0605,1.04v-4.7012h2.3809v14.1231Zm.02-7.4414c0-.46-.02-.78-.4395-1.02a1.2063,1.2063,0,0,0-.58-.16.9361.9361,0,0,0-.52.16c-.4.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.971.971,0,0,0,.82-.4,1.3123,1.3123,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1288.1025,497.6517v-2.4h2.4v2.4Zm0,11.7227V499.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M1296.3027,509.475c-1.3808,0-2.1006-.3008-2.4-.961a2.6151,2.6151,0,0,1-.2-1.18v-6.6211h-1.3007v-1.5811h1.28v-3.7207h2.3614v3.7207h1.42v1.5811h-1.42v5.8408a1.35,1.35,0,0,0,.06.56c.14.48.6006.56,1.0205.56a2.638,2.638,0,0,0,.3995-.04v1.7207A5.07,5.07,0,0,1,1296.3027,509.475Z" />
                                        <path className="st18" d="M1299.4414,497.6517v-2.4h2.4v2.4Zm0,11.7227V499.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M1310.1826,508.5345a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.02-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.45,2.45,0,0,1,1310.1826,508.5345Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7474.7474,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1317.5029,509.3744v-7.541c0-.6407-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.0205,1.1806-1.0205,1.3808v7.3809h-2.3809V499.1322h2.4209l-.0205.74a2.6381,2.6381,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1326.6221,509.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3594.3809a2.257,2.257,0,0,1-1.7012.74,2.0953,2.0953,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2156,3.2156,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.3194-1.08a.9642.9642,0,0,0-.5205-.14.7767.7767,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1807v-1.16a2.05,2.05,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.0205,2.8008,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0662,5.0662,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2826,1.2826,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1331.3008,509.3744V495.2513h2.4v14.1231Z" />
                                        <path className="st18" d="M1200.4346,524.7933c-.42.52-1.02.7-2.3.7h-2.2012v5.8809h-2.62V517.2513h5.2412a3.6458,3.6458,0,0,1,.7.0606,1.97,1.97,0,0,1,1.38,1,5.1406,5.1406,0,0,1,.3007,2.12v1.86C1200.9355,523.6127,1200.875,524.2533,1200.4346,524.7933Zm-2.06-4.0205a1.7071,1.7071,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2607c.54,0,.84-.06,1-.28a1.8256,1.8256,0,0,0,.1807-.98Z" />
                                        <path className="st18" d="M1207.9141,523.3129a1.6837,1.6837,0,0,0-.7793-.16,1.604,1.604,0,0,0-.9.24,2.13,2.13,0,0,0-.6406,1.94v6.041h-2.36V521.1322h2.3809l-.02,1.3008a4.1074,4.1074,0,0,1,.36-.6006,2.2168,2.2168,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1215.6543,530.5345a3.4123,3.4123,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.02-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.3194,0,2.28-.0195,2.92,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.45,2.45,0,0,1,1215.6543,530.5345Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7449.7449,0,0,0-.4795.1406c-.3808.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1220.2529,534.5756a2.9042,2.9042,0,0,1-1.58.34,6.8244,6.8244,0,0,1-.96-.06v-1.98c.4795,0,.64-.02.84-.2.2608-.24.2207-.4405.2207-1.02V521.1322h2.38v11.2022A2.5964,2.5964,0,0,1,1220.2529,534.5756Zm-1.4795-14.9434v-2.3809h2.38v2.3809Z" />
                                        <path className="st18" d="M1226.0537,526.7738v1.6807a2.0072,2.0072,0,0,0,.06.68.811.811,0,0,0,.82.58.7533.7533,0,0,0,.7607-.5605,4.9851,4.9851,0,0,0,.0791-1.16h2.3008v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8277,3.8277,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8164,2.8164,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8709.8709,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1238.4736,530.5345a3.3222,3.3222,0,0,1-2.66,1.04,3.4393,3.4393,0,0,1-2.7207-.96,2.8872,2.8872,0,0,1-.62-2.02v-4.541a2.9744,2.9744,0,0,1,.5605-1.9609,3.5877,3.5877,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.6228,2.6228,0,0,0-.04-.5.8346.8346,0,0,0-.86-.64,1.073,1.073,0,0,0-.5606.14c-.3193.22-.42.54-.42,1.24v4.4609a2.1076,2.1076,0,0,0,.0606.7.8611.8611,0,0,0,.88.64,1.1642,1.1642,0,0,0,.52-.14,1.3548,1.3548,0,0,0,.4-1.1807v-1.18h2.3613v1.16A3.1847,3.1847,0,0,1,1238.4736,530.5345Z" />
                                        <path className="st18" d="M1244.5537,531.475c-1.3808,0-2.1006-.3008-2.4014-.961a2.6281,2.6281,0,0,1-.1992-1.18v-6.6211h-1.3008v-1.5811h1.28v-3.7207h2.36v3.7207h1.4209v1.5811h-1.4209v5.8408a1.3289,1.3289,0,0,0,.0605.5605c.14.48.6.56,1.02.56a2.6357,2.6357,0,0,0,.4-.04v1.7207A5.0692,5.0692,0,0,1,1244.5537,531.475Z" />
                                        <path className="st18" d="M1253.0938,530.6547a3.0722,3.0722,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5842,3.5842,0,0,1-.22-1.32v-1.0606h2.2v.8a2.4326,2.4326,0,0,0,.0606.6406.7964.7964,0,0,0,.8594.66.9652.9652,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5258,2.5258,0,0,1-1.2207-2.46c.0205-2.041,1.2607-2.6211,3.1006-2.6211,1.501,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1407h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2012,1.54a3.6851,3.6851,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,1253.0938,530.6547Z" />
                                        <path className="st18" d="M1265.6533,531.3744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2528,2.2528,0,0,1-1.7.74,2.0934,2.0934,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.975,2.975,0,0,1,.4805-2.1006,3.2043,3.2043,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9575.9575,0,0,0-.52-.14.7761.7761,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.1807v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6477,3.6477,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.068,5.068,0,0,0,.3007,2.32c.02.06.06.1.1592.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3008.2793-.3408.68-.3408,1.4794,0,.7608.1006,1.2.58,1.1807a1.2785,1.2785,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1275.0137,523.3129a1.6853,1.6853,0,0,0-.78-.16,1.6076,1.6076,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.041h-2.36V521.1322h2.38l-.0195,1.3008a4.0738,4.0738,0,0,1,.3593-.6006,2.2169,2.2169,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1279.3125,526.7738v1.6807a1.9786,1.9786,0,0,0,.0605.68.8112.8112,0,0,0,.82.58.7522.7522,0,0,0,.76-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3008v.82a2.82,2.82,0,0,1-.1006.9209c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.04-3.0811-1.96a6.6252,6.6252,0,0,1-.18-1.3408v-4.32a3.8037,3.8037,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8269,2.8269,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.8193-1.02a.8717.8717,0,0,0-.8809,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1299.2939,531.3744h-2.2412l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.1611-6.7617h2.32Z" />
                                        <path className="st18" d="M1305.6924,526.7738v1.6807a1.9786,1.9786,0,0,0,.0605.68.81.81,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.574,6.574,0,0,1-.1807-1.3408v-4.32a3.8113,3.8113,0,0,1,.2608-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1321.8525,531.3744l.0205-1a2.27,2.27,0,0,1-2.0009,1.2,2.451,2.451,0,0,1-1.42-.44c-.7607-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3638,2.3638,0,0,1,1.1807-.34,2.9947,2.9947,0,0,1,2.0605,1.04v-4.7012h2.38v14.1231Zm.0205-7.4414c0-.46-.0205-.78-.44-1.02a1.2013,1.2013,0,0,0-.58-.16.9363.9363,0,0,0-.52.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.0606.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3058,1.3058,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1332.6123,530.5345a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.0195-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.45,2.45,0,0,1,1332.6123,530.5345Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7474.7474,0,0,0-.4795.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1335.7109,519.6517v-2.4h2.4v2.4Zm0,11.7227V521.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M1344.9727,531.3744v-7.541c0-.6407-.04-.9-.36-1.1006a.7529.7529,0,0,0-.46-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.3808V521.1322h2.4209l-.0205.74a2.6378,2.6378,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1356.5127,532.3949a2.5188,2.5188,0,0,1-1.04,2.02,4.3574,4.3574,0,0,1-2.4209.6,6.1142,6.1142,0,0,1-.82-.06,2.5894,2.5894,0,0,1-2.16-1.48,3.8141,3.8141,0,0,1-.16-1.26h2.36a1.5467,1.5467,0,0,0,.04.48.8374.8374,0,0,0,.88.6006.9727.9727,0,0,0,.54-.1406c.4-.22.4209-.68.4209-1.08v-1.58a2.5191,2.5191,0,0,1-2.081,1,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.2607v-5.4209a3.5134,3.5134,0,0,1,.1406-1.12,2.0442,2.0442,0,0,1,2.04-1.4609,2.9161,2.9161,0,0,1,2.1211,1.02v-.82h2.4v10.6025C1356.5527,531.9545,1356.5322,532.1752,1356.5127,532.3949Zm-2.3809-8.4219c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.041v4.5605a1.9515,1.9515,0,0,0,.08.6006.85.85,0,0,0,.8594.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1232.9434,553.3744c-.2-.22-.2393-.3994-.2793-.9-.12.12-.2207.2607-.36.3809a2.2546,2.2546,0,0,1-1.7.74,2.0919,2.0919,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.0811-.76l2.48-1.24v-.6006c0-.6-.0605-.9-.32-1.08a.9616.9616,0,0,0-.52-.14.7774.7774,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6449,3.6449,0,0,1,2.58-.76c.3,0,2.08-.02,2.8007,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0662,5.0662,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2808,1.2808,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1243.0234,552.6547a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5708,3.5708,0,0,1-.2207-1.32v-1.0606h2.2012v.8a2.4425,2.4425,0,0,0,.06.6406.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2479,1.2479,0,0,0-.7-1.32l-2.001-1.4209a2.5251,2.5251,0,0,1-1.22-2.46c.0195-2.041,1.26-2.6211,3.1005-2.6211,1.5,0,2.32.52,2.6407,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1407h-2.0605v-.74c0-.16.04-1.12-.8809-1.12a.7786.7786,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,1243.0234,552.6547Z" />
                                        <path className="st18" d="M1257.8242,552.5941a1.919,1.919,0,0,1-.44.54,2.0171,2.0171,0,0,1-1.32.44,3.1335,3.1335,0,0,1-2.2207-1.0606v4.4014h-2.38V543.1322h2.38v.78a3.2659,3.2659,0,0,1,2.1406-.9805,2.1783,2.1783,0,0,1,1.7.82,3.0564,3.0564,0,0,1,.48,1.8809v5.4414A3.5227,3.5227,0,0,1,1257.8242,552.5941Zm-2.0605-6.7607c0-.58-.1407-.8008-.42-.9805a1.0959,1.0959,0,0,0-.5206-.14,1.0332,1.0332,0,0,0-.54.14,1.0513,1.0513,0,0,0-.44,1.04v4.6006a1.435,1.435,0,0,0,.08.5811.9352.9352,0,0,0,.9.56.8594.8594,0,0,0,.84-.4805,1.6,1.6,0,0,0,.1006-.58Z" />
                                        <path className="st18" d="M1266.3838,552.5345a3.4133,3.4133,0,0,1-2.7207,1.04,4.8538,4.8538,0,0,1-1.0205-.1,2.499,2.499,0,0,1-1.9-1.6211,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1844,3.1844,0,0,1,.1992-1.3,2.9851,2.9851,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.4547,2.4547,0,0,1,1266.3838,552.5345Zm-1.7813-6.8818c0-.64-.06-.92-.38-1.12a.7258.7258,0,0,0-.4395-.12.7486.7486,0,0,0-.4805.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1274.8838,552.6547a3.072,3.072,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5857,3.5857,0,0,1-.22-1.32v-1.0606h2.2v.8a2.438,2.438,0,0,0,.0605.6406.7966.7966,0,0,0,.8594.66.965.965,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.526,2.526,0,0,1-1.2207-2.46c.02-2.041,1.2608-2.6211,3.1006-2.6211,1.501,0,2.32.52,2.6406,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1407h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2011,1.54a3.6814,3.6814,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,1274.8838,552.6547Z" />
                                        <path className="st18" d="M1283.2637,552.6547a3.0722,3.0722,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5857,3.5857,0,0,1-.22-1.32v-1.0606h2.2v.8a2.438,2.438,0,0,0,.0605.6406.7965.7965,0,0,0,.8594.66.965.965,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.526,2.526,0,0,1-1.2207-2.46c.0205-2.041,1.2608-2.6211,3.1006-2.6211,1.501,0,2.32.52,2.6406,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1407h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7777.7777,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2012,1.54a3.6814,3.6814,0,0,1,.62.62,2.7258,2.7258,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,1283.2637,552.6547Z" />
                                        <path className="st18" d="M1286.2422,541.6517v-2.4h2.4v2.4Zm0,11.7227V543.1322h2.4v10.2422Z" />
                                        <path className="st18" d="M1297.8232,552.1742a2.1418,2.1418,0,0,1-2.0605,1.4209,2.5412,2.5412,0,0,1-1.72-.7,2.2189,2.2189,0,0,1-.36-.4609l-.0205.94h-2.38V539.2513h2.38v4.6817c.1406-.12.2608-.24.4-.3408a3.3034,3.3034,0,0,1,1.74-.66,2.1514,2.1514,0,0,1,2.001,1.36,2.6261,2.6261,0,0,1,.18,1.02v5.8017A2.9052,2.9052,0,0,1,1297.8232,552.1742Zm-2.2607-6.2607a1.1628,1.1628,0,0,0-.2793-.9209.8166.8166,0,0,0-.62-.24.9059.9059,0,0,0-.94.62,2.0049,2.0049,0,0,0-.08.7v4.1416c0,.08,0,.26.02.46.08.6.3408.9.98.9a.7731.7731,0,0,0,.66-.28,1.5637,1.5637,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M1300.501,553.3744V539.2513h2.4v14.1231Z" />
                                        <path className="st18" d="M1307.8018,548.7738v1.6807a1.9786,1.9786,0,0,0,.0605.68.81.81,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1,0-2.8-1.04-3.08-1.96a6.5667,6.5667,0,0,1-.1807-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1320.7822,544.8324l-1.36,1.9805a3.7739,3.7739,0,0,0-.82,1.6211,4.3183,4.3183,0,0,0-.0391.7v.4394h-2.2607v-.7a5.6249,5.6249,0,0,1,.02-.66,3.1525,3.1525,0,0,1,.66-1.6l1.32-2.041a2.4034,2.4034,0,0,0,.5605-1.6406v-1.26a.8865.8865,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.3408h-2.2207v-1.4805a10.1757,10.1757,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2412-2.2207a3.877,3.877,0,0,1,2.3008.6,1.9441,1.9441,0,0,1,.6.7207,4.0946,4.0946,0,0,1,.26,1.7607v.8A3.7608,3.7608,0,0,1,1320.7822,544.8324Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M962.3359,403.0746h-2.74l-1.36-9.6421-1.5206,9.6421h-2.78l-2.5811-14.1231h2.7l1.4209,10.6421,1.52-10.6421h2.46l1.48,10.6421,1.4805-10.6421h2.6406Z" />
                                        <path className="st18" d="M971.2344,403.0746v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8556.8556,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42v7.4214h-2.38V388.9515h2.34l.02,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0252,2.0252,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M981.9551,402.2343a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8443,4.8443,0,0,1-1.0205-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9826,2.9826,0,0,1,3.04-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9492,2.9492,0,0,1,.24,1.36v4.941A2.4539,2.4539,0,0,1,981.9551,402.2343Zm-1.78-6.8814c0-.64-.0605-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7515.7515,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M998.0352,401.4545c-.6807,1.96-3.3008,1.92-3.6006,1.92a6.8731,6.8731,0,0,1-1.3408-.14,2.9484,2.9484,0,0,1-2.3809-1.6806,3.8017,3.8017,0,0,1-.1992-1.48v-7.6816c0-.24.0195-.5.04-.74a2.6913,2.6913,0,0,1,1-2.1806,4.2988,4.2988,0,0,1,2.92-.82,4.7056,4.7056,0,0,1,2.6407.64,2.4636,2.4636,0,0,1,.8808,1.04,3.12,3.12,0,0,1,.24,1.6v2.1607h-2.5606v-1.78c0-1.0806-.1807-1.6607-1.3408-1.6607-1,0-1.2.62-1.2,1.5406v7.5615c0,.92.2,1.6006,1.28,1.6006,1.16,0,1.2607-.66,1.2607-1.7408v-1.84h2.5606v2A5.2516,5.2516,0,0,1,998.0352,401.4545Z" />
                                        <path className="st18" d="M1004.6738,403.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2528,2.2528,0,0,1-1.7.74,2.092,2.092,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9744,2.9744,0,0,1,.4805-2.1006,3.198,3.198,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9525.9525,0,0,0-.5195-.14.7758.7758,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.9605v.38h-2.1807v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6475,3.6475,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8739,1.8739,0,0,1,.24.98v5.7012a5.0671,5.0671,0,0,0,.3007,2.32c.02.06.06.1.1592.18Zm-.34-5.1611-1.16.76c-.08.06-.1806.14-.2.16-.3007.28-.3408.68-.3408,1.48,0,.76.1006,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1014.0342,395.0131a1.6853,1.6853,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V392.8324h2.38l-.0195,1.3a4.1034,4.1034,0,0,1,.3593-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1020.7539,395.0131a1.6853,1.6853,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V392.8324h2.38l-.0195,1.3a4.1162,4.1162,0,0,1,.3594-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1022.793,391.3519v-2.4h2.4v2.4Zm0,11.7227V392.8324h2.4v10.2422Z" />
                                        <path className="st18" d="M1030.0938,398.4735v1.6807a2.0137,2.0137,0,0,0,.0595.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.56,4.9412,4.9412,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8007-1.0405-3.081-1.9605a6.6141,6.6141,0,0,1-.18-1.34v-4.3208a3.8016,3.8016,0,0,1,.26-1.38,3.5866,3.5866,0,0,1,5.9013-.18,2.8284,2.8284,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1042.0332,402.3544a3.071,3.071,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.4357,2.4357,0,0,0,.0605.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5267,2.5267,0,0,1-1.2207-2.4605c.0205-2.04,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6806,1.1206l2.2,1.54a3.6308,3.6308,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1042.0332,402.3544Z" />
                                        <path className="st18" d="M1056.1729,402.2343a3.4109,3.4109,0,0,1-2.7208,1.0405,4.8418,4.8418,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9645,2.9645,0,0,1,.2392,1.36v4.941A2.45,2.45,0,0,1,1056.1729,402.2343Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1063.4531,403.0746v-.84a3.2423,3.2423,0,0,1-2.1806,1.02,2.2511,2.2511,0,0,1-1.2-.36,2.5153,2.5153,0,0,1-.8008-2.34v-7.7217h2.4v7.3613a1.2689,1.2689,0,0,0,.2207.9805.8732.8732,0,0,0,.64.26,1.0291,1.0291,0,0,0,.6006-.2c.3994-.34.32-1.1.32-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M1071.6533,403.1747c-1.3808,0-2.1006-.3-2.4-.96a2.6184,2.6184,0,0,1-.2-1.18V394.413h-1.3007v-1.5806h1.28v-3.7207h2.3614v3.7207h1.42v1.5806h-1.42v5.8408a1.35,1.35,0,0,0,.0595.56c.14.48.6006.56,1.0205.56a2.7011,2.7011,0,0,0,.3994-.04v1.72A5.0429,5.0429,0,0,1,1071.6533,403.1747Z" />
                                        <path className="st18" d="M955.9951,425.0746h-2.24l-1-6.0811-1.2,6.0811h-2.2608l-2.08-10.2422h2.32l1.08,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3213Z" />
                                        <path className="st18" d="M964.335,425.0746v-7.2818a2.0572,2.0572,0,0,0-.08-.8.857.857,0,0,0-.8008-.46.8884.8884,0,0,0-.8994.7,1.4279,1.4279,0,0,0-.04.42v7.4214h-2.3808V410.9515h2.3408l.0195,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0231,2.0231,0,0,1,2,1.4,2.9234,2.9234,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M969.3535,413.3519v-2.4h2.4v2.4Zm0,11.7227V414.8324h2.4v10.2422Z" />
                                        <path className="st18" d="M980.2744,424.2343a3.3225,3.3225,0,0,1-2.66,1.0405,3.4393,3.4393,0,0,1-2.7207-.9605,3.4934,3.4934,0,0,1-.42-.72,3.54,3.54,0,0,1-.2-1.3v-4.541a2.9732,2.9732,0,0,1,.56-1.96,3.5887,3.5887,0,0,1,2.7813-1.16c.2392,0,2.38,0,3.04,1.5405a3.2811,3.2811,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5416,2.5416,0,0,0-.04-.5.8328.8328,0,0,0-.8593-.64,1.07,1.07,0,0,0-.5606.14c-.32.22-.42.5406-.42,1.2408v4.4609a2.14,2.14,0,0,0,.06.7.8614.8614,0,0,0,.8808.64,1.1715,1.1715,0,0,0,.52-.14,1.3561,1.3561,0,0,0,.4-1.1807v-1.18h2.36v1.1607A3.1821,3.1821,0,0,1,980.2744,424.2343Z" />
                                        <path className="st18" d="M987.3945,425.0746v-7.2818a2.06,2.06,0,0,0-.08-.8.8571.8571,0,0,0-.8008-.46.8882.8882,0,0,0-.8994.7,1.4279,1.4279,0,0,0-.04.42v7.4214h-2.3808V410.9515h2.3408l.0195,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0231,2.0231,0,0,1,2,1.4,2.9258,2.9258,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1002.4346,425.0746l-2.2012-5.2212v5.2212h-2.36V410.9515h2.36v7.3819l1.9609-3.501h2.6407l-2.6211,4.2607,2.9,5.9815Z" />
                                        <path className="st18" d="M1008.9336,420.4735v1.6807a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9883,4.9883,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.824,3.824,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8193,2.8193,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1019.3135,425.8148a4.6442,4.6442,0,0,1-.8,2.0606,2.3212,2.3212,0,0,1-2.0606.74,8.0684,8.0684,0,0,1-1.54-.18l.3594-1.88a3.205,3.205,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6611-10.2422h2.36l1.32,6.5815,1.02-6.5815h2.3408Z" />
                                        <path className="st18" d="M1032.0527,425.1747c-1.38,0-2.1006-.3-2.4-.96a2.6321,2.6321,0,0,1-.2-1.18V416.413h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.35,1.35,0,0,0,.06.56c.1407.48.6006.56,1.0205.56a2.701,2.701,0,0,0,.4-.04v1.72A5.0523,5.0523,0,0,1,1032.0527,425.1747Z" />
                                        <path className="st18" d="M1039.3135,425.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2569,2.2569,0,0,1-1.7011.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9794,2.9794,0,0,1,.4805-2.1006,3.2082,3.2082,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9592.9592,0,0,0-.5205-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1807v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.0654,5.0654,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2844,1.2844,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1049.3936,424.3544a3.0713,3.0713,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5814,3.5814,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.0205-2.04,1.2607-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1049.3936,424.3544Z" />
                                        <path className="st18" d="M1056.9336,425.0746l-2.2012-5.2212v5.2212h-2.36V410.9515h2.36v7.3819l1.961-3.501h2.6406l-2.6211,4.2607,2.9014,5.9815Z" />
                                        <path className="st18" d="M1066.5732,424.3544a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5814,3.5814,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.02-2.04,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1066.5732,424.3544Z" />
                                        <path className="st18" d="M1075.9932,416.5326l-1.36,1.9805a3.7718,3.7718,0,0,0-.82,1.6206,4.3238,4.3238,0,0,0-.0391.7v.44h-2.2607v-.7a5.6249,5.6249,0,0,1,.02-.66,3.1528,3.1528,0,0,1,.66-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.5605-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.875,3.875,0,0,1,2.3008.6,1.9413,1.9413,0,0,1,.6.72,4.0989,4.0989,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,1075.9932,416.5326Zm-4.48,8.542V422.474h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1211.1992,311.1444V304.683h-2.3808v6.4614h-2.6016v-14.123h2.6016v5.541h2.3808v-5.541h2.6v14.123Z" />
                                        <path className="st18" d="M1222.2588,310.3041a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8527,4.8527,0,0,1-1.0205-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7465,3.7465,0,0,1-.18-1.38v-4.581a3.1886,3.1886,0,0,1,.1992-1.3,2.9847,2.9847,0,0,1,3.041-1.6607c.32,0,2.2813-.02,2.9209,1.5a2.9493,2.9493,0,0,1,.24,1.36v4.9409A2.4539,2.4539,0,0,1,1222.2588,310.3041Zm-1.78-6.8814c0-.64-.0605-.92-.3808-1.12a.7258.7258,0,0,0-.4395-.12.7542.7542,0,0,0-.4805.14c-.38.28-.3593.7-.3593,1.12v5.2012c0,.18-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1233.3389,311.1444h-2.24l-1.0009-6.081-1.1993,6.081h-2.2607l-2.0811-10.2422h2.32l1.081,6.7613,1.2-6.7613h1.94l1.08,6.7613,1.16-6.7613h2.3213Z" />
                                        <path className="st18" d="M1247.0977,311.1444l.0195-1a2.2685,2.2685,0,0,1-2,1.2,2.4478,2.4478,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3526,2.3526,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0606,1.0405v-4.7011h2.3808v14.123Zm.0195-7.4414c0-.46-.0195-.78-.4395-1.02a1.1991,1.1991,0,0,0-.58-.16.93.93,0,0,0-.52.16c-.4.28-.3808.7-.3808,1.14v4.3614c0,.6.0605.82.34,1a1.12,1.12,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3048,1.3048,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1257.8574,310.3041a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7271,3.7271,0,0,1-.18-1.38v-4.581a3.1886,3.1886,0,0,1,.1992-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.92,1.5a2.9509,2.9509,0,0,1,.24,1.36v4.9409A2.45,2.45,0,0,1,1257.8574,310.3041Zm-1.7793-6.8814c0-.64-.0605-.92-.3808-1.12a.7258.7258,0,0,0-.4395-.12.7521.7521,0,0,0-.4805.14c-.3808.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1274.3984,311.1444h-2.24l-1.001-6.081-1.2,6.081h-2.26l-2.0811-10.2422h2.32l1.0811,6.7613,1.1992-6.7613h1.9414l1.08,6.7613,1.16-6.7613h2.32Z" />
                                        <path className="st18" d="M1280.7969,306.5433v1.6807a1.9816,1.9816,0,0,0,.0605.68.8112.8112,0,0,0,.82.58.753.753,0,0,0,.76-.5606,4.9957,4.9957,0,0,0,.08-1.16h2.3008v.82a2.8174,2.8174,0,0,1-.1006.92c-.3994,1.44-1.6807,1.84-3.04,1.84-2.1015,0-2.8007-1.0405-3.081-1.96a6.6378,6.6378,0,0,1-.1807-1.34V303.723a3.8236,3.8236,0,0,1,.26-1.38,3.5875,3.5875,0,0,1,5.9023-.18,2.8305,2.8305,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8714.8714,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1297.4766,303.0829a1.687,1.687,0,0,0-.7793-.16,1.6059,1.6059,0,0,0-.9.24,2.1308,2.1308,0,0,0-.6407,1.94v6.0415h-2.3613V300.9022h2.3809l-.02,1.3a4.2127,4.2127,0,0,1,.3594-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1301.7754,306.5433v1.6807a1.9816,1.9816,0,0,0,.0605.68.8125.8125,0,0,0,.82.58.7507.7507,0,0,0,.76-.5606,4.9957,4.9957,0,0,0,.08-1.16h2.3008v.82a2.8443,2.8443,0,0,1-.1.92c-.4,1.44-1.6817,1.84-3.0411,1.84-2.1015,0-2.8007-1.0405-3.08-1.96a6.5865,6.5865,0,0,1-.1817-1.34V303.723a3.8177,3.8177,0,0,1,.2617-1.38,3.5857,3.5857,0,0,1,5.9-.18,2.8305,2.8305,0,0,1,.2793,1.46v2.92Zm1.7012-2.94c0-.22.039-1.02-.82-1.02a.8725.8725,0,0,0-.8808,1.02v1.26h1.7012Z" />
                                        <path className="st18" d="M1316.2988,311.1444h-2.2422l-1-6.081-1.1992,6.081h-2.2617l-2.08-10.2422h2.32l1.08,6.7613,1.2012-6.7613h1.9394l1.08,6.7613,1.1621-6.7613h2.32Z" />
                                        <path className="st18" d="M1324.5566,311.1444c-.2011-.22-.24-.4-.2812-.9-.1192.12-.2188.26-.3594.38a2.2532,2.2532,0,0,1-1.7012.74,2.0943,2.0943,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.48-2.1006,3.19,3.19,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0605-.9-.32-1.08a.9515.9515,0,0,0-.5195-.14.7765.7765,0,0,0-.74.4,2.9585,2.9585,0,0,0-.08.96v.38h-2.1817v-1.16a2.052,2.052,0,0,1,.58-1.46,3.6536,3.6536,0,0,1,2.582-.76c.2989,0,2.08-.02,2.8008,1.28a1.887,1.887,0,0,1,.2383.98v5.7012a5.0793,5.0793,0,0,0,.3008,2.32c.0195.06.0605.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.1816.14-.2011.16-.2989.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2886,1.2886,0,0,0,.8613-.36l.26-.22Z" />
                                        <path className="st18" d="M1333.916,303.0829a1.6866,1.6866,0,0,0-.7793-.16,1.6063,1.6063,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.94v6.0415h-2.3613V300.9022h2.3808l-.0195,1.3a4.2029,4.2029,0,0,1,.3594-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1340.1172,311.1444l.02-1a2.2684,2.2684,0,0,1-2,1.2,2.4478,2.4478,0,0,1-1.42-.44c-.7617-.56-.8613-1.22-.8613-2.84v-4.6611a2.5409,2.5409,0,0,1,1.041-2.38,2.353,2.353,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0605,1.0405v-4.7011h2.3809v14.123Zm.02-7.4414c0-.46-.02-.78-.4394-1.02a1.2,1.2,0,0,0-.58-.16.9355.9355,0,0,0-.5215.16c-.3984.28-.3789.7-.3789,1.14v4.3614c0,.6.0586.82.34,1a1.12,1.12,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3042,1.3042,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1230.2891,326.5629c-.42.52-1.02.7-2.3008.7h-2.2012v5.8813h-2.62v-14.123h5.2412a3.6814,3.6814,0,0,1,.7.06,1.97,1.97,0,0,1,1.38,1,5.1426,5.1426,0,0,1,.3008,2.1206v1.86C1230.7891,325.3827,1230.7285,326.0228,1230.2891,326.5629Zm-2.0606-4.0205a1.7073,1.7073,0,0,0-.2-1.1407,1.1184,1.1184,0,0,0-.96-.3h-1.2813v4.1407h1.2608c.54,0,.8408-.06,1.0009-.28a1.8349,1.8349,0,0,0,.18-.9805Z" />
                                        <path className="st18" d="M1235.3477,328.5433v1.6807a1.9816,1.9816,0,0,0,.0605.68.8111.8111,0,0,0,.82.58.753.753,0,0,0,.76-.5606,4.9957,4.9957,0,0,0,.08-1.16h2.3007v.82a2.82,2.82,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.04,1.84-2.1015,0-2.8008-1.0405-3.081-1.96a6.6378,6.6378,0,0,1-.1807-1.34V325.723a3.8236,3.8236,0,0,1,.26-1.38,3.5875,3.5875,0,0,1,5.9023-.18,2.8305,2.8305,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8714.8714,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1246.5684,325.0829a1.6886,1.6886,0,0,0-.78-.16,1.6094,1.6094,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.3613V322.9022h2.3809l-.02,1.3a4.1162,4.1162,0,0,1,.3594-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1251.8281,320.9618c-.4414.1-.4414.58-.4414.68v1.26h1.0606v1.5806h-1.08v8.6616h-2.36v-8.6616h-1.06v-1.5806h1.06a10.6257,10.6257,0,0,1,.06-2.28,1.7211,1.7211,0,0,1,.6006-1.1406,2.85,2.85,0,0,1,1.9805-.5c.3,0,.6005.02.88.04v1.96A2.1084,2.1084,0,0,0,1251.8281,320.9618Z" />
                                        <path className="st18" d="M1260.1875,332.3041a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.0195-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7271,3.7271,0,0,1-.18-1.38v-4.581a3.1886,3.1886,0,0,1,.1992-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.92,1.5a2.9493,2.9493,0,0,1,.24,1.36v4.9409A2.45,2.45,0,0,1,1260.1875,332.3041Zm-1.7793-6.8814c0-.64-.0605-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7519.7519,0,0,0-.48.14c-.3808.28-.36.7-.36,1.12v5.2012c0,.18-.0205,1.06.82,1.06.8994,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1267.9678,325.0829a1.6853,1.6853,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V322.9022h2.3809l-.02,1.3a4.0445,4.0445,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1278.3691,333.1444v-7.5815a1.1169,1.1169,0,0,0-.3613-1.0406.9352.9352,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.4614h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7911.7911,0,0,0-.5606-.18c-.9795,0-.92,1.04-.92,1.22v7.5815h-2.3594V322.9022h2.38v.68a2.9182,2.9182,0,0,1,2.02-.9,2.4415,2.4415,0,0,1,1.92,1.02,3.14,3.14,0,0,1,1.6806-.94,3.23,3.23,0,0,1,.66-.06,1.9261,1.9261,0,0,1,1.96,1.4,2.7,2.7,0,0,1,.1006.92v8.1216Z" />
                                        <path className="st18" d="M1287.5078,333.1444c-.2012-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2525,2.2525,0,0,1-1.7.74,2.0907,2.0907,0,0,1-1.24-.38c-.8409-.62-.78-1.84-.78-2.74a2.9739,2.9739,0,0,1,.48-2.1006,3.1886,3.1886,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0605-.9-.32-1.08a.9515.9515,0,0,0-.5195-.14.7758.7758,0,0,0-.74.4,2.955,2.955,0,0,0-.08.96v.38h-2.1806v-1.16a2.0474,2.0474,0,0,1,.58-1.46,3.647,3.647,0,0,1,2.581-.76c.2988,0,2.08-.02,2.8,1.28a1.8741,1.8741,0,0,1,.24.98v5.7012a5.0788,5.0788,0,0,0,.3,2.32c.0195.06.0605.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.1807.14-.2012.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2822,1.2822,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1296.4082,333.1444v-7.5415c0-.64-.041-.9-.3613-1.1a.7481.7481,0,0,0-.459-.12c-1.1211,0-1.0215,1.18-1.0215,1.38v7.3813h-2.3809V322.9022h2.4219l-.0215.74a2.6379,2.6379,0,0,1,2.0411-.94,1.9954,1.9954,0,0,1,2.0605,1.44,3.2361,3.2361,0,0,1,.08,1v8.0019Z" />
                                        <path className="st18" d="M1307.2871,332.3041a3.3216,3.3216,0,0,1-2.66,1.0405,3.4413,3.4413,0,0,1-2.7208-.96,2.89,2.89,0,0,1-.621-2.0206v-4.541a2.98,2.98,0,0,1,.5605-1.96,3.5887,3.5887,0,0,1,2.7813-1.16c.24,0,2.3808,0,3.041,1.5406a3.3028,3.3028,0,0,1,.1992,1.32v1.22h-2.34v-1.24a2.4469,2.4469,0,0,0-.041-.5.832.832,0,0,0-.8593-.64,1.0718,1.0718,0,0,0-.5606.14c-.32.22-.42.5405-.42,1.2407v4.4609a2.1092,2.1092,0,0,0,.0605.7.8594.8594,0,0,0,.8789.64,1.1782,1.1782,0,0,0,.5215-.14,1.3586,1.3586,0,0,0,.4-1.1807v-1.18h2.3594v1.1606A3.1759,3.1759,0,0,1,1307.2871,332.3041Z" />
                                        <path className="st18" d="M1312.4668,328.5433v1.6807a2.043,2.043,0,0,0,.0586.68.8124.8124,0,0,0,.82.58.7544.7544,0,0,0,.7617-.5606,4.9957,4.9957,0,0,0,.08-1.16h2.2988v.82a2.8443,2.8443,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.041,1.84-2.1,0-2.7988-1.0405-3.08-1.96a6.5486,6.5486,0,0,1-.18-1.34V325.723a3.8034,3.8034,0,0,1,.26-1.38,3.5875,3.5875,0,0,1,5.9023-.18,2.8305,2.8305,0,0,1,.2793,1.46v2.92Zm1.6992-2.94c0-.22.041-1.02-.82-1.02a.8709.8709,0,0,0-.8789,1.02v1.26h1.6992Z" />
                                        <path className="st18" d="M1325.4473,324.6024l-1.3594,1.9805a3.76,3.76,0,0,0-.82,1.6206,4.3244,4.3244,0,0,0-.0391.7v.44h-2.2617v-.7a5.6249,5.6249,0,0,1,.02-.66,3.1618,3.1618,0,0,1,.66-1.6l1.32-2.0405a2.4046,2.4046,0,0,0,.5605-1.6407v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2011-1.18.8613-2.2207,3.2421-2.2207a3.8776,3.8776,0,0,1,2.3008.6,1.95,1.95,0,0,1,.6.72,4.0976,4.0976,0,0,1,.26,1.7607v.8A3.7513,3.7513,0,0,1,1325.4473,324.6024Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M948.6846,312.1747v-6.4614h-2.3809v6.4614h-2.6006V298.0516h2.6006v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M959.7451,311.3344a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8443,4.8443,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9827,2.9827,0,0,1,3.04-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.4539,2.4539,0,0,1,959.7451,311.3344Zm-1.78-6.8814c0-.64-.06-.92-.3808-1.12a.7258.7258,0,0,0-.4395-.12.7509.7509,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M970.8252,312.1747h-2.24l-1.001-6.0811-1.2,6.0811h-2.26l-2.081-10.2422h2.32l1.081,6.7612,1.2-6.7612h1.9405l1.08,6.7612,1.16-6.7612h2.32Z" />
                                        <path className="st18" d="M984.584,312.1747l.02-1a2.2686,2.2686,0,0,1-2,1.2,2.451,2.451,0,0,1-1.4209-.4405c-.76-.56-.8594-1.22-.8594-2.84V304.433a2.54,2.54,0,0,1,1.04-2.38,2.353,2.353,0,0,1,1.18-.34,2.9916,2.9916,0,0,1,2.0605,1.04v-4.7012h2.3809v14.1231Zm.02-7.4414c0-.46-.02-.78-.4394-1.02a1.1985,1.1985,0,0,0-.58-.16.932.932,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1193,1.1193,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3105,1.3105,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M995.3438,311.3344a3.4118,3.4118,0,0,1-2.7208,1.0405,4.8418,4.8418,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,995.3438,311.3344Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7478.7478,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1011.8838,312.1747h-2.24l-1-6.0811-1.2,6.0811h-2.2608l-2.08-10.2422h2.32l1.08,6.7612,1.2-6.7612h1.9405l1.08,6.7612,1.16-6.7612h2.3213Z" />
                                        <path className="st18" d="M1018.2832,307.5736v1.6807a2.01,2.01,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7533.7533,0,0,0,.7607-.56,4.9883,4.9883,0,0,0,.0791-1.16h2.3008v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8262,3.8262,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.82,2.82,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8711.8711,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1038.1445,309.1542a2.9671,2.9671,0,0,1-1.32,2.58,4.184,4.184,0,0,1-2.2412.44h-4.1807V298.0516h4.7813a2.9635,2.9635,0,0,1,1.94.7,3.5314,3.5314,0,0,1,1.04,3.02v6.6016C1038.1641,308.6342,1038.1641,308.8939,1038.1445,309.1542Zm-2.581-7.6016a1.3866,1.3866,0,0,0-.36-1.2007,1.7411,1.7411,0,0,0-1.18-.24h-1.0205v10.0019h.92a4.5877,4.5877,0,0,0,.74-.04c.8408-.16.9-.78.9-1.48Z" />
                                        <path className="st18" d="M1043.0029,307.5736v1.6807a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0405-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1054.9434,311.4545a3.0713,3.0713,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5817,3.5817,0,0,1-.22-1.32v-1.06h2.2v.8a2.4347,2.4347,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.0205-2.04,1.2607-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7786.7786,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1054.9434,311.4545Z" />
                                        <path className="st18" d="M1057.9219,300.452v-2.4h2.4v2.4Zm0,11.7227V301.9325h2.4v10.2422Z" />
                                        <path className="st18" d="M1069.5039,313.1947a2.5181,2.5181,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0065,6.0065,0,0,1-.82-.06,2.5877,2.5877,0,0,1-2.16-1.48,3.8171,3.8171,0,0,1-.16-1.26h2.36a1.55,1.55,0,0,0,.04.48.8376.8376,0,0,0,.88.6.9784.9784,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08v-1.5806a2.52,2.52,0,0,1-2.0811,1,2.6366,2.6366,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.26v-5.4214a3.5152,3.5152,0,0,1,.1406-1.12,2.0445,2.0445,0,0,1,2.04-1.46,2.9146,2.9146,0,0,1,2.1211,1.02v-.82h2.4v10.602C1069.5439,312.7548,1069.5234,312.975,1069.5039,313.1947Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.0405v4.56a1.9906,1.9906,0,0,0,.0791.6006.8509.8509,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1076.4033,312.1747v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3814h-2.3809V301.9325h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M962.0146,333.3344a3.4124,3.4124,0,0,1-2.7207,1.0405,4.8452,4.8452,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7279,3.7279,0,0,1-.18-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9825,2.9825,0,0,1,3.04-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.4539,2.4539,0,0,1,962.0146,333.3344Zm-1.78-6.8814c0-.64-.0606-.92-.3809-1.12a.7255.7255,0,0,0-.4394-.12.7513.7513,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M969.2939,334.1747v-.84a3.2372,3.2372,0,0,1-2.1806,1.02,2.2533,2.2533,0,0,1-1.2-.36,2.5146,2.5146,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2726,1.2726,0,0,0,.22.9805.8754.8754,0,0,0,.6406.26,1.0267,1.0267,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M979.0137,326.1132a1.6853,1.6853,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.1307,2.1307,0,0,0-.64,1.94v6.0415h-2.36V323.9325h2.38l-.0195,1.3a4.1034,4.1034,0,0,1,.3593-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M992.3936,332.4745c-.6006,2.1-3.0206,2.0005-3.4405,2.0005a4.2808,4.2808,0,0,1-2.3408-.6206,2.1539,2.1539,0,0,1-.78-1.08,4.1388,4.1388,0,0,1-.18-1.18v-1.9h2.42v1.34c0,.72.08,1.06.44,1.26a1.0659,1.0659,0,0,0,.4805.12.9374.9374,0,0,0,.5595-.18c.32-.24.38-.58.38-1.28V320.0516h2.6211V330.974A6.61,6.61,0,0,1,992.3936,332.4745Z" />
                                        <path className="st18" d="M1001.0137,333.3344a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8421,4.8421,0,0,1-1.02-.1,2.4969,2.4969,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9492,2.9492,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1001.0137,333.3344Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7478.7478,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.0205,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1010.6533,332.9745a2.14,2.14,0,0,1-2.0595,1.42,2.542,2.542,0,0,1-1.7208-.7,2.2657,2.2657,0,0,1-.36-.46l-.0195.94h-2.3809V320.0516h2.3809v4.6812c.14-.12.26-.24.3994-.34a3.3074,3.3074,0,0,1,1.7412-.66,2.15,2.15,0,0,1,2,1.36,2.6279,2.6279,0,0,1,.18,1.0205v5.8012A2.9063,2.9063,0,0,1,1010.6533,332.9745Zm-2.26-6.2612a1.1636,1.1636,0,0,0-.28-.9205.8122.8122,0,0,0-.62-.24.906.906,0,0,0-.9405.62,2.0289,2.0289,0,0,0-.08.7v4.1411a4.4871,4.4871,0,0,0,.02.46c.08.6.34.9.98.9a.7731.7731,0,0,0,.66-.28,1.5623,1.5623,0,0,0,.2608-1.08Z" />
                                        <path className="st18" d="M1021.9531,334.2748c-1.3808,0-2.1006-.3-2.4013-.9605a2.63,2.63,0,0,1-.1993-1.18v-6.6211h-1.3007v-1.5806h1.28v-3.7207h2.36v3.7207h1.4209v1.5806h-1.4209v5.8408a1.3289,1.3289,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6905,2.6905,0,0,0,.4-.04v1.72A5.0446,5.0446,0,0,1,1021.9531,334.2748Z" />
                                        <path className="st18" d="M1028.9326,334.9149a4.643,4.643,0,0,1-.8,2.0606,2.3209,2.3209,0,0,1-2.0605.74,8.0694,8.0694,0,0,1-1.54-.18l.3594-1.88a3.2065,3.2065,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6612-10.2422h2.36l1.32,6.5815,1.0205-6.5815h2.3408Z" />
                                        <path className="st18" d="M1039.4131,333.3944a1.9284,1.9284,0,0,1-.4395.54,2.0216,2.0216,0,0,1-1.32.4405,3.134,3.134,0,0,1-2.2207-1.0606v4.4009h-2.3808V323.9325h2.3808v.78a3.264,3.264,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0549,3.0549,0,0,1,.48,1.88v5.4414A3.517,3.517,0,0,1,1039.4131,333.3944Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.09,1.09,0,0,0-.52-.14,1.0332,1.0332,0,0,0-.54.14,1.0518,1.0518,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9349.9349,0,0,0,.9.56.8591.8591,0,0,0,.84-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1044.5322,329.5736v1.6807a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0405-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1056.4727,333.4545a3.0713,3.0713,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5817,3.5817,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.02-2.04,1.2607-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1056.4727,333.4545Z" />
                                        <path className="st18" d="M1065.8926,325.6327l-1.36,1.9805a3.773,3.773,0,0,0-.82,1.6206,4.3347,4.3347,0,0,0-.039.7v.44h-2.2608v-.7a5.6278,5.6278,0,0,1,.0195-.66,3.1528,3.1528,0,0,1,.66-1.6l1.32-2.0406a2.4044,2.4044,0,0,0,.5606-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8748,3.8748,0,0,1,2.3008.6,1.9509,1.9509,0,0,1,.6.72,4.0973,4.0973,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,1065.8926,325.6327Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M684.17,310.3749h-2.74l-1.36-9.6421-1.52,9.6421h-2.78l-2.5805-14.1231h2.7l1.42,10.6421,1.5205-10.6421h2.46l1.4805,10.6421,1.48-10.6421h2.6406Z" />
                                        <path className="st18" d="M693.0693,310.3749v-7.2818a2.06,2.06,0,0,0-.08-.8.8564.8564,0,0,0-.8-.46.8888.8888,0,0,0-.9.7,1.4279,1.4279,0,0,0-.04.42v7.4214h-2.38V296.2518h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0242,2.0242,0,0,1,2,1.4,2.938,2.938,0,0,1,.14,1.0205v8.0215Z" />
                                        <path className="st18" d="M703.79,309.5345a3.412,3.412,0,0,1-2.7208,1.0406,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38v-4.5811a3.1765,3.1765,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.9491,2.9491,0,0,1,.24,1.36v4.941A2.4518,2.4518,0,0,1,703.79,309.5345Zm-1.7808-6.8813c0-.64-.06-.92-.38-1.12a.7269.7269,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M712.3481,298.6522v-2.4h2.4v2.4Zm0,11.7227V300.1327h2.4v10.2422Z" />
                                        <path className="st18" d="M722.7886,309.6547a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5757,3.5757,0,0,1-.22-1.32v-1.06h2.2006v.8a2.44,2.44,0,0,0,.06.64.7971.7971,0,0,0,.86.66.966.966,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.4205a2.5261,2.5261,0,0,1-1.22-2.46c.02-2.04,1.26-2.6206,3.1006-2.6206,1.5,0,2.32.52,2.6406,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7789.7789,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6448,3.6448,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,722.7886,309.6547Z" />
                                        <path className="st18" d="M736.9287,310.3749c-.04-.08-.08-.14-.12-.22a2.562,2.562,0,0,1-.26-1.14v-3.2207c0-.22,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24v6.2212h-2.6006V296.2518h5.2011a2.6434,2.6434,0,0,1,1.7207.46,2.7939,2.7939,0,0,1,.82,2.5205v1.52a3.54,3.54,0,0,1-.28,1.5205,1.6853,1.6853,0,0,1-.3.38,2.6048,2.6048,0,0,1-1.24.5,2.85,2.85,0,0,1,.78.28,2.3407,2.3407,0,0,1,1.14,2.18v2.8809a3.2172,3.2172,0,0,0,.36,1.66.8522.8522,0,0,0,.24.22Zm-.42-10.9424c0-.54-.08-.72-.3-.94a1.2029,1.2029,0,0,0-.9-.22h-1.36v3.8609h1.36a1.1939,1.1939,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                        <path className="st18" d="M743.9478,305.7738v1.6807a1.9967,1.9967,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0406,1.84-2.1006,0-2.8008-1.0406-3.08-1.9605a6.5885,6.5885,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8239,2.8239,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M755.8882,309.6547a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5757,3.5757,0,0,1-.22-1.32v-1.06h2.2007v.8a2.4411,2.4411,0,0,0,.06.64.7972.7972,0,0,0,.86.66.9658.9658,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2481,1.2481,0,0,0-.7-1.32l-2-1.4205a2.5261,2.5261,0,0,1-1.22-2.46c.02-2.04,1.26-2.6206,3.1006-2.6206,1.5,0,2.32.52,2.6406,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14H754.248v-.74c0-.16.04-1.12-.88-1.12a.7789.7789,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6448,3.6448,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,755.8882,309.6547Z" />
                                        <path className="st18" d="M765.2285,309.5946a1.9141,1.9141,0,0,1-.44.54,2.0172,2.0172,0,0,1-1.32.4405,3.1343,3.1343,0,0,1-2.22-1.0606v4.4009h-2.38V300.1327h2.38v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1781,2.1781,0,0,1,1.7.82,3.0489,3.0489,0,0,1,.4805,1.88v5.4414A3.512,3.512,0,0,1,765.2285,309.5946Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.0931,1.0931,0,0,0-.5205-.14,1.0379,1.0379,0,0,0-.54.14,1.0523,1.0523,0,0,0-.44,1.0405v4.6006a1.425,1.425,0,0,0,.08.5806.9344.9344,0,0,0,.9.56.86.86,0,0,0,.84-.48,1.6075,1.6075,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M773.7881,309.5345a3.41,3.41,0,0,1-2.7207,1.0406,4.84,4.84,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.964,2.964,0,0,1,.2393,1.36v4.941A2.4493,2.4493,0,0,1,773.7881,309.5345Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M781.1084,310.3749v-7.5415c0-.64-.04-.9-.36-1.1a.7491.7491,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3809V300.1327h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M791.5078,309.6547a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5832,3.5832,0,0,1-.22-1.32v-1.06h2.2v.8a2.4362,2.4362,0,0,0,.06.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2469,1.2469,0,0,0-.7-1.32l-2-1.4205a2.5264,2.5264,0,0,1-1.2208-2.46c.0206-2.04,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.634,3.634,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,791.5078,309.6547Z" />
                                        <path className="st18" d="M794.4863,298.6522v-2.4h2.4v2.4Zm0,11.7227V300.1327h2.4v10.2422Z" />
                                        <path className="st18" d="M806.0684,309.1747a2.1416,2.1416,0,0,1-2.0606,1.42,2.5448,2.5448,0,0,1-1.7207-.7,2.2617,2.2617,0,0,1-.36-.4605l-.02.9405h-2.3808V296.2518h2.3808v4.6812c.14-.12.26-.24.4-.34a3.3,3.3,0,0,1,1.74-.66,2.1493,2.1493,0,0,1,2,1.36,2.6115,2.6115,0,0,1,.1806,1.0206v5.8012A2.91,2.91,0,0,1,806.0684,309.1747Zm-2.2608-6.2612a1.1632,1.1632,0,0,0-.28-.9205.8119.8119,0,0,0-.62-.24.9065.9065,0,0,0-.94.62,2.0258,2.0258,0,0,0-.08.7v4.1411a4.4871,4.4871,0,0,0,.02.46c.08.6.34.9.9805.9a.7752.7752,0,0,0,.66-.28,1.5635,1.5635,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M808.7461,310.3749V296.2518h2.4v14.1231Z" />
                                        <path className="st18" d="M816.0469,305.7738v1.6807a2.0127,2.0127,0,0,0,.0595.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M715.1689,320.1923c-.44.1-.44.58-.44.68v1.26h1.0606v1.5806h-1.0806v8.6616h-2.36v-8.6616h-1.06v-1.5806h1.06a10.5962,10.5962,0,0,1,.06-2.28,1.721,1.721,0,0,1,.6-1.1406,2.85,2.85,0,0,1,1.9805-.5c.3,0,.6.02.88.04v1.9605A2.1089,2.1089,0,0,0,715.1689,320.1923Z" />
                                        <path className="st18" d="M723.5293,331.5345a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8426,4.8426,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9833,2.9833,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.92,1.5a2.9489,2.9489,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,723.5293,331.5345Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M731.3086,324.3134a1.6845,1.6845,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.94v6.0415h-2.36V322.1327h2.38l-.02,1.3a4.12,4.12,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M746.7891,332.3749h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0806-10.2422h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612H749.11Z" />
                                        <path className="st18" d="M755.1284,332.3749v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8562.8562,0,0,0-.8-.46.889.889,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42v7.4214h-2.38V318.2518h2.34l.02,4.7012a2.7205,2.7205,0,0,1,2.0806-1.02,2.0241,2.0241,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.0205v8.0215Z" />
                                        <path className="st18" d="M764.2686,332.3749c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.0805-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.079,5.079,0,0,0,.3,2.32c.0205.06.0605.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2813,1.2813,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M772.1084,332.475c-1.3809,0-2.1006-.3-2.4-.9605a2.6171,2.6171,0,0,1-.2-1.18v-6.6211H768.207v-1.5806h1.28V318.412h2.3613v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.14.48.6006.56,1.0205.56a2.6879,2.6879,0,0,0,.3994-.04v1.72A5.0429,5.0429,0,0,1,772.1084,332.475Z" />
                                        <path className="st18" d="M781.6885,323.8329l-1.36,1.9805a3.7725,3.7725,0,0,0-.82,1.6206,4.3347,4.3347,0,0,0-.039.7v.44H777.208v-.7a5.6278,5.6278,0,0,1,.0195-.66,3.1531,3.1531,0,0,1,.66-1.6l1.32-2.0405a2.4044,2.4044,0,0,0,.5606-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34H775.667v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8748,3.8748,0,0,1,2.3008.6,1.9413,1.9413,0,0,1,.6.72,4.0973,4.0973,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,781.6885,323.8329Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M714.1724,210v-6.4614h-2.3809V210h-2.6006V195.8768h2.6006v5.541h2.3809v-5.541h2.6005V210Z" />
                                        <path className="st18" d="M725.2324,209.16a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8426,4.8426,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.0411-1.6606c.32,0,2.28-.02,2.92,1.5a2.95,2.95,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,725.2324,209.16Zm-1.7807-6.8813c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.7492.7492,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M736.3125,210h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0805-10.2422h2.32l1.0805,6.7612,1.2-6.7612h1.9405l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M750.0713,210l.02-1a2.27,2.27,0,0,1-2.0005,1.2,2.45,2.45,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5407,2.5407,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9926,2.9926,0,0,1,2.0605,1.0405v-4.7012h2.38V210Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1985,1.1985,0,0,0-.58-.16.9306.9306,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1161,1.1161,0,0,0,.58.16.9689.9689,0,0,0,.82-.4,1.3069,1.3069,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M760.8315,209.16a3.4118,3.4118,0,0,1-2.7207,1.0406,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38v-4.5811a3.1681,3.1681,0,0,1,.2-1.3,2.9834,2.9834,0,0,1,3.0406-1.6606c.32,0,2.28-.02,2.92,1.5a2.95,2.95,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,760.8315,209.16Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.727.727,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M777.3726,210h-2.2413l-1-6.0811-1.2,6.0811H770.67l-2.08-10.2422h2.32l1.08,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.1612-6.7612h2.32Z" />
                                        <path className="st18" d="M783.77,205.3988V207.08a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.99,4.99,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M694.8633,232l.24-12.2427L692.8228,232h-2.4205l-2.34-12.2427.2,12.2427h-2.5605V217.8768h4.2412l1.66,9.8824,1.6807-9.8824h4.1807V232Z" />
                                        <path className="st18" d="M704.3418,232c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9766,2.9766,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9547.9547,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.0806-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2811,1.2811,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M713.2417,232v-7.5415c0-.64-.04-.9-.36-1.1a.7486.7486,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V232h-2.38V221.7577h2.42l-.02.74a2.6345,2.6345,0,0,1,2.0405-.94,1.9957,1.9957,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1V232Z" />
                                        <path className="st18" d="M722.3618,232c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9762,2.9762,0,0,1,.48-2.1006,3.2007,3.2007,0,0,1,1.0805-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M733.582,233.02a2.5137,2.5137,0,0,1-1.04,2.02,4.3532,4.3532,0,0,1-2.42.6,6.0007,6.0007,0,0,1-.82-.06,2.5889,2.5889,0,0,1-2.1607-1.48,3.8431,3.8431,0,0,1-.16-1.26h2.36a1.5346,1.5346,0,0,0,.04.48.8383.8383,0,0,0,.88.6.9809.9809,0,0,0,.54-.14c.4-.22.42-.68.42-1.08V231.12a2.5183,2.5183,0,0,1-2.0806,1.0005,2.6411,2.6411,0,0,1-1.22-.3c-.94-.5-1-1.36-1-2.26v-5.4213a3.5149,3.5149,0,0,1,.14-1.12,2.044,2.044,0,0,1,2.04-1.46,2.9118,2.9118,0,0,1,2.1206,1.02v-.82h2.4V232.36C733.6221,232.58,733.6021,232.8,733.582,233.02Zm-2.38-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.04v4.5605a1.9515,1.9515,0,0,0,.08.6006.8506.8506,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M738.521,227.3988V229.08a1.9935,1.9935,0,0,0,.06.68.8116.8116,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.96a6.5959,6.5959,0,0,1-.18-1.34v-4.3208a3.8127,3.8127,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8252,2.8252,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M756.4014,231.16a3.322,3.322,0,0,1-2.66,1.0406,3.4394,3.4394,0,0,1-2.7207-.96,2.878,2.878,0,0,1-.62-2.02v-4.541a2.974,2.974,0,0,1,.56-1.96,3.5873,3.5873,0,0,1,2.7808-1.16c.24,0,2.38,0,3.04,1.5405a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5833,2.5833,0,0,0-.04-.5.834.834,0,0,0-.86-.64,1.071,1.071,0,0,0-.5605.14c-.32.22-.42.5406-.42,1.2407V229.1a2.1226,2.1226,0,0,0,.06.7.8613.8613,0,0,0,.88.64,1.1716,1.1716,0,0,0,.52-.14,1.3556,1.3556,0,0,0,.4-1.1807v-1.18h2.3608V229.1A3.1827,3.1827,0,0,1,756.4014,231.16Z" />
                                        <path className="st18" d="M763.521,232v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8562.8562,0,0,0-.8-.46.889.889,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42V232h-2.38V217.8768h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0231,2.0231,0,0,1,2,1.4,2.9175,2.9175,0,0,1,.1407,1.02V232Z" />
                                        <path className="st18" d="M772.6606,232c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.48-2.1006,3.2089,3.2089,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0595-.9-.3193-1.08a.9592.9592,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38H768.5v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8808,1.8808,0,0,1,.2393.9805v5.7012a5.066,5.066,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1806.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M781.561,232v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38V232h-2.3808V221.7577H779.76l-.0206.74a2.6364,2.6364,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1V232Z" />
                                        <path className="st18" d="M793.1021,233.02a2.5184,2.5184,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0079,6.0079,0,0,1-.82-.06,2.5878,2.5878,0,0,1-2.16-1.48,3.817,3.817,0,0,1-.16-1.26h2.36a1.5482,1.5482,0,0,0,.04.48.8375.8375,0,0,0,.88.6.978.978,0,0,0,.54-.14c.4-.22.4209-.68.4209-1.08V231.12a2.5194,2.5194,0,0,1-2.0811,1.0005,2.6366,2.6366,0,0,1-1.22-.3c-.94-.5-1.001-1.36-1.001-2.26v-5.4213a3.5124,3.5124,0,0,1,.1407-1.12,2.0443,2.0443,0,0,1,2.04-1.46,2.9144,2.9144,0,0,1,2.1211,1.02v-.82h2.4V232.36C793.1421,232.58,793.1216,232.8,793.1021,233.02Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2608.2-.38.44-.38,1.04v4.5605a1.9874,1.9874,0,0,0,.0791.6006.8508.8508,0,0,0,.86.54.96.96,0,0,0,.9805-1.1407Z" />
                                        <path className="st18" d="M798.0405,227.3988V229.08a1.9778,1.9778,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.56,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.0406-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M811.021,223.4579l-1.36,1.9805a3.7725,3.7725,0,0,0-.82,1.6206,4.3347,4.3347,0,0,0-.039.7v.44h-2.2608v-.7a5.5993,5.5993,0,0,1,.02-.66,3.1521,3.1521,0,0,1,.66-1.6l1.32-2.04a2.4044,2.4044,0,0,0,.5606-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34H805v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8748,3.8748,0,0,1,2.3008.6,1.9413,1.9413,0,0,1,.6.72,4.097,4.097,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,811.021,223.4579ZM806.5405,232v-2.6006h2.32V232Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1233.1387,88.8744h-2.74l-1.36-9.6421-1.5205,9.6421h-2.78l-2.58-14.1231h2.7l1.42,10.6421,1.5206-10.6421h2.4609l1.48,10.6421,1.48-10.6421h2.6406Z" />
                                        <path className="st18" d="M1242.0381,88.8744V81.5927a2.0577,2.0577,0,0,0-.08-.8.8572.8572,0,0,0-.8008-.46.8884.8884,0,0,0-.8994.7,1.4314,1.4314,0,0,0-.04.42v7.4214h-2.3809V74.7513h2.3408l.02,4.7012a2.7218,2.7218,0,0,1,2.081-1.02,2.0231,2.0231,0,0,1,2,1.4,2.9229,2.9229,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1251.1777,88.8744c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2528,2.2528,0,0,1-1.7.74,2.092,2.092,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9744,2.9744,0,0,1,.4805-2.1006,3.1988,3.1988,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9525.9525,0,0,0-.52-.14.7758.7758,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.96v.38h-2.1807v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6475,3.6475,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8741,1.8741,0,0,1,.24.98v5.7012a5.0659,5.0659,0,0,0,.3008,2.32c.0195.06.0595.1.1591.18Zm-.34-5.1611-1.16.76c-.08.06-.1806.14-.2.16-.3007.28-.3408.68-.3408,1.48,0,.76.1006,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1259.0176,88.9745c-1.3809,0-2.1006-.3-2.4014-.9605a2.63,2.63,0,0,1-.1992-1.18V80.2128h-1.3008V78.6322h1.28V74.9115h2.36v3.7207h1.4209v1.5806h-1.4209v5.8408a1.3269,1.3269,0,0,0,.0606.56c.14.48.6.56,1.02.56a2.7024,2.7024,0,0,0,.4-.04v1.72A5.0436,5.0436,0,0,1,1259.0176,88.9745Z" />
                                        <path className="st18" d="M1270.7773,88.9745c-1.3808,0-2.1005-.3-2.4013-.9605a2.63,2.63,0,0,1-.1992-1.18V80.2128h-1.3008V78.6322h1.28V74.9115h2.36v3.7207h1.4209v1.5806h-1.4209v5.8408a1.3289,1.3289,0,0,0,.0605.56c.14.48.6.56,1.02.56a2.7037,2.7037,0,0,0,.4-.04v1.72A5.0439,5.0439,0,0,1,1270.7773,88.9745Z" />
                                        <path className="st18" d="M1277.7568,89.6146a4.643,4.643,0,0,1-.8,2.0606,2.3209,2.3209,0,0,1-2.0605.74,8.0879,8.0879,0,0,1-1.541-.18l.36-1.88a3.2065,3.2065,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6611-10.2422h2.36l1.32,6.5815,1.0205-6.5815h2.3409Z" />
                                        <path className="st18" d="M1288.2373,88.0941a1.9176,1.9176,0,0,1-.44.54,2.018,2.018,0,0,1-1.32.44,3.1336,3.1336,0,0,1-2.22-1.0606v4.4009h-2.3808V78.6322h2.3808v.78a3.2641,3.2641,0,0,1,2.1407-.98,2.1807,2.1807,0,0,1,1.7.82,3.0548,3.0548,0,0,1,.4794,1.88v5.4414A3.5114,3.5114,0,0,1,1288.2373,88.0941Zm-2.0605-6.7612c0-.58-.14-.8-.42-.98a1.0912,1.0912,0,0,0-.5205-.14,1.0375,1.0375,0,0,0-.54.14,1.053,1.053,0,0,0-.4395,1.04v4.6006a1.4167,1.4167,0,0,0,.08.5806.9339.9339,0,0,0,.8994.56.86.86,0,0,0,.8408-.4805,1.6131,1.6131,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1293.3564,84.2733V85.954a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.5605,4.9412,4.9412,0,0,0,.08-1.16h2.3008v.82a2.8177,2.8177,0,0,1-.1006.9205c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.08-1.9605a6.5682,6.5682,0,0,1-.1806-1.34V81.453a3.824,3.824,0,0,1,.26-1.38,3.5866,3.5866,0,0,1,5.9013-.18,2.8284,2.8284,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1305.2969,88.1542a3.0712,3.0712,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.4357,2.4357,0,0,0,.06.64.7976.7976,0,0,0,.86.66.9674.9674,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.9805,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5267,2.5267,0,0,1-1.2207-2.46c.02-2.04,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6806,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1305.2969,88.1542Z" />
                                        <path className="st18" d="M1319.4365,88.0341a3.4106,3.4106,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.0195-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38V81.3929a3.1668,3.1668,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.9209,1.5005a2.9643,2.9643,0,0,1,.2393,1.36v4.941A2.45,2.45,0,0,1,1319.4365,88.0341Zm-1.78-6.8814c0-.64-.0595-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.751.751,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1325.7559,76.6918c-.44.1-.44.58-.44.68v1.26h1.06v1.5806h-1.08v8.6616h-2.36V80.2128h-1.0605V78.6322h1.0605a10.7135,10.7135,0,0,1,.06-2.28,1.7211,1.7211,0,0,1,.6006-1.1406,2.8515,2.8515,0,0,1,1.98-.5c.3,0,.6.02.88.04v1.96A2.112,2.112,0,0,0,1325.7559,76.6918Z" />
                                        <path className="st18" d="M1222.6689,110.8744l.24-12.2427-2.2813,12.2427h-2.42l-2.3408-12.2427.2,12.2427h-2.5606V96.7513h4.2412l1.66,9.8824,1.6807-9.8824h4.1806v14.1231Z" />
                                        <path className="st18" d="M1232.1475,110.8744c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2524,2.2524,0,0,1-1.7.74,2.0921,2.0921,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9744,2.9744,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9522.9522,0,0,0-.52-.14.7756.7756,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.1807v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.6476,3.6476,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8,1.28a1.8747,1.8747,0,0,1,.24.98v5.7012a5.0659,5.0659,0,0,0,.3008,2.32c.02.06.06.1.1592.18Zm-.34-5.1611-1.16.76c-.08.06-.1807.14-.2.16-.3008.28-.3409.68-.3409,1.48,0,.76.1006,1.2.58,1.18a1.2807,1.2807,0,0,0,.86-.36l.2607-.22Z" />
                                        <path className="st18" d="M1241.0469,110.8744v-7.5415c0-.64-.04-.9-.3594-1.1a.7506.7506,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3808V100.6322h2.4209l-.0205.74a2.6347,2.6347,0,0,1,2.041-.94,1.995,1.995,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1250.167,110.8744c-.2-.22-.2393-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2544,2.2544,0,0,1-1.7.74,2.0906,2.0906,0,0,1-1.24-.38c-.8408-.62-.78-1.84-.78-2.74a2.9789,2.9789,0,0,1,.48-2.1006,3.2038,3.2038,0,0,1,1.0811-.76l2.4805-1.24v-.6c0-.6-.0606-.9-.32-1.08a.956.956,0,0,0-.52-.14.7772.7772,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.18v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.6448,3.6448,0,0,1,2.58-.76c.3,0,2.08-.02,2.8008,1.28a1.8747,1.8747,0,0,1,.24.98v5.7012a5.0667,5.0667,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2825,1.2825,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1261.3877,111.8944a2.5151,2.5151,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,6.0292,6.0292,0,0,1-.82-.06,2.5879,2.5879,0,0,1-2.16-1.48,3.8409,3.8409,0,0,1-.16-1.26h2.36a1.55,1.55,0,0,0,.04.48.839.839,0,0,0,.8809.6.9833.9833,0,0,0,.54-.14c.3994-.22.42-.68.42-1.08V109.994a2.5192,2.5192,0,0,1-2.081,1.0005,2.64,2.64,0,0,1-1.22-.3c-.94-.5-1-1.36-1-2.26v-5.4214a3.5136,3.5136,0,0,1,.14-1.12,2.0444,2.0444,0,0,1,2.04-1.46,2.9127,2.9127,0,0,1,2.1211,1.02v-.82h2.4v10.6021C1261.4277,111.4545,1261.4072,111.6747,1261.3877,111.8944Zm-2.3809-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.0405v4.56a1.9515,1.9515,0,0,0,.08.6006.85.85,0,0,0,.86.54.9594.9594,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1266.3262,106.2733v1.6807a1.9819,1.9819,0,0,0,.0605.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3008v.82a2.8177,2.8177,0,0,1-.1006.9205c-.3994,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.081-1.9605a6.6141,6.6141,0,0,1-.18-1.34V103.453a3.8016,3.8016,0,0,1,.26-1.38,3.5866,3.5866,0,0,1,5.9013-.18,2.8284,2.8284,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8719.8719,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1277.5469,102.8129a1.6853,1.6853,0,0,0-.78-.16,1.61,1.61,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V100.6322h2.38l-.02,1.3a4.1034,4.1034,0,0,1,.3593-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1284.9873,110.1542a3.072,3.072,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.4357,2.4357,0,0,0,.0605.64.7966.7966,0,0,0,.8594.66.9655.9655,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2481,1.2481,0,0,0-.7-1.32l-2-1.42a2.5267,2.5267,0,0,1-1.2207-2.46c.02-2.04,1.2608-2.6206,3.1006-2.6206,1.501,0,2.32.52,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2011,1.54a3.667,3.667,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1284.9873,110.1542Z" />
                                        <path className="st18" d="M1297.5859,110.8744l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.451,2.451,0,0,1-1.42-.44c-.7607-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3577,2.3577,0,0,1,1.1807-.34,2.9932,2.9932,0,0,1,2.06,1.04V96.7513h2.38v14.1231Zm.02-7.4414c0-.4605-.02-.78-.44-1.0205a1.1957,1.1957,0,0,0-.58-.16.9322.9322,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.119,1.119,0,0,0,.58.16.9685.9685,0,0,0,.82-.4,1.3041,1.3041,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1308.3457,110.0341a3.4106,3.4106,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1668,3.1668,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.9209,1.5005a2.9643,2.9643,0,0,1,.2393,1.36v4.941A2.45,2.45,0,0,1,1308.3457,110.0341Zm-1.78-6.8814c0-.64-.0595-.92-.38-1.12a.728.728,0,0,0-.44-.12.751.751,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1324.8867,110.8744h-2.2412l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.1611-6.7612h2.32Z" />
                                        <path className="st18" d="M1331.2852,106.2733v1.6807a2.0137,2.0137,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7608-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1,0-2.8-1.0405-3.08-1.9605a6.5631,6.5631,0,0,1-.1807-1.34V103.453a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1257.1973,132.8744v-7.5415c0-.64-.04-.9-.3594-1.1a.7506.7506,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3808V122.6322h2.4209l-.0205.74a2.6347,2.6347,0,0,1,2.041-.94,1.995,1.995,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1264.457,128.2733v1.6807a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.5605,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34V125.453a3.8255,3.8255,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8181,2.8181,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1273.2568,128.2733v1.6807a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7535.7535,0,0,0,.7608-.5605,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8008-1.0405-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34V125.453a3.8255,3.8255,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8181,2.8181,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1283.957,132.8744l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.451,2.451,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5419,2.5419,0,0,1,1.04-2.38,2.3613,2.3613,0,0,1,1.1807-.34,2.9932,2.9932,0,0,1,2.0605,1.04v-4.7012h2.38v14.1231Zm.02-7.4414c0-.4605-.02-.78-.44-1.0205a1.196,1.196,0,0,0-.58-.16.934.934,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6.0606.82.3409,1a1.1125,1.1125,0,0,0,.58.16.9679.9679,0,0,0,.8194-.4,1.3041,1.3041,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1295.457,124.3324l-1.36,1.98a3.7707,3.7707,0,0,0-.82,1.6206,4.3244,4.3244,0,0,0-.0391.7v.44h-2.2607v-.7a5.352,5.352,0,0,1,.0205-.66,3.1435,3.1435,0,0,1,.6591-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.5605-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.22v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.8594-2.2207,3.24-2.2207a3.8747,3.8747,0,0,1,2.3008.6,1.95,1.95,0,0,1,.6.72,4.0976,4.0976,0,0,1,.26,1.7607v.8A3.7614,3.7614,0,0,1,1295.457,124.3324Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1207.125,209.4745v-6.4614h-2.3809v6.4614h-2.6V195.3514h2.6v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M1218.1846,208.6342a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8421,4.8421,0,0,1-1.02-.1,2.4969,2.4969,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38V201.993a3.1677,3.1677,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1218.1846,208.6342Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7484.7484,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1229.2646,209.4745h-2.24l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3213Z" />
                                        <path className="st18" d="M1243.0234,209.4745l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.451,2.451,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5419,2.5419,0,0,1,1.04-2.38,2.3613,2.3613,0,0,1,1.1807-.34,2.9932,2.9932,0,0,1,2.06,1.0405v-4.7012h2.38v14.1231Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1955,1.1955,0,0,0-.58-.16.9292.9292,0,0,0-.52.16c-.4.28-.3809.7-.3809,1.14v4.3613c0,.6.0606.82.3409,1a1.1125,1.1125,0,0,0,.58.16.9679.9679,0,0,0,.8194-.4,1.3041,1.3041,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1253.7842,208.6342a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8443,4.8443,0,0,1-1.0205-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7275,3.7275,0,0,1-.18-1.38V201.993a3.1677,3.1677,0,0,1,.2-1.3,2.9826,2.9826,0,0,1,3.04-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9492,2.9492,0,0,1,.24,1.36v4.941A2.4539,2.4539,0,0,1,1253.7842,208.6342Zm-1.78-6.8814c0-.64-.06-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7521.7521,0,0,0-.4805.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1270.3242,209.4745h-2.24l-1.001-6.0811-1.2,6.0811h-2.26l-2.081-10.2422h2.32l1.0811,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.32Z" />
                                        <path className="st18" d="M1276.7227,204.8734v1.6807a1.9819,1.9819,0,0,0,.0605.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3007v.82a2.82,2.82,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.081-1.96a6.6141,6.6141,0,0,1-.18-1.34v-4.3208a3.803,3.803,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8284,2.8284,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8719.8719,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1292.8838,209.4745l.0195-1a2.2686,2.2686,0,0,1-2,1.2,2.4519,2.4519,0,0,1-1.4209-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5419,2.5419,0,0,1,1.04-2.38,2.3613,2.3613,0,0,1,1.1807-.34,2.9934,2.9934,0,0,1,2.0605,1.0405v-4.7012h2.3809v14.1231Zm.0195-7.4414c0-.46-.0195-.78-.44-1.02a1.196,1.196,0,0,0-.58-.16.9313.9313,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1193,1.1193,0,0,0,.58.16.9707.9707,0,0,0,.82-.4,1.3105,1.3105,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1300.2021,204.8734v1.6807a2.01,2.01,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7608-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8419,2.8419,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.5631,6.5631,0,0,1-.1807-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8718.8718,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1310.4629,209.4745h-2.32l-2.2608-10.2422h2.3809l1.0605,7.1016,1.22-7.1016h2.3808Z" />
                                        <path className="st18" d="M1316.9619,204.8734v1.6807a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1323.501,209.4745V195.3514h2.4v14.1231Z" />
                                        <path className="st18" d="M1334.2422,208.6342a3.4106,3.4106,0,0,1-2.7207,1.0405,4.84,4.84,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7205,3.7205,0,0,1-.1807-1.38V201.993a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9643,2.9643,0,0,1,.2393,1.36v4.941A2.45,2.45,0,0,1,1334.2422,208.6342Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7507.7507,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1343.7021,208.6942a1.93,1.93,0,0,1-.4394.54,2.0222,2.0222,0,0,1-1.32.44,3.1338,3.1338,0,0,1-2.2207-1.0606v4.4009h-2.3809V199.2323h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.98,2.1794,2.1794,0,0,1,1.7.82,3.0558,3.0558,0,0,1,.48,1.88v5.4414A3.5161,3.5161,0,0,1,1343.7021,208.6942Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.0909,1.0909,0,0,0-.52-.14,1.0331,1.0331,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.935.935,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1222.9941,230.6342a3.4115,3.4115,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38V223.993a3.1681,3.1681,0,0,1,.2-1.3,2.9841,2.9841,0,0,1,3.0411-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1222.9941,230.6342Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7484.7484,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1230.2734,231.4745v-.84a3.2367,3.2367,0,0,1-2.18,1.02,2.2558,2.2558,0,0,1-1.2012-.36,2.5168,2.5168,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2689,1.2689,0,0,0,.2207.98.8733.8733,0,0,0,.64.26,1.0294,1.0294,0,0,0,.6006-.2c.3994-.34.3193-1.1.3193-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M1239.9932,223.413a1.6837,1.6837,0,0,0-.7793-.16,1.6061,1.6061,0,0,0-.9.24,2.1307,2.1307,0,0,0-.6406,1.94v6.0415h-2.36V221.2323h2.3809l-.0205,1.3a4.1375,4.1375,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1256.7744,231.4745l.24-12.2427-2.2812,12.2427h-2.42l-2.3408-12.2427.2,12.2427h-2.5606V217.3514h4.2412l1.66,9.8824,1.6806-9.8824h4.1807v14.1231Z" />
                                        <path className="st18" d="M1266.2529,231.4745c-.1992-.22-.2392-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2547,2.2547,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.8409-.62-.78-1.84-.78-2.74a2.9784,2.9784,0,0,1,.48-2.1006,3.2024,3.2024,0,0,1,1.081-.76l2.4805-1.24v-.6c0-.6-.0606-.9-.32-1.08a.9566.9566,0,0,0-.5205-.14.7773.7773,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6445,3.6445,0,0,1,2.58-.76c.3,0,2.0811-.02,2.8008,1.28a1.8747,1.8747,0,0,1,.24.98v5.7012a5.0654,5.0654,0,0,0,.3,2.32c.02.06.0595.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2822,1.2822,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1275.1533,231.4745V223.933c0-.64-.04-.9-.36-1.1a.7473.7473,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3814h-2.38V221.2323h2.42l-.0195.74a2.6342,2.6342,0,0,1,2.04-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1284.2725,231.4745c-.1993-.22-.2393-.4-.2793-.9-.12.12-.2207.26-.36.38a2.2546,2.2546,0,0,1-1.7.74,2.09,2.09,0,0,1-1.24-.38c-.8408-.62-.78-1.84-.78-2.74a2.9789,2.9789,0,0,1,.4795-2.1006,3.2038,3.2038,0,0,1,1.0811-.76l2.48-1.24v-.6c0-.6-.0605-.9-.32-1.08a.9566.9566,0,0,0-.5205-.14.7771.7771,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.76c.3,0,2.081-.02,2.8007,1.28a1.8741,1.8741,0,0,1,.24.98v5.7012a5.0654,5.0654,0,0,0,.3,2.32c.0205.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2826,1.2826,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1295.4932,232.4945a2.5122,2.5122,0,0,1-1.04,2.02,4.3566,4.3566,0,0,1-2.4209.6,5.996,5.996,0,0,1-.8193-.06,2.588,2.588,0,0,1-2.1611-1.48,3.8409,3.8409,0,0,1-.16-1.26h2.3613a1.5381,1.5381,0,0,0,.0391.48.8389.8389,0,0,0,.8809.6.9808.9808,0,0,0,.54-.14c.4-.22.42-.68.42-1.08v-1.5806a2.5186,2.5186,0,0,1-2.08,1.0005,2.6417,2.6417,0,0,1-1.2207-.3c-.94-.5-1-1.36-1-2.26v-5.4214a3.5136,3.5136,0,0,1,.14-1.12,2.0446,2.0446,0,0,1,2.041-1.46,2.9106,2.9106,0,0,1,2.12,1.02v-.82h2.4v10.6021C1295.5332,232.0546,1295.5137,232.2748,1295.4932,232.4945Zm-2.38-8.4214c0-.2.08-1.24-.961-1.24a.9216.9216,0,0,0-.58.18c-.26.2-.38.44-.38,1.0405v4.56a1.9484,1.9484,0,0,0,.08.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M1300.4326,226.8734v1.6807a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.99,4.99,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1311.6533,223.413a1.6912,1.6912,0,0,0-.78-.16,1.6075,1.6075,0,0,0-.9.24,2.1308,2.1308,0,0,0-.6407,1.94v6.0415h-2.36V221.2323h2.3808l-.02,1.3a4.1505,4.1505,0,0,1,.36-.6,2.2174,2.2174,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1319.0928,230.7543a3.0712,3.0712,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.4357,2.4357,0,0,0,.0605.64.7976.7976,0,0,0,.86.66.9674.9674,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5267,2.5267,0,0,1-1.2207-2.46c.0205-2.0405,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6806,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1319.0928,230.7543Z" />
                                        <path className="st18" d="M1328.5127,222.9325l-1.36,1.9805a3.773,3.773,0,0,0-.82,1.6206,4.3347,4.3347,0,0,0-.039.7v.44h-2.2608v-.7a5.5964,5.5964,0,0,1,.02-.66,3.1518,3.1518,0,0,1,.66-1.6l1.32-2.0406a2.4044,2.4044,0,0,0,.5606-1.6406v-1.26a.8863.8863,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8748,3.8748,0,0,1,2.3008.6,1.9509,1.9509,0,0,1,.6.72,4.097,4.097,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,1328.5127,222.9325Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1469.8252,207.8744V201.413h-2.3809v6.4614h-2.6005V193.7513h2.6005v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M1480.8848,207.0341a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8421,4.8421,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7279,3.7279,0,0,1-.18-1.38v-4.5811a3.1863,3.1863,0,0,1,.1992-1.3,2.9838,2.9838,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.92,1.5005a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1480.8848,207.0341Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7255.7255,0,0,0-.4394-.12.7519.7519,0,0,0-.4805.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.0205,1.06.82,1.06.8995,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1491.9648,207.8744h-2.24l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7612,1.2012-6.7612h1.9394l1.08,6.7612,1.16-6.7612h2.3213Z" />
                                        <path className="st18" d="M1505.7246,207.8744l.0195-1a2.2686,2.2686,0,0,1-2,1.2,2.4519,2.4519,0,0,1-1.4209-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5409,2.5409,0,0,1,1.041-2.38,2.353,2.353,0,0,1,1.18-.34,2.9912,2.9912,0,0,1,2.06,1.04v-4.7012h2.3809v14.1231Zm.0195-7.4414c0-.46-.0195-.78-.4394-1.02a1.2008,1.2008,0,0,0-.58-.16.932.932,0,0,0-.5205.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1186,1.1186,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.3112,1.3112,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1516.4844,207.0341a3.4116,3.4116,0,0,1-2.7207,1.0405,4.8337,4.8337,0,0,1-1.02-.1,2.4968,2.4968,0,0,1-1.9-1.6206,3.7014,3.7014,0,0,1-.1807-1.38v-4.5811a3.1668,3.1668,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.3193,0,2.2793-.02,2.92,1.5005a2.9492,2.9492,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1516.4844,207.0341Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7461.7461,0,0,0-.48.14c-.3809.28-.3613.7-.3613,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1533.0244,207.8744h-2.24l-1.001-6.0811-1.1992,6.0811h-2.2608l-2.081-10.2422h2.32l1.0811,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3213Z" />
                                        <path className="st18" d="M1539.4238,203.2733v1.6807a2.01,2.01,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7542.7542,0,0,0,.7608-.5605,4.9883,4.9883,0,0,0,.0791-1.16h2.3007v.82a2.82,2.82,0,0,1-.1005.9205c-.3995,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.081-1.96a6.6141,6.6141,0,0,1-.18-1.34V200.453a3.803,3.803,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8181,2.8181,0,0,1,.28,1.46v2.92Zm1.6992-2.94c0-.22.0411-1.02-.8193-1.02a.8712.8712,0,0,0-.88,1.02v1.26h1.6992Z" />
                                        <path className="st18" d="M1555.584,207.8744l.0195-1a2.2686,2.2686,0,0,1-2,1.2,2.4519,2.4519,0,0,1-1.4209-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5409,2.5409,0,0,1,1.041-2.38,2.353,2.353,0,0,1,1.18-.34,2.9934,2.9934,0,0,1,2.0605,1.04v-4.7012h2.3809v14.1231Zm.0195-7.4414c0-.46-.0195-.78-.44-1.02a1.196,1.196,0,0,0-.58-.16.9313.9313,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1193,1.1193,0,0,0,.58.16.9707.9707,0,0,0,.82-.4,1.3105,1.3105,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1562.9023,203.2733v1.6807a2.01,2.01,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7608-.5605,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8419,2.8419,0,0,1-.1.9205c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34V200.453a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8718.8718,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1573.1631,207.8744h-2.32l-2.2608-10.2422h2.3809l1.0605,7.1016,1.22-7.1016h2.3808Z" />
                                        <path className="st18" d="M1579.6621,203.2733v1.6807a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7525.7525,0,0,0,.7607-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34V200.453a3.8113,3.8113,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8313,2.8313,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1586.2012,207.8744V193.7513h2.4v14.1231Z" />
                                        <path className="st18" d="M1596.9424,207.0341a3.4106,3.4106,0,0,1-2.7207,1.0405,4.8421,4.8421,0,0,1-1.02-.1,2.4968,2.4968,0,0,1-1.9-1.6206,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1668,3.1668,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6607c.32,0,2.28-.02,2.9209,1.5005a2.9643,2.9643,0,0,1,.2393,1.36v4.941A2.45,2.45,0,0,1,1596.9424,207.0341Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7507.7507,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1606.4023,207.0941a1.93,1.93,0,0,1-.4394.54,2.0222,2.0222,0,0,1-1.32.44,3.1338,3.1338,0,0,1-2.2207-1.0606v4.4009h-2.3809V197.6322h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.18,2.18,0,0,1,1.7.82,3.0558,3.0558,0,0,1,.48,1.88v5.4414A3.5161,3.5161,0,0,1,1606.4023,207.0941Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.0909,1.0909,0,0,0-.52-.14,1.0331,1.0331,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9349.9349,0,0,0,.9.56.8594.8594,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1483.1846,229.0341a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8519,4.8519,0,0,1-1.02-.1,2.4982,2.4982,0,0,1-1.9-1.6206,3.7469,3.7469,0,0,1-.18-1.38v-4.5811a3.1668,3.1668,0,0,1,.2-1.3,2.9825,2.9825,0,0,1,3.04-1.6607c.32,0,2.2813-.02,2.9209,1.5005a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.4538,2.4538,0,0,1,1483.1846,229.0341Zm-1.78-6.8814c0-.64-.06-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7542.7542,0,0,0-.4805.14c-.38.28-.3594.7-.3594,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1490.4639,229.8744v-.84a3.2378,3.2378,0,0,1-2.1807,1.02,2.2555,2.2555,0,0,1-1.2-.36,2.517,2.517,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.98.8733.8733,0,0,0,.64.26,1.0314,1.0314,0,0,0,.6006-.2c.3994-.34.3194-1.1.3194-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M1500.1836,221.8129a1.6837,1.6837,0,0,0-.7793-.16,1.6063,1.6063,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.94v6.0415h-2.36V219.6322h2.38l-.02,1.3a4.1931,4.1931,0,0,1,.3594-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1507.8027,229.8744V215.7513h6.8418v2.1407h-4.2422v3.3208h4.002v2.14h-4.002v4.3408h4.4415v2.1807Z" />
                                        <path className="st18" d="M1525.2646,229.8744v-7.5816a1.1186,1.1186,0,0,0-.36-1.0405.9343.9343,0,0,0-.5-.14c-.8008,0-.9609.66-.9609,1.3v7.4614h-2.3594v-7.5215c0-.42.039-.8-.32-1.1006a.7911.7911,0,0,0-.5606-.18c-.98,0-.92,1.04-.92,1.22v7.5816h-2.36V219.6322h2.38v.68a2.9184,2.9184,0,0,1,2.0215-.9,2.4411,2.4411,0,0,1,1.92,1.02,3.1387,3.1387,0,0,1,1.68-.94,3.23,3.23,0,0,1,.66-.06,1.9273,1.9273,0,0,1,1.9609,1.4,2.7052,2.7052,0,0,1,.1.92v8.1216Z" />
                                        <path className="st18" d="M1536.6445,229.0941a1.9365,1.9365,0,0,1-.4414.54,2.0153,2.0153,0,0,1-1.32.44,3.1353,3.1353,0,0,1-2.22-1.0606v4.4009h-2.38V219.6322h2.38v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0558,3.0558,0,0,1,.4805,1.88v5.4414A3.5258,3.5258,0,0,1,1536.6445,229.0941Zm-2.0605-6.7612c0-.58-.1406-.8-.42-.9805a1.0969,1.0969,0,0,0-.5215-.14,1.0345,1.0345,0,0,0-.5391.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9346.9346,0,0,0,.8994.56.86.86,0,0,0,.8408-.4805,1.6,1.6,0,0,0,.1006-.58Z" />
                                        <path className="st18" d="M1539.502,229.8744V215.7513h2.4v14.1231Z" />
                                        <path className="st18" d="M1550.2441,229.0341a3.4124,3.4124,0,0,1-2.7207,1.0405,4.8452,4.8452,0,0,1-1.0205-.1,2.4969,2.4969,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1668,3.1668,0,0,1,.2-1.3,2.9846,2.9846,0,0,1,3.041-1.6607c.3193,0,2.28-.02,2.9209,1.5005a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.4539,2.4539,0,0,1,1550.2441,229.0341Zm-1.7812-6.8814c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7484.7484,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1557.1826,230.6146a4.643,4.643,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0605.74,8.0787,8.0787,0,0,1-1.54-.18l.3594-1.88a3.2129,3.2129,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.66-10.2422h2.36l1.32,6.5815,1.02-6.5815h2.3408Z" />
                                        <path className="st18" d="M1563.5625,225.2733v1.6807a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7526.7526,0,0,0,.7607-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34V222.453a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8315,2.8315,0,0,1,.2792,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1572.3623,225.2733v1.6807a1.9788,1.9788,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7526.7526,0,0,0,.7607-.5605,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.0405-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34V222.453a3.8113,3.8113,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8313,2.8313,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1584.3027,229.1542a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9675.9675,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.98,1.247,1.247,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.46c.0205-2.0405,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1584.3027,229.1542Z" />
                                        <path className="st18" d="M1593.7227,221.3324l-1.36,1.9805a3.7718,3.7718,0,0,0-.82,1.6206,4.3244,4.3244,0,0,0-.0391.7v.44h-2.2607v-.7a5.6249,5.6249,0,0,1,.02-.66,3.1528,3.1528,0,0,1,.66-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.5605-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.1906,10.1906,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2413-2.2207a3.8746,3.8746,0,0,1,2.3007.6,1.95,1.95,0,0,1,.6.72,4.1,4.1,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,1593.7227,221.3324Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1473.0654,310.6747h-2.74l-1.36-9.6421-1.52,9.6421h-2.78l-2.58-14.1231h2.7l1.42,10.6421,1.5205-10.6421h2.4609l1.48,10.6421,1.48-10.6421h2.6406Z" />
                                        <path className="st18" d="M1481.9648,310.6747v-7.2818a2.06,2.06,0,0,0-.08-.8.8571.8571,0,0,0-.8008-.46.8882.8882,0,0,0-.8994.7,1.4279,1.4279,0,0,0-.04.42v7.4214h-2.3808V296.5516h2.3408l.02,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0231,2.0231,0,0,1,2,1.4,2.9234,2.9234,0,0,1,.14,1.0205v8.0215Z" />
                                        <path className="st18" d="M1491.1045,310.6747c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2528,2.2528,0,0,1-1.7.74,2.0924,2.0924,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9744,2.9744,0,0,1,.48-2.1006,3.1984,3.1984,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9526.9526,0,0,0-.52-.14.7757.7757,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1806v-1.16a2.0478,2.0478,0,0,1,.58-1.46,3.648,3.648,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.0672,5.0672,0,0,0,.3008,2.32c.0195.06.06.1.1592.18Zm-.34-5.1612-1.16.76c-.08.06-.1807.14-.2.16-.3008.28-.3408.68-.3408,1.48,0,.76.1006,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.2607-.22Z" />
                                        <path className="st18" d="M1498.9443,310.7748c-1.3808,0-2.1005-.3-2.4013-.9605a2.63,2.63,0,0,1-.1992-1.18v-6.6211h-1.3008v-1.5806h1.28v-3.7207h2.36v3.7207h1.4209v1.5806h-1.4209v5.8408a1.3289,1.3289,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6905,2.6905,0,0,0,.4-.04v1.72A5.0439,5.0439,0,0,1,1498.9443,310.7748Z" />
                                        <path className="st18" d="M1514.5449,310.0345a4.3408,4.3408,0,0,1-3.0615.9405,4.5164,4.5164,0,0,1-2.94-.82,2.288,2.288,0,0,1-.7-.88,3.8313,3.8313,0,0,1-.3-1.62v-7.9219a3.4,3.4,0,0,1,.88-2.56,4.5872,4.5872,0,0,1,3.081-.92c2.04,0,3.1211.84,3.541,1.66a3.9141,3.9141,0,0,1,.3194,1.88v7.8618A3.066,3.066,0,0,1,1514.5449,310.0345Zm-1.8008-10.062a2.4842,2.4842,0,0,0-.16-1.1406,1.1026,1.1026,0,0,0-1.08-.62,1.4351,1.4351,0,0,0-.84.26c-.58.4-.5,1.02-.5,1.6006v7.1411a2.2371,2.2371,0,0,0,.2392,1.3,1.3441,1.3441,0,0,0,1.0606.42,1.5245,1.5245,0,0,0,.82-.24c.4795-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M1524.3643,309.8944a1.9161,1.9161,0,0,1-.44.54,2.017,2.017,0,0,1-1.32.4405,3.1334,3.1334,0,0,1-2.22-1.0606v4.4009h-2.3809V300.4325h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1806,2.1806,0,0,1,1.7.82,3.0547,3.0547,0,0,1,.4795,1.88v5.4414A3.5114,3.5114,0,0,1,1524.3643,309.8944Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0919,1.0919,0,0,0-.5205-.14,1.038,1.038,0,0,0-.54.14,1.0529,1.0529,0,0,0-.4394,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9338.9338,0,0,0,.8994.56.86.86,0,0,0,.8408-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1533.584,309.8944a1.9158,1.9158,0,0,1-.44.54,2.0175,2.0175,0,0,1-1.32.4405,3.1336,3.1336,0,0,1-2.22-1.0606v4.4009h-2.3808V300.4325h2.3808v.78a3.264,3.264,0,0,1,2.1406-.9805,2.1806,2.1806,0,0,1,1.7.82,3.054,3.054,0,0,1,.4795,1.88v5.4414A3.5114,3.5114,0,0,1,1533.584,309.8944Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0915,1.0915,0,0,0-.52-.14,1.0375,1.0375,0,0,0-.54.14,1.0529,1.0529,0,0,0-.4395,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9338.9338,0,0,0,.8994.56.86.86,0,0,0,.8408-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1542.1436,309.8344a3.4117,3.4117,0,0,1-2.7207,1.0405,4.8421,4.8421,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1542.1436,309.8344Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7478.7478,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8995,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1549.9229,302.6132a1.6837,1.6837,0,0,0-.7793-.16,1.6065,1.6065,0,0,0-.9.24,2.1311,2.1311,0,0,0-.6407,1.94v6.0415h-2.36V300.4325h2.3808l-.0205,1.3a4.1505,4.1505,0,0,1,.36-.6,2.2174,2.2174,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1555.123,310.7748c-1.38,0-2.1005-.3-2.4-.9605a2.6307,2.6307,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.1406.48.6005.56,1.0205.56a2.6905,2.6905,0,0,0,.4-.04v1.72A5.054,5.054,0,0,1,1555.123,310.7748Z" />
                                        <path className="st18" d="M1562.4434,310.6747v-.84a3.2428,3.2428,0,0,1-2.1807,1.02,2.2511,2.2511,0,0,1-1.2-.36,2.5153,2.5153,0,0,1-.8008-2.34v-7.7217h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.9805.8732.8732,0,0,0,.64.26,1.0291,1.0291,0,0,0,.6005-.2c.3995-.34.32-1.1.32-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M1571.7041,310.6747v-7.5415c0-.64-.04-.9-.36-1.1a.75.75,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3814h-2.3809V300.4325h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1576.7021,298.952v-2.4h2.4v2.4Zm0,11.7227V300.4325h2.4v10.2422Z" />
                                        <path className="st18" d="M1584.9023,310.7748c-1.3808,0-2.1005-.3-2.4-.9605a2.6171,2.6171,0,0,1-.2-1.18v-6.6211h-1.3008v-1.5806h1.28v-3.7207h2.3614v3.7207h1.42v1.5806h-1.42v5.8408a1.35,1.35,0,0,0,.06.56c.14.48.6006.56,1.0206.56a2.6906,2.6906,0,0,0,.3994-.04v1.72A5.0439,5.0439,0,0,1,1584.9023,310.7748Z" />
                                        <path className="st18" d="M1588.042,298.952v-2.4h2.4v2.4Zm0,11.7227V300.4325h2.4v10.2422Z" />
                                        <path className="st18" d="M1595.3418,306.0736v1.6807a2.01,2.01,0,0,0,.06.68.8123.8123,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1607.2822,309.9545a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5832,3.5832,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9665.9665,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3995-.98,1.2483,1.2483,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.0205-2.04,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1607.2822,309.9545Z" />
                                        <path className="st18" d="M1444.6338,328.0736v1.6807a1.9809,1.9809,0,0,0,.0605.68.8117.8117,0,0,0,.82.58.7523.7523,0,0,0,.76-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3008v.82a2.8168,2.8168,0,0,1-.1006.92c-.3994,1.44-1.68,1.84-3.04,1.84-2.1005,0-2.8007-1.0405-3.081-1.9605a6.6141,6.6141,0,0,1-.18-1.34v-4.3208a3.8016,3.8016,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8291,2.8291,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.8194-1.02a.8719.8719,0,0,0-.8808,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1455.1543,332.6747l-1.16-4.1411-1.16,4.1411h-2.461l2.2208-5.5611-1.8008-4.6811h2.4609l.88,3.4209.9209-3.4209h2.3l-1.84,4.6811,2.2,5.5611Z" />
                                        <path className="st18" d="M1459.5537,320.952v-2.4h2.4v2.4Zm0,11.7227V322.4325h2.4v10.2422Z" />
                                        <path className="st18" d="M1469.9941,331.9545a3.07,3.07,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.5683,3.5683,0,0,1-.2207-1.32v-1.06h2.2012v.8a2.44,2.44,0,0,0,.06.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2483,1.2483,0,0,0-.7-1.32l-2.001-1.42a2.5258,2.5258,0,0,1-1.22-2.4605c.0195-2.04,1.26-2.6206,3.1-2.6206,1.5,0,2.32.52,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.8809-1.12a.7794.7794,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,1469.9941,331.9545Z" />
                                        <path className="st18" d="M1476.1338,332.7748c-1.38,0-2.1006-.3-2.4-.9605a2.6307,2.6307,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.1406.48.6006.56,1.02.56a2.7146,2.7146,0,0,0,.4-.04v1.72A5.06,5.06,0,0,1,1476.1338,332.7748Z" />
                                        <path className="st18" d="M1487.9541,320.4921c-.44.1-.44.58-.44.68v1.26h1.0605v1.5806h-1.081v8.6616h-2.36v-8.6616h-1.06v-1.5806h1.06a10.567,10.567,0,0,1,.0606-2.28,1.7205,1.7205,0,0,1,.6-1.1406,2.8513,2.8513,0,0,1,1.98-.5c.3,0,.6006.02.8809.04v1.96A2.1088,2.1088,0,0,0,1487.9541,320.4921Z" />
                                        <path className="st18" d="M1496.3145,331.8344a3.4126,3.4126,0,0,1-2.7207,1.0405,4.8455,4.8455,0,0,1-1.0206-.1,2.4981,2.4981,0,0,1-1.9-1.6206,3.7253,3.7253,0,0,1-.18-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9826,2.9826,0,0,1,3.04-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.4543,2.4543,0,0,1,1496.3145,331.8344Zm-1.78-6.8814c0-.64-.0606-.92-.3809-1.12a.7255.7255,0,0,0-.4394-.12.7513.7513,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1504.0938,324.6132a1.6837,1.6837,0,0,0-.7793-.16,1.6065,1.6065,0,0,0-.9.24,2.1311,2.1311,0,0,0-.6407,1.94v6.0415h-2.36V322.4325h2.3808l-.02,1.3a4.1505,4.1505,0,0,1,.36-.6,2.2174,2.2174,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1517.2939,331.8344a3.4115,3.4115,0,0,1-2.7207,1.0405,4.8418,4.8418,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.3193,0,2.28-.02,2.92,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.45,2.45,0,0,1,1517.2939,331.8344Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7478.7478,0,0,0-.48.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1524.5732,332.6747v-.84a3.2367,3.2367,0,0,1-2.18,1.02,2.256,2.256,0,0,1-1.2012-.36,2.5168,2.5168,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2689,1.2689,0,0,0,.2207.9805.8732.8732,0,0,0,.64.26,1.0291,1.0291,0,0,0,.6006-.2c.3994-.34.3193-1.1.3193-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M1534.293,324.6132a1.6837,1.6837,0,0,0-.7793-.16,1.6066,1.6066,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.94v6.0415h-2.36V322.4325h2.3809l-.02,1.3a4.1375,4.1375,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1541.9121,332.6747V318.5516h6.8418v2.1407h-4.2412v3.3208h4.001v2.14h-4.001v4.3408h4.44v2.1807Z" />
                                        <path className="st18" d="M1559.374,332.6747v-7.5816a1.1185,1.1185,0,0,0-.36-1.0405.9343.9343,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.4614h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7907.7907,0,0,0-.5605-.18c-.9795,0-.92,1.04-.92,1.22v7.5816h-2.36V322.4325h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4416,2.4416,0,0,1,1.9209,1.02,3.14,3.14,0,0,1,1.6807-.94,3.2246,3.2246,0,0,1,.66-.06,1.9269,1.9269,0,0,1,1.96,1.4,2.7048,2.7048,0,0,1,.1.92v8.1216Z" />
                                        <path className="st18" d="M1570.7529,331.8944a1.9281,1.9281,0,0,1-.4394.54,2.0216,2.0216,0,0,1-1.32.4405,3.1336,3.1336,0,0,1-2.2207-1.0606v4.4009h-2.3809V322.4325h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.18,2.18,0,0,1,1.7.82,3.0556,3.0556,0,0,1,.4805,1.88v5.4414A3.5176,3.5176,0,0,1,1570.7529,331.8944Zm-2.0605-6.7612c0-.58-.14-.8-.42-.9805a1.0909,1.0909,0,0,0-.52-.14,1.0331,1.0331,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4179,1.4179,0,0,0,.08.5806.935.935,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1573.6113,332.6747V318.5516h2.4v14.1231Z" />
                                        <path className="st18" d="M1584.3525,331.8344a3.4106,3.4106,0,0,1-2.7207,1.0405,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9841,2.9841,0,0,1,3.0411-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9658,2.9658,0,0,1,.2392,1.36v4.941A2.45,2.45,0,0,1,1584.3525,331.8344Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1591.292,333.4149a4.6442,4.6442,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0606.74,8.0777,8.0777,0,0,1-1.54-.18l.3594-1.88a3.2121,3.2121,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0206-.28-2.6612-10.2422h2.3613l1.32,6.5815,1.0195-6.5815h2.3408Z" />
                                        <path className="st18" d="M1597.6719,328.0736v1.6807a2.0127,2.0127,0,0,0,.0595.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1606.4717,328.0736v1.6807a1.9809,1.9809,0,0,0,.0605.68.8107.8107,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1618.4121,331.9545a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5832,3.5832,0,0,1-.22-1.32v-1.06h2.2v.8a2.4357,2.4357,0,0,0,.06.64.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2468,1.2468,0,0,0-.7-1.32l-2-1.42a2.5267,2.5267,0,0,1-1.2207-2.4605c.0206-2.04,1.2608-2.6206,3.1016-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6308,3.6308,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,1618.4121,331.9545Z" />
                                        <path className="st18" d="M1627.832,324.1327l-1.36,1.9805a3.7707,3.7707,0,0,0-.82,1.6206,4.3238,4.3238,0,0,0-.0391.7v.44h-2.2607v-.7a5.6278,5.6278,0,0,1,.02-.66,3.1535,3.1535,0,0,1,.66-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.5605-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8747,3.8747,0,0,1,2.3008.6,1.95,1.95,0,0,1,.6.72,4.097,4.097,0,0,1,.26,1.7607v.8A3.7614,3.7614,0,0,1,1627.832,324.1327Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1470.2842,461.5746v-6.4619h-2.3809v6.4619h-2.6006V447.4515h2.6006v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M1481.3438,460.7338a3.4109,3.4109,0,0,1-2.7208,1.041,4.8324,4.8324,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3007,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.0205,2.92,1.5a2.9524,2.9524,0,0,1,.24,1.36v4.9414A2.4488,2.4488,0,0,1,1481.3438,460.7338Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7514.7514,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.0606.82,1.0606.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1492.4238,461.5746h-2.24l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7607,1.2-6.7607h1.94l1.08,6.7607,1.16-6.7607h2.3213Z" />
                                        <path className="st18" d="M1506.1826,461.5746l.02-1a2.2714,2.2714,0,0,1-2.001,1.2,2.4509,2.4509,0,0,1-1.42-.44c-.76-.5606-.86-1.2208-.86-2.8409v-4.6611a2.5415,2.5415,0,0,1,1.04-2.38,2.3552,2.3552,0,0,1,1.1807-.3408,2.9939,2.9939,0,0,1,2.0605,1.041v-4.7012h2.38v14.1231Zm.02-7.4414c0-.461-.02-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.9251.9251,0,0,0-.52.16c-.4.28-.3809.7-.3809,1.14v4.3613c0,.6006.0606.82.3408,1a1.1081,1.1081,0,0,0,.58.16.9667.9667,0,0,0,.8194-.4,1.3023,1.3023,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1516.9434,460.7338a3.4117,3.4117,0,0,1-2.7207,1.041,4.8361,4.8361,0,0,1-1.0206-.1006,2.4972,2.4972,0,0,1-1.9-1.62,3.7253,3.7253,0,0,1-.18-1.38v-4.5811a3.17,3.17,0,0,1,.2-1.3007,2.9822,2.9822,0,0,1,3.04-1.66c.32,0,2.28-.0205,2.9209,1.5a2.9524,2.9524,0,0,1,.24,1.36v4.9414A2.4526,2.4526,0,0,1,1516.9434,460.7338Zm-1.78-6.8809c0-.6406-.0606-.92-.3809-1.12a.7254.7254,0,0,0-.4394-.12.7549.7549,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.0606.82,1.0606.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1533.4834,461.5746h-2.24l-1.001-6.0811-1.2,6.0811h-2.26l-2.081-10.2422h2.32l1.081,6.7607,1.2-6.7607h1.94l1.08,6.7607,1.16-6.7607h2.32Z" />
                                        <path className="st18" d="M1539.8818,456.973v1.6807a1.982,1.982,0,0,0,.0606.6807.8122.8122,0,0,0,.82.58.7524.7524,0,0,0,.76-.56,4.9982,4.9982,0,0,0,.08-1.16h2.3008v.82a2.8147,2.8147,0,0,1-.1006.92c-.3994,1.44-1.68,1.8408-3.04,1.8408-2.1006,0-2.8008-1.041-3.0811-1.961a6.6156,6.6156,0,0,1-.18-1.34v-4.3213a3.8012,3.8012,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8319,2.8319,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.8193-1.0205a.8721.8721,0,0,0-.8809,1.0205v1.2607h1.7Z" />
                                        <path className="st18" d="M1554.1416,456.973v1.6807a1.9851,1.9851,0,0,0,.0605.6807.8124.8124,0,0,0,.82.58.7522.7522,0,0,0,.76-.56,4.9989,4.9989,0,0,0,.08-1.16h2.3008v.82a2.8147,2.8147,0,0,1-.1006.92c-.3994,1.44-1.68,1.8408-3.04,1.8408-2.1006,0-2.8008-1.041-3.0811-1.961a6.6179,6.6179,0,0,1-.18-1.34v-4.3213a3.8,3.8,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8331,2.8331,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.8193-1.0205a.8721.8721,0,0,0-.8809,1.0205v1.2607h1.7Z" />
                                        <path className="st18" d="M1564.4014,461.5746h-2.32l-2.2608-10.2422h2.3809l1.0605,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M1572.7617,461.5746c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0922,2.0922,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9784,2.9784,0,0,1,.4805-2.1006,3.2026,3.2026,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.3193-1.08a.9544.9544,0,0,0-.5205-.1406.7762.7762,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.1806v-1.16a2.0461,2.0461,0,0,1,.58-1.46,3.6491,3.6491,0,0,1,2.581-.7607c.3,0,2.08-.0195,2.8008,1.28a1.8764,1.8764,0,0,1,.24.98v5.7012a5.0658,5.0658,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1611-1.16.76c-.08.0606-.1806.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.286,1.286,0,0,0,.86-.3593l.26-.2207Z" />
                                        <path className="st18" d="M1577.44,461.5746V447.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1586.6621,461.5746v-.8408a3.2423,3.2423,0,0,1-2.1807,1.0205,2.2511,2.2511,0,0,1-1.2-.36,2.5143,2.5143,0,0,1-.8007-2.34v-7.7217h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.9805.8746.8746,0,0,0,.64.26,1.0257,1.0257,0,0,0,.6006-.2c.3994-.34.32-1.1.32-1.16v-7.2412h2.4v10.2422Z" />
                                        <path className="st18" d="M1595.8213,461.5746c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2569,2.2569,0,0,1-1.7011.74,2.0925,2.0925,0,0,1-1.24-.38c-.84-.62-.7793-1.8408-.7793-2.74a2.9783,2.9783,0,0,1,.4795-2.1006,3.204,3.204,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.3194-1.08a.9539.9539,0,0,0-.52-.1406.7761.7761,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38H1591.66v-1.16a2.0463,2.0463,0,0,1,.58-1.46,3.6494,3.6494,0,0,1,2.5811-.7607c.3,0,2.08-.0195,2.8008,1.28a1.8828,1.8828,0,0,1,.2392.98v5.7012a5.0663,5.0663,0,0,0,.3008,2.32c.0195.0606.06.1006.16.1807Zm-.34-5.1611-1.16.76c-.08.0606-.1807.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2862,1.2862,0,0,0,.86-.3593l.26-.2207Z" />
                                        <path className="st18" d="M1603.6611,461.6742c-1.3808,0-2.1006-.3-2.4-.96a2.6215,2.6215,0,0,1-.2-1.1806v-6.6211H1599.76v-1.58h1.28v-3.7207h2.3614v3.7207h1.42v1.58h-1.42v5.8408a1.35,1.35,0,0,0,.06.56c.14.4805.6006.5606,1.02.5606a2.7729,2.7729,0,0,0,.4-.04v1.72A5.0186,5.0186,0,0,1,1603.6611,461.6742Z" />
                                        <path className="st18" d="M1609.0615,456.973v1.6807a2.0138,2.0138,0,0,0,.06.6807.8129.8129,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.9934,4.9934,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8719.8719,0,0,0-.88,1.0205v1.2607h1.7Z" />
                                        <path className="st18" d="M1448.083,483.5746V469.4515h2.5811v14.1231Z" />
                                        <path className="st18" d="M1457.6445,483.5746v-7.542c0-.64-.04-.9-.36-1.1a.7437.7437,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3819h-2.38V473.3324h2.42l-.02.74a2.6326,2.6326,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1466.8037,483.5746l.0205-1a2.2714,2.2714,0,0,1-2.001,1.2,2.4509,2.4509,0,0,1-1.42-.44c-.76-.5606-.86-1.2208-.86-2.8409v-4.6611a2.5415,2.5415,0,0,1,1.04-2.38,2.3552,2.3552,0,0,1,1.1807-.3408,2.9939,2.9939,0,0,1,2.0605,1.041v-4.7012h2.38v14.1231Zm.0205-7.4414c0-.461-.0205-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.9251.9251,0,0,0-.5195.16c-.4.28-.3809.7-.3809,1.14v4.3613c0,.6006.0606.82.3408,1a1.1081,1.1081,0,0,0,.58.16.9667.9667,0,0,0,.8194-.4,1.3023,1.3023,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1471.8633,471.8519v-2.4h2.4v2.4Zm0,11.7227V473.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1480.624,483.5746h-2.3213l-2.26-10.2422h2.38l1.0605,7.1016,1.2207-7.1016h2.38Z" />
                                        <path className="st18" d="M1484.8623,471.8519v-2.4h2.4v2.4Zm0,11.7227V473.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1494.0635,483.5746l.02-1a2.27,2.27,0,0,1-2,1.2,2.4516,2.4516,0,0,1-1.4209-.44c-.76-.5606-.8594-1.2208-.8594-2.8409v-4.6611a2.5391,2.5391,0,0,1,1.04-2.38,2.347,2.347,0,0,1,1.18-.3408,2.9921,2.9921,0,0,1,2.0605,1.041v-4.7012h2.3809v14.1231Zm.02-7.4414c0-.461-.02-.78-.4394-1.02a1.1927,1.1927,0,0,0-.58-.16.9278.9278,0,0,0-.5205.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6006.06.82.34,1a1.1142,1.1142,0,0,0,.58.16.9683.9683,0,0,0,.82-.4,1.3088,1.3088,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1503.3027,483.5746v-.8408a3.2368,3.2368,0,0,1-2.18,1.0205,2.2558,2.2558,0,0,1-1.2011-.36,2.5159,2.5159,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.9805.8748.8748,0,0,0,.64.26,1.0257,1.0257,0,0,0,.6006-.2c.3994-.34.3193-1.1.3193-1.16v-7.2412h2.4v10.2422Z" />
                                        <path className="st18" d="M1512.4629,483.5746c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2528,2.2528,0,0,1-1.7.74,2.0909,2.0909,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9738,2.9738,0,0,1,.48-2.1006,3.1941,3.1941,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.32-1.08a.9477.9477,0,0,0-.52-.1406.7753.7753,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.1806v-1.16a2.0463,2.0463,0,0,1,.58-1.46,3.6477,3.6477,0,0,1,2.5811-.7607c.3,0,2.08-.0195,2.8,1.28a1.8764,1.8764,0,0,1,.24.98v5.7012a5.0663,5.0663,0,0,0,.3008,2.32c.02.0606.06.1006.1592.1807Zm-.34-5.1611-1.16.76c-.08.0606-.1807.1406-.2.16-.3008.28-.3408.6806-.3408,1.48,0,.76.1006,1.2.58,1.18a1.2823,1.2823,0,0,0,.86-.3593l.2607-.2207Z" />
                                        <path className="st18" d="M1517.1426,483.5746V469.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1534.8828,476.9926c-.42.5205-1.02.7-2.3.7h-2.2012v5.8819h-2.62V469.4515h5.2412a3.705,3.705,0,0,1,.7.06,1.9747,1.9747,0,0,1,1.38,1,5.1461,5.1461,0,0,1,.3008,2.1211v1.86C1535.3838,475.8129,1535.3232,476.4525,1534.8828,476.9926Zm-2.06-4.0206a1.7072,1.7072,0,0,0-.2-1.1406,1.12,1.12,0,0,0-.9609-.3h-1.28v4.1406h1.2608c.54,0,.84-.0595,1-.2793a1.8266,1.8266,0,0,0,.1806-.98Z" />
                                        <path className="st18" d="M1539.9424,478.973v1.6807a2.0138,2.0138,0,0,0,.06.6807.8122.8122,0,0,0,.82.58.7534.7534,0,0,0,.7607-.56,4.9914,4.9914,0,0,0,.0791-1.16h2.3008v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6807,1.8408-3.041,1.8408-2.1006,0-2.8008-1.041-3.08-1.961a6.5668,6.5668,0,0,1-.1807-1.34v-4.3213a3.8221,3.8221,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8227,2.8227,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8713.8713,0,0,0-.88,1.0205v1.2607h1.7Z" />
                                        <path className="st18" d="M1551.1621,475.5131a1.6837,1.6837,0,0,0-.7793-.16,1.6089,1.6089,0,0,0-.9.2393,2.1318,2.1318,0,0,0-.6406,1.94v6.042h-2.36V473.3324h2.3809l-.0205,1.3a4.1779,4.1779,0,0,1,.36-.6,2.2183,2.2183,0,0,1,1.9805-.8809Z" />
                                        <path className="st18" d="M1556.4219,471.392c-.4405.1-.4405.58-.4405.68v1.2607h1.0606v1.58h-1.08v8.6621h-2.36v-8.6621h-1.0606v-1.58h1.0606a10.64,10.64,0,0,1,.06-2.28,1.7184,1.7184,0,0,1,.6006-1.1406,2.8466,2.8466,0,0,1,1.98-.5c.3,0,.6.0195.88.04v1.96A2.108,2.108,0,0,0,1556.4219,471.392Z" />
                                        <path className="st18" d="M1564.7822,482.7338a3.41,3.41,0,0,1-2.7207,1.041,4.8315,4.8315,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1713,3.1713,0,0,1,.2-1.3007,2.9835,2.9835,0,0,1,3.041-1.66c.32,0,2.28-.0205,2.9209,1.5a2.9673,2.9673,0,0,1,.2393,1.36v4.9414A2.4485,2.4485,0,0,1,1564.7822,482.7338Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.7285.7285,0,0,0-.4405-.12.7539.7539,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.0195,1.0606.82,1.0606.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1572.5625,475.5131a1.6919,1.6919,0,0,0-.78-.16,1.6107,1.6107,0,0,0-.9.2393,2.1318,2.1318,0,0,0-.6406,1.94v6.042h-2.36V473.3324h2.3808l-.0205,1.3a4.1815,4.1815,0,0,1,.36-.6,2.218,2.218,0,0,1,1.98-.8809Z" />
                                        <path className="st18" d="M1582.9629,483.5746v-7.582a1.1172,1.1172,0,0,0-.36-1.04.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.4619h-2.36v-7.5215c0-.4209.04-.8008-.32-1.1006a.7907.7907,0,0,0-.5605-.18c-.9795,0-.92,1.04-.92,1.22v7.582h-2.36V473.3324h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4418,2.4418,0,0,1,1.9209,1.0205,3.14,3.14,0,0,1,1.6806-.94,3.2518,3.2518,0,0,1,.66-.06,1.9265,1.9265,0,0,1,1.96,1.4,2.7027,2.7027,0,0,1,.1.92v8.1221Z" />
                                        <path className="st18" d="M1592.1016,483.5746c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0924,2.0924,0,0,1-1.24-.38c-.84-.62-.78-1.8408-.78-2.74a2.9789,2.9789,0,0,1,.4805-2.1006,3.204,3.204,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.0595-.9-.3193-1.08a.9542.9542,0,0,0-.5205-.1406.7761.7761,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38H1587.94v-1.16a2.0465,2.0465,0,0,1,.58-1.46,3.6492,3.6492,0,0,1,2.5811-.7607c.3,0,2.08-.0195,2.8007,1.28a1.8758,1.8758,0,0,1,.24.98v5.7012a5.0645,5.0645,0,0,0,.3,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1611-1.16.76c-.08.0606-.1807.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.2864,1.2864,0,0,0,.86-.3593l.26-.2207Z" />
                                        <path className="st18" d="M1601.0029,483.5746v-7.542c0-.64-.04-.9-.36-1.1a.746.746,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3819h-2.3809V473.3324h2.4209l-.0205.74a2.6348,2.6348,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1611.8828,482.7338a3.3224,3.3224,0,0,1-2.6611,1.041,3.4408,3.4408,0,0,1-2.7207-.961,3.59,3.59,0,0,1-.42-.72,3.5386,3.5386,0,0,1-.2-1.3008v-4.541a2.9733,2.9733,0,0,1,.56-1.96,3.585,3.585,0,0,1,2.78-1.16c.24,0,2.3808,0,3.041,1.54a3.283,3.283,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5336,2.5336,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.0686,1.0686,0,0,0-.56.14c-.32.2207-.42.5411-.42,1.2413v4.4609a2.1379,2.1379,0,0,0,.06.6992.8609.8609,0,0,0,.88.6406,1.1782,1.1782,0,0,0,.5205-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.1806h2.36v1.1611A3.1808,3.1808,0,0,1,1611.8828,482.7338Z" />
                                        <path className="st18" d="M1617.0615,478.973v1.6807a2.0138,2.0138,0,0,0,.06.6807.8129.8129,0,0,0,.82.58.7525.7525,0,0,0,.7607-.56,4.9934,4.9934,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8719.8719,0,0,0-.88,1.0205v1.2607h1.7Z" />
                                        <path className="st18" d="M1630.042,475.0326l-1.36,1.9805a3.7693,3.7693,0,0,0-.82,1.62,4.3409,4.3409,0,0,0-.039.7v.44h-2.2608v-.7a5.5964,5.5964,0,0,1,.02-.66,3.1534,3.1534,0,0,1,.66-1.6006l1.32-2.04a2.4053,2.4053,0,0,0,.5606-1.6406v-1.2607a.8859.8859,0,0,0-.9-.98c-.76,0-.9805.4394-.9805,1.08v2.34h-2.2207v-1.4795a10.18,10.18,0,0,1,.08-1.3408c.2-1.18.86-2.2207,3.2412-2.2207a3.8725,3.8725,0,0,1,2.3008.6006,1.948,1.948,0,0,1,.6.72,4.1,4.1,0,0,1,.26,1.7607v.8A3.7631,3.7631,0,0,1,1630.042,475.0326Zm-4.48,8.542V480.974h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1461.4844,653.5746v-6.461h-2.3809v6.461h-2.6006V639.4515h2.6006v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M1472.5439,652.7347a3.4122,3.4122,0,0,1-2.7207,1.04,4.7644,4.7644,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1615,3.1615,0,0,1,.2-1.3,2.9828,2.9828,0,0,1,3.041-1.6611c.3193,0,2.28-.02,2.92,1.501a2.9461,2.9461,0,0,1,.24,1.3594v4.9414A2.45,2.45,0,0,1,1472.5439,652.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7514.7514,0,0,0-.48.14c-.3809.28-.36.7012-.36,1.1211v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1483.624,653.5746h-2.24l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.3213Z" />
                                        <path className="st18" d="M1493.2227,641.8519v-2.4h2.4v2.4Zm0,11.7227V643.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1503.6631,652.8539a3.0669,3.0669,0,0,1-2.62.9209c-1.1407,0-2.4-.22-2.9-1.4209a3.5653,3.5653,0,0,1-.2207-1.32v-1.06h2.2011v.8a2.452,2.452,0,0,0,.06.6406.7978.7978,0,0,0,.86.66.9619.9619,0,0,0,.54-.1406,1.1015,1.1015,0,0,0,.4-.9805,1.2486,1.2486,0,0,0-.7-1.32l-2.001-1.42a2.5259,2.5259,0,0,1-1.22-2.461c.02-2.04,1.26-2.62,3.1006-2.62,1.5,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1406h-2.0606v-.74c0-.16.04-1.1211-.8808-1.1211a.78.78,0,0,0-.82.92c0,.6006.2207.8008.6806,1.1211l2.2,1.54a3.6089,3.6089,0,0,1,.62.62,2.7223,2.7223,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1503.6631,652.8539Z" />
                                        <path className="st18" d="M1514.3623,648.973v1.6817a1.9755,1.9755,0,0,0,.0606.68.8122.8122,0,0,0,.82.58.7522.7522,0,0,0,.76-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3008v.82a2.8147,2.8147,0,0,1-.1006.92c-.3994,1.44-1.68,1.8408-3.04,1.8408-2.1006,0-2.8008-1.04-3.0811-1.96a6.6252,6.6252,0,0,1-.18-1.3408v-4.3213a3.802,3.802,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8331,2.8331,0,0,1,.28,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.8193-1.0215a.8725.8725,0,0,0-.8809,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1525.0225,653.5746c-.1993-.2207-.2393-.4-.2793-.9-.12.12-.2207.26-.36.3809a2.2546,2.2546,0,0,1-1.7.74,2.0919,2.0919,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4795-2.1006,3.2107,3.2107,0,0,1,1.0811-.7607l2.48-1.24v-.6c0-.6-.0605-.9-.32-1.08a.9516.9516,0,0,0-.5205-.1406.7768.7768,0,0,0-.74.4,2.9528,2.9528,0,0,0-.08.9609v.379h-2.18v-1.16a2.0473,2.0473,0,0,1,.58-1.46,3.6444,3.6444,0,0,1,2.58-.7607c.3,0,2.081-.0195,2.8007,1.2812a1.8725,1.8725,0,0,1,.24.98v5.7012a5.0662,5.0662,0,0,0,.3,2.32c.0205.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2792-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2856,1.2856,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1535.583,652.7347a3.3232,3.3232,0,0,1-2.66,1.04,3.44,3.44,0,0,1-2.7208-.96,2.8876,2.8876,0,0,1-.62-2.0215v-4.541a2.9726,2.9726,0,0,1,.56-1.96,3.5905,3.5905,0,0,1,2.7813-1.16c.2392,0,2.38,0,3.04,1.54a3.2865,3.2865,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5416,2.5416,0,0,0-.04-.5.8332.8332,0,0,0-.8593-.6406,1.0587,1.0587,0,0,0-.5606.1406c-.32.22-.42.54-.42,1.24v4.4609a2.1379,2.1379,0,0,0,.06.6992.8613.8613,0,0,0,.8808.6406,1.1642,1.1642,0,0,0,.5195-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1829,3.1829,0,0,1,1535.583,652.7347Z" />
                                        <path className="st18" d="M1542.7021,653.5746v-7.2813a2.0659,2.0659,0,0,0-.08-.8007.856.856,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.4262,1.4262,0,0,0-.04.42v7.4219h-2.38V639.4515h2.34l.0205,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0258,2.0258,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1553.3008,653.5746V639.4515h6.8418v2.1407h-4.2412v3.32h4.0019v2.1406h-4.0019v4.3408h4.4414v2.1807Z" />
                                        <path className="st18" d="M1570.7637,653.5746v-7.582a1.1181,1.1181,0,0,0-.36-1.04.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3007v7.461h-2.36v-7.5215c0-.42.04-.8008-.32-1.1006a.791.791,0,0,0-.5606-.18c-.9794,0-.92,1.04-.92,1.22v7.582h-2.36V643.3324h2.38v.68a2.92,2.92,0,0,1,2.0205-.9,2.4393,2.4393,0,0,1,1.9209,1.0215,3.1433,3.1433,0,0,1,1.6806-.9414,3.2518,3.2518,0,0,1,.66-.06,1.9274,1.9274,0,0,1,1.96,1.4,2.7094,2.7094,0,0,1,.1.9209v8.1211Z" />
                                        <path className="st18" d="M1582.1426,652.7943a1.9446,1.9446,0,0,1-.4395.54,2.0249,2.0249,0,0,1-1.32.44,3.1318,3.1318,0,0,1-2.2207-1.0606v4.4014h-2.3809V643.3324h2.3809v.78a3.2611,3.2611,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0543,3.0543,0,0,1,.4805,1.88v5.4414A3.52,3.52,0,0,1,1582.1426,652.7943Zm-2.0606-6.7607c0-.58-.14-.8008-.42-.9805a1.0768,1.0768,0,0,0-.52-.1406,1.03,1.03,0,0,0-.54.1406,1.05,1.05,0,0,0-.44,1.04v4.6006a1.4145,1.4145,0,0,0,.08.58.9349.9349,0,0,0,.9.5606.8592.8592,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1585.001,653.5746V639.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1595.7422,652.7347a3.4113,3.4113,0,0,1-2.7207,1.04,4.7628,4.7628,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9829,2.9829,0,0,1,3.041-1.6611c.32,0,2.28-.02,2.9209,1.501a2.96,2.96,0,0,1,.2393,1.3594v4.9414A2.45,2.45,0,0,1,1595.7422,652.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7537.7537,0,0,0-.4795.14c-.38.28-.36.7012-.36,1.1211v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1602.6816,654.3148a4.6383,4.6383,0,0,1-.8,2.0606,2.3184,2.3184,0,0,1-2.0605.74,8.12,8.12,0,0,1-1.54-.18l.3594-1.8808a3.2136,3.2136,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6611-10.2422h2.3613l1.32,6.582,1.0195-6.582h2.3409Z" />
                                        <path className="st18" d="M1609.0615,648.973v1.6817a2.0072,2.0072,0,0,0,.06.68.8129.8129,0,0,0,.82.58.7525.7525,0,0,0,.7607-.5605,4.987,4.987,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.82-1.0215a.8723.8723,0,0,0-.88,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1617.8613,648.973v1.6817a1.9755,1.9755,0,0,0,.0606.68.8113.8113,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.82-1.0215a.8723.8723,0,0,0-.88,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1466.9844,675.5746l.0195-1a2.2678,2.2678,0,0,1-2,1.2,2.4516,2.4516,0,0,1-1.4209-.44c-.76-.5606-.8594-1.2208-.8594-2.84v-4.6621a2.5391,2.5391,0,0,1,1.04-2.38,2.347,2.347,0,0,1,1.18-.3408,2.9921,2.9921,0,0,1,2.06,1.041v-4.7012h2.3809v14.1231Zm.0195-7.4414c0-.461-.0195-.78-.4394-1.02a1.2043,1.2043,0,0,0-.58-.16.9361.9361,0,0,0-.5205.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6006.06.82.34,1a1.1142,1.1142,0,0,0,.58.16.9683.9683,0,0,0,.82-.4,1.3088,1.3088,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1477.7441,674.7347a3.4122,3.4122,0,0,1-2.7207,1.04,4.7636,4.7636,0,0,1-1.02-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1636,3.1636,0,0,1,.2-1.3,2.983,2.983,0,0,1,3.0411-1.6611c.3193,0,2.28-.02,2.92,1.501a2.9461,2.9461,0,0,1,.24,1.3594v4.9414A2.45,2.45,0,0,1,1477.7441,674.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.7514.7514,0,0,0-.48.14c-.3809.28-.36.7012-.36,1.1211v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1480.8428,663.8519v-2.4h2.4v2.4Zm0,11.7227V665.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1490.1035,675.5746v-7.541c0-.6407-.04-.9-.3594-1.1006a.7538.7538,0,0,0-.4609-.12c-1.12,0-1.02,1.18-1.02,1.3808v7.3809h-2.3809V665.3324h2.4209l-.02.74a2.6363,2.6363,0,0,1,2.041-.94,1.9952,1.9952,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1501.6436,676.5941a2.5136,2.5136,0,0,1-1.04,2.0215,4.3592,4.3592,0,0,1-2.42.6,5.9118,5.9118,0,0,1-.82-.06,2.5885,2.5885,0,0,1-2.1612-1.48,3.8454,3.8454,0,0,1-.1591-1.2608h2.36a1.5176,1.5176,0,0,0,.04.4805.8366.8366,0,0,0,.88.6.9832.9832,0,0,0,.54-.14c.4-.22.42-.6807.42-1.08v-1.5811a2.5144,2.5144,0,0,1-2.08,1.001,2.6494,2.6494,0,0,1-1.2207-.3c-.9394-.5-1-1.3613-1-2.2607v-5.4209a3.5222,3.5222,0,0,1,.1406-1.1211,2.0437,2.0437,0,0,1,2.04-1.46,2.9124,2.9124,0,0,1,2.12,1.02v-.82h2.4V675.934C1501.6836,676.1547,1501.6641,676.3754,1501.6436,676.5941Zm-2.38-8.4209c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.3808.44-.3808,1.04v4.5606a1.9558,1.9558,0,0,0,.08.6015.85.85,0,0,0,.86.5391.96.96,0,0,0,.98-1.1406Z" />
                                        <path className="st18" d="M1517.7637,675.5746h-2.24l-1-6.0811-1.2,6.0811h-2.2607l-2.08-10.2422h2.32l1.08,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.3213Z" />
                                        <path className="st18" d="M1521.9023,663.8519v-2.4h2.4v2.4Zm0,11.7227V665.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1530.1025,675.6742c-1.38,0-2.1005-.3-2.4-.96a2.6351,2.6351,0,0,1-.2-1.1806v-6.6211h-1.3v-1.58h1.28v-3.7207h2.36v3.7207h1.42v1.58h-1.42v5.8418a1.35,1.35,0,0,0,.06.5605c.1407.48.6006.56,1.0206.56a2.7981,2.7981,0,0,0,.4-.04v1.7207A5.1,5.1,0,0,1,1530.1025,675.6742Z" />
                                        <path className="st18" d="M1537.4434,675.5746v-7.2813a2.0634,2.0634,0,0,0-.08-.8007.8574.8574,0,0,0-.8008-.46.8884.8884,0,0,0-.8994.7,1.4262,1.4262,0,0,0-.04.42v7.4219h-2.3808V661.4515h2.3408l.0195,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0238,2.0238,0,0,1,2,1.4,2.9234,2.9234,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1552.6025,667.5131a1.683,1.683,0,0,0-.7793-.16,1.6085,1.6085,0,0,0-.9.2393,2.1327,2.1327,0,0,0-.6407,1.9414v6.041h-2.36V665.3324h2.3808l-.02,1.3008a4.12,4.12,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1556.9023,670.973v1.6817a1.9755,1.9755,0,0,0,.0606.68.8113.8113,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8389,2.8389,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1006,0-2.8008-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.3213a3.8236,3.8236,0,0,1,.26-1.38,3.588,3.588,0,0,1,5.9024-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.82-1.0215a.8723.8723,0,0,0-.88,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1569.9834,676.5941a2.5192,2.5192,0,0,1-1.04,2.0215,4.366,4.366,0,0,1-2.4209.6,5.9263,5.9263,0,0,1-.82-.06,2.5861,2.5861,0,0,1-2.16-1.48,3.82,3.82,0,0,1-.16-1.2608h2.36a1.5511,1.5511,0,0,0,.04.4805.8369.8369,0,0,0,.88.6.9828.9828,0,0,0,.54-.14c.4-.22.4209-.6807.4209-1.08v-1.5811a2.5166,2.5166,0,0,1-2.081,1.001,2.6443,2.6443,0,0,1-1.22-.3c-.94-.5-1.001-1.3613-1.001-2.2607v-5.4209a3.5222,3.5222,0,0,1,.1406-1.1211,2.0447,2.0447,0,0,1,2.04-1.46,2.9161,2.9161,0,0,1,2.121,1.02v-.82h2.4V675.934C1570.0234,676.1547,1570.0029,676.3754,1569.9834,676.5941Zm-2.3809-8.4209c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.2607.2-.38.44-.38,1.04v4.5606a1.9982,1.9982,0,0,0,.0791.6015.85.85,0,0,0,.86.5391.9594.9594,0,0,0,.98-1.1406Z" />
                                        <path className="st18" d="M1576.7822,675.5746c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3594.3809a2.2574,2.2574,0,0,1-1.7012.74,2.0952,2.0952,0,0,1-1.24-.3809c-.84-.62-.7793-1.84-.7793-2.74a2.979,2.979,0,0,1,.4795-2.1006,3.2152,3.2152,0,0,1,1.08-.7607l2.4805-1.24v-.6c0-.6-.06-.9-.3193-1.08a.9544.9544,0,0,0-.52-.1406.7762.7762,0,0,0-.74.4,2.9563,2.9563,0,0,0-.08.9609v.379h-2.1807v-1.16a2.0473,2.0473,0,0,1,.58-1.46,3.6491,3.6491,0,0,1,2.581-.7607c.3,0,2.08-.0195,2.8008,1.2812a1.879,1.879,0,0,1,.2393.98v5.7012a5.068,5.068,0,0,0,.3007,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3.2792-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2871,1.2871,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1586.1426,667.5131a1.6919,1.6919,0,0,0-.78-.16,1.6107,1.6107,0,0,0-.9.2393,2.1329,2.1329,0,0,0-.6406,1.9414v6.041h-2.36V665.3324h2.3809l-.02,1.3008a4.1074,4.1074,0,0,1,.36-.6006,2.2169,2.2169,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M1592.3418,675.5746l.0205-1a2.27,2.27,0,0,1-2.001,1.2,2.4506,2.4506,0,0,1-1.42-.44c-.7607-.5606-.86-1.2208-.86-2.84v-4.6621a2.5392,2.5392,0,0,1,1.04-2.38,2.3516,2.3516,0,0,1,1.1807-.3408,2.9962,2.9962,0,0,1,2.0605,1.041v-4.7012h2.38v14.1231Zm.0205-7.4414c0-.461-.0205-.78-.44-1.02a1.2018,1.2018,0,0,0-.58-.16.9361.9361,0,0,0-.52.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6006.0606.82.34,1a1.1145,1.1145,0,0,0,.58.16.9671.9671,0,0,0,.82-.4,1.3016,1.3016,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1606.0215,675.6742c-1.3809,0-2.1006-.3-2.4-.96a2.62,2.62,0,0,1-.2-1.1806v-6.6211H1602.12v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8418a1.3476,1.3476,0,0,0,.06.5605c.14.48.6006.56,1.02.56a2.7716,2.7716,0,0,0,.3994-.04v1.7207A5.0842,5.0842,0,0,1,1606.0215,675.6742Z" />
                                        <path className="st18" d="M1614.8613,674.7347a3.4113,3.4113,0,0,1-2.7207,1.04,4.7636,4.7636,0,0,1-1.0195-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9829,2.9829,0,0,1,3.041-1.6611c.32,0,2.28-.02,2.9209,1.501a2.96,2.96,0,0,1,.2393,1.3594v4.9414A2.45,2.45,0,0,1,1614.8613,674.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7539.7539,0,0,0-.48.14c-.38.28-.36.7012-.36,1.1211v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1440.9541,697.5746c-.1992-.2207-.2393-.4-.2793-.9-.12.12-.2207.26-.36.3809a2.2547,2.2547,0,0,1-1.7.74,2.0921,2.0921,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.9794,2.9794,0,0,1,.4794-2.1006,3.2107,3.2107,0,0,1,1.0811-.7607l2.4805-1.24v-.6c0-.6-.0606-.9-.32-1.08a.9517.9517,0,0,0-.5206-.1406.7768.7768,0,0,0-.74.4,2.9528,2.9528,0,0,0-.08.9609v.379h-2.18v-1.16a2.0478,2.0478,0,0,1,.58-1.46,3.6446,3.6446,0,0,1,2.58-.7607c.3,0,2.0811-.0195,2.8008,1.2812a1.8732,1.8732,0,0,1,.24.98v5.7012a5.0676,5.0676,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2792-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2854,1.2854,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1451.5146,696.7347a3.3232,3.3232,0,0,1-2.66,1.04,3.44,3.44,0,0,1-2.7207-.96,2.8876,2.8876,0,0,1-.62-2.0215v-4.541a2.9731,2.9731,0,0,1,.56-1.96,3.5906,3.5906,0,0,1,2.7813-1.16c.2393,0,2.38,0,3.04,1.54a3.2865,3.2865,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5437,2.5437,0,0,0-.04-.5.8333.8333,0,0,0-.8594-.6406,1.059,1.059,0,0,0-.5606.1406c-.32.22-.42.54-.42,1.24v4.4609a2.1379,2.1379,0,0,0,.06.6992.8614.8614,0,0,0,.8809.6406,1.1645,1.1645,0,0,0,.52-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1823,3.1823,0,0,1,1451.5146,696.7347Z" />
                                        <path className="st18" d="M1458.6338,697.5746v-7.2813a2.0634,2.0634,0,0,0-.08-.8007.8558.8558,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.43,1.43,0,0,0-.04.42v7.4219h-2.38V683.4515h2.34l.02,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0258,2.0258,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1463.6533,685.8519v-2.4h2.4v2.4Zm0,11.7227V687.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1470.9531,692.973v1.6817a1.9755,1.9755,0,0,0,.0606.68.8122.8122,0,0,0,.82.58.7524.7524,0,0,0,.76-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3008v.82a2.8147,2.8147,0,0,1-.1006.92c-.3994,1.44-1.68,1.8408-3.04,1.8408-2.1006,0-2.8008-1.04-3.0811-1.96a6.6252,6.6252,0,0,1-.18-1.3408v-4.3213a3.802,3.802,0,0,1,.26-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.832,2.832,0,0,1,.28,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.8193-1.0215a.8725.8725,0,0,0-.8809,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1481.2139,697.5746h-2.32l-2.2608-10.2422h2.3809l1.0605,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M1485.4531,685.8519v-2.4h2.4v2.4Zm0,11.7227V687.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1494.7139,697.5746v-7.541c0-.6407-.04-.9-.36-1.1006a.75.75,0,0,0-.46-.12c-1.12,0-1.0206,1.18-1.0206,1.3808v7.3809h-2.38V687.3324h2.42l-.0195.74a2.6358,2.6358,0,0,1,2.04-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1506.2539,698.5941a2.5162,2.5162,0,0,1-1.04,2.0215,4.366,4.366,0,0,1-2.4209.6,5.9244,5.9244,0,0,1-.82-.06,2.5862,2.5862,0,0,1-2.16-1.48,3.8462,3.8462,0,0,1-.16-1.2608h2.36a1.5511,1.5511,0,0,0,.04.4805.8382.8382,0,0,0,.8809.6.9885.9885,0,0,0,.54-.14c.3994-.22.42-.6807.42-1.08v-1.5811a2.517,2.517,0,0,1-2.0811,1.001,2.6481,2.6481,0,0,1-1.22-.3c-.94-.5-1-1.3613-1-2.2607v-5.4209a3.5215,3.5215,0,0,1,.14-1.1211,2.0447,2.0447,0,0,1,2.04-1.46,2.9147,2.9147,0,0,1,2.1211,1.02v-.82h2.4V697.934C1506.2939,698.1547,1506.2734,698.3754,1506.2539,698.5941Zm-2.3809-8.4209c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.04v4.5606a1.9589,1.9589,0,0,0,.08.6015.85.85,0,0,0,.86.5391.9591.9591,0,0,0,.9794-1.1406Z" />
                                        <path className="st18" d="M1517.5527,697.6742c-1.38,0-2.1006-.3-2.4-.96a2.6352,2.6352,0,0,1-.2-1.1806v-6.6211h-1.3v-1.58h1.28v-3.7207h2.36v3.7207h1.42v1.58h-1.42v5.8418a1.35,1.35,0,0,0,.06.5605c.1407.48.6006.56,1.0205.56a2.7981,2.7981,0,0,0,.4-.04v1.7207A5.1,5.1,0,0,1,1517.5527,697.6742Z" />
                                        <path className="st18" d="M1524.8926,697.5746v-7.2813a2.0634,2.0634,0,0,0-.08-.8007.8559.8559,0,0,0-.8-.46.89.89,0,0,0-.9.7,1.43,1.43,0,0,0-.04.42v7.4219h-2.38V683.4515h2.34l.02,4.7012a2.72,2.72,0,0,1,2.08-1.02,2.0258,2.0258,0,0,1,2.001,1.4,2.9478,2.9478,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1532.1719,692.973v1.6817a1.9786,1.9786,0,0,0,.0605.68.8123.8123,0,0,0,.82.58.7523.7523,0,0,0,.76-.5605,4.9925,4.9925,0,0,0,.08-1.16h2.3008v.82a2.8147,2.8147,0,0,1-.1006.92c-.3994,1.44-1.68,1.8408-3.04,1.8408-2.1006,0-2.8007-1.04-3.081-1.96a6.6177,6.6177,0,0,1-.18-1.3408v-4.3213a3.8,3.8,0,0,1,.26-1.38,3.587,3.587,0,0,1,5.9013-.1806,2.8319,2.8319,0,0,1,.28,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.8194-1.0215a.8725.8725,0,0,0-.8808,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1538.7119,685.8519v-2.4h2.4v2.4Zm0,11.7227V687.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1548.4326,689.5131a1.6853,1.6853,0,0,0-.78-.16,1.6119,1.6119,0,0,0-.9.2393,2.132,2.132,0,0,0-.64,1.9414v6.041h-2.36V687.3324h2.38l-.02,1.3008a4.0864,4.0864,0,0,1,.3594-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1562.9326,696.934a4.3349,4.3349,0,0,1-3.0605.9414,4.518,4.518,0,0,1-2.9405-.82,2.2932,2.2932,0,0,1-.7-.8809,3.8161,3.8161,0,0,1-.3-1.62v-7.9219a3.3966,3.3966,0,0,1,.88-2.5605,4.5854,4.5854,0,0,1,3.0811-.92c2.04,0,3.12.84,3.54,1.66a3.8992,3.8992,0,0,1,.32,1.88v7.8623A3.0629,3.0629,0,0,1,1562.9326,696.934Zm-1.8-10.0616a2.4848,2.4848,0,0,0-.16-1.1406,1.1028,1.1028,0,0,0-1.08-.62,1.4357,1.4357,0,0,0-.8408.26c-.58.4-.5,1.0205-.5,1.6015v7.1406a2.24,2.24,0,0,0,.24,1.3008,1.3436,1.3436,0,0,0,1.0605.42,1.5206,1.5206,0,0,0,.82-.24c.4794-.34.46-.9.46-1.42Z" />
                                        <path className="st18" d="M1572.9326,696.3744a2.1418,2.1418,0,0,1-2.0605,1.4209,2.5422,2.5422,0,0,1-1.7207-.7012,2.23,2.23,0,0,1-.36-.46l-.0195.94h-2.3809V683.4515h2.3809v4.6817c.14-.1211.26-.24.4-.3408a3.3,3.3,0,0,1,1.74-.66,2.149,2.149,0,0,1,2,1.36,2.6109,2.6109,0,0,1,.1807,1.02v5.8017A2.9052,2.9052,0,0,1,1572.9326,696.3744Zm-2.2607-6.2608a1.1637,1.1637,0,0,0-.28-.9209.81.81,0,0,0-.62-.24.9071.9071,0,0,0-.94.62,2.0222,2.0222,0,0,0-.08.7v4.1416a4.4653,4.4653,0,0,0,.0205.459c.08.6006.34.9.98.9a.7764.7764,0,0,0,.66-.2793,1.5637,1.5637,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M1577.1113,700.7758a2.9093,2.9093,0,0,1-1.581.34,6.7241,6.7241,0,0,1-.96-.06v-1.9805c.48,0,.6406-.0195.84-.1992.2607-.24.2207-.4405.2207-1.02V687.3324h2.38v11.2021A2.5986,2.5986,0,0,1,1577.1113,700.7758Zm-1.48-14.9434v-2.3809h2.38v2.3809Z" />
                                        <path className="st18" d="M1582.9111,692.973v1.6817a2.0072,2.0072,0,0,0,.06.68.813.813,0,0,0,.82.58.7527.7527,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.82-1.0215a.8723.8723,0,0,0-.88,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1595.332,696.7347a3.324,3.324,0,0,1-2.6611,1.04,3.4409,3.4409,0,0,1-2.7207-.96,3.61,3.61,0,0,1-.42-.7207,3.5386,3.5386,0,0,1-.2-1.3008v-4.541a2.9738,2.9738,0,0,1,.5605-1.96,3.5889,3.5889,0,0,1,2.78-1.16c.24,0,2.3809,0,3.041,1.54a3.2865,3.2865,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5336,2.5336,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.0574,1.0574,0,0,0-.56.1406c-.32.22-.42.54-.42,1.24v4.4609a2.1379,2.1379,0,0,0,.06.6992.861.861,0,0,0,.88.6406,1.1648,1.1648,0,0,0,.52-.14,1.3567,1.3567,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1829,3.1829,0,0,1,1595.332,696.7347Z" />
                                        <path className="st18" d="M1601.4121,697.6742c-1.3809,0-2.1006-.3-2.4-.96a2.6215,2.6215,0,0,1-.2-1.1806v-6.6211h-1.3008v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8418a1.3476,1.3476,0,0,0,.06.5605c.14.48.6006.56,1.0205.56a2.77,2.77,0,0,0,.3994-.04v1.7207A5.0836,5.0836,0,0,1,1601.4121,697.6742Z" />
                                        <path className="st18" d="M1604.5508,685.8519v-2.4h2.4v2.4Zm0,11.7227V687.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1613.3105,697.5746h-2.32l-2.2607-10.2422h2.3809l1.0605,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M1619.8105,692.973v1.6817a1.9755,1.9755,0,0,0,.0606.68.8113.8113,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8389,2.8389,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1006,0-2.8-1.04-3.08-1.96a6.5667,6.5667,0,0,1-.1807-1.3408v-4.3213a3.8087,3.8087,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.9394c0-.2207.04-1.0215-.82-1.0215a.8723.8723,0,0,0-.88,1.0215v1.26h1.7Z" />
                                        <path className="st18" d="M1631.751,696.8539a3.0682,3.0682,0,0,1-2.62.9209c-1.1407,0-2.4-.22-2.9014-1.4209a3.58,3.58,0,0,1-.22-1.32v-1.06h2.2v.8a2.4432,2.4432,0,0,0,.0605.6406.7976.7976,0,0,0,.86.66.9619.9619,0,0,0,.54-.1406,1.1014,1.1014,0,0,0,.3994-.9805,1.2472,1.2472,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.461c.0205-2.04,1.2608-2.62,3.1016-2.62,1.5,0,2.32.52,2.64,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1406H1630.11v-.74c0-.16.04-1.1211-.88-1.1211a.7792.7792,0,0,0-.82.92c0,.6006.2207.8008.6806,1.1211l2.2,1.54a3.6124,3.6124,0,0,1,.62.62,2.7223,2.7223,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1631.751,696.8539Z" />
                                        <path className="st18" d="M1641.1709,689.0326l-1.36,1.9805a3.7693,3.7693,0,0,0-.82,1.62,4.3469,4.3469,0,0,0-.039.7012v.4394H1636.69v-.7a5.5964,5.5964,0,0,1,.02-.66,3.1534,3.1534,0,0,1,.66-1.6006l1.32-2.04a2.4053,2.4053,0,0,0,.5606-1.6406v-1.2607a.8863.8863,0,0,0-.9-.9795c-.76,0-.98.44-.98,1.08v2.3409h-2.2207v-1.4805a10.16,10.16,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2412-2.2207a3.87,3.87,0,0,1,2.3008.6,1.9448,1.9448,0,0,1,.6.7207,4.0917,4.0917,0,0,1,.26,1.76v.8008A3.7618,3.7618,0,0,1,1641.1709,689.0326Zm-4.4805,8.542V694.974h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1496.6846,813.475h-2.74l-1.36-9.6416-1.5205,9.6416h-2.78l-2.58-14.1231h2.7l1.42,10.6426,1.5206-10.6426h2.4609l1.4805,10.6426,1.4794-10.6426h2.6407Z" />
                                        <path className="st18" d="M1505.584,813.475v-7.2813a2.0558,2.0558,0,0,0-.08-.8.8566.8566,0,0,0-.8008-.4609.8884.8884,0,0,0-.8994.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.3809V799.3519h2.3408l.02,4.7012a2.7218,2.7218,0,0,1,2.081-1.02,2.0238,2.0238,0,0,1,2,1.4,2.9229,2.9229,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1514.7236,813.475c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3808a2.2525,2.2525,0,0,1-1.7.74,2.0934,2.0934,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.975,2.975,0,0,1,.48-2.1006,3.2047,3.2047,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.0595-.9-.32-1.08a.9575.9575,0,0,0-.52-.14.7761.7761,0,0,0-.74.3994,2.963,2.963,0,0,0-.08.9609v.38h-2.1807v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6477,3.6477,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8725,1.8725,0,0,1,.24.9805v5.7012a5.0669,5.0669,0,0,0,.3008,2.32c.02.06.06.1.1591.18Zm-.34-5.1612-1.16.7608c-.08.06-.1806.14-.2.16-.3007.2793-.3408.68-.3408,1.48,0,.7608.1006,1.2.58,1.1807a1.2786,1.2786,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1522.5635,813.5756c-1.3809,0-2.1006-.3008-2.4014-.961a2.6272,2.6272,0,0,1-.1992-1.18v-6.6211h-1.3008v-1.581h1.28v-3.7207h2.36v3.7207h1.4209v1.581h-1.4209v5.8409a1.3269,1.3269,0,0,0,.0606.56c.14.48.6.56,1.02.56a2.6371,2.6371,0,0,0,.4-.04v1.7207A5.0632,5.0632,0,0,1,1522.5635,813.5756Z" />
                                        <path className="st18" d="M1535.3633,813.475v-7.2813a2.0558,2.0558,0,0,0-.08-.8.8566.8566,0,0,0-.8008-.4609.8884.8884,0,0,0-.8994.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.3809V799.3519h2.3408l.02,4.7012a2.7218,2.7218,0,0,1,2.081-1.02,2.0238,2.0238,0,0,1,2,1.4,2.9229,2.9229,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M1544.5029,813.475c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3808a2.2525,2.2525,0,0,1-1.7.74,2.0934,2.0934,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.975,2.975,0,0,1,.4805-2.1006,3.2053,3.2053,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9575.9575,0,0,0-.52-.14.7761.7761,0,0,0-.74.3994,2.963,2.963,0,0,0-.08.9609v.38h-2.1807v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6477,3.6477,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8725,1.8725,0,0,1,.24.9805v5.7012a5.0669,5.0669,0,0,0,.3008,2.32c.0195.06.0595.1.1591.18Zm-.34-5.1612-1.16.7608c-.08.06-.1806.14-.2.16-.3007.2793-.3408.68-.3408,1.48,0,.7608.1006,1.2.58,1.1807a1.2786,1.2786,0,0,0,.86-.36l.2608-.22Z" />
                                        <path className="st18" d="M1554.583,812.7552a3.0716,3.0716,0,0,1-2.6211.92c-1.14,0-2.4-.22-2.9-1.42a3.5854,3.5854,0,0,1-.22-1.32v-1.06h2.2v.8a2.438,2.438,0,0,0,.0605.6406.7966.7966,0,0,0,.8594.66.9654.9654,0,0,0,.54-.14,1.104,1.104,0,0,0,.4-.98,1.248,1.248,0,0,0-.7-1.32l-2-1.4209a2.5257,2.5257,0,0,1-1.2207-2.46c.0206-2.041,1.2608-2.6211,3.1006-2.6211,1.501,0,2.32.52,2.6407,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1406h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2011,1.54a3.6855,3.6855,0,0,1,.62.62,2.7267,2.7267,0,0,1,.38,1.6611A3.305,3.305,0,0,1,1554.583,812.7552Z" />
                                        <path className="st18" d="M1565.2822,808.8744v1.6807a1.9745,1.9745,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.9921,4.9921,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1575.9424,813.475c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3594.3808a2.2566,2.2566,0,0,1-1.7011.74,2.0954,2.0954,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2156,3.2156,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.3194-1.08a.9642.9642,0,0,0-.52-.14.7767.7767,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.1807v-1.16a2.05,2.05,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.8732,1.8732,0,0,1,.24.9805v5.7012a5.0678,5.0678,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.7608c-.08.06-.1807.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7608.1,1.2.58,1.1807a1.2827,1.2827,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1586.5029,812.6351a3.324,3.324,0,0,1-2.6611,1.04,3.4409,3.4409,0,0,1-2.7207-.96,3.5823,3.5823,0,0,1-.42-.7207,3.5366,3.5366,0,0,1-.2-1.3v-4.541a2.9754,2.9754,0,0,1,.5605-1.961,3.589,3.589,0,0,1,2.78-1.16c.24,0,2.3809,0,3.041,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.54,2.54,0,0,0-.04-.5.8337.8337,0,0,0-.86-.64,1.0677,1.0677,0,0,0-.56.14c-.32.22-.42.54-.42,1.24v4.461a2.1418,2.1418,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1648,1.1648,0,0,0,.5205-.14,1.3557,1.3557,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1829,3.1829,0,0,1,1586.5029,812.6351Z" />
                                        <path className="st18" d="M1593.623,813.475v-7.2813a2.0582,2.0582,0,0,0-.08-.8.8552.8552,0,0,0-.8-.4609.8886.8886,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209v7.4209h-2.3808V799.3519h2.3408l.02,4.7012a2.722,2.722,0,0,1,2.0811-1.02,2.0238,2.0238,0,0,1,2,1.4,2.918,2.918,0,0,1,.1406,1.02v8.0215Z" />
                                        <path className="st18" d="M1464.8438,835.475V821.3519h2.58V835.475Z" />
                                        <path className="st18" d="M1474.4043,835.475v-7.541c0-.6407-.04-.9-.3594-1.1006a.754.754,0,0,0-.4609-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.3809V825.2328h2.4209l-.02.74a2.6363,2.6363,0,0,1,2.041-.94,1.9952,1.9952,0,0,1,2.06,1.44,3.2407,3.2407,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1483.5645,835.475l.02-1a2.2678,2.2678,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.4405c-.76-.5595-.8594-1.22-.8594-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3587,2.3587,0,0,1,1.18-.34,2.991,2.991,0,0,1,2.0606,1.04v-4.7012h2.3808V835.475Zm.02-7.4414c0-.46-.02-.78-.4395-1.02a1.2038,1.2038,0,0,0-.58-.16.9364.9364,0,0,0-.5206.16c-.4.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.9712.9712,0,0,0,.82-.4,1.3131,1.3131,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M1488.623,823.7523v-2.4h2.4v2.4Zm0,11.7227V825.2328h2.4V835.475Z" />
                                        <path className="st18" d="M1497.3838,835.475h-2.32l-2.2608-10.2422h2.3809l1.06,7.1016,1.22-7.1016h2.3808Z" />
                                        <path className="st18" d="M1501.623,823.7523v-2.4h2.4v2.4Zm0,11.7227V825.2328h2.4V835.475Z" />
                                        <path className="st18" d="M1510.8232,835.475l.0206-1a2.27,2.27,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.5595-.86-1.22-.86-2.84v-4.6611a2.5423,2.5423,0,0,1,1.04-2.3809,2.3675,2.3675,0,0,1,1.1807-.34,2.9931,2.9931,0,0,1,2.0606,1.04v-4.7012h2.38V835.475Zm.0206-7.4414c0-.46-.0206-.78-.4405-1.02a1.2013,1.2013,0,0,0-.58-.16.9334.9334,0,0,0-.52.16c-.4.28-.3808.7-.3808,1.1406v4.3613c0,.6.0605.8194.3408,1a1.1174,1.1174,0,0,0,.58.16.9694.9694,0,0,0,.8193-.4,1.3059,1.3059,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1520.0635,835.475v-.84a3.2373,3.2373,0,0,1-2.1807,1.0205,2.2529,2.2529,0,0,1-1.2-.36,2.5155,2.5155,0,0,1-.8-2.3408v-7.7217h2.4v7.3613a1.2726,1.2726,0,0,0,.22.9805.8744.8744,0,0,0,.6407.2607,1.03,1.03,0,0,0,.6-.2c.4-.3408.32-1.1006.32-1.16v-7.2422h2.4V835.475Z" />
                                        <path className="st18" d="M1529.2227,835.475c-.1993-.22-.2393-.3994-.2793-.9-.12.12-.2207.2607-.36.3808a2.2542,2.2542,0,0,1-1.7.74,2.0919,2.0919,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.0811-.76l2.48-1.24v-.6006c0-.6-.0605-.9-.32-1.08a.9616.9616,0,0,0-.5205-.14.7774.7774,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6449,3.6449,0,0,1,2.58-.76c.3,0,2.081-.02,2.8007,1.28a1.8725,1.8725,0,0,1,.24.9805v5.7012a5.0664,5.0664,0,0,0,.3,2.32c.0205.06.06.1.16.18Zm-.34-5.1612-1.16.7608c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7608.1,1.2.58,1.1807a1.2805,1.2805,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1533.9023,835.475V821.3519h2.4V835.475Z" />
                                        <path className="st18" d="M1548.5225,835.475c-.1993-.22-.2393-.3994-.2793-.9-.12.12-.2207.2607-.36.3808a2.2542,2.2542,0,0,1-1.7.74,2.0919,2.0919,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.0811-.76l2.48-1.24v-.6006c0-.6-.0605-.9-.32-1.08a.9616.9616,0,0,0-.5205-.14.7774.7774,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6449,3.6449,0,0,1,2.58-.76c.3,0,2.081-.02,2.8007,1.28a1.8725,1.8725,0,0,1,.24.9805v5.7012a5.0664,5.0664,0,0,0,.3,2.32c.0205.06.06.1.16.18Zm-.34-5.1612-1.16.7608c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.48,0,.7608.1,1.2.58,1.1807a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M1559.083,834.6351a3.3232,3.3232,0,0,1-2.66,1.04,3.44,3.44,0,0,1-2.7208-.96,3.4957,3.4957,0,0,1-.42-.7207,3.5387,3.5387,0,0,1-.2-1.3v-4.541a2.9742,2.9742,0,0,1,.56-1.961,3.5907,3.5907,0,0,1,2.7813-1.16c.2392,0,2.38,0,3.04,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.548,2.548,0,0,0-.04-.5.8325.8325,0,0,0-.8593-.64,1.069,1.069,0,0,0-.5606.14c-.32.22-.42.54-.42,1.24v4.461a2.1418,2.1418,0,0,0,.06.7.8615.8615,0,0,0,.8808.64,1.1642,1.1642,0,0,0,.5195-.14,1.3554,1.3554,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1829,3.1829,0,0,1,1559.083,834.6351Z" />
                                        <path className="st18" d="M1566.2031,835.475v-7.2813a2.0558,2.0558,0,0,0-.08-.8.855.855,0,0,0-.8-.4609.8885.8885,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209v7.4209h-2.3808V821.3519h2.3408l.02,4.7012a2.7218,2.7218,0,0,1,2.0811-1.02,2.0238,2.0238,0,0,1,2,1.4,2.92,2.92,0,0,1,.1406,1.02v8.0215Z" />
                                        <path className="st18" d="M1571.2217,823.7523v-2.4h2.4v2.4Zm0,11.7227V825.2328h2.4V835.475Z" />
                                        <path className="st18" d="M1578.5225,830.8744v1.6807a1.9777,1.9777,0,0,0,.0605.68.81.81,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9929,4.9929,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.0411,1.84-2.1005,0-2.8-1.04-3.08-1.96a6.5667,6.5667,0,0,1-.1807-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1588.7822,835.475h-2.32l-2.2607-10.2422h2.3808l1.0606,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M1595.2822,830.8744v1.6807a1.9745,1.9745,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.9921,4.9921,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1605.9824,835.475l.02-1a2.27,2.27,0,0,1-2.0009,1.2,2.45,2.45,0,0,1-1.42-.4405c-.7607-.5595-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.3809,2.3638,2.3638,0,0,1,1.1807-.34,2.9926,2.9926,0,0,1,2.0605,1.04v-4.7012h2.38V835.475Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.2018,1.2018,0,0,0-.58-.16.9361.9361,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.1406v4.3613c0,.6.06.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.306,1.306,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M1617.4824,826.933l-1.36,1.9805a3.7742,3.7742,0,0,0-.82,1.621,4.3189,4.3189,0,0,0-.0391.7v.44h-2.2607v-.7a5.6278,5.6278,0,0,1,.02-.66,3.1522,3.1522,0,0,1,.66-1.6l1.32-2.041a2.4034,2.4034,0,0,0,.5605-1.6406v-1.26a.8865.8865,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.3409h-2.2207v-1.4805a10.177,10.177,0,0,1,.08-1.34c.2-1.1806.86-2.2207,3.2412-2.2207a3.8773,3.8773,0,0,1,2.3008.6,1.9537,1.9537,0,0,1,.6.7207,4.094,4.094,0,0,1,.26,1.7607v.8A3.7608,3.7608,0,0,1,1617.4824,826.933Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1053.9023,825.2748v-6.4619h-2.3808v6.4619h-2.6006V811.1517h2.6006v5.541h2.3808v-5.541h2.6006v14.1231Z" />
                                        <path className="st18" d="M1064.9619,824.434a3.4107,3.4107,0,0,1-2.7207,1.041,4.8315,4.8315,0,0,1-1.0195-.1006,2.4959,2.4959,0,0,1-1.9-1.62,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1713,3.1713,0,0,1,.2-1.3007,2.9835,2.9835,0,0,1,3.041-1.66c.3194,0,2.28-.02,2.92,1.5a2.9532,2.9532,0,0,1,.24,1.36v4.9415A2.4485,2.4485,0,0,1,1064.9619,824.434Zm-1.78-6.8809c0-.6406-.0595-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7516.7516,0,0,0-.4795.14c-.3808.28-.36.7-.36,1.12v5.2012c0,.1807-.0205,1.0606.82,1.0606.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1076.042,825.2748h-2.24l-1-6.0811-1.2,6.0811h-2.2608l-2.08-10.2422h2.32l1.08,6.7607,1.2-6.7607h1.94l1.08,6.7607,1.16-6.7607h2.3213Z" />
                                        <path className="st18" d="M1089.8008,825.2748l.0205-1a2.2716,2.2716,0,0,1-2.001,1.2,2.45,2.45,0,0,1-1.42-.4405c-.76-.56-.86-1.2207-.86-2.8408v-4.6611a2.5414,2.5414,0,0,1,1.04-2.38,2.3547,2.3547,0,0,1,1.1806-.3408,2.9942,2.9942,0,0,1,2.0606,1.041v-4.7012h2.38v14.1231Zm.0205-7.4414c0-.461-.0205-.78-.44-1.02a1.19,1.19,0,0,0-.58-.16.93.93,0,0,0-.5205.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6006.0605.82.3408,1a1.1078,1.1078,0,0,0,.58.16.9666.9666,0,0,0,.8193-.4,1.3022,1.3022,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M1100.5615,824.434a3.4117,3.4117,0,0,1-2.7207,1.041,4.8357,4.8357,0,0,1-1.02-.1006,2.4973,2.4973,0,0,1-1.9-1.62,3.7275,3.7275,0,0,1-.18-1.38v-4.5811a3.19,3.19,0,0,1,.1993-1.3007,2.9842,2.9842,0,0,1,3.041-1.66c.32,0,2.28-.02,2.9209,1.5a2.9532,2.9532,0,0,1,.24,1.36v4.9415A2.4527,2.4527,0,0,1,1100.5615,824.434Zm-1.7812-6.8809c0-.6406-.06-.92-.38-1.12a.7258.7258,0,0,0-.4395-.12.7545.7545,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.0606.82,1.0606.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1109.2393,825.2748V811.1517h6.8418v2.1407H1111.84v3.32h4.001v2.1406h-4.001v4.3408h4.4414v2.1807Z" />
                                        <path className="st18" d="M1126.7012,825.2748v-7.5821a1.1171,1.1171,0,0,0-.36-1.04.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3v7.4619h-2.36v-7.5215c0-.4209.04-.8008-.32-1.1006a.791.791,0,0,0-.5606-.18c-.9794,0-.92,1.04-.92,1.22v7.5821h-2.36V815.0326h2.3808v.68a2.9155,2.9155,0,0,1,2.0205-.9,2.4414,2.4414,0,0,1,1.92,1.02,3.14,3.14,0,0,1,1.6806-.94,3.2518,3.2518,0,0,1,.66-.06,1.9263,1.9263,0,0,1,1.96,1.4,2.6951,2.6951,0,0,1,.1006.92v8.1221Z" />
                                        <path className="st18" d="M1138.0811,824.4945a1.93,1.93,0,0,1-.4405.54,2.02,2.02,0,0,1-1.32.4405,3.1354,3.1354,0,0,1-2.22-1.0606v4.4H1131.72V815.0326h2.3809v.78a3.2611,3.2611,0,0,1,2.1406-.9805,2.1806,2.1806,0,0,1,1.7.82,3.0541,3.0541,0,0,1,.48,1.88v5.4414A3.5145,3.5145,0,0,1,1138.0811,824.4945Zm-2.0606-6.7617c0-.58-.14-.8-.42-.9805a1.0919,1.0919,0,0,0-.5205-.14,1.0428,1.0428,0,0,0-.54.14,1.0533,1.0533,0,0,0-.4394,1.041v4.6006a1.4145,1.4145,0,0,0,.08.58.9336.9336,0,0,0,.8994.56.86.86,0,0,0,.8408-.48,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1140.94,825.2748V811.1517h2.4v14.1231Z" />
                                        <path className="st18" d="M1151.6807,824.434a3.4107,3.4107,0,0,1-2.7207,1.041,4.8327,4.8327,0,0,1-1.02-.1006,2.496,2.496,0,0,1-1.9-1.62,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1713,3.1713,0,0,1,.2-1.3007,2.9835,2.9835,0,0,1,3.041-1.66c.3193,0,2.28-.02,2.92,1.5a2.9519,2.9519,0,0,1,.24,1.36v4.9415A2.4488,2.4488,0,0,1,1151.6807,824.434Zm-1.78-6.8809c0-.6406-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7514.7514,0,0,0-.4795.14c-.3809.28-.36.7-.36,1.12v5.2012c0,.1807-.02,1.0606.82,1.0606.8995,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1158.6191,826.015a4.6483,4.6483,0,0,1-.8,2.0606,2.3184,2.3184,0,0,1-2.0605.7392,8.0377,8.0377,0,0,1-1.54-.18l.3593-1.88a3.2088,3.2088,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6611-10.2422h2.3613l1.32,6.581,1.0195-6.581h2.3409Z" />
                                        <path className="st18" d="M1164.999,820.6732v1.6807a1.981,1.981,0,0,0,.0606.6806.8113.8113,0,0,0,.8193.58.7525.7525,0,0,0,.7607-.5605,4.9934,4.9934,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1006,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8084,3.8084,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1173.7988,820.6732v1.6807a2.0128,2.0128,0,0,0,.06.6806.813.813,0,0,0,.82.58.7527.7527,0,0,0,.7608-.5605,4.9982,4.9982,0,0,0,.08-1.16h2.3v.82a2.8416,2.8416,0,0,1-.1.92c-.4,1.44-1.6806,1.8408-3.041,1.8408-2.1,0-2.8-1.041-3.08-1.961a6.5645,6.5645,0,0,1-.1806-1.34v-4.3213a3.8084,3.8084,0,0,1,.2607-1.38,3.5871,3.5871,0,0,1,5.9014-.1806,2.8337,2.8337,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8719.8719,0,0,0-.88,1.02v1.2607h1.7Z" />
                                        <path className="st18" d="M1185.7393,824.5541a3.0712,3.0712,0,0,1-2.62.9209c-1.1406,0-2.4-.2207-2.9013-1.4209a3.5789,3.5789,0,0,1-.22-1.32v-1.06h2.2v.8a2.4329,2.4329,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9675.9675,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.9805,1.2486,1.2486,0,0,0-.7-1.32l-2-1.42a2.5273,2.5273,0,0,1-1.2207-2.461c.0205-2.04,1.2607-2.62,3.1016-2.62,1.5,0,2.32.52,2.64,1.1006a2.2985,2.2985,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7793.7793,0,0,0-.82.92c0,.6006.2207.8008.6807,1.1211l2.2,1.54a3.6124,3.6124,0,0,1,.62.62,2.7209,2.7209,0,0,1,.38,1.66A3.3041,3.3041,0,0,1,1185.7393,824.5541Z" />
                                        <path className="st18" d="M1198.2988,825.2748c-.2-.2207-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2574,2.2574,0,0,1-1.7012.74,2.0922,2.0922,0,0,1-1.24-.38c-.84-.62-.7793-1.8408-.7793-2.74a2.9778,2.9778,0,0,1,.4795-2.1006,3.2026,3.2026,0,0,1,1.08-.7607l2.48-1.24v-.6c0-.6006-.06-.9-.3193-1.08a.9544.9544,0,0,0-.5205-.1406.7762.7762,0,0,0-.74.4,2.9531,2.9531,0,0,0-.08.96v.38h-2.1807v-1.16a2.0461,2.0461,0,0,1,.58-1.46,3.6491,3.6491,0,0,1,2.581-.7607c.3,0,2.08-.0195,2.8008,1.28a1.8822,1.8822,0,0,1,.2393.98v5.7012a5.0663,5.0663,0,0,0,.3007,2.32c.02.0606.06.1006.16.1807Zm-.34-5.1612-1.16.76c-.08.0606-.1806.1406-.2.16-.3.28-.34.6806-.34,1.48,0,.76.1,1.2.58,1.18a1.286,1.286,0,0,0,.86-.3593l.26-.2208Z" />
                                        <path className="st18" d="M1208.8594,824.434a3.3226,3.3226,0,0,1-2.6612,1.041,3.4411,3.4411,0,0,1-2.7207-.961,3.5947,3.5947,0,0,1-.42-.72,3.5407,3.5407,0,0,1-.2-1.3008v-4.541a2.9734,2.9734,0,0,1,.5606-1.96,3.585,3.585,0,0,1,2.78-1.16c.24,0,2.3809,0,3.0411,1.54a3.2828,3.2828,0,0,1,.2,1.32v1.2208h-2.3409v-1.24a2.5357,2.5357,0,0,0-.04-.5.8346.8346,0,0,0-.86-.6406,1.0682,1.0682,0,0,0-.56.14c-.32.2207-.42.5411-.42,1.2413v4.4609a2.1413,2.1413,0,0,0,.0595.6992.861.861,0,0,0,.88.6406,1.1785,1.1785,0,0,0,.52-.14,1.3564,1.3564,0,0,0,.4-1.1807v-1.1806h2.36v1.1611A3.1808,3.1808,0,0,1,1208.8594,824.434Z" />
                                        <path className="st18" d="M1214.94,825.3744c-1.3809,0-2.1006-.3-2.4-.96a2.62,2.62,0,0,1-.2-1.1806v-6.6211h-1.3008v-1.58h1.28v-3.7207h2.3613v3.7207h1.42v1.58h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.5605c.14.4805.6005.5606,1.0205.5606a2.7591,2.7591,0,0,0,.3994-.04v1.72A5.0176,5.0176,0,0,1,1214.94,825.3744Z" />
                                        <path className="st18" d="M1224.52,816.7328l-1.36,1.9805a3.7669,3.7669,0,0,0-.82,1.62,4.33,4.33,0,0,0-.0391.7v.44h-2.2607v-.7a5.6278,5.6278,0,0,1,.02-.66,3.1544,3.1544,0,0,1,.66-1.6005l1.32-2.04a2.4053,2.4053,0,0,0,.5605-1.6406v-1.2607a.8858.8858,0,0,0-.9-.9795c-.76,0-.98.4394-.98,1.08v2.34h-2.2208v-1.48a10.18,10.18,0,0,1,.08-1.3408c.2-1.18.86-2.2207,3.2412-2.2207a3.8724,3.8724,0,0,1,2.3008.6006,1.9471,1.9471,0,0,1,.6.72,4.1,4.1,0,0,1,.26,1.7607v.8A3.762,3.762,0,0,1,1224.52,816.7328Zm-4.48,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1206.0352,653.5746v-6.461h-2.3809v6.461h-2.6016V639.4515h2.6016v5.5411h2.3809v-5.5411h2.6v14.1231Z" />
                                        <path className="st18" d="M1217.0947,652.7347a3.4133,3.4133,0,0,1-2.7207,1.04,4.8622,4.8622,0,0,1-1.0205-.1,2.499,2.499,0,0,1-1.9-1.6211,3.7491,3.7491,0,0,1-.18-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.983,2.983,0,0,1,3.04-1.6611c.32,0,2.2812-.0195,2.9209,1.501a2.9478,2.9478,0,0,1,.24,1.36v4.94A2.4547,2.4547,0,0,1,1217.0947,652.7347Zm-1.78-6.8818c0-.64-.0606-.92-.3809-1.12a.7258.7258,0,0,0-.44-.12.75.75,0,0,0-.48.1406c-.38.2793-.3594.7-.3594,1.12v5.2012c0,.18-.02,1.06.82,1.06.8994,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1228.1748,653.5746h-2.24l-1.001-6.0811-1.1992,6.0811h-2.2608l-2.081-10.2422h2.32l1.081,6.7617,1.2-6.7617h1.9405l1.08,6.7617,1.16-6.7617h2.3213Z" />
                                        <path className="st18" d="M1243.6543,652.7347a3.3234,3.3234,0,0,1-2.66,1.04,3.4381,3.4381,0,0,1-2.7207-.96,3.4851,3.4851,0,0,1-.4209-.7207,3.56,3.56,0,0,1-.2-1.3v-4.541a2.9745,2.9745,0,0,1,.5606-1.9609,3.59,3.59,0,0,1,2.7812-1.16c.2393,0,2.38,0,3.04,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.6313,2.6313,0,0,0-.04-.5.8334.8334,0,0,0-.8594-.64,1.0668,1.0668,0,0,0-.56.14c-.32.22-.42.54-.42,1.24v4.4609a2.1452,2.1452,0,0,0,.06.7.8613.8613,0,0,0,.88.64,1.1645,1.1645,0,0,0,.5205-.14,1.3548,1.3548,0,0,0,.3994-1.1807v-1.18h2.3614v1.16A3.1891,3.1891,0,0,1,1243.6543,652.7347Z" />
                                        <path className="st18" d="M1250.6934,653.5746c-.2-.22-.24-.3994-.2793-.9-.1211.12-.2207.2607-.3614.3809a2.2517,2.2517,0,0,1-1.6992.74,2.09,2.09,0,0,1-1.24-.3809c-.8408-.62-.7813-1.84-.7813-2.74a2.98,2.98,0,0,1,.48-2.1006,3.2057,3.2057,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9572.9572,0,0,0-.5195-.14.7767.7767,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.18v-1.16a2.045,2.045,0,0,1,.58-1.461,3.6449,3.6449,0,0,1,2.58-.76c.3,0,2.08-.02,2.8007,1.28a1.8725,1.8725,0,0,1,.24.98v5.7012a5.0662,5.0662,0,0,0,.3,2.32c.0195.06.06.1.1592.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2792-.3409.68-.3409,1.4794,0,.7608.1006,1.2.58,1.1807a1.2813,1.2813,0,0,0,.8614-.36l.26-.22Z" />
                                        <path className="st18" d="M1259.5938,653.5746v-7.541c0-.6407-.04-.9-.3594-1.1006a.7542.7542,0,0,0-.461-.12c-1.12,0-1.02,1.1806-1.02,1.3808v7.3809h-2.3809V643.3324h2.42l-.02.74a2.6367,2.6367,0,0,1,2.0411-.94,1.995,1.995,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M1270.1719,653.5746V639.4515h6.8418v2.1407h-4.2412v3.3213h4.0009v2.14h-4.0009v4.3408h4.44v2.1807Z" />
                                        <path className="st18" d="M1287.6348,653.5746v-7.5811a1.1179,1.1179,0,0,0-.3614-1.041.934.934,0,0,0-.5-.14c-.8,0-.96.66-.96,1.3007v7.461h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7907.7907,0,0,0-.5605-.18c-.98,0-.92,1.04-.92,1.2207v7.5811h-2.3593V643.3324h2.38v.6807a2.9173,2.9173,0,0,1,2.0205-.9,2.4413,2.4413,0,0,1,1.92,1.02,3.1411,3.1411,0,0,1,1.6806-.9405,3.21,3.21,0,0,1,.66-.06,1.9268,1.9268,0,0,1,1.96,1.4,2.7078,2.7078,0,0,1,.1005.9209v8.1211Z" />
                                        <path className="st18" d="M1299.0127,652.7943a1.9162,1.9162,0,0,1-.4395.54,2.0194,2.0194,0,0,1-1.32.44,3.132,3.132,0,0,1-2.2207-1.0606v4.4014h-2.3808V643.3324h2.3808v.78a3.2665,3.2665,0,0,1,2.1407-.9805,2.1793,2.1793,0,0,1,1.7.82,3.0556,3.0556,0,0,1,.48,1.8809v5.4414A3.5139,3.5139,0,0,1,1299.0127,652.7943Zm-2.0606-6.7607c0-.58-.14-.8008-.42-.9805a1.09,1.09,0,0,0-.52-.14,1.0279,1.0279,0,0,0-.54.14,1.0513,1.0513,0,0,0-.44,1.04v4.6006a1.419,1.419,0,0,0,.08.5811.9353.9353,0,0,0,.9.56.8591.8591,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1301.8721,653.5746V639.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1312.6123,652.7347a3.4113,3.4113,0,0,1-2.7207,1.04,4.85,4.85,0,0,1-1.0195-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.45,2.45,0,0,1,1312.6123,652.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7468.7468,0,0,0-.4795.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1319.5527,654.3148a4.6383,4.6383,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0605.74,8.12,8.12,0,0,1-1.54-.18l.3594-1.8808a3.2136,3.2136,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6611-10.2422h2.3613l1.32,6.582,1.0195-6.582h2.3409Z" />
                                        <path className="st18" d="M1325.9316,648.974v1.6807a1.9755,1.9755,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7527.7527,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1334.7314,648.974v1.6807a2.0072,2.0072,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7526.7526,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8441,2.8441,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1346.6729,652.8549a3.0713,3.0713,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5843,3.5843,0,0,1-.22-1.32v-1.06h2.2v.8a2.4326,2.4326,0,0,0,.0606.6406.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2466,1.2466,0,0,0-.7-1.32l-2-1.4209a2.5258,2.5258,0,0,1-1.2207-2.46c.02-2.041,1.2607-2.6211,3.1016-2.6211,1.5,0,2.32.52,2.64,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1406h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6486,3.6486,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3054,3.3054,0,0,1,1346.6729,652.8549Z" />
                                        <path className="st18" d="M1211.375,675.5746h-2.24l-1-6.0811-1.2012,6.0811h-2.26l-2.08-10.2422h2.32l1.08,6.7617,1.2-6.7617h1.94l1.08,6.7617,1.16-6.7617h2.32Z" />
                                        <path className="st18" d="M1221.2148,674.7347a3.4122,3.4122,0,0,1-2.7207,1.04,4.8428,4.8428,0,0,1-1.0195-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7019,3.7019,0,0,1-.1806-1.38v-4.5811a3.1615,3.1615,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.3193,0,2.2793-.0195,2.92,1.501a2.9478,2.9478,0,0,1,.24,1.36v4.94A2.45,2.45,0,0,1,1221.2148,674.7347Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.7284.7284,0,0,0-.44-.12.742.742,0,0,0-.4795.1406c-.3809.2793-.3614.7-.3614,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M1228.9941,667.5131a1.683,1.683,0,0,0-.7793-.16,1.6037,1.6037,0,0,0-.9.24,2.13,2.13,0,0,0-.6407,1.94v6.041h-2.36V665.3324h2.38l-.02,1.3008a4.1814,4.1814,0,0,1,.3594-.6006,2.2169,2.2169,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M1235.5938,675.5746l-2.2-5.2207v5.2207h-2.36V661.4515h2.36v7.3819l1.96-3.501h2.6406l-2.62,4.2607,2.9,5.9815Z" />
                                        <path className="st18" d="M1249.4141,675.5746c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2524,2.2524,0,0,1-1.7.74,2.0919,2.0919,0,0,1-1.24-.3809c-.8408-.62-.78-1.84-.78-2.74a2.975,2.975,0,0,1,.4805-2.1006,3.1959,3.1959,0,0,1,1.08-.76l2.4805-1.24v-.6006c0-.6-.0606-.9-.32-1.08a.9572.9572,0,0,0-.52-.14.776.776,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.1807v-1.16a2.05,2.05,0,0,1,.58-1.461,3.648,3.648,0,0,1,2.5811-.76c.2988,0,2.08-.02,2.8,1.28a1.8732,1.8732,0,0,1,.24.98v5.7012a5.08,5.08,0,0,0,.3,2.32c.0205.06.0606.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1807.14-.2012.16-.3.2792-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2806,1.2806,0,0,0,.86-.36l.2607-.22Z" />
                                        <path className="st18" d="M1259.4941,674.8549a3.0719,3.0719,0,0,1-2.6211.92c-1.1406,0-2.4-.22-2.9-1.42a3.571,3.571,0,0,1-.2207-1.32v-1.06h2.2011v.8a2.3941,2.3941,0,0,0,.0606.6406.7956.7956,0,0,0,.8593.66.967.967,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5241,2.5241,0,0,1-1.2207-2.46c.02-2.041,1.26-2.6211,3.1006-2.6211,1.5009,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1406h-2.0606v-.74c0-.16.04-1.12-.8808-1.12a.7787.7787,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6527,3.6527,0,0,1,.6211.62,2.7276,2.7276,0,0,1,.3789,1.6611A3.31,3.31,0,0,1,1259.4941,674.8549Z" />
                                        <path className="st18" d="M1270.1934,670.974v1.6807a2.01,2.01,0,0,0,.0595.68.8111.8111,0,0,0,.82.58.7542.7542,0,0,0,.7608-.5605,4.9851,4.9851,0,0,0,.0791-1.16h2.3008v.82a2.82,2.82,0,0,1-.1006.9209c-.3994,1.44-1.68,1.84-3.04,1.84-2.1005,0-2.8007-1.04-3.081-1.96a6.6177,6.6177,0,0,1-.18-1.3408v-4.32a3.8037,3.8037,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8154,2.8154,0,0,1,.28,1.46v2.9209Zm1.6992-2.94c0-.2207.041-1.02-.8194-1.02a.8709.8709,0,0,0-.88,1.02v1.26h1.6992Z" />
                                        <path className="st18" d="M1279.9531,663.392c-.44.1006-.44.58-.44.6806v1.26h1.0605v1.5811h-1.081v8.6611h-2.3594v-8.6611h-1.0605v-1.5811h1.0605a10.6257,10.6257,0,0,1,.06-2.28,1.72,1.72,0,0,1,.6006-1.1406,2.8513,2.8513,0,0,1,1.98-.5c.2989,0,.6.02.88.04v1.961A2.1129,2.1129,0,0,0,1279.9531,663.392Z" />
                                        <path className="st18" d="M1285.834,663.392c-.4414.1006-.4414.58-.4414.6806v1.26h1.0605v1.5811h-1.08v8.6611h-2.36v-8.6611h-1.06v-1.5811h1.06a10.6257,10.6257,0,0,1,.06-2.28,1.7233,1.7233,0,0,1,.6006-1.1406,2.8526,2.8526,0,0,1,1.98-.5c.3,0,.6006.02.88.04v1.961A2.1069,2.1069,0,0,0,1285.834,663.392Z" />
                                        <path className="st18" d="M1290.7529,670.974v1.6807a2.0072,2.0072,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7527.7527,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8008-1.04-3.08-1.96a6.5515,6.5515,0,0,1-.18-1.3408v-4.32a3.806,3.806,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1303.1738,674.7347a3.324,3.324,0,0,1-2.6611,1.04,3.4409,3.4409,0,0,1-2.7207-.96,3.5823,3.5823,0,0,1-.42-.7207,3.5366,3.5366,0,0,1-.2-1.3v-4.541a2.975,2.975,0,0,1,.5605-1.9609,3.5889,3.5889,0,0,1,2.78-1.16c.24,0,2.3809,0,3.041,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5408,2.5408,0,0,0-.04-.5.8337.8337,0,0,0-.86-.64,1.0686,1.0686,0,0,0-.56.14c-.32.22-.42.54-.42,1.24v4.4609a2.1418,2.1418,0,0,0,.06.7.8612.8612,0,0,0,.88.64,1.1648,1.1648,0,0,0,.52-.14,1.3557,1.3557,0,0,0,.4-1.1807v-1.18h2.36v1.16A3.1829,3.1829,0,0,1,1303.1738,674.7347Z" />
                                        <path className="st18" d="M1309.2529,675.6752c-1.3808,0-2.1006-.3008-2.4-.961a2.6153,2.6153,0,0,1-.2-1.18v-6.621h-1.3007v-1.5811h1.28v-3.7207h2.3614v3.7207h1.42v1.5811h-1.42v5.8408a1.35,1.35,0,0,0,.06.5605c.14.48.6006.56,1.0205.56a2.638,2.638,0,0,0,.3995-.04v1.7207A5.0709,5.0709,0,0,1,1309.2529,675.6752Z" />
                                        <path className="st18" d="M1312.3916,663.8519v-2.4h2.4v2.4Zm0,11.7227V665.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1321.1523,675.5746h-2.32l-2.2607-10.2422h2.3808l1.0606,7.1016,1.22-7.1016h2.3809Z" />
                                        <path className="st18" d="M1327.6523,670.974v1.6807a1.9755,1.9755,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8441,2.8441,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1334.1914,675.5746V661.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1343.0723,676.3148a4.6407,4.6407,0,0,1-.8,2.0606,2.3188,2.3188,0,0,1-2.0606.74,8.118,8.118,0,0,1-1.54-.18l.3593-1.8808a3.2136,3.2136,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.0205-.28-2.6611-10.2422h2.3613l1.32,6.582,1.02-6.582h2.3408Z" />
                                        <path className="st18" d="M1232.0439,697.5746c-.2-.22-.2392-.3994-.28-.9-.1192.12-.22.2607-.3594.3809a2.2553,2.2553,0,0,1-1.7012.74,2.0953,2.0953,0,0,1-1.24-.3809c-.84-.62-.7793-1.84-.7793-2.74a2.9744,2.9744,0,0,1,.4795-2.1006,3.21,3.21,0,0,1,1.081-.76l2.4805-1.24v-.6006c0-.6-.0605-.9-.32-1.08a.9616.9616,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.9594,2.9594,0,0,0-.08.9609v.38h-2.1807v-1.16a2.0537,2.0537,0,0,1,.58-1.461,3.6477,3.6477,0,0,1,2.581-.76c.3,0,2.08-.02,2.8,1.28a1.8732,1.8732,0,0,1,.24.98v5.7012a5.0667,5.0667,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3008.2792-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2784,1.2784,0,0,0,.8594-.36l.2607-.22Z" />
                                        <path className="st18" d="M1242.124,696.8549a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9-1.42a3.571,3.571,0,0,1-.2207-1.32v-1.06h2.2012v.8a2.4425,2.4425,0,0,0,.06.6406.7968.7968,0,0,0,.86.66.9624.9624,0,0,0,.5391-.14,1.1016,1.1016,0,0,0,.4-.98,1.2472,1.2472,0,0,0-.6993-1.32l-2.0009-1.4209a2.5251,2.5251,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1-2.6211,1.501,0,2.32.52,2.6407,1.1006a2.3034,2.3034,0,0,1,.2812,1.24v1.1406h-2.0605v-.74c0-.16.039-1.12-.8809-1.12a.7786.7786,0,0,0-.82.92c0,.6.2207.8.68,1.12l2.2012,1.54a3.6851,3.6851,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,1242.124,696.8549Z" />
                                        <path className="st18" d="M1256.9238,696.7943a1.8988,1.8988,0,0,1-.4394.54,2.0194,2.0194,0,0,1-1.32.44,3.1317,3.1317,0,0,1-2.2207-1.0606v4.4014h-2.3809V687.3324h2.3809v.78a3.2659,3.2659,0,0,1,2.1406-.9805,2.18,2.18,0,0,1,1.7.82,3.0553,3.0553,0,0,1,.4795,1.8809v5.4414A3.5089,3.5089,0,0,1,1256.9238,696.7943Zm-2.0605-6.7607c0-.58-.14-.8008-.42-.9805a1.0909,1.0909,0,0,0-.52-.14,1.0365,1.0365,0,0,0-.541.14,1.0525,1.0525,0,0,0-.4394,1.04v4.6006a1.4364,1.4364,0,0,0,.08.5811.9347.9347,0,0,0,.9.56.8594.8594,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M1265.4844,696.7347a3.4133,3.4133,0,0,1-2.7207,1.04,4.8538,4.8538,0,0,1-1.0205-.1,2.4976,2.4976,0,0,1-1.9-1.6211,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9851,2.9851,0,0,1,3.041-1.6611c.3194,0,2.28-.0195,2.9209,1.501a2.9463,2.9463,0,0,1,.24,1.36v4.94A2.4547,2.4547,0,0,1,1265.4844,696.7347Zm-1.7813-6.8818c0-.64-.0595-.92-.38-1.12a.728.728,0,0,0-.44-.12.7443.7443,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M1273.9844,696.8549a3.0722,3.0722,0,0,1-2.6211.92c-1.1406,0-2.4-.22-2.9-1.42a3.571,3.571,0,0,1-.2207-1.32v-1.06h2.2012v.8a2.3951,2.3951,0,0,0,.06.6406.7958.7958,0,0,0,.8594.66.9667.9667,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2-1.4209a2.5242,2.5242,0,0,1-1.2207-2.46c.0195-2.041,1.26-2.6211,3.1006-2.6211,1.501,0,2.32.52,2.6406,1.1006a2.3016,2.3016,0,0,1,.28,1.24v1.1406h-2.06v-.74c0-.16.04-1.12-.8809-1.12a.7786.7786,0,0,0-.82.92c0,.6.2207.8.6806,1.12l2.2,1.54a3.6491,3.6491,0,0,1,.6211.62,2.7269,2.7269,0,0,1,.3789,1.6611A3.31,3.31,0,0,1,1273.9844,696.8549Z" />
                                        <path className="st18" d="M1282.3633,696.8549a3.07,3.07,0,0,1-2.62.92c-1.14,0-2.4-.22-2.9-1.42a3.5843,3.5843,0,0,1-.22-1.32v-1.06h2.2v.8a2.4917,2.4917,0,0,0,.06.6406.7984.7984,0,0,0,.86.66.9652.9652,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2478,1.2478,0,0,0-.7-1.32l-2.001-1.4209a2.5268,2.5268,0,0,1-1.22-2.46c.0205-2.041,1.2607-2.6211,3.1006-2.6211,1.5,0,2.32.52,2.6406,1.1006a2.3025,2.3025,0,0,1,.28,1.24v1.1406h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.22.8.68,1.12l2.2012,1.54a3.6773,3.6773,0,0,1,.6191.62,2.7247,2.7247,0,0,1,.3809,1.6611A3.3006,3.3006,0,0,1,1282.3633,696.8549Z" />
                                        <path className="st18" d="M1285.3428,685.8519v-2.4h2.4v2.4Zm0,11.7227V687.3324h2.4v10.2422Z" />
                                        <path className="st18" d="M1296.9238,696.3744a2.1418,2.1418,0,0,1-2.0605,1.4209,2.5412,2.5412,0,0,1-1.72-.7,2.2189,2.2189,0,0,1-.36-.4609l-.0205.94h-2.38V683.4515h2.38v4.6817c.1406-.12.2607-.24.4-.3408a3.3034,3.3034,0,0,1,1.74-.66,2.1514,2.1514,0,0,1,2.001,1.36,2.6279,2.6279,0,0,1,.18,1.02v5.8017A2.9052,2.9052,0,0,1,1296.9238,696.3744Zm-2.2607-6.2608a1.1631,1.1631,0,0,0-.2793-.9209.8168.8168,0,0,0-.62-.24.906.906,0,0,0-.94.62,2.0079,2.0079,0,0,0-.08.7v4.1416c0,.08,0,.26.02.46.08.6.3408.9.98.9a.7731.7731,0,0,0,.66-.28,1.5637,1.5637,0,0,0,.26-1.08Z" />
                                        <path className="st18" d="M1299.6016,697.5746V683.4515h2.4v14.1231Z" />
                                        <path className="st18" d="M1306.9023,692.974v1.6807a1.9755,1.9755,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7526.7526,0,0,0,.7608-.5605,4.9918,4.9918,0,0,0,.08-1.16h2.3v.82a2.8441,2.8441,0,0,1-.1.9209c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M1319.8828,689.0326l-1.36,1.9805a3.7756,3.7756,0,0,0-.82,1.6211,4.3286,4.3286,0,0,0-.039.7v.4394h-2.2608v-.7a5.5964,5.5964,0,0,1,.02-.66,3.1517,3.1517,0,0,1,.66-1.6l1.32-2.041a2.4029,2.4029,0,0,0,.5606-1.6406v-1.26a.8866.8866,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.3409h-2.2207v-1.4805a10.1749,10.1749,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2412-2.2207a3.8772,3.8772,0,0,1,2.3008.6,1.9441,1.9441,0,0,1,.6.7207,4.094,4.094,0,0,1,.26,1.7607v.8A3.7608,3.7608,0,0,1,1319.8828,689.0326Zm-4.4805,8.542V694.974h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M713.6831,448.8744h-2.74l-1.36-9.6416-1.52,9.6416h-2.78l-2.5805-14.1226h2.7l1.42,10.6421,1.52-10.6421h2.46l1.4805,10.6421,1.48-10.6421h2.6406Z" />
                                        <path className="st18" d="M722.582,448.8744v-7.2813a2.06,2.06,0,0,0-.08-.8.8564.8564,0,0,0-.8-.46.8888.8888,0,0,0-.9.7,1.4279,1.4279,0,0,0-.04.42v7.4209h-2.38V434.7518h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0242,2.0242,0,0,1,2,1.4,2.938,2.938,0,0,1,.14,1.0205v8.021Z" />
                                        <path className="st18" d="M731.7217,448.8744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2534,2.2534,0,0,1-1.7.74,2.0929,2.0929,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2021,3.2021,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1607-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M739.5615,448.975c-1.38,0-2.1006-.3008-2.4009-.961a2.6284,2.6284,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28V434.912h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6485,2.6485,0,0,0,.4-.04v1.7207A5.0776,5.0776,0,0,1,739.5615,448.975Z" />
                                        <path className="st18" d="M753.5615,448.1547a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5768,3.5768,0,0,1-.22-1.32v-1.06h2.2007v.8a2.4357,2.4357,0,0,0,.06.64.797.797,0,0,0,.86.66.9663.9663,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2482,1.2482,0,0,0-.7-1.32l-2-1.4205a2.526,2.526,0,0,1-1.22-2.46c.02-2.04,1.26-2.6206,3.1006-2.6206,1.5,0,2.32.52,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.779.779,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6484,3.6484,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3049,3.3049,0,0,1,753.5615,448.1547Z" />
                                        <path className="st18" d="M760.7412,448.8744v-7.2813a2.0572,2.0572,0,0,0-.08-.8.8562.8562,0,0,0-.8-.46.889.889,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42v7.4209h-2.38V434.7518h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0241,2.0241,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.0205v8.021Z" />
                                        <path className="st18" d="M771.4614,448.0345a3.4123,3.4123,0,0,1-2.7207,1.04,4.863,4.863,0,0,1-1.02-.1,2.4992,2.4992,0,0,1-1.9-1.6211,3.7238,3.7238,0,0,1-.18-1.3794v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.983,2.983,0,0,1,3.04-1.6606c.32,0,2.2812-.02,2.9209,1.5a2.95,2.95,0,0,1,.24,1.36v4.941A2.4542,2.4542,0,0,1,771.4614,448.0345Zm-1.78-6.8813c0-.64-.0606-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.7533.7533,0,0,0-.48.14c-.38.28-.3594.7-.3594,1.12v5.2012c0,.18-.02,1.06.8193,1.06.9,0,.8409-.92.8409-1.08Z" />
                                        <path className="st18" d="M778.7417,448.8744v-.84a3.2419,3.2419,0,0,1-2.1807,1.0206,2.2511,2.2511,0,0,1-1.2-.36,2.5156,2.5156,0,0,1-.8007-2.34v-7.7217h2.4v7.3613c0,.36-.04.72.2208.98a.8716.8716,0,0,0,.64.2607,1.0324,1.0324,0,0,0,.6006-.2c.3994-.3408.32-1.1.32-1.16v-7.2417h2.4v10.2417Z" />
                                        <path className="st18" d="M783.78,448.8744V434.7518h2.4v14.1226Z" />
                                        <path className="st18" d="M792.981,448.8744l.02-1a2.27,2.27,0,0,1-2.001,1.2,2.4506,2.4506,0,0,1-1.42-.44c-.7608-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3569,2.3569,0,0,1,1.1806-.34,2.9936,2.9936,0,0,1,2.0606,1.04v-4.7012h2.38v14.1226Zm.02-7.4409c0-.46-.02-.78-.44-1.02a1.1954,1.1954,0,0,0-.58-.16.9322.9322,0,0,0-.5206.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6.0595.8194.34,1a1.1239,1.1239,0,0,0,.58.16.97.97,0,0,0,.82-.4,1.304,1.304,0,0,0,.1807-.84Z" />
                                        <path className="st18" d="M696.8013,466.2738v1.6807a1.9944,1.9944,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0406,1.84-2.1005,0-2.8007-1.04-3.0805-1.96a6.5921,6.5921,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M707.4614,470.8744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2538,2.2538,0,0,1-1.7.74,2.0926,2.0926,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2064,3.2064,0,0,1,1.0805-.76l2.4805-1.24v-.6006c0-.6-.06-.9-.32-1.08a.96.96,0,0,0-.52-.14.7768.7768,0,0,0-.74.3994,2.9633,2.9633,0,0,0-.08.961v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.461,3.6463,3.6463,0,0,1,2.5805-.76c.3,0,2.08-.0205,2.8,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0678,5.0678,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2794,1.2794,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M718.0215,470.0345a3.3229,3.3229,0,0,1-2.66,1.04,3.44,3.44,0,0,1-2.7207-.96,2.8806,2.8806,0,0,1-.62-2.02v-4.541a2.9741,2.9741,0,0,1,.56-1.9609,3.5889,3.5889,0,0,1,2.7807-1.16c.24,0,2.38,0,3.0406,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3409v-1.24a2.5915,2.5915,0,0,0-.04-.5.8337.8337,0,0,0-.86-.64,1.0713,1.0713,0,0,0-.5605.14c-.32.22-.42.54-.42,1.24v4.4609a2.1278,2.1278,0,0,0,.06.7.8614.8614,0,0,0,.88.64,1.1646,1.1646,0,0,0,.52-.14,1.3551,1.3551,0,0,0,.4-1.1807v-1.18h2.3609v1.16A3.1841,3.1841,0,0,1,718.0215,470.0345Z" />
                                        <path className="st18" d="M725.1416,470.8744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8559.8559,0,0,0-.8-.4609.889.889,0,0,0-.9.7,1.4331,1.4331,0,0,0-.04.4209v7.4209h-2.38V456.7513h2.34l.02,4.7012a2.72,2.72,0,0,1,2.0805-1.02,2.0247,2.0247,0,0,1,2,1.4,2.935,2.935,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M735.74,470.8744V456.7513h6.8418v2.1407h-4.2412v3.3213h4.001v2.14h-4.001v4.3408h4.4409v2.1807Z" />
                                        <path className="st18" d="M753.2021,470.8744v-7.5811a1.12,1.12,0,0,0-.36-1.041.9358.9358,0,0,0-.5-.14c-.8,0-.9605.66-.9605,1.3008v7.4609h-2.36v-7.5215c0-.42.04-.8-.32-1.1006a.7919.7919,0,0,0-.5606-.18c-.98,0-.92,1.04-.92,1.2207v7.5811h-2.36V460.6322h2.38v.6807a2.9166,2.9166,0,0,1,2.0205-.9,2.4414,2.4414,0,0,1,1.92,1.02,3.1376,3.1376,0,0,1,1.68-.94,3.2015,3.2015,0,0,1,.66-.0606,1.9276,1.9276,0,0,1,1.96,1.4,2.7073,2.7073,0,0,1,.1.9209v8.1211Z" />
                                        <path className="st18" d="M764.5815,470.0941a1.91,1.91,0,0,1-.44.54,2.016,2.016,0,0,1-1.32.44,3.1326,3.1326,0,0,1-2.22-1.0606v4.4014h-2.38V460.6322h2.38v.78a3.2659,3.2659,0,0,1,2.1406-.9805,2.18,2.18,0,0,1,1.7.82,3.0559,3.0559,0,0,1,.48,1.8809v5.4414A3.515,3.515,0,0,1,764.5815,470.0941Zm-2.0605-6.7607c0-.58-.14-.8008-.42-.9805a1.0939,1.0939,0,0,0-.52-.14,1.0333,1.0333,0,0,0-.54.14,1.0519,1.0519,0,0,0-.44,1.04v4.6006a1.4278,1.4278,0,0,0,.08.5811.9347.9347,0,0,0,.9.56.86.86,0,0,0,.84-.4805,1.6072,1.6072,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M767.44,470.8744V456.7513h2.4v14.1231Z" />
                                        <path className="st18" d="M778.18,470.0345a3.4113,3.4113,0,0,1-2.7207,1.04,4.8515,4.8515,0,0,1-1.02-.1,2.4977,2.4977,0,0,1-1.9-1.6211,3.7205,3.7205,0,0,1-.1807-1.38v-4.5811a3.1632,3.1632,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6611c.32,0,2.28-.0195,2.9209,1.501a2.9615,2.9615,0,0,1,.2393,1.36v4.94A2.4509,2.4509,0,0,1,778.18,470.0345Zm-1.78-6.8818c0-.64-.06-.92-.38-1.12a.728.728,0,0,0-.44-.12.7472.7472,0,0,0-.48.1406c-.38.2793-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M785.1206,471.6146a4.64,4.64,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.06.74,8.1181,8.1181,0,0,1-1.54-.18l.3594-1.8808a3.2121,3.2121,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6612-10.2422h2.3614l1.32,6.582,1.0195-6.582h2.3408Z" />
                                        <path className="st18" d="M791.5,466.2738v1.6807a1.9755,1.9755,0,0,0,.0606.68.81.81,0,0,0,.8193.58.7525.7525,0,0,0,.7607-.56,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5718,6.5718,0,0,1-.1806-1.3408v-4.32a3.8127,3.8127,0,0,1,.2607-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8286,2.8286,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M800.3,466.2738v1.6807a1.9786,1.9786,0,0,0,.0605.68.81.81,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.574,6.574,0,0,1-.1807-1.3408v-4.32a3.8113,3.8113,0,0,1,.2608-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M685.4224,492.8744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.36.3809a2.2534,2.2534,0,0,1-1.7.74,2.0927,2.0927,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.9772,2.9772,0,0,1,.48-2.1006,3.2078,3.2078,0,0,1,1.0806-.76l2.48-1.24v-.6006c0-.6-.06-.9-.32-1.08a.9593.9593,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.18v-1.16a2.05,2.05,0,0,1,.58-1.461,3.6466,3.6466,0,0,1,2.5806-.76c.3,0,2.08-.0205,2.8,1.28a1.8734,1.8734,0,0,1,.24.9805v5.7012a5.0665,5.0665,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.18.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M695.9824,492.0345a3.3227,3.3227,0,0,1-2.66,1.04,3.4393,3.4393,0,0,1-2.7207-.96,2.8812,2.8812,0,0,1-.62-2.02v-4.541a2.9741,2.9741,0,0,1,.56-1.9609,3.5892,3.5892,0,0,1,2.7808-1.16c.24,0,2.38,0,3.04,1.541a3.2793,3.2793,0,0,1,.2,1.32v1.22h-2.3408v-1.24a2.5894,2.5894,0,0,0-.04-.5.8336.8336,0,0,0-.86-.64,1.0715,1.0715,0,0,0-.5606.14c-.32.22-.42.54-.42,1.24v4.4609a2.1244,2.1244,0,0,0,.06.7.8613.8613,0,0,0,.88.64,1.164,1.164,0,0,0,.52-.14,1.3551,1.3551,0,0,0,.4-1.1807v-1.18h2.3609v1.16A3.1841,3.1841,0,0,1,695.9824,492.0345Z" />
                                        <path className="st18" d="M703.1021,492.8744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8561.8561,0,0,0-.8-.4609.8888.8888,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209v7.4209h-2.38V478.7513h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0248,2.0248,0,0,1,2,1.4,2.9356,2.9356,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M708.1216,481.1517v-2.4h2.4v2.4Zm0,11.7227V482.6322h2.4v10.2422Z" />
                                        <path className="st18" d="M715.4214,488.2738v1.6807a1.9944,1.9944,0,0,0,.06.68.8112.8112,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9888,4.9888,0,0,0,.08-1.16h2.3008v.82a2.8332,2.8332,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.04-3.0806-1.96a6.6,6.6,0,0,1-.18-1.3408v-4.32a3.8149,3.8149,0,0,1,.26-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M725.6821,492.8744h-2.3208l-2.26-10.2422h2.38l1.0606,7.1016,1.22-7.1016h2.38Z" />
                                        <path className="st18" d="M732.1812,488.2738v1.6807a1.9944,1.9944,0,0,0,.06.68.8111.8111,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9888,4.9888,0,0,0,.08-1.16h2.3007v.82a2.8359,2.8359,0,0,1-.1.9209c-.4,1.44-1.68,1.84-3.0406,1.84-2.1006,0-2.8008-1.04-3.0805-1.96a6.5921,6.5921,0,0,1-.18-1.3408v-4.32a3.8164,3.8164,0,0,1,.26-1.3809,3.5864,3.5864,0,0,1,5.9014-.18,2.8205,2.8205,0,0,1,.28,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8713.8713,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M747.3413,492.975c-1.38,0-2.1006-.3008-2.4009-.961a2.6271,2.6271,0,0,1-.2-1.18v-6.6211h-1.3v-1.5811h1.28v-3.7207h2.36v3.7207h1.42v1.5811h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.6485,2.6485,0,0,0,.4-.04v1.7207A5.0776,5.0776,0,0,1,747.3413,492.975Z" />
                                        <path className="st18" d="M754.6812,492.8744v-7.2813a2.0561,2.0561,0,0,0-.08-.8.8561.8561,0,0,0-.8-.4609.8888.8888,0,0,0-.9.7,1.43,1.43,0,0,0-.04.4209v7.4209h-2.38V478.7513h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0248,2.0248,0,0,1,2.0005,1.4,2.9356,2.9356,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M759.7007,481.1517v-2.4h2.4v2.4Zm0,11.7227V482.6322h2.4v10.2422Z" />
                                        <path className="st18" d="M770.1411,492.1547a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4009-.22-2.9009-1.42a3.5782,3.5782,0,0,1-.22-1.32v-1.0606h2.2v.8a2.438,2.438,0,0,0,.0605.6406.7978.7978,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2465,1.2465,0,0,0-.7-1.32l-2-1.4209a2.5255,2.5255,0,0,1-1.22-2.46c.02-2.041,1.26-2.6211,3.1011-2.6211,1.5,0,2.32.52,2.64,1.1006a2.3024,2.3024,0,0,1,.28,1.24v1.1407h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7778.7778,0,0,0-.82.92c0,.6.2207.8.6807,1.12l2.2,1.54a3.6522,3.6522,0,0,1,.62.62,2.7265,2.7265,0,0,1,.38,1.6611A3.3049,3.3049,0,0,1,770.1411,492.1547Z" />
                                        <path className="st18" d="M782.42,493.6146a4.64,4.64,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.06.74,8.1181,8.1181,0,0,1-1.54-.18l.3594-1.8808a3.2129,3.2129,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6612-10.2422h2.3614l1.32,6.582,1.0195-6.582h2.3408Z" />
                                        <path className="st18" d="M788.8,488.2738v1.6807a1.9786,1.9786,0,0,0,.0605.68.81.81,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9925,4.9925,0,0,0,.08-1.16h2.3v.82a2.8468,2.8468,0,0,1-.1.9209c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.574,6.574,0,0,1-.1807-1.3408v-4.32a3.8113,3.8113,0,0,1,.2608-1.3809,3.5863,3.5863,0,0,1,5.9013-.18,2.8276,2.8276,0,0,1,.2793,1.46v2.9209Zm1.7-2.94c0-.2207.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M799.46,492.8744c-.2-.22-.24-.3994-.28-.9-.12.12-.22.2607-.3594.3809a2.257,2.257,0,0,1-1.7012.74,2.0953,2.0953,0,0,1-1.24-.3809c-.84-.62-.78-1.84-.78-2.74a2.98,2.98,0,0,1,.4805-2.1006,3.2156,3.2156,0,0,1,1.08-.76l2.48-1.24v-.6006c0-.6-.0595-.9-.3193-1.08a.9642.9642,0,0,0-.52-.14.7766.7766,0,0,0-.74.3994,2.96,2.96,0,0,0-.08.961v.38h-2.1807v-1.16a2.05,2.05,0,0,1,.58-1.461,3.65,3.65,0,0,1,2.581-.76c.3,0,2.08-.0205,2.8008,1.28a1.8727,1.8727,0,0,1,.24.9805v5.7012a5.0662,5.0662,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.7607c-.08.06-.1806.14-.2.16-.3.2793-.34.68-.34,1.4794,0,.7608.1,1.2.58,1.1807a1.2823,1.2823,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M808.8208,484.8129a1.6919,1.6919,0,0,0-.78-.16,1.6059,1.6059,0,0,0-.9.24,2.13,2.13,0,0,0-.6406,1.94v6.041h-2.36V482.6322H806.52l-.02,1.3008a4.12,4.12,0,0,1,.36-.6006,2.2166,2.2166,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M817.3,484.3324l-1.36,1.9805a3.7763,3.7763,0,0,0-.82,1.6211,4.3292,4.3292,0,0,0-.039.7v.4394H812.82v-.7a5.5964,5.5964,0,0,1,.02-.66,3.1508,3.1508,0,0,1,.66-1.6l1.32-2.041a2.4034,2.4034,0,0,0,.5606-1.6406v-1.26a.8866.8866,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.3408h-2.2207v-1.4805a10.1757,10.1757,0,0,1,.08-1.34c.2-1.1807.86-2.2207,3.2412-2.2207a3.8772,3.8772,0,0,1,2.3008.6,1.9441,1.9441,0,0,1,.6.7207,4.0946,4.0946,0,0,1,.26,1.7607v.8A3.7618,3.7618,0,0,1,817.3,484.3324Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M426.2656,460.7152h-2.74l-1.36-9.6426-1.5205,9.6426h-2.78l-2.5806-14.123h2.7l1.42,10.6416,1.5205-10.6416h2.46l1.48,10.6416,1.48-10.6416h2.6406Z" />
                                        <path className="st18" d="M435.1646,460.7152V453.433a2.0558,2.0558,0,0,0-.08-.8.856.856,0,0,0-.8-.461.8886.8886,0,0,0-.9.7012,1.4265,1.4265,0,0,0-.04.42v7.4219h-2.38v-14.123h2.34l.02,4.7011a2.718,2.718,0,0,1,2.0806-1.0214,2.0247,2.0247,0,0,1,2,1.4,2.9385,2.9385,0,0,1,.14,1.0215v8.0215Z" />
                                        <path className="st18" d="M440.1836,448.9926v-2.4h2.4v2.4Zm0,11.7226V450.473h2.4v10.2422Z" />
                                        <path className="st18" d="M451.104,459.8744a3.32,3.32,0,0,1-2.66,1.04,3.44,3.44,0,0,1-2.7207-.96,2.8759,2.8759,0,0,1-.62-2.02v-4.5411a2.9759,2.9759,0,0,1,.56-1.9609,3.5892,3.5892,0,0,1,2.7807-1.16c.24,0,2.38,0,3.0406,1.541a3.2825,3.2825,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5737,2.5737,0,0,0-.04-.5.8343.8343,0,0,0-.86-.6406,1.07,1.07,0,0,0-.56.14c-.32.22-.42.54-.42,1.2412v4.4609a2.1239,2.1239,0,0,0,.06.6992.8623.8623,0,0,0,.88.6407,1.1688,1.1688,0,0,0,.52-.1407,1.353,1.353,0,0,0,.4-1.18v-1.1807h2.3609v1.1611A3.1832,3.1832,0,0,1,451.104,459.8744Z" />
                                        <path className="st18" d="M458.2241,460.7152V453.433a2.0558,2.0558,0,0,0-.08-.8.8558.8558,0,0,0-.8-.461.8888.8888,0,0,0-.9.7012,1.43,1.43,0,0,0-.04.42v7.4219h-2.38v-14.123h2.34l.02,4.7011a2.7178,2.7178,0,0,1,2.0806-1.0214,2.0245,2.0245,0,0,1,2,1.4,2.9379,2.9379,0,0,1,.14,1.0215v8.0215Z" />
                                        <path className="st18" d="M474.4043,460.7152c-.04-.08-.08-.1406-.12-.2207a2.5622,2.5622,0,0,1-.26-1.1406v-3.2207c0-.2188,0-.44-.02-.68-.08-.78-.66-.96-1.34-.96h-1.24v6.2217h-2.6005v-14.123h5.2011a2.6456,2.6456,0,0,1,1.7207.4589,2.7967,2.7967,0,0,1,.82,2.5215v1.52a3.5449,3.5449,0,0,1-.28,1.5214,1.6883,1.6883,0,0,1-.3.379,2.6,2.6,0,0,1-1.24.5,2.8624,2.8624,0,0,1,.78.2812,2.34,2.34,0,0,1,1.14,2.18v2.8809a3.2167,3.2167,0,0,0,.36,1.66.862.862,0,0,0,.24.2207Zm-.42-10.9433c0-.5391-.08-.72-.3-.94a1.2035,1.2035,0,0,0-.9-.2207h-1.36v3.8613h1.36a1.1951,1.1951,0,0,0,.9805-.34c.2-.24.22-.44.22-1Z" />
                                        <path className="st18" d="M481.4229,456.1136v1.6807a2.0006,2.0006,0,0,0,.06.6807.8124.8124,0,0,0,.82.58.7539.7539,0,0,0,.76-.5606,4.9947,4.9947,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.4394-1.68,1.84-3.0406,1.84-2.1006,0-2.8008-1.04-3.0805-1.96a6.5846,6.5846,0,0,1-.18-1.3409v-4.32a3.8192,3.8192,0,0,1,.26-1.3808,3.5864,3.5864,0,0,1,5.9014-.18,2.8221,2.8221,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8714.8714,0,0,0-.88,1.0205v1.2608h1.7Z" />
                                        <path className="st18" d="M493.3633,459.9945a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5752,3.5752,0,0,1-.22-1.32v-1.0606h2.2007v.8008a2.44,2.44,0,0,0,.06.64.7972.7972,0,0,0,.86.66.9664.9664,0,0,0,.54-.14,1.1039,1.1039,0,0,0,.4-.98,1.2479,1.2479,0,0,0-.7-1.32l-2-1.42a2.528,2.528,0,0,1-1.22-2.461c.02-2.041,1.26-2.621,3.1006-2.621,1.5,0,2.32.5214,2.6406,1.1015a2.2976,2.2976,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7783.7783,0,0,0-.82.92c0,.6.22.8007.68,1.1211l2.2007,1.539a3.6342,3.6342,0,0,1,.62.6211,2.7205,2.7205,0,0,1,.38,1.66A3.305,3.305,0,0,1,493.3633,459.9945Z" />
                                        <path className="st18" d="M502.0439,459.8744a3.4088,3.4088,0,0,1-2.7207,1.04,4.8561,4.8561,0,0,1-1.02-.1,2.4971,2.4971,0,0,1-1.9-1.6211,3.7222,3.7222,0,0,1-.18-1.3789v-4.582a3.1645,3.1645,0,0,1,.2-1.3,2.9831,2.9831,0,0,1,3.04-1.6611c.32,0,2.2812-.02,2.9209,1.5009a2.9537,2.9537,0,0,1,.24,1.36v4.9414A2.4527,2.4527,0,0,1,502.0439,459.8744Zm-1.78-6.8818c0-.64-.0606-.92-.38-1.1192a.7237.7237,0,0,0-.44-.1211.7507.7507,0,0,0-.4805.1406c-.38.2793-.3594.7-.3594,1.12v5.2011c0,.1807-.02,1.06.8194,1.06.9,0,.8408-.92.8408-1.08Z" />
                                        <path className="st18" d="M509.3232,460.7152v-.8408a3.2423,3.2423,0,0,1-2.1806,1.0205,2.25,2.25,0,0,1-1.2-.36,2.5152,2.5152,0,0,1-.8008-2.3408V450.473h2.4v7.3614a1.2705,1.2705,0,0,0,.2207.98.8748.8748,0,0,0,.64.26,1.0249,1.0249,0,0,0,.6006-.2c.3994-.3408.32-1.1006.32-1.16V450.473h2.4v10.2422Z" />
                                        <path className="st18" d="M519.0439,452.6527a1.6913,1.6913,0,0,0-.78-.16,1.6055,1.6055,0,0,0-.9.24,2.1308,2.1308,0,0,0-.6406,1.94v6.042h-2.36V450.473h2.3809l-.02,1.3a4.1074,4.1074,0,0,1,.36-.6006,2.217,2.217,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M526.9639,459.8744a3.3208,3.3208,0,0,1-2.6612,1.04,3.4412,3.4412,0,0,1-2.7207-.96,3.6,3.6,0,0,1-.42-.72,3.5405,3.5405,0,0,1-.2-1.3007v-4.5411a2.9763,2.9763,0,0,1,.5606-1.9609,3.5887,3.5887,0,0,1,2.78-1.16c.24,0,2.3809,0,3.0411,1.541a3.2829,3.2829,0,0,1,.2,1.32v1.2207h-2.3408v-1.24a2.5357,2.5357,0,0,0-.04-.5.8346.8346,0,0,0-.86-.6406,1.0671,1.0671,0,0,0-.5595.14c-.32.22-.42.54-.42,1.2412v4.4609a2.1376,2.1376,0,0,0,.06.6992.8621.8621,0,0,0,.88.6407,1.1692,1.1692,0,0,0,.52-.1407,1.3533,1.3533,0,0,0,.4-1.18v-1.1807h2.36v1.1611A3.1817,3.1817,0,0,1,526.9639,459.8744Z" />
                                        <path className="st18" d="M532.1426,456.1136v1.6807a2.0167,2.0167,0,0,0,.0595.6807.8131.8131,0,0,0,.82.58.7536.7536,0,0,0,.7607-.5606,4.9985,4.9985,0,0,0,.08-1.16h2.3v.82a2.8413,2.8413,0,0,1-.1.92c-.4,1.4394-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.04-3.08-1.96a6.5665,6.5665,0,0,1-.1807-1.3409v-4.32a3.8141,3.8141,0,0,1,.2608-1.3808,3.5863,3.5863,0,0,1,5.9013-.18,2.8291,2.8291,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8716.8716,0,0,0-.88,1.0205v1.2608h1.7Z" />
                                        <path className="st18" d="M544.083,459.9945a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5826,3.5826,0,0,1-.22-1.32v-1.0606h2.2v.8008a2.4348,2.4348,0,0,0,.06.64.7978.7978,0,0,0,.86.66.9672.9672,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.3994-.98,1.2479,1.2479,0,0,0-.7-1.32l-2-1.42a2.5284,2.5284,0,0,1-1.2207-2.461c.0206-2.041,1.2608-2.621,3.1016-2.621,1.5,0,2.32.5214,2.64,1.1015a2.2984,2.2984,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7779.7779,0,0,0-.82.92c0,.6.2207.8007.6807,1.1211l2.2,1.539a3.62,3.62,0,0,1,.62.6211,2.7213,2.7213,0,0,1,.38,1.66A3.305,3.305,0,0,1,544.083,459.9945Z" />
                                        <path className="st18" d="M434.9243,481.9945a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5752,3.5752,0,0,1-.22-1.32v-1.0606h2.2007v.8008a2.44,2.44,0,0,0,.06.64.7972.7972,0,0,0,.86.66.9668.9668,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.4-.98,1.2479,1.2479,0,0,0-.7-1.32l-2-1.42a2.5279,2.5279,0,0,1-1.22-2.461c.02-2.041,1.26-2.621,3.1006-2.621,1.5,0,2.32.5214,2.6407,1.1015a2.2984,2.2984,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7784.7784,0,0,0-.82.92c0,.6.22.8007.68,1.1211l2.2007,1.539a3.6378,3.6378,0,0,1,.62.6211,2.7212,2.7212,0,0,1,.38,1.66A3.3045,3.3045,0,0,1,434.9243,481.9945Z" />
                                        <path className="st18" d="M442.104,482.7152V475.433a2.0558,2.0558,0,0,0-.08-.8.8558.8558,0,0,0-.8-.461.8888.8888,0,0,0-.9.7012,1.43,1.43,0,0,0-.04.42v7.4219h-2.38v-14.123h2.34l.02,4.7011a2.7177,2.7177,0,0,1,2.0805-1.0214,2.0246,2.0246,0,0,1,2,1.4,2.9379,2.9379,0,0,1,.14,1.0215v8.0215Z" />
                                        <path className="st18" d="M451.2437,482.7152c-.2-.2207-.24-.4-.28-.9-.12.1192-.22.26-.36.38a2.253,2.253,0,0,1-1.7.74,2.0976,2.0976,0,0,1-1.24-.38c-.84-.6211-.78-1.8409-.78-2.74a2.98,2.98,0,0,1,.48-2.1015,3.2064,3.2064,0,0,1,1.0806-.76l2.48-1.24v-.6c0-.6005-.06-.9-.32-1.08a.9483.9483,0,0,0-.52-.1407.776.776,0,0,0-.74.4,2.95,2.95,0,0,0-.08.96v.38h-2.18v-1.16a2.0491,2.0491,0,0,1,.58-1.4609,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.0805-.0195,2.8,1.28a1.8742,1.8742,0,0,1,.24.9805v5.7011a5.0665,5.0665,0,0,0,.3,2.32c.02.06.06.1006.16.1807Zm-.34-5.1621-1.16.7607c-.08.06-.18.14-.2.16-.3.28-.34.6807-.34,1.48,0,.7607.1,1.2012.58,1.1807a1.28,1.28,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M455.9233,482.7152v-14.123h2.4v14.123Z" />
                                        <path className="st18" d="M460.9629,482.7152v-14.123h2.4v14.123Z" />
                                        <path className="st18" d="M479.4443,482.7152h-2.24l-1-6.082-1.2,6.082h-2.26l-2.0806-10.2422h2.32l1.0806,6.7608,1.2-6.7608h1.94l1.08,6.7608,1.16-6.7608h2.3208Z" />
                                        <path className="st18" d="M485.8428,478.1136v1.6807a2.0006,2.0006,0,0,0,.06.6807.8124.8124,0,0,0,.82.58.7539.7539,0,0,0,.76-.5606,4.9947,4.9947,0,0,0,.08-1.16h2.3008v.82a2.8278,2.8278,0,0,1-.1.92c-.4,1.4394-1.68,1.84-3.0406,1.84-2.1005,0-2.8007-1.04-3.08-1.96a6.5846,6.5846,0,0,1-.18-1.3409v-4.32a3.8178,3.8178,0,0,1,.26-1.3808,3.5863,3.5863,0,0,1,5.9013-.18,2.8221,2.8221,0,0,1,.28,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8714.8714,0,0,0-.88,1.0205v1.2608h1.7Z" />
                                        <path className="st18" d="M502.0234,482.7152v-.8408a3.2423,3.2423,0,0,1-2.1806,1.0205,2.25,2.25,0,0,1-1.2-.36,2.5152,2.5152,0,0,1-.8008-2.3408V472.473h2.4v7.3614a1.27,1.27,0,0,0,.2207.98.8748.8748,0,0,0,.64.26,1.0249,1.0249,0,0,0,.6006-.2c.3994-.3408.32-1.1006.32-1.16V472.473h2.4v10.2422Z" />
                                        <path className="st18" d="M512.4629,481.9945a3.0711,3.0711,0,0,1-2.62.92c-1.1407,0-2.4-.22-2.9014-1.42a3.5826,3.5826,0,0,1-.22-1.32v-1.0606h2.2v.8008a2.4348,2.4348,0,0,0,.06.64.7978.7978,0,0,0,.86.66.9672.9672,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.3994-.98,1.2465,1.2465,0,0,0-.7-1.32l-2-1.42a2.5284,2.5284,0,0,1-1.2207-2.461c.02-2.041,1.2608-2.621,3.1016-2.621,1.5,0,2.32.5214,2.64,1.1015a2.2976,2.2976,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7779.7779,0,0,0-.82.92c0,.6.2207.8007.6806,1.1211l2.2,1.539a3.6164,3.6164,0,0,1,.62.6211,2.7205,2.7205,0,0,1,.38,1.66A3.305,3.305,0,0,1,512.4629,481.9945Z" />
                                        <path className="st18" d="M517.7021,478.1136v1.6807a1.9817,1.9817,0,0,0,.0606.6807.8114.8114,0,0,0,.8193.58.7537.7537,0,0,0,.7608-.5606,4.9985,4.9985,0,0,0,.08-1.16h2.3v.82a2.8386,2.8386,0,0,1-.1.92c-.4,1.4394-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5592,6.5592,0,0,1-.1807-1.3409v-4.32a3.8156,3.8156,0,0,1,.2607-1.3808,3.5864,3.5864,0,0,1,5.9014-.18,2.8291,2.8291,0,0,1,.2793,1.4609v2.92Zm1.7-2.94c0-.22.04-1.0205-.82-1.0205a.8716.8716,0,0,0-.88,1.0205v1.2608h1.7Z" />
                                        <path className="st18" d="M530.6826,474.1722l-1.36,1.9805a3.7744,3.7744,0,0,0-.82,1.6211,4.3305,4.3305,0,0,0-.0391.7v.44h-2.2608v-.7011a5.5983,5.5983,0,0,1,.02-.66,3.1558,3.1558,0,0,1,.66-1.6l1.32-2.041a2.4031,2.4031,0,0,0,.5606-1.6406v-1.26a.8867.8867,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.3408h-2.2207V471.973a10.18,10.18,0,0,1,.08-1.3408c.2-1.1807.86-2.2207,3.2412-2.2207a3.8731,3.8731,0,0,1,2.3008.6006,1.9394,1.9394,0,0,1,.6.72,4.0948,4.0948,0,0,1,.26,1.7608v.8A3.7575,3.7575,0,0,1,530.6826,474.1722Zm-4.4805,8.543v-2.6016h2.32v2.6016Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M159.105,310.9745v-6.4614h-2.3809v6.4614h-2.6006V296.8514h2.6006v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M170.165,310.1342a3.4121,3.4121,0,0,1-2.7207,1.04,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38V303.493a3.1681,3.1681,0,0,1,.2-1.3,2.9834,2.9834,0,0,1,3.0406-1.6606c.32,0,2.28-.02,2.92,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.4518,2.4518,0,0,1,170.165,310.1342Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.727.727,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M181.2451,310.9745h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0805-10.2422h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M195.0039,310.9745l.02-1a2.27,2.27,0,0,1-2,1.2,2.4514,2.4514,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5408,2.5408,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9924,2.9924,0,0,1,2.06,1.0405v-4.7012h2.38v14.1231Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1972,1.1972,0,0,0-.58-.16.933.933,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1161,1.1161,0,0,0,.58.16.9689.9689,0,0,0,.82-.4,1.3076,1.3076,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M205.7642,310.1342a3.4121,3.4121,0,0,1-2.7207,1.04,4.8438,4.8438,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38V303.493a3.1765,3.1765,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.4521,2.4521,0,0,1,205.7642,310.1342Zm-1.7808-6.8814c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M222.3042,310.9745h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0805-10.2422h2.32l1.0805,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M228.7031,306.3734v1.6807a1.9945,1.9945,0,0,0,.06.68.8118.8118,0,0,0,.82.58.7527.7527,0,0,0,.7608-.56,4.995,4.995,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.04-3.08-1.96a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5872,3.5872,0,0,1,5.9019-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8714.8714,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M247.0635,310.1942a1.93,1.93,0,0,1-.44.54,2.0222,2.0222,0,0,1-1.32.44,3.1338,3.1338,0,0,1-2.2207-1.0606v4.4009h-2.3809V300.7323h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0551,3.0551,0,0,1,.4805,1.88v5.4414A3.517,3.517,0,0,1,247.0635,310.1942Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0908,1.0908,0,0,0-.5195-.14,1.0332,1.0332,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9351.9351,0,0,0,.9.56.8592.8592,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M249.9219,310.9745V296.8514h2.4v14.1231Z" />
                                        <path className="st18" d="M259.083,310.9745c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3593.38a2.2574,2.2574,0,0,1-1.7012.74,2.0937,2.0937,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9794,2.9794,0,0,1,.48-2.1006,3.21,3.21,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3193-1.08a.96.96,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1806v-1.16a2.0477,2.0477,0,0,1,.58-1.4605,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8747,1.8747,0,0,1,.24.98v5.7012a5.0667,5.0667,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2842,1.2842,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M267.9834,310.9745V303.433c0-.64-.04-.9-.36-1.1a.7491.7491,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3809V300.7323h2.4209l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9963,1.9963,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M148.6943,333.0746c-1.38,0-2.1005-.3-2.4008-.96a2.63,2.63,0,0,1-.2-1.18v-6.6211h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7171,2.7171,0,0,0,.4-.04v1.72A5.0522,5.0522,0,0,1,148.6943,333.0746Z" />
                                        <path className="st18" d="M157.5347,332.1342a3.4121,3.4121,0,0,1-2.7207,1.04,4.8438,4.8438,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7229,3.7229,0,0,1-.18-1.38V325.493a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.9494,2.9494,0,0,1,.24,1.36v4.941A2.4521,2.4521,0,0,1,157.5347,332.1342Zm-1.7808-6.8814c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M164.7939,332.9745l.02-1a2.27,2.27,0,0,1-2,1.2,2.4511,2.4511,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5408,2.5408,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9926,2.9926,0,0,1,2.0606,1.0405v-4.7012h2.38v14.1231Zm.02-7.4414c0-.46-.02-.78-.44-1.02a1.1967,1.1967,0,0,0-.58-.16.933.933,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1158,1.1158,0,0,0,.58.16.9688.9688,0,0,0,.82-.4,1.3076,1.3076,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M173.9741,332.9745c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0911,2.0911,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9762,2.9762,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.0805-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9582,2.9582,0,0,0-.08.9605v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.4605,3.646,3.646,0,0,1,2.5805-.76c.3,0,2.0806-.02,2.8,1.28a1.8747,1.8747,0,0,1,.24.98v5.7012a5.067,5.067,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2812,1.2812,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M182.4941,333.7147a4.6483,4.6483,0,0,1-.8,2.0606,2.32,2.32,0,0,1-2.0605.74,8.0875,8.0875,0,0,1-1.54-.18l.36-1.88a3.2065,3.2065,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6606-10.2422h2.36l1.32,6.5815,1.02-6.5815h2.34Z" />
                                        <path className="st18" d="M187.1333,324.2128h-.92l1.4-2.7007h-1.24v-2.6607h2.36v2.6607Z" />
                                        <path className="st18" d="M195.9141,332.2543a3.071,3.071,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5755,3.5755,0,0,1-.22-1.32v-1.06h2.2006v.8a2.44,2.44,0,0,0,.06.64.797.797,0,0,0,.86.66.9666.9666,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2481,1.2481,0,0,0-.7-1.32l-2.0005-1.42a2.5262,2.5262,0,0,1-1.22-2.4605c.02-2.0405,1.26-2.6206,3.1006-2.6206,1.5,0,2.32.52,2.6406,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.779.779,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6484,3.6484,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,195.9141,332.2543Z" />
                                        <path className="st18" d="M212.1743,330.5941a2.3372,2.3372,0,0,1-1.24,2.1,4.8568,4.8568,0,0,1-2,.28h-4.4609V318.8514h4.3813a3.44,3.44,0,0,1,2.34.54,2.5906,2.5906,0,0,1,.84,2.3408v1.26a5.9524,5.9524,0,0,1-.08,1,1.671,1.671,0,0,1-1.5606,1.38,2.0667,2.0667,0,0,1,1.4.68,2.8958,2.8958,0,0,1,.4,1.9v2C212.1943,330.1737,212.1743,330.3939,212.1743,330.5941Zm-2.72-8.4219a2.2664,2.2664,0,0,0-.06-.62c-.18-.66-.74-.68-1.28-.68h-1.0406v3.5606h.94a5.0014,5.0014,0,0,0,.7-.04c.5-.1.74-.4.74-1.26Zm.18,5.6812a1.9479,1.9479,0,0,0-.08-.78c-.2-.58-.84-.6-1.36-.6h-1.1206v4.461h1.1006a3.5446,3.5446,0,0,0,.66-.04c.56-.08.8-.42.8-1.38Z" />
                                        <path className="st18" d="M218.9932,332.9745v-.84a3.2378,3.2378,0,0,1-2.1807,1.02,2.2544,2.2544,0,0,1-1.2-.36,2.5158,2.5158,0,0,1-.8-2.34v-7.7217h2.4v7.3613a1.2712,1.2712,0,0,0,.22.9805.8744.8744,0,0,0,.64.26,1.0282,1.0282,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M229.4336,332.2543a3.0708,3.0708,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5755,3.5755,0,0,1-.22-1.32v-1.06h2.2007v.8a2.4406,2.4406,0,0,0,.06.64.7972.7972,0,0,0,.86.66.9664.9664,0,0,0,.54-.14,1.1042,1.1042,0,0,0,.4-.9805,1.2481,1.2481,0,0,0-.7-1.32l-2.0005-1.42a2.5264,2.5264,0,0,1-1.22-2.4605c.02-2.0405,1.26-2.6206,3.1006-2.6206,1.5005,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7789.7789,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6308,3.6308,0,0,1,.62.62,2.7238,2.7238,0,0,1,.38,1.6606A3.3048,3.3048,0,0,1,229.4336,332.2543Z" />
                                        <path className="st18" d="M232.4121,321.2518v-2.4h2.4v2.4Zm0,11.7227V322.7323h2.4v10.2422Z" />
                                        <path className="st18" d="M241.6738,332.9745V325.433c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3809V322.7323h2.4209l-.02.74a2.6364,2.6364,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.0605,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M248.9326,328.3734v1.6807a1.9788,1.9788,0,0,0,.0606.68.8106.8106,0,0,0,.8193.58.7525.7525,0,0,0,.7607-.56,4.99,4.99,0,0,0,.08-1.16h2.3v.82a2.8446,2.8446,0,0,1-.1.9205c-.4,1.44-1.6806,1.84-3.041,1.84-2.1006,0-2.8-1.04-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.8106,3.8106,0,0,1,.2607-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M260.873,332.2543a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5814,3.5814,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9675.9675,0,0,0,.54-.14,1.1041,1.1041,0,0,0,.3994-.9805,1.247,1.247,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.02-2.0405,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,260.873,332.2543Z" />
                                        <path className="st18" d="M269.2529,332.2543a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9675.9675,0,0,0,.54-.14,1.1043,1.1043,0,0,0,.3994-.9805,1.2483,1.2483,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.02-2.0405,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,269.2529,332.2543Z" />
                                        <path className="st18" d="M278.6729,324.4325l-1.36,1.9805a3.7718,3.7718,0,0,0-.82,1.6206,4.3238,4.3238,0,0,0-.0391.7v.44h-2.2607v-.7a5.6249,5.6249,0,0,1,.0195-.66,3.1528,3.1528,0,0,1,.66-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.56-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.1906,10.1906,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2413-2.2207a3.8746,3.8746,0,0,1,2.3007.6,1.9394,1.9394,0,0,1,.6.72,4.097,4.097,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,278.6729,324.4325Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M417.3047,312.0746v-6.4614h-2.3809v6.4614h-2.6006V297.9515h2.6006v5.5411h2.3809v-5.5411h2.6006v14.1231Z" />
                                        <path className="st18" d="M428.3647,311.2343a3.412,3.412,0,0,1-2.7207,1.0405,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38v-4.5811a3.166,3.166,0,0,1,.2-1.3,2.9832,2.9832,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.92,1.5a2.9507,2.9507,0,0,1,.24,1.36v4.941A2.4518,2.4518,0,0,1,428.3647,311.2343Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.727.727,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M439.4448,312.0746h-2.24l-1.0005-6.0811-1.2,6.0811h-2.26l-2.0806-10.2422h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M453.1636,312.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.6463,3.6463,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.0656,5.0656,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2815,1.2815,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M462.5234,304.0131a1.6837,1.6837,0,0,0-.78-.16,1.6076,1.6076,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V301.8324h2.38l-.02,1.3a4.1333,4.1333,0,0,1,.36-.6,2.2174,2.2174,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M466.8232,307.4735v1.6807a1.9945,1.9945,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0405-3.0806-1.9605a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8233,2.8233,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M486.8037,312.0746h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0805-10.2422h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M493.2026,307.4735v1.6807a1.9945,1.9945,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7529.7529,0,0,0,.76-.56,4.992,4.992,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.04,1.84-2.1006,0-2.8008-1.0405-3.0806-1.9605a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9014-.18,2.8233,2.8233,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M510.603,311.3544a3.0709,3.0709,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5829,3.5829,0,0,1-.22-1.32v-1.06h2.2v.8a2.43,2.43,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9665.9665,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3995-.98,1.247,1.247,0,0,0-.7-1.32l-2-1.42a2.5266,2.5266,0,0,1-1.2207-2.4605c.02-2.04,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.06v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.63,3.63,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,510.603,311.3544Z" />
                                        <path className="st18" d="M515.8423,307.4735v1.6807a1.9819,1.9819,0,0,0,.06.68.8107.8107,0,0,0,.8194.58.7526.7526,0,0,0,.7607-.56,4.9957,4.9957,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1006,0-2.8-1.0405-3.08-1.9605a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8091,3.8091,0,0,1,.2608-1.38,3.5866,3.5866,0,0,1,5.9013-.18,2.83,2.83,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M525.5425,312.1747c-1.3809,0-2.1006-.3-2.4-.96a2.6171,2.6171,0,0,1-.2-1.18V303.413h-1.3008v-1.5806h1.28v-3.7207h2.3613v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.14.48.6006.56,1.02.56a2.7025,2.7025,0,0,0,.3994-.04v1.72A5.0436,5.0436,0,0,1,525.5425,312.1747Z" />
                                        <path className="st18" d="M538.3228,312.0746v-.84a3.2428,3.2428,0,0,1-2.1807,1.02,2.2511,2.2511,0,0,1-1.2-.36,2.5153,2.5153,0,0,1-.8008-2.34v-7.7217h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.9805.8732.8732,0,0,0,.64.26,1.0291,1.0291,0,0,0,.6005-.2c.3995-.34.32-1.1.32-1.16v-7.2417h2.4v10.2422Z" />
                                        <path className="st18" d="M549.7222,311.2943a1.93,1.93,0,0,1-.44.54,2.0222,2.0222,0,0,1-1.32.44,3.1338,3.1338,0,0,1-2.2207-1.0606v4.4009h-2.3809V301.8324h2.3809v.78a3.2635,3.2635,0,0,1,2.1406-.9805,2.1794,2.1794,0,0,1,1.7.82,3.0556,3.0556,0,0,1,.4805,1.88v5.4414A3.517,3.517,0,0,1,549.7222,311.2943Zm-2.0606-6.7612c0-.58-.14-.8-.42-.9805a1.0908,1.0908,0,0,0-.5195-.14,1.0332,1.0332,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.4167,1.4167,0,0,0,.08.5806.9351.9351,0,0,0,.9.56.8592.8592,0,0,0,.84-.4805,1.6146,1.6146,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M423.105,333.2343a3.4121,3.4121,0,0,1-2.7207,1.0405,4.8418,4.8418,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.9833,2.9833,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.92,1.5a2.9492,2.9492,0,0,1,.24,1.36v4.941A2.4518,2.4518,0,0,1,423.105,333.2343Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M430.8843,326.0131a1.6845,1.6845,0,0,0-.78-.16,1.608,1.608,0,0,0-.9.24,2.13,2.13,0,0,0-.64,1.94v6.0415h-2.36V323.8324h2.38l-.02,1.3a4.12,4.12,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M439.4644,335.0946a2.5141,2.5141,0,0,1-1.04,2.02,4.3532,4.3532,0,0,1-2.42.6,6.0216,6.0216,0,0,1-.82-.06,2.5893,2.5893,0,0,1-2.1607-1.48,3.8431,3.8431,0,0,1-.16-1.26h2.36a1.5322,1.5322,0,0,0,.04.48.8381.8381,0,0,0,.88.6.9809.9809,0,0,0,.54-.14c.4-.22.42-.68.42-1.08v-1.5806a2.5181,2.5181,0,0,1-2.0806,1,2.6411,2.6411,0,0,1-1.22-.3c-.94-.5-1-1.36-1-2.26v-5.4214a3.5111,3.5111,0,0,1,.14-1.12,2.0437,2.0437,0,0,1,2.04-1.46,2.9116,2.9116,0,0,1,2.1206,1.02v-.82h2.4v10.602C439.5044,334.6547,439.4844,334.8749,439.4644,335.0946Zm-2.38-8.4214c0-.2.08-1.24-.96-1.24a.92.92,0,0,0-.58.18c-.26.2-.38.44-.38,1.0405v4.56a1.9484,1.9484,0,0,0,.08.6006.85.85,0,0,0,.86.54.96.96,0,0,0,.98-1.1407Z" />
                                        <path className="st18" d="M446.2642,334.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2534,2.2534,0,0,1-1.7.74,2.0913,2.0913,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9767,2.9767,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.0806-.76l2.48-1.24v-.6c0-.6-.06-.9-.32-1.08a.9542.9542,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.6461,3.6461,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.0656,5.0656,0,0,0,.3,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2817,1.2817,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M455.1641,334.0746v-7.5415c0-.64-.04-.9-.36-1.1a.7488.7488,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.38V323.8324h2.42l-.02.74a2.6345,2.6345,0,0,1,2.0405-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M460.1631,322.3519v-2.4h2.4v2.4Zm0,11.7227V323.8324h2.4v10.2422Z" />
                                        <path className="st18" d="M464.8027,334.0746v-2l3.5811-6.3213h-3.2007v-1.92h5.6411v1.92l-3.541,6.3213h3.7012v2Z" />
                                        <path className="st18" d="M477.2832,334.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9766,2.9766,0,0,1,.48-2.1006,3.2,3.2,0,0,1,1.0806-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9547.9547,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.18v-1.16a2.048,2.048,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.0806-.02,2.8,1.28a1.8745,1.8745,0,0,1,.24.98v5.7012a5.067,5.067,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2814,1.2814,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M485.1235,334.1747c-1.38,0-2.1006-.3-2.4008-.96a2.63,2.63,0,0,1-.2-1.18V325.413h-1.3v-1.5806h1.28v-3.7207h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7171,2.7171,0,0,0,.4-.04v1.72A5.0522,5.0522,0,0,1,485.1235,334.1747Z" />
                                        <path className="st18" d="M488.2627,322.3519v-2.4h2.4v2.4Zm0,11.7227V323.8324h2.4v10.2422Z" />
                                        <path className="st18" d="M499.0034,333.2343a3.4107,3.4107,0,0,1-2.72,1.0405,4.8426,4.8426,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9643,2.9643,0,0,1,.2393,1.36v4.941A2.45,2.45,0,0,1,499.0034,333.2343Zm-1.78-6.8814c0-.64-.06-.92-.38-1.12a.7271.7271,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M506.3237,334.0746v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.0205,1.18-1.0205,1.38v7.3814h-2.3808V323.8324h2.4208l-.02.74a2.6364,2.6364,0,0,1,2.0411-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M515.4429,334.0746c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.2569,2.2569,0,0,1-1.7011.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.48-2.1006,3.2082,3.2082,0,0,1,1.08-.76l2.4805-1.24v-.6c0-.6-.06-.9-.3194-1.08a.9592.9592,0,0,0-.52-.14.7764.7764,0,0,0-.74.4,2.9547,2.9547,0,0,0-.08.9605v.38h-2.1807v-1.16a2.0482,2.0482,0,0,1,.58-1.46,3.65,3.65,0,0,1,2.5811-.76c.3,0,2.08-.02,2.8008,1.28a1.881,1.881,0,0,1,.2392.98v5.7012a5.0659,5.0659,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1611-1.16.76c-.08.06-.1807.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2844,1.2844,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M520.1216,334.0746V319.9515h2.4v14.1231Z" />
                                        <path className="st18" d="M525.1616,334.0746V319.9515h2.4v14.1231Z" />
                                        <path className="st18" d="M534.0425,334.8148a4.6442,4.6442,0,0,1-.8,2.0606,2.3187,2.3187,0,0,1-2.0606.74,8.0777,8.0777,0,0,1-1.54-.18l.3594-1.88a3.2121,3.2121,0,0,0,.9.14c.82,0,1.04-.88,1.08-1.34l.02-.28-2.6611-10.2422h2.3613l1.32,6.5815,1.02-6.5815h2.3408Z" />
                                        <path className="st18" d="M544.603,325.5326l-1.36,1.9805a3.7707,3.7707,0,0,0-.82,1.6206,4.3238,4.3238,0,0,0-.0391.7v.44h-2.2607v-.7a5.6249,5.6249,0,0,1,.0195-.66,3.1535,3.1535,0,0,1,.66-1.6l1.32-2.0406a2.4043,2.4043,0,0,0,.56-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8747,3.8747,0,0,1,2.3008.6,1.95,1.95,0,0,1,.6.72,4.097,4.097,0,0,1,.26,1.7607v.8A3.7614,3.7614,0,0,1,544.603,325.5326Zm-4.48,8.542V331.474h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M146.9175,200.2172v-6.4615h-2.3809v6.4615H141.936V186.0941h2.6006v5.541h2.3809v-5.541h2.6006v14.1231Z" />
                                        <path className="st18" d="M157.9775,199.3768a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8435,4.8435,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7251,3.7251,0,0,1-.18-1.38v-4.5811a3.1758,3.1758,0,0,1,.2-1.3,2.9843,2.9843,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.95,2.95,0,0,1,.24,1.36v4.9409A2.4515,2.4515,0,0,1,157.9775,199.3768Zm-1.7807-6.8813c0-.64-.06-.92-.38-1.12a.7266.7266,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M169.0576,200.2172h-2.24l-1-6.0811-1.2,6.0811h-2.26l-2.0805-10.2422h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M182.8164,200.2172l.02-1a2.27,2.27,0,0,1-2,1.2,2.4505,2.4505,0,0,1-1.42-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5408,2.5408,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9922,2.9922,0,0,1,2.06,1.0405v-4.7012h2.38v14.1231Zm.02-7.4414c0-.46-.02-.78-.44-1.0206a1.1992,1.1992,0,0,0-.58-.16.9311.9311,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1161,1.1161,0,0,0,.58.16.9688.9688,0,0,0,.82-.4,1.3071,1.3071,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M193.5767,199.3768a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8438,4.8438,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7255,3.7255,0,0,1-.18-1.38v-4.5811a3.1656,3.1656,0,0,1,.2-1.3,2.9832,2.9832,0,0,1,3.0405-1.6606c.32,0,2.28-.02,2.92,1.5a2.9488,2.9488,0,0,1,.24,1.36v4.9409A2.4519,2.4519,0,0,1,193.5767,199.3768Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7263.7263,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M210.1167,200.2172h-2.24l-1-6.0811-1.2,6.0811h-2.26L201.335,189.975h2.32l1.0805,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M216.5156,195.6161v1.6807a1.9935,1.9935,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7527.7527,0,0,0,.76-.56,4.9868,4.9868,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.96a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8139,3.8139,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8239,2.8239,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M232.6763,200.2172l.0195-1a2.2687,2.2687,0,0,1-2,1.2,2.4508,2.4508,0,0,1-1.4209-.44c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5407,2.5407,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9932,2.9932,0,0,1,2.06,1.0405v-4.7012h2.3809v14.1231Zm.0195-7.4414c0-.46-.0195-.78-.44-1.0206a1.1967,1.1967,0,0,0-.58-.16.9318.9318,0,0,0-.5195.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.0595.82.34,1a1.119,1.119,0,0,0,.58.16.9712.9712,0,0,0,.82-.4,1.3107,1.3107,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M239.9946,195.6161v1.6807a2.01,2.01,0,0,0,.06.68.8125.8125,0,0,0,.82.58.7527.7527,0,0,0,.7608-.56,4.9953,4.9953,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6806,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.5682,6.5682,0,0,1-.1806-1.34v-4.3208a3.81,3.81,0,0,1,.2607-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M250.2554,200.2172h-2.32l-2.2608-10.2422h2.3809l1.06,7.1015,1.22-7.1015h2.3809Z" />
                                        <path className="st18" d="M256.7544,195.6161v1.6807a2.01,2.01,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9961,4.9961,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8088,3.8088,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M263.2935,200.2172V186.0941h2.4v14.1231Z" />
                                        <path className="st18" d="M274.0347,199.3768a3.41,3.41,0,0,1-2.7207,1.0406,4.8421,4.8421,0,0,1-1.02-.1,2.4969,2.4969,0,0,1-1.9-1.6206,3.7232,3.7232,0,0,1-.1806-1.38v-4.5811a3.1673,3.1673,0,0,1,.2-1.3,2.9839,2.9839,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.9209,1.5a2.9639,2.9639,0,0,1,.2392,1.36v4.9409A2.45,2.45,0,0,1,274.0347,199.3768Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7276.7276,0,0,0-.44-.12.75.75,0,0,0-.4795.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M283.4946,199.4369a1.9281,1.9281,0,0,1-.4394.54,2.0216,2.0216,0,0,1-1.32.44,3.1336,3.1336,0,0,1-2.2207-1.0606v4.4009h-2.3809V189.975h2.3809v.78a3.264,3.264,0,0,1,2.1406-.98,2.18,2.18,0,0,1,1.7.82,3.0555,3.0555,0,0,1,.48,1.88v5.4414A3.517,3.517,0,0,1,283.4946,199.4369Zm-2.06-6.7612c0-.58-.14-.8-.42-.9805a1.0907,1.0907,0,0,0-.52-.14,1.0332,1.0332,0,0,0-.54.14,1.0517,1.0517,0,0,0-.44,1.0405v4.6006a1.418,1.418,0,0,0,.08.5806.935.935,0,0,0,.9.56.8594.8594,0,0,0,.84-.48,1.6149,1.6149,0,0,0,.1-.58Z" />
                                        <path className="st18" d="M170.7471,221.3768a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8438,4.8438,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7229,3.7229,0,0,1-.18-1.38v-4.5811a3.1779,3.1779,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5a2.9488,2.9488,0,0,1,.24,1.36v4.9409A2.4514,2.4514,0,0,1,170.7471,221.3768Zm-1.7808-6.8813c0-.64-.06-.92-.38-1.12a.7263.7263,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M178.0264,222.2172v-.84a3.2383,3.2383,0,0,1-2.18,1.02,2.2543,2.2543,0,0,1-1.2007-.36,2.5158,2.5158,0,0,1-.8-2.34V211.975h2.4v7.3613a1.2712,1.2712,0,0,0,.22.9805.8747.8747,0,0,0,.64.26,1.0275,1.0275,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16V211.975h2.4v10.2422Z" />
                                        <path className="st18" d="M187.7461,214.1556a1.686,1.686,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.64,1.9405v6.0415h-2.36V211.975h2.38l-.02,1.3a4.115,4.115,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M203.0664,219.8368a2.3373,2.3373,0,0,1-1.24,2.1,4.8571,4.8571,0,0,1-2,.28h-4.461V208.0941h4.3814a3.44,3.44,0,0,1,2.34.54,2.5906,2.5906,0,0,1,.84,2.3408v1.26a5.9517,5.9517,0,0,1-.08,1,1.6711,1.6711,0,0,1-1.5606,1.38,2.0669,2.0669,0,0,1,1.4.68,2.8958,2.8958,0,0,1,.4,1.9v2C203.0864,219.4164,203.0664,219.6366,203.0664,219.8368Zm-2.72-8.4219a2.2664,2.2664,0,0,0-.06-.62c-.18-.66-.74-.68-1.28-.68h-1.0406v3.5606h.94a4.9812,4.9812,0,0,0,.7-.04c.5-.1.74-.4.74-1.26Zm.18,5.6812a1.9477,1.9477,0,0,0-.08-.78c-.2-.58-.84-.6-1.36-.6h-1.1206v4.4609h1.1006a3.5538,3.5538,0,0,0,.66-.04c.56-.08.8-.42.8-1.38Z" />
                                        <path className="st18" d="M209.8857,222.2172v-.84a3.2378,3.2378,0,0,1-2.18,1.02,2.2543,2.2543,0,0,1-1.2007-.36,2.5158,2.5158,0,0,1-.8-2.34V211.975h2.4v7.3613a1.2708,1.2708,0,0,0,.22.9805.8747.8747,0,0,0,.64.26,1.0275,1.0275,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16V211.975h2.4v10.2422Z" />
                                        <path className="st18" d="M220.3257,221.4969a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5752,3.5752,0,0,1-.22-1.32v-1.06h2.2007v.8a2.4411,2.4411,0,0,0,.06.64.7972.7972,0,0,0,.86.66.9658.9658,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2484,1.2484,0,0,0-.7-1.32l-2.0005-1.42a2.5261,2.5261,0,0,1-1.22-2.46c.02-2.0405,1.26-2.6206,3.1006-2.6206,1.5005,0,2.32.52,2.6406,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7789.7789,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6411,3.6411,0,0,1,.62.62,2.723,2.723,0,0,1,.38,1.6606A3.3044,3.3044,0,0,1,220.3257,221.4969Z" />
                                        <path className="st18" d="M223.3047,210.4945v-2.4h2.4v2.4Zm0,11.7227V211.975h2.4v10.2422Z" />
                                        <path className="st18" d="M232.5659,222.2172v-7.5415c0-.64-.04-.9-.36-1.1a.7493.7493,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.38V211.975h2.42l-.02.74a2.6362,2.6362,0,0,1,2.041-.94,1.9961,1.9961,0,0,1,2.0606,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M239.8247,217.6161v1.6807a2.01,2.01,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9961,4.9961,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.811,3.811,0,0,1,.2608-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.832,2.832,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M251.7651,221.4969a3.0706,3.0706,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9013-1.42a3.5811,3.5811,0,0,1-.22-1.32v-1.06h2.2v.8a2.4308,2.4308,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.967.967,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3994-.98,1.2484,1.2484,0,0,0-.7-1.32l-2-1.42a2.5263,2.5263,0,0,1-1.2207-2.46c.02-2.0405,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3005,2.3005,0,0,1,.28,1.24v1.14h-2.0606v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3039,3.3039,0,0,1,251.7651,221.4969Z" />
                                        <path className="st18" d="M260.145,221.4969a3.0706,3.0706,0,0,1-2.62.92c-1.1406,0-2.4-.22-2.9014-1.42a3.5826,3.5826,0,0,1-.22-1.32v-1.06h2.2v.8a2.4308,2.4308,0,0,0,.0606.64.7976.7976,0,0,0,.86.66.9667.9667,0,0,0,.54-.14,1.1037,1.1037,0,0,0,.3995-.98,1.2472,1.2472,0,0,0-.7-1.32l-2-1.42a2.5263,2.5263,0,0,1-1.2207-2.46c.02-2.0405,1.2607-2.6206,3.1015-2.6206,1.5,0,2.32.52,2.64,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.7785.7785,0,0,0-.82.92c0,.6.2207.8.6807,1.1206l2.2,1.54a3.6268,3.6268,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3039,3.3039,0,0,1,260.145,221.4969Z" />
                                        <path className="st18" d="M161.6558,232.4945v-2.4h2.4v2.4Zm0,11.7227V233.975h2.4v10.2422Z" />
                                        <path className="st18" d="M170.9165,244.2172v-7.5415c0-.64-.04-.9-.36-1.1a.7484.7484,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.38V233.975h2.42l-.02.74a2.6345,2.6345,0,0,1,2.04-.94,1.9956,1.9956,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M184.5356,244.3173c-1.38,0-2.1-.3-2.4008-.96a2.6308,2.6308,0,0,1-.2-1.18v-6.6211h-1.3V233.975h1.28v-3.7207h2.36v3.7207h1.42v1.5805h-1.42v5.8409a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7174,2.7174,0,0,0,.4-.04v1.72A5.0456,5.0456,0,0,1,184.5356,244.3173Z" />
                                        <path className="st18" d="M191.876,244.2172v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8563.8563,0,0,0-.8-.46.8889.8889,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42v7.4214h-2.38V230.0941h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0242,2.0242,0,0,1,2.0005,1.4,2.9356,2.9356,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M199.1553,239.6161v1.6807a1.9967,1.9967,0,0,0,.06.68.8118.8118,0,0,0,.82.58.7528.7528,0,0,0,.76-.56,4.9923,4.9923,0,0,0,.08-1.16h2.3008v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0406,1.84-2.1,0-2.8007-1.0406-3.0805-1.96a6.5885,6.5885,0,0,1-.18-1.34v-4.3208a3.8124,3.8124,0,0,1,.26-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.8239,2.8239,0,0,1,.28,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8715.8715,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M214.3755,232.0345c-.44.1-.44.58-.44.68v1.26h1.06v1.5805H213.915v8.6617h-2.36v-8.6617h-1.06V233.975h1.06a10.6108,10.6108,0,0,1,.06-2.28,1.721,1.721,0,0,1,.6-1.1406,2.85,2.85,0,0,1,1.9805-.5c.3,0,.6.02.88.04v1.96A2.1125,2.1125,0,0,0,214.3755,232.0345Z" />
                                        <path className="st18" d="M221.2153,244.2172v-.84a3.2368,3.2368,0,0,1-2.1806,1.02,2.2538,2.2538,0,0,1-1.2-.36,2.5156,2.5156,0,0,1-.8-2.34V233.975h2.4v7.3613a1.2708,1.2708,0,0,0,.22.9805.8747.8747,0,0,0,.64.26,1.0275,1.0275,0,0,0,.6-.2c.4-.34.32-1.1.32-1.16V233.975h2.4v10.2422Z" />
                                        <path className="st18" d="M229.415,244.3173c-1.38,0-2.1-.3-2.4008-.96a2.6308,2.6308,0,0,1-.2-1.18v-6.6211h-1.3V233.975h1.28v-3.7207h2.36v3.7207h1.42v1.5805h-1.42v5.8409a1.3392,1.3392,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7039,2.7039,0,0,0,.4-.04v1.72A5.0456,5.0456,0,0,1,229.415,244.3173Z" />
                                        <path className="st18" d="M236.7358,244.2172v-.84a3.2418,3.2418,0,0,1-2.1806,1.02,2.2507,2.2507,0,0,1-1.2-.36,2.5153,2.5153,0,0,1-.8008-2.34V233.975h2.4v7.3613a1.2693,1.2693,0,0,0,.2207.9805.8736.8736,0,0,0,.64.26,1.0287,1.0287,0,0,0,.6006-.2c.3994-.34.32-1.1.32-1.16V233.975h2.4v10.2422Z" />
                                        <path className="st18" d="M246.4556,236.1556a1.6934,1.6934,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.131,2.131,0,0,0-.6406,1.9405v6.0415h-2.36V233.975h2.3809l-.02,1.3a4.1321,4.1321,0,0,1,.36-.6,2.2176,2.2176,0,0,1,1.9805-.88Z" />
                                        <path className="st18" d="M250.7544,239.6161v1.6807a2.01,2.01,0,0,0,.06.68.8124.8124,0,0,0,.82.58.7526.7526,0,0,0,.7607-.56,4.9961,4.9961,0,0,0,.08-1.16h2.3v.82a2.8437,2.8437,0,0,1-.1.92c-.4,1.44-1.6807,1.84-3.041,1.84-2.1,0-2.8-1.0406-3.08-1.96a6.57,6.57,0,0,1-.1807-1.34v-4.3208a3.8088,3.8088,0,0,1,.2608-1.38,3.5867,3.5867,0,0,1,5.9013-.18,2.831,2.831,0,0,1,.2793,1.46v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8717.8717,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M263.7349,235.6752l-1.36,1.98a3.7724,3.7724,0,0,0-.82,1.6206,4.325,4.325,0,0,0-.0391.7v.44h-2.2607v-.7a5.6269,5.6269,0,0,1,.02-.66,3.1531,3.1531,0,0,1,.66-1.6l1.32-2.0405a2.4043,2.4043,0,0,0,.5605-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.9805.44-.9805,1.08v2.34h-2.2207v-1.48a10.1793,10.1793,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.8753,3.8753,0,0,1,2.3007.6,1.9513,1.9513,0,0,1,.6.72,4.0989,4.0989,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,263.7349,235.6752Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M138.0732,110.975h-2.74l-1.36-9.6421-1.5206,9.6421h-2.78l-2.5806-14.1231h2.7l1.42,10.6421,1.52-10.6421h2.46l1.48,10.6421,1.48-10.6421h2.6406Z" />
                                        <path className="st18" d="M146.9722,110.975v-7.2818a2.0572,2.0572,0,0,0-.08-.8.8563.8563,0,0,0-.8-.46.889.889,0,0,0-.9.7,1.4314,1.4314,0,0,0-.04.42v7.4214h-2.38V96.8519h2.34l.02,4.7012a2.7207,2.7207,0,0,1,2.0806-1.02,2.0242,2.0242,0,0,1,2,1.4,2.9356,2.9356,0,0,1,.14,1.02v8.0215Z" />
                                        <path className="st18" d="M156.1118,110.975c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.36.38a2.2538,2.2538,0,0,1-1.7.74,2.0915,2.0915,0,0,1-1.24-.38c-.84-.62-.78-1.84-.78-2.74a2.9762,2.9762,0,0,1,.48-2.1006,3.2007,3.2007,0,0,1,1.0805-.76l2.4805-1.24v-.6c0-.6-.06-.9-.32-1.08a.9545.9545,0,0,0-.52-.14.7765.7765,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.18v-1.16a2.0477,2.0477,0,0,1,.58-1.46,3.6465,3.6465,0,0,1,2.5806-.76c.3,0,2.08-.02,2.8,1.28a1.875,1.875,0,0,1,.24.9805v5.7012a5.0671,5.0671,0,0,0,.3,2.32c.02.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.18.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2809,1.2809,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M163.9517,111.0751c-1.38,0-2.1006-.3-2.4009-.9605a2.6306,2.6306,0,0,1-.2-1.18v-6.621h-1.3v-1.5806h1.28V97.0121h2.36v3.7207h1.42v1.5806h-1.42v5.8408a1.3372,1.3372,0,0,0,.06.56c.14.48.6.56,1.02.56a2.7039,2.7039,0,0,0,.4-.04v1.72A5.0519,5.0519,0,0,1,163.9517,111.0751Z" />
                                        <path className="st18" d="M176.7114,110.975l.02-1a2.27,2.27,0,0,1-2,1.2,2.4506,2.4506,0,0,1-1.4205-.4405c-.76-.56-.86-1.22-.86-2.84v-4.6611a2.5408,2.5408,0,0,1,1.04-2.38,2.3572,2.3572,0,0,1,1.18-.34,2.9922,2.9922,0,0,1,2.0605,1.04V96.8519h2.38V110.975Zm.02-7.4414c0-.4605-.02-.78-.44-1.0205a1.1972,1.1972,0,0,0-.58-.16.933.933,0,0,0-.52.16c-.4.28-.38.7-.38,1.14v4.3613c0,.6.06.82.34,1a1.1157,1.1157,0,0,0,.58.16.9691.9691,0,0,0,.82-.4,1.3078,1.3078,0,0,0,.18-.84Z" />
                                        <path className="st18" d="M187.4717,110.1346a3.4119,3.4119,0,0,1-2.7207,1.0406,4.8418,4.8418,0,0,1-1.02-.1,2.4975,2.4975,0,0,1-1.9-1.6206,3.7229,3.7229,0,0,1-.18-1.38v-4.5811a3.1783,3.1783,0,0,1,.2-1.3,2.9844,2.9844,0,0,1,3.041-1.6606c.32,0,2.28-.02,2.92,1.5005a2.9489,2.9489,0,0,1,.24,1.36v4.941A2.4514,2.4514,0,0,1,187.4717,110.1346Zm-1.7808-6.8813c0-.64-.06-.92-.38-1.12a.7267.7267,0,0,0-.44-.12.75.75,0,0,0-.48.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.02,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M204.0117,110.975h-2.24l-1.0005-6.0811-1.2,6.0811h-2.26L195.23,100.7328h2.32l1.0806,6.7612,1.2-6.7612h1.94l1.08,6.7612,1.16-6.7612h2.3208Z" />
                                        <path className="st18" d="M210.4106,106.3739v1.6807a1.9935,1.9935,0,0,0,.06.68.8117.8117,0,0,0,.82.58.7528.7528,0,0,0,.76-.5605,4.992,4.992,0,0,0,.08-1.16h2.3007v.82a2.83,2.83,0,0,1-.1.92c-.4,1.44-1.68,1.84-3.0405,1.84-2.1006,0-2.8008-1.0406-3.0806-1.9605a6.5936,6.5936,0,0,1-.18-1.34v-4.3208a3.8142,3.8142,0,0,1,.26-1.38,3.5868,3.5868,0,0,1,5.9014-.18,2.8239,2.8239,0,0,1,.28,1.4605v2.92Zm1.7-2.94c0-.22.04-1.02-.82-1.02a.8716.8716,0,0,0-.88,1.02v1.26h1.7Z" />
                                        <path className="st18" d="M227.811,110.2548a3.0707,3.0707,0,0,1-2.6206.92c-1.14,0-2.4-.22-2.9-1.42a3.5759,3.5759,0,0,1-.22-1.32v-1.06h2.2007v.8a2.4357,2.4357,0,0,0,.06.64.797.797,0,0,0,.86.66.9661.9661,0,0,0,.54-.14,1.1038,1.1038,0,0,0,.4-.98,1.2483,1.2483,0,0,0-.7-1.32l-2-1.42a2.526,2.526,0,0,1-1.22-2.46c.02-2.04,1.26-2.6206,3.1-2.6206,1.5005,0,2.32.52,2.6407,1.1006a2.3,2.3,0,0,1,.28,1.24v1.14h-2.0605v-.74c0-.16.04-1.12-.88-1.12a.779.779,0,0,0-.82.92c0,.6.22.8.68,1.1206l2.2007,1.54a3.6484,3.6484,0,0,1,.62.62,2.7237,2.7237,0,0,1,.38,1.6606A3.3043,3.3043,0,0,1,227.811,110.2548Z" />
                                        <path className="st18" d="M233.9507,111.0751c-1.3809,0-2.1006-.3-2.4-.9605a2.6172,2.6172,0,0,1-.2-1.18v-6.621h-1.3008v-1.5806h1.28V97.0121h2.3613v3.7207h1.42v1.5806h-1.42v5.8408a1.3476,1.3476,0,0,0,.06.56c.14.48.6006.56,1.02.56a2.6893,2.6893,0,0,0,.3994-.04v1.72A5.0436,5.0436,0,0,1,233.9507,111.0751Z" />
                                        <path className="st18" d="M241.21,110.975c-.2-.22-.24-.4-.28-.9-.12.12-.22.26-.3594.38a2.257,2.257,0,0,1-1.7012.74,2.0939,2.0939,0,0,1-1.24-.38c-.84-.62-.7793-1.84-.7793-2.74a2.9789,2.9789,0,0,1,.48-2.1006,3.21,3.21,0,0,1,1.08-.76l2.48-1.24v-.6c0-.6-.0595-.9-.3193-1.08a.9592.9592,0,0,0-.52-.14.7763.7763,0,0,0-.74.4,2.9543,2.9543,0,0,0-.08.96v.38h-2.1807v-1.16a2.0479,2.0479,0,0,1,.58-1.46,3.6493,3.6493,0,0,1,2.581-.76c.3,0,2.08-.02,2.8008,1.28a1.8808,1.8808,0,0,1,.2393.9805v5.7012a5.066,5.066,0,0,0,.3008,2.32c.0195.06.06.1.16.18Zm-.34-5.1612-1.16.76c-.08.06-.1806.14-.2.16-.3.28-.34.68-.34,1.48,0,.76.1,1.2.58,1.18a1.2838,1.2838,0,0,0,.86-.36l.26-.22Z" />
                                        <path className="st18" d="M250.1108,110.975v-7.5415c0-.64-.04-.9-.36-1.1a.7494.7494,0,0,0-.46-.12c-1.12,0-1.02,1.18-1.02,1.38v7.3814h-2.3808V100.7328H248.31l-.02.74a2.6361,2.6361,0,0,1,2.041-.94,1.9962,1.9962,0,0,1,2.06,1.44,3.2368,3.2368,0,0,1,.08,1v8.002Z" />
                                        <path className="st18" d="M259.27,110.975l.0205-1a2.27,2.27,0,0,1-2.0009,1.2,2.45,2.45,0,0,1-1.42-.4405c-.7607-.56-.86-1.22-.86-2.84v-4.6611a2.54,2.54,0,0,1,1.04-2.38,2.3577,2.3577,0,0,1,1.1807-.34,2.9952,2.9952,0,0,1,2.06,1.04V96.8519h2.38V110.975Zm.0205-7.4414c0-.4605-.0205-.78-.44-1.0205a1.196,1.196,0,0,0-.58-.16.932.932,0,0,0-.52.16c-.3994.28-.38.7-.38,1.14v4.3613c0,.6.0606.82.34,1a1.119,1.119,0,0,0,.58.16.9687.9687,0,0,0,.82-.4,1.3042,1.3042,0,0,0,.1806-.84Z" />
                                        <path className="st18" d="M273.0093,98.7924c-.44.1-.44.58-.44.68v1.26h1.06v1.5806h-1.08v8.6616h-2.36v-8.6616h-1.0606v-1.5806h1.0606a10.72,10.72,0,0,1,.0595-2.28,1.7211,1.7211,0,0,1,.6006-1.1406,2.8517,2.8517,0,0,1,1.9805-.5c.3,0,.6.02.88.04v1.96A2.1092,2.1092,0,0,0,273.0093,98.7924Z" />
                                        <path className="st18" d="M281.37,110.1346a3.41,3.41,0,0,1-2.7207,1.0406,4.8409,4.8409,0,0,1-1.02-.1,2.4967,2.4967,0,0,1-1.9-1.6206,3.7227,3.7227,0,0,1-.1807-1.38v-4.5811a3.1677,3.1677,0,0,1,.2-1.3,2.984,2.984,0,0,1,3.0411-1.6606c.32,0,2.28-.02,2.9208,1.5005a2.964,2.964,0,0,1,.2393,1.36v4.941A2.4493,2.4493,0,0,1,281.37,110.1346Zm-1.78-6.8813c0-.64-.06-.92-.38-1.12a.7285.7285,0,0,0-.44-.12.75.75,0,0,0-.4794.14c-.38.28-.36.7-.36,1.12v5.2012c0,.18-.0195,1.06.82,1.06.9,0,.84-.92.84-1.08Z" />
                                        <path className="st18" d="M289.15,102.9135a1.6919,1.6919,0,0,0-.78-.16,1.6085,1.6085,0,0,0-.9.24,2.1313,2.1313,0,0,0-.6406,1.9405v6.0415h-2.36V100.7328h2.3808l-.0205,1.3a4.145,4.145,0,0,1,.36-.6,2.2173,2.2173,0,0,1,1.98-.88Z" />
                                        <path className="st18" d="M297.63,102.433l-1.36,1.98a3.7725,3.7725,0,0,0-.82,1.6206,4.3238,4.3238,0,0,0-.0391.7v.44H293.15v-.7a5.6278,5.6278,0,0,1,.0195-.66,3.1531,3.1531,0,0,1,.66-1.6l1.32-2.0405a2.4043,2.4043,0,0,0,.56-1.6406v-1.26a.8862.8862,0,0,0-.9-.98c-.76,0-.98.44-.98,1.08v2.34h-2.2207v-1.48a10.178,10.178,0,0,1,.08-1.34c.2-1.18.86-2.2207,3.2412-2.2207a3.875,3.875,0,0,1,2.3008.6,1.9509,1.9509,0,0,1,.6.72,4.0973,4.0973,0,0,1,.26,1.7607v.8A3.7625,3.7625,0,0,1,297.63,102.433Zm-4.4805,8.542v-2.6006h2.32v2.6006Z" />
                                    </g>

                                </>}
                                {SelectedLanguage === "de-DE" && <>
                                    <g>
                                        <path className="st18" d="M111.8,534l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L111.8,534z"/>
                                        <path className="st18" d="M125.9,530.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L125.9,530.6z
			 M121.2,529.4l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L121.2,529.4z"/>
                                        <path className="st18" d="M137.2,526.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H137.2z"/>
                                        <path className="st18" d="M140.9,525.4v-1.9h1V521h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H140.9z"/>
                                        <path className="st18" d="M154.7,529.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H151l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V529.4z M152.1,526.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V526.8z"/>
                                        <path className="st18" d="M161,533.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H161z"/>
                                        <path className="st18" d="M173,529.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V529.4z M170.4,526.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V526.8z"/>
                                        <path className="st18" d="M179.3,533.9V528l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H179.3z"/>
                                        <path className="st18" d="M196,533.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H196z"/>
                                        <path className="st18" d="M202.2,522.5v-2.7h2.6v2.7H202.2z M202.2,533.9v-10.3h2.6v10.3H202.2z" />
                                        <path className="st18" d="M206.7,533.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H206.7z"/>
                                        <path className="st18" d="M223.4,533.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H223.4z"/>
                                        <path className="st18" d="M232.5,533.9V528l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H232.5z"/>
                                        <path className="st18" d="M241.9,534l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L241.9,534z M241.7,527.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L241.7,527.3z"/>
                                        <path className="st18" d="M123.8,555.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H123.8z"/>
                                        <path className="st18" d="M134.5,555.9l0-1.4l-0.4,0.8l-0.4,0.4L133,556l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H134.5z M133.2,551.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L133.2,551.6z"/>
                                        <path className="st18" d="M142.8,548.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H142.8z"/>
                                        <path className="st18" d="M152.5,544.5v-2.7h2.6v2.7H152.5z M152.5,555.9v-10.3h2.6v10.3H152.5z" />
                                        <path className="st18" d="M161.1,548.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H161.1z"/>
                                        <path className="st18" d="M164.8,547.4v-1.9h1V543h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H164.8z"/>
                                        <path className="st18" d="M176.3,555.9V554l4.2-6.3H177v-2.1h6.7v1.8l-4.4,6.5h4.4v2H176.3z" />
                                        <path className="st18" d="M190,555.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H190z"/>
                                        <path className="st18" d="M199.3,547.4v-1.9h1V543h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H199.3z"/>
                                        <path className="st18" d="M210.3,555.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H210.3z"/>
                                        <path className="st18" d="M219.4,555.9V550l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H219.4z"/>
                                        <path className="st18" d="M225.9,552v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L225.9,552z M225.9,555.9v-2.7h2.6v2.7H225.9z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M444.5,624l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L444.5,624z"/>
                                        <path className="st18" d="M451.3,612.5v-2.7h2.6v2.7H451.3z M451.3,624v-10.3h2.6V624H451.3z" />
                                        <path className="st18" d="M463.3,619.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L463,622l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V619.4z M460.6,616.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V616.8z"/>
                                        <path className="st18" d="M474.4,616.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H474.4z"/>
                                        <path className="st18" d="M478.1,615.5v-1.9h1V611h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H478.1z"/>
                                        <path className="st18" d="M492,619.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V619.4z M489.3,616.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V616.8z"/>
                                        <path className="st18" d="M498.3,624v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V624H498.3z"/>
                                        <path className="st18" d="M510.3,619.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L510,622l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L503,622l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V619.4z M507.6,616.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V616.8z"/>
                                        <path className="st18" d="M512,624l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H512z"/>
                                        <path className="st18" d="M523.2,624V618l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H523.2z"/>
                                        <path className="st18" d="M460.9,646l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H460.9z"/>
                                        <path className="st18" d="M467.1,634.5v-2.7h2.6v2.7H467.1z M467.1,646v-10.3h2.6V646H467.1z" />
                                        <path className="st18" d="M471.7,646l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H471.7z"/>
                                        <path className="st18" d="M488.5,646l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4L484,645l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V646L488.5,646z M488.3,639.4l-0.2-1.2
			l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3L488.3,639.4z"
                                        />
                                        <path className="st18" d="M497.4,646l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8H493l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H497.4z M496,641.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L496,641.6z"/>
                                        <path className="st18" d="M505.6,638.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H505.6z"/>
                                        <path className="st18" d="M425.2,668v-11.7h-2.3v-2.4h7.5v2.4h-2.3V668H425.2z" />
                                        <path className="st18" d="M436.1,668l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H436.1z M434.7,663.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L434.7,663.6z"/>
                                        <path className="st18" d="M447.6,668l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2L441,667l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L447.6,668z M442.9,662.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L442.9,662.6z"/>
                                        <path className="st18" d="M456.8,663.4H452l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V663.4z M454.2,660.8L454,660l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V660.8z"/>
                                        <path className="st18" d="M462.6,660.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H462.6z"/>
                                        <path className="st18" d="M474.2,668l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L474.2,668z M469.5,662.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L469.5,662.6z"/>
                                        <path className="st18" d="M483.4,663.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V663.4z M480.8,660.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V660.8z"/>
                                        <path className="st18" d="M489.2,660.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H489.2z"/>
                                        <path className="st18" d="M493.4,662.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V661l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L493.4,662.1z"/>
                                        <path className="st18" d="M506.8,668v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H506.8z"/>
                                        <path className="st18" d="M515.9,668l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2L514,660l0,0.8h-2.5l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H515.9z M514.5,655.9h-2v-2.1h2V655.9z M514.5,663.6l-0.7,0.5
			l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L514.5,663.6z M517.6,655.9h-2v-2.1h2V655.9z"/>
                                        <path className="st18" d="M520.5,668v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H520.5z"/>
                                        <path className="st18" d="M525.3,659.5v-1.9h1V655h2.6v2.6h1.4v1.9H529v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H525.3z"/>
                                        <path className="st18" d="M533.7,664v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L533.7,664z M533.7,668v-2.7h2.6v2.7H533.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M700.6,812.3l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L700.6,812.3z"/>
                                        <path className="st18" d="M711.9,812.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H711.9z M710.6,807.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L710.6,807.9z"/>
                                        <path className="st18" d="M720.2,804.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H720.2z"/>
                                        <path className="st18" d="M734.5,812.3V806l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H734.5z"/>
                                        <path className="st18" d="M743.5,812.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H743.5z M742.2,807.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L742.2,807.9z"/>
                                        <path className="st18" d="M747.8,812.3l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H747.8z M752.4,805.7
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L752.4,805.7z"/>
                                        <path className="st18" d="M764.3,807.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V807.7z M761.7,805.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V805.2z"/>
                                        <path className="st18" d="M770.6,812.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V802l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H770.6z"/>
                                        <path className="st18" d="M787.3,812.3l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H787.3z"/>
                                        <path className="st18" d="M793.5,800.9v-2.7h2.6v2.7H793.5z M793.5,812.3V802h2.6v10.3H793.5z" />
                                        <path className="st18" d="M798,812.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2L801,805
			l-0.2,0.7l0,1v5.6H798z"/>
                                        <path className="st18" d="M676,834.3l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8L673,833l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L676,834.3z M671.4,829l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L671.4,829z"/>
                                        <path className="st18" d="M685.3,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M682.6,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M696.2,834.3v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9H687V824h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L696.2,834.3z"/>
                                        <path className="st18" d="M708.2,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M705.5,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M709.9,822.9v-2.7h2.6v2.7H709.9z M709.9,834.3V824h2.6v10.3H709.9z" />
                                        <path className="st18" d="M719,834.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V824l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H719z"/>
                                        <path className="st18" d="M727.6,826.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H727.6z"/>
                                        <path className="st18" d="M736.4,834.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H736.4z M735.1,829.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L735.1,829.9z"/>
                                        <path className="st18" d="M749.8,834.3v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6V824h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L749.8,834.3z"/>
                                        <path className="st18" d="M767.2,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M764.6,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M768.9,834.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H768.9z"/>
                                        <path className="st18" d="M775.6,834.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H775.6z"/>
                                        <path className="st18" d="M789.7,829.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V829.7z M787,827.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V827.2z"/>
                                        <path className="st18" d="M791.4,822.9v-2.7h2.6v2.7H791.4z M791.4,834.3V824h2.6v10.3H791.4z" />
                                        <path className="st18" d="M795.9,828.4l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L795.9,828.4z"/>
                                        <path className="st18" d="M809.3,834.3V828l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H809.3z"/>
                                        <path className="st18" d="M813.2,825.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H813.2z"/>
                                        <path className="st18" d="M821.6,830.4v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L821.6,830.4z M821.6,834.3v-2.7h2.6v2.7H821.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1247.4,510.1l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1247.4,510.1z"/>
                                        <path className="st18" d="M1261.6,505.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V505.5z M1259,502.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V502.9z"/>
                                        <path className="st18" d="M1263.3,510.1v-14.1h2.7v14.1H1263.3z" />
                                        <path className="st18" d="M1267.8,504.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1267.8,504.2z"/>
                                        <path className="st18" d="M1281.2,510.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1281.2z"/>
                                        <path className="st18" d="M1293.2,505.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V505.5z M1290.6,502.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V502.9z"/>
                                        <path className="st18" d="M1304.8,510.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1304.8z M1303.5,505.7l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1303.5,505.7z"/>
                                        <path className="st18" d="M1313.9,510.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1313.9,510.1z M1313.7,503.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1313.7,503.5z"/>
                                        <path className="st18" d="M1216.6,532.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1216.6z"/>
                                        <path className="st18" d="M1228.5,528.7l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1228.5,528.7z
			 M1223.7,527.6l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1223.7,527.6z"/>
                                        <path className="st18" d="M1230.2,526.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1230.2,526.2z"/>
                                        <path className="st18" d="M1250.3,532.1l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1250.3z M1248.7,522.4l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1248.7,522.4z"/>
                                        <path className="st18" d="M1259.1,532.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1259.1z"/>
                                        <path className="st18" d="M1264,532.1v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1264z"/>
                                        <path className="st18" d="M1268.8,523.5v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1268.8z"/>
                                        <path className="st18" d="M1275.2,532.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1275.2z"/>
                                        <path className="st18" d="M1286.4,532.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1286.4z M1285,520h-2v-2.1h2V520z M1285,527.7l-0.7,0.5
			l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1285,527.7z M1288.1,520h-2v-2.1h2V520z"/>
                                        <path className="st18" d="M1297.9,532.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1297.9,532.1z M1293.2,526.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1293.2,526.7z"/>
                                        <path className="st18" d="M1307.1,527.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V527.5z M1304.5,524.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V524.9z"/>
                                        <path className="st18" d="M1318.3,524.7l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1318.3z"/>
                                        <path className="st18" d="M1322.6,520.6v-2.7h2.6v2.7H1322.6z M1322.6,532.1v-10.3h2.6v10.3H1322.6z" />
                                        <path className="st18" d="M1331.7,532.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1331.7z"/>
                                        <path className="st18" d="M1341.1,532.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1341.1,532.1z M1340.9,525.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1340.9,525.5z"/>
                                        <path className="st18" d="M1239.9,554.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1239.9z"/>
                                        <path className="st18" d="M1251.7,550.7l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1251.7,550.7z
			 M1247,549.6l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1247,549.6z"/>
                                        <path className="st18" d="M1253,545.5v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1253z"/>
                                        <path className="st18" d="M1266.1,554.1l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1266.1z"/>
                                        <path className="st18" d="M1279.7,549.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V549.5z M1277.1,546.9l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V546.9z"/>
                                        <path className="st18" d="M1286,554.1v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1286z"/>
                                        <path className="st18" d="M1295.4,554.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1295.4,554.1z M1295.2,547.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1295.2,547.5z"/>
                                        <path className="st18" d="M1299.7,542.6v-2.7h2.6v2.7H1299.7z M1299.7,554.1v-10.3h2.6v10.3H1299.7z" />
                                        <path className="st18" d="M1311.6,554.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1311.6,554.1z M1306.9,548.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1306.9,548.7z"/>
                                        <path className="st18" d="M1315.4,550.1v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1315.4,550.1z M1315.4,554.1v-2.7h2.6v2.7H1315.4z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M944.6,404.1l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L944.6,404.1z"/>
                                        <path className="st18" d="M958.9,399.5H954l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V399.5z M956.2,397l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V397z"/>
                                        <path className="st18" d="M960.6,404.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H960.6z"/>
                                        <path className="st18" d="M980.1,399.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V399.5z M977.4,397l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V397z"/>
                                        <path className="st18" d="M981.8,404.1l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H981.8z"/>
                                        <path className="st18" d="M988.4,404.1V390h2.7v14.1H988.4z" />
                                        <path className="st18" d="M1000.4,399.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V399.5z M997.8,397l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V397z"/>
                                        <path className="st18" d="M1006.9,404.1l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1V390h2.6v14.1L1006.9,404.1z M1006.7,397.5
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1006.7,397.5z"/>
                                        <path className="st18" d="M1011.3,392.7V390h2.6v2.7H1011.3z M1011.3,404.1v-10.3h2.6v10.3H1011.3z" />
                                        <path className="st18" d="M1023.1,404.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1023.1,404.1z M1018.5,398.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1018.5,398.7z"/>
                                        <path className="st18" d="M1024.4,395.6v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1024.4z"/>
                                        <path className="st18" d="M1042.9,404.1l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1042.9z"/>
                                        <path className="st18" d="M1056.5,399.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V399.5z M1053.9,397l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V397z"/>
                                        <path className="st18" d="M1058.2,404.1V390h2.7v14.1H1058.2z" />
                                        <path className="st18" d="M1062.7,398.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1062.7,398.2z"/>
                                        <path className="st18" d="M1076.1,404.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1076.1z"/>
                                        <path className="st18" d="M1088.1,399.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V399.5z M1085.5,397l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V397z"/>
                                        <path className="st18" d="M948,416l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1l1.2,0.8
			l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8v1.2
			l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L948,416z"/>
                                        <path className="st18" d="M952.6,420.2l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L952.6,420.2z"/>
                                        <path className="st18" d="M966,426.1v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H966z"/>
                                        <path className="st18" d="M970.5,426.1V412h2.7v14.1H970.5z" />
                                        <path className="st18" d="M979.8,426.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H979.8z M978.3,414.1h-2V412h2V414.1z M981.3,414.1h-2V412h2
			V414.1z"/>
                                        <path className="st18" d="M988.3,418.7l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H988.3z"/>
                                        <path className="st18" d="M996.6,418.7l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H996.6z"/>
                                        <path className="st18" d="M1008.3,421.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V421.5z M1005.7,419l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V419z"/>
                                        <path className="st18" d="M1010,426.1V412h2.7v14.1H1010z" />
                                        <path className="st18" d="M1019.1,426.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1019.1z M1017.8,421.7l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1017.8,421.7z"/>
                                        <path className="st18" d="M1028,426.1v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1028z"/>
                                        <path className="st18" d="M1032.9,426.1v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1032.9z"/>
                                        <path className="st18" d="M1045.6,426.1l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1045.6,426.1z M1040.9,420.7l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1040.9,420.7z"/>
                                        <path className="st18" d="M1051.9,426.1l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1051.9z M1050.6,421.7l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1050.6,421.7z"/>
                                        <path className="st18" d="M1056.2,426.1l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1056.2z M1060.8,419.4
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1060.8,419.4z"/>
                                        <path className="st18" d="M1072.7,421.5h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V421.5z M1070.1,419l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V419z"/>
                                        <path className="st18" d="M1076.4,422.1v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1076.4,422.1z M1076.4,426.1v-2.7h2.6v2.7H1076.4z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1225.9,312l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1225.9,312z"/>
                                        <path className="st18" d="M1232.7,300.5v-2.7h2.6v2.7H1232.7z M1232.7,312v-10.3h2.6V312H1232.7z" />
                                        <path className="st18" d="M1244.7,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1242.1,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1256.4,312v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1256.4z"/>
                                        <path className="st18" d="M1268.3,308.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1268.3,308.6z
			 M1263.5,307.5l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1263.5,307.5z"/>
                                        <path className="st18" d="M1274.7,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1274.7z"/>
                                        <path className="st18" d="M1286.6,308.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L1286.6,308.6z
			 M1281.8,307.5l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L1281.8,307.5z"/>
                                        <path className="st18" d="M1288.4,312l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1288.4z"/>
                                        <path className="st18" d="M1295.1,300.5v-2.7h2.6v2.7H1295.1z M1295.1,312v-10.3h2.6V312H1295.1z" />
                                        <path className="st18" d="M1307,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1304.4,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1308.8,312l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1308.8z"/>
                                        <path className="st18" d="M1322.8,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M1320.2,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M1329.1,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1329.1z"/>
                                        <path className="st18" d="M1231.3,334l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1231.3z"/>
                                        <path className="st18" d="M1237.5,322.5v-2.7h2.6v2.7H1237.5z M1237.5,334v-10.3h2.6V334H1237.5z" />
                                        <path className="st18" d="M1242.1,334l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1242.1z"/>
                                        <path className="st18" d="M1254.4,334v-14.1h2.8v11.6h4.1v2.5H1254.4z" />
                                        <path className="st18" d="M1270.3,329.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V329.4z M1267.6,326.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V326.8z"/>
                                        <path className="st18" d="M1272,322.5v-2.7h2.6v2.7H1272z M1272,334v-10.3h2.6V334H1272z" />
                                        <path className="st18" d="M1280.6,326.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1280.6z"/>
                                        <path className="st18" d="M1284.3,325.4v-1.9h1V321h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1284.3z"/>
                                        <path className="st18" d="M1295.3,334v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V334H1295.3z"/>
                                        <path className="st18" d="M1304.4,334V328l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1304.4z"/>
                                        <path className="st18" d="M1316.3,334l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1316.3,334z M1311.6,328.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L1311.6,328.6z"/>
                                        <path className="st18" d="M1320.1,330v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1320.1,330z M1320.1,334v-2.7h2.6v2.7H1320.1z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M954.2,313l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7L951,313l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L954.2,313z"/>
                                        <path className="st18" d="M961,301.5v-2.7h2.6v2.7H961z M961,313v-10.3h2.6V313H961z" />
                                        <path className="st18" d="M973,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M970.4,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M980.2,313l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H980.2z M984.8,306.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L984.8,306.3z"/>
                                        <path className="st18" d="M996.7,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M994,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M1002.4,305.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1002.4z"/>
                                        <path className="st18" d="M1014.1,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M1011.5,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M1015.3,304.4v-1.9h1V300h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1015.3z"/>
                                        <path className="st18" d="M1021.4,313V311l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H1021.4z" />
                                        <path className="st18" d="M1037.8,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M1035.2,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M1044.2,313V307l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1044.2z"/>
                                        <path className="st18" d="M1060.8,313l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1060.8z"/>
                                        <path className="st18" d="M1067,301.5v-2.7h2.6v2.7H1067z M1067,313v-10.3h2.6V313H1067z" />
                                        <path className="st18" d="M1071.6,313l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1071.6z"/>
                                        <path className="st18" d="M963.6,335l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H963.6z"/>
                                        <path className="st18" d="M977.2,330.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L970,333l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V330.4z M974.5,327.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V327.8z"/>
                                        <path className="st18" d="M978.9,335v-14.1h2.7V335H978.9z" />
                                        <path className="st18" d="M983.4,329.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V328l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L983.4,329.1z"/>
                                        <path className="st18" d="M996.8,335v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H996.8z"/>
                                        <path className="st18" d="M1008.8,330.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V330.4z M1006.1,327.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V327.8z"/>
                                        <path className="st18" d="M1020.8,324.9l0-0.3l0-0.9l-0.4-0.8l-0.6-0.2l-0.4,0.1l-0.5,0.3l-0.2,0.4l-0.1,0.6l0.1,0.6l0.3,0.4l1.2,1
			l1.2,0.8l1,0.8l1,1.2l0.4,1l0.1,1.3l-0.2,1.4l-0.6,1.3l-1,0.8l-1.5,0.5h-1l-1.4-0.1l-1-0.4l-0.8-0.8l-0.4-0.8l-0.2-0.9l0-1.8h2.8
			v1.2l0.1,0.9l0.4,0.4l0.7,0.2l0.7-0.2l0.4-0.5l0.1-0.9l-0.1-0.9l-0.5-0.7l-0.9-0.7l-1.3-0.9l-0.9-0.7l-0.9-0.9l-0.4-0.9l-0.2-1.4
			l0.1-1.2l0.4-1.1l0.5-0.6l0.8-0.5l0.8-0.3l1.3-0.1l1.7,0.3l1,0.6l0.5,0.8l0.3,1.3l0,1.3L1020.8,324.9z"/>
                                        <path className="st18" d="M1024.9,326.4v-1.9h1V322h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1024.9z"/>
                                        <path className="st18" d="M1038.7,330.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V330.4z M1036,327.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V327.8z"/>
                                        <path className="st18" d="M1040.4,335v-14.1h2.7V335H1040.4z" />
                                        <path className="st18" d="M1045,335v-14.1h2.7V335H1045z" />
                                        <path className="st18" d="M1056.9,330.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V330.4z M1054.3,327.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V327.8z"/>
                                        <path className="st18" d="M1060.6,331v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1060.6,331z M1060.6,335v-2.7h2.6v2.7H1060.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M694.5,322l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L694.5,322z"/>
                                        <path className="st18" d="M708.8,317.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H705l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V317.4z M706.1,314.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V314.8z"/>
                                        <path className="st18" d="M710.5,322l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H710.5z"/>
                                        <path className="st18" d="M731.7,321.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L731.7,321.9z"/>
                                        <path className="st18" d="M740.8,322l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2L739,314l0,0.8h-2.5l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H740.8z M739.5,317.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L739.5,317.6z"/>
                                        <path className="st18" d="M744.9,316.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V315l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L744.9,316.1z"/>
                                        <path className="st18" d="M758.4,322v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H758.4z"/>
                                        <path className="st18" d="M762.3,313.4v-1.9h1V309h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H762.3z"/>
                                        <path className="st18" d="M780.8,322l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H780.8z"/>
                                        <path className="st18" d="M791.5,322l0-1.4l-0.4,0.8l-0.4,0.4L790,322l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H791.5z M790.2,317.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L790.2,317.6z"/>
                                        <path className="st18" d="M799.8,314.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5L796,316l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H799.8z"/>
                                        <path className="st18" d="M806.1,318v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L806.1,318z M806,322v-2.7h2.6v2.7H806z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M697.9,209.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L697.9,209.9z"/>
                                        <path className="st18" d="M704.7,198.5v-2.7h2.6v2.7H704.7z M704.7,209.9v-10.3h2.6v10.3H704.7z" />
                                        <path className="st18" d="M716.7,205.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V205.3z M714,202.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V202.8z"/>
                                        <path className="st18" d="M731.1,209.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L731.1,209.9z M726.5,204.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L726.5,204.6z"/>
                                        <path className="st18" d="M740.4,205.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V205.3z M737.7,202.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V202.8z"/>
                                        <path className="st18" d="M742.1,209.9v-14.1h2.7v14.1H742.1z" />
                                        <path className="st18" d="M746.7,198.5v-2.7h2.6v2.7H746.7z M746.7,209.9v-10.3h2.6v10.3H746.7z" />
                                        <path className="st18" d="M755.8,209.9V204l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H755.8z"/>
                                        <path className="st18" d="M767.6,209.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L767.6,209.9z M763,204.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L763,204.6z"/>
                                        <path className="st18" d="M768.9,201.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3L772,210l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H768.9z"/>
                                        <path className="st18" d="M785.4,209.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3L781,209l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H785.4z"/>
                                        <path className="st18" d="M794.4,209.9V204l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H794.4z"/>
                                        <path className="st18" d="M803,202.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H803z"/>
                                        <path className="st18" d="M724.4,231.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L724.4,231.9z"/>
                                        <path className="st18" d="M735.8,231.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H735.8z M734.5,227.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L734.5,227.5z"/>
                                        <path className="st18" d="M744.6,231.9V226l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H744.6z"/>
                                        <path className="st18" d="M754,231.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L754,231.9z M753.8,225.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L753.8,225.3z"/>
                                        <path className="st18" d="M765.7,227.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H762l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V227.3z M763.1,224.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V224.8z"/>
                                        <path className="st18" d="M767.4,231.9v-14.1h2.7v14.1H767.4z" />
                                        <path className="st18" d="M774,228v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6H772l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L774,228z M774,231.9v-2.7h2.6v2.7H774z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1256,89.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1256,89.9z"/>
                                        <path className="st18" d="M1270.3,85.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L1270,88l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L1263,88l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V85.3z M1267.6,82.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V82.8z"/>
                                        <path className="st18" d="M1272,89.9V75.8h2.7v14.1H1272z" />
                                        <path className="st18" d="M1276.5,84l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1V85l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L1276.5,84z"/>
                                        <path className="st18" d="M1289.9,89.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1289.9z"/>
                                        <path className="st18" d="M1301.8,85.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V85.3z M1299.2,82.8L1299,82l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V82.8z"/>
                                        <path className="st18" d="M1218.9,111.9l0-14.1h6.6v2.5h-3.7l-0.1,3h3.4v2.4h-3.4v6.2H1218.9z" />
                                        <path className="st18" d="M1231.6,111.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1231.6z M1230,99.9h-2v-2.1h2V99.9z M1233.1,99.9h-2v-2.1h2V99.9
			z"/>
                                        <path className="st18" d="M1240.6,111.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1240.6z"/>
                                        <path className="st18" d="M1245.2,111.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1245.2z"/>
                                        <path className="st18" d="M1256.5,111.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1256.5z"/>
                                        <path className="st18" d="M1265.6,111.9V106l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1265.6z"/>
                                        <path className="st18" d="M1277.4,111.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1277.4,111.9z M1272.8,106.6l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1272.8,106.6z"/>
                                        <path className="st18" d="M1283.3,104.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1283.3z"/>
                                        <path className="st18" d="M1292.7,111.9l-2.4-5.4v5.4h-2.6V97.8h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1292.7z" />
                                        <path className="st18" d="M1296.7,111.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1296.7z"/>
                                        <path className="st18" d="M1307.9,111.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1307.9z M1306.5,99.9h-2v-2.1h2V99.9z M1306.5,107.5
			l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1306.5,107.5z M1309.6,99.9h-2v-2.1h2V99.9z"/>
                                        <path className="st18" d="M1312.5,111.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1312.5z"/>
                                        <path className="st18" d="M1317.3,103.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1317.3z"/>
                                        <path className="st18" d="M1331.2,107.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V107.3z M1328.5,104.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V104.8z"/>
                                        <path className="st18" d="M1221.7,133.9l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1221.7z M1226.3,127.3
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1226.3,127.3z"/>
                                        <path className="st18" d="M1230.8,133.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1230.8z"/>
                                        <path className="st18" d="M1242,133.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1242z M1240.6,129.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1240.6,129.5z"/>
                                        <path className="st18" d="M1250.9,133.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1250.9z"/>
                                        <path className="st18" d="M1255.3,128l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1255.3,128z"/>
                                        <path className="st18" d="M1268.7,133.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1268.7z"/>
                                        <path className="st18" d="M1280.7,129.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V129.3z M1278,126.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V126.8z"/>
                                        <path className="st18" d="M1287,133.9V128l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1287z"/>
                                        <path className="st18" d="M1303.6,133.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1303.6z"/>
                                        <path className="st18" d="M1309.8,122.5v-2.7h2.6v2.7H1309.8z M1309.8,133.9v-10.3h2.6v10.3H1309.8z" />
                                        <path className="st18" d="M1314.4,133.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1314.4z"/>
                                        <path className="st18" d="M1323,130v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1323,130z M1323,133.9v-2.7h2.6v2.7H1323z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1225.7,199.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1225.7,199.9z"/>
                                        <path className="st18" d="M1232.5,188.5v-2.7h2.6v2.7H1232.5z M1232.5,199.9v-10.3h2.6v10.3H1232.5z" />
                                        <path className="st18" d="M1244.5,195.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V195.3z M1241.9,192.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V192.8z"/>
                                        <path className="st18" d="M1259,195.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V195.3z M1256.4,192.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V192.8z"/>
                                        <path className="st18" d="M1265.4,199.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1265.4z"/>
                                        <path className="st18" d="M1269.3,191.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1269.3z"/>
                                        <path className="st18" d="M1282.4,199.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1282.4z"/>
                                        <path className="st18" d="M1288.6,188.5v-2.7h2.6v2.7H1288.6z M1288.6,199.9v-10.3h2.6v10.3H1288.6z" />
                                        <path className="st18" d="M1293.1,194l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1293.1,194z"/>
                                        <path className="st18" d="M1307,199.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1307z" />
                                        <path className="st18" d="M1318.5,195.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V195.3z M1315.8,192.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V192.8z"/>
                                        <path className="st18" d="M1320.2,199.9v-14.1h2.7v14.1H1320.2z" />
                                        <path className="st18" d="M1329.4,199.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1329.4z"/>
                                        <path className="st18" d="M1215.1,221.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1215.1z"/>
                                        <path className="st18" d="M1221.3,210.5v-2.7h2.6v2.7H1221.3z M1221.3,221.9v-10.3h2.6v10.3H1221.3z" />
                                        <path className="st18" d="M1225.9,221.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1225.9z"/>
                                        <path className="st18" d="M1237.9,210.5v-2.7h2.6v2.7H1237.9z M1237.9,221.9v-10.3h2.6v10.3H1237.9z" />
                                        <path className="st18" d="M1247.1,221.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1247.1z"/>
                                        <path className="st18" d="M1255.6,214.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1255.6z"/>
                                        <path className="st18" d="M1267.2,221.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1267.2,221.9z M1262.6,216.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1262.6,216.5z"/>
                                        <path className="st18" d="M1276.5,217.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V217.3z M1273.8,214.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V214.8z"/>
                                        <path className="st18" d="M1282.2,214.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1282.2z"/>
                                        <path className="st18" d="M1291,221.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1291z M1289.7,217.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1289.7,217.5z"/>
                                        <path className="st18" d="M1304.4,221.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1304.4,221.9z"/>
                                        <path className="st18" d="M1308.4,213.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1308.4z"/>
                                        <path className="st18" d="M1325,221.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1325,221.9z M1324.8,215.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1324.8,215.3z"/>
                                        <path className="st18" d="M1329.4,210.5v-2.7h2.6v2.7H1329.4z M1329.4,221.9v-10.3h2.6v10.3H1329.4z" />
                                        <path className="st18" d="M1341.3,217.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V217.3z M1338.7,214.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V214.8z"/>
                                        <path className="st18" d="M1199.5,243.9V238l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1199.5,243.9z"/>
                                        <path className="st18" d="M1209,243.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1209z M1207.6,239.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1207.6,239.5z"/>
                                        <path className="st18" d="M1217.8,243.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1217.8z"/>
                                        <path className="st18" d="M1226.8,243.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1226.8z M1225.5,239.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1225.5,239.5z"/>
                                        <path className="st18" d="M1238.4,243.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1238.4,243.9z M1233.7,238.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1233.7,238.5z"/>
                                        <path className="st18" d="M1247.6,239.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V239.3z M1245,236.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V236.8z"/>
                                        <path className="st18" d="M1258.5,243.9v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1258.5,243.9z"/>
                                        <path className="st18" d="M1270.5,239.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V239.3z M1267.9,236.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V236.8z"/>
                                        <path className="st18" d="M1276.8,243.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1276.8z"/>
                                        <path className="st18" d="M1280.8,235.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1280.8z"/>
                                        <path className="st18" d="M1287.6,243.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1287.6z"/>
                                        <path className="st18" d="M1297.5,243.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1297.5z M1296.1,231.8h-2v-2.1h2V231.8z M1296.2,239.5
			l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1296.2,239.5z M1299.2,231.8h-2v-2.1h2V231.8z"
                                        />
                                        <path className="st18" d="M1306.3,243.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1306.3z"/>
                                        <path className="st18" d="M1310.9,232.5v-2.7h2.6v2.7H1310.9z M1310.9,243.9v-10.3h2.6v10.3H1310.9z" />
                                        <path className="st18" d="M1322.7,243.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1322.7,243.9z M1318.1,238.5l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1318.1,238.5z"/>
                                        <path className="st18" d="M1329.7,243.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1329.7z" />
                                        <path className="st18" d="M1341.1,239.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V239.3z M1338.5,236.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V236.8z"/>
                                        <path className="st18" d="M1342.9,232.5v-2.7h2.6v2.7H1342.9z M1342.9,243.9v-10.3h2.6v10.3H1342.9z" />
                                        <path className="st18" d="M1346.8,235.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1346.8z"/>
                                        <path className="st18" d="M1355.2,239.9v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1355.2,239.9z M1355.2,243.9v-2.7h2.6v2.7H1355.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1487.2,208.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1487.2,208.9z"/>
                                        <path className="st18" d="M1494.1,197.5v-2.7h2.6v2.7H1494.1z M1494.1,208.9v-10.3h2.6v10.3H1494.1z" />
                                        <path className="st18" d="M1506,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M1503.4,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M1520.6,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M1517.9,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M1526.9,208.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1526.9z"/>
                                        <path className="st18" d="M1530.9,200.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1530.9z"/>
                                        <path className="st18" d="M1544,208.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1544z"/>
                                        <path className="st18" d="M1550.2,197.5v-2.7h2.6v2.7H1550.2z M1550.2,208.9v-10.3h2.6v10.3H1550.2z" />
                                        <path className="st18" d="M1554.6,203l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1554.6,203z"/>
                                        <path className="st18" d="M1568.6,208.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1568.6z" />
                                        <path className="st18" d="M1580,204.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V204.3z M1577.4,201.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V201.8z"/>
                                        <path className="st18" d="M1581.7,208.9v-14.1h2.7v14.1H1581.7z" />
                                        <path className="st18" d="M1590.9,208.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1590.9z"/>
                                        <path className="st18" d="M1453.7,230.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1453.7z"/>
                                        <path className="st18" d="M1460,219.5v-2.7h2.6v2.7H1460z M1460,230.9v-10.3h2.6v10.3H1460z" />
                                        <path className="st18" d="M1464.5,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1464.5z"/>
                                        <path className="st18" d="M1481.2,230.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1481.2z"/>
                                        <path className="st18" d="M1490.3,230.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1490.3z"/>
                                        <path className="st18" d="M1498.9,223.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1498.9z"/>
                                        <path className="st18" d="M1510.6,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1507.9,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1512.3,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1512.3z"/>
                                        <path className="st18" d="M1526.4,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1523.7,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1543.6,230.9V225l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1543.6,230.9z"/>
                                        <path className="st18" d="M1548.5,219.5v-2.7h2.6v2.7H1548.5z M1548.5,230.9v-10.3h2.6v10.3H1548.5z" />
                                        <path className="st18" d="M1552.5,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1552.5z"/>
                                        <path className="st18" d="M1563.4,230.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1563.4z M1562,226.5l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1562,226.5z"/>
                                        <path className="st18" d="M1567.6,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1567.6z"/>
                                        <path className="st18" d="M1574.3,230.9l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1574.3z M1578.9,224.2
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1578.9,224.2z"/>
                                        <path className="st18" d="M1590.8,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1588.2,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1592.6,219.5v-2.7h2.6v2.7H1592.6z M1592.6,230.9v-10.3h2.6v10.3H1592.6z" />
                                        <path className="st18" d="M1596.5,222.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1596.5z"/>
                                        <path className="st18" d="M1610.3,226.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V226.3z M1607.7,223.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V223.8z"/>
                                        <path className="st18" d="M1612.1,230.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1612.1z"/>
                                        <path className="st18" d="M1620.7,226.9v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1620.7,226.9z M1620.7,230.9v-2.7h2.6v2.7H1620.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1513.9,301l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1513.9,301z"/>
                                        <path className="st18" d="M1528.1,296.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V296.4z M1525.5,293.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V293.8z"/>
                                        <path className="st18" d="M1529.8,301v-14.1h2.7V301H1529.8z" />
                                        <path className="st18" d="M1534.3,295.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V294l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1534.3,295.1z"/>
                                        <path className="st18" d="M1547.7,301v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1547.7z"/>
                                        <path className="st18" d="M1559.7,296.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V296.4z M1557.1,293.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V293.8z"/>
                                        <path className="st18" d="M1459.5,323v-14.1h4.6l1.2,0.3l0.8,0.5l0.6,0.8l0.4,1.2l0.1,1.8l-0.1,1.4l-0.2,1l-0.4,0.8l-0.6,0.7l-0.8,0.4
			l-0.9,0.2l-1.3,0h-0.6v5.1H1459.5z M1463.1,315.4l0.7-0.2l0.4-0.6l0.1-1l0-1.3l-0.3-0.7l-0.4-0.2l-0.5-0.1h-0.8v4.1L1463.1,315.4z
			"/>
                                        <path className="st18" d="M1476.2,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1473.5,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1477.9,323l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1477.9z"/>
                                        <path className="st18" d="M1488.6,315.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1488.6z"/>
                                        <path className="st18" d="M1492.9,326.7v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1l-0.9-0.1l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L1492.9,326.7z M1495.5,318.3l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V318.3z"/>
                                        <path className="st18" d="M1509.4,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1506.8,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1516.2,323l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1516.2z" />
                                        <path className="st18" d="M1519.7,314.4v-1.9h1V310h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1519.7z"/>
                                        <path className="st18" d="M1526.1,311.5v-2.7h2.6v2.7H1526.1z M1526.1,323v-10.3h2.6V323H1526.1z" />
                                        <path className="st18" d="M1532.3,323l-2.4-10.3l2.7,0l1,5.5l0.2,2.6l0-0.9l0.2-1.4l1.1-5.8h2.7l-2.6,10.3H1532.3z" />
                                        <path className="st18" d="M1546.4,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1543.8,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1552.7,323V317l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1552.7z"/>
                                        <path className="st18" d="M1567.3,323v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1567.3z"/>
                                        <path className="st18" d="M1576.3,323l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1576.3z M1575,318.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1575,318.6z"/>
                                        <path className="st18" d="M1580.6,323l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1580.6z M1585.2,316.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1585.2,316.3z"/>
                                        <path className="st18" d="M1597.1,318.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V318.4z M1594.5,315.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V315.8z"/>
                                        <path className="st18" d="M1603.4,323V317l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1603.4z"/>
                                        <path className="st18" d="M1465.5,345v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V345H1465.5z"/>
                                        <path className="st18" d="M1474.6,345V339l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1474.6z"/>
                                        <path className="st18" d="M1483.1,337.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1483.1z"/>
                                        <path className="st18" d="M1494.8,340.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V340.4z M1492.2,337.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V337.8z"/>
                                        <path className="st18" d="M1496.6,345l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1496.6z"/>
                                        <path className="st18" d="M1510.6,340.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V340.4z M1508,337.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V337.8z"/>
                                        <path className="st18" d="M1527.8,344.9v-5.8l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1527.8,344.9z"/>
                                        <path className="st18" d="M1532.8,333.5v-2.7h2.6v2.7H1532.8z M1532.8,345v-10.3h2.6V345H1532.8z" />
                                        <path className="st18" d="M1536.7,336.4v-1.9h1V332h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1536.7z"/>
                                        <path className="st18" d="M1547.6,345l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1547.6z M1546.3,340.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1546.3,340.6z"/>
                                        <path className="st18" d="M1551.9,345l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1551.9z"/>
                                        <path className="st18" d="M1558.6,345l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2l0,1.7
			v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1558.6z M1563.2,338.3l-0.2-1
			l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1563.2,338.3z"/>
                                        <path className="st18" d="M1575.1,340.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V340.4z M1572.4,337.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V337.8z"/>
                                        <path className="st18" d="M1576.8,333.5v-2.7h2.6v2.7H1576.8z M1576.8,345v-10.3h2.6V345H1576.8z" />
                                        <path className="st18" d="M1580.8,336.4v-1.9h1V332h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H1580.8z"/>
                                        <path className="st18" d="M1594.6,340.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V340.4z M1592,337.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V337.8z"/>
                                        <path className="st18" d="M1596.3,345l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1596.3z"/>
                                        <path className="st18" d="M1605,341v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1605,341z M1604.9,345v-2.7h2.6v2.7H1604.9z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1491.8,462.6l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1491.8,462.6z"/>
                                        <path className="st18" d="M1498.7,451.2v-2.7h2.6v2.7H1498.7z M1498.7,462.6v-10.3h2.6v10.3H1498.7z" />
                                        <path className="st18" d="M1510.7,458h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V458z M1508,455.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V455.5z"/>
                                        <path className="st18" d="M1517.8,462.6l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1517.8z M1522.4,455.9
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1522.4,455.9z"/>
                                        <path className="st18" d="M1534.3,458h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V458z M1531.7,455.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V455.5z"/>
                                        <path className="st18" d="M1542.8,462.6l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1542.8z"/>
                                        <path className="st18" d="M1556.4,458h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V458z M1553.7,455.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V455.5z"/>
                                        <path className="st18" d="M1558.1,462.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1558.1z"/>
                                        <path className="st18" d="M1564.2,454.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1564.2z"/>
                                        <path className="st18" d="M1578,458h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V458z M1575.4,455.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V455.5z"/>
                                        <path className="st18" d="M1584.3,462.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1584.3z"/>
                                        <path className="st18" d="M1491.8,484.6l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1491.8z"/>
                                        <path className="st18" d="M1498.1,473.2v-2.7h2.6v2.7H1498.1z M1498.1,484.6v-10.3h2.6v10.3H1498.1z" />
                                        <path className="st18" d="M1502.6,484.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1502.6z"/>
                                        <path className="st18" d="M1515,484.6v-14.1h2.8v11.6h4.1v2.5H1515z" />
                                        <path className="st18" d="M1530.8,480h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V480z M1528.2,477.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V477.5z"/>
                                        <path className="st18" d="M1532.6,473.2v-2.7h2.6v2.7H1532.6z M1532.6,484.6v-10.3h2.6v10.3H1532.6z" />
                                        <path className="st18" d="M1541.1,477.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1541.1z"/>
                                        <path className="st18" d="M1544.8,476.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1544.8z"/>
                                        <path className="st18" d="M1555.9,484.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1555.9z"/>
                                        <path className="st18" d="M1565,484.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1565z"/>
                                        <path className="st18" d="M1576.8,484.6l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1576.8,484.6z M1572.2,479.2l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1572.2,479.2z"/>
                                        <path className="st18" d="M1580.6,480.6v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1580.6,480.6z M1580.6,484.6v-2.7h2.6v2.7H1580.6z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1503.7,640.5h4.3l0.6,0l1.1,0.3l0.6,0.4l0.5,0.5l0.3,0.7l0.3,2l-0.1,1.2l-0.4,1.1l-0.6,0.7l-0.5,0.2
			l0.8,0.3l0.5,0.4l0.3,0.6l0.3,1.9l-0.2,1.9l-0.6,1l-0.6,0.5l-0.6,0.3l-1.3,0.1h-4.6V640.5z M1507.1,646.4l0.8-0.1l0.3-0.2l0.3-0.3
			l0.1-1.4l-0.1-0.7l-0.6-0.6l-0.7-0.1h-0.8v3.4H1507.1z M1507.3,652.3l0.8-0.1l0.4-0.3l0.2-0.8l0-0.8l-0.3-1.2l-0.5-0.3l-0.8-0.1
			h-0.9v3.7H1507.3z"/>
                                        <path className="st18" d="M1520.8,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1518.1,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1526.5,647.3l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1526.5z"/>
                                        <path className="st18" d="M1530.2,646.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1530.2z"/>
                                        <path className="st18" d="M1544,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1541.4,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1550.4,654.7v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1550.4z"/>
                                        <path className="st18" d="M1562.3,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1559.7,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1568.6,654.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1568.6z"/>
                                        <path className="st18" d="M1464.8,676.7l-0.7-3.9h-2.3l-0.7,3.9h-3l3.1-14.2h3.6l3,14.2H1464.8z M1463.2,667.1l-0.2-2.1l-0.3,2.1
			l-0.5,3.4h1.6L1463.2,667.1z"/>
                                        <path className="st18" d="M1468.9,676.7l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1468.9z M1473.5,670
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1473.5,670z"/>
                                        <path className="st18" d="M1484.7,676.7l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H1484.7z"/>
                                        <path className="st18" d="M1498.3,672.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V672.1z M1495.7,669.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V669.6z"/>
                                        <path className="st18" d="M1500.1,665.3v-2.7h2.6v2.7H1500.1z M1500.1,676.7v-10.3h2.6v10.3H1500.1z" />
                                        <path className="st18" d="M1504.5,670.8l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1504.5,670.8z"/>
                                        <path className="st18" d="M1518,676.7v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1518z"/>
                                        <path className="st18" d="M1527.2,676.7v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1527.2z"/>
                                        <path className="st18" d="M1536.2,676.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1536.2z"/>
                                        <path className="st18" d="M1548.1,676.7l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1548.1,676.7z M1543.4,671.3l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5V671l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1543.4,671.3z"/>
                                        <path className="st18" d="M1557.3,672.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V672.1z M1554.7,669.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V669.6z"/>
                                        <path className="st18" d="M1563.7,676.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1563.7z"/>
                                        <path className="st18" d="M1573.6,665.3v-2.7h2.6v2.7H1573.6z M1573.6,676.7v-10.3h2.6v10.3H1573.6z" />
                                        <path className="st18" d="M1582.8,676.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1582.8z"/>
                                        <path className="st18" d="M1597.5,676.7l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1597.5,676.7z M1597.3,670.1
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1597.3,670.1z"/>
                                        <path className="st18" d="M1609.3,672.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V672.1z M1606.6,669.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V669.6z"/>
                                        <path className="st18" d="M1611,676.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1611z"/>
                                        <path className="st18" d="M1479,698.7v-2.3l5.1-9.4h-4.5v-2.4h7.8v2.3l-5.3,9.4h5v2.4H1479z" />
                                        <path className="st18" d="M1488.8,687.3v-2.7h2.6v2.7H1488.8z M1488.8,698.7v-10.3h2.6v10.3H1488.8z" />
                                        <path className="st18" d="M1500.8,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1498.2,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1502.5,698.7v-14.1h2.7v14.1H1502.5z" />
                                        <path className="st18" d="M1514.5,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1511.9,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1516.2,698.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1516.2z"/>
                                        <path className="st18" d="M1522.9,698.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1522.9z"/>
                                        <path className="st18" d="M1537,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1534.3,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1538.7,687.3v-2.7h2.6v2.7H1538.7z M1538.7,698.7v-10.3h2.6v10.3H1538.7z" />
                                        <path className="st18" d="M1543.2,692.8l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1543.2,692.8z"/>
                                        <path className="st18" d="M1556.6,698.7v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1556.6z"/>
                                        <path className="st18" d="M1565.8,698.7v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H1565.8z"/>
                                        <path className="st18" d="M1574.9,698.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1574.9z"/>
                                        <path className="st18" d="M1586.7,698.7l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1586.7,698.7z M1582.1,693.3l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5V693l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1582.1,693.3z"/>
                                        <path className="st18" d="M1590.5,694.7v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1590.5,694.7z M1590.5,698.7V696h2.6v2.7H1590.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1470.2,814.3l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1470.2,814.3z"/>
                                        <path className="st18" d="M1481.6,814.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1481.6z M1480.2,809.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1480.2,809.9z"/>
                                        <path className="st18" d="M1489.8,806.9l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1489.8z"/>
                                        <path className="st18" d="M1504.1,814.3V808l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1504.1z"/>
                                        <path className="st18" d="M1513.2,814.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1513.2z M1511.8,809.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1511.8,809.9z"/>
                                        <path className="st18" d="M1516.8,805.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1516.8z"/>
                                        <path className="st18" d="M1533.4,814.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V808
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1533.4,814.3z M1533.2,807.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1533.2,807.7z"/>
                                        <path className="st18" d="M1545.2,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1542.5,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1546.9,814.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1546.9z"/>
                                        <path className="st18" d="M1566.4,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1563.7,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1568.1,802.9v-2.7h2.6v2.7H1568.1z M1568.1,814.3V804h2.6v10.3H1568.1z" />
                                        <path className="st18" d="M1577.2,814.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V804l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1577.2z"/>
                                        <path className="st18" d="M1581.5,814.3v-1.9l4.2-6.3h-3.6V804h6.7v1.8l-4.4,6.5h4.4v2H1581.5z" />
                                        <path className="st18" d="M1597.9,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1595.3,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1599.7,814.3v-14.1h2.7v14.1H1599.7z" />
                                        <path className="st18" d="M1608.8,814.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V804l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1608.8z"/>
                                        <path className="st18" d="M1620.8,809.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V809.7z M1618.1,807.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V807.2z"/>
                                        <path className="st18" d="M1472.7,836.3v-5.8l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1472.7,836.3z"/>
                                        <path className="st18" d="M1477.7,824.9v-2.7h2.6v2.7H1477.7z M1477.7,836.3V826h2.6v10.3H1477.7z" />
                                        <path className="st18" d="M1481.6,827.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1481.6z"/>
                                        <path className="st18" d="M1492.5,836.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1492.5z M1491.2,831.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1491.2,831.9z"/>
                                        <path className="st18" d="M1496.8,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1496.8z"/>
                                        <path className="st18" d="M1503.5,836.3l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1503.5z M1508.1,829.7
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1508.1,829.7z"/>
                                        <path className="st18" d="M1520,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1517.3,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1521.7,824.9v-2.7h2.6v2.7H1521.7z M1521.7,836.3V826h2.6v10.3H1521.7z" />
                                        <path className="st18" d="M1525.7,827.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1525.7z"/>
                                        <path className="st18" d="M1539.5,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1536.9,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1541.2,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1541.2z"/>
                                        <path className="st18" d="M1560.7,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1558.1,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1562.4,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1562.4z"/>
                                        <path className="st18" d="M1569.1,836.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1569.1z"/>
                                        <path className="st18" d="M1583.2,831.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V831.7z M1580.5,829.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V829.2z"/>
                                        <path className="st18" d="M1584.9,824.9v-2.7h2.6v2.7H1584.9z M1584.9,836.3V826h2.6v10.3H1584.9z" />
                                        <path className="st18" d="M1589.4,830.4l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1589.4,830.4z"/>
                                        <path className="st18" d="M1602.8,836.3V830l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1602.8z"/>
                                        <path className="st18" d="M1606.7,827.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1606.7z"/>
                                        <path className="st18" d="M1615.1,832.4v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1615.1,832.4z M1615.1,836.3v-2.7h2.6v2.7H1615.1z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1033.8,826.3l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1033.8,826.3z"/>
                                        <path className="st18" d="M1040.6,814.9v-2.7h2.6v2.7H1040.6z M1040.6,826.3V816h2.6v10.3H1040.6z" />
                                        <path className="st18" d="M1052.6,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1050,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1064.3,826.3V820l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1064.3z"/>
                                        <path className="st18" d="M1073.4,826.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1073.4z M1072,821.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1072,821.9z"/>
                                        <path className="st18" d="M1082.2,826.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V816l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1082.2z"/>
                                        <path className="st18" d="M1091.5,826.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V820
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1091.5,826.3z M1091.3,819.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1091.3,819.7z"/>
                                        <path className="st18" d="M1103.3,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1100.7,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1105,826.3v-14.1h2.7v14.1H1105z" />
                                        <path className="st18" d="M1114.2,826.3v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0V816l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1114.2z"/>
                                        <path className="st18" d="M1128.9,826.3l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9V820
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1128.9,826.3z M1128.7,819.7
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1128.7,819.7z"/>
                                        <path className="st18" d="M1133.3,814.9v-2.7h2.6v2.7H1133.3z M1133.3,826.3V816h2.6v10.3H1133.3z" />
                                        <path className="st18" d="M1145.2,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1142.6,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1162.4,826.3v-5.8l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1162.4,826.3z"/>
                                        <path className="st18" d="M1167.4,814.9v-2.7h2.6v2.7H1167.4z M1167.4,826.3V816h2.6v10.3H1167.4z" />
                                        <path className="st18" d="M1171.3,817.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1171.3z"/>
                                        <path className="st18" d="M1182.2,826.3l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1182.2z M1180.9,821.9l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1180.9,821.9z"/>
                                        <path className="st18" d="M1186.5,826.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1186.5z"/>
                                        <path className="st18" d="M1193.2,826.3l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1193.2z M1197.8,819.7
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1197.8,819.7z"/>
                                        <path className="st18" d="M1209.7,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1207,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1211.4,814.9v-2.7h2.6v2.7H1211.4z M1211.4,826.3V816h2.6v10.3H1211.4z" />
                                        <path className="st18" d="M1215.4,817.8v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1215.4z"/>
                                        <path className="st18" d="M1229.2,821.7h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V821.7z M1226.6,819.2l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V819.2z"/>
                                        <path className="st18" d="M1230.9,826.3l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1230.9z"/>
                                        <path className="st18" d="M1239.6,822.4v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1239.6,822.4z M1239.5,826.3v-2.7h2.6v2.7H1239.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M1226.7,654.7l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L1226.7,654.7z"/>
                                        <path className="st18" d="M1233.6,643.3v-2.7h2.6v2.7H1233.6z M1233.6,654.7v-10.3h2.6v10.3H1233.6z" />
                                        <path className="st18" d="M1245.5,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1242.9,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1257.8,654.7l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H1257.8z" />
                                        <path className="st18" d="M1266.3,654.7l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1266.3z M1265,650.3l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1265,650.3z"/>
                                        <path className="st18" d="M1275.1,654.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1275.1z"/>
                                        <path className="st18" d="M1284.3,654.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1284.3z"/>
                                        <path className="st18" d="M1296.9,655.4l-0.1,1.2l-0.4,0.9l-0.6,0.5l-0.6,0.2l-1.4,0.1l-0.9-0.1l0-2.2l0.9-0.1l0.4-0.4l0.1-0.8v-10.4
			h2.6V655.4z M1294.3,643.3v-2.7h2.6v2.7H1294.3z"/>
                                        <path className="st18" d="M1306.2,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1303.6,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1312.7,654.7l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L1312.7,654.7z M1312.5,648.1
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L1312.5,648.1z"/>
                                        <path className="st18" d="M1324.5,650.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V650.1z M1321.8,647.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V647.6z"/>
                                        <path className="st18" d="M1326.2,654.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1326.2z"/>
                                        <path className="st18" d="M1203.9,676.7v-5.8l0-3.7l0.1-2.1l-0.1,1l-0.3,1.5l-0.3,1.8l-1.6,7.3l-2.7,0l-1.6-7.1l-0.4-2.4l-0.3-1.5
			l-0.1-1.1l0,1.4l0.2,2.8l0,8l-2.7,0l-0.1-14.1l4.2,0l1.6,7.9l0.4,2l0.1,1.5l0.2-1.8l0.2-1.3l1.6-8.3h4.1l0,14.1L1203.9,676.7z"/>
                                        <path className="st18" d="M1208.8,665.3v-2.7h2.6v2.7H1208.8z M1208.8,676.7v-10.3h2.6v10.3H1208.8z" />
                                        <path className="st18" d="M1212.8,668.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1212.8z"/>
                                        <path className="st18" d="M1223.7,676.7l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1223.7z M1222.4,672.3l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1222.4,672.3z"/>
                                        <path className="st18" d="M1227.9,676.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1227.9z"/>
                                        <path className="st18" d="M1234.6,676.7l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1234.6z M1239.2,670
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1239.2,670z"/>
                                        <path className="st18" d="M1251.1,672.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V672.1z M1248.5,669.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V669.6z"/>
                                        <path className="st18" d="M1252.9,665.3v-2.7h2.6v2.7H1252.9z M1252.9,676.7v-10.3h2.6v10.3H1252.9z" />
                                        <path className="st18" d="M1256.8,668.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1256.8z"/>
                                        <path className="st18" d="M1270.7,672.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V672.1z M1268,669.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V669.6z"/>
                                        <path className="st18" d="M1272.4,676.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1272.4z"/>
                                        <path className="st18" d="M1293.6,676.7v-6.2l0-0.8l-0.1-0.7l-0.3-0.4l-0.5-0.2l-0.6,0.2l-0.3,0.3l-0.1,1.5l0,6.2h-2.7l0-6.9l-0.1-0.8
			l-0.3-0.3l-0.5-0.2l-0.6,0.2l-0.3,0.4l-0.1,1.6v5.9h-2.6v-10.3h2.5v1.4l0.7-0.9l0.8-0.5l0.9-0.2l0.9,0.1l0.8,0.5l0.4,0.7l0.4-0.5
			l0.6-0.5l0.6-0.3l0.9-0.1l0.9,0.1l0.5,0.3l0.5,0.5l0.4,1.2l0,0.7v7.6L1293.6,676.7z"/>
                                        <path className="st18" d="M1305.5,673.7l-0.3,1.4l-0.8,1.1l-1,0.6l-1.5,0.2l-1.1,0l-0.9-0.4l-0.7-0.4l-0.4-0.5l-0.4-0.8l-0.2-0.9
			l0-2.7l0-1.2l0.3-1.8l0.5-0.8l0.8-0.6l1.1-0.4l1.2-0.1l1.5,0.2l1.3,0.7l0.6,1l0.3,1.4l0,2L1305.5,673.7z M1301.3,664.6h-2v-2.1h2
			V664.6z M1302.9,669.6l-0.2-1.1l-0.3-0.3l-0.5-0.2l-0.6,0.3l-0.2,0.4l-0.1,3.5l0,1.5l0.2,0.9l0.3,0.2l0.5,0.1l0.7-0.3l0.2-0.5
			l0.1-2.9L1302.9,669.6z M1304.4,664.6h-2v-2.1h2V664.6z"/>
                                        <path className="st18" d="M1314.6,676.7l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0
			l0,0.5l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5
			l0-2.3l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L1314.6,676.7z M1310,671.3l0,1.5
			l0.1,0.9l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5V671l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1
			L1310,671.3z"/>
                                        <path className="st18" d="M1316.5,676.7v-14.1h2.7v14.1H1316.5z" />
                                        <path className="st18" d="M1321,665.3v-2.7h2.6v2.7H1321z M1321,676.7v-10.3h2.6v10.3H1321z" />
                                        <path className="st18" d="M1325.5,670.8l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L1325.5,670.8z"/>
                                        <path className="st18" d="M1338.9,676.7v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H1338.9z"/>
                                        <path className="st18" d="M1347.5,669.3l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6
			l0.6,0.5l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8
			v-0.6h2.6v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1
			l0.3-1.2l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H1347.5z"/>
                                        <path className="st18" d="M1351.2,668.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1351.2z"/>
                                        <path className="st18" d="M1213.5,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1210.9,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1215.7,698.7v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1215.7z"/>
                                        <path className="st18" d="M1221.5,698.7v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H1221.5z"/>
                                        <path className="st18" d="M1226.9,687.3v-2.7h2.6v2.7H1226.9z M1226.9,698.7v-10.3h2.6v10.3H1226.9z" />
                                        <path className="st18" d="M1231.2,698.7v-1.9l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H1231.2z" />
                                        <path className="st18" d="M1240.2,687.3v-2.7h2.6v2.7H1240.2z M1240.2,698.7v-10.3h2.6v10.3H1240.2z" />
                                        <path className="st18" d="M1252.2,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1249.5,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1258.5,698.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1258.5z"/>
                                        <path className="st18" d="M1262.4,690.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1262.4z"/>
                                        <path className="st18" d="M1278.8,698.7l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H1278.8z M1277.4,694.3l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L1277.4,694.3z"/>
                                        <path className="st18" d="M1283,698.7l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H1283z"/>
                                        <path className="st18" d="M1289.7,698.7l0-14.1h2.6v5.4l0.3-0.7l0.5-0.6l0.6-0.4l0.8-0.1l0.8,0.1l0.6,0.3l0.5,0.5l0.3,0.6l0.3,1.2
			l0,1.7v2.9l-0.2,1.4l-0.3,0.8l-0.4,0.6l-0.5,0.4l-0.6,0.3l-0.6,0l-0.9-0.1l-0.6-0.5l-0.6-0.9l-0.2-0.7v2H1289.7z M1294.3,692
			l-0.2-1l-0.5-0.5l-0.4-0.1l-0.5,0.2l-0.2,0.3l-0.2,1.1v3.4l0.1,0.7l0.3,0.4l0.6,0.2l0.4-0.1l0.4-0.5l0.1-1.4L1294.3,692z"/>
                                        <path className="st18" d="M1306.2,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1303.6,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1307.9,687.3v-2.7h2.6v2.7H1307.9z M1307.9,698.7v-10.3h2.6v10.3H1307.9z" />
                                        <path className="st18" d="M1311.9,690.2v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3
			l-0.7-0.5l-0.5-1l-0.1-0.8v-6H1311.9z"/>
                                        <path className="st18" d="M1325.7,694.1h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V694.1z M1323.1,691.6l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V691.6z"/>
                                        <path className="st18" d="M1332,698.7v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H1332z"/>
                                        <path className="st18" d="M1338.6,694.7v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L1338.6,694.7z M1338.5,698.7V696h2.6v2.7H1338.5z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M703.7,449.6l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L703.7,449.6z"/>
                                        <path className="st18" d="M715.1,449.6l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4L714,441l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H715.1z M713.7,445.2l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L713.7,445.2z"/>
                                        <path className="st18" d="M723.3,442.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5L723,446l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H723.3z"/>
                                        <path className="st18" d="M737,442.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H737z"/>
                                        <path className="st18" d="M748.6,446.2l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L748.6,446.2z
			 M743.9,445.1l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1
			l-0.1,1.6L743.9,445.1z"/>
                                        <path className="st18" d="M750.5,449.6v-14.1h2.7v14.1H750.5z" />
                                        <path className="st18" d="M755,449.6v-14.1h2.7v14.1H755z" />
                                        <path className="st18" d="M767.6,450.3l-0.1,1.2l-0.4,0.9l-0.6,0.5l-0.6,0.2l-1.4,0.1l-0.9-0.1l0-2.2l0.9-0.1l0.4-0.4l0.1-0.8v-10.4
			h2.6V450.3z M765,438.2v-2.7h2.6v2.7H765z"/>
                                        <path className="st18" d="M777,445h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V445z M774.3,442.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V442.5z"/>
                                        <path className="st18" d="M783.5,449.6l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L783.5,449.6z M783.3,443
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L783.3,443z"/>
                                        <path className="st18" d="M795.2,445h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V445z M792.6,442.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V442.5z"/>
                                        <path className="st18" d="M797,449.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H797z"/>
                                        <path className="st18" d="M688.6,471.6v-14.1h6.7v2.6h-3.8v3h3.4v2.5h-3.4v3.4h3.8v2.6H688.6z" />
                                        <path className="st18" d="M697.1,460.2v-2.7h2.6v2.7H697.1z M697.1,471.6v-10.3h2.6v10.3H697.1z" />
                                        <path className="st18" d="M706.2,471.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H706.2z"/>
                                        <path className="st18" d="M710.5,471.6v-1.9l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H710.5z" />
                                        <path className="st18" d="M726.9,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M724.3,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M728.6,471.6v-14.1h2.7v14.1H728.6z" />
                                        <path className="st18" d="M737.8,471.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H737.8z"/>
                                        <path className="st18" d="M749.7,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H746l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M747.1,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3L745,464l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M761.7,471.6l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L761.7,471.6z M761.5,465
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L761.5,465z"/>
                                        <path className="st18" d="M766,460.2v-2.7h2.6v2.7H766z M766,471.6v-10.3h2.6v10.3H766z" />
                                        <path className="st18" d="M778,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M775.3,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M783.7,464.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H783.7z"/>
                                        <path className="st18" d="M795.4,467h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V467z M792.8,464.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V464.5z"/>
                                        <path className="st18" d="M801.2,464.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8L803,471l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H801.2z"/>
                                        <path className="st18" d="M696.4,488.9l0,1.3l-0.2,1.2l-0.5,1.1l-1,0.8l-0.9,0.4l-1,0l-1,0l-0.7-0.3l-0.9-0.7l-0.6-1l-0.3-1.1l0-1.1
			l0-1.2h2.7l0,0.8l0.1,1.9l0.3,0.3l0.4,0.1l0.4-0.1l0.3-0.6l0.1-1v-10.2h2.9L696.4,488.9z"/>
                                        <path className="st18" d="M703,493.6l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4L702,485l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H703z M701.6,489.2l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5
			l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L701.6,489.2z"/>
                                        <path className="st18" d="M711.8,493.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H711.8z"/>
                                        <path className="st18" d="M716.4,493.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H716.4z"/>
                                        <path className="st18" d="M735.8,489H731l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V489z M733.2,486.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3L731,486l-0.1,1.2h2.2V486.5z"/>
                                        <path className="st18" d="M737.6,493.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H737.6z"/>
                                        <path className="st18" d="M744.2,493.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			L747,487l0,1v5.6H744.2z"/>
                                        <path className="st18" d="M758.3,489h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V489z M755.6,486.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V486.5z"/>
                                        <path className="st18" d="M760,482.2v-2.7h2.6v2.7H760z M760,493.6v-10.3h2.6v10.3H760z" />
                                        <path className="st18" d="M764.5,487.7l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L764.5,487.7z"/>
                                        <path className="st18" d="M777.9,493.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H777.9z"/>
                                        <path className="st18" d="M789.9,489H785l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V489z M787.2,486.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V486.5z"/>
                                        <path className="st18" d="M796.2,493.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H796.2z"/>
                                        <path className="st18" d="M802.7,489.6v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L802.7,489.6z M802.7,493.6v-2.7h2.6v2.7H802.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M415.6,461.6l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L415.6,461.6z"/>
                                        <path className="st18" d="M429.8,457H425l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M427.2,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M431.5,461.6v-14.1h2.7v14.1H431.5z" />
                                        <path className="st18" d="M436,455.7l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L436,455.7z"/>
                                        <path className="st18" d="M449.5,461.6v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H449.5z"/>
                                        <path className="st18" d="M461.4,457h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M458.8,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M474.7,461.6l-0.2-0.5l-0.2-1.9v-0.8l-0.1-1.3l-0.1-0.4l-0.3-0.5l-0.5-0.2l-0.4-0.1h-1.1v5.7h-2.9v-14.1h5.3
			l1,0.2l0.7,0.3l0.6,0.5l0.6,1.1l0.2,1.8l0,0.9l-0.2,1.2l-0.6,1l-0.8,0.4l0.9,0.5l0.5,1l0.1,1v0.3l0.2,2.2l0.3,1.4l0.2,0.4H474.7z
			 M473.4,453.5l0.4-0.1l0.4-0.5l0.1-1.1l0-0.9l-0.1-0.4l-0.4-0.5l-1.1-0.1h-0.9v3.7H473.4z"/>
                                        <path className="st18" d="M486.4,457h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M483.7,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M492.1,454.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H492.1z"/>
                                        <path className="st18" d="M500.4,454.2l-0.1-0.9L500,453l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H500.4z"/>
                                        <path className="st18" d="M512,458.2l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L512,458.2z M507.3,457.1l0.1,1.5
			l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6L507.3,457.1z"
                                        />
                                        <path className="st18" d="M518.5,461.6v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H518.5z"/>
                                        <path className="st18" d="M523,461.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H523z"/>
                                        <path className="st18" d="M529.6,455.7l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L529.6,455.7z"/>
                                        <path className="st18" d="M545.8,457H541l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V457z M543.2,454.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3L541,454l-0.1,1.2h2.2V454.5z"/>
                                        <path className="st18" d="M552.1,461.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H552.1z"/>
                                        <path className="st18" d="M427.7,476.2l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H427.7z"/>
                                        <path className="st18" d="M439.4,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M436.7,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M440.5,475.1v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H440.5z"/>
                                        <path className="st18" d="M446.7,483.6v-1.9l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H446.7z" />
                                        <path className="st18" d="M463.1,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M460.5,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M469.4,483.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H469.4z"/>
                                        <path className="st18" d="M486,483.6l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H486z"/>
                                        <path className="st18" d="M492.3,472.2v-2.7h2.6v2.7H492.3z M492.3,483.6v-10.3h2.6v10.3H492.3z" />
                                        <path className="st18" d="M496.8,483.6l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H496.8z"/>
                                        <path className="st18" d="M516.3,479h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V479z M513.7,476.5l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V476.5z"/>
                                        <path className="st18" d="M518,472.2v-2.7h2.6v2.7H518z M518,483.6v-10.3h2.6v10.3H518z" />
                                        <path className="st18" d="M527.2,483.6v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H527.2z"/>
                                        <path className="st18" d="M533.7,479.6v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L533.7,479.6z M533.7,483.6v-2.7h2.6v2.7H533.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M149.1,312l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7L146,312l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L149.1,312z"/>
                                        <path className="st18" d="M156,300.5v-2.7h2.6v2.7H156z M156,312v-10.3h2.6V312H156z" />
                                        <path className="st18" d="M167.9,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M165.3,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M175.1,315.7v-14.1h2.5v2l0.6-1.3l1-0.8l0.8-0.1l0.7,0.1l0.6,0.3l0.5,0.6l0.3,0.6l0.2,1.4l0.1,3v1.4
			l-0.1,1.1l-0.4,1l-0.7,0.8l-0.6,0.3l-0.8,0.1L179,312l-0.7-0.5l-0.4-0.7l-0.2-0.5l0,0.9v4.5L175.1,315.7z M177.7,307.3l0.1,1.2
			l0.2,0.9l0.4,0.3l0.3,0.1l0.5-0.2l0.4-0.7l0.1-2.3l0-1.3l-0.1-1.1l-0.3-0.4l-0.6-0.3l-0.5,0.1l-0.4,0.6l-0.1,1.9V307.3z"/>
                                        <path className="st18" d="M184.2,312v-14.1h2.7V312H184.2z" />
                                        <path className="st18" d="M193.3,312l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H193.3z M191.9,307.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L191.9,307.6z"/>
                                        <path className="st18" d="M202.1,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H202.1z"/>
                                        <path className="st18" d="M214.1,307.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V307.4z M211.4,304.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V304.8z"/>
                                        <path className="st18" d="M220.4,312V306l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H220.4z"/>
                                        <path className="st18" d="M237,312l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9l0,1.1
			l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H237z"/>
                                        <path className="st18" d="M243.2,300.5v-2.7h2.6v2.7H243.2z M243.2,312v-10.3h2.6V312H243.2z" />
                                        <path className="st18" d="M247.8,312l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H247.8z"/>
                                        <path className="st18" d="M264.6,312l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6V312L264.6,312z M264.4,305.3l-0.2-1.2
			l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3L264.4,305.3z"
                                        />
                                        <path className="st18" d="M273.5,312l0-1.4l-0.4,0.8l-0.4,0.4L272,312l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H273.5z M272.2,307.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L272.2,307.6z"/>
                                        <path className="st18" d="M281.7,304.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5L278,306l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H281.7z"/>
                                        <path className="st18" d="M149.9,334v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H149.9z"/>
                                        <path className="st18" d="M161.9,329.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V329.4z M159.3,326.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V326.8z"/>
                                        <path className="st18" d="M168.3,334v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V334H168.3z"/>
                                        <path className="st18" d="M172.2,325.4v-1.9h1V321h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H172.2z"/>
                                        <path className="st18" d="M178.6,322.5v-2.7h2.6v2.7H178.6z M178.6,334v-10.3h2.6V334H178.6z" />
                                        <path className="st18" d="M190.4,334l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L190.4,334z M185.8,328.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L185.8,328.6z"/>
                                        <path className="st18" d="M199.7,329.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2H196l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V329.4z M197.1,326.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V326.8z"/>
                                        <path className="st18" d="M212.7,334l0-1.2l-0.3,0.5l-0.8,0.6l-0.6,0.3l-0.8,0l-0.8,0l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4l0-2l0.1-3
			l0.1-1.5l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3l0,0.9h-2.8v-0.6l-0.1-1.4l-0.4-0.4
			l-0.8-0.3l-0.5,0.1l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6l0,1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1l0-1.1v-0.8H211V326
			h4.2v7.9H212.7z"/>
                                        <path className="st18" d="M224.6,329.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V329.4z M222,326.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V326.8z"/>
                                        <path className="st18" d="M230.4,326.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H230.4z"/>
                                        <path className="st18" d="M234.6,328.1l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7
			l0.2,0.7l0.1,1.5l-2.6,0V327l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8
			l0.1-1.2h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5
			L234.6,328.1z"/>
                                        <path className="st18" d="M248,334v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3l0.7-0.5
			l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H248z"/>
                                        <path className="st18" d="M257.1,334l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H257.1z M255.7,321.9h-2v-2.1h2V321.9z M255.7,329.6
			l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L255.7,329.6z M258.8,321.9h-2v-2.1h2V321.9z"/>
                                        <path className="st18" d="M261.7,334v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H261.7z"/>
                                        <path className="st18" d="M266.5,325.4v-1.9h1V321h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H266.5z"/>
                                        <path className="st18" d="M274.9,330v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L274.9,330z M274.9,334v-2.7h2.6v2.7H274.9z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M427.2,313l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L427.2,313z"/>
                                        <path className="st18" d="M434.1,301.5v-2.7h2.6v2.7H434.1z M434.1,313v-10.3h2.6V313H434.1z" />
                                        <path className="st18" d="M438.6,313l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H438.6z"/>
                                        <path className="st18" d="M458,309.6l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7l-0.1-0.9
			l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L458,309.6z M453.2,308.5l0.1,1.5
			l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1l-0.1,1.6L453.2,308.5z"
                                        />
                                        <path className="st18" d="M459.8,313l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H459.8z"/>
                                        <path className="st18" d="M473.8,313l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L473.8,313z M469.1,307.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L469.1,307.6z"/>
                                        <path className="st18" d="M480.1,313l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H480.1z M478.8,308.6l-0.7,0.5l-0.2,0.4l-0.1,0.6l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L478.8,308.6z"/>
                                        <path className="st18" d="M489,313V307l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L487,307v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H489z"/>
                                        <path className="st18" d="M493.5,301.5v-2.7h2.6v2.7H493.5z M493.5,313v-10.3h2.6V313H493.5z" />
                                        <path className="st18" d="M502.1,305.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H502.1z"/>
                                        <path className="st18" d="M506.4,301.5v-2.7h2.6v2.7H506.4z M506.4,313v-10.3h2.6V313H506.4z" />
                                        <path className="st18" d="M518.4,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M515.7,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M520.1,313l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H520.1z"/>
                                        <path className="st18" d="M534.2,308.4h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V308.4z M531.5,305.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V305.8z"/>
                                        <path className="st18" d="M540.5,313V307l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H540.5z"/>
                                        <path className="st18" d="M456.3,335l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H456.3z"/>
                                        <path className="st18" d="M462.5,323.5v-2.7h2.6v2.7H462.5z M462.5,335v-10.3h2.6V335H462.5z" />
                                        <path className="st18" d="M467.1,335l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H467.1z"/>
                                        <path className="st18" d="M483.8,335v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7V335H483.8z"/>
                                        <path className="st18" d="M492.9,335V329l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L491,329v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H492.9z"/>
                                        <path className="st18" d="M501.4,327.6l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H501.4z"/>
                                        <path className="st18" d="M507.7,331v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L507.7,331z M507.7,335v-2.7h2.6v2.7H507.7z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M163.5,200.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L163.5,200.9z"/>
                                        <path className="st18" d="M170.3,189.5v-2.7h2.6v2.7H170.3z M170.3,200.9v-10.3h2.6v10.3H170.3z" />
                                        <path className="st18" d="M182.3,196.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L182,199l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V196.3z M179.6,193.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V193.8z"/>
                                        <path className="st18" d="M196.8,196.3H192l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V196.3z M194.2,193.8L194,193l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V193.8z"/>
                                        <path className="st18" d="M203.1,200.9V195l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H203.1z"/>
                                        <path className="st18" d="M207.1,192.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H207.1z"/>
                                        <path className="st18" d="M220.2,200.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H220.2z"/>
                                        <path className="st18" d="M226.4,189.5v-2.7h2.6v2.7H226.4z M226.4,200.9v-10.3h2.6v10.3H226.4z" />
                                        <path className="st18" d="M230.9,195l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L230.9,195z"/>
                                        <path className="st18" d="M244.8,200.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H244.8z" />
                                        <path className="st18" d="M256.3,196.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L256,199l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L249,199l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V196.3z M253.6,193.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V193.8z"/>
                                        <path className="st18" d="M258,200.9v-14.1h2.7v14.1H258z" />
                                        <path className="st18" d="M267.1,200.9V195l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H267.1z"/>
                                        <path className="st18" d="M149.8,222.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H149.8z"/>
                                        <path className="st18" d="M156,211.5v-2.7h2.6v2.7H156z M156,222.9v-10.3h2.6v10.3H156z" />
                                        <path className="st18" d="M160.5,222.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H160.5z"/>
                                        <path className="st18" d="M177.4,222.9l0-1.9l-0.3,0.8l-0.7,0.9l-0.6,0.4l-0.8,0.1l-0.6,0l-0.8-0.4l-0.6-0.7l-0.3-0.9l-0.1-1.9v-2.5
			l0-1.8l0.3-0.9l0.5-0.8l0.8-0.6l0.9-0.2l0.7,0.1l0.8,0.5l0.4,0.6l0.3,0.6l-0.1-1.1v-4.4h2.6v14.1L177.4,222.9z M177.2,216.3
			l-0.2-1.2l-0.3-0.3l-0.4-0.1l-0.4,0.1l-0.4,0.3l-0.2,0.9l0,1.4l0,1.7l0.1,1l0.3,0.6l0.6,0.2l0.4-0.1l0.4-0.4l0.1-0.3l0.1-2.3
			L177.2,216.3z"/>
                                        <path className="st18" d="M186.2,222.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8h-2.5l0.2-1.5l0.5-0.9
			l0.8-0.6l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H186.2z M184.9,218.5l-0.7,0.5l-0.2,0.4L184,220l0,0.4
			l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L184.9,218.5z"/>
                                        <path className="st18" d="M194.5,215.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H194.5z"/>
                                        <path className="st18" d="M203.9,222.9V221l4.2-6.3h-3.6v-2.1h6.7v1.8l-4.4,6.5h4.4v2H203.9z" />
                                        <path className="st18" d="M217.6,222.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H217.6z"/>
                                        <path className="st18" d="M227.2,222.9l-2.4-5.4v5.4h-2.6v-14.1h2.6v8.3l2.4-4.5h3l-2.7,4.8l2.6,5.5H227.2z" />
                                        <path className="st18" d="M235.9,222.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H235.9z M234.4,210.9h-2v-2.1h2V210.9z M237.4,210.9h-2v-2.1h2
			V210.9z"/>
                                        <path className="st18" d="M244.9,222.9V217l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4L243,217v5.9l-2.7,0v-10.3l2.6,0v1.1l0.4-0.5
			l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H244.9z"/>
                                        <path className="st18" d="M249.9,222.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H249.9z"/>
                                        <path className="st18" d="M254.7,214.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H254.7z"/>
                                        <path className="st18" d="M261.1,211.5v-2.7h2.6v2.7H261.1z M261.1,222.9v-10.3h2.6v10.3H261.1z" />
                                        <path className="st18" d="M273,222.9l-0.2,1.7l-0.5,0.9l-1.1,0.8l-0.8,0.2l-1,0l-1.9-0.2l-0.8-0.4l-0.5-0.7l-0.2-0.6l0-1l2.5,0l0,0.5
			l0.3,0.4l0.7,0.2l0.7-0.3l0.3-0.7l0-0.8l0-1.8l-0.4,0.5l-0.7,0.7l-0.6,0.3l-0.7,0.1l-1.1-0.2l-0.5-0.5l-0.4-0.8l-0.3-1.5l0-2.3
			l0.1-2.4l0.2-0.8l0.4-0.7l0.8-0.7l1.1-0.2l0.7,0.1l0.8,0.6l0.4,0.6l0.3,0.6l0-0.6v-1h2.5L273,222.9z M268.3,217.6l0,1.5l0.1,0.9
			l0.2,0.3l0.6,0.2l0.6-0.2l0.3-0.4l0.2-1.5v-1.1l-0.1-1.3l-0.1-0.8l-0.4-0.3l-0.4-0.1l-0.6,0.2l-0.3,0.7l-0.1,1.1L268.3,217.6z"/>
                                        <path className="st18" d="M282.2,218.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7L282,221l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7L275,221l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V218.3z M279.6,215.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V215.8z"/>
                                        <path className="st18" d="M182,244.9l0-1.2l-0.3,0.5l-0.8,0.6l-0.6,0.3l-0.8,0l-0.8,0l-1.1-0.5l-0.7-0.8l-0.6-1.5l-0.1-2.4l0-2l0.1-3
			l0.1-1.5l0.6-1.4l1-0.9l1.1-0.4l1.5-0.2l1,0.1l1.1,0.4l0.7,0.5l0.6,0.8l0.3,0.9l0.2,1.3l0,0.9h-2.8v-0.6l-0.1-1.4l-0.4-0.4
			l-0.8-0.3l-0.5,0.1l-0.5,0.4l-0.3,1.2l-0.1,1.9v3.6l0,1.2l0.3,1.1l0.4,0.4l0.6,0.2l0.6-0.2l0.5-0.4l0.2-1.1l0-1.1v-0.8h-1.5V237
			h4.2v7.9H182z"/>
                                        <path className="st18" d="M193.9,240.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V240.3z M191.3,237.8l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V237.8z"/>
                                        <path className="st18" d="M199.6,237.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H199.6z"/>
                                        <path className="st18" d="M203.9,239l0-1.1l0.1-1.1l0.3-0.8l0.4-0.5l0.5-0.4l0.8-0.4l1.3-0.2l1.3,0.1l0.9,0.3l0.8,0.6l0.4,0.7l0.2,0.7
			l0.1,1.5l-2.6,0v-0.4l-0.2-1.3l-0.3-0.2l-0.5-0.2l-0.6,0.3l-0.3,1l0,1v1.5l0.1,2l0.1,0.6l0.4,0.5l0.5,0.1l0.6-0.3l0.2-0.8l0.1-1.2
			h2.7l-0.1,1.3l-0.4,1.3l-0.7,0.8l-0.8,0.4l-0.7,0.2l-1.1,0.1l-1.6-0.2l-1-0.6l-0.4-0.5l-0.3-0.6l-0.2-1l-0.1-1.5L203.9,239z"/>
                                        <path className="st18" d="M217.3,244.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H217.3z"/>
                                        <path className="st18" d="M226.3,244.9l0-1.4l-0.4,0.8l-0.4,0.4l-0.6,0.3l-0.9,0.1l-0.7,0l-0.5-0.3l-0.5-0.5l-0.5-1l-0.1-1.1l0-0.8
			l0.4-1.2l0.7-0.6l2.3-1l0.2-0.1l0.6-0.4l0.2-0.7l-0.1-0.6l-0.4-0.4l-0.3-0.1l-0.5,0.2l-0.3,0.5l0,0.8H222l0.2-1.5l0.5-0.9l0.8-0.6
			l1.1-0.3l0.8,0l1,0.1l1,0.3l0.7,0.6l0.5,0.9l0.2,1.5v6.3l0.3,0.9H226.3z M225,232.9h-2v-2.1h2V232.9z M225,240.5l-0.7,0.5
			l-0.2,0.4L224,242l0,0.4l0.3,0.5l0.5,0.2l0.6-0.2l0.5-0.6l0.1-0.6v-1.6L225,240.5z M228,232.9h-2v-2.1h2V232.9z"/>
                                        <path className="st18" d="M231,244.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H231z"/>
                                        <path className="st18" d="M235.8,236.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9h-1.4v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H235.8z"/>
                                        <path className="st18" d="M244.2,241v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2l-0.3,0.6
			l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7l0.1,1
			l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L244.2,241z M244.2,244.9v-2.7h2.6v2.7H244.2z"/>
                                    </g>
                                    <g>
                                        <path className="st18" d="M151.6,111.9l-1.2-7.2l-0.3-3.1l-0.2,2.2l-0.3,1.7l-1.2,6.3l-3.3,0l-2.5-14.1l2.8,0l1.3,8.6l0.1,1.1l0,1.3
			l0.1-1.9l0.3-2l1.3-7l3.1,0l1.1,7.4l0.2,2.2l0,1.4l0.2-2.2l1.3-8.7h2.7l-2.5,14.1L151.6,111.9z"/>
                                        <path className="st18" d="M165.8,108.5l-0.3,1.6l-0.4,0.7l-0.7,0.6l-1,0.5l-1.3,0.1h-0.9l-1.2-0.4l-0.7-0.5l-0.5-0.7l-0.3-0.7
			l-0.1-0.9l0-2.5l0-1.5l0.3-1.6l0.7-0.9l1.2-0.7l1.6-0.3l1.3,0.2l0.9,0.4l0.8,0.7l0.4,0.7l0.2,0.8l0.1,2.4L165.8,108.5z M161,107.4
			l0.1,1.5l0.2,1l0.3,0.3l0.5,0.1l0.4-0.1l0.3-0.2l0.2-0.5l0.1-2.9l0-1.8l-0.2-1l-0.3-0.3l-0.5-0.1l-0.6,0.2l-0.3,1L161,106
			L161,107.4z"/>
                                        <path className="st18" d="M168,111.9v-8.5l-1,0v-1.9l1,0v-1.3l0.2-1.3l0.5-0.6l0.7-0.4l1.6-0.2l1,0v2.2l-0.7,0.1l-0.4,0.1l-0.2,0.4
			l0,0.6v0.4h1.3v1.9h-1.3v8.5H168z"/>
                                        <path className="st18" d="M178.1,111.9v-1l-0.8,0.8l-0.7,0.3l-0.9,0.1l-1-0.1l-0.5-0.3l-0.4-0.6l-0.3-0.8l0-1.2l0-0.8v-6.6h2.7v6.9
			l0.1,0.7l0.3,0.5l0.5,0.2l0.6-0.3l0.3-0.6l0.1-1.8v-5.6h2.7v10.3H178.1z M176.6,99.9h-2v-2.1h2V99.9z M179.6,99.9h-2v-2.1h2V99.9z
			"/>
                                        <path className="st18" d="M182.6,111.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H182.6z"/>
                                        <path className="st18" d="M198.6,104.5l-0.1-0.9l-0.3-0.3l-0.4-0.1l-0.5,0.2l-0.2,0.2l-0.1,0.4l0.1,0.6l1.1,0.7l0.8,0.4l1,0.6l0.6,0.5
			l0.4,0.6l0.3,1.5l-0.1,1l-0.3,0.8l-0.4,0.5l-0.6,0.4l-1.2,0.4l-0.9,0l-1-0.1l-1.1-0.2l-0.7-0.5l-0.5-0.7l-0.3-0.8l0-0.8v-0.6h2.6
			v0.6l0.2,0.9l0.3,0.2l0.5,0.1l0.6-0.2l0.3-0.9l-0.1-0.5l-0.3-0.4l-0.8-0.6l-1.1-0.6l-0.7-0.5l-0.9-0.8l-0.3-0.7l-0.1-1l0.3-1.2
			l0.8-1l1-0.5l1.5-0.2l1,0.1l1.4,0.6l0.6,0.6l0.2,0.5l0.2,1.4H198.6z"/>
                                        <path className="st18" d="M202.3,103.4v-1.9h1v-2.6h2.6v2.6h1.4v1.9H206v5.2l0.1,0.5l0.3,0.4l1.1,0.1v2.3l-1.9,0.1l-0.9-0.3l-0.7-0.5
			l-0.5-1l-0.1-0.8v-6H202.3z"/>
                                        <path className="st18" d="M216.2,107.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V107.3z M213.5,104.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V104.7z"/>
                                        <path className="st18" d="M222.5,111.9v-6.3l-0.2-1.6l-0.3-0.2l-0.5-0.1l-0.7,0.2l-0.3,0.7l0,1.1v6.3h-2.6l0-14.1h2.7v4.7l0.3-0.3
			l0.7-0.5l1.3-0.3l1.2,0.3l0.6,0.5l0.4,1.1l0.1,1.1v7.6H222.5z"/>
                                        <path className="st18" d="M234.4,107.3h-4.8l0.1,2l0.5,0.8l0.6,0.2l0.6-0.2l0.4-0.7l0.1-0.8h2.7l-0.2,1.5l-0.3,0.7l-0.4,0.5l-1,0.7
			l-0.8,0.2h-0.9l-1.1,0l-0.8-0.3l-1-0.7l-0.6-1.1l-0.3-1.5l0-1.5l0.1-2.6l0.2-0.9l0.4-0.7l0.7-0.7l0.6-0.3l0.7-0.2l1.2-0.1l1.7,0.3
			l0.7,0.5l0.6,0.6l0.5,1.5l0.1,2.3V107.3z M231.8,104.7l-0.2-0.8l-0.4-0.5l-0.6-0.2l-0.7,0.3l-0.3,0.8l-0.1,1.2h2.2V104.7z"/>
                                        <path className="st18" d="M240.8,111.9v-5.9l-0.2-1.9l-0.3-0.3l-0.5-0.2l-0.4,0.1l-0.4,0.4l-0.1,1.8v5.9l-2.7,0v-10.3l2.6,0v1.1
			l0.4-0.5l0.6-0.5l0.6-0.2l0.9-0.1l1.2,0.3l0.6,0.5l0.4,0.9l0.1,1v7.8H240.8z"/>
                                        <path className="st18" d="M257.4,111.9l-0.8-4.7l-0.3-1.1l-0.1-1.6l-0.2,1.5l-0.2,1.2l-1,4.6l-2.8,0l-2-10.3l2.7,0l0.6,4.9l0.3,1.9
			l0,1.1l0.2-1.2l0.2-1.7l1-5l2.6,0l0.9,5.3l0.3,2.5l0.1-1.3l0.1-1.1l0.8-5.4h2.7l-2.3,10.3H257.4z"/>
                                        <path className="st18" d="M263.6,100.4v-2.7h2.6v2.7H263.6z M263.6,111.9v-10.3h2.6v10.3H263.6z" />
                                        <path className="st18" d="M268.2,111.9l0-10.3l2.6,0l0,1.6l0.4-0.7l0.6-0.7l0.5-0.3l0.8-0.1l0.4,0v2.4l-1.3,0.1l-0.6,0.2l-0.4,0.4
			l-0.2,0.7l0,1v5.6H268.2z"/>
                                        <path className="st18" d="M276.8,107.9v-1.3l0.1-1.2l0.6-1l1.1-1.5l0.3-0.5l0.3-1.3l0-0.6l-0.1-0.5l-0.3-0.3l-0.5-0.3l-0.5,0.2
			l-0.3,0.6l-0.1,0.6l0,0.6h-2.7l0.1-0.9l0.1-0.9l0.3-0.6l0.5-0.6l0.6-0.5l0.7-0.3l1.5-0.2l1.1,0.1l0.8,0.3l0.7,0.5l0.5,0.8l0.2,0.7
			l0.1,1l-0.1,1.2l-0.2,0.9l-0.7,1.2l-0.5,0.7l-0.5,0.7l-0.3,0.6l-0.1,1.7L276.8,107.9z M276.8,111.9v-2.7h2.6v2.7H276.8z"/>
                                    </g>
                                </>}
                            </g>
                            <g id="Titles" className="outer-titles">
                                {SelectedLanguage === "en-GB" && <>
                                    <g>
                                        <path className="st18" d="M21.3608,766.52a7.7529,7.7529,0,0,1,1.35,4.751c0,3.6445-1.5654,6.3164-5.2905,7.1l-.9448-1.8086a4.9848,4.9848,0,0,0,2.1323-.4326c1.188-.5391,2.51-1.97,2.51-4.8584a7.7005,7.7005,0,0,0-.27-2.1592,3.9938,3.9938,0,0,0-3.7793-3.1045,3.4115,3.4115,0,0,0-1.8354.5127c-1.0259.6484-1.4844,1.7275-2.0781,3.9414a22.7724,22.7724,0,0,1-1.2959,4.1836,4.5141,4.5141,0,0,1-4.2378,2.7539,4.3743,4.3743,0,0,1-2.8345-.918c-2.1323-1.6738-2.1055-4.8593-2.1055-5.372a6.7858,6.7858,0,0,1,2.2134-5.4522A6.4419,6.4419,0,0,1,6.8657,764.55l.8906,1.6728a5.4452,5.4452,0,0,0-1.6464.7832,4.8829,4.8829,0,0,0-1.9434,4.1573,5.4256,5.4256,0,0,0,1.2144,3.59,2.8612,2.8612,0,0,0,2.1865.8906,2.9241,2.9241,0,0,0,1.7817-.5674,5.9489,5.9489,0,0,0,1.7813-3.4814c.4858-1.7813.9721-4.1836,2.16-5.6416a4.6841,4.6841,0,0,1,3.7251-1.7813A5.2555,5.2555,0,0,1,21.3608,766.52Z" />
                                        <path className="st18" d="M22.2246,746.7572H13.29V758.472h8.9346v1.7813H3.1675V758.472H11.67V746.7572H3.1675v-1.8086H22.2246Z" />
                                        <path className="st18" d="M19.9033,726.21a7.13,7.13,0,0,1,2.2945,2.9961,8.1949,8.1949,0,0,1,.5126,2.9961,7.17,7.17,0,0,1-2.2133,5.5332c-1.2686,1.2149-3.6983,2.7266-7.855,2.7266-1.0259,0-4.8584-.0274-7.585-2.5371a7.1434,7.1434,0,0,1-1.8354-2.6182,8.1835,8.1835,0,0,1-.54-3.1045,7.3028,7.3028,0,0,1,3.1856-6.37,11.6955,11.6955,0,0,1,6.7212-1.7822A12.6881,12.6881,0,0,1,19.9033,726.21ZM6.2178,727.8832a6.1651,6.1651,0,0,0-1.3765,1.6738,5.4691,5.4691,0,0,0-.5937,2.6455c0,4.7236,4.5615,6.3428,8.4487,6.3428.6748,0,4.6426-.0264,6.8828-2.3477a5.4593,5.4593,0,0,0,1.5117-3.914,5.0991,5.0991,0,0,0-1.7275-4.1035c-2.3213-2.1319-6.1-2.2129-6.7749-2.2129A10.3767,10.3767,0,0,0,6.2178,727.8832Z" />
                                        <path className="st18" d="M22.2246,707.2562l-8.4487,6.02V717.73h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129a5.5867,5.5867,0,0,1,2.5371-4.7236,5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1817Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678V717.73H12.21v-4.4815c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,707.8773Z" />
                                        <path className="st18" d="M4.76,695.1586H22.2246v1.8086H4.76v5.9922H3.1675v-13.82H4.76Z" />
                                        <path className="st18" d="M4.76,671.6693H22.2246v1.8086H4.76V679.47H3.1675V665.65H4.76Z" />
                                        <path className="st18" d="M22.2246,662.11H3.1675V649.6664h1.647v10.6347h6.8017v-9.6367H13.29v9.6367h7.2613v-11.31h1.6733Z" />
                                        <path className="st18" d="M22.2246,632.5482l-8.4487,6.0195v4.4542h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129a5.5867,5.5867,0,0,1,2.5371-4.7236,5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1817Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678v5.8848H12.21V638.54c0-.4853,0-.999-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,633.1693Z" />
                                        <path className="st18" d="M22.2246,611.2465l-16.1958-.1348,16.1958,6.208v1.2412L6.0288,624.9047l16.1958-.2159V626.47H3.1675v-2.1318l16.1689-6.4522L3.1675,611.7054v-2.24H22.2246Z" />
                                        <path className="st18" d="M26.5977,590.6976a16.09,16.09,0,0,1-13.1456,6.5586,17.6575,17.6575,0,0,1-5.3989-.8906,14.0458,14.0458,0,0,1-5.2094-3.0771,15.25,15.25,0,0,1-2.1324-2.51l.8907-.6485A13.08,13.08,0,0,0,4.14,592.5873a15.6116,15.6116,0,0,0,9.2042,2.6992,18.4774,18.4774,0,0,0,8.1518-1.917,12.9919,12.9919,0,0,0,2.5107-1.6465,8.0761,8.0761,0,0,0,1.5923-1.7Z" />
                                        <path className="st18" d="M21.7656,585.2416H20.0112l-5.6147-11.04-5.5606,11.04H7.1084L13.479,572.69h1.8086Z" />
                                        <path className="st18" d="M7.6279,567.5082c0-3.71-1.82-4.4805-3.85-4.62v-1.9258H22.2246v2.206H8.293a6.7858,6.7858,0,0,1,1.12,3.99v.35Z" />
                                        <path className="st18" d="M14.3428,547.3334h7.8818v1.7812H14.3428l-11.1753,7.1v-2.1328l9.4746-5.8847L3.1675,542.07v-2.1865Z" />
                                        <path className="st18" d="M20.4971,529.19a5.521,5.521,0,0,1,2.0512,4.5889,6.4357,6.4357,0,0,1-.6748,2.9687c-1.5923,3.0772-5.3715,3.1856-6.208,3.1856a7.6776,7.6776,0,0,1-2.6186-.4053,5.7676,5.7676,0,0,1-4.022-5.5332,5.4636,5.4636,0,0,1,1.8359-4.13,7.2734,7.2734,0,0,1,4.5347-1.6738H15.8v9.9873a6.6683,6.6683,0,0,0,2.16-.2432,3.9782,3.9782,0,0,0,3.1308-4.13,4.3,4.3,0,0,0-2.6992-4.13l.4321-1.4844A8.6456,8.6456,0,0,1,20.4971,529.19Zm-10.0415,4.8321a3.9834,3.9834,0,0,0,2.2407,3.6435,6.9727,6.9727,0,0,0,1.7812.4863v-8.125C11.1577,530.4047,10.4556,532.6176,10.4556,534.0219Z" />
                                        <path className="st18" d="M21.55,515.7152a2.1933,2.1933,0,0,1-1.6733.5674,3.5893,3.5893,0,0,1,1.1064.6748,5.2466,5.2466,0,0,1,1.5117,3.8867,4.0607,4.0607,0,0,1-1.1069,3.0772,3.4487,3.4487,0,0,1-2.4023.9716,3.1519,3.1519,0,0,1-2.0245-.7011c-1.1875-.9727-1.5654-2.5645-1.97-3.9688-.2163-.8095-.459-1.6191-.6211-2.4287l-.3237-1.5117h-.6748a3.2546,3.2546,0,0,0-1.2417.1348c-.8369.2695-1.7275.999-1.7007,2.9423,0,1.6465.459,2.4834,1.1069,2.9151a3.7168,3.7168,0,0,0,1.9971.4863l-.2969,1.7539a6.5793,6.5793,0,0,1-2.24-.7558,4.8391,4.8391,0,0,1-1.9707-4.292,5.3635,5.3635,0,0,1,1.377-3.9405c1.0258-.8642,2.4023-.7832,3.644-.7832h4.13c1.2417,0,2.1054.0274,2.4834-.4316a2.4276,2.4276,0,0,0,.2969-1.4844h1.2685C22.2246,514.3392,22.09,515.1761,21.55,515.7152Zm-6.1274.5674.2158.81c.6748,2.5107,1.188,4.67,1.7549,5.4521a1.9672,1.9672,0,0,0,1.5654.8106,2.1362,2.1362,0,0,0,.918-.2432,2.5934,2.5934,0,0,0,1.2143-2.3486,4.7137,4.7137,0,0,0-2.4292-4.13,5.0391,5.0391,0,0,0-2.2676-.3506Z" />
                                        <path className="st18" d="M10.6445,503.431a5.7709,5.7709,0,0,0-.081.9981,3.3036,3.3036,0,0,0,3.05,3.4824,20.5319,20.5319,0,0,0,3.05.1348h5.56v1.5927H9.3491v-1.5927H11.4a3.0322,3.0322,0,0,1-.648-.3506,3.7861,3.7861,0,0,1-1.2685-1.5117,4.3443,4.3443,0,0,1-.3237-1.836,7.2146,7.2146,0,0,1,.0537-.9443Z" />
                                        <path className="st18" d="M19.1743,495.7631a14.1093,14.1093,0,0,1,5.21,3.1035,15.4816,15.4816,0,0,1,2.1055,2.5107l-.8638.6475a13.9111,13.9111,0,0,0-2.5371-2.4561,15.6032,15.6032,0,0,0-9.2583-2.7265,18.429,18.429,0,0,0-8.125,1.89,16.6519,16.6519,0,0,0-2.51,1.62,8.8532,8.8532,0,0,0-1.5927,1.7l-.9986-.6748a16.16,16.16,0,0,1,13.1182-6.5322A17.8939,17.8939,0,0,1,19.1743,495.7631Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M791.4927,9.6093a4.0912,4.0912,0,0,1-1.4033,1.1606c-1.35.6479-2.9151.6479-5.9385.6479H779.94v8.5835h-1.7275V.9442h6.5322c1.1065,0,2.2129-.0268,3.3467.0811a4.3105,4.3105,0,0,1,3.2393,1.4306,5.5321,5.5321,0,0,1,1.2422,3.6983A5.7075,5.7075,0,0,1,791.4927,9.6093Zm-1.2149-5.6416c-.8906-1.4576-2.24-1.4576-3.7246-1.4849-.6748,0-1.35-.0269-1.998-.0269H779.94V9.8251h4.0762c.836,0,1.6465-.0269,2.4561-.0269,1.35-.0273,2.6455-.081,3.5625-1.2148a3.536,3.536,0,0,0,.7832-2.4566A4.1286,4.1286,0,0,0,790.2778,3.9677Z" />
                                        <path className="st18" d="M796.0327,20.0013V.9442h12.4434v1.647H797.8413V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M825.6743,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,816.5776.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6962,11.6962,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,825.6743,17.68ZM824.0015,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,824.0015,3.9945Z" />
                                        <path className="st18" d="M845.6006,9.6093a4.0912,4.0912,0,0,1-1.4033,1.1606c-1.35.6479-2.9151.6479-5.9385.6479h-4.2114v8.5835H832.32V.9442h6.5327c1.1065,0,2.2129-.0268,3.3467.0811a4.31,4.31,0,0,1,3.2393,1.4306,5.5321,5.5321,0,0,1,1.2422,3.6983A5.7075,5.7075,0,0,1,845.6006,9.6093Zm-1.2149-5.6416c-.8906-1.4576-2.24-1.4576-3.7246-1.4849-.6748,0-1.35-.0269-1.998-.0269h-4.6157V9.8251h4.0766c.836,0,1.6465-.0269,2.4561-.0269,1.35-.0273,2.6455-.081,3.5625-1.2148a3.536,3.536,0,0,0,.7832-2.4566A4.1286,4.1286,0,0,0,844.3857,3.9677Z" />
                                        <path className="st18" d="M850.1406,20.0013V.9442h1.835V18.328h10.5273v1.6733Z" />
                                        <path className="st18" d="M866.26,20.0013V.9442h12.4433v1.647H868.0684V9.3929h9.6367v1.6739h-9.6367V18.328h11.3095v1.6733Z" />
                                        <path className="st18" d="M891.4229,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M907.542,20.0013V.9442h12.4434v1.647H909.3506V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M931.4893,20.0013h-1.62L922.8779.9442h1.9981l5.8574,16.5737L936.7256.9442h1.917Z" />
                                        <path className="st18" d="M942.0469,20.0013V.9442H954.49v1.647H943.8555V9.3929h9.6367v1.6739h-9.6367V18.328H955.165v1.6733Z" />
                                        <path className="st18" d="M959.3,20.0013V.9442h1.835V18.328h10.5273v1.6733Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M410.22,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,401.1235.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6962,11.6962,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,410.22,17.68ZM408.5474,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,408.5474,3.9945Z" />
                                        <path className="st18" d="M429.1743,20.0013l-6.0195-8.4487h-4.4541v8.4487h-1.835V.9442h5.3711c1.0528,0,2.1328-.0268,3.2129.0274a5.5863,5.5863,0,0,1,4.7236,2.5371,5.8305,5.8305,0,0,1,.7286,2.8882,4.73,4.73,0,0,1-3.32,4.7509,8.2169,8.2169,0,0,1-2.3212.4048l6.1816,8.4487Zm-.6211-15.8178c-.8906-1.4844-2.4287-1.62-3.9678-1.62h-5.8847V9.9872h4.4814c.4854,0,.9991,0,1.5117-.0274,1.376-.0537,2.834-.1347,3.752-1.4033a3.5835,3.5835,0,0,0,.6748-2.2407A4.0477,4.0477,0,0,0,428.5532,4.1835Z" />
                                        <path className="st18" d="M448.29,6.2079a6.1038,6.1038,0,0,0-2.0245-3.293,5.2272,5.2272,0,0,0-3.2119-.9448,6.1813,6.1813,0,0,0-2.6719.5669c-1.89.8638-4.0488,3.2661-4.0488,7.99,0,4.6968,1.9434,7.0723,3.9131,7.9092a5.9358,5.9358,0,0,0,2.376.4317,5.0133,5.0133,0,0,0,3.8867-1.5386c.9443-1.0259,1.7813-2.645,1.7813-5.6416H441.272V10.0409h8.583v9.96H448.29v-3.185a5.6948,5.6948,0,0,1-1.4571,2.1592,6.2105,6.2105,0,0,1-4.292,1.5117,7.99,7.99,0,0,1-3.7519-.8906c-3.2666-1.7544-4.3731-5.5337-4.3731-8.9888,0-1.0527.0274-6.4512,4.13-8.9888A8.1186,8.1186,0,0,1,442.9722.4584c4.8047,0,6.289,3.1582,7.0185,4.832Z" />
                                        <path className="st18" d="M468.2974,20.0013l-2.1866-5.75h-8.5293l-2.1865,5.75h-1.998L461.1714.9442h1.5117l7.585,19.0571ZM461.9,3.05l-3.7246,9.7446h7.3955Z" />
                                        <path className="st18" d="M486.6567,20.0013,475.4819,3.5355V20.0013h-1.7812V.9442h1.7812l11.3106,16.3037L486.6567.9442h1.7823V20.0013Z" />
                                        <path className="st18" d="M493.7612,20.0013V.9442H495.57V20.0013Z" />
                                        <path className="st18" d="M502.0493,18.3817h11.4453v1.62H499.4858V18.8138l11.7413-16.25h-11.04V.9442h13.47V2.1859Z" />
                                        <path className="st18" d="M530.5581,20.0013l-2.1865-5.75h-8.5293l-2.1865,5.75h-1.9981L523.4321.9442h1.5117l7.585,19.0571ZM524.1606,3.05l-3.7246,9.7446h7.3955Z" />
                                        <path className="st18" d="M539.605,2.537V20.0013h-1.8086V2.537h-5.9922V.9442h13.82V2.537Z" />
                                        <path className="st18" d="M549.1646,20.0013V.9442h1.8085V20.0013Z" />
                                        <path className="st18" d="M569.7075,17.68a7.13,7.13,0,0,1-2.9961,2.2944,8.194,8.194,0,0,1-2.9961.5127,7.1692,7.1692,0,0,1-5.5332-2.2134c-1.2148-1.2686-2.7265-3.6982-2.7265-7.855,0-1.0259.0273-4.8584,2.5371-7.5849A7.144,7.144,0,0,1,560.6108.9984a8.1847,8.1847,0,0,1,3.1045-.54,7.3026,7.3026,0,0,1,6.37,3.1855,11.6954,11.6954,0,0,1,1.7823,6.7212A12.6885,12.6885,0,0,1,569.7075,17.68ZM568.0347,3.9945a6.166,6.166,0,0,0-1.6739-1.3765,5.47,5.47,0,0,0-2.6455-.5937c-4.7236,0-6.3427,4.5615-6.3427,8.4487,0,.6748.0263,4.6426,2.3476,6.8828a5.459,5.459,0,0,0,3.9141,1.5118,5.0993,5.0993,0,0,0,4.1035-1.7276c2.1318-2.3213,2.2129-6.1,2.2129-6.7749A10.3781,10.3781,0,0,0,568.0347,3.9945Z" />
                                        <path className="st18" d="M589.3091,20.0013,578.1343,3.5355V20.0013H576.353V.9442h1.7813l11.31,16.3037L589.3091.9442h1.7822V20.0013Z" />
                                        <path className="st18" d="M609.397,20.0013l-2.1866-5.75h-8.5292l-2.1866,5.75h-1.998L602.271.9442h1.5117l7.585,19.0571ZM603,3.05l-3.7246,9.7446H606.67Z" />
                                        <path className="st18" d="M614.8013,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M638.8306,20.0013V.9442h1.8349V18.328h10.5274v1.6733Z" />
                                        <path className="st18" d="M654.95,20.0013V.9442h12.4434v1.647H656.7583V9.3929h9.6367v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M678.897,20.0013h-1.62L670.2856.9442h1.9981l5.8574,16.5737L684.1333.9442h1.917Z" />
                                        <path className="st18" d="M689.4546,20.0013V.9442h12.4433v1.647H691.2632V9.3929H700.9v1.6739h-9.6367V18.328h11.31v1.6733Z" />
                                        <path className="st18" d="M706.7075,20.0013V.9442h1.835V18.328H719.07v1.6733Z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M22.2246,437.015H3.1675V435.18H20.5513V424.6527h1.6733Z" />
                                        <path className="st18" d="M19.9033,408.8578a7.129,7.129,0,0,1,2.2945,2.9961A8.1945,8.1945,0,0,1,22.71,414.85a7.17,7.17,0,0,1-2.2133,5.5332c-1.2686,1.2148-3.6983,2.7265-7.855,2.7265-1.0259,0-4.8584-.0273-7.585-2.5371a7.1428,7.1428,0,0,1-1.8354-2.6181,8.1839,8.1839,0,0,1-.54-3.1045,7.3027,7.3027,0,0,1,3.1856-6.37,11.6962,11.6962,0,0,1,6.7212-1.7823A12.6882,12.6882,0,0,1,19.9033,408.8578ZM6.2178,410.5306a6.166,6.166,0,0,0-1.3765,1.6739,5.4691,5.4691,0,0,0-.5937,2.6455c0,4.7236,4.5615,6.3427,8.4487,6.3427.6748,0,4.6426-.0263,6.8828-2.3476a5.4594,5.4594,0,0,0,1.5117-3.9141,5.0992,5.0992,0,0,0-1.7275-4.1035c-2.3213-2.1318-6.1-2.2129-6.7749-2.2129A10.3781,10.3781,0,0,0,6.2178,410.5306Z" />
                                        <path className="st18" d="M22.2246,389.2562,5.7588,400.431H22.2246v1.7813H3.1675V400.431l16.3037-11.31-16.3037.1357V387.474H22.2246Z" />
                                        <path className="st18" d="M8.4312,369.1146a6.1034,6.1034,0,0,0-3.293,2.0244,5.2277,5.2277,0,0,0-.9448,3.212,6.1809,6.1809,0,0,0,.5669,2.6718c.8637,1.89,3.2661,4.0489,7.99,4.0489,4.6968,0,7.0723-1.9434,7.9092-3.9131a5.9384,5.9384,0,0,0,.4316-2.376,5.0128,5.0128,0,0,0-1.5386-3.8867c-1.0258-.9444-2.645-1.7813-5.6416-1.7813v7.0176H12.2642v-8.583h9.96v1.5654H19.04a5.6933,5.6933,0,0,1,2.1591,1.4571,6.21,6.21,0,0,1,1.5117,4.2919,7.99,7.99,0,0,1-.8906,3.752c-1.7544,3.2666-5.5337,4.373-8.9887,4.373-1.0528,0-6.4512-.0273-8.9888-4.13a8.1179,8.1179,0,0,1-1.1607-4.4268c0-4.8047,3.1582-6.2891,4.8321-7.0185Z" />
                                        <path className="st18" d="M4.76,348.1605H22.2246v1.8086H4.76v5.9922H3.1675v-13.82H4.76Z" />
                                        <path className="st18" d="M22.2246,338.601H3.1675V326.1576h1.647v10.6348h6.8017v-9.6368H13.29v9.6368h7.2613v-11.31h1.6733Z" />
                                        <path className="st18" d="M22.2246,309.0394l-8.4487,6.02v4.4541h8.4487v1.8349H3.1675v-5.3711c0-1.0527-.0269-2.1328.0273-3.2129A5.5867,5.5867,0,0,1,5.7319,308.04a5.8325,5.8325,0,0,1,2.8882-.7285,4.73,4.73,0,0,1,4.751,3.32,8.2215,8.2215,0,0,1,.4048,2.3213l8.4487-6.1816Zm-15.8179.6211c-1.4843.8906-1.62,2.4287-1.62,3.9678v5.8848H12.21v-4.4815c0-.4853,0-.998-.0273-1.5117-.0537-1.376-.1348-2.834-1.4033-3.752a3.5841,3.5841,0,0,0-2.2407-.6748A4.0492,4.0492,0,0,0,6.4067,309.6605Z" />
                                        <path className="st18" d="M22.2246,287.7377l-16.1958-.1348,16.1958,6.208v1.2412L6.0288,301.3959l16.1958-.2158v1.7812H3.1675v-2.1328l16.1689-6.4512L3.1675,288.1967v-2.24H22.2246Z" />
                                        <path className="st18" d="M26.5977,267.1888a16.09,16.09,0,0,1-13.1456,6.5586,17.6575,17.6575,0,0,1-5.3989-.8906A14.0458,14.0458,0,0,1,2.8438,269.78a15.2459,15.2459,0,0,1-2.1324-2.51l.8907-.6484a13.0819,13.0819,0,0,0,2.5375,2.457,15.6116,15.6116,0,0,0,9.2042,2.6992,18.4774,18.4774,0,0,0,8.1518-1.917,12.9919,12.9919,0,0,0,2.5107-1.6465,8.0736,8.0736,0,0,0,1.5923-1.7Z" />
                                        <path className="st18" d="M21.7656,261.7328H20.0112l-5.6147-11.04-5.5606,11.04H7.1084L13.479,249.181h1.8086Z" />
                                        <path className="st18" d="M7.6279,243.9994c0-3.71-1.82-4.4805-3.85-4.62v-1.9258H22.2246v2.206H8.293a6.7859,6.7859,0,0,1,1.12,3.99v.35Z" />
                                        <path className="st18" d="M14.3428,223.8246h7.8818v1.7812H14.3428l-11.1753,7.1v-2.1328l9.4746-5.8847-9.4746-6.127v-2.1865Z" />
                                        <path className="st18" d="M20.4971,205.681a5.5226,5.5226,0,0,1,2.0512,4.5889,6.4357,6.4357,0,0,1-.6748,2.9687c-1.5923,3.0772-5.3715,3.1856-6.208,3.1856a7.6776,7.6776,0,0,1-2.6186-.4053,5.7676,5.7676,0,0,1-4.022-5.5332,5.4636,5.4636,0,0,1,1.8359-4.13,7.2734,7.2734,0,0,1,4.5347-1.6738H15.8v9.9873a6.6654,6.6654,0,0,0,2.16-.2432,3.9782,3.9782,0,0,0,3.1308-4.13,4.3,4.3,0,0,0-2.6992-4.13l.4321-1.4844A8.6456,8.6456,0,0,1,20.4971,205.681Zm-10.0415,4.8321a3.9834,3.9834,0,0,0,2.2407,3.6435,6.9727,6.9727,0,0,0,1.7812.4863v-8.125C11.1577,206.8959,10.4556,209.1088,10.4556,210.5131Z" />
                                        <path className="st18" d="M21.55,192.2064a2.1929,2.1929,0,0,1-1.6733.5674,3.5893,3.5893,0,0,1,1.1064.6748,5.2466,5.2466,0,0,1,1.5117,3.8867,4.0607,4.0607,0,0,1-1.1069,3.0772,3.4487,3.4487,0,0,1-2.4023.9717,3.1521,3.1521,0,0,1-2.0245-.7012c-1.1875-.9727-1.5654-2.5645-1.97-3.9688-.2163-.81-.459-1.6191-.6211-2.4287l-.3237-1.5117h-.6748a3.2566,3.2566,0,0,0-1.2417.1348c-.8369.2695-1.7275.999-1.7007,2.9424,0,1.6464.459,2.4834,1.1069,2.915a3.7168,3.7168,0,0,0,1.9971.4863l-.2969,1.7539a6.5793,6.5793,0,0,1-2.24-.7558,4.8391,4.8391,0,0,1-1.9707-4.292,5.3636,5.3636,0,0,1,1.377-3.9405c1.0258-.8642,2.4023-.7832,3.644-.7832h4.13c1.2417,0,2.1054.0274,2.4834-.4316a2.4276,2.4276,0,0,0,.2969-1.4844h1.2685C22.2246,190.83,22.09,191.6674,21.55,192.2064Zm-6.1274.5674.2158.81c.6748,2.5107,1.188,4.67,1.7549,5.4521a1.967,1.967,0,0,0,1.5654.8106,2.1362,2.1362,0,0,0,.918-.2432,2.5933,2.5933,0,0,0,1.2143-2.3486,4.7137,4.7137,0,0,0-2.4292-4.13,5.0391,5.0391,0,0,0-2.2676-.3506Z" />
                                        <path className="st18" d="M10.6445,179.9222a5.7717,5.7717,0,0,0-.081.9981,3.3036,3.3036,0,0,0,3.05,3.4824,20.5319,20.5319,0,0,0,3.05.1348h5.56V186.13H9.3491v-1.5927H11.4a3.0278,3.0278,0,0,1-.648-.3506,3.7861,3.7861,0,0,1-1.2685-1.5117,4.3443,4.3443,0,0,1-.3237-1.836,7.2146,7.2146,0,0,1,.0537-.9443Z" />
                                        <path className="st18" d="M19.1743,172.2543a14.1093,14.1093,0,0,1,5.21,3.1035,15.4816,15.4816,0,0,1,2.1055,2.5107l-.8638.6475a13.9111,13.9111,0,0,0-2.5371-2.4561,15.6039,15.6039,0,0,0-9.2583-2.7265,18.429,18.429,0,0,0-8.125,1.89,16.66,16.66,0,0,0-2.51,1.62,8.8532,8.8532,0,0,0-1.5927,1.7l-.9986-.6748a16.16,16.16,0,0,1,13.1182-6.5322A17.8939,17.8939,0,0,1,19.1743,172.2543Z" />
                                    </g>
                                </>
                                }
                                {SelectedLanguage === "de-DE" && <>
                                    <g>
                                        <path className="st18" d="M1.7,775.6v-1.8H12L1.7,763.5V761l7.3,7.4l11.2-8v2.2l-10,7.1l4.1,4.1h5.9v1.8H1.7z" />
                                        <path className="st18" d="M14.2,743.4c4.2,0,6.5,2.8,6.5,7s-2.2,7-6.5,7H1.7v-1.8h12.5c3.6,0,4.9-2.3,4.9-5.2c0-2.8-1.3-5.2-4.9-5.2
			H1.7v-1.8H14.2z"/>
                                        <path className="st18" d="M1.7,738.5v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,736.7v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,721.4V708h1.1l15.8,11.4v-11.3h1.6V722H19L3.3,710.6v10.8H1.7z" />
                                        <path className="st18" d="M1.7,704.3V692h1.6v10.5h6.2v-9.4h1.6v9.4h9.1v1.8H1.7z" />
                                        <path className="st18" d="M1.7,688.2v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,686.4v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,670.4v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M14.6,662.3c3.1-0.2,4.4-2.2,4.4-5.3c0-3-1.5-5.2-3.8-5.2c-5.7,0-2.1,11.3-9.4,11.3c-3.4,0-4.7-3.4-4.7-6
			c0-3.2,1-5.7,4.1-6.7l0.9,1.7c-2.4,0.8-3.4,2.7-3.4,5.1c0,1.9,1.1,4.2,3.1,4.2c5.5,0,2-11.3,9.1-11.3c3.6,0,5.7,2.6,5.7,7
			c0,3.8-1.4,6.4-5.1,7.1L14.6,662.3z"/>
                                        <path className="st18" d="M1.7,647.3v-13.7h1.6v5.9h16.9v1.8H3.3v5.9H1.7z" />
                                        <path className="st18" d="M1.7,630v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,609.7c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,609.7z"/>
                                        <path className="st18" d="M25.1,588.9c-2.4,4.4-7.4,6.5-12.4,6.5s-10-1.9-12.4-6.5h1.4c2.7,3.5,7.1,4.6,11,4.6s8.3-1.1,11-4.6H25.1z"
                                        />
                                        <path className="st18" d="M20.4,572.7l-6.1,13.4h-1.4l-6.1-13.4h1.4l5.4,11.8l5.4-11.8H20.4z" />
                                        <path className="st18" d="M4.5,566.2c0.1-1.5-0.4-3.5-2.8-3.6v-1.5h18.5v1.6h-15c0.6,1.1,0.8,2.3,0.8,3.5H4.5z" />
                                        <path className="st18" d="M1.7,543.3h13c3.6,0,6,1.4,6,5.4c0,3.5-1.7,5.6-5.1,5.6h-0.5l-0.3-1.8h0.7c2.1,0,3.6-1.2,3.6-3.8
			c0-2.7-1.7-3.6-4.3-3.6H1.7V543.3z"/>
                                        <path className="st18" d="M20.3,527.1c0,2,0,3.2-2.2,3.3v0.1c1.7,1,2.3,2.5,2.3,4.5c0,2.1-1,4.1-3.3,4.1c-2.7,0-3.4-2.4-4-4.6l-1-3.9
			H11c-1.6,0-2.3,1.2-2.3,3.2c0,2.1,1,3.1,3,3.2l-0.3,1.8c-2.7-0.5-4-2.2-4-5c0-4.7,2.7-4.7,3.9-4.7h6.1c1.6,0,1.6-0.7,1.6-1.8H20.3
			z M19,534.8c0-1.5-1-4.3-4-4.3h-1.4l1.2,4.6c0.3,1.3,0.9,2.3,2.3,2.3C18.5,537.4,19,536,19,534.8z"/>
                                        <path className="st18" d="M1.7,524.1v-1.6h7.9v-0.1c-1.6-0.8-2.2-2.3-2.2-3.8c0-3.1,1.8-4.5,4.3-4.5h8.5v1.6h-8
			c-2.5,0-3.4,1.1-3.4,3.1c0,1.9,1.2,3.6,4.1,3.6h7.3v1.6H1.7z"/>
                                        <path className="st18" d="M7.7,509.2v-1.6h1.7v-0.1c-1.3-0.9-2.1-1.8-2.1-3.5c0-0.4,0-0.7,0.1-1.1h1.4c-0.1,0.4-0.1,0.7-0.1,1.1
			c0,2.5,1.8,3.6,4.3,3.6h7.2v1.6H7.7z"/>
                                        <path className="st18" d="M23.7,501.8c-2.7-3.5-7.1-4.6-11-4.6s-8.3,1.1-11,4.6H0.3c2.4-4.4,7.4-6.5,12.4-6.5s10,1.9,12.4,6.5H23.7z"
                                        />
                                    </g>
                                    <g>
                                        <path className="st18" d="M1.7,437v-1.8h16.9v-10.4h1.6V437H1.7z" />
                                        <path className="st18" d="M1.7,413.9l18.5-7.3v1.9l-5.6,2.1v8.3l5.6,2.3v1.9l-18.5-7.7V413.9z M13.2,411.3L4,414.8l9.1,3.6V411.3z" />
                                        <path className="st18" d="M1.7,403.3v-1.8l15.5-10.8v-0.1H1.7v-1.8h18.5v1.8L4.6,401.4v0.1h15.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,370.3c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,370.3z"/>
                                        <path className="st18" d="M1.7,364.1v-12.3h1.6v10.5h6.2v-9.4h1.6v9.4h9.1v1.8H1.7z" />
                                        <path className="st18" d="M1.7,348v-8.4c0-3.9,2.5-5.5,5.1-5.5c3.2,0,4.8,2.1,5.1,5.6l8.3-6.1v2.3l-8.2,6v4.3h8.2v1.8H1.7z
			 M10.4,346.2v-5.6c0-3.8-1.9-4.7-3.5-4.7c-2.2,0-3.5,1.2-3.5,4.4v5.9H10.4z"/>
                                        <path className="st18" d="M1.7,330.1v-1.8h18.5v1.8H1.7z" />
                                        <path className="st18" d="M14.6,322.1c3.1-0.2,4.4-2.2,4.4-5.3c0-3-1.5-5.2-3.8-5.2c-5.7,0-2.1,11.3-9.4,11.3c-3.4,0-4.7-3.4-4.7-6
			c0-3.2,1-5.7,4.1-6.7l0.9,1.7c-2.4,0.8-3.4,2.7-3.4,5.1c0,1.9,1.1,4.2,3.1,4.2c5.5,0,2-11.3,9.1-11.3c3.6,0,5.7,2.6,5.7,7
			c0,3.8-1.4,6.4-5.1,7.1L14.6,322.1z"/>
                                        <path className="st18" d="M1.7,307.1v-13.7h1.6v5.9h16.9v1.8H3.3v5.9H1.7z" />
                                        <path className="st18" d="M1.7,289.8V288h18.5v1.8H1.7z" />
                                        <path className="st18" d="M6.8,269.5c-2.8,0.9-4,2.6-4,5.3c0,4.8,4.3,6.9,8.1,6.9c3.8,0,8.1-1.9,8.1-6.4c0-4.1-3.2-5.7-6-5.7h-1v7
			h-1.6v-8.6h9.7v1.6h-2.7v0.1c2.3,0.9,3.1,3.3,3.1,5.7c0,5.5-4.4,8.2-9.7,8.2c-5,0-9.7-2.7-9.7-8.5c0-3.6,1.5-5.8,4.7-7.1
			L6.8,269.5z"/>
                                        <path className="st18" d="M25.1,248.7c-2.4,4.4-7.4,6.5-12.4,6.5s-10-1.9-12.4-6.5h1.4c2.7,3.5,7.1,4.6,11,4.6s8.3-1.1,11-4.6H25.1z"
                                        />
                                        <path className="st18" d="M14.3,232.5l6.1,13.4H19l-5.4-11.8l-5.4,11.8H6.8l6.1-13.4H14.3z" />
                                        <path className="st18" d="M4.5,226c0.1-1.5-0.4-3.5-2.8-3.6v-1.5h18.5v1.6h-15c0.6,1.1,0.8,2.3,0.8,3.5H4.5z" />
                                        <path className="st18" d="M1.7,203h13c3.6,0,6,1.4,6,5.4c0,3.5-1.7,5.6-5.1,5.6h-0.5l-0.3-1.8h0.7c2.1,0,3.6-1.2,3.6-3.8
			c0-2.7-1.7-3.6-4.3-3.6H1.7V203z"/>
                                        <path className="st18" d="M20.3,186.9c0,2,0,3.2-2.2,3.3v0.1c1.7,1,2.3,2.5,2.3,4.5c0,2.1-1,4.1-3.3,4.1c-2.7,0-3.4-2.4-4-4.6l-1-3.9
			H11c-1.6,0-2.3,1.2-2.3,3.2c0,2.1,1,3.1,3,3.2l-0.3,1.8c-2.7-0.5-4-2.2-4-5c0-4.7,2.7-4.7,3.9-4.7h6.1c1.6,0,1.6-0.7,1.6-1.8H20.3
			z M19,194.6c0-1.5-1-4.3-4-4.3h-1.4l1.2,4.6c0.3,1.3,0.9,2.3,2.3,2.3C18.5,197.2,19,195.8,19,194.6z"/>
                                        <path className="st18" d="M1.7,183.9v-1.6h7.9v-0.1c-1.6-0.8-2.2-2.3-2.2-3.8c0-3.1,1.8-4.5,4.3-4.5h8.5v1.6h-8
			c-2.5,0-3.4,1.1-3.4,3.1c0,1.9,1.2,3.6,4.1,3.6h7.3v1.6H1.7z"/>
                                        <path className="st18" d="M7.7,169v-1.6h1.7v-0.1c-1.3-0.9-2.1-1.8-2.1-3.5c0-0.4,0-0.7,0.1-1.1h1.4c-0.1,0.4-0.1,0.7-0.1,1.1
			c0,2.5,1.8,3.6,4.3,3.6h7.2v1.6H7.7z"/>
                                        <path className="st18" d="M23.7,161.6c-2.7-3.5-7.1-4.6-11-4.6s-8.3,1.1-11,4.6H0.3c2.4-4.4,7.4-6.5,12.4-6.5s10,1.9,12.4,6.5H23.7z"
                                        />
                                    </g>
                                    <g>
                                        <path className="st18" d="M778.2,0.5h2.2l6.1,15.5h0.1l6.1-15.5h2.2V19h-1.8V3.9h-0.1L787.2,19h-1.2L780,3.9H780V19h-1.8V0.5z" />
                                        <path className="st18" d="M800.3,0.5h1.8V19h-1.8V0.5z" />
                                        <path className="st18" d="M805.7,0.5h13.7v1.6h-5.9V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M830,0.5l7.3,18.5h-1.9l-2.1-5.6H825l-2.3,5.6h-1.9l7.7-18.5H830z M832.7,12l-3.5-9.1l-3.6,9.1H832.7z" />
                                        <path className="st18" d="M840.3,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M842.1,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M857.9,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			h-8.3V0.5z M859.7,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M859.7,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M876.4,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M893.8,0.5h1.8V19h-1.8V0.5z" />
                                        <path className="st18" d="M899.3,0.5H913v1.6H907V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M916.3,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M933.4,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M935.2,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M951,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			H951V0.5z M952.8,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M952.8,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M969.5,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M986.1,0.5h13.4v1.1L988,17.4h11.3V19h-13.9v-1.1L997,2.1h-10.8V0.5z" />
                                        <path className="st18" d="M1010.8,0c5.6,0,8.2,4.8,8.2,9.7c0,4.9-2.6,9.7-8.2,9.7c-5.6,0-8.2-4.8-8.2-9.7
			C1002.6,4.9,1005.2,0,1010.8,0z M1010.8,17.9c4.5,0,6.4-4.3,6.4-8.1c0-3.8-1.9-8.1-6.4-8.1c-4.5,0-6.4,4.3-6.4,8.1
			C1004.4,13.6,1006.3,17.9,1010.8,17.9z"/>
                                        <path className="st18" d="M1036.6,5.6c-0.9-2.8-2.6-4-5.3-4c-4.8,0-6.9,4.3-6.9,8.1c0,3.8,1.9,8.1,6.4,8.1c4.1,0,5.7-3.2,5.7-6v-1h-7
			V9.3h8.6V19h-1.6v-2.7h-0.1c-0.9,2.3-3.3,3.1-5.7,3.1c-5.5,0-8.2-4.4-8.2-9.7c0-5,2.7-9.7,8.5-9.7c3.6,0,5.8,1.5,7.1,4.7
			L1036.6,5.6z"/>
                                        <path className="st18" d="M1042.7,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M1060.2,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8l-10.8-15.5h-0.1V19h-1.8V0.5z" />
                                    </g>
                                    <g>
                                        <path className="st18" d="M385.8,13c0,4.2-2.8,6.5-7,6.5s-7-2.2-7-6.5V0.5h1.8V13c0,3.6,2.3,4.9,5.2,4.9c2.8,0,5.2-1.3,5.2-4.9V0.5
			h1.8V13z"/>
                                        <path className="st18" d="M391.2,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L393,3.5H393V19h-1.8V0.5z" />
                                        <path className="st18" d="M409.4,0.5H423v1.6h-5.9V19h-1.8V2.1h-5.9V0.5z" />
                                        <path className="st18" d="M426.4,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M443.5,0.5h8.4c3.9,0,5.5,2.5,5.5,5.1c0,3.2-2.1,4.8-5.6,5.1l6.1,8.3h-2.3l-6-8.2h-4.3V19h-1.8V0.5z
			 M445.3,9.2h5.6c3.8,0,4.7-1.9,4.7-3.5c0-2.2-1.2-3.5-4.4-3.5h-5.9V9.2z"/>
                                        <path className="st18" d="M461.4,0.5h1.8L474,16.1h0.1V0.5h1.8V19h-1.8L463.3,3.5h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M481.1,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1H494V19h-12.9V0.5z" />
                                        <path className="st18" d="M498.5,0.5h1.8v8.2h11.3V0.5h1.8V19h-1.8v-8.7h-11.3V19h-1.8V0.5z" />
                                        <path className="st18" d="M518.9,0.5h2.2l6.1,15.5h0.1l6.1-15.5h2.2V19h-1.8V3.9h-0.1L527.9,19h-1.2l-5.9-15.1h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M540.7,0.5H553v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M558.1,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L560,3.5h-0.1V19h-1.8V0.5z" />
                                        <path className="st18" d="M578.9,13.5c0.2,3.1,2.2,4.4,5.3,4.4c3,0,5.2-1.5,5.2-3.8c0-5.7-11.3-2.1-11.3-9.4c0-3.4,3.4-4.7,6-4.7
			c3.2,0,5.7,1,6.7,4.1l-1.7,0.9c-0.8-2.4-2.7-3.4-5.1-3.4c-1.9,0-4.2,1.1-4.2,3.1c0,5.5,11.3,2,11.3,9.1c0,3.6-2.6,5.7-7,5.7
			c-3.8,0-6.4-1.4-7.1-5.1L578.9,13.5z"/>
                                        <path className="st18" d="M595.3,0.5h8.1c5,0,5.6,3.3,5.6,4.3c0,2.4-1.1,3.7-3.5,4.1V9c2.8,0.3,4.1,2.4,4.1,4.8c0,2.7-1.8,5.1-6.1,5.1
			h-8.3V0.5z M597.2,8.3h5.8c2.8,0,4.4-1.1,4.4-3.1c0-2.3-1.4-3.1-4.2-3.1h-6V8.3z M597.2,17.4h6.5c3.1,0,4.3-1.8,4.3-3.5
			c0-2.3-1.8-4-4.9-4h-5.9V17.4z"/>
                                        <path className="st18" d="M613.9,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M630.5,0.5h13.4v1.1l-11.5,15.8h11.3V19h-13.9v-1.1l11.4-15.8h-10.8V0.5z" />
                                        <path className="st18" d="M655.2,0c5.6,0,8.2,4.8,8.2,9.7c0,4.9-2.6,9.7-8.2,9.7c-5.6,0-8.2-4.8-8.2-9.7C647,4.9,649.6,0,655.2,0z
			 M655.2,17.9c4.5,0,6.4-4.3,6.4-8.1c0-3.8-1.9-8.1-6.4-8.1c-4.5,0-6.4,4.3-6.4,8.1C648.8,13.6,650.7,17.9,655.2,17.9z"/>
                                        <path className="st18" d="M681,5.6c-0.9-2.8-2.6-4-5.3-4c-4.8,0-6.9,4.3-6.9,8.1c0,3.8,1.9,8.1,6.4,8.1c4.1,0,5.7-3.2,5.7-6v-1h-7V9.3
			h8.6V19h-1.6v-2.7h-0.1c-0.9,2.3-3.3,3.1-5.7,3.1c-5.5,0-8.2-4.4-8.2-9.7c0-5,2.7-9.7,8.5-9.7c3.6,0,5.8,1.5,7.1,4.7L681,5.6z"/>
                                        <path className="st18" d="M687.1,0.5h12.4v1.6h-10.6v6.6h9.5v1.6h-9.5v7.1h11.1V19h-12.9V0.5z" />
                                        <path className="st18" d="M704.6,0.5h1.8l10.8,15.5h0.1V0.5h1.8V19h-1.8L706.5,3.5h-0.1V19h-1.8V0.5z" />
                                    </g>
                                </>}
                            </g>
                            <g id="Paths">
                                <line id="Path_9_" className="st19" x1="1539.5" y1="253.2" x2="1539.5" y2="278.7" />
                                <line id="Path_10_" className="st19" x1="1539.5" y1="356.7" x2="1539.5" y2="426.2" />
                                <line id="Path_11_" className="st19" x1="1539.5" y1="504.3" x2="1539.5" y2="585.9" />
                                <line id="Path_12_" className="st19" x1="1539.5" y1="756.6" x2="1539.5" y2="779.9" />
                                <line id="Path_13_" className="st19" x1="1417.4" y1="819" x2="1399.6" y2="819" />
                                <line id="Path_14_" className="st19" x1="890.9" y1="819" x2="871.6" y2="819" />
                                <line id="Path_34_" className="st19" x1="359.7" y1="317.8" x2="335.8" y2="317.8" />
                                <line id="Path_35_" className="st19" x1="627.5" y1="318" x2="603.8" y2="318" />
                                <line id="Path_36_" className="st19" x1="890.8" y1="318" x2="871.6" y2="318" />
                                <line id="Path_37_" className="st19" x1="1134.7" y1="318" x2="1154.7" y2="318" />
                                <line id="Path_38_" className="st19" x1="1398.6" y1="214.9" x2="1417.4" y2="215" />
                                <line id="Path_43_" className="st19" x1="1399.7" y1="671.4" x2="1417.4" y2="671.4" />
                                <line id="Path_42_" className="st19" x1="1278" y1="568.1" x2="1277.9" y2="586" />
                                <line id="Path_41_" className="st19" x1="1277.8" y1="756.4" x2="1277.7" y2="781.1" />
                                <line id="Path_40_" className="st19" x1="1012.3" y1="356.8" x2="1012.3" y2="370.5" />
                                <line id="Path_39_" className="st19" x1="787" y1="253.2" x2="786.9" y2="278.6" />
                                <path id="Path_46_" className="st19" d="M786.9,174.5" />
                                <path id="Path_45_" className="st19" d="M787,149" />
                                <line id="Path_44_" className="st19" x1="711.6" y1="253.2" x2="711.5" y2="278.6" />
                                <line id="Path_53_" className="st19" x1="519.7" y1="253.2" x2="519.6" y2="278.6" />
                                <line id="Path_51_" className="st19" x1="1316.1" y1="145" x2="1316.1" y2="175" />
                                <line id="Path_52_" className="st19" x1="1240.8" y1="145" x2="1240.7" y2="175" />
                                <line id="Path_50_" className="st19" x1="213.8" y1="144.9" x2="213.7" y2="175.1" />
                                <line id="Path_49_" className="st19" x1="213.8" y1="278.6" x2="213.8" y2="253.2" />
                                <line id="Path_48_" className="st19" x1="359.7" y1="466.9" x2="296.4" y2="466.8" />
                                <line id="Path_47_" className="st19" x1="359.7" y1="569.1" x2="296.4" y2="569.1" />
                                <line id="Path_54_" className="st19" x1="627.5" y1="466.9" x2="603.7" y2="466.8" />
                                <line id="Path_55_" className="st19" x1="1155.8" y1="720" x2="605.8" y2="719.9" />
                                <line id="Path_56_" className="st19" x1="481.4" y1="356.8" x2="481.3" y2="426.2" />
                                <line id="Path_57_" className="st19" x1="481.2" y1="504.3" x2="481.1" y2="531.3" />
                                <line id="Path_58_" className="st19" x1="627.6" y1="214.9" x2="335.9" y2="214.9" />
                                <line id="Path_59_" className="st19" x1="1154.7" y1="214.9" x2="871.5" y2="214.9" />
                                <line id="Path_60_" className="st19" x1="1154.7" y1="227.7" x2="871.5" y2="227.7" />
                                <path id="Path_18_" className="st19" d="M335.8,106.7h370.3c3.1,0,5.7,2.6,5.7,5.7v62.8" />
                                <path id="Path_17_" className="st19" d="M71.3,426.2V323.5c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_15_" className="st19" d="M627.5,819.4H486.9c-3.1,0-5.7-2.6-5.7-5.7v-56.6" />
                                <path id="Path_16_" className="st19" d="M490.9,819.4H179.6c-3.1,0-5.7-2.6-5.7-5.7V651.8" />
                                <path id="Path_33_" className="st19" d="M1012.7,448.6v12.5c0,3.1-2.6,5.7-5.7,5.7H871.7" />
                                <path id="Path_32_" className="st19" d="M71.2,329.8v-110c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_31_" className="st19" d="M519.7,278.6v-45.1c0-3.1,2.6-5.7,5.7-5.7h102.2" />
                                <path id="Path_30_" className="st19" d="M444.6,278.6v-45.1c0-3.1-2.6-5.7-5.7-5.7h-103" />
                                <path id="Path_29_" className="st19" d="M1154.7,92.1l-629.2,0.1c-3.1,0-5.7,2.6-5.7,5.7l-0.1,98.5c0,3.1-2.6,5.7-5.7,5.7H335.9" />
                                <path id="Path_28_" className="st19" d="M787.3,504.4l0.2,46.3c0,3.1,2.6,5.7,5.7,5.7l382.2-0.4c3.1,0,5.7,2.6,5.7,5.7v24.6" />
                                <path id="Path_27_" className="st19" d="M603.7,556.4l102.2,0.4c3.1,0,5.7-2.6,5.7-5.7v-46.8" />
                                <path id="Path_26_" className="st19" d="M444.6,426.2v-26.8c0-3.1-2.6-5.7-5.7-5.7l-219.2,0.1c-3.1,0-5.7-2.6-5.7-5.7v-31.5" />
                                <path id="Path_25_" className="st19" d="M359.6,555.9l-39.9,0.1c-3.1,0-5.7-2.6-5.7-5.7V356.8" />
                                <path id="Path_24_" className="st19" d="M1501.4,586v-57.4c0-3.1-2.6-5.7-5.7-5.7h-101.6" />
                                <path id="Path_23_" className="st19" d="M1501.4,426.2v-10.9c0-3.1-2.6-5.7-5.7-5.7h-361" />
                                <path id="Path_22_" className="st19" d="M1520.4,426.2v-24.1c0-3.1-2.6-5.7-5.7-5.7h-154.2H1320c-3.1,0-5.7-2.6-5.7-5.7v-34" />
                                <path id="Path_21_" className="st19" d="M1238.9,356.7V460c0,3.1-2.6,5.7-5.7,5.7H1155h-13.1c-3.1,0-5.7,2.6-5.7,5.7V781" />
                                <path id="Path_20_" className="st19" d="M71.3,224.8v-113c0-3.1,2.6-5.7,5.7-5.7h14.9" />
                                <path id="Path_19_" className="st19" d="M1012.7,278.8l-0.3-32.1c0-3.1,2.6-5.7,5.7-5.7h136.1" />
                                <rect id="Path_8_" x="1031.1" y="209.3" className="st17" width="1.3" height="0.4" />
                                <path id="Path_7_" className="st19" d="M871.5,106.5" />
                                <path id="Path_6_" className="st19" d="M1154.7,106.5" />
                                <path id="Path_5_" className="st19" d="M799.9,175.1v-49.6c0-3.1,2.6-5.7,5.7-5.7h348.8" />
                                <path id="Path_4_" className="st19" d="M786.9,175.1v-63.4c0-3.1,2.6-5.7,5.7-5.7h361.8" />
                                <rect id="Path_3_" x="1031.1" y="88.1" className="st17" width="1.3" height="0.4" />
                                <path id="Path_2_" className="st19" d="M1314.4,381.1" />
                                <path id="Path" className="st19" d="M1314.4,355.6" />
                            </g>
                            <g id="Arrows">
                                <polyline id="Arrow_9_" className="st20" points="1406.7,828.3 1412.7,838 1406.7,846.8 	" />
                                <polyline id="Arrow_10_" className="st20" points="614,477.8 620,487.5 614,496.3 	" />
                                <polyline id="Arrow_11_" className="st20" points="344.8,477.8 350.8,487.5 344.8,496.3 	" />
                                <polyline id="Arrow_12_" className="st20" points="344.8,577.8 350.8,587.5 344.8,596.4 	" />
                                <polyline id="Arrow_13_" className="st20" points="344.8,530.4 350.8,540.1 344.8,549 	" />
                                <polyline id="Arrow_14_" className="st20" points="80,328 86,337.7 80,346.5 	" />
                                <polyline id="Arrow_15_" className="st20" points="80,222.5 86,232.2 80,241 	" />
                                <polyline id="Arrow_16_" className="st20" points="80,114.1 86,123.8 80,132.6 	" />
                                <polyline id="Arrow_23_" className="st20" points="1144.8,729.3 1150.8,739 1144.8,747.8 	" />
                                <polyline id="Arrow_22_" className="st20" points="613,329.5 619,339.2 613,348.1 	" />
                                <polyline id="Arrow_21_" className="st20" points="879.3,329.5 885.3,339.2 879.3,348.1 	" />
                                <polyline id="Arrow_20_" className="st20" points="1142.2,329.5 1148.2,339.2 1142.2,348.1 	" />
                                <polyline id="Arrow_19_" className="st20" points="1142.2,218.3 1148.2,228 1142.2,236.8 	" />
                                <polyline id="Arrow_18_" className="st20" points="1140.2,97 1146.2,106.7 1140.2,115.6 	" />
                                <polyline id="Arrow_17_" className="st20" points="1140.2,65.7 1146.2,75.4 1140.2,84.2 	" />
                                <polyline id="Arrow_24_" className="st20" points="1548.7,770.5 1558.4,764.5 1567.2,770.5 	" />
                                <polyline id="Arrow_25_" className="st20" points="721.1,519.5 730.8,513.5 739.6,519.5 	" />
                                <polyline id="Arrow_26_" className="st20" points="453.9,770.1 463.6,764.1 472.5,770.1 	" />
                                <polyline id="Arrow_27_" className="st20" points="146.6,666.1 156.3,660.1 165.1,666.1 	" />
                                <polyline id="Arrow_34_" className="st20" points="721.1,268.8 730.8,262.8 739.6,268.8 	" />
                                <polyline id="Arrow_33_" className="st20" points="776,262.8 766.3,268.8 757.5,262.8 	" />
                                <polyline id="Arrow_47_" className="st20" points="702.9,162.5 693.2,168.5 684.4,162.5 	" />
                                <polyline id="Arrow_48_" className="st20" points="827.2,162.5 817.5,168.5 808.7,162.5 	" />
                                <polyline id="Arrow_35_" className="st20" points="437,263.8 427.3,269.8 418.4,263.8 	" />
                                <polyline id="Arrow_49_" className="st20" points="203.8,262.8 194.1,268.8 185.2,262.8 	" />
                                <polyline id="Arrow_50_" className="st20" points="203.8,157.6 194.1,163.6 185.2,157.6 	" />
                                <polyline id="Arrow_36_" className="st20" points="512.1,263.8 502.4,269.8 493.5,263.8 	" />
                                <polyline id="Arrow_37_" className="st20" points="473.3,412.6 463.6,418.6 454.8,412.6 	" />
                                <polyline id="Arrow_40_" className="st20" points="434.1,412.6 424.4,418.6 415.6,412.6 	" />
                                <polyline id="Arrow_38_" className="st20" points="473.3,515.3 463.6,521.3 454.8,515.3 	" />
                                <polyline id="Arrow_39_" className="st20" points="1267.7,765.5 1258,771.5 1249.2,765.5 	" />
                                <polyline id="Arrow_46_" className="st20" points="1126.6,765.5 1117,771.5 1108.1,765.5 	" />
                                <polyline id="Arrow_45_" className="st20" points="1267.7,570.9 1258,576.9 1249.2,570.9 	" />
                                <polyline id="Arrow_44_" className="st20" points="1002.4,360.9 992.7,366.9 983.8,360.9 	" />
                                <polyline id="Arrow_43_" className="st20" points="1002.4,264.6 992.7,270.6 983.8,264.6 	" />
                                <polyline id="Arrow_42_" className="st20" points="1172.4,570.9 1162.8,576.9 1153.9,570.9 	" />
                                <polyline id="Arrow_41_" className="st20" points="1548.7,517.9 1558.4,511.9 1567.2,517.9 	" />
                                <polyline id="Arrow_30_" className="st20" points="1548.7,370.9 1558.4,364.9 1567.2,370.9 	" />
                                <polyline id="Arrow_31_" className="st20" points="1326.8,370.9 1336.5,364.9 1345.3,370.9 	" />
                                <polyline id="Arrow_32_" className="st20" points="1548.7,267.2 1558.4,261.2 1567.2,267.2 	" />
                                <polyline id="Arrow_29_" className="st20" points="1326.5,162.2 1336.2,156.2 1345,162.2 	" />
                                <polyline id="Arrow_28_" className="st20" points="1227.3,156.2 1217.6,162.2 1208.8,156.2 	" />
                                <polyline id="Arrow_7_" className="st20" points="883.7,810.7 877.7,801 883.7,792.1 	" />
                                <polyline id="Arrow_8_" className="st20" points="1148.6,398.9 1142.6,389.2 1148.6,380.4 	" />
                                <polyline id="Arrow_5_" className="st20" points="888.3,206.1 882.3,196.4 888.3,187.5 	" />
                                <polyline id="Arrow_6_" className="st20" points="350.8,308.3 344.8,298.6 350.8,289.8 	" />
                                <polyline id="Arrow_4_" className="st20" points="884,458 878,448.3 884,439.5 	" />
                                <polyline id="Arrow_3_" className="st20" points="1409.2,514.3 1403.2,504.6 1409.2,495.8 	" />
                                <polyline id="Arrow_2_" className="st20" points="1411.2,663.4 1405.2,653.7 1411.2,644.9 	" />
                                <polyline id="Arrow_1_" className="st20" points="1411.5,205.7 1405.5,196 1411.5,187.1 	" />
                                <polyline id="Arrow" className="st20" points="612.6,185.3 618.6,195 612.6,203.9 	" />
                            </g>
                            <g className="hover">
                                <rect id="Rectangle_-_4" x="747.9" y="465.8" className="st-transparent" width="926.9" height="425.2"
                                    onMouseEnter={() => this.hoverPie(28)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 28)} />
                                <rect id="Rectangle_-_3" x="40.6" y="465.8" className="st-transparent" width="707.2" height="425.2"
                                    onMouseEnter={() => this.hoverPie(27)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 27)} />
                                <rect id="Rectangle_-_2-2" x="747.9" y="40.6" className="st-transparent" width="926.9" height="425.2" />
                                <rect id="Rectangle_-_2-1" x="747.9" y="40.6" className="st-transparent" width="926.9" height="425.2"
                                    onMouseEnter={() => this.hoverPie(26)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 26)} />
                                <rect id="Rectangle_-_1" x="40.6" y="40.6" className="st-transparent" width="707.2" height="425.2"
                                    onMouseEnter={() => this.hoverPie(25)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 25)} />
                                <path id="Rectangle_-_24" className="st-transparent" d="M290.8,651.9H58.1c-3.1,0-5.7-2.5-5.7-5.7V431.9c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v214.3C296.4,649.3,293.9,651.9,290.8,651.9z"
                                    onMouseEnter={() => this.hoverPie(24)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 24)} />
                                <path id="Rectangle_-_23" className="st-transparent" d="M598,757H365.4c-3.1,0-5.7-2.5-5.7-5.7V537c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v214.3C603.7,754.4,601.2,757,598,757z"
                                    onMouseEnter={() => this.hoverPie(23)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 23)} />
                                <path id="Rectangle_-_22" className="st-transparent" d="M865.9,858.1H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,855.5,869,858.1,865.9,858.1z"
                                    onMouseEnter={() => this.hoverPie(22)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 22)} />
                                <path id="Rectangle_-_21" className="st-transparent" d="M1230.8,489.7c17,3.1,11.1-2.6,24.1-5.1c17.6,0.5,35.8-5.1,53.4-3.3 c10,1.2,3.7,5.2,13.4,6.1c18.8,4.7,3,3.5,44.3,6.6c5.8,0.5,16.4,4.2,16.4,4.2c9.4,11,5.7,5.2,10.9,16.9c0.6,1.4,1.3,19.2,1.3,19.2 c-4.6,11.7-9.6,8.5-22.7,14.8c-4.9,5.6-20.3,8.1-28.8,10.9c-11.2,3.2-16.1-0.8-24.5,2.9c-5.5,2.4-41.3,5.2-41.3,5.2 c-19.4-0.5-15.8-1.4-35.2-2.4c-8.8-0.5-22.8-10.1-24.9-10.3c-13.7-1.9-19.4-4.7-33.4-5.2c-17.3-4.5-13.4-7.5-25.5-16.9 c-8.2-18.8,13.5-34.8,33.8-39.9C1223.7,495.2,1213.8,486.7,1230.8,489.7z"
                                    onMouseEnter={() => this.hoverPie(21)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 21)} />
                                <path id="Rectangle_-_20" className="st-transparent" d="M1129.1,448.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7V443C1134.8,446.2,1132.3,448.7,1129.1,448.7z"
                                    onMouseEnter={() => this.hoverPie(20)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 20)} />
                                <path id="Rectangle_-_19" className="st-transparent" d="M1392.9,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,354.2,1396.1,356.7,1392.9,356.7z"
                                    onMouseEnter={() => this.hoverPie(19)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 19)} />
                                <path id="Rectangle_-_18" className="st-transparent" d="M1129.1,356.7H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1134.8,354.2,1132.3,356.7,1129.1,356.7z"
                                    onMouseEnter={() => this.hoverPie(18)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 18)} />
                                <path id="Rectangle_-_17" className="st-transparent" d="M865.9,356.7H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,354.2,869,356.7,865.9,356.7z"
                                    onMouseEnter={() => this.hoverPie(17)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 17)} />
                                <path id="Rectangle_-_16" className="st-transparent" d="M865.9,253.2H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,250.6,869,253.2,865.9,253.2z"
                                    onMouseEnter={() => this.hoverPie(16)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 16)} />
                                <path id="Rectangle_-_15" className="st-transparent" d="M1392.9,145h-232.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,142.5,1396.1,145,1392.9,145z"
                                    onMouseEnter={() => this.hoverPie(15)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 15)} />
                                <path id="Rectangle_-_14" className="st-transparent" d="M1392.9,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1398.6,250.6,1396.1,253.2,1392.9,253.2z"
                                    onMouseEnter={() => this.hoverPie(14)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 14)} />
                                <path id="Rectangle_-_13" className="st-transparent" d="M1655.7,253.2h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,250.6,1658.9,253.2,1655.7,253.2z"
                                    onMouseEnter={() => this.hoverPie(13)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 13)} />
                                <path id="Rectangle_-_12" className="st-transparent" d="M1655.7,356.7h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,354.2,1658.9,356.7,1655.7,356.7z"
                                    onMouseEnter={() => this.hoverPie(12)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 12)} />
                                <path id="Rectangle_-_11" className="st-transparent" d="M1655.7,504.3h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,501.8,1658.9,504.3,1655.7,504.3z"
                                    onMouseEnter={() => this.hoverPie(11)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 11)} />
                                <path id="Rectangle_-_10" className="st-transparent" d="M1655.7,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1661.4,754,1658.9,756.5,1655.7,756.5z"
                                    onMouseEnter={() => this.hoverPie(10)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 10)} />
                                <path id="Rectangle_-_9" className="st-transparent" d="M1655.7,858.1h-232.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C1661.4,855.5,1658.9,858.1,1655.7,858.1z"
                                    onMouseEnter={() => this.hoverPie(9)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 9)} />
                                <path id="Rectangle_-_8" className="st-transparent" d="M1394,859.1H896.5c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H1394 c3.1,0,5.7,2.5,5.7,5.7v66.7C1399.6,856.5,1397.1,859.1,1394,859.1z"
                                    onMouseEnter={() => this.hoverPie(8)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 8)} />
                                <path id="Rectangle_-_7" className="st-transparent" d="M1394.2,756.5h-232.6c-3.1,0-5.7-2.5-5.7-5.7V591.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v159.3C1399.8,754,1397.3,756.5,1394.2,756.5z"
                                    onMouseEnter={() => this.hoverPie(7)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 7)} />
                                <path id="Rectangle_-_6" className="st-transparent" d="M865.9,504.3H633.2c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C871.6,501.8,869,504.3,865.9,504.3z"
                                    onMouseEnter={() => this.hoverPie(6)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 6)} />
                                <path id="Rectangle_-_5" className="st-transparent" d="M598,504.3H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,501.8,601.2,504.3,598,504.3z"
                                    onMouseEnter={() => this.hoverPie(5)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 5)} />
                                <path id="Rectangle_-_4_1_" className="st-transparent" d="M330.2,356.7H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,354.2,333.3,356.7,330.2,356.7z"
                                    onMouseEnter={() => this.hoverPie(4)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 4)} />
                                <path id="Rectangle_-_3_1_" className="st-transparent" d="M598,356.7H365.4c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7H598 c3.1,0,5.7,2.5,5.7,5.7v66.7C603.7,354.2,601.2,356.7,598,356.7z"
                                    onMouseEnter={() => this.hoverPie(3)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 3)} />
                                <path id="Rectangle_-_2_1_" className="st-transparent" d="M330.2,253.2H97.6c-3.1,0-5.7-2.5-5.7-5.7v-66.7c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,250.6,333.3,253.2,330.2,253.2z"
                                    onMouseEnter={() => this.hoverPie(2)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 2)} />
                                <path id="Rectangle_-_1_1_" className="st-transparent" d="M330.2,145H97.6c-3.1,0-5.7-2.5-5.7-5.7V72.6c0-3.1,2.5-5.7,5.7-5.7h232.6 c3.1,0,5.7,2.5,5.7,5.7v66.7C335.9,142.5,333.3,145,330.2,145z"
                                    onMouseEnter={() => this.hoverPie(1)} onMouseLeave={() => this.hoverPie(-1)} onClick={(e) => this.selectPie(e, 1)} />
                            </g>
                        </g>
                    </svg>
                </div></div>
                <Zoom onClick={() => this.ToggleFullsreen()} />
                <div className="close-zoom"><FullscreenClose onClick={() => this.ToggleFullsreen()} /></div>
                <div className="close-zoom pos-bottom">
                    <div className={"scroll-indication" + (this.state.showIndicator ? ' show' : '')} onClick={() => this.ToggleFullsreen()}>
                        <div className="scroll-indication-text">Close for details</div>
                        <div className="scroll-indication-arrow"><ArrowDown /></div>
                    </div>
                </div>

                <div className="copyright-malik">&copy; Malik 2023</div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelSet,
        SelectedPath
    } = state.model;

    return {
        SelectedLanguage,
        ModelSet,
        SelectedPath
    };
}

const actionCreators = {
    setSelectedPath: modelActions.setSelectedPath
};

const connectedModel4 = connect(mapState, actionCreators)(Model4);
export { connectedModel4 as Model4 };
