/** Language locale */
export enum LanguageLocale {
  German = 'de-DE',
  English = 'en-GB'
}

/** Language name. Usually is used for displaying purpose */
export enum LanguageName {
  German = 'DE',
  English = 'ENG'
}

/** User that is stored as a part of AppBarState */
export interface User {
  id: number,
  companyName?: string,
  defaultLanguage: LanguageLocale,
  email: string,
  firstName: string,
  lastName: string
}

/** AppBar state that is shared across AppBar components */
export interface AppBarState {
  language: {
    locale: LanguageLocale,
    name: LanguageName
  },
  user: User,
  loggedInState: LoginState,
  theme: Theme,
  webchat: {
    history: string[]
  }
}

/** Theme - light or dark */
export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

/** Indicates different stages of login process */
export enum LoginState {
  LoggedOut = 'logged-out',
  LoggingIn = 'logging-in',
  LoggedIn = 'logged-in'
}
