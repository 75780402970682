import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { mkpActions, modelActions } from '../../../../store/_actions';
//components
import { BreadcrumbsNav } from '../../../shared/Breadcrumbs/BreadcrumbsNav';
import { Stage } from './../../../shared/Stage/Stage';
import { Tiles } from './../../../shared/Tiles/Tiles';
import { BiteContent } from './BiteContent';
import { Loader } from '../../../shared/Loader/Loader';
//styles
import './Bite.scss';
import { getUserId } from '../../../../store/_services';
import { layoutConstants } from '../../../../store/_constants';

class Bite extends Component {
  constructor(props) {
    super(props);

    //get id from url
    const { id } = this.props.match.params;

    this.BiteId = id;

    this.state = {
      Container: null,
      Topic: null,
      Bite: null,
      Refreshing: true,
      RefreshingTiles: true,
    };
  }

  componentDidMount() {
    if (document.getElementsByTagName('body')[0].scrollTop > 0) {
      document.getElementsByTagName('body')[0].scrollTo({ top: 0, behavior: 'smooth' });
    }

    //console.log("Mounted component: Bite");
    this.props.getBite(this.BiteId, this.props.SelectedLanguage);
    this.props.ModelClose();

    //getlikes
    if (this.props.likes === undefined) {
      this.props.getLikes(getUserId(), 1, -1);
    }

    //getfavorites
    if (this.props.favorites === undefined) {
      this.props.getFavorites(getUserId(), 1, -1, this.props.SelectedLanguage);
    }
  }

  componentDidUpdate(prevProps) {
    //on "page" change
    if (this.state.BiteId !== this.props.match.params.id) {
      this.setState(
        {
          Container: null,
          Topic: null,
          Bite: null,
          BiteId: this.props.match.params.id,
          Refreshing: true,
          RefreshingTiles: true,
        },
        function () {
          this.props.getBite(this.props.match.params.id, this.props.SelectedLanguage);
        },
      );
    }

    if (this.props.Bite) {
      if (prevProps.Bite !== this.props.Bite && this.props.Bite.items[0].id == this.state.BiteId) {
        this.setState(
          {
            Bite: this.props.Bite.items[0],
            RefreshingTiles: false,
            IsChanging: true,
          },
          function () {
            //get topic bites
            this.props.getBitePages(this.props.Bite.items[0].id, -1, this.props.SelectedLanguage);

            //check if this needs to pe updated
            if (this.props.Topic !== undefined) {
              if (this.state.Bite.topicId !== this.props.Topic.items[0].id) {
                this.props.getTopic(this.props.Bite.items[0].topicId, this.props.SelectedLanguage);
              } else {
                this.setState(
                  {
                    Topic: this.props.Topic.items[0],
                    Refreshing: false,
                  },
                  function () {
                    if (this.props.Container !== undefined) {
                      if (this.state.Topic.containerId !== this.props.Container.items[0].id) {
                        this.props.getContainer(this.props.Topic.items[0].containerId, this.props.SelectedLanguage);
                      } else {
                        this.setState(
                          {
                            Container: this.props.Container.items[0],
                            Refreshing: false,
                          },
                          function () {
                            this.UpdateSelectedPath(
                              this.state.Container.id,
                              this.state.Topic.id,
                              this.state.Bite.id,
                              'Page',
                              this.state.Container.modelId,
                            );
                          },
                        );
                      }
                    } else {
                      this.props.getContainer(this.props.Topic.items[0].containerId, this.props.SelectedLanguage);
                    }
                  },
                );
              }
            } else {
              this.props.getTopic(this.props.Bite.items[0].topicId, this.props.SelectedLanguage);
            }
          },
        );
      }
    }

    //topic changed and id's match (updated)
    if (this.props.Topic) {
      if (prevProps.Topic !== this.props.Topic && this.props.Topic.items[0].id == this.props.Bite.items[0].topicId) {
        //check if this needs to pe updated
        if (this.props.Container !== undefined) {
          if (this.props.Topic.items[0].containerId != this.props.Container.id) {
            this.props.getContainer(this.props.Topic.items[0].containerId, this.props.SelectedLanguage);
            this.setState({
              Topic: this.props.Topic.items[0],
              Refreshing: false,
            });
          }
        } else {
          this.setState({
            Topic: this.props.Topic.items[0],
          });
          this.props.getContainer(this.props.Topic.items[0].containerId, this.props.SelectedLanguage);
        }
      }
    }

    //topic changed and id's match (updated)
    if (this.props.Container) {
      if (
        prevProps.Container !== this.props.Container &&
        this.props.Container.items[0].id == this.props.Topic.items[0].containerId
      ) {
        //check if this needs to pe updated
        this.setState(
          {
            Container: this.props.Container.items[0],
            Refreshing: false,
          },
          function () {
            this.UpdateSelectedPath(
              this.state.Container.id,
              this.state.Topic.id,
              this.state.Bite.id,
              'Page',
              this.state.Container.modelId,
            );
          },
        );
      }
    }

    //container change (updated)
    if (this.props.Topic && this.props.Container && this.props.Bite) {
      if (
        this.props.Bite.items[0].id == this.state.BiteId &&
        this.props.Topic.items[0].containerId === this.props.Container.items[0].id &&
        prevProps.Container !== this.props.Container
      ) {
        this.setState(
          {
            Container: this.props.Container.items[0],
            RefreshingTiles: false,
          },
          function () {
            this.UpdateSelectedPath(
              this.state.Container.id,
              this.state.Topic.id,
              this.state.Bite.id,
              'Page',
              this.state.Container.modelId,
            );
          },
        );
      }
    }
  }

  /************************************
   * SELECTED PATH UPDATE
   ***********************************/

  UpdateSelectedPath(container, topic, bite, lastUpdate, model) {
    let path = {};
    path = Object.assign({}, path, this.props.SelectedPath);

    if (model) {
      path.Model = model;
    }
    if (container) {
      path.Container = container;
    }
    if (topic) {
      path.Topic = topic;
    }
    if (bite) {
      path.Bite = bite;
    }
    if (lastUpdate != null) {
      path.LastUpdate = lastUpdate;
    }

    this.props.setSelectedPath(path);

    this.setState({ indexLoader: false });
  }

  render() {
    return (
      <div className="alias-bite">
        <BreadcrumbsNav Type="Bite" Refreshing={!this.state.Topic || !this.state.Container || !this.state.Bite} />
        <Stage Type="Bite" Refreshing={!this.state.Topic || !this.state.Container || !this.state.Bite} />
        {this.state.Refreshing && (
          <div className="page-body">
            <Loader />
          </div>
        )}
        {this.props.Bite && this.props.Pages && !this.state.Refreshing && <BiteContent />}
        {/* this is only for mvp - static media mode */}
        {/*!this.state.RefreshingTiles && this.props.Bite.items[0].topicId !== 5 && <Tiles Title="RELATED HOLOS FEEDS" Type="topic" Ignore={this.props.match.params.id} />*/}
        {!this.state.RefreshingTiles /*&& this.props.Bite.items[0].topicId === 5*/ && (
          <Tiles
            Title={
              this.props.SelectedLanguage == layoutConstants.EnglishLanguage
                ? 'RELATED HOLOS FEEDS'
                : 'VERWANDTE HOLOS FEEDS'
            }
            Type="topic"
            Ignore={this.props.match.params.id}
            Id={this.BiteId}
          />
        )}
        {this.state.RefreshingTiles && (
          <div className="tile-container">
            <input type="checkbox" id={'tiles_' + 'RELATED HOLOS TOPICS'.replace(' ', '')} hidden />
            <div className="tile-title">
              <label htmlFor={'tiles_' + 'RELATED HOLOS TOPICS'.replace(' ', '')}>RELATED HOLOS TOPICS</label>
            </div>
            <div className="tile-content">
              <Loader />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { SelectedLanguage } = state.layout;

  const { Container, Topic, Bite, Pages, likes, favorites } = state.mkp;

  const { ModelSet, SelectedPath } = state.model;

  return {
    SelectedLanguage,
    Container,
    Topic,
    Bite,
    Pages,
    likes,
    favorites,
    ModelSet,
    SelectedPath,
  };
}

const actionCreators = {
  getContainer: mkpActions.getContainer,
  getTopic: mkpActions.getTopic,
  getBite: mkpActions.getBite,
  getBitePages: mkpActions.getBitePages,
  ModelClose: mkpActions.ModelClose,
  getLikes: mkpActions.getLike,
  getFavorites: mkpActions.getFavorite,
  setSelectedPath: modelActions.setSelectedPath,
};

const connectedMKP = connect(mapState, actionCreators)(Bite);
export { connectedMKP as Bite };
