import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { Tile } from './../../../shared/Tile/Tile';
//icons
import { ReactComponent as IconQuizz } from './../../../../Assets/images/icons/icon_quizz.svg';
import { ReactComponent as IconExercise } from './../../../../Assets/images/icons/icon_exercises.svg';
import { ReactComponent as IconTime } from './../../../../Assets/images/icons/icon_time.svg';

import { layoutConstants } from '../../../../store/_constants';


class TopicBite extends Component {
    constructor(props) {
        super(props);

        this.accessToken = this.props.accessToken;

        this.state = {
            Bite: this.props.Bite,
            Topic: this.props.Topic,
            loading: false
        };

    }

    render() {
        return (
            <div className={"topic" + (this.props.OpenContext.Id === this.state.Bite.id ? ' active-bite':'')}>
                {this.state.Topic && <Tile Tile={this.state.Bite} />}
                <div className="bite-text">
                    <div className="subtext" dangerouslySetInnerHTML={{ __html: this.state.Bite.overview }}></div>
                </div>
                <div className="bite-quizz">
                    <div className="bq-title">{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "QUIZZES & EXERCISES" : "QUIZZE & ÜBUNGEN"}</div>
                    <div className="bq-element">
                        <div className="bq-icon">
                            <IconQuizz />
                        </div>
                        <span>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Interactive Quizzes" : "Interaktive Quizze"}</span><span>{this.state.Bite.quizCounter}</span>
                    </div>
                    <div className="bq-element">
                        <div className="bq-icon">
                            <IconExercise />
                        </div>
                        <span>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Exercises & Reflections" : "Übungen & Reflexionen"}</span><span>{this.state.Bite.reflectionCounter}</span>
                    </div>
                    <div className="bq-element">
                        <div className="bq-icon">
                            <IconTime />
                        </div>
                        <div><strong>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Estimated time effort" : "Geschätzter Zeitaufwand"}:</strong> {this.state.Bite.estimatedTimeInMinutes} min.</div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { loading, Topic, OpenContext} = state.mkp;
    const {
        SelectedLanguage
    } = state.layout;

    return { SelectedLanguage, loading, Topic, OpenContext };
}

const connectedMKP = connect(mapState, null)(TopicBite);
export { connectedMKP as TopicBite };