import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { modelActions, mkpActions, authenticationActions } from '../../store/_actions';
// components
import { Bite } from '../pages/mkp/bite/Bite';
import { Home } from '../pages/mkp/home/Home';
import { Topic } from '../pages/mkp/topic/Topic';
import { Feedback } from './Feedback/Feedback';
import {Switch} from "react-router-dom";

class Layout extends Component {
    
    constructor(props) {
        super(props);
        this.isFeedbackDisplayed = false;
    }

    async componentDidMount() {
        if (this.props.Theme === "lightMode") {
            document.getElementsByTagName('html')[0].classList.add('lightMode');
            document.getElementsByTagName('html')[0].classList.remove('darkMode');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('lightMode');
            document.getElementsByTagName('html')[0].classList.add('darkMode');
        }
        
        //prevent Horizontal scroll to changepage                
        document.getElementById("root").addEventListener("wheel", this.callback);

        //get models
        this.LoadModels();

        //getlikes
        this.LoadLiked();

        //getfavorites
        this.LoadFavorited()
    }

    componentWillUnmount() {
        //remove registered listened
        if (document.getElementById("root").length > 0) {
            document.getElementById("root").removeEventListener("wheel", this.callback);
        }
    }

    callback(event) {
        const delta = event.deltaX || event.wheelDeltaX;
        if (!delta) { return; }
        if (Math.abs(delta) > 0) {
            event.preventDefault();
        }
    }

    LoadModels() {
        if (!this.props.ModelTree) {
            //load all models            
            this.props.getModels(this.props.SelectedLanguage);
        }
    }

    LoadFavorited() {
        if (this.props.favorites === undefined) {
            const user = JSON.parse(localStorage.getItem('user'));
            this.props.getFavorites(user.profile.id, 1, 25, this.props.SelectedLanguage);
        }
    }

    LoadLiked() {
        if (this.props.likes === undefined) {
            const user = JSON.parse(localStorage.getItem('user'));
            this.props.getLikes(user.profile.id, 1, 25);
        }
    }

    componentDidUpdate(prevProps) {
        //theme updated
        if (prevProps.Theme !== this.props.Theme) {
            const _this = this;
            setTimeout(function () {
                if (_this.props.Theme === "lightMode") {
            document.getElementsByTagName('html')[0].classList.add('lightMode');
            document.getElementsByTagName('html')[0].classList.remove('darkMode');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('lightMode');
            document.getElementsByTagName('html')[0].classList.add('darkMode');
        }
            }, 300);
        }

        //language updated
        if (prevProps.SelectedLanguage !== this.props.SelectedLanguage) {
            setTimeout(function () {
                document.location.reload();
            }, 300);
        }

        if (!prevProps.loggedIn && this.props.loggedIn) {
            //get models
            this.LoadModels();

            //getlikes
            this.LoadLiked();

            //getfavorites
            this.LoadFavorited();
        }

        //loaded models
        if (prevProps.LoadingModels && !this.props.LoadingModels && this.props.SelectedPath.LastUpdate !== "Page") {
            this.props.updateModelSet(this.props.Models);

            //set initial path
            let path = {};
            path = Object.assign({}, path, this.props.SelectedPath);
            path.Model = this.props.Models.items[0].id;
            path.LastUpdate = "Model";
            this.props.setSelectedPath(path);
        }
    }

    render() {
        return (
            <div className="layout-container">
                <div className="content-main">
                    <Switch>
                        <Route exact path='/' render={(props) => <Home {...props} />}/>
                        <Route exact path='/Model/:id' render={(props) => <Home {...props} />}/>
                        <Route path='/Topic/:id' render={(props) => <Topic {...props} />}/>
                        <Route path='/Feed/:id' render={(props) => <Bite {...props} />}/>
                    </Switch>
                </div>
                {this.isFeedbackDisplayed && <Feedback/>}
                <small className='text-muted position-absolute bottom-0 end-0' hidden>v.{process.env.REACT_APP_VERSION}</small>
            </div>
        );
    }
}

function mapState(state) {
    const {
        loggedIn
    } = state.authentication;

    const {
        SelectedLanguage,
    } = state.layout;

    const {
        Theme
    } = state.theme;

    const {
        Models,
        LoadingModels,
        ModelTree,
        LoadingModelTree,
        ModelSet,
        SelectedPath
    } = state.model;

    const {
        SelectedModel,
        likes,
        favorites
    } = state.mkp;

    return {
        Theme,
        loggedIn,
        Models,
        LoadingModels,
        ModelTree,
        LoadingModelTree,
        ModelSet,
        SelectedPath,
        SelectedLanguage,
        SelectedModel,
        likes,
        favorites
    };
}

const actionCreators = {
    getModels: modelActions.getModels,
    updateModelSet: modelActions.updateModelSet,
    SelectModel: mkpActions.SelectModel,
    setSelectedPath: modelActions.setSelectedPath,
    getLikes: mkpActions.getLike,
    getFavorites: mkpActions.getFavorite,
    logout: authenticationActions.logout,
};

const connectedAuthentication = withRouter(connect(mapState, actionCreators)(Layout));
export { connectedAuthentication as Layout };