import React, { Component } from 'react';
import FilePlayer from 'react-player/lib/players/FilePlayer'
//svg's
import { ReactComponent as MediaNext } from './../../../../Assets/images/icons/media/forward_icon.svg';
import { ReactComponent as MediaPause } from './../../../../Assets/images/icons/media/icon_pause.svg';
import { ReactComponent as MediaPlay } from './../../../../Assets/images/icons/media/play_icon.svg';
import { ReactComponent as MediaPrev } from './../../../../Assets/images/icons/media/backwards_icon.svg';
import { ReactComponent as MediaReplay } from './../../../../Assets/images/icons/media/icon_replay.svg';

export class BiteAudio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            played: 0,
            duration: 0,
            remainingTime: "",
            playedTime: "",
            seeker: "0",
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Stop === false && this.props.Stop === true && this.state.playing) {
            this.handlePlayPause();
        }
    }

    ref = player => {
        this.player = player
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    skipTo = (dir) => {
        var found = false;
        var i = 0;
        var pos = -1;
        if (dir > 0) {
            while (!found && i < this.props.Sections.length) {
                if (this.props.Sections[i] > this.player.getCurrentTime()) {
                    found = true;
                    pos = this.props.Sections[i] / this.player.getDuration();
                }
                i++;
            }
        } else {
            i = this.props.Sections.length - 1;
            while (!found && i >= 1) {
                if (this.props.Sections[i] < this.player.getCurrentTime()) {
                    found = true;
                    pos = this.props.Sections[i - 1] / this.player.getDuration();
                }
                i--;
            }
        }

        if (pos !== -1) {
            this.setState({ played: pos }, function () {
                this.player.seekTo(pos);
            });
        }
    }

    handleDuration = (duration) => {
        this.setState({ duration })
    }

    handleProgress = state => {
        this.setState(state)
        this.updateTimes();
        this.updateSeeker(this.state.played * 100 + "%");
    }

    updateTimes() {
        //elapsed
        var time = this.state.played * this.state.duration;
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "") + secs;

        this.setState({ playedTime: ret });

        //remaining
        time = this.state.duration * (1 - this.state.played);
        hrs = ~~(time / 3600);
        mins = ~~((time % 3600) / 60);
        secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "") + secs;

        this.setState({ remainingTime: "-" + ret });
    }

    updateSeeker(pos) {
        this.setState({ seeker: pos })
    }

    handleSeekChange = e => {
        var pos = 0;
        var left = 0;
        if (e !== undefined) {
            //click position
            var offset = e.currentTarget.getClientRects()[0];
            left = e.clientX - offset.left;
            pos = (100 * left) / e.currentTarget.offsetWidth;
            pos = parseFloat(pos / 100 * this.state.duration)
        }

        this.updateSeeker(left + "px");
        this.setState({ played: pos })
        this.player.seekTo(pos);
    }

    handleReplay = e => {
        this.handleSeekChange();
    }

    render() {
        return (
            <div className="mc-container">
                <div className="mc-controls">
                    <div className="mc-progress-tracker">
                        <div className="mc-seekbar" onClick={this.handleSeekChange}>
                            <div className="mc-played-line" style={{ width: this.state.seeker }}></div>
                            <div style={{ left: this.state.seeker }}></div>
                        </div>
                        <div className="mc-played">{this.state.playedTime}</div>
                        <div className="mc-remaining">{this.state.remainingTime}</div>
                    </div>
                    <div className="mc-buttons">
                        <div className="btn-mc mc-replay"><MediaReplay onClick={this.handleReplay} /></div>
                        <div className="mc-buttons-main">
                            <div className="btn-mc mc-prev"><MediaPrev onClick={() => this.skipTo(-1)} /></div>
                            <div className="btn-mc mc-playPause">
                                <MediaPlay onClick={this.handlePlayPause} className={this.state.playing ? 'hide' : 'icon-play'} />
                                <MediaPause onClick={this.handlePlayPause} className={!this.state.playing ? 'hide' : 'icon-pause'} />
                            </div>
                            <div className="btn-mc mc-next"><MediaNext onClick={() => this.skipTo(1)} /></div>
                        </div>
                    </div>
                </div>
                <FilePlayer
                    id="audioPlayer"
                    url={this.props.File}
                    width="0"
                    height="0"
                    ref={this.ref}
                    playing={this.state.playing}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration} />
            </div>
        );
    }
}
