import { layoutConstants } from '../_constants';

const initialState = {
    SelectedLanguage: layoutConstants.GermanLanguage
}

export function layout(state = initialState, action) {
    switch (action.type) {
        //set current language
        case layoutConstants.SET_LANGUAGE:
            return {
                ...state,
                SelectedLanguage: action.language
            };
        default:
            return state
    }
}

export default layout