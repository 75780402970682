import { feedbackConstants } from '../_constants';

const initialState = {
    FeedbackStatus: "",
    FeedbackMessage: "",
    FeedbackError: false
}

export function feedback(state = initialState, action) {
    switch (action.type) {

        case feedbackConstants.FEEDBACK_REQUEST:
            return {
                ...state,
                FeedbackLoading: true,
                FeedbackError: false,
            };
        case feedbackConstants.FEEDBACK_SUCCESS:
            return {
                ...state,
                FeedbackStatus: "Success",
                FeedbackMessage: action.result.inlineMessage,
                FeedbackLoading: false

            };
        case feedbackConstants.FEEDBACK_FAILURE:
            return {
                ...state,
                FeedbackStatus: "Failure",
                FeedbackMessage: action.error,
                FeedbackError : true,
                FeedbackLoading: false
            };
        default:
            return state
    }
}

export default feedback