import { feedbackConstants } from '../_constants';
import { feedbackService } from '../_services';

export const feedbackActions = {
    sendFeedback
};

function sendFeedback(fields) {
    return dispatch => {
        dispatch(request({}));

        feedbackService.sendFeedback(fields)
            .then(
                result => {
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(result) { return { type: feedbackConstants.FEEDBACK_REQUEST, result } }
    function success(result) { return { type: feedbackConstants.FEEDBACK_SUCCESS, result } }
    function failure(error) { return { type: feedbackConstants.FEEDBACK_FAILURE, error } }
}
