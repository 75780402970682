import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { modelActions, mkpActions } from '../../../../../store/_actions';
//components
import { ModelIndex } from './../ModelIndex/ModelIndex';
import { Model1 } from './Models/Model1';
import { Model2 } from './Models/Model2';
import { Model3 } from './Models/Model3';
import { Model4 } from './Models/Model4';
import { Model5 } from './Models/Model5';
import { Loader } from '../../../../shared/Loader/Loader';
//icons
import { ReactComponent as Model1Icon } from './../../../../../Assets/images/models/management-grundlagen.svg';
import { ReactComponent as Model2Icon } from './../../../../../Assets/images/models/management-organisationen.svg';
import { ReactComponent as Model3Icon } from './../../../../../Assets/images/models/management-personen.svg';
import { ReactComponent as Model4Icon } from './../../../../../Assets/images/models/management-prozesse.svg';
import { ReactComponent as Model5Icon } from './../../../../../Assets/images/models/management-grossen-transformation-21.svg';
import { ReactComponent as ArrowDown } from './../../../../../Assets/images/icons/icon_arrow_down_1.svg';
import {withRouter} from "react-router-dom";
import { layoutConstants } from '../../../../../store/_constants';

const prev2 = 'prev2';
const prev = "prev";
const active = "active";
const next = "next";
const next2 = "next2";

class ModelCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            carouselView: this.props.CarouselView,
            showIndicator: false,
            Zoom: false,
            navisList: [],
        };

        this.navigationStyles = [prev2, prev, active, next, next2];
        /*check on this one: */
        this.blockSwipe = false;

        // handlers
        this.handleSelectedModel = this.handleSelectedModel.bind(this);
        this.SetZoom = this.SetZoom.bind(this);
        this.GoToIndex = this.GoToIndex.bind(this);
        this.handleCarouselViewChange = this.handleCarouselViewChange.bind(this);
    }

    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {
        if (this.props.ModelSet.totalItems > 0) {
            this.LoadModelCarouselInfo();
        }
    }

    componentWillUnmount() {
        //test scroll event1
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        if (document.getElementById("modelElem")) {
            document.getElementById("modelElem").removeEventListener("wheel", tHandler);
        }
    }

    InitCarouselSliderEvent() {
        // scroll event for models slider 
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("modelElem").addEventListener("wheel", tHandler, false);
    }

    callback(event) {
        const delta = event.deltaX || event.wheelDeltaX;

        //if its horizontal then:
        if (Math.abs(delta) > 0) {
            event.preventDefault();
            event.stopPropagation();

            if (!this.blockSwipe) {
                this.blockSwipe = true;

                if (delta > 0) {
                    document.getElementById("modelNav").getElementsByClassName(next)[0].click();
                }
                else if (delta < 0) {
                    document.getElementById("modelNav").getElementsByClassName(prev)[0].click();
                }
            }
        }
    }

    throttled(delay, fn) {
        let lastCall = 0;
        return function (...args) {
            const now = (new Date()).getTime();
            if (now - lastCall < delay) {
                return false;
            }
            lastCall = now;
            return fn(...args);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.ModelSet !== this.props.ModelSet) {
            if (this.props.ModelSet.totalItems > 0 && this.state.navisList.length === 0) {
                if(this.props.match.isExact && this.props.match.params.id){
                    const modelId = parseInt(this.props.match.params.id);
                    if(this.props.ModelSet.items.find(x => x.id === modelId)){
                        this.updateSelectedPath(modelId);
                        this.LoadModelCarouselInfo(modelId);
                    } else{
                        this.LoadModelCarouselInfo();
                    }
                    window.history.replaceState(null, null, '/');
                } else {
                    this.LoadModelCarouselInfo();
                }
            }
        }

        if (prevState.navisList.length === 0 && this.state.navisList.length > 0 && this.props.SelectedPath.LastUpdate === "Page" && this.props.SelectedPath.Model !== this.props.Models.items[0].id) {
            //set the path when coming back from a topic/Bite page
            this.handleSelectedModel(null, this.props.SelectedPath.Model, this.props.SelectedPath.Model);
        }

        if (prevProps.CarouselView !== this.props.CarouselView) {
            this.setState({ carouselView: this.props.CarouselView });
        }
    }


    LoadModelCarouselInfo(modelId) {
        const navisList = [
            {
                icon: <Model4Icon/>,
                active: this.props.ModelSet.items[3],
                model: this.props.ModelSet.items[3],                
            },
            {
                icon: <Model5Icon/>,
                active: this.props.ModelSet.items[4],
                model: this.props.ModelSet.items[4],                
            },
            {
                icon: <Model1Icon/>,
                active: this.props.ModelSet.items[0],
                model: this.props.ModelSet.items[0],                
            },
            {
                icon: <Model2Icon/>,
                active: this.props.ModelSet.items[1],
                model: this.props.ModelSet.items[1],                
            },
            {
                icon: <Model3Icon/>,
                active: this.props.ModelSet.items[2],
                model: this.props.ModelSet.items[2],                
            },
        ];
        
        if(modelId !== undefined) {
            this.makeCurrent(navisList, modelId);
        }        


        /*this.navisList = [
           "Management Prozesse",
           "Management der\r\nGrossen\r\nTransformation 21",
           "Management Grundlagen",
           "Management für\r\nOrganisationen",
           "Management für\r\nPersonen"
       ];
       this.navisListActive = [
           "Management Prozesse",
           "Management der\r\nGrossen Transformation 21",
           "Management Grundlagen",
           "Management für Organisationen",
           "Management für Personen"
       ];*/

        this.setState({
            navisList: navisList
        }, function () {
            this.InitCarouselSliderEvent();
        });
    }


    SetZoom() {
        this.setState({ Zoom: !this.state.Zoom });
    }

    GoToIndex() {
        document.getElementsByTagName("body")[0].scrollTo({ top: this.refs.modelNav.offsetTop, behavior: 'smooth' });
    }

    /************************************
   * MODEL CHANGE
   ***********************************/

    changeModel(clickedElementStyle, modelId) {
        const isAlreadyActive = clickedElementStyle.indexOf(active) !== -1;
        if (isAlreadyActive) {
            return;
        }

        const isExpandedNavClicked = clickedElementStyle.indexOf("slide-nav") !== -1;
        const navigationDirection = clickedElementStyle.replace("slide-nav ", "");
        
        if (isExpandedNavClicked) {
            // 1. click on carousel subnav
            document.getElementById("modelElem").getElementsByClassName(navigationDirection)[0].click();
            return;
        }

        //click on carousel model

        //1. slide carousel
        this.changeModelTo(navigationDirection);

        //add timeout so the slider animation doesn't lag
        if (this.props.SelectedPath.LastUpdate !== "Page") {
            setTimeout(function () {

                //2. Update selected model
                this.updateSelectedPath(modelId);

                this.blockSwipe = false;
            }.bind(this), 550);
        }
    }

    updateSelectedPath(modelId) {
        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);
        path.Model = modelId;
        path.LastUpdate = "Model";
        this.props.setSelectedPath(path);
    }

    /************************************
    * MODEL CHANGE - DIRECTION FUNCTIONS
    ***********************************/

    changeModelTo(navigationDirection) {
        switch (navigationDirection) {
            case prev2:
                this.prev(); 
                this.prev(); 
                break;
            case prev:
                this.prev(); 
                break;
            case next:
                this.next(); 
                break;
            case next2:
                this.next();
                this.next();
                break;
            default: 
                break;
        }
    }
    
    makeCurrent(navisList, modelId) {
        if(navisList.find(x => x.model.id === modelId) === undefined) {
            return;
        }
        
        while(navisList[2].model.id !== modelId) {
            this.navigationStyles.unshift(this.navigationStyles.pop());
            navisList.push(navisList.shift());
        }
    }

    next() {
        this.navigationStyles.unshift(this.navigationStyles.pop());

        const navisList = this.state.navisList;
        navisList.push(navisList.shift());

        this.setState({
            navisList: navisList
        });
    }

    prev() {
        this.navigationStyles.push(this.navigationStyles.shift());

        const navisList = this.state.navisList;
        navisList.unshift(navisList.pop());

        this.setState({
            navisList: navisList
        });
    }

    /************************************
    * MODEL CHANGE UPON MODEL CLICK (FROM MODEL 2)
    ***********************************/

    handleSelectedModel(e, to, from) {
        if (from === this.props.SelectedPath.Model) {
            if (e) {
                e.stopPropagation();
            }

            //get index of selected model
            const order = this.state.navisList.filter(listItem => listItem.model.id === to)[0].model.order;
            let index = 2;
            switch (order) {
                case 1: index = 2; break;
                case 2: index = 3; break;
                case 3: index = 4; break;
                case 4: index = 0; break;
                case 5: index = 1; break;
                default: break;
            }

            document.getElementById("modelElem").children[index].click();
        }
    }

    /************************************
    * click on Model section or index
    ***********************************/

    setIndicator() {
        if (!this.state.showIndicator && document.getElementsByClassName("magnify").length === 0) {
            this.setState({ showIndicator: true }, function () {
                setTimeout(function () {
                    this.setState({ showIndicator: false })
                }.bind(this), 3000);
            });
        }
    }

    /************************************
    * Update Carousel View
    ***********************************/

    handleCarouselViewChange() {
        this.props.setCarouselView(!this.props.CarouselView);
    }

    render() {
        const { carouselView, navisList, showIndicator } = this.state
        const { ModelSet } = this.props

        return (
            <div className="model-carousel">
                <div className="content-wrapper">
                    <input id="modelsSizeToggle" type="checkbox"
                        defaultChecked={carouselView}
                        onChange={this.handleCarouselViewChange}
                        hidden />
                    <div id="mySlide" className={"slider" + (navisList.length === 0 || ModelSet.totalItems === 0 ? " loading" : "")}>                 
                        {navisList.length > 0 && ModelSet.totalItems > 0 && <div>
                            <div id="modelElem" className={"model-container" + (this.state.Zoom ? ' zooming' : '')}>
                                <div className={this.navigationStyles[0]} onClick={(e) => this.changeModel(e.currentTarget.className, this.props.ModelSet.items[3].id)}>
                                    <Model4
                                        handleZoom={this.SetZoom}
                                        ScrollTo={this.GoToIndex}
                                    />
                                </div>
                                <div className={this.navigationStyles[1]} onClick={(e) => this.changeModel(e.currentTarget.className, this.props.ModelSet.items[4].id)}>
                                    <Model5 Selected={this.props.SelectedPath.Model} handleZoom={this.SetZoom} />
                                </div>
                                <div className={this.navigationStyles[2]} onClick={(e) => this.changeModel(e.currentTarget.className, this.props.ModelSet.items[0].id)}>
                                    <Model1 Selected={this.props.SelectedPath.Model} handleZoom={this.SetZoom} />
                                </div>
                                <div className={this.navigationStyles[3]} onClick={(e) => this.changeModel(e.currentTarget.className, this.props.ModelSet.items[1].id)}>
                                    <Model2
                                        handleChangeModel={this.handleSelectedModel}
                                        handleZoom={this.SetZoom}
                                        ScrollTo={this.GoToIndex}
                                    />
                                </div>
                                <div className={this.navigationStyles[4]} onClick={(e) => this.changeModel(e.currentTarget.className, this.props.ModelSet.items[2].id)}>
                                    <Model3
                                        handleZoom={this.SetZoom}
                                        ScrollTo={this.GoToIndex}
                                    />
                                </div>

                            </div>
                            <div className={"scroll-indication" + (showIndicator ? ' show' : '')} onClick={(e) => this.GoToIndex()}>
                                <div className="scroll-indication-text">Scroll for details</div>
                                <div className="scroll-indication-arrow"><ArrowDown /></div>
                            </div>
                            <div className="slider-navigation" ref="modelNav">
                            <div className='logoBanner'></div>
                                <div className="slider-navigation-container" id="modelNav">
                                    <div className={`slide-nav ${prev2}`} onClick={(e) => this.changeModel(e.currentTarget.className, navisList[0].model.id)}>
                                        {navisList[0].icon}
                                        <span>{navisList[0].model.title}</span>
                                    </div>
                                    <div className="line" />
                                    <div className={`slide-nav ${prev}`} onClick={(e) => this.changeModel(e.currentTarget.className, navisList[1].model.id)}>
                                        {navisList[1].icon}
                                        <span>{navisList[1].model.title}</span>
                                    </div>
                                    <div className="line" />
                                    <div className="slide-nav center">
                                        {navisList[2].icon}
                                        <span>{navisList[2].active.title}</span>
                                    </div>
                                    <div className="line" />
                                    <div className={`slide-nav ${next}`} onClick={(e) => this.changeModel(e.currentTarget.className, navisList[3].model.id)}>
                                        {navisList[3].icon}
                                        <span>{navisList[3].model.title}</span>
                                    </div>
                                    <div className="line" />
                                    <div className={`slide-nav ${next2}`} onClick={(e) => this.changeModel(e.currentTarget.className, navisList[4].model.id)}>
                                        {navisList[4].icon}
                                        <span>{navisList[4].model.title}</span>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {(navisList.length === 0 || ModelSet.totalItems === 0) && <Loader />}
                    </div>
                    {navisList.length > 0 && ModelSet.totalItems > 0 && <div className="view-toggle">{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "MODELS VIEW" : "MODELL ANSICHT"}<label htmlFor="modelsSizeToggle"><ArrowDown /></label></div>}
                    <ModelIndex />
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        CarouselView,
        ModelSet,
        Models,
        LoadingModels,
        LoadingModelTree,
        ModelTree,
        SelectedPath,
    } = state.model;

    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelState
    } = state.mkp;

    return {
        SelectedLanguage,
        CarouselView,
        ModelSet,
        Models,
        LoadingModels,
        LoadingModelTree,
        ModelTree,
        SelectedPath,
        ModelState
    };
}

const actionCreators = {
    setCarouselView: modelActions.setCarouselView,
    getModelTree: modelActions.getModelTree,
    updateModelSet: modelActions.updateModelSet,
    setSelectedPath: modelActions.setSelectedPath,
    SetModelState: mkpActions.SetModelState
};

const connectedMKP = connect(mapState, actionCreators)(withRouter(ModelCarousel));
export { connectedMKP as ModelCarousel };