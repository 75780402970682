import { themeConstants } from '../_constants';

const initialState = {
    Theme: "lightMode"
}

export function theme(state = initialState, action) {
    switch (action.type) {
        //Update complete modelset
        case themeConstants.UPDATE_THEME:
            return {
                ...state,
                Theme: action.theme
            };
        default:
            return state
    }
}

export default theme