export const feedbackService = {
    sendFeedback
};

function sendFeedback(fields) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },        
        body: JSON.stringify({
            fields: fields
        })
    };

    return fetch("https://api.hsforms.com/submissions/v3/integration/submit/7060604/dc413e92-a986-47bf-a28f-3ff24f0e5517", requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

//response handler
function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        console.log(data);
        if (response.status !== 200) {
            const error = (data) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}