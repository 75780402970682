import { mkpConstants } from '../_constants';
import { mkpService } from '../_services';
//import { history } from '../_helpers';

export const mkpActions = {
    setSelectedIndexContainerId,
    setSelectedIndexTopicId,
    setSelectedIndexBiteId,
    setOpenContext,
    getAllContainers,
    getContainer,
    getContainersByModel,
    getContainerTopics,
    getContainerTopicsIndex,
    getTopic,
    getTopicName,
    getTopicBites,
    getTopicBitesList,
    getTopicBitesIndex,
    getBite,
    getBitePages,
    setPageCompleted,
    getRelatedBites,
    getRelatedTopics,
    SelectModel,
    ModelClose,
    ModelOpen,
    SetModelState,
    getLike,
    addLike,
    delLike,
    getFavorite,
    addFavorite,
    delFavorite
};

function setSelectedIndexContainerId(id) {
    return dispatch => {
        dispatch(success(id));
    }
    function success(id) { return { type: mkpConstants.SET_SELECTED_INDEX_CONTAINER_ID, id } }
}

function setSelectedIndexTopicId(id) {
    return dispatch => {
        dispatch(success(id));
    }
    function success(id) { return { type: mkpConstants.SET_SELECTED_INDEX_TOPIC_ID, id } }
}

function setSelectedIndexBiteId(id) {
    return dispatch => {
        dispatch(success(id));
    }
    function success(id) { return { type: mkpConstants.SET_SELECTED_INDEX_BITE_ID, id } }
}

function setOpenContext(id) {
    return dispatch => {
        dispatch(success(id));
    }
    function success(id) { return { type: mkpConstants.SET_OPEN_CONTEXT, id } }
}

function SelectModel(model) {
    return dispatch => {
        dispatch(success(model));
    }
    function success(model) { return { type: mkpConstants.SELECT_MODEL, model } }
}

function ModelClose() {
    return dispatch => {
        dispatch(success());
    }
    function success() { return { type: mkpConstants.MODEL_CLOSE } }
}

function ModelOpen() {
    return dispatch => {
        dispatch(success());
    }
    function success() { return { type: mkpConstants.MODEL_OPEN } }
}

function SetModelState(model) {
    return dispatch => {
        dispatch(success(model));
    }
    function success(model) { return { type: mkpConstants.SET_MODEL_STATE, model } }
}

function getAllContainers(selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getAllContainers(selectedCulture)
            .then(
                containers => {
                    dispatch(success(containers));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(containers) { return { type: mkpConstants.CONTAINERS_REQUEST, containers } }
    function success(containers) { return { type: mkpConstants.CONTAINERS_SUCCESS, containers } }
    function failure(error) { return { type: mkpConstants.CONTAINERS_FAILURE, error } }
}

function getContainer(id, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getContainer(id, selectedCulture)
            .then(
                container => {
                    dispatch(success(container));       
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(container) { return { type: mkpConstants.CONTAINER_REQUEST, container } }
    function success(container) { return { type: mkpConstants.CONTAINER_SUCCESS, container } }
    function failure(error) { return { type: mkpConstants.CONTAINER_FAILURE, error } }
}

function getContainersByModel(id, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getContainersByModel(id, selectedCulture)
            .then(
                containers => {
                    dispatch(success(containers));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(containers) { return { type: mkpConstants.MODEL_CONTAINERS_REQUEST, containers } }
    function success(containers) { return { type: mkpConstants.MODEL_CONTAINERS_SUCCESS, containers } }
    function failure(error) { return { type: mkpConstants.MODEL_CONTAINERS_FAILURE, error } }
}

function getContainerTopics(id, page, index, selectedCulture) {
    return async dispatch => {
        dispatch(request({}));

        try {
            const containerTopics = await mkpService.getContainerTopics(id, page, index, selectedCulture);
            dispatch(success(containerTopics));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(containerTopics) { return { type: mkpConstants.CONTAINER_TOPICS_REQUEST, containerTopics } }
    function success(containerTopics) { return { type: mkpConstants.CONTAINER_TOPICS_SUCCESS, containerTopics } }
    function failure(error) { return { type: mkpConstants.CONTAINER_TOPICS_FAILURE, error } }
}

function getContainerTopicsIndex(id, page, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getContainerTopicsIndex(id, page, items, selectedCulture)
            .then(
                topics => {
                    dispatch(success(topics));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topics) { return { type: mkpConstants.TOPICS_INDEX_REQUEST, topics } }
    function success(topics) { return { type: mkpConstants.TOPICS_INDEX_SUCCESS, topics } }
    function failure(error) { return { type: mkpConstants.TOPICS_INDEX_FAILURE, error } }
}

function getTopic(id, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getTopic(id, selectedCulture)
            .then(
                topic => {
                    dispatch(success(topic));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topic) { return { type: mkpConstants.TOPIC_REQUEST, topic } }
    function success(topic) { return { type: mkpConstants.TOPIC_SUCCESS, topic } }
    function failure(error) { return { type: mkpConstants.TOPIC_FAILURE, error } }
}

function getTopicName(id, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getTopicName(id, selectedCulture)
            .then(
                topic => {
                    dispatch(success(topic));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topic) { return { type: mkpConstants.TOPIC_NAME_REQUEST, topic } }
    function success(topic) { return { type: mkpConstants.TOPIC_NAME_SUCCESS, topic } }
    function failure(error) { return { type: mkpConstants.TOPIC_NAME_FAILURE, error } }
}

function getTopicBites(id, page, index, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getTopicBites(id, page, index, selectedCulture)
            .then(
                topicBites => {
                    dispatch(success(topicBites));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topicBites) { return { type: mkpConstants.TOPIC_BITES_REQUEST, topicBites } }
    function success(topicBites) { return { type: mkpConstants.TOPIC_BITES_SUCCESS, topicBites } }
    function failure(error) { return { type: mkpConstants.TOPIC_BITES_FAILURE, error } }
}

function getTopicBitesList(id, page, index, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getTopicBites(id, page, index, selectedCulture)
            .then(
                topicBites => {
                    dispatch(success(topicBites));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topicBites) { return { type: mkpConstants.TOPIC_BITES_LIST_REQUEST, topicBites } }
    function success(topicBites) { return { type: mkpConstants.TOPIC_BITES_LIST_SUCCESS, topicBites } }
    function failure(error) { return { type: mkpConstants.TOPIC_BITES_LIST_FAILURE, error } }
}

function getTopicBitesIndex(id, page, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getTopicBitesIndex(id, page, items, selectedCulture)
            .then(
                bites => {
                    dispatch(success(bites));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(bites) { return { type: mkpConstants.TOPIC_BITES_INDEX_REQUEST, bites } }
    function success(bites) { return { type: mkpConstants.TOPIC_BITES_INDEX_SUCCESS, bites } }
    function failure(error) { return { type: mkpConstants.TOPIC_BITES_INDEX_FAILURE, error } }
}

function getBite(id, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getBite(id, selectedCulture)
            .then(
                bite => {
                    dispatch(success(bite));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(bite) { return { type: mkpConstants.BITE_REQUEST, bite } }
    function success(bite) { return { type: mkpConstants.BITE_SUCCESS, bite } }
    function failure(error) { return { type: mkpConstants.BITE_FAILURE, error } }
}

function getBitePages(id, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getBitePages(id, items, selectedCulture)
            .then(
                pages => {
                    dispatch(success(pages));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(pages) { return { type: mkpConstants.BITE_PAGES_REQUEST, pages } }
    function success(pages) { return { type: mkpConstants.BITE_PAGES_SUCCESS, pages } }
    function failure(error) { return { type: mkpConstants.BITE_PAGES_FAILURE, error } }
}

function setPageCompleted(id) {
    return dispatch => {
        dispatch(request({}));

        mkpService.setPageCompleted(id)
            .then(
                data => {
                    dispatch(success(data));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(data) { return { type: mkpConstants.BITE_PAGE_COMPLETED_REQUEST, data } }
    function success(data) { return { type: mkpConstants.BITE_PAGE_COMPLETED_SUCCESS, data } }
    function failure(error) { return { type: mkpConstants.BITE_PAGE_COMPLETED_FAILURE, error } }
}

function getRelatedBites(id, page, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getRelatedBites(id, page, items, selectedCulture)
            .then(
                topicBites => {
                    dispatch(success(topicBites));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topicBites) { return { type: mkpConstants.GET_RELATED_BITES_REQUEST, topicBites } }
    function success(topicBites) { return { type: mkpConstants.GET_RELATED_BITES_SUCCESS, topicBites } }
    function failure(error) { return { type: mkpConstants.GET_RELATED_BITES_FAILURE, error } }
}

function getRelatedTopics(id, page, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getRelatedTopics(id, page, items, selectedCulture)
            .then(
                containerTopics => {
                    dispatch(success(containerTopics));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(containerTopics) { return { type: mkpConstants.GET_RELATED_TOPICS_REQUEST, containerTopics } }
    function success(containerTopics) { return { type: mkpConstants.GET_RELATED_TOPICS_SUCCESS, containerTopics } }
    function failure(error) { return { type: mkpConstants.GET_RELATED_TOPICS_FAILURE, error } }
}

//likes
function getLike(id, page, items) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getLike(id, page, items)
            .then(
                data => {
                    dispatch(success(data));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(data) { return { type: mkpConstants.LIKE_REQUEST, data } }
    function success(data) { return { type: mkpConstants.LIKE_SUCCESS, data } }
    function failure(error) { return { type: mkpConstants.LIKE_FAILURE, error } }
}

function addLike(userId, resourceType, resourceId) {
    return dispatch => {
        dispatch(request({}));

        mkpService.addLike(userId, resourceType, resourceId)
            .then(
                like => {
                    dispatch(success(like));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(like) { return { type: mkpConstants.LIKE_ADD_REQUEST, like } }
    function success(like) { return { type: mkpConstants.LIKE_ADD_SUCCESS, like } }
    function failure(error) { return { type: mkpConstants.LIKE_ADD_FAILURE, error } }
}

function delLike(id) {
    return dispatch => {
        dispatch(request({}));

        mkpService.delLike(id)
            .then(
                data => {
                    dispatch(success(data));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(data) { return { type: mkpConstants.LIKE_DEL_REQUEST, data } }
    function success(data) { return { type: mkpConstants.LIKE_DEL_SUCCESS, data } }
    function failure(error) { return { type: mkpConstants.LIKE_DEL_FAILURE, error } }
}

//favorites
function getFavorite(id, page, items, selectedCulture) {
    return dispatch => {
        dispatch(request({}));

        mkpService.getFavorite(id, page, items, selectedCulture)
            .then(
                data => {
                    dispatch(success(data));             
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(data) { return { type: mkpConstants.FAVORITE_REQUEST, data } }
    function success(data) { return { type: mkpConstants.FAVORITE_SUCCESS, data } }
    function failure(error) { return { type: mkpConstants.FAVORITE_FAILURE, error } }
}

function addFavorite(userId, resourceType, resourceId) {
    return dispatch => {
        dispatch(request({}));

        mkpService.addFavorite(userId, resourceType, resourceId)
            .then(
                favorite => {
                    dispatch(success(favorite));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(favorite) { return { type: mkpConstants.FAVORITE_ADD_REQUEST, favorite } }
    function success(favorite) { return { type: mkpConstants.FAVORITE_ADD_SUCCESS, favorite } }
    function failure(error) { return { type: mkpConstants.FAVORITE_ADD_FAILURE, error } }
}

function delFavorite(id) {
    return dispatch => {
        dispatch(request({}));

        mkpService.delFavorite(id)
            .then(
                data => {
                    dispatch(success(data));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(data) { return { type: mkpConstants.FAVORITE_DEL_REQUEST, data } }
    function success(data) { return { type: mkpConstants.FAVORITE_DEL_SUCCESS, data } }
    function failure(error) { return { type: mkpConstants.FAVORITE_DEL_FAILURE, error } }
}