import { authenticationConstants } from '../_constants';
import { authenticationService } from '../_services';

export const authenticationActions = {
    logout,
    getProfile
};

function logout() {
    authenticationService.logout();
    return { type: authenticationConstants.LOGOUT };
}

function getProfile() {
    return dispatch => {
        authenticationService.getProfile().then(
            user => {
                dispatch(success(user));           
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    function success(user) { return { type: authenticationConstants.PROFILE_SUCCESS, user } }
    function failure(user) { return { type: authenticationConstants.PROFILE_FAILURE, user } }
}