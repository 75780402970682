import { highlightsConstants } from '../_constants';
import { highlightsService } from '../_services';
//import { history } from '../_helpers';

export const highlightsActions = {
    getHighlights
};

function getHighlights(selectedCulture) {

    return dispatch => {
        dispatch(request({}));

        highlightsService.getHighlights(selectedCulture)
            .then(
                highlights => {
                    dispatch(success(highlights));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(highlights) { return { type: highlightsConstants.HIGHLIGHTS_REQUEST, highlights } }
    function success(highlights) { return { type: highlightsConstants.HIGHLIGHTS_SUCCESS, highlights } }
    function failure(error) { return { type: highlightsConstants.HIGHLIGHTS_FAILURE, error } }
}