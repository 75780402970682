import { authHeader } from '../_helpers';

const api = process.env.REACT_APP_URL_API;

export const highlightsService = {
    getHighlights
};


async function getHighlights(selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/highlights?&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(highlights => {
            return highlights;
        });
}

function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if ((response.success !== undefined && !response.success) || (response.ok !== undefined && !response.ok)) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}