import { authHeader } from '../_helpers';

const api = process.env.REACT_APP_URL_API;

export const mkpService = {
    getAllContainers,
    getContainer,
    getContainersByModel,
    getContainerTopics,
    getContainerTopicsIndex,
    getTopic,
    getTopicName,
    getTopicBites,
    getTopicBitesIndex,
    getBite,
    getBitePages,
    setPageCompleted,
    getRelatedBites,
    getRelatedTopics,
    getLike,
    addLike,
    delLike,
    getFavorite,
    addFavorite,
    delFavorite
};

async function getAllContainers(selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/containers?culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(containers => {
            return containers;
        });
}

async function getContainer(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/containers?Id=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(container => {
            return container;
        });
}

async function getContainersByModel(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/containers?ModelId=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(containers => {
            return containers;
        });
}

async function getContainerTopics(id, page, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/topics?ContainerId=` + id + '&Page=' + page + '&ItemsPerPage=' + items + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(containerTopics => {
            return containerTopics;
        });
}

async function getContainerTopicsIndex(id, page, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/topics?ItemsPerPage=' + items + '&containerId=' + id + '&page=' + page + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(topics => {
            return topics;
        });
}

async function getTopic(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/topics?Id=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(topic => {
            return topic;
        });
}

async function getTopicBites(id, page, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/bites?TopicId=` + id + '&Page=' + page + '&ItemsPerPage=' + items + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(topicBites => {
            return topicBites;
        });
}

async function getTopicBitesIndex(id, page, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/bites?ItemsPerPage=' + items + '&TopicId=' + id + '&page=' + page + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(bites => {
            return bites;
        });
}

async function getTopicName(id) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/topics?Id=` + id, requestOptions)
        .then(handleResponse)
        .then(topic => {
            return topic;
        });
}

async function getBite(id, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + `/bites?Id=` + id + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(bite => {
            return bite;
        });
}

async function getBitePages(id, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/pages?biteId=' + id + '&ItemsPerPage=' + items + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(pages => {
            return pages;
        });
}

async function setPageCompleted(id) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/pages/' + id + '/mark-as-completed', requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getRelatedBites(id, page, items) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/bites/related?BiteId=' + id + '&Page=' + page + '&ItemsPerPage=' + items, requestOptions)
        .then(handleResponse)
        .then(topicBites => {
            return topicBites;
        });
}


async function getRelatedTopics(id, page, items) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader()
    };

    return fetch(api + '/topics/related?TopicId=' + id + '&Page=' + page + '&ItemsPerPage=' + items, requestOptions)
        .then(handleResponse)
        .then(containerTopics => {
            return containerTopics;
        });
}

//likes
async function getLike(id, page, items) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };

    return fetch(api + '/likes?UserId=' + id + '&Page=' + page + '&ItemsPerPage=' + items, requestOptions)
        .then(handleResponse)
        .then(like => {
            return like;
        });
}

async function addLike(userId, resourceType, resourceId) {
    const requestOptions = {
        method: 'POST',
        headers: await authHeader(true),
        body: JSON.stringify({
            userId: userId,
            resourceType: resourceType,
            resourceId: resourceId
        })
    };

    return fetch(api + `/likes`, requestOptions)
        .then(handleResponse)
        .then(like => {
            return like;
        });
}

async function delLike(id) {
    const requestOptions = {
        method: 'delete',
        headers: await authHeader()
    };

    return fetch(api + `/likes/` + id, requestOptions)
        .then(handleResponse)
        .then(like => {
            return like;
        });
}

//favorites
async function getFavorite(id, page, items, selectedLanguage) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };

    return fetch(api + '/favorites?UserId=' + id + '&Page=' + page + '&ItemsPerPage=' + items + `&culture=${selectedLanguage}`, requestOptions)
        .then(handleResponse)
        .then(like => {
            return like;
        });
}
//'Content-Type': 'application/json'
async function addFavorite(userId, resourceType, resourceId) {
    const requestOptions = {
        method: 'POST',
        headers: await authHeader(true),
        body: JSON.stringify({
            userId: userId,
            resourceType: resourceType,
            resourceId: resourceId
        })
    };

    return fetch(api + `/favorites`, requestOptions)
        .then(handleResponse)
        .then(favorite => {
            return favorite;
        });
}

async function delFavorite(id) {
    const requestOptions = {
        method: 'delete',
        headers: await authHeader()
    };

    return fetch(api + `/favorites/` + id, requestOptions)
        .then(handleResponse)
        .then(like => {
            return like;
        });
}

//response handler
async function handleResponse(response) {
    const text = await response.text();
    const data = text && JSON.parse(text);
    if ((response.success !== undefined && !response.success) || (response.ok !== undefined && !response.ok)) {
        throw (data && data.message) || response.statusText;
    }

    return data;
}