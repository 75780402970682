import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { feedbackActions } from './../../../store/_actions';
//components
import { Loader } from './../../shared/Loader/Loader';
//icons
import { ReactComponent as IconFeedback } from '../../../Assets/images/icons/icon_feedback.svg';
import { ReactComponent as IconClose } from '../../../Assets/images/icons/icon_close_1.svg';
import { ReactComponent as IconRating } from '../../../Assets/images/icons/icon_rating.svg';
import { ReactComponent as IconArrpw } from '../../../Assets/images/icons/icon_arrow_right_1.svg';
//styles
import "./Feedback.scss";

class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feedbackIsOpen: false
        };

        this.toggleFeedbackForm = this.toggleFeedbackForm.bind(this);
        this.setFeedbackoverallexperience = this.setFeedbackoverallexperience.bind(this);
        this.setFeedbacksuggestiontype = this.setFeedbacksuggestiontype.bind(this);
        this.setFeedbacknextmodule = this.setFeedbacknextmodule.bind(this);
        this.setFeedbacknextmoduleText = this.setFeedbacknextmoduleText.bind(this);
        this.OpenModule = this.OpenModule.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.SendFeedback = this.SendFeedback.bind(this);
    }

    componentDidMount() {
        this.setInitialState();
    }

    //open/close feedback window
    toggleFeedbackForm() {
        if (this.state.feedbackIsOpen) {
            this.setInitialState();
        } else {
            this.setState({ feedbackIsOpen: true });
        }
    }

    //this.setInitialState
    setInitialState() {
        this.setState({           
            feedbackIsOpen: false,
            
            feedbackoverallexperience: 0,
            feedbackoveralldescription: "",
            feedbacksuggestiontype: "",
            feedbacksuggestiontext: "",
            feedbacknextmodule: "",
            Modules: [
                {
                    Title: "Managerial Effectiveness Audit (MEA)",
                    Text: "<p>Based on our MKP’s “Management für Personen”-Modell”:</p><p>Comprehensively evaluating the managerial effectiveness of your executives with respect to all relevant tasks and tools. Comparing self and external perceptions determines open and hidden strengths and weaknesses and derives tailor-made management development and learning feeds within holos.</p>",
                    Textarea: false,
                    Open: false
                },
                {
                    Title: "Management System Audit (MSA)",
                    Text: "<p>Based on our MKP’s Integrated Management System („Management für Organisationen”):</p><p>Checking the managerial viability of your organisation by assessing its management processes and systems. Involve those and as many people as you want to do so. Holos does the methodological work and shows you the results, in any form.</p>",
                    Textarea: false,
                    Open: false
                },
                {
                    Title: "Management Feedback System (MFS)",
                    Text: "<p>Leveraging the collective intelligence of your organization by crowdsolving: Involve your staff in any opinion-forming and solution-finding processes for the challenges ahead, or, simply ask them for their opinion on any topic. AI does the evaluation, holos the visualisation.</p>",
                    Textarea: false,
                    Open: false
                },
                {
                    Title: "Mastermind (MM)",
                    Text: "<p>Multimedia platform for small closed holos community groups facing similar challenges in similar situations. Share thoughts, experiences, lessons learned and more and get your peer’s insights.</p>",
                    Textarea: false,
                    Open: false
                },
                {
                    Title: "Any other module?",
                    Text: "What would that be?",
                    Textarea: true,
                    TextareaText: "",
                    Open: false
                }
            ],

            submitted: false,
            showMissingFieldstext: false,
            sending: false,
            showSendError: false
        });
    }

    //set Feedback experience
    setFeedbackoverallexperience(rated) {
        this.setState({ feedbackoverallexperience: rated });
    }

    //set suggestion type
    setFeedbacksuggestiontype(index) {
        if (index !== "None") {
            this.setState({ feedbacksuggestiontype: index });
        } else {
            this.setState({ feedbacksuggestiontype: index, feedbacksuggestiontext: "" });
        }
    }

    //set proposed next module
    setFeedbacknextmodule(index) {
        this.setState({ feedbacknextmodule: index });
    }

    //set proposed next module suggestion
    setFeedbacknextmoduleText(e) {
        const { value } = e.target;
        let mod = this.state.Modules;
        mod[this.state.feedbacknextmodule].TextareaText = value;

        this.setState({ Modules: mod });
    }

    //open proposed next module description
    OpenModule(index) {
        let mod = this.state.Modules;
        mod[index].Open = !mod[index].Open;

        this.setState({ Modules: mod });
    }

    //handle input changes
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    //send feedback form
    SendFeedback() {
        this.setState({ submitted: true }, function () {
            if (document.getElementsByClassName("invalid").length === 0) {
                this.setState({ showMissingFieldstext: false });
                let user = JSON.parse(localStorage.getItem('user'));
                let answer = [];
                answer.push({ "name": "email", "value": user.profile.email });
                answer.push({ "name": "feedbackoverallexperience", "value": this.state.feedbackoverallexperience });
                answer.push({ "name": "feedbackoveralldescription", "value": this.state.feedbackoveralldescription });
                answer.push({ "name": "feedbacksuggestiontype", "value": this.state.feedbacksuggestiontype });
                if (this.state.feedbacksuggestiontype !== "None") {
                    answer.push({ "name": "feedbacksuggestiontext", "value": this.state.feedbacksuggestiontext });
                }
                answer.push({ "name": "feedbacknextmodule", "value": this.state.Modules[this.state.feedbacknextmodule].Title });
                if (this.state.Modules[this.state.feedbacknextmodule].Textarea) {
                    answer.push({ "name": "feedbacknextmoduleothertext", "value": this.state.Modules[this.state.feedbacknextmodule].TextareaText });
                }

                this.setState({ sending: true });
                this.props.sendFeedback(answer)
            } else {
                this.setState({ showMissingFieldstext: true });
            }
        });
    }

    //check for feedback request response and handle errors
    componentDidUpdate(prevProps) {
        if (prevProps.FeedbackLoading && !this.props.FeedbackLoading) {
            if (this.props.FeedbackError) {
                this.setState({ showSendError: true, sending: false });
            } else {
                this.toggleFeedbackForm();
                this.setState({ sending: false });
            }
        }
    }

    render() {
        const {
            feedbackoverallexperience,
            feedbackoveralldescription,
            feedbacksuggestiontype,
            feedbacksuggestiontext,
            feedbacknextmodule,
            submitted,
            showMissingFieldstext,
            sending,
            showSendError
        } = this.state;

        return (
            <div className={"feedback-container" + (this.state.feedbackIsOpen ? ' active' : '')}>
                <div className="sticky-feedback" onClick={() => this.toggleFeedbackForm()}><span>Feedback</span><IconFeedback /></div>
                <div className="feedback-form">
                    <div className="feedback-header">
                        <IconClose onClick={() => this.toggleFeedbackForm()} />
                        <IconFeedback />
                        <div>Your Feedback is important to us!</div>
                    </div>
                    <div className="feedback-body">
                        <div className="feedback-question">
                            <div className="f-question-label">How was your overall experience?</div>
                            <div className={"f-question-rating" + (feedbackoverallexperience === 0 && submitted ? ' invalid' : '')}>
                                <div className={"f-rate" + (feedbackoverallexperience > 0 ? ' selected' : '')} onClick={() => this.setFeedbackoverallexperience(1)}>
                                    <IconRating />
                                </div>
                                <div className={"f-rate" + (feedbackoverallexperience > 1 ? ' selected' : '')} onClick={() => this.setFeedbackoverallexperience(2)}>
                                    <IconRating />
                                </div>
                                <div className={"f-rate" + (feedbackoverallexperience > 2 ? ' selected' : '')} onClick={() => this.setFeedbackoverallexperience(3)}>
                                    <IconRating />
                                </div>
                                <div className={"f-rate" + (feedbackoverallexperience > 3 ? ' selected' : '')} onClick={() => this.setFeedbackoverallexperience(4)}>
                                    <IconRating />
                                </div>
                                <div className={"f-rate" + (feedbackoverallexperience > 4 ? ' selected' : '')} onClick={() => this.setFeedbackoverallexperience(5)}>
                                    <IconRating />
                                </div>
                            </div >
                            <div className={"f-question-textfield" + (feedbackoveralldescription === "" && submitted ? ' invalid' : '')}>
                                <textarea
                                    placeholder="Please briefly describe what led you to the above valuation:"
                                    name="feedbackoveralldescription"
                                    value={feedbackoveralldescription}
                                    onChange={this.handleChange}></textarea>
                            </div>
                        </div >
                        <div className="feedback-question">
                            <div className="f-question-label">Do you have any suggestions you can provide us with on our - hopefully joint - journey?</div>
                            <div className="f-radio-buttons">
                                <div className={"f-radio-button" + (feedbacksuggestiontype === "Bug" ? ' active' : '')} >
                                    <span
                                        className={(feedbacksuggestiontype === "" && submitted ? 'invalid' : '')}
                                        onClick={() => this.setFeedbacksuggestiontype("Bug")}></span>Bug
                                </div>
                                <div className={"f-radio-button" + (feedbacksuggestiontype === "Suggestion" ? ' active' : '')} >
                                    <span
                                        className={(feedbacksuggestiontype === "" && submitted ? 'invalid' : '')}
                                        onClick={() => this.setFeedbacksuggestiontype("Suggestion")}></span>Suggestion
                                </div>
                                <div className={"f-radio-button" + (feedbacksuggestiontype === "Others" ? ' active' : '')}>
                                    <span
                                        className={(feedbacksuggestiontype === "" && submitted ? 'invalid' : '')}
                                        onClick={() => this.setFeedbacksuggestiontype("Others")}></span>Others
                                </div>
                                <div className={"f-radio-button" + (feedbacksuggestiontype === "None" ? ' active' : '')} >
                                    <span
                                        className={(feedbacksuggestiontype === "" && submitted ? 'invalid' : '')}
                                        onClick={() => this.setFeedbacksuggestiontype("None")}></span>None
                                </div>
                            </div>
                            <div className={"f-question-textfield" + (feedbacksuggestiontype > 2 ? ' hidden' : '') + (feedbacksuggestiontype !== "None" && feedbacksuggestiontext === "" && submitted ? ' invalid' : '')}>
                                {feedbacksuggestiontype === "Bug" && <textarea placeholder="What is it?" name="feedbacksuggestiontext" value={feedbacksuggestiontext} onChange={this.handleChange}></textarea>}
                                {feedbacksuggestiontype === "Suggestion" && <textarea placeholder="What would you suggest?" name="feedbacksuggestiontext" value={feedbacksuggestiontext} onChange={this.handleChange}></textarea>}
                                {feedbacksuggestiontype === "Others" && <textarea placeholder="Write here..." name="feedbacksuggestiontext" value={feedbacksuggestiontext} onChange={this.handleChange}></textarea>}
                            </div>
                        </div>
                        <div className="feedback-question">
                            <div className="f-question-label">You just experienced our Management Knowledge Platform (MKP). What would you suggest we shall tackle and develop as our next module in holos?</div>
                            {this.state.Modules && this.state.Modules.map((Module, index) => {
                                return <div className="f-question-answer" key={index}>
                                    <div className={"f-radio-buttons" + (Module.Open ? ' active' : '') + (Module.Textarea ? ' isTextarea' : '')}>
                                        <div className={"f-radio-button" + (feedbacknextmodule === index ? ' active' : '')} >
                                            <span
                                                className={(feedbacknextmodule === "" && submitted ? 'invalid' : '')}
                                                onClick={() => this.setFeedbacknextmodule(index)}></span>
                                            {Module.Title}
                                            {!Module.Textarea && <IconArrpw onClick={() => this.OpenModule(index)} />}</div>
                                        {!Module.Textarea && <div className="f-qa-answer" dangerouslySetInnerHTML={{ __html: Module.Text }}></div>}
                                        {Module.Textarea && feedbacknextmodule === index && <div className={"f-qa-answer f-question-textfield" + (feedbacknextmodule === index && this.state.Modules[index].TextareaText === "" && submitted ? ' invalid' : '')}>
                                            <textarea placeholder={Module.Text} onChange={this.setFeedbacknextmoduleText}></textarea>
                                        </div>}
                                    </div>
                                </div>
                            })}
                        </div>
                        {showSendError && <p>{this.props.FeedbackMessage}</p>}
                        {showMissingFieldstext && <p>Please answer all questions.</p>}
                        <div className="button" onClick={() => this.SendFeedback()}>SEND FEEDBACK</div>
                    </div>
                </div>
                {sending && <Loader Text="SENDING" />}
            </div>
        )
    }
}

function mapState(state) {
    const { FeedbackStatus, FeedbackMessage, FeedbackLoading, FeedbackError } = state.feedback;
    return { FeedbackStatus, FeedbackMessage, FeedbackLoading, FeedbackError };
}

const actionCreators = {
    sendFeedback: feedbackActions.sendFeedback
};

const connectedFeedback = connect(mapState, actionCreators)(Feedback);
export { connectedFeedback as Feedback };