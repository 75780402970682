import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { modelActions, mkpActions } from './../../../store/_actions';
//components
import { Actions } from './../Actions/Actions';
import { TileEmbed } from './../TileEmbed/TileEmbed';
//icons
import { ReactComponent as ArrowDown } from './../../../Assets/images/icons/icon_arrow_down_1.svg';
import { ReactComponent as IconVideo } from './../../../Assets/images/icons/icon_video.svg';
//styles
import "./Tile.scss";

class Tile extends Component {

    constructor(props) {
        super(props);
        this.imageUrl = process.env.REACT_APP_PAGE_URL_IMAGE;

        this.state = {
            Tile: this.props.Tile,
            loading: false,
            ActiveEmbed: false,
        };

        this.openEmbedment = this.openEmbedment.bind(this);
        this.closeEmbedment = this.closeEmbedment.bind(this);
    }


    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {
        const { Tile } = this.state;

        if (Tile.type === "Bite") {

            //check if topic exists on modelSet
            var bitePathFound = this.findBitePath(Tile.topicId);

            //check missing info for tile
            if (bitePathFound) {
                this.setState({
                    Tile: {
                        ...Tile,
                        Model: {
                            id: bitePathFound.Model.id,
                            title: bitePathFound.Model.title
                        },
                        Container: {
                            id: bitePathFound.Container.id,
                            title: bitePathFound.Container.title
                        },
                        Topic: {
                            id: bitePathFound.Topic.id,
                            title: bitePathFound.Topic.title,
                            containerId: bitePathFound.Topic.containerId
                        }
                    }
                })
            } else {
                //load topic                
                this.props.getTopic(Tile.topicId, this.props.SelectedLanguage);
            }
        } else if (Tile.type === "Topic") {
            //check if topic exists on modelSet
            var topicPathFound = this.findTopicPath(Tile.containerId);

            //check missing info for tile
            if (topicPathFound) {
                this.setState({
                    Tile: {
                        ...Tile,
                        Model: {
                            id: topicPathFound.Model.id,
                            title: topicPathFound.Model.title
                        },
                        Container: {
                            id: topicPathFound.Container.id,
                            title: topicPathFound.Container.title
                        }
                    }
                })
            } else {
                //load topic                
                this.props.getContainer(Tile.containerId, this.props.SelectedLanguage);
            }
        }

    }

    componentDidUpdate(prevProps) {
        //check if topic was loaded and set it for rendering
        if (prevProps.LoadedTopic !== this.props.LoadedTopic && this.props.LoadedTopic.id === this.state.Tile.topicId && !this.state.Tile.Topic) {
            this.setState({
                Tile: {
                    ...this.state.Tile,
                    Topic: {
                        id: this.props.LoadedTopic.id,
                        title: this.props.LoadedTopic.title,
                        containerId: this.props.LoadedTopic.containerId
                    }
                }
            })
        }

        //check if container was loaded and set it for rendering
        if (prevProps.LoadedContainer !== this.props.LoadedContainer && this.props.LoadedContainer.id === this.state.Tile.containerId && !this.state.Tile.Container) {
            if (this.props.ModelSet.items.filter(m => m.id === this.props.LoadedContainer.modelId).length > 0) {
                this.setState({
                    Tile: {
                        ...this.state.Tile,
                        Model: {
                            id: this.props.LoadedContainer.modelId,
                            title: this.props.ModelSet.items.filter(m => m.id === this.props.LoadedContainer.modelId)[0].title
                        },
                        Container: {
                            id: this.props.LoadedContainer.id,
                            title: this.props.LoadedContainer.title
                        }
                    }
                })
            }
        }

        //embedment updated
        if (prevProps.OpenContext.Id === -1 && this.props.OpenContext.Id !== -1) {
            const container = document.getElementsByClassName('content-main')[0].childNodes[0];
            container.classList.add("grow");
        } else if (prevProps.OpenContext.Id !== -1 && this.props.OpenContext.Id === -1) {
            const container = document.getElementsByClassName('content-main')[0].childNodes[0];
            container.classList.remove("grow");
        }

    }

    /************************************
    * GET TILE PATH
    ***********************************/

    findBitePath(id) {
        var topicFound = null;
        var foundPath = {};

        for (var m = 0; m < this.props.ModelSet.items.length && !topicFound; m++) {
            foundPath.Model = {
                id: this.props.ModelSet.items[m].id,
                title: this.props.ModelSet.items[m].title,
            };

            if (this.props.ModelSet.items[m].containers) {
                for (var c = 0; c < this.props.ModelSet.items[m].containers.length && !topicFound; c++) {
                    foundPath.Container = {
                        id: this.props.ModelSet.items[m].containers[c].id,
                        title: this.props.ModelSet.items[m].containers[c].title,
                    };

                    if (this.props.ModelSet.items[m].containers[c].topics.filter(c => c.id === id).length > 0) {
                        topicFound = true;
                        foundPath.Topic = {
                            id: this.props.ModelSet.items[m].containers[c].topics.filter(c => c.id === id)[0].id,
                            title: this.props.ModelSet.items[m].containers[c].topics.filter(c => c.id === id)[0].title,
                            containerId: this.props.ModelSet.items[m].containers[c].topics.filter(c => c.id === id)[0].containerId
                        };
                    }
                }
            }
        }

        if (topicFound) {
            return foundPath;
        } else {
            return null;
        }
    }

    findTopicPath(id) {
        var containerFound = null;
        var foundPath = {};

        for (var m = 0; m < this.props.ModelSet.items.length && !containerFound; m++) {
            foundPath.Model = {
                id: this.props.ModelSet.items[m].id,
                title: this.props.ModelSet.items[m].title,
            };

            if (this.props.ModelSet.items[m].containers) {
                if (this.props.ModelSet.items[m].containers.filter(c => c.id === id).length > 0) {
                    containerFound = true;
                    foundPath.Container = {
                        id: this.props.ModelSet.items[m].containers.filter(c => c.id === id)[0].id,
                        title: this.props.ModelSet.items[m].containers.filter(c => c.id === id)[0].title,
                    };
                }
            }
        }

        if (containerFound) {
            return foundPath;
        } else {
            return null;
        }
    }


    /************************************
    * OPEN/CLOSE CONTEXT/EMBEDMENT
    ***********************************/

    openEmbedment(type, i, e) {
        e.preventDefault();

        if (this.props.OpenContext.Id === i && this.state.ActiveEmbed) {
            this.setState({ ActiveEmbed: false }, function () {
                this.props.setOpenContext({ Type: "", Id: -1 });

            });
        } else {
            this.setState({ ActiveEmbed: true }, function () {
                this.props.setOpenContext({ Type: type, Id: i });
            });
        }

    }

    closeEmbedment(i, e, ignore) {
        this.setState({ ActiveEmbed: false }, function () {
            if (!ignore) {
                this.props.setOpenContext({ Type: "", Id: -1 });
            }
        });
    }


    render() {
        const { Tile, ActiveEmbed } = this.state
        const fullUrl = `${process.env.REACT_APP_PAGE_URL_IMAGE}${Tile.modelIcon}`
        const { isHighlight, FromSearch, OpenContext } = this.props

        return (
            <div className={"tile" + (this.props.hidden ? ' hidden' : '') + ((OpenContext.Id === Tile.id && OpenContext.Type === Tile.type && ActiveEmbed) ? ' fixed' : '')}>
                {Tile && <div className="tile-inner" id={"tile" + Tile.id}>
                    <Link to={((OpenContext.Id === Tile.id && OpenContext.Type === Tile.type && ActiveEmbed) || (Tile.type === 'Bite' && Tile.inProduction) ) ? '#' : { pathname: '/' + (Tile.type.toLowerCase() === "topic" ? Tile.type.toLowerCase() : 'feed') + '/' + Tile.id }}
                          onClick={(e) => {
                              if(Tile.type === 'Bite' && Tile.inProduction) {
                                  e.stopPropagation()
                              }
                          }}
                          className={"content is" + Tile.type}>
                        {!FromSearch && Tile.inProduction && <div className="label fresh">COMING SOON</div>}
                        {!FromSearch && Tile.isSpecial && <div className="label hot">SPECIAL</div>}
                        {!FromSearch && Tile.isNew && <div className="label hot">NEW</div>}
                        {!FromSearch && <Actions Id={Tile.id} Type={Tile.type} accessToken={this.state.accessToken} />}
                        <div className="tile-content">
                            <div className="t-content-top">
                                <div className="t-content-left">
                                    <div className="t-image">
                                        <img src={fullUrl} alt="" />
                                    </div>
                                    <div className="t-category">{Tile.type === "Bite" ? "FEED" : "TOPIC"}</div>
                                </div>
                                <div className="t-content-right">
                                    {!isHighlight && Tile.type === "Topic" && Tile.Container && <div className="t-container" dangerouslySetInnerHTML={{ __html: Tile.Container.title }}></div>}
                                    {Tile.type === "Topic" && <div className="t-topic" dangerouslySetInnerHTML={{ __html: Tile.title }}></div>}
                                    {!isHighlight && Tile.type === "Bite" && Tile.Topic && <div className="t-topic" dangerouslySetInnerHTML={{ __html: Tile.Topic.title }}></div>}
                                    {Tile.type === "Bite" && <div className="t-bite"><div dangerouslySetInnerHTML={{ __html: Tile.title }}></div></div>}
                                    {isHighlight && <div className="t-description" dangerouslySetInnerHTML={{ __html: Tile.subtitle }}></div>}
                                </div>
                            </div>
                            {!FromSearch && <div className="t-content-bottom">
                                <div className="t-embed" onClick={(e) => this.openEmbedment(Tile.type, Tile.id, e)}><ArrowDown />CONTEXT</div>
                                {Tile.video && <div className="t-media"><IconVideo /></div>}
                            </div>}
                        </div>
                    </Link>
                    {!FromSearch && <div className="progress">
                        <div className={"bar p-" + Tile.progressPercentage}></div>
                    </div>}
                    {(OpenContext.Id === Tile.id && OpenContext.Type === Tile.type && ActiveEmbed) && <TileEmbed Tile={Tile} handleClose={this.closeEmbedment} />}
                </div>}

            </div>
        );
    }
}

function mapState(state) {
    const {
        ModelSet,
        LoadedTopic,
        LoadedContainer
    } = state.model;

    const {
        SelectedLanguage
    } = state.layout;

    const {
        OpenContext
    } = state.mkp;

    return {
        SelectedLanguage,
        ModelSet,
        LoadedTopic,
        LoadedContainer,
        OpenContext
    };
}

const actionCreators = {
    getTopic: modelActions.getTopic,
    getContainer: modelActions.getContainer,
    setOpenContext: mkpActions.setOpenContext
};

const connectedTile = connect(mapState, actionCreators)(Tile);
export { connectedTile as Tile };