import { combineReducers } from 'redux'
import authentication from './authentication.reducer';
import layout from './layout.reducer';
import theme from './theme.reducer';
import model from './model.reducer';
import highlights from './highlights.reducer';
import mkp from './mkp.reducer';
import feedback from './feedback.reducer';
import search from './search.reducer';

export default combineReducers({
    authentication,
    layout,
    theme,
    model,
    highlights,
    mkp,
    feedback,
    search
})