import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mkpActions } from '../../../store/_actions';
//components
import { Tile } from './../Tile/Tile';
import { TileShadow } from './../Tile/TileShadow';
import { Loader } from '../Loader/Loader';
//svg's
import { ReactComponent as ArrowRight } from './../../../Assets/images/icons/icon_arrow_right_1.svg';
import { ReactComponent as ArrowLeft } from './../../../Assets/images/icons/icon_arrow_left_1.svg';
import { ReactComponent as ModeIcon } from './../../../Assets/images/icons/ico_direction_v1.svg';
//styles
import "./Tiles.scss";
import {getUserId} from "../../../store/_services";

class TilesLikes extends Component {
    constructor(props) {
        super(props);
        
        this.ItemsPerPage = 25;
        this.ItemsPerSlidePage = 4;
        this.blockSwipe = false;

        this.state = {
            Loading: false,
            Title: props.Title,
            Sliding: false,
            Tiles: [],
            Page: 1,
            FetchingMore: false,
            FetchNoMore: false,
            SlidePageCount: 1,
            SingleSlide: 0,
            SingleClass: " ",
            TotalItems: 0,
            Pagination: [],
            ViewMode: false
        };


        this.slide = this.slide.bind(this);
        this.switchView = this.switchView.bind(this);
    }


    componentDidMount() {
        this.props.getLikes(getUserId(), this.state.Page, this.ItemsPerPage);

        //wheel scroll event
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("wheelLikes").addEventListener("wheel", tHandler);
    }

    callback(event) {
        var delta = event.deltaX || event.wheelDeltaX;
        
        //if its horizontal then:
        if (Math.abs(delta) > 0) {
            event.preventDefault();
            event.stopPropagation();

            //check if swipe allowed
            if ((this.refs.rightArrow.classList.contains("active") && delta > 0) || (this.refs.leftArrow.classList.contains("active") && delta < 0)) {

                if (!this.blockSwipe) {
                    this.blockSwipe = true;

                    if (delta > 0) {
                        this.slide(1);
                    }
                    else if (delta < 0) {
                        this.slide(0);
                    }
                }
            }
        }
    }


    throttled(delay, fn) {
        let lastCall = 0;
        return function (...args) {
            const now = (new Date()).getTime();
            if (now - lastCall < delay) {
                return false;
            }
            lastCall = now;
            return fn(...args);
        }
    }

    componentWillUnmount() {
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("wheelLikes").removeEventListener("wheel", tHandler);
    }

    componentDidUpdate(prevProps) {
        //if likes changed
        if (prevProps.likeChange !== this.props.likeChange && prevProps.likeChange == true) {
            this.setState({ Page: 1 }, function () {
                if (this.ItemsPerPage < this.state.Tiles.length) {
                    this.props.getLikes(getUserId(), this.state.Page, this.state.Tiles.length);
                } else {
                    this.props.getLikes(getUserId(), this.state.Page, this.ItemsPerPage);
                }
            });
        }

        //set loader
        if (prevProps.loadingLikes !== this.props.loadingLikes && !this.state.FetchingMore) {
            this.setState({ Loading: this.props.loadingLikes });
        }

        //check tiles
        if (prevProps.loadingLikes && !this.props.loadingLikes) {
            if (!this.state.FetchingMore) {
                //starting new content
                if (this.props.likes !== prevProps.likes && this.props.likes.data.items.length > 0) {
                    if (this.props.likes !== prevProps.likes) {
                        this.setState({
                            Tiles: this.props.likes.data.items,
                            TotalItems: this.props.likes.data.totalItems,
                            Loading: false
                        }, function () {
                            this.checkArrowVisibility();
                        });
                    }
                } else {
                    this.setState({ Tiles: [], Loading: false }, function () {
                        this.checkArrowVisibility();
                    });
                }
            } else {
                //geting next page
                if (this.props.likes.data.items.length > 0) {
                    this.setState({
                        Tiles: this.state.Tiles.concat(this.props.likes.data.items),
                        Loading: false,
                        FetchingMore: false
                    }, function () {
                        this.refs.rightArrow.classList.add("active");
                    });
                } else {
                    //loaded all items
                    this.setState({
                        Loading: false,
                        FetchingMore: false,
                        FetchNoMore: true
                    });
                }
            }
        }
    }

    slide(dir) {
        if (!this.state.Sliding) {
            this.setState({ Sliding: true }, function () {
                if (this.state.TotalItems > this.ItemsPerSlidePage * 2) {
                    let page;
                    if (dir === 1) {
                        page = this.state.SlidePageCount + 1;
                        this.refs.leftArrow.classList.add("active");
                    } else {
                        page = this.state.SlidePageCount - 1;
                        if (page == 1) {
                            this.refs.leftArrow.classList.remove("active");
                        }
                    }
                    this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100) + "%)";
                    setTimeout(
                        function () {
                            this.setState({ Sliding: false });
                        }.bind(this),
                        300
                    );

                    this.setState({ SlidePageCount: page }, function () {
                        this.blockSwipe = false;
                        this.checkArrowVisibility();
                    });
                } else {
                    this.slideSingle(dir);
                }
            });
        }
    }

    slideSingle(dir) {
        let page = this.state.SlidePageCount;
        let single = this.state.SingleSlide;

        if (dir === 1) {

            this.setState({ SingleSlide: single + 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 75) {
                    page = this.state.SlidePageCount + 1;

                    this.setState({ SingleSlide: -25, SlidePageCount: page });
                }
                this.refs.leftArrow.classList.add("active");
            });
        } else {
            if (single === -25) {
                single = 100;
                page = page - 1;
            }

            this.setState({ SingleSlide: single - 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 100) {
                    this.setState({ SingleSlide: 75, SlidePageCount: page });
                } else if (single === 25 && page == 1) {
                    this.refs.leftArrow.classList.remove("active");
                }
            });
        }

        setTimeout(
            function () {
                this.setState({ SingleClass: (this.state.SingleSlide !== -25 ? " Single" + this.state.SingleSlide : ''), Sliding: false }, function () {
                    this.checkArrowVisibility();
                    this.blockSwipe = false;
                });
            }.bind(this),
            750
        );
    }

    checkArrowVisibility() {
        let tempSize = 0;
        let stop = false;
        let i = 0;

        let TileWidth = 0;
        if (this.state.Tiles.length > 0) {
            TileWidth = this.refs.containerInner.childNodes[0].clientWidth + 5;

            while (this.refs.containerInner.offsetWidth > tempSize && !stop) {

                if (i == this.refs.containerInner.childNodes.length || (tempSize + TileWidth > this.refs.containerInner.offsetWidth)) {
                    stop = true;
                } else {
                    tempSize += TileWidth;
                    i++;
                }
            }

            //check position and load more if at the end
            //console.log("i: " + i + " x " + this.state.SlidePageCount + " <= " + this.state.Tiles.length);
            if (i * this.state.SlidePageCount < this.state.Tiles.length && i !== 0) {
                this.refs.rightArrow.classList.add("active");
            } else {
                this.refs.rightArrow.classList.remove("active");
                this.checkForMoreTiles();
            }

            this.setPageNavigaiton(i);
        }
    }

    setPageNavigaiton(i) {
        var pg = [];
        if (i > 0) {
            for (let p = 0; p < this.state.TotalItems / i; p++) {
                pg.push(p + 1);
            }
        }
        this.setState({ Pagination: pg });
    }


    checkForMoreTiles() {
        if (this.state.TotalItems > this.ItemsPerPage && this.state.Tiles.length < this.state.TotalItems) {
            if (!this.state.FetchNoMore) {
                setTimeout(
                    function () {
                        this.setState({ Page: this.state.Page + 1, FetchingMore: true }, function () {
                            this.props.getLikes(getUserId(), this.state.Page, this.ItemsPerPage);
                        });
                    }.bind(this),
                    300
                );
            } else {
                this.refs.rightArrow.classList.remove("active");
            }
        } else {
            this.setState({ FetchNoMore: true });
        }
    }

    switchView() {
        this.setState({ ViewMode: !this.state.ViewMode });
    }

    render() {

        return (
            <div className="tile-container newest">
                <input type="checkbox" id={"tiles_" + this.state.Title.replace(" ", "")} hidden />
                <div className="tile-title">
                    <label htmlFor={"tiles_" + this.state.Title.replace(" ", "")}><ArrowRight /></label>
                    <div className={"view-mode" + (this.state.ViewMode ? ' vertical' : '')} onClick={() => this.switchView()}><ModeIcon /></div>
                    <span>{this.state.Title}</span>
                </div>
                <div className={"tile-content" + (this.state.ViewMode ? ' vertical' : '')} ref="container" id="wheelLikes">
                    <div className="tile-pagination">
                        {this.state.Tiles.length > 0 && this.state.Pagination.map((P, index) => {
                            return <div className={this.state.SlidePageCount === (index + 1) ? 'active' : ''} key={"pageCount-" + index}></div>
                        })}
                    </div>

                    {this.state.Loading && <Loader />}

                    <div className={"tile-content-inner" + (this.state.TotalItems <= this.ItemsPerSlidePage * 2 ? " single" : "") + this.state.SingleClass} ref="containerInner">
                        {!this.state.Loading && this.state.Tiles.map((T, index) => {
                            return <Tile Tile={T.resource} key={"likes-" + index} />
                        })}
                        {this.state.FetchingMore && !this.state.ViewMode && <div className="tile">
                            <div className="tile-inner">
                                <div className="content is"><Loader /></div>
                            </div>
                        </div>}
                    </div>

                    {!this.state.Loading && <TileShadow class="left" />}
                    {!this.state.Loading && <TileShadow class="right" />}
                    <div className="arrow left" ref="leftArrow" onClick={() => this.slide(0)}><ArrowLeft /></div>
                    <div className="arrow right" ref="rightArrow" onClick={() => this.slide(1)}><ArrowRight /></div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { likes, likeChange, loadingLikes } = state.mkp;
    return { likes, likeChange, loadingLikes };
}

const actionCreators = {
    getLikes: mkpActions.getLike
};

const connectedMKP = connect(mapState, actionCreators)(TilesLikes);
export { connectedMKP as TilesLikes };