export const modelConstants = {

    //set carousel view
    SET_CAROUSEL_VIEW: 'SET_CAROUSEL_VIEW',

    //get models
    MODELS_REQUEST: 'MODELS_GET_REQUEST',
    MODELS_SUCCESS: 'MODELS_GET_SUCCESS',
    MODELS_FAILURE: 'MODELS_GET_FAILURE',

    //get model tree
    MODEL_TREE_REQUEST: 'MODEL_TREE_GET_REQUEST',
    MODEL_TREE_SUCCESS: 'MODEL_TREE_GET_SUCCESS',
    MODEL_TREE_FAILURE: 'MODEL_TREE_GET_FAILURE',

    //Update IETM FROM
    UPDATE_COMING_FROM: 'UPDATE_COMING_FROM',

    // update model state
    UPDATE_MODEL_SET: 'UPDATE_MODEL_SET',

    //update selected path
    UPDATE_SELECTED_PATH: 'UPDATE_SELECTED_PATH',

    //Load Topic to TempLibrary
    LOAD_TOPIC_REQUEST: 'LOAD_TOPIC_REQUEST',
    LOAD_TOPIC_SUCCESS: 'LOAD_TOPIC_SUCCESS',
    LOAD_TOPIC_FAILURE: 'LOAD_TOPIC_FAILURE',

    //Load Container to TempLibrary
    LOAD_CONTAINER_REQUEST: 'LOAD_CONTAINER_REQUEST',
    LOAD_CONTAINER_SUCCESS: 'LOAD_CONTAINER_SUCCESS',
    LOAD_CONTAINER_FAILURE: 'LOAD_CONTAINER_FAILURE'
};