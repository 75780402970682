import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { highlightsActions } from '../../../../../store/_actions';
//components
import { Tile } from './../../../../shared/Tile/Tile';
import { Loader } from '../../../../shared/Loader/Loader';
import {layoutConstants} from "../../../../../store/_constants";

class Highlights extends Component {
    constructor(props) {
        super(props);

        this.imageUrl = process.env.REACT_APP_PAGE_URL_IMAGE;
        this.blockSwipe = false;

        this.state = {
            activeIndex: 0,
            interval: 10000,
            Slides: [],
            TotalSlides: 0,
        };

        this.goToSlide = this.goToSlide.bind(this);
        this.renderSlide = this.renderSlide.bind(this);
    }

    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {
        this.props.getHighlights(this.props.SelectedLanguage);
        //test scroll event1
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        //const tHandler = this.debounced(10, myHandler);
        document.getElementById("wheelSlider").addEventListener("wheel", tHandler, false);

        const el = document.getElementById("wheelSlider");
        var preventHistoryBack = function (e) {
            var delta = e.deltaX || e.wheelDeltaX;

            if (!delta) { return; }

            window.WebKitMediaKeyError /*detect safari*/ && (delta *= -1);

            if (((el.scrollLeft + el.offsetWidth) === el.scrollWidth && delta > 0) || (el.scrollLeft === 0 && delta < 0)) {
                e.preventDefault();
            }
        };

        el.addEventListener('wheel', preventHistoryBack);
    }

    callback(event) {
        var delta = event.deltaX;

        //if its horizontal then:
        if (Math.abs(delta) > 0) {
            event.preventDefault();
            event.stopPropagation();

            if (!this.blockSwipe) {
                this.blockSwipe = true;

                if (delta > 0) {
                    this.goToSlide(1, undefined)
                }
                else if (delta < 0) {
                    this.goToSlide(0, undefined)
                }
            }
        }
    }

    throttled(delay, fn) {
        let lastCall = 0;
        return function (...args) {
            const now = (new Date()).getTime();
            if (now - lastCall < delay) {
                return false;
            }
            lastCall = now;
            return fn(...args);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.highlightsLoader && !this.props.highlightsLoader && this.props.highlights) {
            //create ouput array (api doesn't return slides)
            let newSlides = [];
            for (var i = 0; i < this.props.highlights.totalItems; i += 2) {
                newSlides[i] = {
                    left: this.props.highlights.items[i],
                    right: this.props.highlights.items[i + 1],
                }
            }

            this.setState({ Slides: newSlides, TotalSlides: this.props.highlights.totalItems / 2 }, function () {
                this.goToSlide(0, 0)
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.sliderInterval);

        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("wheelSlider").removeEventListener("wheel", tHandler, false);
    }

    /************************************
    * SLIDE FUNCTIONS
    ***********************************/

    startSlider = () => {
        this.sliderInterval = setInterval(() => {
            this.goToSlide(0);
        }, this.state.interval);
    };

    goToSlide(dir, index) {
        // 1 - left
        // 0 - right
        // not triggered by nav bulltes
        if (index === undefined) {
            if (dir === 1) {
                index = this.state.activeIndex - 1;
            } else {
                index = this.state.activeIndex + 1;
            }
        }

        // update next position
        if (dir === 1 && index === -1) {
            index = this.state.TotalSlides - 1;
        } else if (dir === 0 && index === this.state.TotalSlides) {
            index = 0;
        }

        this.setState({ activeIndex: index }, function () {
            this.blockSwipe = false;
        });
        clearInterval(this.sliderInterval);
        this.startSlider();
    }

    /************************************
    * RENDER SLIDES
    ***********************************/

    renderSlide(slide) {
        const isGermanLng = this.props.SelectedLanguage === layoutConstants.GermanLanguage;
        const buttonTitle = isGermanLng ? 'MEHR ERFAHREN' : 'DISCOVER';

        if (slide.type === "Image") {
            return <div className={"slide-inner type-" + slide.type}>
                <div className="slide-image" style={{ backgroundImage: "url(" + this.imageUrl + slide.image + ")" }}></div>
                {slide.url && !slide.isUrlExternal && <Link to={{ pathname: slide.url }} className="btn">{buttonTitle}</Link>}
                {slide.url && slide.isUrlExternal && <a href={slide.url} target="_blank" rel="noopener noreferrer" className="btn">{buttonTitle}</a>}
            </div>
        } else if (slide.type === "Topic") {
            return <div className={"slide-inner type-" + slide.type}>
                <Tile Tile={slide.topic} isHighlight={true} />
                <Link to={{ pathname: "topic/" + slide.topic.id }} className="btn">{buttonTitle}</Link>
            </div>
        } else if (slide.type === "Bite") {
            return <div className={"slide-inner type-" + slide.type}>
                <Tile Tile={slide.bite} isHighlight={true} />
                <Link to={{ pathname: "feed/" + slide.bite.id }} className="btn">{buttonTitle}</Link>
            </div>
        }
        return;
    }

    render() {
        return (
            <div className="content-slider" id="wheelSlider">
                <div className="slider" id="slider">
                    <div className="slide-navigation">
                        {this.state.Slides.map((slide, index) =>
                            <span className={Math.round(index / 2) === this.state.activeIndex ? "active" : ""} onClick={() => this.goToSlide(0, Math.round(index / 2))} key={index} />
                        )}
                    </div>
                    {this.props.highlightsLoader && < Loader />}
                    {!this.props.highlightsLoader && <div className="slide-container">
                        {this.state.Slides.map((slide, index) =>
                            <div className={Math.round(index / 2) === this.state.activeIndex ? "slide active" : "slide"} key={index}>
                                {this.renderSlide(slide.left)}
                                {this.renderSlide(slide.right)}
                            </div>
                        )}
                    </div>}

                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { SelectedLanguage } = state.layout;
    const { highlights, highlightsLoader } = state.highlights;
    return { SelectedLanguage, highlights, highlightsLoader };
}

const actionCreators = {
    getHighlights: highlightsActions.getHighlights
};

const connectedHighlights = connect(mapState, actionCreators)(Highlights);
export { connectedHighlights as Highlights };
