import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import FilePlayer from 'react-player/lib/players/FilePlayer'
//redux
import { connect } from 'react-redux';
import { mkpActions } from './../../../../store/_actions';
import { layoutConstants } from '../../../../store/_constants';
//components
import { BiteAudio } from "./BiteAudio";
//svg's
import { ReactComponent as ArrowBack } from './../../../../Assets/images/icons/icon_arrow_left_1.svg';
import { ReactComponent as DeleteIcon } from './../../../../Assets/images/icons/icon_delete.svg';
import { ReactComponent as FileIcon } from './../../../../Assets/images/icons/icon_file.svg';
import { ReactComponent as Fullscreen } from './../../../../Assets/images/icons/icon_fullscreen_1.svg';
import { ReactComponent as FullscreenClose } from './../../../../Assets/images/icons/icon_fullscreen_2.svg';
import { ReactComponent as MediaMode } from './../../../../Assets/images/icons/icon_mode_media.svg';
import { ReactComponent as MediaNext } from './../../../../Assets/images/icons/media/forward_icon.svg';
import { ReactComponent as MediaPause } from './../../../../Assets/images/icons/media/icon_pause.svg';
import { ReactComponent as MediaPlay } from './../../../../Assets/images/icons/media/play_icon.svg';
import { ReactComponent as MediaPrev } from './../../../../Assets/images/icons/media/backwards_icon.svg';
import { ReactComponent as MediaReplay } from './../../../../Assets/images/icons/media/icon_replay.svg';
import { ReactComponent as NoteIcon } from './../../../../Assets/images/icons/icon_note.svg';
//import { ReactComponent as ReadMode } from './../../../../Assets/images/icons/icon_mode_read.svg';
import { ReactComponent as UploadIcon } from './../../../../Assets/images/icons/icon_upload.svg';
import { ReactComponent as IconClose } from './../../../../Assets/images/icons/icon_close_1.svg';
import { ReactComponent as IconQuizz } from './../../../../Assets/images/icons/icon_quizz.svg';
import { ReactComponent as IconExercise } from './../../../../Assets/images/icons/icon_exercises.svg';
import { ReactComponent as IconTime } from './../../../../Assets/images/icons/icon_time.svg';

//bite audio
import AudioFile from './../../../../Assets/biteTemp/RR_Sachs_Interview.mp3';
//import AudioFile from './../../../../Assets/biteTemp/265/SinnUndUnsinnPage11.mp3';

class BiteContent extends Component {
    constructor(props) {
        super(props);

        this.self = this;
        this.imageUrl = process.env.REACT_APP_PAGE_URL_IMAGE;
        this.lastPage = -1;
        this.isAudio = true;
        this.isAssetsSectionDisplayed = false;
        this.notes = [
            {
                Id: "note1",
                Date: "11.11.2019",
                Time: "16:47",
                Title: "1 Maecenas nec odio",
                Text: "Lorem ipsum dolor sit amet, consectetuer..."
            },
            {
                Id: "note2",
                Date: "11.11.2019",
                Time: "16:47",
                Title: "2 Maecenas nec odio",
                Text: "Lorem ipsum dolor sit amet, consectetuer..."
            },
            {
                Id: "note3",
                Date: "11.11.2019",
                Time: "16:47",
                Title: "3 Maecenas nec odio",
                Text: "Lorem ipsum dolor sit amet, consectetuer..."
            }
        ];
        this.uploads = [
            {
                Id: "upload1",
                Date: "11.11.2019",
                Time: "16:47",
                Title: "1 File",
                Text: "uploads_v1.pdf (5 MB)"
            },
            {
                Id: "upload2",
                Date: "11.11.2019",
                Time: "16:47",
                Title: "2 File",
                Text: "uploads_v2.pdf (7.6 MB)"
            },
        ];

        this.state = {
            //lastPage: -1,
            loading: false,
            Content: this.props.Bite.items[0],
            Pages: this.props.Pages,
            activeContainer: "",
            activeAsset: 1,
            contentActive: false,

            isMedia: false,
            Expand: false,

            StopAudio: false,
            playing: false,
            played: 0,
            duration: 0,
            remainingTime: "2:37",
            playedTime: "0:00",
            seeker: "0",

            biteTrasncriptIndex: 0,
            biteTrasncript: [
                { "start": 1.43, "end": 3.229, "text": "Die Bedeutung von Sitzungen wird" },
                { "start": 3.23, "end": 4.23, "text": "weiter steigen." },
                { "start": 5.3, "end": 6.77, "text": "Führungskräfte verwenden einen" },
                { "start": 7.28, "end": 8.779, "text": "beachtlichen Anteil ihrer" },
                { "start": 8.78, "end": 10.97, "text": "Zeitressource für diese Aufgabe." },
                { "start": 12.32, "end": 13.839, "text": "Leider steht der Aufwand oft in" },
                { "start": 14.21, "end": 15.778, "text": "einem krassen Missverhältnis zum" },
                { "start": 16.07, "end": 17.07, "text": "erzielten Output, was" },
                { "start": 18.2, "end": 19.523, "text": "zwangsläufig die Frage nach" },
                { "start": 20.21, "end": 21.925, "text": "der Sinnhaftigkeit vieler Sitzungen" },
                { "start": 21.98, "end": 22.98, "text": "aufwirft." },
                { "start": 24.41, "end": 26.149, "text": "Für nicht wenige von uns sind" },
                { "start": 26.15, "end": 27.5, "text": "Sitzungen ein Albtraum." },
                { "start": 28.28, "end": 29.629, "text": "Wir können aber nicht darauf" },
                { "start": 29.63, "end": 30.63, "text": "verzichten." },
                { "start": 31.61, "end": 32.989, "text": "Wenn wir unsere Ziele erreichen" },
                { "start": 32.99, "end": 34.558, "text": "wollen, müssen wir Informationen" },
                { "start": 35.24, "end": 37.189, "text": "austauschen, gemeinsame" },
                { "start": 37.19, "end": 38.894, "text": "Beschlüsse erarbeiten und Massnahmen" },
                { "start": 39.35, "end": 40.35, "text": "festlegen." },
                { "start": 41.22, "end": 42.779, "text": "Die Bedeutung von Sitzungen wird" },
                { "start": 42.78, "end": 43.799, "text": "wegen der zunehmenden" },
                { "start": 43.8, "end": 45.359, "text": "Spezialisierung noch steigen." }]
        };

        this.UpdateBiteProgress = this.UpdateBiteProgress.bind(this);
        this.OpenAssetContent = this.OpenAssetContent.bind(this);
        this.updateTranscript = this.updateTranscript.bind(this);
        this.renderPageElement = this.renderPageElement.bind(this);
        this.changeViewMode = this.changeViewMode.bind(this);
        this.JumpToTopic = this.JumpToTopic.bind(this);
        this.ToggleAudioPlayer = this.ToggleAudioPlayer.bind(this);
        this.ToggleExpand = this.ToggleExpand.bind(this);
        this.ToggleQuiz = this.ToggleQuiz.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        document.getElementsByTagName("body")[0].addEventListener("scroll", this.scrollCheck);

        if (this.state.Pages.totalItems > 0) {
            // check progress and jump to relative section
            var lastCompleted = this.state.Pages.items.sort((a, b) => a.order - b.order).filter(t => t.completed);
            if (this.state.Pages.totalItems > lastCompleted.length && lastCompleted.length > 0) {
                this.JumpToTopic(lastCompleted.length - 1);
            }
            this.UpdateBiteProgress(0);
        }
        //console.log(this.props.SelectedPath);
    }

    componentWillUnmount() {
        document.getElementsByTagName("body")[0].removeEventListener("scroll", this.scrollCheck);
    }

    scrollCheck = (e) => {
        var pos = document.getElementsByTagName("body")[0].scrollTop;

        //handle sticky progressbar
        if (pos >= 530) {
            //add sticky
            document.getElementsByClassName('page-body')[0].classList.add('sticky');
            document.getElementsByClassName('p-overview')[0].style.height = window.innerHeight - 125 + "px";

            //first need to check if we hit bottom else just add scrollTop
            var top = document.getElementsByClassName("p-content")[0].clientHeight + document.getElementsByClassName("p-content")[0].getBoundingClientRect().top;
            if (top >= (window.innerHeight - 125)) {
                document.getElementsByClassName('p-overview')[0].style.top = document.getElementsByTagName("body")[0].scrollTop - 445 + "px";
            }

        } else {
            if (document.getElementsByClassName('page-body')[0]) {
                //remove sticky
                document.getElementsByClassName('page-body')[0].classList.remove('sticky');
            }
        }

        //update progress
        var found = false;
        var i = 0;
        if (document.getElementById("bitePlayer").getElementsByClassName("topic").length > 0) {
            while (i < this.state.Pages.totalItems && !found) {
                if (document.getElementById("bitePlayer").getElementsByClassName("topic")[i].getBoundingClientRect().top > -(document.getElementById("bitePlayer").getElementsByClassName("topic")[i].clientHeight - 30)) {
                    found = true;
                    this.UpdateBiteProgress(i);
                }
                i++;
            }
        }

        //check if scroll its bottom, then set all remaining topics to completed
        if (Math.round(document.getElementsByTagName("body")[0].scrollHeight - pos) === document.getElementsByTagName("body")[0].clientHeight) {
            //console.log("hits bottom");

            //add active to the last topic
            if (this.state.Pages.totalItems > 0) {

                this.UpdateBiteProgress(this.state.Pages.totalItems - 1);
            }

            //check if ther e are still incomplete topics
            //update completed page
            i = 0;
            while (i < this.state.Pages.totalItems) {
                if (!this.state.Pages.items.sort((a, b) => a.order - b.order)[i].completed) {
                    this.props.setPageCompleted(this.state.Pages.items.sort((a, b) => a.order - b.order)[i].id);
                    //console.log("update page " + i + " of " + this.state.Pages.totalItems);
                }
                i++;
            }
        }
    }

    componentDidUpdate(prevProps) {
        //force pages update
        if (!prevProps.loadingPages && this.props.loadingPages) {
            this.setState({ Pages: [] });
        } else if (prevProps.loadingPages && !this.props.loadingPages) {
            if (document.getElementsByTagName("body")[0].scrollTop > 0) { document.getElementsByTagName("body")[0].scrollTo({ top: 0, behavior: 'smooth' }); }
            this.setState({ Pages: this.props.Pages });
        }

        if (prevProps.PageCompleted !== this.props.PageCompleted && this.props.PageCompleted !== undefined) {
            if (this.props.PageCompleted.success) {
                //here the bite got updated. Update ModelSet.
                //get completed pages and calulate percentage
                const completedPages = this.state.Pages.items.sort(a => a.completed).length;
                const percentage = Math.round((100 * completedPages) / this.state.Pages.totalItems);
                //console.log(percentage);

                //update Modelset whithout reloading the entire tree
                if (this.props.ModelSet.items) {
                    const model = this.props.ModelSet.items.filter(item => item.id === this.props.SelectedPath.Model)[0];

                    if (model.containers) {
                        const container = model.containers.filter(item => item.id === this.props.SelectedPath.Container)[0];
                        const topic = container.topics.filter(item => item.id === this.props.SelectedPath.Topic)[0];
                        topic.bites.filter(item => item.id === this.props.SelectedPath.Bite)[0].progressPercentage = percentage;
                    }
                }
            }
        }

    }

    UpdateBiteProgress(i) {
        if (this.state.Pages.totalItems > 1) {
            let index = i;
            //check if scroll is at the bottom then it should set the last one active            
            const pos = document.getElementsByTagName("body")[0].scrollTop;
            if (Math.round(document.getElementsByTagName("body")[0].scrollHeight - pos) === document.getElementsByTagName("body")[0].clientHeight) {
                index = this.refs.topics.getElementsByClassName('topic').length - 1;
            }

            if (this.refs.progress.getElementsByClassName('active').length > 0) {
                this.refs.progress.getElementsByClassName('active')[0].classList.remove('active');
            }

            if (this.refs.topics.getElementsByClassName('active').length > 0) {
                this.refs.topics.getElementsByClassName('active')[0].classList.remove('active');
            }

            this.refs.progress.getElementsByClassName('bar')[index].classList.add('active');
            this.refs.topics.getElementsByClassName('topic')[index].classList.add('active');

            if (i === -1) { index++; }
            var pages = this.state.Pages.items.length;
            var stepWidth = (this.refs.progress.offsetWidth - (pages)) / pages * (index + 1) + index;
            if (this.refs.progress.getElementsByClassName('total')[0].offsetWidth < stepWidth) {
                this.refs.progress.getElementsByClassName('total')[0].style.width = stepWidth + 'px';
            }

            //update completed page
            if (!this.state.Pages.items?.sort((a, b) => a.order - b.order)[i].completed) {
                this.props.setPageCompleted(this.state.Pages.items.sort((a, b) => a.order - b.order)[i].id);
            }
        }
    }

    //topic select
    JumpToTopic(topic) {
        var jump = document.getElementsByTagName("body")[0].scrollTop + document.getElementById("bitePlayer").getElementsByClassName("topic")[topic].getBoundingClientRect().top - 60;
        document.getElementsByTagName("body")[0].scrollTo({ top: jump, behavior: 'smooth' });
    }

    //audio poup
    ToggleAudioPlayer() {
        var popup = document.getElementsByClassName("audio-player")[0];
        if (popup.classList.contains("active")) {
            this.setState({ StopAudio: true }, function () {
                popup.classList.remove("active");
            });
        } else {
            this.setState({ StopAudio: false }, function () {
                popup.classList.add("active");
            });
        }
    }

    //switch between assets (upload/notes/downlaods)
    OpenAssetContent(val, active) {
        this.setState({ contentActive: val });
        this.setState({ activeContainer: active });
    }

    //Expanded view
    ToggleExpand() {
        //stop media if playing and change to read amode
        this.setState({ Expand: !this.state.Expand });
    }

    //quiz popup    
    ToggleQuiz(index) {
        var popup = document.getElementById("bitePlayer").getElementsByClassName("topic")[index - 1].getElementsByClassName("popup-overlay")[0];
        if (popup.classList.contains("active")) {
            popup.classList.remove("active");
        } else {
            popup.classList.add("active");
        }
    }

    /** media player */
    ref = player => {
        this.player = player
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({ duration })
    }

    handleProgress = state => {
        //console.log('onProgress', state)

        this.setState(state)
        this.updateTimes();
        this.updateSeeker(this.state.played * 100 + "%");
    }

    updateTimes() {
        //elapsed
        var time = this.state.played * this.state.duration;
        var hrs = ~~(time / 3600);
        var mins = ~~((time % 3600) / 60);
        var secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "") + secs;

        this.setState({ playedTime: ret });

        //remaining
        time = this.state.duration * (1 - this.state.played);
        hrs = ~~(time / 3600);
        mins = ~~((time % 3600) / 60);
        secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "") + secs;

        this.setState({ remainingTime: "-" + ret });
    }

    updateSeeker(pos) {
        this.setState({ seeker: pos })
    }

    handleSeekChange = e => {
        var pos = 0;
        var left = 0;
        if (e !== undefined) {
            //click position
            var offset = e.currentTarget.getClientRects()[0];
            left = e.clientX - offset.left;
            pos = (100 * left) / e.currentTarget.offsetWidth;
            pos = parseFloat(pos / 100 * this.state.duration)
        }

        this.updateSeeker(left + "px");
        this.setState({ played: pos })
        this.player.seekTo(pos);

    }

    handleReplay = e => {
        this.handleSeekChange();
        this.setState({ biteTrasncriptIndex: 0 });
        var activeStep = document.getElementById('bitePlayer').getElementsByClassName('step active')[0];
        var currentIndex = this.state.biteTrasncriptIndex;

        while (currentIndex > -1) {
            activeStep.getElementsByTagName('span')[currentIndex].classList.remove('highlight', 'read');
            currentIndex--;
        }
        this.setState({ biteTrasncriptIndex: 0 });
    }

    //transcript highlight
    updateTranscript(player) {
        if (this.state.playing) {
            var currentIndex = this.state.biteTrasncriptIndex;
            //var activeStep = document.getElementById('bitePlayer').getElementsByClassName('step active')[0];
            var activeStep = document.getElementById('bitePlayer').getElementsByClassName('step active')[0].getElementsByClassName('play-this')[0];
            activeStep.getElementsByTagName('span')[currentIndex].classList.remove('highlight');

            if (player.currentTime > this.state.biteTrasncript[currentIndex].end && currentIndex < this.state.biteTrasncript.length) {
                while (player.currentTime > this.state.biteTrasncript[currentIndex].end && currentIndex < this.state.biteTrasncript.length - 1) {
                    activeStep.getElementsByTagName('span')[currentIndex].classList.add('read');
                    currentIndex++;
                }
                this.setState({ biteTrasncriptIndex: currentIndex });

            } else if (player.currentTime < this.state.biteTrasncript[currentIndex].start && currentIndex > 0) {

                while (player.currentTime < this.state.biteTrasncript[currentIndex].start && currentIndex > 0) {
                    currentIndex--;
                }
                this.setState({ biteTrasncriptIndex: currentIndex });

            } else {
                activeStep.getElementsByTagName('span')[currentIndex].classList.add('read', 'highlight');
            }
        }
    }

    //insert page content
    renderPageElement(Page, Content, index) {
        if (Content.type === "Text") {
            return <div className="elem-text" dangerouslySetInnerHTML={{ __html: Content.text }} key={"step" + Page.id + Content.id + index}></div>
        }
        else if (Content.type === "MultipleImage") {
            return <div className="elem-images" key={"step" + Page.id + index}>{Content.images?.sort((a, b) => a.order - b.order)
                .map((I, index) =>
                    <div className="elem-image" key={"step" + Page.id + Content.id + index}>
                        <img src={this.imageUrl + I.url} alt={"step" + Page.id + Content.id + index} />
                    </div>
                )}
            </div>
        }
        else if (Content.type === "Image") {
            return <div className="elem-image" key={"step" + Page.id + index}>
                <img src={this.imageUrl + Content.url} alt={Content.url} />
                {Content.subtitle && <div className="elem-text" dangerouslySetInnerHTML={{ __html: Content.subtitle }}></div>}
            </div>
        }
        else if (Content.type === "Quiz") {
            return <div key={"step" + Page.id + Content.id + index}>
                <div className="elem-text quizz-link" onClick={() => this.ToggleQuiz(Page.order)} dangerouslySetInnerHTML={{ __html: Content.text }}></div>
                <div className="popup-overlay">
                    <div className="popup" key={"step" + Page.id + index}>
                        <IconClose onClick={() => this.ToggleQuiz(Page.order)} />
                        <iframe className="elem-quiz" src={Content.url} title={Page.title}></iframe>
                    </div>
                </div>
            </div>

        } else {
            return <div key={"step" + Page.id + index}>{Content.type}</div>
        }

    }

    changeViewMode(val) {
        //stop media if playing and change to read amode
        this.setState({ isMedia: val }, function () {
            if (!val && this.state.playing) {
                this.handlePlayPause();
            }
        })
    }

    navigate(event) {
        if (event.target.tagName === 'A') {
            const href = event.target.getAttribute('href');
            event.preventDefault();
            this.props.history.push("/" + href);
            //this.props.history.goBack();
        }
    }

    render() {
        const styles = {
            progress: { width: this.state.Content.progressPercentage + '%' }
        };
        return (
            <div className={"page-body" + (this.state.Expand ? " expanded-view" : "")}>

                <div className={"p-content" + (this.state.isMedia ? ' isMedia' : '')}>
                    {this.state.Pages.totalItems > 1 && <div className={"bite-progress elem6" + (this.state.Pages.totalItems < 2 ? " invisible" : "")} ref="progress">
                        <div className="progress-container">
                            {this.state.Pages && this.state.Pages.items.map((page, index) =>
                                <div className="bar" onClick={() => this.JumpToTopic(index)} key={"page" + page.id}><div><p>{index + 1}</p></div></div>
                            )}
                        </div>
                        <div className="total started" style={styles.progress}></div>
                    </div>}
                    {this.state.Pages.totalItems > 1 && <p className={(this.state.Pages.totalItems < 2 ? " invisible" : "")}>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "PROGRESS" : "FORTSCHRITT"}</p>}
                    <div className={"bite-player"} id="bitePlayer" ref="bitePlayer">
                        <div className="step overview-element">
                            <div className="elem-title"><p>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "OVERVIEW" : "ÜBERBLICK"}</p></div>
                            {this.state.Content && <div className="elem-text" dangerouslySetInnerHTML={{ __html: this.state.Content.overview }}></div>}
                            <div className="elem-text">
                                {this.state.Content && <p className="time margin-t">
                                    <IconTime />
                                    <div><strong>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Estimated time effort" : "Geschätzter Zeitaufwand"}:&nbsp;</strong>{this.state.Content.estimatedTimeInMinutes} min.</div></p>}
                            </div>
                        </div>

                        {this.state.Content.overviewImage && <div className="step">
                            <div className="title"></div>
                            <div className="preview"><img src={this.imageUrl + this.state.Content.overviewImage} alt="" /></div>
                        </div>}

                        {this.state.Pages && !this.props.loadingPage && this.state.Pages.items?.sort((a, b) => a.order - b.order)
                            .map((P, index) =>
                                <div className="step topic" key={"step" + index}>
                                    <div className="elem-title-wrapper" onClick={(e) => this.navigate(e)}>
                                        <div className="elem-title" dangerouslySetInnerHTML={{ __html: P.title }}></div>
                                        {P.content?.sort((a, b) => a.order - b.order)
                                            .map((C, index) => {
                                                return [(this.renderPageElement(P, C, index))]
                                            })}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>

                <div className={"p-overview" + (this.state.isMedia ? " multimedia-mode" : "")}>
                    <div className="bar" ref="overviewBar"></div>
                    <div className="step mode-container">
                        <div className="modes">
                            {/*<div className="mode-read">
                                <div onClick={() => this.changeViewMode(false)}><ReadMode /></div>
                                <p>READ ONLY</p>
                            </div>*/}
                            {this.state.Content.audioFile &&
                                <div className="mode-multi">
                                    <div onClick={() => this.ToggleAudioPlayer()}><MediaMode /></div>
                                    <p>AUDIO</p>
                                </div>
                            }
                        </div>
                        <div className="expand">
                            <div className="expand-icon" onClick={() => this.ToggleExpand()}><Fullscreen /><FullscreenClose /></div  >
                        </div>
                        {/*mulimedia mode*/}
                        {!this.isAudio && <div className="media-controls">
                            <div className="mc-container">
                                <div className="mc-controls">
                                    <div className="mc-buttons">
                                        <div className="btn-mc mc-replay"><MediaReplay onClick={this.handleReplay} /></div>
                                        <div className="btn-mc mc-prev"><MediaPrev /></div>
                                        <div className="btn-mc mc-playPause">
                                            <MediaPlay onClick={this.handlePlayPause} className={this.state.playing ? 'hide' : 'icon-play'} />
                                            <MediaPause onClick={this.handlePlayPause} className={!this.state.playing ? 'hide' : 'icon-pause'} />
                                        </div>
                                        <div className="btn-mc mc-next"><MediaNext /></div>
                                    </div>
                                    <div className="mc-progress-tracker">
                                        <div className="mc-seekbar" onClick={this.handleSeekChange}>
                                            <div style={{ left: this.state.seeker }}></div>
                                        </div>
                                        <div className="mc-played">{this.state.playedTime}</div>
                                        <div className="mc-remaining">{this.state.remainingTime}</div>
                                    </div>
                                </div>
                                <FilePlayer
                                    id="audioPlayer"
                                    className="hide"
                                    url={AudioFile}
                                    width="0"
                                    height="0"
                                    ref={this.ref}
                                    playing={this.state.playing}
                                    onProgress={this.handleProgress}
                                    onDuration={this.handleDuration} />
                            </div>
                        </div>}
                        {/*audio mode*/}
                        {this.isAudio && <div className="popup-overlay audio-player">
                            {this.props.Bite && this.props.Topic && <div className="popup">
                                <IconClose onClick={() => this.ToggleAudioPlayer()} />
                                {this.props.Bite.items[0].bannerImage && <div className="thumbnail" style={{ backgroundImage: "url(" + this.imageUrl + this.props.Bite.items[0].bannerImage + ")" }}></div>}
                                {this.props.Topic.items[0] && <div className="topic-title" dangerouslySetInnerHTML={{ __html: this.props.Topic.items[0].title }}></div>}
                                {this.props.Bite.items[0] && <div className="bite-title" dangerouslySetInnerHTML={{ __html: this.props.Bite.items[0].title }}></div>}
                                <div className="media-controls">
                                    <BiteAudio
                                        File={this.imageUrl + this.state.Content.audioFile}
                                        Sections={this.state.Content.audioFileChapterStartSeconds}
                                        Stop={this.state.StopAudio} />
                                </div>
                            </div>}
                        </div>}
                    </div>
                    {this.state.Pages.totalItems > 1 &&
                        <div className="bite-quizz topics">
                            <div className="bq-title">{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "TOPICS COVERED" : "BEHANDELTE THEMEN"}</div>
                            <div className="bq-element no-border" ref="topics">
                                {!this.props.loadingPage && this.state.Pages.items.map((page, index) => {
                                    if (page.title !== "") {
                                        return <div className="topic" onClick={() => this.JumpToTopic(index)} key={"page" + page.id + "-" + index}>
                                            <div className="number"><p>{index + 1}</p></div>
                                            <div dangerouslySetInnerHTML={{ __html: page.title }}></div>
                                        </div>
                                    } else {
                                        return <div className="topic" onClick={() => this.JumpToTopic(index)} key={"page" + page.id + "-" + index}>
                                            <div className="number"><p>{index + 1}</p></div>
                                            <div></div>
                                        </div>
                                    }
                                }
                                )}
                            </div>
                        </div>}
                    <div className="bite-quizz">
                        <div className="bq-title">{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "QUIZZES & EXERCISES" : "QUIZZE & ÜBUNGEN"}</div>
                        <div className="bq-element">
                            <div className="bq-icon">
                                <IconQuizz />
                            </div>
                            <span>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Interactive Quizzes" : "Interaktive Quizze"}</span><span>{this.state.Content.quizCounter}</span>
                        </div>
                        <div className="bq-element">
                            <div className="bq-icon">
                                <IconExercise />
                            </div>
                            <span>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "Exercises & Reflections" : "Übungen & Reflexionen"}</span><span>{this.state.Content.reflectionCounter}</span>
                        </div>
                    </div>

                    <input type="checkbox" id="assetsToggle" hidden />
                    {this.isAssetsSectionDisplayed && <div className="assets-container">
                        <div className="a-menu">
                            <div className={this.state.activeAsset === 1 ? ' active' : ''} onClick={() => this.setState({ activeAsset: 1 })}>NOTES</div>
                            <div className={this.state.activeAsset === 2 ? ' active' : ''} onClick={() => this.setState({ activeAsset: 2 })}>UPLOADS</div>
                            {this.state.Content.downloads.length > 0 && <div className={this.state.activeAsset === 3 ? ' active' : ''} onClick={() => this.setState({ activeAsset: 3 })}>DOWNLOADS</div>}
                        </div>
                        <div className={"notes-container a-container" + (this.state.activeAsset === 1 ? ' active' : '')}>
                            {this.notes.map((n, index) =>
                                <div className="a-row" onClick={() => this.OpenAssetContent(true, n.Id)} key={n.Id}>
                                    <span className="a-left">
                                        <span>{n.Date}</span>
                                        <span>{n.Time}</span>
                                    </span>
                                    <span className="a-right">
                                        <span>{n.Title}</span>
                                        <span>{n.Text}</span>
                                    </span>
                                </div>
                            )}
                            <div className="a-icon" onClick={() => this.OpenAssetContent(true, "notes")}><NoteIcon /></div>
                        </div>
                        <div className={"uploads-container a-container" + (this.state.activeAsset === 2 ? ' active' : '')}>
                            {this.uploads.map((u, index) =>
                                <div className="a-row" onClick={() => this.OpenAssetContent(true, u.Id)} key={u.Id}>
                                    <span className="a-left">
                                        <span>{u.Date}</span>
                                        <span>{u.Time}</span>
                                    </span>
                                    <span className="a-right">
                                        <span>{u.Title}</span>
                                        <span>{u.Text}</span>
                                    </span>
                                </div>
                            )}
                            <div className="a-icon"><UploadIcon /></div>
                        </div>

                        {this.state.Content.downloads && <div className={"downloads-container a-container" + (this.state.activeAsset === 3 ? ' active' : '')}>
                            {this.state.Content.downloads.map((u, index) =>
                                <Link to={{ pathname: this.imageUrl + u.url }} target="_blank" className="a-row" /*onClick={() => this.OpenAssetContent(true, u.Id)}*/ key={"page" + u.Id}>
                                    <span className="a-left">
                                        <span>File</span>
                                        <span></span>
                                    </span>
                                    <span className="a-right">
                                        <span>{u.filename}</span>
                                        <span></span>
                                    </span>
                                </Link>
                            )}
                        </div>}

                        <div className={"element-container" + (this.state.contentActive ? " active" : "")}>
                            <div className="ec-back" onClick={() => this.OpenAssetContent(false, "")}><ArrowBack />Back</div>

                            {this.notes.map((n, index) =>
                                <div className={this.state.activeContainer === n.Id ? "ec-content ec-note active" : "ec-content ec-note"} key={n.Id}>
                                    <div className="ec-inner">
                                        <div className="ec-title">{n.Title}</div>
                                        <div className="ec-text">{n.Text}</div>
                                    </div>
                                </div>
                            )}

                            {this.uploads.map((u, index) =>
                                <div className={this.state.activeContainer === u.Id ? "ec-content ec-file active" : "ec-content ec-file"} key={u.Id}>
                                    <div className="ec-inner">
                                        <div className="a-row">
                                            <span className="a-left"><FileIcon /></span>
                                            <span className="a-right">
                                                <span>{u.Title}</span>
                                                <span>{u.Text}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={"ec-content ec-typing" + (this.state.activeContainer === "notes" ? " active" : "")} >
                                <div className="ec-inner">
                                    <div className="ec-title">Type text</div>
                                    <textarea className="ec-text" placeholder="Type note..."></textarea>
                                </div>
                            </div>

                        </div>
                        <div className="a-icon"><DeleteIcon /></div>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        loading,
        Topic,
        Bite,
        Pages,
        PageCompleted,
        loadingPages
    } = state.mkp;

    const {
        ModelSet,
        SelectedPath
    } = state.model;

    const {
        SelectedLanguage
    } = state.layout;

    return {
        SelectedLanguage,
        loading,
        Topic,
        Bite,
        Pages,
        PageCompleted,
        loadingPages,
        ModelSet,
        SelectedPath
    };
}

const actionCreators = {
    setPageCompleted: mkpActions.setPageCompleted
};

const connectedMKP = connect(mapState, actionCreators)(withRouter(BiteContent));
export { connectedMKP as BiteContent };