import React, { Component } from 'react';
import { connect } from 'react-redux';
//components
import { Actions } from './../Actions/Actions';
import { Loader } from '../Loader/Loader';
//icons
import { ReactComponent as IconPlay } from './../../../Assets/images/icons/icon_play.svg';
import { ReactComponent as IconClose } from './../../../Assets/images/icons/icon_close_1.svg';
//styles
import './Stage.scss';

class Stage extends Component {
  constructor(props) {
    super(props);

    this.imageUrl = process.env.REACT_APP_PAGE_URL_IMAGE;

    //temporary for new media feature

    this.state = {
      Content: null,
      Type: this.props.Type,
      loading: true,
      openMedia: false,
      mediaLink: '',
    };

    this.toggleMediaPlayer = this.toggleMediaPlayer.bind(this);
  }

  componentDidMount() {
    if (this.props.Topic && this.props.Container) {
      //this loads for topic & bite
      this.setState({
        Container: this.props.Container.items[0],
        Topic: this.props.Topic.items[0],
      });

      if (this.state.Type === 'Topic') {
        this.setState({
          stageBG: this.props.Topic.items[0].bannerImage,
          mediaLink: this.props.Topic.items[0].video,
        });
      }
    }

    if (this.state.Type === 'Bite' && this.props.Bite) {
      this.setState({
        Bite: this.props.Bite.items[0],
        stageBG: this.props.Bite.items[0].bannerImage,
      });
    }

    if (!this.props.Refreshing) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Refreshing !== this.props.Refreshing) {
      if (this.props.Refreshing) {
        this.setState({ loading: true });
      } else {
        //set updated container if it chnaged
        this.setState({
          Container: this.props.Container.items[0],
          Topic: this.props.Topic.items[0],
        });

        if (this.state.Type === 'Topic') {
          this.setState({
            stageBG: this.props.Topic.items[0].bannerImage,
            mediaLink: this.props.Topic.items[0].video,
          });
        }

        //set updated bite if it chnaged
        if (this.state.Type === 'Bite') {
          this.setState({
            Bite: this.props.Bite.items[0],
            stageBG: this.props.Bite.items[0].bannerImage,
          });
        }

        //set loader
        this.setState({ loading: false });
      }
    }
  }

  toggleMediaPlayer() {
    this.setState({ openMedia: !this.state.openMedia });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="banner">
          <Loader />
        </div>
      );
    } else {
      return (
        <div className="banner" style={{ backgroundImage: 'url(' + this.imageUrl + this.state.stageBG + ')' }}>
          <div className="banner-content">
            {this.state.Container && (
              <div className="bc-title" dangerouslySetInnerHTML={{ __html: this.state.Container.title }} />
            )}
            <div className="bc-bottom">
              {this.state.Topic && (
                <div className="bc-topic" dangerouslySetInnerHTML={{ __html: this.state.Topic.title }} />
              )}
              {this.state.Bite && (
                <div className="bc-bite" dangerouslySetInnerHTML={{ __html: this.state.Bite.title }} />
              )}
            </div>
            {this.state[this.state.Type] && (
              <div
                className="bc-description"
                dangerouslySetInnerHTML={{ __html: this.state[this.state.Type].subtitle }}
              />
            )}
          </div>
          {this.state.mediaLink && (
            <div className="banner-play">
              <IconPlay onClick={() => this.toggleMediaPlayer()} />
              {this.state.openMedia && (
                <div className="popup-overlay media-player">
                  <div className="popup">
                    <IconClose onClick={() => this.toggleMediaPlayer()} />
                    <iframe
                      src={this.state.mediaLink}
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      title="temp"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="banner-bar">
            {this.state[this.state.Type] && <Actions Id={this.state[this.state.Type].id} Type={this.state.Type} />}
          </div>
        </div>
      );
    }
  }
}

function mapState(state) {
  const { Container, Topic, Bite } = state.mkp;
  return { Container, Topic, Bite };
}

const connectedMKP = connect(mapState, null)(Stage);
export { connectedMKP as Stage };
