import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions, mkpActions } from '../../../../store/_actions';
//components
import { BreadcrumbsNav } from '../../../shared/Breadcrumbs/BreadcrumbsNav';
import { Stage } from './../../../shared/Stage/Stage';
import { TopicContent } from './TopicContent';
import { Tiles } from './../../../shared/Tiles/Tiles';
import { Loader } from './../../../shared/Loader/Loader';
//styles
import './Topic.scss';
import { getUser } from '../../../../store/_services';

class Topic extends Component {
  constructor(props) {
    super(props);

    //get id from url
    const { id } = this.props.match.params;

    this.state = {
      Container: null,
      Topic: null,
      TopicId: id,
      Refreshing: true,
      RefreshingBites: true,
      RefreshingTiles: true,
    };
  }

  /************************************
   * LIFECYCLE
   ***********************************/

  componentDidMount() {
    //console.log("Mounted component: Topic");
    if (document.getElementsByTagName('body')[0].scrollTop > 0) {
      document.getElementsByTagName('body')[0].scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.props.getTopic(this.props.match.params.id, this.props.SelectedLanguage);

    //getlikes
    if (this.props.likes === undefined) {
      this.user = getUser();
      this.props.getLikes(this.user.profile.id, 1, -1);
    }
    //getfavorites
    if (this.props.favorites === undefined) {
      this.user = getUser();
      this.props.getFavorites(this.user.profile.id, 1, -1, this.props.SelectedLanguage);
    }
  }

  componentDidUpdate(prevProps) {
    //if the id doesn't match load correct topic (case: changing topic in tiles container)
    if (this.state.TopicId !== this.props.match.params.id) {
      this.setState(
        {
          Container: null,
          Topic: null,
          TopicId: this.props.match.params.id,
          Refreshing: true,
          RefreshingTiles: true,
        },
        function () {
          this.props.getTopic(this.props.match.params.id, this.props.SelectedLanguage);
        },
      );
    }

    //topic changed and id's match (updated)
    if (this.props.Topic) {
      if (prevProps.Topic !== this.props.Topic && this.props.Topic.items[0].id == this.state.TopicId) {
        this.setState(
          {
            Topic: this.props.Topic.items[0],
            Refreshing: false,
            RefreshingTiles: false,
          },
          function () {
            //check if this needs to pe updated
            if (this.props.Container !== undefined) {
              if (this.state.Topic.containerId !== this.props.Container.items[0].id) {
                this.props.getContainer(this.state.Topic.containerId, this.props.SelectedLanguage);
              } else {
                this.setState({ Container: this.props.Container.items[0] }, function () {
                  this.UpdateSelectedPath(
                    this.state.Container.id,
                    this.state.Topic.id,
                    -1,
                    'Page',
                    this.state.Container.modelId,
                  );
                });
              }
            } else {
              this.props.getContainer(this.state.Topic.containerId, this.props.SelectedLanguage);
            }
          },
        );
      }
    }

    //container changed
    if (this.props.Container) {
      if (
        prevProps.Container !== this.props.Container &&
        this.state.Topic.containerId == this.props.Container.items[0].id
      ) {
        this.setState(
          {
            Container: this.props.Container.items[0],
          },
          function () {
            this.UpdateSelectedPath(
              this.state.Container.id,
              this.state.Topic.id,
              -1,
              'Page',
              this.state.Container.modelId,
            );
          },
        );
      }
    }

    //container change (updated)
    if (this.props.Topic && this.props.Container) {
      if (
        this.props.Topic.items[0].id === this.state.TopicId &&
        this.props.Topic.items[0].containerId === this.props.Container.items[0].id &&
        prevProps.Container !== this.props.Container
      ) {
        this.setState({ RefreshingTiles: false });
      }
    }
  }

  /************************************
   * SELECTED PATH UPDATE
   ***********************************/

  UpdateSelectedPath(container, topic, bite, lastUpdate, model) {
    let path = {};
    path = Object.assign({}, path, this.props.SelectedPath);

    if (model) {
      path.Model = model;
    }
    if (container) {
      path.Container = container;
    }
    if (topic) {
      path.Topic = topic;
    }
    if (bite) {
      path.Bite = bite;
    }
    if (lastUpdate != null) {
      path.LastUpdate = lastUpdate;
    }

    this.props.setSelectedPath(path);

    this.setState({ indexLoader: false });
  }

  render() {
    return (
      <div className={'alias-topic' + (this.props.OpenContext.Id !== -1 ? ' context-open' : '')}>
        <BreadcrumbsNav Type="Topic" Refreshing={!this.state.Topic || !this.state.Container} />
        <Stage Type="Topic" Refreshing={!this.state.Topic || !this.state.Container} />
        {this.state.Refreshing && (
          <div className="page-body">
            <Loader />
          </div>
        )}
        {this.state.Topic && !this.state.Refreshing && <TopicContent TopicInfo={this.state.Topic} />}
        {!this.state.RefreshingTiles && this.props.match.params.id && (
          <Tiles
            Title="RELATED HOLOS TOPICS"
            Type="container"
            Ignore={this.props.match.params.id}
            Id={this.state.TopicId}
          />
        )}
        {this.state.RefreshingTiles && (
          <div className="tile-container">
            <input type="checkbox" id={'tiles_' + 'RELATED HOLOS TOPICS'.replace(' ', '')} hidden />
            <div className="tile-title">
              <label htmlFor={'tiles_' + 'RELATED HOLOS TOPICS'.replace(' ', '')}>RELATED HOLOS TOPICS</label>
            </div>
            <div className="tile-content">
              <Loader />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { SelectedLanguage } = state.layout;

  const { Container, Topic, TopicBitesList, likes, favorites, OpenContext } = state.mkp;

  return {
    SelectedLanguage,
    Container,
    Topic,
    TopicBitesList,
    likes,
    favorites,
    OpenContext,
  };
}

const actionCreators = {
  getContainer: mkpActions.getContainer,
  getTopic: mkpActions.getTopic,
  getLikes: mkpActions.getLike,
  getFavorites: mkpActions.getFavorite,
  setSelectedPath: modelActions.setSelectedPath,
};

const connectedMKP = connect(mapState, actionCreators)(Topic);
export { connectedMKP as Topic };
