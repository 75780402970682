import { modelConstants } from '../_constants';
import { modelService } from '../_services';

export const modelActions = {
    setCarouselView,
    getModels,
    getModelTree,
    updateModelSet,
    setUpdateComingFrom,
    setSelectedPath,
    getTopic,
    getContainer
};


function setCarouselView(set) {
    return dispatch => {
        dispatch(success(set));
    }
    function success(set) { return { type: modelConstants.SET_CAROUSEL_VIEW, set } }
}

function getModels(selectedLanguage) {
    return dispatch => {
        dispatch(request({}));

        modelService.getModels(selectedLanguage)
            .then(
                models => {
                    dispatch(success(models));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(models) { return { type: modelConstants.MODELS_REQUEST, models } }
    function success(models) { return { type: modelConstants.MODELS_SUCCESS, models } }
    function failure(error) { return { type: modelConstants.MODELS_FAILURE, error } }
}

function getModelTree(id, selectedLanguage) {
    return dispatch => {
        dispatch(request({}));

        modelService.getModelTree(id, selectedLanguage)
            .then(
                tree => {
                    dispatch(success(tree));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(tree) { return { type: modelConstants.MODEL_TREE_REQUEST, tree } }
    function success(tree) { return { type: modelConstants.MODEL_TREE_SUCCESS, tree } }
    function failure(error) { return { type: modelConstants.MODEL_TREE_FAILURE, error } }
}

function updateModelSet(set) {
    return dispatch => {
        dispatch(success(set));
    }
    function success(set) { return { type: modelConstants.UPDATE_MODEL_SET, set } }
}

function setUpdateComingFrom(set) {
    return dispatch => {
        dispatch(success(set));
    }
    function success(set) { return { type: modelConstants.UPDATE_COMING_FROM, set } }
}

function setSelectedPath(path) {
    return dispatch => {
        dispatch(success(path));
    }
    function success(path) { return { type: modelConstants.UPDATE_SELECTED_PATH, path } }
}

function getTopic(id, selectedLanguage) {
    return dispatch => {
        dispatch(request({}));

        modelService.getTopic(id, selectedLanguage)
            .then(
                topic => {
                    dispatch(success(topic));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(topic) { return { type: modelConstants.LOAD_TOPIC_REQUEST, topic } }
    function success(topic) { return { type: modelConstants.LOAD_TOPIC_SUCCESS, topic } }
    function failure(error) { return { type: modelConstants.LOAD_TOPIC_FAILURE, error } }
}

function getContainer(id, selectedLanguage) {
    return dispatch => {
        dispatch(request({}));

        modelService.getContainer(id, selectedLanguage)
            .then(
                container => {
                    dispatch(success(container));
                    //history.push('/');                    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(container) { return { type: modelConstants.LOAD_CONTAINER_REQUEST, container } }
    function success(container) { return { type: modelConstants.LOAD_CONTAINER_SUCCESS, container } }
    function failure(error) { return { type: modelConstants.LOAD_CONTAINER_FAILURE, error } }
}