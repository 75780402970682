import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { mkpActions } from '../../../store/_actions';
//components
import { Tile } from './../Tile/Tile';
import { TileShadow } from './../Tile/TileShadow';
import { Loader } from '../Loader/Loader';
import { LoadMore } from '../LoadMore/LoadMore';
//svg's
import { ReactComponent as ArrowRight } from './../../../Assets/images/icons/icon_arrow_right_1.svg';
import { ReactComponent as ArrowLeft } from './../../../Assets/images/icons/icon_arrow_left_1.svg';
import { ReactComponent as ModeIcon } from './../../../Assets/images/icons/ico_direction_v1.svg';
//styles
import "./Tiles.scss";

class Tiles extends Component {
    constructor(props) {
        super(props);

        this.ItemsPerPage = 25;
        this.ItemsPerSlidePage = 4;
        this.blockSwipe = false;

        this.state = {
            Id: -1,
            Loading: false,
            Title: props.Title,
            Sliding: false,
            Tiles: [],
            tilesType: this.props.Type,
            container: this.props.Container,
            Page: 1,
            FetchingMore: false,
            FetchNoMore: false,
            SlidePageCount: 1,
            SingleSlide: 0,
            SingleClass: " ",
            TotalItems: 0,
            Pagination: [],
            ViewMode: false
        };

        this.slide = this.slide.bind(this);
        this.checkForMoreTiles = this.checkForMoreTiles.bind(this);
        this.switchView = this.switchView.bind(this);
    }

    slide(dir) {
        if (!this.state.Sliding) {
            this.setState({ Sliding: true }, function () {
                if (this.state.TotalItems > this.ItemsPerPage * 2) {
                    let page;
                    if (dir === 1) {
                        page = this.state.SlidePageCount + 1;
                        this.refs.leftArrow.classList.add("active");
                    } else {
                        page = this.state.SlidePageCount - 1;
                        if (page == 1) {
                            this.refs.leftArrow.classList.remove("active");
                        }
                    }
                    this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100) + "%)";
                    setTimeout(
                        function () {
                            this.setState({ Sliding: false });
                        }.bind(this),
                        750
                    );

                    this.setState({ SlidePageCount: page }, function () {
                        this.checkArrowVisibility();
                        this.blockSwipe = false;
                    });

                } else {
                    this.slideSingle(dir);
                }
            });
        }
    }

    slideSingle(dir) {
        let page = this.state.SlidePageCount;
        let single = this.state.SingleSlide;

        if (dir === 1) {

            this.setState({ SingleSlide: single + 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 75) {
                    page = this.state.SlidePageCount + 1;

                    this.setState({ SingleSlide: -25, SlidePageCount: page });
                }
                this.refs.leftArrow.classList.add("active");
            });
        } else {
            if (single === -25) {
                single = 100;
                page = page - 1;
            }

            this.setState({ SingleSlide: single - 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 100) {
                    this.setState({ SingleSlide: 75, SlidePageCount: page });
                } else if (single === 25 && page == 1) {
                    this.refs.leftArrow.classList.remove("active");
                }
            });
        }

        setTimeout(
            function () {
                this.setState({ SingleClass: (this.state.SingleSlide !== -25 ? " Single" + this.state.SingleSlide : ''), Sliding: false }, function () {
                    this.checkArrowVisibility();
                    this.blockSwipe = false;
                });
            }.bind(this),
            750
        );
    }

    componentDidMount() {

        if (this.state.tilesType === "topic") {
            this.setState({ topicId: this.props.Bite.items[0].topicId });
        }
        if (this.state.tilesType === "container") {
            this.setState({ containerId: this.props.Topic.items[0].containerId });
        }

        this.LoadTiles();

        //wheel scroll event
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementsByClassName("wheel")[0].addEventListener("wheel", tHandler);     
    }

    callback(event) {
        const el = document.getElementsByClassName("wheel")[0];
        var delta = event.deltaX || event.wheelDeltaX;

        if (!delta) { return; }

        window.WebKitMediaKeyError /*detect safari*/ && (delta *= -1);

        //check if we hit the borders and prevent the browser to change (history)
        if (((el.scrollLeft + el.offsetWidth) === el.scrollWidth && delta > 0) || (el.scrollLeft === 0 && delta < 0)) {
            event.preventDefault();
        } else {
            //var delta = event.deltaX;

            //if its horizontal then:
            if (Math.abs(delta) > 0) {
                event.preventDefault();
                event.stopPropagation();

                //check if swipe allowed
                if ((this.refs.rightArrow.classList.contains("active") && event.deltaX > 0) || (this.refs.leftArrow.classList.contains("active") && event.deltaX < 0)) {

                    if (!this.blockSwipe) {
                        this.blockSwipe = true;

                        if (delta > 0) {
                            this.slide(1);
                        }
                        else if (delta < 0) {
                            this.slide(0);
                        }
                    }
                }
            }
        }
    }

    throttled(delay, fn) {
        let lastCall = 0;
        return function (...args) {
            const now = (new Date()).getTime();
            if (now - lastCall < delay) {
                return false;
            }
            lastCall = now;
            return fn(...args);
        }
    }

    componentWillUnmount() {
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementsByClassName("wheel")[0].removeEventListener("wheel", tHandler);
    }

    LoadTiles() {
        if (this.state.tilesType === "topic") {
            if (this.props.Id) {
                this.setState({ Id: this.props.Id }, function () {
                    this.props.getRelatedBites(this.state.Id, 1, this.ItemsPerPage, this.props.SelectedLanguage);
                })
            } else {
                this.setState({ Id: this.props.Bite.items[0].topicId }, function () {
                    this.props.getTopicBites(this.state.Id, 1, this.ItemsPerPage, this.props.SelectedLanguage);
                })
            }
        }
        else if (this.state.tilesType === "container") {
            if (this.props.Id) {
                this.setState({ Id: this.props.Id }, function () {
                    this.props.getRelatedTopics(this.state.Id, 1, this.ItemsPerPage, this.props.SelectedLanguage);
                })
            } else {
                this.setState({ Id: this.props.Topic.items[0].containerId }, function () {
                    this.props.getContainerTopics(this.state.Id, 1, this.ItemsPerPage, this.props.SelectedLanguage);
                })
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (!this.state.Loading && !this.state.FetchingMore && (this.props.loadingTopics || this.props.loadingBites)) {
            this.setState({ Loading: true });
        }
        //console.log("type: "+this.state.tilesType);
        if ((prevProps.loadingTopics && !this.props.loadingTopics) || (prevProps.loadingBites && !this.props.loadingBites)) {
            if (!this.state.FetchingMore) {
                //starting new content
                if (this.props.TopicBites !== prevProps.TopicBites && this.props.TopicBites.items.length > 0 && this.state.tilesType === "topic") {
                    if (this.props.TopicBites !== prevProps.TopicBites) {

                        //check if opened bite is here and remove it
                        let itemIndex = this.props.TopicBites.items.findIndex(c => c.id.toString() == this.props.Ignore.toString());
                        let totalSize = this.props.TopicBites.totalItems;
                        let tiles = this.props.TopicBites.items;
                        if (itemIndex !== -1) {
                            if (tiles.length > 1) {
                                tiles.splice(itemIndex, 1);
                            } else {
                                tiles = [];
                            }
                            totalSize--;
                        }

                        this.setState({
                            Tiles: tiles,
                            TotalItems: totalSize,
                            Type: "Bite",
                            Loading: false
                        }, function () {

                            this.checkArrowVisibility();
                        });
                    }
                } else if (this.props.ContainerTopics !== prevProps.ContainerTopics && this.props.ContainerTopics.items.length > 0 && this.state.tilesType === "container") {
                    if (this.props.ContainerTopics !== prevProps.ContainerTopics) {

                        //check if opened bite is here and remove it
                        let itemIndex = this.props.ContainerTopics.items.findIndex(c => c.id.toString() == this.props.Ignore.toString());
                        let totalSize = this.props.ContainerTopics.totalItems;
                        let tiles = this.props.ContainerTopics.items;
                        if (itemIndex !== -1) {
                            if (tiles.length > 1) {
                                tiles.splice(itemIndex, 1);
                            } else {
                                tiles = [];
                            }
                            totalSize--;
                        }

                        this.setState({
                            Tiles: tiles,
                            TotalItems: totalSize,
                            Type: "Topic",
                            Loading: false
                        }, function () {
                            this.checkArrowVisibility();
                        });
                    }
                } else {
                    this.setState({ Tiles: [], Loading: false }, function () {
                        this.checkArrowVisibility();
                    });
                }
            } else {
                //getting next page
                if (this.state.tilesType === "topic") {
                    if (this.props.TopicBites.items.length > 0) {

                        //check if opened bite is here and remove it
                        let itemIndex = this.props.TopicBites.items.findIndex(c => c.id.toString() == this.props.Ignore.toString());
                        let totalSize = this.props.TopicBites.totalItems;
                        let tiles = this.props.TopicBites.items;
                        if (itemIndex !== -1) {
                            if (tiles.length > 1) {
                                tiles.splice(itemIndex, 1);
                            } else {
                                tiles = [];
                            }
                            totalSize--;
                        }

                        this.setState({
                            Tiles: this.state.Tiles.concat(tiles),
                            TotalItems: totalSize,
                            Loading: false,
                            FetchingMore: false
                        }, function () {
                            this.refs.rightArrow.classList.add("active");
                        });
                    } else {
                        //loaded all items
                        this.setState({
                            Loading: false,
                            FetchingMore: false,
                            FetchNoMore: true
                        });
                    }
                } else if (this.state.tilestype === "container") {
                    if (this.props.ContainerTopics.items.length > 0) {
                        //check if opened bite is here and remove it
                        let itemIndex = this.props.ContainerTopics.items.findIndex(c => c.id.toString() == this.props.Ignore.toString());
                        let totalSize = this.props.ContainerTopics.totalItems;
                        let tiles = this.props.ContainerTopics.items;
                        if (itemIndex !== -1) {
                            if (tiles.length > 1) {
                                tiles.splice(itemIndex, 1);
                            } else {
                                tiles = [];
                            }
                            totalSize--;
                        }

                        this.setState({
                            Tiles: this.state.Tiles.concat(tiles),
                            TotalItems: totalSize,
                            Loading: false,
                            FetchingMore: false
                        }, function () {
                            this.refs.rightArrow.classList.add("active");
                        });
                    } else {
                        //loaded all items
                        this.setState({
                            Loading: false,
                            FetchingMore: false,
                            FetchNoMore: true
                        });
                    }
                }
            }
        }
    }

    checkArrowVisibility() {
        let tempSize = 0;
        let stop = false;
        let i = 0;

        let TileWidth = 0;
        if (this.state.Tiles.length > 0 && this.refs.containerInner) {
            TileWidth = this.refs.containerInner.childNodes[0].clientWidth/* + 5*/;
        }
        if (this.refs.containerInner) {
            while (this.refs.containerInner.offsetWidth > tempSize && !stop) {

                if (i == this.refs.containerInner.childNodes.length) {
                    stop = true;
                } else if (tempSize + TileWidth > this.refs.containerInner.offsetWidth) {
                    stop = true;
                    //i--;
                } else {
                    tempSize += TileWidth;
                    i++;
                }
            }

            //check position and load more if at the end
            //console.log("i: " + i + " x " + this.state.SlidePageCount + " <= " + this.state.Tiles.length);
            if (i * this.state.SlidePageCount < this.state.Tiles.length) {
                this.refs.rightArrow.classList.add("active");
            } else {
                this.refs.rightArrow.classList.remove("active");
                this.checkForMoreTiles();
            }

            //if pagination doesn't exist yet
            if (this.state.Pagination.length === 0) {
                this.setPageNavigation(i);
            }
        }
    }

    setPageNavigation(i) {
        //console.log("i: " + i);
        //console.log("total: " + this.state.TotalItems)
        let pg = [];
        for (let p = 0; p < this.state.TotalItems / i; p++) {
            pg.push(p + 1);
        }
        this.setState({ Pagination: pg });
    }

    checkForMoreTiles() {
        if (this.state.TotalItems > this.ItemsPerPage && this.state.Tiles.length < this.state.TotalItems) {
            if (!this.state.FetchNoMore) {

                setTimeout(
                    function () {
                        this.setState({ Page: this.state.Page + 1, FetchingMore: true }, function () {
                            if (this.state.tilesType === "topic") {
                                if (this.props.Id) {
                                    this.props.getRelatedBites(this.state.Id, this.state.Page, this.ItemsPerPage, this.props.SelectedLanguage);
                                } else {
                                    this.props.getTopicBites(this.state.Id, this.state.Page, this.ItemsPerPage, this.props.SelectedLanguage);
                                }
                            } else if (this.state.tilesType === "container") {
                                if (this.props.Id) {
                                    this.props.getRelatedTopics(this.state.Id, this.state.Page, this.ItemsPerPage, this.props.SelectedLanguage);
                                } else {
                                    this.props.getContainerTopics(this.state.Id, this.state.Page, this.ItemsPerPage, this.props.SelectedLanguage);
                                }
                            }
                        });
                    }.bind(this),
                    300
                );

            } else {
                this.refs.rightArrow.classList.remove("active");
            }
        } else {
            this.setState({ FetchNoMore: true });
        }
    }

    switchView() {
        this.setState({ ViewMode: !this.state.ViewMode });
    }

    render() {

        return (
            <div className="tile-container newest">
                <input type="checkbox" id={"tiles_" + this.state.Title.replace(" ", "")} hidden />
                <div className="tile-title">
                    <label htmlFor={"tiles_" + this.state.Title.replace(" ", "")}><ArrowRight /></label>
                    <div className={"view-mode" + (this.state.ViewMode ? ' vertical' : '')} onClick={() => this.switchView()}><ModeIcon /></div>
                    <span>{this.state.Title}</span>
                </div>
                <div id="tileContainer" className={"tile-content wheel" + (this.state.ViewMode ? ' vertical' : '')} ref="container">
                    <div className="tile-pagination">
                        {this.state.Tiles.length > 0 && this.state.Pagination.map((P, index) => {
                            return <div className={this.state.SlidePageCount === (index + 1) ? 'active' : ''} key={"pageCount-" + index}><span></span></div>
                        })}
                    </div>
                    
                    {this.state.Loading && <Loader />}
                    {((this.props.Topic && this.state.tilesType == "topic") || this.state.tilesType == "container") && <div className={"tile-content-inner" + (this.state.TotalItems <= this.ItemsPerSlidePage * 2 ? " single" : "") + this.state.SingleClass} ref="containerInner">
                        {!this.state.Loading && this.state.Tiles.map((T, index) => {

                            if (this.state.tilesType == "topic") {
                                return <Tile
                                    Tile={T}
                                    key={this.state.tilesType + index} />
                            }
                            else if (this.state.tilesType == "container") {
                                return <Tile
                                    Tile={T}
                                    key={this.state.tilesType + index} />
                            }
                            else { return <div></div> }
                        }
                        )}
                        {this.state.FetchingMore && !this.state.ViewMode && <div className="tile">
                            <div className="tile-inner">
                                <div className="content is"><Loader /></div>
                            </div>
                        </div>}
                    </div>}
                    {!this.state.FetchNoMore && this.state.Tiles.length < this.state.TotalItems &&
                        <LoadMore loadMore={this.checkForMoreTiles} loading={this.state.FetchingMore} />
                    }

                    {!this.state.Loading && <TileShadow class="left" />}
                    {!this.state.Loading && <TileShadow class="right" />}
                    <div className="arrow left" ref="leftArrow" onClick={() => this.slide(0)}><ArrowLeft /></div>
                    <div className="arrow right" ref="rightArrow" onClick={() => this.slide(1)}><ArrowRight /></div>
                </div>

            </div>

        );
    }
}

function mapState(state) {
    const { SelectedLanguage } = state.layout;
    const {  loading, ContainerTopics, loadingTopics, TopicBites, loadingBites, Container, Topic, Bite } = state.mkp;
    return { SelectedLanguage, loading, ContainerTopics, loadingTopics, TopicBites, loadingBites, Container, Topic, Bite };
}

const actionCreators = {
    getContainerTopics: mkpActions.getContainerTopics,
    getTopicBites: mkpActions.getTopicBites,
    getLikes: mkpActions.getLike,
    getFavorites: mkpActions.getFavorite,
    getRelatedBites: mkpActions.getRelatedBites,
    getRelatedTopics: mkpActions.getRelatedTopics
};

const connectedMKP = connect(mapState, actionCreators)(Tiles);
export { connectedMKP as Tiles };