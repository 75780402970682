import React, { Component } from 'react';
//styles
import './BreadcrumbsNav.scss';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class BreadcrumbsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Type: this.props.Type,
      loading: true,
      Container: null,
      Topic: null,
      Bite: null,
      Refreshing: true,
      RefreshingTiles: true,
    };
  }

  componentDidMount() {
    if (this.props.Topic && this.props.Container) {
      this.setState({
        Container: this.props.Container.items[0],
        Topic: this.props.Topic.items[0],
      });
    }

    if (this.state.Type === 'Bite' && this.props.Bite) {
      console.info('if this.state.Type === Bite');
      this.setState({
        Bite: this.props.Bite.items[0],
      });
    }

    if (!this.props.Refreshing) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Refreshing !== this.props.Refreshing) {
      if (this.props.Refreshing) {
        this.setState({ loading: true });
      } else {
        this.setState({
          Container: this.props.Container.items[0],
          Topic: this.props.Topic.items[0],
        });

        //set updated bite if it changed
        if (this.state.Type === 'Bite') {
          this.setState({
            Bite: this.props.Bite.items[0],
          });
        }

        //set loader
        this.setState({ loading: false });
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <div className="breadcrumbsNav">Loading...</div>;
    } else {
      return (
        <div className="breadcrumbsNav">
          <div className="breadcrumbsNav-inner">
            <div className="breadcrumbsNav-label">
              <div className="breadcrumbsNav-label-item">INDEX</div>
              <div className="breadcrumbsNav-label-item">TOPIC</div>
              <div className="breadcrumbsNav-label-item">{this.state.Bite && <>FEED</>}</div>
            </div>
            <div className="breadcrumbsNav-path">
              <div className="breadcrumbsNav-path-container">
                <div className="breadcrumbsNav-path-inner">
                  <div className="breadcrumbsNav-path-section">
                    <div className="breadcrumbsNav-path-section-inner">
                      {this.props.Container && <Link to="/">Home</Link>}
                    </div>
                  </div>

                  <div className="breadcrumbsNav-path-section">
                    {this.props.Topic && this.props.Type === 'Bite' ? (
                      <div className="breadcrumbsNav-path-section-inner">
                        <Link
                          to={`/topic/${this.props.Topic.items[0].id}`}
                          dangerouslySetInnerHTML={{ __html: this.props.Topic.items[0].title }}
                        />
                      </div>
                    ) : (
                      <div
                        className="breadcrumbsNav-path-section-inner"
                        dangerouslySetInnerHTML={{ __html: this.state.Topic.title }}
                      />
                    )}
                  </div>

                  <div className="breadcrumbsNav-path-section">
                    {this.state.Bite && (
                      <div
                        className="breadcrumbsNav-path-section-inner"
                        dangerouslySetInnerHTML={{ __html: this.state.Bite.title }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

function mapState(state) {
  const { Container, Topic, Bite } = state.mkp;
  return { Container, Topic, Bite };
}

const connectedMKP = connect(mapState, null)(BreadcrumbsNav);
export { connectedMKP as BreadcrumbsNav };
