import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mkpActions } from './../../../../store/_actions';
//components
import { TopicBite } from './TopicBite';
import { Loader } from './../../../shared/Loader/Loader';
import { LoadMore } from './../../../shared/LoadMore/LoadMore';

import { layoutConstants } from '../../../../store/_constants';

class TopicContent extends Component {

    constructor(props) {
        super(props);

        this.ItemsPerPage = 25;

        this.state = {
            Loading: true,
            Topic: this.props.TopicInfo,
            Page: 1,
            FetchingMore: false,
            FetchNoMore: false,
            Tiles: [],
            TotalItems: 0
        }

        this.LoadMore = this.LoadMore.bind(this);
    }


    componentDidMount() {
        this.props.getTopicBitesList(this.state.Topic.id, 1, this.ItemsPerPage, this.props.SelectedLanguage);
    }

    componentDidUpdate(prevProps) {

        if (prevProps.loadingBitesList && !this.props.loadingBitesList) {
            if (!this.state.FetchingMore) {
                //starting new content
                if (this.props.TopicBitesList != prevProps.TopicBitesList && this.props.TopicBitesList.items.length > 0) {
                    if (this.props.TopicBitesList !== prevProps.TopicBitesList) {
                        this.setState({
                            Tiles: this.props.TopicBitesList.items,
                            TotalItems: this.props.TopicBitesList.totalItems,
                            Loading: false
                        });
                    }
                } else {
                    this.setState({ Tiles: [], Loading: false });
                }
            } else {
                //getting next page
                if (this.props.TopicBitesList.items.length > 0) {
                    this.setState({
                        Tiles: this.state.Tiles.concat(this.props.TopicBitesList.items),
                        FetchingMore: false
                    });
                } else {
                    //loaded all items
                    this.setState({
                        FetchingMore: false,
                        FetchNoMore: true
                    });
                }

            }
        }
    }

    LoadMore() {
        if (!this.state.FetchNoMore) {
            this.setState({ Page: this.state.Page + 1, FetchingMore: true }, function () {
                this.props.getTopicBitesList(this.state.Topic.id, this.state.Page, this.ItemsPerPage, this.props.SelectedLanguage);
            });
        }
    }

    render() {
        return (
            <div className="page-body">
                <div className="p-overview">
                    <div className="bar"></div>
                    <p>{this.props.SelectedLanguage == layoutConstants.EnglishLanguage ? "OVERVIEW" : "ÜBERBLICK"}</p>
                    {this.state.Topic && <div className="text" dangerouslySetInnerHTML={{ __html: this.state.Topic.overview }}></div>}
                </div>
                <div className="p-content">
                    <div className="bar" style={{height: '5px', marginBottom: '1px'}}></div>
                    <div className="progress" style={{height: '3px'}}>
                        <div className={"bar p-" + this.state.Topic.progressPercentage} 
                             style={{backgroundColor: 'var(--bs-progress-bar-color)'}}></div>
                    </div>
                    <p>HOLOS FEEDS</p>
                    <div className="topic-container">
                        {!this.props.Loading && this.state.Tiles.length > 0 && this.state.Tiles.map((bite, index) =>
                            <TopicBite Bite={bite} Topic={this.state.Topic} key={index} />
                        )}
                        {this.state.Loading && <Loader />}
                        {!this.state.Loading && this.state.TotalItems > this.ItemsPerPage && !this.state.FetchNoMore &&
                            <LoadMore loadMore={this.LoadMore} loading={this.state.FetchingMore} />
                        }
                        {this.state.FetchingMore && <div className="topic"><Loader /></div>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { SelectedLanguage } = state.layout;
    const {  loadingBitesList, TopicBitesList } = state.mkp;
    return { SelectedLanguage, loadingBitesList, TopicBitesList };
}

const actionCreators = {
    getTopicBitesList: mkpActions.getTopicBitesList
};

const connectedMKP = connect(mapState, actionCreators)(TopicContent);
export { connectedMKP as TopicContent };