import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {CssVarsPonyfill} from 'css-vars-ponyfill-react'
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {MsalProvider} from "@azure/msal-react";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import { Loader } from '../src/components/shared/Loader/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';

//redux
import {Provider} from 'react-redux'

import {PersistGate} from 'redux-persist/integration/react'
import returnStoreAndPersistor from "./store/_helpers/store";
import ProtectedComponent from "./components/layout/ProtectedComponent";
import {BrowserRouter} from "react-router-dom";
import {AppBarLoader} from "./components/layout/AppBar/app-bar-loader";

const {store, persistor} = returnStoreAndPersistor();

CssVarsPonyfill({
    externalStyleSheets: true,
    onError: (error) => {
        console.log(error);
    }
})

const appBarLoader = new AppBarLoader()

// wait until app bar is loaded
const timeout = setTimeout(async () => {
    const msalInstance = await appBarLoader.getMsalInstance();
    
    createRoot(document.getElementById('root'))
    .render(
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <AuthenticatedTemplate>
                        <ProtectedComponent>
                            <BrowserRouter>
                                <App persistor={persistor} store={store}/>
                            </BrowserRouter>
                        </ProtectedComponent>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <div className="container vh-100">
                            <div className="d-flex justify-content-center align-items-center vh-100">
                                {/* <div className="text-info">You are not signed in! Redirecting to login...</div> */}
                                <Loader />
                            </div>
                        </div>                      
                    </UnauthenticatedTemplate>
                </PersistGate>
            </Provider>
        </MsalProvider>);
    
    clearTimeout(timeout)
}, 500)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();