import { modelConstants } from '../_constants';

const initialState = {
    CarouselView:false,
    LoadingModels: false,
    Models: {
        items: [],
        totalItems: 0
    },
    LoadingModelTree: false,
    ModelTree: null,
    ModelSet: {
        items:[]
    },
    SelectedPath: {
        Model: -1,
        Container: -1,
        Topic: -1,
        Bite: -1,
        LastUpdate: ""
    },
    LoadedTopic: null,
    LoadedContainer: null
}

export function model(state = initialState, action) {
    switch (action.type) {
        //set model caroussel view
        case modelConstants.SET_CAROUSEL_VIEW:
            return {
                ...state,
                CarouselView: action.set
            };
        //load modelConstants
        case modelConstants.MODELS_REQUEST:
            return {
                ...state,
                LoadingModels: true
            };
        case modelConstants.MODELS_SUCCESS:
            return {
                ...state,
                LoadingModels: false,
                Models: action.models.data
            };
        case modelConstants.MODELS_FAILURE:
            return {
                ...state,
                LoadingModels: false,
            };
        //load model tree
        case modelConstants.MODEL_TREE_REQUEST:
            return {
                ...state,
                LoadingModelTree: true
            };
        case modelConstants.MODEL_TREE_SUCCESS:
            return {
                ...state,
                LoadingModelTree: false,
                ModelTree: action.tree.data
            };
        case modelConstants.MODEL_TREE_FAILURE:
            return {
                ...state,
                LoadingModelTree: false,
                ModelTree: action.tree.data
            };
        //Update complete modelset
        case modelConstants.UPDATE_MODEL_SET:
            return {
                ...state,
                ModelSet: action.set
            };
        //Update coming from index
        /*case modelConstants.UPDATE_COMING_FROM:
            return {
                ...state,
                UpdateComingFrom: action.set
            };*/
        //Update Selection path
        case modelConstants.UPDATE_SELECTED_PATH:
            return {
                ...state,
                SelectedPath: action.path
            };
        //Load Topic
        case modelConstants.LOAD_TOPIC_REQUEST:
            return {
                ...state,
            };
        case modelConstants.LOAD_TOPIC_SUCCESS:
            return {
                ...state,
                LoadedTopic: action.topic.data.items[0]
            };
        case modelConstants.LOAD_TOPIC_FAILURE:
            return {
                ...state,
            };
        //Load Container
        case modelConstants.LOAD_CONTAINER_REQUEST:
            return {
                ...state,
            };
        case modelConstants.LOAD_CONTAINER_SUCCESS:
            return {
                ...state,
                LoadedContainer: action.container.data.items[0]
            };
        case modelConstants.LOAD_CONTAINER_FAILURE:
            return {
                ...state,
            };
        default:
            return state
    }
}

export default model