import _ from "lodash";
import { AppBarState } from "./app-bar-state";


export default class AppBarStateObservable {
    appBarState: AppBarState | null
    constructor() {
        this.appBarState = null;
        document.querySelector('app-bar')!.addEventListener('appBarStateChanged', newState => {
            this.appBarState = (newState as any).detail as AppBarState;
            this.notify(this.appBarState);
        })
    }
    
    private notify(appBarState: AppBarState) {
        _.forEach(this.observers, observer => {
            observer(appBarState);
        })
    }

    private observers: Array<(state: AppBarState) => void> = new Array<(state: AppBarState) => void>();

    public attach(observer: (state: AppBarState) => void) {
        if(!this.observers.find(_ => _ === observer)){
            this.observers.push(observer)
        }
    }

    public detach(observer: (state: AppBarState) => void) {
        this.observers = _.remove(this.observers, _ => _ === observer)
    }
}