import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions } from './../../../../../store/_actions';
///components
import { Loader } from '../../../../shared/Loader/Loader';
import { Tile } from './../../../../shared/Tile/Tile';
import { TileShadow } from './../../../../shared/Tile/TileShadow';
import { LoadMore } from '../../../../shared/LoadMore/LoadMore';
//icons
import { ReactComponent as ArrowRight } from './../../../../../Assets/images/icons/icon_arrow_right_1.svg';
import { ReactComponent as ArrowLeft } from './../../../../../Assets/images/icons/icon_arrow_left_1.svg';
import { ReactComponent as ModeIcon } from './../../../../../Assets/images/icons/ico_direction_v1.svg';

class KnowledgeExplorer extends Component {
    constructor(props) {
        super(props);

        this.Tree = [];
        this.ItemsPerPage = 25;/*min has to be 8*/
        this.blockSwipe = false;
        this.starting = true;

        this.state = {
            Loading: true,
            Tiles: [],
            Tiles1: [],
            Tiles2: [],
            TotalItems: 0,
            Page: 1,
            Sliding: false,
            FetchNoMore: true,
            FetchingMore: false,
            SlidePageCount: 1,
            SingleSlide: 0,
            SingleClass: " ",
            Pagination: [],
            ViewMode: false,
            Init2Rows: false
        };

        this.slide = this.slide.bind(this);
        this.switchView = this.switchView.bind(this);
        this.checkForMoreTiles = this.checkForMoreTiles.bind(this);
    }

    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {
        if (this.props.SelectedPath.LastUpdate === "Page") {
            //if coming from a topic or bite page and model already loaded
            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];
            if (activeModelSet.containers) {
                this.setState({ Loading: true }, function () {
                    this.resetExplorer();
                    setTimeout(function () {
                        this.loadBranch();
                    }.bind(this), 50);
                });
            }
        }

        //wheel scroll event
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("wheelExplorer").addEventListener("wheel", tHandler);
    }

    componentWillUnmount() {
        //test scroll event1
        const myHandler = (event) => this.callback(event);
        const tHandler = this.throttled(1250, myHandler);
        document.getElementById("wheelExplorer").removeEventListener("wheel", tHandler);
    }

    callback(event) {
        var delta = event.deltaX || event.wheelDeltaX;

        //if its horizontal then:
        if (Math.abs(delta) > 0) {
            event.preventDefault();
            event.stopPropagation();

            //check if swipe allowed
            if ((this.refs.rightArrow.classList.contains("active") && event.deltaX > 0) || (this.refs.leftArrow.classList.contains("active") && event.deltaX < 0)) {

                if (!this.blockSwipe) {
                    this.blockSwipe = true;

                    if (delta > 0) {
                        this.slide(1);
                    }
                    else if (delta < 0) {
                        this.slide(0);
                    }
                }
            }
        }
    }

    throttled(delay, fn) {
        let lastCall = 0;
        return function (...args) {
            const now = (new Date()).getTime();
            if (now - lastCall < delay) {
                return false;
            }
            lastCall = now;
            return fn(...args);
        }
    }

    componentDidUpdate(prevProps) {

        //first model change for each model 
        if (prevProps.ModelSet.Model !== this.props.ModelSet.Model) {
            this.setState({ Loading: true }, function () {
            });
        }

        if ((prevProps.ModelSet !== this.props.ModelSet ||
            (prevProps.SelectedPath.Model !== this.props.SelectedPath.Model && this.props.SelectedPath.LastUpdate === "Model") ||
            (prevProps.SelectedPath.Container !== -1 && this.props.SelectedPath.Container === -1))
            && this.props.SelectedPath.LastUpdate !== "page" && prevProps.SelectedPath.LastUpdate !== "page") {

            var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

            if (activeModelSet.containers) {

                if (this.props.SelectedPath.LastUpdate === "Page") {
                    this.loadBranch();
                } else {
                    //load data into explorer tiles                
                    this.setState({ Loading: true }, function () {
                        this.resetExplorer();
                        setTimeout(function () {
                            this.initFullTree();
                        }.bind(this), 50);
                    });
                }

            }
        }


        if ((prevProps.SelectedPath !== this.props.SelectedPath) && this.props.SelectedPath.LastUpdate !== "Model" && this.props.SelectedPath.LastUpdate !== "") {
            //selectd path changed
            if (this.props.SelectedPath.Bite !== -1 || this.props.SelectedPath.Topic !== -1 || this.props.SelectedPath.Container !== -1) {
                this.setState({ Loading: true }, function () {
                    this.resetExplorer();
                    setTimeout(function () {
                        this.loadBranch();
                    }.bind(this), 50);
                });
            }

        }


    }

    /************************************
    * SELECTED PATH UPDATE
    ***********************************/

    UpdateSelectedPath(container, topic, bite, lastUpdate, model) {
        let path = {};
        path = Object.assign({}, path, this.props.SelectedPath);

        if (model) {
            path.Model = model;
        }
        if (container) {
            path.Container = container;
        }
        if (topic) {
            path.Topic = topic;
        }
        if (bite) {
            path.Bite = bite;
        }
        if (lastUpdate != null) {
            path.LastUpdate = lastUpdate;
        }

        this.props.setSelectedPath(path);

        this.setState({ indexLoader: false });
    }

    /************************************
    * RESET TILE CONTAINERS
    ***********************************/

    resetExplorer() {
        this.Tree = [];
        this.setState({
            Tiles: [],
            Tiles1: [],
            Tiles2: [],
            TotalItems: 0
        });
    }

    /************************************
    * LOAD CONTENT INTO KNOWLEDGE EXPLORER
    ***********************************/

    initFullTree() {
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        for (var c = 0; c < activeModelSet.containers.length; c++) {
            for (var t = 0; t < activeModelSet.containers[c].topics.length; t++) {

                this.Tree.push({
                    id: activeModelSet.containers[c].topics[t].id,
                    title: activeModelSet.containers[c].topics[t].title,
                    type: activeModelSet.containers[c].topics[t].type,
                    video: activeModelSet.containers[c].topics[t].video,
                    progressPercentage: activeModelSet.containers[c].topics[t].progressPercentage,
                    modelIcon: activeModelSet.containers[c].topics[t].modelIcon,
                    containerId: activeModelSet.containers[c].topics[t].containerId,
                    isSpecial: activeModelSet.containers[c].topics[t].isSpecial,
                    inProduction: activeModelSet.containers[c].topics[t].inProduction,
                    hidden: false
                });

                for (var b = 0; b < activeModelSet.containers[c].topics[t].bites.length; b++) {
                    this.Tree.push({
                        id: activeModelSet.containers[c].topics[t].bites[b].id,
                        title: activeModelSet.containers[c].topics[t].bites[b].title,
                        type: activeModelSet.containers[c].topics[t].bites[b].type,
                        video: activeModelSet.containers[c].topics[t].bites[b].video,
                        progressPercentage: activeModelSet.containers[c].topics[t].bites[b].progressPercentage,
                        modelIcon: activeModelSet.containers[c].topics[t].bites[b].modelIcon,
                        topicId: activeModelSet.containers[c].topics[t].bites[b].topicId,
                        isSpecial: activeModelSet.containers[c].topics[t].bites[b].isSpecial,
                        inProduction: activeModelSet.containers[c].topics[t].bites[b].inProduction,
                        hidden: false
                    });
                }
            }
        }

        //if we're setting up 2 row slider then hide the non visible from 1st row

        if (this.Tree.length > 8) {
            var tiles2 = JSON.parse(JSON.stringify(this.Tree.slice(0, this.ItemsPerPage))).map((item, index) => { item.hidden = index > 3; return item });

            this.setState({
                Tiles1: tiles2,
                Tiles2: JSON.parse(JSON.stringify(this.Tree.slice(4, this.ItemsPerPage))),
                TotalItems: this.Tree.length,
                Page: 1,
                SlidePageCount: 1,
                SingleSlide: 0,
                SingleClass: " ",
                Pagination: [],
                Init2Rows: false,
                FetchNoMore: false,
                FetchingMore: false,
                Loading: false
            }, function () {
                this.checkArrowVisibility();
            });
        } else {
            this.setState({
                Tiles: this.Tree.slice(0, this.ItemsPerPage),
                TotalItems: this.Tree.length,
                Page: 1,
                SlidePageCount: 1,
                SingleSlide: 0,
                SingleClass: " ",
                Pagination: [],
                Init2Rows: false,
                FetchNoMore: false,
                FetchingMore: false,
                Loading: false
            }, function () {
                this.checkArrowVisibility();
            });
        }

        if (this.starting) { this.starting = false; }
    }

    loadBranch() {
        const { Topic, Container } = this.props.SelectedPath;
        var activeModelSet = this.props.ModelSet.items.filter(i => i.id === this.props.SelectedPath.Model)[0];

        if (Topic !== -1) {
            //get bite children
            for (var c = 0; c < activeModelSet.containers.length; c++) {
                for (var t = 0; t < activeModelSet.containers[c].topics.length; t++) {

                    if (activeModelSet.containers[c].topics[t].id === Topic) {
                        for (var b = 0; b < activeModelSet.containers[c].topics[t].bites.length; b++) {
                            this.Tree.push({
                                id: activeModelSet.containers[c].topics[t].bites[b].id,
                                title: activeModelSet.containers[c].topics[t].bites[b].title,
                                type: activeModelSet.containers[c].topics[t].bites[b].type,
                                video: activeModelSet.containers[c].topics[t].bites[b].video,
                                progressPercentage: activeModelSet.containers[c].topics[t].bites[b].progressPercentage,
                                modelIcon: activeModelSet.containers[c].topics[t].bites[b].modelIcon,
                                topicId: activeModelSet.containers[c].topics[t].bites[b].topicId,
                                isSpecial: activeModelSet.containers[c].topics[t].bites[b].isSpecial,
                                inProduction: activeModelSet.containers[c].topics[t].bites[b].inProduction,
                                hidden: false
                            });
                        }
                    }

                }
            }

        } else if (Container !== -1) {
            //get topic children
            for (let c = 0; c < activeModelSet.containers.length; c++) {
                if (activeModelSet.containers[c].id === Container) {
                    for (let t = 0; t < activeModelSet.containers[c].topics.length; t++) {

                        this.Tree.push({
                            id: activeModelSet.containers[c].topics[t].id,
                            title: activeModelSet.containers[c].topics[t].title,
                            type: activeModelSet.containers[c].topics[t].type,
                            video: activeModelSet.containers[c].topics[t].video,
                            progressPercentage: activeModelSet.containers[c].topics[t].progressPercentage,
                            modelIcon: activeModelSet.containers[c].topics[t].modelIcon,
                            containerId: activeModelSet.containers[c].topics[t].containerId,
                            isSpecial: activeModelSet.containers[c].topics[t].isSpecial,
                            inProduction: activeModelSet.containers[c].topics[t].inProduction,
                            hidden: false
                        });
                    }
                }
            }

        }

        //set state
        if (this.Tree.length > 8) {
            var tiles2 = JSON.parse(JSON.stringify(this.Tree.slice(0, this.ItemsPerPage))).map((item, index) => { item.hidden = index > 3; return item });

            this.setState({
                Tiles1: tiles2,
                Tiles2: JSON.parse(JSON.stringify(this.Tree.slice(4, this.ItemsPerPage))),
                TotalItems: this.Tree.length,
                Page: 1,
                SlidePageCount: 1,
                SingleSlide: 0,
                SingleClass: " ",
                Pagination: [],
                Init2Rows: false,
                FetchNoMore: false,
                FetchingMore: false,
                Loading: false
            }, function () {
                this.checkArrowVisibility();
            });

        } else {
            this.setState({
                Tiles: this.Tree.slice(0, this.ItemsPerPage),
                TotalItems: this.Tree.length,
                Page: 1,
                SlidePageCount: 1,
                SingleSlide: 0,
                SingleClass: " ",
                Pagination: [],
                Init2Rows: false,
                FetchNoMore: true,
                FetchingMore: false,
                Loading: false
            }, function () {
                this.checkArrowVisibility();
            });
        }
        if (this.props.SelectedPath.LastUpdate === "Page") {
            //this.UpdateSelectedPath(null, null, null, "", null);
        }
    }

    /************************************
    * SLIDING 
    ***********************************/

    slide(dir) {
        //console.log("hit slide");
        if (!this.state.Sliding) {
            this.setState({ Sliding: true }, function () {
                //if (this.state.TotalItems > this.ItemsPerPage * 2) {
                if (this.refs.outerContainer.classList.contains("knowledge-explorer")) {

                    //check conainer and set arrows visibility
                    let page;
                    if (dir === 1) {
                        page = this.state.SlidePageCount + 1;
                        this.refs.leftArrow.classList.add("active");
                    } else {
                        page = this.state.SlidePageCount - 1;
                        if (page == 1) {
                            this.refs.leftArrow.classList.remove("active");
                        }
                    }

                    //set slide/page position
                    this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100) + "%)";
                    setTimeout(
                        function () {
                            this.setState({ Sliding: false });
                        }.bind(this),
                        300
                    );

                    this.setState({ SlidePageCount: page }, function () {
                        this.blockSwipe = false;
                        this.checkArrowVisibility(dir);
                    });

                } else {
                    this.slideSingle(dir);
                }
            });
        }
    }

    slideSingle(dir) {
        var page = this.state.SlidePageCount;
        var single = this.state.SingleSlide;

        if (dir === 1) {

            this.setState({ SingleSlide: single + 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 75) {
                    page = this.state.SlidePageCount + 1;

                    this.setState({ SingleSlide: -25, SlidePageCount: page });
                }
                this.refs.leftArrow.classList.add("active");
            });
        } else {
            if (single === -25) {
                single = 100;
                page = page - 1;
            }

            this.setState({ SingleSlide: single - 25 }, function () {
                this.refs.containerInner.style.transform = "translateX(-" + ((page - 1) * 100 + parseInt(this.state.SingleSlide)) + "%)";
                if (single === 100) {
                    this.setState({ SingleSlide: 75, SlidePageCount: page });
                } else if (single === 25 && page == 1) {
                    this.refs.leftArrow.classList.remove("active");
                }
            });
        }

        setTimeout(
            function () {
                this.setState({ SingleClass: (this.state.SingleSlide !== -25 ? " Single" + this.state.SingleSlide : ''), Sliding: false }, function () {
                    this.checkArrowVisibility();
                    this.blockSwipe = false;
                });
            }.bind(this),
            750
        );
    }

    /************************************
    * ARROW VISIBILITY
    ***********************************/

    checkArrowVisibility(dir) {
        let tempSize = 0;
        let stop = false;
        let i = 0;

        let TileWidth = 0;
        if (this.state.TotalItems > 0 && this.refs.containerInner.childNodes.length > 0) {
            TileWidth = this.refs.containerInner.childNodes[0].clientWidth;

            while (this.refs.containerInner.offsetWidth > tempSize && !stop) {

                if (i == this.refs.containerInner.childNodes.length) {
                    stop = true;
                } else if (tempSize + TileWidth > this.refs.containerInner.offsetWidth) {
                    stop = true;
                    //i--;
                } else {
                    tempSize += TileWidth;
                    i++;
                }
            }


            //if it's a double row slider
            if (this.state.TotalItems > 8) {
                if (!this.state.Init2Rows) {
                    this.Init2RowSlider(i);
                } else {
                    if (dir !== 0 && dir !== 1) {
                        this.Set2RowSlider(1, i, this.state.SlidePageCount);
                    } else {
                        this.Set2RowSlider(dir, i, this.state.SlidePageCount);
                    }
                }
            }

            //check position and load more if at the end
            //console.log("i: " + i + " x " + this.state.SlidePageCount + " <= " + this.state.Tiles.length);
            if ((this.state.TotalItems < 9 && (i * this.state.SlidePageCount < this.state.Tiles.length)) ||
                (this.state.TotalItems > 8 && (i * (this.state.SlidePageCount + 1) < this.state.Tiles1.length))) {
                this.refs.rightArrow.classList.add("active");
            } else {
                this.refs.rightArrow.classList.remove("active");
                this.checkForMoreTiles();
            }

            //if pagination doesn't exist yet
            if (this.state.Pagination.length === 0) {
                this.setPageNavigation(i);
            }
        }
    }

    /************************************
    * INITIALISE 2 ROW SLIDER (OK)
    ***********************************/

    Init2RowSlider(i) {
        //reset slide position
        this.refs.containerInner.style.transform = "translateX(0)";
        //reset arrow
        this.refs.leftArrow.classList.remove("active");
        //update state
        this.setState({ Init2Rows: true }, function () {
            setTimeout(
                function () {
                    if (this.refs.outerContainer) {
                        //whithout this, the bottom row doesn't slide
                        this.refs.outerContainer.classList.add("started");
                    }
                }.bind(this),
                1000
            );
        });
    }

    /************************************
    * UPATES VISIBLE TILES ON SLIDE (OK)
    ***********************************/

    Set2RowSlider(dir, items, page) {
        //console.log("page: " + page);
        //console.log("itemsPerPage: " + items);
        //console.log("dir: " + dir);
        var startIndex, finishIndex, t, T1, T2;

        if (dir === 1) {
            //top part
            startIndex = ((page - 1) * items);
            finishIndex = ((page) * items) - 1;
            T1 = this.state.Tiles1;
            for (t = finishIndex; t >= startIndex; t--) {
                T1[t].hidden = false;
            }
            this.setState({ Tiles1: T1 });

            //bottom part
            startIndex = ((page - 1) * items) - items;
            if (startIndex < 0) {
                startIndex = 0;
            }
            finishIndex = (page - 1) * items;
            T2 = this.state.Tiles2;

            for (t = startIndex; t < finishIndex; t++) {
                T2[t].hidden = true;
            }
            this.setState({ Tiles2: T2 });
        } else if (dir !== 1) {

            //top part
            startIndex = page * items;
            if (startIndex < 0) {
                startIndex = 0;
            }
            finishIndex = (page * items) + items;
            T1 = this.state.Tiles1;

            for (t = startIndex; t < finishIndex; t++) {
                T1[t].hidden = true;
            }
            this.setState({ Tiles1: T1 });

            //bottom part
            startIndex = ((page - 1) * items);
            finishIndex = ((page) * items) - 1;
            T2 = this.state.Tiles2;
            for (t = finishIndex; t >= startIndex; t--) {
                if (t >= 0) {
                    T2[t].hidden = false;
                }
            }
            this.setState({ Tiles2: T2 });
        }
    }

    /************************************
    * PAGINATION (OK)
    ***********************************/

    setPageNavigation(i) {
        //console.log("i: " + i);
        //console.log("total: " + this.state.TotalItems)
        var pg = [];
        if (i > 0) {
            var stop = this.state.TotalItems / i;
            if (this.state.TotalItems > 8) {
                stop--;
            }
            for (let p = 0; p < stop; p++) {
                pg.push(p + 1);
            }
        }

        this.setState({ Pagination: pg });
    }

    /************************************
    * LOAD MORE
    ***********************************/

    checkForMoreTiles() {
        if (this.state.TotalItems > 8 && this.state.Tiles1.length < this.state.TotalItems) {
            //2row slider
            if (!this.state.FetchNoMore && !this.state.FetchingMore) {
                this.setState({ Page: this.state.Page + 1, FetchingMore: true }, function () {
                    setTimeout(function () {
                        var newItems = this.Tree.slice(this.state.Tiles1.length, this.state.Tiles1.length + this.ItemsPerPage);
                        var newItemsTiles1 = JSON.parse(JSON.stringify(this.Tree.slice(this.state.Tiles.length, this.state.Tiles.length + this.ItemsPerPage)))
                            .map((item, index) => { item.hidden = true; return item });

                        this.setState({
                            Tiles1: this.state.Tiles1.concat(newItemsTiles1),
                            Tiles2: this.state.Tiles2.concat(JSON.parse(JSON.stringify(newItems))),
                            FetchingMore: false
                        }, function () {
                            if (parseInt(this.state.Tiles1.length) >= parseInt(this.state.TotalItems)) {
                                this.setState({ FetchNoMore: true });
                            }
                            this.checkArrowVisibility();
                        });

                    }.bind(this), 750);
                });
            } else {
                this.refs.rightArrow.classList.remove("active");
            }

        } else if (this.state.TotalItems < 9 && this.state.Tiles.length < this.state.TotalItems) {
            //this won't happen because 1 row is always single slide here
        }


    }

    /************************************
    * VIEW SWITCH
    ***********************************/

    switchView() {
        this.setState({ ViewMode: !this.state.ViewMode });
    }

    render() {
        const { Title } = this.props
        const { TotalItems, FetchingMore, FetchNoMore, Loading } = this.state

        return (
            <div ref="outerContainer" className={"tile-container newest knowledge-explorer" + (TotalItems < 9 && !Loading ? ' small-explorer' : '')}>
                <input type="checkbox" id={"tiles_" + Title.replace(" ", "")} hidden />
                <div className="tile-title">
                    <label htmlFor={"tiles_" + Title.replace(" ", "")}><ArrowRight /></label>
                    <div className={"view-mode" + (this.state.ViewMode ? ' vertical' : '')} onClick={() => this.switchView()}><ModeIcon /></div>
                    <span>{Title}</span>
                </div>
                <div className={"tile-content" + (this.state.Sliding ? ' sliding' : '') + (this.state.ViewMode ? ' vertical' : '')} ref="container" id="wheelExplorer">
                    <div className="tile-pagination">
                        {this.state.TotalItems > 0 && this.state.Pagination.map((P, index) => {
                            return <div className={this.state.SlidePageCount === (index + 1) ? 'active' : ''} key={"pageCount-" + index}></div>
                        })}
                    </div>

                    {!Loading && TotalItems < 9 &&
                        <div className={"tile-content-inner" + this.state.SingleClass} ref="containerInner">

                            {TotalItems > 0 && this.state.Tiles.map((T, index) => {
                                return <Tile Tile={T} key={"explorer" + index} />
                            }
                            )}
                            {FetchingMore && !this.state.ViewMode && <div className="tile">
                                <div className="tile-inner">
                                    <div className="content is"><Loader /></div>
                                </div>
                            </div>}
                        </div>
                    }
                    {!Loading && TotalItems > 8 &&
                        <div className="tile-content-inner" ref="containerInner">
                            {this.state.Tiles1.length > 0 && this.state.Tiles1.map((T, index) => {
                                return <Tile Tile={T} hidden={T.hidden} key={"explorer1" + index} />
                            }
                            )}
                        </div>
                    }
                    {!Loading && TotalItems > 8 &&
                        <div className="tile-content-inner bottom-row" ref="containerInner2">

                            {this.state.Tiles2.length > 0 && this.state.Tiles2.map((T, index) => {
                                return <Tile Tile={T} hidden={T.hidden} key={"explorer2" + index} />
                            }
                            )}
                            {FetchingMore && !this.state.ViewMode && <div className="tile">
                                <div className="tile-inner">
                                    <div className="content is"><Loader /></div>
                                </div>
                            </div>}
                        </div>
                    }
                    {Loading && !FetchingMore && <Loader />}
                    {!FetchNoMore && !Loading &&
                        <LoadMore loadMore={this.checkForMoreTiles} loading={FetchingMore} />
                    }

                    {!Loading && <TileShadow class="left" />}
                    {!Loading && <TileShadow class="right" />}
                    {!Loading && <div className="arrow left" ref="leftArrow" onClick={() => this.slide(0)}><ArrowLeft /></div>}
                    {!Loading && <div className="arrow right" ref="rightArrow" onClick={() => this.slide(1)}><ArrowRight /></div>}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {
        ModelSet,
        SelectedPath
    } = state.model;

    return {
        ModelSet,
        SelectedPath
    };
}

const actionCreators = {
    setSelectedPath: modelActions.setSelectedPath
};

const connectedExplorer = connect(mapState, actionCreators)(KnowledgeExplorer);
export { connectedExplorer as KnowledgeExplorer };