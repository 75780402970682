import React, { Component } from 'react';

export class ModelActiveL2 extends Component {
    constructor(props) {
        super(props);

        // initial state
        this.state = {
          
        }
      
    }
    

    render() {
        const { toggleLevel } = this.props
        return (
            <svg version="1.1" className="modelActive" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 95.09 92.93" xmlSpace="preserve">
                <g id="Circle">
	                <g>
		                <path className="st0" d="M88.79,63.32l0.06-0.15c0.02-0.05,0.04-0.1,0.06-0.15l0.74,0.29c-0.02,0.05-0.04,0.1-0.06,0.15l-0.06,0.15
			                L88.79,63.32z"/>
	                </g>
	                <g>
		
			                <rect x="88.51" y="64.23" transform="matrix(0.401 -0.9161 0.9161 0.401 -6.0949 119.9386)" className="st0" width="0.32" height="0.79"/>
	                </g>
	                <g>
		
			                <rect x="87.92" y="65.53" transform="matrix(0.4228 -0.9062 0.9062 0.4228 -8.9019 117.8727)" className="st0" width="0.32" height="0.79"/>
	                </g>
	                <g>
		                <polygon className="st0" points="87.04,67.16 87.07,67.09 87.11,67.02 87.18,66.88 87.89,67.24 87.81,67.38 87.78,67.45 87.74,67.52 
					                "/>
	                </g>
	                <g>
		                <polygon className="st0" points="86.37,68.4 86.53,68.13 87.22,68.51 87.06,68.79 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="85.67,69.63 85.83,69.35 86.51,69.75 86.35,70.03 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="84.93,70.82 85.1,70.56 85.77,70.98 85.6,71.25 		"/>
	                </g>
	                <g>
		                <path className="st0" d="M84.15,72c0.06-0.09,0.12-0.17,0.18-0.26l0.66,0.44c-0.06,0.09-0.12,0.18-0.18,0.27L84.15,72z"/>
	                </g>
	                <g>
		                <polygon className="st0" points="83.34,73.15 83.52,72.89 84.17,73.35 83.98,73.62 		"/>
	                </g>
	                <g>
		
			                <rect x="82.74" y="73.99" transform="matrix(0.6096 -0.7927 0.7927 0.6096 -26.6065 94.7506)" className="st0" width="0.32" height="0.79"/>
	                </g>
	                <g>
		                <path className="st0" d="M81.61,75.37c0.03-0.04,0.07-0.08,0.1-0.12l0.1-0.12l0.61,0.5l-0.1,0.13c-0.03,0.04-0.07,0.08-0.1,0.12
			                L81.61,75.37z"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="80.69,76.44 80.9,76.2 81.5,76.72 81.28,76.96 		"/>
                    </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel==2 ? 'hidden' : '')}>
		
			                <rect x="79.98" y="77.24" transform="matrix(0.6806 -0.7327 0.7327 0.6806 -31.2833 83.5131)" className="st0" width="0.32" height="0.79"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <path className="st0" d="M78.76,78.49c0.07-0.08,0.15-0.15,0.22-0.23l0.56,0.56c-0.08,0.08-0.15,0.15-0.23,0.23L78.76,78.49z"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="77.75,79.47 77.98,79.26 78.53,79.83 78.29,80.05 		"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="76.71,80.42 76.94,80.21 77.47,80.8 77.23,81.01 		"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="75.64,81.34 75.88,81.13 76.39,81.74 76.14,81.95 		"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <path className="st0" d="M74.54,82.22c0.08-0.07,0.17-0.13,0.25-0.19l0.49,0.62c-0.08,0.07-0.17,0.13-0.25,0.2L74.54,82.22z"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		
			                <rect x="73.62" y="82.89" transform="matrix(0.8081 -0.5891 0.5891 0.8081 -34.9054 59.4461)" className="st0" width="0.32" height="0.79"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="72.26,83.88 72.52,83.7 72.97,84.35 72.71,84.53 		"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <path className="st0" d="M71.09,84.65c0.09-0.05,0.18-0.11,0.27-0.17l0.43,0.66c-0.09,0.06-0.18,0.12-0.27,0.18L71.09,84.65z"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <polygon className="st0" points="69.89,85.39 70.16,85.23 70.57,85.91 70.29,86.07 		"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
			            <polygon className="st0" points="68.67,86.09 68.94,85.94 69.33,86.63 69.05,86.78 			"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
			            <polygon className="st0" points="67.42,86.76 67.7,86.61 68.07,87.31 67.79,87.46 			"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <path className="st0" d="M66.16,87.38c0.1-0.04,0.19-0.09,0.29-0.13l0.34,0.71c-0.1,0.05-0.19,0.09-0.29,0.14L66.16,87.38z"/>
	                </g>
                    <g className={(this.props.Level === 2 && this.props.activeModel == 5 ? 'hidden' : '')}>
		                <rect x="65.02" y="87.86" transform="matrix(0.9163 -0.4005 0.4005 0.9163 -29.8894 33.4885)" className="st0" width="0.32" height="0.79"/>
	                </g>
	                <g>
				        <rect x="63.71" y="88.42" transform="matrix(0.9258 -0.3781 0.3781 0.9258 -28.8402 30.7464)" className="st0" width="0.32" height="0.79"/>
	                </g>
	                <g>
			            <path className="st0" d="M62.26,89.01c0.1-0.03,0.2-0.07,0.3-0.11l0.28,0.74c-0.1,0.04-0.2,0.08-0.3,0.11L62.26,89.01z"/>
	                </g>
	                <g>
			            <polygon className="st0" points="60.93,89.47 61.23,89.37 61.48,90.12 61.18,90.22 			"/>
	                </g>
	                <g>
			            <polygon className="st0" points="59.59,89.89 59.89,89.8 60.12,90.55 59.81,90.65 			"/>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="58.23,90.26 58.54,90.18 58.74,90.95 58.43,91.03 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="56.86,90.6 57.02,90.56 57.17,90.53 57.35,91.3 57.19,91.33 57.04,91.37 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="55.48,90.89 55.79,90.83 55.95,91.6 55.63,91.66 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="54.1,91.13 54.41,91.08 54.54,91.86 54.22,91.91 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="52.7,91.34 53.02,91.29 53.12,92.08 52.8,92.12 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M51.3,91.5c0.05,0,0.11-0.01,0.16-0.01l0.16-0.02l0.08,0.79l-0.16,0.02c-0.05,0.01-0.11,0.01-0.16,0.01
				                L51.3,91.5z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="49.9,91.61 50.22,91.59 50.27,92.38 49.95,92.4 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="48.49,91.69 48.81,91.67 48.84,92.46 48.52,92.48 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="47.09" y="91.71" transform="matrix(0.9999 -0.0123 0.0123 0.9999 -1.1278 0.5872)" className="st0" width="0.32" height="0.79"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="45.68,91.7 45.99,91.71 45.98,92.5 45.66,92.5 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M44.27,91.65c0.11,0.01,0.21,0.01,0.32,0.02l-0.04,0.79c-0.11,0-0.21-0.01-0.32-0.02L44.27,91.65z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="42.86,91.55 43.18,91.57 43.12,92.36 42.79,92.33 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="41.46,91.4 41.78,91.44 41.69,92.23 41.37,92.19 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="40.07,91.22 40.38,91.26 40.27,92.05 39.95,92 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M38.68,90.99c0.05,0.01,0.1,0.02,0.16,0.03l0.16,0.03l-0.14,0.78l-0.16-0.03c-0.05-0.01-0.11-0.02-0.16-0.03
				                L38.68,90.99z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="37.29,90.71 37.6,90.78 37.44,91.55 37.13,91.49 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="35.92,90.4 36.23,90.47 36.04,91.24 35.73,91.16 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="34.56,90.04 34.87,90.12 34.66,90.88 34.35,90.8 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="33.21,89.64 33.36,89.69 33.51,89.73 33.28,90.49 33.13,90.44 32.97,90.39 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="31.87,89.19 32.17,89.3 31.92,90.04 31.61,89.94 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="30.55,88.7 30.85,88.82 30.57,89.56 30.27,89.44 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="29.21,88.16 29.5,88.28 29.2,89.01 28.9,88.89 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M27.96,87.61c0.1,0.04,0.19,0.09,0.29,0.13l-0.33,0.72c-0.1-0.04-0.19-0.09-0.29-0.14L27.96,87.61z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="26.26" y="87.27" transform="matrix(0.4453 -0.8954 0.8954 0.4453 -63.4947 72.3578)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="24.99" y="86.62" transform="matrix(0.4664 -0.8846 0.8846 0.4664 -63.2131 68.7607)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M24.2,85.67c0.09,0.05,0.18,0.11,0.28,0.16l-0.39,0.69c-0.09-0.05-0.19-0.1-0.28-0.16L24.2,85.67z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="22.73,84.77 23,84.94 22.58,85.61 22.31,85.44 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="21.55,84.01 21.81,84.18 21.37,84.84 21.1,84.66 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="20.39,83.2 20.65,83.39 20.19,84.03 19.93,83.84 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="19.27,82.36 19.52,82.55 19.04,83.18 18.78,82.98 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="18.16,81.48 18.41,81.68 17.91,82.3 17.66,82.09 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M17.09,80.57l0.12,0.11c0.04,0.03,0.08,0.07,0.12,0.1l-0.52,0.6c-0.04-0.04-0.08-0.07-0.12-0.1l-0.12-0.11
					                L17.09,80.57z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="15.49" y="79.87" transform="matrix(0.6806 -0.7327 0.7327 0.6806 -53.5578 37.2026)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="14.46" y="78.89" transform="matrix(0.6985 -0.7156 0.7156 0.6985 -52.0834 34.4616)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M14.04,77.65c0.04,0.04,0.07,0.08,0.11,0.11l0.11,0.11l-0.57,0.55l-0.11-0.12
					                c-0.04-0.04-0.07-0.08-0.11-0.12L14.04,77.65z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="13.08,76.61 13.29,76.85 12.71,77.38 12.49,77.14 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="12.16,75.55 12.37,75.79 11.76,76.3 11.56,76.05 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="11.27,74.45 11.47,74.7 10.85,75.2 10.65,74.94 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M10.42,73.33c0.06,0.08,0.12,0.17,0.19,0.26l-0.63,0.47c-0.06-0.09-0.13-0.17-0.19-0.26L10.42,73.33z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="8.97" y="72.38" transform="matrix(0.8218 -0.5698 0.5698 0.8218 -39.6628 18.2622)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="8.18" y="71.21" transform="matrix(0.8357 -0.5492 0.5492 0.8357 -37.7871 16.435)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="8.07,69.82 8.15,69.96 8.23,70.09 7.56,70.51 7.47,70.37 7.39,70.23 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="7.36,68.6 7.52,68.88 6.83,69.27 6.67,68.99 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="6.02" y="67.53" transform="matrix(0.8839 -0.4677 0.4677 0.8839 -30.9104 10.8585)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="6.06,66.11 6.2,66.39 5.49,66.74 5.35,66.45 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="5.47,64.83 5.6,65.12 4.88,65.44 4.75,65.15 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="4.92,63.53 5.04,63.82 4.31,64.13 4.19,63.83 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="3.7" y="62.35" transform="matrix(0.9348 -0.3551 0.3551 0.9348 -21.9285 5.5271)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M3.94,60.89c0.02,0.05,0.03,0.1,0.05,0.15l0.05,0.15l-0.75,0.26L3.24,61.3c-0.02-0.05-0.03-0.1-0.05-0.15
					                L3.94,60.89z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="3.51,59.55 3.6,59.85 2.85,60.09 2.76,59.78 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="3.13,58.19 3.21,58.5 2.45,58.71 2.36,58.4 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.79,56.83 2.86,57.14 2.09,57.32 2.02,57.01 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M2.49,55.45l0.03,0.16c0.01,0.05,0.02,0.1,0.03,0.15l-0.78,0.16c-0.01-0.05-0.02-0.11-0.03-0.16l-0.03-0.16
					                L2.49,55.45z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.23,54.07 2.29,54.38 1.51,54.51 1.45,54.2 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.02,52.67 2.07,52.99 1.28,53.1 1.24,52.78 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="1.08" y="51.31" transform="matrix(0.9935 -0.114 0.114 0.9935 -5.8567 0.5036)" className="st0" width="0.79" height="0.32"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M1.73,49.87c0.01,0.11,0.02,0.21,0.02,0.32l-0.79,0.06c-0.01-0.11-0.02-0.21-0.03-0.32L1.73,49.87z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="1.65,48.46 1.66,48.78 0.87,48.82 0.86,48.5 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="1.61,47.06 1.61,47.37 0.82,47.39 0.82,47.07 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="1.61,45.65 1.61,45.97 0.82,45.96 0.82,45.63 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="1.66,44.24 1.65,44.56 0.86,44.52 0.87,44.2 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M1.75,42.83c-0.01,0.11-0.02,0.21-0.02,0.32l-0.79-0.06c0.01-0.11,0.02-0.21,0.03-0.32L1.75,42.83z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="1.89,41.43 1.85,41.75 1.07,41.67 1.1,41.34 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.07,40.04 2.02,40.35 1.24,40.24 1.28,39.92 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.29,38.64 2.23,38.96 1.45,38.83 1.51,38.51 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M2.55,37.26c-0.01,0.05-0.02,0.1-0.03,0.15l-0.03,0.16l-0.78-0.16l0.03-0.16c0.01-0.05,0.02-0.11,0.03-0.16
					                L2.55,37.26z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="2.86,35.89 2.79,36.2 2.02,36.02 2.09,35.7 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="3.21,34.52 3.13,34.83 2.36,34.62 2.45,34.31 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="3.6,33.17 3.51,33.47 2.76,33.25 2.85,32.94 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M4.04,31.83l-0.05,0.15c-0.02,0.05-0.03,0.1-0.05,0.15l-0.75-0.25c0.02-0.05,0.03-0.1,0.05-0.15l0.05-0.15
					                L4.04,31.83z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="4.52,30.51 4.41,30.8 3.67,30.53 3.78,30.23 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="5.04,29.2 4.92,29.49 4.19,29.19 4.31,28.89 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="5.6,27.9 5.47,28.19 4.75,27.87 4.88,27.58 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="6.2,26.63 6.06,26.92 5.35,26.57 5.49,26.28 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="6.26" y="24.94" transform="matrix(0.4677 -0.8839 0.8839 0.4677 -18.9761 19.1568)" className="st0" width="0.32" height="0.79"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="7.52,24.14 7.36,24.42 6.67,24.03 6.83,23.75 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="8.23,22.93 8.15,23.06 8.07,23.2 7.39,22.79 7.47,22.65 7.56,22.52 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="8.41" y="21.26" transform="matrix(0.55 -0.8352 0.8352 0.55 -14.2303 16.9056)" className="st0" width="0.32" height="0.79"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="9.21" y="20.08" transform="matrix(0.5698 -0.8218 0.8218 0.5698 -12.8002 16.5081)" className="st0" width="0.32" height="0.79"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M10.61,19.43c-0.06,0.08-0.12,0.17-0.19,0.26l-0.64-0.47c0.06-0.09,0.13-0.17,0.19-0.26L10.61,19.43z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="11.47,18.32 11.27,18.57 10.65,18.08 10.85,17.83 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="12.37,17.23 12.16,17.48 11.56,16.97 11.76,16.72 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="13.29,16.17 13.08,16.41 12.49,15.88 12.71,15.64 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M14.26,15.15l-0.11,0.11c-0.04,0.04-0.07,0.08-0.11,0.11l-0.57-0.55c0.04-0.04,0.07-0.08,0.11-0.12
					                l0.11-0.12L14.26,15.15z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="14.7" y="13.58" transform="matrix(0.7157 -0.6984 0.6984 0.7157 -5.5362 14.3507)" className="st0" width="0.32" height="0.79"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="16.27,13.18 16.04,13.39 15.5,12.81 15.74,12.59 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <path className="st0" d="M17.33,12.24c-0.04,0.03-0.08,0.07-0.12,0.1l-0.12,0.11l-0.52-0.6l0.12-0.11c0.04-0.04,0.08-0.07,0.12-0.1
					                L17.33,12.24z"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="18.41,11.34 18.16,11.54 17.66,10.93 17.91,10.73 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				
					                <rect x="18.99" y="9.86" transform="matrix(0.7925 -0.6098 0.6098 0.7925 -2.2802 13.8065)" className="st0" width="0.32" height="0.79"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="20.65,9.64 20.39,9.82 19.93,9.18 20.19,8.99 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="21.81,8.84 21.55,9.02 21.1,8.36 21.37,8.18 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="23,8.08 22.73,8.25 22.31,7.58 22.58,7.41 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="24.2,7.35 23.93,7.51 23.53,6.83 23.81,6.67 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="25.02" y="5.9" transform="matrix(0.8846 -0.4664 0.4664 0.8846 -0.0277 12.4707)" className="st0" width="0.32" height="0.79"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="26.49" y="5.2" transform="matrix(0.8955 -0.4451 0.4451 0.8955 0.2948 12.4473)" className="st0" width="0.32" height="0.79"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M28.24,5.28c-0.1,0.04-0.19,0.09-0.29,0.13l-0.33-0.72c0.1-0.05,0.19-0.09,0.29-0.14L28.24,5.28z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="29.54,4.72 29.24,4.84 28.94,4.11 29.24,3.99 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="30.85,4.21 30.55,4.32 30.27,3.58 30.57,3.46 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="32.17,3.73 31.87,3.83 31.61,3.09 31.92,2.98 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="33.51,3.29 33.36,3.34 33.21,3.39 32.97,2.63 33.13,2.58 33.28,2.54 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="34.87,2.9 34.56,2.98 34.35,2.22 34.66,2.14 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="36.23,2.55 35.92,2.63 35.73,1.86 36.04,1.78 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="37.6,2.24 37.29,2.31 37.13,1.54 37.44,1.47 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M38.99,1.98L38.83,2c-0.05,0.01-0.1,0.02-0.16,0.03l-0.14-0.78c0.05-0.01,0.11-0.02,0.16-0.03l0.16-0.03
				                L38.99,1.98z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="40.38,1.76 40.07,1.8 39.95,1.02 40.27,0.98 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="41.78,1.58 41.46,1.62 41.37,0.83 41.69,0.8 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="43.18,1.45 42.86,1.48 42.79,0.69 43.12,0.66 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M44.58,1.36c-0.11,0-0.21,0.01-0.32,0.02l-0.04-0.79c0.11-0.01,0.21-0.01,0.32-0.02L44.58,1.36z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="45.99,1.31 45.68,1.32 45.66,0.53 45.98,0.52 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="46.85" y="0.75" transform="matrix(0.0139 -0.9999 0.9999 0.0139 45.6789 48.1413)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="48.81,1.35 48.49,1.34 48.52,0.55 48.84,0.56 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="50.22,1.43 49.9,1.41 49.95,0.62 50.27,0.64 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M51.62,1.56l-0.16-0.02c-0.05-0.01-0.11-0.01-0.16-0.01l0.08-0.79c0.05,0,0.11,0.01,0.16,0.01l0.16,0.02
				                L51.62,1.56z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="53.02,1.73 52.7,1.68 52.8,0.9 53.12,0.94 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="54.41,1.94 54.1,1.89 54.22,1.11 54.54,1.16 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="55.79,2.2 55.48,2.14 55.63,1.36 55.95,1.42 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="57.17,2.5 57.02,2.46 56.86,2.43 57.04,1.65 57.19,1.69 57.35,1.73 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="58.54,2.84 58.23,2.76 58.43,1.99 58.74,2.08 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="59.89,3.23 59.59,3.14 59.81,2.38 60.12,2.47 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="61.23,3.65 60.93,3.55 61.18,2.8 61.48,2.9 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M62.56,4.12c-0.1-0.04-0.2-0.08-0.3-0.11l0.27-0.74c0.1,0.03,0.2,0.07,0.3,0.11L62.56,4.12z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="63.48" y="4.05" transform="matrix(0.3784 -0.9256 0.9256 0.3784 35.8073 61.7403)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="64.79" y="4.6" transform="matrix(0.4002 -0.9164 0.9164 0.4002 34.7327 62.5916)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M66.45,5.78c-0.1-0.05-0.19-0.09-0.29-0.14l0.34-0.71c0.1,0.05,0.2,0.09,0.29,0.14L66.45,5.78z"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="67.7,6.41 67.42,6.27 67.79,5.56 68.07,5.71 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="68.94,7.08 68.67,6.93 69.05,6.24 69.33,6.39 			"/>
		                </g>
	                </g>
	                <g>
		                <polygon className="st0" points="70.16,7.79 69.89,7.63 70.29,6.95 70.57,7.12 		"/>
	                </g>
	                <g>
		                <path className="st0" d="M71.35,8.54c-0.09-0.06-0.18-0.12-0.27-0.17l0.43-0.67c0.09,0.06,0.18,0.12,0.27,0.18L71.35,8.54z"/>
	                </g>
	                <g>
		
			                <rect x="72.22" y="8.75" transform="matrix(0.5696 -0.8219 0.8219 0.5696 23.9315 63.5197)" className="st0" width="0.79" height="0.32"/>
	                </g>
	                <g>
		
			                <rect x="73.38" y="9.57" transform="matrix(0.5901 -0.8073 0.8073 0.5901 22.3819 63.5487)" className="st0" width="0.79" height="0.32"/>
	                </g>
	                <g>
		                <path className="st0" d="M74.79,11c-0.08-0.07-0.17-0.13-0.25-0.19l0.49-0.62c0.08,0.07,0.17,0.13,0.25,0.2L74.79,11z"/>
	                </g>
	                <g>
		                <polygon className="st0" points="75.88,11.89 75.64,11.69 76.14,11.08 76.39,11.28 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="76.94,12.81 76.71,12.6 77.23,12.01 77.47,12.22 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="77.98,13.77 77.75,13.55 78.29,12.97 78.53,13.19 		"/>
	                </g>
	                <g>
		                <path className="st0" d="M78.98,14.75c-0.07-0.08-0.15-0.15-0.22-0.23l0.56-0.56c0.08,0.08,0.15,0.15,0.23,0.23L78.98,14.75z"/>
	                </g>
	                <g>
		                <polygon className="st0" points="79.96,15.77 79.74,15.54 80.32,15 80.54,15.23 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="80.9,16.82 80.69,16.58 81.28,16.06 81.5,16.3 		"/>
	                </g>
	                <g>
		                <path className="st0" d="M81.81,17.9l-0.1-0.12c-0.03-0.04-0.07-0.08-0.1-0.12l0.61-0.51c0.03,0.04,0.07,0.08,0.1,0.12l0.1,0.13
			                L81.81,17.9z"/>
	                </g>
	                <g>
		
			                <rect x="82.5" y="18.47" transform="matrix(0.7934 -0.6087 0.6087 0.7934 5.7874 54.3142)" className="st0" width="0.79" height="0.32"/>
	                </g>
	                <g>
		                <polygon className="st0" points="83.52,20.13 83.34,19.87 83.98,19.41 84.17,19.67 		"/>
	                </g>
	                <g>
		                <path className="st0" d="M84.33,21.28c-0.06-0.09-0.12-0.17-0.18-0.26l0.65-0.45c0.06,0.09,0.12,0.18,0.18,0.27L84.33,21.28z"/>
	                </g>
	                <g>
		                <polygon className="st0" points="85.1,22.47 84.93,22.2 85.6,21.77 85.77,22.04 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="85.83,23.67 85.67,23.4 86.35,22.99 86.51,23.27 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="86.53,24.89 86.37,24.62 87.06,24.23 87.22,24.52 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="87.18,26.14 87.11,26 87.07,25.93 87.04,25.86 87.74,25.5 87.78,25.57 87.81,25.64 87.89,25.79 		"/>
	                </g>
	                <g>
		                <polygon className="st0" points="87.79,27.41 87.66,27.12 88.37,26.78 88.51,27.07 		"/>
	                </g>
	                <g>
		
			                <rect x="88.27" y="28.23" transform="matrix(0.9164 -0.4003 0.4003 0.9164 -3.9506 37.8726)" className="st0" width="0.79" height="0.32"/>
	                </g>
	                <g>
		                <g>
			                <line className="st0" x1="1.18" y1="45.91" x2="1.21" y2="45.91"/>
			                <line className="st0" x1="1.21" y1="47.4" x2="1.18" y2="47.4"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="88.75,30 88.63,29.71 89.36,29.41 89.49,29.71 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="89.25,31.32 89.15,31.02 89.89,30.74 90,31.04 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="89.72,32.65 89.62,32.35 90.37,32.09 90.47,32.4 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			                <g>
				                <polygon className="st0" points="90.14,33.99 90.05,33.69 90.8,33.46 90.9,33.77 				"/>
			                </g>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="90.55" y="35.03" transform="matrix(0.9692 -0.2462 0.2462 0.9692 -5.8639 23.4753)" className="st0" width="0.79" height="0.32"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="90.91,56.59 90.98,56.28 91.75,56.44 91.68,56.76 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			
				                <rect x="90.84" y="57.5" transform="matrix(0.2477 -0.9688 0.9688 0.2477 12.3657 131.7229)" className="st0" width="0.32" height="0.79"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="90.21,59.32 90.3,59.01 91.06,59.22 90.97,59.53 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <polygon className="st0" points="89.8,60.66 89.9,60.36 90.65,60.6 90.56,60.91 			"/>
		                </g>
	                </g>
	                <g>
		                <g>
			                <path className="st0" d="M89.35,62l0.05-0.15c0.02-0.05,0.03-0.1,0.05-0.15l0.75,0.26c-0.02,0.05-0.03,0.1-0.05,0.15l-0.06,0.15
				                L89.35,62z"/>
		                </g>
	                </g>
                </g>
        		<g id="Navigation">
					<path className="line-mid st1" d="M91.92,40.7c0.61,3.4,0.72,6.95,0.28,10.53"/>
					<g className={"level l1" + (this.props.Level === 2 ? ' active' : '')} onClick={() => toggleLevel(true)}>
                        <path className="st2" d="M92.94,51.56c-0.19-0.14-0.42-0.25-0.67-0.3c-0.88-0.16-1.73,0.42-1.89,1.3 s0.42,1.73,1.3,1.89c0.88,0.16,1.73-0.42,1.89-1.3C93.69,52.52,93.42,51.92,92.94,51.56" />
		                <path className="st0" d="M91.55,53.59v-0.16l0.45-0.7l0.09-0.17l0.03-0.19l-0.01-0.1l-0.05-0.09l-0.11-0.03l-0.05,0.01l-0.07,0.03 l-0.05,0.1l-0.01,0.16v0.12h-0.2v-0.19l0.01-0.12l0.03-0.09l0.09-0.11l0.1-0.05l0.16-0.02l0.1,0.01l0.15,0.08l0.08,0.12l0.04,0.2 l-0.03,0.18l-0.06,0.17l-0.09,0.14l-0.35,0.54h0.55v0.19H91.55z"/>
	                </g>
                    <g className={"level l1" + (this.props.Level === 1 ? ' active' : '')} onClick={() => toggleLevel(false)}>
                        <path className="st2" d="M92.86,37.77c-0.19-0.14-0.42-0.25-0.67-0.3c-0.88-0.16-1.73,0.42-1.89,1.3 c-0.16,0.88,0.42,1.73,1.3,1.89c0.88,0.16,1.73-0.42,1.89-1.3C93.61,38.74,93.34,38.13,92.86,37.77" />
		                <path className="st0" d="M91.81,39.82v-1.19h-0.25v-0.15l0.06-0.01l0.09-0.04l0.05-0.05l0.03-0.06l0.03-0.12h0.19v1.61H91.81z"/>
	                </g>
                </g>
            </svg>

            );
    }
}
