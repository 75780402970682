import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modelActions } from './../../../store/_actions';
//components
import { Loader } from './../Loader/Loader';
import { Model1 } from './../../pages/mkp/home/ModelCarousel/Models/Model1';
import { Model2 } from './../../pages/mkp/home/ModelCarousel/Models/Model2';
import { Model3 } from './../../pages/mkp/home/ModelCarousel/Models/Model3';
import { Model4 } from './../../pages/mkp/home/ModelCarousel/Models/Model4';
import { Model5 } from './../../pages/mkp/home/ModelCarousel/Models/Model5';
//icons
import { ReactComponent as IconClose } from './../../../Assets/images/icons/icon_close_1.svg';
//styles
import "./TileEmbed.scss";

class TileEmbed extends Component {

    constructor(props) {
        super(props);

        this.contextElement = React.createRef();

        this.state = {
            Tile: this.props.Tile,
            Loading: true
        };
    }

    /************************************
    * LIFECYCLE
    ***********************************/

    componentDidMount() {
        console.log("mount tile context");
        const { Tile } = this.state;

        var pathFound = null;
        if (Tile.type === "Bite") {
            pathFound = this.findPath(Tile.Topic.containerId);

            //check missing info for tile                
            if (pathFound) {
                this.setState({
                    Tile: {
                        ...Tile,
                        Model: {
                            id: pathFound.Model.id,
                            title: pathFound.Model.title
                        },
                        Container: {
                            id: pathFound.Container.id,
                            title: pathFound.Container.title
                        },
                        containerId: pathFound.Container.id,
                    },
                    Loading: false
                })
            } else  {
                this.setState({
                    Tile: {
                        ...Tile,
                        containerId: Tile.Topic.containerId
                    }
                }, function () {
                    this.props.getContainer(Tile.Topic.containerId, this.props.SelectedLanguage);
                });
            }
        } else {
            this.setState({ Loading: false });
        }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        //get missing info for tile
        if (prevProps.LoadedContainer !== this.props.LoadedContainer) {
            if (this.props.LoadedContainer.id === this.state.Tile.containerId && !this.state.Tile.Container) {

                this.setState({
                    Tile: {
                        ...this.state.Tile,
                        Container: {
                            id: this.props.LoadedContainer.id,
                            title: this.props.LoadedContainer.title
                        },
                        Model: {
                            id: this.props.LoadedContainer.modelId,
                            title: this.props.ModelSet.items.filter(m => m.id === this.props.LoadedContainer.modelId)[0].title
                        }
                    },
                    Loading: false
                })
            }
        }
    }

    /************************************
    * HANDLES CLICK OUTSIDE THE WINDOW
    ***********************************/

    handleClickOutside = (e) => {
        if (this.contextElement && !this.contextElement.current.contains(e.target) && !(e.target.classList.contains("t-embed") && this.contextElement.current.parentNode.contains(e.target))) {
            //close it
            this.props.handleClose(this.props.Tile.id, e, e.target.classList.contains("t-embed"));
        }
    }

    /************************************
    * GET FULL PATHY FROM MODELSET
    ***********************************/

    findPath(id) {
        var containerFound = null;
        var foundPath = {};
        for (var m = 0; m < this.props.ModelSet.items.length && !containerFound; m++) {
            foundPath.Model = {
                id: this.props.ModelSet.items[m].id,
                title: this.props.ModelSet.items[m].title,
            };

            if (this.props.ModelSet.items[m].containers) {
                if (this.props.ModelSet.items[m].containers.filter(c => c.id === id).length > 0) {
                    containerFound = true;
                    foundPath.Container = {
                        id: this.props.ModelSet.items[m].containers.filter(c => c.id === id)[0].id,
                        title: this.props.ModelSet.items[m].containers.filter(c => c.id === id)[0].title,
                    };
                }
            }
        }

        if (containerFound) {
            return foundPath;
        } else {
            return null;
        }
    }

    render() {
        const { Tile, Loading } = this.state
        return (
            <div className="embed" ref={this.contextElement}>
                {!Loading && <div className="embed-inner">
                    <div className="e-top"><div onClick={() => this.props.handleClose(Tile.id)}><IconClose /></div></div>
                    {Tile.Model && <div className="e-title">{Tile.Model.title}</div>}
                    {Tile.Model && <div className="e-model">
                        {Tile.Model.id === 1 && <Model1 FromContext={true} />}
                        {Tile.Model.id === 3 && <Model2 FromContext={true} Tile={Tile} />}
                        {Tile.Model.id === 2 && <Model3 FromContext={true} Tile={Tile} />}
                        {Tile.Model.id === 5 && <Model4 FromContext={true} Tile={Tile} />}
                        {Tile.Model.id === 4 && <Model5 FromContext={true} />}
                    </div>}
                    <div className="e-bottom">
                        {Tile.Container && <div className="e-text" dangerouslySetInnerHTML={{ __html: Tile.Container.title }}></div>}
                        {Tile.type === "Topic" && Tile.title && <div className={"e-text" + (!Tile.title ? ' active' : '')} dangerouslySetInnerHTML={{ __html: Tile.title }}></div>}
                        {Tile.type === "Bite" && Tile.Topic && <div className={"e-text" + (!Tile.title ? ' active' : '')} dangerouslySetInnerHTML={{ __html: Tile.Topic.title }}></div>}
                        {Tile.type === "Bite" && <div className="e-text active" dangerouslySetInnerHTML={{ __html: Tile.title }}></div>}
                    </div>
                </div>}
                {Loading && <div className="embed-inner"><Loader /></div>}
            </div>
        );
    }
}

function mapState(state) {
    const {
        SelectedLanguage
    } = state.layout;

    const {
        ModelSet,
        LoadedContainer
    } = state.model;

    return {
        SelectedLanguage,
        ModelSet,
        LoadedContainer,
    };
}

const actionCreators = {
    getContainer: modelActions.getContainer
};

const connectedTileEmbed = connect(mapState, actionCreators)(TileEmbed);
export { connectedTileEmbed as TileEmbed };