import { highlightsConstants } from '../_constants';

const initialState = {
    highlights: []
}

export function highlights(state = initialState, action) {
    switch (action.type) {
        case highlightsConstants.HIGHLIGHTS_REQUEST:
            return {
                ...state,
                highlightsLoader: true
            };
        case highlightsConstants.HIGHLIGHTS_SUCCESS:
            return {
                ...state,
                highlights: action.highlights.data,
                highlightsLoader: false

            };
        case highlightsConstants.HIGHLIGHTS_FAILURE:
            return {
                ...state,
                highlightsLoader: false
            };
        default:
            return state
    }
}

export default highlights